<template>
	<div class="container-page">
		<paginacao-filtro
			@filtrar="filtrar"
			@limpar="limparFiltro"
			:mostrarBotaoNovo="mostrarBotaoNovo"
			@adicionarNovo="$emit('adicionarNovo')"
			@handleShowTable="handleShowTable"
			:ControlePaginacao="Paginacao"		
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="input-group input-group-previsao">
								<select v-model="filtro.tipoData" @change="limparDatas">
									<option value="SEL">
										Nenhuma
									</option>
									<option value="cheg">
										Chegada
									</option>
									<option value="ped">
										Pedido
									</option>
									<option value="prev">
										Prevista
									</option>
									<option value="venc">
										Vencimento
									</option>
								</select>
								<label for="data" style="background-color: #f8f8f8;">
									Data
								</label>
							</div>
							<div class="input-group" v-if="filtro.tipoData=='cheg'">
								<input
									v-model="filtro.dataChegadaI"
									id="dataChegadaI" 
									type="date"  
									placeholder=" "
									@blur="saiuData"
								/>
								<label for="dataChegadaI" class="lg-view" style="background-color: #f8f8f8;">
									Data ínicio chegada
								</label>
								<label for="dataChegadaI" class="sm-view" style="background-color: #f8f8f8;">
									Início chegada
								</label>
							</div>
							<div class="input-group" v-if="filtro.tipoData=='cheg'">
								<input
									v-model="filtro.dataChegadaF"
									id="dataChegadaF" 
									type="date"  
									placeholder=" "
									@blur="saiuData"
								/>
								<label for="dataChegadaF" class="lg-view" style="background-color: #f8f8f8;">
									Data Final chegada
								</label>
								<label for="dataChegadaF" class="sm-view" style="background-color: #f8f8f8;">
									Final chegada
								</label>
							</div>
							<div class="input-group" v-if="filtro.tipoData=='ped'">
								<input
									v-model="filtro.dataPedidoI"
									id="dataPedidoI" 
									type="date"
									placeholder=" "
									@blur="saiuData"
								/>
								<label for="dataPedidoI" class="lg-view" style="background-color: #f8f8f8;">
									Data ínicio pedido
								</label>
								<label for="dataPedidoI" class="sm-view" style="background-color: #f8f8f8;">
									Início pedido
								</label>
							</div>
							<div class="input-group" v-if="filtro.tipoData=='ped'">
								<input
									v-model="filtro.dataPedidoF"
									id="dataPedidoF" 
									type="date"  
									placeholder=" "
									@blur="saiuData"
								/>
								<label for="dataPedidoF" class="lg-view" style="background-color: #f8f8f8;">
									Data Final pedido
								</label>
								<label for="dataPedidoF" class="sm-view" style="background-color: #f8f8f8;">
									Final pedido
								</label>
							</div>
							<div class="input-group" v-if="filtro.tipoData=='prev'">
								<input
									v-model="filtro.dataPrevistaI"
									id="dataPrevistaI" 
									type="date"
									placeholder=" "
									@blur="saiuData"
								/>
								<label for="dataPrevistaI" class="lg-view" style="background-color: #f8f8f8;">
									Data ínicio prevista
								</label>
								<label for="dataPrevistaI" class="sm-view" style="background-color: #f8f8f8;">
									Início prevista
								</label>
							</div>
							<div class="input-group" v-if="filtro.tipoData=='prev'">
								<input	
									v-model="filtro.dataPrevistaF"
									id="dataPrevistaF" 
									type="date"
									placeholder=" "
									@blur="saiuData"
								/>
								<label for="dataPrevistaF" class="lg-view" style="background-color: #f8f8f8;">
									Data Final prevista
								</label>
								<label for="dataPrevistaF" class="sm-view" style="background-color: #f8f8f8;">
									Final prevista
								</label>
							</div>
							<div class="input-group" v-if="filtro.tipoData=='venc'">
								<input
									v-model="filtro.dataVencimentoI"
									id="dataVencimentoI" 
									type="date"
									placeholder=" "
									@blur="saiuData"
								/>
								<label for="dataVencimentoI" class="lg-view" style="background-color: #f8f8f8;">
									Data ínicio vencimento
								</label>
								<label for="dataVencimentoI" class="sm-view" style="background-color: #f8f8f8;">
									Início vencimento
								</label>
							</div>
							<div class="input-group" v-if="filtro.tipoData=='venc'">
								<input	
									v-model="filtro.dataVencimentoF"
									id="dataVencimentoF" 
									type="date"
									placeholder=" "
									@blur="saiuData"
								/>
								<label for="dataVencimentoF" class="lg-view" style="background-color: #f8f8f8;">
									Data Final vencimento
								</label>
								<label for="dataVencimentoF" class="sm-view" style="background-color: #f8f8f8;">
									Final vencimento
								</label>
							</div>
						</div>
						<div class="row-filtro">
							<div class="input-group input-group-previsao">
								<input 
									v-model="filtro.codigo"
									type="text" 
									id="cdpedido" 
									placeholder=" " 
									autocomplete="off"
								/>
								<label for="cdpedido" style="background-color: #f8f8f8;">
									Código Pedido
								</label>
							</div>
							<div class="input-group input-group-previsao">
								<select v-model="filtro.empresa">
									<option :value="emp.id" :key="emp.id" v-for="emp in Empresas">
										{{ emp.fantasia }}
									</option>
								</select>
								<label for="empresa" style="background-color: #f8f8f8;">
									Empresa
								</label>
							</div>
							<div class="input-group input-group-previsao">
								<input 
									type="text" 
									id="fornecedor" 
									placeholder=" " 
									v-model="filtro.nome"
									autocomplete="off"
								/>
								<label for="fornecedor" style="background-color: #f8f8f8;">
									Nome
								</label>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<TabelaPrevisaoCompra 
					@selecionarPrevisaoCompra="$emit('selecionarPrevisaoCompra', $event)" 
					@filtrarPromocoes="filtrar(null)"
					:showTable="showTable"
					v-if="filtro.tipoData != 'venc'"
				/>
				<TabelaPrevisaoCompraVencimento
					:showTable = "showTable"
					:Parcelas = "Parcelas"
					v-else
				/>
			</template>
		</paginacao-filtro>
	</div>
</template>
<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import TabelaPrevisaoCompra from "../components/TabelaPrevisaoCompra.vue";
import TabelaPrevisaoCompraVencimento from "./TabelaPrevisaoCompraVencimento.vue";
import { dateFormatISO } from "../utils/dateformat";
export default{
	name: "ConsultaPrevisaoCompra",
	components: {
		PaginacaoFiltro,
		TabelaPrevisaoCompra,
		TabelaPrevisaoCompraVencimento
	},
	props: {
		mostrarBotaoNovo: Boolean,
	},
	data() {
		return {
			filtro: {
				codigo: "",
				dataChegadaF: "",
				dataChegadaI: "",
				dataPedidoF: "",
				dataPedidoI: "",
				dataPrevistaF: "",
				dataPrevistaI: "",
				dataVencimentoF: "",
				dataVencimentoI: "",
				nome: "",
				page: "",
				size: "",
				sort: "",
				sortOrder: "",
				tipoData: "SEL",
				empresa: this.$store.state.Empresa.id,
			},	
			Parcelas: [],		
			showTable: true,
		};
	},
	computed: {
		Empresas() {
			return this.$store.state.Empresas;
		},
		Paginacao() {
			return {
				paginaAtual: this.$store.state.paginaPrevisaoComprasAtual,
				totalPaginas: this.$store.state.totalPaginasPrevisaoCompras
			};
		}
	},
	methods: {
		saiuData(){
			if(this.filtro.dataChegadaI == "" && this.filtro.tipoData === "cheg"){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataChegadaI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.filtro.dataChegadaF == "" && this.filtro.tipoData === "cheg"){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataChegadaF = dateFormatISO({date: null, format: "ISOdate"});
			}
			if(this.filtro.dataPedidoI == "" && this.filtro.tipoData === "ped"){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataPedidoI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.filtro.dataPedidoF == "" && this.filtro.tipoData === "ped"){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataPedidoF = dateFormatISO({date: null, format: "ISOdate"});
			}
			if(this.filtro.dataPrevistaI == "" && this.filtro.tipoData === "prev"){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataPrevistaI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.filtro.dataPrevistaF == "" && this.filtro.tipoData === "prev"){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataPrevistaF = dateFormatISO({date: null, format: "ISOdate"});
			}
			if(this.filtro.dataVencimentoI == "" && this.filtro.tipoData === "venc"){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataVencimentoI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.filtro.dataVencimentoF == "" && this.filtro.tipoData === "venc"){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataVencimentoF = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		handleShowTable() {
			this.showTable = !this.showTable;
		},
		limparDatas(){			
			const dataAtual = new Date();
			const dataAtualFormatada = dataAtual.getFullYear() + "-" + (dataAtual.getMonth() + 1).toString().padStart(2, "0") + "-" + dataAtual.getDate().toString().padStart(2, "0");
			const dataInicialFormatada = dataAtual.getFullYear() + "-" + (dataAtual.getMonth() + 1).toString().padStart(2, "0") + "-01";
			this.filtro.dataChegadaF = "";
			this.filtro.dataChegadaI = "";
			this.filtro.dataPedidoF = "";
			this.filtro.dataPedidoI = "";
			this.filtro.dataPrevistaF = "";
			this.filtro.dataPrevistaI = "";
			this.filtro.dataVencimentoF = "";
			this.filtro.dataVencimentoI = "";
			if(this.filtro.tipoData === "cheg"){
				this.filtro.dataChegadaF = dataAtualFormatada;
				this.filtro.dataChegadaI = dataInicialFormatada;
			}
			if(this.filtro.tipoData === "ped"){
				this.filtro.dataPedidoF = dataAtualFormatada;
				this.filtro.dataPedidoI = dataInicialFormatada;
			}
			if(this.filtro.tipoData === "prev"){
				this.filtro.dataPrevistaF = dataAtualFormatada;
				this.filtro.dataPrevistaI = dataInicialFormatada;
			}
			if(this.filtro.tipoData === "venc"){
				this.filtro.dataVencimentoF = dataAtualFormatada;
				this.filtro.dataVencimentoI = dataInicialFormatada;
			}
			this.limparTabela();		
		},
		limparFiltro() {
			this.filtro = {
				codigo: "",
				dataChegadaF: "",
				dataChegadaI: "",
				dataPedidoF: "",
				dataPedidoI: "",
				dataPrevistaF: "",
				dataPrevistaI: "",
				dataVencimentoF: "",
				dataVencimentoI: "",
				nome: "",
				page: "",
				size: "",
				sort: "",
				sortOrder: "",
				tipoData: "SEL",
				empresa: this.$store.state.Empresa.id,
			};			
			this.Parcelas = [];
			this.limparTabela();
		},
		limparTabela(){
			this.$store.commit("limpaPrevisaoCompra");
		},
		async filtrar(page) {
			this.filtro.page = page;
			try {
				if(this.filtro.tipoData != "venc"){
					const previsaoCompraValidacaoData = (this.filtro.dataChegadaF?this.filtro.dataChegadaF:1)<(this.filtro.dataChegadaI?this.filtro.dataChegadaI:0) || (this.filtro.dataPedidoF?this.filtro.dataPedidoF:1)<(this.filtro.dataPedidoI?this.filtro.dataPedidoI:0) || (this.filtro.dataPrevistaF?this.filtro.dataPrevistaF:1)<(this.filtro.dataPrevistaI?this.filtro.dataPrevistaI:0);
					if( previsaoCompraValidacaoData ){
						throw new Error("A data final não pode ser antes da data inicial, verifique!");
					}else{
						await this.$store.dispatch("filtrarPrevisaoCompras", {...this.filtro});					
						this.$forceUpdate();
						return ;
					}
				}
				else{
					const previsaoCompraValidacaoData = (this.filtro.dataVencimentoF?this.filtro.dataVencimentoF:1)<(this.filtro.dataVencimentoI?this.filtro.dataVencimentoI:0);
					if( previsaoCompraValidacaoData ){
						throw new Error("A data final não pode ser antes da data inicial, verifique!");
					}else{
						this.Parcelas = await this.$store.dispatch("filtrarPrevisaoComprasVencimento", {...this.filtro});					
						this.$forceUpdate();
						return ;
					}
				}
			}
			catch(error){
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		}
	},
	mounted() {
		this.limparFiltro();
	}
};
</script>
<style scoped>
.input-group{
	margin: 10px;
	width: 100%;
}
.filtro-content{
	width: 100%;
	display: flex;
	flex-direction: row;
}
.container-page {
	width: 100%;
	overflow: hidden;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 10px;
}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
#textoPesquisa{
	width: 65vh;
}
.sm-view {
	display: none;
}

.lg-view {
	display: inline-block;
}
@media screen and (max-width:1080px){
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
}
@media screen and (max-width: 769px) {
	#textoPesquisa{
		width: 42vh;
	}
}
@media screen and (max-width: 425px){
	.filtro-content{
		flex-direction: column;
	}
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
	.row-filtro .input-group{
		margin-right: 0px;
	}
}
</style>