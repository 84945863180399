import {doRequest} from "./http.js";
import { 
	ContaPagar,
	Titulo,
	TituloBaixarAPI,
} from "../models/ContaPagar";
export default{
	state: {
		ContasPagar: {
			ListaContas: [],
			paginacao: {
				totalPaginas: 0,
				paginaAtual: 0,
				totalElements: 0,
				size: 25,
			},
		},
	},
	actions: {
		// TITULOS
		async addTituloPagar({ state, commit }, payload) {
			try {
				state.carregando = true;
				const url = "contasAPagar/adicionar";
				payload.cdEmpresa = state.Empresa.id;
				const body = JSON.stringify(payload);
				const data = await doRequest(url, {
					method: "POST",
					body,
				}, commit);
				state.carregando = false;
				if(data && data.length > 0) {
					for(let error of data) {
						commit("relatarErro", {
							mensagem: `Erro: O campo ${error.field === "nome" ? "fornecedor" : null}, ${error.error}`
						});
					}
					return {error: true};
				}

				if(data && data?.cddig){
					commit("relatarErro", {
						mensagem: "Títulos adicionados com sucesso!",
						sucess: true 
						
					});
					return data;
				} else {
					commit("relatarErro", {
						mensagem: "Algo deu errado, por favor tente novamente, se o erro persistir entre em contato com o suporte!"
					});
					return {error: true};
				}

			} catch (error) {
				commit("relatarErro", {
					mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))
				});
				console.log(error);
				return null;
			}
		},
		
		async adicionarTitulosPagar({state, commit}, payload){
			const cdIdentificador = state.Caixa.caixa.cdcaixa;
			try {
				state.carregando = true;
				payload.cdEmpresa = state.Empresa.id;
				let url = `contasAPagar/adicionarLista?cdIdentificador=${cdIdentificador}`;

				const body = JSON.stringify(payload);
				const data = await doRequest(url, {
					method: "POST",
					body,
					cache: "no-cache"
				}, commit);
				state.carregando = false;
				
				if(data && data[0]?.cddig){
					commit("relatarErro", {
						mensagem: "Títulos adicionados com sucesso!",
						sucess: true 
						
					});
					return data;
				} else {
					commit("relatarErro", {
						mensagem: "Algo deu errado, por favor tente novamente, se o erro persistir entre em contato com o suporte!"
					});
				}
				return null;
				
			} catch (error) {
				console.error(error);
				commit("relatarErro", {
					mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))
				});
				return null;
			}
		},

		async atualizarTitulo({state, commit}, payload) {
			try {
				state.carregando = true;
				payload.cdEmpresa = state.Empresa.id;
				let url = "contasAPagar/modificar";
				if(!payload.cddig) {
					throw new Error("Parece que algo deu errado, por favor tente novamente!");
				} else {
					url +=  `?cddig=${payload.cddig}`;
				}
				const body = JSON.stringify(payload);
				const data = await doRequest(url, {
					method: "PUT",
					body,
					cache: "no-cache"
				}, commit);
				state.carregando = false;
				if(data.mensagem && !data.erro) {
					commit("relatarErro", {
						mensagem: data.mensagem,
						sucess: true,
					});
					return data;
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {
					mensagem: error.message,
				});
			}
		},

		//METODOS CONTA A PAGAR
		async buscarContaUnica({ commit}, payload){
			try {
				const {
					cdagencia,
					cdbanco,
					cdconta,
					cddig,
					tipo
				} = payload;
				let url = "contasAPagar";
				url += `${cdagencia ? `?cdagencia=${cdagencia?.trim()}` : ""} `;
				url += `${cdbanco ? `&cdbanco=${cdbanco?.trim()}` : ""} `;
				url += `${cdconta ? `&cdconta=${cdconta?.trim()}` : ""} `;
				url += `${cddig ? `&cddig=${cddig}` : ""} `;
				url += `${tipo ? `&tipo=${tipo?.trim()}` : ""} `;
				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache"
				}, commit);
				if(data && !data.erro){
					return new Titulo(data).convertForm(data);
				}
				return null;
				
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message|| JSON.stringify(error)});
			}
		},

		async filtrarContasPagar({ commit}, payload){
			try {
				const {
					dataVencI,
					dataVencF,
					dataBaixaI,
					dataBaixaF,
					dataDocI,
					dataDocF,
					cdnota,
					cdPedido,
					cdfornecedor,
					condicao,
					valor,
					cdempresa,
					sort,
					sortOrder,
					query,
					page, 
					size
				} = payload;
				let url = "contasAPagar/contas";
				url += `${sort ? `?sort=${sort}` : "?sort=datavenc"}`;
				url += `${sortOrder ? `&sortOrder=${sortOrder}` : "&sortOrder=DESC"}`;
				url += `${cdempresa ? `&cdempresa=${cdempresa}` : ""}`;
				url += `${dataVencI ? `&dataVencI=${dataVencI}` : ""}`;
				url += `${dataVencF ? `&dataVencF=${dataVencF}` : ""}`;
				url += `${dataBaixaI ? `&dataBaixaI=${dataBaixaI}` : ""}`;
				url += `${dataBaixaF ? `&dataBaixaF=${dataBaixaF}` : ""}`;
				url += `${dataDocI ? `&dataDocI=${dataDocI}` : ""}`;
				url += `${dataDocF ? `&dataDocF=${dataDocF}` : ""}`;
				url += `${cdnota ? `&cdcompra=${cdnota}` : ""}`;
				url += `${cdPedido ? `&cddoc=${cdPedido}` : ""}`;
				url += `${cdfornecedor ? `&cdfornecedor=${cdfornecedor}` : ""}`;
				url += `${condicao ? `&condicao=${condicao}` : "&condicao=A"}`;
				url += `${valor ? `&valor=${valor}` : ""}`;
				url += `${query ? `&nome=${query}` : ""}`;
				url += `${page ? `&page=${page}` : ""}`;
				url += `${size ? `&size=${size}` : ""}`;
				
				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache"
				}, commit);

				if(data && data.mensagem){
					commit("atualizarListaContasPagar", []);
					return null;
				}
				if(data && !data.erro){
					commit("atualizarListaContasPagar", data);
				}

			} catch (error) {
				console.log(error);
			}
		},

		
		async alterarSalvarTitulo({state, commit}, payload){
			try {
				state.carregando = true;
				let url = `contasAPagar/modificar?cddig=${payload.cddig}`;
				const body = JSON.stringify(payload);
				const data = await doRequest(url,{
					method: "PUT",
					body
				}, commit);
				state.carregando = false;
				if(data && data.mensagem && !data.erro){
					return commit("relatarErro", {
						mensagem: data.mensagem,
						sucess: true
					});
				} else {
					return commit("relatarErro", {
						mensagem: data.mensagem,
					});
				}
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}
		},

		async alteraVencimento({state, commit}, payload){
			try {
				state.carregando = true;
				let url = "contasAPagar/prorrogar-vencimento";
				const body = JSON.stringify(payload);
				const data = await doRequest(url,{
					method: "PUT",
					body
				}, commit);
				state.carregando = false;
				return data;
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}
		},
		
		async removerLancamento({state, commit}, payload){
			try {
				const {
					cdagencia,
					cdbanco,
					cdconta,
					cddig,
					tipo
				} = payload;
				let url = `contasAPagar/removerLancamento?cdusuario=${state.Usuario.id}`;
				url += `${cdagencia ? `&cdagencia=${cdagencia}` : ""}`;
				url += `${cdbanco ? `&cdbanco=${cdbanco}` : ""}`;
				url += `${cdconta ? `&cdconta=${cdconta}` : ""}`;
				url += `${cddig ? `&cddig=${cddig}` : ""}`;
				url += `${tipo ? `&tipo=${tipo}` : ""}`;
				const data = await doRequest(url, {
					method: "DELETE",
					cache: "no-cache"
				}, commit);
				if(data && data.erro){
					return;
				}
				if(data && data.mensagem){
					commit("relatarErro", {
						mensagem: data.mensagem
					});
				}
				
			} catch (error) {
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
			}
		},

		async estornarTituloBaixado({state, commit}, payload){
			try {
				const {
					cdagencia,
					cdbanco,
					cdconta,
					cddig,
					tipo
				} = payload;
				let url = `contasAPagar/removerLancamento?cdusuario=${state.Usuario.id}`;
				url += `${cdagencia ? `&cdagencia=${cdagencia}` : ""}`;
				url += `${cdbanco ? `&cdbanco=${cdbanco}` : ""}`;
				url += `${cdconta ? `&cdconta=${cdconta}` : ""}`;
				url += `${cddig ? `&cddig=${cddig}` : ""}`;
				url += `${tipo ? `&tipo=${tipo}` : ""}`;
	
				const data = await doRequest(url, {method: "DELETE", cache: "no-cache"}, commit);
				if(data && data.erro){
					return;
				}
				if(data && data.mensagem){
					commit("relatarErro", {
						mensagem: data.mensagem
					});
				}
				
			} catch (error) {
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
			}

		},

		async baixarTitulos({state, commit}, payload){
			try {
				state.carregando = true;
				let url = "contasAPagar/baixarEmLote";
				url += `?cdusuario=${state.Usuario.id}`;
				const form = [];
				if(payload?.forEach){
					payload.forEach(conta => form.push(new TituloBaixarAPI(conta)));
				}
				const body = JSON.stringify(form);
				const data = await doRequest(url,  {
					method: "POST",
					body,
					cache: "no-cache"
				}, commit);
				state.carregando = false;
				if(data && data.erro){
					commit("relatarErro", {
						mensagem: data.mensagem,
					});
					return null;
				}
				if(data && data.mensagem && data.mensagem !== "Caixa está fechado, verifique!"){
					commit("relatarErro", {
						mensagem: data.mensagem,
						sucess: true
					});
				} else {
					commit("relatarErro", {
						mensagem: data.mensagem,
					});
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", { mensagem: error.message });
				return null;
			}	
		},

		async addTransferenciaFinanceiro({state, commit}, payload) {
			state.carregando = true;
			try {
				const body = JSON.stringify(payload);

				const url = "transferencia-financeiro";

				const data = await doRequest(url, {
					method: "POST",
					body,
				}, commit);
				state.carregando = false;
				if(data && (!data.erro || !data.mensagem)) {
					return commit("relatarErro", {
						mensagem: "Transferência realizada com sucesso",
						sucess: true,
					});
				}
			} catch (error) {
				console.error("Erro ao realizar transferência entre contas", error);
				state.carregando = false;
			}
		}
	},
	
	mutations :{
		atualizarListaContasPagar(state, payload){
			state.ContasPagar.ListaContas = [];
			if(payload?.content?.length > 0){
				state.ContasPagar.ListaContas = payload?.content.map(movi => new ContaPagar(movi));
			}
			state.ContasPagar.paginacao = {
				last: payload.last || false,
				paginaAtual: payload.page || 0,
				size: payload.size || 25,
				totalElements: payload.totalElements || 0,
				totalPaginas: payload.totalPages || 0
			};
		}
	}
};