<template>
	<div class="caixa-financeiro-item">
		<label :for="`${movimento.cdtipo}V`">{{ movimento.descricao }}</label>
		<i
			class="fas fa-plus-circle"
			:style="`${movimento.deposito == 'S' ? 'color: var(--bg-primario);' : 'color: var(--confirmar);'}`"
			@click="buscarMovimento(movimento.cdtipo, movimento.tipo)"
		></i>
		<input
			type="text"
			:id="`${movimento.cdtipo}RS`"
			class="text-right"
			readonly
			:value="(movimento.valor || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
		>
		<input
			type="text"
			:id="`${movimento.cdtipo}RM`"
			class="text-right"
			readonly
			:value="(movimento.valorManual || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
			@contextmenu.prevent="alterarValorInformado(movimento)"
			@dblclick="alterarValorInformado(movimento)"
		>
		<input
			type="text"
			:id="`${movimento.cdtipo}DR`"
			:class="`text-right ${classDiferenca}`"
			readonly
			:value="Number(valorDiferenca || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
			@click="consultarMovimentos(movimento)"
		>
	</div>
</template>
<script>
export default {
	name: "MovimentacaoCaixa",
	props: {
		movimento: Object
	},
	computed: {
		Caixa() {
			return this.$store.state.Caixa;
		},
		classDiferenca () {
			if (this.valorDiferenca > 0) {
				return  "positiva";
			} else if(this.valorDiferenca < 0){
				return "negativa";
			} else {
				return "";
			}
		},
		valorDiferenca () {
			let valor = Number(this.movimento.valor).toFixed(4);
			valor.substring(0, valor.length - 2);

			let valorManual = Number(this.movimento.valorManual).toFixed(4);
			valorManual.substring(0, valorManual.length - 2);

			return Number(Number(valorManual || 0)) - Number(Number(valor|| 0));
		},
	},
	methods: {
		buscarMovimento(cdtipo, tipo){
			this.$emit("buscarMovimento", cdtipo, tipo);
		},
		alterarValorInformado(movimento){
			this.$emit("alterarValorInformado", movimento);
		},
		consultarMovimentos(movimento) {
			if (this.valorDiferenca != 0) {
				this.$emit("consultarMovimentos", movimento);
			}
		}
	}
};

</script>
<style scoped>
.caixa-financeiro-item {
	display: flex;
	padding: 2px;
	position: relative;
}

.caixa-financeiro-item i {
	position: absolute;
	left: 31%;
	top: 5px;
	font-size: 14pt;
}

.caixa-financeiro-item label,
.caixa-item label {
	width: 30%;
	margin: 0px 2px;
	text-align: right;
	word-wrap: normal;
	font-size: 10pt;
}

.caixa-item input,
.caixa-financeiro-item input {
	width: 20%;
	margin: 0px 2px;
	text-align: right;
}

.negativa{
	color: red; 
	border: 1px solid red;
	cursor: pointer;
}

.positiva{
	color: blue; 
	border: 1px solid blue;
	cursor: pointer;
}
</style>