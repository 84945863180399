<template>
	<div class="container-page">
		<paginacao-filtro
			@filtrar="filtrar"
			@limpar="limparFiltro"
			:mostrarBotaoNovo="mostrarBotaoNovo"
			@adicionarNovo="$emit('adicionarNovo')"
			@handleShowTable="handleShowTable"
			:ControlePaginacao="Paginacao"
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">							
							<div class="input-group">
								<input 
									type="search" 
									id="textoPesquisa" 
									name="filtro" 
									placeholder=" " 
									autocomplete="off"
									v-model="filtroPesquisa"
									@keydown.enter.prevent="filtrar"
								/>
								<label for="textoPesquisa" style="background-color: #f8f8f8;">
									Pesquisa
								</label>
							</div>							
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<TabelaContas 
					tipoConteudo="Contas" 
					v-on:selecionarConta="$emit('selecionarConta', $event)" 
					:showTable="showTable"
				/>
			</template>
		</paginacao-filtro>
	</div>
</template>

<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import TabelaContas from "../components/TabelaContas.vue";
export default{
	name: "ConsultaContas",
	components: {
		PaginacaoFiltro,
		TabelaContas,
	},	
	props: {
		mostrarBotaoNovo: Boolean,
	},
	data() {
		return {
			filtroPesquisa: "",
			checkboxPesquisa: true,
			showTable: true,
		};
	},
	computed: {
		Paginacao() {
			return {
				paginaAtual: this.$store.state.paginaContasAtual,
				totalPaginas: this.$store.state.totalPaginasContas
			};
		}
	},
	methods: {
		handleShowTable() {
			this.showTable = !this.showTable;
		},
		limparFiltro() {
			this.filtroPesquisa = "";
			this.checkboxPesquisa = true;
			this.focarInputPesquisa();
			this.filtrar(null);
		},
		focarInputPesquisa() {
			setTimeout(() => {
				document.getElementById("textoPesquisa").focus();
			}, 50);
		},
		async filtrar(page) {
			const filtro = {
				page: page || 0,
				query: this.filtroPesquisa,		
				todos: this.filtroPesquisa,		
				buscaTodasEmpresas: this.todasEmpresas,
			};
			if(!this.filtroPesquisa){
				await this.$store.dispatch("buscarContas", filtro);
			}else{
				await this.$store.dispatch("filtrarContas", filtro);
			}			
		}
	},
	mounted() {
		this.filtrar();
	}
};
</script>

<style scoped>
.container-page {
	width: 100%;
	overflow: hidden;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;
}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
#textoPesquisa{
	width: 50vw;
}
@media screen and (max-width: 769px) {
	#textoPesquisa{
		width: 42vh;
	}
}
</style>