<template>
	<div :class="`${!showTable ? 'table-full' : ''}`" id="listaModal" v-if="Vendas.length > 0" :style="{'--height': height}">
		<table>
			<thead>
				<tr>
					<td class="centro-azul">Cód. Venda</td>
					<td class="centro-azul">NFC-e</td>
					<td>Nome Cliente </td>
					<td>Produto</td>
					<td class="centro">Itens</td>
					<td>Data de Emissão</td>
					<td>Valor Unitário</td>
					<td>ValorPago</td>
				</tr>
			</thead>
			<tbody>
				<tr 
					v-for="(venda, index) in Vendas" :key="index" 
					@click="$emit('selecionarVenda', venda)"
				>
					<td class="centro-azul">
						<button :id="index" class="hand-button" v-if="index == indexSelecionado" 
							@keydown.tab.prevent="" > 
							<i class="fas fa-hand-point-right" style="color: var(--primario)" ></i>
						</button>
						{{ venda.cdVenda }}
					</td>
					<td class="centro-azul">
						{{ venda.nfce }}
					</td>
					<td>
						{{ venda.nomeCliente }}
					</td>
					<td>
						{{ venda.cdProduto }} - {{ venda.descricaoProduto }}
					</td>
					<td class="centro">
						{{ venda.qtdProdutos }}
					</td>
					<td class="centro">
						{{ venda.dataEmissao }}
					</td>
					<td class="direita">
						{{ (venda.valorUnitario || 0) | formatarParaReais }}
					</td>
					<td class="direita">
						{{ (venda.valorPago || 0) | formatarParaReais }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="mensagemVenda" v-else>
		<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
		&nbsp;
		<h1>Ajuste os filtros para começar a buscar!</h1>
	</div>
</template>
<script>
import formatarParaReais from "../filters/formatarParaMoeda";
export default{
	name: "ListagemVendasTroca",
	props: {
		Vendas: Array,
		showTable: Boolean,
		height: String,
	},
	data(){
		return {
			indexSelecionado: 0
		};
	},

	filters: {
		formatarParaReais
	},

	created() {
		document.addEventListener("keydown", this.eventosTeclado);
	},

	destroyed() {
		document.removeEventListener("keydown",this.eventosTeclado);
	},

	methods: {
		eventosTeclado(e) {
			if (e.key == "ArrowUp") {
				if (this.indexSelecionado > 0) {
					this.indexSelecionado--;
				}
				setTimeout(() => {
					document.getElementById(this.indexSelecionado).focus();
				}, 10);
			}
			if (e.key == "ArrowDown") {
				if (this.indexSelecionado < this.Vendas.length -1) {
					this.indexSelecionado++;
				}
				setTimeout(() => {
					document.getElementById(this.indexSelecionado).focus();
				}, 10);
			}
			if (e.key == "Tab") {
				this.indexSelecionado = 0;
				setTimeout(() => {
					if (e.target.id == "nomeCliente") {
						document.getElementById("campoPesquisa").focus();
					} else if (e.target.id == "campoPesquisa") {
						document.getElementById("textoPesquisa").focus();
						document.getElementById("textoPesquisa").select();
					} else {
						document.getElementById("nomeCliente").focus();
						document.getElementById("nomeCliente").select();
					}
				}, 50);
			}
		}
	}
};
</script>
<style scoped>
table {
	padding: 10px;
}
table thead tr td,
table tbody tr td{
	white-space: nowrap;
	text-align: left;
	width: 100%;
}
.centro-azul{
	color: var(--primario);
	text-align: center;
}
.centro{
	text-align: center;
}
.direita{
	text-align: right;
}
.mensagemVenda{
	display: flex;
	height: 65vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
#listaModal.table-full{
	height: 77vh !important;
}
#listaModal {
    width: 95%;
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;	
	height: var(--height) !important;
}
@media screen and (max-width: 768px) {
	#listaModal.table-full{
		height: 68vh !important;
	}
	#listaModal{
		height: 43vh !important;
	}
}
@media screen and (max-width: 425px) {
	#listaModal.table-full{
		height: 71vh !important;
	}
	#listaModal{
		height: 30vh !important;
	}
}
</style>