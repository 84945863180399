<template>
	<div class="container-page-relatorio">
		<i 
			class="fas fa-print"
			style="color: var(--primario)"
			id="impressao"
			@click="toggleDropdown()"
			v-if="CRM && CRM.length > 0"
		/>
		<div class="dropdown-add" id="dropdownExport" ref="dropdownExport">
			<span
				class="dropdown-item"
				@click="exportarXLSX()"
			>
				Exportar para Excel
			</span>
			<span
				class="dropdown-item"
				@click="exportarPDF()"
			>
				Exportar para PDF
			</span>
		</div>
		<Filtro
			@handleShowTable="handleShowTable"
			@filtrar="filtrar"
			@limpar="limpar"
		>
			<div class="filtro-content">
				<div class="column-filtro">
					<div class="row-filtro">
						<div 
							class="input-group select-group" 
						>
							<select 
								id="tipo" 
								v-model="filtro.tipo"
							>
								<option value="D">
									Meses sem compra
								</option>
								<option value="P">
									Periodo última compra
								</option>
							</select>
							<label for="tipo" style="background-color: #f8f8f8;">
								Tipo Filtro
							</label>
						</div>
						<div 
							class="input-group select-group" 
							v-if="filtro.tipo==='D'"
						>
							<select 
								id="mesesAno" 
								v-model="filtro.dias"
								style="min-width: 170px;"
							>
								<option value="30">
									1 mês
								</option>
								<option value="60">
									2 meses
								</option>
								<option value="90">
									3 meses
								</option>
								<option value="120">
									4 meses
								</option>
								<option value="150">
									5 meses
								</option>
								<option value="180">
									6 meses
								</option>
							</select>
							<label for="mesesAno" style="background-color: #f8f8f8;">
								Meses sem compra
							</label>
						</div>
						<div class="row" v-else>
							<div class="input-group">
								<input 
									type="date" 
									v-model="filtro.dataI"
									placeholder=" "
									id="dataI"
									@blur="validarData('dataI')"
								/>
								<label for="dataI">
									Data Inicial
								</label>
							</div>
							<div class="input-group">
								<input 
									type="date" 
									v-model="filtro.dataF"
									placeholder=" "
									id="dataF"
									@blur="validarData('dataF')"
								/>
								<label for="dataF">
									Data Final
								</label>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</Filtro>
			<div>
				<div 
					class="container-tabela"
					v-if="CRM.length > 0 && !loadingTable"
				>
					<div class="header-tabela">
						<div class="linha-header-tabela">
							<div class="campo-tabela campo-menor">
								Cód.
							</div>
							<div class="campo-tabela campo-maior">
								Nome
							</div>
							<div class="campo-tabela campo-medio">
								Celular
							</div>
							<div class="campo-tabela campo-medio">
								Telefone
							</div>
							<div class="campo-tabela campo-medio">
								Nascimento
							</div>
							<div class="campo-tabela campo-medio">
								Primeira
							</div>
							<div class="campo-tabela campo-medio">
								Ultima
							</div>
							<div class="campo-tabela campo-menor">
								Qtd.
							</div>
							<div class="campo-tabela campo-medio">
								Valor
							</div>
							<div class="campo-tabela campo-maior">
								Endereco
							</div>
							<div class="campo-tabela campo-maior">
								Bairro
							</div>
							<div class="campo-tabela campo-medio">
								Cidade
							</div>
							<div class="campo-tabela campo-menor">
								UF
							</div>
						</div>
					</div>
					<div class="body-tabela">
						<div 
							class="linha-body-tabela" 
							v-for="(crm, index) in CRM"
							:key="index"
						>
							<div class="campo-tabela campo-menor">
								{{ crm.cdcliente }}
							</div>
							<div class="campo-tabela campo-maior">
								{{ crm.nome }}
							</div>
							<div class="campo-tabela campo-medio">
								{{ crm.celular }}
							</div>
							
							<div class="campo-tabela campo-medio">
								{{ crm.fone }}
							</div>
							<div class="campo-tabela campo-medio">
								{{ crm.nascimento?crm.nascimento.split("-").reverse().join("/"):"" }}
							</div>
							<div class="campo-tabela campo-medio">
								{{ crm.primeira.split("-").reverse().join("/") }}
							</div>
							<div class="campo-tabela campo-medio">
								{{ crm.ultima.split("-").reverse().join("/") }}
							</div>
							<div class="campo-tabela campo-menor">
								{{ crm.qtdade }}
							</div>
							<div class="campo-tabela campo-medio">
								{{ (crm.valor || 0) | formatarParaReais }}
							</div>
							<div class="campo-tabela campo-maior">
								{{ crm.endereco }}
							</div>
							<div class="campo-tabela campo-maior">
								{{ crm.bairro }}
							</div>
							<div class="campo-tabela campo-medio">
								{{ crm.nomecidade }}
							</div>
							<div class="campo-tabela campo-menor">
								{{ crm.ufcidade }}
							</div>
						</div>
					</div>
				</div>
				<div class="container-aviso" v-else>
					<i 
						class="fas fa-exclamation-triangle" 
						style="color: yellow" 
					/>
					<span>
						Nenhum faturamento encontrado!
					</span>
				</div>
			</div>
	</div>
</template>
<script>
import Filtro from "../components/Filtro.vue";
import formatarParaReais from "../filters/formatarParaMoeda";
import { proximoCampo } from "../utils/utils";
export default {
	name: "RelatorioAniversarios",
	data() {
		return {
			proximoCampo,
			filtro:{
				tipo:"D",
				dias:"30",
				dataI:new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1
				).toLocaleDateString("en-CA"),
				dataF:new Date().toLocaleDateString("en-CA"),
			},
			fullTable: false,
		};
	},
	components: {
		Filtro,
	},

	computed: {
		CRM(){
			return this.$store.state.Crm.ListaCrm || [];
		},
	},
	filters: {
		formatarParaReais
	},
	mounted() {
		this.filtrar();
	},

	methods: {
		validarData(campo) {
			if(this.filtro[campo] === ""){
				this.$store.commit("relatarErro", {
					mensagem: "Data inválida, por favor verifique"
				});
				this.proximoCampo(campo);
			}
		},
		limpar() {
			this.filtro.tipo = "D";
			this.filtro.dias = "SEL";
			this.filtro.dataI = new Date(
				new Date().getFullYear(),
				new Date().getMonth(),
				1
			).toLocaleDateString("en-CA");
			this.filtro.dataF = new Date().toLocaleDateString("en-CA");
			this.filtrar();
		},
		async filtrar() {
			let filtro = {};
			if(this.filtro.tipo === "D"){
				filtro.dias = new Date();
				filtro.dias.setDate(filtro.dias.getDate() - this.filtro.dias);
				filtro.dias = filtro.dias.toLocaleDateString("en-CA");
			}
			else{
				filtro.dataI = this.filtro.dataI;
				filtro.dataF = this.filtro.dataF;
			}
			filtro.tipo = this.filtro.tipo;
			await this.$store.dispatch("filtrarCrm", filtro);
		},
		toggleDropdown() {
			const dropdown = document.getElementById("dropdownExport");
			if(dropdown) {
				dropdown.classList.toggle("dropdown-active");
			}
		},
		async exportarPDF() {
			try {				
				if(this.CRM && this.CRM.length === 0) {
					throw new Error(
						"Para gerar o relatório é necessário filtrar ao menos um registro, favor verificar"
					);
				}
				let filtro = {};
				if(this.filtro.tipo === "D"){
					filtro.dias = this.filtro.dias;
				}
				else{
					filtro.dataI = this.filtro.dataI;
					filtro.dataF = this.filtro.dataF;
				}
				filtro.tipo = this.filtro.tipo;
				await this.$store.dispatch("gerarRelatorioCrmPDF", {empresa:this.$store.state.Empresa, itens: this.CRM, filtro: filtro});
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
				});
				return null;
			}
		},
		async exportarXLSX() {
			try {				
				if(this.CRM && this.CRM.length === 0) {
					throw new Error(
						"Para gerar o relatório é necessário filtrar ao menos um registro, favor verificar"
					);
				}
				let filtro = {};
				if(this.filtro.tipo === "D"){
					filtro.dias = this.filtro.dias;
				}
				else{
					filtro.dataI = this.filtro.dataI;
					filtro.dataF = this.filtro.dataF;
				}
				filtro.tipo = this.filtro.tipo;
				await this.$store.dispatch("gerarRelatorioCrmXLSX", {crm: this.CRM, filtro: filtro});
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
				});
				return null;
			}
		},
	},
};
</script>
<style scoped>
.dropdown-add{
	position: absolute;
	bottom: 50px;
	left: 185px;
	display: flex;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	width: 40px;
	height: 40px;
	gap: 5px;
	border-radius: 10px;
	padding: 5px;
	transition: all .2s ease;
	opacity: 0;
	font-size: 2px;	
	background: #FFF;
	z-index: 2;
}
.dropdown-add span {
	cursor: pointer;
	width: 100%;
	padding: 0px 5px;
}
.dropdown-add span:hover {
	border-bottom: 1px solid #cecece;
}
.dropdown-active {
	transition: all .2s ease;
	opacity: 1;
	width: 180px;
	height: 70px;
	font-size: 16px;
}
.container-tabela{
	width: 89vw;
	overflow: auto;
	height: 62vh;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	font-size: 13px;
}
.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0px 5px;
}
.header-tabela, .body-tabela{
	width: auto;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	width: 100%;
}
.body-tabela .linha-body-tabela{
	width: 100%;
	border-bottom: 1px solid #cecece;
}
.container-page-relatorio{
	width: 90vw;
	height: 87vh;
	box-shadow: var(--shadow-secundario);
	border-radius: 10px;
}
.campo-menor{
	width: 95px;
	min-width: 95px;
	text-align: center;
}
.campo-medio{
	width: 150px;
	min-width: 150px;
	text-align: left;
}
.campo-maior{
	width: 250px;
	min-width: 250px;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
}

.row-filtro {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;
}
.column-filtro {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.checked {
    display: flex;
    align-content: center;
    justify-content: center;
}
.checked .selecionar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.check {
	display: block;
	position: relative;
	width: 60px;
	height: 30px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}
.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}
input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}
.table-scroll {
	width: 100%;
	overflow-y: auto;
	height: 52vh;
}

.table-scroll .container-table table {
	width: 100%;
}
.fullTable {
	height: 70vh;
}
.container-aviso{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.container-aviso i {
	font-size: 200px;
}
.container-aviso span {
	font-size: 26px;
	font-weight: bold;
	margin-top: 10px;
}

#impressao{
	position: absolute;
	bottom: 30px;
	left: 140px;
	font-size: 35px;

	padding: 7px;
	border-radius: 50%;
    box-shadow: var(--shadow-secundario);

	background: #FFF;
	z-index: 2;
}


@media screen and (max-height: 660px) {
	.table-scroll {
		height: 55vh;
	}
	.fullTable {
		height: 72vh;
	}
}

@media screen and (max-width: 425px){
	.row-filtro .input-group {
		width: 100%;
	}
	
	.table-scroll {
		width: 100vw;
		height: 25vh !important;
		overflow: auto;
	}
	.fullTable {
		height: 45vh;
	}

	.fullTable .container-aviso {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.fullTable .container-aviso i{
		font-size: 120px;
	}
	.fullTable .container-aviso span {
		font-size: 22px;
		text-align: center;
	}

	.container-aviso {
		display: none;
	}
	#impressao{
		position: absolute;
		bottom: 0px;
		left: 0px;
		font-size: 35px;
	
		padding: 7px;
		border-radius: 50%;
		box-shadow: var(--shadow-secundario);
	
		background: #FFF;
		z-index: 2;
	}
}
</style>