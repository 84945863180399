import {doRequest} from "./http.js";
import { 
	PlanoConta, 
	PlanoContaAPI,
} from "../models/ContaPagar";
export default {
	state: {
		PlanoContas: {
			ListaPlanoContas: [],
			ListaPlanoContasVisualizacao: [],
			paginacao: {
				totalPages: 0,
				page: 0,
				totalElements: 0,
				size: 25,
			},
		},
	},

	actions: {
		async buscarPlanoContas({state, commit}, payload){
			try {
				state.carregando = true;
				const {
					somentePai,
					query
				} = payload;
				let url = "planoconta/list?";
				url += "size=9999999";
				url += "&sort=id";
				url += "&sortOrder=asc";
				url += `${somentePai ? `&mostraSomentePai=${somentePai}` : "&mostraSomentePai=false"}`;
				url += `${query ? `&query=${query}` : ""}`;
				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache",
				}, commit);
				

				if(data && (!data.erro || data.mensagem)){
					commit("atualizarListaPlanoContas", data);
					state.carregando = false;
					return;
				} else {
					commit("atualizarListaPlanoContas", []);
					state.carregando = false;
					return {
						mensagem: data.mensagem
					
					};
				}
			} catch (error) {
				console.log(error);	
				state.carregando = false;

			}
		},

		
		async buscarPlanoContasVisualizar({state, commit}) {
			try {
				state.carregando = true;
				const url = "planoconta/listAll?empresa=001";

				const data = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);
			
				if(data && !data.erro || !data.mensagem) {
					state.carregando = false;
					commit("atualizarListaPlanoContasVisualizacao", data);
					return;			
				} else {
					commit("atualizarListaPlanoContasVisualizacao", []);
					state.carregando = false;
					throw new Error("Problema ao consultar plano de contas");
				}
			} catch (error) {
				console.error(error);
			}
		},
		

		async adicionarPlanoConta({state, commit}, payload){
			try {
				state.carregando = true;
				const url = "planoconta";
				const form = new PlanoContaAPI(payload);
				const body = JSON.stringify(form);
				const data = await doRequest(url, {
					method: "POST",
					body,
					cache: "no-cache"
				}, commit);
				state.carregando = false;
				return data;
			} catch (error) {
				console.log(error);
				
			}
		},

		async editarPlanoConta({state, commit}, payload){
			try {
				state.carregando = true;
				const url = `planoconta?id=${payload.id}`;
				const form = new PlanoContaAPI(payload);
				const body = JSON.stringify(form);
				const data = await doRequest(url, {
					method: "PUT",
					body,
					cache: "no-cache"
				}, commit);
				state.carregando = false;
				return data;
			} catch (error) {
				console.log(error);
			}
		},

		async buscarPlanoContaPorId({state, commit}, payload) {
			try {
				const { id } = payload;

				if(!id) throw new Error("É necesário o código do plano de conta para continuar!");

				let url = "planoconta";

				url += `?id=${id}`;
				state.carregando = true;
				const data = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);
				state.carregando = false;
				

				if(data && !data.erro) {
					return new PlanoConta(data);
				}
				throw new Error(
					data 
						? data.mensagem 
							? data.mensagem 
							: data.details || JSON.stringify(data) 
						: ""
				);
			} catch (error) {
				commit("relatarErro", {
					mensagem: error.message
				});
				return null;
			}
		},
	},

	mutations: {
		atualizarListaPlanoContasVisualizacao(state, payload) {
			state.PlanoContas.ListaPlanoContasVisualizacao = [];
			state.PlanoContas.ListaPlanoContasVisualizacao = payload;
		},

		atualizarListaPlanoContas(state, payload){
			if(payload?.content?.length > 0){
				state.PlanoContas.ListaPlanoContas = payload?.content.map(conta =>  new PlanoConta(conta));
			} else {
				state.PlanoContas.ListaPlanoContas = [];
			}
		},
	}
};