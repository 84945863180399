export class TransferenciaEstoqueProdutoAPI {
	cdseq;
	cdproduto;
	quantidade;
	custo;
	custofixo;
	markup;
	valorprazo;
	descvista;
	ncm;
	valorvista;

	constructor(produto, cdseq) {
		this.cdseq = cdseq;
		this.cdproduto = produto.idProduto || produto.cdProduto;
		this.quantidade = produto.qtdTransferir;
		this.custo = produto.custoFixoProduto || produto.custoProduto;
		this.custofixo = produto.custoFixoProduto || produto.custoProduto || produto.custoFixoProduto;
		this.markup = produto.margemProduto || 0;
		this.valorprazo = produto.valorPrazo;
		this.descvista = produto.descontoProduto || 0;
		this.valorvista = produto.valorVista;
		this.ncm = produto.ncm || "";
	}
}

export class TransferenciaEstoqueAPI {
	data;
	cdusuario;
	nomeUsuario;
	tipo;
	cdempresaorigem;
	cdempresadestino;
	produtos = [];
	valorcusto;
	valorvenda;

	constructor(transferencia) {
		this.data = transferencia.data;
		this.cdusuario = transferencia.cdusuario;
		this.nomeUsuario = transferencia.nomeusuario;
		this.tipo = transferencia.tipo;
		this.cdempresaorigem = transferencia.cdempresaorigem;
		this.cdempresadestino = transferencia.cdempresadestino;
		if(transferencia?.produtos){
			this.produtos = transferencia.produtos.map((prod, index) => new TransferenciaEstoqueProdutoAPI(prod, index +1));
		}
		this.valorcusto = transferencia.valorcusto ? "S" : "N";
		this.valorvenda = transferencia.valorvenda ? "S" : "N";
	}
}

export class TransferenciaEstoqueProduto {
	cdseq;
	cdproduto;
	quantidade;
	custo;
	custofixo;
	markup;
	valorPrazo;
	descvista;
	valorVista;
	custoFixoProduto;
	idProduto;
	produtoTodo;
	ncm;

	constructor(produto, cdseq) {
		this.cdseq = cdseq;
		this.idProduto = produto.cdproduto;
		this.quantidade = produto.quantidade;
		this.custoProduto = produto.custofixo || produto.custo;
		this.custofixo = produto.custofixo || produto.custo;
		this.markup = produto.markup;
		this.valorPrazo = produto.valorprazo;
		this.descvista = produto.descvista;
		this.valorVista = produto.valorvista;
		this.produtoTodo = produto.produto;
		this.ncm = produto.produto.ncm;
	}
}

export class TransferenciaEstoque {
	cdtransferencia;
	data;
	cdUsuario;
	nomeUsuario;
	tipo;
	cdempresaorigem;
	cdempresadestino;
	empresadestino;
	produtos = [];
	valorcusto;
	valorvenda;
	situacao;

	constructor(transferencia) {
		this.cdtransferencia = transferencia.id;
		this.data = transferencia.data;
		this.cdusuario = transferencia.cdusuario;
		this.nomeUsuario = transferencia.nomeusuario;
		this.tipo = transferencia.tipo;
		this.cdempresaorigem = transferencia.cdempresaorigem;
		this.cdempresadestino = transferencia.cdempresadestino;
		this.produtos = transferencia.itens ? transferencia.itens.map((prod, index) => new TransferenciaEstoqueProduto(prod, index)) : [];
		this.qtdTotal = 0;
		this.produtos.forEach(produto => {
			this.qtdTotal += produto.quantidade;
		});
		this.valorcusto = transferencia.valorcusto == "S";
		this.valorvenda = transferencia.valorvenda == "S";
		this.empresadestino = transferencia.empresadestino;
		this.situacao = transferencia.situacao;
	}
}
