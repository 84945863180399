<template>
	<div id="gridPrincipalContasReceber">
		<div id="filtros">
			<i 
				class="fas fa-address-book"
				@click="listarClientes = true"
				style="color: blue; font-size: 20px;"
				v-if="this.$route.name == 'ContasReceber'"
			></i>
			<div class="input-group">
				<input
					type="search"
					autocomplete="off"
					readonly
					@input="listarClientes = true"
					:value="`${Cliente.id} - ${Cliente.nome}`" 
					@focus="listarClientes = true"
					v-if="this.$route.name == 'ContasReceber'"
				>
			</div>
			<div 
				class="paginacao"
				v-if="contasReceber.paginaMaxima > 1"
			>
				<span
					v-if="contasReceber.paginaAtual > 1"
					@click="buscarContas(0)"
				>
					Primeira
				</span>
				<span
					@click="buscarContas(contasReceber.paginaAtual - 1)"
					v-if="contasReceber.paginaAtual > 0"
				>
					{{ contasReceber.paginaAtual }}
				</span>
				<span class="ativa">
					{{ contasReceber.paginaAtual + 1 }}
				</span>
				<span
					@click="buscarContas(contasReceber.paginaAtual + 1)"
					v-if="contasReceber.paginaAtual + 1 < contasReceber.paginaMaxima"
				>
					{{ contasReceber.paginaAtual + 2 }}
				</span>
				<span
					v-if="contasReceber.paginaAtual + 2 < contasReceber.paginaMaxima"
					@click="buscarContas(contasReceber.paginaMaxima - 1)"
				>
					Última
				</span>
			</div>
			<select
				id="ordem"
				class="m-2 p-2"
				@select="selecionarMetodoOrdem"
				v-model="metodoOrdem"
			>
				<option value="dataVenc" v-if="condicao == 'A'">
					Data de vencimento
				</option>
				<option value="dataBaixa" v-else>
					Data da baixa
				</option>
				<option value="cdMovi">
					Código
				</option>
			</select>
			<div class="row">
				<div class="row between m-2 ">
					<input type="radio" id="aberto" class="pointer m-5" name="condicaoContas" value="A"
						v-model="condicao" @click="buscarContas">
					<label for="aberto" class="w-100 pointer">Aberto</label>
				</div>
				<div class="row between m-2 ">
					<input type="radio" id="baixado" class="pointer m-5" name="condicaoContas" value="B"
						v-model="condicao" @click="buscarContas">
					<label for="baixado" class="w-100 pointer">Baixado</label>
				</div>
			</div>
			<i class="fas fa-sort-amount-down" style="color: blue; font-size: 20px;" v-if="ordem == 'desc'"
				@click="ordenar('asc')"></i>
			<i class="fas fa-sort-amount-down-alt" style="color: blue; font-size: 20px;" v-else
				@click="ordenar('desc')"></i>
			<div class="row">
				<label for="sizeExibicao">
					Exibição: 
				</label>
				&nbsp;
				<select v-model="size" id="sizeExibicao" @change="buscarContas">
					<option :value="25">
						25 itens
					</option>
					<option :value="50">
						50 itens
					</option>
					<option :value="100">
						100 itens
					</option>
					<option :value="150">
						150 itens
					</option>
					<option :value="Paginacao.totalElements">
						Todos itens
					</option>
				</select>
			</div>
			<div class="paginacao">
				<span @click="buscarContas(0)" v-if="Paginacao.pageAtual > 1">1</span>
				<span @click="buscarContas(Paginacao.page - 1)" v-if="Paginacao.page > 0">
					{{Paginacao.page}}
				</span>
				<span class="ativa">
					{{Paginacao.page + 1}}
				</span>
				<span @click="buscarContas(Paginacao.page + 1)"  v-if="Paginacao.page + 1 < Paginacao.totalPages">
					{{Paginacao.page + 2}}
				</span>
				<span @click="buscarContas(Paginacao.totalPages - 1) " v-if="Paginacao.page + 2 < Paginacao.totalPages">
					{{Paginacao.totalPages}}
				</span>
			</div>
		</div>

		<div id="conteudo" class="col between" v-if="contasReceber.contas && contasReceber.contas.length > 0">
			<div class="scrollable m-0-auto w-100">
				<table class="m-0 w-100">
					<thead>
						<tr>
							<td style="text-align: center; cursor: pointer">
								<input type="checkbox" class="checkbox-tabela pointer" id="selecionarTudo"
									@change="selecionarTudo">
								Selec.
							</td>
							<td style="text-align: center">Emp.</td>
							<td style="text-align: center">Parc.</td>
							<td style="text-align: center">Nº Doc.</td>
							<td style="text-align: right">Valor pedido</td>
							<td style="text-align: center">Data emissão</td>
							<td style="text-align: center" v-if="condicao == 'A'">Data vencimento</td>
							<td style="text-align: center" v-else>Data baixa</td>
							<td style="text-align: right" v-if="condicao == 'A'">Montante</td>
							<td style="text-align: right">Valor recebido</td>
							<td style="text-align: center" v-if="condicao == 'A'">Último pgto.</td>
							<td style="text-align: center" v-if="condicao == 'A'">Dias de atraso</td>
							<td style="text-align: right" v-if="condicao == 'A'">Juros</td>
							<td style="text-align: right" v-if="condicao == 'A'">Valor restante</td>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(conta, index) in contasReceber.contas"
							:key="index"
							@contextmenu.prevent="
								condicao == 'A' ? buscaVenda(conta.cdMovi, conta.cdEmpresa) : buscaBaixa(conta)
							"
							:class="verificaContaVencida(conta)"
						>
							<td style="text-align: center">
								<input 
									type="checkbox"
									:id="index"
									@change="selecionarContaCheckbox"
									v-model="conta.selecionada"
									:value="true"
									class="js-checkbox-financeiro pointer"
								>
							</td>
							<td style="text-align: center" @click="selecionarConta(conta)">
								{{ conta.cdEmpresa }}
							</td>
							<td style="text-align: center" @click="selecionarConta(conta)">
								{{ conta.cdLan }}
							</td>
							<td style="text-align: center" @click="selecionarConta(conta)">
								{{ condicao == 'A' ? conta.cdDoc ? conta.cdDoc : conta.cdMovi : conta.cdDig }}
							</td>
							<td style="text-align: right" @click="selecionarConta(conta)">
								{{ 
									(conta.valorTitulo || 0) | formatarParaReais
								}}
							</td>
							<td style="text-align: center" @click="selecionarConta(conta)">
								{{
										new Date(new Date(conta.dataDoc).getFullYear(),
										new Date(conta.dataDoc).getMonth(), new Date(conta.dataDoc).getDate() + 1).toLocaleDateString("pt-BR")
								}}
							</td>
							<td style="text-align: center" @click="selecionarConta(conta)" v-if="condicao == 'A'">
								{{
									dateFormatBR(conta.dataVenc)
								}}
							</td>
							<td style="text-align: center" @click="selecionarConta(conta)" v-else>
								{{
									montarDataBaixaCorreta(conta)
								}}
							</td>
							<td style="text-align: right" @click="selecionarConta(conta)" v-if="condicao == 'A'">
								{{ 
									(conta.contarc || 0) | formatarParaReais 
								}}
							</td>
							<td style="text-align: right" @click="selecionarConta(conta)">
								{{
									(conta.valor || 0) | formatarParaReais
								}}
							</td>
							<td
								style="text-align: center"
								@click="selecionarConta(conta)"
								v-if="condicao == 'A'"
							>
								{{
									conta.dataUltimoPagamento ? formatarParaDataBR(conta.dataUltimoPagamento) : ''
								}}
							</td>
							<td 
								style="text-align: center"
								@click="selecionarConta(conta)" v-if="condicao == 'A'"
							>
								{{ conta.diasEmAtraso && conta.diasEmAtraso > 0 ? conta.diasEmAtraso : 0 }}
							</td>
							<td style="text-align: right" @click="selecionarConta(conta)" v-if="condicao == 'A'">
								{{ 
									(conta.jurosCalculados + (conta.jurosAcumulado || 0)) | formatarParaReais
								}}
							</td>
							<td 
								style="text-align: right"
								@click="selecionarConta(conta)"
								v-if="condicao == 'A'"
							>
								{{
									Number(Math.trunc(conta.restante * 100) / 100) | formatarParaReais
								}}
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="col w-100 between">
				<div class="row w-100 p-2 m-2 mb-5">
					<div class="input-group">
						<input
							type="text"
							id="selecionado"
							readonly
							class="inputValores"
							:value="(valorSelecionado || 0) | formatarParaReais">
						<label for="selecionado">
							Selecionado
						</label>
					</div>
					<div class="input-group">
						<input 
							type="text"
							id="total"
							readonly
							class="inputValores"
							:value="(valorTotal || 0) | formatarParaReais">
						<label for="total">
							Subtotal
						</label>
					</div>
					<div class="input-group">
						<input 
							type="text"
							id="totalJuros"
							readonly
							class="inputValores"
							:value="(totalJuros || 0) | formatarParaReais">
						<label for="totalJuros">
							Total juros
						</label>
					</div>
					<div class="input-group">
						<input
							type="text"
							id="subtotal"
							readonly
							class="inputValores"
							:value="((valorTotal + totalJuros ) || 0) | formatarParaReais">
						<label for="subtotal">
							Total
						</label>
					</div>
				</div>
				<div 
					class="row w-100 p-2 m-2 mt-5"
					v-if="Object.keys(totalPorEmpresa).length > 1 && condicao == 'A'"
				>
					<div 
						class="input-group"
						v-for="cdempresa in Object.keys(totalPorEmpresa)"
						:key="cdempresa"
					>
						<input 
							type="text"
							:id="cdempresa"
							readonly
							class="total-empresa"
							placeholder=" "
							:value="(totalPorEmpresa[cdempresa] || 0) | formatarParaReais"
							@focus="selecionarContasPorCdempresa(cdempresa)"
						/>
						<label :for="cdempresa">
							{{ cdempresa }} - {{ Empresas.find(emp => emp.id === cdempresa).fantasia }}
						</label>
					</div>
				</div>
				<div class="row around w-100 p-2 m-2">
					<button class="botao botao-adicionar mx-5 w-100" @click="baixarSelecionados"
						v-if="condicao == 'A' && ($store.state.Usuario.supervisor || $store.state.Usuario.caixa)">Baixar
						selecionados</button>
					<button id="confirmar" class="botao botao-adicionar mx-5 w-100" @click="reimprimirConta"
						v-else-if="($store.state.Usuario.supervisor || $store.state.Usuario.caixa)">
						Imprimir
					</button>
					<button class="botao botao-acao mx-5 w-100" @click="gerarExtrato" v-if="condicao == 'A'">
						{{valorSelecionado > 0 ? "Imprimir" : "Extrato"}}
					</button>
					<button class="botao botao-acao mx-5 w-100" @click="verificarPossibilidadeEstorno"
						v-else-if="($store.state.Usuario.supervisor || $store.state.Usuario.caixa)">
						Estornar
					</button>
					<button class="botao botao-cancelar mx-5 w-100" @click="cancelar">Cancelar</button>
				</div>
			</div>
		</div>

		<div id="conteudo" class="col center w-80 h-80" style="align-self: center; margin:auto" v-else>
			<h1>Nenhuma {{ condicao == "A" ? "conta" : "baixa" }} encontrada</h1>
			<h4>Tente buscar novamente</h4>
		</div>

		<Modal v-if="baixarTitulo" v-on:fecharModal="fecharModal" :dimensoes="{ width: '90%', height: '90%' }">
			<div class="col center w-100" v-if="!lancarCheques">
				<h2>Você selecionou {{ valorSelecionado.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) }}
				</h2>
				<div class="row between w-90 m-5">
					<div class="col between w-40 input-group">
						<input type="date" id="dataBaixa" class="w-100 p-2" v-model="dataBaixa"
							:readonly="!$store.state.Usuario.usuarioPrivilegiado" style="margin-top: 2px;">
						<label for="dataBaixa">Data da baixa</label>
					</div>
					<div class="col between w-40 input-group" v-if="formaRecebimento.deposito === 'S'">
						<select id="js-contaCorrente" v-model="contaSelecionada"
						>
							<option :value="{}" selected>Selecione uma opção</option>
							<option v-for="(conta, index) in ContasAceitaPix" :value="conta" :key="`conta-${index}`">
								{{ conta.nome }}
							</option>
						</select>
						<label for="js-ContaCorrente">Conta</label>
					</div>
					<div class="col between w-40 input-group">
						<select id="js-formaRecebimento" v-model="formaRecebimento" class="w-100 p-2"
							@keyup.enter.prevent="selecionarFormaPagamento"
							@keydown.enter.prevent="selecionarFormaPagamento"
							@keydown.tab.prevent="selecionarFormaPagamento">
							<option :value="{}" selected>Selecione</option>
							<option :value="metodo" v-for="metodo in formasPagamento" :key="metodo.cdtipo">
								{{ metodo.descricao }}</option>
						</select>
						<label for="js-formaRecebimento">Forma de recebimento</label>
					</div>
				</div>

				<div class="row between w-90 m-5">
					<div class="col between w-30 input-group">
						<input type="text" id="js-valor-parcelas" style="text-align: right;" class="w-100 p-2"
							placeholder="R$ 0,00"
							:value="valorParcelas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
							readonly @keydown.enter="proximoInput('jurosBaixa')">
						<label for="js-valor-parcelas">Valor das parcelas</label>
					</div>

					<div class="col between w-30 input-group">
						<input type="text" id="jurosBaixa" style="text-align: right;" class="w-100 p-2"
							placeholder="R$ 0,00" v-model="jurosBaixa" @input="mascaraFloat"
							@focus="e => e.target.select()"
							:readonly="!$store.state.Usuario.descontoRecebimento && !$store.state.Usuario.usuarioPrivilegiado"
							@keydown.enter="proximoInput('valorFinalReceber')">
						<label for="jurosBaixa">Valor dos juros</label>
					</div>

					<div class="col between w-30 input-group">
						<input type="text" id="valorFinalReceber" style="text-align: right;" class="w-100 p-2"
							placeholder="R$ 0,00"
							:value="valorReceber.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })" readonly
							@keydown.enter="proximoInput('valorRecebido')">
						<label for="valorFinalReceber">Valor a receber</label>
					</div>
				</div>

				<div class="row between w-90 m-5">
					<div class="col between w-40 input-group">
						<input type="text" id="valorRecebido" style="text-align: right;" class="w-100 p-2"
							placeholder="R$ 0,00" v-model="valorRecebido"
							@keydown.enter="proximoInput(saldo > 0 ? 'saldo' : 'valorEntreguePeloCliente')"
							@input="mascaraFloat" @focus="e => e.target.select()">
						<label for="valorRecebido">Valor da baixa</label>
					</div>
					<div class="col between w-40 input-group">
						<input type="text" id="saldo" class="w-100 p-2"
							@keydown.enter="proximoInput('valorEntreguePeloCliente')" autocomplete="off"
							:value="saldo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })" readonly>
						<label for="saldo">Saldo</label>
					</div>
				</div>

				<div class="row between w-90 m-5">
					<div class="col between w-40 input-group">
						<input type="text" id="valorEntreguePeloCliente" style="text-align: right;" class="w-100 p-2"
							placeholder="R$ 0,00" v-model="valorEntreguePeloCliente"
							@keydown.enter="proximoInput(troco > 0 ? 'troco' : 'observacao')" @input="mascaraFloat"
							@focus="e => e.target.select()">
						<label for="valorEntreguePeloCliente">Valor recebido</label>
					</div>
					<div class="col between w-40 input-group">
						<input type="text" id="troco" class="w-100 p-2" @keydown.enter="proximoInput('observacao')"
							autocomplete="off" :value="`R$ ${troco}`" readonly>
						<label for="troco">Troco</label>
					</div>
				</div>

				<div class="row between w-90 m-5">
					<div class="col between w-100 input-group">
						<input type="text" id="observacao" class="w-100 p-2" v-model="observacao"
							@keydown.enter.prevent="" autocomplete="off" maxlength="150">
						<label for="observacao">Observações</label>
					</div>
				</div>


				<div class="col between w-90 m-5" v-if="formaRecebimento.cheque != 'S'">
					<button class="botao botao-cancelar my-5 w-100" @click="fecharModal">Cancelar</button>
					<button id="confirmar" class="botao botao-adicionar my-5 w-100" @click="receberConta('parcial')">
						Baixar
					</button>
					<button class="botao botao-acao my-5 w-100" @click="receberConta('total')"
						v-if="($store.state.Usuario.descontoRecebimento || $store.state.Usuario.usuarioPrivilegiado) && saldo != 0">
						{{ saldo > 0 ? "Lançar saldo como desconto" : saldo == 0 ? "Baixar integral" : "Lançar saldo como aumento"}}
					</button>
				</div>
				<div class="col between w-90 m-5" v-else>
					<button class="botao botao-cancelar my-5 w-100" @click="fecharModal">Cancelar</button>
					<button class="botao botao-adicionar my-5 w-100" @click="iniciarCheque(false)">Baixar</button>
					<button class="botao botao-acao my-5 w-100" @click="iniciarCheque(true)"
						v-if="($store.state.Usuario.descontoRecebimento || $store.state.Usuario.usuarioPrivilegiado) && saldo != 0">
						{{ saldo > 0 ? "Lançar saldo como desconto" : saldo == 0 ? "Baixar integral" : "Lançar saldo como aumento"}}
					</button>
				</div>
			</div>
			<LancamentoCheque :valorReceber="valorParaCheque" :metodoRecebimento="formaRecebimento"
				v-on:confirmarParcelas="confirmouCheque" v-else />
		</Modal>

		<Modal v-if="listarClientes" v-on:fecharModal="fecharModal">
			<ConsultaCliente
				@selecionarPessoa="selecionarCliente"
				:mostrarBotaoNovo="false"
				:height="'65vh'"
			/>
		</Modal>

		<Modal v-if="baixaSelecionada" v-on:fecharModal="fecharModal" :dimensoes="{ width: 'auto', height: 'auto' }">
			<h2>Resumo do pagamento</h2>
			<table class="m-0 w-100" v-if="baixaSelecionada.conta">
				<thead>
					<tr>
						<td style="text-align: right">$ Total</td>
						<td style="text-align: right" v-if="baixaSelecionada.conta.aumdesc != 0">
							{{
								(baixaSelecionada.conta.aumdesc || 0) > 0 ?
								"$ Desconto" :
								"$ Aumento"
							}}
						</td>
						<td style="text-align: right">$ Pago</td>
						<td style="text-align: center">Data pagamento</td>
						<td style="text-align: center">Forma pagamento</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td style="text-align: right">
							{{
								(baixaSelecionada.conta.vlrtitulo || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
							}}
						</td>
						<td style="text-align: right" v-if="baixaSelecionada.conta.aumdesc != 0">
							{{
								(
									(baixaSelecionada.conta.aumdesc || 0) > 0 ?
									(baixaSelecionada.conta.aumdesc || 0) :
									((baixaSelecionada.conta.aumdesc || 0) * -1)
								).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
							}}
						</td>
						<td style="text-align: right">
							{{
								(baixaSelecionada.conta.valor || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
							}}
						</td>
						<td style="text-align: center">
							{{
								new Date(baixaSelecionada.conta.databaixa).toLocaleString("pt-BR")
							}}
						</td>
						<td style="text-align: center">
							{{ baixaSelecionada.conta.pagamento || baixaSelecionada.conta.cdtipopgto }}
						</td>
					</tr>
				</tbody>
			</table>

			<h2>Contas pagas</h2>
			<div class="scrollable m-0-auto w-100" v-if="baixaSelecionada.baixas && baixaSelecionada.baixas.length > 0">
				<table class="m-0 w-100">
					<thead>
						<tr>
							<td style="text-align: center">Parc.</td>
							<td style="text-align: center">Nº Doc.</td>
							<td style="text-align: right">Valor pedido</td>
							<td style="text-align: center">Data emissão</td>
							<td style="text-align: center">Data vencimento</td>
							<td style="text-align: right">Montante</td>
							<td style="text-align: center">Dias de atraso</td>
							<td style="text-align: right">Juros</td>
							<td style="text-align: right">Total pago</td>
							<td style="text-align: right">Valor restante</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(conta, index) in baixaSelecionada.baixas" :key="index"
							@contextmenu.prevent="buscaVenda(conta.cdmovi, conta.cdEmpresa)" @click="reimprimirConta()">
							<td style="text-align: center">
								<label>{{ conta.cdlan }}</label>
							</td>
							<td style="text-align: center">
								<label>{{ conta.cdmovi }}</label>
							</td>
							<td style="text-align: right">
								<label>{{ conta.vlrtitulo.toLocaleString("pt-BR", {
										style: "currency", currency:
											"BRL"
									})
								}}</label>
							</td>
							<td style="text-align: center">
								<label>
									{{
											new Date(new Date(conta.datadoc).getFullYear(),
												new Date(conta.datadoc).getMonth(), new Date(conta.datadoc).getDate() + 1)
												.toLocaleDateString("pt-BR")
									}}
								</label>
							</td>
							<td style="text-align: center">
								<label>
									{{
											new Date(new Date(conta.datavenc).getFullYear(),
												new Date(conta.datavenc).getMonth(), new Date(conta.datavenc).getDate() + 1)
												.toLocaleDateString("pt-BR")
									}}
								</label>
							</td>
							<td style="text-align: right">
								<label>{{ conta.contarc.toLocaleString("pt-BR", {
										style: "currency", currency:
											"BRL"
									})
								}}</label>
							</td>
							<td style="text-align: center">
								<label>
									{{ conta.diasvencidos && conta.diasvencidos > 0 ? conta.diasvencidos : 0 }}
								</label>
							</td>
							<td style="text-align: right">
								<label>{{ (conta.jurosPagoBaixa || 0).toLocaleString("pt-BR", {
										style: "currency", currency:
											"BRL"
									})
								}}</label>
							</td>
							<td style="text-align: right">
								<label>
									{{
										(
											(conta.valorPagoBaixa || 0)
										).toLocaleString("pt-BR", {
											style: "currency", currency: "BRL"
										})
									}}
								</label>
							</td>
							<td style="text-align: right">
								<label>{{ (conta.restante).toLocaleString("pt-BR", {
										style: "currency", currency:
											"BRL"
									})
								}}</label>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Modal>
		<Modal v-if="vendaSelecionada" v-on:fecharModal="fecharModal"
			:dimensoes="{ width: '65%', height: 'auto' }">
			<PreImpressao :vendaFinalizada="vendaSelecionada" v-on:fecharModal="fecharModal"
			:empresa="empresaVenda" />
		</Modal>

		<Modal v-if="abrirCaixa" v-on:fecharModal="abrirCaixa = false" :dimensoes="{ width: '65%', height: 'auto' }">
			<AberturaCaixa v-on:caixaAberto="abrirCaixa = false" :caixa="$store.state.Caixa.caixa"
				v-on:cancelar="abrirCaixa = false" />
		</Modal>
		<Modal v-if="abrirModalPix" v-on:fecharModal="abrirModalPix = false, $store.dispatch('cancelarCobrancaPix', form)" :dimensoes:="{width: '50% !important', height: 'auto'}">
			<PixCobranca  :dados="form" @fecharModal="abrirModalPix = false" :contaBaixada="contaBaixa" @baixarConta="baixarContas" :valorPix="Number(valorRecebido.replace(',', '.'))" />
		</Modal>
		<Modal v-if="parcelarPagamento" @fecharModal="parcelarPagamento = false">
			<ParcelarPagamento :metodoRecebimento="formaRecebimento" :valorReceber="valorRecebido" :contaBaixar="contaReceb" @confirmarParcelamento="baixarContas"> </ParcelarPagamento>
		</Modal>
	</div>
</template>

<script>

import ConsultaCliente from "../components/ConsultaCliente.vue";
import Modal from "../components/Modal.vue";
import PreImpressao from "../components/PreImpressao.vue";
import AberturaCaixa from "../components/AberturaCaixa.vue";
import LancamentoCheque from "../components/LancamentoCheque.vue";
import PixCobranca from "../components/PixCobranca.vue";
import ParcelarPagamento from "../components/ParcelarPagamento.vue";
import { Empresa } from "../models/Empresa";
import { dateFormatBR } from "../utils/dateformat";
import formatarParaReais from "../filters/formatarParaMoeda";

export default {
	name: "ContasReceber",

	components: {
		ConsultaCliente,
		Modal,
		PreImpressao,
		AberturaCaixa,
		LancamentoCheque,
		PixCobranca,
		ParcelarPagamento
	},

	data() {
		return {
			abrirModalPix: false,
			formaRecebimento: {},
			contaSelecionada: null,
			baixarTitulo: false,
			dataBaixa: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
			valorRecebido: "",
			valorEntreguePeloCliente: "",
			timerFiltro: null,
			listarClientes: false,
			Cliente: null,
			parcelas: [],
			observacao: "",
			metodoOrdem: "dataVenc",
			ordem: "asc",
			empresaVenda: null,
			vendaSelecionada: null,
			aumdesc: "",
			condicao: "A",
			baixaSelecionada: null,
			abrirCaixa: false,
			jurosBaixa: "",
			saldoComoDesconto: false,
			valorParaCheque: 0,
			lancarCheques: false,
			cheques: null,
			parcelarPagamento: false,
			form: {},
			contaBaixa: {},
			contaReceb: {},
			size: 150,
			dateFormatBR
		};
	},

	props: {
		clienteProps: Object
	},

	computed: {
		Usuario() {
			return this.$store.state.Usuario;
		},

		Empresas() {
			return this.$store.state.Empresas;
		},
		
		Empresa() {
			return this.$store.state.Empresa;
		},

		Contas() {
			return this.$store.state.Contas;
		},

		ContasAceitaPix() {
			return this.Contas.filter(cc => cc.aceitapix);
		},

		contasReceber() {
			return this.$store.state.Financeiro.contasReceber;
		},
		totalPorEmpresa() {
			let precos = {};
			this.contasReceber.contas.forEach(conta => {
				if (!Object.keys(precos).includes(conta.cdEmpresa)) {
					precos[conta.cdEmpresa] = Number(Number(conta.restante).toFixed(2));
				} else {
					precos[conta.cdEmpresa] += Number(Number(conta.restante).toFixed(2));
				}
			});
			return precos;
		},
		formasPagamento() {
			return this.$store.state.formasPagamento.filter(metodo => metodo.mostrarecebimento == "S");
		},
		valorTotal() {
			let preco = 0;
			this.contasReceber.contas.forEach(conta => {
				preco += Number(this.condicao != "B" ? Number(Number(conta.contarc) - Number(conta.valor)).toFixed(2) : Number(conta.valor).toFixed(2) || Number(conta.restante).toFixed(2));
			});
			return preco;
		},
		totalJuros() {
			let preco = 0;
			this.contasReceber.contas.forEach(conta => {
				preco += Number(Number(conta.jurosCalculados).toFixed(2) || 0) + Number(Number(conta.jurosAcumulado).toFixed(2) || 0);
			});
			return preco;
		},
		valorParcelas() {
			let preco = 0;
			this.parcelas.forEach(conta => {
				preco += Number(((conta.contarc || 0) - (conta.valor || 0)).toFixed(2));
			});
			return preco;
		},
		valorJuros() {
			let preco = 0;
			this.parcelas.forEach(conta => {
				preco += Number(((conta.jurosCalculados || 0) + (conta.jurosAcumulado || 0)).toFixed(2));
			});
			return preco;
		},
		valorReceber() {
			return Number(this.valorParcelas.toFixed(2)) + Number(`${this.jurosBaixa || 0}`.replace(".", "").replace(",", "."));
		},
		valorSelecionado() {
			let preco = 0;
			this.parcelas.forEach(conta => {
				if (this.condicao == "A") {
					preco += Number((conta.restante || 0));
				} else {
					preco += conta.valor;
				}
			});
			return Number(Math.trunc(preco * 100) / 100);
		},
		saldo() {
			return Number((
				Number(this.valorParcelas.toFixed(2))
				+ Number(this.valorJuros.toFixed(2))
				- Number(`${this.valorRecebido}`.replace(".", "").replace(",", ".") || 0)
			).toFixed(2));
		},

		troco() {
			return Number(Number(`${this.valorEntreguePeloCliente}`.replace(".", "").replace(",", ".") || 0) - Number(`${this.valorRecebido}`.replace(".", "").replace(",", ".") || 0)).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
		},

		Paginacao () {
			return this.$store.state.Financeiro.paginacao;
		}
	},

	filters: {
		formatarParaReais
	},

	methods: {
		formatarParaDataBR(data) {
			const {ano, mes, dia} = this.montarData(data);
			return new Date(ano, mes, dia + 1).toLocaleDateString("pt-BR");
		},

		montarData(data) {
			const ano = new Date(data).getFullYear();
			const mes = new Date(data).getMonth();
			const dia = new Date(data).getDate();
			return {
				ano,
				mes,
				dia
			};
		},

		montarDataBaixaCorreta(conta) {
			const { ano, mes, dia } = this.montarData(conta.dataBaixa);
			if (conta?.dataBaixa?.toLocaleString("pt-BR").split(" ")[1] === "21:00:00") {
				return new Date(ano, mes, dia + 1).toLocaleDateString("pt-BR");
			} else {
				return new Date(ano, mes, dia).toLocaleDateString("pt-BR");
			}
		},

		verificaContaVencida(conta) {
			const { ano, mes, dia } = this.montarData(conta.dataVenc);
			const dataVencimento = new Date(ano, mes, dia +1);
			dataVencimento.setHours(0, 0, 0, 0);

			const dataAtual = new Date();
			dataAtual.setHours(0, 0, 0, 0);

			if(
				(conta.restante > 0.05 && conta.condicao !== "R" ) 
				&& dataVencimento < dataAtual
			) {
				return "conta-vencida";
			} else {
				return "";
			}
		},

		iniciarCheque (comDesconto) {
			this.lancarCheques = true;
			this.saldoComoDesconto = comDesconto || false;
			this.valorParaCheque = Number(`${this.valorRecebido}`.replace(".", "").replace(",", ".")).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
		},

		selecionarContasPorCdempresa(cdempresa) {
			this.parcelas = [];
			this.contasReceber.contas.forEach(conta => {
				if (conta.cdEmpresa == cdempresa) {
					conta.selecionada = true;
					this.parcelas.push(conta);
				} else {
					conta.selecionada = false;
				}
			});
			this.$forceUpdate();
		},

		confirmouCheque(parcela) {
			this.cheques = parcela.cheques;
			if (this.saldoComoDesconto) {
				this.receberConta("total");
			} else {
				this.receberConta("parcial");
			}
		},

		mascaraFloat(e) {
			const regex = /[0-9,]/;
			if (!regex.test(e.data) && e.data != null) {
				this[e.target.id] = this[e.target.id].substring(0, this[e.target.id].length - 1);
			}
			if (e.target.id == "jurosBaixa") {
				this.atualizouJurosBaixa();
			} else if (e.target.id == "valorRecebido") {
				this.atualizouValorRecebido();
			}
		},

		atualizouValorRecebido() {
			this.valorEntreguePeloCliente = this.valorRecebido;
			this.valorParaCheque = Number(`${this.valorRecebido}`.replace(".", "").replace(",", ".")).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
		},

		atualizouJurosBaixa() {
			this.valorRecebido = this.valorReceber.toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
			this.valorEntreguePeloCliente = this.valorRecebido;
			this.valorParaCheque = Number(`${this.valorRecebido}`.replace(".", "").replace(",", ".")).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
		},

		cancelar() {
			if (this.$route.name == "ContasReceber") {
				this.$router.push({name: "Principal"});
			} else {
				this.$emit("fecharContasReceber");
			}
		},

		baixarSelecionados() {
			if (!this.verificarCaixaAberto()) return;
			if (this.valorSelecionado > 0) {
				this.contasReceber.contas.forEach(conta => {
					conta.dataBaixa = this.dataBaixa;
				});
				this.valorRecebido = this.valorSelecionado.toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				this.valorEntreguePeloCliente = this.valorSelecionado.toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				this.jurosBaixa = this.valorJuros.toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
				this.valorParaCheque = this.valorSelecionado;
				this.baixarTitulo = true;
				setTimeout(() => {
					document.getElementById("js-formaRecebimento").focus();
				}, 10);
			} else {
				this.$store.commit("relatarErro", { mensagem: "Favor selecionar os títulos para baixar." });
			}
		},

		selecionarFormaPagamento(e) {
			if (e.target.value == "") return;
			setTimeout(() => {
				document.getElementById("valorRecebido").focus();
				document.getElementById("valorRecebido").select();
			}, 10);
		},

		buscarContas(page) {
			clearTimeout(this.timerFiltro);
			this.timerFiltro = setTimeout(() => {
				this.parcelas = [];
				this.metodoOrdem = this.condicao == "A" ? "dataVenc" : "dataBaixa";
				document.querySelectorAll(".js-checkbox-financeiro").forEach(el => el.checked = false);
				this.$store.dispatch("buscarContasCdCliente", {
					cdCliente: this.Cliente.id,
					condicao: this.condicao,
					sort: "datavenc",
					sortOrder: "asc",
					size: this.size || 25,
					page: typeof (page) == "number" ? page : 0
				});
			}, 250);
		},

		selecionarTudo(e) {
			setTimeout(() => {
				this.parcelas = [];
				this.contasReceber.contas.forEach(conta => {
					conta.selecionada = e.target.checked;
					if (e.target.checked) {
						this.parcelas.push(conta);
					} else {
						this.parcelas = [];
					}
				});
				this.$forceUpdate();
			}, 50);
		},

		selecionarContaCheckbox(e) {
			e.preventDefault();
			if (e.target.checked) {
				if (this.condicao == "B") {
					this.parcelas = [];
					this.contasReceber.contas.forEach(conta => conta.selecionada = false);
					this.contasReceber.contas[e.target.id].selecionada = true;
				}
				this.parcelas.push(this.contasReceber.contas[e.target.id]);
			} else {
				this.parcelas = this.parcelas.filter(baixa => baixa.selecionada);
			}
			this.$forceUpdate();
		},

		selecionarConta(conta) {
			if (this.condicao == "B") {
				this.parcelas = [];
				this.contasReceber.contas.forEach(conta => conta.selecionada = false);
			}
			conta.selecionada = !conta.selecionada;
			if (conta.selecionada) {
				this.parcelas.push(conta);
			} else {
				this.parcelas = this.parcelas.filter(baixa => baixa.selecionada);
			}
			this.$forceUpdate();
		},

		selecionarMetodoOrdem(e) {
			this.metodoOrdem = e.target.value;
			this.$store.commit("ordenarContasReceber", { metodoOrdem: this.metodoOrdem, ordem: this.ordem });
			this.removerCheckboxSelecionados();
		},
		removerCheckboxSelecionados() {
			const listaCheckbox = document?.getElementsByClassName("js-checkbox-financeiro");
			const arrCheckbox = Array.from(listaCheckbox);
			if(arrCheckbox && arrCheckbox.length > 0) {
				arrCheckbox.forEach(element => element.checked = false);
			}
		},



		fecharModal() {
			this.baixarTitulo = false;
			this.listarClientes = false;
			this.vendaSelecionada = null;
			this.empresaVenda = null;
			this.baixaSelecionada = null;
			this.valorRecebido = "";
			this.formaRecebimento = {};
			this.valorEntreguePeloCliente = "";
			this.lancarCheques = false;
			this.saldoComoDesconto = false;
		},

		selecionarCliente(cliente) {
			this.Cliente = cliente;
			this.$route.params.cliente = cliente;
			this.contasReceber.contas = [];
			this.parcelas = [];
			this.buscarContas();
			this.listarClientes = false;
		},
		configurarConciliadora (item) {
			this.contaReceb = {...item};
			this.parcelarPagamento = true;
		},

		receberConta(tipo, ignorarPix) {
			if(!this.contaSelecionada) {
				this.contaSelecionada = this.$store.state.Caixa?.caixa;
			}
			if (tipo == "total" && !this.Usuario.descontoRecebimento && this.aumdesc > 0) {
				return this.$store.commit("relatarErro", {
					mensagem: `Para dar desconto no recebimento é necessário solicitar autorização.
					Tem certeza que deseja lançar como desconto?`, tipo: "polar", item: {
						conta: true,
						aumdesc: this.saldo || 0,
						parcelas: this.parcelas,
						cliente: this.Cliente,
						dataBaixa: this.dataBaixa,
						observa: this.observacao,
						tipoPgto: this.formaRecebimento,
						contaCorrente: {
							cdagencia: this.contaSelecionada?.agencia,
							cdbanco: this.contaSelecionada?.banco,
							cdconta: this.contaSelecionada?.cc,
						},
						valorBaixa: Number(`${this.valorRecebido}`.replace(".", "").replace(",", ".") || 0) || 0,
						valorEntreguePeloCliente: Number(`${this.valorEntreguePeloCliente}`.replace(".", "").replace(",", ".") || 0) || 0,
						route: "ContasReceber",
						cheques: this.cheques
					}
				});
			}
			if(this.formaRecebimento.cartao == "S"){
				return this.configurarConciliadora({
					conta: true,
					aumdesc: tipo == "total" ? this.saldo : 0,
					parcelas: this.parcelas,
					cliente: this.Cliente,
					dataBaixa: this.dataBaixa,
					observa: this.observacao,
					contaCorrente: {
						cdagencia: this.contaSelecionada.agencia,
						cdbanco: this.contaSelecionada.banco,
						cdconta: this.contaSelecionada.cc,
					},
					tipoPgto: this.formaRecebimento,
					valorBaixa: Number(`${this.valorRecebido}`.replace(".", "").replace(",", ".") || 0) || 0,
					valorEntreguePeloCliente: Number(`${this.valorEntreguePeloCliente}`.replace(".", "").replace(",", ".") || 0) || 0,
					route: "ContasReceber",
					cheques: this.cheques
				});
			}
			if (!this.formaRecebimento.cdtipo) {
				return this.$store.commit("relatarErro", { mensagem: "Favor selecionar a forma de recebimento." });
			}
			if (this.valorRecebido <= 0) {
				return this.$store.commit("relatarErro", { mensagem: "O valor da baixa deve ser menor ou igual que o valor recebido." });
			}
			if ((!this.valorRecebido || this.valorRecebido <= 0)) {
				return this.$store.commit("relatarErro", { mensagem: "Não é possível baixar contas com o valor informado, favor verificar." });
			}
			if (this.formaRecebimento.descricao == "PIX" && !ignorarPix){
				return this.verificarPix(tipo);
			}

			
			this.baixarContas({
				aumdesc: tipo == "total" ? this.saldo : 0,
				parcelas: this.parcelas,
				cliente: this.Cliente,
				dataBaixa: this.dataBaixa,
				observa: this.observacao,
				contaCorrente: {
					cdagencia: this.contaSelecionada?.agencia,
					cdbanco: this.contaSelecionada?.banco,
					cdconta: this.contaSelecionada?.cc,
				},
				tipoPgto: this.formaRecebimento,
				valorBaixa: Number(`${this.valorRecebido}`.replace(".", "").replace(",", ".") || 0) || 0,
				valorEntreguePeloCliente: Number(`${this.valorEntreguePeloCliente}`.replace(".", "").replace(",", ".") || 0) || 0,
				cheques: this.cheques
			});
		},
		async verificarPix(tipo){
			this.contaBaixa = {
				aumdesc: tipo == "total" ? this.saldo : 0,
				parcelas: this.parcelas,
				cliente: this.Cliente,
				dataBaixa: this.dataBaixa,
				observa: this.observacao,
				tipoPgto: this.formaRecebimento,
				contaCorrente: {
					cdagencia: this.contaSelecionada?.agencia,
					cdbanco: this.contaSelecionada?.banco,
					cdconta: this.contaSelecionada?.cc,
				},
				valorBaixa: Number(`${this.valorRecebido}`.replace(".", "").replace(",", ".") || 0) || 0,
				valorEntreguePeloCliente: Number(`${this.valorEntreguePeloCliente}`.replace(".", "").replace(",", ".") || 0) || 0,
				cheques: this.cheques
			};
			if(this.$store.state.ConfiguracaoPixEmpresa.length === 0){
				this.$store.commit("relatarErro", {mensagem: "Utilizar o pix manualmente"});
				return this.receberConta(tipo, true);

			}
			if (this.$store.state.ConfiguracaoPixEmpresa[0]){
				this.form = {...this.$store.state.ConfiguracaoPixEmpresa[0], valor: this.valorRecebido};
				return this.abrirModalPix = true;
			} else {
				return this.receberConta(tipo, true);
			}
		},
		ordenar(ordem) {
			this.ordem = ordem;
			this.$store.commit("ordenarContasReceber", { metodoOrdem: this.metodoOrdem, ordem: this.ordem });
			this.removerCheckboxSelecionados();
		},

		async buscaVenda(cdVenda, cdEmpresa) {
			try {
				if (!cdVenda) return;
				const venda = await this.$store.dispatch("buscarVendaPorId", { cdVenda, cdEmpresa });
				if (!venda) return;
				if (venda.cdEmpresa != this.$store.state.Empresa.id) {
					const empresa = await this.$store.dispatch("buscarEmpresaId", { id: venda.cdEmpresa });
					if (!empresa?.id) {
						throw new Error("Empresa da venda não encontrada.");
					}
					this.empresaVenda = new Empresa(empresa);
				}
				this.vendaSelecionada = { ...venda };
			} catch (error) {
				console.log(error);
				return this.$store.commit("relatarErro", { mensagem: error.message || "Não foi possível encontrar a venda." });
			}
		},

		async buscaBaixa(conta) {
			try {
				if (!conta) return;
				const baixa = await this.$store.dispatch("buscarBaixa", {
					...conta,
					impressao: false
				});
				this.baixaSelecionada = { ...baixa };
			} catch (error) {
				console.log(error);
				return this.$store.commit("relatarErro", {
					mensagem: "Não foi possível encontrar a venda."
				});
			}
		},

		gerarExtrato() {
			if (this.valorSelecionado > 0) {
				return this.$store.dispatch("gerarExtrato", {
					empresa: this.$store.state.Empresa,
					parcelas: this.contasReceber.contas.filter(conta => conta.selecionada),
					cliente: this.Cliente,
					total: false
				});
			}
			this.$store.dispatch("solicitarImpressaoExtrato", {
				cliente: this.Cliente,
			});
		},

		proximoInput(idCampo) {
			setTimeout(() => {
				const input = document.getElementById(idCampo);
				if (input) {
					input.focus();
					if (input.select) input.select();
				}
			}, 100);
		},

		async baixarContas(params, parcelas) {
			const contaBaixada = await this.$store.dispatch("baixarContas", { 
				...params, parcelasCartao: parcelas 
			});
			if (contaBaixada) {
				await this.$store.dispatch("gerarRecibo", { ...contaBaixada });
				await this.$store.dispatch("buscarStateInicial");
				this.cancelar();
			}
		},
		reimprimirConta() {
			this.$store.dispatch("buscarBaixa", 
				!this.baixaSelecionada 
					? { ...this.parcelas[0], impressao: true } 
					: {
						cdBanco: this.baixaSelecionada.conta.cdbanco,
						cdAgencia: this.baixaSelecionada.conta.cdagencia,
						cdConta: this.baixaSelecionada.conta.cdconta,
						cdDig: this.baixaSelecionada.conta.cddig,
						tipo: this.baixaSelecionada.conta.tipo,
						impressao: true
					});
		},
		verificarPossibilidadeEstorno() {
			try {
				if (!this.parcelas.length) return;
				if (this.parcelas[0].dataBaixa) {
					const contasAux = [...this.contasReceber.contas];
					if (contasAux.sort((a, b) => new Date(b.dataBaixa).getTime() - new Date(a.dataBaixa).getTime())[0].cdDig != this.parcelas[0].cdDig) {
						throw new Error("É necessário estornar as baixas mais recentes primeiro!");
					}
					if ((new Date(this.parcelas[0].dataBaixa).toLocaleDateString("en-CA") != new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")) && !this.Usuario.usuarioPrivilegiado) {
						throw new Error("Não é possível estornar uma conta que não foi baixada na data de hoje.");
					}
				} else {
					if ((this.parcelas[0].dataDoc != new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")) && !this.Usuario.usuarioPrivilegiado) {
						throw new Error("Não é possível estornar uma conta que não foi baixada na data de hoje.");
					}
				}
				if (!this.Usuario.supervisor && !this.Usuario.usuarioPrivilegiado) {
					return this.$store.commit("relatarErro", {
						mensagem: "Será necessário solicitar autorização para estornar a conta selecionada, tem certeza?",
						tipo: "polar",
						item: {
							...this.parcelas[0],
							conta: true,
							route: "ContasReceber"
						}
					});
				}
				return this.$store.commit("relatarErro", {
					mensagem: "Tem certeza que deseja estornar a conta selecionada?",
					tipo: "polar",
					item: { ...this.parcelas[0] }
				});
			} catch (error) {
				this.resetData();
				return this.$store.commit("relatarErro", { mensagem: error.message });
			}
		},
		estornarConta() {
			this.removerCheckboxSelecionados();
			this.$store.dispatch("estornarConta", { ...this.parcelas[0] });
			this.resetData();
		},
		polarConfirmado(item) {
			if ((!this.Usuario.supervisor && !this.Usuario.usuarioPrivilegiado) && item.conta) {
				return this.$router.replace({
					name: "Autenticacao",
					query: { autorizacao: true },
					params: {
						...item,
						route: "ContasReceber",
						cliente: this.Cliente
					}
				});
			}
			if (item.abrirCaixa) {
				return this.abrirCaixa = true;
			}
			this.parcelas = [item];
			return this.estornarConta();
		},

		resetData() {
			this.parcelas = [];
			this.removerCheckboxSelecionados();
		},

		eventosTeclado(evento) {
			if (evento.key == "F2") {
				this.listarClientes = !this.listarClientes;
			}
		},

		verificarCaixaAberto() {
			if (this.$store.state.Caixa.caixa.aberto == "N") {
				this.$store.commit("relatarErro", { 
					mensagem: "O caixa está fechado, deseja abrir o caixa para iniciar as movimentações?",
					tipo: "polar",
					item: {
						abrirCaixa: true,
						conta: false 
					}
				});
				return false;
			} else {
				return true;
			}
		},
	},

	created() {
		this.$store.dispatch("buscarEmpresas");
		this.$store.dispatch("buscarFormasPagamento");
		if (this.$route.params.cliente) {
			this.Cliente = this.$route.params.cliente;
			this.buscarContas();
		}
		if (this.clienteProps) {
			this.Cliente = this.clienteProps;
			this.buscarContas();
		}
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		document.addEventListener("keydown", this.eventosTeclado);
	},
	destroyed() {
		document.removeEventListener("keydown", this.eventosTeclado);
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
	},
	mounted() {
		if (this.$route.query.autorizacao) {
			if (this.$route.params.conta) {
				this.parcelas = [this.$route.params];
				this.estornarConta();
				this.$route.query.autorizacao = false;
			}
		}
	}
};
</script>

<style scoped>
.w-40{
	width: 40%!important;
}
.w-30{
	width: 30%!important;
}
.w-100{
	width: 100%!important;
}

.mb-5 {
	margin-bottom: 5px;
}

.mt-5 {
	margin-top: 5px;
}

.input-group {
	width: 100%;
}


.conta-vencida {
	background-color: red;
	color: #FFF;
}
#gridPrincipalContasReceber {
	display: grid;
	max-width: 100vw;
	width: 100%;
	max-height: 92vh;
	height: 92vh;
	grid-template-columns: 50% 50%;
	grid-template-rows: 68px auto;
	grid-template-areas: "filtros filtros"
		"conteudo conteudo";
	background-color: #dddddd;
}

#filtros {
	grid-area: filtros;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: #ffffff;
	padding: 5px;
	margin: 10px 10px 10px 10px;
	border-radius: 10px;
	box-shadow: 1px 1px 5px #292929;
}

#conteudo {
	grid-area: conteudo;
	background-color: #ffffff;
	margin: 0px 10px 10px 10px;
	box-shadow: 1px 1px 5px #292929;
	padding: 5px;
	border-radius: 10px;
	display: flex;
	overflow: hidden;
}

.shadow {
	box-shadow: 1px 1px 5px #292929;
}

.btn {
	width: 100%;
	height: auto;
}

.row.around.w-90 {
	box-shadow: 1px 1px 2px #292929;
	padding: 5px 10px;
	border-radius: 5px;
	background-color: #ffffff;
}

.titulo {
	box-shadow: 1px 1px 2px #292929;
	padding: 5px 10px;
	margin: 10px 0px;
}

.scrollable {
	overflow: auto;
	display: flex;
}

.row.around.w-30.p-2.shadow label,
.row.around.w-100.p-2.shadow label {
	font-size: 12pt;
	font-weight: bolder;
}

tbody td label {
	cursor: pointer;
}


.h-100 {
	height: 80vh;
}

.inputValores {
	font-size: 12pt;
	font-weight: bold;
	width: 100%;
	text-align: right;
}
.total-empresa {
	font-size: 12pt;
	font-weight: bold;
	text-align: right;
	cursor: pointer;
}


@media screen and (max-width: 1024px) {
	.row.around.w-30.p-2.shadow {
		flex-direction: column;
	}

	.row.around.w-30.p-2.shadow input {
		width: 90%;
	}
}

@media screen and (max-width: 768px) {
	#gridPrincipalContasReceber {
		display: grid;
		grid-template-rows: 145px auto;
		overflow: auto;
	}

	#conteudo {
		min-height: 460px;
	}

	#filtros {
		font-size: 10pt;
		flex-wrap: wrap;
	}

	.row.around.w-90 input {
		font-size: 10pt;
	}

	.row.around.w-90 input {
		font-size: 10pt;
		width: 95%;
	}

	.row.around.w-90 select {
		width: 85%;
	}

	.row.w-100.p-2.m-2 {
		flex-wrap: wrap;
	}

	.row.around.w-30.p-2.shadow {
		width: 25%;
	}

	.row.around.w-30.p-2.shadow label,
	.row.around.w-30.p-2.shadow input {
		font-size: 10pt;
	}

	*,
	label,
	input {
		font-size: 10pt;
	}

	.btn {
		margin: 3px 0px;
	}
}

@media screen and (max-width: 425px) {

	*,
	label {
		font-size: var(--txt-pequeno) !important;
	}

	i {
		font-size: var(--txt-grande) !important;
	}

	.row.around.w-30.p-2.shadow {
		width: 50%;
		flex-direction: column;
	}

	.row.around.w-100.p-2.m-2 button {
		width: 90%;
		margin: 5px;
		height: auto;
		min-height: 20px;
	}

	.row.around.w-100.p-2.m-2 {
		height: auto;
	}

	.row.between.w-90.m-5 {
		flex-direction: column;
	}

	.col.between.w-30 {
		width: 100%;
	}

	.btn {
		margin-bottom: 10px;
	}
}
</style>
