import { dateFormatBR } from "../utils/dateformat";


export class Titulo {
	constructor(titulo) {
		this.cdlan = titulo.cdlan || "";
		this.cddig = titulo.cddig;
		this.cddoc = titulo.cddoc || "";
		this.competencia = titulo.competencia || `${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2,"0")}`;
		this.databaixa = titulo.databaixa;
		this.datadoc = titulo.datadoc || new Date().toLocaleDateString("en-CA")
		;
		this.datavenc = titulo.datavenc;
		this.cdPessoa = titulo.cdPessoa || "";
		this.nome = titulo.nome || "";
		this.planoContas = titulo.planoContas || [];
		this.cdTipoPgto = titulo.cdTipoPgto || "";
		this.origem = titulo.origem !== "O" 
			? this.stringToUpperCase(titulo.origem || "") 
			: this.stringToUpperCase(titulo.origemOutro);
		this.observacao = this.stringToUpperCase(titulo.observacao || "");
		this.valorTitulo = titulo.valorTitulo || "";
		this.valorParcela = titulo?.contarc || 0;
		this.valorEntrada = titulo?.valorEntrada || 0;
		this.valorAbatimento = titulo?.valorAbatimento || 0;
		this.vencimentoOriginal = titulo?.vencimentoOriginal || "";
		this.tipo = titulo?.tipo;
		
		// BANCO
		this.agencia = titulo?.agencia || "";
		this.banco = titulo?.banco || "";
		this.contaCorrente = titulo?.contaCorrente || "";
		this.conta = {};

		this.valorextenso = titulo.valorextenso || "";
	}

	convertNumber(str) {
		const numeroFormatado = typeof str === "string" ? str?.replace(".", "")?.replace(",", ".") : str;

		const number = parseFloat(numeroFormatado);
		return number;
	}

	convertForm(titulo) {
		const dto = this;
		dto.cdlan = titulo?.cdlan || "";
		dto.cddoc = titulo?.cddoc ? titulo?.cddoc : titulo?.cdmovi || "";
		dto.competencia = titulo?.competencia || "";
		dto.datadoc = titulo?.datadoc || "";
		dto.datavenc = titulo?.datavenc || "";
		dto.valorTitulo = `${Number(titulo?.vlrtitulo || 0).toFixed(2)}`.replace(".", ",");
		dto.valorParcela = `${Number(titulo?.contarc || 0).toFixed(2)}`.replace(".",",");
		dto.cdPessoa = titulo?.cdpessoa || "";
		dto.cdTipoPgto = titulo?.cdtipopgto || "";
		dto.cdIdentificador = titulo?.cdIdentificador || "";
		dto.origem = titulo?.origem || "";
		dto.observacao = titulo?.observa || "";

		dto.valorextenso = titulo.valorextenso || "";

		// BANCO
		dto.conta = {
			agencia: `${titulo.cdagencia || ""}`.trim(),
			banco: `${titulo.cdbanco || ""}`.trim(),
			cc: `${titulo.cdconta || ""}`.trim(),
		};


		if(titulo.planoConta?.length > 1){
			dto.planoContas = titulo?.planoConta.map(rateio => new RateioConta(rateio));
		} else {
			dto.planoContas = titulo?.planoConta;
		}

		return {
			...dto
		};

	}

	convert(titulo) {
		const {	
			valorParcela,
			valorTitulo,
			observacao,
			competencia,
			planoContas,
			databaixa,
			cddoc,
			...rest
		} = titulo;
		this.valorParcela = this.convertToNumber(valorParcela || 0);
		this.valorTitulo = this.convertToNumber(valorTitulo || 0);
		this.observacao = this.stringToUpperCase(observacao || "");
		this.databaixa = databaixa ? new Date(databaixa || "")?.toISOString().split("Z")[0] : "";
		this.competencia = `${competencia}-01`;
		this.cddoc = `${cddoc}`.substring(0, 50);
		if(planoContas.length > 0) {
			this.planoContas = planoContas.map(plano => new RateioContaAPI(plano));
		}
		
		return {
			valorEntrada: this.valorParcela,
			valorParcela: this.valorParcela,
			valorTitulo: this.valorTitulo,
			observacao: this.observacao,
			planoContas: this.planoContas,
			competencia: this.competencia,
			databaixa: this.databaixa,
			cddoc: this.cddoc,
			...rest
		};
	}

	stringToUpperCase(str) {
		return str ? str.toUpperCase() : null;
	}

	convertToNumber(str) {
		const numeroFormatado = typeof str === "string" ? str?.replace(".", "")?.replace(",", ".") : str;

		const number = parseFloat(numeroFormatado);
		return number;
	}

}

export class RateioContaAPI {
	constructor(payload) {
		this.cdCentroCusto = payload?.centroCusto?.cdcusto || payload?.cdCentroCusto;
		this.competencia = payload?.competencia;
		this.idPlanoconta = payload?.planoConta?.vid || payload?.idPlanoconta;
		this.percentual = payload?.percentual || payload?.percentual;
		this.valor = payload?.valor;
	}
}
export class RateioConta {
	constructor(rateio){
		this.cdCentroCusto = rateio.cdCentroCusto;
		this.idrateioconta = rateio.idrateioconta;
		this.percentual = rateio.percentual;
		this.competencia = rateio.competencia;
		this.valor = this.convertNumber(rateio.valor || 0); 
	}

	convertNumber(str) {
		const numeroFormatado = typeof str === "string" ? str.replace(".", "").replace(",", ".") : str;
		const number = parseFloat(numeroFormatado);
		return number;
	}
}


export class ContaPagar {
	constructor(movi){
		this.cdempresa = movi.cdempresa;
		this.cdmovi = movi.cdmovi || movi.cddoc;
		this.cdlan = movi.cdlan;
		this.cdpessoa = movi.cdpessoa || "";
		this.cdvendedor = movi?.cdvendedor || "";
		this.nome = movi.nome;
		this.origem = movi.origem;
		this.competencia = this.formatarCompetencia(movi.competencia);
		this.formaPagamento = movi.pagamento;
		this.dataEmissao = dateFormatBR(movi.datadoc);
		this.dataBaixa = movi?.databaixa ? dateFormatBR(movi?.databaixa?.includes("T") ? movi?.databaixa?.split("T")[0] : movi?.databaixa) : "";
		this.dataVenc = dateFormatBR(movi.datavenc);
		this.valorParcela = movi.contarc || 0;
		this.debito = movi.debito || 0;
		this.valorPago = movi.valor || 0;
		this.restante = movi.restante || 0;
		this.valorTitulo = movi.vlrtitulo || 0;
		this.situacao = movi.situacao == "A" || !movi.databaixa ? "Aberto" : "Baixado";
		this.cdconta = movi.cdconta;
		this.cdagencia = movi.cdagencia;
		this.cdbanco = movi.cdbanco;
		this.cddig = movi.cddig;
		this.tipo = movi.tipo;
		this.planoConta = movi.planoConta && movi.planoConta.length > 0 ? movi?.planoConta.map(plano => new PlanoContaTitulo(plano)) : null;
		this.observacao = movi.observa || "";
		this.origem = movi.origem || "";		
		this.observacaoCompra = movi.observacao || "";
	}
	formatarCompetencia(competencia){
		if(competencia){
			let novaCompetencia = competencia.split("-");
			return `${novaCompetencia[0]}-${novaCompetencia[1]}`;
		}	
	}
}

export class CentroCusto{
	descricao;
	cdcusto;
	ativo;
	constructor(centro){
		this.cdcusto = centro.cdcusto;
		this.descricao = centro.descricao;
		this.ativo = centro.ativo;
	}
	convert() {
		const {descricao, ...rest} = this;

		this.descricao = `${descricao || ""}`.toUpperCase();

		return {
			descricao: this.descricao,
			...rest,
		};
	}
}


export class PlanoContaTitulo{
	cdCentroCusto;
	centroCusto;
	id;
	percentual;
	planoConta;
	competencia;
	valor;
	constructor(plano){
		this.centroCusto = new CentroCusto({
			cdcusto: plano.cdCentroCusto,
			descricao: plano.centroCusto,
			ativo: true
		});
		this.id = plano.id;
		this.competencia = plano.competencia;
		this.percentual = plano.percentual;
		this.valor = plano.valor;
		this.planoConta = new PlanoConta(plano.planoConta);
	}
}


export class PlanoConta {
	cod;
	descricao;
	tipoConta;
	alteraValor;
	filhos;
	idContaPai;
	sequencia;
	valorPrevisao;
	constructor(conta){
		this.cod = conta.id;
		this.descricao = conta.nome;
		this.tipoConta = conta.tipoPlanoConta;
		this.alteraValor = conta.podeAlterarValor;
		this.filhos = conta.filhos?.length > 0 ? conta.filhos.map(filho => new PlanoConta(filho)) : [];
		this.idContaPai = conta.plano_conta_pai_id;
		this.sequencia = conta.sequencia;
		this.valorPrevisao = conta.previsao;
	}
}

export class PlanoContaAPI{
	idPlanoContaPai; 
	nome;
	podeALterarValor;
	tipoPlanoConta;
	sequencia;
	previsao;
	constructor(novoPlano){
		this.idPlanoContaPai = novoPlano.idContaPai;
		this.nome = novoPlano?.nome.toUpperCase();
		this.podeAlterarValor = novoPlano.alterarValor;
		this.tipoPlanoConta = novoPlano.tipoPlanoConta;
		this.sequencia = novoPlano.sequencia;
		this.previsao = novoPlano.previsao;
	}
}

export class TituloBaixarAPI{
	constructor(conta){
		
		this.cdagenciaBaixa = conta.cdagenciaBaixa,
		this.cdbancoBaixa = conta.cdbancoBaixa,
		this.cdcontaBaixa = conta.cdcontaBaixa,
		this.aumdesc = conta?.aumdesc;
		this.cdpessoa = conta?.cdcliente;
		this.cddoc = conta?.cddoc;
		this.cdempresa = conta?.cdempresa;
		this.cdvendedor = conta?.cdvendedor;
		this.dataBaixa = this.convertDate(conta?.dataBaixa);
		this.nome = conta?.nome;
		this.observa = conta?.observa;
		this.tipoPgto = conta?.tipoPgto;
		this.tituloABaixarCdagencia = conta?.tituloABaixarCdagencia ? conta?.tituloABaixarCdagencia.trim() : "";
		this.tituloABaixarCdbanco = conta?.tituloABaixarCdagencia ? conta?.tituloABaixarCdbanco.trim() : "";
		this.tituloABaixarCdconta = conta?.tituloABaixarCdconta ? conta?.tituloABaixarCdconta.trim() : "";
		this.tituloABaixarCddig = conta?.tituloABaixarCddig;
		this.tituloABaixarTipo = conta?.tituloABaixarTipo;
		this.valorBaixa = conta?.valorBaixa;
		this.planoContas = conta?.planoContas.map(pc => new PlanoContaBaixaAPI(pc));
		this.valorEntreguePeloCliente = conta?.valorEntreguePeloCliente;
	}

	convertDate(date){
		return date.split("/").reverse().join("-");
	}
}

export class ContaAgenda{
	constructor(conta) {
		this.condicao = conta.condicao;
		this.title = this.condicao === "B" ? `${this.formatToCurrency(conta.valor)} baixado` : `${this.formatToCurrency(conta.valor)} em aberto`;
		this.date = conta.data;
		this.quantidade = conta.quantidade;
		this.color = this.condicao === "B" ? "#0384fc" : "#f00";
	}

	formatToCurrency(number) {
		return Number(number || 0).toLocaleString("pt-BR", {
			style: "currency",
			currency: "BRL"
		});
	}
}


class PlanoContaBaixaAPI{
	constructor(pc) {
		this.percentual = pc.percentual;
		this.competencia = pc.competencia;
		this.valor = pc.valor;
		this.idPlanoconta = pc.planoConta.id;
		this.cdCentroCusto = pc.cdCentroCusto;
	}
}