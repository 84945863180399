<template>
    <div id="listaModal" :class="`col w-95 ${!showTable? 'table-full' : ''}`" v-if="Contas.length >= 1">
        <table class="w-100">
            <thead>
                <tr>
                    <td style="color: blue; text-align: center;">Nome</td>
                    <td style="text-align: center;">Banco</td>
                    <td style="text-align: center;">Agência</td>
                    <td style="text-align: center;">Conta Corrente</td>
                </tr>
            </thead>
            <tbody>
                <tr class="itemLista" v-for="(conta, index) in Contas" :key="index" @click="selecionarConta(conta)">
                    <td style="color: blue; text-align: center;">
                        <button :id="index" class="hand-button" v-if="index == indexAtual"> 
                            <i class="fas fa-hand-point-right" style="color: var(--primario)" >
                            </i>
                        </button>
                        {{conta[tipoConteudo == "Contas" ? "nome" : "identificador"]}}
                    </td>
                    <td style="text-align: center;">
                        {{conta.banco}}
                    </td>
                    <td style="text-align: center;">
                        {{conta.agencia}}
                    </td>
                    <td style="text-align: center;">
                        {{conta.cc}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div v-else class="col center w-100">
        <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt" @click="fecharModal"></i>
        <h1>Conta não encontrada no banco de dados da loja.</h1>
        <h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
    </div>
</template>
<script>
export default {
	name: "TabelaContas",

	props: {
		tipoConteudo: String,
		showTable: Boolean, 
	},

	computed: {
		Contas() {
			if (this.tipoConteudo == "Caixas") {
				return this.$store.state[this.tipoConteudo].content;    
			}
			return this.$store.state[this.tipoConteudo];
		}
	},

	data() {
		return {
			indexAtual: 0
		};
	},

	methods: {
		selecionarConta(conta){
			return this.$emit("selecionarConta", conta);
		},

		fecharModal(){
			this.$emit("fecharModal");
		},
	},
};
</script>
<style scoped>
#listaModal {	
    overflow: auto;
    margin: 0 auto;
    font-size: var(--txt-normal);
	height: 65vh;
}
#listaModal.table-full{
	height: 77vh !important;
}
@media screen and (max-width: 768px) {
	#listaModal.table-full{
		height: 68vh !important;
	}
	#listaModal{
		height: 43vh !important;
	}
}
@media screen and (max-width: 425px) {
	#listaModal.table-full{
		height: 71vh !important;
	}
	#listaModal{
		height: 30vh !important;
	}
}
</style>