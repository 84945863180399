/* eslint-disable no-console */
import { register } from "register-service-worker";

const appName = require("../package.json").name;
const appVersion = require("../package.json").version;
const cacheName = `${appName}-${appVersion}`;

if (process.env.NODE_ENV === "production") {
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready () {
			console.log("App is being served from cache by a service worker.");
		},
		async registered () {
			try {
				let versao = localStorage.getItem("versao");
				if (versao && (versao != appVersion)) {
					localStorage.removeItem("versao");
					versao = null;
				}
				if (!versao) {
					localStorage.setItem("versao", appVersion);
				}
				const cacheNames = await caches.keys();
				if (cacheNames.length) {
					for (const cache of cacheNames) {
						await caches.delete(cache);
					}
					console.log("caches deleted!");
				}
				await caches.open(cacheName);
				console.log("Service worker has been registered.");
			} catch (error) {
				console.log(error);
				localStorage.clear();
			}
		},
		cached () {
			console.log("Content has been cached for offline use.");
		},
		updatefound () {
			console.log("New content is downloading.");
		},
		updated () {
			console.log("New content is available; please refresh.");
		},
		offline () {
			console.log("No internet connection found. App is running in offline mode.");
		},
		error (error) {
			console.error("Error during service worker registration:", error);
		},
		fetch (event){
			console.log(event);
		},
	});
}
