<template>
	<div></div>
</template>
<script>
export default {
	name: "ArrowNavigationGrupoProduto",
	props: {
		ArrayItens: {
			type: Array,
			required: true,
		},
		selectedItem: Object,
		type: String,
	},

	data() {
		return {
			currentIndex: -1,
		};
	},

	watch: {
		selectedItem(newGroup, oldGroup) {
			if(newGroup !== oldGroup) {
				this.currentIndex = this.findSelectedItem();
			}
		}
	},

	methods: {
		findSelectedItem() {
			return this.ArrayItens.findIndex(item => item.cdGrupo === this.selectedItem.cdGrupo);
		},

		handleArrowNavigation(e) {
			if(e.key === "ArrowRight") {
				this.navigateRight();
			} else if (e.key === "ArrowLeft") {
				this.navigateLeft();
			}
		},

		navigateRight() {
			const nextIndex = (this.currentIndex + 1) % this.ArrayItens.length;
			this.$emit(`selecionou${this.type}`, this.ArrayItens[nextIndex]);
		},

		navigateLeft() {
			const prevIndex = (this.currentIndex - 1 + this.ArrayItens.length) % this.ArrayItens.length;
			this.$emit(`selecionou${this.type}`, this.ArrayItens[prevIndex]);
		}
	},

	mounted() {
		this.currentIndex = this.findSelectedItem();
		window.addEventListener("keydown", this.handleArrowNavigation);
	},

	beforeDestroy() {
		window.removeEventListener("keydown", this.handleArrowNavigation);
	}
};
</script>