export class Produto {
	constructor({
		cdpai,
		descvista,
		ativo,
		cdempresa,
		descricao,
		cofins,
		valoricmsst,
		custo,
		customedio,
		saldo,
		saldoCondicional,
		ipi,
		valorpis,
		pis,
		valoripi,
		valorcofins,
		frete,
		icmsint,
		icmsest,
		ultsaldo,
		pistributacao,
		fator,
		custofixo,
		pontoequi,
		ultvenda,
		ultcusto,
		ncm,
		icmsestsittrib,
		ultmarkup,
		cfopesta,
		icmsst,
		valoroutros,
		outros,
		icmsintsittrib,
		promocao,
		pissittrib,
		markup,
		valorfrete,
		cfopinter,
		ultfator,
		valormarkupseg,
		curvaabc,
		cdorigem,
		cofinssittrib,
		markupseg,
		ultsaida,
		vlrvista,
		valorPromocaoVista,
		valorPromocaoPrazo,
		ipisittrib,
		ipitributacao,
		cdsubgrupo,
		cdproduto,
		cdgrupo,
		cdreferencia,
		grade,
		codbarra,
		codbarraFornecedor,
		variacao,
		unidade,
		cofinstributacao,
		dataultalteracao,
		percentualacrescimofisica,
		embalagem,
		qteEmbalagem,
		pesoBruto,
		pesoLiquido,
		estoqueMaximo,
		estoqueMinimo,
		geraComissao,
		ecf,
		cdFornecedor,
		nomeFornecedor,
		multiplo,
		codinter,
		quantidade,
		cdpromocao,
		dataPromocaoInico,
		dataPromocaofim,
		promocaoPrazo,
		promocaoVista,
		nomePromocao,
		cdagrupamento,
		descresumido,
		tipo,
		servprod,
		id,
		datacadastro,
		saldoOutrasEmpresa,
		empresas,
		outrosProdutos,
		dultcompra,
		dultvenda,
		granulado,
		qtdcondicional
	}) {
		this.cdpromocao = cdpromocao;
		this.dataPromocaoInico = dataPromocaoInico;
		this.dataPromocaofim = dataPromocaofim;
		this.promocao = promocao;
		this.promocaoPrazo = promocaoPrazo;
		this.promocaoVista = promocaoVista;
		this.nomePromocao = nomePromocao;
		this.id = cdproduto || id;
		this.descricao = `${descricao || ""}`.substring(0,100);
		this.pai = cdpai || null;
		this.valorVista = Number((vlrvista || pontoequi || 0).toFixed(2));
		this.valorPrazo = Number((pontoequi || 0).toFixed(2));
		this.codBarra = codbarra;
		this.codbarraFornecedor = codbarraFornecedor;
		this.cdReferencia = cdreferencia;
		this.ativo = ativo;
		this.unidade = unidade;
		this.cdEmpresa = cdempresa;
		this.cdgrupo = cdgrupo;
		this.cdsubgrupo = cdsubgrupo;
		this.saldo = saldo;
		this.saldoCondicional = saldoCondicional;
		this.custo = custo;
		this.custoMedio = customedio;
		this.valoripi = valoripi;
		this.ipi = ipi;
		this.valorpis = valorpis;
		this.pis = pis;
		this.valorcofins = valorcofins;
		this.cofins = cofins;
		this.valoricmsst = valoricmsst;
		this.icmsst = icmsst;
		this.valorfrete = valorfrete;
		this.frete = frete;
		this.valoroutros = valoroutros;
		this.outros = outros;
		this.valormarkupseg = valormarkupseg;
		this.markupseg = markupseg;
		this.custofixo = custofixo;
		this.markup = markup;
		this.fator = fator;
		this.pontoequi = pontoequi;
		this.descvista = descvista;
		this.vlrvista = vlrvista;
		this.valorPromocaoVista = typeof(valorPromocaoVista) == "number" ? Number(Number(valorPromocaoVista).toFixed(2)) : Number(`${valorPromocaoVista}`.replace(".", "").replace(",", ".") || 0);
		this.valorPromocaoPrazo = typeof(valorPromocaoPrazo) == "number" ? Number(Number(valorPromocaoPrazo).toFixed(2)) : Number(`${valorPromocaoPrazo}`.replace(".", "").replace(",", ".") || 0);
		this.percentualacrescimofisica = percentualacrescimofisica;
		this.promocao = promocao;
		this.ultcusto = ultcusto;
		this.dataultalteracao = dataultalteracao;
		this.ultmarkup = ultmarkup;
		this.ultfator = ultfator;
		this.ultvenda = ultvenda;
		this.ultsaida = ultsaida;
		this.ultimoCusto = ultcusto;
		this.icmsint = icmsint;
		this.icmsest = icmsest;
		this.ultsaldo = ultsaldo;
		this.pistributacao = pistributacao;
		this.ncm = ncm;
		this.icmsestsittrib = icmsestsittrib;
		this.cfopesta = cfopesta;
		this.icmsintsittrib = icmsintsittrib;
		this.pissittrib = pissittrib;
		this.cfopinter = cfopinter;
		this.curvaabc = curvaabc;
		this.cdorigem = cdorigem;
		this.cofinssittrib = cofinssittrib;
		this.ipisittrib = ipisittrib;
		this.ipitributacao = ipitributacao;
		this.grade = grade;
		this.variacao = variacao;
		this.cofinstributacao = cofinstributacao;
		this.embalagem = embalagem;
		this.qteEmbalagem = qteEmbalagem;
		this.estoqueMaximo = estoqueMaximo;
		this.estoqueMinimo = estoqueMinimo;
		this.pesoBruto = pesoBruto;
		this.pesoLiquido = pesoLiquido;
		this.geraComissao = geraComissao;
		this.ecf = (ecf == "S");
		this.cdFornecedor = cdFornecedor;
		this.nomeFornecedor = nomeFornecedor;
		this.multiplo = multiplo;
		this.codinter = codinter;
		this.quantidade = quantidade;
		this.cdagrupamento = cdagrupamento;
		this.descresumido = descresumido;
		this.tipo = tipo;
		this.servprod = servprod;
		this.datacadastro = datacadastro;
		this.saldoOutrasEmpresas = saldoOutrasEmpresa;
		this.empresas = empresas ? empresas : null;
		this.outrosProdutos = outrosProdutos || [];
		this.dataUltCompra = dultcompra;
		this.dataUltVenda = dultvenda;
		this.qtdcondicional = (granulado || qtdcondicional) || 0;
	}
}

export class ProdutoApi {
	constructor(produto) {
		this.cdagrupamento = produto?.cdagrupamento;
		this.cdFornecedor = produto.fornecedor ? produto.fornecedor.id : (produto.cdfornecedor || produto.cdFornecedor);
		this.cdgrupo = typeof(produto.cdsubgrupo) == "number" ? produto.cdsubgrupo : produto.cdsubgrupo ? produto.cdsubgrupo.cdsubgrupo : 0;
		this.cdlinha = produto.cdlinha ? produto.cdlinha : typeof(produto.cdgrupo) == "number" ? produto.cdgrupo : produto.grupoSelecionado ? produto.grupoSelecionado.cdGrupo : 0;
		this.cdpai = produto.pai || produto.cdpai;
		this.cdproduto = produto.cdproduto;
		this.cdreferencia = this.setUpperCase(produto.cdReferencia || produto.cdreferencia);
		this.codbarra = produto.codBarra || produto.codbarra;
		this.codbarraFornecedor = produto.codbarraFornecedor;
		this.codinter = produto.codinter || 0;
		this.descricao = this.setUpperCase(produto.descricao.includes(";") ? produto.descricao.substring(0,100).split(";")[0] : produto.descricao.substring(0, 100));
		this.embalagem = this.setUpperCase(produto.embalagem || produto.unidade);
		this.estoqueMaximo = Number((produto.estoqueMaximo || "").toString().replace(",", "."));
		this.estoqueMinimo = Number((produto.estoqueMinimo || "").toString().replace(",", "."));
		this.formacaoPreco = produto.formacaoPreco,
		this.geraComissao = produto.geraComissao;
		this.grade = produto.grade;
		this.multiplo = Number((produto.multiplo || "").toString().replace(",", "."));
		this.pesoBruto = Number((produto.pesoBruto || "").toString().replace(",", "."));
		this.pesoLiquido = Number((produto.pesoLiquido || "").toString().replace(",", "."));
		this.qteEmbalagem = Number((produto.qteEmbalagem || 1).toString().replace(",", "."));
		this.unidade =  this.setUpperCase(produto?.unidade);
		this.variacao = produto.variacao;
		this.ativo = produto.ativo || "A";
		this.fornecedor = produto.fornecedor;
		this.desconto = produto.desconto;
		this.descresumido = this.setUpperCase(produto.descresumido ? produto.descresumido : null);
		this.servprod = produto.servprod;
		this.datacadastro = produto.datacadastro;
		this.tipo = produto.tipo;

	}

	setUpperCase(field){
		return field ? field.toUpperCase() : "";
	}
}

export class FormacaoPreco {
	constructor(produto) {
		this.cdorigem = produto.cdorigem;
		this.cfopesta = produto.cfopesta;
		this.cfopinter = produto.cfopinter;
		this.cofins = produto.cofins;
		this.cofinssittrib = produto.cofinssittrib;
		this.cofinstributacao = produto.cofinstributacao;
		this.curvaabc = produto.curvaabc;
		this.custo = Number(Number(produto.custo).toFixed(2)) || Number(Number(produto.vlrunitario).toFixed(2));
		this.custofixo = produto.custofixo || produto.vlrunitario;
		this.customedio = produto.custoMedio;
		this.dataultalteracao = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-");
		this.descvista = produto.descvista? typeof(produto.descvista) == "number" ? Number(Number(produto.descvista).toFixed(2)) : Number(`${produto.descvista}`.replace(".", "").replace(",", ".") || 0) : produto.cdesc;
		this.ecf = produto.ecf ? "S" : "N";
		this.fator = produto.fator;
		this.frete = typeof(produto.frete) == "number" ? produto.frete : Number(`${produto.frete}`.replace(".", "").replace(",", ".") || 0);
		this.icmsest = produto.icmsest;
		this.icmsestsittrib = produto.icmsestsittrib;
		this.icmsint = produto.icmsint;
		this.icmsintsittrib = produto.icmsintsittrib;
		this.icmsst = typeof(produto.icmsst) == "number" ? produto.icmsst : Number(`${produto.icmsst}`.replace(".", "").replace(",", ".") || 0);
		this.ipi = typeof(produto.ipi) == "number" ? produto.ipi :  Number(`${produto.ipi}`.replace(".", "").replace(",", ".") || 0);
		this.ipisittrib = produto.ipisittrib;
		this.ipitributacao = produto.ipitributacao;
		this.markup = produto.markup ? (typeof(produto.markup) == "number" ? produto.markup : Number(`${produto.markup}`.replace(".", "").replace(",", ".") || 0)) : (typeof(produto.margem) == "number" ? produto.margem : Number(`${produto.margem}`.replace(".", "").replace(",", ".") || 0));
		this.markupseg = produto.markupseg;
		this.ncm = produto.ncm;
		this.outros = typeof(produto.outros) == "number" ? Number(Number(produto.outros).toFixed(2)) : Number(`${produto.outros}`.replace(".", "").replace(",", ".") || 0);
		this.percentualacrescimofisica = produto.percentualacrescimofisica;
		this.pis = produto.pis;
		this.pissittrib = produto.pissittrib;
		this.pistributacao = produto.pistributacao;
		this.pontoequi = produto.pontoequi ? typeof(produto.pontoequi) == "number" ? Number(Number(produto.pontoequi).toFixed(2)) : Number(`${produto.pontoequi}`.replace(".", "").replace(",", ".") || 0) : typeof (produto.prazo) == "number" ? Number(Number(produto.prazo).toFixed(2)) : Number((produto.prazo || "0").replace(".","").replace(",","."));
		this.promocao = produto.promocao;
		this.saldo = 
			typeof produto.saldo == "number" 
				? produto.saldo 
				: Number(`${produto.saldo}`.replace(".", "").replace(",", ".") || 0);
		this.saldoCondicional =  typeof produto.saldoCondicional == "number" ? produto.saldoCondicional : Number(`${produto.saldoCondicional}`.replace(".", "").replace(",", ".") || 0);
		this.ultcusto = produto.ultcusto;
		this.ultfator = produto.ultfator;
		this.ultmarkup = produto.ultmarkup;
		this.ultsaida = produto.ultsaida;
		this.ultsaldo = typeof produto.ultsaldo == "number" ? produto.ultsaldo : Number(`${produto.ultsaldo}`.replace(".", "").replace(",", ".") || 0);
		this.ultvenda = produto.ultvenda;
		this.valorcofins = Number(Number(produto.valorcofins).toFixed(2));
		this.valorfrete = typeof(produto.valorfrete) == "number" ? Number(Number(produto.valorfrete).toFixed(2)) : Number(`${produto.valorfrete}`.replace(".", "").replace(",", ".") || 0);
		this.valoricmsst = typeof(produto.valoricmsst) == "number" ? Number(Number(produto.valoricmsst).toFixed(2)) : Number(`${produto.valoricmsst}`.replace(".", "").replace(",", ".") || 0);
		this.valoripi = typeof(produto.valoripi) == "number" ? Number(Number(produto.valoripi).toFixed(2)) : Number(`${produto.valoripi}`.replace(".", "").replace(",", ".") || 0);
		this.valormarkupseg = produto.valormarkupseg;
		this.valoroutros = typeof(produto.valoroutros) == "number" ? Number(Number(produto.valoroutros).toFixed(2)) : Number(`${produto.valoroutros}`.replace(".", "").replace(",", ".") || 0);
		this.valorpis = typeof(produto.valorpis) == "number" ?  Number(Number(produto.valorpis).toFixed(2)) : Number(`${produto.valorpis}`.replace(".", "").replace(",", ".") || 0);
		this.vlrvista = produto.valorVista || typeof(produto.vlrvista) == "number" ? Number(Number(produto.vlrvista).toFixed(2)) : Number(`${produto.vlrvista}`.replace(".", "").replace(",", ".") || 0);
		this.agrupamento = produto.agrupamento;
		this.cfopintercompra = produto.cfopintercompra;
		this.cfopestacompra = produto.cfopestacompra;
	}
}

export class FormacaoPrecoCompra {
	constructor(produto) {
		this.cdorigem = produto.cdorigem;
		this.cfopesta = produto.cfopesta;
		this.cfopinter = produto.cfopinter;
		this.cofins = produto.cofins;
		this.cofinssittrib = produto.cofinssittrib;
		this.cofinstributacao = produto.cofinstributacao;
		this.curvaabc = produto.curvaabc;
		this.custo = Number((produto.vlrunitario || produto.custo || 0).toFixed(2));
		this.custofixo = Number((produto.custo || 0).toFixed(2));
		this.customedio = Number((produto.custo || 0).toFixed(2));
		this.dataultalteracao = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-");
		this.descvista = produto.cdesc;
		this.ecf = produto.ecf ? "S" : "N";
		this.fator = produto.fator;
		this.frete = typeof(produto.frete) == "number" ? Number(Number(produto.frete).toFixed(2)) : Number(`${produto.frete || 0}`.replace(".", "").replace(",", "."));
		this.icmsest = Number(Number(produto.icmsest || 0).toFixed(2));
		this.icmsestsittrib = produto.icmsestsittrib;
		this.icmsint = Number(Number(produto.icmsint || 0).toFixed(2));
		this.icmsintsittrib = produto.icmsintsittrib;
		this.icmsst = typeof(produto.icmsst) == "number" ? Number(Number(produto.icmsst).toFixed(2)) : Number(`${produto.icmsst}`.replace(".", "").replace(",", "."));
		this.ipi = typeof(produto.ipi) == "number" ? Number(Number(produto.ipi).toFixed(2)) :  Number(`${produto.ipi}`.replace(".", "").replace(",", "."));
		this.ipisittrib = produto.ipisittrib;
		this.ipitributacao = produto.ipitributacao;
		this.markup = typeof(produto.margem) == "number" ? Number(Number(produto.margem).toFixed(2)) : Number(`${produto.margem}`.replace(".", "").replace(",", ".") || 0);
		this.markupseg = produto.markupseg;
		this.ncm = produto.ncm;
		this.outros = typeof(produto.outros) == "number" ?  Number(Number(produto.outros).toFixed(2)) : Number(`${produto.outros}`.replace(".", "").replace(",", ".") || 0);
		this.percentualacrescimofisica = produto.percentualacrescimofisica;
		this.pis = produto.pis;
		this.pissittrib = produto.pissittrib;
		this.pistributacao = produto.pistributacao;
		this.pontoequi = typeof (produto.prazo) == "number" ? Number(Number(produto.prazo).toFixed(2)) : Number((produto.prazo || "0").replace(".","").replace(",","."));
		this.promocao = produto.promocao;
		this.ultsaldo = produto.saldo;
		this.saldo = (produto.saldo);
		this.saldoCondicional = (produto.saldoCondicional);
		this.ultcusto = produto.ultcusto;
		this.ultfator = produto.ultfator;
		this.ultmarkup = produto.ultmarkup;
		this.ultsaida = produto.ultsaida;
		this.ultvenda = produto.ultvenda;
		this.valorcofins = Number(Number(produto.valorcofins).toFixed(2));
		this.valorfrete = typeof(produto.valorfrete) == "number" ? Number(Number(produto.valorfrete).toFixed(2)) : Number(`${produto.valorfrete}`.replace(".", "").replace(",", ".") || 0);
		this.valoricmsst = typeof(produto.valoricmsst) == "number" ? Number(Number(produto.valoricmsst).toFixed(2)) : Number(`${produto.valoricmsst}`.replace(".", "").replace(",", ".") || 0);
		this.valoripi = typeof(produto.valoripi) == "number" ? Number(Number(produto.valoripi).toFixed(2)) : Number(`${produto.valoripi}`.replace(".", "").replace(",", ".") || 0);
		this.valormarkupseg = produto.valormarkupseg;
		this.valoroutros = typeof(produto.valoroutros) == "number" ? Number(Number(produto.valoroutros).toFixed(2)) : Number(`${produto.valoroutros}`.replace(".", "").replace(",", ".") || 0);
		this.valorpis = typeof(produto.valorpis) == "number" ?  Number(Number(produto.valorpis).toFixed(2)) : Number(`${produto.valorpis}`.replace(".", "").replace(",", ".") || 0);
		this.vlrvista = typeof(produto.vista) == "number" ? Number(Number(produto.vista).toFixed(2)) : Number(`${produto.vista}`.replace(".", "").replace(",", ".") || 0);
		this.agrupamento = produto.agrupamento;
		this.cfopintercompra = produto.cfopintercompra;
		this.cfopestacompra = produto.cfopestacompra;
	}
}