<template>
	<div class="EmpresaDados">
		<div class="titulo">
			<h1>Dados da Empresa</h1>
		</div>
			<div class="box-cadastro">
				<div class="box">
					<div class="alinhamento">
						<div class="campos">
							<div class="input-group">
								<input v-model="EmpresaCadastrada.id" autocomplete="off" id="id" type="text" disabled/>
								<label class="mx-5 w-auto" for="codigo">Código</label>
							</div>
						</div>
						<div class="campos">
							<div class="input-group" style="width: 100%;">
								<input v-model="EmpresaCadastrada.dataCadastro" autocomplete="off" id="dataCadastro" type="date" disabled/>
								<label class="mx-5 w-auto" for="dataCadastro">Data Cadastro</label>
							</div>
						</div>
						<div id="ativo" @click="mudarAtivo('ativo', 'A', 'I')" class="campos">
							<label class="mx-5 w-auto" for="ativo">Ativo: </label>
							<i class="far fa-times-circle" v-if="EmpresaCadastrada.ativo == 'I'" style="color: red;" >Não</i>
							<i class="far fa-check-circle" v-else style="color: green">Sim</i>
						</div>
						<div class="campos">
							<div class="input-group grow">
								<input v-model="EmpresaCadastrada.nome" autocomplete="off" id="empresa" type="text"
								@keydown.enter="proximoCampo('responsavel')" maxlength="50" placeholder=" "/>
								<label class="mx-5 w-auto" for="empresa">Razão Social</label>
							</div>
						</div>
					</div>
				</div>
				<div class="box">
						<div class="campos">
							<div class="input-group grow">
								<input v-model="EmpresaCadastrada.responsavel" autocomplete="off" id="responsavel" type="text"
								@keydown.enter="proximoCampo('fantasia')" maxlength="30" placeholder=" "/>
								<label class="mx-5 w-auto" for="responsavel">Responsável</label>
							</div>
						</div>
					<div class="campos">
						<div class="input-group grow">
							<input v-model="EmpresaCadastrada.fantasia" autocomplete="off" id="fantasia" type="text"
							@keydown.enter="proximoCampo('cnpj')" maxlength="50" placeholder=" "/>
							<label class="mx-5 w-auto" for="fantasia">Fantasia</label>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="campos">
						<div class="input-group grow" id="cnpjBorder">
							<input v-model="EmpresaCadastrada.cnpj" autocomplete="off" id="cnpj" type="text" pattern="[0-9]+$"
							@input="mascara" maxlength="18" @keydown.enter="proximoCampo('ie')" @keyup="validarCnpj" placeholder=" "/>
							<label class="mx-5 w-auto " for="cnpj">CNPJ</label>
						</div>
					</div>
					<div class="campos">
						<div class="input-group grow">
							<input v-model="EmpresaCadastrada.ie" autocomplete="off" id="ie" type="text"
							@keydown.enter="proximoCampo('cep')" maxlength="20" placeholder=" "/>
							<label class="mx-5 w-auto" for="ie">Inscrição Estadual</label>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="alinhamento">
						<div class="campos">
							<div class="input-group grow">
								<input v-model="EmpresaCadastrada.cep" autocomplete="off" id="cep" type="text"
								@input="mascara" @keydown.enter="proximoCampo('nomeCidade')" maxlength="9"/>
								<label class="mx-5 w-auto" for="cep">CEP</label>
							</div>
						</div>
						<div class="campos">
							<div class="input-group grow">
								<input v-model="EmpresaCadastrada.nomeCidade" autocomplete="off" id="nomeCidade" type="text" @dblclick="solicitaListarCidades"
								@keydown.enter="proximoCampo('ufCidade')" @contextmenu.prevent="solicitaListarCidades" readonly placeholder=" "/>
								<label class="mx-5 w-auto" for="nomeCidade">Cidade</label>
							</div>
						</div>
						<div class="campos">
							<div class="input-group grow">
								<input v-model="EmpresaCadastrada.ufCidade" autocomplete="off" id="ufCidade" type="text"
								@keydown.enter="proximoCampo('endereco')" maxlength="2" readonly placeholder=" "/>
								<label class="mx-5 w-auto" for="ufCidade">UF</label>
							</div>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="alinhamento">
						<div class="campos">
							<div class="input-group grow">
								<input v-model="EmpresaCadastrada.endereco" autocomplete="off" id="endereco" type="text"
								@keydown.enter="proximoCampo('numero')" maxlength="40" placeholder=" "/>
								<label class="mx-5 w-auto" for="endereco">Endereço</label>
							</div>
						</div>
						<div class="campos">
							<div class="input-group grow">
								<input v-model="EmpresaCadastrada.numero" autocomplete="off" id="numero" type="text"
								@keydown.enter="proximoCampo('bairro')" @input="mascaraInteger" maxlength="8" placeholder=" "/>
								<label class="mx-5 w-auto" for="numero">Número</label>
							</div>
						</div>
					</div>
					<div class="alinhamento">
						<div class="campos">
							<div class="input-group grow">
								<input v-model="EmpresaCadastrada.bairro" autocomplete="off" id="bairro" type="text"
								@keydown.enter="proximoCampo('complemento')" maxlength="30" placeholder=" "/>
								<label class="mx-5 w-auto" for="bairro">Bairro</label>
							</div>
						</div>
						<div class="campos">
							<div class="input-group grow">
								<input v-model="EmpresaCadastrada.complemento" autocomplete="off" id="complemento" type="text"
								@keydown.enter="proximoCampo('fone')" maxlength="60" placeholder=" "/>
								<label class="mx-5 w-auto" for="complemento">Complemento</label>
							</div>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="campos">
						<div class="input-group grow">
							<input v-model="EmpresaCadastrada.fone" autocomplete="off" id="fone" type="text"
							@input="mascara" @keydown.enter="proximoCampo('fax')" maxlength="14" placeholder=" "/>
							<label class="mx-5 w-auto" for="fone">Telefone 1</label>
						</div>
					</div>
					<div class="campos">
						<div class="input-group grow">
							<input v-model="EmpresaCadastrada.fax" autocomplete="off" id="fax" type="text"
							@input="mascara" @keydown.enter="proximoCampo('celular')" maxlength="14" placeholder=" "/>
							<label class="mx-5 w-auto" for="fax">Telefone 2</label>
						</div>
					</div>
					<div class="campos">
						<div class="input-group grow">
							<input v-model="EmpresaCadastrada.celular" autocomplete="off" id="celular" type="text"
							@input="mascara" @keydown.enter="proximoCampo('email')" maxlength="14" placeholder=" "/>
							<label class="mx-5 w-auto" for="celular">Celular</label>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="campos">
						<div class="input-group grow">
							<input v-model="EmpresaCadastrada.email" autocomplete="off" id="email" type="text"
							@keydown.enter="proximoCampo('site')" maxlength="30" style="text-transform: lowercase;" placeholder=" "/>
							<label class="mx-5 w-auto" for="email">E-mail</label>
						</div>
					</div>
					<div class="campos">
						<div class="input-group grow">
							<input v-model="EmpresaCadastrada.home" autocomplete="off" id="site" type="text"
							@keydown.enter="proximoCampo('logo')" maxlength="50" style="text-transform: lowercase;" placeholder=" "/>
							<label class="mx-5 w-auto" for="site">Site</label>
						</div>
					</div>
					<!-- <div class="campos">
						<div class="inputBox">
							<label class="mx-5 w-auto" for="logo">Logo da Empresa:</label>
							<input autocomplete="off" id="logo" type="file"/>
						</div>
					</div> -->
				</div>
			</div>
			<div class="botoes-container">
				<button @click="cancelarEdicao" class="botao botao-cancelar">
					<i class="fas fa-times"></i>
					Cancelar
				</button>
				<button @click="salvarEmpresaCadastrada" class="botao botao-adicionar">
					<i class="fa fa-check"></i>
					Salvar
				</button>
			</div>
		<form @submit.prevent="" @keydown.enter.prevent="">
		</form>
		<Modal v-if="listarCidades" v-on:fecharModal="listarCidades = false">
			<ConsultaCidades
				@selecionarCidade="selecionarCidade"
			/>
			<!-- <Paginacao tipoConteudo="Cidades" :desabilitarNovo="true">
				<TabelaCidade v-on:selecionarCidade="selecionarCidade" />
			</Paginacao> -->
		</Modal>
	</div>
</template>
<script>
import Modal from "./Modal.vue";
// import Paginacao from "./Paginacao.vue";
// import TabelaCidade from "./TabelaCidade.vue";
import ConsultaCidades from "./ConsultaCidades.vue";

class EmpresaDados {
	constructor(empDados) {
		this.id = empDados.id;
		this.dataCadastro = empDados.dataCadastro;
		this.ativo = empDados.ativo;
		this.nome = empDados.nome;
		this.fantasia = empDados.fantasia;
		this.responsavel = empDados.responsavel;
		this.cnpj = empDados.cnpj;
		this.ie = empDados.ie;
		this.cep = empDados.cep;
		this.cdCidade = empDados.cdCidade;
		this.ufCidade = empDados.ufCidade;
		this.endereco = empDados.endereco;
		this.numero = empDados.numero;
		this.bairro = empDados.bairro;
		this.complemento = empDados.complemento;
		this.fone = empDados.fone;
		this.fax = empDados.fax;
		this.celular = empDados.celular;
		this.email = empDados.email;
		this.home = empDados.home;
		this.nomeCidade = empDados.nomeCidade;
		this.logo = empDados.logo;
	}
}
export default {
	name: "EmpresaDados",
	props: {
		Empresa: Object,
	},
	data() {
		return {
			listarCidades: false,
			timerFiltro: null,
			naovalida: false,
			EmpresaCadastrada: {
				id: "",
				dataCadastro: "",
				ativo: "A",
				nome: "",
				fantasia: "",
				responsavel: "",
				cnpj: "",
				ie: "",
				cep: "",
				cdCidade: "",
				ufCidade: "",
				nomeCidade: "",
				endereco: "",
				numero: "",
				bairro: "",
				complemento: "",
				fone: "",
				fax: "",
				celular: "",
				email: "",
				home: "",
				logo: "",
			},
		};
	},
	components: {
		Modal, 
		// Paginacao, 
		// TabelaCidade,
		ConsultaCidades,
	},
	computed: {
		empresadados() {
			return this.$store.state.empresadados;
		},
		Empresas() {
			return this.$store.state.Empresa;
		},
	},
	methods: {
		selecionarCidade(cidade) {
			this.EmpresaCadastrada.cdCidade = cidade.cdcidade;
			this.EmpresaCadastrada.ufCidade = cidade.uf;
			this.EmpresaCadastrada.nomeCidade = cidade.nomecidade;

			this.listarCidades = false;
		},
		solicitaListarCidades() {
			this.listarCidades = true;
		},
		verificaCNPJ(cnpj) {
			cnpj = cnpj.replace(/[^\d]+/g,"");
			if(cnpj == "") return false;

			if (cnpj.length != 14)
				return false;

			if (cnpj == "00000000000000" ||
				cnpj == "11111111111111" ||
				cnpj == "22222222222222" ||
				cnpj == "33333333333333" ||
				cnpj == "44444444444444" ||
				cnpj == "55555555555555" ||
				cnpj == "66666666666666" ||
				cnpj == "77777777777777" ||
				cnpj == "88888888888888" ||
				cnpj == "99999999999999")
				return false;

			let tamanho = cnpj.length - 2;
			let numeros = cnpj.substring(0,tamanho);
			let digitos = cnpj.substring(tamanho);
			let soma = 0;
			let pos = tamanho - 7;
			let i;
			for (i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
					pos = 9;
			}
			let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(0))
				return false;

			tamanho = tamanho + 1;
			numeros = cnpj.substring(0,tamanho);
			soma = 0;
			pos = tamanho - 7;
			for (i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
					pos = 9;
			}
			resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(1)) return false;
			return true;
		},
		async validarCnpj(){
			clearTimeout(this.timerFiltro);
			this.timerFiltro = setTimeout(async() =>{
				const inputCnpj = document.getElementById("cnpjBorder");
				const cnpj = await this.$store.dispatch("validarCnpjEmpresa", { cnpj: this.EmpresaCadastrada.cnpj});
				if(!this.EmpresaCadastrada.id){
					if(!cnpj?.mensagem){
						inputCnpj.style.border = "3px solid #f00";
						this.naovalida = true;	
						return;
					}
				}
				if(!this.verificaCNPJ(this.EmpresaCadastrada.cnpj)){
					inputCnpj.style.border = "3px solid #f00";
					this.naovalida = true;
				} else {
					inputCnpj.style.border = "3px solid var(--border)";
					this.naovalida = false;
				}
				this.naovalida = false;
			}, 230);
		},
		proximoCampo(idCampo) {
			setTimeout(() => {
				const campo = document.getElementById(idCampo);
				if (campo?.focus) {
					campo.focus();
					if (campo.select) {
						campo.select();
					}
				}
			}, 10);
		},
		async pegarCidade() {
			this.$store.state.carregando = true;
			const cidade = await this.$store.dispatch("buscarCidadeViaCep", {cep: this.EmpresaCadastrada.cep});
			this.$store.state.carregando = false;
			if (!cidade) {
				this.EmpresaCadastrada.cdCidade = null;
				this.EmpresaCadastrada.nomeCidade = "";
				this.EmpresaCadastrada.ufCidade = "";
				return;
			}
			if (cidade.cdcidade) {
				this.EmpresaCadastrada.cdCidade = cidade.cdcidade;
				this.EmpresaCadastrada.nomeCidade = cidade.nomecidade;
				this.EmpresaCadastrada.ufCidade = cidade.uf;
				if (cidade.logradouro && !this.EmpresaCadastrada.endereco) {
					this.EmpresaCadastrada.endereco = `${cidade.logradouro}`.toUpperCase();
				}
				if (cidade.bairro && !this.EmpresaCadastrada.bairro) {
					this.EmpresaCadastrada.bairro = `${cidade.bairro}`.toUpperCase();
				}
			}
		},
		mascara(e) {
			const id = e.target.id;
			const texto = e.target.value;
			if (id == "cnpj") {
				const regex = /[0-9]/;
				if(!e.data) return;
				if (!regex.test(e.data) && e.data != null) {
					this.EmpresaCadastrada[id] = this.EmpresaCadastrada[id].substring(0,texto.length-1);
				}
				if (texto.length == 2 || texto.length == 6) {
					this.EmpresaCadastrada[id] += ".";

				}
				if(texto.length == 3){
					if(texto[2] != "."){
						this.EmpresaCadastrada[id] = this.EmpresaCadastrada[id].slice(0, 2) + "." + e.data;
					}
				}
				if(texto.length == 7){
					if(texto[6] != "."){
						this.EmpresaCadastrada[id] = this.EmpresaCadastrada[id].slice(0, 6) + "." + e.data;

					}
				}

				if (texto.length == 10) e.target.value += "/";
				if (texto.length == 11) {
					if (texto.length-1 == "/"){
						return;
					}
					this.EmpresaCadastrada[id] = this.EmpresaCadastrada[id].slice(0, 10);
					this.EmpresaCadastrada[id] += "/";
				}
				if (texto.length == 15) {
					this.EmpresaCadastrada[id] += "-";
				}
				if (texto.length == 16) {
					if(texto[15] != "-"){
						this.EmpresaCadastrada[id] = this.EmpresaCadastrada[id].slice(0, 15) + "-" + e.data;
					}
				}

			} else if (id == "cep") {
				const regex = /[0-9]/;
				if(!e.data) return;
				if(!regex.test(e.data)){
					this.EmpresaCadastrada[id] = this.EmpresaCadastrada[id].substring(0,texto.length-1);
					return;
				}
				if (texto.length == 5) {
					if (texto.length-1 == "-"){
						return;
					}
					this.EmpresaCadastrada[id] += "-";
				}
				if (texto.length == 6 && e.data != "-") {
					this.EmpresaCadastrada[id] = this.EmpresaCadastrada[id].slice(0, 5) + "-" + e.data;
				}
				if (!this.EmpresaCadastrada.cidade && this.EmpresaCadastrada[id].length == 9) {
					clearTimeout(this.timerFiltro);
					this.timerFiltro = setTimeout(() => {
						this.pegarCidade({cancelado: false});
					}, 500);
				}
			} else if (["fone", "fax", "celular"].includes(id)) {
				const regex = /[()0-9]/;
				regex.lastIndex = 0;
				if(!e.data) return;
				if (!regex.test(e.data)) {
					this.EmpresaCadastrada[id] = this.EmpresaCadastrada[id].substring(0,texto.length-1);
					return;
				}
				if (e.data === null){
					if (texto.length == 13 && texto[8] != "-") {
						this.EmpresaCadastrada[id] = `${this.EmpresaCadastrada[id].slice(0, 8) + "-" + texto[10] + texto.slice(10) }`;
					}
					return;
				}
				if (texto.length == 1) {
					this.EmpresaCadastrada[id] = "(" + e.data;
				}
				if (texto.length == 3) {
					this.EmpresaCadastrada[id] += ")";
				}
				if (texto.length == 4 && texto.length -1 != ")") {
					this.EmpresaCadastrada[id] = this.EmpresaCadastrada[id].slice(0, 3) + ")" + e.data;
				}
				if (texto.length == 8) {
					if (texto.length-1 == "-"){
						return;
					}
					this.EmpresaCadastrada[id] += "-";
				}
				if (texto.length == 10 && e.data != "-") {
					if(texto[8] != "-"){
						this.EmpresaCadastrada[id] = this.EmpresaCadastrada[id].slice(0, 8) + "-" + e.data;
					}
				}
				if (texto.length == 14 && texto[8] == "-") {
					this.EmpresaCadastrada[id] = `${this.EmpresaCadastrada[id].slice(0, 8)+ texto[9] + "-" + texto.slice(10)}`;
				}
			}
		},
		mudarAtivo(campo, letra1, letra2) {
			if (this.EmpresaCadastrada[campo] == letra1) {
				this.EmpresaCadastrada[campo] = letra2;
			} else {
				this.EmpresaCadastrada[campo] = letra1;
			}
		},
		mascaraInteger(e) {
			const regex = /[0-9]/;
			if (!regex.test(e.data) && e.data != null) {
				this.EmpresaCadastrada[e.target.id] = this.EmpresaCadastrada[e.target.id].substring(0, this.EmpresaCadastrada[e.target.id].length - 1);
			}
		},
		validarCampos(){
			try {
				if (!this.verificaCNPJ(this.EmpresaCadastrada.cnpj)) {
					throw new Error("CNPJ não passou no cálculo do digito verificador");
				}
				if(this.naovalida){
					throw new Error("Já existe uma empresa cadastrada com esse CNPJ");
				}
				if (!this.EmpresaCadastrada.nome) {
					throw new Error("Campo Razão Social não pode estar vazio");
				}
				const regexCaracteresEspeciais = /[~^!@#$%¨^*()_={}+?;<>'´`|ªº°"/\\]/;
				if (regexCaracteresEspeciais.test(this.EmpresaCadastrada.nome)) {
					throw new Error("Campo Razão Social contém caracteres inválidos, verifique!");
				}
				if (!this.EmpresaCadastrada.fantasia) {
					throw new Error("Campo Fantasia não pode estar vazio");
				}
				if (regexCaracteresEspeciais.test(this.EmpresaCadastrada.fantasia)) {
					throw new Error("Campo Fantasia contém caracteres inválidos, verifique!");
				}
				if (!this.EmpresaCadastrada.responsavel) {
					throw new Error("Campo Responsável não pode estar vazio");
				}
				if (!this.EmpresaCadastrada.cnpj) {
					throw new Error("Campo CNPJ não pode estar vazio");
				}
				if (!this.EmpresaCadastrada.ie) {
					throw new Error("Campo Inscrição Estadual não pode estar vazio");
				}
				if (!this.EmpresaCadastrada.cep) {
					throw new Error("Campo CEP não pode estar vazio");
				}
				if (!this.EmpresaCadastrada.cdCidade) {
					throw new Error("Campo Cidade não pode estar vazio");
				}
				if (!this.EmpresaCadastrada.ufCidade) {
					throw new Error("Campo UF não pode estar vazio");
				}
				if (!this.EmpresaCadastrada.endereco) {
					throw new Error("Campo Endereço não pode estar vazio");
				}
				if (!this.EmpresaCadastrada.numero) {
					throw new Error("Campo Número não pode estar vazio");
				}
				if (!this.EmpresaCadastrada.bairro) {
					throw new Error("Campo Bairro não pode estar vazio");
				}
				if (!this.EmpresaCadastrada.fone) {
					throw new Error("Campo Telefone 1 não pode estar vazio");
				}
				if (!this.EmpresaCadastrada.celular) {
					throw new Error("Campo Celular não pode estar vazio");
				}
				return true;
			} catch (error){
				this.$store.commit("relatarErro", {mensagem: error.message});
				return false;
			}
		},
		async salvarEmpresaCadastrada() {
			if(!this.validarCampos()) return;
			this.$emit("salvarDados", {
				...this.EmpresaCadastrada,
				tela: {
					index: 0,
					mostra: "config",
					salvarEmpresa: true,
				}
			});
		},
		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},
		
	},
	mounted() {

		this.$bus.$on("executarAcao", this.salvarEmpresaCadastrada);
		if (this.Empresa) {
			if (this.Empresa.novo) return;
			this.EmpresaCadastrada = new EmpresaDados(this.Empresa);
		}
	},
	destroyed(){
		this.$bus.$off("executarAcao", this.salvarEmpresaCadastrada);
	}
};
</script>
<style scoped>
.input-group label {
	height: 12px;
}

.botao-adicionar i {
	color: var(--adicionar-borda);
}

.botao-cancelar i {
	color: var(--cancelar-borda);
}

.grow {
	flex-grow: 1;
}

.EmpresaDados {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.box-cadastro {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: auto;
	margin-bottom: 0px;
	padding: 5px;
	border: 0;
	gap: 3px;
}

.box-cadastro .box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	gap: 3px;
}
.box-cadastro .box .campos {
	display: flex;
    padding: .2rem 0;
}
.box-cadastro .box .campos .inputBox {
	display: flex;
    width: 100%;
}
.box-cadastro .box .campos .inputBox label {
    white-space: nowrap;
}
.box-cadastro .box .campos .inputBox input {
    text-transform: uppercase;
    width: 100%;
}
.botoes-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 5px 0px;
}

.botoes-container button {
	height: auto;
	flex-grow: 1;
	margin: 5px;
}
@media screen and (min-width: 1000px) {
	.box-cadastro .box {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
		width: 100%;
	}
	.box-cadastro .box .alinhamento {
		display: flex;
		flex-direction: row;
		width: 100%;
	}
	.box-cadastro .box:nth-child(1) .campos:nth-child(1) {
		width: 280px;
	}
	.box-cadastro .box:nth-child(1) .campos:nth-child(2) {
		width: 240px;
	}
	.box-cadastro .box:nth-child(1) .campos:nth-child(3) {
		width: 200px;
	}
	.box-cadastro .box:nth-child(4) .alinhamento .campos:nth-child(1) {
		width: 180px;
	}
	.box-cadastro .box:nth-child(4) .alinhamento .campos:nth-child(3) {
		width: 90px;
	}
	.box-cadastro .box:nth-child(5) .alinhamento:nth-child(1) .campos:nth-child(2) {
		width: 200px;
	}
	.box-cadastro .box:nth-child(5) {
		display: flex;
		flex-direction: column;
	}
	.box-cadastro .box:nth-child(7) {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	}
	.box-cadastro .box .campos {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
	}
	.box-cadastro .box .campos .inputBox {
		display: flex;
		width: 100%;
	}
	.box-cadastro .box .campos .inputBox input {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.alinhamento {
		display: flex;
		flex-direction: row;
	}

	#ativo {
		align-items: center;
	}
}

@media screen and (max-width: 500px) {
	.titulo {
		font-size: 8pt;
	}
    .botoes-container span {
        display: none;
    }
}

@media screen and (max-width: 440px) {
	.alinhamento {
		flex-wrap: wrap;
		gap: 3px;
	}

	.alinhamento .campos {
		flex-grow: 1;
	}

}
</style>
