<template>
	<div class="cadastro-produto-aba">
		<div class="container-page">

			<div class="container-form">
				<div class="input-group small item1">
					<input type="date" name="" id="datacadastro" v-model="produtoCadastrado.datacadastro" />
					<label for="datacadastro">Data</label>
				</div>
				<div class="input-group item2">
					<input type="date" name="" id="dataAlteracao" v-model="produtoCadastrado.dataAlteracao" placeholder=" " />
					<label for="dataAlteracao" class="lg-view">Data últ. alteração</label>
					<label for="dataAlteracao" class="sm-view">Data últ. alt.</label>
				</div>
				<div class="input-group big item3">
					<i 
						class="fas fa-times" 
						style="color: red" 
						id="removerBotao" 
						v-if="this.produtoCadastrado.grupoSelecionado.cdGrupo && !produtoCadastrado.cdproduto"
						@click="removerGrupo"
						
					/>
					<i 
						class="fas fa-pen-square" 
						style="color: var(--primario)" 
						id="editarBotao" 
						v-if="this.produtoCadastrado.grupoSelecionado.cdGrupo"
						@click="cadastrarGrupoProduto = true"
					/>
					<i 
						class="fas fa-plus-square"
						id="adicionarBotao"
						style="color: var(--primario)"
						@click="cadastrarGrupoProduto = true"
						v-else
					/>
					<input 
						type="text"  
						id="buscaGrupo"  
						v-model="textoBuscaGrupo" 
						placeholder=" " 
						@input="buscarGrupo" 
						@focus="listaGrupoFoco"
						autocomplete="off"
						@keydown.enter="proximoCampo('subgrupo')"
						v-if="!this.produtoCadastrado.grupoSelecionado.cdGrupo"
						style="padding-right:20%;"
					/>
					<input 
						type="text" 
						id="buscaGrupo"  
						class="resize-input-editar"
						:value="`${this.produtoCadastrado.grupoSelecionado.cdGrupo} - ${this.produtoCadastrado.grupoSelecionado.descricao}`" 
						readonly
						placeholder=" "
						v-else
					/>
					<label for="buscaGrupo">Grupo</label>
					<ListaBuscas 
						v-show="listaGrupo"
						ref="listaGrupo" 
						:Lista="gruposProdutos"
						:tipoLista="listaGrupo ? 'Grupos' : 'Subgrupos'"
						@selecionarItem="selecionarGrupo"
					/>
				</div>
				<div class="input-group big item4">
					<i 
						class="fas fa-times" 
						style="color: red; left: 92%" 
						id="removerBotao" 
						v-if="produtoCadastrado.subGrupoSelecionado && !produtoCadastrado.subGrupoSelecionado.nenhumSelecionado"
						@click="removerSubgrupoSelecionado"
						
					/>
					<input 
						type="text" 
						id="subgrupo" 
						autocomplete="off"
						@keydown.enter="proximoCampo('fornecedor')"
						@focus="listaSubGrupoFoco"
						:readonly="!produtoCadastrado.grupoSelecionado.cdGrupo || produtoCadastrado.grupoSelecionado.subGrupos.length == 0 || produtoCadastrado.subGrupoSelecionado.nenhumSelecionado"
						:style="!produtoCadastrado.grupoSelecionado.cdGrupo ? 'color: red' : ''"
						:value="!produtoCadastrado.grupoSelecionado.cdGrupo ? 'Selecione o grupo primeiro' : produtoCadastrado.grupoSelecionado.subGrupos.length == 0 ? 'O GRUPO NÃO TEM SUBGRUPOS' : ''"
						v-if="!produtoCadastrado.subGrupoSelecionado && !produtoCadastrado.subGrupoSelecionado.nenhumSelecionado"
					/>
					<input
						type="text"
						id="subgrupo"
						autocomplete="off"
						readonly
						@focus="listaSubGrupoFoco"
						:value="montarMensagemInputSubGrupo()"	
						v-else
					/>
					<label for="subgrupo">Sub Grupo</label>
					<ListaBuscas 
						v-show="listaSubGrupo" 
						ref="listaSubGrupo"
						:Lista="produtoCadastrado.grupoSelecionado.subGrupos ? produtoCadastrado.grupoSelecionado.subGrupos : []"
						:tipoLista="'Subgrupos'"
						@selecionarElemento="selecionarSubGrupo"
					/>
				</div>
				<div class="input-group big item5">
					<input 
						type="text"
						id="fornecedor" 
						placeholder=" "  
						@click="listarFornecedor = true"
						@keydown.enter="proximoCampo('cdReferencia')"
						:value="produtoCadastrado.fornecedor ? produtoCadastrado.fornecedor.nome : ''"	
					/>
					<label for="fornecedor">Fornecedor</label>
				</div>
				<div class="input-group item6">
					<input 
						type="text" 
						id="cdReferencia" 
						placeholder=" "
						@keydown.enter="proximoCampo('codBarra')"
						autocomplete="off"
						v-model="produtoCadastrado.cdReferencia"
					/>
					<label for="cdReferencia">Referência</label>
				</div>
				<div class="input-group item7">
					<input 
						type="text" 
						id="cdbarrafornecedor" 
						placeholder=" " 
						:value="produtoCadastrado.cdbarraFornecedor || ''"
						readonly
					/>
					<label for="cdbarrafornecedor" class="lg-view">Cód. Barras Fornecedor</label>
					<label for="cdbarrafornecedor" class="sm-view">Cód. Barras Forn.</label>
				</div>
				<div class="input-group item8">
					<input 
						type="text" 
						id="codBarra" 
						placeholder=" " 
						v-model="produtoCadastrado.codBarra"
						v-mascaraFloat
						@keydown.enter="proximoCampo('descricao')"
						autocomplete="off"
						maxlength="12"
					/>
					<label for="codBarra">
						Cód. Barras
					</label>
				</div>
				<div class="input-group item9">
					<i
						class="fas fa-clone"
						style="color: var(--primario);"
						id="botaoDuplicar"
						@click="duplicarProduto"
						v-if="produtoCadastrado.cdproduto && !PropProduto.cdagrupamento && !PropProduto.pai"
					></i>
					<input 
						type="text" 
						id="cdproduto" 
						placeholder=" " 
						autocomplete="off"
						v-model="produtoCadastrado.cdproduto"
						v-mascaraFloat
						maxlength="20"
					/>
					<label for="cdproduto">Cód. Produto</label>
				</div>
				<div class="input-group item10">
					<input 
						type="text"
						id="descricao" 
						placeholder=" " 
						v-model="produtoCadastrado.descricao"
						@keydown.enter="proximoCampo('descresumido')"
						autocomplete="off"
						maxlength="200"
					/>
					<label for="descricao">Descrição</label>
				</div>
				<div class="input-group item11">
					<input 
						type="text"
						id="descresumido" 
						placeholder=" " 
						autocomplete="off"
						@keydown.enter="proximoCampo('embalagem')"
						v-model="produtoCadastrado.descresumido"
					/>
					<label for="descresumido">Descrição Resumida</label>
				</div>
				<div class="input-group item12">
					<input 
						type="text" 
						name="" 
						id="embalagem" 
						@keydown.enter="proximoCampo('qteEmbalagem')"
						placeholder=" " 
						v-model="produtoCadastrado.embalagem"
						maxlength="3"	
					/>
					<label for="embalagem">Embalagem</label>
				</div>
				<div class="input-group item13">
					<input 
						type="text" 
						id="qteEmbalagem" 
						placeholder=" " 
						@keydown.enter="proximoCampo('unidade')"
						autocomplete="off"
						v-model="produtoCadastrado.qteEmbalagem"
						v-mascaraFloat
					/>
					<label for="qteEmbalagem">Qtd. Embalagem</label>
				</div>
				<div class="input-group item14">
					<input 
						type="text" 
						id="unidade" 
						placeholder=" " 
						autocomplete="off"
						@keydown.enter="proximoCampo('multiplo')"
						maxlength="3"
						v-model="produtoCadastrado.unidade"	
					/>
					<label for="unidade">Unidade</label>
				</div>
				<div class="input-group item15">
					<input 
						type="text" 
						id="multiplo" 
						placeholder=" " 
						@keydown.enter="proximoCampo('pesoBruto')"
						autocomplete="off"
						v-model="produtoCadastrado.multiplo"
						v-mascaraFloat
					/>
					<label for="multiplo">Múltiplo</label>
				</div>
				<div class="input-group item16">
					<input 
						type="text"
						id="pesoBruto" 
						placeholder=" "
						autocomplete="off" 
						v-model="produtoCadastrado.pesoBruto"
						@keydown.enter="proximoCampo('dataUltCompra')"
						v-mascaraFloat
					/>
					<label for="pesoBruto">Peso Bruto</label>
				</div>
				<div class="input-group">
					<input 
						type="date"
						id="dataUltCompra" 
						placeholder=" "
						readonly
						autocomplete="off" 
						v-model="produtoCadastrado.dataUltCompra"
						@keydown.enter="proximoCampo('dataUltVenda')"
					/>
					<label for="dataUltCompra">Data ult. Compra</label>
				</div>
				<div class="input-group">
					<input 
						type="date"
						id="dataUltVenda" 
						placeholder=" "
						readonly
						autocomplete="off" 
						v-model="produtoCadastrado.dataUltVenda"
						@keydown.enter="proximoCampo('pesoLiquido')"
					/>
					<label for="dataUltVenda">Data ult. Venda</label>
				</div>
				<div class="input-group item17">
					<input 
						type="text"
						id="pesoLiquido" 
						placeholder=" " 
						autocomplete="off" 
						@keydown.enter="proximoCampo('estoqueMaximo')"
						v-model="produtoCadastrado.pesoLiquido"
						v-mascaraFloat
					/>
					<label for="pesoLiquido">Peso Líquido</label>
				</div>
				<div class="input-group item18">
					<input 
						type="text" 
						id="estoqueMaximo"
						autocomplete="off" 
						placeholder=" "
						@keydown.enter="proximoCampo('estoqueMinimo')"
						v-model="produtoCadastrado.estoqueMaximo" 
						v-mascaraFloat
					/>
					<label for="estoqueMaximo">Estoque Máximo</label>
				</div>
				<div class="input-group item19">
					<input 
						type="text" 
						id="estoqueMinimo" 
						placeholder=" " 
						autocomplete="off"
						v-model="produtoCadastrado.estoqueMinimo"
						v-mascaraFloat
					/>
					<label for="estoqueMinimo">Estoque Mínimo</label>
				</div>
				<div class="agroup">
					<div class="input-group-switch card-agroup">
						<label class="label-switch">Comissão</label>
						<label class="container">
							<input
								type="checkbox"
								v-model="produtoCadastrado.geraComissao"
								class="esconder-input"
								@change="mudarCheckbox('geraComissao', 'S', 'N')"
								id="esconder-input"
							/>
							<span class="thumb-container">
								<span class="thumb"></span>
							</span>
								<span class="track"></span>
						</label>
					</div>
					<div class="input-group-switch card-agroup">
						<label class="label-switch">Ativo</label>
						<label class="container">
							<input
								type="checkbox"
								v-model="produtoCadastrado.ativo"
								@change="mudarCheckbox('ativo', 'A', 'I')"
								class="esconder-input"
								id="esconder-input"
							/>
							<span class="thumb-container">
								<span class="thumb"></span>
							</span>
								<span class="track"></span>
						</label>
					</div>
					<div class="input-group-switch card-agroup">
						<label class="label-switch" v-if="produtoCadastrado.servprod">Produto</label>
						<label class="label-switch" v-else>Serviço</label>
						<label class="container">
							<input
								type="checkbox"
								v-model="produtoCadastrado.servprod"
								class="esconder-input"
								@change="mudarCheckbox('servprod', 'P', 'S')"
								id="esconder-input"
							/>
							<span class="thumb-container">
								<span class="thumb"></span>
							</span>
								<span class="track"></span>
						</label>
					</div>
				</div>
			</div>
		</div>
		<div class="botoes">
			<button class="botao botao-cancelar" @click="cancelarEdicao">
				<span class="lg-view">
					Cancelar
				</span>
				<span class="sm-view">
					Cancelar
				</span>
			</button>
			<button @click="solicitarCodigoBarra" class="botao botao-acao">
				<i class="fas fa-barcode" style="color:var(--acao-texto)"></i>
				<span class="lg-view">
					Cod. Barra [Alt + B]
				</span>
				<span class="sm-view">Cod. Barra</span>
			</button>
			<button class="botao botao-adicionar" @click="adicionarProduto">
				<span class="lg-view">
					Salvar [Alt + S]
				</span>
				<span class="sm-view">
					Salvar
				</span>
			</button>
		</div>
		<!-- <div class="conteudo-cadastro">
			<div class="linha-cadastro-produto">
				<div class="linha-cadastro-item flex-grow-1">
					<label for="dataCadastro">Data: &nbsp;</label>
					<input type="date" v-model="produtoCadastrado.datacadastro" id="dataCadastro" style="width: 230px" readonly/>
					&nbsp;
					<label for="dataCadastro">Data última alteração: &nbsp;</label>
					<input type="date" v-model="produtoCadastrado.dataAlteracao" id="dataCadastro" style="width: 230px" readonly/>
					&nbsp;
					<label for="buscaGrupo">Grupo:&nbsp;</label>
					<input type="text" id="buscaGrupo" class="input-select" list="grupos" @input="buscarGrupo" 
					v-model="textoBuscaGrupo" placeholder="Pesquisar grupos" @keydown.enter="proximoCampo('subgrupo')">
					<datalist name="grupos" id="grupos">
						<option :value="`${grupo.cdGrupo} ${grupo.descricao}`" v-for="grupo in grupoProduto" :key="grupo.cdgrupo"></option>
					</datalist>
					<i v-if="produtoCadastrado.grupoSelecionado.novo" class="fas fa-plus-square" @click="cadastrarGrupoProduto = true"></i>
					<i v-else class="fas fa-pen-square" @click="cadastrarGrupoProduto = true"></i>
				</div>
				<div class="linha-cadastro-item flex-grow-1">
					<label for="subgrupo">Sub Grupo:&nbsp;</label>
					<select id="subgrupo" v-model="produtoCadastrado.cdsubgrupo" @keydown.enter="proximoCampo('fornecedor')" class="input-select">
						<option value="" >{{produtoCadastrado.grupoSelecionado.novo ? "Selecione o grupo primeiro" : "Selecionar o subgrupo"}}</option>
						<option :value="subgrupo" v-for="subgrupo in produtoCadastrado.grupoSelecionado.subGrupos" :key="subgrupo.cdsubgrupo">
							{{subgrupo.descricao}}
						</option>
					</select>  
				</div>
			</div>
			<div class="linha-cadastro-produto">
				<div class="linha-cadastro-item flex-grow-1">
					<label for="fornecedor">Fornecedor:&nbsp;</label>
					<input type="text"  placeholder="Selecione" readonly id="fornecedor" autocomplete="off" class="w-100"
					@click="listarClientes = true" :value="produtoCadastrado.fornecedor ? produtoCadastrado.fornecedor.nome : ''" 
					@keydown.enter="proximoCampo('cdReferencia')" :disabled="Fornecedor">
				</div>
				<div class="linha-cadastro-item flex-grow-1">
					<label for="cdReferencia">Referência:&nbsp;</label>
					<input
						autocomplete="off"
						id="cdReferencia"
						type="text" 
						v-model="produtoCadastrado.cdReferencia"
						maxlength="30"
						class="w-100"
						@keydown.enter="proximoCampo('cdproduto')"
					>
				</div>
			</div>

			//QUANDO ESTIVER FINALIZADO TUDO
			<div class="linha-cadastro-produto">
				<div class="linha-cadastro-item">
					<i
						class="fas fa-clone"
						style="color: var(--primario);"
						@click="duplicarProduto"
						v-if="produtoCadastrado.cdproduto && !PropProduto.cdagrupamento && !PropProduto.pai"
					></i>
					<label for="cdproduto">Cd. Produto:&nbsp;</label>
					<input @input="setOnlyNumbers" autocomplete="off" id="cdproduto" type="text" v-model="produtoCadastrado.cdproduto" maxlength="20"
					@keydown.enter="proximoCampo('descricao')">
				</div>
				<div class="linha-cadastro-item flex-grow-1">
					<label for="descricao">Descrição:&nbsp;</label>
					<input @input="setUpperCase" autocomplete="off" id="descricao" type="text" v-model="produtoCadastrado.descricao" maxlength="100"
					@keydown.enter="proximoCampo('codBarra')">
				</div>
				<div class="linha-cadastro-item">
					<label for="codBarra">Cod. Barras:&nbsp;</label>
					<input @input="setOnlyNumbers" autocomplete="off" id="codBarra" type="text" v-model="produtoCadastrado.codBarra" maxlength="12"
					@keydown.enter="proximoCampo('descresumido')">
				</div>
				<div class="linha-cadastro-item">
					<label for="cdbarraFornecedor">Cód. Barra Fornecedor:&nbsp;</label>
					<input type="text" id="cdbarraFornecedor" :value="produtoCadastrado.codbarraFornecedor || ''" style="text-align: right;"/>
				</div>
			</div>
			<div class="linha-cadastro-produto wrap">
				<div class="linha-cadastro-item flex-grow-1">
					<label for="descresumido">Descrição resumida:&nbsp;</label>
					<input id="descresumido" type="text" v-model="produtoCadastrado.descresumido" style="text-transform: uppercase;"
					@keydown.enter="proximoCampo('embalagem')" autocomplete="off" maxlength="60">
				</div>
				<div class="linha-cadastro-item">
					<label for="embalagem">Embalagem:&nbsp;</label>
					<input @input="setUpperCase" autocomplete="off" maxlength="3" id="embalagem" type="text" v-model="produtoCadastrado.embalagem"
					class="input-pequeno" @keydown.enter="proximoCampo('qteEmbalagem')">
				</div>
				<div class="linha-cadastro-item">
					<label for="qteEmbalagem">Qtd. Embalagem:&nbsp;</label>
					<input @input="setOnlyNumbers" autocomplete="off" id="qteEmbalagem" type="text" v-model="produtoCadastrado.qteEmbalagem"
					class="input-pequeno" @keydown.enter="proximoCampo('unidade')">
				</div>
				<div class="linha-cadastro-item">
					<label for="unidade">Unidade:&nbsp;</label>
					<input @input="setUpperCase" autocomplete="off" maxlength="3" id="unidade" type="text" v-model="produtoCadastrado.unidade"
					class="input-pequeno" @keydown.enter="proximoCampo('multiplo')">
				</div>
				<div class="linha-cadastro-item">
					<label for="multiplo">Múltiplo:&nbsp;</label>
					<input @input="setOnlyNumbers" autocomplete="off" id="multiplo" type="text" v-model="produtoCadastrado.multiplo"
					class="input-pequeno" @keydown.enter="proximoCampo('pesoBruto')">
				</div>
				<div class="linha-cadastro-item">
					<label for="pesoBruto">Peso Bruto:&nbsp;</label>
					<input @input="setOnlyNumbers" autocomplete="off" id="pesoBruto" type="text" v-model="produtoCadastrado.pesoBruto"
					class="input-pequeno" @keydown.enter="proximoCampo('pesoLiquido')">
				</div>
				<div class="linha-cadastro-item">
					<label for="pesoLiquido">Peso Líquido:&nbsp;</label>
					<input @input="setOnlyNumbers" autocomplete="off" id="pesoLiquido" type="text" v-model="produtoCadastrado.pesoLiquido"
					class="input-pequeno" @keydown.enter="proximoCampo('estoqueMaximo')">
				</div>
				<div class="linha-cadastro-item">
					<label for="estoqueMaximo">Estoque Maximo:&nbsp;</label>
					<input @input="setOnlyNumbers" autocomplete="off" id="estoqueMaximo" type="text" v-model="produtoCadastrado.estoqueMaximo"
					class="input-pequeno" @keydown.enter="proximoCampo('estoqueMinimo')">
				</div>
				<div class="linha-cadastro-item">
					<label for="estoqueMinimo">Estoque Mínimo:&nbsp;</label>
					<input @input="setOnlyNumbers" autocomplete="off" id="estoqueMinimo" type="text" v-model="produtoCadastrado.estoqueMinimo"
					class="input-pequeno" @keydown.enter="proximoCampo('btn-salvar')">
				</div> 
				<div class="linha-cadastro-item nowrap">
					<label for="comissao">Comissão:&nbsp;</label>
					<div class="checked">
						<div class="selecionar">
							<input type="checkbox"  v-model="produtoCadastrado.geraComissao" @change="mudarCheckbox('geraComissao', 'S', 'N')" id="geraComissao" />
							<label class="check" for="geraComissao"></label>
							<span v-if="produtoCadastrado.geraComissao">&nbsp;SIM</span>
							<span v-else>&nbsp;NÃO</span>
						</div>
					</div>
				</div>
				<div class="linha-cadastro-item nowrap">
					<label>Ativo:&nbsp;</label>
					<div class="checked">
						<div class="selecionar" >
							<input type="checkbox"  v-model="produtoCadastrado.ativo" @change="mudarCheckbox('ativo', 'A', 'I')" id="ativo" />
							<label class="check" for="ativo"></label>
							<span v-if="produtoCadastrado.ativo">&nbsp;SIM</span>
							<span v-else>&nbsp;NÃO</span>
						</div>
					</div>
                </div>
				<div class="linha-cadastro-item nowrap">
					<div class="checked">
						<div class="selecionar">
							<span v-if="produtoCadastrado.servprod">&nbsp; Produto</span>
							<span v-else>&nbsp; Serviço</span>
							&nbsp;
							<input type="checkbox"  v-model="produtoCadastrado.servprod" @change="mudarCheckbox('servprod', 'P', 'S')" id="servprod" />
							<label class="check" for="servprod"></label>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div id="botoes">
			<button @click="cancelarEdicao" class="btn btn-cancelar">
				<i class="fas fa-times" style="color: white"></i> 
				Cancelar
				<span>[Alt+c]</span>
			</button>
		
			<button @click="adicionarProduto" class="btn btn-confirmar" id="btn-salvar">
				<i class="fa fa-check" style="color: white"></i>
				Salvar
				<span>[Alt+s]</span>
			</button>
		</div>
		

		
		MODAIS -->

		<Modal 
			v-if="codigoGerar" 
			:dimensoes="{ width: '80%', height: '80%' }" 
			v-on:fecharModal="codigoGerar = null"
			:idModal="'codigo-barras'"
		>
			<CodigoBarra v-bind:codiBarra="codigoGerar" v-on:fechartela="codigoGerar = null"/>
		</Modal>
		<Modal v-if="cadastrarGrupoProduto" @fecharModal="cadastrarGrupoProduto = false">
			<CadastroGrupoProduto
				@fecharModal="fecharModal" 
				:grupoProduto="produtoCadastrado.grupoSelecionado" 
				v-if="cadastrarGrupoProduto"
				@cadastrouGrupo="cadastrouGrupo"
			/>
		</Modal>
		<Modal v-if="listarFornecedor" @fecharModal="listarFornecedor = false">
			<consulta-cliente  
				@selecionarPessoa="selecionarFornecedor" 
				@adicionarNovo="cadastrarFornecedor = true"
				:mostrarBotaoNovo="true"
				v-if="!cadastrarFornecedor"
			/>
			<!-- <Paginacao 
				tipoConteudo="Clientes" @adicionarNovo="cadastrarFornecedor = true" 
				v-if="!cadastrarFornecedor" class="paginacao"
				>
					<TabelaPessoas tipoConteudo="Clientes" class="tabelaPessoas"
					@selecionarPessoa="selecionarFornecedor" @fecharModal="listarFornecedor = false" />
			</Paginacao> -->
			<CadastroCliente @cancelarEdicao="cadastrarFornecedor = false" v-else :cliente="{novo: true}"/>
		</Modal>
	</div>
</template>

<script>
// import Paginacao from "./Paginacao.vue";
// import TabelaPessoas from "./TabelaPessoas.vue";
import CadastroGrupoProduto from "./CadastroGrupoProduto.vue";
import ListaBuscas from "./ListaBuscas.vue";
import Modal from "./Modal.vue";
import CadastroCliente from "./CadastroCliente.vue";
import { FormacaoPreco } from "../models/Produto.js";
import CodigoBarra from "./CodigoBarra.vue";
import { dateFormatISO } from "../utils/dateformat";
import { proximoCampo } from "../utils/utils";
import ConsultaCliente from "./ConsultaCliente.vue";
export default {
	name: "ProdutoDados",

	props: {
		PropProduto: Object,
		Fornecedor: Object
	},

	components: {
		// Paginacao,
		// TabelaPessoas,
		CadastroGrupoProduto,
		Modal,
		ListaBuscas,
		CadastroCliente,
		CodigoBarra,
		ConsultaCliente,
	},
	data(){
		return {
			produtoCadastrado: {
				datacadastro: dateFormatISO({date: null, format: "ISOdate"}),
				dataAlteracao: dateFormatISO({date: null, format: "ISOdate"}),
				grupoSelecionado: {novo: true},
				subGrupoSelecionado: {},
				cdsubgrupo: "",
				fornecedor: {},
				cdReferencia: "",
				cdbarraFornecedor: "",
				codBarra: "",
				cdproduto: "",
				descricao: "",
				descresumido: "",
				embalagem: "UN",
				unidade: "UN",
				multiplo: "",
				pesoBruto: "",
				qteEmbalagem: "1",
				pesoLiquido: "",
				estoqueMaximo: "",
				estoqueMinimo: "",
				codinter: "",
				ativo: true,
				comissaoCampo: false,
				servprod: true,
				
			},

			proximoCampo,
			
			listarFornecedor: false,
			cadastrarFornecedor: false,

			cadastrarGrupoProduto: false,
			textoBuscaGrupo: "",
			listaGrupo: false,
			codigoGerar: null,
			listaSubGrupo: false,
		};
	},

	computed: {
		gruposProdutos(){
			return this.$store.state.grupoProduto;
		},
		Empresa(){
			return this.$store.state.Empresa;
		}
	},
	beforeMount() {
		this.$store.dispatch("buscarGrupos");
		window.addEventListener("click", this.handleClickOutside);
	},
	mounted(){
		this.produtoEmpresaConectada();
		this.$bus.$on("salvarProduto", this.adicionarProduto);
		// this.iniciarData();
	},
	destroyed(){
		window.removeEventListener("click", this.handleClickOutside);
		this.$bus.$off("salvarProduto", this.adicionarProduto);
	},
	methods: {
		montarMensagemInputSubGrupo() {
			const nenhumSubGrupoSelecionado = this.produtoCadastrado?.subGrupoSelecionado?.nenhumSelecionado;
			if(!nenhumSubGrupoSelecionado && (this.produtoCadastrado.subGrupoSelecionado?.cdsubgrupo || this.produtoCadastrado.subGrupoSelecionado?.cdsubgrupo === 0)) {
				return `${this.produtoCadastrado?.subGrupoSelecionado?.cdsubgrupo } - ${this.produtoCadastrado?.subGrupoSelecionado?.descricao }`;
			} else {
				return "";
			}
		
		},

		handleClickOutside(e) {
			const listGroupContainer = this.$refs.listaGrupo?.$el;
			const listSubGroupContainer = this.$refs.listaSubGrupo?.$el;
			if(!listGroupContainer?.contains(e.target)) {
				if(this.listaGrupo) {
					this.listaGrupo = false;
				}
			}
			if(!listSubGroupContainer?.contains(e.target)) {
				if(this.listaSubGrupo) {
					this.listaSubGrupo = false;
				}
			}
		},
		//PRODUTO
		solicitarCodigoBarra(){
			if (!this.PropProduto || this.PropProduto?.novo) {
				return this.$store.commit("relatarErro", {mensagem: "Favor salvar o cadastro do produto antes de gerar as etiquetas!", alerta: true});
			}
			this.codigoGerar = {
				barraunico: this.PropProduto,
			};
		},

		duplicarProduto() {
			this.$store.state.carregando = true;
			setTimeout(() => {
				this.produtoCadastrado.cdproduto = null;
				this.produtoCadastrado.id = null;
				this.produtoCadastrado.codBarra = null;
				this.produtoCadastrado.codinter = null;
				this.produtoCadastrado.cdReferencia = null;
				this.$emit("duplicarProduto");
				this.$store.state.carregando = false;
			}, 100);
		},
		async dispararAcao({acao, payload}){
			let data = await this.$store.dispatch(`${acao}`, payload ? {...payload} : null);
			if(data.cdReferencia === ""){
				this.produtoCadastrado.cdproduto = data.id;
				this.produtoCadastrado.codBarra = data.codBarra;
				this.produtoCadastrado.codinter = data.codinter;
				const produtoReferencia = {
					...this.produtoCadastrado, 
					cdreferencia: data.id, 
					cdlinha: data.cdgrupo,
					ativo: this.produtoCadastrado.ativo ? "A" : "I",
					servprod: this.produtoCadastrado.servprod ? "P" : "S",
					geraComissao: this.produtoCadastrado.geraComissao ? "S" : "N"
					
				}; 
				data = await this.$store.dispatch("atualizarProduto", produtoReferencia);
			}
			return data;
		},

		validarCampos() {
			try {
				if (this.produtoCadastrado.grupoSelecionado.novo) {
					throw new Error("Favor selecionar um grupo!");
				}
				if (!this.produtoCadastrado.descricao || !`${this.produtoCadastrado.descricao}`.trim().length) {
					throw new Error("Favor preencher a descrição do produto!");
				}
				if (!this.produtoCadastrado.unidade || !`${this.produtoCadastrado.unidade}`.trim().length) {
					throw new Error("Favor preencher a unidade do produto com até 3 (três) letras!");
				}
				if (!this.produtoCadastrado.embalagem || !`${this.produtoCadastrado.embalagem}`.trim().length) {
					throw new Error("Favor preencher a embalagem do produto com até 3 (três) letras!");
				}
				if (!this.produtoCadastrado.qteEmbalagem || !`${this.produtoCadastrado.qteEmbalagem}`.trim().length) {
					throw new Error("Favor preencher a quantidade da embalagem do produto!");
				}
				return true;
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
					alerta: true,
				});
				return false;
			}
		},
		
		async produtoEmpresaConectada(){
			let cdempresa;
			if(this.PropProduto?.empresas?.length > 0){
				cdempresa = this.PropProduto?.empresas[0].cdempresa; 
			}
			await this.$store.dispatch("selecionarProdutoPorEmpresa", {id:  this.PropProduto?.id, cdempresa: cdempresa });

			return this.iniciarData();
		},
		populaSubgrupo(){
			if(this.produtoCadastrado.cdproduto){
				return this.$store.state.produtoSelecionado.cdsubgrupo;
			}
			else if(this.PropProduto?.duplicando){
				return this.produtoCadastrado.cdsubgrupo?this.produtoCadastrado.cdsubgrupo:null;
			}
			else{
				return this.$store.state.produtoSelecionado?.cdsubgrupo?this.$store.state.produtoSelecionado.cdsubgrupo:this.produtoCadastrado.cdsubgrupo;
			}
		},
		populaGrupo(){
			if(this.produtoCadastrado.cdproduto){
				return this.$store.state.produtoSelecionado.cdgrupo;
			}
			else if(this.PropProduto?.duplicando){
				return this.produtoCadastrado.grupoSelecionado?.cdGrupo?this.produtoCadastrado.grupoSelecionado.cdGrupo:this.$store.state.produtoSelecionado.cdgrupo;
			}
			else{
				return this.$store.state.produtoSelecionado?.cdgrupo?this.$store.state.produtoSelecionado.cdgrupo:this.produtoCadastrado.cdgrupo;
			}
		},
		async adicionarProduto() {
			if (!this.validarCampos()) return;
			let formacaoPreco = {};
			formacaoPreco[this.Empresa.id] = new FormacaoPreco({...this.PropProduto, ...this.produtoCadastrado});
			let ncm = null;
			if (this.produtoCadastrado.subGrupoSelecionado?.ncm) {
				ncm = this.produtoCadastrado.subGrupoSelecionado.ncm;
			} else if (this.produtoCadastrado.grupoSelecionado?.ncm) {
				ncm = this.produtoCadastrado.grupoSelecionado.ncm;
			}
			let produto = null;
			if (!this.PropProduto || this.PropProduto?.novo || !this.PropProduto?.id || !this.produtoCadastrado.cdproduto) {
				produto = await this.dispararAcao(
					{
						acao: "cadastrarProduto", 
						payload: {
							...this.$store.state.produtoSelecionado,
							...this.produtoCadastrado,
							cdgrupo: this.populaGrupo(),
							cdsubgrupo: this.populaSubgrupo(),
							ativo: this.produtoCadastrado.ativo ? "A" : "I",
							servprod: this.produtoCadastrado.servprod ? "P" : "S",
							geraComissao: this.produtoCadastrado.geraComissao ? "S" : "N",
							formacaoPreco,
							ncm,
							cdpai: null,
							pai: null,
							cdagrupamento: null,
						}
					}
				);
				if (!produto.id) return;
				this.produtoCadastrado.cdproduto = produto.id;
				this.produtoCadastrado.codBarra = produto.codBarra;
				this.produtoCadastrado.codinter = produto.codinter;
			} else {
				produto = await this.dispararAcao(
					{
						acao: "atualizarProduto",
						payload: {
							...this.$store.state.produtoSelecionado,
							...this.produtoCadastrado,
							ativo: this.produtoCadastrado.ativo ? "A" : "I",
							servprod: this.produtoCadastrado.servprod ? "P" : "S",
							geraComissao: this.produtoCadastrado.geraComissao ? "S" : "N",
							formacaoPreco,
							ncm,
						}
					}
				);
				if (!produto.id) return;
			}
			await this.bloqueiaAlterarCampos();
			await this.$store.dispatch("selecionarProdutoPorEmpresa", {id: produto.id});
			setTimeout(() => {
				this.$emit("salvarProduto");
			}, 50);
		},

		async iniciarData() {
			this.$store.state.carregando = true;
			setTimeout(async () => {
				if (this.Fornecedor?.id) {
					this.produtoCadastrado.fornecedor = {...this.Fornecedor};
				}
				if ((this.PropProduto && !this.PropProduto?.novo) || (this.PropProduto?.novo && this.PropProduto?.duplicando)) {
					let grupoBanco = null;
					if (this.PropProduto.cdgrupo) {
						grupoBanco = await this.verificarGrupo();
					}
					this.produtoCadastrado = this.preencherProdutoProp(grupoBanco);
					this.proximoCampo("cdproduto");
				} else {
					this.proximoCampo("buscaGrupo");
					this.produtoCadastrado.subGrupoSelecionado.nenhumSelecionado = true;
				}
				if(!this.PropProduto?.novo){
					const input = document.getElementById("cdbarrafornecedor");
					input.disabled = true;
				}
				this.bloqueiaAlterarCampos();
				this.$store.state.carregando = false;
			}, 500);
		},
		
		async verificarGrupo(){
			const grupo =  await this.$store.dispatch("buscarUnicoGrupo", this.PropProduto.cdgrupo);
			if (!grupo) {
				this.$store.state.carregando = false;
				return this.$store.commit("relatarErro", {mensagem: "Grupo não encotrado!"});
			}
			return grupo;
		},

		preencherSubGrupo(grupoBanco) {
			if(this.PropProduto?.novo) {
				return {nenhumSelecionado: true};
			}
			if(
				(this.PropProduto?.cdsubgrupo && 
				this.PropProduto?.cdsubgrupo > 0) &&
				grupoBanco?.subGrupos?.length > 0
			) {
				return grupoBanco.subGrupos.find(sg => sg?.cdsubgrupo === this.PropProduto?.cdsubgrupo);
			}
			return {nenhumSelecionado: true};

		},

		preencherProdutoProp(grupoBanco){
			return {
				cdagrupamento: this.PropProduto.cdagrupamento,
				grupoSelecionado: grupoBanco || {novo: true},
				cdgrupo: this.PropProduto.cdgrupo,
				subGrupoSelecionado: this.preencherSubGrupo(grupoBanco), 
				cdproduto: this.PropProduto.id || this.PropProduto.cdproduto,
				cdReferencia: this.PropProduto.cdReferencia,
				codBarra: this.PropProduto.codBarra,
				codbarraFornecedor: this.PropProduto.codbarraFornecedor,
				descricao: this.PropProduto.descricao,
				descresumido: this.PropProduto.descresumido,
				unidade: this.PropProduto.unidade,
				ativo: this.PropProduto.ativo == "A",
				fornecedor: this.PropProduto.nomeFornecedor ? {id: this.PropProduto.cdFornecedor, nome: this.PropProduto.nomeFornecedor} : "",
				embalagem: this.PropProduto.embalagem,
				pesoBruto: this.PropProduto.pesoBruto,
				qteEmbalagem: this.PropProduto.qteEmbalagem,
				pesoLiquido: this.PropProduto.pesoLiquido,
				estoqueMaximo: this.PropProduto.estoqueMaximo,
				geraComissao: this.PropProduto.geraComissao == "S",
				multiplo: this.PropProduto.multiplo,
				estoqueMinimo: this.PropProduto.estoqueMinimo,
				codinter: this.PropProduto.codinter,
				servprod: (this.PropProduto.servprod || "P") == "P",
				dataAlteracao: this.PropProduto.dataultalteracao || new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
				datacadastro: this.PropProduto.datacadastro ? this.PropProduto.datacadastro : new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
				dataUltCompra: this.PropProduto.dataUltCompra || "",
				dataUltVenda: this.PropProduto.dataUltVenda || "",
				tipo: this.PropProduto.tipo || "S",
			};
		},
		//GERAL
		
		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},

		mudarCheckbox (campo) {
			this.produtoCadastrado[campo] = this.produtoCadastrado[campo] ? this.produtoCadastrado[campo] = true : this.produtoCadastrado[campo] = false;  
			this.$forceUpdate();
		},

		fecharModal(){
			this.cadastrarGrupoProduto = false;
		},

		async bloqueiaAlterarCampos() {
			document.getElementById("cdproduto").readOnly = true;
			if (this.produtoCadastrado.codBarra != "SEM GTIN" && this.produtoCadastrado.codBarra) {
				document.getElementById("codBarra").readOnly = true;
			}
			if (this.produtoCadastrado.grupoSelecionado.cdGrupo) {
				document.getElementById("buscaGrupo").disabled = true;
			}
		},


		//FORNECEDOR
		selecionarFornecedor(fornecedor){
			this.produtoCadastrado.fornecedor = fornecedor;
			this.listarFornecedor = false;
			setTimeout(() => {
				document.getElementById("fornecedor").focus();
				document.getElementById("fornecedor").select();
			}, 50);
		},

		//GRUPO E SUBGRUPO
		listaSubGrupoFoco(){
			setTimeout(() => {
				this.listaSubGrupo = true;
			}, 200);
		},
		removerSubgrupoSelecionado(){
			if(this.produtoCadastrado?.grupoSelecionado?.cdGrupo){
				setTimeout(() => {
					this.listaSubGrupo = true;
				}, 200);
			}
			this.produtoCadastrado.subGrupoSelecionado = {nenhumSelecionado: true};
			this.$forceUpdate();
		},
		selecionarSubGrupo(subg){
			if(subg){
				this.produtoCadastrado.subGrupoSelecionado = subg ? subg : {};
				this.produtoCadastrado.cdsubgrupo = subg?.cdsubgrupo;
				this.listaSubGrupo = false;
			}
		},
		listaGrupoFoco(){
			this.buscarGrupo();
			setTimeout(() => {
				this.listaGrupo = true;
			}, 200);
		},

		buscarGrupo(){
			this.listaGrupo = true;
			setTimeout( async () => {
				await this.$store.dispatch("filtrarGrupos", {
					descricaoGrupo: this.textoBuscaGrupo
				});
			}, 200);
		},
		
		cadastrouGrupo(grupo){
			this.selecionarGrupo(grupo);
		},

		selecionarGrupo(grupo){
			const { cdGrupo, descricao, subGrupos } = grupo;
			this.produtoCadastrado.grupoSelecionado = {
				cdGrupo,
				descricao,
				subGrupos
			};
			this.listaGrupo = false;
			setTimeout(() => {
				this.listaSubGrupo = true;
				document.getElementById("subgrupo")?.focus();
			}, 200);
		},

		removerGrupo(){
			this.textoBuscaGrupo = "";
			this.produtoCadastrado.subGrupoSelecionado = {};
			this.produtoCadastrado.grupoSelecionado = {novo: true};
			document.getElementById("buscaGrupo").disabled = false;
		}
	},

	capturarEventosTeclado(e) {
		if (`${e.key}`.toUpperCase() == "S" && e.altKey) {
			e.preventDefault();
			this.adicionarProduto();
		} else if (`${e.key}`.toUpperCase() == "C" && e.altKey) {
			e.preventDefault();
			this.cancelarEdicao();
		} else if (`${e.key}`.toUpperCase() == "B" && e.altKey) {
			e.preventDefault();
			this.solicitarCodigoBarra();
		}
	},		

};
</script>

<style scoped>
.resize-input-editar{
	padding-right: 20%;
}
.container-page{
	height: 75vh;
	width: 100%;
	overflow-y: hidden;
}
.container-form{
	display: grid;
	width: 100%;
	grid-template-columns: 195px 225px 195px 250px auto;
	gap: 15px;
	margin-top: 30px;
}
.agroup{
	display: flex;
	flex-direction: row;
	gap: 25px;
	margin-top: 15px;
	width: 425px;
}

.card-agroup{
	width: 75px;
}
.item3{
	grid-column: 3/5;
}

.item5{
	grid-column: 1/3;
}
.item10{
	grid-column: 2/5;
}

#removerBotao{
	position: absolute;
	width: 10%;
	top: 10px;
	left: 85%;
}
#editarBotao{
	position: absolute;
	top: 10px;
	left: 80%;
}
#adicionarBotao{
	position: absolute;
	width: 10%;
	top: 7px;
	left: 90%;
	font-size: 1.2rem;
}
#botaoDuplicar{
	position: absolute;
	top: 9px;
	left: 80%;
}

.botoes{
	gap: 40px;
}

.botoes button{
	width: 45%;
}

.sm-view {
	display: none;
}


/* MEDIA QUERIES */
@media screen and (max-width: 1260px){
	.input-group-switch label{
		font-size: 14px;
	}
	.input-group{
		width: auto;
		height: 30px;
		position: relative;
		border: 3px solid var(--border);
		border-radius: 8px;
		margin: 0px 10px;
	}
	.input-group label{
		font-size: 14px;
		padding: 5px !important;
		line-height: 10PX !important;
	}
	.input-group input{
		padding: 5px;
		height: 30px;
		font-size: 14px;
		line-height: 22px;

	}
	.item10{
		grid-column: 2/4;
	}
	
}
@media screen and (max-width: 1210px){
	.container-form{
		grid-template-columns: 195px 225px auto auto;
	}
	.item4{
		grid-column: 1/3;
	}
	.item5{
		grid-column: 3/5;
	}
	.item7{
		grid-column: 2/4;
	}
	.item10{
		grid-column: 2/5;
	}
	.item11{
		grid-column: 1/3;
	}
}
@media screen and (max-width: 970px){
	.container-form{
		grid-template-columns: 165px 190px auto ;
	}
}

@media screen and (max-width: 1080px) {
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.container-form {
		width: 100%;
		gap: 15px 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.input-group {
		width: 47%;
	}

	.item5 {
		width: 97%;
	}

	.item10 {
		width: 97%;
	}

	.item11 {
		width: 97%;
	}

	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	.item2 .sm-view {
		display: none;
	}

	.item2 .lg-view {
		display: inline-block;
	}

	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}

	#adicionarBotao {
		top: 2px;
		left: 92%;
	}

	#editarBotao {
		left: 88%;
		top: 4px;
	}

	#removerBotao {
		left: 94%;
		top: 4px;
	}
}

@media screen and (max-width: 440px) {
	.botao i{
		display: none;
	}
	.resize-input-editar{
		padding-right: 30%!important;
	}
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.container-form {
		width: 100%;
		gap: 15px 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.input-group {
		width: 44%;
		height: 100%;
	}

	.item5 {
		width: 94%;
	}

	.item10 {
		width: 94%;
	}

	.item11 {
		width: 94%;
	}

	

	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	.item2 .sm-view {
		display: none;
	}

	.item2 .lg-view {
		display: inline-block;
	}

	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}

	#adicionarBotao {
		top: 4px;
		left: 85%;
		transform: scale(1.1);
	}

	#editarBotao {
		left: 70%;
		top: 7px;
		transform: scale(1.3);
	}

	#removerBotao {
		left: 85%!important;
		top: 7px;
		transform: scale(1.3);
	}
}

@media screen and (max-width: 425px){
	
	.container-form{
		grid-template-columns: auto 200px;
		gap: 10px;
	}
	.input-group{
		margin: 0px 5px;
	}
	.item3{
		grid-row: 2/2;
		grid-column: 1/2;
	}
	.item4{
		grid-row: 2/2;
		grid-column: 2/2;
	}
	.item5{
		grid-row: 3/3;
		grid-column: 1/3;
	}
	.item6{
		grid-row: 4/4;
		grid-column: 1/2;
	}
	.item7{
		grid-row: 4/4;
		grid-column: 2/2;
	}
	.item8{
		grid-row: 5/5;
		grid-column: 1/2;
	}
	.item9{
		grid-row: 5/5;
		grid-column: 2/3;
	}
	.item10{
		grid-row: 6/6;
		grid-column: 1/3;
	}
	.agroup{
		gap: 25px;
	}
	
	.input-group-switch label{
		font-size: 14px;
	}
	.track{
		width: 65px !important;
	}
	.esconder-input:checked + .thumb-container{
		transform: translateX(120%) !important;
	}
	.botoes{
		margin-top: 25px;
	}
	#removerBotao{
		position: absolute;
		width: 10%;
		top: 5px;
		left: 75%;
	}
	#editarBotao{
		position: absolute;
		width: 10%;
		top: 5px;
		left: 85%;
	}
	#adicionarBotao{
		position: absolute;
		width: 10%;
		top: 3px;
		left: 80%;
	}

}

@media screen and (max-width: 425px) {
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.container-form {
		width: 100%;
		gap: 15px 5px;
	}

	.input-group {
		width: 46%;
	}

	.item5 {
		width: 96%;
	}

	.item10 {
		width: 96%;
	}

	.item11 {
		width: 96%;
	}

	

	



	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	.item2 .sm-view {
		display: none;
	}

	.item2 .lg-view {
		display: inline-block;
	}

	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}

	#adicionarBotao {
		top: 4px;
		left: 85%;
		transform: scale(1.1);
	}

	#editarBotao {
		left: 70%;
		top: 7px;
		transform: scale(1.3);
	}

	#removerBotao {
		left: 85%;
		top: 7px;
		transform: scale(1.3);
	}
}
@media screen and (max-width: 400px) {
	.botao i{
		display:none;
	}
}
@media screen and (max-width: 375px) {
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.container-form {
		width: 100%;
		gap: 15px 5px;
	}

	.input-group {
		width: 45%;
	}

	.item5 {
		width: 95%;
	}

	.item10 {
		width: 95%;
	}

	.item11 {
		width: 95%;
	}

	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	.item2 .sm-view {
		display: none;
	}

	.item2 .lg-view {
		display: inline-block;
	}

	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}

	#adicionarBotao {
		top: 4px;
		left: 83%;
		transform: scale(1.1);
	}

	#editarBotao {
		left: 68%;
		top: 7px;
		transform: scale(1.3);
	}

	#removerBotao {
		left: 85%!important;
		top: 7px;
		transform: scale(1.3);
	}
}

@media screen and (max-width: 320px) {
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.container-form {
		width: 100%;
		gap: 15px 5px;
	}

	.input-group {
		width: 45%;
	}

	.item5 {
		width: 95%;
	}

	.item10 {
		width: 95%;
	}

	.item11 {
		width: 95%;
	}

	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	.item2 .lg-view {
		display: none;
	}

	.item2 .sm-view {
		display: inline-block;
	}

	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}

	#adicionarBotao {
		top: 4px;
		left: 80%;
		transform: scale(1.1);
	}

	#editarBotao {
		left: 65%;
		top: 7px;
		transform: scale(1.3);
	}

	#removerBotao {
		left: 85%;
		top: 7px;
		transform: scale(1.3);
	}
}
</style>