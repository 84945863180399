<template>
	<div class="container-modal-transferencia">
		<div class="modal-transferencia-titulo">
			<span>
				Lançamento de Transferência
			</span>
		</div>
		<div class="container-formulario">
			<div class="origem-container">
				<span class="origem-title">
					Transferir De
				</span>
				<div class="container-row">
					<div class="input-group">
						<select 
							id="contaOrigem" 
							v-model="contaOrigem"
							@keydown.enter="proximoCampo('vlrMontante')"
						>
							<option value="SEL" disabled>
								SELECIONE UMA OPÇÃO
							</option>
							<option
								v-for="(conta, index) in Contas"
								:value="conta"
								:key="index"
							>
								{{ conta.nome }}
							</option>
						</select>
						<label for="contaOrigem">
							Conta
						</label>
					</div>
					<div class="input-group">
						<input
							type="text"
							id="vlrMontante"
							autocomplete="off"
							v-mascaraFloat
							v-model="valorTransferir"
							@keydown.enter="proximoCampo('tpDeposito')"
							placeholder=" "
						/>
						<label for="vlrMontante">
							Valor Montante
						</label>
					</div>
					<div class="input-group">
						<select 
							id="tpDeposito" 
							v-model="tipoDeposito"
							@keydown.enter="proximoCampo('dtDigitacao')"
						>
							<option value="SEL" disabled>
								SELECIONE UMA OPÇÃO
							</option>
							<option
								v-for="(forma, index) in FormasPagamento"
								:value="forma.cdtipo"
								:key="index"
							>
								{{ forma.descricao }}
							</option>
						</select>
						<label for="tpDeposito">
							Tipo depósito
						</label>
					</div>
					<div class="input-group">
						<input 
							type="date"
							placeholder=" "
							id="dtDigitacao"
							@keydown.enter="proximoCampo('dtTransferencia')"
							v-model="dataDigitacao"
						/>
						<label for="dtDigitacao">
							Data Digitação
						</label>
					</div>
					<div class="input-group">
						<input 
							type="date"
							placeholder=" "
							id="dtTransferencia"
							@keydown.enter="proximoCampo('pcOrigem')"
							v-model="dataTransf"
						/>
						<label for="dtTransferencia">
							Data Transf.
						</label>
					</div>
				</div>
				<div class="container-row">
					<div class="input-group">
						<select 
							id="pcOrigem" 
							v-model="pcOrigem"
							@keydown.enter="proximoCampo('origemObservacao')"
						>
							<option disabled value="SEL">
								SELECIONE UMA OPÇÃO
							</option>
							<option 
								v-for="plano in PlanoContas"
								:key="plano.vid"
								:value="plano"
								:disabled="plano.alterarValor === 0"
								:class="`${plano.alterarValor === 0 ? 'disabled-option' : ''}`"
							>
								<span>
									{{ plano.nome }}
								</span>	
							</option>
						</select>
						<label for="pcOrigem">
							Plano de Contas
						</label>
					</div>
					<div class="input-group" id="origem-obs">
						<input
							type="text"
							id="origemObservacao"
							autocomplete="off"
							v-model="origemObs"
							@keydown.enter="proximoCampo('contaDestino')"
							placeholder=" "
						/>
						<label for="origemObservacao">
							Observação
						</label>
					</div>
					
				</div>
			</div>
			<div class="destino-container">
				<span class="destino-title">
					Para
				</span>
				<div class="container-row">
					<div class="input-group">
						<select 
							id="contaDestino" 
							v-model="contaDestino"
							@keydown.enter="proximoCampo('destinoObservacao')"
						>
							<option value="SEL" disabled>
								SELECIONE UMA OPÇÃO
							</option>
							<option
								v-for="(conta, index) in ContasEntrada"
								:value="conta"
								:key="index"
							>
								{{ conta.nome }}
							</option>
						</select>
						<label for="contaDestino">
							Conta
						</label>
					</div>
					<div class="input-group" id="destino-obs">
						<input
							type="text"
							id="destinoObservacao"
							autocomplete="off"
							v-model="destinoObs"
							@keydown.enter="proximoCampo('empDestino')"
							placeholder=" "
						/>
						<label for="destinoObservacao">
							Observação
						</label>
					</div>
				</div>
				<div class="container-row">
					<div class="input-group">
						<select 
							id="empDestino" 
							v-model="cdEmpresaDestino"
						>
							<option disabled value="SEL">
								SELECIONE UMA OPÇÃO
							</option>
							<option 
								v-for="emp in Empresas"
								:key="emp.id"
								:value="emp.id"
								
							>
								{{ emp.id }} - {{ emp.nome }}
							</option>
						</select>
						<label for="empDestino">
							Empresa destino
						</label>
					</div>
				</div>
			</div>
		</div>
		<div class="container-buttons">
			<button
				class="botao botao-cancelar"
				@click="$emit('fecharTransferencia')"
			>
				Cancelar
			</button>
			<button
				class="botao botao-adicionar"
				@click="realizarTransferenciaFinanceiro"
			>
				Salvar
			</button>
		</div>
	</div>	
</template>
<script>
import { proximoCampo } from "../utils/utils";

export default {
	name: "AdicionarTransferencia",

	computed: {
		Contas(){
			return this.$store.state.Contas;
		},
		ContasEntrada(){
			return this.Contas.filter(conta => conta != this.contaOrigem);
		},
		FormasPagamento(){
			const formasPgto = this.$store.state.formasPagamento.filter(forma => forma.mostrarecebimento == "S" && forma.vistaprazo == 1 && forma.quantidadediasdeprazo == 0);
			return formasPgto;
		},
		
		PlanoContas() {
			return this.$store.state.PlanoContas.ListaPlanoContasVisualizacao;
		},

		Empresas() {
			return this.$store.state.Empresas.filter(emp => emp.id !== this.Empresa.id);
		},

		Empresa() {
			return this.$store.state.Empresa;
		}
	},

	data() {
		return {
			proximoCampo,
			contaOrigem: "SEL",
			valorTransferir: "",
			tipoDeposito: "SEL",
			dataDigitacao: new Date().toLocaleDateString("en-CA"),
			dataTransf: new Date().toLocaleDateString("en-CA"),
			pcOrigem: "SEL",
			origemObs: "",

			contaDestino: "SEL",
			destinoObs: "",
			cdEmpresaDestino: "SEL",
		};
	},
	
	methods: {
		convertStringToNumber(value) {
			return typeof value === "string" ? Number(value.replace(",", ".")) : value;
		},

		async realizarTransferenciaFinanceiro() {
			try {
				if(!this.validarCampos()) return;
				const form = this.convertForm();
				await this.$store.dispatch("addTransferenciaFinanceiro", form);
				this.clearForm();
			} catch (error) {
				console.error("Erro ao realizar transferência: ", error);
			}
		},

		clearForm() {
			this.contaOrigem = "SEL";
			this.valorTransferir = "";
			this.tipoDeposito = "SEL";
			this.dataDigitacao = new Date().toLocaleDateString("en-CA");
			this.dataTransf = new Date().toLocaleDateString("en-CA");
			this.pcOrigem = "SEL";
			this.origemObs = "";
			this.contaDestino = "SEL";
			this.destinoObs = "";
			
			this.cdEmpresaDestino = "SEL";
			this.fecharModal();
		},

		fecharModal() {
			this.$emit("fecharTransferencia");
		},

		convertForm() {
			const cDestino = this.contaDestino;
			const cOrigem = this.contaOrigem;
			this.valorTransferir = this.convertStringToNumber(this.valorTransferir || 0);
			this.origemObs = this.origemObs.toUpperCase();
			this.destinoObs = this.destinoObs.toUpperCase();
			return {
				valor: this.valorTransferir,
				data: this.dataTransf,
				observacao: this.origemObs,
				competencia: "",
				cdTipoPgto: this.tipoDeposito,
				planoContas: [
					{
						cdCentroCusto: "",
						idPlanoconta: this.pcOrigem.vid,
						percentual: 100,
						valor: this.valorTransferir
					}
				],
				// ORIGEM
				agenciaOrigem: cOrigem.agencia,
				bancoOrigem: cOrigem.banco,
				contaCorrenteOrigem: cOrigem.cc,
				cdEmpresaOrigem: this.Empresa.id,
				// DESTINO
				agenciaDestino: cDestino.agencia,
				bancoDestino: cDestino.banco,
				contaCorrenteDestino: cDestino.cc,
				cdEmpresaDestino: this.cdEmpresaDestino,
				cdidentificador: -1,
			};
		},

		validarCampos() {
			let mensagemErro = "";
			if(!this.pcOrigem || this.pcOrigem === "SEL") {
				mensagemErro = "Por favor selecione a o plano de contas!";
			}
			if (!this.contaOrigem || this.contaOrigem === "SEL") {
				mensagemErro = "Por favor selecione a conta que vai transferir";
			} else if (!this.contaDestino || this.contaDestino === "SEL") {
				mensagemErro = "Por favor selecione a conta que receberá a transferência";
			} else if (!this.valorTransferir || this.valorTransferir === "") {
				mensagemErro = "Por favor informe o valor da transferência";
			}
			if (mensagemErro) {
				this.$store.commit("relatarErro", { mensagem: mensagemErro });
				return false;
			}
			return true;
		}
	}
};
</script>
<style scoped>

.container-modal-transferencia{
	display: flex;
	flex-direction: column;
}

.modal-transferencia-titulo{
	font-size: 24px;
	text-align: left;
	padding: 5px 5px;
}

.container-formulario{
	width: 100%;
	height: 54vh;
	overflow: auto;
	padding: 10px;
	border: solid 2px #cecece;
	display: flex;
	flex-direction: column;	
}

.origem-container,
.destino-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.destino-title {
	padding: 5px;
}

.container-row {
	display: flex;
	gap: 10px;
	padding: 10px;
	width: 100%;
	border-bottom: 1px solid #cecece;
}

#origem-obs{
	width: 800px;
}

#destino-obs {
	width: 850px;
}

.container-buttons{
	width: 100%;
	display: flex;
	gap: 10px;
	padding: 25px 0px;
}
.container-buttons button {
	width: 50%;
}

.disabled-option {
	color: rgb(252, 95, 95);
}
@media screen and (max-width: 425px) {
	.container-formulario {
		flex-direction: column;
	}
	.container-row{
		flex-direction: column;
	}
	#origem-obs, #destino-obs{
		width: auto;
	}
}
</style>