
<template>
    <div id="gridPrincipalConsorcio">
        <div id="painel-controle">
            <div class="linha-cadastro">
				<div class="input-group">
					<input type="date" id="data" v-model="data" @keydown.enter="proximoCampo('status')" 
					:disabled="!$store.state.Usuario.supervisor && !$store.state.Usuario.caixa" @blur="saiuData">
					<label for="data" class="text-right" >Data cadastro</label>
				</div>
				<div class="input-group">
					<select id="status" v-model="status" @keydown.enter="proximoCampo(status == 'L' ? 'liberado' : 'cliente')" 
					@select="proximoCampo(status == 'L' ? 'liberado' : 'cliente')" :disabled="!$store.state.Usuario.supervisor && !$store.state.Usuario.caixa || Lancamento">
						<option value="A">Aberto</option>
						<option value="C" v-if="!Lancamento">Cancelado</option>
						<option value="L" v-if="!Lancamento">Liberado</option>
						<option value="R" v-if="!Lancamento">Recebeu</option>
					</select>
					<label for="status" class="text-right" >Situação</label>
					</div>
                <div class="input-group" v-if="status == 'L'">
					<input style="text-align:right;" type="text" id="liberado" v-model="liberado" @keydown.enter="proximoCampo('cliente')"
					:disabled="!$store.state.Usuario.supervisor && !$store.state.Usuario.caixa && !$store.state.Usuario.usuarioPrivilegiado">
					<label for="liberado" class="text-right" >$ Liberado</label>
				</div>
            </div>
            <div class="linha-cadastro">
				<div class="row">
					<div class="input-group">
						<input :value="cliente? cliente.id+' - '+cliente.nome :''" type="text" id="cliente"
						@keydown="efetuarpesquisa" @select="efetuarpesquisa" @click="efetuarpesquisa" :readonly="!Consorcio.novo || cliente">
						<label for="cliente" class="text-right" @click="efetuarpesquisa" >Cliente</label>
					</div>
					<i class="fas fa-times" style="color : blue" @click="Consorcio.novo ? cliente = null : null" 
					v-if="cliente"></i>
				</div>
            </div>
            <div class="linha-cadastro">
				<div class="row">
					<div class="input-group">
						<input :value="vendedor? vendedor.id+' - '+vendedor.nome :''" type="text" id="vendedor" 
						@keydown="efetuarpesquisa" @select="efetuarpesquisa" @click="efetuarpesquisa" :readonly="!Consorcio.novo"
						:disabled="!$store.state.Usuario.supervisor && !$store.state.Usuario.caixa && !$store.state.Usuario.usuarioPrivilegiado
						&& !$store.state.Usuario.vendeParaOutroVendedor">						
						<label for="vendedor" class="text-right" >Vendedor</label>
					</div>
					<i class="fas fa-times" style="color : blue" @click="Consorcio.novo ? vendedor = null : null" 
						v-if="vendedor && (($store.state.Usuario.supervisor || $store.state.Usuario.caixa || $store.state.Usuario.usuarioPrivilegiado)
						|| $store.state.Usuario.vendeParaOutroVendedor)"></i>
				</div>
                <div class="input-group">
					<input v-model="dataPagamentoComissao" type="date" id="dataPagamentoComissao"
					placeholder="Informe a data" :readonly="!Consorcio.novo" @blur="saiuData">
					<label for="dataPagamentoComissao" class="text-right" >Pagar comissão</label>
				</div>
            </div>
            <div class="linha-cadastro">
				<div class="input-group">
					<input v-model="valorTotalConsorcio" type="text" id="valorTotalConsorcio" @input="mascara" @keydown.enter="proximoCampo('parcela')" :readonly="!Consorcio.novo">
					<label for="valorTotalConsorcio" style="text-align:right;" >$ Consórcio</label>
				</div>
				<div class="input-group">
					<input v-model="parcela" type="text" id="parcela" @input="gerarparcelas" @keydown.enter="proximoCampo('vencimento')"
					:readonly="!Consorcio.novo">
					<label for="parcela" >Nº Parcelas</label>
				</div>
				<div class="input-group">
					<input v-model="vencimento" type="date" id="vencimento" @input="gerarparcelas" @keydown.enter="proximoCampo('btn-salvar')"
					:readonly="!Consorcio.novo" @blur="saiuData">
					<label for="vencimento" style="text-align:right; margin-left: 2px; ">1º Vencimento</label>
				</div>
            </div>
        </div>       
        <div id="conteudo">
            <div class="scroll">
                <table class="w-100">
                    <thead>
                        <tr>
                            <td style="color: var(--primario); text-align: center;">Pacela</td>
                            <td class="center">Vencimento</td>
                            <td class="center">Valor</td>
                            <td class="center">Baixa</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(mostrar, index) in parcelas" :key="index">
                            <td style="color: var(--primario); text-align: center;">{{mostrar.codigo}}</td>
                            <td class="center">{{new Date(mostrar.vencimento.split("-")).toLocaleDateString("pt-BR")}}</td>
                            <td style="text-align: right;">{{Number(mostrar.parcela).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</td>
                            <td style="text-align: center;">{{mostrar.databaixa ? new Date(mostrar.databaixa.split("T")[0].split("-")).toLocaleDateString("pt-BR") : ""}}</td>
                        </tr>    
                    </tbody>
                </table>
            </div>
            <div class="botoes">
                <button class="botao botao-cancelar" @click="cancelarCadastro"> 
					<label for="botaoCancelar" class="lg-view">Cancelar [Alt + C]</label>
					<label for="botaoCancelar" class="sm-view">Cancelar</label>
                </button>
                <button  class="botao botao-adicionar" id="btn-salvar" @click="gravarconsorcio">
					<label for="botaoAdicionar" class="lg-view">Salvar [Alt + S]</label>
					<label for="botaoAdicionar" class="sm-view">Salvar</label>
                </button>
            </div>
        </div>        
        <Modal v-if="ListarVendedores" v-on:fecharModal="fecharModal">
			<consulta-vendedores 
				@selecionarPessoa="selecionarVendedor"
			/>
            <!-- <Paginacao tipoConteudo="Vendedores" :desabilitarNovo="true">
                <TabelaPessoas tipoConteudo="Vendedores" v-on:selecionarPessoa="selecionarVendedor" />
            </Paginacao> -->
        </Modal>
        <Modal v-if="listarClientes" v-on:fecharModal="fecharModal">
			<consulta-cliente  
				@selecionarPessoa="selecionarCliente" 
			/>
            <!-- <Paginacao tipoConteudo="Clientes" :desabilitarNovo="true">
                <TabelaPessoas tipoConteudo="Clientes" v-on:selecionarPessoa="selecionarCliente"/>
            </Paginacao> -->
        </Modal>        
    </div>
</template>

<script>
// import Paginacao from "./Paginacao.vue";
import Modal from "./Modal.vue";
// import TabelaPessoas from "./TabelaPessoas.vue";
import { dateFormatISO } from "../utils/dateformat";
import ConsultaCliente from "./ConsultaCliente.vue";
import ConsultaVendedores from "./ConsultaVendedores.vue";

export default {
	name: "CadastroConsorcio",
    
	data(){
		return {            
			data: dateFormatISO({
				date:null,
				format: "ISOdate"
			}),            
			campopesquisar : "periodo",
			pesquisar :"Período",
			listarClientes : false,
			ListarVendedores: false,
			campofiltro : "",
			ProdutoSel : null,
			status : "A",
			cliente : "",
			vendedor :"",
			valorTotalConsorcio : "",
			parcela : "1",
			liberado : "",
			vencimento : dateFormatISO({
				date: new Date(
					new Date().getFullYear(),
					new Date().getMonth() +1,
					new Date().getDate()
				).toLocaleDateString("en-CA"),
				format: "ISOdate"
			}),
			parcelas: [],
			timerGeraParcelas: null,
			dataPagamentoComissao: dateFormatISO({
				date: null,
				format: "ISOdate"
			})
		};

	},

	props:{
		Consorcio : Object,
		Lancamento : Boolean,
	},

	computed:{
	},

	components: {
		// Paginacao,
		Modal,
		// TabelaPessoas,
		ConsultaCliente,
		ConsultaVendedores,
	},

	methods:{
		saiuData(){
			if(this.data == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.data = dateFormatISO({date: null, format: "ISOdate"});
			}
			if(this.dataPagamentoComissao == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataPagamentoComissao = dateFormatISO({date: null, format: "ISOdate"});
			}
			if(this.vencimento == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.vencimento = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		proximoCampo(id) {
			if (!id) {
				throw new Error("Falta o atribuito id!");
			}
			const input = document.getElementById(id);
			input.focus();
			if (input.select) {
				input.select();
			}
			if (id == "parcela"){
				this.valorTotalConsorcio = (Number(this.valorTotalConsorcio.replace(",", "."))).toLocaleString("pt-BR",{style: "decimal", minimumFractionDigits: 2,maximumFractionDigits: 2});
			}
			if (id == "cliente"){
				this.liberado = (Number(this.liberado.replace(",", "."))).toLocaleString("pt-BR",{style: "decimal", minimumFractionDigits: 2,maximumFractionDigits: 2});
			}
		},
		fecharModal() {
			if (this.listarClientes) {
				this.listarClientes = false;
				setTimeout(() => {
					document.getElementById("cliente").focus();
				},20);
			} else if (this.ListarVendedores) {
				this.ListarVendedores = false;
				setTimeout(() => {
					document.getElementById("vendedor").focus();
				},20);
			}
		},
		mascara(evento){
			const id = evento.target.id;
			const input = document.getElementById(id);
			const v = input.value;
			var er = /[^0-9.,]/;
			er.lastIndex = 0;
			if (er.test(v)) {
				this[id] =  this[id].substring(0, v.length-1);
				return;
			}
			if (id == "valorTotalConsorcio") {
				this.gerarparcelas();
			}
		},
		efetuarpesquisa(evento){
			const tipo = evento.target.id;
			if (evento.key == "Enter") {
				return setTimeout(() => {
					this.proximoCampo(evento.target.id == "cliente" ? "vendedor" : "valorTotalConsorcio");
				},20);
			}
			if (tipo == "vendedor" && !this.vendedor){
				this.ListarVendedores = true;
			}
			if (tipo == "cliente" && !this.cliente){
				this.listarClientes = true;
			}
		},
		selecionarCliente(cliente){
			this.listarClientes = false;
			this.cliente = cliente;
		},
		selecionarVendedor(vendedor){
			this.ListarVendedores = false;
			this.vendedor = vendedor;
		},
		cancelarCadastro(){
			this.$emit("cancelouCadastro", null);
		},
		async gravarconsorcio(){
			try {
				if (!this.cliente) {
					return this.$store.commit("relatarErro", {mensagem: "Informe o cliente"});
				}
				if (!this.vendedor) {
					return this.$store.commit("relatarErro", {mensagem: "Informe o vendedor"});
				}
				if (this.parcelas.length == 0) {
					return this.$store.commit("relatarErro", {mensagem: "Informe as parcelas"});
				}
				if (this.valorTotalConsorcio <= 0) {
					return this.$store.commit("relatarErro", {mensagem: "Informe o valor do consórcios"});
				}
				if (this.parcela <= 0) {
					return this.$store.commit("relatarErro", {mensagem: "Informe a quantidade das parcelas"});
				}    
				if (this.valorTotalConsorcio < this.liberado) {
					return this.$store.commit("relatarErro", {mensagem: "O valor liberado não pode ser maior que o valor total"});
				} 
				if (this.Consorcio.novo){                
					let dataatual = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()).getTime();
					if (new Date (this.vencimento).getTime() <= dataatual){
						return this.$store.commit("relatarErro", {mensagem: "Data do primeiro vencimento tem que ser maior que a data atual"});
					}
					const consorcioCadastrado = await this.$store.dispatch("adicionarConsorcio", {            
						cdcliente : this.cliente.id,
						cdvendedor: this.vendedor.id,
						data      : this.data,
						valor     : Number(this.valorTotalConsorcio.replace(".", "").replace(",",".")),
						liberado : Number(this.liberado.replace(".", "").replace(",",".")),
						// observacao: payload.observacao,                    
						parcelas  : this.parcelas,
						qtdeParcela: Number(this.parcela.replace(",",".")), 
						// Number(this.parcela.replace(",",".")),
						status: this.status,
					});

					if (!consorcioCadastrado) return;
					this.cancelarCadastro();
				} else {
					await this.$store.dispatch("atualizarConsorcio", {            
						cdconsorcio : this.Consorcio.cdconsorcio,
						cdcliente : this.cliente.id,
						cdvendedor: this.vendedor.id,
						data      : this.data,
						liberado : Number(this.liberado.replace(".", "").replace(",",".")),
						valor    : Number(this.valorTotalConsorcio.replace(".", "").replace(",",".")),
						// Number(this.valor.replace(".","").replace(",",".")),
						// observacao: payload.observacao,
						parcelas  : this.parcelas,                    
						qtdeParcela: Number(this.parcela.replace(",",".")), 
						status: this.status,
					});
					this.cancelarCadastro();
				}
			} catch (error) {
				console.log(error);
			}
		},
		gerarparcelas(){
			clearTimeout(this.timerGeraParcelas);
			this.timerGeraParcelas = setTimeout(() => {
				let calcular =  (((this.valorTotalConsorcio.replace(".", "")).replace(",", ".")) / Number(this.parcela.replace(",", ".")));
				this.parcelas = [];
				for (let i = 0; i < this.parcela; i++){
					this.parcelas.push({
						codigo : i+1,
						vencimento : i==0 ? dateFormatISO({date: this.vencimento, format: "ISOdate" }) : dateFormatISO({date: new Date (this.vencimento.split("-")[0],Number(this.vencimento.split("-")[1]) + i-1,this.vencimento.split("-")[2]).toLocaleDateString("en-CA"), format: "ISOdate" }),
						parcela : calcular,                    
					});
				}
			},350);
		},

		eventosTeclado(e) {
			if (e.key.toUpperCase() == "S" && e.altKey) {
				this.gravarconsorcio();
			}
			if (e.key.toUpperCase() == "C" && e.altKey) {
				this.cancelarCadastro();
			}
		},
	},

	mounted(){
		this.$store.dispatch("buscarFormasPagamento");
		document.addEventListener("keydown", this.eventosTeclado);
		if (!this.Consorcio.novo){
			this.data  = dateFormatISO({
				date: this.Consorcio.data,
				format: "ISOdate"
			});
			this.status = this.Consorcio.status;
			this.parcela = String(this.Consorcio.qtdeParcela);
			this.valorTotalConsorcio  =  Number(this.Consorcio.valor).toLocaleString("pt-BR",{style: "decimal", minimumFractionDigits: 2,maximumFractionDigits: 2});
			this.cliente = this.Consorcio.cliente;
			this.vendedor = this.Consorcio.vendedor;
			this.liberado = this.Consorcio.liberado;
			this.parcelas = this.Consorcio.parcelas.map((parc) => ({
				cddig : parc.cddig,
				codigo : parc.cdlan,
				vencimento : parc.datavenc,
				parcela : parc.contarc,
				databaixa : parc.databaixa,
			}));
		}
		if (this.$store.state.Usuario.cdVendedor) {
			this.selecionarVendedor({
				nome: this.$store.state.Usuario.nome,
				id: this.$store.state.Usuario.cdVendedor
			});
		}
	},

	destroyed() {
		document.removeEventListener("keydown", this.eventosTeclado);
	}
};

</script>

<style scoped>
.row{
	width:100%;
	align-items:center;
}
.input-group{
	margin-top:5px;
	width:100%;
}
#gridPrincipalConsorcio {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 200px auto;
    grid-template-areas: 
        "painel-controle"
        "conteudo";
    background-color: var(--bg-app);
    padding-top: 5px;
    height: 100%;
    width: 100%;
}

#painel-controle {        
    grid-area: painel-controle;
    width: 95%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
}

#conteudo {
    grid-area: conteudo;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1;
    border-top-left-radius: 3%;
    border-top-right-radius: 3%;
    background-color: var(--bg-secundario);
    overflow: hidden;
}
#conteudo .scroll {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
}
.linha-cadastro {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 95%;
    text-align: left;
    align-items: center;
    margin: 2px 5px;
    justify-content: flex-start;
}
.linha-cadastro .linha-cadastro-vendedor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}
.linha-cadastro input {
    flex-grow: 1;
}
.linha-cadastro > .linha-cadastro-vendedor {
    width: auto;
    max-width: 700px;
    position: relative;
}
.linha-cadastro .linha-cadastro-vendedor input[id="vendedor"] {
    width: 428px;
}
.linha-cadastro.between {
    justify-content: space-between;
}
.linha-cadastro-item {
    display: flex;
    flex-direction: row;
}
.icone-limpar-campo-pesquisa {
    position: absolute;
    left: 125px;
}
#cliente, #vendedor {
    padding-left: 20px;
}
.novo-botao{
    position: absolute;
    right: 5%;
    padding: 5px;
    background-color: transparent;
}
.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}

.botoes .botao {
	width: 45%;
}
.lg-view {
	display: inline-block;
}

.sm-view {
	display: none;
}
@media screen and (max-width: 995px) {
    #gridPrincipalConsorcio {
        grid-template-rows: 150px auto;
    }
    .linha-cadastro.between,
    .linha-cadastro {
        width: 95%;
        margin: 2px auto;
        flex-wrap: wrap;
    }
    .linha-cadastro .linha-cadastro-vendedor #vendedor {
        width: auto;
        flex-grow: 1;
        max-width: 380px;
    }
    .linha-cadastro .linha-cadastro-vendedor {
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
    }
    .linha-cadastro .linha-cadastro-vendedor ~ .linha-cadastro-vendedor {
        margin-left: 2px;
        flex-grow: 0;
    }
    .linha-cadastro .linha-cadastro-vendedor label[for="dataPagamentoComissao"] {
        width: auto;
    }
    .linha-cadastro .linha-cadastro-vendedor input[type="date"] {
        width: 136px;
    }
}
@media screen and (max-width: 978px) {
    #gridPrincipalConsorcio {
        grid-template-rows: 205px auto;
    }
    .linha-cadastro-item {
        margin: 2px 0px;
    }
    .linha-cadastro label[for="vencimento"] {
        margin-left: 0px !important;
        width: 120px;
    }
    #data,
    #vencimento {
        width: 150px;
    }
}
@media screen and (max-width: 768px){
	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}
}
@media screen and (max-width: 642px) {
    #gridPrincipalConsorcio {
        grid-template-rows: 220px auto;
    }
    #painel-controle {
        overflow: auto;
    }
    .linha-cadastro .linha-cadastro-vendedor {
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
    }
    .linha-cadastro .linha-cadastro-vendedor ~ .linha-cadastro-vendedor {
        margin-left: 0;
        margin-top: 5px;
        flex-grow: 0;
    }
    .linha-cadastro .linha-cadastro-vendedor #vendedor {
        max-width: 100%;
    }
    .icone-limpar-campo-pesquisa {
        font-size: 6pt;
    }
    #botoes {
        width: 100%;
    }
    #botoes button {
        width: 45%;
    }
    #botoes button span {
        display: none;
    }
}
@media screen and (max-width: 425px){
	.input-group{
		margin:5px;
	}
}
</style>