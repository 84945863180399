<template>
	<div class="container-page">
		<i
			class="fas fa-print"
			v-if="PosicaoEstoque && PosicaoEstoque.length > 0 ? true : false"
			id="btn-relatorio"
			@click="soliciarRelatorio"
		/>
		<Filtro
			@handleShowTable="showTable"
			@filtrar="filtrar"
		>
			<div class="row">
				<div class="container-input">
					<div class="input-group">
						<select
							v-model="Filtro.tipoFiltroCodigo"
							placeholder=" "
							id="tipo-filtro-codigo"
						>
							<option selected value="SEL" disabled>
								SELECIONE UMA OPÇÃO
							</option>
							<option
								v-for="(option, index) in Filtros"
								:key="index"
								:value="option.value"
							>
								{{ option.title }}
							</option>
						</select>
						<label for="tipo-filtro-codigo"> 
							Filtro por código
						</label>
					</div>
				</div>
				<div class="container-input">
					<div
						class="input-group"
						v-if="Filtro.tipoFiltroCodigo === 'cdreferencia'"
					>
						<input 
							placeholder=" "
							v-model="Filtro.cdreferencia"
							id="cdreferencia"
						/>
						<label for="cdreferencia">
							Cód. Referência
						</label>
					</div>
					<div
						class="input-group"
						v-if="Filtro.tipoFiltroCodigo === 'codbarra'"
					>
						<input 
							placeholder=" "
							v-model="Filtro.codbarra"
							id="codbarra"
						/>
						<label for="codbarra">
							Cód. Barras
						</label>
					</div>
					<div
						class="input-group"
						v-if="Filtro.tipoFiltroCodigo === 'descricao'"
					>
						<input 
							placeholder=" "
							v-model="Filtro.descricao"
							id="descricao"
						/>
						<label for="descricao">
							Descrição produto
						</label>
					</div>
				</div>
				<div class="container-input">
					<div class="input-group">
						<input 
							type="date"
							placeholder=" "
							v-model="Filtro.dataInicio"
							id="dataInicio"
						/>
						<label for="dataInicio">
							Data Inicial
						</label>
					</div>
				</div>
				<div class="container-input">
					<div class="input-group">
						<input 
							type="date"
							placeholder=" "
							v-model="Filtro.dataFinal"
							id="dataFinal"
						/>
						<label for="dataFinal">
							Data Final
						</label>
					</div>
				</div>
				<div class="container-input">
					<div class="input-group">
						<select
							v-model="Filtro.empresa"
							placeholder=" "
							id="empresa"
						>
							<option value="SEL" disabled>
								SELECIONE UMA OPÇÃO
							</option>
							<option value="ALL" selected>
								TODAS EMPRESAS
							</option>
							<option
								v-for="(emp, index) in Empresas"
								:key="index"
								:value="emp.id"
							>
								{{ emp.id }} - {{ emp.nome }}
							</option>
						</select>
						<label for="empresa">
							Empresa
						</label>
					</div>
				</div>
			</div>
		</Filtro>
		<div
			class="container-tabela"
			v-if="PosicaoEstoque && PosicaoEstoque.length > 0 && !loadingTable ? true : false"
		>
			<div class="header-tabela">
				<div class="linha-header-tabela">
					<div class="campo-menor">
						Emp.
					</div>
					<div class="campo-medio">
						Cód. produto
					</div>
					<div class="campo-maior">
						Descrição
					</div>
					<div class="campo-menor">
						Qtd. Compra
					</div>
					<div class="campo-medio">
						$ Compra
					</div>
					<div class="campo-menor">
						Custo
					</div>
					<div class="campo-medio">
						últ. compra
					</div>
					<div class="campo-menor">
						Qtd. vendido
					</div>
					<div class="campo-medio">
						últ. venda
					</div>
				</div>
			</div>
			<div class="body-tabela">
				<div 
					class="linha-body-tabela"
					v-for="(pos, index) in PosicaoEstoque"
					:key="index"
				>
					<div class="campo-menor">
						{{ pos.cdEmpresa }}
					</div>
					<div class="campo-medio">
						{{ pos.cdproduto }}
					</div>
					<div class="campo-maior">
						{{ pos.descricao }}
					</div>
					<div class="campo-menor">
						{{ pos.quantidadeComprada || 0 }}
					</div>
					<div class="campo-medio">
						{{ (pos.totalCompra || 0) | formatarParaReais }}
					</div>
					<div class="campo-menor">
						{{ (pos.customedio || 0) | formatarParaReais }}
					</div>
					<div class="campo-medio">
						{{ `${pos.ultimaCompra || ""}`.split("-").reverse().join("/") }}
					</div>
					<div class="campo-menor">
						{{ pos.qtdeVendido || 0 }}
					</div>
					<div class="campo-medio">
						{{ `${pos.dataultimavenda || ""}`.split("-").reverse().join("/") }}
					</div>
				</div>
			</div>
		</div>
		<div 
			v-else-if="loadingTable" 
			class="col center w-100 h-67vh"
		>
			<Loader 
				:width="'15vh'"
				:height="'15vh'"
			/>
		</div>
		<div 
			v-else
			class="col center w-100 triangle"
			id="table"
		>
			<i 
				class="fas fa-exclamation-triangle"
				style="color: yellow; font-size: 90pt"
			></i>
			<h3>
				Nenhuma conta encontrada no sistema.
			</h3>
			<h3>
				Tente alterar ou diminiur os parâmetros de busca.
			</h3>
		</div>
	</div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Filtro from "../components/Filtro.vue";
import Loader from "../components/Loader.vue";
import formatarParaReais from "../filters/formatarParaMoeda";
export default {
	name: "RelatorioPosicaoEstoque",
	components: {
		Filtro,
		Loader
	},
	data() {
		return {
			Filtro: {
				cdreferencia: "",
				tipoFiltroCodigo: "SEL",
				codbarra: "",
				descricao: "",
				empresa: "ALL",
				dataInicio: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString("en-CA"),
				dataFinal: new Date().toLocaleDateString("en-CA"),
			},
			loadingTable: false,
			Filtros: [
				{
					title: "Cód. barras",
					value: "codbarra"
				},
				{
					title: "Cód. referência",
					value: "cdreferencia"
				},
				{
					title: "Descrição",
					value: "descricao"
				},
			]
		};
	},

	computed: {
		...mapState(["Empresas", "Empresa"]),
		PosicaoEstoque() {
			return this.$store.state.Relatorios.PosicaoEstoque;
		}

	},

	filters: {
		formatarParaReais
	},

	mounted() {
		this.Filtro.empresa = this.Empresa.id;
		this.filtrar();
	},
	methods: {
		...mapActions(["filtrarProdutoPosicaoEstoque"]),

		showTable () {
			const table = document.querySelector(".container-tabela");
			if(table) {
				table.classList.toggle("fullTable");
			}
		},

		async soliciarRelatorio() {
			await this.$store.dispatch("gerarRelatorioPosicaoEstoque", this.PosicaoEstoque);
		},
		
		async filtrar() {
			this.loadingTable = true;
			try {
				await this.filtrarProdutoPosicaoEstoque(this.Filtro);
				this.loadingTable = false;
			} catch (error) {
				console.error(error);
			}
		}
	}
};
</script>
<style scoped>
.container-page {
	width: 91vw;
	height: 90vh;
	overflow-y: hidden;
}

#descricao {
	width: 300px;
}

.row{
	padding: 10px 0px;
}

#tipo-filtro-codigo{
	width: 150px;
}

#empresa {
	width: 180px;
}


.container-tabela{
	width: 89vw;
	overflow: auto;
	height: 67vh;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.container-tabela.fullTable {
	height: 76vh;
}
.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 2px 5px;
}
.header-tabela, .body-tabela{
	font-size: 13px;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}

.campo-menor {
	width: 80px;
	text-align: center;
}
.campo-medio{
	width: 175px;
	text-align: center;
}
.campo-maior {
	width: 300px;
	min-width: 300px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
}
.h-67vh{
	height: 67vh;
}


@media screen and (max-width: 450px) {
	.container-page {
		width: 100vw;
		height: 75vh;
	}
	.container-tabela {
		width: 100vw;
		height: 30vh;
	}
	.row{
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
	.h-67vh{
		height: 40vh;
	}
	.container-tabela.fullTable {
		height: 63vh;
	}
	#btn-relatorio{
		left: 0px;
	}
}
</style>