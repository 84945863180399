<template>
	<div class="content-page">
		<slot></slot>
	</div>
</template>
<script>
export default{
	name: "ContentPage"
};
</script>
<style scoped>

.content-page{
	margin: 10px auto;
	width: 90vw;
	height: 90vh;
	border: 2px solid #cecece;
}

@media screen and (max-width: 425px) {
	.content-page {
		width: 98%;
		height: 98%;
		margin: 5px 5px;
	}
}
</style>








