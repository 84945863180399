<template>
	<div class="container-pagina">
		<paginacao-filtro
			@handleShowTable="showFilter"
			@filtrar="filtrarLogs"
			@limpar="limpar"
			:ControlePaginacao="paginacao"
		>
			<template v-slot:filtro>
				<div class="row-filtro" style="width: auto;">
					<div class="column-filtro">
						<div class="row-filtro"  v-for="(filtro, index) in Object.keys(filtros)" :key="index">
							<div class="filtro-card-linha-item">
								<input type="checkbox" :id="filtros[filtro].campoFiltro" v-model="filtros[filtro].selecionado" />
								<label :for="filtros[filtro].campoFiltro">{{filtro}}</label>
							</div>
							<div class="input-group" style="min-width: 200px;">
								<select v-model="filtros[filtro].opcaoSelecionada" @change="selecionouOpcaoFiltro(filtro)">
									<option v-for="(opt, index) in Object.keys(filtros[filtro].opcoes)" 
										:key="`option-${index}`" 
										:value="filtros[filtro].opcoes[opt]"
									>
										{{opt}}  
									</option>
								</select>
							</div>
						</div>
					</div>
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="filtro-card-linha-item">
								<label>Data Inicio</label>
							</div>
							<div class="input-group" style="min-width: 170px;">
								<input type="date" v-model="inputDataI"/>
							</div>
						</div>
						<div class="row-filtro">
							<div class="filtro-card-linha-item">				
								<label>Data Final</label>
							</div>
							<div class="input-group" style="margin-left: 14px;min-width: 170px;">
								<input type="date" v-model="inputDataF"/>
							</div>
						</div>
					</div>
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="filtro-card-linha-item">
								<label>Exibir Logs:</label>
							</div>
							<div class="filtro-card-linha-item">
								<select v-model="size" >
									<option :value="25">25 itens</option>
									<option :value="50">50 itens</option>
									<option :value="100">100 itens</option>
								</select>
							</div>
		
						</div>
						<div class="row-filtro">
							<div class="input-group">
								<input type="text" id="pesquisa-livre" placeholder="Buscar Log por Código" v-model="pesquisaLivre" />
							</div>
						</div>

					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<div id="listaModal" :class="`tabela-container ${!showTable ? 'table-full' : ''}`">
					<div class="tabela-log" v-if="listaLogs.length > 0">
						<table>
							<thead>
								<tr>
									<th style="width:110px">
										Seq.
									</th>
									<th>Usuário</th>
									<th>Cód. {{ filtro.toLowerCase() }}</th>
									<th>Tela</th>
									<th>Ação</th>
									<th>Motivo</th>
									<th>Data</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in listaLogs" :key="index">
									<td style="color: blue; width: 110px;">{{item.cdlog}}</td>
									<td >{{item.cdusuario}} - {{item.nomeusuario}}</td>
									<td>{{item.codigo}}</td>
									<td>{{item.tela}}</td>
									<td>{{item.acao}}</td>
									<td class="campoMotivo" :id="index">
										<span class="tooltipText">{{item.motivo}}</span>
										{{item.motivo}}
									</td>

									<td>{{ formatDate(item.dataalteracao)}}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-else style="display:flex; align-items: center;justify-content: center;flex-direction: column;height: 100%;width: 100%;">
						<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
						<h1>Nenhum log encontrado.</h1>
						<h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
					</div>
				</div>
			</template>
		</paginacao-filtro>		
		
	</div>
</template>
<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro";
export default{
	name: "Logs",
	components: {
		PaginacaoFiltro,
	},
	data() {
		return {
			detail: -1,
			timerFiltroLogs: null,
			inputDataI: "",
			inputDataF: "",
			dataICheck:false,
			dataFCheck:false,
			pesquisaLivre: "",
			filtro: "",
			size: this.$store.state.Logs.paginacao.size,
			filtros: {
				"Telas":{
					campoFiltro: "telas",
					opcoes: {

					},
					selecionado: false,
					opcaoSelecionada: null,
				},
				"Acao": {
					campoFiltro: "acao",
					opcoes: {

					},
					selecionado: false,
					opcaoSelecionada: null,
				}
			},
			showTable:true,
		};
	},
	computed: {
		
		filtrosLogs(){
			return this.$store.state.filtrosLog;
		},
		paginacao(){
			return {
				paginaAtual: this.$store.state.Logs.paginacao.page,
				totalPaginas: this.$store.state.Logs.paginacao.totalPages,
			};
		},
		listaLogs() {
			return this.$store.state.Logs.listaLogs;
		}
	},
	methods: {
		formatDate(dateString) {
			const date = new Date(dateString);
			const day = String(date.getDate()).padStart(2, "0");
			const month = String(date.getMonth() + 1).padStart(2, "0"); // Meses são baseados em zero
			const year = date.getFullYear();
			const hours = String(date.getHours() + 3).padStart(2, "0"); // + 3 por causa do fuso horario
			const minutes = String(date.getMinutes()).padStart(2, "0");
			return `${day}/${month}/${year}, ${hours}:${minutes}`;
		},

		selecionouOpcaoFiltro(filtro) {
			this.filtros[filtro].selecionado = true;
			setTimeout(() => {
				this.filtro = this.filtros[filtro].opcaoSelecionada;
			}, 1000);
		},
		async filtrarLogs(novaPage) {
			clearTimeout(this.timerFiltroLogs);
			this.timerFiltroLogs = setTimeout(async () => {
				let filtro = {
					page: novaPage >= 0 ? novaPage : this.paginacao.page,
					filtroDinamico: {},
					size: this.size ? this.size : 25,
					dataI: this.inputDataI,
					dataF: this.inputDataF,
					codigo: this.pesquisaLivre,

				};
				Object.keys(this.filtros).filter(key => this.filtros[key].selecionado).forEach(key => {
					if(this.filtros[key].campoFiltro != "acao"){
						this.filtro = this.filtros[key].opcaoSelecionada; 
					}
					if (this.filtros[key].opcaoSelecionada != "Selecione" && this.filtros[key].opcaoSelecionada != null) {
						filtro.filtroDinamico[this.filtros[key].campoFiltro] = this.filtros[key].opcaoSelecionada;
					}
				});
				await this.$store.dispatch("buscarLogs", filtro);
				if(!this.filtros.Telas.selecionado){
					this.filtro = "";
				}
			}, 1000);
		},
		limpar(){
			this.dataICheck = false;
			this.dataFCheck = false;
			this.size = 25;
			this.inputDataI = "";
			this.inputDataF = "";
			this.pesquisaLivre = "";
			this.filtro = "";
			this.filtros = {
				"Telas":{
					campoFiltro: "telas",
					opcoes: {

					},
					selecionado: false,
					opcaoSelecionada: null,
				},
				"Acao": {
					campoFiltro: "acao",
					opcoes: {

					},
					selecionado: false,
					opcaoSelecionada: null,
				}
			};
			this.filtrarLogs(null);
		},
		showFilter(){
			this.showTable = !this.showTable;
		},
	},
	mounted() {
		this.$store.dispatch("buscarLogs");
		this.$store.dispatch("buscarFiltrosLogs").then(()=>{
			clearTimeout(this.timerFiltroLogs);
			this.timerFiltroLogs = setTimeout(() => {
				this.filtrosLogs.tela.forEach(tela =>{
					this.filtros.Telas.opcoes[tela] = tela;
				});
				this.filtrosLogs.acao.forEach(acao =>{
					this.filtros.Acao.opcoes[acao] = acao;
				});
				this.filtros.Telas.opcaoSelecionada = this.filtrosLogs.tela[0];
				this.filtros.Acao.opcaoSelecionada = this.filtrosLogs.acao[0];
			}, 500);
		});
	}
};
</script>
<style scoped>
.container-pagina{
	width: 100vw;
	height: 90vh;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
}

/*FILTROS*/
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;
	margin-top: 5px;
}
.column-filtro{
	display: flex;
	flex-direction: column;
}
#filtros-fieldset {
	padding-left: 10px;
	border-left: none;
	border-right: none;
	border-bottom: none;
	border-color: var(--primario);
}

#filtros-fieldset legend {
	padding: 0px 5px;
}

.filtro-card-container{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0px 5px;
	width: 100%;
}
.filtro-card-linha{
	display: flex;
	flex-direction: row;
	margin: 5px 0px;
}
.filtro-card-linha .filtro-card-linha-item:nth-child(1){
	min-width: 150px;
	width: 100%;
	flex-grow: 1;
}
.filtro-card-linha-item{
	display: flex;
	flex-direction: row;
	align-items: center;
}
.filtro-card-linha-item input[type="checkbox"]{
	margin: 0px 5px;
	cursor: pointer;
}

.filtro-card-linha-item label{
	margin: 0px 5px;
	cursor: pointer;
}

.filtro-card-linha-item select{
	background-color: var(--bg-app);
	border: none;
	border-radius: 5px;
	box-shadow: 1px 1px 3px #292929;
	text-align: left;
	max-width: 550px;
	user-select: none;
}

.filtro-card-linha-item select:focus {
	user-select: none;
	border: 1px solid var(--primario);
}

.filtro-card-busca {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0px;
	margin-right: 5px;
	flex-wrap: wrap;
	flex-grow: 1;
}


.filtro-card-busca #pesquisa-livre {
	margin: 0px 5px;
	min-width: 280px;
	width: 100%;
}

.filtro-card-busca .filtro-card-linha:nth-child(2){
	flex-grow: 1;
}

.paginacao {
	margin: 0px 5px;
}
.filtro-card-container.esconder {
	opacity: 0;
	transition-duration: 250ms;
	transition-property: all;
	transition-timing-function: ease-in-out;
}


.esconder.oculto {
	display: none;
	transition-delay: 250ms;
}

/*TABELA DE LOGS*/
#listaModal {
    width: 95%;
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;
	height: 55vh;
}

#listaModal.table-full{
	height: 75vh !important;
}


/*TOOL TIP*/
.tooltipText{
	visibility: hidden;
	width: auto;
	white-space: nowrap;
	background-color: black;
	color: #fff;
	text-align: center;
	padding: 0 5px;
	border-radius: 6px;

	position: absolute;
	right: 30px;
	z-index: 1;
}
.campoMotivo:hover .tooltipText{
  visibility: visible;

}


.tabela-log table{
	width: 100%;
	padding: 5px;
}

.tabela-log .campoMotivo{
	max-width: 250px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.activeDetail{
	text-align: left !important;
	white-space: wrap !important;
}

.tabela-log table thead tr th,
.tabela-log table tbody tr td{	
	width: auto;
	padding: 5px;
	min-width: 110px;
	text-align: center;
	white-space: wrap;
}
@media(max-width: 710px){

}
@media screen and (max-width: 768px) {
	#listaModal.table-full{
		height: 68vh !important;
	}

	#listaModal{
		height: 42vh !important;
	}
}
@media screen and (max-width: 425px) {
	#listaModal.table-full{
		height: 71vh !important;
	}

	#listaModal{
		height: 30vh !important;
	}
}
</style>