<template>
	<div id="listaModal" :class="`${!showTable ? 'table-full' : ''}`">
		<div class="table-container" v-if="listaTransferencias.length > 0">
			<table>
				<thead v-if="listaTransferencias.length > 0">
					<tr>
						<th>
							Cód.
							<i class="fas fa-sort"  style="color:blue" @click="ordenarLista('id')" ></i>
						</th>
						<th>
							Ação
							
						</th>
						<th>
							Data
							<i class="fas fa-sort"  style="color:blue" @click="ordenarLista('data')" ></i>
						</th>
						<th>
							Cód. Empresa Destino
							<i class="fas fa-sort"  style="color:blue" @click="ordenarLista('cdempresadestino')" ></i>
						</th>
						<th>Nome do Usuário</th>
						<th>Tipo da Transferência</th>
						<th>Situação</th>
						<th>Qtd. Produtos</th>
					</tr>
				</thead>	
				<tbody>
					<tr v-for="(transferencia, index) in listaTransferencias" :key="index"  >
						<td style="color: blue" @click="editarTransferencia(transferencia, index)">{{transferencia.cdtransferencia}}</td>
						<td >
							<i class="fas fa-trash" style="color: #f00" @click="solicitarExcluirTransferencia(transferencia)" v-if="transferencia.situacao != 'CONFIRMADO'"></i>
							<i class="fas fa-print" style="color: var(--primario)"
								@click="solicitarImpressao(transferencia)" v-if="transferencia.situacao === 'CONFIRMADO'"></i>
						</td>
						<td @click="editarTransferencia(transferencia, index)">{{transferencia.data.split('-').reverse().join('/')}}</td>
						<td @click="editarTransferencia(transferencia, index)">{{transferencia.cdempresadestino}}</td>
						<td @click="editarTransferencia(transferencia, index)">{{transferencia.nomeUsuario}}</td>
						<td @click="editarTransferencia(transferencia, index)">{{transferencia.tipo === "N" ? "NORMAL" : "VENDA"}}</td>
						<td @click="editarTransferencia(transferencia, index)">{{transferencia.situacao}}</td>
						<td @click="editarTransferencia(transferencia, index)">{{transferencia.qtdTotal}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div v-else class="col center" id="warning">
			<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
			<h1>Nenhum registro encontrado.</h1>
			<h2>Tente alterar ou diminuir os parâmetros de busca.</h2>
		</div>
	</div>
</template>
<script>

export default{
	props: {
		showTable: Boolean,
	},
	data(){
		return{
			ordenar: "",
			sort: "",
			timerFiltrarTransferencia: null,
			paginacao: {
				size: 25,
				pageAtual: 0,
				totalPages: 0,
			},
		};
	},
	computed:{
		listaTransferencias(){
			return this.$store.state.transferenciaEstoque.listaTransferencias;
		},
		paginacaoTransferencias(){
			return this.$store.state.transferenciaEstoque.paginacao;
		},
	},
	methods:{

		solicitarImpressao(transferencia){
			this.$store.commit("relatarErro", {
				mensagem: "Deseja imprimir o relatório em A4?",
				tipo: "polar",
				item: {
					component: this.$options._componentTag,
					params: transferencia,
					relatorio: true,
				}
			});

		},
		imprimirRelatorio({pageWidth}, transferencia){
			this.$store.dispatch("gerarComprovanteTransferencia", {pageWidth, transferencia});
		},
		solicitarExcluirTransferencia(transferencia){
			this.$store.commit("relatarErro", {
				mensagem: `Tem certeza que deseja excluir a transferência Cód. ${transferencia.cdtransferencia}?`,
				tipo: "polar",
				item: {
					acao: this.excluirTransferencia,
					params: transferencia,
					component: this.$options._componentTag,
				}
			});
		},
		async excluirTransferencia(transferencia){
			try {
				if(transferencia.situacao == "DIGITACAO"){
					await this.$store.dispatch("deleteTransferencia", transferencia.cdtransferencia);
					await this.$store.dispatch("buscarTransferenciaEstoque");
					this.$store.commit("relatarErro", {mensagem: `Transferência Cód ${transferencia.cdtransferencia} excluida com sucesso!`, sucess: true});
				}
				
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
			}

		},
		polarConfirmado(item){
			if (item.component == this.$options._componentTag) {
				if(item.relatorio){
					this.imprimirRelatorio({
						pageWidth: "A4",
					}, item.params);
				}
				if(item.acao ? typeof item.acao == "function" : false){
					item.acao(item.params);
				}
			}
		},
		cancelarPolar(item){
			if(item.relatorio){
				this.imprimirRelatorio({
					pageWidth: null,

				}, item.params);
			}
		},
		async ordenarLista(campo){
			this.ordenar = !this.ordenar;
			this.sort = campo;
			var ordenarInt = {
				ordem: this.ordenar ? "asc" : "desc",
				sort: this.sort,
			};
			this.$emit("ordenarLista",ordenarInt);
		},
		abrirCadastro(){
			this.$emit("abrirCadastro");
		},
		incluirTransferencia(){
			this.$emit("incluirTransferencia");
		},
		editarTransferencia(transferencia){
			this.$emit("selecionarTransferencia", transferencia);
		}
	},
	mounted(){
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		this.$bus.$on("cancelarPolar", this.cancelarPolar);

	},
	destroyed() {
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
		this.$bus.$off("cancelarPolar", this.cancelarPolar);
	},
	
};

</script>
<style scoped>
.table-container
{
	overflow: auto;
	height: 100%;
	width: 100%;
}
.table-container table
{
	width: 100%;
	margin: auto 0;
	padding: 0 10px;
	min-width: 1000px;

}

.table-container table thead tr th,
.table-container table tbody tr td

{
	width: auto;
	max-width: 150px;
	text-align: center;
	white-space: wrap;
}

#listaModal {
    width: 95%;
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;
	height: 55vh !important;
}

#listaModal.table-full{
	height: 77vh !important;
}

@media screen and (max-width: 1024px) {
	#listaModal.table-full{
		height: 68vh !important;
	}

	#listaModal{
		height: 25vh !important;
	}
}
@media screen and (max-width: 768px) {
	#listaModal.table-full{
		height: 65vh !important;
	}

	#listaModal{
		height: 15vh !important;
	}
}

@media screen and (max-width: 425px) {
	#listaModal.table-full{
		height: 65vh !important;
	}

	#listaModal{
		height: 25vh !important;
	}
}
</style>