import {doRequest} from "./http.js";
import { 
	FluxoCaixa, 
} from "../models/FluxoCaixa";
export default {
	state: {
		FluxoCaixa: {
			ListaFluxoCaixa: [],
			paginacao: {
				totalPages: 0,
				page: 0,
				totalElements: 0,
				size: 25,
			},
		},
	},

	actions: {
		async buscarFluxoCaixa({state, commit}, payload) {
			try {
				state.carregando = true;
				const { empresa, dataI, dataF, conta, centroCusto } = payload;

				if(!empresa) throw new Error("É necesário escolher uma empresa!");
				if(!dataI) throw new Error("É necesário escolher uma data inicial!");
				if(!dataF) throw new Error("É necesário escolher uma data Final!");

				let url = "relatorio-financeiro/fluxo-caixa";

				url += `?empresa=${empresa}`;
				url += `${dataI?"&dataI="+dataI:""}`;
				url += `${dataF?"&dataF="+dataF:""}`;
				url += `${conta?"&agencia="+conta.agencia:""}`;
				url += `${conta?"&banco="+conta.banco:""}`;
				url += `${conta?"&conta="+conta.conta:""}`;
				url += `${centroCusto?"&centroCusto="+centroCusto:""}`;
				state.carregando = true;
				const data = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);
				state.carregando = false;
				

				if(data && !data.erro) {
					commit("atualizarListaFluxoCaixa", data);
					state.carregando = false;
					return;
				}
				throw new Error(
					data 
						? data.mensagem 
							? data.mensagem 
							: data.details || JSON.stringify(data) 
						: ""
				);
			} catch (error) {
				commit("relatarErro", {
					mensagem: error.message
				});
				return null;
			}
		},
		async buscarDetalhesFluxoCaixa({state, commit}, payload){
			try {
				state.carregando = true;
				const { dataI, dataF, conta, centroCusto, pesquisa } = payload;
				if(!dataI) throw new Error("É necesário escolher uma data inicial!");
				if(!dataF) throw new Error("É necesário escolher uma data Final!");

				let url = "relatorio-financeiro/fluxo-details";

				url += `?pesquisa=${pesquisa}`;
				url += `${dataI?"&dataI="+dataI:""}`;
				url += `${dataF?"&dataF="+dataF:""}`;
				url += `${conta?"&agencia="+conta.agencia:""}`;
				url += `${conta?"&banco="+conta.banco:""}`;
				url += `${conta?"&conta="+conta.conta:""}`;
				url += `${centroCusto?"&centroCusto="+centroCusto:""}`;
				state.carregando = true;
				const data = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);
				state.carregando = false;
				

				if(data && !data.erro) {
					state.carregando = false;
					return data;
				}
				throw new Error(
					data 
						? data.mensagem 
							? data.mensagem 
							: data.details || JSON.stringify(data) 
						: ""
				);
			} catch (error) {
				commit("relatarErro", {
					mensagem: error.message
				});
				return null;
			}
		},
	},

	mutations: {
		atualizarListaFluxoCaixa(state, payload){
			if(payload?.length > 0){
				state.FluxoCaixa.ListaFluxoCaixa = payload?.map(fluxo =>  new FluxoCaixa(fluxo));
			} else {
				state.FluxoCaixa.ListaFluxoCaixa = [];
			}
		},
	}
};