<template>
	<container-page>
		<content-page>
			<div class="header-page">
				<div class="container-input-import">
					<input 
						type="file"
						id="import-input"
						@change="handleSelectFile"
					/>
					<label for="import-input" class="label-import">
						<i class="fas fa-file-download"></i>
						<span v-if="!arquivoSelecionado">
							Selecionar arquivo
						</span>
						<span v-else>
							{{ arquivoSelecionado.name }}
						</span>
					</label>
				</div>
				<div class="input-group">
					<select
						v-model="contaSelecionada"
						placeholder=" "
						id="conta"
					>
						<option selected disabled>
							SELECIONE UMA OPÇÃO
						</option>
						<option
							v-for="(conta, index) in Contas"
							:key="index"
							:value="conta"
						>
							{{ conta.nome }}
						</option>
					</select>
					<label for="conta">
						Conta
					</label>
				</div>
				<button
					class="btn-import"
					v-if="arquivoSelecionado"
					@click="realizarImportacao"
				>
					Importar
				</button>
			</div>
			<div
				class="container-tabela"
				v-if="Transactions && Transactions.length > 0 ? true : false"
			>
				<div class="header-tabela">
					<div class="linha-header-tabela">
						<div class="campo-tabela campo-pequeno">Ação</div>
						<div class="campo-tabela campo-maior">N° Movimentação</div>
						<div class="campo-tabela campo-medio">Observação</div>
						<div class="campo-tabela campo-valor">Valor</div>
						<div class="campo-tabela campo-data">Data</div>
						<div class="campo-tabela campo-metodo">Método Pagamento</div>
						<div class="campo-tabela campo-pequeno">Tipo</div>
					</div>
				</div>
				<div class="body-tabela">
					<div
						class="linha-body-tabela"
						v-for="(transaction, index) in Transactions"
						:key="index"
						:class="transaction && transaction.cddoc ? 'title-lancado' : ''"
					>
						<div class="campo-tabela campo-pequeno">
							<i
								class="fas fa-edit"
								@click="handleEditTitle(transaction)"
							/>
							<i class="fas fa-return" />
						</div>
						<div class="campo-tabela campo-maior">
							{{ transaction.nmrMovimentacao }}
						</div>
						<div class="campo-tabela campo-medio">
							{{ transaction.observacao }}
						</div>
						<div class="campo-tabela campo-valor">
							{{ transaction.valor | formatarParaReais }}
						</div>
						<div class="campo-tabela campo-data">
							{{ transaction.data.split("-").reverse().join("/") }}
						</div>
						<div class="campo-tabela campo-metodo">
							{{ transaction.metodoPgto }}
						</div>
						<div class="campo-tabela campo-pequeno">
							{{ 
								transaction.tipo === 3 ? "DESPESA" : "RECEBIMENTO"
							}}
						</div>
					</div>
				</div>
			</div>
		</content-page>
		<Modal
			v-if="alterarTitulo"
			@fecharModal="fecharModal"
			:dimensoes="{
				width: '80%',
				height: '80%'
			}"
			:idModal="'modal-conciliacao'"
			
		>
			<AdicionarTitulo
				@salvouTitulo="setSavedTransaction"
				:Titulo="TituloAlterar"
				:tipoTitulo="TituloAlterar.tipo"
				@fecharLancamentoTitulo="fecharModal"
			/>
		</Modal>
	</container-page>
</template>
<script>
import ContainerPage from "./ContainerPage.vue";
import formatarParaReais from "../filters/formatarParaMoeda";
import ContentPage from "./ContentPage.vue";
import Modal from "./Modal.vue";
import AdicionarTitulo from "./AdicionarTitulo.vue";

export default {
	name: "Conciliacao",
	components: {
		ContainerPage,
		ContentPage,
		Modal,
		AdicionarTitulo
	},

	filters: {
		formatarParaReais
	},

	data() {
		return {
			arquivoSelecionado: null,
			alterarTitulo: false,
			TituloAlterar: {
				importacao: true
			},
			contaSelecionada: "SEL"
		};
	},

	computed: {
		Transactions () {
			return this.$store.state?.Financeiro?.Importacao?.Transactions || [];
		},
		Contas () {
			return this.$store.state.Contas;
		}
	},

	destroyed() {
		this.$store.state.Financeiro.Conciliacao.Transactions = [];
	},

	methods: {
		async setSavedTransaction(title) {
			const cddoc = this.removerEspacos(title.cddoc.substring(0, 20));

			const moviEncontrada = this.Transactions?.find(
				transaction => {
					const nmrMovi = this.removerEspacos(transaction.nmrMovimentacao.substring(0, 20));
					
					return nmrMovi === cddoc;
				}
			);
			if(moviEncontrada) {
				const { conta } = title;
				moviEncontrada.cdconta = conta.cc;
				moviEncontrada.banco = conta.banco;
				moviEncontrada.agencia = conta.agencia;

				moviEncontrada.cddig = title.cddig;
				moviEncontrada.tipo = title.tipo;
	
				moviEncontrada.cddoc = cddoc;
			}
		},

		fecharModal() {
			this.alterarTitulo = false;
			this.TituloAlterar = {};
		},

		// async isRegistredTransaction(transaction) {
		// 	try {
		// 		const data = await this.$store.dispatch("findTransactionByValorExtenso", {
		// 			cddoc: this.removerEspacos((transaction?.nmrMovimentacao || "").substring(0, 20))
		// 		});
		// 		if(data && !data.financeiroId) {
		// 			this.handleEditTitle(transaction);
		// 		} else {
		// 			this.$store.commit("relatarErro", {
		// 				mensagem: "Transação está cadastrada no sistema, por favor verifique!",
		// 				alerta: true,
		// 			});
		// 		}
		// 	} catch (error) {
		// 		console.error(error);
		// 	}
		// },

		async handleEditTitle(transaction) {

			if(transaction.cddig) {
				const res = await this.$store.dispatch("buscarContaUnica", transaction);
				this.alterarTitulo = true;
				this.TituloAlterar = {
					valorParcela: res.valorTitulo,
					valorTitulo: res.valorTitulo,
					observacao: res.observacao,
					cddoc: res.cddoc,
					valorextenso: res.valorextenso,
					tipo: res.tipo == 3 ? "despesa" : "receita",
					databaixa: res.databaixa,
					datadoc: res.datadoc,
					datavenc: res.datavenc,
					competencia: res.competencia,
					situacaoTitulo: "B",
					conta: res.conta,
					planoContas: res.planoContas,
					cdTipoPgto: res.cdTipoPgto,
					cdPessoa: res.cdPessoa,
					nome: res.nome,
					origem: res.origem,
				};
				return;
			}

			const dataTransaction = transaction.data;
			const dtCompetencia = `${dataTransaction.split("-")[0]}-${dataTransaction.split("-")[1]}`;
			this.alterarTitulo = true;
			this.TituloAlterar = {
				valorParcela: (transaction.valor || 0).toLocaleString("pt-BR", {
					style: "decimal",
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				}),
				valorTitulo: transaction.valor,
				observacao: transaction.nmrMovimentacao,
				cddoc: this.removerEspacos(`${transaction?.nmrMovimentacao || ""}`?.trim().substring(0, 20)),
				valorextenso: this.removerEspacos((transaction?.nmrMovimentacao || "")?.substring(0, 20)),
				tipo: transaction.tipo === 3 ? "despesa" : "receita",
				databaixa: dataTransaction,
				datadoc: dataTransaction,
				datavenc: dataTransaction,
				competencia: dtCompetencia,
				situacaoTitulo: "B",
				conta: this.contaSelecionada,
			};
		},

		removerEspacos(str) {
			return str.replace(/\s/g, "");
		},

		handleSelectFile(e) {
			const file = e.target.files[0];
			if(!file?.name?.endsWith(".ofx")) {
				return this.$store.commit("relatarErro", {
					mensagem: "Arquivo inválido, por favor selecione um arquivo com a extensão '.ofx'"
				});
			} else {
				this.arquivoSelecionado = file;
			}
		},

		realizarImportacao() {
			if(!this.arquivoSelecionado) {
				return;
			}
			const reader = new FileReader();

			reader.onloadend = () => {
				const dataURL = reader.result;
				
				const base64Data = dataURL.split(",")[1];
				this.$store.dispatch("realizarProcessamentoOFX", base64Data);
			};
			reader.readAsDataURL(this.arquivoSelecionado);
		},
	}
};
</script>
<style scoped>
.header-page{
	width: 100%;
	padding: 20px;
	display: flex;
	align-items: center;
	gap: 10px;
}

.btn-import {
	width: auto;
	border: 1px solid #cecece;
	background: #FFF;
	padding: 5px 20px;
	font-size: 16px;
	border-radius: 8px;
	transition: all .2s ease-in;
}

.btn-import:hover {
	background: var(--acao-background);
	color: #FFF;
	border: 1px solid var(--acao-borda);
}

#import-input{
	display: none;
}

.label-import {
	display: flex;
	align-items: center;
	border: 1px solid #cecece;
	padding: 5px 10px;
	border-radius: 8px;
}
.label-import i {
	color: var(--primario);
	font-size: 18px;
} 


.container-tabela{
	width: 89vw;
	overflow: auto;
	height: 73vh;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 2px 5px;
	font-size: 13px;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}

.campo-maior {
	width: 325px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.campo-medio {
	width: 220px;
	margin-left: 10px;
}
.campo-data,
.campo-valor {
	width: 150px;
	text-align: center;
}
.campo-pequeno {
	width: 125px;
	text-align: center;
}

.campo-metodo{
	width: 150px;
	text-align: center;
}
.fa-edit {
	color: var(--primario);
}
.title-lancado{
	background-color:  #6170f779 !important;
	color: #FFF;
}
</style>
