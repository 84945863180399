import { render, staticRenderFns } from "./TabelaConsorcio.vue?vue&type=template&id=18dc2f06&scoped=true"
import script from "./TabelaConsorcio.vue?vue&type=script&lang=js"
export * from "./TabelaConsorcio.vue?vue&type=script&lang=js"
import style0 from "./TabelaConsorcio.vue?vue&type=style&index=0&id=18dc2f06&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18dc2f06",
  null
  
)

export default component.exports