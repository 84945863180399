import Vue from "vue";

Vue.directive("mascaraInteger", el => {

	el.onkeydown = function (e){
		const regex = /[^0-9]/;
		const regexKey = [
			"Backspace",
			"ArrowRight",
			"ArrowLeft",
			"Delete",
			"End",
			"Home",
			"Tab"
		];
		if (regex.test(e.key) && !regexKey.includes(e.key)) {
			e.preventDefault();
		}
	};
});

Vue.directive("mascaraFloat", el => {
	el.onkeydown = function (e){
		const regex = /[0-9,]/;
		const regexKey = [
			"Backspace",
			"ArrowRight",
			"ArrowLeft",
			"Delete",
			"End",
			"Home",
			"Tab"
		];
		if (!regex.test(e.key) && !regexKey.includes(e.key)) {
			e.preventDefault();
		}
		if ((`${e.target.value}`.includes(",") || !`${e.target.value}`.length) && e.key == ","){
			e.preventDefault();
		}
	};
});