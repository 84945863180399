<template>
    <div id="Vendedores">
        <h1 class="titulo">Dados do Vendedor</h1>
		<div class="container-page"> 
			<div class="camposCadastro">	
				<div class="cadastroLinha">
					<div class="input-group" style="width: auto;" v-for="(key, index) in Object.keys(camposLinha01)" :key="key">
						<input 
							v-model="vendedorCadastrar[key]" :id="key" 
							:type="camposLinha01[key].type" autocomplete="off" 
							:class="camposLinha01[key].class || 'input-numero'"
							placeholder=" "
							:disabled="camposLinha01[key].disabled || false"
							@keydown.enter="proximoCampo(
								(index +1) == Object.keys(camposLinha01).length ?
								Empresas.length > 1 ? 'cdempresa' : Object.keys(camposLinha02)[0] : 
								Object.keys(camposLinha01)[index+1]
							)"
						>
						<label :for="key">{{camposLinha01[key].label}}&nbsp;</label>
					</div>
					<div class="input-group" id="codigoEmpresa" v-if="Empresas.length > 1">
						<select id="cdempresa" v-model="vendedorCadastrar.cdempresa" @keydown.enter="proximoCampo(Object.keys(vendedorCadastrar.camposLinha02)[0])">
							<option :value="null">Todas</option>
							<option v-for="empresa in Empresas" :key="empresa.id" :value="empresa.id">{{empresa.id}} - {{empresa.fantasia}}</option>
						</select>
						<label for="cdempresa">Empresa&nbsp;</label>
					</div>
					<div class="agroup">
						<div class="input-group-switch">
							<div id="ativo" @click="mudarAtivo('ativo', 'A', 'I')">
								<label for="ativo">Ativo:&nbsp;</label>
									<i class="far fa-times-circle" v-if="vendedorCadastrar.ativo == 'I' " style="color: red;"> Não</i>
									<i class="far fa-check-circle" v-else style="color: green;"> Sim</i>
							</div>
						</div>
					</div>
				</div>
				<div class="cadastroLinha">
					<div>
						<div class="cadastroLinha" id="comissoes">
							<div class="input-group" v-for="(key, index) in Object.keys(camposLinha02)" :key="key">
								<input 
									v-model="vendedorCadastrar[key]" class="input-numero" :id="key" 
									:type="camposLinha02[key].type" :placeholder="camposLinha02[key].placeholder"
									@keydown.enter="proximoCampo(
										(index +1) == Object.keys(camposLinha02).length ?
										Object.keys(camposLinha01).filter(key => !camposLinha01[key].disabled)[0] : 
										Object.keys(camposLinha02)[index+1]
									)"
									autocomplete="off"
									v-mascaraFloat
								>									
								<label :for="key">{{camposLinha02[key].label}}&nbsp;</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        <div class="botoes">
            <button class="botao botao-cancelar" id="botaoCancelar" @click="cancelarEdicao">
				<label for="botaoCancelar" class="lg-view">Cancelar [Alt + C]</label>
				<label for="botaoCancelar" class="sm-view">Cancelar</label>
			</button>
            <button class="botao botao-adicionar" id="botaoAdicionar" @click="editarVendedor">
				<label for="botaoAdicionar" class="lg-view">Salvar [Alt + S]</label>
				<label for="botaoAdicionar" class="sm-view">Salvar</label>
			</button>
        </div>
    </div>
</template>

<script>
class VendedorComponent {
	constructor(vendedor) {
		this.id = vendedor.id;
		this.nome = `${vendedor.nome || ""}`.toUpperCase();
		this.ativo = vendedor.ativo;
		this.cargo = vendedor.cargo;
		this.cdempresa = vendedor.cdempresa;
		this.maxPorcentagemDesconto = vendedor.maxPorcentagemDesconto;
		this.maxNumeroParcelas = vendedor.maxNumeroParcelas;
		this.minValorParcela = vendedor.minValorParcela;
		this.maxDiasTroca = vendedor.maxDiasTroca;
		this.comissaoVista = vendedor.comissaoVista ? Number(vendedor.comissaoVista).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}) : "";
		this.comissaoPrazo = vendedor.comissaoPrazo ? Number(vendedor.comissaoPrazo).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}) : "";
		this.comissaoConsorcio = vendedor.comissaoConsorcio ? Number(vendedor.comissaoConsorcio).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}) : "";
		if (vendedor.parametros) {
			this.parametros = vendedor.parametros;
		} else {
			this.parametros = {};
		}
	}
}
class VendedorStore {
	constructor(vendedor) {
		this.id = vendedor.id;
		this.nome = `${vendedor.nome}`.toUpperCase();
		this.ativo = vendedor.ativo;
		this.cargo = vendedor.cargo || "VENDEDOR";
		this.cdempresa = vendedor.cdempresa;
		this.comissaoVista = Number(`${vendedor.comissaoVista}`);
		this.comissaoPrazo = Number(`${vendedor.comissaoPrazo}`);
		this.comissaoConsorcio = Number(`${vendedor.comissaoConsorcio}`);
		this.parametros = vendedor.parametros;
	}
}
export default {
	name: "Vendedores",

	props: {
		vendedor: Object,
	},

	computed: {
		Empresas() {
			return this.$store.state.Empresas;
		}
	},

	data() {
		return {
			vendedorCadastrar: new VendedorComponent({cargo: "VENDEDOR", cdempresa: this.$store.state.Empresa.id}),
			camposLinha01: {
				"id": {
					type: "text",
					label: "Cód.",
					placeholder: " ",
					id: "id",
					disabled: true,
				},
				"nome": {
					type: "text",
					label: "Nome",
					placeholder: " ",
					id: "nome",
					disabled: false,
					class: "input-flex"
				}
			},
			camposLinha02: {
				"comissaoVista" : {
					type: "text",
					label: "% Comissão á vista",
					placeholder: " ",
				},
				"comissaoPrazo": {
					type: "text",
					label: "% Comissão á prazo",
					placeholder: " "
				},
				"comissaoConsorcio": {
					type: "text",
					label: "% Comissão consórcio",
					placeholder: " "
				}
			},
		};
	},

	methods: {
		converterCamposLinha01() {
			Object.keys(this.camposLinha01).forEach(key => {
				this.vendedorCadastrar[key] = `${this.vendedorCadastrar[key]}`.toUpperCase();
			});
		},
		converterCamposLinha02() {
			Object.keys(this.camposLinha02).forEach(key => {
				this.vendedorCadastrar[key] = Number(`${this.vendedorCadastrar[key]}`.replace(".", "").replace(",", "."));
			});
		},
		async editarVendedor() {
			if (!this.vendedorCadastrar.nome) {
				return this.$store.commit("relatarErro", {mensagem: "Campo nome não pode estar vazio"});
			}
			let vendedorCadastrado = null;
			this.converterCamposLinha01();
			this.converterCamposLinha02();
			if (this.vendedor && this.vendedor.novo) {
				vendedorCadastrado = await this.$store.dispatch("adicionarVendedor", new VendedorStore({...this.vendedorCadastrar}));
			} else if (JSON.stringify(this.vendedorCadastrar) != JSON.stringify(this.vendedor)) {
				vendedorCadastrado = await this.$store.dispatch("atualizarVendedor", new VendedorStore({...this.vendedorCadastrar, id: this.vendedor.id}));
			}
			if (vendedorCadastrado?.id) {
				this.cancelarEdicao();
			}
		},

		mudarAtivo() {
			if (this.vendedorCadastrar.ativo == "A") {
				this.vendedorCadastrar.ativo = "I";
			} else {  
				this.vendedorCadastrar.ativo = "A";
			}
		},

		proximoCampo(idCampo) {
			setTimeout(() => {
				const campo = document.getElementById(idCampo);
				if (campo?.focus) {
					campo.focus();
					if (campo.select) {
						campo.select();
					}
				}
			}, 10);
		},

		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},
	
		iniciarComponente() {
			if (this.vendedor && !this.vendedor.novo) {
				this.vendedorCadastrar = new VendedorComponent(this.vendedor);
			} else {
				this.vendedorCadastrar = new VendedorComponent({id: null, cargo: "VENDEDOR", ativo: "A", cdempresa: this.$store.state.Empresa.id});
			}
		}
	},

	mounted(){
		this.iniciarComponente();
	},
}; 
 
</script>   

<style scoped>
#Vendedores {
    width: auto;
    margin: 0px 10px;
	margin-top: 5px;
    overflow-y: hidden;
    height: auto;
    display: flex;
    flex-direction: column;
	flex-grow: 1;
}

/* CADASTRO */


.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}

.botoes .botao {
	width: 45%;
}
/* ----- */
.container-page{
	height: 92vh;
	width: 100%;
	overflow-y: hidden;
	
}
.camposCadastro{
	width: 100%;
	height: 92vh;
	display: flex;	
	flex-direction: column;
}
.cadastroLinha{
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.cadastroLinha .input-group-switch{
	margin-top: 20px;
	margin-left: 15px;
}

.lg-view {
	display: inline-block;
}

.sm-view {
	display: none;
}

#id {
	width: 8vw;
}

#nome {
	width: 38vw;
}

#codigoEmpresa {
	width: 20vw;
}

#comissoes {
	width: 85vw;
}
#comissoes .input-group {
	width: 25vw;
}

#comissoes input {
	text-align: right;
}

.cadastroLinha .input-group {
	margin-top: 15px;
}


@media screen and (max-width: 1024px) {
	.cadastroLinha .input-group {
		width: 100%;
	}

	#comissoes .input-group{
		width: 30%;
	}
}

@media screen and (max-width: 768px) {
	.titulo {
		font-size: 18pt;
	}

	.cadastroLinha .input-group {
		width: 100%;
	}

	#comissoes .input-group{
		width: 30%;
	}

	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	.botoes {
		gap: 20px;
	}	

	#id {
		width: 10vw;
	}

	#codigoEmpresa {
		width: 30vw;
	}
}

@media screen and (max-width: 440px) {
	.titulo {
		font-size: 18pt;
	}

	.input-group {
		width: 100% !important;
	}

	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	.botoes {
		gap: 20px;
	}	

	.container-page {
		overflow: auto;
	}

	::-webkit-scrollbar {
		display: none;
	}

	.cadastroLinha #comissoes {
		width: 100%;
	}

	#nome {
		width: 100%;
	}
}

</style>