<template>
	<article class="GerenciaPerfis" v-if="!perfilSelecionado">
		<fieldset id="filtros-fieldset">
			<legend @click="esconderFiltro">
				<i class="fa fa-angle-right" v-if="!mostraFiltro" style="color: var(--primario);"></i>
				<i class="fa fa-angle-down" v-else style="color: var(--primario)"></i>
				Filtros
			</legend>
			<div class="filtro-card-controle">
				<div class="filtro-card-filtros">
					<div class="filtro-card-linha" v-for="(filtro, index) in Object.keys(filtros)" :key="index">
						<div class="filtro-card-linha-item">
							<input type="checkbox" :id="filtros[filtro].campoFiltro" @change="filtrarPerfis(null)"
								v-model="filtros[filtro].selecionado" :value="true">
							<label :for="filtros[filtro].campoFiltro">{{ filtro }}</label>
						</div>
						<div class="filtro-card-linha-item">
							<select v-model="filtros[filtro].opcaoSelecionada" @change="selecionouOpcaoFiltro(filtro)">
								<option value="Selecione">Selecione</option>
								<option v-for="(opt, index) in Object.keys(filtros[filtro].opcoes)"
									:key="`option-${index}`" :value="filtros[filtro].opcoes[opt]">{{ opt }}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="filtro-card-busca">
					<div class="filtro-card-linha">
						<div class="filtro-card-linha-item">
							<label for="size">Exibir</label>
							<select id="size" v-model="paginacao.size" @change="filtrarPerfis(null)">
								<option :value="25">25 itens</option>
								<option :value="50">50 itens</option>
								<option :value="100">100 itens</option>
							</select>
						</div>
						<div class="paginacao" v-if="paginacao.totalPaginas > 0">
							<span v-if="paginacao.paginaAtual > 1" @click="filtrarPerfis(0)">1</span>
							<span @click="filtrarPerfis(paginacao.paginaAtual - 1)" v-if="paginacao.paginaAtual > 0">
								{{ paginacao.paginaAtual }}
							</span>
							<span class="ativa">
								{{ paginacao.paginaAtual + 1 }}
							</span>
							<span @click="filtrarPerfis(paginacao.paginaAtual + 1)"
								v-if="paginacao.paginaAtual + 1 < paginacao.totalPaginas">
								{{ paginacao.paginaAtual + 2 }}
							</span>
							<span v-if="paginacao.paginaAtual + 2 < paginacao.totalPaginas"
								@click="filtrarPerfis(paginacao.totalPaginas - 1)">{{ paginacao.totalPaginas }}</span>
						</div>
					</div>
					<div class="filtro-card-linha">
						<input type="text" id="pesquisa-livre" placeholder="Busca de perfis" v-model="pesquisaLivre" @input="filtrarPerfis(null)">
						<button class="botao botao-acao" @click="selecionarPerfil(null)">
							<i class="far fa-plus-square" style="color:var(--acao-texto)"></i>
							<span>Incluir</span>
						</button>
					</div>
				</div>
			</div>
		</fieldset>
		<div class="card-lista-overflow">
			<div class="card-lista">
				<div v-for="perfil in ListaPerfis.content" :key="perfil.nome" class="card-item">
					<div class="item-foto">
						<i class="icon fas fa-user"></i>
					</div>
					<div class="info">
						<p>{{perfil.id}} - {{perfil.descricao}}</p>
						<p>
							Ativo:
							<i style="color: var(--confirmar)" v-if="perfil.ativo == 'A'" class="fa fa-check"> Sim</i>
							<i class="fa fa-times" style="color:var(--cancelar)" v-else> Não</i>
						</p>
						<P>{{perfil.cargo}}</P>
					</div>
					<div v-if="perfil.parametros != null" class="info-adicionais">
						<p>
							Libera cliente:
							<i style="color: var(--confirmar)" v-if="perfil.parametros.liberaCliente == 'S'" class="fa fa-check"> Sim</i>
							<i class="fa fa-times" style="color:var(--cancelar)" v-else> Não</i>
						</p>
						<p>
							Libera desconto:
							<i style="color: var(--confirmar)" v-if="perfil.parametros.liberaDesconto == 'S'" class="fa fa-check"> Sim</i>
							<i class="fa fa-times" style="color:var(--cancelar)" v-else> Não</i>
						</p>
					</div>
					<div v-else class="info-adicionais">
						<p>Perfil sem parametros.</p>
					</div>
					<div class="icons">
						<i class="icon fas fa-pencil-alt" @click="selecionarPerfil(perfil)"></i>
						<i class="icon fas fa-eye" @click="desativarOuAtivarItem(perfil)" style="color: var(--primario)" v-if="perfil.ativo == 'A'"></i>
						<i class="icon fa fa-eye-slash" @click="desativarOuAtivarItem(perfil)" style="color:var(--primario)" v-else></i>
						<i class="fa fa-trash" @click="deletarItem(perfil)" style="color: var(--cancelar)"></i>
					</div>
				</div>
				
			</div>
		</div>
	</article>
	<article v-else class="GerenciaPerfis">
		<div class="dados-container">
			<div class="dados-linha">
				<div class="input-group dados-item">					
					<input :value="perfilCadastrar.id" disabled="disabled" type="text" maxlength= "10" id="id" class="input-numero">
					<label for="id">Cód.&nbsp;</label>
				</div>
				<div class="input-group dados-item">
					<input v-model="perfilCadastrar.descricao" type="text" id="descricao" class="input-flex" @keydown.enter="proximoCampo('maxDiasTroca')">
					<label for="descricao">Nome&nbsp;</label>					
				</div>
				<div id="ativo" @click="mudarAtivo('ativo', 'A', 'I')" class="campos">
					<label class="w-30" for="ativo"> Ativo:</label>
					<i class="far fa-check-circle " v-if="perfilCadastrar.ativo == 'A'" style="color:green;"> Sim</i>
					<i class="far fa-times-circle " v-else style="color: red;"> Não</i>
				</div>
			</div>
			<div class="dados-linha wrap">
				<div class="input-group dados-item">					
					<input v-model="perfilCadastrar.maxDiasTroca" type="text" id="maxDiasTroca" @keydown.enter="proximoCampo('maxNumeroParcelas')"
					@input="validarEntradaInteger">
					<label for="maxDiasTroca" v-if="ehcelular()">Prazo troca&nbsp;</label>
					<label for="maxDiasTroca" v-if="!ehcelular()">Prazo para troca&nbsp;</label>
				</div>
				<div class="input-group dados-item">					
					<input v-model="perfilCadastrar.maxNumeroParcelas" type="text" id="maxNumeroParcelas" @keydown.enter="proximoCampo('maxPorcentagemDesconto')" 
					@input="validarEntradaInteger">
					<label for="maxNumeroParcelas" v-if="ehcelular()">Máx. parcelas&nbsp;</label>
					<label for="maxNumeroParcelas" v-if="!ehcelular()">Máximo de parcelas&nbsp;</label>
				</div>
				<div class="input-group dados-item">					
					<input v-model="perfilCadastrar.maxPorcentagemDesconto" type="text" id="maxPorcentagemDesconto" @keydown.enter="proximoCampo('minValorParcela')"
					@input="validarEntradaFloat">
					<label for="maxPorcentagemDesconto" v-if="ehcelular()">% máx. desconto&nbsp;</label>
					<label for="maxPorcentagemDesconto" v-if="!ehcelular()">% máximo de desconto&nbsp;</label>
				</div>
				<div class="input-group dados-item">					
					<input v-model="perfilCadastrar.minValorParcela" type="text" id="minValorParcela"
					@input="validarEntradaFloat">
					<label for="minValorParcela" v-if="ehcelular()">Valor mín. parcela&nbsp;</label>
					<label for="minValorParcela" v-if="!ehcelular()">Valor mínimo por parcela&nbsp;</label>
				</div>
			</div>
		</div>
		<ParametrosUsuarios :Parametro="perfilCadastrar.parametros" @passarDados="preencherParametros"/>
		<div class="botoes buttons">
			<button class="botao botao-cancelar" @click="zerarData"><i class="fas fa-times icone-cancelar"></i>Cancelar <span>(Alt + c)</span></button>
			<button class="botao botao-adicionar" @click="salvar"><i class="fa fa-check icone-salvar"></i>Salvar <span>(Alt + s)</span></button>
		</div>
	</article>
</template>

<script>
import ParametrosUsuarios from "../components/ParametrosUsuarios.vue";

export default {
	name: "GerenciaPerfis",

	components: {
		ParametrosUsuarios,
	},
	computed:{
		ListaPerfis(){
			return this.$store.state.ListaPerfis;
		}
	},
	data(){
		return {
			pesquisaLivre: "",
			mostraFiltro: true,
			filtros: {
				"Situação": {
					campoFiltro: "situacao",
					opcoes: {
						"Ativo": "A",
						"Inativo": "I",
						"Ambos": null,
					},
					selecionado: false,
					opcaoSelecionada: null
				}
			},
			paginacao: {
				size: 25,
				paginaAtual: 0,
				totalPaginas: 0
			},
			perfilCadastrar: {
				id: null,
				descricao: "",
				ativo: "A",
				maxNumeroParcelas: "1",
				maxDiasTroca: "0",
				maxPorcentagemDesconto: "0",
				minValorParcela: "0",
				parametros: {}
			},
			perfilSelecionado: null,
		};
	},
	methods:{
		validarEntradaInteger(evento) {
			const id = evento.target.id;
			const valor = evento.data;
			const regex = /[0-9]/;
			if (!regex.test(valor) && valor != null) {
				this.perfilCadastrar[id] = this.perfilCadastrar[id].substring(0, this.perfilCadastrar[id].length -1);
			}
		},
		validarEntradaFloat(evento) {
			const id = evento.target.id;
			const valor = evento.data;
			const regex = /[0-9,.]/;
			if (!regex.test(valor) && valor != null) {
				this.perfilCadastrar[id] = this.perfilCadastrar[id].substring(0, this.perfilCadastrar[id].length -1);
			}
		},
		proximoCampo(idCampo) {
			setTimeout(() => {
				const campo = document.getElementById(idCampo);
				if (campo?.focus) {
					campo.focus();
					if (campo.select) {
						campo.select();
					}
				}
			}, 10);
		},
		mudarAtivo(campo, letra1, letra2){
			if(this.perfilCadastrar[campo] == letra1){
				this.perfilCadastrar[campo] = letra2;
			} else{
				this.perfilCadastrar[campo] = letra1;
			}
		},
		preencherParametros(param){
			this.perfilCadastrar.parametros = param;
		},
		zerarData() {
			this.perfilCadastrar = {
				descricao: "",
				ativo: "A",
				maxNumeroParcelas: "1",
				maxDiasTroca: "0",
				maxPorcentagemDesconto: "0",
				minValorParcela: "0",
				parametros: {}
			};
			this.perfilSelecionado = null;
		},
		async salvar(){
			if (this.perfilCadastrar.ativo == "A") {
				if (!this.perfilCadastrar.descricao) {
					return this.$store.commit("relatarErro", {mensagem: "Favor informar o nome do perfil e tente novamente!"});
				}
			}
			let perfilCadastrado = null;
			if (this.perfilSelecionado?.novo) {
				perfilCadastrado = await this.$store.dispatch("cadastrarPerfil", {...this.perfilCadastrar});
			} else {
				perfilCadastrado = await this.$store.dispatch("atualizarPerfil", {...this.perfilCadastrar, id: this.perfilSelecionado.id});
			}
			if (perfilCadastrado?.id) {
				await this.filtrarPerfis(0);
				this.zerarData();
			}
		},

		selecionarPerfil(perfil){
			if (!perfil){
				this.perfilSelecionado = {novo: true};
			} else {
				this.perfilCadastrar.id = perfil.id;
				this.perfilCadastrar.descricao = perfil.descricao;
				this.perfilCadastrar.ativo = perfil.ativo;
				this.perfilCadastrar.maxDiasTroca = `${perfil.maxDiasTroca || 0}`;
				this.perfilCadastrar.maxNumeroParcelas = `${perfil.maxNumeroParcelas || 0}`;
				this.perfilCadastrar.maxPorcentagemDesconto = Number(`${perfil.maxPorcentagemDesconto || 0}`).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.perfilCadastrar.minValorParcela = Number(`${perfil.minValorParcela || 0}`).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.perfilCadastrar.parametros = perfil.parametros;
				this.perfilSelecionado = {...perfil};
			}
		},
		desativarOuAtivarItem(perfil){
			var ativoInativo = perfil.ativo == "A" ? "desativar" : "ativar";
			this.$store.commit("relatarErro", {
				mensagem: `Tem certeza que deseja ${ativoInativo} o perfil ${perfil.descricao}?`,
				tipo: "polar",
				item: {
					desativarPerfil: perfil.ativo == "A",
					ativarPerfil: perfil.ativo == "I",
					perfil
				}
			});
		},
		
		deletarItem(perfil){
			this.$store.commit("relatarErro", {
				mensagem: `Tem certeza que deseja excluir o perfil ${perfil.descricao}?`,
				tipo: "polar",
				item: {
					deletarPerfil: true,
					perfil
				}
			});
		},

		confirmouPolar(item){
			if (item?.perfil) {
				const {perfil, deletarPerfil, desativarPerfil, ativarPerfil} = item;
				if (deletarPerfil) {
					this.$store.dispatch("deletarPerfil", perfil);
				} else if(desativarPerfil){
					this.$store.dispatch("atualizarPerfil", {...perfil, ativo: "I"});
				} else if(ativarPerfil){
					this.$store.dispatch("atualizarPerfil", {...perfil, ativo: "A"});
				}
			}
		},

		esconderFiltro(){
			let elemento = document.getElementById("filtros-fieldset");
			elemento = elemento.children[1];
			if (elemento.classList.contains("esconder")){
				elemento.classList.remove("oculto");
				setTimeout(() =>{
					elemento.classList.remove("esconder");
				}, 255);
				this.mostraFiltro = true;
			} else {
				elemento.classList.add("esconder");
				this.mostraFiltro = false;
				setTimeout(() =>{
					elemento.classList.add("oculto");
				}, 255);
			}
		},

		async buscarPerfis() {
			await this.$store.dispatch("buscarPerfis");
			this.paginacao.size = this.ListaPerfis.size;
			this.paginacao.paginaAtual = this.ListaPerfis.paginaAtual;
			this.paginacao.totalPaginas = this.ListaPerfis.totalPaginas;
		},

		async filtrarPerfis(novaPagina) {
			clearTimeout(this.timer);
			this.timer = setTimeout(async () => {
				let filtro = {
					page: novaPagina >= 0 ? novaPagina : this.paginacao.paginaAtual,
					size: this.paginacao.size,
					query: this.pesquisaLivre,
					filtroDinamico: {}
				};
				Object.keys(this.filtros).filter(key => this.filtros[key].selecionado).forEach(key => {
					if (this.filtros[key].opcaoSelecionada != "Selecione" && this.filtros[key].opcaoSelecionada != null) {
						filtro.filtroDinamico[this.filtros[key].campoFiltro] = this.filtros[key].opcaoSelecionada;
					}
				});
				await this.$store.dispatch("filtrarPerfis", filtro);
				this.paginacao.size = this.ListaPerfis.size;
				this.paginacao.paginaAtual = this.ListaPerfis.paginaAtual;
				this.paginacao.totalPaginas = this.ListaPerfis.totalPaginas;
			}, 420);
		},

		selecionouOpcaoFiltro(filtro) {
			this.filtros[filtro].selecionado = true;
			this.filtrarPerfis(null);
		},

		ehcelular(){
			if(screen.width < 450){
				return true;
			}
			return false;
		},
	},
	mounted(){
		this.$bus.$on("confirmarPolar", this.confirmouPolar);
	},

	beforeMount(){
		this.buscarPerfis();
	},

	beforeDestroy(){
		this.$store.commit("limparListaPerfis");
		this.$bus.$off("confirmarPolar", this.confirmouPolar);
	}

};
</script>

<style scoped>
.GerenciaPerfis {
	display: flex;
	flex-direction: column;
	padding: 10px;
	flex-grow: 1;
	height: 100%;
	overflow-y: hidden;
}

/* FILTRO */
#filtros-fieldset{
	padding-left: 10px;
	border-left: none;
	border-right: none;
	border-bottom: none;
	border-color: var(--primario);
	width: 100%;
}
.filtro-card-controle{
	display: flex;
	flex-direction: row;
	transition-duration: 250ms;
	transition-property: all;
	transition-timing-function: ease-in-out;
	opacity: 1;
	align-items: center;
}
.filtro-card-controle input{
	padding: 0 5px 0 5px;
}
#filtros-fieldset legend {
	cursor: pointer;
	user-select: none;
}
.filtro-card-controle.esconder {
	opacity: 0;
	transition-duration: 250ms;
	transition-property: all;
	transition-timing-function: ease-in-out;
}
.esconder.oculto {
	display: none;
	opacity: 0;
	transition-delay: 250ms;
}
.filtro-card-linha-item input[type="checkbox"] {
	margin: 0px 5px;
	cursor: pointer;
}
.filtro-card-linha{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 5px 0px;
}
.filtro-card-linha-item{
	display: flex;
	flex-direction: row;
	align-items: center;
}
.filtro-card-linha .filtro-card-linha-item:nth-child(1) {
	min-width: 200px;
	max-width: 300px;
	flex-grow: 1;
}
.filtro-card-filtros{
	display: flex;
	flex-direction: column;
	margin: 0px 5px;
}
.filtro-card-busca .filtro-card-linha:nth-child(2) {
	flex-grow: 1;
}
.filtro-card-busca{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0px;
	flex-wrap: wrap;
	flex-grow: 1;
}
.filtro-card-busca .filtro-card-linha:nth-child(2) {
	flex-grow: 1;
}
.filtro-card-linha-item label{
	margin: 0px 5px;
	cursor: pointer;
}
.filtro-card-linha-item select {
	background-color: var(--bg-app);
	border: none;
	border-radius: 5px;
	box-shadow: 1px 1px 3px #292929;
	text-align: left;
	max-width: 550px;
	user-select: none;
}
.filtro-card-linha-item select:focus {
	user-select: none;
	border: 1px solid var(--primario);
}
.filtro-card-busca #pesquisa-livre{
	margin: 0px 5px;
	min-width: 280px;
	width: 100%;
}
.filtro-card-linha-item select{
	background-color: var(--bg-app);
	border-radius: 5px;
	box-shadow: 1px 1px 3px #292929;
	text-align: left;
	max-width: 550px;
}
.icon{
	color:black;
}

/* LISTA */
.card-lista-overflow{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	flex-grow: 1;
	overflow-y: auto;
	padding-bottom: 10px;
}
.card-lista{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap:10px;
	margin: 0px 5px;
}
.card-lista .card-item {
	display: flex;
	flex-direction: row;
	grid-template-columns: repeat(2, 1fr);
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	width: 100%;
	height: auto;
	border-radius: 1rem;
	box-shadow: 0 0.1rem 0.5rem rgb(0 0 0 / 30%);
	margin-top: 10px;
}
.card-lista .card-item:hover {
	box-shadow: 0.2rem 0.2rem 0.5rem rgb(0 0 0 / 50%);
}
.card-lista .item-foto {
	margin: 5px;
}
.card-lista .item-foto i{
	font-size: 2.3rem;
}
.card-lista .card-item p {
	white-space: nowrap;
	font-size: 18px;
}
.card-lista .card-item .info{
	text-align: left;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin: 0px 2px;
}
.card-lista .card-item .info-adicionais{
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.card-lista .card-item .icons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 5px;
	opacity: 0;
	transition-duration: 220ms;
	transition-property: all;
	transition-timing-function: ease-in-out;
}
.card-lista .card-item:hover .icons {
	opacity: 1;
}
.card-lista .card-item .icons i {
	margin: 0.3rem;
	cursor: pointer;
	font-size: 1.3rem;
}
.card-lista .card-item .icons i:nth-child(1){
	color: var(--confirmar);
}
.card-lista .card-item .icons i:nth-child(2){
	color: var(--confirmar);
}

/* CADASTRO */
.dados-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.dados-container .dados-linha {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}
.dados-container .dados-linha:nth-child(2) .warp .dados-item{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 5px;
	white-space: nowrap;
	width: 24.2%;
}
.dados-container .dados-item {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: auto;
	margin: 5px;
	white-space: nowrap;
}
.dados-container .dados-linha #ativo{
	width: auto;
	flex-wrap: nowrap;
	white-space: nowrap;
}
.dados-container .dados-linha:nth-child(1) .dados-item:nth-child(2) {
	width: 100%;
}
.dados-container .dados-linha .dados-item .input-numero {
	max-width: 90px;
	min-width: 60px;
}
.dados-container .dados-linha .dados-item.wrap {
	flex-wrap: wrap;
}
.dados-container .input-flex {
	width: 100%;
	text-transform: uppercase;
}

.buttons{
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}

.buttons .icone-salvar{
	color:#198a19;
}

.buttons .icone-cancelar{
	color:#d11515;
}

.buttons button{
	width: 49%;
	margin-top: 5px;
}

@media screen and (max-width: 1200px){
	.card-lista{
		display: block !important;
	}
}

@media screen and (max-width: 840px){
	.filtro-card-controle{
		flex-wrap: wrap;
	}
	.buttons button span{
		display: none;
	}
	.filtro-card-filtros {
		flex-grow: 1;
	}
	.filtro-card-filtros .filtro-card-linha-item:nth-child(2) {
		flex-grow: 1;
		justify-content: flex-end;
	}
	.filtro-card-busca #pesquisa-livre {
		min-width: unset;
	}
	.filtro-card-linha .filtro-card-linha-item:nth-child(1) {
		min-width: unset;
	}
	.card-lista{
		display: block !important;
		height: 25vh;
	}
}

@media screen and (max-width: 640px){
	.card-lista .card-item .icons i{
		margin-left: 0.5rem;
	}

	.filtro-card-linha .filtro-card-linha-item:nth-child(1) {
		min-width: unset;
	}
	.card-lista .card-item{
		display: flex !important;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		height: 40vh;
		width: 100%;
		padding: 20px;
	}
	.card-lista .card-item .info{
		text-align: center;
	}

	.card-lista .card-item .icons{
		display: inline-block;
		text-align: left;
	}
	.box-funcionalidades{
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	.buttons button i{
		display: none;
	}
	.buttons{
		align-items: center;
	}
	.dados-container .dados-linha:nth-child(2) .dados-item {
		width: 100%;
	}
	.dados-container .dados-linha:nth-child(2) .dados-item .input-numero {
		width: 100%;
		max-width: unset;
	}
}

@media screen and (max-width: 450px){
	.filtro-card-busca .filtro-card-linha:nth-child(1) {
		flex-wrap: wrap;
	}
	.filtro-card-busca .filtro-card-linha:nth-child(1) .paginacao {
		justify-content: flex-start;
		margin: 0px;
		margin-top: 5px;
		width: 100%;
	}

	.box-funcionalidades{
		display: block !important;
	}
	.dados-container{
		display: flex;
		flex-direction: column;
		align-items: left;
		max-width: 100%;
		justify-content: left;
	}
	.dados-container .nome-ativo input[type='text']{
		width: 100%;
	}

	.dados-container .dados-linha:nth-child(1) {
		flex-wrap: wrap;
	}
	.dados-container .dados-linha:nth-child(2) .dados-item {
		order: 3;
	}

	.dados-container .dados-linha:nth-child(1) .dados-item:nth-child(2){
		width: 70%;
	}

	.dados-container .dados-linha:nth-child(2) .dados-item{
		width: 46%;
	}

	label, input {
		font-size: 14px;
		height: 20px;
	}
}
</style>