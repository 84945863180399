<template>
	<div id="app">
		<Erros v-if="mensagemErro !== null" :Conteudo="mensagemErro" />
		<Carregando v-if="carregando" />
		<NavBar id="NavBar" texto="BDS Sistemas" />
		<Autenticacao v-if="!Usuario" />
		<main id="routerView" v-else-if="Usuario">
			<router-view></router-view>
		</main>
		<InformativoFlutuante v-if="Informativo" />
		<Impressao v-if="base64" />
	</div>
</template>

<script>
import NavBar from "./components/NavBar";
import "@fortawesome/fontawesome-free/css/all.css";
import Erros from "./components/Erros.vue";
import Carregando from "./components/Carregando.vue";
import Autenticacao from "./components/Autenticacao.vue";
import InformativoFlutuante from "./components/InformativoFlutuante.vue";
import Impressao from "./pages/Impressao.vue";
import "./App.css";

export default {
	name: "App",

	components: {
		NavBar,
		Erros,
		Carregando,
		Autenticacao,
		InformativoFlutuante,
		Impressao
	},

	computed: {
		Usuario() {
			return this.$store.state.Usuario;
		},

		Empresa() {
			return this.$store.state.Empresa;
		},

		Caixa() {
			return this.$store.state.Caixa;
		},

		mensagemErro() {
			return this.$store.state.mensagemErro;
		},

		carregando() {
			return this.$store.state.carregando;
		},
		base64() {
			return this.$store.state.base64;
		},
		Informativo() {
			return this.$store.state.Informativo;
		}
	},
	created() {
		window.onbeforeunload = function () {
			return "Dude, are you sure you want to leave? Think of the kittens!";
		};
		document.addEventListener("keydown", (event) => {
			if (`${event.key}`.toUpperCase() == "J" && event.ctrlKey) event.preventDefault();
		});
	},

	destroyed() {
		window.onbeforeunload = null;
	}
};
</script>

<style>
#app {
	min-height: 100vh;
	max-width: 100vw;
	height: auto;
	width: auto;
	display: grid;
	grid-template-rows: 10vh 90vh;
	grid-template-columns: 1fr;
	background: var(--bg-app);
}

#NavBar {
	grid-row: 1/2;
	grid-column: 1/2;
	height: 10vh;
	display: flex;
}

#routerView {
	height: 90vh;
	grid-row: 2/3;
	grid-column: 1/2;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
