<template>
	<div class="card">
		<span class="line"></span>
		<div :class="`card-body ${loading ? 'loading' : ''}`">
			<span class="value-view-details" v-if="!loading">
				<span class="value">
					{{
						value || 0
					}}
				</span>
				<i
					:class="iconClasses[label]"
					style="color: var(--primario)"
					@click="emitirEventoDetails(label)"
				/>
			</span>
			<span v-else class="skeleton_value"></span>
			<span class="text" v-if="!loading">
				{{label}}
			</span>
			<span v-else class="skeleton_label"></span>
		</div>
	</div>
</template>
<script>
export default {
	name: "CardDashboard",
	props: {
		label: {
			type: String,
			default: ""
		},
		value: [Number, String],
		loading: Boolean
	},

	computed: {
		possuiDetalhes() {
			return this.label === "TOTAL VENDAS" || this.label === "VENDAS CLIENTE DEVEDOR" ? true : false;
		},

		iconClasses() {
			return {
				"TOTAL VENDAS": "fas fa-dollar-sign fa-lg",
				"VENDAS CLIENTE DEVEDOR": "fas fa-user-tag fa-lg",
				"NOVOS CLIENTES": "fas fa-user-plus fa-lg",
				"ITENS VENDIDO": "fas fa-boxes fa-lg",
				"TOTAL NOTAS EMITIDAS": "fas fa-file-contract fa-lg",
				"VENDA S/CADASTRO": "fas fa-user-times fa-lg",
				"PRODUTOS P/ATENDIMENTO": "fas fa-box-open fa-lg",
				"QUANT. DEVOLUÇÃO": "fas fa-cart-arrow-down fa-lg",
				"QUANT. VENDAS": "fas fa-cart-plus fa-lg",
				"QUANT. CONDICIONAL": "fas fa-shopping-bag fa-lg",
				"TICKET MÉDIO": "fas fa-chart-bar fa-lg"
			};
		}
	},

	methods: {
		emitirEventoDetails(label) {
			if(label === "VENDAS CLIENTE DEVEDOR") {
				this.$emit("buscarVendasClienteDevedor");
			} else if(label === "TOTAL VENDAS") {
				this.$emit("buscarVendasDetalhadas");
			}
		}
	}
};
</script>
<style scoped>
.card{
	width: 235px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
	padding: 0px 5px;
	flex-grow: 1;
}
.card-body {
	display: flex;
	flex-direction: column;
	padding: 3px 20px;
}
.line{
	width: 5px;
	height: 40px;
	background: var(--acao-background);
	
}

.card .value {
	font-size: 13px;
	font-weight: 600;
	color: var(--acao-background);
	transition: animation .5s ease-in;
	animation: marginLeft .5s;
	width: 150px;
}

.value-view-details{
	display: flex;
	align-items: center;
}


@keyframes marginLeft {
	from {
		margin-left: 200px;
		opacity: 0;
	}
	to {
		margin-left: 0px;
		opacity: 1;
	}
}
.card .text {
	font-size: 10px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 1px;
	color: var(--text-light);
	transition: animation .5s ease-in;
	animation: marginLeft .5s;
}

.skeleton_value{
	width: 150px;
	height: 10px;
	background: #cecece;
	animation: skeleton-loading 1s linear infinite alternate;
	border-radius: 10px;
}
.skeleton_label{
	width: 100px;
	height: 10px;
	background: #cecece;
	margin-top: 10px;
	animation: skeleton-loading 1s linear infinite alternate;
	border-radius: 10px;
}
.loading {
	gap: 10px;
	padding: 20px;
}
@keyframes skeleton-loading {
	0% {
		background-color: hsl(200, 20%, 80%);
	}
	100% {
		background-color: hsl(200, 20%, 95%);
	}
}

@media screen and (max-width: 1360px) {
	.card{
		width: 200px;
	}
}

</style>