export class EntradaItem {
	constructor(item) {
		if (!item) return;
		this.cdempresa = item.cdempresa;
		this.cdfornecedor = item.cdfornecedor;
		this.custo = item.custo;
		this.cdreferencia = item.cdreferencia;
		this.cdproduto = item.cdproduto;
		this.codbarraFornecedor = item.codbarraFornecedor;
		this.ncm = item.ncm;
		this.cdseq = item.cdseq;
		this.cfop = item.cfop;
		this.cofins = item.cofins;
		this.valorcofins = item.cofinsrs;
		this.descricao = item.descricao;
		this.frete = item.frete;
		this.valorfrete = item.freters;
		this.icms = item.icms;
		this.valoricms = item.icmsrs;
		this.icmsst = item.icmssubs;
		this.valoricmsst = item.icmssubsrs;
		this.ipi = item.ipi;
		this.valoripi = item.ipirs;
		this.outros = item.outros;
		this.valoroutros = item.outrosrs;
		this.pis = item.pis;
		this.valorpis = item.pisrs;
		this.quantidade = item.qtdecompra;
		this.unidade = item.unidade;
		this.vlrcompra = item.vlrcompra;
		this.vlrunitario = item.vlrunitario;
		this.desconto = item?.desconto;
		if(this.desconto) {
			this.percDesc = (this.desconto * 100) / this.vlrcompra;
		}
	}
}

export class EntradaItemAPI {
	constructor(produto) {
		this.cdseq = produto.cdseq;
		this.codbarraFornecedor = produto.codbarraFornecedor;
		this.ncm = produto.ncm;
		this.cdreferencia = produto.cdreferencia || produto.id;
		this.cfop = produto.cfopentrada || produto.cfop;
		this.cdorigem = produto.cdorigem;
		this.cofins = produto.cofins;
		this.cofinsrs = produto.valorcofins;
		this.descricao = `${produto.descricao}`.substring(0,100);
		this.frete = typeof(produto.frete) == "number" ? Number(Number(produto.frete).toFixed(4)) : Number(`${produto.frete || 0}`.split(".").join("").replace(",", "."));
		this.freters = typeof(produto.valorfrete) == "number" ? Number(Number(produto.valorfrete).toFixed(4)) : Number(`${produto.valorfrete || 0}`.split(".").join("").replace(",", "."));
		this.icms = produto.icms;
		this.icmsrs = produto.valoricms;
		this.icmssubs = typeof(produto.icmsst) == "number" ? Number(Number(produto.icmsst).toFixed(4)) : Number(`${produto.icmsst || 0}`.split(".").join("").replace(",", "."));
		this.icmssubsrs = typeof(produto.valoricmsst) == "number" ? produto.valoricmsst : Number(`${produto.valoricmsst || 0}`.split(".").join("").replace(",", "."));
		this.ipi = typeof(produto.ipi) == "number" ? produto.ipi : Number(`${produto.ipi || 0}`.split(".").join("").replace(",", "."));
		this.ipirs = typeof(produto.valoripi) == "number" ? produto.valoripi : Number(`${produto.valoripi || 0}`.split(".").join("").replace(",", "."));
		this.outros = produto.FCPST ? (
			((typeof(produto.valoroutros) == "number" ? produto.valoroutros : Number(`${produto.valoroutros || 0}`.split(".").join("").replace(",", ".")))
			+ produto.valorFCPST) / produto.vlrunitario *100
		) : Number(`${produto.outros}`.split(".").join("").replace(",", "."));
		this.outrosrs = produto.FCPST ? (
			(typeof(produto.valoroutros) == "number" ? produto.valoroutros : Number(`${produto.valoroutros || 0}`.split(".").join("").replace(",", ".")))
			+ produto.valorFCPST
		) : typeof(produto.valoroutros) == "number" ? produto.valoroutros : Number(`${produto.valoroutros || 0}`.split(".").join("").replace(",", "."));
		this.pis = produto.pis;
		this.pisrs = produto.valorpis;
		this.qtdecompra = typeof(produto.quantidade) == "number" ? produto.quantidade : Number(`${produto.quantidade || 1}`.split(".").join("").replace(",", "."));
		this.unidade = produto.unidade;
		this.vlrcompra = produto.vlrcompra;
		this.vlrunitario = produto.vlrunitario;
		this.desconto = produto?.desconto;
		// this.percDesc = produto?.percDesc;
	}
}

export class EntradaEstoque {
	constructor(item) {
		this.saldo = item?.saldo || item?.produto?.saldo;
		this.cdseq = item.cdseq;
		this.cdempresa = item.cdempresa;
		this.cdcompra = item.cdcompra;
		this.cdproduto = item.cdproduto;
		this.codinter = item.produto?.codinter;
		this.cdagrupamento = item.cdagrupamento;
		this.cdreferencia = item.cdreferencia;
		this.cdfornecedor = item.cdfornecedor;
		this.quantidade = (item.qtde || 0);
		this.descricao = item.descricao || item.produto?.descricao;
		this.ncm = item.ncm || item.produto?.ncm;
		this.vlrunitario = item.vlrunitario;
		this.vlrcompra = item.vlrcompra;
		this.icms = item.icms;
		this.valoricms = item.valoricms;
		this.ipi = item.ipi;
		this.valoripi = item.ipirs;
		this.frete = item.frete;
		this.valorfrete = item.freters;
		this.outros = item.outros;
		this.valoroutros = item.outrosrs;
		this.desconto = item.desconto;
		this.valordesconto = item.descontors;
		this.cfop = item.cfop;
		this.icmssubs = item.icmsst;
		this.valoricmsst = item.icmsstrs;
		this.pis = item.pis;
		this.valorpis = item.pisrs;
		this.cofins = item.cofins;
		this.valorcofins = item.cofinsrs;
		this.markup = item.markup;
		this.prazo = item.prazo;
		this.cdesc = item.descvista;
		this.descvista = item.descTabela ? item.descTabela : item.descvista ;
		this.vista = item.vista;
		this.status = item.status;
		this.cdpai = item.cdpai;
		this.ecf = item.ecf == "S";

		this.cdlote = item.cdlote;
		this.codbarraFornecedor = item.codbarraFornecedor || item.produto?.codbarraFornecedor;
		this.cdgrupo = item.produto?.cdgrupo;
		this.cdsubgrupo = item.produto?.cdsubgrupo;
		this.codbarra = item.produto?.codbarra;
		this.custo = item.produto?.custo;
		this.custofixo = item.produto?.custofixo;
		this.customedio = item.produto?.customedio;
		this.cfopesta = item.produto?.cfopesta;
		this.cfopinter = item.produto?.cfopinter;
		this.unidade = item.produto?.unidade;
		this.variacao = item.produto?.variacao;
		this.grade = item.produto?.grade;
		this.descresumido = item.produto?.descresumido;
		this.tipo = item?.produto?.tipo;
	}
}

export class EntradaEstoqueAPI {
	constructor(produto) {
		this.cdlote = produto.cdlote;
		this.cdproduto = produto.cdproduto;
		this.cdempresa = produto.cdempresa;
		this.cdreferencia = produto.cdreferencia || produto.cdproduto;
		this.cdfornecedor = produto.cdFornecedor;
		this.codbarraFornecedor = produto.codbarraFornecedor;
		this.ncm = produto.ncm;
		this.basesittrib = produto.basesittrib;
		this.cdseq = produto.cdseq;
		this.codinter = produto.codinter;
		this.cdorigem = produto.cdorigem;
		this.qtde = produto.quantidade;
		this.cdpai = produto.cdpai;
		this.cdagrupamento = produto.cdagrupamento;
		this.cfop = produto.cfopentrada || produto.cfop;
		this.descresumido = produto.descresumido;
		this.vlrunitario = typeof(produto.vlrunitario) == "number" ? produto.vlrunitario : Number(`${produto.vlrunitario || 0}`.split(".").join("").replace(",", "."));
		this.cofins = produto.cofins;
		this.cofinsrs = produto.valorcofins;
		this.frete = typeof(produto.frete) == "number" ? Number(Number(produto.frete).toFixed(4)) : Number(`${produto.frete || 0}`.split(".").join("").replace(",", "."));
		this.freters = typeof(produto.valorfrete) == "number" ? Number(Number(produto.valorfrete).toFixed(4)) : Number(`${produto.valorfrete || 0}`.split(".").join("").replace(",", "."));
		this.desconto = typeof(produto.desconto) == "number" ? Number(Number(produto.desconto).toFixed(5)) : Number(`${produto.valorfrete || 0}`.split(".").join("").replace(",", "."));
		this.descontors = typeof(produto.valordesconto) == "number" ? Number(Number(produto.valordesconto).toFixed(5)) : Number(`${produto.valordesconto || 0}`.split(".").join("").replace(",", "."));
		this.icms = produto.icms;
		this.icmsrs = produto.valoricms;
		this.icmssst = typeof(produto.icmsst) == "number" ? Number(Number(produto.icmsst).toFixed(4)) : Number(`${produto.icmsst || 0}`.split(".").join("").replace(",", "."));
		this.icmsstrs = typeof(produto.valoricmsst) == "number" ? produto.valoricmsst : Number(`${produto.valoricmsst || 0}`.split(".").join("").replace(",", "."));
		this.ipi = typeof(produto.ipi) == "number" ? produto.ipi : Number(`${produto.ipi || 0}`.split(".").join("").replace(",", "."));
		this.ipirs = typeof(produto.valoripi) == "number" ? produto.valoripi : Number(`${produto.valoripi || 0}`.split(".").join("").replace(",", "."));
		this.outros = produto.FCPST ? (
			((typeof(produto.valoroutros) == "number" ? produto.valoroutros : Number(`${produto.valoroutros || 0}`.split(".").join("").replace(",", ".")))
			+ produto.valorFCPST) / produto.vlrunitario *100
		) : Number(`${produto.outros}`.split(".").join("").replace(",", "."));
		this.outrosrs = produto.FCPST ? (
			(typeof(produto.valoroutros) == "number" ? produto.valoroutros : Number(`${produto.valoroutros || 0}`.split(".").join("").replace(",", ".")))
			+ produto.valorFCPST
		) : typeof(produto.valoroutros) == "number" ? produto.valoroutros : Number(`${produto.valoroutros || 0}`.split(".").join("").replace(",", "."));
		this.pis = produto.pis;
		this.pisrs = produto.valorpis;
		if (produto.margem) {
			this.markup = typeof(produto.margem) == "number" ? produto.margem : Number(`${produto.margem || 0}`.split(".").join("").replace(",", "."));
		} else {
			this.markup = typeof(produto.markup) == "number" ? produto.markup : Number(`${produto.markup || 0}`.split(".").join("").replace(",", "."));
		}
		this.descvista = produto.descTabela ? Number(`${produto.descTabela}`.replace(",",".")) : typeof(produto.descvista) == "number" ? produto.descvista : Number(`${produto.descvista || 0}`.replace(",", "."));
		this.prazo = typeof(produto.prazo) == "number" ? produto.prazo : Number(`${produto.prazo || 0}`.split(".").join("").replace(",", "."));
		this.vista = typeof(produto.vista) == "number" ? produto.vista : Number(`${produto.vista || 0}`.split(".").join("").replace(",", "."));
	}
}