<template>
	<div class="container-page">
		<paginacao-filtro
			@filtrar="filtrar"
			@limpar="limparFiltro"
			:mostrarBotaoNovo="mostrarBotaoNovo"
			@adicionarNovo="$emit('adicionarNovo')"
			@handleShowTable="handleShowTable"
			:ControlePaginacao="Paginacao"
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">							
							<div class="input-group">
								<input 
									type="search" 
									id="textoPesquisa" 
									name="filtro" 
									placeholder=" " 
									autocomplete="off"
									v-model="filtroPesquisa"
									@keydown.enter.prevent="filtrar"
								/>
								<label for="textoPesquisa" style="background-color: #f8f8f8;">
									Pesquisa
								</label>
							</div>
							<div class="checked">
								<div class="selecionar">
									<label for="js-checkbox-pesquisa" v-if="checkboxPesquisa" class="pointer">
										&nbsp;
										Ativos
									</label>
									<label for="js-checkbox-pesquisa" v-else class="pointer">
										&nbsp;
										Ambos
									</label>
									&nbsp;
									<input
										type="checkbox"
										id="js-checkbox-pesquisa"
										@change="checkboxPesquisa = !checkboxPesquisa"
										:checked="checkboxPesquisa"

									/>
									<label class="check" for="js-checkbox-pesquisa"></label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<TabelaFormasPagamentos 
					v-on:selecionarFormaPagamento="$emit('selecionarFormaPagamento', $event)"
					:showTable="showTable"
				/>
			</template>
		</paginacao-filtro>
	</div>
</template>

<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import TabelaFormasPagamentos from "../components/TabelaFormasPagamento.vue";
export default{
	name: "ConsultaFormasPagamentos",
	components: {
		PaginacaoFiltro,
		TabelaFormasPagamentos,
	},	
	props: {
		mostrarBotaoNovo: Boolean,
	},
	data() {
		return {
			filtroPesquisa: "",
			checkboxPesquisa: true,
			showTable: true,
		};
	},
	computed: {
		Paginacao() {
			return {
				paginaAtual: this.$store.state.paginaFormasPagamentoAtual,
				totalPaginas: this.$store.state.totalPaginasFormasPagamento
			};
		}
	},
	methods: {
		handleShowTable() {
			this.showTable = !this.showTable;
		},
		limparFiltro() {
			this.filtroPesquisa = "";
			this.checkboxPesquisa = true;
			this.focarInputPesquisa();
			this.filtrar(null);
		},
		handleTipoFiltro() {
			this.filtroPesquisa = "";
			this.focarInputPesquisa();
		},
		focarInputPesquisa() {
			setTimeout(() => {
				document.getElementById("textoPesquisa").focus();
			}, 50);
		},
		async filtrar(page) {
			const filtro = {
				page: page || 0,
				checkboxPesquisa: this.checkboxPesquisa,
				query: this.filtroPesquisa,		
			};
			if(filtro.page == 0 && (!filtro.query || filtro.query.trim == "")){
				await this.$store.dispatch("buscarFormasPagamento", filtro);
			}else{
				await this.$store.dispatch("filtrarFormasPagamento", filtro);
			}			
		}
	},
	mounted() {
		this.filtrar();
	}
};
</script>

<style scoped>
.container-page {
	width: 100%;
	overflow: hidden;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;
}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
.checked {
    display: flex;
    align-content: center;
    justify-content: center;
}
.checked .selecionar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.check {
	display: block;
	position: relative;
	width: 60px;
	height: 30px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}
.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}
input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}
#textoPesquisa{
	width: 50vw;
}
@media screen and (max-width: 769px) {
	#textoPesquisa{
		width: 42vh;
	}
}
</style>