<template>
	<div class="cadastroCliente">
		<div class="cliente">
			<div class="dados col ml-10">
				<div class="row" id="divNomeCliente" style="margin:10px" v-if="!this.$store.state.Empresa.validaAdicionalCliente">
					<div id="cdCliente" class="input-group marg-top row w-30">						
						<input type="number" :value="cliente.id" class="w-100" disabled>
						<label class="mx-5 text-right w-auto" for="nome">Código </label>
					</div>
					<div class="input-group input-group-correcao row marg-top" style="width:100%">						
						<input id="nome" type="text" @input="mascara" v-model="cliente.nome" class="w-100"
							autocomplete="off" @keydown.enter.prevent="proximoCampo('tipoPessoa')">
						<label class="mx-5 text-right w-auto" for="nome">Nome </label>
					</div>
				</div>
				<div class="cel row mb-5 h-30px w-100 linha">
					<div class="input-group row w-100 tipoPessoa" id="tipo-pessoa">
						<select @change="clienteCadastrado.cnpj = ''" type="text" v-model="clienteCadastrado.tipoPessoa"
							class="w-100" id="tipoPessoa"
							@keydown.enter.prevent="proximoCampo(clienteCadastrado.tipoPessoa == 'F' ? 'cpf' : 'cnpj')">
							<option value="F">Física</option>
							<option value="J">Jurídica</option>
						</select>
						<label for="tipoPessoa" class="mx-5 text-right w-auto nowrap">Tipo de pessoa</label>
					</div>
					<div class="input-group input-group-correcao row w-100" v-if="clienteCadastrado.tipoPessoa == 'F'">						
						<input id="cpf" type="text" v-model="clienteCadastrado.cnpj" @input="mascara" maxlength="14"
							class="w-100" autocomplete="off" @keydown.enter.prevent="proximoCampo('ierg')">
						<label class="mx-5  text-right" for="cpf">CPF</label>
					</div>
					<div class="input-group input-group-correcao row w-100" v-else>						
						<input id="cnpj" type="text" v-model="clienteCadastrado.cnpj" @input="mascara" maxlength="18"
							class="w-100" autocomplete="off" @keydown.enter.prevent="proximoCampo('ierg')">
							<label class="mx-5  text-right" for="cnpj">CNPJ</label>
					</div>
				</div>
				<div class="cel row mb-5 h-30px w-100 linha">
					<div id="iergControle" class="input-group input-group-correcao row w-100">
						<input type="text" v-model="clienteCadastrado.ierg" class="w-100" autocomplete="off" id="ierg"
							@keydown.enter.prevent="proximoCampo($store.state.Usuario.incluiCliente ? 'buscaClassi' : 'ierg')">
						<label for="ierg" v-if="clienteCadastrado.tipoPessoa === 'F'"
							class="mx-5 w-auto  text-right">RG</label>
						<label for="ierg" class="mx-5 w-100 text-right nowrap" v-else>Inscrição estadual</label>
					</div>
					<div class="input-group input-group-correcao row w-100">
						<input list="classificacao" type="text"  id="buscaClassi" v-model="buscaClassificacao"
						:disabled="!$store.state.Usuario.incluiCliente" @keydown.enter.prevent="proximoCampo(clienteCadastrado.tipoPessoa == 'F' ? 'dataNascimento' : 'fantasia')"
						autocomplete="off" @keydown="filtarClassificacao" style="text-transform: uppercase"/>
						<datalist id="classificacao" name="classificacao">
							<option :value="classi.descricao" v-for="classi in classificacao" :key="classi.cdclassi">
								{{ classi.descricao }}
							</option>
						</datalist>
						<label class="mx-5 text-right w-auto" for="buscaClassi">Classificação</label>
					</div>
				</div>
				<div class="cel row mb-5 h-30px w-100 linha">
					<div class="row w-100">
						<div class="input-group input-group-correcao row w-100" v-if="clienteCadastrado.tipoPessoa == 'F'">
							<input type="date" @input="mascara" id="dataNascimento"
								v-model="clienteCadastrado.dataNascimento" class="w-auto" autocomplete="off"
								@keydown.enter="proximoCampo($store.state.Usuario.incluiCliente ? 'js-lmtCredito' : 'avalista')">
							<label for="dataNascimento" class="mx-5 text-right w-auto nowrap">Data de
							nascimento</label>
						</div>
						<div class="input-group input-group-correcao row w-100" v-else>							
							<input type="text" v-model="clienteCadastrado.fantasia" class="w-100" autocomplete="off"
								id="fantasia">
							<label class="mx-5  text-right w-auto" for="fantasia">Fantasia</label>
						</div>
					</div>
					<div id="limitCredito" class="input-group input-group-correcao row w-85 nowrap">
						<input type="text" class="w-100" id="js-lmtCredito" autocomplete="off" value="SEM LIMITE"
							placeholder="SEM LIMITE" v-if="clienteCadastrado.limitecredito == -1" readonly
							@focus="liberarCampoLimiteCredito">
						<input type="text" class="w-100" id="js-lmtCredito" autocomplete="off"
							placeholder="-1 (sem limite)" v-else v-model="clienteCadastrado.limitecredito"
							:readonly="!$store.state.Usuario.incluiCliente" @keydown.enter="proximoCampo('avalista')">
						<label for="js-lmtCredito" class="mx-5  text-right w-auto" style="color: var(--text);top:-12px; left: 16px; bottom: auto;background-color: var(--body);padding: 0px 4px;">Limite de Crédito</label>
					</div>
				</div>
				<div class="input-group row mb-5 h-30px w-100 linha">
					<input type="text" class="w-100" placeholder="" readonly id="avalista"
						@keydown.space="() => { listarClientes = true; clienteCadastrado.avalista = null; }"
						@click="() => { listarClientes = true; clienteCadastrado.avalista = null; }"
						@select="() => { listarClientes = true; clienteCadastrado.avalista = null; }"
						:value="clienteCadastrado.avalista ? clienteCadastrado.avalista.nome : ''" autocomplete="off"
						@keydown.enter.prevent="proximoCampo('cep')">						
					<label class="mx-5  text-right w-auto" for="avalista">Avalista</label>
				</div>
				<div class="cel row mb-5 h-30px w-100 nowrap linha">
					<div class="row w-100 " @click="mudarSerasa">
						<label for="sitSerasa" class="mx-5 text-right w-auto">Situação Serasa:</label>
						<i class="far fa-times-circle w-100 text-left" v-if="clienteCadastrado.sitcerasa == 'N'"
							style="color: red;"> Não Consta</i>
						<i class="far fa-check-circle w-100 text-left" v-else style="color: green"> Consta</i>
					</div>
					<div class="input-group" v-if="clienteCadastrado.sitcerasa == 'C'">
						<input type="date" @input="mascara" id="datacerasa" v-model="clienteCadastrado.datacerasa"
							class="w-100" autocomplete="off" @keydown.enter.prevent="proximoCampo('obsSerasa')">
						<label for="datacerasa" class="mx-5 text-right nowrap w-auto">Data Serasa:</label>
					</div>
				</div>
				<div class="cel row mb-5 h-30px w-100">
					<div class="input-group" v-if="clienteCadastrado.sitcerasa == 'C'">
						<input type="text" class="w-100" id="obsSerasa" v-model="clienteCadastrado.obscerasa"
							autocomplete="off" @keydown.enter.prevent="proximoCampo('cep')">
						<label for="obsSerasa" class="mx-5 text-right w-auto">Observação Serasa:</label>
					</div>
					<div id="ativo" @click="mudarAtivo" class="row w-30">
						<label class="mx-5  text-right w-auto" for="ativo">Ativo: </label>
						<i class="far fa-times-circle w-100 text-left" v-if="clienteCadastrado.ativo == 'I'"
							style="color: red;"> Não</i>
						<i class="far fa-check-circle w-100 text-left" v-else style="color: green"> Sim</i>
					</div>
				</div>
			</div>

			<div class="dados col mr-10">
				<div class="cel row mb-5 h-30px w-100">
					<div id="cepControle" class="input-group row w-80 marg-top">
						<input type="text" v-model="clienteCadastrado.cep" id="cep" @input="mascara" maxlength="9"
							class="w-100" autocomplete="off" @keydown.enter.prevent="proximoCampo('cidade')">
						<label class="mx-5  text-right w-auto" for="cep">CEP</label>
					</div>
					<div class="input-group row w-100 marg-top">
						<input type="text" v-model="clienteCadastrado.cidade.nomecidade" class="w-100"
							autocomplete="off" readonly id="cidade" @keydown.enter.prevent="proximoCampo('uf')"
							@dblclick="solicitarListaCidades" @contextmenu.prevent="solicitarListaCidades">
						<label class="mx-5  text-r text-right w-auto" for="cidade">Cidade</label>
					</div>
					<div id="ufControle" class="input-group row w-30 marg-top">
						<input type="text" id="uf" v-model="clienteCadastrado.cidade.uf" class="w-100"
							autocomplete="off" maxlength="2" readonly @keydown.enter.prevent="proximoCampo('endereco')">
						<label class="mx-5  text-right w-auto" for="uf">UF</label>
					</div>
				</div>
				<div class="cel row mb-5 h-30px w-100 linha">
					<div class="input-group input-group-correcao row w-100">
						<input type="text" @input="mascara" id="endereco" v-model="clienteCadastrado.endereco"
							class="w-100" autocomplete="off" @keydown.enter.prevent="proximoCampo('numero')">
						<label class="mx-5  text-right w-auto" for="endereco">Logradouro</label>
					</div>
					<div class="input-group input-group-correcao-resp row w-35">
						<input type="number" v-model="clienteCadastrado.numero" class="w-100" autocomplete="off"
							id="numero" @keydown.enter.prevent="proximoCampo('bairro')">
						<label class="mx-5  text-right w-auto" for="numero">Número</label>
					</div>
				</div>
				<div class="cel row mb-5 h-30px w-100 linha">
					<div class="input-group input-group-correcao row w-100">
						<input type="text" @input="mascara" id="bairro" v-model="clienteCadastrado.bairro" class="w-100"
							autocomplete="off" @keydown.enter.prevent="proximoCampo('complemento')">
						<label class="mx-5  text-right w-auto" for="bairro">Bairro</label>
					</div>
					<div class="input-group input-group-correcao row w-90">
						<input type="text" v-model="clienteCadastrado.complemento" class="w-100" autocomplete="off"
							id="complemento" @keydown.enter.prevent="proximoCampo('celular')">
						<label class="mx-5  text-right w-auto" for="complemento">Complemento</label>
					</div>
				</div>
				<div class="cel row mb-5 h-30px w-100 linha">
					<div class="input-group input-group-correcao row w-100">
						<input type="text" class="w-100" @input="mascara" id="celular"
							v-model="clienteCadastrado.celular" autocomplete="off" maxlength="14"
							@keydown.enter.prevent="proximoCampo('contato')"  v-mascaraInteger>
						<label class="mx-5  text-right w-auto" for="celular">Celular</label>
					</div>
					<div class="input-group input-group-correcao row w-100">
						<input type="text" class="w-100" v-model="clienteCadastrado.contato" autocomplete="off"
							id="contato" @keydown.enter.prevent="proximoCampo('fone')">
						<label class="mx-5  text-right w-auto nowrap" for="contato">Contato</label>
					</div>
				</div>
				<div class="cel row mb-5 h-30px w-100 linha">
					<div class="input-group input-group-correcao row w-100">
						<input type="text" class="w-100" @input="mascara" id="fone" v-model="clienteCadastrado.fone"
							autocomplete="off" maxlength="14" @keydown.enter.prevent="proximoCampo('fax')"  v-mascaraInteger>
						<label class="mx-5  text-right w-auto" for="fone">Telefone</label>
					</div>
					<div class="input-group input-group-correcao row w-100">
						<input type="text" class="w-100" @input="mascara" id="fax" v-model="clienteCadastrado.fax"
							autocomplete="off" maxlength="14" @keydown.enter.prevent="proximoCampo('email')"  v-mascaraInteger>
						<label for="fax" class="mx-5 text-right w-auto nowrap">Telefone 2</label>
					</div>
				</div>
				<div class="input-group row mb-5 h-30px w-100 linha">
					<input type="text" class="w-100 input-email" v-model="clienteCadastrado.email" autocomplete="off" id="email">
					<label for="email" class="mx-5 text-right w-auto nowrap">E-mail</label>
				</div>
				<div class="input-group row mb-5 h-30px w-100 linha">
					<input type="text" style="text-transform:uppercase;" maxlength="255" v-model="clienteCadastrado.mensagemVenda" autocomplete="off" class="w-100"/>
					<label for="mensagem" class="mx-5 text-right w-auto nowrap">Mensagem da venda</label>
				</div>
				<div class="textarea row mb-5 h-30px w-100 linha textarea-group">
					<textarea style="text-transform:uppercase" maxlength="255" class="w-100"
						v-model="clienteCadastrado.observacao"></textarea>
					<label for="observacao" class="mx-5 text-right w-auto nowrap">Observações</label>
				</div>
			</div>
		</div>
		<div class="botoes" id="botoes">
			<button @click="()=>{this.$emit('cancelarEdicao')}" class="botao botao-cancelar">
				<label for="botaoCancelar" class="lg-view">Cancelar [Alt + C]</label>
				<label for="botaoCancelar" class="sm-view">Cancelar</label>
			</button>
			<button @click="()=>{this.$emit('editarCliente', {clienteCadastrado, classi, classiErro});}" class="botao botao-adicionar">
				<label for="botaoAdicionar" class="lg-view">Salvar [Alt + S]</label>
				<label for="botaoAdicionar" class="sm-view">Salvar</label>
			</button>
		</div>
		<Modal v-if="listarClientes" v-on:fecharModal="listarClientes = false">
			<consulta-cliente  
				@selecionarPessoa="selecionarAvalista" 
			/>
			<!-- <Paginacao tipoConteudo="Clientes" :desabilitarNovo="true">
				<TabelaPessoas tipoConteudo="Clientes" v-on:selecionarPessoa="selecionarAvalista"
					v-on:fecharModal="listarClientes = false" />
			</Paginacao> -->
		</Modal>
		<Modal v-if="listarCidades" v-on:fecharModal="listarCidades = false">
			<ConsultaCidades
				@selecionarCidade="selecionarCidade"
			/>
			<!-- <Paginacao tipoConteudo="Cidades" :desabilitarNovo="true">
				<TabelaCidade v-on:selecionarCidade="selecionarCidade" />
			</Paginacao> -->
		</Modal>
	</div>
</template>
<script>
import ConsultaCliente from "./ConsultaCliente.vue";
import Modal from "./Modal.vue";
// import Paginacao from "./Paginacao.vue";
// import TabelaPessoas from "./TabelaPessoas.vue";
// import TabelaCidade from "./TabelaCidade.vue";
import ConsultaCidades from "./ConsultaCidades.vue";
export default {
	name: "ClienteDados",

	props: {
		cliente: Object,
	},
	components: {
		Modal,
		// Paginacao,
		// TabelaPessoas,
		// TabelaCidade,
		ConsultaCliente,
		ConsultaCidades,
	},

	data() {
		return {
			clienteCadastrado: {
				ativo: "A",
				avalista: null,
				avisar: "",
				bairro: "",
				celular: "",
				cep: "",
				cidade: {
					nomecidade: "",
					uf: "",
				},
				classificacao: null,
				cnpj: "",
				complemento: "",
				contato: "",
				dataCadastro: null,
				dataNascimento: "",
				datacerasa: "",
				email: "",
				endereco: "",
				fantasia: "",
				fax: "",
				fone: "",
				id: "",
				ierg: "",
				limitecredito: "-1",
				nome: "",
				numero: "",
				obscerasa: "",
				sitcerasa: "N",
				tipoPessoa: "F",
				mensagemVenda: "",
				observacao: "",
			},
			buscaClassificacao: "",
			timerFiltro: null,
			classiErro: false,
			listarClientes: false,
			listarCidades: false,
		};
	},

	methods: {
		async filtarClassificacao({descricao}){
			clearTimeout(this.timerFiltro);
			this.timerFiltro = setTimeout(async () => {
				if (this.classi?.cdclassi) return;
				const filtro = {
					descricao: descricao ? descricao : this.buscaClassificacao
				};
				const data = await this.$store.dispatch("filtrarClassificacao", filtro);
				if(data.mensagem){
					return this.classiErro = true;
				}
			}, 1000);
			this.classiErro = false;
		},
		liberarCampoLimiteCredito(evento) {
			if (!this.$store.state.Usuario.incluiCliente) return;
			this.clienteCadastrado.limitecredito = "";
			if (evento.target.select) {
				evento.target.select();
			}
		},
		autorizarLimiteCredito(tipo) {
			if (!this.$store.state.Usuario.incluiCliente) {
				return;
			}
			if (!tipo) {
				document.getElementById("js-lmtCredito").readOnly = tipo;
				this.clienteCadastrado.limitecredito = "0";
				setTimeout(() => {
					document.getElementById("js-lmtCredito").focus();
					document.getElementById("js-lmtCredito").select();
				}, 50);
			} else {
				if (typeof (this.clienteCadastrado.limitecredito) == "string") {
					if (this.clienteCadastrado.limitecredito.includes("R$ ")) {
						this.clienteCadastrado.limitecredito = this.clienteCadastrado.limitecredito.replace("R$ ", "");
					}
					if (this.clienteCadastrado.limitecredito.includes(",")) {
						this.clienteCadastrado.limitecredito = Number(this.clienteCadastrado.limitecredito.replace(",", "."));
					} else {
						this.clienteCadastrado.limitecredito = Number(this.clienteCadastrado.limitecredito);
					}
				}
				this.clienteCadastrado.limitecredito = Number(this.clienteCadastrado.limitecredito).toLocaleString("pt-BR", {
					style: "currency", currency: "BRL"
				});
				document.getElementById("js-lmtCredito").readOnly = tipo;
			}
		},
		selecionarAvalista(cliente) {
			if(cliente.id === this.cliente.id) return this.$store.commit("relatarErro", {
				mensagem: "Não é possivel o avalista ser o mesmo cliente, por favor verifique"
			});
			this.clienteCadastrado.avalista = cliente;
			this.listarClientes = false;
			this.proximoCampo("cep");
		},
		solicitarListaCidades() {
			this.listarCidades = true;
		},
		selecionarCidade(cidade) {
			this.clienteCadastrado.cidade = {
				cdcidade: cidade.cdcidade,
				codibge: cidade.codibge,
				nomecidade: cidade.nomecidade,
				uf: cidade.uf,
				cidadeSelecionada: true
			};
			this.listarCidades = false;
		},
		mudarAtivo() {
			if (this.$store.state.Usuario.supervisor && this.$store.state.Usuario.incluiCliente) {
				if (this.clienteCadastrado.ativo == "A") {
					this.clienteCadastrado.ativo = "I";
				} else {
					this.clienteCadastrado.ativo = "A";
				}
			}
		},
		mudarSerasa() {
			if (this.clienteCadastrado.sitcerasa == "N") {
				this.clienteCadastrado.sitcerasa = "C";
			}
			else {
				this.clienteCadastrado.sitcerasa = "N";
			}
			if (this.clienteCadastrado.sitcerasa == "C") {
				const input = document.getElementById("datacerasa");
				if (input) input.focus();
			}
		},
		mascara(evento) {
			const id = evento.target.id;
			const input = document.getElementById(id);
			const v = input.value;
			if (evento.data === null) {
				if ((id == "celular") && (v.length == 13 && v[8] != "-")) {
					this.clienteCadastrado[id] = `${this.clienteCadastrado[id].slice(0, 8) + "-" + v[10] + v.slice(10)}`;
				}
				return;
			}
			if (id === "nome"){
				const regex =  /[^a-zA-Z0-9\s]/;
				if (regex.test(this.clienteCadastrado[id])) {
					this.clienteCadastrado[id] = this.clienteCadastrado[id].substring(0, this.clienteCadastrado[id].length - 1);
				}
				return this.clienteCadastrado[id] = this.clienteCadastrado[id].toUpperCase();
			}
			if (id == "bairro" || id == "endereco") {
				// const regex = /[~^!@#$%¨^&*()_={}+-?;<>'´`|ªº°"/\\]/;
				// if (id == "nome" && regex.test(this.clienteCadastrado[id])) {
				// 	this.clienteCadastrado[id] = this.clienteCadastrado[id].substring(0, this.clienteCadastrado[id].length - 1);
				// }
				return this.clienteCadastrado[id] = this.clienteCadastrado[id].toUpperCase();
			}
			if (isNaN(v[v.length - 1])) { // impede entrar outro caractere que não seja número
				this.clienteCadastrado[id != "cpf" ? id : "cnpj"] = this.clienteCadastrado[id != "cpf" ? id : "cnpj"].substring(0, v.length - 1);
				return;
			}
			if (id == "cpf") {
				if (v.length == 3 || v.length == 7) {
					this.clienteCadastrado.cnpj += ".";
				}
				if ((v.length == 4 || v.length == 8) && v.length - 1 != ".") {
					this.clienteCadastrado.cnpj = v.slice(0, v.length - 1) + "." + evento.data;
				}
				if (v.length == 11) {
					this.clienteCadastrado.cnpj += "-";
				}
				if (v.length == 12 && v.length - 1 != "-") {
					this.clienteCadastrado.cnpj = v.slice(0, v.length - 1) + "-" + evento.data;
				}
			}
			else if (id == "cnpj") {
				if (v.length == 2 || v.length == 6) {
					this.clienteCadastrado.cnpj += ".";
				}
				if (v.length == 3) {
					if (v.length - 1 == "/") {
						return;
					}
					this.clienteCadastrado.cnpj = this.clienteCadastrado.cnpj.slice(0, 10);
					this.clienteCadastrado.cnpj += "/" + evento.data;
				}
				if (v.length == 10) input.value += "/";
				if (v.length == 11) {
					if (v.length - 1 == "/") {
						return;
					}
					this.clienteCadastrado.cnpj = this.clienteCadastrado.cnpj.slice(0, 10);
					this.clienteCadastrado.cnpj += "/" + evento.data;
				}
				if (v.length == 15) {
					this.clienteCadastrado.cnpj += "-";
				}
			} else if (id == "celular" || id == "fone" || id == "fax") {
				if (v.length == 1) {
					this.clienteCadastrado[id] = "(" + evento.data;
				}
				if (v.length == 3) {
					this.clienteCadastrado[id] += ")";
				}
				if (v.length == 4 && v.length - 1 != ")") {
					this.clienteCadastrado[id] = this.clienteCadastrado[id].slice(0, 3) + ")" + evento.data;
				}
				if (v.length == 8) {
					if (v.length - 1 == "-") {
						return;
					}
					this.clienteCadastrado[id] += "-";
				}
				if (v.length == 9 && evento.data != "-") {
					this.clienteCadastrado[id] = this.clienteCadastrado[id].slice(0, 8) + "-" + evento.data;
				}
				if (v.length == 14 && v[8] == "-") {
					this.clienteCadastrado[id] = `${this.clienteCadastrado[id].slice(0, 8) + v[9] + "-" + v.slice(10)}`;
				}
			} else if (id == "dataNascimento" && this.clienteCadastrado.dataNascimento > new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")) {
				this.clienteCadastrado.dataNascimento = "";
				return this.$store.commit("relatarErro", { mensagem: "A data informada é maior que a data atual. Verifique!" });
			} else if (id == "datacerasa" && this.clienteCadastrado.datacerasa > new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")) {
				this.clienteCadastrado.datacerasa = "";
				return this.$store.commit("relatarErro", { mensagem: "A data informada é maior que a data atual. Verifique!" });
			} else if (id == "cep") {
				if (v.length == 5) {
					if (v.length - 1 == "-") {
						return;
					}
					this.clienteCadastrado[id] += "-";
				}
				if (v.length == 6 && evento.data != "-") {
					this.clienteCadastrado[id] = this.clienteCadastrado[id].slice(0, 5) + "-" + evento.data;
				}
				if (!this.clienteCadastrado.cidade.cidadeSelecionada && this.clienteCadastrado[id].length == 9) {
					clearTimeout(this.timerFiltro);
					this.timerFiltro = setTimeout(() => {
						this.pegarCidade({ cancelado: false });
					}, 500);
				}
			}
		},
		eventosTeclado(e) {
			if (`${e.key}`.toUpperCase() == "S" && e.altKey) {
				e.preventDefault();
				this.$emit("editarCliente",{clienteCadastrado: this.clienteCadastrado,classi: this.classi, classiErro: this.classiErro});
			}
			if (`${e.key}`.toUpperCase() == "C" && e.altKey) {
				e.preventDefault();
				this.$emit("cancelarEdicao");
			}
		},
		alterarEndereco(item) {
			if (item.tipo == "nomecidade" || item.tipo == "uf") {
				this.clienteCadastrado.cidade[item.tipo] = item.valor.toUpperCase();
			} else {
				this.clienteCadastrado[item.tipo] = item.valor.toUpperCase();
			}
			this.pegarCidade({ cancelado: false, ...item });
		},
		alterarEndereco2(item) {
			if (!item) return;
			this.pegarCidade({ ...item, cancelado: true });

		},
		async pegarCidade(item) {
			try {
				if (!this.clienteCadastrado.cep) return;
				const {
					bairro, cep, logradouro, nomecidade, uf, erro, cdcidade
				} = await this.$store.dispatch("buscarCidadeViaCep", { cep: this.clienteCadastrado.cep });

				if (erro) return;

				!this.clienteCadastrado.bairro && bairro ? this.clienteCadastrado.bairro = bairro.toUpperCase() : "";
				!this.clienteCadastrado.endereco && logradouro ? this.clienteCadastrado.endereco = logradouro.toUpperCase() : "";
				!this.clienteCadastrado.cep && cep ? this.clienteCadastrado.cep = cep : "";
				uf ? this.clienteCadastrado.cidade.uf = uf.toUpperCase() : "";
				nomecidade ? this.clienteCadastrado.cidade.nomecidade = nomecidade.toUpperCase() : "";
				cdcidade ? this.clienteCadastrado.cidade.cdcidade = cdcidade : "";

				if (!item?.cancelado && item.tipo != "bairro") {
					if (bairro && (this.clienteCadastrado.bairro != bairro.toUpperCase())) {
						return this.$store.commit("relatarErro", {
							mensagem: `O cep digitado corresponde a esse bairro: ${bairro}.
						Deseja alterar?`, tipo: "polar", item: {
								tipo: "bairro",
								valor: bairro
							}
						});
					}
				}

				if (!item?.cancelado && item.tipo != "endereco") {
					if (logradouro && (this.clienteCadastrado.endereco != logradouro.toUpperCase())) {
						return this.$store.commit("relatarErro", {
							mensagem: `O cep digitado corresponde a esse logradouro: ${logradouro}.
						Deseja alterar?`, tipo: "polar", item: {
								tipo: "endereco",
								valor: logradouro
							}
						});
					}
				}

				if (!item?.cancelado && item.tipo != "uf") {
					if (uf && this.clienteCadastrado.cidade.uf != uf.toUpperCase()) {
						return this.$store.commit("relatarErro", {
							mensagem: `O cep digitado corresponde a esse UF: ${uf}.
						Deseja alterar?`, tipo: "polar", item: {
								tipo: "uf",
								valor: uf
							}
						});
					}
				}

				if (!item?.cancelado && item.tipo != "nomecidade") {
					if (nomecidade && this.clienteCadastrado.cidade.nomecidade != nomecidade.toUpperCase()) {
						return this.$store.commit("relatarErro", {
							mensagem: `O cep digitado corresponde a essa cidade: ${nomecidade}.
						Deseja alterar?`, tipo: "polar", item: {
								tipo: "nomecidade",
								valor: nomecidade
							}
						});
					}
				}

			} catch (error) {
				console.log(error);
			}
		},
		proximoCampo(idCampo) {
			const campo = document.getElementById(idCampo);
			if (!campo) {
				console.log("Método próximo campo não foi possível encontrar o campo!");
			} else {
				campo.focus();
				if (campo.select) {
					campo.select();
				}
			}
		},
		async iniciarComponent () {
			await this.$store.dispatch("buscarClassificacao");
			if (this.cliente) {
				if (this.cliente.novo){
					const classiPadrao = await this.$store.dispatch("buscarClassificacaoPadrao");
					this.clienteCadastrado.classificacao = {
						...classiPadrao
					};
					this.buscaClassificacao = classiPadrao.descricao;
					return;
				}
				this.clienteCadastrado = {
					...this.cliente,
					ativo: this.cliente.ativo,
					avalista: this.cliente.avalista,
					avisar: this.cliente.avisar,
					bairro: this.cliente.bairro,
					celular: this.cliente.celular,
					cep: this.cliente.cep,
					cidade: {
						cdcidade: this.cliente.cidade ? this.cliente.cidade.cdcidade : "",
						codibge: this.cliente.cidade ? this.cliente.cidade.codibge : "",
						nomecidade: this.cliente.cidade ? this.cliente.cidade.nomecidade : "",
						uf: this.cliente.cidade ? this.cliente.cidade.uf : "",
					},
					classificacao: this.cliente.classificacao,
					cnpj: this.cliente.cnpj,
					complemento: this.cliente.complemento,
					contato: this.cliente.contato,
					dataCadastro: null,
					dataNascimento: this.cliente.dataNascimento,
					datacerasa: this.cliente.datacerasa,
					email: this.cliente.email,
					endereco: this.cliente.endereco,
					fantasia: this.cliente.fantasia,
					fax: this.cliente.fax,
					fone: this.cliente.fone,
					id: this.cliente.id,
					ierg: this.cliente.ierg,
					limitecredito: this.cliente.limitecredito != -1 ? (this.cliente.limitecredito || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }) : -1,
					nome: this.cliente.nome,
					numero: this.cliente.numero,
					obscerasa: this.cliente.obscerasa,
					sitcerasa: this.cliente.sitcerasa || "N",
					tipoPessoa: this.cliente.tipoPessoa,
					mensagemVenda: this.cliente.mensagemVenda || "",
					observacao: this.cliente.observacao || "",
				};
				this.buscaClassificacao = this.cliente.classificacao?.descricao ||  this.clienteCadastrado.classificacao?.descricao || "";
			}
			else if (this.$route.params.cliente) {
				this.clienteCadastrado = {
					...this.$route.params.cliente,
					ativo: this.$route.params.cliente.ativo,
					avalista: this.$route.params.cliente.avalista,
					avisar: this.$route.params.cliente.avisar,
					bairro: this.$route.params.cliente.bairro,
					celular: this.$route.params.cliente.celular,
					cep: this.$route.params.cliente.cep,
					cidade: {
						cdcidade: this.$route.params.cliente.cidade ? this.$route.params.cliente.cidade.cdcidade : "",
						codibge: this.$route.params.cliente.cidade ? this.$route.params.cliente.cidade.codibge : "",
						nomecidade: this.$route.params.cliente.cidade ? this.$route.params.cliente.cidade.nomecidade : "",
						uf: this.$route.params.cliente.cidade ? this.$route.params.cliente.cidade.uf : "",
					},
					classificacao: this.$route.params.cliente.classificacao?.descricao,
					cnpj: this.$route.params.cliente.cnpj,
					complemento: this.$route.params.cliente.complemento,
					contato: this.$route.params.cliente.contato,
					dataCadastro: this.$route.params.cliente.dataCadastro,
					dataNascimento: this.$route.params.cliente.dataNascimento,
					datacerasa: this.$route.params.cliente.datacerasa,
					email: this.$route.params.cliente.email,
					endereco: this.$route.params.cliente.endereco,
					fantasia: this.$route.params.cliente.fantasia,
					fax: this.$route.params.cliente.fax,
					fone: this.$route.params.cliente.fone,
					id: this.$route.params.cliente.id,
					ierg: this.$route.params.cliente.ierg,
					limitecredito: this.$route.params.cliente.limitecredito != -1 ? Number(this.$route.params.cliente.limitecredito || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }) : -1,
					nome: this.$route.params.cliente.nome,
					numero: this.$route.params.cliente.numero,
					obscerasa: this.$route.params.cliente.obscerasa,
					sitcerasa: this.$route.params.cliente.sitcerasa || "N",
					tipoPessoa: this.$route.params.cliente.tipoPessoa,
					mensagemVenda: this.$route.params.cliente.mensagemVenda || "",
					observacao: this.$route.params.cliente.observacao || "",
				};
				this.buscaClassificacao = this.$route.params.cliente.classificacao?.descricao || this.cliente.classificacao?.descricao || this.clienteCadastrado.classificacao?.descricao || "";
			}
		}
	},
	mounted() {
		if(!this.cliente.id){
			this.clienteCadastrado.cep = this.$store.state.Empresa.cep;
		}
		document.addEventListener("keydown", this.eventosTeclado);
		if (this.clienteCadastrado.cep) {
			this.pegarCidade({ cancelado: false });
		}
		this.$bus.$on("confirmarPolar", this.alterarEndereco);
		this.$bus.$on("cancelarPolar", this.alterarEndereco2);
		this.iniciarComponent();
		this.$bus.$on("salvarCliente", ()=>{this.$emit("editarCliente", {clienteCadastrado: this.clienteCadastrado,classi: this.classi, classiErro: this.classiErro});});
	},
	destroyed() {
		document.removeEventListener("keydown", this.eventosTeclado);
		this.$bus.$off("salvarCliente", ()=>{this.$emit("editarCliente", {clienteCadastrado: this.clienteCadastrado,classi: this.classi, classiErro: this.classiErro});});
		this.$bus.$off("confirmarPolar", this.alterarEndereco);
		this.$bus.$off("cancelarPolar", this.alterarEndereco2);
	},
	computed: {
		classificacao() {
			return this.$store.state.Classificacao.content;
		},
		classi () {
			return this.classificacao.find(classif => `${classif.descricao}` == `${this.buscaClassificacao}`);
		}
	},
};
</script>

<style scoped>
.input-email{
	text-transform: lowercase;
}
.input-group-correcao{
	width:100%;
}
#tipo-pessoa{
	width:50%;
}
.marg-top{
	margin-top:5px;
}
.linha{
	margin-top:20px
}
select {
	z-index: 1;
}
.cadastroCliente {
	display: flex;
	flex-direction: column;
	align-self: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	overflow: auto;
}
.cliente {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	width: 100%;
	height: 100%;
	align-items: flex-start;
	justify-content: space-between;
	overflow-y: auto;
}
.btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 30%;
}
.alinha-texto-botao{
	width: 100%;
}
#divEamil {
	margin-right: 5px;
}
.dados {
	width: 48%;
}
i {
	align-self: center;
}
input[type="date"]::-webkit-calendar-picker-indicator {
	color: #2c3e50;
	padding: 0px;
	cursor: pointer;
	/* display: none; */
}
textarea {
	resize: none;
	padding: 5px 5px;
	box-sizing: border-box;
	height: 80px;
	overflow-y: auto;

}
.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	padding: 10px 0px;
}
.botoes .botao {
	width: 45%;
}
.lg-view {
	display: inline-block;
}
.sm-view {
	display: none;
}
@media screen and (max-width: 1280px) {
	.cliente {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}

	.dados {
		margin: 0;
		width: 90%;
	}
	label,
	input {
		font-size: 14px;
	}
	.row.w-100 label {
		margin: auto 5px;
	}
}
@media screen and (max-width: 768px){
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
}
@media screen and (max-width: 675px) {
	#limitCredito,
	#complemento,
	#numero,
	#ativo,
	#cdCliente,
	#cepControle,
	#ufControle {
		width: 100%;
	}
	.row.w-100 {
		height: auto;
	}
	label,
	input {
		font-size: 14px;
		height: 20px;
	}
	button span {
		display: none;
	}
	.textarea {
		display: flex;
		flex-direction: column;
	}
}
@media screen and (max-width: 600px) {
	.cel {
		display: flex;
		flex-direction: column;
		height: auto;
		margin: 0;
	}
	.row.w-100 {
		margin: 10px 0px;
		height: auto;
	}
	label,
	i {
		width: auto;
		height: auto;
		vertical-align: middle;
	}
	input {
		width: 100%;
		;
		height: auto;
	}
}
@media screen and (max-width: 425px) {
	#tipo-pessoa{
		width:100%;
	}
	.input-group-correcao-resp{
		width:100%;
	}
	.input-group{
		margin-left:0px;
	}
	.botao i{
		display:none;
	}
}
@media screen and (max-width:375px){
	.botao{
		min-width:95px;
	}
}
</style>