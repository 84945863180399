<template>
	<div class="container-page-relatorio">
		<i 
			class="fas fa-print"
			style="color: var(--primario)"
			id="impressao"
			@click="gerarImpressaoRelatorio"
			v-if="Faturamentos.length > 0"
		/>
		<Filtro
			@handleShowTable="handleShowTable"
			@filtrar="filtrar"
			@limpar="limpar"
		>
			<div class="filtro-content">
				<div class="column-filtro">
					<div class="row-filtro">
						<div class="input-group">
							<input 
								type="date" 
								v-model="Filtro.datai"
								placeholder=" "
								id="datai"
								@blur="saiuData"
							/>
							<label for="datai">
								Data Inicial
							</label>
						</div>
						<div class="input-group">
							<input 
								type="date" 
								v-model="Filtro.dataf"
								placeholder=" "
								id="dataf"
								@blur="saiuData"
							/>
							<label for="dataf">
								Data Final
							</label>
						</div>
						<div class="input-group">
							<select v-model="Filtro.empresa">
								<option 
									v-for="emp in Empresas"
									:key="emp.id"
									:value="emp.id"
									
								>
									{{ emp.id }} - {{ emp.nome }}
								</option>
							</select>
							<label>Empresa</label>
						</div>
						<div class="input-group">
							<select v-model="Filtro.notafiscal">
								<option :value="true">
									Sim
								</option>
								<option :value="false">
									Nao
								</option>
							</select>
							<label>NFC-E</label>
						</div>
					</div>
				</div>
			</div>
		</Filtro>
		<div 
			:class="`table-scroll  ${fullTable ? 'fullTable' : ''}`"  
		>
			<div class="container-table"
				v-if="Faturamentos.length > 0"		
			>
				<table>
					<thead>
						<tr>
							<td>
								Hora
							</td>
							<td>
								Domingo
							</td>
							<td>
								Segunda
							</td>
							<td>
								Terça
							</td>
							<td>
								Quarta
							</td>
							<td>
								Quinta
							</td>
							<td>
								Sexta
							</td>	
							<td>
								Sábado
							</td>	
						</tr>
					</thead>
					<tbody>
						<tr v-for="(faturamento, index) in Faturamentos" :key="index"	>
							<td>
								{{ faturamento.hora }}:00
							</td>
							<td>
								R$ {{ faturamento.domingo.toLocaleString("pt-BR", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								}) }}
							</td>
							<td>
								R$ {{ faturamento.segunda.toLocaleString("pt-BR", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								}) }}
							</td>
							<td>
								R$ {{ faturamento.terca.toLocaleString("pt-BR", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								}) }}
							</td>
							<td>
								R$ {{ faturamento.quarta.toLocaleString("pt-BR", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								}) }}
							</td>
							<td>
								R$ {{ faturamento.quinta.toLocaleString("pt-BR", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								}) }}
							</td>
							<td>
								R$ {{ faturamento.sexta.toLocaleString("pt-BR", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								}) }}
							</td>
							<td>
								R$ {{ faturamento.sabado.toLocaleString("pt-BR", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								}) }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="container-aviso" v-else>
				<i 
					class="fas fa-exclamation-triangle" 
					style="color: yellow" 
				/>
				<span>
					Nenhum faturamento encontrado!
				</span>
			</div>
		</div>
		<div class="row w-100" style="justify-content:flex-end">
			<div class="input-group" style="margin-top: 10px; max-width: 80%;">
				<input disabled :value="'R$ '+Total">
				<label>Total:</label>
			</div>
		</div>
		<Modal v-if="false" @fecharModal="fecharModal">
			<PreImpressao
				:vendaFinalizada="Venda"
				:conferir="true"
				:permiteCancelar="true"
				:empresa="Empresa"
				@fecharModal="fecharModal"
			>
			</PreImpressao>
		</Modal>
	</div>
</template>
<script>
import Filtro from "../components/Filtro.vue";
import Modal from "../components/Modal.vue";
import PreImpressao from "../components/PreImpressao.vue";
import formatarParaReais from "../filters/formatarParaMoeda";
import { dateFormatISO } from "../utils/dateformat";
export default {
	name: "RelatorioFaturamentoDiasXHoras",
	data() {
		return {
			Filtro: {
				datai: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1
				).toLocaleDateString("en-CA"),
				dataf: new Date().toLocaleDateString("en-CA"),
				notafiscal: true,
				empresa: this.$store.state.Empresa.id,
			},
			fullTable: false,
		};
	},
	components: {
		Filtro,
		Modal,
		PreImpressao
	},

	computed: {
		Faturamentos(){
			return this.$store.state.Relatorios.FaturamentoDiasXHoras || [];
		},
		Empresas() {
			return this.$store.state.Empresas;
		},
		Total(){
			let total = 0;
			this.Faturamentos.forEach(faturamento =>{
				for( let chave in faturamento){
					if( chave != "hora"){
						total += faturamento[chave];
					}
				}
			});
			return total.toLocaleString("pt-BR", {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			}); 
		}
	},
	filters: {
		formatarParaReais
	},
	mounted() {
		this.filtrar();
	},

	methods: {
		saiuData(){
			if(this.Filtro.datai == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.Filtro.datai = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.Filtro.dataf == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.Filtro.dataf = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		async gerarImpressaoRelatorio() {
			try {
				
				if(this.Faturamentos.length===0) {
					throw new Error(
						"Para gerar o relatório é necessário filtrar um faturamento primeiro, favor verificar"
					);
				}
				await this.$store.dispatch("gerarRelatorioFaturamentoDiasXHoras", {
					dataI: this.Filtro.datai,
					dataF: this.Filtro.dataf,
					itens: this.Faturamentos,
					total: this.Total,
					empresa: this.Empresas.filter(emp => emp.id == this.Filtro.empresa)[0],
				});
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
				});
				return null;
			}
		},

		handleShowTable() {
			this.fullTable = !this.fullTable;
		},

		async filtrar() {
			await this.$store.dispatch("buscarRelatorioFaturamentoDiasXHoras", {
				...this.Filtro
			});
		},

		limpar() {
			this.Filtro = {
				datai: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1
				).toLocaleDateString("en-CA"),
				dataf: new Date().toLocaleDateString("en-CA"),
				empresa: this.$store.state.Empresa.id,
				notafiscal: true,
			};
			this.filtrar();
		},
	},
};
</script>
<style scoped>
.container-page-relatorio{
	width: 100%;
	height: 90vh;
	box-shadow: var(--shadow-secundario);
	border-radius: 10px;
}
.row-filtro {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;
}
.column-filtro {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.container-input-button{
	display: flex;
	flex-direction: row;
	align-items: center;
}
.container-input-button i {
	color: var(--acao-borda);
}
.container-table{
	width: 100%;
	display: flex;
	flex-direction: row;
}
.table-scroll {
	width: 100%;
	overflow-y: auto;
	height: 52vh;
}

.table-scroll .container-table table {
	width: 100%;
}
.centralizado{
	text-align: center;
}
.azul {
	color: var(--primario);
}
.fullTable {
	height: 70vh;
}
.container-aviso{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.container-aviso i {
	font-size: 200px;
}
.container-aviso span {
	font-size: 26px;
	font-weight: bold;
	margin-top: 10px;
}

.totalizador{
	width: 100%;
}

.totalizador .row-totalizador {
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
}

.totalizador .row-totalizador input {
	text-align: right;
}

#impressao{
	position: absolute;
	bottom: 30px;
	left: 140px;
	font-size: 35px;

	padding: 7px;
	border-radius: 50%;
    box-shadow: var(--shadow-secundario);

	background: #FFF;
	z-index: 2;
}


@media screen and (max-height: 660px) {
	.table-scroll {
		height: 60vh;
	}
	.fullTable {
		height: 72vh;
	}
}

@media screen and (max-width: 425px){
	.row-filtro .input-group {
		width: 100%;
	}

	.container-input-button {
		width: 100%;
	}
	
	.table-scroll {
		width: 100vw;
		height: 20vh;
		overflow: auto;
	}
	.fullTable {
		height: 45vh;
	}

	.fullTable .container-aviso {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.fullTable .container-aviso i{
		font-size: 120px;
	}
	.fullTable .container-aviso span {
		font-size: 22px;
		text-align: center;
	}
	.container-page-relatorio {
		height: 71vh;
	}

	.container-aviso {
		display: none;
	}
	.totalizador .row-totalizador {
		flex-wrap: wrap;
	}
	.totalizador .row-totalizador .input-group {
		width: 125px;
		flex-grow: 1;
		margin: 5px 2px;
	}
	#impressao{
		position: absolute;
		bottom: 0px;
		left: 0px;
		font-size: 35px;
	
		padding: 7px;
		border-radius: 50%;
		box-shadow: var(--shadow-secundario);
	
		background: #FFF;
		z-index: 2;
	}
}
</style>