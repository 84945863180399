<!-- teste gui para bianca agr vai eud ...-->
<template>
	<div class="padrao-pagina">
		<aside class="aba-container">
			<div class="aba-controle">
				<h2 v-for="(tab) in Object.keys(abas)" :key="tab"
					:class="`aba ${abas[tab].selecionada ? 'aba-selecionada' : ''}`" @click="mudarAba(tab)">
					{{ abas[tab].mensagem }}</h2>
			</div>
		</aside>
		<section v-if="abas['aba-usuario'].selecionada" class="pagina-conteudo aba-atual" id="aba-usuario">
			<GerenciaUsuarios />
		</section>
		<section v-if="abas['aba-perfil'].selecionada" class="pagina-conteudo" id="aba-perfil">
			<GerenciaPerfis />
		</section>
	</div>
</template>

<script>
import GerenciaUsuarios from "./GerenciaUsuarios.vue";
import GerenciaPerfis from "./GerenciaPerfis.vue";
export default {
	name: "Acessos",
	components: {
		GerenciaUsuarios,
		GerenciaPerfis,
	},

	data() {
		return {
			abas: {
				"aba-usuario": {
					mensagem: "Usuários",
					selecionada: true,
				},
				"aba-perfil": {
					selecionada: false,
					mensagem: "Perfis"
				}
			}

		};
	},

	methods: {
		mudarAba(tab) {
			[...document.getElementsByClassName("pagina-conteudo")].forEach(el => {
				el.classList.remove("aba-atual");
			});
			Object.keys(this.abas).forEach(tab => this.abas[tab].selecionada = false);
			this.abas[tab].selecionada = true;
			setTimeout(() => {
				document.getElementById(tab).classList.add("aba-atual");
			}, 50);


		},

	}

};
</script>

<style scoped>
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.padrao-pagina {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 90vh;
	width: 100%;
	max-width: 100%;
	background-color: var(--bg-secundario);
}

.aba-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: var(--bg-secundario);
}

.aba-controle {
	display: flex;
	flex-direction: row;
	height: 45px;
	flex-grow: 1;
	padding: 0px;
	padding-top: 8px;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 0px;
	background-color: var(--bg-secundario);
}

.aba {
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	background-color: var(--bg-secundario);
	cursor: pointer;
	text-align: center;
	margin: 0px 8px;
	text-decoration: none;
	color: #292929;
	font-weight: bold;
	font-size: 14pt;
	text-shadow: 0px 0px 0px #292929;
	transition-duration: 220ms;
	transition-property: all;
	transition-timing-function: ease-in-out;
}

.aba.aba-selecionada {
	background-color: var(--bg-app);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	box-shadow: 4px 1px 5px 1px #292929;
	cursor: default;
}

.pagina-conteudo {
	display: none;
	grid-auto-flow: column;
	grid-auto-columns: 100%;
	block-size: 100%;
	flex-grow: 1;
	background-color: var(--bg-app);
	box-shadow: 1px 3px 2px 1px #292929;
	margin: 0px 5px;
	margin-bottom: 5px;
	border-radius: 5px;
	overflow: hidden;
	will-change: transform;
	transition-duration: 220ms;
	transition-property: all;
	transition-timing-function: ease-in-out;
	opacity: 0;
	animation: fadeOutOpacity;
	animation-duration: 220ms;
	animation-timing-function: ease-in-out;
}

.aba-atual {
	display: grid;
	opacity: 1;
	animation: fadeInOpacity;
	animation-duration: 220ms;
	animation-timing-function: ease-in-out;
}
</style>