import { doRequest } from "./http.js";
import { Grade } from "../models/Grade.js";

export default {
	state: {
		Grades: [],
	},

	actions: {
		async buscarGrades({ commit }) {
			try {
				let url;
				url = "grade";
				const data = await doRequest(url, { method: "get", cache: "no-cache" }, commit);
				if (data.length) {
					commit("atualizarListaGrades", data);
				}
			} catch (error) {
				console.log(error);
				commit("atualizarListaGrades", null);
			}
		},

		async adicionarGrade({ commit, dispatch, state }, payload) {
			try {
				state.carregando = true;
				const body = JSON.stringify({
					identificacao: `${payload.identificacao || ""}`.toUpperCase(),
					grade: payload.grades?.length ? payload.grades.map(grade => `${grade || ""}`.toUpperCase()) : [],
				});
				let url = "grade";
				const data = await doRequest(url, { method: "post", cache: "no-cache", body }, commit);
				if (data.codigo) {
					await dispatch("buscarGrades");
					state.carregando = false;
					return new Grade(data);
				} else {
					throw new Error(JSON.stringify(data));
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
				return false;
			}
		},

		async editarGrade({ commit, dispatch, state }, payload) {
			try {
				state.carregando = true;
				const body = JSON.stringify({
					identificacao: `${payload.identificacao || ""}`.toUpperCase(),
					grade: payload.grades?.length ? payload.grades.map(grade => `${grade || ""}`.toUpperCase()) : [],
				});
				let url = `grade?codigo=${payload.codigo}`;
				const data = await doRequest(url, { method: "put", cache: "no-cache", body }, commit);
				if (data.codigo) {
					await dispatch("buscarGrades");
					state.carregando = false;
					return new Grade(data);
				}
				else {
					throw new Error(JSON.stringify(data));
				}
			} catch (error) {
				console.log(error);
				state.carregando = false;
				commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
				return false;
			}
		},

		async excluirGrades({ commit, dispatch }, payload) {
			try {
				let url = `grade?codigo=${payload}`;
				const data = await doRequest(url, { method: "delete", cache: "no-cache" }, commit);
				if (!data.erro) {
					await dispatch("buscarGrades");
					return data;
				}
				else {
					throw new Error(JSON.stringify(data));
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", { mensagem: JSON.stringify(error.message)});
				return false;
			}
		},
		async excluirGrade({ state, commit, dispatch }, payload) {
			try {
				let url = `grade/grade?codigo=${payload.codigo}&grade=${payload.grade}`;
				const data = await doRequest(url, { method: "delete", cache: "no-cache" }, commit);
				if (!data.erro) {
					await dispatch("buscarGrades");
					return state.Grades.find(e => e.codigo == payload.codigo);
				}
				else {
					throw new Error(JSON.stringify(data));
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", { mensagem: JSON.stringify(error) });
				return false;
			}
		},
	},


	mutations: {
		atualizarListaGrades(state, payload) {
			state.Grades = [];
			if (!payload) return;
			state.Grades = [...payload].map(grade => new Grade(grade));
		},
	},
};
