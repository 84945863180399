// import state from "../store/state";
import { doRequest } from "./http";
import { Financeiro } from "../models/Financeiro.js";

export default {
	state: {
		consorcio: [],
		finaconsorcio: null,
	},

	actions: {
		async buscarUnicoConsorcio({commit}, payload){
			try {
				let url;
				if (!payload || payload == undefined){
					url = "grupoProduto/all?page=0&size=25&sort=descricao&sortOrder=asc";
				} else {
					url = `grupoProduto/all?page=${payload.page || 0}&size=${payload.size || 25}&sort=descricao&sortOrder=asc`;
				}
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.content) {
					return commit("atualizarListaGrupoProdutos", data);
				}
				else {
					return commit("atualizarListaGrupoProdutos", {
						content: [],
						page: 0,
						totalPages: -1,
					});
				}
			} catch (error) {
				console.log(error);
			}
		},

		async FiltrarConsorcios({commit, state}, payload){
			state.carregando = true;
			try {                          
				let url = `consorcio/filter?empresa=${payload.cdempresa}${payload.cdcliente ? `&cdcliente=${payload.cdcliente}` : ""}${payload.cdvendedor ? `&cdvendedor=${payload.cdvendedor}` : ""}`+
                `${payload.parcelasPago ? `&parcelasPago=${payload.parcelasPago}` : ""}${payload.status ? `&status=${payload.status}` : ""}${payload.dataf ? `&dataf=${payload.dataf}` : ""}${payload.datai ? `&datai=${payload.datai}` : ""}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.length) {
					return commit("atualizarListaConsorcio", data);
				}
				commit("atualizarListaConsorcio",[]);
			} catch (error) {
				commit("atualizarListaConsorcio",[]);
			}
			state.carregando = false;
		},


		async adicionarConsorcio({state, commit, dispatch}, payload){
			try {
				state.carregando = true;
				const formaPagamentoConsorcio = state.formasPagamento.find((pgto) => pgto.consorcio == "C");
				if (!formaPagamentoConsorcio) {
					throw new Error("Favor conferir forma de pagamento consórcio 'C'!");
				}
				const body = JSON.stringify ({
					cdIdentificador : state.Caixa.caixa.cdcaixa,
					cdcliente: payload.cdcliente,
					cdempresa: state.Empresa.id,
					cdvendedor: payload.cdvendedor,
					data: payload.data,
					liberado: payload.liberado,
					observacao: payload.observacao,
					parcelas: payload.parcelas.map((parc) => ({
						cddig : 0,
						cdlan: parc.codigo,
						cdtipopgto: formaPagamentoConsorcio.cdtipo,
						contarc: Number(parc.parcela),
						credito: 0,
						datapgto: null,
						datavenc: parc.vencimento,
						observa: `Realizado por ${state.Usuario.id} - ${state.Usuario.nome} em ${new Date().toLocaleString("pt-BR")}`,
						pagamento:  formaPagamentoConsorcio.descricao
					})),
					qtdeParcela: payload.qtdeParcela,
					status: payload.status,
					uciduser: state.Usuario.id,
					utilizado: 0,
					valor: payload.valor
				});
				let url = "consorcio";
				const data = await doRequest(url, {method: "post", body}, commit);
				state.carregando = false;
				if (data.erro) {
					commit("relatarErro", {mensagem: data.mensagem});
					return null;
				}
				if (!data.cdconsorcio) {
					commit("relatarErro", {mensagem: data.mensagem || JSON.stringify(data)});
					return null;
				}
				await dispatch("gerarComprovanteConsorcio", {
					...data,
					empresa: state.Empresa,
					parcelas: data.parcelas.map(conta => new Financeiro(conta)),
				});
				return data;
			} catch (error) {
				console.log(error);
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
		},

		async atualizarConsorcio({state, commit}, payload) {
			try {
				state.carregando = true;
				const formaPagamentoConsorcio = state.formasPagamento.find((pgto) => pgto.consorcio == "C");
				if (!formaPagamentoConsorcio) {
					throw new Error("Favor conferir forma de pagamento consórcio 'C'!");
				}
				const body = JSON.stringify ({
					cdIdentificador: payload.cdIdentificador,
					cdcliente: payload.cdcliente,
					cdempresa: payload.cdempresa,
					cdvendedor: payload.cdvendedor,
					data: payload.data,
					liberado: payload.liberado,
					observacao: payload.observacao,
					parcelas: payload.parcelas.map((parc) => ({
						cdlan: parc.codigo,
						cddig: parc.cddig,
						cdtipopgto: state.formasPagamento.find((pgto) => pgto.consorcio == "C").cdtipo,
						contarc: Number(parc.parcela),
						credito: 0,
						datapgto: null,
						datavenc: parc.vencimento,
						observa: `Alterado por ${state.Usuario.id} - ${state.Usuario.nome} em ${new Date().toLocaleString("pt-BR")}`,
						pagamento:  state.formasPagamento.find((pgto) => pgto.consorcio == "C").descricao
					})),
					qtdeParcela: payload.qtdeParcela,
					status: payload.status,
					uciduser: payload.uciduser,
					utilizado: payload.utilizado,
					valor: payload.valor
				});
				let url = `consorcio?id=${payload.cdconsorcio}`;
				const data = await doRequest(url, {method: "put", body}, commit);
				state.carregando = false;
				if (data.erro) {
					return commit("relatarErro", {mensagem: data.mensagem});
				}
				if (!data.cdconsorcio) {
					return commit("relatarErro", {mensagem: data.mensagem || JSON.stringify(data)});
				}
			} catch (error) {
				console.log(error);
				return commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
		},

		async liberarValorConsorcio({state, commit}, payload) {
			try {
				state.carregando = true;
				let url = `consorcio/liberarValor?id=${payload.cdconsorcio}&cdempresa=${payload.cdempresa}`;
				url += `&valorLiberar=${payload.valorLiberar}`;
				const data = await doRequest(url, {method: "put"}, commit);
				state.carregando = false;
				if (data.erro) {
					return commit("relatarErro", {mensagem: data.mensagem});
				}
				if (Object.keys(data).includes("details")) {
					return commit("relatarErro", {mensagem: JSON.stringify(data)});
				}
			} catch (error) {
				console.log(error);
				return commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
		},

		async cancelarConsorcio({state, commit}, payload) {
			try {
				state.carregando = true;
				let url = `consorcio/cancelarConsorcio?id=${payload.cdconsorcio}&cdempresa=${payload.cdempresa}`;
				const data = await doRequest(url, {method: "delete"}, commit);
				state.carregando = false;
				if (data.erro) {
					return commit("relatarErro", {mensagem: data.mensagem});
				}
				if (Object.keys(data).includes("details")) {
					return commit("relatarErro", {mensagem: JSON.stringify(data)});
				}
			} catch (error) {
				console.log(error);
				return commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
		},
	},

	mutations: {
		atualizarListaConsorcio(state, payload) {
			state.consorcio = [];
			state.consorcio = payload;
		},
	},
    
};

