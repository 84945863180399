<template>
	<!-- COMPONENTE PARA CONTROLAR A PAGINACAO -->
	<div class="container-paginacao">
		<div v-if="habilitaFiltroGrupo">
			<span @click="solicitarAberturaModal" class="noneSpan">{{ grupos? "Grupos" : "Produtos"}}</span><span class="newSpan">{{ grupos? "Grupos" : "Prod."}}</span>
			<i @click="solicitarAberturaModal" class="fas fa-toggle-on switch-icon" style="color: var(--confirmar)" v-if="grupos"></i>
			<i @click="solicitarAberturaModal" class="fas fa-toggle-off switch-icon" style="color: var(--confirmar)" v-else></i>
		</div>	
		<div class="paginacao-content" v-if="Paginacao.totalPaginas > 0">
			<span 
				v-if="Paginacao.paginaAtual > 1" 
				@click="alterarPaginacao(0)"
			>
				1
			</span>
			<span 
				v-if="Paginacao.paginaAtual > 1" 
			>
				...
			</span>
			<span 
				v-if="Paginacao.paginaAtual > 0" 
				@click="alterarPaginacao(Paginacao.paginaAtual -1)"
			>
				{{ Paginacao.paginaAtual }}
			</span>
			<span 
				class="active" 
				@click="alterarPaginacao(Paginacao.paginaAtual)"
			>
				{{ Paginacao.paginaAtual + 1 }}
			</span>
			<span 
				v-if="Paginacao.paginaAtual !== Paginacao.totalPaginas - 1"
				@click="alterarPaginacao(Paginacao.paginaAtual + 1)" 
			>
				{{ Paginacao.paginaAtual + 2 }}
			</span>

			<span v-if="Paginacao.paginaAtual !== Paginacao.totalPaginas - 1">
				...
			</span>
			<span 
				v-if="Paginacao.paginaAtual !== Paginacao.totalPaginas - 1"
				@click="alterarPaginacao(Paginacao.totalPaginas - 1)"
			>
				{{ Paginacao.totalPaginas }}
			</span>
		</div>
		<Modal v-if="selecionarGrupo" v-on:fecharModal="selecionarGrupo = false">
			<ConsultaGrupos
				@mudarModal="solicitarAberturaModal" 
				:mostrarBotaoNovo="false"
				@selecionarGrupos="selecionarPorGrupo"
				:habilitaFiltroGrupo="true"
				:heightTabela="'55vh'"
				:modal="true"
				:desabilitarAcao="true"
			/>
		</Modal>
	</div>	
</template>
<script>
import ConsultaGrupos from "./ConsultaGrupos.vue";
import Modal from "./Modal.vue";

export default {
	name: "PaginacaoControle",
	
	data(){		
		return {
			selecionarGrupo: false,
		};
	},
	
	components: { 
		ConsultaGrupos,
		Modal,
	},
	
	props: {
		Paginacao: Object,
		habilitaFiltroGrupo: Boolean,
		grupos: Boolean,
	},
	
	methods: {
		alterarPaginacao(page) {
			this.$emit("alterarPaginacao", page);
		},
		solicitarAberturaModal(){
			this.selecionarGrupo = !this.selecionarGrupo;
			this.$emit("mudarModal");
			this.$forceUpdate();
		},
		async selecionarPorGrupo ({grupo, subgrupo}) {
			let data = await this.$store.dispatch("buscarProdutoPorGrupo", {grupo, subgrupo});
			if(!data){
				return this.$store.commit("relatarErro", {mensagem: "Nenhum produto encontrado"});
			}
			const Paginacao = {
				totalElements: data.length,
				totalPages: 1,
				page: 0
			};
			this.$store.commit("atualizarListaProdutosState", {Produtos: data, Paginacao: Paginacao});
			this.selecionarGrupo = false;
			this.buscarGrupo = true;
		},
	},
};
</script>
<style scoped>
.container-paginacao{
	display: flex;
	justify-content: center;
	align-items: center;	
	width: 100%;
	height: 6vh;
}
.paginacao-content{
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.paginacao-content span{
	cursor: pointer;
}
.active{
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--primario);
	width: 32px;
	height: 32px;
	border-radius: 5px;
	background-color: #cecece;
}
.newSpan{
	display: none;
}
@media screen and (max-width: 425px) {
	.noneSpan{
		display: none;
	}
	.newSpan{
		display: inline;
		width: 40px !important;
	}
}
</style>