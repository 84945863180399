<template>
	<ContainerPage id="container-fluxo-caixa">
		<ContentPage class="body-page">
			<button 
				id="relatorio" 
				@click="gerarRelatorioFluxoCaixa"
			>
					<i class="fas fa-print"></i>
			</button>
			<Filtro
				@filtrar="filtrar()"
				@limpar="limparFiltro"
				@handleShowTable="handleShowTable"
			>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="input-group">
								<select 
									v-model="filtro.empresa" 
									id="empresa"
								>
									<option 
										v-for="(emp, index) in Empresas" 
										:key="index"
										:value="emp.id"
									>
										{{ emp.id }} - {{ emp.nome }}
									</option>
								</select>
								<label for="empresa">
									Empresa
								</label>
							</div>
							<div class="input-group">
								<select v-model="filtro.conta" id="conta">
									<option value="SEL" disabled>SELECIONE UMA CONTA</option>
									<option v-for="(conta, index) in Contas" :key="index" :value="{agencia : conta.agencia, banco : conta.banco, conta : conta.cc}">
										{{ conta.nome }}
									</option>
								</select>
								<label for="conta">
									Conta
								</label>
							</div>
							<div class="input-group">
								<select 
									id="centrodecusto"
									v-model="filtro.centroCusto"
								>
									<option disabled value="SEL">SELECIONE UMA OPÇÃO</option>
									<option 
										v-for="centro in CentroCustos"
										:key="centro.cdcusto"
										:value="centro.cdcusto"
									>
										{{ centro.cdcusto }} - {{ centro.descricao }}
									</option>
								</select>
								<label for="centrodecusto">
									Centro de Custo
								</label>
							</div>
							<div class="input-group">
								<input 
									type="date" 
									placeholder=" " 
									id="dataI" 
									v-model="filtro.dataI"
									@blur="validarData('dataI')"
								/>
								<label for="dataI">
									De
								</label>
							</div>
							<div class="input-group">
								<input 
									type="date" 
									placeholder=" " 
									id="dataF" 
									v-model="filtro.dataF"
									@blur="validarData('dataF')"
								/>
								<label for="dataF">
									Até
								</label>
							</div>
						</div>
					</div>
				</div>
			</Filtro>
			<div :style="{height:this.height}">
				<div class="container-tabela" v-if="FluxosCaixa.length > 0" >
					<div class="header-tabela">
						<div class="linha-header-tabela">					
							<div class="campo-tabela campoGrande">
								<span>Nível</span>
							</div>
							<div class="campo-tabela">
								<span>Valor</span>
							</div>
							<div class="campo-tabela">
								<span>Grupo</span>
							</div>
							<div class="campo-tabela">
								<span>Total</span>
							</div>
							<div class="campo-tabela">
								<span>Saldo</span>
							</div>
						</div>
					</div>
					<div class="body-tabela">						
						<div v-for="(fluxo, index) in FluxosCaixa" :key="index">
							<div class="linha-body-tabela" style="height: 1px;background-color: #cecece;">
							</div>
							<div class="linha-body-tabela">
								<div class="campo-tabela campoGrande">
									{{fluxo.nivel?fluxo.nivel.replace(/ /g, '&nbsp;'):""}}<i class="fas fa-eye" v-if="fluxo.pesquisa" style="color: var(--primario)" @click="pesquisa(fluxo)"></i>
								</div>
								<div class="campo-tabela">
									{{fluxo.valor!=null?fluxo.valor.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}):""}}
								</div>
								<div class="campo-tabela">
									{{fluxo.grupo!=null?fluxo.grupo.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}):""}}
								</div>
								<div class="campo-tabela">
									{{fluxo.total!=null?fluxo.total.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}):""}}
								</div>								
								<div class="campo-tabela">
									{{fluxo.saldo!=null?fluxo.saldo.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}):""}}
								</div>
							</div>							
						</div>
					</div>
				</div>
			</div>
			<Modal
				v-if="mostrarDetalhes" 
				@fecharModal="mostrarDetalhes = false"
				:idModal="'adicionar-centro-custo'"
				:dimensoes="{width: '85vw', height: '75vh'}"
			>
				<div class="conteudoModal">
					<div class="tabelaModal">
						<div class="container-tabela">
							<div class="header-tabela">
								<div class="linha-header-tabela">
									<div class="campo-tabela campoGrandeDetalhe">
										<span>Favorecido</span>
									</div>			
									<div class="campo-tabela campoGrandeDetalhe">
										<span>Nível</span>
									</div>
									<div class="campo-tabela">
										<span>Valor</span>
									</div>
									<div class="campo-tabela">
										<span>Data Baixa</span>
									</div>
									<div class="campo-tabela campoGrande">
										<span>Observação</span>
									</div>
								</div>
							</div>
							<div class="body-tabela">						
								<div v-for="(detalhe, index) in detalhes" :key="index">
									<div class="linha-body-tabela" style="height: 1px;background-color: #cecece;">
									</div>
									<div class="linha-body-tabela">
										<div class="campo-tabela campoGrandeDetalhe">
											{{ detalhe.favorecido }}
										</div>
										<div class="campo-tabela campoGrandeDetalhe">
											{{ detalhe.nivel }}
										</div>
										<div class="campo-tabela">
											{{detalhe.valor!=null?detalhe.valor.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}):""}}
										</div>
										<div class="campo-tabela">
											{{detalhe.databaixa.split("-").reverse().join("/")}}
										</div>
										<div class="campo-tabela campoGrande">
											{{ detalhe.observacao }}
										</div>
									</div>							
								</div>
							</div>
						</div>
					</div>
					<button class="botao botao-acao" @click="gerarRelatorioDetalhesFluxoCaixa">
						Imprimir <i class="fas fa-print" style="color:var(--primario)"></i>
					</button>
				</div>
			</Modal>
		</ContentPage>
	</ContainerPage>
</template>
<script>
import Filtro from "../components/Filtro.vue";
import ContentPage from "../components/ContentPage.vue";
import ContainerPage from "../components/ContainerPage.vue";
import Modal from "../components/Modal.vue";
import formatarParaReais from "../filters/formatarParaMoeda";
import { proximoCampo } from "../utils/utils";
export default{
	name: "FluxoCaixa",
	components: {
		Filtro,
		ContentPage,
		ContainerPage,
		Modal,
	},
	data(){
		return {
			proximoCampo,
			height:"",
			filtro:{
				empresa: this.$store.state.Empresa.id,
				conta: "SEL",
				centroCusto: "SEL",
				dataI:"",
				dataF:"",
			},
			mostrarDetalhes:false,
			detalhes:[],
		};
	},
	computed: {
		Empresas(){
			return this.$store.state.Empresas;
		},
		Contas(){
			return this.$store.state.Contas.filter(c => c.ativo === "A");
		},
		CentroCustos(){
			return this.$store.state.CentroCustos.Lista;
		},
		FluxosCaixa() {
			return this.$store.state?.FluxoCaixa?.ListaFluxoCaixa;
		},
		isMobileDevice() {
			const userAgent = navigator.userAgent;
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
		},
	},
	mounted(){
		this.limparFiltro();
		this.filtrar();
		this.handleShowTable();
	},

	filters: {
		formatarParaReais
	},

	methods: {
		async filtrar(){
			const filtro = {...this.filtro};
			filtro.conta = filtro.conta === "SEL"? null:filtro.conta;
			filtro.centroCusto = filtro.centroCusto === "SEL"? null:filtro.centroCusto;
			await this.$store.dispatch("buscarFluxoCaixa",filtro);
		},
		limparFiltro(){
			const anoAtual = new Date().getFullYear();
			const mesAtual = new Date().getMonth();
			const diaAtual = new Date().getDate();
			this.filtro.dataI = new Date(anoAtual, mesAtual, 1).toLocaleDateString("en-ca");
			this.filtro.dataF = new Date(anoAtual, mesAtual, diaAtual).toLocaleDateString("en-ca");
			this.filtro.empresa = this.$store.state.Empresa.id;
			this.filtro.conta = "SEL";
			this.filtro.centroCusto = "SEL";
		},
		validarData(campo) {
			if(this.filtro[campo] === ""){
				this.$store.commit("relatarErro", {
					mensagem: "Data inválida, por favor verifique"
				});
				this.proximoCampo(campo);
			}
		},
		handleShowTable() {
			if(this.isMobileDevice){
				if(this.height === "30vh") {
					this.height = "60vh";
				} else {
					this.height = "30vh";
				}
			} else {
				if(this.height === "60vh") {
					this.height = "75vh";
				} else {
					this.height = "60vh";
				}
			}
		},
		async gerarRelatorioFluxoCaixa() {
			const filtro = {
				conta: this.filtro.conta === "SEL"? "":this.filtro.conta,
				centroCusto: this.filtro.centroCusto === "SEL"? "":this.filtro.centroCusto,
				dataI: this.filtro.dataI,
				dataF: this.filtro.dataF,
				empresa: this.filtro.empresa,
			};
			const data = {
				empresa: this.$store.state.Empresa,
				itens: [...this.FluxosCaixa],
				filtro: filtro,
			};
			this.$store.dispatch("gerarRelatorioFluxoCaixa", data );
		},
		async pesquisa(fluxo) {
			const filtro = {
				conta: this.filtro.conta === "SEL"? null:this.filtro.conta,
				centroCusto: this.filtro.centroCusto === "SEL"? null:this.filtro.centroCusto,
				dataI: this.filtro.dataI,
				dataF: this.filtro.dataF,
				pesquisa: fluxo.pesquisa,
			};
			const detalhes = await this.$store.dispatch("buscarDetalhesFluxoCaixa",filtro);
			this.detalhes = detalhes;
			this.mostrarDetalhes = true;
		},
		async gerarRelatorioDetalhesFluxoCaixa() {
			const filtro = {
				conta: this.filtro.conta === "SEL"? null:this.filtro.conta,
				centroCusto: this.filtro.centroCusto === "SEL"? null:this.filtro.centroCusto,
				dataI: this.filtro.dataI,
				dataF: this.filtro.dataF,
				empresa: this.filtro.empresa,
			};
			const data = {
				empresa: this.$store.state.Empresa,
				itens: [...this.detalhes],
				filtro: filtro,
			};
			this.$store.dispatch("gerarRelatorioDetalhesFluxoCaixa", data );
		},
	}
};
</script>
<style scoped>
.conteudoModal{
	height: 100%;
}
.tabelaModal{
	height: 80%;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	margin-top: 7px;
}
.container-tabela{
	width: 95%;
	overflow: auto;
	height: 100%;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	font-size: 13px;
}
.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0px 5px;
}
.header-tabela, .body-tabela{
	width: 100%;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	width: 100%;
}
.body-tabela .linha-body-tabela{
	width: 100%;
	border-bottom: 1px solid #cecece;
}
.campo-tabela{
	min-width: 130px;
}
.campoGrande{
	width: 100%;
}
.campoGrandeDetalhe {
	min-width: 200px !important;
}
.container-list{
	display: flex;
	width: 100%;
	flex-direction: column;
	height: 62vh;
	overflow-y: auto;
}
.group-pesquisar{
	width: 45vw;
}
.input-value {
	text-align: right;
}
.pc-nome{
	min-width: 250px;	
	display: flex;
	align-items: center
}
.card-pc{
	display: flex;
	align-items: center;
}
#relatorio{
	width: 50px;
	height: 50px;
	border-radius: 50%;
	text-align: center;
	position: absolute;
	bottom: 15px;
	right: 30px;
	border: none;	
	outline: none;
	background-color: var(--acao-background);
}
#relatorio i {
	font-size: 20px;
}
</style>
