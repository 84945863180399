<template>
    <div class="col w-100 my-5 extrato">
        <h2>Extrato de caixa</h2>
        <h2>{{Caixa.caixa.cdcaixa}} - {{Caixa.caixa.identificador}}</h2>
        <div class="extrato-item">
            <h3 class="text-left">Abertura</h3>
            <h3 class="extrato-item-valor">{{(Caixa.saldoAnterior || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</h3>
        </div>
        <div class="extrato-item" v-for="(movimento, index) in Caixa.movimentacaoRecebimento.filter(metodo => (metodo.valor >0) && (metodo.deposito == 'N') && metodo.vistaprazo == 1)" :key="`${movimento.cdtipo}R-${index}`">
            <span class="text-left">Recebimentos em {{movimento.descricao.toLowerCase()}} </span>
            <span class="extrato-item-valor">{{(movimento.valor || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</span>
        </div>
        <div class="extrato-item" v-for="(movimento, index) in Caixa.movimentacaoVista.filter(metodo => (metodo.valor >0) && (metodo.deposito == 'N') )" :key="`${movimento.cdtipo}V-${index}`">
            <span class="text-left">Vendas em {{(movimento.descricao).toLowerCase()}} </span>
            <span class="extrato-item-valor">{{(movimento.valor || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</span>
        </div>
        <div class="extrato-item">
            <h3 class="text-left">Total Sistema</h3>
            <h3 class="extrato-item-valor">
                {{
                    Number(total || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})
                }}
            </h3>
        </div>
        <div class="extrato-item" style="color: var(--confirmar)">
            <span class="text-left">Recargas</span>
            <span class="extrato-item-valor">+ {{(Caixa.movimentoCaixa.recarga || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</span>
        </div>
        <div class="extrato-item" style="color: var(--deletar)">
            <span class="text-left">Sangrias</span>
            <span class="extrato-item-valor">- {{(Caixa.movimentoCaixa.pagamento || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</span>
        </div>
		<div class="extrato-item">
            <h3 class="text-left">Saldo final </h3>
            <h3 class="extrato-item-valor">{{(Caixa.saldoFinal || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</h3>
        </div>

        <div class="extrato-item">
            <h3 class="text-left">Total manual </h3>
            <h3 class="extrato-item-valor">
                {{
					Number(totalManual || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})

					
                }}
            </h3>
        </div>
        <div class="extrato-item" 
        :style="Number(Number(Number(Caixa.movimentacaoVista.filter(metodo => (metodo.valorManual > 0) && (metodo.deposito == 'N')).map(m => m.valorManual).length > 0 ?
                    Caixa.movimentacaoVista.filter(metodo => (metodo.valorManual > 0) && (metodo.deposito == 'N')).map(m => m.valorManual)
                    .reduce((t, v) => t += v) : 0) +
                    Number(Caixa.movimentacaoRecebimento.filter(metodo => (metodo.valorManual > 0) && (metodo.deposito == 'N') && metodo.vistaprazo == 1).map(m => m.valorManual).length > 0 ?
                    Caixa.movimentacaoRecebimento.filter(metodo => (metodo.valorManual > 0) && (metodo.deposito == 'N') && metodo.vistaprazo == 1).map(m => m.valorManual)
                    .reduce((t, v) => t += v) : 0)) -
                    Number(Number(Caixa.movimentacaoVista.filter(metodo => (metodo.valor > 0) && (metodo.deposito == 'N')).map(m => m.valor).length > 0 ?
                    Caixa.movimentacaoVista.filter(metodo => (metodo.valor > 0) && (metodo.deposito == 'N')).map(m => m.valor)
                    .reduce((t, v) => t += v) : 0) +
                    Number(Caixa.movimentacaoRecebimento.filter(metodo => (metodo.valor > 0) && (metodo.deposito == 'N') && (metodo.vistaprazo == 1)).map(m => m.valor).length > 0 ?
                    Caixa.movimentacaoRecebimento.filter(metodo => (metodo.valor > 0) && (metodo.deposito == 'N') && metodo.vistaprazo == 1).map(m => m.valor)
                    .reduce((t, v) => t += v) : 0))
                ) > 0.01 ? 'color: var(--confirmar)' : Number(Number(Number(Caixa.movimentacaoVista.filter(metodo => (metodo.valorManual > 0) && (metodo.deposito == 'N')).map(m => m.valorManual).length > 0 ?
                    Caixa.movimentacaoVista.filter(metodo => (metodo.valorManual > 0) && (metodo.deposito == 'N')).map(m => m.valorManual)
                    .reduce((t, v) => t += v) : 0) +
                    Number(Caixa.movimentacaoRecebimento.filter(metodo => (metodo.valorManual > 0) && (metodo.deposito == 'N') && metodo.vistaprazo == 1).map(m => m.valorManual).length > 0 ?
                    Caixa.movimentacaoRecebimento.filter(metodo => (metodo.valorManual > 0) && (metodo.deposito == 'N') && metodo.vistaprazo == 1).map(m => m.valorManual)
                    .reduce((t, v) => t += v) : 0)) -
                    Number(Number(Caixa.movimentacaoVista.filter(metodo => (metodo.valor > 0) && (metodo.deposito == 'N')).map(m => m.valor).length > 0 ?
                    Caixa.movimentacaoVista.filter(metodo => (metodo.valor > 0) && (metodo.deposito == 'N')).map(m => m.valor)
                    .reduce((t, v) => t += v) : 0) +
                    Number(Caixa.movimentacaoRecebimento.filter(metodo => (metodo.valor > 0) && (metodo.deposito == 'N') && (metodo.vistaprazo == 1)).map(m => m.valor).length > 0 ?
                    Caixa.movimentacaoRecebimento.filter(metodo => (metodo.valor > 0) && (metodo.deposito == 'N') && metodo.vistaprazo == 1).map(m => m.valor)
                    .reduce((t, v) => t += v) : 0))
                ) < -0.01 ? 'color: var(--deletar)' : ''">
            <h3 class="text-left">Diferença </h3>
            <h3 class="extrato-item-valor">
                {{
                    Number(diferenca || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})
                }}
            </h3>
        </div>

        <button class="botao botao-adicionar w-90 m-auto" @click="imprimirExtrato">Imprimir</button>
    </div>
</template>

<script>
export default {
	name: "ExtratoVista",

	props: {
		Caixa: Object
	},
	computed: {
		totalManual () {
			return Number(Number(this.vendas.valor) + Number(this.recebimentos.valor));
		},
		total () {
			let valor = 0;
			const movVista = this.Caixa.movimentacaoVista.filter(metodo => (metodo.valor > 0) && (metodo.deposito == "N"));
			if(movVista.length > 0){

				movVista.forEach(m => valor += m.valor);
			}
			const movimentacaoRecebimento = this.Caixa.movimentacaoRecebimento.filter(metodo => (metodo.valor > 0) && (metodo.deposito == "N") && metodo.vistaprazo == 1);
			if(movimentacaoRecebimento.length > 0){

				movimentacaoRecebimento.forEach(m => valor += m.valor);
			}
			valor += this.Caixa.saldoAnterior;
			return valor;
		},
		diferenca () {
			return Number(Number(this.vendas.valor + this.recebimentos.valor) - Number(this.totalManual));
		},
		vendas () {
			const venda = {};
			const movVista = this.Caixa.movimentacaoVista.filter(metodo => (metodo.valor > 0) && (metodo.deposito == "N"));
			movVista.forEach(mov => {
				venda.descricao = mov.descricao;
				venda.valor = mov.valor;
			});
			return venda;
		},
		recebimentos () {
			const recebimento = {};

			const movRecebimento = this.Caixa.movimentacaoRecebimento.filter(metodo => (metodo.valor >0) && (metodo.deposito == "N") && metodo.vistaprazo == 1);
			movRecebimento.forEach(mov => {
				recebimento.descricao = mov.descricao;
				recebimento.valor = mov.valor;
			});
			return recebimento;
		},
	},

	methods: {
		imprimirExtrato() {
			this.$store.dispatch("gerarExtratoVista", {
				...this.Caixa, 
				total: this.total,
				totalManual: this.totalManual,
				diferenca: this.diferenca,
				venda: this.vendas,
				recebimento: this.recebimentos,
			});
		}
	},
	mounted(){
	}
};
</script>

<style scoped>
.extrato {
    display: flex;
    overflow: auto;
    padding-bottom: 10px;
}

.extrato-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px auto;
    width: 90%;
    min-width: 400px !important;
}
.extrato-item-valor {
    min-width: max-content;
}
</style>