import { doRequest } from "./http";

export default {
	state: {
		Contas: [],
		paginaContasAtual: 0,
		totalPaginasContas: -1
	},

	actions: {

		async buscarContaBancoUnica({commit, state}, payload)  {
			try {
				const {
					agencia,
					banco,
					cc,
				} =  payload;
				state.carregando = true;
				if(!agencia || !banco || !cc) {
					throw new Error("Esta faltando algum campo para realizar a filtragem");
				} else {
					let url = `banco?agencia=${agencia}&banco=${banco}&cc=${cc}`;

					const data = await doRequest(url, {
						method: "GET",
						cache: "no-cache"
					}, commit);
					state.carregando = false;
					if(data && !data.erro || !data.error) {
						return data;
					}
					return null;
				}
			} catch (error) {
				console.error(error.message);

				return null;
			}
		},

		async buscarContas({commit, state}) {
			try {
				state.carregando = true;
				let url;
				url = `banco/filter${state.Usuario.usuarioPrivilegiado ? "" : `?cdempresa=${state.Empresa.id}`}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.content) {
					commit("atualizarListaContas", data);
				} else {
					commit("atualizarListaContas", {
						content: [],
						page: 0,
						totalPages: -1
					});
				}
				state.carregando = false;
			} catch (error) {
				console.log(error);
				state.carregando = false;
			}
		},

		async adcionarConta({commit, state}, payload) {
			try {
				state.carregando = true;
				const body = JSON.stringify({
					agencia: payload.agencia,
					ativo: payload.ativo,
					banco: payload.banco,
					cc: payload.cc,
					cdempresa: payload.cdempresa || state.Empresa.id,
					nome: payload.nome,
					tipo: payload.tipo,
					aceitapix: payload.aceitapix
				});
				let url = "banco";
				const data = await doRequest(url, {method: "post", body}, commit);
				state.carregando = false;
				if (data.message == "Server Error") {
					return commit("relatarErro", {mensagem: JSON.stringify(data.details)});
				}
			} catch (error) {
				console.log(error);
			}
		},

		async editarConta({commit, state}, payload){
			try {
				state.carregando = true;
				const body = JSON.stringify({
					ativo: payload.ativo,
					cdempresa: payload.cdempresa || state.Empresa.id,
					nome: payload.nome,
					tipo: payload.tipo,
					aceitapix: payload.aceitapix,
				});
				let url = `banco?agencia=${payload.ContaAnterior.agencia}&banco=${payload.ContaAnterior.banco}&cc=${payload.ContaAnterior.cc}`;
				const data = await doRequest(url, {method: "put", body}, commit);
				state.carregando = false;
				if (data.message == "Server Error") {
					return commit("relatarErro", {mensagem: JSON.stringify(data.details)});
				}
			} catch (error) {
				console.log(error);
			}
		},

		async filtrarContas({commit, state}, payload){
			try {
				state.carregando = true;
				let url = `banco/filter?nome=${payload.todos}${state.Usuario.usuarioPrivilegiado ? "" : `&cdempresa=${state.Empresa.id}`}`;
				const data = await doRequest(url, {method: "get"}, commit);
				state.carregando = false;
				if (!data.content) {
					commit("atualizarListaContas", null);
				} else {
					commit("atualizarListaContas", data);
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
			}
		},
	},

	mutations: {
		atualizarListaContas(state, payload){
			state.Contas = [];
			state.paginaContasAtual = -1;
			state.totalPaginasContas = 0;
			if (payload) {
				state.Contas = payload.content;
				state.paginaContasAtual = payload.page;
				state.totalPaginasContas = payload.totalPages;
			}
		},
	}
};