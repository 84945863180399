
<template>
	<div class="containerPage">
		<h1 class="titulo">Cadastro Crédito Cliente</h1>
		<div class="containerForm">
			<div class="cadastroLinha">
				<div class="input-group small-input">
					<input 
					type="date" 
					v-model="form.dataCadastro"
					placeholder=" " 
					@blur="saiuData();validaData()"
					/>
					<label>Data cadastro</label>
				</div>
				<div class="input-group pessoa">
					<input 
					type="text" 
					:value="vendedorSelecionado.id 
							? `Vendedor: ${vendedorSelecionado.id} - ${vendedorSelecionado.nome}` 
							: 'Clique para selecionar um vendedor'" 
						readonly
						id="selecionaVendedor"
						@click="listarVendedores = true"
					/>
					<i 
						class="fas fa-times" 
						style="color: red;" 
						@click="vendedorSelecionado = {}"
						v-if="vendedorSelecionado.id"			
						
					/>
				</div>
			</div>
			<div class="cadastroLinha">
				<div class="input-group pessoa">
					<input type="text" 
					:value="clienteSelecionado.id 
							? `Cliente: ${clienteSelecionado.id} - ${clienteSelecionado.nome}` 
							: 'Clique para selecionar um cliente'" 
						id="selecionaCliente"
						readonly
						@click="listarClientes = true"
					/>
					<i 
						class="fas fa-times" 
						style="color: red;" 
						@click="clienteSelecionado = {}"
						v-if="clienteSelecionado.id"
					/>
				</div>
				<div class="input-group small-input">
					<input 
					type="text"
					id="valor"
					style="text-align: right;"
					v-model="form.valor"
					placeholder=" "
					v-mascaraFloat
					/>
					<label for="valor">Valor em R$</label>
				</div>
			</div>
			<div class="cadastroLinha">
				<div class="input-group" id="observacao">
					<input 
						type="text" 
						v-model="form.observacao" 
						id="observacao"
						style="text-transform: uppercase; height: auto; " 
						placeholder=" "
					/>
					<label for="observacao">Observação</label>
				</div>
			</div>
		</div>
		<div class="botoes">
			<button class="botao botao-cancelar" @click="$emit('cancelarCreditoCliente')">
				Cancelar
			</button>
			<button class="botao botao-adicionar" @click="cadastrarCredito">
				{{this.credito.novo?"Cadastrar":"Salvar"}}
			</button>
		</div>
		<Modal 
			:dimensoes="{width: '95%', height: '95%'}" 
			v-if="listarClientes"
			@fecharModal="fecharModalCancelarCliente"
		>
			<consulta-cliente  
				@selecionarPessoa="selecionarCliente" 
				@adicionarNovo="cadastrandoCliente = {novo: true}"
				:mostrarBotaoNovo="true"
				v-if="!cadastrandoCliente"
			/>
			<!-- <Paginacao tipoConteudo="Clientes" @adicionarNovo="cadastrandoCliente = {novo: true}" 
				v-if="!cadastrandoCliente" >
				<TabelaPessoas 
					tipoConteudo="Clientes" 
					@selecionarPessoa="selecionarCliente"
				/>
			</Paginacao> -->
			<CadastroCliente
				v-else-if="cadastrandoCliente != null"
				:cliente="cadastrandoCliente"
				@cancelarEdicao="cancelarEdicaoCliente"
			>
			</CadastroCliente>
		</Modal>
		<Modal
			:dimensoes="{width: '95%', height: '95%'}"
			v-if="listarVendedores"
			@fecharModal="fecharModalCancelarVendedor"
		>
			<consulta-vendedores 
				@selecionarPessoa="selecionarVendedor"
				@adicionarNovo="cadastrandoVendedor = {novo: true}"
				:mostrarBotaoNovo="true"
				v-if="!cadastrandoVendedor"
			/>
			<!-- <Paginacao tipoConteudo="Vendedores" @adicionarNovo="cadastrandoVendedor = {novo: true}" 
				v-if="!cadastrandoVendedor">
				<TabelaPessoas 
					tipoConteudo="Vendedores" 
					@selecionarPessoa="selecionarVendedor"
				/>
			</Paginacao> -->
			<CadastroVendedor
				v-else-if="cadastrandoVendedor != null"
				:vendedor="cadastrandoVendedor"
				@cancelarEdicao="cancelarEdicaoVendedor"
			>

			</CadastroVendedor>
		</Modal>
	</div>
</template>
<script>
import { dateFormatISO } from "../utils/dateformat";
import Modal from "../components/Modal.vue";
// import Paginacao from "./Paginacao.vue";
// import TabelaPessoas from "./TabelaPessoas.vue";
import CadastroCliente from "./CadastroCliente.vue";
import CadastroVendedor from "./CadastroVendedor.vue";
import ConsultaVendedores from "./ConsultaVendedores.vue";
import ConsultaCliente from "./ConsultaCliente.vue";
import { CreditoCliente } from "../models/Cliente";

export default{
	name: "CadastroCreditoCliente",
	props: {
		credito: Object,
	},
	components: {
		Modal,
		// Paginacao,
		// TabelaPessoas,
		CadastroCliente,
		CadastroVendedor,
		ConsultaVendedores,
		ConsultaCliente,
	},
	data(){
		return {
			form: {
				cliente: "",
				dataCadastro: dateFormatISO({date: null, format: "ISOdate"}),
				valor: "",
				observacao: ""
			},
			listarClientes: false,
			clienteSelecionado: {},

			listarVendedores: false,
			vendedorSelecionado: {},
			cadastrandoCliente: null, 
			cadastrandoVendedor: null,
		};
	},
	computed: {
		store(){
			return this.$store;
		}
	},
	mounted(){
		if(!this.credito.novo){
			this.preencherDados();
		}
	},
	methods: {
		saiuData(){
			if(this.form.dataCadastro == ""){
				if(this.credito){
					this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
					this.form.dataCadastro = dateFormatISO({date: this.credito.data, format: "ISOdate"});
				}
				else{
					this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
					this.form.dataCadastro = dateFormatISO({date: null, format: "ISOdate"});
				}				
			}
		},
		//Metodos Cliente
		selecionarCliente({ id, nome, cnpj }){
			this.clienteSelecionado = {
				id,
				nome,
				cnpj
			};
			this.listarClientes = false;
		},
		//Metodos Vendedor
		selecionarVendedor({id, nome}){
			this.vendedorSelecionado = {
				id, 
				nome
			};
			this.listarVendedores = false;
		},
		//Validações
		validaData(){
			if(this.form.dataCadastro<dateFormatISO({date: null, format: "ISOdate"})){
				this.form.dataCadastro=dateFormatISO({date: null, format: "ISOdate"});
				this.$store.commit("relatarErro", {mensagem: "Data de inválida. A data deve ser maior ou igual a data atual!"});
			}
		},
		validarCampos(){
			try {
				if(!this.clienteSelecionado.id){
					throw new Error("Para cadastrar o crédito é necessário selecionar um cliente");
				}
				if(!this.vendedorSelecionado.id){
					throw new Error("Para cadastrar o crédito é necessário selecionar um vendedor");
				}
				if(!this.form.valor){
					throw new Error("Por favor informe um valor para cadastrar");
				}
				return true;
			} catch (error) {
				this.store.commit("relatarErro", {
					mensagem: error.message
				});
				return false;
			}
		},
		//Transformações
		montarPayload(){
			return {
				id: this.credito ? this.credito.id : "",
				cdcliente: this.clienteSelecionado.id,
				cdIdentificador: this.store.state.Caixa.caixa.cdcaixa,
				cdempresa: this.store.state.Empresa.id,
				cdvendedor: this.vendedorSelecionado.id,
				uciduser: this.store.state.Usuario.id,
				dataCadastro: this.form.dataCadastro,
				valor: this.form.valor.includes("R$") ? Number(this.form.valor.split("$")[1].split(".").join("").replace(",", ".")) : Number(this.form.valor.replace(",", ".")),
				observacao: this.form.observacao ? this.form.observacao.toUpperCase() : "",
				qtdeParcela: 1,
				utilizado: this.credito.valorPago
			};
		},
		preencherDados(){
			const cliente = this.credito?.cliente;
			const vendedor = this.credito?.vendedor;
			if(cliente){
				this.clienteSelecionado = {
					id: cliente.id,
					nome: cliente.nome
				};
			}
			if(vendedor){
				this.vendedorSelecionado = {
					id: vendedor.id,
					nome: vendedor.nome
				};
			}
			this.form = {
				observacao: this.credito.observacao,
				valor: Number(this.credito.valor).toLocaleString("pt-BR", {
					style: "currency",
					currency: "BRL"
				}),
				dataCadastro: dateFormatISO({date: this.credito.data, format: "ISOdate"})
			};
		},
		limparCampos(){
			this.vendedorSelecionado = {};
			this.clienteSelecionado = {};
			this.form = {};
			this.form.dataCadastro = dateFormatISO({date: null, format: "ISOdate"});
		},
		//Cadastro Alteração
		async cadastrarCredito(){
			if(this.credito.id){
				if(!this.validarCampos()) return;
				return this.atualizarCreditoCliente();
			}
			if(!this.validarCampos()) return;
			const data = await this.store.dispatch("cadastrarCreditoCliente", this.montarPayload());
			if(data && (data.erro || data.mensagem)){
				return this.store.commit("relatarErro", {mensagem: `${data.mensagem ? data.mensagem : "Erro ao cadastrar, favor verificar"}`});
			} else {
				this.limparCampos();
				this.$emit("cancelarCreditoCliente");
				data.valor = data.valor.toFixed(2).replace(".", ",");
				const credito = new CreditoCliente(data);
				this.$store.dispatch("gerarReciboCredito", {
					credito,
				});
				return;
			}
		},
		async atualizarCreditoCliente(){
			const data = await this.store.dispatch("atualizarCreditoCliente", this.montarPayload());
			if(data && (data.erro || data.mensagem)){
				return this.store.commit("relatarErro", {mensagem: `${data.mensagem ? data.mensagem : "Erro ao cadastrar, favor verificar"}`});
			} else {
				this.limparCampos();
				this.$emit("cancelarCreditoCliente");
				return this.store.commit("relatarErro", {mensagem: "Crédito alterado com sucesso!", sucess: true});
			}
		},
		cancelarEdicaoCliente() {
			this.cadastrandoCliente = null;
			this.$store.state.situacaoCliente = {};
		},

		fecharModalCancelarCliente() {
			this.cadastrandoCliente = null;
			this.$store.state.situacaoCliente = {};
			this.listarClientes = false;
		},

		cancelarEdicaoVendedor() {
			this.cadastrandoVendedor = null;
			this.$store.state.situacaoVendedor = {};
		},

		fecharModalCancelarVendedor() {
			this.cadastrandoVendedor = null;
			this.$store.state.situacaoVendedor = {};
			this.listarVendedores = false;
		},
	}
};
</script>
<style scoped>
.containerPage{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	padding: 10px 10px;
	overflow: auto;
	
}

.containerForm{
	display: flex;
	width: 90%;
	flex-direction: column;
	height: 85vh;
	margin-top: 20px;
	align-items: center;
	gap: 15px;
}

.cadastroLinha{
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.pessoa { 
	width: 75%;
}

.small-input {
	width: 20%;
}

.small-input input {
	text-align: center;
}

.input-group label {
	height: 15px;
}

.botoes {
	gap: 30px;
	padding: 0;
	margin-bottom: 10px;
}

#observacao {
	width: 100%;
}

#observacaoEValor .input-group {
	width: 46%;
}

#selecionaCliente {
	width: 95%;
}

#selecionaVendedor {
	width: 95%;
}

.input-group i {
	width: 3px;
} 

.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}

.botoes .botao {
	width: 45%;
}
@media screen and (max-width: 1024px) {
	.pessoa label {
		margin-left: 94%;
	}

	.pessoa {
		width: 70%;
	}

	#observacao {
		width: 95%;
	}
}

@media screen and (max-width: 768px) {
	.pessoa label {
		margin-left: 93%;
	}
}

@media screen and (max-width: 440px) {
	.pessoa {
		width: 100%;
	}

	.pessoa label {
		margin-left: 91%;
	}

	.cadastroLinha .input-group {
		width: 100%;
	}

	.cadastroLinha .input-group input {
		width: 100%;
	}

	#observacao {
		width: 100%;
	}

	#valor {
		width: 100%;
	}

	#observacaoEValor {
	width: 100%;
	justify-content: left;
	gap: 15px;
	}

	#observacaoEValor .input-group {
		width: 100%;	
	}

	#selecionaCliente {
		width: 91%;
	}

	#selecionaVendedor {
		width: 91%;
	}

	.input-group i {
		width: 3px;
	} 
}

@media screen and (max-width: 375px) {
	.pessoa label {
		margin-left: 90%;
	}
}

@media screen and (max-width: 320px) {
	.pessoa label {
		margin-left: 88%;
	}

	#observacaoEValor {
	width: 100%;
	justify-content: left;
	gap: 15px;
	}

	#observacaoEValor .input-group {
		width: 100%;	
	}
}
</style>