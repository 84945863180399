<template>
	<div class="containerPage">
		<h1 class="titulo">Dados da Classificação</h1>
		<div class="camposCadastro">
			<div class="cadastroLinha">
				<div class="input-group small item1" >
					<input type="text" id="cdClassificacao" readonly v-model="Cadastro.cdClassificacao" />
					<label for="cdClassificacao">Cód</label>
				</div>
				<div class="input-group">
					<input 
						type="text" id="nomeClassficacao"  
						v-model="Cadastro.nomeClassficacao" 
						placeholder=" "
						@keydown.enter.prevent="proximoCampo('descValorVista')" 
					/>
					<label for="nomeClassficacao">
						Nome classificação
					</label>
				</div>
				<div class="input-group-switch">
					<label class="label-switch">Vender somente á vista</label>
					<label class="container">
						<input 
							type="checkbox" 
							v-model="Cadastro.somenteVista"
							class="esconder-input"
							id="esconder-input" 
						/>
						<span class="thumb-container">
							<span class="thumb"></span>
						</span>
						<span class="track"></span>
					</label>
				</div>
				<!-- <div class="input-group-switch">
					<label class="label-switch">Vender somente á vista:</label>
					<div class="thumb-container">
						
						<input class="esconder-input" type="checkbox" id="somenteVista" v-model="Cadastro.somenteVista"/>
						<label class="track" for="somenteVista"></label>
						<span class="thumb" v-if="Cadastro.somenteVista"></span>
						<span class="thumb" v-else> </span>
					</div>
				</div> -->
			</div>
			<div class="cadastroLinha">
				<div class="input-group">
					<input 
						type="text" id="descValorVista" 
						v-model="Cadastro.descValorVista" 
						placeholder=" "
						@keydown="mascaraFloat"
						@keydown.enter.prevent="proximoCampo('descValorPrazo')"
					/>
					<label for="descValorVista">% Desconto valor a vista</label>
				</div>
				<div class="input-group">
					<input 
						type="text" id="descValorPrazo" 
						v-model="Cadastro.descValorPrazo" 
						placeholder=" "
						@keydown="mascaraFloat" 
						@keydown.enter.prevent="proximoCampo('qtdProdutos')" 
					/>
					<label for="descValorPrazo">% Desconto valor a prazo</label>
				</div>
				<div class="input-group">
					<input 
						type="text" id="qtdProdutos" 
						placeholder=" " 
						v-model="Cadastro.qtdProdutos" 
						@keydown="mascaraInteger" 
						@keydown.enter.prevent="proximoCampo('nomeClassficacao')"
					/>
					<label for="qtdProdutos" class="lg-view">Quantidade de produtos na condicional</label>
					<label for="qtdProdutos" class="sm-view">Qtd. de Produtos na Condicional</label>
				</div>
				
			</div>
		</div>
		<div class="botoes">
			<button class="botao botao-cancelar" @click="cancelarClassificacao"><span>Cancelar</span></button>
			<button class="botao botao-adicionar" @click="salvarCadastroClassificacao"><span>Salvar</span></button>
		</div>
	</div>
</template>
<script>
export default{
	name:"CadastroClassificacao",
	data () {
		return {
			Cadastro: {
				cdClassificacao: "",
				nomeClassficacao: "",
				descValorVista: "",
				descValorPrazo: "",
				qtdProdutos: "",
				somenteVista: false,
			}
		};
	},
	props: {
		classificacaoEditar: Object,
	},
	methods: {
		// eventosTeclado(e) {
		// 	if (e.key.toUpperCase() == "S" && e.altKey) {
		// 		e.preventDefault();
		// 		this.salvarCadastroClassificacao();
		// 	}
		// 	if (e.key.toUpperCase() == "C" && e.altKey) {
		// 		e.preventDefault();
		// 		this.cancelarClassificacao();
		// 	}
		// },
		proximoCampo(id) {
			return document.getElementById(id).focus();
		},
		salvarCadastroClassificacao () {
			try {
				if(this.Cadastro.nomeClassficacao.length == "0"){
					throw new Error("Campo nome classificação deve ser preenchido.");
				}
				if(this.classificacaoEditar.novo){
					this.$store.dispatch("adicionarClassificacao", {...this.Cadastro, qtdProdutos: !this.Cadastro.qtdProdutos || this.Cadastro.qtdProdutos == "" ? -1 : this.Cadastro.qtdProdutos});
				}
				if(!this.classificacaoEditar.novo){
					this.$store.dispatch("atualizarClassificacao", {
						...this.Cadastro,
						qtdProdutos: !this.Cadastro.qtdProdutos || this.Cadastro.qtdProdutos === "0" || this.Cadastro.qtdProdutos === "" ? -1 : this.Cadastro.qtdProdutos
					});
				}
				this.cancelarClassificacao();
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},
		cancelarClassificacao () {
			this.$emit("cancelarCadastroClassificacao");
		},
		mascaraFloat(e) {
			const regex = /[0-9,]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
			if ((`${e.target.value}`.includes(",") || !`${e.target.value}`.length) && e.key == ","){
				e.preventDefault();
			}
		},
		mascaraInteger(e) {
			const regex = /[0-9]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
		},
		preencherCampos(){
			const classi = {...this.classificacaoEditar};
			this.Cadastro.cdClassificacao = classi.cdclassi;
			this.Cadastro.nomeClassficacao = classi.descricao;
			this.Cadastro.somenteVista = classi.bloqueiavendaprazo;
			this.Cadastro.descValorVista = `${classi.descvista}`.replace(".", ",");
			this.Cadastro.descValorPrazo = `${classi.descprazo}`.replace(".", ",");
			this.Cadastro.qtdProdutos = classi.limitecondicional == 0 || classi.limitecondicional == -1 ? "" : classi.limitecondicional;
			this.Cadastro.padrao = classi.padrao;
		}
	},
	mounted(){
		document.addEventListener("keydown", this.eventosTeclado);
		if(!this.classificacaoEditar.novo){
			this.preencherCampos();
		} else {
			this.Cadastro.descValorVista = 0;
			this.Cadastro.descValorPrazo = 0;
		}
	}
};
</script>
<style scoped>
.containerPage{
	width: 100%;
	height: 100%;
	padding: 0px 10px;
	overflow: auto;
}
.camposCadastro{
	width: 100%;
	height: 75vh;
	display: flex;
	flex-direction: column;	
	margin-top: 10px;
}
.cadastroLinha{
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;	
}

.cadastroLinha .input-group{
	width: 350px;
	margin-top: 15px;
}
.campoItem .inputPequeno{
	width: 100%;
	max-width: 100px;
	text-align: right;
}
.campoItem .inputNormal{
	width: 500px;
	text-transform: uppercase;

}
.containerButtons{
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 30px;
	margin-top: 0px;
}
.containerButtons button{
	width: 50%;
}
.checked {
    display: flex;
    align-content: center;
    justify-content: center;
}
.checked .selecionar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.check {
	display: block;
	position: relative;
	width: 60px;
	height: 30px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}
.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}

input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}

.sm-view {
	display: none;
}

.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}

.botoes .botao {
	width: 45%;
}
@media screen and (max-width: 1200px){
	
	.campoItem .inputNormal{
		width: 300px;
	}
	.cadastroLinha{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.cadastroLinha .campoItem{
		margin-top: 10px;
	}

	.containerPage {
		width: 100%;
	}

	.camposCadastro {
		height: 74vh;
	}
}
@media screen and (max-width: 770px){
	.camposCadastro{
		height: 68vh;
	}

	.containerPage {
		height: 100%;
	}
}

.input-group-switch{
	margin-top: 15px;
	margin-bottom: 15px;
}

@media screen and (max-width: 425px){

	.input-group-switch{
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.campoItem .inputNormal{
		width: 200px;
	}
	.campoItem{
		display: flex;
		flex-direction: column;
	}

}

@media screen and (max-width: 374px) {
	.sm-view {
		display: inline-block;
	}

	.lg-view {
		display: none;
	}
}
</style>