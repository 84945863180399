import { render, staticRenderFns } from "./TrocarSenha.vue?vue&type=template&id=6bcd46c3&scoped=true"
import script from "./TrocarSenha.vue?vue&type=script&lang=js"
export * from "./TrocarSenha.vue?vue&type=script&lang=js"
import style0 from "./TrocarSenha.vue?vue&type=style&index=0&id=6bcd46c3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bcd46c3",
  null
  
)

export default component.exports