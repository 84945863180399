<template>
	<div class="containerPage">
		<i class="fas fa-print" id="btn-relatorio" @click="solicitarRelatorio"
			v-if="Relatorios.entradaProduto.length"></i>
		<div class="containerFiltro">
			
			<div class="filtroLinha">
				<div class="input-group" id="filtrarFornecedor" style="margin-right: 0;" @click="buscaFornecedores = true">
					<input type="text" placeholder="Filtrar por fornecedor" :value="fornecedor.nome" id="pesquisaLivre" readonly />
				</div>
				<i class="fas fa-address-book" @click="buscaFornecedores = true" style="color: var(--primario); font-size: 20px; margin-top: 10px;" v-if="!fornecedor.id"></i>
				<i class="fas fa-times" @click="limparFornecedor" style="color: var(--deletar); font-size: 20px; margin-top: 10px;" v-else></i>
				<div class="datas">
					<div class="row">
						<div class="input-group">
							<input 
							type="date" 
							id="dataI" 
							v-model="dataI"
							@blur="saiuData"
							/>
							<label for="dataI">Data Ínicio</label>
						</div>
						<div class="input-group">
							<input 
							type="date" 
							id="dataF" 
							v-model="dataF"
							@blur="saiuData"
							/>
							<label for="dataF">Data Final</label>
						</div>
					</div>
					<button id="botao-filtrar" class="botao botao-acao" style="width:100px;margin-right:10px" @click="buscarEntradaProdutos">
						<i
							class="fas fa-search"
							for="cdproduto"
							style="color: var(--acao-borda)"
						></i>
						<span id="textoFiltrar">Filtrar</span>
					</button>
				</div>
			</div>
			<div class="filtroLinha2">
				<div class="input-group selecionarGrupos" @click="buscaGrupo = true">
					<input type="text" class="w-50" autocomplete="off" :value="grupoInput ? `Grupo: ${grupoInput}${inputSubgrupo ? ` Subgrupo: ${inputSubgrupo}` : ''}` : 'Selecionar Grupo'" readonly/>
				</div>
				<button class="botao botao-acao" id="consultarGrupo" @click="buscaGrupo = true" v-if="!grupoInput">
					<i class="fas fa-search" style="color: var(--primario)"></i>
					<label for="consultarGrupo" class="lg-view">Consultar Grupo</label>
					<label for="consultarGrupo" class="sm-view">Grupo</label>
				</button>
				<button class="botao botao-cancelar" id="removerGrupo" @click="removerGrupo" v-else>			
					<i class="fas fa-times" style="color: var(--cancelar-borda)"></i>
					<label for="removerGrupo" class="lg-view">Remover Grupo</label>
					<label for="removerGrupo" class="sm-view">Grupo</label>
				</button>
			</div>
		</div>
		<div class="tabelaProdutos">
			<div class="scrollable">
				<table v-if="Relatorios.entradaProduto.length" >
					<thead>
						<tr>
							<th>Data</th>
							<th>Cód.</th>
							<th>Referência</th>
							<th>Descrição</th>
							<th>Qtd.</th>
							<th>$ Unitário</th>
							<th>$ Produtos</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(produto, index) in Relatorios.entradaProduto" :key="index">
							<td>
								{{ (produto.dataemissao).split("-").reverse().join("/") }}
							</td>
							<td>
								{{ produto.cdproduto }}
							</td>
							<td>
								{{ produto.cdreferencia }}
							</td>
							<td>
								{{ produto.descricao }}
							</td>
							<td>
								{{ (produto.quantidade || 0) }}
							</td>
							<td>
								{{ (produto.valorunitario || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"}) }}
							</td>
							<td>
								{{ (produto.valorprodutos || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"}) }}
							</td>
					
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="totalizadores">
			<div class="input-group">
				<input type="text" :value="`${totalQuantidade}`" />
				<label>Total Qtd.</label>
			</div>
			<div class="input-group">
				<input type="text" :value="`${total}`" />
				<label>Total $Produtos</label>
			</div>
		</div>
		<Modal v-if="buscaFornecedores" @fecharModal="buscaFornecedores = false">
			<consulta-cliente  
				@selecionarPessoa="selecionarFornecedor" 
				:height="'65vh'"
			/>
			<!-- <Paginacao tipoConteudo="Clientes" :desabilitarNovo="true">
				<TabelaPessoas tipoConteudo="Clientes"  v-on:selecionarPessoa="selecionarFornecedor" />
			</Paginacao> -->
		</Modal>
		<Modal v-if="buscaGrupo" @fecharModal="buscaGrupo = false">
			<TabelaGrupoProduto tipoConteudo="Grupos" @selecionarGrupos="selecionarGrupo" :desabilitarAcao="true"></TabelaGrupoProduto>
		</Modal>
	</div>
</template>
<script>
import Modal from "../components/Modal.vue";
// import Paginacao from "../components/Paginacao.vue";
// import TabelaPessoas from "../components/TabelaPessoas.vue";
import ConsultaCliente from "./ConsultaCliente.vue";


import { mapState } from "vuex";
import TabelaGrupoProduto from "./TabelaGrupoProduto.vue";
import { dateFormatISO } from "../utils/dateformat";
import { gerarRelatorio } from "../utils/Relatorio/impressaorelatorio";
export default{
	name: "RelatorioEntradaProduto",
	data(){
		return { 
			dataI: "",
			dataF: "",
			timer: "",
			buscaFornecedores: false,
			buscaGrupo: false,
			grupoInfo: {},
			grupoInput: "",
			inputSubgrupo: "",
			fornecedor: {
				id: "",
				nome: ""
			}
		};
	},
	components: {
		Modal,
		// Paginacao,
		// TabelaPessoas,
		TabelaGrupoProduto,
		ConsultaCliente,
	},
	computed: {
		...mapState(["Relatorios"]),
		total () {
			let valor = 0;
			this.Relatorios.entradaProduto.map(produto => {
				valor += Number(produto.valorprodutos || 0);
			});
			return Number(valor || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"});
		},
		totalQuantidade () {
			let quantidade = 0;
			this.Relatorios.entradaProduto.map(produto => {
				quantidade += Number(produto.quantidade || 0);
			});
			return quantidade;
		}
	},
	methods: {
		saiuData(){
			if(this.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataF = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		limparFornecedor(){
			this.fornecedor = {};
			this.buscarEntradaProdutos();
		},
		selecionarFornecedor(fornecedor){
			this.fornecedor = {
				id: fornecedor.id,
				nome: fornecedor.nome
			};
			this.buscaFornecedores = false;
			this.buscarEntradaProdutos();
		},
		solicitarRelatorio(){
			this.$store.commit("relatarErro", {
				mensagem:"Deseja imprimir o relatório em A4?",
				tipo: "polar",
				item: {
					relatorio: true
				}
			});
		},
		confirmarPolar(item){
			if(item.relatorio){
				this.imprimirRelatorio({
					pageWidth: "A4"
				});
			}
		},
		cancelarPolar(item){
			if(item.relatorio){
				this.imprimirRelatorio({
					pageWidth: null
				});
			}
		},
		imprimirRelatorio({pageWidth}){
			gerarRelatorio({
				filtro: {
					empresa: this.$store.state.Empresa,
					itens: [...this.Relatorios.entradaProduto],
					dataI: this.dataI,
					dataF: this.dataF,
					pageWidth
				},
				acao: "gerarRelatorioEntradaProduto"
			});
		},
		selecionarGrupo({grupo, subgrupo}) {
			const grupoRes = this.$store.state.grupoProduto.find(g => g.cdGrupo == grupo);
			const subgRes = grupoRes.subGrupos.find(subg => subg.cdsubgrupo == subgrupo);
			this.grupoInput = grupoRes?.descricao || "";
			this.inputSubgrupo = subgRes?.descricao || "";
			this.buscaGrupo = false;
			this.grupoInfo = {
				grupo: grupo,
				subgrupo: subgrupo
			};
			this.buscarEntradaProdutos();
		},
		removerGrupo(){
			this.grupoInput = "";
			this.inputSubgrupo = "";
			this.buscarEntradaProdutos();
		},
		async buscarEntradaProdutos() {
			if(this.timer) {
				clearTimeout(this.timer);
			}
			this.timer = setTimeout( async () => {
				await this.$store.dispatch("buscarRelatorioEntradaProduto", {
					dataI: this.dataI,
					dataF: this.dataF,
					grupo: this.grupoInfo.grupo,
					subgrupo: this.grupoInfo.subgrupo,
					cdfornecedor: this.fornecedor ? this.fornecedor.id : null
				});
			}, 1200);
		}
	},
	beforeMount() {
		this.dataI = dateFormatISO({
			date: this.dataI = new Date(
				new Date().getFullYear(),
				new Date().getMonth(),
				1
			).toLocaleDateString("en-CA"),
			format: "ISOdate"
		});
		this.dataF = dateFormatISO({
			date: null,
			format: "ISOdate"
		});
		this.buscarEntradaProdutos();
	},
	mounted(){
		this.$bus.$on("confirmarPolar", this.confirmarPolar);
		this.$bus.$on("cancelarPolar", this.cancelarPolar);
	},
	destroyed(){
		this.$bus.$off("confirmarPolar", this.confirmarPolar);
		this.$bus.$off("cancelarPolar", this.cancelarPolar);
	},
};
</script>
<style scoped>
.containerPage{
	width: 100%;
	height: 87vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.containerFiltro{
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
}

.filtroLinha{
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 5px;
}
#filtrarFornecedor {
	width: 50%;
}

.fa-address-book {
	margin-left: 10px;
}

.filtroLinha .fa-times {
	margin-left: 10px;
}
.filtroLinha .datas{
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.filtroLinha .datas label{
	margin-right: 5px;
}
.filtroLinha2{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: 40px;
	margin-top: 5px;
}

.filtroLinha2 input{
	width: 100%;
}

.botao-acao, .botao-cancelar {
	height: 38px;
	width: 17%;
	padding: 0;
}


.selecionarGrupos{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	gap: 5px;
	margin-right: 10px;
	width: 50%;
}
.scrollable{
	overflow: auto;
}
.tabelaProdutos {
	border-radius: 10px;
	box-shadow: var(--shadow-secundario);
	overflow: hidden;
	height: 100%;
	grid-area: sub-item;
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin: 5px 5px 0 5px;
}
.tabelaProdutos table thead tr th,
.tabelaProdutos table tbody tr td{
	text-align: center;
}

.tabelaProdutos table{
	width: 100%;
}

.totalizadores {
	margin-top: 12px;
	width: 15%;
	width: 100%;
	display: flex;
	justify-content: right;
}

.totalizadores input{
	text-align: right;
	font-size: 18px;
}

.sm-view {
	display: none;
}

@media screen and (max-width: 1024px) {
	.sm-view {
		display: inline-block;
	}

	.lg-view {
		display: none;
	}
}

@media screen and (max-width: 440px) {
	.datas{
		flex-direction: column!important;
	}
	#botao-filtrar{
		align-self: center;
		margin-top: 5px;
		margin-left: 10px;
	}
	.containerPage{
		width: 100vw;
	}
	.filtroLinha .datas{
		gap: 0px;
	}
	.tabelaProdutos {
		overflow: auto;
		max-width: 100vw;
	}

	.sm-view, .lg-view {
		display: none;
	}

	.filtroLinha {
		flex-wrap: wrap;
		gap: 10px;
	}

	#filtrarFornecedor {
		width: 88%;
	}

	.fa-address-book {
		margin-left: 0;
	}

	.filtroLinha .fa-times {
		margin-left: 0;
	}

	.selecionarGrupos {
		width: 88%;
	}

	.botao-acao, .botao-cancelar {
		width: 10%;
		margin-right: 5px;
	}

	.datas {
		width: 100%;
		margin-bottom: 5px;
	}

	.datas .input-group {
		width: 50%;
	}

	.totalizadores {
		margin-bottom: 5px;
	}

	.totalizadores .input-group {
		width: 40%;
	}
}
@media screen and (max-width: 320px) {
	.containerPage {
		height: 94vh;
	}
}
</style>