<template>
	<div class="container-page">
		<Filtro
			:mostrarBotaoNovo="true"
			@adicionarNovo="$emit('abrirCadastro')"
			@handleShowTable="handleShowTable()"
			@filtrar="filtrarCreditoClientes"
			@limpar="limparFiltro"
		>
			<div class="filtro-content">
				<div class="column-filtro">
					<div class="row-filtro">
						<div class="input-group" id="inicio">
							<input type="date" v-model="filtro.dataI" id="dataI" @blur="saiuData"/>
							<label for="dataI" style="background-color:#f8f8f8">Início</label>
						</div>
						<div class="input-group" id="fim">
							<input type="date" v-model="filtro.dataF" id="dataI" @blur="saiuData"/>
							<label for="dataI" style="background-color:#f8f8f8">Fim</label>
						</div>
						<div class="input-group">
							<select v-model="filtro.status" id="status">
								<option value="">
									Todos
								</option>
								<option value="A">
									Aberto
								</option>
								<option value="C">
									Cancelado
								</option>
								<option value="L">
									Liberado
								</option>
								<option value="R">
									Recebeu
								</option>
							</select>
							<label for="status" style="background-color:#f8f8f8">Status</label>
						</div>
					</div>
					<div class="row-filtro">
						<div class="input-group" id="pessoa">
							<select v-model="filtro.filtroSelecionado">
								<option value="cliente">
									Cliente
								</option>
								<option value="vendedor">
									Vendedor
								</option>
							</select>
						</div>
						<div v-if="filtro.filtroSelecionado == 'cliente'">
							<input 
								type="text" 
								readonly
								id="cliente"
								@click="listarClientes = true"
								:value="clienteSelecionado.id
								? `Cliente: ${clienteSelecionado.id} - ${clienteSelecionado.nome}`
								: 'Filtrar Por Cliente'"
								class="input-group"
							/>
						</div>
						<div v-else>
							<input 
								type="text" 
								readonly
								id="vendedor"
								@click="listarVendedores = true"
								:value="vendedorSelecionado.id
								? `Cliente: ${vendedorSelecionado.id} - ${vendedorSelecionado.nome}`
								: 'Filtrar Por Vendedor'"
								class="input-group"
							/>
						</div>
					</div>
				</div>
			</div>			
		</Filtro>
		<TabelaCreditoCliente
			:showTable="showTable"
			@editarCreditoCliente="$emit('editarCreditoCliente', $event)"
		/>
		<Modal 
			v-if="listarClientes" 
			:dimensoes="{width: '95%', height: '95%'}"
			@fecharModal="listarClientes = false"
		>
			<consulta-cliente  
				@selecionarPessoa="selecionarPessoa" 
				:mostrarBotaoNovo="false"
				:height="'55vh'"
			/>
			<!-- <Paginacao tipoConteudo="Clientes">
				<TabelaPessoas tipoConteudo="Clientes" @selecionarPessoa="selecionarPessoa"/>
			</Paginacao> -->
		</Modal>
		<Modal 
			v-if="listarVendedores" 
			:dimensoes="{width: '95%', height: '95%'}"
			@fecharModal="listarVendedores = false"
		>
			<consulta-vendedores 
				@selecionarPessoa="selecionarPessoa"
				:mostrarBotaoNovo="false"
				:height="'55vh'"
			/>
			<!-- <Paginacao tipoConteudo="Vendedores">
				<TabelaPessoas tipoConteudo="Vendedores" @selecionarPessoa="selecionarPessoa"/>
			</Paginacao> -->
		</Modal>
	</div>
</template>
<script>
import Modal from "../components/Modal.vue";
// import Paginacao from "../components/Paginacao.vue";
// import TabelaPessoas from "../components/TabelaPessoas.vue";
import TabelaCreditoCliente from "../components/TabelaCreditoCliente.vue";
import Filtro from "../components/Filtro.vue";
import { dateFormatISO } from "../utils/dateformat";
import ConsultaCliente from "../components/ConsultaCliente.vue";
import ConsultaVendedores from "../components/ConsultaVendedores.vue";
export default{
	name: "ConsultaCreditoCliente",
	components: {
		Modal,
		// Paginacao,
		// TabelaPessoas,
		TabelaCreditoCliente,
		ConsultaCliente,
		ConsultaVendedores,
		Filtro,
	},	
	data(){
		return {
			filtro: {
				filtroSelecionado: "cliente",
				status: "",
				dataI: dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1,
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				}),
				dataF: dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth() + 1,
						0
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				}),
			},

			listarClientes: false,
			clienteSelecionado: {},

			listarVendedores: false,
			vendedorSelecionado: {},

			showTable:true,
		};
	},
	beforeMount(){
		this.$store.dispatch("filtrarCreditoCliente");
	},
	methods: {
		saiuData(){
			if(this.filtro.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.filtro.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataF = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth() + 1,
						0
					).toLocaleDateString("en-CA"), 
					format: "ISOdate"
				});
			}
		},
		//Diverso
		selecionarPessoa(pessoa){
			if(this.filtro.filtroSelecionado === "cliente"){
				this.clienteSelecionado = {
					id: pessoa.id,
					nome: pessoa.nome
				};
				this.listarClientes = false;
				// this.filtrarCreditoClientes();
			} else if(this.filtro.filtroSelecionado === "vendedor"){
				this.vendedorSelecionado = {
					id: pessoa.id,
					nome: pessoa.nome
				};
				console.log(this.vendedorSelecionado);
				this.listarVendedores = false;
				// this.filtrarCreditoClientes();
			}
		},
		//Filtro
		filtrarCreditoClientes(){
						
			let montaFiltro = {};
			montaFiltro = {
				datai: this.filtro.dataI,
				dataf: this.filtro.dataF,
				status: this.filtro.status,
				cdcliente: this.filtro.filtroSelecionado === "cliente" ? this.clienteSelecionado.id : null,
				cdvendedor: this.filtro.filtroSelecionado === "vendedor" ? this.vendedorSelecionado.id : null
			};
			setTimeout( async () => {
				await this.$store.dispatch("filtrarCreditoCliente", {...montaFiltro});
			}, 100);
		},		
		limparFiltro(){
			this.filtro = {
				filtroSelecionado: "cliente",
				status: "",
				dataI: dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1,
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				}),
				dataF: dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth() + 1,
						0
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				}),
			};
			this.clienteSelecionado = {};
			this.vendedorSelecionado = {};
			this.filtrarCreditoClientes();
		},
		//Ação
		handleShowTable() {
			this.showTable = !this.showTable;
		},
	}
};
</script>
<style scoped>
.container-page {
	width: 100%;
	overflow: hidden;
}
.filtro-content{
	flex-direction: column;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;
	margin:5px;

}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
#cliente,
#vendedor{
	width: 50vw;
	font-size: 15px;
}

.container{
	display: flex;
	width: 90%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

#pessoa {
	width: 10vw; 
}

@media screen and (max-width: 1040px) {
	.containerTabela {
		height: 50vh;
		width: 88vw;
	}
}

@media screen and (max-width: 768px) {
	.containerTabela {
		height: 55vh;
		width: 96vw;
	}

	#pessoa {
		width: 18vw;
	}

	::-webkit-calendar-picker-indicator {
		display: none;
	}
}

@media screen and (max-width: 440px) {
	.containerPage {
		overflow: auto;
	}
	.containerTabela {
		width: 98vw;
		overflow: auto;
		margin-top: 2vh;
	}

	.input-group {
		width: 100%;
		margin: 0;
	}

	#inicio {
		margin: 0;
	}

	#fim {
		margin: 0;
	}

	#inicio, #fim {
		width: 48%;
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		gap: 10px;
	}
	
	.filtroItem {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}

	.filtroLinha {
		gap: 3vw;
		width: 100%;
		display: space-between;
	}

	#pessoa {
		width: 29vw;
		margin-right: 0;
	}

	#cliente, #vendedor {
		width: 57vw;
		margin-left: 0;
	}
	
	.botaoDiv {
		width: 100%;
		margin: 0;
	}

	.botao-acao {
		width: 100%;
	}
}

@media screen and (max-width: 425px) {
	#cliente, #vendedor {
		width: 56vw;
	}
}

@media screen and (max-width: 375px) {
	#cliente, #vendedor {
		width: 55vw;
	}
}

@media screen and (max-width: 320px) {
	#cliente, #vendedor {
		width: 52vw;
	}
}
</style>