export class MovimentacaoConciliadoraAPI{
	cdagencia;
	cdbanco;
	cdbandeira;
	cdconta;
	cddig;
	cdmovi;
	codprodutoconciliadora;
	doccartao;
	cddoc;
	nsucartao;
	tipo;
	constructor(movi){
		this.codprodutoconciliadora = movi.cdprodConciliadora;
		this.cdagencia = movi.cdagencia;
		this.cdbanco = movi.cdbanco;
		this.cdbandeira = movi.cdbandeira;
		this.cdconta = movi.cdconta;
		this.cddig = movi.cddig;
		this.cdmovi = movi.cdmovi;
		this.doccartao = movi.doccartao;
		this.nsucartao = movi.nsucartao;
		this.tipo = movi.tipo;
	}
}


export class Movimentacao{

	constructor(movi){
		this.cdbandeira = movi?.cdbandeira;
		this.codprodutoconciliadora = movi?.codprodutoconciliadora;
		this.detalhes = movi?.detalhes || [];
		this.doccartao = movi?.doccartao;
		this.nsucartao = movi?.nsucartao;
		this.parcela = movi?.parcela;
		this.valorParcelaAjustada = movi?.valorParcelaAjustada;
		this.valorParcelaSistema = movi?.valorParcelaSistema;
		this.valorTitulo = movi?.valorTitulo;
		this.enviadoConciliadora = movi?.enviadoConciliadora;
	}

	convertNumberToDecimal(number){
		return Number(number || 0	).toLocaleString("pt-BR", {
			style: "decimal",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	}

	convertDetails(details) {
		return details.map(detail => {
			return new DetalhesMovimentacao(detail);
		});
	}
}


class DetalhesMovimentacao{
	constructor(detail){
		this.cdbandeira = detail.cdbandeira;
		this.codprodutoconciliadora = detail.codprodutoconciliadora;
		this.doccartao = detail.doccartao;
		this.enviadoConciliadora = detail.enviadoConciliadora;
		this.id = detail.id;
		this.nsucartao = detail.nsucartao;
		this.valorparcelaajustado = detail.valorparcelaajustado;
	}
}

export class MovimentacaoConciliadora{
	cdmovi;
	cddig;
	movimentacao;
	nome;
	contarc;
	valor;
	datadoc;
	enviadoconciliadora;
	adm;
	cdbandeira;
	pagamento;
	nsucartao;
	doccartao;
	nomeprodutoconciliadora;
	codprodutoconciliadora;
	cdagencia;
	cdbanco;
	cdconta;
	cddig;
	cdempresa;
	cdprodConciliadora;
	tipo
	constructor(movi){
		this.cdmovi = movi.cdmovi;
		this.cddig = movi.cddig;
		this.movimentacao = movi.movimentacao;
		this.nome = movi.nome;
		this.contarc = movi.contarc;
		this.valor = movi.valor;
		this.datadoc = movi.datadoc;
		this.enviadoconciliadora = movi.enviadoconciliadora;
		this.adm = movi.adm;
		this.cdbandeira = movi.cdbandeira;
		this.pagamento = movi.pagamento;
		this.nsucartao = movi.nsucartao;
		this.doccartao = movi.doccartao;
		this.nomeprodutoconciliadora = movi.nomeprodutoconciliadora;
		this.codprodutoconciliadora = movi.codprodutoconciliadora;
		this.cdprodConciliadora = movi.codprodutoconciliadora;
		this.cdagencia = movi.cdagencia;
		this.cdbanco = movi.cdbanco;
		this.cdconta = movi.cdconta;
		this.cddig = movi.cddig;
		this.cdempresa = movi.cdempresa;
		this.tipo = movi.tipo;
	}
}
