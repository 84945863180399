<template>
    <div id="gridPrincipalCompras">
        <div :class="`${!showTable ? 'table-full' : ''}`" id="listaModal">
            <div class="conteudo-scroll">
                <table class="w-100" v-if="comprasfiltradas.length">
                    <thead>
                        <tr>
                            <td style="text-align: center; max-width: 90px;">Nº Compra</td>
                            <td style="text-align: center; color: var(--primario)">Data</td>
                            <td style="text-align: left; min-width: unset;">Fornecedor</td>
                            <td style="text-align: left; color: var(--primario)">Transporte</td>
                            <td style="text-align: center; color: var(--primario)">$ Compra</td>
                            <td class="center">Ação</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(compras, index) in comprasfiltradas" :key="index">
                            <td class="text-center text-nowrap">{{compras.cdcompra}}</td>
                            <td class="text-center text-nowrap">{{new Date(new Date(compras.datacompra).getFullYear(),new Date(compras.datacompra).getMonth(),new Date(compras.datacompra).getDate()+1).toLocaleDateString("pt-BR")}}</td>
                            <td class="text-left text-nowrap" style="min-width: unset;">{{`${compras.fornecedor ? `${compras.fornecedor.id} - ${compras.fornecedor.nome}` : ""}`}}</td>
                            <td class="text-left text-nowrap">{{compras.transporte ? `${compras.transporte.id} - ${compras.transporte.nome}` : ""}}</td>
                            <td class="text-right text-nowrap">{{(compras.valorbrutopedido || 0).toLocaleString("pt-BR",{style:"currency",currency:"BRL"})}}</td>
                            <td class="text-center text-nowrap" style="text-align: center; white-space: nowrap;">
                                <i class="fas fa-edit" style="color: var(--confirmar)" @click="$emit('editarCompra',compras)"></i>
                                <i class="fas fa-trash-alt" style="color: var(--deletar)" @click="$emit('solicitaDeletarCompra',compras)"></i>
								<i class="fas fa-print" style="color: var(--primario)" @click="$emit('solicitaRelatorioCompra',compras)" v-if="comprasfiltradas.length >= 1"></i>
							</td>
                            <!-- @click="editarItem(produtokit)" @keydown.space="editarItem(produtokit)" :tabindex="8+produtokit.id"></i> @click="removerItem(produtokit, index)"-->
                        </tr>
                    </tbody>
                </table>
                <AlertaConsultaVazia mensagem="Nenhuma compra encontrada!" v-else />
            </div>
        </div>        
    </div>
</template>


<script>
import AlertaConsultaVazia from "./AlertaConsultaVazia.vue";


export default {
	name: "TabelaCompras",
	props: {
		showTable: Boolean,
	},
	computed:{
		comprasfiltradas(){
			return this.$store.state.filtrocompras;
		},
		
	},
	components: {
		AlertaConsultaVazia,
	},
	methods:{
		selecionarProduto(produto){
			this.listarProdutos = false;
			this.campofiltro = produto.id+" - "+produto.descricao;
			// document.getElementById("js-quant").focus();
			// document.getElementById("js-quant").select();
		},

		selecionarPesquisa(){
			console.log("selecionarPesquisa");
			if (this.campoPesquisa != "cdcompra") {
				this.efetuarpesquisa();
			} else {
				this.fornecedor = null;
				this.filtrarCompras();
			}
		},

		efetuarpesquisa() {
			if (this.campoPesquisa == "cdproduto"){
				this.listarProdutos = true;
			} else if ((this.campoPesquisa == "cdfornecedor") ||  (this.campoPesquisa == "cdtransporte")){
				this.fornecedor = null;
				this.campofiltro = "";
				this.listarClientes = true;
			}
		},

		selecionarCliente(fornecedor){
			this.listarClientes = false;
			this.fornecedor =  fornecedor;
			this.campofiltro = `${fornecedor.id} ${fornecedor.nome}`;
			this.filtrarCompras();
		},

		novaCompra(){
			this.$emit("selecionarCompra", {novo: true});
		},

		editarCompra(compra){
			this.$emit("selecionarCompra", compra);
		},

		solicitaDeletarCompra(compra){
			this.$store.commit("relatarErro", {
				mensagem: `Tem certeza que deseja cancelar a compra ${compra.cdcompra}?`,
				item: {
					acao: "deletarCompra",
					compra
				},
				tipo: "polar"
			});
		},

		async deletarCompra(compra) {
			const deletado = await this.$store.dispatch("deletarCompra", {
				cdcompra: compra.cdcompra,
				cdempresa: compra.cdempresa,
				cdfornecedor: compra.fornecedor.id,
				tipo: compra.tipo
			});
			if (deletado) {
				this.filtrarCompras();
			}
		},

		capturarPolarConfirmado(item) {
			if (item.compra) {
				if (item.acao && this[item.acao]) {
					this[item.acao](item.compra);
				}
			}
			if(item.relatorioUnico){
				this.impressaoCompraUnica({
					pageWidth: "A4",
					compra: {...item.compraUnica}
				});
			}
			if (item.relatorio){
				this.impressaoComprasFiltradas({
					pageWidth: "A4"
				});
			}
		},
		capturaPolarCancelado(item){
			if(item.relatorio){
				this.impressaoComprasFiltradas({
					pageWidth: null,
					
				});
			}
			if(item.relatorioUnico){
				this.impressaoCompraUnica({
					pageWidth: null,
					compra: item.compraUnica
				});
			}
		}
	},
	destroyed() {
		this.$store.commit("atualizarfiltroCompras", []);
	}
};


</script>
<style scoped>

.conteudo-scroll {
    overflow: auto;
    width: 100%;
    display: flex;
}
#listaModal {
    width: 100%;
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;
	height: 65vh !important;
}
#listaModal.table-full{
	height: 85vh !important;
}
@media screen and (max-width: 768px){
	#listaModal.table-full{
		height: 68vh !important;
	}

	#listaModal{
		height: 43vh !important;
	}
}
@media screen and (max-width:425px){
	#listaModal.table-full{
		height: 71vh !important;
	}

	#listaModal{
		height: 30vh !important;
	}
}

</style>
