import router from "../router/index.js";
import { doRequest } from "./http.js";
import { ListaUsuarios, Usuario, UsuarioAPI, Usuarios } from "../models/Usuario.js";

export default {
	state: {
		Usuario: null,
		ListaUsuarios: {
			content: [],
			size: 25,
			paginaAtual: 0,
			totalPaginas: 0
		},
		timerLogout: null
	},

	actions: {
		logOut({ state }) {
			router.replace({ name: "Autenticacao" });
			state.Usuario = null;
			state.Empresa = null;
			state.Caixa = null;
		},
		async autenticarUsuario({ commit, state, dispatch }, payload) {
			try {
				state.carregando = true;
				const { usuario, senha, retornarLogin } = payload;
				const login = usuario.split("@")[0];
				const empresaLogin = usuario.split("@")[1];
				const url = "usuario/login";
				const body = JSON.stringify({login, senha});
				const data = await doRequest(url, { method: "post", body, cache: "no-cache" }, commit);
				if (!data) {
					state.carregando = false;
					return;
				}
				if (data.mensagem == "Favor solicitar a troca de senha!") {
					state.Usuario = {
						nome: login,
						login
					};
					state.carregando = false;
					return router.push({ name: "TrocarSenha", params: { usuario: state.Usuario }, query: {necessarioTrocarSenha: true}});
				} else if (data.mensagem) {
					throw new Error(data.mensagem);
				}
				if (data.details) {
					throw new Error(JSON.stringify(data.details));
				}
				if (!data.id) {
					throw new Error("Favor verificar se o usuário está configurado!");
				} else {
					commit("usuarioAutenticado", data);
					if (retornarLogin) {
						return new Usuario(data);
					}
					await dispatch("iniciarLoginUsuario", {empresaLogin, data});
				}
			} catch (error) {
				state.carregando = false;
				return commit("relatarErro", { mensagem: error.message || (error.details || JSON.stringify(error)) });
			}
		},

		async iniciarLoginUsuario({ commit, state, dispatch }, payload) {
			try {
				const {empresaLogin, data} = payload;
				state.Informativo = null;
				if (!empresaLogin) {
					commit("usuarioAutenticado", data);
					if (data.empresa) {
						await dispatch("buscarEmpresaPorId", data.empresa);
					} else {
						router.push({ name: "SelecionaEmpresa" });
					}
					await dispatch("buscarEmpresas");
				} else if (empresaLogin) {
					const empresa = await dispatch("buscarEmpresaPorAtalho", empresaLogin.toUpperCase());
					if (empresa.error) {
						throw new Error(JSON.stringify(empresa.error));
					}
					if ((empresa.cdempresa && data.empresa) && (empresa.cdempresa != data.empresa)) {
						state.carregando = false;
						throw new Error("Você tentou acessar uma empresa diferente da cadastrada para o seu usuário. Verifique!");
					}
					if ((data.supervisor == "S" || data.usuarioPrivilegiado == "S")) {
						await dispatch("buscarEmpresas");
					}
					commit("usuarioAutenticado", data);
					commit("atualizarEmpresa", empresa);
					await dispatch("verificarCaixaExistente");
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				return commit("relatarErro", { mensagem: error.message || (error.details || JSON.stringify(error)) });
			}
		},

		async trocarSenha({ commit, state, dispatch }, payload) {
			try {
				state.carregando = true;
				const { login, novaSenha, senha } = payload;
				const url = `usuario/trocarSenha?idUsuario=${state.Usuario.id || 0}`;
				const body = JSON.stringify({login, senha, novaSenha});
				const data = await doRequest(url, { method: "put", body, cache: "no-cache" }, commit);
				state.carregando = false;
				if (!data) return;
				if (data.mensagem) {
					throw new Error(data.mensagem);
				}
				if (data.details) {
					throw new Error(JSON.stringify(data.details));
				}
				if (!data.id) {
					throw new Error(JSON.stringify(data));
				}
				if (!state.Usuario.id || (state.Usuario.id == data.id)) {
					dispatch("iniciarLoginUsuario", {data});
				}
				if (state.Usuario.cargo == "ADMINISTRADOR") {
					router.go(-1);
				}
				return data;
			} catch (error) {
				state.carregando = false;
				console.error(error);
				commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
				return null;
			}
		},

		async autorizarSolicitacao({ commit, state }, payload) {
			state.carregando = true;
			try {
				const { usuario, senha, autorizado } = payload;
				const login = usuario.split("@")[0];
				const url = "usuario/login";
				const body = JSON.stringify({login, senha});
				const data = await doRequest(url, { method: "post", body, cache: "no-cache" }, commit);
				state.carregando = false;
				if (!data) {
					state.carregando = false;
					return;
				}
				if (data.mensagem) {
					throw new Error(data.mensagem);
				}
				if (data.details) {
					throw new Error(JSON.stringify(data.details));
				}
				const user = new Usuario(data);
				if (autorizado.clienteDevedor) {
					if (user.liberaCliente) {
						state.Venda.cdUsuarioLiberacao = user.id;
						state.Venda.somenteVista = false;
						return router.replace({ name: autorizado.route, query: { ...router.currentRoute.query, autorizacao: true }, params: { ...autorizado } });
					}
				} else if (autorizado.desconto) {
					if ((user.maxPorcentagemDesconto >= autorizado.desconto) && user.liberaDesconto) {
						state.Venda.cdUsuarioLiberacao = user.id;
						state.Venda.somenteVista = false;
						return router.replace({ name: autorizado.route, query: { ...router.currentRoute.query, autorizacao: true }, params: { ...autorizado } });
					}
				} else if (autorizado.conta) {
					if ((user.supervisor || user.usuarioPrivilegiado)) {
						return router.replace({ name: autorizado.route, query: { ...router.currentRoute.query, autorizacao: true }, params: { ...autorizado } });
					}
				}
				commit("relatarErro", { mensagem: "Usuário sem permissão para autorização." });
			} catch (error) {
				console.log(error);
			}
			state.carregando = false;
		},

		async autenticarLiberacao({ commit, state }, payload) {
			state.carregando = true;
			try {
				const { usuario, senha } = payload;
				const url = "usuario/login";
				const body = JSON.stringify({login: usuario, senha});
				const data = await doRequest(url, { method: "post", body, cache: "no-cache" }, commit);
				state.carregando = false;
				if (!data) {
					state.carregando = false;
					return null;
				}
				if (data.mensagem) {
					throw new Error(data.mensagem);
				}
				if (data.details) {
					throw new Error(JSON.stringify(data.details));
				}
				return new Usuario(data);
			} catch (error) {
				console.log(error);
				state.carregando = false;
				commit("relatarErro", { mensagem: error.message });
				return null;
			}
		},

		async buscarUsuarios({ commit, state }, payload) {
			state.carregando = true;
			try {
				let url = "usuario/all?sort=nome&sortOrder=asc&size=25";
				if (payload) {
					const { page } = payload;
					url += `&page=${page || 0}`;
				}
				const data = await doRequest(url, { method: "get", cache: "no-cache" }, commit);
				state.carregando = false;
				if (data.content) {
					commit("atualizarListaUsuarios", data);
				} else {
					commit("atualizarListaUsuarios", null);
				}
			} catch (error) {
				console.log(error);
				state.carregando = false;
			}
		},

		async filtrarUsuarios({ commit, state }, payload) {
			state.carregando = true;
			try {
				let url = "usuario/filter?sort=nome&sortOrder=asc";
				const { page, size, query, filtroDinamico } = payload;
				url += `&page=${page || 0}`;
				url += `&size=${size || state.ListaUsuarios.size}`;
				if (query) {
					url += `&query=${query}`;
				}
				if (Object.keys(filtroDinamico).length > 0) {
					Object.keys(filtroDinamico).forEach(key => {
						url += `&${key}=${filtroDinamico[key]}`;
					});
				}
				const data = await doRequest(url, { method: "get", cache: "no-cache" }, commit);
				state.carregando = false;
				if (data.content) {
					commit("atualizarListaUsuarios", data);
				} else {
					commit("atualizarListaUsuarios", null);
				}
			} catch (error) {
				console.log(error);
				state.carregando = false;
			}
		},
		iniciarLogoutUsuario({ state, dispatch }) {
			const minutosLogout = state.Empresa?.minutosExpiraSenha || 0;
			if (!minutosLogout) return;
			document.addEventListener("mousemove", () => {
				if (state.Usuario) {
					dispatch("verificarLogoutUsuario");
				}
			});
			document.addEventListener("keypress", () => {
				if (state.Usuario) {
					dispatch("verificarLogoutUsuario");
				}
			});
		},
		verificarLogoutUsuario({ state, dispatch }) {
			clearTimeout(state.timerLogOut);
			const routesToExitLogout = [
				"/fecharCaixa",
				"/caixa",
				"/relatorios",
				"/empresas",
				"/financeiro"
			];
			if(!state.Usuario.usaCelular){
				const minutosLogout = (state.Empresa?.minutosExpiraSenha || 0)*60*1000;
				if (!minutosLogout) return;
				state.timerLogOut = setTimeout(() => {
					if (state.itensVenda.length) {
						return dispatch("verificarLogoutUsuario");
					}
					if (state.Venda) {
						if (state.Venda.cliente?.id != state.ClientePadrao?.id) {
							return dispatch("verificarLogoutUsuario");
						}
					}
					if (routesToExitLogout.includes(router.currentRoute.path)) {
						return dispatch("verificarLogoutUsuario");
					}

					if (router.currentRoute.query.cadastro) {
						return dispatch("verificarLogoutUsuario");
					}
					// 					

					if (state.mensagemErro?.mensagem) {
						return dispatch("verificarLogoutUsuario");
					}
					dispatch("logOut");
					clearTimeout(state.timerLogOut);
				}, minutosLogout);
			}
		},

		async cadastrarUsuario({ commit, state }, payload) {
			state.carregando = true;
			try {
				const url = `usuario?cdUsuarioCadastro=${state.Usuario.id}`;
				const body = JSON.stringify(new UsuarioAPI(payload));
				const data = await doRequest(url, { method: "post", body, cache: "no-cache" }, commit);
				state.carregando = false;
				if (!data.id) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				return new Usuarios(data);
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
				return null;
			}
		},

		async atualizarUsuario({ commit, state }, payload) {
			state.carregando = true;
			try {
				const url = `usuario?id=${payload.id}&cdUsuario=${state.Usuario.id}`;
				const body = JSON.stringify(new UsuarioAPI(payload));
				const data = await doRequest(url, { method: "put", body, cache: "no-cache" }, commit);
				state.carregando = false;
				if (!data.id) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				if (data.id == state.Usuario.id) {
					commit("usuarioAutenticado", data);
				}
				return new Usuarios(data);
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
				return null;
			}
		},

		async acessarUsuario({dispatch, commit, state}, usuario) {
			try {
				state.carregando = true;
				if (!usuario) {
					throw new Error("Usuário não informado!");
				}
				if (usuario.ativo != "A") {
					throw new Error("Usuário não está ativo, verifique!");
				}
				commit("usuarioAutenticado", usuario);
				if (usuario.empresa) {
					await dispatch("buscarEmpresaPorId", usuario.empresa);
				} else {
					await dispatch("verificarCaixaExistente");
				}
				state.carregando = false;
				// router.replace({name: "Principal"});
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
			}
		}
	},

	mutations: {
		usuarioAutenticado(state, payload) {
			state.Usuario = null;
			if (payload == null) {
				return;
			}
			state.Usuario = new Usuario(payload);
		},

		autorizarSolicitacao(state, payload) {
			state.autorizacao = payload;
		},

		atualizarListaUsuarios(state, payload) {
			state.ListaUsuarios = new ListaUsuarios(payload);
		},

		limparListaUsuarios(state) {
			state.ListaUsuarios = new ListaUsuarios(null);
		},

		alterarUsuarioConectado(state, usuario) {
			state.Usuario = {...usuario};
			if (usuario.parametros) {
				Object.keys(usuario.parametros).forEach(key => {
					state.Usuario[key] = usuario.parametros[key];
				});
			}
		}
	},
};