<template>
	<div class="containerModalServico">
		<h3 style="color: var(--primario)">
			{{produtoSelecionado.id}} - {{produtoSelecionado.descricao}}
		</h3>
		<div class="camposServicos">
			<div class="row grow w-100">
				<div class="servicoItem">
					<label>Seq:&nbsp;</label>
					<input type="text" v-model="inputCdSeq" id="inputCdSeq" @keydown="$emit('mascarar')" @keydown.enter.prevent="proximoCampo('servicoId')" style="max-width: 60px" @focusout="buscarItem(inputCdSeq)" />
				</div>
				<div class="searchComponent">
					<label>Descrição:&nbsp;</label>
					<div>
						<input type="text" v-model="inputServico" @input="buscarNCM" id="servicoId" style="width: 100%; text-transform: uppercase;" autocomplete="off" />
						<div class="ncmSelecionado" v-if="ncmSelecionado.descricao">
							<div>
								<i class="fas fa-times" @click="removerNCMSelecionado" style="color: var(--deletar)"></i>
								<span>NCM SELECIONADO:</span>
								<span>NCM: {{ ncmSelecionado.ncm }}</span>
								<span>DESCRIÇÃO: {{ ncmSelecionado.descricao }}</span>
							</div>
						</div>
						<div class="ncmResult" v-if="mostraListaNCM" id="ncmLista">
							<div class="ncmResultItem" v-for="(ncm, index) in listaNCM" :key="index" @click="selecionarNCM(ncm, index)">
								<button id="buttonSeta" class="handle-button" type="button"><i class="fas fa-hand-point-right" style="color: var(--primario)" v-if="index == ncmSelecionadoIndex"></i></button>
								<div> 
									<span>NCM: <span>{{ ncm.ncm }}</span></span>
								</div>
								<div>
									<span>
										Descrição: <span>{{ ncm.descricao }}</span>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="row grow w-100">
				<div class="servicoItem">
					<label>Quant.:&nbsp;</label>
					<input type="text" v-model="inputQtdServico" id="inputQtdServico" @keydown.enter="$emit('proximoCampo', 'inputValor')" @input="mascaraFloat" />
				</div>
				<div class="servicoItem grow">
					<label>Valor:&nbsp;</label>
					<input type="text" v-model="inputValorServico" autocomplete="off" @keydown="mascaraFloat" id="inputValor" @keydown.enter.prevent="adicionarServico" />
				</div>
			</div>
		</div>
		<div class="buttons">
			<button class="btn btn-cancelar" @click="$emit('cancelarServico')">
				Cancelar <span>[Alt + C]</span>
			</button>
			<button class="btn btn-confirmar" @click="adicionarServico">
				Salvar <span>[Alt + S]</span>
			</button>
		</div>
	</div>
</template>
<script>
export default{
	name: "AdicionarServico",
	props: {
		produto: Object
	},
	data(){
		return {
			inputCdSeq: "",
			inputQtdServico: "",
			inputServico: "",
			inputValorServico: "",
			clienteStorage: {},
			vendedorStorage: {},
			itensVendaStorage: [],
			listaNCM: [],
			mostraListaNCM: false,
			ncmSelecionado : {},
			ncmSelecionadoIndex: 0,
		};
	},
	computed: {
		produtoSelecionado(){
			return this.$store.state.produtoSelecionado;
		},
		itensVenda(){
			return this.$store.state.itensVenda;
		},
	},
	methods: {
		buscarItem (seq){
			const item = this.itensVenda.find(item => item.seq == seq);
			if(!item) return;
			if(item){
				this.preencherDadosServico({...item, id: item.produto.id});
			}
		},
		async buscarNCM(){
			setTimeout( async () => {
				this.listaNCM = await this.$store.dispatch("buscarNCM", this.inputServico);
				if(this.listaNCM?.length > 0) {
					this.mostraListaNCM = true;
				} else {
					this.mostraListaNCM = false;
				}
				if(this.inputServico == ""){
					this.mostraListaNCM = false;
				}
			}, 1000);
		},
		removerNCMSelecionado(){
			this.ncmSelecionado = {};
			setTimeout(() => {
				const input = document.getElementById("servicoId");
				input?.focus();
				
			}, 50);
			this.inputServico = "";
		},
		selecionarNCM(ncmSel, index){
			this.mostraListaNCM = false;
			this.ncmSelecionadoIndex = index;
			this.ncmSelecionado = {...ncmSel};
			this.inputServico = this.$store.state.produtoSelecionado.descricao;
			setTimeout(() => {
				const input = document.getElementById("servicoId");
				input?.select();
				
			}, 50);
		},
		selecionarNCMSeta(e){
			const div = document.getElementById("ncmLista");
			div?.scrollBy({
				top: e.key == "ArrowUp" ? -30 : 30
			});
			if (e.key == "ArrowUp") {
				e.preventDefault();
				if (this.ncmSelecionadoIndex != 0) {
					this.ncmSelecionadoIndex--;
					this.$forceUpdate();
				}
			}
			if (e.key == "ArrowDown") {
				e.preventDefault();
				if (this.ncmSelecionadoIndex < this.listaNCM.length -1) {
					this.ncmSelecionadoIndex++;
					this.$forceUpdate();
				}
			}
		},
		preencherDadosServico(produto){
			let valor = 0;
			if (!produto.descricao) {
				produto.descricao = produto.produto?.descricao;
			}
			this.$store.state.produtoSelecionado = {...produto};
			if (!produto.seq) {
				this.inputCdSeq = `${this.itensVenda.length +1}`;
				this.inputServico = produto.descricao;
				this.inputQtdServico = produto.quantidade || "1";
				valor = !this.$store.state.Venda.valorVista ? produto.pontoequi : produto.vlrvista;
			} else {
				this.inputCdSeq = `${produto.seq}`;
				this.inputServico = produto.produto?.descricao || "";
				this.inputQtdServico = produto.quantidade || "1";
				valor = (produto.valorUnitario || 0);
			}
			this.inputValorServico = Number(valor || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			setTimeout(() => {
				const inputDescricao = document.getElementById("servicoId");
				inputDescricao.focus();
				if(inputDescricao.select){
					inputDescricao.select();
				}
			}, 60);
		},
		eventosTeclado(e){
			if(e.key == "Enter"){
				if(this.servico){
					if(!this.ncmSelecionado.descricao){
						e.preventDefault();
						this.selecionarNCM(this.listaNCM[this.ncmSelecionadoIndex], this.ncmSelecionadoIndex);
					} else {
						this.proximoCampo("inputQtdServico");
					}
				}
			}
			this.selecionarNCMSeta(e);
		},
		adicionarServico() {
			const produto = this.$store.state.produtoSelecionado;
			produto.descricao = this.inputServico.toUpperCase();
			produto.valorVista = Number(`${this.inputValorServico}`.split(".").join("").replace(",", "."));
			produto.valorPrazo = Number(`${this.inputValorServico}`.split(".").join("").replace(",", "."));
			produto.quantidade = Number(`${this.inputQtdServico}`.split(".").join("").replace(",", ".")) || (produto.quantidade || 1);
			produto.valorUnitario = Number(`${this.inputValorServico}`.split(".").join("").replace(",", "."));
			produto.vlrvista = Number(`${this.inputValorServico}`.split(".").join("").replace(",", "."));
			produto.pontoequi = Number(`${this.inputValorServico}`.split(".").join("").replace(",", "."));
			produto.ncm = this.ncmSelecionado.ncm;
			produto.cdproduto = this.produto?.id;
			produto.produto = {
				valorPrazo: Number(`${this.inputValorServico}`.split(".").join("").replace(",", ".")),
				valorVista: Number(`${this.inputValorServico}`.split(".").join("").replace(",", ".")),
				servprod: produto.servprod
			};
			if(produto?.produto){
				produto.produto.valorPrazo = Number(`${this.inputValorServico}`.split(".").join("").replace(",", "."));
				produto.produto.valorVista = Number(`${this.inputValorServico}`.split(".").join("").replace(",", "."));
				
			}
			this.servico = false;
			this.ncmSelecionado = {};
			this.$store.state.produtoSelecionado = null;
			const seq = Number(this.inputCdSeq);
			if (seq > this.itensVenda.length) {
				this.$emit("incluirItemVenda", {...produto, servprod: produto.servprod});
			} else {
				produto.seq = seq;
				this.seq = seq-1;
				this.$emit("atualizarItemVenda", produto);
			}
			this.$emit("fecharModal");
			this.$emit("atualizarProduto", produto);
		},
		mascaraFloat(e) {
			const regex = /[0-9,.]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
		},
		mascaraInteger(e) {
			const regex = /[0-9]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
		},
	},
	mounted(){
		if(this.produto){
			this.preencherDadosServico(this.produto);
		}
		window.addEventListener("keydown", this.eventosTeclado);
	},
	destroyed(){
		window.removeEventListener("keydown", this.eventosTeclado);
	}
};
</script>
<style scoped>

.containerModalServico{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: center;
	align-items: center;
	white-space: nowrap;
	width: 100%;
}
.camposServicos{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	width: 100%;
	gap: 30px;
}
.servicoItem {
	display: flex;
	flex-direction: row;
	height: 25px;
	padding: 0px 10px;
}

.buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 10px;
	gap: 10px;
}
.buttons .btn{
	width: 50%;
}


.searchComponent{
	width: 80%;
	display: flex;
}
.searchComponent div{
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start !important;
	justify-content: flex-start !important;
}
.ncmResult{
	position: absolute;
	top: 65px;
	width: 68% !important;
	height: 90px;
	background-color: #FFF;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	-webkit-box-shadow: 0px 13px 34px -7px rgba(0,0,0,0.82); 
	box-shadow: 0px 13px 34px -7px rgba(0,0,0,0.82);
	overflow-y: auto;
}

.ncmResultItem{
	display: flex !important;
	flex-direction: row !important;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 5px 5px;
	border-bottom: 1px solid #cecece;
}
.ncmResultItem div{
	padding: 2px 2px;
	width: 30%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.ncmResultItem button{
	margin-right: 10px;
}

.ncmResultItem:hover{
	background-color: #cecece;
	cursor: pointer;
}

.ncmSelecionado div{
	height: 10px;
	display: flex !important;
	flex-direction: row !important;
	justify-content: flex-start !important;
}

.ncmSelecionado div i{
	margin-left: 3px;
	margin-right: 10px;
	width: 20px;
}
.ncmSelecionado span{
	margin-right: 15px;
}

#buttonSeta{
	outline: none;
	border: none;
	background-color: transparent;
}

</style>