<template>
    <div id="grid-caixa" class="conteudo-item">
        <i
            class="fas fa-print"
            id="btn-relatorio"
            @click="imprimirRelatorio"
            v-if="Relatorios.caixa.length > 0"
        ></i>
        <div class="informacoes-conteudo">
            <div class="informacoes-item">
                <div class="input-group">
					<input
					v-model="dataI"
					type="date"
					id="dataI"
					@blur="saiuData"
                    />
                    <label for="dataI">Data Inicial</label>
                </div>
                <div class="input-group">
					<input
					v-model="dataF"
					type="date"
					id="dataF"
					@blur="saiuData"
                    />
                    <label for="dataF">Data final</label>
                </div>
				<button class="botao botao-acao" style="margin-right:10px" @click="buscarRelatorioCaixa">
					<i
						class="fas fa-search"
						for="cdproduto"
						style="color: var(--acao-borda)"
					></i>
					<span id="textoFiltrar">Filtrar</span>
				</button>
            </div>
        </div>
        <div class="conteudo-filtro">
            <div id="filtro-caixas">
                <div
                    class="caixa-item"
                    v-for="(caixa, index) in $store.state.Caixas.content"
                    :key="index"
                    :id="`caixa-item-${caixa.cdcaixa}`"
                    @click="consultarCaixa({ ...caixa })"
                >
                    {{ caixa.identificador }}
                </div>
            </div>
        </div>
        <div class="conteudo-subitem">
            <div
                class="bloco-movimento"
                v-if="Relatorios.caixa.filter((e) => e.condicao == 'S').length"
            >
                <h3>Saldo Anterior</h3>
                <div
                    class="input-group movimento-item"
                    v-for="mov in Relatorios.caixa.filter(
                        (e) => e.condicao == 'S'
                    )"
                    :key="mov.movimento + mov.condicao"
                >
                    <div class="titulo-movimento">Saldo Anterior</div>
                    <div
                        class="valor-movimento"
                        :style="
                            mov.credito > 0 || mov.prazo > 0
                                ? 'color: var(--confirmar)'
                                : mov.debito > 0
                                ? 'color: var(--deletar)'
                                : ''
                        "
                    >
                        <i
                            class="fas fa-pause-circle"
                            style="
                                color: var(--confirmar);
                                transform: rotate(90deg);
                            "
                        ></i>

                        {{
                            (mov.credito || mov.prazo).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })
                        }}
                    </div>
                </div>
            </div>
            <div
                class="bloco-movimento"
                v-if="Relatorios.caixa.filter((e) => e.condicao == 'V').length"
            >
                <h3>Vendas</h3>
                <div
                    class="input-group movimento-item"
                    v-for="mov in Relatorios.caixa.filter(
                        (e) => e.condicao == 'V'
                    )"
                    :key="mov.movimento + mov.condicao"
                >
                    <div class="titulo-movimento">{{ mov.movimento }}</div>
                    <div
                        class="valor-movimento"
                        :style="
                            mov.credito > 0 || mov.prazo > 0
                                ? 'color: var(--confirmar)'
                                : mov.debito > 0
                                ? 'color: var(--deletar)'
                                : ''
                        "
                    >
                        <i
                            @click="
                                buscarMovimento(mov.condicao, mov.cdTipoPgto)
                            "
                            v-if="mov.credito > 0 || mov.prazo > 0"
                            class="fas fa-plus-circle"
                            style="color: var(--confirmar)"
                        ></i>
                        <i
                            @click="
                                buscarMovimento(mov.condicao, mov.cdTipoPgto)
                            "
                            v-else
                            class="fas fa-minus-circle"
                            style="color: var(--deletar)"
                        ></i>

                        {{
                            (mov.credito || mov.prazo).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })
                        }}
                    </div>
                </div>
                <div class="input-group movimento-item">
                    <div class="titulo-movimento">Total</div>
                    <div
                        class="valor-movimento"
                        style="color: #000000; font-weight: bold;"
                    >
                        <i
                            class="fas fa-pause-circle"
                            style="
                                color: var(--confirmar);
                                transform: rotate(90deg);
                            "
                        ></i>
                        {{ totalVendas }}
                    </div>
                </div>
            </div>
            <div
                class="bloco-movimento"
                v-if="Relatorios.caixa.filter((e) => e.condicao == 'R').length"
            >
                <h3>Recebimentos</h3>
                <div
                    class="input-group movimento-item"
                    v-for="mov in Relatorios.caixa.filter(
                        (e) => e.condicao == 'R'
                    )"
                    :key="mov.movimento + mov.condicao"
                >
                    <div class="titulo-movimento">{{ mov.movimento }}</div>
                    <div
                        class="valor-movimento"
                        :style="
                            mov.credito > 0 || mov.prazo > 0
                                ? 'color: var(--confirmar)'
                                : mov.debito > 0
                                ? 'color: var(--deletar)'
                                : ''
                        "
                    >
                        <i
                            @click="
                                buscarMovimento(mov.condicao, mov.cdTipoPgto)
                            "
                            v-if="mov.credito > 0 || mov.prazo > 0"
                            class="fas fa-plus-circle"
                            style="color: var(--confirmar)"
                        ></i>
                        <i
                            @click="
                                buscarMovimento(mov.condicao, mov.cdTipoPgto)
                            "
                            v-else
                            class="fas fa-minus-circle"
                            style="color: var(--deletar)"
                        ></i>

                        {{
                            (mov.credito || mov.prazo).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })
                        }}
                    </div>
                </div>
                <div class="input-group movimento-item">
                    <div class="titulo-movimento">Total</div>
                    <div
                        class="valor-movimento"
                        style="color: #000000; font-weight: bold;"
                    >
                        <i
                            class="fas fa-pause-circle"
                            style="
                                color: var(--confirmar);
                                transform: rotate(90deg);
                            "
                        ></i>
                        {{ totalRecebimentos }}
                    </div>
                </div>
            </div>
            <div
                class="bloco-movimento"
                v-if="Relatorios.caixa.filter((e) => e.condicao == 'M').length"
            >
                <h3>Movimentação</h3>
                <div
                    class="movimento-item"
                    v-for="mov in Relatorios.caixa.filter(
                        (e) => e.condicao == 'M' && e.credito > 0
                    )"
                    :key="mov.movimento + mov.condicao"
                >
                    <div class="titulo-movimento">Recarga em {{ mov.movimento }}:</div>
                    <div
                        class="valor-movimento"
                        style="color: var(--confirmar)"
                    >
                        <i
                            @click="
                                buscarMovimento(mov.condicao, mov.cdTipoPgto, 'credito')
                            "
                            class="fas fa-plus-circle"
                            style="color: var(--confirmar)"
                        ></i>
                        {{
                            (mov.credito || mov.prazo).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })
                        }}
                    </div>
                </div>
                <div
                    class="input-group movimento-item"
                    v-for="mov in Relatorios.caixa.filter(
                        (e) => e.condicao == 'M' && e.debito > 0
                    )"
                    :key="mov.debito"
                >
                    <div class="titulo-movimento">Sangria em {{ mov.movimento }}</div>
                    <div class="valor-movimento" style="color: var(--deletar)">
                        <i
                            @click="
                                buscarMovimento(mov.condicao, mov.cdTipoPgto, 'debito')
                            "
                            class="fas fa-minus-circle"
                            style="color: var(--deletar)"
                        ></i>
                        {{
                            mov.debito.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })
                        }}
                    </div>
                </div>
            </div>
            <div class="bloco-movimento">
                <h3>Total</h3>
                <div class="input-group movimento-item">
                    <div class="titulo-movimento">Total</div>
                    <div
                        class="valor-movimento"
                        style="color: var(--confirmar)"
                    >
                        <i
                            class="fas fa-pause-circle"
                            style="
                                color: var(--confirmar);
                                transform: rotate(90deg);
                            "
                        ></i>
                        {{ total }}
                    </div>
                </div>
            </div>
        </div>

        <Modal v-if="abrirModal" v-on:fecharModal="abrirModal = false">
            <RelatorioCaixasModal v-bind:tipoMovimento="tipoMovimento" />
        </Modal>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { dateFormatISO } from "../utils/dateformat";
import Modal from "./Modal.vue";
import RelatorioCaixasModal from "./RelatorioCaixasModal.vue";
import { gerarRelatorio } from "../utils/Relatorio/impressaorelatorio";
export default {
	name: "RelatorioCaixas",

	components: {
		Modal,
		RelatorioCaixasModal,
	},

	data() {
		return {
			dataI: "",
			dataF: "",
			abrirModal: false,
			caixaController: "",
			tipoMovimento: "",
			timerFiltro: null
		};
	},

	computed: {
		...mapState(["Relatorios"]),
		total() {
			let preco = 0;
			if (this.Relatorios.caixa[this.Relatorios.caixa.length-1]) {
				preco += this.Relatorios.caixa[this.Relatorios.caixa.length-1].saldo;
			}
			return preco.toLocaleString("pt-BR", {
				style: "currency",
				currency: "BRL",
			});
		},

		totalVendas() {
			let preco = 0;
			this.Relatorios.caixa.forEach((e) => {   
				if (e.condicao == "V") {
					if (e.credito > 0 || e.prazo > 0) {
						preco += Number(e.credito || e.prazo);
					}
					if (e.debito > 0) {
						preco -= Number(e.debito);
					}
				}       
			});
			return preco.toLocaleString("pt-BR", {
				style: "currency",
				currency: "BRL",
			});
		},

		totalRecebimentos() {
			let preco = 0;
			this.Relatorios.caixa.forEach((e) => {   
				if (e.condicao == "R") {
					if (e.credito > 0 || e.prazo > 0) {
						preco += Number(e.credito || e.prazo);
					}
					if (e.debito > 0) {
						preco -= Number(e.debito);
					}
				}       
			});
			return preco.toLocaleString("pt-BR", {
				style: "currency",
				currency: "BRL",
			});
		},
	},

	methods: {
		saiuData(){
			if(this.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataF = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		imprimirRelatorio() {
			gerarRelatorio({
				filtro: {
					relatorio: this.Relatorios.caixa,
					dataI: this.dataI,
					dataF: this.dataF
				},
				acao: "relatorioCaixa",
			});
			
		},
		consultarCaixa(caixa) {
			if (!caixa) {
				return;
			}
			const itensCaixa = document.querySelectorAll("div.caixa-item");
			if (itensCaixa.length > 0) {
				itensCaixa.forEach(el => {
					el.classList.remove(["caixa-ativo"]);
				});
			}
			this.caixaController = caixa.cdcaixa;
			if (itensCaixa.length > 0) {
				document.getElementById(`caixa-item-${caixa.cdcaixa}`).classList.add(["caixa-ativo"]);
			}
			this.buscarRelatorioCaixa(caixa.cdEmpresa);
		},

		async buscarRelatorioCaixa(emp) {
			if (this.timerFiltro) {
				clearTimeout(this.timerFiltro);
			}
			this.timerFiltro = setTimeout(() => {
				this.$store.dispatch("buscarRelatorioCaixa", {
					cdidentificador: this.caixaController,
					dataI: this.dataI,
					dataF: this.dataF,
					empresa: emp?emp:this.$store.state.Empresa.id
				});
			},500);
		},

		buscarMovimento(condicao, cdTipoPgto, tipo) {
			this.$store.dispatch("buscarMovimentoCaixa", {
				cdidentificador: this.caixaController,
				cdTipoPgto: cdTipoPgto,
				condicao: condicao,
				dataI: this.dataI,
				dataF: this.dataF,
			});
			if (tipo) {
				this.tipoMovimento = tipo;
			}
			else {
				this.tipoMovimento = "";
			}
			this.abrirModal = true;
		},
	},

	mounted() {
		setTimeout(() => {
			document.getElementById(`caixa-item-${this.caixaController}`).classList.add(["caixa-ativo"]);
		}, 50);
	},

	beforeMount() {
		this.dataI = dateFormatISO({
			date: new Date(
				new Date().getFullYear(),
				new Date().getMonth(),
				1
			).toLocaleDateString("en-CA"),
			format: "ISOdate"
		});
		this.dataF = dateFormatISO({date: null, format: "ISOdate"});
		this.caixaController = this.$store.state.Caixa.caixa.cdcaixa;
		this.$store.dispatch("buscarCaixas");
		this.buscarRelatorioCaixa();
	},
};
</script>
<style scoped>
#grid-caixa {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
        "info-conteudo"
        "filtro-caixas"
        "sub-item";
}

.informacoes-conteudo {
    display: flex;
    flex-direction: row;
    grid-area: info-conteudo;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
}

.informacoes-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
	margin-top: 10px;
}

.informacoes-item label {
    display: flex;
    justify-content: center;
    align-items: center;
}

.informacoes-item input[type="date"] {
    max-width: 140px;
}

#dataI {
    margin-right: 5px;
}

#filtro-caixas {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding: 8px 0px;
    max-width: 98vw;
    grid-area: filtro-caixas;
}

.caixa-item {
    padding: 5px;
    margin: 0px 5px;
    cursor: pointer;
	background-color: #bbbbbb63;
	border-radius: 5px;
	border: 3px solid var(--border);
}

.caixa-ativo {
    background-color: var(--acao-background);
	border: 3px solid var(--acao-borda);
    color: var(--acao-borda);
    border-radius: 5px;
    cursor: default;
	box-shadow: var(--shadow-secundario);
	font-weight: 500;
}

.conteudo-subitem {
    overflow: auto;
    height: 100%;
    grid-area: sub-item;
    position: relative;
	min-height: 66vh;
}

table {
    grid-area: tabela-caixas;
    background: #ddd;
    width: 100%;
    cursor: default;
}

td,
th {
    padding: 5px;
    cursor: default;
}

td:nth-child(1),
th:nth-child(1) {
    text-align: center;
}

td:nth-child(2),
th:nth-child(2) {
    text-align: left;
}

td:nth-child(3),
th:nth-child(3),
td:nth-child(4),
th:nth-child(4),
td:nth-child(5),
th:nth-child(5),
td:nth-child(6),
th:nth-child(6),
td:nth-child(7),
th:nth-child(7) {
    text-align: right;
}

.movimento-item {
    display: flex;
    flex-direction: row;
    margin: 2px;
    width: 100%;
    justify-content: space-between;
    padding: 0 5px;
}

.titulo-movimento {
    display: flex;
    min-width: 180px;
    justify-content: flex-start;
    align-items: center;
}

.valor-movimento {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 150px;
    max-width: 150px;
}

.bloco-movimento {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 540px;
    min-width: 340px;
    margin: 0 auto;
}

h3 {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

@media screen and (max-width: 1125px) {
    .informacoes-conteudo {
        flex-direction: column;
        align-items: flex-start;
    }

    .informacoes-item {
        max-width: 100%;
        width: auto;
        justify-content: flex-start;
    }

    .informacoes-item input[type="date"] {
        max-width: 150px;
    }
}

@media screen and (max-width: 768px) {
    #btn-relatorio {
        left: 0;
    }
}

@media screen and (max-width: 600px) {
    .informacoes-conteudo {
        align-items: flex-end;
    }
}

@media screen and (max-width: 520px) {

    .informacoes-conteudo {
        align-items: center;
    }
    .informacoes-item {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .informacoes-item div {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .informacoes-item div input[type="date"] {
        width: 120px;
    }

    .informacoes-item
        div
        input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
    }
}

@media screen and (max-width: 440px){
	#textoFiltrar{
		display:none;
	}
}

@media screen and (max-width: 320px) {
	.conteudo-subitem .bloco-movimento {
		min-width: 100%;
		max-width: 100%;
	}

	.valor-movimento {
		min-width: auto;
		max-width: auto;
	}
}
</style>