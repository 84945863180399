<template>
	<div class="container-page">		
        <div id="painel-controle">
			<i class="fas fa-print" id="btn-relatorio" @click="solicitarImpressaoCompras" v-if="comprasfiltradas.length >= 1"></i>
		</div>
		<Filtro
			:mostrarBotaoNovo="true"			
			@filtrar="filtrarCompras"
			@limpar="limparFiltro"			
			@adicionarNovo="$emit('selecionarCompra', {novo: true});"
			@handleShowTable="handleShowTable()"
		>
			<div class="filtro-content">
				<div class="column-filtro">
					<div class="row-filtro">						
						<div class="datas-controle" v-if="campoPesquisa != 'cdcompra'">
							<div class="input-group">
								<input type="date" id="dataI" v-model="dataI" @keydown.enter="filtrarCompras" @blur="saiuData">
								<label for="dataI" style="background-color: #f8f8f8;">De</label>
							</div>
							<div class="input-group">
								<input type="date" id="dataF" v-model="dataF" @keydown.enter="filtrarCompras" @blur="saiuData">
								<label for="dataF" style="background-color: #f8f8f8;">Até</label>
							</div>
						</div>
						<div id="texto-pesquisa-controle">
							<div class="input-group">
								<select id="campoPesquisa" @change="selecionaCampoPesquisa" v-model="campoPesquisa">
									<option value="periodo">
										Período
									</option>
									<option value="cdcompra">
										Código da compra
									</option>
									<option value="cdfornecedor">
										Fornecedor
									</option>
									<option value="cdtransporte">Transportadora</option>
								</select>
							</div>
							<div class="input-group" v-if="campoPesquisa != 'periodo'" style="max-width: 190px;">
								<input v-model="campofiltro" type="text" id="campoFiltro" class="mx-2" autocomplete="off" :placeholder="pesquisar"
								v-if="campoPesquisa != 'periodo'" @keydown="selecionarPesquisa" @click="selecionarPesquisa" @focus="(e) => e.target.select()">
							</div>
						</div>
						<div class="input-group" >
							<input 
								type="text" 
								id="total" 
								:value="`Total: ${totalizador}`" 
								readonly
							>
						</div>
					</div>
				</div>
			</div>			
		</Filtro> 
		
		<TabelaCompras
			@solicitaRelatorioCompra="solicitaRelatorioCompra"
			@solicitaDeletarCompra="solicitaDeletarCompra"
			@editarCompra="$emit('selecionarCompra', $event)"
			:showTable="showTable"
		/>
		
		<!-- <Modal 
			v-if="listarProdutos"
			:dimensoes="{width: '95%', height: '95%'}"
			@fecharModal="listarProdutos = false"
		>
			<consulta-produtos
				:mostrarBotaoNovo="false"
				:Produtos="listadeproduto"
				@selecionarProduto="selecionarProduto"
				:modal="true"
			>
			</consulta-produtos>
		</Modal> -->
        <Modal 
			v-if="listarClientes" 
			v-on:fecharModal="listarClientes = false"
		>
			<consulta-cliente  
				@selecionarPessoa="selecionarCliente" 
				:height="'65vh'"
			/>
            <!-- <Paginacao tipoConteudo="Clientes" :desabilitarNovo="true">
                <TabelaPessoas tipoConteudo="Clientes"
                v-on:selecionarPessoa="selecionarCliente" v-on:fecharModal="fecharModal"/>
            </Paginacao> -->
        </Modal>
        <Confirmar 
			v-if="mensagemPergunta"
			:mensagemPergunta="mensagemPergunta"
			@confirmado="perguntaConfirmada"
			@cancelado="perguntaCancelada"
			:acaoCancelar="acaoCancelar" 
			:acaoConfirmar="acaoConfirmar" 
		/>
	</div>
</template>
<script>
// import Paginacao from "./Paginacao.vue";
// import ListagemProdutos from "./ListagemProdutos.vue";
import Modal from "./Modal.vue";
// import TabelaPessoas from "./TabelaPessoas.vue";
import Confirmar from "./Confirmar.vue";
import TabelaCompras from "../components/TabelaCompras.vue";
import Filtro from "../components/Filtro.vue";
import { dateFormatISO } from "../utils/dateformat";
import ConsultaCliente from "../components/ConsultaCliente.vue";

export default{
	name: "ConsultaCompras",
	components: {
		TabelaCompras,
		Filtro,
		// Paginacao,
		// ListagemProdutos,
		Modal,
		// TabelaPessoas,
		Confirmar,
		ConsultaCliente,
	},	
	data(){
		return {
			dataI: dateFormatISO({date: null, format: "ISOdate"}),
			dataF: dateFormatISO({date: null, format: "ISOdate"}),
			campoPesquisa : "periodo",
			pesquisar : "Código da compra",
			listarClientes : false,
			cadastrocompras : false,
			campofiltro : "",
			ProdutoSel : null,
			timerFiltro : null,
			mensagemPergunta: null,
			acaoConfirmar: null,
			acaoCancelar: null,
			fornecedor: null,
			transportadora: null,
			showTable: true,
		};
	},
	computed: {
		
		comprasfiltradas(){
			return this.$store.state.filtrocompras;
		},
		totalizador(){
			let total = 0;
			this.comprasfiltradas.map(compra => {
				total += Number(compra.valorbrutopedido);
			});
			return Number(total).toLocaleString("pt-BR", {
				style: "currency", 
				currency: "BRL"
			});
		},
	},
	mounted(){		
		this.filtrarCompras();
		this.$bus.$on("confirmarPolar", this.capturarPolarConfirmado);
		this.$bus.$on("cancelarPolar", this.capturaPolarCancelado);
	},
	destroyed() {
		this.$bus.$off("confirmarPolar", this.capturarPolarConfirmado);
		this.$bus.$off("cancelarPolar", this.capturaPolarCancelado);
	},
	methods:{
		saiuData(){
			if(this.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataI = dateFormatISO({date: null, format: "ISOdate"});
			}
			if(this.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataF = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		handleShowTable(){
			this.showTable = !this.showTable;
		},
		impressaoComprasFiltradas({ pageWidth }){
			this.$store.dispatch("gerarRelatorioCompras", {
				empresa: this.$store.state.Empresa,
				compras: this.comprasfiltradas,
				dataInicio: dateFormatISO({date: this.dataI, format: "ISOdate"}),
				dataFinal: dateFormatISO({date: this.dataF, format: "ISOdate"}),
				pageWidth
			});
		},		
		fecharModal() {
			this.listarClientes = false;
			let filtro = {
				datai: dateFormatISO({date: this.dataI, format: "ISOdate"}),
				dataf: dateFormatISO({date: this.dataF, format: "ISOdate"}),
				cdempresa: this.$store.state.Empresa.id
			};
			this.$store.dispatch("buscarCompras", filtro);
		},
		filtrarCompras() {
			clearTimeout(this.timerFiltro);
			if ((this.dataF ? this.dataF : this.dataI) < this.dataI) {
				return this.$store.commit("relatarErro", {
					mensagem: "A data final do filtro não pode ser antes da data inicial, verifique!"
				});
			}
			this.timerFiltro = setTimeout(() => {
				let filtro = {
					cdempresa: this.$store.state.Empresa.id
				};
				if(this.campoPesquisa != "cdcompra"){
					if (this.campoPesquisa != "periodo" && this.campofiltro) {
						if (this.fornecedor) {
							filtro[this.campoPesquisa] = this.fornecedor.id;
							filtro["datai"] = dateFormatISO({date: this.dataI, format: "ISOdate"});
							filtro["dataf"] = dateFormatISO({date: this.dataF, format: "ISOdate"});
						} else {
							filtro[this.campoPesquisa] = this.campofiltro;
							filtro["datai"] = dateFormatISO({date: this.dataI, format: "ISOdate"});
							filtro["dataf"] = dateFormatISO({date: this.dataF, format: "ISOdate"});
						}
					} else {
						filtro["datai"] = dateFormatISO({date: this.dataI, format: "ISOdate"});
						filtro["dataf"] = dateFormatISO({date: this.dataF, format: "ISOdate"});
					}
				}
				else{
					filtro[this.campoPesquisa] = this.campofiltro;
				}
				if(this.campoPesquisa == "cdfornecedor" || this.campoPesquisa == "cdtransporte"){
					filtro[this.campoPesquisa] = this.fornecedor.id;
				}
				if(this.campoPesquisa === "cdproduto") {
					this.$store.dispatch("buscarProdutosCompra", filtro);
					return;
				}
				this.$store.dispatch("buscarCompras", filtro);
			}, 450);
		},
		limparFiltro(){
			this.dataI = dateFormatISO({date: null, format: "ISOdate"});
			this.dataF = dateFormatISO({date: null, format: "ISOdate"});
			this.campoPesquisa = "periodo";
			this.pesquisar = "Código da compra";
			this.campofiltro = "";
			this.timerFiltro = null;
			this.fornecedor = null;
			this.filtrarCompras();
		},
		selecionaCampoPesquisa() {
			this.campofiltro = "";
			this.pesquisar = document.querySelector(`option[value=${this.campoPesquisa}]`).innerText;
			
			if (this.campoPesquisa == "periodo"){
				setTimeout(() => {
					document.getElementById("dataI").focus();
					document.getElementById("dataI").select();
				},50);
			} else{
				setTimeout(() => {
					document.getElementById("campoFiltro").focus();
				},50);
			}
		},
		solicitarImpressaoCompras(){
			this.$store.commit("relatarErro", {
				mensagem: "Deseja imprimir o relatório em A4?",
				tipo: "polar",
				item: {
					relatorio: true
				}
			});
		},	
		selecionarPesquisa(){
			if (this.campoPesquisa != "cdcompra") {
				this.efetuarpesquisa();
			} else {
				this.fornecedor = null;
			}
		},	
		selecionarCliente(fornecedor){
			this.listarClientes = false;
			this.fornecedor =  fornecedor;
			this.campofiltro = `${fornecedor.id} ${fornecedor.nome}`;
		},
		capturarPolarConfirmado(item) {
			if (item.compra) {
				if (item.acao && this[item.acao]) {
					this[item.acao](item.compra);
				}
			}
			if(item.relatorioUnico){
				this.impressaoCompraUnica({
					pageWidth: "A4",
					compra: {...item.compraUnica}
				});
			}
			if (item.relatorio){
				this.impressaoComprasFiltradas({
					pageWidth: "A4"
				});
			}
		},
		solicitaRelatorioCompra(compra){
			this.$store.commit("relatarErro", {
				mensagem: "Deseja imprimir o relatório em A4?",
				tipo: "polar",
				item: {
					relatorioUnico: true,
					compraUnica: compra
				}
			});
		},
		impressaoCompraUnica({pageWidth, compra}){
			this.$store.dispatch("gerarRelatorioCompraUnica", {
				empresa: this.$store.state.Empresa,
				pageWidth,
				compra: compra
			});
		},	
		efetuarpesquisa() {
			// if (this.campoPesquisa == "cdproduto"){
			// 	this.listarProdutos = true;
			// } else  
			if ((this.campoPesquisa == "cdfornecedor") ||  (this.campoPesquisa == "cdtransporte")){
				this.fornecedor = null;
				this.campofiltro = "";
				this.listarClientes = true;
			}
		},
		solicitaDeletarCompra(compra){
			this.$store.commit("relatarErro", {
				mensagem: `Tem certeza que deseja cancelar a compra ${compra.cdcompra}?`,
				item: {
					acao: "deletarCompra",
					compra
				},
				tipo: "polar"
			});
		},
		async deletarCompra(compra) {
			const deletado = await this.$store.dispatch("deletarCompra", {
				cdcompra: compra.cdcompra,
				cdempresa: compra.cdempresa,
				cdfornecedor: compra.fornecedor.id,
				tipo: compra.tipo
			});
			if (deletado) {
				this.filtrarCompras();
			}
		},
		capturaPolarCancelado(item){
			if(item.relatorio){
				this.impressaoComprasFiltradas({
					pageWidth: null,
					
				});
			}
			if(item.relatorioUnico){
				this.impressaoCompraUnica({
					pageWidth: null,
					compra: item.compraUnica
				});
			}
		},
	}
};
</script>
<style scoped>
.container-page {
	width: 100%;
	overflow: hidden;
}
.filtro-content{
	flex-direction: column;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;
	margin:5px;

}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
.datas-controle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
#texto-pesquisa-controle{	
    display: flex;
    flex-direction: row;
}
@media screen and (max-width:400px){
	.datas-controle {
		flex-direction: column;
	}
	.datas-controle .input-group:nth-child(1){
		margin-bottom: 10px;
	}
	#texto-pesquisa-controle{	
		flex-direction: column;
	}
	#texto-pesquisa-controle .input-group:nth-child(1){
		margin-bottom: 10px;
	}
	.input-group{
		width: 80vw;
	}
}
</style>