<template>
    <div id="Parametros">
        <h2 class="w-100 center my-5">Configurar Parametros</h2>

        <div class="col w-100">
            <div class="row w-100 between border">
                <h3 class="mx-5 w-100">Descrição</h3>
                <div class="row w-20 between">
                    <h5>Sim</h5>
                    <h5>Não</h5>
                </div>
            </div>
            <div class="row w-100 between border">
                <h3 class="mx-5 w-100">Permite vender com saldo negativo</h3>
                <div class="row w-20 between">
                    <input type="radio" name="permiteVenderSaldoNegativo" id="permiteVenderSaldoNegativoTrue" class="mx-5"
                    v-model="novoParametro.permiteVenderSaldoNegativo">
                    <input type="radio" name="permiteVenderSaldoNegativo" id="permiteVenderSaldoNegativoFalse" class="mx-5"
                    v-model="novoParametro.permiteVenderSaldoNegativo">
                </div>
            </div>
            <div class="row w-100 between border">
                <h3 class="mx-5 w-100">Necessário aprovar venda do vendedor</h3>
                <div class="row w-20 between">
                    <input type="radio" name="somenteVendaSemCaixa" id="somenteVendaSemCaixaTrue" class="mx-5"
                    :checked="novoParametro.somenteVendaSemCaixa ? 'checked' : 'none'">
                    <input type="radio" name="somenteVendaSemCaixa" id="somenteVendaSemCaixaFalse" class="mx-5"
                    :checked="novoParametro.somenteVendaSemCaixa ? 'none' : 'checked'" >
                </div>
            </div>
            <div class="row w-100 between border">
                <h3 class="mx-5 w-100">Vendedor recebe venda á prazo</h3>
                <div class="row w-20 between">
                    <input type="radio" name="vendedorRecebePrazo" id="vendedorRecebePrazoTrue" class="mx-5"
                    :checked="novoParametro.vendedorRecebePrazo ? 'checked' : 'none'">
                    <input type="radio" name="vendedorRecebePrazo" id="vendedorRecebePrazoFalse" class="mx-5"
                    :checked="novoParametro.vendedorRecebePrazo ? 'none' : 'checked'" >
                </div>
            </div>
        </div>

        <div class="row w-100 between border">
            <h3 class="mx-5 w-100">Atalho de login</h3>
            <input id="atalhoLogin" type="text" :placeholder="Parametros.loginAtalho" @keydown.down.prevent="focarInputId('cdConsumidorFinal')" 
            @keydown.enter="focarInputId('cdConsumidorFinal')" class="input-controle" v-model="novoParametro.loginAtalho" autocomplete="off" >
        </div>
        <div class="row w-100 between border">
            <h3 class="mx-5 w-100">Código consumidor final</h3>
            <input id="cdConsumidorFinal" type="number" :placeholder="Parametros.cdConsumidorFinal" @keydown.up.prevent="focarInputId('atalhoLogin')"
            @keydown.down.prevent="focarInputId('maxPorcentagemDesconto')" @keydown.enter="focarInputId('maxPorcentagemDesconto')" min="0" 
            class="input-controle" v-model="novoParametro.cdConsumidorFinal" autocomplete="off" >
        </div>
        <div class="row w-100 between border">
            <h3 class="mx-5 w-100">Máximo desconto permitido</h3>
            <input id="maxPorcentagemDesconto" type="number" :placeholder="Parametros.maxPorcentagemDesconto + '%'" 
            @keydown.up.prevent="focarInputId('cdConsumidorFinal')" @keydown.down.prevent="focarInputId('maxNumeroParcelas')" 
            @keydown.enter="focarInputId('maxNumeroParcelas')" min="0" class="input-controle" v-model="novoParametro.maxPorcentagemDesconto" autocomplete="off">
        </div>
        <div class="row w-100 between border">
            <h3 class="mx-5 w-100">Número máximo de parcelas</h3>
            <input id="maxNumeroParcelas" type="number" :placeholder="Parametros.maxNumeroParcelas + 'x'" 
            @keydown.up.prevent="focarInputId('maxPorcentagemDesconto')" @keydown.down.prevent="focarInputId('minValorParcela')" 
            @keydown.enter="focarInputId('minValorParcela')" min="0" class="input-controle" v-model="novoParametro.maxNumeroParcelas" autocomplete="off" >
        </div>
        <div class="row w-100 between border">
            <h3 class="mx-5 w-100">Valor mínimo de parcela</h3>
            <input id="minValorParcela" type="number" :placeholder="Parametros.minValorParcela
            .toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})" 
            @keydown.up.prevent="focarInputId('maxNumeroParcelas')" @keydown.down.prevent="focarInputId('maxDiasTroca')" 
            @keydown.enter="focarInputId('maxDiasTroca')" min="0" class="input-controle" v-model="novoParametro.minValorParcela" autocomplete="off">
        </div>
        <div class="row w-100 between border">
            <h3 class="mx-5 w-100">Prazo máximo para trocas</h3>
            <input id="maxDiasTroca" type="number" :placeholder="Parametros.maxDiasTroca + ' dias'" 
            @keydown.up.prevent="focarInputId('minValorParcela')" min="0" class="input-controle" v-model="novoParametro.maxDiasTroca"
            @keydown.down.prevent="" autocomplete="off">
        </div>
        <div class="col w-100 between my-5">
            <button class="btnAcao w-100 my-5" style="background-color: var(--confirmar)" @click="salvarParametros">Salvar</button>
            <button class="btnAcao w-100 my-5" style="background-color: var(--cancelar)" @click="cancelar">Cancelar</button>
        </div>
    </div>
</template>

<script>
export default {
	name: "Parametros",

	computed: {
		Parametros() {
			return this.$store.state.Parametros;
		}
	},

	data() {
		return {
			novoParametro: {
				BASE_URL: "",
				loginAtalho: "",
				cdConsumidorFinal: "",
				maxPorcentagemDesconto: "",
				maxNumeroParcelas: "",
				minValorParcela: "",
				maxDiasTroca: "",
				permiteVenderSaldoNegativo: "",
				permitePrazoConsumidorFinal: "",
				somenteVendaSemCaixa: "",
				vendedorRecebePrazo: ""
			}
		};
	},

	methods: {

		focarInputId(id){
			document.getElementById(id).focus();
		},

		salvarParametros() {
			if (!this.novoParametro.loginAtalho) {
				return this.$store.commit("relatarErro", {mensagem: "É necessário informar o atalho de login."});
			}
			if (!this.novoParametro.cdConsumidorFinal && this.novoParametro.cdConsumidorFinal != 0) {
				return this.$store.commit("relatarErro", {mensagem: "É necessário informar o código do consumidor final."});
			}
			if (!this.novoParametro.maxPorcentagemDesconto && this.novoParametro.maxPorcentagemDesconto != 0) {
				return this.$store.commit("relatarErro", {mensagem: "É necessário informar o máximo de desconto permitido."});
			}
			if (!this.novoParametro.maxNumeroParcelas && this.novoParametro.maxNumeroParcelas != 0) {
				return this.$store.commit("relatarErro", {mensagem: "É necessário informar número máximo de parcelas."});
			}
			if (!this.novoParametro.minValorParcela && this.novoParametro.minValorParcela != 0) {
				return this.$store.commit("relatarErro", {mensagem: "É necessário informar valor mínimo de parcela."});
			}
			if (!this.novoParametro.maxDiasTroca && this.novoParametro.maxDiasTroca != 0) {
				return this.$store.commit("relatarErro", {mensagem: "É necessário informar o prazo máximo em dias para troca."});
			}
			return this.$store.dispatch("atualizarParametros", {...this.novoParametro});
		},

		cancelar() {
			return this.$router.push({name: "Principal"});
		}
	},

	mounted() {
		document.getElementsByName("permiteVenderSaldoNegativo")[this.Parametros.permiteVenderSaldoNegativo ? 0 : 1].checked = true;
		document.getElementsByName("somenteVendaSemCaixa")[this.Parametros.somenteVendaSemCaixa ? 0 : 1].checked = true;
		document.getElementsByName("vendedorRecebePrazo")[this.Parametros.vendedorRecebePrazo ? 0 : 1].checked = true;
	}
};
</script>

<style scoped>
#Parametros {
    width: auto;
    padding: 5px;
}

#Parametros * {
    font-size: var(--txt-medio);
}

.border {
    border-bottom: 1px solid #292929;
}

.flex-end {
    justify-content: flex-end;
}

.input-controle {
    width: 20%;
    min-width: 75px;
}

.btnAcao {
    height: auto;
}

@media screen and (max-width: 355px) {

}

@media screen and (max-width: 355px) {
    #Parametros * {
        font-size: var(--txt-pequeno);
    }

    .border label {
        width: 100%;
    }

    .row.between.w-100.h-100.totais {
        flex-direction: column;
    }

    #totalManual {
        width: 100%;
    }

}
</style>