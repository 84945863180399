<template>
	<div :class="`${!showTable ? 'table-full' : ''}`" id="listaModal">
        <table style="width: 100%">
            <thead>
                <tr>
                    <td style="text-align: center; cursor: pointer"
                    @click="selecionarTudo" v-if="selecionar">Selec.</td>
                    <td style="text-align: center">Emp.</td>
                    <td style="text-align: center">Nº Doc.</td>
                    <td style="text-align: center">Parc.</td>
                    <td style="text-align: left">Cliente</td>
                    <!-- <td style="text-align: left">Fone</td> -->
                    <td style="text-align: left; white-space: nowrap;">Tipo Pagamento</td>
                    <td style="text-align: right" v-if="selecionar">Valor pedido</td>
                    <td style="text-align: center" v-if="condicao == 'A'">Emissão</td>
                    <td style="text-align: center">Vencimento</td>
                    <td style="text-align: center" v-if="condicao == 'B'">Baixa</td>
                    <td style="text-align: right" v-if="condicao == 'A'">Montante</td>
                    <td style="text-align: right">Recebido</td>
                    <td style="text-align: center" v-if="condicao == 'A' && selecionar">Último pgto.</td>
                    <td style="text-align: center" v-if="condicao == 'A'">Atraso</td>
                    <td style="text-align: right" v-if="condicao == 'A'">Juros</td>
                    <td style="text-align: right" v-if="condicao == 'A'">Restante</td>
                </tr>
            </thead>
            
            <tbody>
                <tr v-for="(conta, index) in Contas" :key="index" @click="condicao == 'A' ? buscaVenda(conta) : buscaBaixa(conta)" @contextmenu.prevent="condicao == 'A' ? buscaVenda(conta) : buscaBaixa(conta)"
                :style="(conta.restante > 0.05) && (conta.condicao != 'R') && (condicao == 'A') && (new Date(
                    new Date(conta.dataVenc).getFullYear(),
                    new Date(conta.dataVenc).getMonth(),
                    new Date(conta.dataVenc).getDate()
                ) < new Date()) ? 'background-color: rgb(255, 50, 50); color: #fff' : ''" >
                    <td style="text-align: center"  v-if="selecionar">
                        <input type="checkbox" :id="(condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+ index" @change="selecionarValor((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+ index)"
                        class="js-checkbox-financeiro">
                    </td>
                    <td style="text-align: center">{{conta.cdEmpresa}}</td>
                    <td style="text-align: center" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)">
                        <label>{{condicao == 'A' ? conta.cdDoc ? conta.cdDoc : conta.cdMovi : conta.cdDig || conta.cddig}}</label>
                    </td>
                    <td style="text-align: center" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)">
                        <label>{{conta.cdLan}}</label>
                    </td>
                    <td style="text-align: left" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)">
                        <label>{{conta.cdPessoa}} - {{conta.nome}}</label>
                    </td>
                    <!-- <td style="text-align: left; white-space: nowrap" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)">
                        <label>{{conta.fone}}</label>
                    </td> -->
					<td style="text-align: center; white-space: nowrap" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)">
                        <label>{{conta.pagamento}}</label>
                    </td>
                    <td style="text-align: right" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)" v-if="selecionar">
                        <label>{{(conta.valorTitulo || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</label>
                    </td>
                    <td style="text-align: center" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)" v-if="condicao == 'A'">
                        <label>
                            {{
                                new Date(new Date(conta.dataDoc).getFullYear(), 
                                new Date(conta.dataDoc).getMonth(), new Date(conta.dataDoc).getDate() +1)
                                .toLocaleDateString("pt-BR")
                            }}
                        </label>
                    </td>
                    <td style="text-align: center" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)">
                        <label>
                            {{
                                new Date(new Date(conta.dataVenc).getFullYear(), 
                                new Date(conta.dataVenc).getMonth(), new Date(conta.dataVenc).getDate() +1)
                                .toLocaleDateString("pt-BR")
                            }}
                        </label>
                    </td>
                    <td style="text-align: center" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)"
                    v-if="condicao == 'B'">
                        <label>
                            {{
                                new Date(conta.dataBaixa).toLocaleString("pt-BR").split(" ")[1] == "21:00:00" ?
                                new Date(new Date(conta.dataBaixa).getFullYear(), 
                                new Date(conta.dataBaixa).getMonth(), new Date(conta.dataBaixa).getDate() +1)
                                .toLocaleDateString("pt-BR") : new Date(new Date(conta.dataBaixa).getFullYear(), 
                                new Date(conta.dataBaixa).getMonth(), new Date(conta.dataBaixa).getDate())
                                .toLocaleDateString("pt-BR") 
                            }}
                        </label>
                    </td>
                    <td style="text-align: right" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)" v-if="condicao == 'A'">
                        <label>{{conta.contarc.toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</label>
                    </td>
                    <td style="text-align: right" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)">
                        <label>{{conta.valor.toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</label>
                    </td>
                    <td style="text-align: center" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)" v-if="condicao == 'A' && selecionar">
                        <label>
                            {{
                                conta.dataUltimoPagamento ? new Date(new Date(conta.dataUltimoPagamento).getFullYear(), 
                                new Date(conta.dataUltimoPagamento).getMonth(), new Date(conta.dataUltimoPagamento).getDate() +1)
                                .toLocaleDateString("pt-BR") : ""
                            }}
                        </label>
                    </td>
                    <td style="text-align: center" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)" v-if="condicao == 'A'">
                        <label>
                            {{conta.diasEmAtraso && conta.diasEmAtraso > 0 ? conta.diasEmAtraso : 0}}
                        </label>
                    </td>
                    <td style="text-align: right" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)" v-if="condicao == 'A'">
                        <label>{{temJuros(conta)?(conta.jurosCalculados + (conta.jurosAcumulado || 0)).toLocaleString("pt-BR", {style: "currency", currency: "BRL"}):Number(0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</label>
                    </td>
                    <td style="text-align: right" @click="selecionarInput((condicao == 'A' ? conta.cdMovi : conta.cdDig) +'-'+index)" v-if="condicao == 'A'">
                        <label>{{temJuros(conta)?(conta.restante).toLocaleString("pt-BR", {style: "currency", currency: "BRL"}):conta.contarc.toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</label>
                    </td>
                </tr>
            </tbody>
        </table>
        <Modal v-if="vendaSelecionada" v-on:fecharModal="vendaSelecionada = null" :dimensoes="{width: '65%', height: 'auto'}">
            <PreImpressao :vendaFinalizada="vendaSelecionada" v-on:fecharModal="vendaSelecionada = null" :empresa="empresa" />
        </Modal>
	</div>
</template>

<script>
import Modal from "../components/Modal.vue";
import PreImpressao from "../components/PreImpressao.vue";
export default {
	name: "TableContasReceber",

	components: {
		Modal,
		PreImpressao
	},

	props: {
		Contas: Array,
		condicao: String,
		selecionar: Boolean,
		relatorio: Boolean,
		showTable: Boolean,
	},

	data() {
		return {
			vendaSelecionada: null,
			empresa: null,
		};
	},

	methods: {
		temJuros(conta){
			let Empresa;
			this.$store.state.Empresas.forEach(empresa => {
				if (empresa.id === conta.cdEmpresa){
					Empresa = empresa;
				}
			});
			if(conta.diasEmAtraso>Empresa.carenciaJuros){
				return true;
			}
			return false;
		},

		selecionarTudo() {
			if (this.condicao == "A") {
				document.querySelectorAll(".js-checkbox-financeiro").forEach(el => el.click());
			}
		},

		async buscaVenda(conta) {
			try {
				if (!conta) {
					return;
				}
				const venda = await this.$store.dispatch("buscarVendaPorId", {...conta, cdVenda: conta.cdMovi});
				
				const empresa = await this.$store.dispatch("buscarEmpresaId", {id: conta.cdEmpresa});
				this.empresa = empresa;
				if (!venda) return;
				this.vendaSelecionada = {...venda};
			} catch (error) {
				console.log(error);
				return this.$store.commit("relatarErro", {mensagem: "Não foi possível encontrar a venda."});
			}
		},
        
		buscaBaixa(conta) {
			try {
				if (!conta || this.relatorio) {
					return;
				}
				this.$store.dispatch("buscarBaixa", {...conta, impressao: true});
			} catch (error) {
				console.log(error);
			}
		},

		selecionarInput(id) {
			if (!this.selecionar) return;
			document.getElementById(id).click();
		},
	}
};
</script>
<style scoped>
#listaModal {
    width: 95%;
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;
	height: 50vh !important;
}
#listaModal.table-full{
	height: 70vh !important;
}
@media screen and (max-width: 768px) {
	#listaModal.table-full{
		height: 68vh !important;
	}
	#listaModal{
		height: 42vh !important;
	}
}
@media screen and (max-width: 425px) {	
	#listaModal.table-full{
		height: 60vh !important;
	}
	#listaModal{
		height: 15vh !important;
	}
}
</style>
