<template>
    <div id="listaModal" :class="`col w-95  ${!showTable? 'table-full' : ''}`" v-if="Bandeira.content.length >= 1 && tipoConteudo == 'Bandeiras'">
        <table class="w-100">
            <thead>
                <tr>
                    <td style="color: blue; text-align: center;">Cód.</td>
                    <td style="text-align: left;">Bandeira</td>
                    <td style="text-align: center;">Tx. Debito</td>
                    <td style="text-align: center;">Tx. Crédito</td>
                    <td style="text-align: center;">Ativo</td>
                </tr>
            </thead>
            <tbody>
                <tr class="itemLista" v-for="(bandeira, index) in Bandeira.content" :key="index" @click="selecionarBandeira(bandeira)">
					<td style="color: blue; text-align: center;">
                        <button :id="index" style="hand-button" v-if="index == indexAtual" @keydown.tab.prevent="" >
                            <i class="fas fa-hand-point-right" style="color: var(--primario)" >
                            </i>
                        </button>
                        {{bandeira.codigo}}
                    </td>
                    <td style="text-align: left;">{{bandeira.adm}}</td>
                    <td style="text-align: center;">{{((bandeira.txdebito || 0).toFixed(2).toString().replace(".", ",") == "0,00" ? "" :(bandeira.txdebito || 0).toFixed(2).toString().replace(".", ","))}}</td>
                    <td style="text-align: center;">{{((bandeira.txcredito || 0).toFixed(2).toString().replace(".", ",") == "0,00" ? "" :(bandeira.txcredito || 0).toFixed(2).toString().replace(".", ","))}}</td>
					<td style="text-align: center;">{{bandeira.ativo ? "SIM" : "NAO"}}</td>

                </tr>
            </tbody>
        </table>
    </div>
    <div class="MontarSubG" v-else-if="tipoConteudo == 'Grupos'">
        <table class="w-30" v-if="Grupos.length >=1">
            <thead>
                <tr>
                    <td style="color: blue; text-align: center; width :95px">Cód.</td>
                    <td style="text-align: left;">Grupo</td>
                    <td style="text-align: center;">Tipo</td>
                    <td style="text-align: center;" v-if="!desabilitarAcao">Ação</td>
                </tr>
            </thead>
            <tbody>
                <tr class="itemLista" v-for="(grupos, index) in Grupos" :key="index" @click="selecionarGrupos(grupos,index)">
                    <td style="color: blue; text-align: center;">
                        <button :id="index" style="hand-button" v-if="index == indexAtual" @keydown.tab.prevent="" >
                            <i class="fas fa-hand-point-right" style="color: var(--primario)" >
                            </i>
                        </button>
                        {{grupos.cdGrupo}}
                    </td>
                    <td style="text-align: left;">{{grupos.descricao}}</td>
                    <td style="text-align: center;">{{grupos.tipo}}</td>
                    <td style="text-align: center" v-if="!desabilitarAcao">
                        <i class="fas fa-pencil-alt" style="color: var(--confirmar)" @click="editarGrupo(grupos)"
                        @keydown.space="editarGrupo(grupos)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="tSubg" class="w-30">
            <thead>
                <tr>
                    <td style="color: blue; text-align: center; width :95px">Cód.</td>
                    <td style="text-align: left;">Grupo</td>
                </tr>
            </thead>
            <tbody>
                <tr class="itemLista" v-for="(subGrupo, index) in Grupos[indexAtual].subGrupos" :key="index" @click="selecionarsubGrupos(Grupos[indexAtual].cdGrupo, subGrupo.cdsubgrupo)">
                    <td style="color: blue; text-align: center;">
                        <button :id="index" style="hand-button" v-if="index == indexAtual" @keydown.tab.prevent="" >
                            <i class="fas fa-hand-point-right" style="color: var(--primario)" >
                            </i>
                        </button>
                        {{subGrupo.cdsubgrupo}}
                    </td>
                    <td style="text-align: left;">{{subGrupo.descricao}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div v-else class="col center">
        <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt" @click="fecharModal"></i>
        <h1>{{tipoConteudo.slice(0, tipoConteudo.length-1)}} não encontrada no banco de dados da loja.</h1>
        <h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
    </div>
</template>

<script>
export default {
	name: "TabelaBandeira",

	props: {
		tipoConteudo: String,
		desabilitarAcao: Boolean,
		showTable: Boolean, 
	},

	computed: {
		Bandeira() {
			return this.$store.state.Bandeiras;
		},
		Grupos() {
			return this.$store.state.grupoProduto;
		}
	},

	data() {
		return {
			indexAtual: 0
		};
	},

	methods: {
		selecionarBandeira(bandeira){
			return this.$emit("selecionarBandeira", bandeira);
		},

		fecharModal(){
			this.$emit("fecharModal");
		},

		selecionarGrupos(grupos,indice){
			if (!this.Grupos[indice].subGrupos.length){
				return this.$emit("selecionarGrupos", grupos.cdGrupo, 0);
			}
			this.indexAtual = indice;
		},

		selecionarsubGrupos(grupo,subgrupo){
			this.$emit("selecionarGrupos", grupo,subgrupo);
		},

		editarGrupo(grupos){
			return this.$emit("selecionarGrupos", grupos);
		},

		eventosTeclado(e) {
			if (e.key == "ArrowUp") {
				if (this.indexAtual > 0) {
					this.indexAtual--;
				}
				setTimeout(() => {
					document.getElementById(this.indexAtual).focus();
				}, 10);
			}
			if (e.key == "ArrowDown") {
				if (this.tipoConteudo == "Bandeiras") {
					if (this.indexAtual < this.Bandeira.content.length -1) {
						this.indexAtual++;
					}
				}
				if (this.tipoConteudo == "Grupos") {
					if (this.indexAtual < this.Grupos.length -1) {
						this.indexAtual++;
					}
				}
				setTimeout(() => {
					document.getElementById(this.indexAtual).focus();
				}, 10);
			}
			if (e.key == "Enter") {
				if (this.tipoConteudo == "Bandeiras") {
					this.selecionarBandeira(this.Bandeira.content[this.indexAtual]);
				}
				if (this.tipoConteudo == "Grupos") {
					this.selecionarGrupos(this.Grupos[this.indexAtual]);
				}
			}
			if (e.key == "Tab") {
				this.indexAtual = 0;
				setTimeout(() => {
					document.getElementById("textoPesquisa").focus();
				}, 50);
			}
		}
	},

	mounted(){
		document.addEventListener("keydown", this.eventosTeclado);
		this.$store.dispatch("buscarBandeiras");
		document.getElementById("textoPesquisa").addEventListener("focus", () => {
			this.indexAtual = 0;
		});
		this.$store.dispatch("buscarGrupos");
	},

	destroyed() {
		document.removeEventListener("keydown",this.eventosTeclado);
	}
};
</script>

<style scoped>
#listaModal {
	overflow: auto;
    margin: 0 auto;
    font-size: var(--txt-normal);
	height: 65vh;
}

label {
    cursor: pointer;
}

.hand-button {
    background: none;
    border: none;
    outline: none;
    clear: both;
}

.MontarSubG {
    display: flex;
    justify-content: space-around;
}

#tSubg {
    height: max-content;
}

.itemLista {
    height: auto;
    max-height: 30px;
}
#listaModal.table-full{
	height: 77vh !important;
}
@media screen and (max-width: 768px) {
	#listaModal.table-full{
		height: 68vh !important;
	}
	#listaModal{
		height: 43vh !important;
	}
}
@media screen and (max-width: 425px) {
	#listaModal.table-full{
		height: 71vh !important;
	}
	#listaModal{
		height: 30vh !important;
	}
}

</style>
