<template>
	<div>
		<div 
			v-if="!loading"
			class="container-calendar"
		>
			<Fullcalendar 
				:options="calendarOptions"
			/>
		</div>
		<div class="container-loader" v-else>
			<Loader 
				:width="'15vh'" 
				:height="'15vh'"
			/>
		</div>
		<Modal 
			v-if="visualizarContasDia" 
			:dimensoes="{width: '45%', height: 'auto'}"
			:idModal="'modal-por-conta'"
			@fecharModal="fecharModal"
		>
			<div class="container-modal">
				<span class="title-modal">
					{{ 
						`Contas no dia ${configContas.dataI.split("-").reverse().join("/")}`
					}} 
				</span>
				<ul v-if="configContas.contas.length > 0">
					<li v-for="(conta, index) in configContas.contas" :key="index">
						<span class="span-valor-quantidade">
							Valor: {{ conta.title }} - Qtd: {{ conta.quantidade }}
						</span>
						<span class="span-data">
							Dia: {{ `${conta.date}`.split("-").reverse().join("/") }}
						</span>
					</li>
				</ul>
				<span v-else>
					VOCÊ NÃO POSSUI CONTAS NESSE DIA
				</span>
				<div class="botoes">
					<button class="botao botao-cancelar" @click="fecharModal">
						Fechar
					</button>
				</div>
			</div>
		</Modal>
	</div>
</template>
<script>
import Fullcalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";

import Loader from "../components/Loader.vue";
import Modal from "../components/Modal.vue";

export default {
	name: "Agenda",
	components: {
		Fullcalendar,
		Loader,
		Modal
	},

	props: {
		height: String,
		Filtro: Object
	},
	
	computed: {
		ContasAgenda() {
			return this.$store.state.Agenda.Contas;
		}
	},

	data() {
		return {
			calendarOptions: {
				// PLUGINS E CONFIGURAÇÕES INICIAIS
				plugins: [dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin],
				initialView: "dayGridMonth",

				headerToolbar: {
					left: "title",
					center: "dayGridMonth timeGridWeek timeGridDay",
					right: "prevYear prev today next nextYear"
				},

				visibleRange: {
					start: "2022-01-30",
					end: "2022-12-30"
				},

				// EVENTOS EM GERAL
				dateClick: this.handleDateClick,
				events: [],

				// selectable: true,
				// select: this.hanldeSelect,

				// FORMATAÇÃO DE TEXTOS
				buttonText: {
					today: "Atual",
					month: "Mês",
					week: "Semana",
					day: "Dia",
				},
				locale: "PT-BR",
				// ESTILIZAÇÃO
				height: this.height,
			},
			loading: true,


			// CONTAS POR DIA
			visualizarContasDia: false,
			configContas: {
				dataI: "",
				dataF: "",
				contas: []
			}
		};
	},

	watch: {
		height(height) {
			this.calendarOptions.height = height;
		},

		ContasAgenda() {
			this.loading = true;
			this.calendarOptions.events = this.ContasAgenda;
			setTimeout(() => {
				this.loading = false;
			}, 250);
		}
	},

	mounted() {
		setTimeout(() => {
			this.calendarOptions.events = this.ContasAgenda;
			this.loading = false;
		}, 350);

	},

	methods: {
		// async hanldeSelect({startStr, endStr}) {
		// 	console.log("aq");
		// 	this.visualizarContasDia = true,
		// 	this.configContas.dataI = startStr;
		// 	this.configContas.dataF = endStr;
		// 	await this.buscarContasPorData({
		// 		dataVencI: startStr,
		// 		dataVencF: endStr,
		// 	});
		// 	if(!this.configContas.contas) {
		// 		this.configContas.contas = [];
		// 	}
		// },
		async handleDateClick({ dateStr }) {
			this.visualizarContasDia = true,
			this.configContas.dataI = dateStr;
			await this.buscarContasPorData({
				dataVencF: dateStr,
				dataVencI: dateStr,
			});
		},

		async buscarContasPorData({dataVencF, dataVencI}) {
			this.configContas.contas = await this.$store.dispatch("buscarContasPorDia", {
				dataVencF,
				dataVencI,
				condicao: this.Filtro.condicao || "A"
			});
			if(!this.configContas.contas) {
				this.configContas.contas = [];
			}
		},

		fecharModal() {
			this.visualizarContasDia = false,
			this.configContas = {
				dataI: "",
				dataF: "",
				contas: []
			};
		},
	}
};
</script>
<style scoped>
.container-loader{
	width: 100%;
	height: 62vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.container-calendar {
	padding: 10px;
	padding-top: 0px;
}

.container-modal {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 10px;
}

.title-modal { 
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
}

.container-modal ul {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: auto;
	overflow: auto;
}

.container-modal ul li{
	list-style: none;
	display: flex;
	width: 100%;
	padding: 5px;
	border-bottom: 1px solid #cecece;
	cursor: pointer;
}

.container-modal ul li:hover{
	background: #cecece;
}

.botoes {
	width: 100%;
}
.botoes button {
	width: 100%;
	margin-top: 10px;
}

.span-valor-quantidade {
	width: 70%;
	text-align: left;
}

.span-data {
	width: 30%;
}
</style>
