<template>
	<div class="container-page">
		<i 
			class="fas fa-print"
			style="color: var(--primario)"
			id="impressao"
			@click="toggleDropdown()"
			v-if="RelatorioVendas && RelatorioVendas.length > 0"
		/>
		<div class="dropdown-add" id="dropdownExport" ref="dropdownExport">
			<span
				class="dropdown-item"
				@click="exportarXLSX()"
			>
				Exportar para Excel
			</span>
			<span
				class="dropdown-item"
				@click="exportarPDF()"
			>
				Exportar para PDF
			</span>
		</div>
		<Filtro
			@filtrar="filtrarVendasFornecedor"
			@limpar="limpar"
			@handleShowTable="showTable"
		>
			
			<div class="row-filtro">
				<div class="container-input">
					<div class="input-group">
						<input
							id="dataInicio"
							type="date"
							v-model="dataInicio"
							@blur="saiuData"
						/>
						<label>Data Inicio</label>
					</div>
				</div>
				<div class="container-input">
					<div class="input-group">
						<input
							id="dataFinal"
							type="date"
							v-model="dataFinal"
							@blur="saiuData"
						/>
						<label>Data Final</label>
					</div>
				</div>
			</div>
		</Filtro>
		<div 
			class="container-tabela"
			v-if="RelatorioVendas && RelatorioVendas.length > 0 && !loadingTable"
		>
			<div class="header-tabela">
				<div class="linha-header-tabela">
					<div class="campo-tabela campo-menor">
						Venda
					</div>
					<div class="campo-tabela campo-maior">
						Fornecedor
					</div>
					<div class="campo-tabela campo-maior">
						Produto
					</div>
					<div class="campo-tabela campo-menor">
						Qtd.
					</div>
					<div class="campo-tabela campo-menor">
						$ Custo
					</div>
					<div class="campo-tabela campo-medio">
						Emissão
					</div>
					<div class="campo-tabela campo-menor">
						$ Bruto
					</div>
					<div class="campo-tabela campo-menor">
						$ Desconto
					</div>
					<div class="campo-tabela campo-menor">
						$ Troca
					</div>
					<div class="campo-tabela campo-menor">
						$ Valor
					</div>
					<div class="campo-tabela campo-pagamento">
						Pagamento
					</div>
				</div>
			</div>
			<div class="body-tabela">
				<div 
					class="linha-body-tabela" 
					v-for="(movi, index) in RelatorioVendas"
					:key="index"
				>
					<div class="campo-tabela campo-menor">
						{{ movi.venda }}
					</div>
					<div class="campo-tabela campo-maior">
						{{ movi.codigo }} - {{ movi.fornecedor }}
					</div>
					<div class="campo-tabela campo-maior">
						{{ movi.produ }} - {{ movi.produto }}
					</div>
					<div class="campo-tabela campo-menor">
						{{ (movi.quantidade || 0) }}
					</div>
					<div class="campo-tabela campo-menor">
						{{ (movi.custo || 0) | formatarParaReais }}
					</div>
					<div class="campo-tabela campo-medio">
						{{ `${movi.emissao || ""}`.split("-").reverse().join("/") }}
					</div>
					<div class="campo-tabela campo-menor">
						{{ (movi.bruto || 0) | formatarParaReais }}
					</div>
					<div class="campo-tabela campo-menor">
						{{ (movi.desconto || 0) | formatarParaReais }}
					</div>
					<div class="campo-tabela campo-menor">
						{{ (movi.troca || 0) | formatarParaReais }}
					</div>
					<div class="campo-tabela campo-menor">
						{{ (movi.valor || 0) | formatarParaReais }}
					</div>
					<div class="campo-tabela campo-pagamento">
						{{ movi.pagamento }}
					</div>
				</div>
			</div>
		</div>
		<paginacao-controle
			v-if="RelatorioVendas && RelatorioVendas.length > 0 && !loadingTable"
			:Paginacao="Paginacao"
			@alterarPaginacao="filtrarVendasFornecedor"
		>
		</paginacao-controle>
		<div 
			v-else-if="loadingTable" 
			class="col center w-100 height-62vh"
		>
			<Loader 
				:width="'15vh'"
				:height="'15vh'"
			/>
		</div>
		<div 
			v-else
			class="col center w-100 triangle height-62vh"
			id="table"
		>
			<i 
				class="fas fa-exclamation-triangle"
				style="color: yellow; font-size: 90pt"
			></i>
			<h3>
				Nenhuma conta encontrada no sistema.
			</h3>
			<h3>
				Tente alterar ou diminiur os parâmetros de busca.
			</h3>
		</div>
		<div class="containerTotais" v-if="RelatorioVendas && RelatorioVendas.length > 0 && !loadingTable">
			<div class="row">
				<div class="input-group">
					<input
						type="text"
						:value="(Totalizadores.quantidade || 0) | formatarParaReais"
						id="total-qtd"
						placeholder=" "
						readonly
					/>
					<label for="total-qtd">
						Quant.
					</label>
				</div>
				<div class="input-group">
					<input
						type="text"
						:value="(Totalizadores.desconto || 0) | formatarParaReais"
						id="total-desc"
						placeholder=" "
						readonly
					/>
					<label for="total-desc">
						Desconto
					</label>
				</div>
				<div class="input-group">
					<input
						type="text"
						:value="(Totalizadores.troca || 0) | formatarParaReais"
						id="total-troca"
						placeholder=" "
						readonly
					/>
					<label for="total-troca">
						Troca
					</label>
				</div>
				<div class="input-group">
					<input
						type="text"
						:value="(Totalizadores.bruto || 0) | formatarParaReais"
						id="total-bruto"
						placeholder=" "
						readonly
					/>
					<label for="total-bruto">
						Bruto
					</label>
				</div>
				<div class="input-group">
					<input
						type="text"
						:value="(Totalizadores.valor || 0) | formatarParaReais"
						id="total-valor"
						placeholder=" "
						readonly
					/>
					<label for="total-valor">
						Valor
					</label>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import formatarParaReais from "../filters/formatarParaMoeda";
import { dateFormatISO } from "../utils/dateformat";
import Filtro from "../components/Filtro.vue";
import Loader from "../components/Loader.vue";
import PaginacaoControle from "../components/PaginacaoControle.vue";


export default {
	name: "RelatorioFornecedor",

	data() {
		return {
			dataInicio: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString("en-CA"),
			dataFinal: new Date().toLocaleDateString("en-CA"),

			loadingTable: false,
		};
	},

	components: {
		Filtro,
		Loader,
		PaginacaoControle
	},

	filters: {
		formatarParaReais
	},

	computed: {
		...mapState(["Relatorios"]),

		Paginacao() {
			const { page, totalPages } = this.$store.state.Relatorios.paginacao;
			return {
				paginaAtual: page,
				totalPaginas: totalPages,
			};
		},

		RelatorioVendas() {
			return this.Relatorios.vendaFornecedor.content;
		},

		Totalizadores() {
			return this.Relatorios.vendaFornecedor.anexo;
		}
	},

	mounted() {
		this.filtrarVendasFornecedor();
	},

	methods: {
		saiuData(){
			if(this.dataInicio == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataInicio = dateFormatISO({date: null, format: "ISOdate"});
			}
			if(this.dataFinal == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataFinal = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		filtrarVendasFornecedor(page) {
			this.loadingTable = true;
			setTimeout(async () => {
				await this.$store.dispatch("buscarRelatorioVendaFornecedor", {
					dataI: this.dataInicio,
					dataF: this.dataFinal,
					paginaAtual: page || 0 
				});
				this.loadingTable = false;
			}, 1000);
		},
		limpar(){
			this.dataInicio = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString("en-CA");
			this.dataFinal = new Date().toLocaleDateString("en-CA");
			this.filtrarVendasFornecedor();
		},

		showTable () {
			const table = document.querySelector(".container-tabela");
			if(table) {
				table.classList.toggle("fullTable");
			}
		},

		toggleDropdown() {
			const dropdown = document.getElementById("dropdownExport");
			if(dropdown) {
				dropdown.classList.toggle("dropdown-active");
			}
		},

		async exportarPDF() {
			try {
				
				if(this.RelatorioVendas && this.RelatorioVendas.length ===0) {
					throw new Error(
						"Para gerar o relatório é necessário filtrar ao menos um registro, favor verificar"
					);
				}
				const filtro = {
					dataI: this.dataInicio,
					dataF: this.dataFinal,
					paginaAtual: 0,
					sizeSearch: this.$store.state.Relatorios.vendaFornecedor.totalElements,
				};
				await this.$store.dispatch("buscarRelatorioVendaFornecedor", filtro);
				await this.$store.dispatch("gerarRelatorioVendasPorFornecedorPDF", {empresa:this.$store.state.Empresa, vendas: this.RelatorioVendas, totais:this.Totalizadores, datas: {dataI:filtro.dataI, dataF:filtro.dataF}});
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
				});
				return null;
			}
		},
		async exportarXLSX() {
			try {
				
				if(this.RelatorioVendas && this.RelatorioVendas.length === 0) {
					throw new Error(
						"Para gerar o relatório é necessário filtrar ao menos um registro, favor verificar"
					);
				}
				const filtro = {
					dataI: this.dataInicio,
					dataF: this.dataFinal,
					paginaAtual: 0,
					sizeSearch: this.Relatorios.vendaFornecedor.totalElements,
				};
				await this.$store.dispatch("buscarRelatorioVendaFornecedor", filtro);
				await this.$store.dispatch("gerarRelatorioVendasPorFornecedorXLSX", {vendas: this.RelatorioVendas, datas: {dataI:filtro.dataI, dataF:filtro.dataF}});
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
				});
				return null;
			}
		},
	}

	
};
</script>

<style scoped>
.container-page {
	height: 89vh;
}

.container-tabela{
	width: 89vw;
	overflow: auto;
	height: 56vh;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.container-tabela.fullTable {
	height: 72vh;
}
.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 2px 5px;
}
.campo-tabela{
	font-size: 13px;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}

.campo-menor{
	width: 95px;
	text-align: center;
}

.campo-medio{
	width: 135px;
	text-align: center;
}

.campo-maior{
	width: 360px;
	min-width: 345px;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
}

.campo-pagamento {
	min-width: 155px;
	width: 155px;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
}

.height-62vh {
	height: 62vh;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	align-items: center;
}

#impressao{
	position: absolute;
	bottom: 30px;
	left: 140px;
	font-size: 35px;

	padding: 7px;
	border-radius: 50%;
    box-shadow: var(--shadow-secundario);

	background: #FFF;
	z-index: 3;
}

.dropdown-add{
	position: absolute;
	bottom: 50px;
	left: 185px;
	display: flex;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	width: 40px;
	height: 40px;
	gap: 5px;
	border-radius: 10px;
	padding: 5px;
	transition: all .2s ease;
	opacity: 0;
	font-size: 2px;	
	background: #FFF;
	z-index: 2;
}

.dropdown-add span {
	cursor: pointer;
	width: 100%;
	padding: 0px 5px;
}

.dropdown-add span:hover {
	border-bottom: 1px solid #cecece;
}

.dropdown-active {
	transition: all .2s ease;
	opacity: 1;
	width: 180px;
	height: 70px;
	font-size: 16px;
}

@media screen and (max-width: 450px) {
	.height-62vh {
		height: 30vh;
	}
	.container-tabela {
		width: 100vw;
		height: 30vh;
	}
	.row{
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex-grow: 1;
		gap: 10px;
	}
	.row .input-group{
		width: 155px;
	}
}

</style>