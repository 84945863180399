export class Vendedor {
	id;
	nome;
	ativo;
	cargo;
	cdempresa;
	maxPorcentagemDesconto;
	maxNumeroParcelas;
	minValorParcela;
	maxDiasTroca;
	comissaoVista;
	comissaoPrazo;
	comissaoConsorcio;
	constructor(vendedor) {
		this.id = vendedor.id;
		this.nome = vendedor.nome;
		this.ativo = vendedor.ativo;
		this.cargo = vendedor.cargo;
		this.cdempresa = vendedor.cdempresa;
		this.maxPorcentagemDesconto = vendedor.maxPorcentagemDesconto;
		this.maxNumeroParcelas = vendedor.maxNumeroParcelas;
		this.minValorParcela = vendedor.minValorParcela;
		this.maxDiasTroca = vendedor.maxDiasTroca;
		this.comissaoVista = vendedor.comissaoVista;
		this.comissaoPrazo = vendedor.comissaoPrazo;
		this.comissaoConsorcio = vendedor.comissaoConsorcio;
		if (vendedor.parametros) {
			this.parametros = vendedor.parametros;
		} else {
			this.parametros = {};
		}
	}
}