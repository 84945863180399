<template>
    <div id="Caixa">
        <h2 class="w-100 center">Conferência de caixa</h2>
        <div class="row w-100 between" v-if="$store.state.Usuario.usuarioPrivilegiado || $store.state.Usuario.supervisor">
            <label for="dataAbertura">Data do fechamento</label>
            <input type="date" id="dataAbertura" v-model="dataAbertura">
        </div>
        <div class="row w-100 between">
            <h3 class="w-100">Descrição</h3>
            <h5 style="margin-right: 25px;">Vendas</h5>
            <h5 class="">Recebimento</h5>
        </div>
        <div class="col w-100 between border h-100" v-for="(metodo, index) in formasPagamento" :key="index">
            <div class="row w-100 between h-100">
                <label :for="metodo.cdtipo + 'V'" class="w-100">{{metodo.descricao}}</label>
                <div class="row flex-end h-100">
                    <input type="text" :id="metodo.cdtipo + 'V'" class="text-right input-cdtipo" placeholder="R$ 0,00" autocomplete="off"
                    @input="recalcularTotal" @keydown.up="retornarMetodo(index-1, 'V')"
                    @keydown.down="proximoMetodo(index+1, 'V')" @keydown.enter="proximoMetodo(index+1, 'V')" @keydown.right="proximoMetodo(index, 'R')">
                    <input type="text" :id="metodo.cdtipo + 'R'" class="text-right input-cdtipo" placeholder="R$ 0,00" autocomplete="off"
                    @input="recalcularTotal" @keydown.up="retornarMetodo(index-1, 'R')" :disabled="metodo.mostrarecebimento != 'S'"
                    @keydown.down="proximoMetodo(index+1, 'R')" @keydown.enter="proximoMetodo(index+1, 'R')" @keydown.left="retornarMetodo(index, 'V')">
                </div>
            </div>
        </div>
        <div class="col between w-100 my-5">
            <input type="text" id="observacaoFinal" class="d-flex w-100 h-100" placeholder="Observação final" autocomplete="off"
            style="text-align: left; margin: 0px 0px 5px 0px;" v-model="observacaoFinal" @keydown.up="retornarMetodo(formasPagamento.length-1, 'V')"
            @keydown.down="proximoMetodo('totalManual', 'V')" @keydown.enter="proximoMetodo('totalManual', 'V')" @input="mascara"
            maxlength="80">
            <div class="row between w-100 h-100 totais">
                <h3>Total manual:</h3>
                <input type="text" id="totalManual" style="text-align: right;" readonly @keydown.enter="solicitarFecharCaixa"
                @keydown.up="retornarMetodo('observacaoFinal', 'V')"
                :value="totalManual.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})">
            </div>
            <div class="row between w-100 my-5">
                <button class="botao botao-adicionar w-100" @click="solicitarFecharCaixa">Fechar caixa</button>
            </div>
        </div>
		<Modal v-if="consultarVendasPendentes" v-on:fecharModal="consultarVendasPendentes = false">
			<Conciliadora @fecharModal="consultarVendasPendentes = false" @solicitarFecharCaixa="fecharCaixa" @fecharCaixa="fecharCaixa" />
		</Modal>
    </div>
</template>
<script>
import Conciliadora from "../components/Conciliadora.vue";
import Modal from "../components/Modal.vue";

export default {
	name: "Caixa",
	components: {Modal, Conciliadora},
	data() {
		return {
			totalManual: 0,
			observacaoFinal: "",
			dataAbertura: "",
			dataAtual: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
			consultarVendasPendentes: false,
		};
	},

	computed: {
		formasPagamento() {
			return this.$store.state.formasPagamento.filter(metodo => metodo.mostrarecebimento == "S" || metodo.mostravenda == "S");
		}
	},

	methods: {
		recalcularTotal(e) {
			if (e.data == ",") {
				return e.target.value = e.target.value.slice(0, e.target.value.indexOf(",")) + ".";
			}
			var er = /[^0-9.]/;
			er.lastIndex = 0;
			var campo = e.target;
			if (er.test(campo.value)) {
				campo.value = campo.value.split(e.data)[0];
			}
			let preco = 0;
			this.formasPagamento.forEach(metodo => {
				preco += Number(document.getElementById(`${metodo.cdtipo}V`).value);
				preco += Number(document.getElementById(`${metodo.cdtipo}R`).value);
			});
			return this.totalManual = preco;
		},

		retornarMetodo(index, tipo){
			if (typeof(index) == "string") {
				return document.getElementById(index).focus();
			}
			if (index >= 0) {
				document.getElementById(`${this.formasPagamento[index].cdtipo}${tipo}`).focus();
			}
		},

		proximoMetodo(index, tipo){
			if (typeof(index) == "string") {
				return document.getElementById(index).focus();
			}
			if (index < this.formasPagamento.length) {
				document.getElementById(`${this.formasPagamento[index].cdtipo}${tipo}`).focus();
			} else {
				document.getElementById("observacaoFinal").focus();
			}
		},
		solicitarFecharCaixa() {
			if(this.$store.state.Conciliadora.vendasPendentes.vendas.length > 0){
				return this.$store.commit("relatarErro", {
					mensagem: "Há vendas pendentes a serem conciliadas, deseja fazer a conciliacão?",
					tipo: "polar"
				});
			}
			this.fecharCaixa();
		},
		fecharCaixa(){
			let conferencia = [...this.formasPagamento.map(metodo => {
				return {
					...metodo,
					valorInformado: Number(document.getElementById(`${metodo.cdtipo}V`).value.replace(",", ".")),
					tipo: "V"
				};
			}), ...this.formasPagamento.map(metodo => {
				return {
					...metodo,
					valorInformado: Number(document.getElementById(`${metodo.cdtipo}R`).value.replace(",", ".")),
					tipo: "R"
				};
			})];
			this.$store.state.fechamentoCaixa = {movimentosInformados: conferencia, observacaoFinal:this.observacaoFinal};

			return this.$store.dispatch("fecharCaixa", {
				conferencia,
				observacaoFinal: this.observacaoFinal,
				dataAbertura: this.dataAbertura
			});
		},
		mascara() {
			this.observacaoFinal = this.observacaoFinal.toUpperCase();
		},
		polarConfirmado(){
			this.consultarVendasPendentes = true;
		},
		async buscarPagamentos() {
			await this.$store.dispatch("buscarFormasPagamento");
			this.proximoMetodo(0, "V");
		},
		polarCancelado(){
			this.fecharCaixa();
		}
	},
	mounted() {
		this.dataAbertura = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-");
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		this.$bus.$on("cancelarPolar", this.polarCancelado);
		this.buscarPagamentos();
	},
	destroyed(){
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
		this.$bus.$off("cancelarPolar", this.polarCancelado);
	}
};
</script>

<style scoped>
.w-100{
	width: 100%!important;
}
#Caixa {
    width: auto;
    padding: 5px;
}

.border {
    border-bottom: 1px solid #292929;
}

.flex-end {
    justify-content: flex-end;
}

#totalManual {
    width: 50%;
    font-size: 16pt;
}

.btn {
    height: auto;
}

.input-cdtipo {
    width: 50%;
    max-width: 150px;
}

@media screen and (max-width: 435px) {
    #Caixa {
        font-size: var(--txt-pequeno);
    }

    .border label {
        width: 100%;
    }

    .row.between.w-100.h-100.totais {
        flex-direction: column;
    }

    #totalManual {
        width: 100%;
    }

}
</style>
