import {doRequest} from "./http.js";
import {CentroCusto} from "../models/ContaPagar";
export default{
	state: {
		CentroCustos: {
			Lista: [],
			paginacao: {
				totalPages: 0,
				page: 0,
				totalElements: 0,
				size: 25,
			},
		}
	},
	actions: {
		async adicionarCentroCusto({commit}, payload) {
			let url = "centroCusto";
			try {
				if(payload.ativo) {
					payload.ativo = "A";
				} else {
					payload.ativo = "I";
				}
				const body = JSON.stringify(payload);

				const data = await doRequest(url, {method: "POST", cache: "no-cache", body: body}, commit);
				if(data[0]?.error) {
					throw new Error(`${data[0]?.field} ${data[0]?.error}`);
				}

				commit("relatarErro", {
					mensagem: "Centro de custo cadastrado com sucesso!",
					sucess: true,
				});
				return data;

			} catch (error) {
				console.log(error);
				commit("relatarErro", {
					mensagem: `${error.message}, entre em contato com o suporte!`
				});
				return false;
			}
		},

		async atualizarCentroCusto({state, commit}, payload) {
			let url = "centroCusto";
			try {
				state.carregando = true;
				if(payload.ativo) {
					payload.ativo = "A";
				} else {
					payload.ativo = "I";
				}
				const body = JSON.stringify(payload);
				const data = await doRequest(url, {
					method: "PUT", 
					cache: "no-cache", 
					body: body
				}, commit);
				state.carregando = false;

				if(data && data.erro || data.mensagem) {
					throw new Error(data.erro || data.mensagem);
				}
				
				if(data && !data.erro && !data.mensagem) {
					commit("relatarErro", {
						mensagem: "O Centro de Custo foi atualizado com sucesso!",
						sucess: true,
					});
					return data;
				}
				throw new Error("Não foi possível atualizar o centro de custo, por favor verifique!");
			} catch (error) {
				console.log(error);
				commit("relatarErro", {
					mensagem: error.message
				});
			}
		},

		async buscarCentroCusto({state, commit}, payload){
			try {

				const  { descricao } = payload;
				state.carregando = true;
				let url = "centroCusto/filter?";
				url += "size=25";
				url += "&sort=descricao";
				url += "&sortOrder=asc";
				url += "&somenteAtivo=false";

				url += `${descricao && descricao !== "" ? `&descricao=${descricao}` : ""}`;

				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache",
				}, commit);
				if(data && !data.erro || !data.mensagem){
					state.carregando = false;
					commit("atualizarListaCentroCusto", data);
					return data;
				}
				state.carregando = false;
				commit("atualizarListaCentroCusto", []);				
				return null;
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
				return null;
			}
		},
	},
	mutations :{
		atualizarListaCentroCusto(state, payload){
			if(payload?.content?.length > 0){
				state.CentroCustos.Lista = payload?.content.map(centro =>  new CentroCusto(centro));
			} else {
				state.CentroCustos.Lista = [];
			}
		},
	},
};