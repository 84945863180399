export class Grade {
	codigo = null;
	identificacao = "";
	grades = [];
	constructor(grade) {
		if (!grade) return;
		this.codigo = grade.codigo;
		this.identificacao = `${grade.identificacao || ""}`.toUpperCase();
		if (!grade.grades) return;
		this.grades = [...grade.grades].map(subgrade => ({grade: `${subgrade.grade || ""}`.toUpperCase(), codigo: subgrade.codigo}));
	}
}
