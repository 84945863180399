<template>
    <div class="ProdutoKit">
		<div class="produtokit-conteudo">
			<div class="row center w-100 header-produto">
				<h3 style="color: var(--primario);"><strong>{{produtoSelecionado.id}}</strong></h3>
				<h3 style="color: var(--primario)" id="separacaoTitulo" class="ocultar-atalho">&nbsp; - &nbsp;</h3> 
				<h3 style="color: var(--primario);"><strong>{{produtoSelecionado.descricao}}</strong></h3>
			</div>
			<div class="containerFiltro">
				<div class="pesquisaProduto">
					<div class="input-group" id="codProduto">
						<input
						type="number" v-model="idItem" id="js-id-produto" min="0" placeholder=" " @input="verificarInput"
						class="inputControle sm" @keydown="iniciarTimer" tabindex="1" @keydown.up.prevent="" @keydown.down.prevent=""
						@keydown.enter="proximoCampo('js-quant')" @click="Listarprodutos = true"
						>
						<label for="js-id-produto" class="ocultar-atalho">Código do Produto</label>
						<label for="js-id-produto" class="label sm-view">Cód. Produto</label>
					</div> 
				</div>
				<div class="quantidades">
					<div class="input-group" id="quantidade">
						<input
							style="text-align: right;" type="text" v-model="quantidade" id="js-quant" min="1" class="inputControle sm"
							tabindex="2" v-mascaraInteger @keydown="adicionarProduto" placeholder=" " autocomplete="off" enterkeyhint="done"
						>
						<label for="js-quant" class="ocultar-atalho" autocomplete="off">Quantidade</label>
						<label class="sm-view">Qtd.</label>
					</div>
					<div class="input-group" id="quantidadeTotal">
						<input
							style="text-align: right;" type="text" id="js-tqtde" min="1"
							class="" :value="somaqtde"  disabled autocomplete="disabled"
							>
						<label for="js-tqtde" class="ocultar-atalho">Quantidade Total</label>
						<label class="sm-view">Q. Total</label>
					</div>
					<div class="input-group custoTotal"> 
						<input 
						style="text-align: right;" type="text" id="js-total" min="1" class="inputControle sm" :value="somacusto | formatarParaReais"						
						>
						<label for="js-total" class="ocultar-atalho">Custo Total</label>
						<label for="js-total" class="sm-view">C. Total</label>
					</div>
				</div>
			</div>
			<div id="produtoSeSelecionado">
				<span>
					{{ produtoValido.id }}  	
				</span>
				<span v-show="produtoValido.descricao">-</span>
				<span>
					{{ produtoValido.descricao }}
				</span>
			</div>
			<div>
				<Modal v-if="Listarprodutos" v-on:fecharModal="Listarprodutos = false">
					<consulta-produtos 
						:Produtos="listadeproduto"
						@selecionarProduto="selecionarProduto"
						:height="'55vh'"
					/>
					<!-- <Paginacao tipoConteudo="Produtos" :desabilitarNovo="true"> 
						<ListagemProdutos v-bind:Produtos="listadeproduto" tipoConteudo="Produto" v-on:selecionarProduto="selecionarProduto"/>
					</Paginacao> -->
				</Modal>
			</div>
			<div class="produtokits">
				<table class="w-100" v-if="ItemKit.length >= 1">
					<thead>
						<tr>
							<td class="center">Seq.</td>
							<td class="center" style="color: var(--primario)">Cód.</td>
							<td style="min-width: 400px;">Produtos</td>
							<td class="center">Qtd.</td>
							<td class="center">$ Unit.</td>
							<td class="center">SubTotal</td>
							<td class="center">Ação</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(produtokit, index) in ItemKit" :key="index" class="itemtable" style="max-height: 15px;" :id="`item-${index}`"
							@click="editarItem(produtokit)" @keydown.space="editarItem(produtokit)" :tabindex="8+produtokit.id">
							<td class="center">{{produtokit.seq}}</td>
							<td class="center">{{produtokit.id}}</td>                                   
							<td style="text-align: left;">{{produtokit.descricao}}</td>                                                                   
							<td class="center" id="qtdProdutoTabela">{{(Number(produtokit.quantidade || 0).toFixed(0).toString().replace(".", ","))}}</td>   
							<td class="center" id="qtdProdutoTabelaInput"><input type="number" v-mascaraInteger></td>
							<td style="text-align: right;">{{(Number(produtokit.custo || 0).toFixed(2).toString().replace(".", ","))}}</td>                        
							<td style="text-align: right;">{{(Number((produtokit.custo|| 0) * (produtokit.quantidade || 1)).toFixed(2).toString().replace(".", ","))}}</td>                          
							<td style="text-align: center">
							<i class="fas fa-trash-alt" id="botaoRemoverProduto" style="color: var(--deletar)" 
								@click="removerItem(produtokit, index)"><!-- v-show="!produtokit.ativo" --></i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="botoes">             
			<button class="botao botao-cancelar" @click="cancelarEdicao"> 
				<label for="botaoCancelar" class="lg-view">Cancelar [Alt + C]</label>
				<label for="botaoCancelar" class="sm-view">Cancelar</label>
			</button>
			<button  class="botao botao-adicionar" @click="salvarKits">
				<label for="botaoAdicionar" class="lg-view">Salvar [Alt + S]</label>
				<label for="botaoAdicionar" class="sm-view">Salvar</label>
			</button>            
		</div>
    </div>
</template>

<script>
// import Paginacao from "./Paginacao.vue";
// import ListagemProdutos from "./ListagemProdutos.vue";
import Modal from "./Modal.vue";
import formatarParaReais from "../filters/formatarParaMoeda";
import ConsultaProdutos from "./ConsultaProdutos.vue";

export default {
	name: "ProdutoKit", 

	computed: {
		produtoSelecionado(){
			return this.$store.state.produtoSelecionado;
		},

		listadeproduto(){
			return this.$store.state.Produtos;
		},
		listaInteiraProdutos() {
			return this.$store.dispatch("buscarProdutos");
		},
		somacusto(){
			return this.ItemKit.length > 0 ? (this.ItemKit.map(prod =>prod.custo*prod.quantidade).reduce((total,valor)=>total+=valor)).toFixed(2).replace(".", ","):0;
		},
		somaqtde(){
			return this.ItemKit.length > 0 ? (this.ItemKit.map(prod =>Number(prod.quantidade)).reduce((total,valor)=>total+=valor)).toFixed(0).replace(".", ",") :0;
		}

	},
	components: {
		// Paginacao,
		// ListagemProdutos,
		Modal,
		ConsultaProdutos,
	},

	filters: {
		formatarParaReais,
	},

	data(){
		return {
			Listarprodutos : false,
			Novo : false,
			idItem: "",
			quantidade: 1,
			ItemKit : [],
			ProdutoSel : null,
			produtoValido: {
				id: "Nenhum produto selecionado"
			},
		};        
	},

	methods: {
		limparInputs() {
			this.ProdutoSel = null;
			this.idItem = "";
			this.quantidade = 1;
			this.produtoValido = {
				id: "Nenhum produto selecionado"
			};
			this.produtoSelecionadoTabela();
		},

		cancelarEdicao() {
			this.$emit("mudarAba", {target: {id: "variacao" }});
		},

		editarItem(item){
			this.quantidade = item.quantidade;
			this.ProdutoSel = item;
			this.idItem = item.id;
			this.proximoCampo("js-quant");
			this.produtoSelecionadoTabela();
		},
        
		selecionarProduto(produto){
			this.Listarprodutos = false;
			this.idItem = produto.id;
			this.ProdutoSel = produto;
			this.produtoSelecionadoTabela();
			document.getElementById("js-quant").focus();
			document.getElementById("js-quant").select();
		},
        
		verificarInput(e) {
			const v = e.target.value;
			this.verificarExistenciaProduto(e);
			this.produtoSelecionadoTabela();
			if(isNaN(v[v.length-1]) && e.data != "." && e.data != ","){ // impede entrar outro caractere que não seja número
				e.target.value = v.substring(0, v.length-1);
				return;
			}
		},

		async verificarExistenciaProduto() {
			const produto = await this.$store.dispatch("buscarKitId", this.idItem);
			if (produto) {
				if (produto.id == this.idItem) {
					this.produtoValido = {
						id: produto.id,
						descricao: produto.descricao,
					};
				} else {
					this.produtoValido = {
						id: "Nenhum produto selecionado",
					};
				}
			} else {
				this.produtoValido = {
					id: "Nenhum produto selecionado",
				};
			}
		},

		produtoSelecionadoTabela() {
			this.verificarExistenciaProduto();
			if(this.ItemKit?.forEach) {
				this.ItemKit.forEach((produto, index) => {
					const itemTabela = document.getElementById(`item-${index}`);
					if(produto?.id === this.idItem) {
						if(itemTabela) {
							itemTabela.style.backgroundColor = "lightblue";
						} else {
							itemTabela.style.backgroundColor = "";
						}
					} else {
						itemTabela ? itemTabela.style.backgroundColor = "" : "";
					}
				});
			}
		},

		iniciarTimer (e) {
			// if (e.key == "Enter") {
			//     return setTimeout(this.adicionarProduto, 200);
			// }
			if (e.key === "F4"){
				return this.Listarprodutos = true;
			}
			clearTimeout(this.timer);
			this.timer = setTimeout(this.selecionarItemPorId, 160);
		},

		async selecionarItemPorId(){
			const trouxeprodutokit = await this.$store.dispatch("buscarKitId", this.idItem);            
			if (trouxeprodutokit){
				this.ProdutoSel =  trouxeprodutokit;
			}
		},

		adicionarProduto(e) {
			if (e.key == "Enter") {
				this.proximoCampo("js-id-produto");
				if (!this.ProdutoSel || !this.ProdutoSel.id || this.idItem == "" || !this.idItem){
					this.limparInputs();
					this.$store.commit("relatarErro",{mensagem:"Produto não encontrado!."});
					setTimeout(() => {
						this.proximoCampo("fecharButton");
					}, 50);
					return;
				}
				if (!this.ProdutoSel.cdEmpresa) {
					this.$store.commit("relatarErro", {mensagem:"Produto com cadastro incorreto, verifique."});
					this.limparInputs();
					setTimeout(() => {
						this.proximoCampo("fecharButton");
					}, 50);
					return;
				}
				if(this.quantidade < 1) {
					this.limparInputs();
					this.$store.commit("relatarErro", {mensagem:"A quantidade mínima a ser adicionada é 1."});
					setTimeout(() => {
						this.proximoCampo("fecharButton");
					}, 50);
					return;
				}
				if(this.idItem != this.ProdutoSel.id) {
					this.limparInputs();
					return;
				}
				if (this.ProdutoSel.id == this.produtoSelecionado.id){
					this.limparInputs();
					this.$store.commit("relatarErro",{mensagem:"Não pode incluir Kit nos itens da composição!."});
					setTimeout(() => {
						this.proximoCampo("fecharButton");
					}, 50);
					return;
				}
				if (!this.ProdutoSel.seq){
					const existe = this.ItemKit.map(kit => kit.id).indexOf(this.ProdutoSel.id);
					if (existe >= 0){
						this.ItemKit[existe].quantidade = this.quantidade;
					} else {
						this.ItemKit.push({                    
							...this.ProdutoSel,
							quantidade : this.quantidade,
							seq:this.ItemKit.length+1,                            
						});
					}
				} else {
					if (this.ItemKit[this.ProdutoSel.seq-1] && this.ItemKit[this.ProdutoSel.seq-1].id == this.idItem) {
						this.ItemKit[this.ProdutoSel.seq-1].quantidade = this.quantidade;
					} else {
						if (this.ItemKit?.forEach) {
							this.ItemKit.forEach (item => {
								if (item.id == this.idItem) {
									item.quantidade = this.quantidade;
								}
							});
						}
					}
				}
				this.limparInputs();
				this.proximoCampo("js-id-produto");
			}
		},
        
		proximoCampo(id) {
			setTimeout(() => {
				document.getElementById(id).focus();
				document.getElementById(id).select();                
			}, 50);
		},

		async removerItem(kit, index){
			const deletou = await this.$store.dispatch("deletarKit",{
				cdempresa: kit.cdEmpresa,
				cdproduto: kit.id,
				cdprodutokit: this.produtoSelecionado.id
			});
			if (deletou){
				this.ItemKit.splice(index, 1);
			}
			this.ItemKit.forEach(item => {
				if (item.id == kit.id) {
					this.ItemKit.splice(index, 1);
				}
			});
			this.verificarExistenciaProduto();
			this.limparInputs();
		},

		salvarKits(){
			this.$store.dispatch("salvarKits", this.ItemKit.map(kit => ({
				cdprodutokit : this.produtoSelecionado.id,
				cdempresa : kit?.cdEmpresa || this.$store.state.Empresa.id,
				cdproduto : kit.id,
				quantidade : kit.quantidade,
			})));
		},

		async buscarkitscadastrado(){
			const buscarkitcad = await this.$store.dispatch("buscarKitCadastrado", this.produtoSelecionado.id);         
			if (!buscarkitcad) return;
			this.ItemKit =  buscarkitcad.map((kit, indice) => ({
				...kit,
				seq : indice + 1,
			}));            
		}

	},

	mounted() {        
		this.buscarkitscadastrado();
	},


};
</script>


<style scoped>
/* #qtdProdutoTabela {
	display: none;
} */

#qtdProdutoTabelaInput {
	display: none;
}

#qtdProdutoTabelaInput input {
	width: 40px;
	position: center;
	text-align: center;
}

#produtoSeSelecionado {
	margin: 5px 0px 5px 35px;
	display: inline-block;
	font-weight: bold;
	color: var(--primario);
}
.ProdutoKit {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow-y: hidden;
}
.produtokit-conteudo{
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.produtokits{
    display: flex;
    overflow-y: auto;
    width: 100%;
	max-height: 62vh;
	padding: 0px 3px 0px 3px;
}

.produtokits table {
    max-height: max-content;
	white-space: nowrap;
	height: 100%;
}

.itemtable{
    min-height: 10px !important;
    max-height: 10px !important;
}

.input-group label {
	height: 13px;
}

.containerFiltro {
	margin: 10px 0px 0px 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.containerButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
	margin-bottom: 10px;
	padding: 0px 10px 0px 10px;
	justify-content: center;
}

.sm-view {
	display: none;
}

.pesquisaProduto, .quantidades {
	display: flex;
	flex-direction: row;
}
.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}

.botoes .botao {
	width: 45%;
}
@media screen and (max-width: 1440px) and (min-width: 1400px) {
	#produtoSeSelecionado {
		margin-left: 70px;
	}
}

@media screen and (max-width: 1080px){
	::-webkit-scrollbar {
		display: none;
	}

	.pesquisaProduto {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.quantidades {
		width: 100%;
		display: flex;
		flex-direction: row;
	}

	.lg-view {
		display: none;
	}
	
	.sm-view {
		display: inline-block;
	}
	
	.ocultar-atalho {
		display: none;
	}

	#separacaoTitulo {
		display: inline-block;
	}

	
	#quantidade, #quantidadeTotal {
		width: 17vw;
	}

	.custoTotal .sm-view {
		display: none;
	}

	.custoTotal .ocultar-atalho {
		display: inline-block;
	}

	#produtoSeSelecionado {
		margin-left: 10px;
	}
}

@media screen and (max-width: 440px) {
	.ProdutoKit {
		overflow: auto;
	}

	.header-produto{
		display: block;	
	}

	.containerFiltro {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		gap: 15px;
	}

	#quantidade {
		width: 25vw;
	} 

	#quantidadeTotal {
		width: 34vw;
	}

	.quantidades {
		width: 100%;
		display: flex;
		flex-direction: row;
	}

	.custoTotal {
		width: 40%;
	}

	.pesquisaProduto {
		justify-content: space-between;
	}

	#codProduto {
		flex-grow: 1;
	}

	#separacaoTitulo {
		display: none;
	}
}

@media screen and (max-width: 320px) {
	.custoTotal .ocultar-atalho {
		display: none;
	}

	.custoTotal .sm-view {
		display: inline-block;
	}
}

</style>