<template>
	<div class="container-page">
		<paginacao-filtro
			@filtrar="filtrar"
			@limpar="limparFiltro"
			:mostrarBotaoNovo="mostrarBotaoNovo"
			@adicionarNovo="$emit('abrirCadastro')"
			@handleShowTable="handleShowTable"
			:ControlePaginacao="Paginacao"	
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro"  v-for="(filtro, index) in Object.keys(filtros)" :key="index">
							<div class="input-group" style="min-width: 200px; margin-bottom: 10px;">						
								<select v-model="filtros[filtro].opcaoSelecionada">
									<option :value="null" v-if="filtros[filtro].campoFiltro === 'empresa'">
										Selecionar
									</option>
									<option v-for="(opt, index) in Object.keys(filtros[filtro].opcoes)" 
										:key="`option-${index}`" 
										:value="filtros[filtro].opcoes[opt]"
									>
										{{filtros[filtro].campoFiltro === "empresa" ?  filtros[filtro].opcoes[opt] : ""}} 
										<p v-if="filtros[filtro].campoFiltro === 'empresa'">-</p>
										{{opt}}  
									</option>
								</select>
								<label :for="filtros[filtro].campoFiltro">
									{{filtro}}
								</label>
							</div>
						</div>
					</div>
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="filtroLinhaItem filtroLinhaData" style="display:flex;flex-direction: column;">
								<div class="input-group">
									<input type="date" v-model="filtro.dataI" @blur="saiuData"/>
									<label style="background-color:#f8f8f8">Data Inicio</label>
								</div>
								<div class="input-group">
									<input type="date" v-model="filtro.dataF" @blur="saiuData"/>
									<label style="background-color:#f8f8f8">Data Final</label>
								</div>
							</div>
						</div>
					</div>
					<div class="column-filtro">
						<div class="filtroBusca">
							<div class="row-filtro row-codigo">
								<div class="input-group">									
									<select id="size" v-model="filtro.size" >
										<option :value="25">25 itens</option>
										<option :value="50">50 itens</option>
										<option :value="100">100 itens</option>
									</select>
									<label>Exibir</label>
								</div>								
							</div>
							<div class="row-filtro">
								<div class="filtroLinhaItem">
									<div class="input-group">
										<input type="text" placeholder="Buscar por Código" id="pesquisaLivre" v-model="filtro.id" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<TabelaTransferenciaEstoque 
					@selecionarTransferencia="$emit('selecionarTransferencia', $event)"
					:showTable="showTable"		
					@abrirCadastro="$emit('abrirCadastro',$event)"
					@ordenarLista="ordenarLista"
				/>
			</template>
		</paginacao-filtro>
	</div>
</template>
<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import TabelaTransferenciaEstoque from "../components/TabelaTransferenciaEstoque.vue";
import { dateFormatISO } from "../utils/dateformat";

export default{
	name: "ConsultaTransferenciaEstoque",
	components: {
		PaginacaoFiltro,
		TabelaTransferenciaEstoque
	},
	props: {
		mostrarBotaoNovo: Boolean,
	},
	data() {
		return {
			filtro: {},
			filtros:{},
			showTable: true,
		};
	},

	computed: {
		Empresas() {
			return this.$store.state.Empresas;
		},
		Paginacao() {
			return {
				paginaAtual: this.$store.state.paginaTransferenciaEstoqueAtual,
				totalPaginas: this.$store.state.totalPaginasTransferenciaEstoque
			};
		}
	},

	methods: {
		saiuData(){
			if(this.filtro.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.filtro.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataF = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		handleShowTable() {
			this.showTable = !this.showTable;
		},
		limparFiltro() {
			this.filtro = {
				id: "",
				page: "",
				size: 25,
				filtroDinamico: {},
				dataI: "",
				dataF: "",
				ordem: "",
				sort: "",
			};
			this.filtros = {
				"Situação": {
					campoFiltro: "situacao",
					opcoes: {
						"Digitação": "D",
						"Confirmado": "C",
						"Ambos": null,
					},
					opcaoSelecionada: null
				},
				"Empresa":{
					campoFiltro: "empresa",
					opcoes: {
					},
					opcaoSelecionada: null,
				},
				"Tipo Transferência": {
					campoFiltro: "tipo",
					opcoes: {
						"Normal": "N",
						"Venda": "V",
						"Ambos": null,
					},
					opcaoSelecionada: null,
				}
			};		
			this.$store.state.Empresas.forEach(empresa => {
				this.filtros.Empresa.opcoes[empresa.nome] = empresa.id;	
			});
			this.filtros.Empresa.opcaoSelecionada = null;
			this.filtrar(null);
		},
		iniciaFiltro() {
			this.filtro = {
				id: "",
				page: "",
				size: 25,
				filtroDinamico: {},
				dataI: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1,
				).toLocaleDateString("en-CA"),
				dataF: new Date().toLocaleDateString("en-ca"),
				ordem: "",
				sort: "",
			};
			this.filtros = {
				"Situação": {
					campoFiltro: "situacao",
					opcoes: {
						"Digitação": "D",
						"Confirmado": "C",
						"Ambos": null,
					},
					opcaoSelecionada: "D"
				},
				"Empresa":{
					campoFiltro: "empresa",
					opcoes: {					
					},
					opcaoSelecionada: null,
				},
				"Tipo Transferência": {
					campoFiltro: "tipo",
					opcoes: {
						"Normal": "N",
						"Venda": "V",
						"Ambos": null,
					},
					opcaoSelecionada: null,
				}
			};		
			this.$store.state.Empresas.forEach(empresa => {
				this.filtros.Empresa.opcoes[empresa.nome] = empresa.id;	
			});
			this.filtros.Empresa.opcaoSelecionada = null;
			this.filtrar(null);
		},
		async filtrar(page) {
			this.filtro.filtroDinamico = {};
			Object.keys(this.filtros).forEach(key => {
				if (this.filtros[key].opcaoSelecionada != "Selecione" && this.filtros[key].opcaoSelecionada != null) {
					this.filtro.filtroDinamico[this.filtros[key].campoFiltro] = this.filtros[key].opcaoSelecionada;
				}
			});
			this.filtro.page = page ? page : 0;
			if(!this.filtro.size){
				this.limparFiltro();
			}			
			await this.$store.dispatch("filtrarTransferenciaEstoque", this.filtro);
		},
		ordenarLista(ordenar){
			this.filtro.order = ordenar.ordem;
			this.filtro.sort = ordenar.sort;
			this.filtrar();
		},
	},

	mounted() {
		this.iniciaFiltro();
	}
};
</script>
<style scoped>
.container-page {
	width: 100%;
	overflow: hidden;
}

.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;

}
.column-filtro{
	display: flex;
	flex-direction: column;
}
.filtroLinhaItem{
	display: flex;
	flex-direction: row;
	margin:5px;
}

.filtroLinhaItem select{
	background-color: var(--bg-app);
	border: none;
	border-radius: 5px;
	box-shadow: 1px 1px 3px #292929;
	text-align: left;
	max-width: 200px;
	width: 100%;
	user-select: none;
	margin-left: 5px;
}

.filtroLinhaData{
	display: flex;
	flex-wrap: wrap;
}

.filtro-content{
	display: flex;
	flex-direction: row;
	width: auto;
}

.filtroLinhaData .input-group{
	margin:5px;
}

@media screen and (max-width: 500px){
	.filtroLinhaData .input-group{
		width:40vw;
	}
	.filtroLinhaData{		
		flex-wrap: nowrap;
	}
	.filtro-content{
		flex-direction: column;
	}

	.row-codigo{
		flex-wrap:nowrap;
	}
}
</style>