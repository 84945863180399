<template>
	<div class="main">
		<div class="identificador-vendedor-cliente">
			<img class="logo-bds" :src="imagemEmpresa">
			<div class="identificador-vendedor">
				<h3 @click="trocarVendedor">
					{{
						!Venda.vendedor 
						? "Selecionar vendedor" 
						: `Vendedor: ${Venda.vendedor.nome}`
					}}
				</h3>
			</div>

			<div class="identificador-caixa my-5">
				<h3 @click="trocarCaixa">
					Caixa: {{
						Caixa.caixa.identificador
					}}
				</h3>
			</div>

			<div class="identificador-cliente my-5">
				<i class="fas fa-address-book" @click="trocarCliente"></i>
				<h3 @click="trocarCliente">
					Cliente: 
					{{Venda.cliente ? Venda.cliente.id : "0"}}
					- 
					{{Venda.cliente ?  (Venda.nomeCliente && Venda.cliente.nome != Venda.nomeCliente && Venda.nomeCliente != "") ? Venda.nomeCliente : Venda.cliente.nome : "NÃO ENCONTRADO"}}
				</h3>
				<i
					class="fas fa-wallet"
					v-if="
						Venda.cliente && $store.state.ClientePadrao 
						? Venda.cliente.id != $store.state.ClientePadrao.id : false
					"
					@click="$emit('consultarContas', Venda.cliente)"
				></i>
				<i class="fas fa-id-card" @click="solicitarIncluirObservacao"></i>
				<button
					v-if="$store.state.Usuario.usuarioPrivilegiado"
					@click="abrirDataEmissao"
					class="none"
				>
					<i class="fas fa-calendar-alt"></i>
				</button>
			</div>
		</div>

		<Modal
			v-if="inserirObservacao && !$route.query.baixarCondicional" 
			@fecharModal="cancelouInformacoes" 
			:dimensoes="{width:'auto', height: '85vh'}"
			:idModal="'cupom-dados'"
		>
			<CupomDados
				@fecharModal="inserirObservacao = false" 
				@salvarInformacoes="salvouInformacoes" 
				:clienteVenda="clienteVenda" 
				@cancelarInformacoes="cancelouInformacoes"
			/>
		</Modal>

		<Modal
			v-if="trocarDataEmissao"
			@fecharModal="trocarDataEmissao = !trocarDataEmissao"
			:dimensoes="{
				width: 'auto',
				height: 'auto',
			}"
			:idModal="'data-emissao'"

		>
			<div class="input-group">
				<input 
					type="date"
					id="dataEmissao"
					v-model="dataEmissao"
					@input="alterarDataEmissao"
					@keydown.enter="fecharDataEmissao"
				/>
				<label for="dataEmissao">
					Data emissão
				</label>
			</div>
		</Modal>
	</div>
</template>

<script>
import Modal from "../components/Modal.vue";
import CupomDados from "../components/CupomDados.vue";

export default {
	name: "IdentificadorVendedorCliente",

	data() {
		return {
			trocarDataEmissao: false,
			dataEmissao: "",
			inserirObservacao: false,
			identificador: "",
			observacao: "",
			imagemEmpresa: "",
			cnpjCliente: "",
			ieCliente: "",
			nomeCliente: "",
			foneCliente: "",
			enderecoCliente: "",
			numeroEndereco: "",
			cdCliente: "",
			bairroCliente: "",
			cepCliente: "",
			cidadeCliente: "",
			ufCliente: "",
			tipoPessoa: "F",
			timer: null,
			clienteVenda: {}
		};
	},
	components: {
		Modal,
		CupomDados
	},

	computed: {
		Venda() {
			return this.$store.state.Venda;
		},

		Empresa() {
			return this.$store.state.Empresa;
		},

		Caixa() {
			return this.$store.state.Caixa;
		},

		mostrarDataEmissao () {
			return (this.$store.state.Usuario.supervisor || this.$store.state.Usuario.usuarioPrivilegiado);
		},
	},

	methods: {
		salvouInformacoes(informacoes){
			this.$emit("salvarInformacoes", informacoes);
		},
		verificaCPF(strCPF) {
			strCPF = strCPF.replace(/[^\d]+/g,"");

			if(strCPF == "") return false;

			if (strCPF.length != 11) return false;

			let constants = [
				"00000000000",
				"11111111111",
				"22222222222",
				"33333333333",
				"44444444444",
				"55555555555",
				"66666666666",
				"77777777777",
				"88888888888",
				"99999999999"
			];
			if (this.$store.state.Usuario.validarCnpj) if (constants.includes(strCPF)) return false;

			let Soma;
			let Resto;
			Soma = 0;
			for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
			Resto = (Soma * 10) % 11;
			if ((Resto == 10) || (Resto == 11))  Resto = 0;
			if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;
			Soma = 0;
			for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
			Resto = (Soma * 10) % 11;
			if ((Resto == 10) || (Resto == 11))  Resto = 0;
			if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
			return true;
		},

		verificaCNPJ(cnpj) {

			cnpj = cnpj.replace(/[^\d]+/g,"");

			if(cnpj == "") return false;

			if (cnpj.length != 14) return false;

			let constants = [
				"00000000000000",
				"11111111111111",
				"22222222222222",
				"33333333333333",
				"44444444444444",
				"55555555555555",
				"66666666666666",
				"77777777777777",
				"88888888888888",
				"99999999999999"
			];
			if (this.$store.state.Usuario.validarCnpj) if (constants.includes(cnpj)) return false;

			let tamanho = cnpj.length - 2;
			let numeros = cnpj.substring(0,tamanho);
			let digitos = cnpj.substring(tamanho);
			let soma = 0;
			let pos = tamanho - 7;
			let i;
			for (i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2) pos = 9;
			}
			let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(0)) return false;

			tamanho = tamanho + 1;
			numeros = cnpj.substring(0,tamanho);
			soma = 0;
			pos = tamanho - 7;
			for (i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2) pos = 9;
			}
			resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(1)) return false;
			return true;
		},
		cancelouInformacoes() {
			this.$emit("cancelarInformacoes", true);
			this.inserirObservacao = false;
		},
		proximoCampo(id) {
			setTimeout(() => {
				const campo = document.getElementById(id);
				if (campo) {
					campo.focus();
					if (campo.select) {
						campo.select();
					}
				}
			},50);
		},
		trocarVendedor(){
			const usuario = this.$store.state.Usuario;
			if ((!usuario.supervisor && !usuario.caixa) && !usuario.vendeParaOutroVendedor){
				return;
			}
			return this.$emit("trocarVendedor");
		},

		trocarCaixa() {
			const usuario = this.$store.state.Usuario;
			if ((!usuario.supervisor && !usuario.caixa) || (this.$store.state.Caixas.content.length < 2)){
				return;
			}
			return this.$emit("trocarCaixa");
		},

		trocarCliente() {
			if (
				(!this.$route.query.baixarCondicional) || 
				(this.Venda.cliente.id == this.$store.state.ClientePadrao.id)
			) {
				return this.$emit("trocarCliente");
			}
		},

		solicitarIncluirObservacao() {
			this.inserirObservacao = true;
			if(this.Venda.cliente.id != this.$store.state.ClientePadrao.id){
				this.clienteVenda = this.Venda.cliente;
			}
			if (this.Venda.identificador) {
				this.Venda.identificador = this.identificador;
			}
			if (this.Venda.cliente.id == this.$store.state.ClientePadrao.id) {
				this.cnpjCliente = this.Venda.cnpjCliente || "";
				this.nomeCliente = this.Venda.nomeCliente || "";
				this.foneCliente = this.Venda.foneCliente || "";
				if (this.Venda.enderecoCliente?.includes(",")) {
					this.numeroEndereco = this.Venda.enderecoCliente.split(",")[1].trim();
					this.enderecoCliente = this.Venda.enderecoCliente.split(",")[0];
				} else {
					this.enderecoCliente = this.Venda.enderecoCliente || "";
					this.numeroCliente = this.Venda.numeroCliente || "";
				}
				this.ieCliente = this.Venda.ieCliente || "";
				this.bairroCliente = this.Venda.bairroCliente || "";
				this.cepCliente = this.Venda.cepCliente || "";
				this.cidadeCliente = this.Venda.cidadeCliente || "";
				this.ufCliente = this.Venda.ufCliente || "";
				this.tipoPessoa = this.Venda.tipoPessoa || "F";
				this.identificador = this.Venda.identificador || this.identificador;

				this.clienteVenda = {
					cnpj: this.Venda.cnpjCliente || "",
					nome: this.Venda.nomeCliente || "",
					fone: this.Venda.foneCliente || "",
					numero: this.Venda.numeroEndereco || this.Venda.numeroCliente,
					endereco: this.Venda.enderecoCliente,
					ierg: this.Venda.ieCliente || this.Venda.ierg || "",
					bairro: this.Venda.bairroCliente || "",
					cep: this.Venda.cepCliente || "",
					cidade: this.Venda.cidadeCliente || "",
					uf: this.Venda.ufCliente || "",
					tipoPessoa: this.Venda.tipoPessoa || "F",
					identificador: this.Venda.identificador || this.identificador,
					dataNascimento: this.Venda.nascimentoCliente || "",
				};
			} else {
				this.cdCliente = this.Venda.cliente.id;
				this.cnpjCliente = this.Venda.cliente.cnpj || "";
				this.nomeCliente = this.Venda.cliente.nome || "";
				this.foneCliente = this.Venda.cliente.celular || this.Venda.cliente.fone;
				this.enderecoCliente = this.Venda.cliente.endereco || "";
				this.numeroEndereco = this.Venda.cliente.numero || "";
				this.ieCliente = this.Venda.cliente.ierg || "";
				this.bairroCliente = this.Venda.cliente.bairro || "";
				this.cepCliente = this.Venda.cliente.cep || "";
				this.cidadeCliente = this.Venda.cliente.cidade?.nomecidade || "";
				this.ufCliente = this.Venda.cliente.cidade?.uf || "";
				this.tipoPessoa = this.Venda.cliente.tipoPessoa || "F";
				this.identificador = this.Venda.identificador || "";
			}
			this.proximoCampo("nomeCliente");
		},
		abrirDataEmissao () {
			this.trocarDataEmissao = !this.trocarDataEmissao;
			this.proximoCampo("dataEmissao");
		},
		fecharDataEmissao () {
			this.trocarDataEmissao = !this.trocarDataEmissao;
			this.alterarDataEmissao();
			if (this.$route.name == "Lancamento") {
				this.proximoCampo("js-id-produto");
			}
		},
		atualizarDataEmissaoVenda () {
			if (!this.dataEmissao) {
				this.dataEmissao = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-");
			}
			this.$store.state.Venda.dataEmissao = new Date(
				this.dataEmissao.split("-")[0],
				Number(this.dataEmissao.split("-")[1]) - 1,
				this.dataEmissao.split("-")[2],
				new Date().getHours() - 3,
				new Date().getMinutes(),
				new Date().getSeconds(),
				new Date().getMilliseconds(),
			).toISOString();
		},
		alterarDataEmissao () {
			clearTimeout(this.timer);
			this.timer = setTimeout(this.atualizarDataEmissaoVenda, 400);
		},
		iniciarCampos() {
			if (this.Venda?.dataEmissao) {
				this.dataEmissao = new Date(this.Venda.dataEmissao).toLocaleDateString("en-CA");
			} else {
				this.alterarDataEmissao();
			}
			if (this.Venda.vendedor == null || !this.Venda.vendedor){
				if (this.$route.query.tipoVenda != "C") {
					this.trocarVendedor();
				}
			}
			if (this.Venda.identificador) {
				this.identificador = this.Venda.identificador;
			}
			if (!this.Venda.cliente) {
				this.Venda.cliente = this.$store.state.ClientePadrao;
			}
			if (this.Venda.cliente?.id != this.$store.state.ClientePadrao?.id) {
				this.nomeCliente = this.Venda.nomeCliente || "";
				this.foneCliente = this.Venda.foneCliente || "";
				this.enderecoCliente = this.Venda.enderecoCliente || "";
				this.cnpjCliente = this.Venda.cnpjCliente || "";
				if (this.Venda.enderecoCliente?.includes(",")) {
					this.numeroEndereco = this.Venda.enderecoCliente.split(",")[1].trim();
					this.enderecoCliente = this.Venda.enderecoCliente.split(",")[0];
				} else {
					this.enderecoCliente = this.Venda.enderecoCliente || "";
				}
				this.ieCliente = this.Venda.ieCliente || "";
				this.bairroCliente = this.Venda.bairroCliente || "";
				this.cepCliente = this.Venda.cepCliente || "";
				this.cidadeCliente = this.Venda.cidadeCliente || "";
				this.ufCliente = this.Venda.ufCliente || "";
				this.tipoPessoa = this.Venda.tipoPessoa || "F";
			} else {
				this.nomeCliente = this.Venda.cliente.nome;
				this.foneCliente = this.Venda.cliente.celular || this.Venda.cliente.fone;
				this.enderecoCliente = this.Venda.cliente.endereco;
				this.numeroEndereco = this.Venda.cliente.numero;
				this.cnpjCliente = this.Venda.cliente.cnpj;
				this.ieCliente = this.Venda.cliente.ierg || "";
				this.bairroCliente = this.Venda.cliente.bairro || "";
				this.cepCliente = this.Venda.cliente.cep || "";
				this.cidadeCliente = this.Venda.cliente.cidade?.nomecidade || "";
				this.ufCliente = this.Venda.cliente.cidade?.uf || "";
				this.tipoPessoa = this.Venda.cliente.tipoPessoa || "F";
				this.identificador = this.Venda.identificador || this.identificador;
			}
			this.$forceUpdate();
		},
	},

	created() {
		this.iniciarCampos();
		if (this.Empresa && this.Empresa?.logo) {
			this.imagemEmpresa = `data:image/png;base64,${this.Empresa.logo}`;
		} else {
			this.imagemEmpresa = require("../assets/logo-transp-tidoc.png");
		}
		this.$bus.$on("incluirObservacao", this.solicitarIncluirObservacao);
		this.$bus.$on("atualizadoConsumidorOuVendedor", () => {
			this.$forceUpdate();
		});
	},

	destroyed() {
		this.$bus.$off("incluirObservacao", this.solicitarIncluirObservacao);
		this.$bus.$off("atualizadoConsumidorOuVendedor", () => {
			this.$forceUpdate();
		});
	}

};
</script>

<style scoped>

#identificadorVenda {
	height: auto;
	display: flex;
	align-content: space-between;
}

h3 {
	cursor: pointer;
}


.identificador-vendedor-cliente {
	display: flex;
	flex-direction: column;
	padding: 5px;
	position: relative;
	height: 100%;
	width: 100%;
}

.logo-bds {
	max-width: 150px;
	position: absolute;
	right: 5px;
}

.identificador-vendedor {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	z-index: 1;
	flex-grow: 1;
	max-width: calc(100% - 150px);
}
.identificador-custo{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	margin-bottom: 5px;
}
.identificador-caixa {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	justify-self: flex-end;
	z-index: 1;
}

.identificador-cliente {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-self: flex-end;
}

i {
	font-size: 20px;
	color: var(--primario);
}

.informacoes-cliente {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	width: 100%;
}
.informacoes-cliente-item {
	display: flex;
	flex-direction: row;
	width: 90%;
	margin: 5px auto;
}
.informacoes-cliente-item input[type="text"] {
	width: 100%;
}
.buttons{
    justify-content: space-between;
}
.informacoes-cliente-item .btn{
    width: 45%;
}

@media screen and (max-width: 1005px) {
	* {
		font-size: 11pt;
	}
}

@media screen and (max-width: 380px) {
	* {
		font-size: var(--txt-pequeno) !important;
	}
}

@media screen and (max-height: 595px) {
	.main {
		font-size: var(--txt-pequeno);
	}
}
.none {
	background-color: transparent;
	display: block;
	border: 0px;
	width: max-content;
	height: max-content;
}
</style>
