<template>
    <div class="col around m-auto w-80">
        <div class="col between w-100">
            <h2>Abertura de caixa</h2>
        </div>
        <div class="row center w-100 my-5">
            <div class="col between w-100">
                <label for="dataAbertura">Data de abertura</label>
                <input type="date" id="dataAbertura" class="w-80 p-5" :readonly="!$store.state.Usuario.usuarioPrivilegiado" v-model="dataAbertura" autocomplete="off">
            </div>
            <div class="col between w-100">
                <label for="usuarioCaixa">Usuário</label>
                <input type="text" id="usuarioCaixa" readonly class="w-80 p-5" :value="usuarioCaixa" autocomplete="off">
            </div>
        </div>
        <div class="row center w-100 my-5">
            <div class="col between w-100">
                <label for="identificacaoCaixa">Identificador</label>
                <input type="text" id="identificacaoCaixa" readonly class="w-80 p-5" :value="caixa.identificador" autocomplete="off">
            </div>
            <div class="col between w-100">
                <label for="saldoInicial">Saldo inicial</label>
                <input type="text" id="saldoInicial" class="w-80 p-5" readonly style="text-align: right;" autocomplete="off"
                :value="(caixa.saldoAnterior || 0).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})" 
                @keydown.enter="abrirCaixa">
            </div>
        </div>
        <label for="observacaoInicial">Observação inicial</label>
        <input type="text" id="observacaoInicial" class="w-80 p-5" v-model="observacaoInicial" placeholder="Observação inicial" 
        @keydown.enter="abrirCaixa" autocomplete="off" @input="mascara" maxlength="80">
        <div class="row center w-100 m-5">
            <button class="botao botao-cancelar m-auto w-40" @click="cancelar">Cancelar</button>
            <button class="botao botao-adicionar m-auto w-40" @click="abrirCaixa">Confirmar</button>
        </div>
    </div>
</template>

<script>
export default {
	name: "AberturaCaixa",

	props: ["caixa"],

	data (){
		return {
			dataAbertura: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
			usuarioCaixa: this.$store.state.Usuario.nome,
			observacaoInicial: ""
		};
	},

	methods: {
		cancelar() {
			this.usuarioCaixa = "";
			this.$emit("cancelar");
		},

		abrirCaixa() {
			if (this.dataAbertura < new Date().toLocaleDateString("en-GB").split("/").reverse().join("-") && !this.$store.state.Usuario.usuarioPrivilegiado){
				return this.$store.commit("relatarErro", {mensagem: "Data de abertura inválida. A data deve ser maior que a data atual!"});
			}
			this.$store.dispatch("abrirCaixa", {
				...this.caixa,
				dataAbertura: this.dataAbertura,
				saldoInicial: Number(this.saldoInicial),
				observacaoInicial: this.observacaoInicial
			});
			this.$emit("caixaAberto");
		},
		mascara() {
			this.observacaoInicial = this.observacaoInicial.toUpperCase();
		}
	},

	mounted() {
		setTimeout(() => {
			document.getElementById("observacaoInicial").focus();
		});
	}

};
</script>

<style scoped>
.btn {
    margin: 5px;
}
@media screen and (max-width: 767px) {
    .row.center.w-100 {
        flex-direction: column;
    }

    .btn {
        height: auto;
        margin: 5px 0px;
        width: 80%;
    }
}
</style>