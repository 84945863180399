import { doRequest } from "./http.js";
import Vue from "vue";
import { Produto, ProdutoApi } from "../models/Produto.js";

export default {
	state: {
		Produtos: [],
		ProdutosTroca: [],
		PrecoAll: [],
		produtoFilhos: [],
		Lotes: [],
		produtoSelecionado: null,
		paginaProdutosAtual: -1,
		totalPaginasProdutos: 0,
		inserirProduto: true,
		produtoEntrada: {
			produtoEncontrado: {},
			filhos: []
		},
		consultandoProduto: false,
		ProdutosCodBarraRepetido: []
	},

	actions: {
		async alterarSaldo({ commit, state }, payload) {
			try {
				state.carregando = true;
				const produto = { ...payload };
				let url = `produto/saldo?empresa=${state.Empresa.id}&saldo=${produto.saldo}`;
				url += `&cdproduto=${produto.cdproduto}&cdusuario=${state.Usuario.id}`;
				const data = await doRequest(
					url,
					{ method: "put", cache: "no-cache" },
					commit
				);
				state.carregando = false;
				if (!data.cdproduto) {
					return false;
				}
				return data;
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", {
					mensagem:
						error.mensagem ||
						(error.details
							? JSON.stringify(error.details)
							: error.message || JSON.stringify(error))
				});
				return false;
			}
		},
		async buscarProdutoPorGrupo({ state, commit }, payload) {
			let url;

			if (!payload.subg) {
				url = `produto/grupos?empresa=${state.Empresa.id}&cdgrupo=${payload.grupo}`;
			} else {
				url = `produto/grupos?empresa=${state.Empresa.id}&cdgrupo=${payload.grupo}&cdsubgrupo=${payload.subg}`;
			}
			const data = await doRequest(
				url,
				{ method: "GET", cache: "no-cache" },
				commit
			);

			if (data.mensagem) {
				commit("relatarErro", {
					mensagem: "Nenhum produto encontrado neste grupo"
				});
				return null;
			}
			return data.map(produto => new Produto(produto));
		},
		
		async buscarProdutos({ commit, state }, payload) {
			state.carregando = true;
			try {
				let url;
				const {
					checkboxPesquisa,
					buscaTodasEmpresas
				} = payload;
				if (!payload || payload == undefined) {
					url = `consultaProduto/all?empresa=${buscaTodasEmpresas ? "ALL" : state.Empresa.id}&page=0&size=25&sort=descricao&sortOrder=asc`;
					url += `&somentecomestoque=${true}`;
				} else {
					url = `consultaProduto/all?empresa=${
						buscaTodasEmpresas ? "ALL" : state.Empresa.id
					}&page=${payload.page ||
						0}&size=${payload.size || 25}&sort=descricao&sortOrder=asc`;
					url += `&somentecomestoque=${checkboxPesquisa ? true : false}`;
				}
				const data = await doRequest(
					url,
					{ method: "get", cache: "no-cache" },
					commit
				);
				state.carregando = false;
				if (data.content) {
					return commit("atualizarListaProdutos", data);
				}
				commit("atualizarListaProdutos", {
					content: [],
					page: -1,
					totalPages: 0
				});
			} catch (error) {
				console.log(error);
				commit("atualizarListaProdutos", {
					content: [],
					page: -1,
					totalPages: 0
				});
			}
			state.carregando = false;
		},

		async buscarFilhos({ commit, state }, payload) {
			try {
				let url = `produto/filhos?empresa=${payload.empresa || state.Empresa.id}&id=${payload.pai}&page=0&size=1000&sort=codinter&sortOrder=asc`;
				const data = await doRequest(
					url,
					{ method: "get", cache: "no-cache" },
					commit
				);
				if (data.content) {
					return commit("atualizarListaFilhos", data);
				}
				commit("atualizarListaFilhos", {
					content: [],
					page: -1,
					totalPages: 0
				});
			} catch (error) {
				console.log(error);
				commit("atualizarListaFilhos", {
					content: [],
					page: -1,
					totalPages: 0
				});
			}
		},

		async getFilhos({ commit, state }, payload) {
			let url = `produto/filhos?empresa=${state.Empresa.id}&id=${payload.pai}&page=0&size=25&sort=codinter&sortOrder=asc`;
			const data = await doRequest(
				url,
				{ method: "GET", cache: "no-cache" },
				commit
			);
			if (!data.content) {
				return;
			} else {
				return data;
			}
		},
		async buscarNCM($store, payload){
			const query = payload;

			const url = `auxncm?cdorigem=0&query=${query}`;

			const data = await doRequest(url, {method: "GET", cache: "no-cache"}, $store.commit);
			if(!data) return;
			if(data && data.mensagem) return;
			return data.content;
		},

		async filtrarProdutosParaEntrada({ state}, payload) {
			state.carregando = true;
			try {
				const {
					Cdreferencia,
					codbarraFornecedor,
				} = payload;
				let url = "entrada/localiza-referencia-entrada";
				url += `?referencia=${Cdreferencia || null}`;
				url += `&codbarra=${codbarraFornecedor || null}`;
				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache"
				});
				if (!payload.carregando) {
					state.carregando = false;
				}
				if (data.mensagem) {
					state.produtoEntrada = {
						produtoEncontrado: null,
						filhos: null,
					};
					return state.produtoEntrada;
				}
				if (data && data[0]?.produtoEncontrado) {
					const prod = data[0]?.produtoEncontrado;
					if(data.length > 0) {
						prod.outrosProdutos = [];
						data.forEach((p, index) => {
							if (index !== 0) {
								prod.outrosProdutos.push(p);
							}
						});
					}
					state.produtoEntrada.produtoEncontrado = new Produto(prod);
					if(data[0]?.filhos?.length > 0) {
						state.produtoEntrada.filhos = data[0]?.filhos.map(prod => new Produto(prod));
					}
					return state.produtoEntrada;
				}
				state.produtoEntrada = state.produtoEntrada = {
					produtoEncontrado: null,
					filhos: null,
				};
				return state.produtoEntrada;
			} catch (error) {
				console.log(error);
			}
		},
		// async filtrarProdutosParaEntrada({ commit, state }, payload) {
		// 	console.log("aqui");
		// 	state.carregando = true;
		// 	try {
		// 		const {
		// 			Cdreferencia,
		// 			codbarraFornecedor,
		// 			cdfornecedor
		// 		} = payload;
		// 		let url = "produto/filterCodBarraFornecedor?";
		// 		if (Cdreferencia) {
		// 			url += `Cdreferencia=${Cdreferencia || null}`;
		// 		}
		// 		if (cdfornecedor) {
		// 			url += `&CdFornecedor=${cdfornecedor || null}`;
		// 		}
		// 		if (codbarraFornecedor) {
		// 			url += `&codbarraFornecedor=${codbarraFornecedor || null}`;
		// 		}
		// 		url += "&page=0&size=25&sort=descricao&sortOrder=asc";
		// 		url += `&empresa=${state.Empresa.id}&somentecomestoque=false`;
		// 		const data = await doRequest(
		// 			url,
		// 			{ method: "get", cache: "no-cache" },
		// 			commit
		// 		);
		// 		if (!payload.carregando) {
		// 			state.carregando = false;
		// 		}
		// 		if (data.mensagem) {
		// 			commit("atualizarListaProdutos", {
		// 				content: [],
		// 				page: -1,
		// 				totalPages: 0
		// 			});
		// 			return commit("selecionarProduto", "naoencontrado");
		// 		}
		// 		if (data.content) {
		// 			return commit("atualizarListaProdutos", data);
		// 		}
		// 		commit("atualizarListaProdutos", {
		// 			content: [],
		// 			page: -1,
		// 			totalPages: 0
		// 		});
		// 	} catch (error) {
		// 		commit("atualizarListaProdutos", {
		// 			content: [],
		// 			page: -1,
		// 			totalPages: 0
		// 		});
		// 	}
		// 	state.carregando = false;
		// },

		async filtrarProdutos({ commit, dispatch, state }, payload) {
			state.carregando = true;
			try {
				const {
					query,
					page,
					todos,
					Cdreferencia,
					cdProduto,
					codbarra,
					descricao,
					checkboxPesquisa,
					buscaTodasEmpresas,
					size = 25
				} = payload;
				if ((!query || query === undefined) && !Cdreferencia) {
					return await dispatch("buscarProdutos", payload);
				}
				let url;
				if (todos) {
					url = `consultaProduto/filterInAll?empresa=${buscaTodasEmpresas ? "ALL" : state.Empresa.id}`;
					url += `&page=${page ||
						0}&query=${query}&size=${size}&sort=descricao&sortOrder=asc`;
				
				} else {
					url = `consultaProduto/filterInFields?empresa=${buscaTodasEmpresas ? "ALL" : state.Empresa.id}`;
					if (Cdreferencia) {
						url += `&Cdreferencia=${Cdreferencia || null}`;
					}
					if (cdProduto) {
						if (
							state.Empresa.prefixcodproduto &&
							payload.substring(0, 2) == "99"
						) {
							url += `&cdProduto=${
								state.Empresa.prefixcodproduto
							}${cdProduto || null}`;
						} else {
							url += `&cdProduto=${cdProduto || null}`;
						}
					}
					if (codbarra) {
						url += `&codbarra=${`${codbarra}`.substring(0, 12) ||
							null}`;
					}
					if (descricao) {
						url += `&descricao=${descricao || null}`;
					}
					
					url += `&page=${page ||
						0}&size=${size}&sort=descricao&sortOrder=asc`;
				}
				
				url += `&somentecomestoque=${checkboxPesquisa || false}`;
				const data = await doRequest(
					url,
					{ method: "get", cache: "no-cache" },
					commit
				);
				state.carregando = false;
				if (data.mensagem) {
					commit("atualizarListaProdutos", {
						content: [],
						page: -1,
						totalPages: 0
					});
					// return commit("selecionarProduto", "naoencontrado");
					return;
				}
				if (data.content) {
					return commit("atualizarListaProdutos", data);
				}
				commit("atualizarListaProdutos", {
					content: [],
					page: -1,
					totalPages: 0
				});
			} catch (error) {
				commit("atualizarListaProdutos", {
					content: [],
					page: -1,
					totalPages: 0
				});
			}
			state.carregando = false;
		},

		async buscarProdutoPorId({ state, commit }, payload) {
			if (!payload || payload === undefined || payload == 0) {
				return (state.produtoSelecionado = null);
			}
			try {
				let url = `produto?empresa=${state.Empresa.id}&sortOrder=asc`;
				if (
					state.Empresa.prefixcodproduto &&
					payload.substring(0, 2) == "99"
				) {
					url += `&id=${state.Empresa.prefixcodproduto}${payload}`;
				} else {
					url += `&id=${payload || null}`;
				}
				const data = await doRequest(
					url,
					{ method: "get", cache: "no-cache" },
					commit
				);
				if (data.message) {
					commit("relatarErro", {
						mensagem: JSON.stringify(data.details || data.message)
					});
					return null;
				}
				if (data.mensagem) {
					commit("selecionarProduto", "naoencontrado");
					return null;
				}
				if(data?.saldoOutrasEmpresa.length > 0){
					for(let i=0; i < data.saldoOutrasEmpresa.length; i++){
						const prod = data.saldoOutrasEmpresa[i];
						if(prod.saldo > 0){
							if(!data?.vlrvista){
								data.vlrvista = prod?.vlrvista;
								data.pontoequi = prod?.pontoequi; 
								state.produtoSelecionado = new Produto(data);
								return state.produtoSelecionado;
							}
						}
						if(data.cdempresa === prod.cdempresa) {
							data.pontoequi = prod.pontoequi;
						}
					}
				}
				if (!data.descricao || data.pontoequi <= 0 || !data.pontoequi) {
					commit("selecionarProduto", "cadastroincorreto");
					return null;
				}
				
				state.produtoSelecionado = new Produto(data);
				return state.produtoSelecionado;
			} catch (error) {
				state.produtoSelecionado = null;
				return null;
			}
		},
		async selecionarProdutoPorEmpresa({state, commit}, payload){
			const { id, cdempresa } = payload;
			if (!id || id === undefined || id == 0) {
				return (state.produtoSelecionado = null);
			}
			try {
				let url = `produto?empresa=${cdempresa ? cdempresa : state.Empresa.id}&sortOrder=asc&tipo=V&id=${id}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);

				if(data.cdproduto){
					state.produtoSelecionado = new Produto(data);
					return state.produtoSelecionado;
				}
				if (data.mensagem) {
					commit("selecionarProduto", "naoencontrado");
				}
				return null;
			} catch (error) {
				console.log(error);
				state.produtoSelecionado = null;
				return state.produtoSelecionado;
			}
		},
		async selecionarProdutoPorId({ state, commit }, payload) {
			const { id } = payload;
			if (!id || id === undefined || id == 0) {
				return (state.produtoSelecionado = null);
			}
			try {
				let url = `produto?empresa=${state.Empresa.id}&sortOrder=asc&tipo=V`;
				if (
					state.Empresa.prefixcodproduto &&
					id.substring(0, 2) == "99"
				) {
					url += `&id=${state.Empresa.prefixcodproduto}${id}`;
				} else {
					url += `&id=${id || null}`;
				}
				const data = await doRequest(
					url,
					{ method: "get", cache: "no-cache" },
					commit
				);
				if (data.cdproduto) {
					state.produtoSelecionado = new Produto(data);
					return state.produtoSelecionado;
				}
				setTimeout(() => {
					commit("relatarErro", {
						mensagem:
							data.mensagem ||
							(data.details
								? JSON.stringify(data.details)
								: data.message || JSON.stringify(data)
							)
					});
				}, 50);
				return null;
			} catch (error) {
				return (state.produtoSelecionado = null);
			}
		},
		// async buscarProdutoEmOutraEmpresa({state, commit}, payload){
		// 	try {
		// 		const { id, empresa } = payload;
		// 		state.carregando = true;
		// 		let url = `produto?empresa=${empresa}&id=${id}`;
		// 		const data = await doRequest(url, {
		// 			method: "get",
		// 			cache: "no-cache"
		// 		},
		// 		commit);
		// 		state.carregando = false;
		// 		if(data.message){
		// 			return commit("relatarErro", {
		// 				mensagem: JSON.stringify(data.details | data.message)
		// 			});
		// 		}
		// 		if(data){
		// 			return data;
		// 		}

		// 	} catch (error) {
		// 		console.log(error);
		// 		return null;
		// 	}
		// },
		async buscarProdutoPorIdAllEmp({ state, commit }, payload) {
			try {
				state.carregando = true;
				let url = "produto/allEmpresas";
				if (
					state.Empresa.prefixcodproduto &&
					payload.substring(0, 2) == "99"
				) {
					url += `?id=${state.Empresa.prefixcodproduto}${payload}`;
				} else {
					url += `?id=${payload}`;
				}
				const data = await doRequest(
					url,
					{ method: "get", cache: "no-cache" },
					commit
				);
				state.carregando = false;
				if (data.message) {
					return commit("relatarErro", {
						mensagem: JSON.stringify(data.details || data.message)
					});
				}
				if (data.length > 0) {
					return commit("atualizarProdutosAll", data);
				}
			} catch (error) {
				return commit("atualizarProdutosAll", []);
			}
		},

		async buscarProdutoPorCodigoBarras({ state, commit }, payload) {
			if (!payload || payload === undefined || payload == 0) {
				return (state.produtoSelecionado = null);
			}
			try {
				const url = `produto/codigoBarra?CodBarra=${`${payload}`}&empresa=${state.Empresa.id}`;
				const data = await doRequest(
					url,
					{ method: "get", cache: "no-cache" },
					commit
				);
				if (data?.message) {
					
					commit("relatarErro", {
						mensagem: JSON.stringify(data.details || data.message)
					});
					return null;
				}
				if (data?.mensagem) {
					// commit("selecionarProduto", "naoencontrado");
					return null;
				}
				if(data?.length > 1) {
					state.ProdutosCodBarraRepetido = data?.map(produto => new Produto(produto));
					return "produtosrepetidos";
				}
				if (!data[0].descricao || data[0].pontoequi <= 0 || !data[0].pontoequi) {
					commit("selecionarProduto", "cadastroincorreto");
					return null;
				}
				state.produtoSelecionado = new Produto(data[0]);
				return new Produto(data[0]);
			} catch (error) {
				state.produtoSelecionado = null;
				return null;
			}
		},
		async verificarVariacaoProduto({ state, commit }, payload) {
			const { cdproduto } = payload;

			const url = `venda/findByProdutoVariacao?empresa=${state.Empresa.id}&cdproduto=${cdproduto}`;
			const data = await doRequest(
				url,
				{ method: "GET", cache: "no-cache" },
				commit
			);
			if (data) return data;
			return;
		},
		async verificaProdutoPorCodigoBarras({ state, commit }, payload) {
			try {
				const url = `produto/codigoBarra?CodBarra=${`${payload}`.substring(
					0,
					12
				)}&empresa=${state.Empresa.id}`;
				const data = await doRequest(
					url,
					{ method: "get", cache: "no-cache" },
					commit
				);
				if (data.cdproduto) return new Produto(data);
				return false;
			} catch (error) {
				return false;
			}
		},

		async cadastrarProduto({ commit, state, dispatch }, payload) {
			try {
				state.carregando = true;
				const cdEmpresa = state.Empresa.id;
				const produto = new ProdutoApi(payload);
				if (produto.codbarra) {
					const cadastrado = await dispatch("verificaProdutoPorCodigoBarras", produto.codbarra);
					if (cadastrado) {
						if (cadastrado.id)
							throw new Error(
								`Existe o produto cód. ${cadastrado.id} ${cadastrado.descricao} com o mesmo código de barras. Verifique!`
							);
					}
				}
				const body = JSON.stringify(produto);
				const url = `produto?cdempresa=${cdEmpresa}`;
				const data = await doRequest(
					url,
					{ body, method: "post", cache: "no-cache" },
					commit
				);
				if (!payload.carregando) {
					state.carregando = false;
				}
				if (data.cdproduto) {
					commit("selecionarProduto", data);
					return new Produto(data);
				} else {
					throw new Error((data.mensagem || JSON.stringify(data.details || data)));
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", { mensagem: error.message });
				return error;
			}
		},

		async atualizarProduto({ commit, state, dispatch }, payload) {
			try {
				state.carregando = true;
				const produto = new ProdutoApi(payload);
				if (produto.codbarra) {
					const cadastrado = await dispatch(
						"verificaProdutoPorCodigoBarras",
						produto.codbarra
					);
					if (cadastrado) {
						if (cadastrado.id != produto.cdproduto)
							throw new Error(
								`Existe o produto cód. ${cadastrado.id} ${cadastrado.descricao} com o mesmo código de barras. Verifique!`
							);
					}
				}
				const body = JSON.stringify(produto);
				let url = `produto?cdproduto=${payload.cdproduto}`;
				url += `&cdusuario=${state.Usuario.id}`;
				const data = await doRequest(
					url,
					{ body, method: "put", cache: "no-cache" },
					commit
				);
				if (!payload.carregando) {
					state.carregando = false;
				}
				if (data.cdproduto) {
					commit("selecionarProduto", data);
					await dispatch("selecionarProdutoPorId", {id: payload.cdproduto});
					return new Produto(data);
				} else {
					commit("relatarErro", { mensagem: data.mensagem || JSON.stringify(data.details || data) });
					return data;
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", { mensagem: error.message });
				return error;
			}
		},

		async salvartabelapreco({ state, commit, dispatch }, payload) {
			try {
				const url = "produto";
				state.carregando = true;
				const body = JSON.stringify(
					new ProdutoApi({
						...state.produtoSelecionado,
						cdlinha: state.produtoSelecionado.cdlinha
							? state.produtoSelecionado.cdlinha.cdgrupo
							: state.produtoSelecionado.cdgrupo,
						cdgrupo: state.produtoSelecionado.cdgrupo
							? state.produtoSelecionado.cdgrupo.cdsubgrupo
							: state.produtoSelecionado.cdsubgrupo,
						formacaoPreco: payload
					})
				);
				const data = await doRequest(
					url,
					{ body, method: "post", cache: "no-cache" },
					commit
				);
				state.carregando = false;
				if (!data) {
					return commit("relatarErro", {
						mensagem: "Não foi possivel cadastrar o produto."
					});
				}
				if (data.message) {
					return commit("relatarErro", {
						mensagem: JSON.stringify(data.details || data.message)
					});
				}
				await dispatch("buscarProdutoPorIdAllEmp", data.cdproduto);
				await dispatch("buscarProdutos");
				await dispatch("selecionarProdutoPorId", {id:payload.cdproduto});
			} catch (error) {
				console.log(error);
				return commit("relatarErro", {
					mensagem: JSON.stringify(error)
				});
			}
		},

		async formarPreco({ state, commit, dispatch }, payload) {
			try {
				state.carregando = true;
				let url = `produto/formaPreco?cdproduto=${payload.id}`;
				const preco = { ...payload.formacaoPreco };
				const body = JSON.stringify({ ...preco });
				const data = await doRequest(
					url,
					{ body, method: "post", cache: "no-cache" },
					commit
				);
				state.carregando = false;
				if (
					data.mensagem !=
						"Formação de preço cadastrado com sucesso." ||
					data.message
				) {
					throw new Error(
						data.mensagem || JSON.stringify(data.details)
					);
				}
				if (!payload.manterItem) {
					await dispatch("selecionarProdutoPorId", {id: payload.id});
				}
			} catch (error) {
				console.log(error);
				return commit("relatarErro", {
					mensagem: error.message || JSON.stringify(error)
				});
			}
		},

		async salvarvariacoes({ state, commit, dispatch }, payload) {
			try {
				state.carregando = true;
				const cdEmpresa = state.Empresa.id;
				for (const filhos of payload.produtoFilhos) {
					await new Promise((res, rej) => {
						setTimeout(async () => {
							let url = `produto?cdempresa=${cdEmpresa}`;
							const body = JSON.stringify({
								...new ProdutoApi(filhos)
								// formacaoPreco: payload.formacaoPreco
							});
							let resposta;
							if (filhos.cdproduto) {
								url += `&cdproduto=${filhos.cdproduto}`;
								url += `&cdusuario=${state.Usuario.id}`;
								resposta = await doRequest(
									url,
									{ body, method: "put", cache: "no-cache" },
									commit
								);
							} else {
								resposta = await doRequest(
									url,
									{ body, method: "post", cache: "no-cache" },
									commit
								);
							}

							if (!resposta) {
								state.carregando = false;
								rej(
									"Não foi possivel cadastrar o produto. Rejeitado"
								);
								return commit("relatarErro", {
									mensagem:
										"Não foi possivel cadastrar o produto."
								});
							}
							if (resposta.message) {
								state.carregando = false;
								rej(
									"Não foi possivel cadastrar o produto. Rejeitado na mensagem"
								);
								return commit("relatarErro", {
									mensagem: JSON.stringify(
										resposta.details || resposta.message
									)
								});
							}
							res(true);
						}, 500);
					});
				}
				await dispatch("buscarFilhos", {
					pai: state.produtoSelecionado.id
				});
				state.carregando = false;
			} catch (error) {
				console.log(error);
				return commit("relatarErro", {
					mensagem: JSON.stringify(error)
				});
			}
		},

		async deletarKit({ commit }, payload) {
			try {
				let url;
				url = `produto/kit?cdempresa=${payload.cdempresa}&cdproduto=${payload.cdproduto}&cdprodutokit=${payload.cdprodutokit}`;
				const data = await doRequest(
					url,
					{ method: "delete", cache: "no-cache" },
					commit
				);
				if (data.status == 404) {
					return true;
				} else if (data.mensagem) {
					return true;
				}
				return false;
			} catch (error) {
				commit("relatarErro", { mensagem: JSON.stringify(error) });
				return false;
			}
		},

		async buscarKitId({ state, commit }, payload) {
			if (!payload || payload === undefined || payload == 0) {
				return null;
			}
			try {
				const url = `produto?empresa=${state.Empresa.id}&id=${payload}&sortOrder=asc`;
				const data = await doRequest(
					url,
					{ method: "get", cache: "no-cache" },
					commit
				);
				return new Produto(data);
			} catch (error) {
				console.log(error);
				return null;
			}
		},

		async salvarKits({ commit, state }, payload) {
			try {
				state.carregando = true;
				const url = "produto/kit";
				const body = JSON.stringify(payload);
				const data = await doRequest(
					url,
					{ body, method: "post", cache: "no-cache" },
					commit
				);
				state.carregando = false;
				if (data.mensagem != "Kit cadastrado com sucesso.") {
					commit("relatarErro", { mensagem: JSON.stringify(data) });
				} else {
					commit("relatarErro", { mensagem: data.mensagem, sucess: true});
				}
			} catch (error) {
				console.log(error);
				return commit("relatarErro", {
					mensagem: JSON.stringify(error)
				});
			}
		},

		async buscarKitCadastrado({ commit }, payload) {
			if (!payload || payload === undefined || payload == 0) {
				return null;
			}
			try {
				const url = `produto/kit?produto=${payload}`;
				const data = await doRequest(
					url,
					{ method: "get", cache: "no-cache" },
					commit
				);
				if (data.length > 0) {
					return data.map(kit => new Produto(kit));
				} else if (data.length == 0) {
					return new Produto(data);
				}
			} catch (error) {
				console.log(error);
				return null;
			}
		},

		async salvarLote({commit,state},payload){
			try{
				state.carregando = true;
				const url = "produto-lote";
				const body = JSON.stringify(payload);
				const data = await doRequest(url, {body, method: "post", cache: "no-cache"}, commit);
				state.carregando = false;
				if(data.cdlote){
					commit("relatarErro", { mensagem: "Lote cadastrado com sucesso!", sucess: true});
				} else {
					commit("relatarErro", { mensagem: JSON.stringify(data) });
				}

				return data;
			}
			catch (error) {
				console.log(error);
				return commit("relatarErro", {
					mensagem: JSON.stringify(error)
				});
			}
		},

		async alterarLote({commit,state}, payload){
			try{
				const { cdCompra, cdProduto, cdEmpresa, cdlote } = payload;
				state.carregando = true;
				let url = "produto-lote?";
				url += `${cdCompra ? `cdcompra=${cdCompra}` : ""}`;
				url += `${cdProduto ? `&cdproduto=${cdProduto}` : ""}`;
				url += `${cdEmpresa ? `&empresa=${cdEmpresa}` : ""}`;
				url += `${cdlote ? `&cdlote=${cdlote}` : ""}`;
				
				const body = JSON.stringify(payload);
				const data = await doRequest(url, {body, method: "put", cache: "no-cache"}, commit);
				if(data.lote){
					commit("relatarErro", { mensagem: "Lote alterado com sucesso!", sucess: true});
				} else {
					commit("relatarErro", { mensagem: JSON.stringify(data) });
				}
				state.carregando = false;
			}
			catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", { mensagem: error.message });
				return error;
			}
		},

		async buscarLotes({commit,state},payload) {
			try{
				state.carregando = true;
				let url = `produto-lote/filter?cdproduto=${payload.cdProduto}&empresa=${payload.empresa}`;
				if(payload.saldo) url += `&comSaldo=${payload.saldo}`;
				const data = await doRequest(url,{method: "get", cache: "no-cache"},commit);
				let payloadCommit = !data.mensagem ? data : [];
				commit("atualizarListaLotes", payloadCommit);
				state.carregando = false;
				if(data && !data.mensagem) {
					commit("atualizarListaLotes", data);
					return data;
				}
				commit("atualizarListaLotes", []);
				return null;
				
				
			}
			catch (error){
				state.carregando = false;
				console.log(error);
				return null;
			}
		},
		
		async removerLote({commit,state},payload) {
			try {
				let url;
				url = `produto-lote?cdproduto=${payload.cdproduto}&empresa=${payload.cdempresa}&cdlote=${payload.cdlote}`;
				const data = await doRequest(
					url,
					{ method: "delete", cache: "no-cache" },
					commit
				);
				if (data.status == 404) {
					return true;
				} else if (data.mensagem) {
					return true;
				}
				return false;
			}
			catch(error) {
				state.carregando = false;
				console.log(error);
				return null;
			}
		},

		async buscarRelatorioLotesVencimento({commit,state},payload){
			try{
				state.carregando = true;
				const url = `produto-lote/vencimentos?dataVencF=${payload.dataf}&dataVencI=${payload.datai}`;
				const data = await doRequest(url,{method: "get", cache: "no-cache"},commit);
				let payloadCommit = !data.mensagem ? data : [];
				commit("atualizarListaLotes", payloadCommit);
				state.carregando = false;
				return data;
			}
			catch (error){
				state.carregando = false;
				console.log(error);
				return null;
			}
		},

		async cadastrarAgrupamentoProduto({ commit, state }, payload) {
			try {
				state.carregando = true;
				let url = `produto/agrupamento?cdproduto=${payload.cdproduto}&empresa=${payload.cdempresa}`;
				url += `&cdfornecedor=${payload.cdfornecedor}&cdusuario=${state.Usuario.id}`;
				const body = JSON.stringify(payload.agrupamento);
				const data = await doRequest(
					url,
					{ method: "post", body, cache: "no-cache" },
					commit
				);
				state.carregando = false;
				if (data ? !data.cdproduto : true) {
					commit("relatarErro", {
						mensagem: data
							? data.mensagem
								? data.mensagem
								: data.details || JSON.stringify(data)
							: ""
					});
					return null;
				} else {
					return new Produto(data);
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", { mensagem: error.message });
				return null;
			}
		},

		async buscarAgrupamento({ commit, state }, payload) {
			try {
				state.carregando = true;
				const url = `produto/agrupamento?cdagrupamento=${payload.cdagrupamento}&empresa=${state.Empresa.id}`;
				const data = await doRequest(
					url,
					{ method: "get", cache: "no-cache" },
					commit
				);
				if (!payload.carregando) {
					state.carregando = false;
				}
				if (data ? !data.length : true) {
					throw new Error(
						data
							? data.mensagem
								? data.mensagem
								: data.details || JSON.stringify(data)
							: ""
					);
				} else {
					return data;
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", { mensagem: error.message });
				return null;
			}
		}
	},

	mutations: {
		handleConsultandoProduto(state, payload) {
			state.consultandoProduto = payload;
		},
		// atualizarProdutoEntrada(state, payload) {
		// 	state.produtoEntrada = null;
		// 	if(payload?.produtoEncontrado) {
		// 		state.produtoEntrada.produtoEncontrado = new Produto(payload?.produtoEncontrado);
		// 	}
		// 	if(payload?.filhos) {
		// 		state.produtoEntrada.filhos = payload.filhos.map(filho => new Produto(filho));
		// 	}
		// },

		atualizarListaLotes(state, payload) {
			state.Lotes = payload;
		},

		atualizarListaProdutos(state, payload) {
			state.Produtos = [];
			state.Produtos = payload.content.map(produto => {
				return new Produto(produto);
			});
			state.paginacaoProduto = {
				totalProdutos: payload.totalElements,
			},
			state.paginaProdutosAtual = payload.page;
			state.totalPaginasProdutos = payload.totalPages;
		},
		atualizarListaProdutosState(state, payload){
			state.Produtos = [];
			state.Produtos = payload.Produtos.map(produto => produto);

			state.paginacaoProduto = {};
			state.paginacaoProduto = {
				totalProdutos: payload.Paginacao.totalElements
			};
			state.paginaProdutosAtual = payload.Paginacao.page;
			state.totalPaginasProdutos = payload.Paginacao.totalPages;
		},
		selecionarProduto(state, payload) {
			state.produtoSelecionado = null;
			if (!payload) {
				return;
			} else if (typeof payload == "string" || payload?.constructor?.name === "Produto") {
				state.produtoSelecionado = payload;
				return ;
			}
			state.produtoSelecionado = new Produto({
				...payload,
				vlrvista: payload.valorVista || payload.vlrvista,
				pontoequi: payload.valorPrazo || payload.pontoequi,
				saldo: payload?.produto?.saldo || payload?.saldo,
			});
		},

		selecionarProdutoState(state, payload) {
			state.produtoSelecionado = null;
			if (!payload) {
				return;
			}
			state.produtoSelecionado = payload;
		},

		atualizarProdutosAll(state, payload) {
			Vue.set(state, "PrecoAll", [...payload]);
		},

		atualizarListaFilhos(state, payload) {
			state.produtoFilhos = null;
			state.produtoFilhos = payload.content.map(produto => new Produto(produto));
		}
	}
};
