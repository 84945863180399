import { doRequest } from "./http";

export default {
	state: {
		Cfops: []
	},

	actions: {
		async buscarCfop({commit}) {
			try {
				let url = "cfop/all";
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.length) {
					commit("atualizarListaCfop", data);
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
		},

		async buscarUnicoCFOP({commit},payload) {
			try {                
				let url = `cfop?codigo=${payload}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.cfop) {
					commit("atualizarListaCfop", data);
					return data;
				}
				return null;
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
		},
		// async salvarBandeira({commit, dispatch },payload) {
		//     try {                
		//         const body = JSON.stringify({
		//             adm: payload.adm,
		//             txcredito: payload.txcredito,
		//             txdebito: payload.txdebito,                    
		//         });
		//         let url = "bandeira";
		//         const data = await doRequest(url, {method: "post", cache: "no-cache", body}, commit);
		//         if (data.codigo) {
		//             dispatch("buscarBandeiras");
		//         }
		//     } catch (error) {
		//         console.log(error);
		//         commit("relatarErro", {mensagem: JSON.stringify(error)});
		//     }
		// },                
		// async editarBandeira({commit, dispatch },payload) {
		//     try {                
		//         const body = JSON.stringify({
		//             adm: payload.adm,
		//             txcredito: payload.txcredito,
		//             txdebito: payload.txdebito,                    
		//         });
		//         let url = `bandeira?codigo=${payload.codigo}`;
		//         const data = await doRequest(url, {method: "put", cache: "no-cache", body}, commit);
		//         if (data.codigo) {
		//             dispatch("buscarBandeiras");
		//         }
		//     } catch (error) {
		//         console.log(error);
		//         commit("relatarErro", {mensagem: JSON.stringify(error)});
		//     }
		// },                
        
		// async deletarBandeira({commit, dispatch },payload) {
		//     try {                                
		//         let url = `bandeira/excluirBanceira?codigo=${payload.codigo}`;
		//         const data = await doRequest(url, {method: "delete", cache: "no-cache"}, commit);
		//         if (data.mensagem == "Não pode excluir, pois existe lançamento no financeiro") {
		//             return commit("relatarErro", {mensagem: data.mensagem});                    
		//         }
		//         dispatch("buscarBandeiras");
		//     } catch (error) {
		//         console.log(error);
		//         commit("relatarErro", {mensagem: JSON.stringify(error)});
		//     }
		// },                
	},

	mutations: {
		atualizarListaCfop(state, payload) {
			state.Cfops = null;
			state.Cfops = payload;            
		},
	}
};