import {doRequest} from "./http.js";
import {CompraApi} from "../models/Compra.js";
import {FormacaoPrecoCompra} from "../models/Produto.js";
import {Entrada, EntradaAPI, XML010} from "../models/Entrada.js";
import { EntradaFinanceiroAPI } from "../models/EntradaFinanceiro.js";
import { EntradaEstoqueAPI, EntradaEstoque } from "../models/EntradaItem.js";

export default {
	state: {
		filtrocompras: [],
		Entrada: {},
		Entradas: {},
		xmlIntegracao: []
	},

	actions: {
		async lancarEntrada({commit, state, dispatch}, payload) {
			try {
				state.carregando = true;
				if (!payload.fornecedor.id){
					const existe = await dispatch("filtrarcnpj",{cnpj: this.compraXML.fornecedor.cnpj});
					if (existe != false){
						payload.fornecedor.id = existe.content[0].id;
					} else {
						const cidadecad = await dispatch("buscarIbge", payload.fornecedor.ibge);
						const fornecedorcad = await dispatch("adicionarCliente", {
							cidade: cidadecad.erro ? {
								cdcidade: 0,
								codibge: payload.fornecedor.ibge,
								nomecidade: payload.fornecedor.cidade,
								uf: payload.fornecedor.uf,
							} : cidadecad,
							bairro: payload.fornecedor.bairro,
							celular: payload.fornecedor.celular,
							cep: payload.fornecedor.cep,
							classificacao: {cdclassi : 2},
							cnpj: payload.fornecedor.cnpj,
							dataCadastro: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
							email: payload.fornecedor.email,
							endereco: payload.fornecedor.endereco,
							fantasia: payload.fornecedor.fantasia,
							fone: payload.fornecedor.fone,
							ierg: payload.fornecedor.ierg,
							nome: payload.fornecedor.nome,
							numero: payload.fornecedor.numero,
							tipoPessoa: "J",
						});
						if (!fornecedorcad.id) {
							return {erro: true};
						}
						payload.fornecedor.id = fornecedorcad.id;
					}
				}

				if (!payload.transporte.id && payload.transporte.nome && payload.transporte.cnpj) {
					const existe = await dispatch("filtrarcnpj",{cnpj: this.compraXML.transportadora.cnpj});
					if (existe != false){
						payload.fornecedor.id = existe.content[0].id;
					} else {
						const cidadecad = await dispatch("buscarIbge", payload.transporte.ibge);
						const transportadora = await dispatch("adicionarCliente", {
							cidade: cidadecad.erro ? {
								cdcidade: 0,
								codibge: payload.transporte.ibge,
								nomecidade: payload.transporte.municipio,
								uf: payload.transporte.uf,
							} : cidadecad,
							bairro: payload.transporte.bairro,
							celular: payload.transporte.celular,
							cep: payload.transporte.cep,
							classificacao: {cdclassi : 3},
							cnpj: payload.transporte.cnpj,
							dataCadastro: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
							email: payload.transporte.email,
							endereco: payload.transporte.logradouro,
							fantasia: payload.transporte.fantasia,
							fone: payload.transporte.fone,
							ierg: payload.transporte.ierg,
							nome: payload.transporte.nome,
							numero: payload.transporte.numero,
							tipoPessoa: payload.transporte.cnpj.length > 15 ? "J" : "F",
						});
						payload.transporte.id = transportadora.id;
					}
				}
				const url = `entrada?cdusuario=${state.Usuario.id}`;
				payload.observacao = `Realizado por ${state.Usuario.nome} em ${new Date().toLocaleString("pt-BR")}`;
				const body = JSON.stringify(new EntradaAPI(payload));
				const data = await doRequest(url, {body, method: "post", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdcompra) {
					throw new Error(data.mensagem || JSON.stringify(data.details));
				}
				return new Entrada(data);
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.mensagem || (error.message || JSON.stringify(error))});
				return {erro: true, message: error.mensagem || (error.message || JSON.stringify(error))};				
			}
		},

		async lancarEstoqueEntrada({commit, state, dispatch}, payload) {
			try {
				if (payload.estoque) {
					let produtoCompras = [];
					for (let produto of payload.estoque){
						if (!produto.cdfornecedor) produto.cdfornecedor = payload.fornecedor.id;
						if (!produto.cdproduto){
							let produtoRetorno = null;
							await dispatch("filtrarProdutosParaEntrada", {
								Cdreferencia: produto.cdreferencia, 
								cdfornecedor: produto.cdfornecedor,
								codbarraFornecedor: produto.codbarraFornecedor,
								carregando: true
							});
							if (state?.produtoEntrada?.produtoEncontrado){
								produtoRetorno = state.produtoEntrada.produtoEncontrado;
							} else {
								produtoRetorno = await dispatch("cadastrarProduto", {
									...produto,
									formacaoPreco: {
										[payload.cdempresa] : new FormacaoPrecoCompra(produto)
									},
									carregando: true
								});
							}
							if (!produtoRetorno.id) {
								throw new Error(produtoRetorno.mensagem || (produtoRetorno.message));
							}
							produto.cdproduto = produtoRetorno.id;
							produto.codbarra = produtoRetorno.codBarra;
							produto.cdgrupo = produtoRetorno.cdgrupo;
							produto.cdsubgrupo = produtoRetorno.cdsubgrupo;
							produto.codinter = produtoRetorno.codinter;
						}
						produtoCompras.push(produto);
					}
					const produtos = {};
					produtoCompras.forEach(prod => {
						// VERIFICA SE CDREFERENCIA E CODBARRA FORNECEDOR É IGUAL

						const posicao = prod.cdreferencia && prod.codbarraFornecedor 
							? `${prod.cdreferencia}_${prod.codbarraFornecedor}` 
							: prod.cdproduto;
						if (!produtos[posicao]) {
							produtos[posicao] = {...prod};
						} else {
							const produto_1 = produtos[prod.cdproduto];
							const produto_2 = prod;
							if(
								(produto_1?.cdreferencia && produto_1?.codbarraFornecedor) 
								&& (produto_2?.cdreferencia && produto_2.codbarraFornecedor)
							) {
								const total_produto_1 = (produto_1?.quantidade || 0) * (produto_1?.vlrunitario || 0);
								const total_produto_2 = (produto_2?.quantidade || 0) * (produto_2?.vlrunitario || 0);
								const qtdTotal = (produto_1?.quantidade || 0) + (produto_2?.quantidade || 0); 
								const result = (total_produto_1 + total_produto_2) / qtdTotal;
								produto_1.quantidade = qtdTotal;
								produto_1.vlrunitario = Number(result);
							}
						}
					});
					payload.estoque = [];
					Object.keys(produtos).forEach((key, index) => {
						payload.estoque.push({...produtos[key], cdseq: (index +1)});
					});
				}
				const url = `entrada/estoque
					?cdusuario=${state.Usuario.id}
					&cdcompra=${payload.cdcompra}
					&empresa=${payload.cdempresa}
					&cdfornecedor=${payload.fornecedor.id}
					&tipo=${payload.tipo}`;
				const body = JSON.stringify(new EntradaAPI(payload));
				const data = await doRequest(url, {body, method: "post", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdcompra) {
					throw new Error(data.mensagem || JSON.stringify(data.details));
				}
				return new Entrada(data);
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.mensagem || JSON.stringify(error))});
				return {erro: true, mensagem: error.message || (error.mensagem || JSON.stringify(error))};
			}
		},

		async variarEstoqueEntrada ({ commit, state }, payload) {
			try {
				state.carregando = true;
				let url = `entrada/estoque/variacao?cdusuario=${state.Usuario.id}&cdcompra=${payload.cdcompra}`;
				url += `&empresa=${payload.cdempresa}&cdfornecedor=${payload.cdfornecedor}&tipo=${payload.tipo}`;
				url += `&cdproduto=${payload.cdproduto}&cdseq=${payload.cdseq}&cdreferencia=${payload.cdreferencia}`;
				const body = JSON.stringify(payload.estoque.map(item => new EntradaEstoqueAPI(item)));
				const data = await doRequest(url, {body, method: "put", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.length) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				return data.map(item => new EntradaEstoque(item));
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.mensagem || (error.message || JSON.stringify(error))});
				return {erro: true, message: error.mensagem || (error.message || JSON.stringify(error))};
			}
		},

		async salvarItemEstoqueEntrada ({ commit, state }, payload) {
			try {
				state.carregando = true;
				let url = `entrada/estoque?cdusuario=${state.Usuario.id}&cdcompra=${payload.cdcompra}`;
				url += `&empresa=${payload.cdempresa}&cdfornecedor=${payload.cdfornecedor}&tipo=${payload.tipo}`;
				url += `&cdproduto=${payload.produto.cdproduto}&cdseq=${payload.produto.cdseq}${payload.produto.cdreferencia ? `&cdreferencia=${payload.produto.cdreferencia}` : "&cdreferencia= "}`;
				const body = JSON.stringify(new EntradaEstoqueAPI(payload.produto));
				const data = await doRequest(url, {body, method: "put", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdcompra) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				return new EntradaEstoque({...data, saldo: data.produto.saldo});
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
				return {erro: true, message: error.mensagem || (error.message || JSON.stringify(error))};
			}
		},

		async lancarFinanceiroEntrada({commit, state}, payload) {
			try {
				state.carregando = true;
				let url = `entrada/financeiro?cdusuario=${state.Usuario.id}&cdcompra=${payload.cdcompra}`;
				url += `&empresa=${payload.cdempresa}&cdfornecedor=${payload.fornecedor.id}&tipo=${payload.tipo}`;
				const body = JSON.stringify(payload.financeiro.map((fin, index) => new EntradaFinanceiroAPI({...fin, parcela: fin.parcela || index+1}, payload.cdcompra)));
				const data = await doRequest(url, {body, method: "put", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdcompra) {
					throw new Error(data.mensagem || JSON.stringify(data.details));
				}
				return new Entrada(data);
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.mensagem || (error.message || JSON.stringify(error))});
				return {erro: true, message: error.mensagem || (error.message || JSON.stringify(error))};
			}
		},

		async confirmarEntrada({commit, state}, payload) {
			try {
				state.carregando = true;
				const url = `entrada/confirmar?cdusuario=${state.Usuario.id}&cdcompra=${payload.cdcompra}&empresa=${payload.cdempresa}&cdfornecedor=${payload.cdfornecedor}&tipo=${payload.tipo}`;
				const body = JSON.stringify(new EntradaAPI(payload));
				const data = await doRequest(url, {method: "put", body, cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdcompra) {
					throw new Error(data.mensagem || JSON.stringify(data.details));
				}
				return new Entrada(data);
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.mensagem || (error.message || JSON.stringify(error))});
				return {erro: true, message: error.mensagem || (error.message || JSON.stringify(error))};
			}
		},

		async atualizarEntrada({commit, state}, payload) {
			try {
				state.carregando = true;
				const url = `entrada?cdusuario=${state.Usuario.id}&cdcompra=${payload.cdcompra}&empresa=${payload.cdempresa}&cdfornecedor=${payload.cdfornecedor}&tipo=${payload.tipo}`;
				const body = JSON.stringify(new EntradaAPI(payload));
				const data = await doRequest(url, {body, method: "put", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdcompra) {
					throw new Error(data.mensagem || JSON.stringify(data.details));
				}
				return new Entrada(data);
			} catch (error) {
				state.carregando = false;
				console.log(error);
				return {erro: true, message: error.message || (error.message || JSON.stringify(error))};
			}
		},

		async cadastrarCompras({commit, state, dispatch}, payload) {
			try {
				state.carregando = true;
				if (!payload.fornecedor.id){
					const existe = await dispatch("filtrarcnpj",{cnpj: this.compraXML.fornecedor.cnpj});
					if (existe != false){
						payload.fornecedor.id = existe.content[0].id;
					} else {
						const cidadecad = await dispatch("buscarIbge", payload.fornecedor.ibge);
						const fornecedorcad = await dispatch("adicionarCliente", {
							cidade: cidadecad.erro ? {
								cdcidade: 0,
								codibge: payload.fornecedor.ibge,
								nomecidade: payload.fornecedor.cidade,
								uf: payload.fornecedor.uf,
							} : cidadecad,
							bairro: payload.fornecedor.bairro,
							celular: payload.fornecedor.celular,
							cep: payload.fornecedor.cep,
							classificacao: {cdclassi : 2},
							cnpj: payload.fornecedor.cnpj,
							dataCadastro: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
							email: payload.fornecedor.email,
							endereco: payload.fornecedor.endereco,
							fantasia: payload.fornecedor.fantasia,
							fone: payload.fornecedor.fone,
							ierg: payload.fornecedor.ierg,
							nome: payload.fornecedor.nome,
							numero: payload.fornecedor.numero,
							tipoPessoa: "J",
						});
						if (!fornecedorcad.id) {
							return {erro: true};
						}
						payload.fornecedor.id = fornecedorcad.id;
					}
				}

				if (!payload.transporte.id && payload.transporte.nome && payload.transporte.cnpj) {
					const existe = await dispatch("filtrarcnpj",{cnpj: this.compraXML.transportadora.cnpj});
					if (existe != false){
						payload.fornecedor.id = existe.content[0].id;
					} else {
						const cidadecad = await dispatch("buscarIbge", payload.transporte.ibge);
						const transportadora = await dispatch("adicionarCliente", {
							cidade: cidadecad.erro ? {
								cdcidade: 0,
								codibge: payload.transporte.ibge,
								nomecidade: payload.transporte.municipio,
								uf: payload.transporte.uf,
							} : cidadecad,
							bairro: payload.transporte.bairro,
							celular: payload.transporte.celular,
							cep: payload.transporte.cep,
							classificacao: {cdclassi : 3},
							cnpj: payload.transporte.cnpj,
							dataCadastro: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
							email: payload.transporte.email,
							endereco: payload.transporte.logradouro,
							fantasia: payload.transporte.fantasia,
							fone: payload.transporte.fone,
							ierg: payload.transporte.ierg,
							nome: payload.transporte.nome,
							numero: payload.transporte.numero,
							tipoPessoa: payload.transporte.cnpj.length > 15 ? "J" : "F",
						});
						payload.transporte.id = transportadora.id;
					}
				}
				if (payload.itens) {
					let produtoCompraItens = [];
					for (let produto of payload.itens){
						let produtoRetorno = null;
						if (!produto.cdfornecedor) produto.cdfornecedor = payload.fornecedor.id;
						if (!produto.cdproduto){
							await dispatch("filtrarProdutosParaEntrada", {
								Cdreferencia: produto.cdreferencia, 
								cdfornecedor: produto.cdfornecedor,
								codbarraFornecedor: produto.codbarraFornecedor,
								carregando: true
							});
							if (state.Produtos.length){
								produtoRetorno = await dispatch("atualizarProduto", {
									...produto,
									formacaoPreco: {
										[payload.cdempresa] : new FormacaoPrecoCompra(produto)
									},
									carregando: true
								});
							} else {
								produtoRetorno = await dispatch("cadastrarProduto", {
									...produto,
									formacaoPreco: {
										[payload.cdempresa] : new FormacaoPrecoCompra(produto)
									},
									carregando: true
								});
							}
						} else {
							produtoRetorno = await dispatch("atualizarProduto", {
								...produto,
								formacaoPreco: {
									[payload.cdempresa] : new FormacaoPrecoCompra(produto)
								},
								carregando: true
							});
						}
						if (!produtoRetorno.id) {
							throw new Error(produtoRetorno.mensagem || (produtoRetorno.details));
						}
						produto.cdproduto = produtoRetorno.id;
						produto.codbarra = produtoRetorno.codBarra;
						produto.cdgrupo = produtoRetorno.cdgrupo;
						produto.cdsubgrupo = produtoRetorno.cdsubgrupo;
						produto.codinter = produtoRetorno.codinter;
						produto.ultsaldo = produto.quantidade;
						if (produto.filhos?.length) {
							for (let prodFilho of produto.filhos) {
								produtoRetorno = null;
								if (!prodFilho.pai) prodFilho.pai = produto.cdproduto;
								prodFilho.cfop = produto.cfop;
								if (!prodFilho.cdfornecedor) prodFilho.cdfornecedor = payload.fornecedor.id;
								if (!prodFilho.cdproduto){
									produtoRetorno = await dispatch("cadastrarProduto", {
										...prodFilho,
										pai: produto.cdproduto,
										formacaoPreco: {
											[payload.cdempresa] : new FormacaoPrecoCompra(prodFilho)
										},
										carregando: true
									});
								} else  {
									produtoRetorno = await dispatch("atualizarProduto", {
										...prodFilho,
										pai: produto.cdproduto,
										prodFilho: {
											[payload.cdempresa] : new FormacaoPrecoCompra(prodFilho)
										},
										carregando: true
									});
								}
								if (!produtoRetorno.id) {
									throw new Error(produtoRetorno.mensagem || (produtoRetorno.details));
								}
								prodFilho.cdproduto = produtoRetorno.id;
								prodFilho.codbarra = produtoRetorno.codBarra;
								prodFilho.cdgrupo = produtoRetorno.cdgrupo;
								prodFilho.cdsubgrupo = produtoRetorno.cdsubgrupo;
								prodFilho.codinter = produtoRetorno.codinter;
								produtoCompraItens.push(prodFilho);
							}
						} else {
							produtoCompraItens.push(produto);
						}
					}
					payload.itens = produtoCompraItens;
					payload.itens.forEach((item, index) => {
						item.cdseq = index+1;
					});
				}
				const url = "entrada";
				payload.observacao = `Realizado por ${state.Usuario.nome} em ${new Date().toLocaleString("pt-BR")}`;
				const body = JSON.stringify(new CompraApi(payload));
				const data = await doRequest(url, {body, method: "post", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdcompra) {
					throw new Error(data.mensagem || JSON.stringify(data.details));
				}
				return new Entrada(data);
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.mensagem || (error.message || JSON.stringify(error))});
				return {erro: true, message: error.mensagem || (error.message || JSON.stringify(error))};
			}
		},

		async atualizarCompra({commit, state, dispatch}, payload) {
			try {
				state.carregando = true;
				if (!payload.fornecedor.id){
					const cidadecad = await dispatch("buscarIbge", payload.fornecedor.ibge);
					const fornecedorcad = await dispatch("adicionarCliente", {
						cidade: cidadecad.erro ? {
							cdcidade: 0,
							codibge: payload.fornecedor.ibge,
							nomecidade: payload.fornecedor.cidade,
							uf: payload.fornecedor.uf,
						} : cidadecad,
						bairro: payload.fornecedor.bairro,
						celular: payload.fornecedor.celular,
						cep: payload.fornecedor.cep,
						classificacao: {cdclassi : 2},
						cnpj: payload.fornecedor.cnpj,
						dataCadastro: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
						email: payload.fornecedor.email,
						endereco: payload.fornecedor.endereco,
						fantasia: payload.fornecedor.fantasia,
						fone: payload.fornecedor.fone,
						ierg: payload.fornecedor.ierg,
						nome: payload.fornecedor.nome,
						numero: payload.fornecedor.numero,
						tipoPessoa: "J",
					});
					if (!fornecedorcad.id) {
						return {erro: true};
					}
					payload.fornecedor.id = fornecedorcad.id;
				}

				if (!payload.transporte.id && payload.transporte.nome && payload.transporte.cnpj) {
					const cidadecad = await dispatch("buscarIbge", payload.transporte.ibge);
					const transportadora = await dispatch("adicionarCliente", {
						cidade: cidadecad.erro ? {
							cdcidade: 0,
							codibge: payload.transporte.ibge,
							nomecidade: payload.transporte.municipio,
							uf: payload.transporte.uf,
						} : cidadecad,
						bairro: payload.transporte.bairro,
						celular: payload.transporte.celular,
						cep: payload.transporte.cep,
						classificacao: {cdclassi : 3},
						cnpj: payload.transporte.cnpj,
						dataCadastro: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
						email: payload.transporte.email,
						endereco: payload.transporte.logradouro,
						fantasia: payload.transporte.fantasia,
						fone: payload.transporte.fone,
						ierg: payload.transporte.ierg,
						nome: payload.transporte.nome,
						numero: payload.transporte.numero,
						tipoPessoa: payload.transporte.cnpj.length > 15 ? "J" : "F",
					});
					payload.transporte.id = transportadora.id;
				}

				if (payload.itens) {
					let produtoCompraItens = [];
					for (let produto of payload.itens){
						let produtoRetorno = null;
						if (!produto.cdfornecedor) produto.cdfornecedor = payload.fornecedor.id;
						if (!produto.cdproduto){
							produtoRetorno = await dispatch("cadastrarProduto", {
								...produto,
								formacaoPreco: {
									[payload.cdempresa] : new FormacaoPrecoCompra(produto)
								}
							});
						} else {
							produtoRetorno = await dispatch("atualizarProduto", {
								...produto,
								formacaoPreco: {
									[payload.cdempresa] : new FormacaoPrecoCompra(produto)
								}
							});
						}
						if (!produtoRetorno.id) {
							throw new Error(produtoRetorno.mensagem || (produtoRetorno.details));
						}
						produto.cdproduto = produtoRetorno.id;
						produto.codbarra = produtoRetorno.codBarra;
						produto.codinter = produtoRetorno.codinter;
						produto.cdgrupo = produtoRetorno.cdgrupo;
						produto.cdsubgrupo = produtoRetorno.cdsubgrupo;
						produto.ultsaldo = produto.quantidade;
						if (produto.filhos?.length) {
							for (let prodFilho of produto.filhos) {
								produtoRetorno = null;
								prodFilho.cfop = produto.cfop;
								if (!prodFilho.cdfornecedor) prodFilho.cdfornecedor = payload.fornecedor.id;
								prodFilho.formacaoPreco = null;
								if (!prodFilho.cdproduto){
									produtoRetorno = await dispatch("cadastrarProduto", {
										...prodFilho,
										pai: produto.cdproduto,
										cdpai: produto.cdproduto,
										formacaoPreco: {
											[payload.cdempresa] : new FormacaoPrecoCompra(prodFilho)
										}
									});
								} else {
									produtoRetorno = await dispatch("atualizarProduto", {
										...prodFilho,
										pai: produto.cdproduto,
										cdpai: produto.cdproduto,
										formacaoPreco: {
											[payload.cdempresa] : new FormacaoPrecoCompra(prodFilho)
										}
									});
								}
								if (!produtoRetorno.id) {
									throw new Error(produtoRetorno.mensagem || (produtoRetorno.details));
								}
								prodFilho.cdproduto = produtoRetorno.id;
								prodFilho.codbarra = produtoRetorno.codBarra;
								prodFilho.cdgrupo = produtoRetorno.cdgrupo;
								prodFilho.cdsubgrupo = produtoRetorno.cdsubgrupo;
								prodFilho.codinter = produtoRetorno.codinter;
								produtoCompraItens.push(prodFilho);
							}
						} else if (produto.cdagrupamento) {
							if (!produtoCompraItens.filter(item => item.cdagrupamento == produto.cdagrupamento).length) {
								let prodAgrup = {...produto};
								prodAgrup.quantidade = 0;
								payload.itens.filter(item => item.cdagrupamento == produto.cdagrupamento).forEach(item => {
									prodAgrup.quantidade += typeof item.quantidade == "number" ? item.quantidade : Number(`${item.quantidade}`.replace(".", "").replace(",", "."));
								});
								produtoCompraItens.push(prodAgrup);
							}
						} else {
							produtoCompraItens.push(produto);
						}
					}
					payload.itens = produtoCompraItens;
					payload.itens.forEach((item, index) => {
						item.cdseq = index+1;
					});
				}
				const url = `entrada?cdcompra=${payload.cdcompra}&empresa=${payload.cdempresa}&cdfornecedor=${payload.fornecedor.id}&tipo=${payload.tipo || "C"}`; 
				payload.observacao = `Alterado por ${state.Usuario.nome} em ${new Date().toLocaleString("pt-BR")}`;
				const body = JSON.stringify(new CompraApi(payload));
				const data = await doRequest(url, {body, method: "put", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdcompra) {
					throw new Error(data.mensagem || JSON.stringify(data.details));
				}
				return new Entrada(data);
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.mensagem || JSON.stringify(error))});
				return {erro: true, message: error.message || (error.message || JSON.stringify(error))};
			}
		},

		async buscarCompras({commit, state}, payload){
			state.carregando = true;
			try {
				let url = `entrada/filtro?empresa=${payload.cdempresa}${payload.cdcompra ? `&cdcompra=${payload.cdcompra}` : ""}${payload.cdfornecedor ? `&cdfornecedor=${payload.cdfornecedor}` : ""}`+
                `${payload.cdtransporte ? `&cdtransporte=${payload.cdtransporte}` : ""}${payload.dataf ? `&dataf=${payload.dataf}` : ""}${payload.datai ? `&datai=${payload.datai}` : ""}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.length) {
					return commit("atualizarfiltroCompras", data);
				}
				commit("atualizarfiltroCompras",[]);
			} catch (error) {
				commit("atualizarfiltroCompras",[]);
			}
			state.carregando = false;
		},

		async buscarComprasUnica({commit, state}, payload){
			state.carregando = true;
			try {
				let url = `entrada?cdcompra=${payload.cdcompra}&cdfornecedor=${payload.cdfornecedor}&empresa=${payload.cdempresa}&tipo=${payload.tipo}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdcompra) {
					throw new Error(data.mensagem || JSON.stringify(data));
				}
				return new Entrada(data);
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", {mensagem: error.mensagem || JSON.stringify(error)});
			}
		},

		async deletarCompra({commit, state}, payload){
			state.carregando = true;
			try {
				let url = `entrada?cdcompra=${payload.cdcompra}&cdfornecedor=${payload.cdfornecedor}&empresa=${payload.cdempresa}&tipo=${payload.tipo}`;
				url += `&cdusuario=${state.Usuario.id}&nomeusuario=${state.Usuario.nome}`;
				const data = await doRequest(url, {method: "delete", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.mensagem || data.mensagem != "Entrada removida com sucesso!") {
					commit("relatarErro", {mensagem: data.mensagem || JSON.stringify(data.details)});
					return false;
				}
				return true;
			} catch (error) {
				commit("relatarErro", {mensagem: error.mensagem || JSON.stringify(error)});
				state.carregando = false;
				return false;
			}
		},
        
		async buscarCompraPorNfeChave({commit, state}, payload){
			try {
				let url = `entrada/nfechave?nfechave=${payload}&empresa=${state.Empresa.id}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.length) {
					return new Entrada(data[0]);
				} else {
					return null;
				}
			} catch (error) {
				commit("relatarErro", {mensagem: error.mensagem || JSON.stringify(error)});
				return false;
			}
		},

		async buscarXMLintegracao({commit, state}, payload){
			try {
				state.carregando = true;
				let url = `entrada/integracao/bdsdocs?empresa=${state.Empresa.id}&ativo=${payload.situacao ? payload.situacao : "A"}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.length) {
					commit("atualizarListaXMLintegracao", data);
				} else if (data.erro) {
					commit("atualizarListaXMLintegracao", []);
					commit("relatarErro", {mensagem: data.mensagem || (data.message ? JSON.stringify(data.details) : JSON.stringify(data))});
				} else {
					commit("atualizarListaXMLintegracao", []);
				}
			} catch (error) {
				state.carregando = false;
				// commit("relatarErro", {mensagem: error.mensagem || JSON.stringify(error)});
			}
		},
		async desativarXML({commit, state, dispatch}, payload){
			let url = `entrada/integracao/bdsdocs/desativar?empresa=${state.Empresa.id}&chave=${payload}`;
			const data = await doRequest(url, {method: "put", cache: "no-cache"}, commit);
			commit("relatarErro", {mensagem: data.mensagem, sucess: true});
			dispatch("buscarXMLintegracao");
		}
	},

	mutations: {
		atualizarfiltroCompras(state, payload){
			state.filtrocompras = [];
			state.filtrocompras = payload.map(compra => {
				return new Entrada(compra);
			});
		},

		atualizarListaXMLintegracao(state, payload){
			state.xmlImportados = [];
			state.xmlIntegracao = payload?.map(xml => new XML010(xml));
		},
	}
};