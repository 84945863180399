<template>
	<div class="tela">
		<div class="dummy-text">
			<div class="titulo">
				<h3>Selecione a Empresa</h3>
			</div>
			<div class="table-container" id="table">
				<table>
					<thead>
						<tr>
							<td>Código</td>
							<td>Razão Social</td>
							<td>Nome fantasia</td>
							<td>Atalho de Login</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(empresa, index) in Empresas" :key="index" @click="selecionarEmpresa(empresa)"
						:class="index == empresaSelecionada ? 'background-color: rgba(0,0,0,0.6)' : ''">
							<td><button id="buttonSeta" class="handle-button" type="button"><i class="fas fa-hand-point-right" style="color: var(--primario)" v-if="index == empresaSelecionada"></i></button> {{empresa.id}}</td>
							<td style="text-align: left">{{empresa.nome}}</td>
							<td style="text-align: left">{{empresa.fantasia}}</td>
							<td style="text-align: left">{{empresa.atalhoLoginPdv}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>



export default {
	name: "SelecionaEmpresa",

	data() {
		return {
			empresaSelecionada: 0,
			timer: null,
			startMove: {x:0, y:0},
			seleciouEmpresa: false,
		};
	},

	computed: {
		Empresas(){
			return this.$store.state.Empresas;
		}
	},

	methods: {
		async selecionarEmpresa(empresa) {
			this.empresaSelecionada = this.Empresas.map(emp => emp.id).indexOf(empresa.id);
			this.seleciouEmpresa = true;
			await this.$store.dispatch("buscarEmpresaPorId", empresa.id);
			await this.$store.dispatch("buscarContas");
			const Contas = this.$store.state.Contas; 
			let caixa3 = false;
			Contas.forEach(conta=> {
				if(conta.cdempresa == this.$store.state.Empresa.id && conta.tipo == "3"){
					caixa3 = true;
				}
			});
			if(!caixa3){
				this.$store.commit("relatarErro", {mensagem: "Favor cadastrar uma conta do tipo caixa para a empresa!",alerta: true});
				return;
			}
		},

		eventosTeclado(e) {
			if (e.preventDefault) e.preventDefault();
			if (e.repeat) return;
			if (this.seleciouEmpresa) return;
			if (e.key == "Enter") {
				return this.selecionarEmpresa(this.Empresas[this.empresaSelecionada]);
			}
			if (e.key == "ArrowUp") {
				if (this.empresaSelecionada != 0) {
					this.empresaSelecionada--;
				}
			}
			if (e.key == "ArrowDown") {
				if (this.empresaSelecionada < this.Empresas.length -1) {
					this.empresaSelecionada++;
				}
			}
			setTimeout(() => {
				if (["ArrowUp", "ArrowDown"].includes(e.key) || e.deltaY || e.repeat ){
					this.scrollTabela(e);
				}
				document.getElementById("buttonSeta").focus();
			}, e.repeat ? 0 : 100);
		},
		verificarLoginEmpresa() {
			try {
				const {empresaId} = this.$route.query;
				if (empresaId) {
					const empresaExiste = this.Empresas.map(emp => `${emp.id}`).indexOf(`${empresaId}`);
					if (empresaExiste < 0) {
						throw new Error("Você está tentando acessar uma empresa que não foi encontrada, verifique!");
					}
					this.selecionarEmpresa(this.Empresas[empresaExiste]);
				}
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},

		scrollTabela(event){
			let table = document.querySelector("#table");
			table.scrollBy({
				top: (event.deltaY > 0 || event.key == "ArrowDown" ) ? 30 : -30,
			});
		},

		eventoWheel(event) {
			let holdX = 0;
			let holdY = 0;
			let hold = 0;
			if(event.touches){
				holdX = event.touches[0].pageX - this.startMove.x;
				holdY = event.touches[0].pageY - this.startMove.y;
				hold = event.touches[0].pageY;
				if(Math.abs(holdX) > Math.abs(holdY)) return;
				if(Math.abs(holdY) > Math.abs(holdX)){
					event.preventDefault();
					clearTimeout(this.timer);
					this.timer = setTimeout(() => {
						if (this.startMove.y > hold)  {
							this.eventosTeclado({key: "ArrowDown"});
						} 
						else {
							this.eventosTeclado({key: "ArrowUp"});
						}
					}, 100);
				}
			}
			if(event.deltaY){
				clearTimeout(this.timer);
				this.timer = setTimeout (() => {
					if(event.deltaY > 0){
						this.eventosTeclado({key: "ArrowDown"});
					}else{
						this.eventosTeclado({key: "ArrowUp"});
					}
				}, 100);
			}
		},
		touchStarted(event) {
			var touch = 0;
			touch = event.touches[0];
			this.startMove.y = touch.pageY;
			this.startMove.x = touch.pageX;

		},
	},

	created() {
		this.$store.dispatch("buscarEmpresas");
		setTimeout(() => {
			if(this.Empresas.length == 1){
				this.selecionarEmpresa(this.Empresas[0]);
			}
		}, 300);
		this.$store.state.carregando = false;
	},

	mounted() {
		window.addEventListener("keydown", this.eventosTeclado);
		let table = document.querySelector("#table");
		table.addEventListener("wheel", this.eventoWheel);
		this.verificarLoginEmpresa();
		table.addEventListener("touchmove", this.eventoWheel);
		table.addEventListener("touchstart", this.touchStarted);
	},
	beforeDestroy() {
		window.removeEventListener("keydown", this.eventosTeclado);
		let table = document.querySelector("#table");
		if(!table) return;
		table.removeEventListener("wheel", this.eventoWheel);
	}
};
</script>

<style scoped >
thead tr th {
    font-size: 12pt;
}
tbody tr {
	line-height: 30px;
}
tbody tr td {
	font-size: 11pt;
}
.tela{
	width: 100%;
	height: 92vh;
	overflow: hidden;
}

.dummy-text{
	max-height: 92vh;
	overflow: hidden;
	background: #aaa9a9;
}
.table-container{
	max-height: 92vh ;
	width: 100%;
	overflow-y: hidden;
	scroll-behavior: smooth;
	background-color: var(--bg-app);
	border-radius: 10px;
}

button{
	background: transparent;
	border: none;
}
.table-container table {
	width: 100%;
	padding: 5px;
	white-space: nowrap;
}

.titulo {
	background-color: var(--bg-app);
	width: 100%;
	max-width: 400px;
	margin: 10px auto;
	padding: 5px;
	border-radius: 10px;
	text-align: center; 
	font-size: 16pt;
}
th {
	box-shadow: var(--shadow-secundari);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

</style>