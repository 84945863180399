<template>
	<div class="containerPage">
		<div class="contentPage">
			<h2 class="h-30px">LANÇAMENTO DE MESA</h2>
			<div class="containerMesa">
				<div class="containerInputIcon">
					<div class="col w-90">
						<label for="mesaSelecionada">
							Mesa selecionada
						</label>
						<input 
							type="text" 
							id="mesaSelecionada" 
							placeholder="Insira o número da mesa"
							autocomplete="off"
							@input="filterCliente"
							v-if="!clienteSelecionado.id"
						/>
						<input 
							type="text" 
							id="mesaSelecionada" 
							readonly
							:value="`${clienteSelecionado.nome}`"
							v-else
						/>

						<div 
							class="clienteBuscas" 
							ref="buscaCliente" 
							v-if="findCliente && !clienteSelecionado.id"
						>
							<ul class="contentBuscasCliente">
								<li 
									v-for="(cliente, index) in Clientes" 
									:key="index"
									@click="selecionouCliente(cliente)"
								>
									{{ cliente.nome }}
								</li>
							</ul>
						</div>
					</div>
					<div class="removeAddContainer">
						<i
							class="fas fa-plus" 
							style="color: green"
							v-if="form.cdVenda"
							@click="removePedido"
						/>
						<i
							class="fas fa-trash" 
							style="color: red"
							@click="removeCliente"
						/>

						
					</div>
					<Modal 
						v-if="viewOpenTables" 
						:dimensoes="{width: '90%', height: 'auto'}" 
						@fecharModal="viewOpenTables = false"
					>
						<div class="containerModalMesas">
							<div class="titleModalMesas">
								<h2>MESAS ABERTAS</h2>
							</div>
							<div class="containerCardMesas">
								<div 
									class="cardMesas" 
									v-for="(mesa, index) in VendasAberto" 
									:key="index"
									@click="selecionarMesaAberta(mesa)"
								>
									<div class="nomeCliente">
										<h3> {{ mesa.cliente.nome}} </h3>
									</div>
									<hr />
									<div class="data-valor">
										<small>
											{{ new Date(`${mesa.dataEmissao}`).toLocaleString("pt-BR") }}
										</small>
										<span> {{ mesa.valorBruto | formatarParaReais }} </span>
									</div>
								</div>
							</div>
						</div>
					</Modal>
					<Modal 
						v-if="modalTransferencia" 
						@fecharModal="modalTransferencia = false" 
						:dimensoes="{width: 'auto', height: 'auto'}"
					>
						<div class="containerModalTransferencia">
							<div class="containerModalTitle">
								<h2>
									SELECIONE UMA MESA PARA TRANSFERIR O PEDIDO
								</h2>
							</div>
							<div class="containerTabelaTransferencia">
								<div class="containerInput">
									<label for="searchClient">Buscar Mesa</label>
									<div class="containerInputIconMesa">
										<input 
											type="text" 
											id="searchClient" 
											@input="filterCliente"
										/>
										<i 
											class="fas fa-search" 
											style="color: var(--primario)" 
										/>
									</div>
									<div 
										class="clienteBuscas" 
										ref="buscaClienteTransferencia" 
										v-if="findCliente"
									>
										<ul class="contentBuscasCliente">
											<li 
												v-for="(cliente, index) in Clientes" 
												:key="index"
												@click="selecionouClienteTransferir(cliente)"
											>
												{{ cliente.nome }}
											</li>
										</ul>
									</div>
									<span v-if="mesaTransferir !== null">
										Mesa a trasnferir: {{ mesaTransferir.nome }}
									</span>
								</div>
								<div class="containerButtons">
									<button 
										class="botao botao-cancelar" 
										@click="cancelarTransferirMesa"
									>
										Cancelar
									</button>
									<button 
										class="botao botao-adicionar" 
										@click="salvarClienteTransferir"
									>
										Salvar
									</button>
								</div>
							</div>
						</div>
					</Modal>
				</div>
			</div>
			<div class="containerReferenciaDelivery">
				<div 
					class="containerReferencia" 
					v-if="clienteSelecionado.id"
				>
					<span @click="modalRef = true">
						Referência 
						<span style="font-weight: bold">
							{{ 
								`${form.contato || ""}`.toUpperCase() 
							}}
						</span>
					</span>
					<i 
						class="fas fa-edit" 
						style="color: var(--primario)" 
						@click="modalRef = true" 
					/>
				</div>
				<div class="containerDelivery">
					<input 
						type="checkbox"
						v-model="delivery"
						id="ehDelivery"
					/>
					<label for="ehDelivery">
						Para entregar
					</label>
				</div>
			</div>

			<Modal 
				@fecharModal="modalRef = false"
				v-if="modalRef"
				:dimensoes="{width: 'auto', height: 'auto'}"
			>
				<div class="containerModalRef">
					<div class="containerCampo">
						<label for="inputRef">
							Referência
						</label>
						<input 
							type="text" 
							id="inputRef" 
							v-model="form.contato" 
							@keydown.enter="proximoCampo('saveRef')"
						/>
					</div>
					<div class="containerButtons">
						<button
							class="botao botao-adicionar"
							id="saveRef"
							@click="salvarReferenciaPedido"
						>
							Salvar
						</button>
					</div>
				</div>
			</Modal>
			<div class="containerProduto">
				<div class="row w-100 items-end gap-10">
					<div class="col w-90">
						<label for="findProduto">
							Produto
						</label>
						<input 
							type="text" 
							id="findProduto"
							placeholder="Digite uma descrição"
							autocomplete="off"
							v-model="textoBuscaProduto" 
							@input="buscarProdutos" 
							style="text-transform: uppercase;"
						/>
					</div>
					<div>
						<i class="fas fa-search" style="color: var(--acao-background)" />
					</div>
				</div>
				<div class="row w-90">
					<ListaBuscas
						@selecionarItem="selecionarProduto"
						style="width: 90% !important"
						:Lista="ListaProdutos"
						:tipoLista="'Produtos'"
						:ref="buscarProduto"
						v-show="buscarProduto"
					/>
				</div>
			</div>
			<Modal 
				v-if="editandoProduto" 
				@fecharModal="closeModal" 
				:dimensoes="{width: '90%', height: 'auto'}"
			>
				<ProdutoSugestao 
					:produtoSelecionado="produtoSelecionado" 
					@saveItem="saveItem"
					@cancelItem="cancelItem"
				/>
			</Modal>
			<div 
				class="containerMostraProduto" 
				v-if="QtdProdutosImpressos > 0"
			>
				<label>
					<input 
						type="checkbox" 
						id="controlTodosProdutos" 
						v-model="todosProdutos"
						style="display: none;"
					/>
					<label 
						v-if="todosProdutos" 
						for="controlTodosProdutos">
							Esconder
					</label>
					<label 
						for="controlTodosProdutos"
						v-else 
					>
						Mostrar
					</label> produtos impressos
				</label>
				<span class="qtdImpresso">
					<p>
						{{ QtdProdutosImpressos }}
					</p>
				</span>
			</div>

			<div class="containerTabela">
				<table v-if="Produtos.length > 0">
					<thead>
						<tr>
							<th>Produto</th>
							<th>Qtd.</th>
							<th>Valor</th>
							<th>Ação</th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(produto, index) in Produtos"
							:key="index"
						>
							<td 
								class="td-flex"
								style="text-align: left;"
								@click="handleEditProduct(produto, index)"
							>
								<span>
									{{ produto.descricao }}
								</span>
								
							</td>
							<td  @click="handleEditProduct(produto, index)">
								{{ produto.quantidade }}
							</td>
							<td  @click="handleEditProduct(produto, index)">  
								{{
									(
										produto.sugestoes 
										? ((produto.valorunit * produto.quantidade) + produto.sugestoes.reduce((v, sugestao) => v += (sugestao.custo * sugestao.quantidade), 0))
										: ((produto.valorunit * produto.quantidade )|| 0)
									).toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL"
									})
								}}
							</td>
							<td>
								<i 
									class="fas fa-trash" 
									style="color: red" 
									@click="removeProduct(index)"
								/>	
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="containerTotal">
				<div class="col w-40">
					<label>Quantidade:</label>
					<input 
						type="text" 
						:value="totalQtd" 
						class="total" 
						readonly
					/>
				</div>
				<div class="col w-40">
					<label>Total Valor:</label>
					<input 
						type="text" 
						readonly
						:value="Number(totalValor || 0).toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL'
						})" 
						class="total" 
					/>
				</div>
			</div>
			<div class="botoes">
				<div class="botaoMesa">
					<button 
						class="botao botao-acao" 
						@click="visualizarMesas"
					>
						Ver mesas
					</button>
				</div>
				<div class="lancarCancelar">
					<button
						class="botao botao-cancelar"
						@click="$router.push({ name: 'Principal' })"
					>
						Cancelar
					</button>
					<button 
						class="botao botao-adicionar" 
						@click="lancarMesa"
					>
						Lançar
					</button>
				</div>
			</div>
		</div>
		<Modal 
			v-if="configurarDelivery"
			:dimensoes="{width: '95%', height: 'auto'}"
			@fecharModal="configurarDelivery = false"
		>
			<CupomDados @salvarInformacoes="salvouInformacoes" />
		</Modal>
	</div>
</template>
<script>
import { proximoCampo } from "../utils/utils";
import ListaBuscas from "../components/ListaBuscas.vue";
import Modal from "../components/Modal.vue";
import { ComposicaoKit } from "../models/Venda";
import CupomDados from "../components/CupomDados.vue";
import ProdutoSugestao from "../components/ProdutoSugestao.vue";
import formatarParaReais from "../filters/formatarParaMoeda";



class Pedido {
	constructor(pedido){ 
		this.cliente = pedido.cliente || {};
		this.contato = pedido?.contato || "";
		this.cdcliente = pedido.cdcliente || "";
		this.itens = pedido.itens || [];
		this.vendedor = pedido.vendedor || {};
		this.cdEmpresa = pedido.cdempresa || "";
		this.version = pedido.version || 0;
		this.cdIdentificador = 0;
		this.valorbruto = pedido.valorbruto || 0;
		this.cdVenda = pedido.cdvenda || null;
		this.impressaoRemota = pedido.impressaoRemota || "";
		this.version = 0;
		this.financeiro = pedido.financeiro;
	}

	converter() {
		const { itens, ...rest} = this;
		const produtos = [];

		itens.forEach(item => {
			produtos.push({
				valorunit: item?.valorunit,
				descricao: item?.descricao,
				quantidade: item?.quantidade,
				id: item?.cdproduto,
				produto: item?.produto,
				qtdentregue: item?.qtdentregue,
				cdseq: item?.cdseq,
				observacao: item?.observacao,
				sugestoes: (item?.vendaProdutoKits || item?.composicaoKit).map(sugestao => new ComposicaoKit(sugestao)),
			});
		});

		return {
			itens: produtos,
			...rest,
		};
	}

	
}

export default{
	name: "LancamentoMesa",

	components: {
		ListaBuscas,
		Modal,
		CupomDados,
		ProdutoSugestao
	},

	data(){
		return {
			form: new Pedido({}),

			//Cliente
			findCliente: false,
			clienteSelecionado: {
				id: "",
				nome: ""
			},
	
			//Produto
			todosProdutos: false,
			
			textoBuscaProduto: "",
			produtoSelecionado: {
				sugestoes: []
			},

			sugestoesEditadas: [],

			indexSelecionado: -1,
			editandoProduto: false,
			mostrarSugestoes: true,
			buscarProduto: false,


			modalRef: false,

			configurarDelivery: false,
			delivery: false,

			viewOpenTables: false,

			proximoCampo

		};
	},

	filters: {
		formatarParaReais
	},

	computed: {
		VendasAberto() {
			return this.$store.state.VendasAberto;
		},

		QtdProdutosImpressos() {
			let qtd = 0;
			const produtos = this.form.itens.filter(p => p.qtdentregue !== 0);
			produtos.forEach(prod => {
				qtd += prod.quantidade;
			});
			return qtd;
		},

		Produtos() {
			if(this.todosProdutos) {
				this.$forceUpdate();
				return this.form.itens;
			} else {
				this.$forceUpdate();
				return this.form.itens.filter(p => p.qtdentregue === 0);
			}
		},

		totalQtd() {
			let qtd = 0;
			this.form.itens.forEach(prod => {
				qtd += prod.quantidade;
			});
			return qtd;
		},

		totalSugestao() {
			let vlr = 0;
			const itens = this.Produtos;
			let version = this.form.version;

			for (let i = 0; i < itens.length; i++) {
				const prod = itens[i];
				if (prod.sugestoes) {
					for (let j = 0; j < prod.sugestoes.length; j++) {
						const sugestao = prod.sugestoes[j];
						
						if (sugestao.quantidade > 0) {
							if(sugestao?.multiplo > 1) {
								vlr += Number(sugestao.custo);
							} else {
								vlr += Number(sugestao.quantidade * sugestao.custo);
							}
						}
					}
				}
			}

			return { vlr, version }; 
		},

		totalValor() {
			let vlr = 0;
			this.Produtos.forEach(prod => {
				vlr += Number(prod.valorunit * prod.quantidade);
			});
			this.$forceUpdate();
			return Number(vlr + this.totalSugestao.vlr);
		},

		Clientes() {
			return this.$store.state.Clientes;
		},

		ListaProdutos() {
			return this.$store.state.Produtos;
		},
	},

	mounted () {
		if(this.$route?.query?.alteracao) {
			this.buscarVendaPorId();
		}
		window.addEventListener("click", this.handleClickOutside);
	},
			
	destroyed () {
		window.removeEventListener("click", this.handleClickOutside);
	},

	methods: {
		//GERAL
		visualizarMesas() {
			this.viewOpenTables = true;
			this.$store.dispatch("buscarVendasAberto");
		},

		salvarReferenciaPedido() {
			this.form.contato = this.referencia;
			this.modalRef = false;
		},

		async buscarVendaPorId() {
			const venda = await this.$store.dispatch("buscarVendaPorId", {
				cdVenda: this.$store.state.Venda.cdVenda,
				cdEmpresa: this.$store.state.Empresa.id
			});
			this.startComponent({
				cliente: venda?.cliente,
				cdcliente: venda?.cliente?.id || "",
				itens: venda?.itens || [],
				vendedor: venda?.vendedor || {},
				cdEmpresa: venda?.cdEmpresa || "",
				cdIdentificador: venda?.cdIdentificador || 0,
				valorbruto: venda?.valorBruto || 0,
				cdvenda: venda?.cdVenda || "",
				impressaoRemota: venda?.impressaoRemota || "",
				financeiro: venda?.financeiro || [],
				contato: venda?.contato || "",
			});

		},

		startComponent(venda) {	
			venda?.itens?.forEach (item => {
				item.valorunit = item?.produto?.vlrvista;
			}),
			this.form = new Pedido(venda).converter();
			if(this.form.cdcliente && !this.clienteSelecionado.id) {
				this.clienteSelecionado = {
					id: this.form.cdcliente,
					nome: this.form.cliente.nome
				};
			}
			this.$forceUpdate();
		},

		clearForm() {
			this.clienteSelecionado = {};
			this.produtoSelecionado = {};
			this.form = new Pedido({});
		},

		validateFields() {
			try {
				if(this.delivery ? false : !this.clienteSelecionado?.id) {
					throw new Error("Para finalizar a abertura de mesa é necessário selecionar a mesa!");
				}
				if(this.totalQtd <= 0) {
					throw new Error("É necessário informar pelo menos um produto!");
				}
				return true;
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
				return false;
			}
		},

		async lancarMesa() {
			try {
				this.form.vendedor.id = this.$store.state.Usuario?.id;
				this.form.cdEmpresa = this.$store.state.Empresa?.id;
				this.form.valorbruto = this.totalValor;
				this.form.impressaoRemota = "S";
	
				this.form.itens.forEach((prod, index) => {
					if(prod.valorunit === 0 && prod.sugestoes.length === 0) {
						this.form.itens.splice(index, 1);
					}
					prod.cdseq = index + 1;
					prod.cdtipo = "S";
					if(prod?.sugestoes?.length > 0) {
						let total = prod.valorunit * prod.quantidade;
						prod.sugestoes.forEach((sugestao) => {
							sugestao.cdseq = prod.cdseq;
							total += ((sugestao?.custoOriginal || sugestao?.custo) * (sugestao?.quantidade * (sugestao?.multiplo === 0 ? 1 : sugestao?.multiplo)));
						});
						prod.valorunit = total / (prod.quantidade);

					}
				});
				if(this.delivery){
					this.configurarDelivery = true;
					return;
				}
				if(!this.validateFields()) return;
				await this.finalizarPedido(this.form.cdVenda ? this.form.cdVenda : null);				
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}

		},

		async finalizarPedido(cdvenda){
			if(!cdvenda) {
				await this.$store.dispatch("inserirVendaSemPagamento", {...this.form, mesa: true});
				this.clearForm();
				return;
			}
			await this.$store.dispatch("atualizarVenda", {...this.form, mesa: true});
			this.delivery = false;
			this.clearForm();
		},

		handleClickOutside(e) {
			const buscaContainerProduto = this.$refs.buscarProduto?.$el;
			const buscaContainerMesa = this.$refs.buscaCliente?.$el;

			if(!buscaContainerProduto?.contains(e.target)){
				if(this.buscarProduto){
					this.buscarProduto = false;
				}
			}

			if(!buscaContainerMesa?.contains(e.target)){
				if(this.findCliente){
					this.findCliente = false;
				}
			}
		},

		closeModal() {
			this.form.itens[this.indexSelecionado].sugestoes = JSON.parse(JSON.stringify(this.sugestoesEditadas));
			this.editandoProduto = false;
			
			this.produtoSelecionado = {};
			this.indexSelecionado = -1;
		},

		//METODOS CLIENTE
		selecionarMesaAberta(venda) {
			this.clearForm();
			this.startComponent({
				cliente: venda?.cliente,
				cdcliente: venda?.cliente?.id || "",
				itens: venda?.itens || [],
				vendedor: venda?.vendedor || {},
				cdEmpresa: venda?.cdEmpresa || "",
				cdIdentificador: venda?.cdIdentificador || 0,
				valorbruto: venda?.valorBruto || 0,
				cdvenda: venda?.cdVenda || "",
				impressaoRemota: venda?.impressaoRemota || "",
				financeiro: venda?.financeiro || [],
				contato: venda?.contato || "",
			});
			this.$forceUpdate();
			this.viewOpenTables = false;
		},

		cancelarTransferirMesa() {
			this.mesaTransferir = null;
			this.modalTransferencia = false;
		},

		async salvouInformacoes(clienteSalvo) {
			try {
				this.configurarDelivery = false;
				const classificacaoPadrao = await this.$store.dispatch("buscarClassificacaoPadrao");
				if(!clienteSalvo?.cdCliente) {
					if(clienteSalvo?.foneCliente || clienteSalvo?.cnpj) {
						const cliente = await this.$store.dispatch("adicionarCliente", {
							...clienteSalvo,
							ativo: "I",
							cnpj: clienteSalvo?.cnpjCliente,
							nome: clienteSalvo?.nomeCliente,
							endereco: clienteSalvo?.enderecoCliente,
							numero: clienteSalvo?.numeroEndereco,
							bairro: clienteSalvo?.bairroCliente,
							cep: clienteSalvo?.cepCliente,
							cidade: {
								cdcidade: clienteSalvo?.cdcidade,
								codibge: clienteSalvo?.codibge,
								nomecidade: clienteSalvo?.cidadeCliente,
								uf: clienteSalvo?.ufCliente
							},
							uf: clienteSalvo?.ufCliente,
							fone: clienteSalvo?.foneCliente,
							classificacao: classificacaoPadrao
						});
						if(cliente.id) {
							this.preencherClientePedido(cliente); 
						}
					} else {
						const cliente = await this.$store.dispatch("buscarClientePadrao");
						if(cliente?.id) {
							this.form.cliente = {
								...cliente,
								nome: clienteSalvo?.nomeCliente
							};
							this.form.cdcliente = cliente?.id;
							this.clienteSelecionado = {
								nome: clienteSalvo?.nomeCliente,
								id: cliente?.id
							};
						}
					}
				} else {
					const cliente = await this.$store.dispatch("atualizarCliente", {
						...clienteSalvo, 
						id: clienteSalvo?.cdCliente,
						ativo: "I",
						cnpj: clienteSalvo?.cnpjCliente,
						nome: clienteSalvo?.nomeCliente,
						endereco: clienteSalvo?.enderecoCliente,
						numero: clienteSalvo?.numeroEndereco,
						bairro: clienteSalvo?.bairroCliente,
						cep: clienteSalvo?.cepCliente,
						cidade: {
							cdcidade: clienteSalvo?.cdcidade,
							codibge: clienteSalvo?.codibge,
							nomecidade: clienteSalvo?.cidadeCliente,
							uf: clienteSalvo?.ufCliente
						},
						uf: clienteSalvo?.ufCliente,
						fone: clienteSalvo?.foneCliente,
						classificacao: classificacaoPadrao
					});
					this.preencherClientePedido(cliente);
				}
				this.finalizarPedido(this.form.cdVenda ? this.form.cdVenda : null);
			} catch (error) {
				console.log(error);
			}
		},

		preencherClientePedido(cliente) {
			this.form.cliente = cliente;
			this.form.cdcliente = cliente?.id || cliente?.cdCliente; 
			this.clienteSelecionado = {
				id: cliente?.id || cliente.cdCliente,
				nome: cliente.nome
			};
		},

		async salvarClienteTransferir() {
			this.preencherClientePedido(this.mesaTransferir);
			this.modalTransferencia = false;
			this.mesaTransferir = null;
		},

		selecionouClienteTransferir(cliente) {
			this.mesaTransferir = cliente;
		},

		tableTransfer() {
			this.$store.commit("relatarErro", {
				mensagem: "Tem certeza que deseja transferir o pedido de mesa?",
				tipo: "polar",
			});
			this.filterCliente({target: {value: ""}});
		},

		polarConfirmado() {
			this.modalTransferencia = true;
		},

		filterCliente(e) {
			this.findCliente = true;
			setTimeout( async () => {
				await this.$store.dispatch("filtrarClientes", {
					todos: true,
					query: e.target.value,
					checkboxPesquisa: true,
				});
			}, 300);
		},

		removeCliente() {
			this.form.cdcliente = "";
			// this.form.itens = [];
			this.clienteSelecionado = {
				id: "",
				nome: ""
			};
			if(this.form.cdVenda){
				this.clearForm();
			}
			proximoCampo("mesaSelecionada");
		},

		selecionouCliente(cliente) {
			this.form.cliente = cliente;
			this.form.cdcliente = cliente?.id;
			this.clienteSelecionado = {
				id: cliente.id,
				nome: cliente.nome
			};
			this.findCliente = false;
			setTimeout(() => {
				proximoCampo("inputRef");
			}, 50);
			this.verificaMesaAberta(cliente?.id);
		},

		async verificaMesaAberta(id) {
			const venda = await this.$store.dispatch("buscarVendaAbertoPorCliente", id);
			if(venda.mensagem) return;
			this.startComponent(venda[0]);
		},

		//METODOS PRODUTO

		cancelItem() {
			this.produtoSelecionado = {};
			this.editandoProduto = false;
		},

		removeProduct(index) {
			try {
				if(!this.form.itens[index].qtdentregue === 1) return;
				if (this.todosProdutos) {
					this.form.itens.splice(index, 1);
				} else {
					const filteredItems = this.form.itens.filter((p) => p.qtdentregue === 0);
					const productIndex = this.form.itens.indexOf(filteredItems[index]);
					this.form.itens.splice(productIndex, 1);
				}	
			} catch (error) {
				console.log(error);
			}

		},

		async handleEditProduct(product, index) {
			try {
				if(product.qtdentregue !== 0) return;
				this.indexSelecionado = index;
				this.produtoSelecionado = {...product};
				await this.buscarKit(this.produtoSelecionado.id);
				this.sugestoesEditadas = JSON.parse(JSON.stringify(this.produtoSelecionado.sugestoes));
				this.editandoProduto = true;
			} catch (error) {
				console.log(error);
			}
		},

		saveItem() {
			try {
				const produto = this.Produtos[this.indexSelecionado];
				if(produto){
					produto.quantidade = this.produtoSelecionado?.quantidade;
					produto.observacao = this.produtoSelecionado?.observacao;
					if(this.produtoSelecionado?.sugestoes?.length > 0) {
						produto.sugestoes = [];
						if (this.produtoSelecionado?.sugestoes?.forEach) {
							this.produtoSelecionado.sugestoes.forEach(sugestao => {
								if(sugestao?.quantidade > 0) {
									produto.sugestoes.push({
										...sugestao,
									});
									this.form.version++;
								} else {
									this.form.version--;
								}
							});
						}
					}
				}
				this.produtoSelecionado = {};
				this.indexSelecionado = -1;
				this.editandoProduto = false;
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}
			
		},

		buscarProdutos(e) {
			this.buscarProduto = true;
			setTimeout(async () => {
				await this.$store.dispatch("filtrarProdutos", {
					descricao:  `${e.target.value || ""}`.toUpperCase(),
					query: `${e.target.value || ""}`.toUpperCase(),
				});
			}, 400);
		},

		selecionarProduto(produto) {
			try {
				if(this.editandoProduto) return;
				if(this.buscarProduto) {
					if(!this.clienteSelecionado.id && !this.delivery) {
						throw new Error("Para começar a lançar os produtos é necessário selecionar uma mesa");
					} else {
						if(produto.cdpromocao) {
							this.adicionarProdutoComPromocao(produto);
							
						} else {
							this.adicionarProduto(produto);
						}
						this.textoBuscaProduto = "";
					}
				}
				this.textoBuscaProduto = "";
				this.buscarProduto = false;
			} catch (error) {
				this.textoBuscaProduto = "";
				this.buscarProduto = false;
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}
		},

		adicionarProdutoComPromocao(produto) {
			const unitarioPromocaoVista = produto?.promocaoVista ? Number(produto?.vlrvista - (produto?.vlrvista * (produto?.promocaoVista / 100))) : Number(produto?.vlrvista);
			this.form.itens.push({
				valorunit: unitarioPromocaoVista,
				descricao: produto?.descricao,
				id: produto?.id,
				quantidade: 1,
				qtdentregue: 0,
				produto: {...produto},
				sugestoes: [],
			});
		},

		adicionarProduto(produto) {
			this.form.itens.push({
				valorunit: produto?.vlrvista,
				descricao: produto?.descricao,
				id: produto?.id,
				quantidade: 1,
				qtdentregue: 0,
				produto: {...produto},
				sugestoes: [],
			});
		},
		
		async buscarKit(id) {
			try {
				const kit = await this.$store.dispatch("buscarKitCadastrado", id);
				if (kit) {
					const sugestoesAtualizadas = [];
					kit.forEach(produto => {
						const sugestaoExistente = this.produtoSelecionado?.sugestoes?.find(sugestao => (sugestao.id || sugestao.cdprodutokit) === produto.id);
						const quantidade = sugestaoExistente ? sugestaoExistente.quantidade : 0;
						const sugestaoAtualizada = {
							custo: produto?.vlrvista,
							id: produto?.id,
							quantidade: quantidade,
							descricao: produto.descricao,
							unidade: produto?.unidade
						};
						sugestoesAtualizadas.push(sugestaoAtualizada);
					});

					this.produtoSelecionado.sugestoes = sugestoesAtualizadas;
				}
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}
		},
				

		// PEDIDO
		
		removePedido(){
			this.form = new Pedido({});
			this.form.cliente = {...this.clienteSelecionado};
		},

		handleAmountSugestao(sugestao, increment) {
			if(increment === "add") {
				sugestao.quantidade ++;
			}
			if(increment === "less" && (sugestao?.quantidade > 0)) {
				sugestao.quantidade --;
			}
			this.$forceUpdate();
		},

	}
};
</script>
<style scoped>
.containerPage{
	width: 100vw;
	height: 90vh;
	overflow-y: hidden !important;
}

.contentPage{
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 90vh;
}


.containerMesa,
.containerProduto{
	width: 100%;
	height: 8%;
	padding: 0px 10px;	
}

.containerInputIcon{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	gap: 10px;
}

i{
	font-size: 18px;
}

.containerProduto{
	display: flex;
	flex-direction: column;
}

.containerTabela{
	width: 100vw;
	margin-top: 10px;
	overflow: auto;
	height: 50vh !important;
	padding: 0px 5px;
}

.containerTabela table{
	width: 100%;
}

table tbody tr td{
	text-align: center;
}

.clienteBuscas {
	width: 100%;
	background: #FFF;
	z-index: 999;
	height: auto;
	max-height: 35vh;
	overflow-y: auto;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}


.clienteBuscas li {
	padding: 5px;
}

.clienteBuscas li:hover{
	background: #cecece;
}


.botoes{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
}
.lancarCancelar{
	display: flex;
	width: 100%;
	gap: 10px;
}

.botaoMesa{
	display: flex;
	justify-content: center;
	width: 100%;
}

.botoes button{
	width: 50%;
}

.containerTotal{
	width: 100%;
	height: 10%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding: 5px;
}

.total{
	text-align: right;
	font-weight: bold;
}

input[type="text"] {
	height: 30px;
	text-transform: uppercase;
}

textarea{
	text-transform: uppercase;
}

.containerMostraProduto{
	display: flex;
	flex-direction: row;
	justify-content: start;
	padding: 0px 10px;
	margin-top: 5px;
	gap: 10px;
	align-items: center;
	width: 100%;
}

.qtdImpresso{
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: var(--primario);
	color: #FFF;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.removeAddContainer{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: 10px;
	gap: 5px;
}
.containerReferenciaDelivery {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	gap: 10px;
	justify-content: flex-start;
}

.containerDelivery{
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 5px;
	margin: 5px 5px;
	gap: 5px;
}

.containerReferencia{
	height: 20px;
	margin: 10px 10px;
	padding-left: 10px;
}
.containerModalRef{
	display: flex;
	flex-direction: column;
	width: 100%;
}

.containerCampo {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.containerButtons {
	width: 100%;
	margin-top: 10px;
}

.containerButtons button{
	width: 100%;
}

.containerQuantidade{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.containerQuantidade input {
	border: none;
	outline: none;
	text-align: center;
	width: 50px;
	padding: 10px;
}


.containerQuantidade button{
	padding: 5px;
}
.containerModalTransferencia{
	padding: 10px;
	width: 100%;
}
.containerTabelaTransferencia{
	display: flex;
	flex-direction: column;
	width: 100%;
}

.containerInput{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

#searchClient{
	width: 100%;
}

.containerModalMesas{
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}

.containerCardMesas{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	width: 100%;
	gap: 20px;

}
.cardMesas {
	width: 100%;
	height: 100%;
	border-radius: 12px;
	background-color: red;
	padding: 5px;
	cursor: pointer;
	transition: transform ease-in-out 0.2s;
}

.cardMesas:hover{
	transform: scale(1.1);
}


.nomeCliente h3{
	color: #FFF;
}
.data-valor{
	display: flex;
	flex-direction: column;
	padding-top: 10px;
}

.data-valor{
	color: #FFF;
	font-weight: bold;
}
</style>