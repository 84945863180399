<template>
	<div class="container-page__relatorios">
		<Filtro
			@filtrar="buscarMovimentoProduto"
			@limpar="limparFiltro"
			@handleShowTable="handleShowTable()"
		>
			<div class="row-filtro__relatorios">
				<div class="col-filtro__relatorios">
					<div class="input-group">
						<select 
							id="opcao-filtro"
							v-model="tipoConsulta"
							@change="limparConsultaPessoa"
						>
							<option value="SEL" selected disabled>
								SELECIONE UMA OPÇÃO
							</option>
							<option
								:value="opt"
								v-for="opt in opcoes"
								:key="opt"
							>
								{{ opt }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-filtro__relatorios">
					<div class="input-group" @click="pesquisarPessoa()">
						<input 
							type="search"
							placeholder=" "
							v-model="pesquisaPor" 
							readonly
						/>
						<label for="pesquisa">
							Pesquisar por {{ `${tipoConsulta}`.toLowerCase() }}
							<i class="fas fa-search" style="color: var(--primario)"/>
						</label>
					</div>
				</div>
				<div class="col-filtro__relatorios">
					<div class="input-group">
						<input 
							type="date" 
							id="dataI"
							v-model="dataI"	
							@blur="handleInvalidDate"
						/>
						<label for="dataI">
							Data ínicio
						</label>
					</div>
				</div>
				<div class="col-filtro__relatorios">
					<div class="input-group">
						<input 
							type="date" 
							id="dataF"
							v-model="dataF"	
							@blur="handleInvalidDate"
						/>
						<label for="dataF">
							Data final
						</label>
					</div>
				</div>
				<div class="col-filtro__relatorios">
					<span>
						<input
							type="checkbox"
							v-model="imprimirValor" 
						/>
						<label for="imprimirValor">Imprimir valores</label>
					</span>
				</div>
			</div>
			<div class="row-filtro__relatorios">
				<div class="col-filtro__relatorios">
					<div class="input-group" @click="listarGrupos = true">
						<input 
							type="search"
							readonly
							id="grupoSelecionado"
							placeholder=" "
							:value="grupoInput ? `Grupo: ${grupoInput}${inputSubgrupo ? ` Subgrupo: ${inputSubgrupo}` : ''}` : 'Selecionar Grupo'"
						>
						<label for="grupoSelecionado">
							Selecionar um grupo
							<i class="fas fa-search" style="color: var(--primario)"/>
						</label>
					</div>
				</div>
			</div>
		</Filtro>
		<div
			:class="`container-tabela ${showTable ? 'table-full' : ''} `"
			v-if="Relatorios.movimentoProduto && Relatorios.movimentoProduto.length > 0 ? true : false"
		>
			<div class="header-tabela">
				<div class="linha-header-tabela">
					<div class="campo-tabela campo-data">Data</div>
					<div class="campo-tabela campo-medio">Cliente</div>
					<div class="campo-tabela campo-codigo" >Cód.</div>
					<div class="campo-tabela campo-codigo">Referência</div>
					<div class="campo-tabela campo-maior">Descrição</div>
					<div class="campo-tabela campo-valor">$ Unit.</div>
					<div class="campo-tabela campo-menor-extra">Qtd.</div>
					<div class="campo-tabela campo-valor">$ Produtos</div>
					<div class="campo-tabela campo-valor">$ Desconto</div>
					<div class="campo-tabela campo-valor">$ Pago</div>
				</div>
			</div>
			<div class="body-tabela">
				<div 
					class="linha-body-tabela"
					v-for="(item, index) in Relatorios.movimentoProduto"
					:key="index"
				>
					<div class="campo-tabela campo-data">
						{{ 
							new Date(item.dataemissao.split("-")).toLocaleDateString("pt-BR") 
						}}
					</div>
					<div class="campo-tabela campo-medio">
						{{ 
							tipoConsulta === "vendedor" ? item.vendedor : item.cliente
						}}
					</div>
					<div class="campo-tabela campo-codigo">
						{{ item.cdproduto }}
					</div>
					<div class="campo-tabela campo-codigo">
						{{ item.cdreferencia }}
					</div>
					<div class="campo-tabela campo-maior">
						{{ item.descricao }}
					</div>
					<div class="campo-tabela campo-valor">
						{{ (item.valorunitario || 0) | formatarParaReais }}
					</div>
					<div class="campo-tabela campo-menor-extra">
						{{ item.quantidade }}
					</div>
					<div class="campo-tabela campo-valor">
						{{ (item.valorprodutos || 0) | formatarParaReais }}
					</div>
					<div class="campo-tabela campo-valor">
						{{ (item.desconto || 0) | formatarParaReais }}
					</div>
					<div class="campo-tabela campo-valor">
						{{ (item.subtotal || 0) | formatarParaReais }}
					</div>
				</div>
			</div>
		</div>	
		<div
			v-else
			class="col center"
			id="warning"
		>
			<i
				class="fas fa-exclamation-triangle"
				style="color: yellow; font-size: 100pt"
			></i>
			<h1>
				Nenhum registro encontrado.
			</h1>
			<h2>
				Tente alterar ou diminuir os parâmetros de busca.
			</h2>
		</div>
		<div class="row" style="justify-content: end;">
			<div class="col-filtro__relatorios ">
				<div class="input-group">
					<input
						type="text"
						readonly
						:value="totalQuantidade"
						placeholder=" "
						id="totalQtd"
					/>
					<label for="totalQtd">
						Total Qtd.
					</label>
				</div>
			</div>
			<div class="col-filtro__relatorios ">
				<div class="input-group">
					<input
						type="text"
						readonly
						:value="totalGeral | formatarParaReais"
						placeholder=" "
						id="totalGeral"
					/>
					<label for="totalGeral">
						Total Pago
					</label>
				</div>
			</div>
		</div>
		<Modal
			v-if="listarClientes" 
			@fecharModal="listarClientes = false"
		>
			<consulta-cliente  
				@selecionarPessoa="selecionarCliente"
				:height="'65vh'"
			/>
		</Modal>
		<Modal
			v-if="listarVendedores"
			@fecharModal="listarVendedores = false"
		>
			<consulta-vendedores 
				@selecionarPessoa="selecionarVendedor"
				:height="'65vh'"
			/>
		</Modal>
		<Modal v-if="listarGrupos" v-on:fecharModal="listarGrupos = false">
			<ConsultaGrupos 
				@selecionarGrupos="selecionarPorGrupo"
				:desabilitarAcao="true"
				:heightTabela="'65vh'"
			/>
		</Modal> 
		<i class="fas fa-print" id="btn-relatorio" @click="soliciarRelatorio" />
	</div>

	<!-- <div id="grid-caixa" class="conteudo-item">
			v-if="Relatorios.movimentoProduto.length"></i>
		<Filtro
			
			@filtrar="buscarMovimentoProduto"
			@limpar="limparFiltro"
		>
			<div class="row row-filtro">
				<div class="row between m-2 ">
					<input type="radio" id="busca-cliente" class="pointer m-5" name="tipoConsulta" value="cliente"
						v-model="tipoConsulta" @click="Vendedor = null">
					<label for="busca-cliente" class="w-100 pointer">Cliente</label>
				</div>
				<div class="row between m-2 ">
					<input type="radio" id="busca-vendedor" class="pointer m-5" name="tipoConsulta" value="vendedor"
						v-model="tipoConsulta" @click="Cliente = null">
					<label for="busca-vendedor" class="w-100 pointer">Vendedor</label>
				</div>
				<div class="row between m-2 ">
					<input type="radio" id="busca-fornecedor" class="pointer m-5" name="tipoConsulta" value="fornecedor"
						v-model="tipoConsulta" @click="Vendedor = null">
					<label for="busca-fornecedor" class="w-100 pointer">Fornecedor</label>
				</div>
				<div class="informacoes-item">
					<div class="input-group">
						<select id="opcao-filtro" v-model="tipoConsulta">
							<option value="SEL">SELECIONE UMA OPÇÃO</option>
							<option :value="opt" v-for="opt in opcoes" :key="opt">
								{{ opt }}
							</option>
						</select>
					</div>
				</div>
				<div class="informacoes-item">
					<div class="row center mx-5">
						<input type="checkbox" id="impirmirValor" :value="true" v-model="impirmirValor"
							class="mx-2 pointer">
						<label for="impirmirValor" class="pointer">Imprimir valores</label>
					</div>
				</div>
				<div class="informacoes-item">
					<div class="input-group">
						<input v-model="dataI" type="date" id="dataI" @blur="saiuData"/>
						<label for="dataI" style="background: #f8f8f8;">Data Inicial</label>
					</div>
					<div class="input-group">
						<input v-model="dataF" type="date" id="dataF" @blur="saiuData"/>
						<label for="dataF" style="background: #f8f8f8;">Data final</label>
					</div>
				</div>
			</div>
			<div class="filtrosContainer">
				<div class="filtro-contas row-filtro">
					<div class="input-conta" v-if="tipoConsulta == 'cliente' || tipoConsulta == 'fornecedor'">
						<div class="input-group">
							<input type="search" class="w-50" autocomplete="off" readonly @input="listarClientes = true"
							id="input-nome-cliente" :value="Cliente ? `${Cliente.id} - ${Cliente.nome}` : `Selecionar ${tipoConsulta}`"
							@focus="listarClientes = true">
						</div>
						<i class="fas fa-address-book" @click="listarClientes = true"
							style="color: var(--primario); font-size: 20px;" v-if="!Cliente"></i>
						<i class="fas fa-user-slash" @click="() => { this.Cliente = null;}"
							style="color: var(--primario); font-size: 20px;" v-else></i>
					</div>
					<div class="input-conta" v-else>
						<div class="input-group">
							<input type="search" class="w-50" autocomplete="off" readonly @input="listarVendedores = true"
							id="input-nome-vendedor"
							:value="Vendedor ? `${Vendedor.id} - ${Vendedor.nome}` : 'Selecionar vendedor'"
							@focus="listarVendedores = true">
						</div>
						<i class="fas fa-address-book" @click="listarVendedores = true"
							style="color: var(--primario); font-size: 20px;" v-if="!Vendedor"></i>
						<i class="fas fa-user-slash" @click="() => { this.Vendedor = null;}"
							style="color: var(--primario); font-size: 20px;" v-else></i>
					</div>
					<div class="selecionar-grupos ">
						<div class="input-group" @click="selecionarProdutoPorGrupo">
							<input type="text" class="w-50" autocomplete="off" :value="grupoInput ? `Grupo: ${grupoInput}${inputSubgrupo ? ` Subgrupo: ${inputSubgrupo}` : ''}` : 'Selecionar Grupo'" id="inputGrupo" readonly />
						</div>
						<button class="botao botao-acao" id="selecionarProdutoGrupo" @click="selecionarProdutoPorGrupo" v-if="!grupoInput || grupoInput == ''">
							<i class="fas fa-search" style="color: var(--primario)"></i>
							<label for="selecionarProdutoGrupo" class="lg-view">Consultar Grupo</label>
							<label for="selecionarProdutoGrupo" class="sm-view">Grupo</label>
						</button>
						<button class="botao botao-cancelar" id="removerGrupo" @click="removerGrupoSelecionado" v-else>
							<i class="fas fa-times" style="color: var(--cancelar)"></i>
							<label for="removerGrupo" class="lg-view">Remover Grupo</label>
							<label for="removerGrupo" class="sm-view">Grupo</label>
						</button>
					</div>
				</div>
			</div>
		</Filtro>
		<div class="column">
			<div class="conteudo-subitem">
				<div id="tabela" :class="`${!showTable ? 'table-full' : ''}`">
					<div class="scrollable" v-if="Relatorios.movimentoProduto.length>0">
						<table class="m-0 w-100">
							<thead>
								<tr>
									<td style="text-align: center;">Data</td>
									<td style="text-align: center;" v-if="tipoConsulta == 'vendedor'">Vendedor</td>
									<td style="text-align: center;" v-else>Cliente</td>
									<td style="text-align: center;">Cód.</td>
									<td style="text-align: center;">Referência</td>
									<td>Descrição</td>
									<td style="text-align: right; white-space: nowrap;">$ unitário</td>
									<td style="text-align: center;">Qtd.</td>
									<td style="text-align: right; white-space: nowrap;">$ produtos</td>
									<td style="text-align: right; white-space: nowrap;">$ desconto</td>
									<td style="text-align: right; white-space: nowrap;">$ pago</td>
								</tr>
							</thead>

							<tbody>
								<tr v-for="(item, index) in Relatorios.movimentoProduto" :key="index">
									<td style="text-align: center;">{{ new
											Date(item.dataemissao.split("-")).toLocaleDateString("pt-BR")
									}}</td>
									<td style="text-align: left;" v-if="tipoConsulta == 'vendedor'">{{ item.vendedor }}</td>
									<td style="text-align: left;" v-else>{{ item.cliente }}</td>
									<td style="text-align: center;">{{ item.cdproduto }}</td>
									<td style="text-align: center;">{{ item.cdreferencia }}</td>
									<td>{{ item.descricao }}</td>
									<td style="text-align: right;">{{ (item.valorunitario || 0).toLocaleString("pt-BR", {
											style:
												"decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
										})
									}}</td>
									<td style="text-align: center;">{{ item.quantidade }}</td>
									<td style="text-align: right;">{{ (item.valorprodutos || 0).toLocaleString("pt-BR", {
											style:
												"decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
										})
									}}</td>
									<td style="text-align: right;">{{ (item.desconto || 0).toLocaleString("pt-BR", {
											style:
												"decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
										})
									}}</td>
									<td style="text-align: right;">{{ (item.subtotal || 0).toLocaleString("pt-BR", {
											style:
												"decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
										})
									}}</td>
								</tr>
							</tbody>
						</table>
					</div>
					
				</div>			
				<div class="input-group" id="valorTotal">
					<input type="text" readonly class="input-valor" :value="`${totalGeral}`">
					<label>Total</label>
				</div>
			</div>			
		</div>
		
	</div>-->
</template>
<script>
import { mapState } from "vuex";
import Modal from "./Modal.vue";
import Filtro from "../components/Filtro.vue";

import { dateFormatISO } from "../utils/dateformat";
import { gerarRelatorio } from "../utils/Relatorio/impressaorelatorio";
import formatarParaReais from "../filters/formatarParaMoeda";
import ConsultaCliente from "../components/ConsultaCliente.vue";
import ConsultaVendedores from "../components/ConsultaVendedores.vue";
import ConsultaGrupos from "../components/ConsultaGrupos.vue";


export default {
	name: "RelatorioProdutosVendidos",

	components: {
		Modal,
		
		Filtro,
		ConsultaCliente,
		ConsultaVendedores,
		ConsultaGrupos,
	},
	filters: {
		formatarParaReais
	},
	data() {
		return {
			
			abrirModal: false,
			
			listarClientes: false,
			listarVendedores: false,
			listarGrupos: false,

			Cliente: null,
			Vendedor: null,
			ordem: "desc",
			timerFiltro: null,
			
			grupo: {},
			grupoInput: "",
			inputSubgrupo: "",
			showTable: false,

			pesquisaPor: "",
			opcoes: ["Clientes", "Fornecedor", "Vendedor"],
			tipoConsulta: "Clientes",
			dataI: "",
			dataF: "",
			imprimirValor: true,
		};
	},

	computed: {
		...mapState(["Relatorios"]),

		totalGeral() {
			let preco = 0;
			this.Relatorios.movimentoProduto.map(item => {
				preco += (item.subtotal || 0);
			});
			return preco.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
		},
		totalQuantidade() {
			let quantidade = 0;
			this.Relatorios.movimentoProduto.map(item => {
				quantidade += (item.quantidade || 0);
			});
			return quantidade;
		}
	},

	methods: {
		limparConsultaPessoa() {
			this.pesquisaPor = "";
			if(this.Cliente) this.Cliente = null;
			if(this.Vendedor) this.Vendedor = null;
		},
		pesquisarPessoa(){
			const consulta = this.tipoConsulta;
			if(consulta === "Clientes" || consulta === "Fornecedor") {
				return this.listarClientes = true;
			}
			if(consulta === "Vendedor") {
				return this.listarVendedores = true;
			}
		}, 
		handleInvalidDate(){
			if(this.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataF = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		handleShowTable() {
			this.showTable = !this.showTable;
		},
		limparFiltro(){
			this.dataI = this.dataI = dateFormatISO({
				date: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1
				).toLocaleDateString("en-CA"),
				format: "ISOdate"
			});
			this.dataF = dateFormatISO({date: null, format: "ISOdate"});
			this.tipoConsulta = "Clientes";
			this.impirmirValor = true;
			this.Cliente = null;
			this.Vendedor = null;
			this.grupoInput = "";
			this.inputSubgrupo = "";
			this.grupo = {};
			this.ordem = "desc";
		},
		selecionarProdutoPorGrupo() {
			this.listarGrupos = true;
		},
		selecionarPorGrupo ({grupo, subgrupo}) {
			const grupoRes = this.$store.state.grupoProduto.find(g => g.cdGrupo == grupo);
			const subgRes = grupoRes.subGrupos.find(subg => subg.cdsubgrupo == subgrupo);
			this.grupoInput = grupoRes?.descricao || "";
			this.inputSubgrupo = subgRes?.descricao || "";
			this.grupo = {
				grupo: grupo,
				subg: subgrupo,
			};
			this.listarGrupos = false;
		},
		removerGrupoSelecionado () {
			this.grupo = {};
			this.grupoInput = "";
			this.inputSubgrupo = "";	
		},
		soliciarRelatorio() {
			this.$store.commit("relatarErro", {
				mensagem: "Deseja imprimir o relatório em A4?",
				tipo: "polar",
				item: {
					relatorio: true,
					route: this.$route.name
				}
			});
		},
		imprimirRelatorio({ pageWidth }) {
			gerarRelatorio({
				filtro: {
					empresa: this.$store.state.Empresa,
					itens: this.Relatorios.movimentoProduto,
					dataI: dateFormatISO({
						date: this.dataI,
						format: "ISOdate"
					}),
					dataF: dateFormatISO({
						date: this.dataF,
						format: "ISOdate"
					}),
					cliente: this.Cliente,
					vendedor: this.Vendedor,
					fornecedor: this.Cliente,
					imprimirValor: this.imprimirValor,
					pageWidth,
				},
				acao: "gerarRelatorioMovimentoProduto"
			});
		},
		buscarMovimentoProduto() {
			if (this.timerFiltro) {
				clearTimeout(this.timerFiltro);
			}
			this.timerFiltro = setTimeout(() => {
				this.$store.dispatch("filtrarMovimentoProduto", {
					dataI: dateFormatISO({
						date: this.dataI, 
						format: "ISOdate"
					}),
					dataF: dateFormatISO({
						date: this.dataF, 
						format: "ISOdate"
					}),
					tipoConsulta: this.tipoConsulta,
					cliente: this.Cliente,
					vendedor: this.Vendedor,
					sortOrder: this.ordem,
					grupo: this.grupo.grupo,
					subg: this.grupo.subg
				});
			}, 500);
		},

		ordenar(ordem) {
			this.ordem = ordem;
		},

		selecionarCliente(cliente) {
			this.listarClientes = false;
			this.Cliente = cliente;
			this.pesquisaPor = cliente.nome;
		},

		selecionarVendedor(vendedor) {
			this.listarVendedores = false;
			this.Vendedor = vendedor;
			this.pesquisaPor = vendedor.nome;

		},

		confirmarPolar(item) {
			if (item.relatorio) {
				this.imprimirRelatorio({
					pageWidth: "A4"
				});
			}
		},

		cancelarPolar(item) {
			if (item.relatorio) {
				this.imprimirRelatorio({
					pageWidth: null
				});
			}
		},
	},

	beforeMount() {
		this.dataI = this.dataI = dateFormatISO({
			date: new Date(
				new Date().getFullYear(),
				new Date().getMonth(),
				1
			).toLocaleDateString("en-CA"),
			format: "ISOdate"
		});
		this.dataF = dateFormatISO({date: null, format: "ISOdate"});
		this.buscarMovimentoProduto();
	},

	mounted() {
		this.$bus.$on("confirmarPolar", this.confirmarPolar);
		this.$bus.$on("cancelarPolar", this.cancelarPolar);
	},

	destroyed() {
		this.$bus.$off("confirmarPolar", this.confirmarPolar);
		this.$bus.$off("cancelarPolar", this.cancelarPolar);
	}
};
</script>
<style scoped>
.container-page__relatorios{
	width: 100%;
	height: 90vh;
}

.row-filtro__relatorios{
	display: flex;
	width: 100%;
	align-items: center;
	margin-top: 15px;
}

.col-filtro__relatorios span {
	display: flex;
	gap: 3px;
}

.col-filtro__relatorios .input-group input[type="search"] {
	width: 310px;
	cursor: pointer;
}

#warning{
	height: 55vh;
}

.container-tabela{
	width: 89vw;
	overflow: auto;
	height: 55vh;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 2px 5px;
}
.campo-tabela{
	font-size: 13px;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}

.campo-menor-extra {
	width: 75px;
	text-align: center;
}
.campo-data {
	width: 110px;
	text-align: center;
}

.campo-codigo {
	width: 120px;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
}

.campo-valor {
	width: 100px;
	text-align: right;
}

.campo-medio {
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.campo-maior {
	width: 325px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.container-tabela.table-full{
	height: 75vh !important;
}

/*
.conteudo-item{
	overflow: hidden !important;
}
.buttonsMostraEsconder button {
	background-color: var(--acao-background);
	
	border: none;
	outline: none;
	border-radius: 8px;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 30px;
	height: 30px;

	-webkit-box-shadow: -5px 6px 10px -9px rgba(0,0,0,0.75);
	-moz-box-shadow: -5px 6px 10px -9px rgba(0,0,0,0.75);
	box-shadow: -5px 6px 10px -9px rgba(0,0,0,0.75);
}
.sm-view {
	display: none;
}

.fa-address-book, .fa-user-slash {
	margin-top: 10px;
	margin-left: 0;
}

#grid-caixa {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto 1fr;
	grid-template-areas:
		"info-conteudo"
		"filtro-contas"
		"sub-item";
	height: 92vh;
	width: 100%;
}

.conteudo-filtro {
	margin-top: 10px;
}

.conteudo-subitem {
	border-radius: 10px;
	box-shadow: var(--shadow-secundario);
	overflow: hidden;
	grid-area: sub-item;
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.scrollable {
	overflow: auto;
}

.input-valor {
	font-size: 12pt;
	width: 100%;
	text-align: right;
}

#valorTotal {
	width: 15%;
	align-self: end;
	margin-top: 10px;
	margin-bottom: 5px;
}

#input-nome-cliente {
	width: 100%;
}

#input-nome-vendedor {
	width: 100%;
}

.informacoes-conteudo {
	display: flex;
	flex-direction: row;
	grid-area: info-conteudo;
	align-items: center;
	justify-content: space-between;
	padding: 0px 10px;
}

.informacoes-item {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.informacoes-item label {
	display: flex;
	justify-content: center;
	align-items: center;
}

.informacoes-item input[type="date"] {
	max-width: 140px;
}

#dataI {
	margin-right: 5px;
}

.filtrosContainer{
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
}
.filtro-contas {
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow-x: auto;
	padding: 8px 0px;
	width: 100%;
}

.filtro-contas {
	width: 100%;
}

.selecionar-grupos {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	width: 60%;
	white-space: nowrap;
}

.selecionar-grupos .input-group {
	width: 100%;
}

.selecionar-grupos .botao {
	width: 36%;
	padding: 0;
}

.selecionar-grupos input {
	width: 100%;
}

.botao-cancelar, .botao-acao {
	height: 40px;
}

.input-conta {
	display: flex;
	flex-direction: row;
	width: 40%;
}

.input-conta .input-group {
	width: 100%;
}

#warning {
	margin-top: 10vh;
}
#tabela {
    width: 95%;
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;
	height: 40vh;
}


@media screen and (max-width: 1125px) {
	.informacoes-item {
		max-width: 100%;
		width: auto;
		justify-content: flex-start;
	}

	.informacoes-item input[type="date"] {
		max-width: 150px;
	}

	#valorTotal {
		width: 20%;
	}
}

@media screen and (max-width: 900px) {
	.informacoes-conteudo {
		flex-direction: column;
		align-items: flex-start;
	}

	.input-valor {
		font-size: 10pt;
	}
	.filtrosContainer{
		display: flex;
		flex-direction: column;
	}
	.selecionar-grupos{
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	#btn-relatorio {
		left: 0;
	}

	.sm-view {
		display: inline-block;
	}

	.lg-view {
		display: none;
	}

	#valorTotal {
		width: 30%;
	}

}

@media screen and (max-width: 695px) {
	#btn-relatorio {
		left: 0;
	}
}

@media screen and (max-width: 440px) {
	#textoFiltrar{
		display:none;
	}
	.informacoes-item {
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	.informacoes-item div {
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.informacoes-item div input[type="date"] {
		width: 120px;
	}

	.informacoes-item div input[type="date"]::-webkit-calendar-picker-indicator {
		display: none;
	}

	#grid-caixa {
		max-width: 100vw;
	}

	#relatorio-conteudo {
		max-width: 100vw;
	}

	.conteudo-subitem {
		max-width: 100vw;
	}

	.row .row {
		display: flex;
		flex-direction: row;
		width: auto;
	}

	.conteudo-filtro .row {
		flex-wrap: wrap;
		justify-content: center;
		gap: 8px;
	}

	.conteudo-filtro .row .row {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
	}

	#valorTotal {
		width: 40%;
	}

	.filtro-contas {
		flex-wrap: wrap;
		gap: 8px;
	}

	.input-conta {
		width: 100%;
	}

	.fa-address-book {
		margin-right: 10px;
	}

	#selecionarProdutoGrupo {
		margin-right: 10px;
	}
	#tabela.table-full{
		height: 70vh !important;
	}

	#tabela{
		height: 30vh !important;
	}
	
	.row-filtro{
		display: flex;
		flex-direction: row;
		width: 100%;
		flex-wrap: wrap;
		gap: 10px;
	}
}

@media screen and (max-width: 375px) {
	#grid-caixa {
		height: 145vh;
	}
}

@media screen and (max-width: 320px) {
	#grid-caixa {
		height: 165vh;
	}
} */
</style>