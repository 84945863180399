<template>
	<div class="container-page-relatorio">
		<i 
			class="fas fa-print"
			style="color: var(--primario)"
			id="impressao"
			@click="gerarImpressaoRelatorio"
			v-if="$store.state.Clientes.length > 0"
		/>
		<paginacao-filtro
			@filtrar="filtrar"
			@handleShowTable="handleShowTable"
			@limpar="limpar"
			:ControlePaginacao="Paginacao"
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">
							<div 
								class="input-group select-group" 
							>
								<select 
									id="mesesAno" 
									v-model="mesAniversario"
									@change="filtroPesquisa = mesesAno[mesAniversario - 1]"
								>
									<option disabled selected value="SELMES">
										SELECIONE UM MÊS
									</option>
									<option v-for="(mes, index) in mesesAno" :value="index + 1" :key="index">
										{{ mes }}
									</option>
								</select>
								<label for="mesesAno" style="background-color: #f8f8f8;">
									Mês aniversário
								</label>
							</div>
							<div class="checked">
								<div class="selecionar">
									<label for="js-checkbox-pesquisa" v-if="checkboxPesquisa" class="pointer">
										&nbsp;
										Ativos
									</label>
									<label for="js-checkbox-pesquisa" v-else class="pointer">
										&nbsp;
										Ambos
									</label>
									&nbsp;
									<input
										type="checkbox"
										id="js-checkbox-pesquisa"
										@change="checkboxPesquisa = !checkboxPesquisa"
										:checked="checkboxPesquisa"
									/>
									<label class="check" for="js-checkbox-pesquisa"></label>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<tabela-pessoas 
					class="table-scroll"
					:tipoConteudo="'Clientes'"
					:height="'65vh'"
					:mostrarAcao="false"					
					:showTable="fullTable"
					v-if="$store.state.Clientes.length > 0"
				/>
				<div class="container-aviso" v-else>
					<i 
						class="fas fa-exclamation-triangle" 
						style="color: yellow" 
					/>
					<span>
						Nenhum faturamento encontrado!
					</span>
				</div>
			</template>
		</paginacao-filtro>
		<Modal v-if="false" @fecharModal="fecharModal">
			<PreImpressao
				:vendaFinalizada="Venda"
				:conferir="true"
				:permiteCancelar="true"
				:empresa="Empresa"
				@fecharModal="fecharModal"
			>
			</PreImpressao>
		</Modal>
	</div>
</template>
<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import Modal from "../components/Modal.vue";
import PreImpressao from "../components/PreImpressao.vue";
import formatarParaReais from "../filters/formatarParaMoeda";
import TabelaPessoas from "../components/TabelaPessoas.vue";
export default {
	name: "RelatorioAniversarios",
	data() {
		return {
			mesesAno: [
				"Janeiro",
				"Fevereiro",
				"Março",
				"Abril",
				"Maio",
				"Junho",
				"Julho",
				"Agosto",
				"Setembro",
				"Outubro",
				"Novembro",
				"Dezembro"
			],
			mesAniversario: "SELMES",
			checkboxPesquisa: true,
			fullTable: false,
		};
	},
	components: {
		PaginacaoFiltro,
		Modal,
		PreImpressao,
		TabelaPessoas
	},

	computed: {
		Faturamentos(){
			return this.$store.state.Relatorios.FaturamentoDiasXHoras || [];
		},
		Paginacao() {
			return {
				paginaAtual: this.$store.state.paginaClientesAtual,
				totalPaginas: this.$store.state.totalPaginasClientes
			};
		}
	},
	filters: {
		formatarParaReais
	},
	mounted() {
		this.filtrar();
	},

	methods: {
		async gerarImpressaoRelatorio() {
			try {
				
				if(this.$store.state.Clientes.length===0) {
					throw new Error(
						"Para gerar o relatório é necessário filtrar ao menos um cliente, favor verificar"
					);
				}
				const filtro = {
					page: 0,
					checkboxPesquisa: this.checkboxPesquisa,				
					query: null,
					todos: false,
					nome: null,
					cdCliente: null,
					cnpj: null,
					fone: null,
					niver: this.mesAniversario,
					size: this.$store.state.totalElementsClientes,
				};
				await this.$store.dispatch("filtrarClientes", filtro);
				await this.$store.dispatch("gerarRelatorioAniversarios", {empresa:this.$store.state.Empresa, clientes: this.$store.state.Clientes, mes:this.mesAniversario});
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
				});
				return null;
			}
		},

		handleShowTable() {
			this.fullTable = !this.fullTable;
		},

		async filtrar(page) {
			const filtro = {
				page: page || 0,
				checkboxPesquisa: this.checkboxPesquisa,				
				query: null,
				todos: false,
				nome: null,
				cdCliente: null,
				cnpj: null,
				fone: null,
				niver: this.mesAniversario,
			};

			await this.$store.dispatch("filtrarClientes", filtro);
		},

		limpar() {
			this.checkboxPesquisa = true;
			this.mesAniversario = "SELMES";
			this.filtrar();
		},
	},
};
</script>
<style scoped>
.container-page-relatorio{
	width: 100%;
	height: 90vh;
	box-shadow: var(--shadow-secundario);
	border-radius: 10px;
}
.row-filtro {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;
}
.column-filtro {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.checked {
    display: flex;
    align-content: center;
    justify-content: center;
}
.checked .selecionar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.check {
	display: block;
	position: relative;
	width: 60px;
	height: 30px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}
.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}
input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}
.table-scroll {
	width: 100%;
	overflow-y: auto;
	height: 52vh;
}

.table-scroll .container-table table {
	width: 100%;
}
.fullTable {
	height: 70vh;
}
.container-aviso{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.container-aviso i {
	font-size: 200px;
}
.container-aviso span {
	font-size: 26px;
	font-weight: bold;
	margin-top: 10px;
}

#impressao{
	position: absolute;
	bottom: 30px;
	left: 140px;
	font-size: 35px;

	padding: 7px;
	border-radius: 50%;
    box-shadow: var(--shadow-secundario);

	background: #FFF;
	z-index: 2;
}


@media screen and (max-height: 660px) {
	.table-scroll {
		height: 55vh;
	}
	.fullTable {
		height: 72vh;
	}
}

@media screen and (max-width: 425px){
	.row-filtro .input-group {
		width: 100%;
	}
	
	.table-scroll {
		width: 100vw;
		height: 25vh !important;
		overflow: auto;
	}
	.fullTable {
		height: 45vh;
	}

	.fullTable .container-aviso {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.fullTable .container-aviso i{
		font-size: 120px;
	}
	.fullTable .container-aviso span {
		font-size: 22px;
		text-align: center;
	}

	.container-aviso {
		display: none;
	}
	#impressao{
		position: absolute;
		bottom: 0px;
		left: 0px;
		font-size: 35px;
	
		padding: 7px;
		border-radius: 50%;
		box-shadow: var(--shadow-secundario);
	
		background: #FFF;
		z-index: 2;
	}
}
</style>