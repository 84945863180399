<!-- 
	PARA CONTROLAR O TAMANHO DO MODAL AGORA
	PRECISA SOMENTE PASSAR UM OBJETO COM WIDTH E HEIGHT E UM ID DO MODAL PARA CONTROLE
-->

<template>
	<div class="modal center">
		<div class="modal-interno js-modal-interno col p-10" :id="idModal">
			<i class="fas fa-times interno" @click="fecharModal" v-if="!iconeFechar"></i>
			<slot></slot>
		</div>
	</div>
</template>

<script>

export default {
	name: "Modal",

	props: {
		dimensoes: {
			width: Number,
			height: Number
		},
		idModal: String,
		iconeFechar: Boolean,
	},

	methods: {
		fecharModal(){
			this.$emit("fecharModal");
		},

		eventosTeclado(e){
			if (e.key == "Escape") {
				return this.fecharModal();
			}
		},

		handleResizeModal(modalId) {
			if(!modalId) {
				const tamanho = document.getElementsByClassName("js-modal-interno")?.length;
				const modal = document.getElementsByClassName("js-modal-interno")[tamanho];
				if(modal) {
					modal.style.width = this.dimensoes.width;
					modal.style.height = this.dimensoes.height;
				}
			} else {
				const modal = document.getElementById(modalId);
				if(modal) {
					modal.style.width = this.dimensoes.width;
					modal.style.height = this.dimensoes.height;
				}
			}
		}
	},
	watch: {
		idModal: {
			handler(newV) {
				this.handleResizeModal(newV);
			},
			deep: true,
		}
	},

	mounted() {

		if (this.dimensoes) {
			this.handleResizeModal(this.idModal);
			// document.getElementsByClassName("js-modal-interno")[0].style.width = this.dimensoes.width;
			// document.getElementsByClassName("js-modal-interno")[0].style.height = this.dimensoes.height;
		}
	},

	created(){
		window.addEventListener("keydown", this.eventosTeclado);
	},

	destroyed(){
		window.removeEventListener("keydown", this.eventosTeclado);
	}
};
</script>

<style scoped>
.modal {
	height: 100vh;
	width: 100%;
	max-width: 100vw;
	background-color: rgba(0, 0, 0, 0.7);
	position: fixed !important;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.modal-interno {
	background-color: var(--bg-app);
	box-shadow: var(--shadow-primario);
	width: 95vw;
	height: 95vh;
	margin: auto auto;
	border-radius: 10px;
	position: absolute !important;
	overflow: auto;
	max-height: 100vh;
}

.fas.fa-times.interno {
	color: var(--deletar);
	font-size: var(--txt-grande);
	position: absolute;
	top: 1px;
	right: 1px;
}
.slot {
	width: 100%;
	max-width: 100%;
	height: 100%;
	overflow: auto;
}

@media screen and (max-width: 1110px) {
	/* .modal-interno {
		width: 95% !important;
		height: 95% !important;
	} */

	.row.w-100.between {
		flex-direction: row !important;
	}
}

/* @media screen and (max-width: 425px) {
	.modal-interno {
		width: 100% !important;
		height: 100% !important;
	}
} */
/* 
@media screen and (max-height: 515px) {
	.modal-interno {
		width: 100vw !important;
		height: 100vh !important;
		padding: 10px;
		overflow: auto;
	}
} */
</style>