import { dateFormatISO } from "../utils/dateformat";

export class Financeiro {
	constructor(conta) {
		this.cdEmpresa = conta.cdempresa;
		this.cdBanco = conta.cdbanco;
		this.cdAgencia = conta.cdagencia;
		this.cdConta = conta.cdconta;
		this.cdDig = conta.cddig;
		this.tipo = conta.tipo;
		this.cdLan = conta.cdlan;
		this.cdMovi = conta.cdmovi;
		this.tipoMovi = conta.tipomovi;
		this.cdDoc = conta.cddoc;
		this.cdPessoa = conta.cdpessoa;
		this.nome = conta.nome;
		this.fone = conta.fone;
		this.dataDoc = dateFormatISO({date:conta.datadoc, format: "ISOdate"});
		this.dataVenc = dateFormatISO({date:conta.datavenc, format: "ISOdate"});
	
		this.dataBaixa = conta.databaixa;
		this.dataUltimoPagamento = conta.dataultimopagamento;
		this.credito = conta.credito;
		this.contarc = conta.contarc;
		this.debito = conta.debito;
		this.valor = conta.valor;
		this.cdTipoPgto = conta.cdtipopgto;
		this.pagamento = conta.pagamento;
		this.cdVendedor = conta.cdvendedor;
		this.observa = conta.observa;
		this.valorTitulo = conta.vlrtitulo;
		this.aumentoDesconto = conta.aumdesc;
		this.restante = conta.restante;
		this.jurosCalculados = conta.jurosCalculados;
		this.diasEmAtraso = conta.diasvencidos;
		this.valorPagoBaixa = conta.valorPagoBaixa;
		this.jurosPagoBaixa = conta.jurosPagoBaixa;
		this.jurosAcumulado = conta.jurosacumulado;
		this.situacao = conta.situacao;
		this.condicao = conta.condicao;
	}
}