<template>
    <div id="TabelaPromocao" :class="`${!showTable? 'table-full' : ''}`">       
        <div class="tabela-controle" v-if="Promocoes.length >= 1">
            <div class="tabela-item">
                <table class="w-100">
                    <thead>
                        <tr>
                            <td style="color: blue; text-align: center; width: 80px">Cód.</td>
                            <td style="text-align: left; flex-grow: 1">Nome</td>
							<td style="text-align: center; width: 200px">Início - Fim ou Dias</td>
                            <td style="text-align: center; width: 100px; white-space: nowrap">Desc. Prazo</td>
                            <td style="text-align: center; width: 100px; white-space: nowrap">Desc. Vista</td>
                            <td style="text-align: center; width: 170px; white-space: nowrap">Todos os Produtos</td>
                            <td style="text-align: center; width: 80px; white-space: nowrap" v-if="$store.state.Usuario.incluiProduto">Ação</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="itemLista" v-for="(promocao, index) in Promocoes" :key="index">
                            <td style="color: blue; text-align: center;">
                                <!-- <button :id="index" style="hand-button" v-if="index == indexPromocaoSelecionada" @keydown.tab.prevent="" > 
                                    <i class="fas fa-hand-point-right" style="color: var(--primario)" >
                                    </i>
                                </button> -->
                                {{promocao.cdpromocao}}
                            </td>
                            <td style="text-align: left; white-space: nowrap;">{{promocao.nome}}</td>
							<td style="text-align: center; white-space: nowrap;" 
								v-if="promocao.domingo && promocao.segunda && promocao.terca && promocao.quarta && promocao.quinta
								&& promocao.sexta && promocao.sabado && (parseInt(promocao.datafinal) - parseInt(promocao.datainicio) == 1000)"
							>
								Todos os dias
							</td>
							<td style="text-align: center; white-space: nowrap;"
								v-else-if="(parseInt(promocao.datafinal) - parseInt(promocao.datainicio) == 1000) && 
								(promocao.domingo || promocao.segunda || promocao.terca || promocao.quarta || promocao.quinta
								|| promocao.sexta || promocao.sabado)"
							>
								<span v-show="promocao.domingo">Dom &nbsp;</span>
								<span v-show="promocao.segunda">Seg &nbsp;</span>
								<span v-show="promocao.terca">Ter &nbsp;</span>
								<span v-show="promocao.quarta">Qua &nbsp;</span>
								<span v-show="promocao.quinta">Qui &nbsp;</span>
								<span v-show="promocao.sexta">Sex &nbsp;</span>
								<span v-show="promocao.sabado">Sab</span>
							</td>
                            <td style="text-align: center;" v-else>
								{{new Date((promocao.datainicio || "").split("-")).toLocaleDateString("pt-BR")}}
									-
								{{new Date((promocao.datafinal || "").split("-")).toLocaleDateString("pt-BR")}}
							</td>
                            <td style="text-align: center;">{{(promocao.descontoprazo || 0)}}%</td>
                            <td style="text-align: center;">{{(promocao.descontovista || 0)}}%</td>
                            <td style="text-align: center;">{{promocao.todosprodutos == 'S' ? "Sim" : "Não"}}</td>
                            <td style="text-align: center;" v-if="$store.state.Usuario.incluiProduto">
                                <i class="fas fa-edit" style="color: var(--confirmar)" @click="selecionarPromocao(promocao)"></i>
                                <i class="fas fa-trash" style="color: var(--deletar)" @click="excluirPromocao(promocao)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else id="TabelaPromocao" :class="`tela-consulta-vazia ${!showTable? 'table-full' : ''}`">
            <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
            <h1>Nenhuma promoção encontrada.</h1>
            <h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
        </div>
    </div>

</template>
<script>

export default {
	name: "TabelaPromocao",
	props: {
		showTable: Boolean, 
	},
	computed: {
		Promocoes() {
			return this.$store.state.Promocoes;
		},
	},

	data() {
		return {
			indexPromocaoSelecionada: 0,
		};
	},

	methods: {
		selecionarPromocao(promocao){
			this.$emit("selecionarPromocao", promocao);
		},
		async cancelarPromocao(promocao) {
			const deletado = await this.$store.dispatch("cancelarPromocao", promocao);
			if (!deletado) return;
			this.$emit("filtrarPromocoes");
		},
		excluirPromocao(promocao) {
			this.$store.commit("relatarErro", {
				mensagem: `Tem certeja que deseja cancelar a promoção ${promocao.nome}?`,
				tipo: "polar",
				item: {
					acao: this.cancelarPromocao,
					promocao
				}
			});
			this.$emit("filtrarPromocoes");
		},

		capturarPolarConfirmado(item) {
			if (item.promocao) {
				if (item.acao) {
					item.acao(item.promocao);
				}
			}
		}
	},

	mounted() {
		this.$bus.$on("confirmarPolar", this.capturarPolarConfirmado);
	},

	destroyed() {
		this.$bus.$off("confirmarPolar", this.capturarPolarConfirmado);
	}
};
</script>
<style scoped>
#TabelaPromocao {
    display: flex;
    flex-direction: column;
    width: 98%;
    margin: 5px auto;
    overflow: auto;
	height: 65vh;
}
.tabela-controle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.tabela-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    overflow: auto;
}
#TabelaPromocao.table-full{
	height: 77vh !important;
}
@media screen and (max-width: 768px) {
	#TabelaPromocao.table-full{
		height: 68vh !important;
	}
	#TabelaPromocao{
		height: 43vh !important;
	}
}
@media screen and (max-width: 425px) {
	#TabelaPromocao.table-full{
		height: 71vh !important;
	}
	#TabelaPromocao{
		height: 30vh !important;
	}
}
</style>