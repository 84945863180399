<template>
	<div id="grid-financeiro">
		<div class="container-menu">
			<MenuLateral 
				tipo="Financeiro"
				@consultarContasPagar="mudarMenu('ContasPagar')"
				@consultarPlanoContas="mudarMenu('PlanoContas')"
				@consultarAgendaContas="mudarMenu('AgendaContas')"
				@consultarCentroCustos="mudarMenu('CentroCustos')"
				@consultarExtrato="mudarMenu('Extrato')"
				@consultarDespesas="mudarMenu('Despesas')"
				@consultarReceitas="mudarMenu('Receitas')"
				@consultarImportacao="mudarMenu('Importacao')"
				@consultarFluxoCaixa="mudarMenu('FluxoCaixa')"
			/>
		</div>
		<div class="container-contas">
			<ContasPagar v-if="mostrar == 'ContasPagar'" />
			<PlanoContas v-else-if="mostrar == 'PlanoContas'" />
			<AgendaContas v-else-if="mostrar === 'AgendaContas'" />
			<CentroCustos v-else-if="mostrar === 'CentroCustos'" />
			<Extrato v-else-if="mostrar === 'Extrato'" />
			<Despesas v-else-if="mostrar === 'Despesas'" />
			<Receitas v-else-if="mostrar === 'Receitas'" />
			<Importacao v-else-if="mostrar === 'Importacao'" />
			<FluxoCaixa v-else-if="mostrar === 'FluxoCaixa'" />
		</div>
	</div>
</template>
<script>
import MenuLateral from "../components/MenuLateral.vue";
import ContasPagar from "../components/ContasPagar.vue";
import PlanoContas from "../components/PlanoContas.vue";
import AgendaContas from "../components/AgendaContas.vue";
import CentroCustos from "../components/CentroCustos.vue";
import Importacao from "../components/Conciliacao.vue";
import Extrato from "../components/Extrato.vue";
import Despesas from "../components/Despesas.vue";
import Receitas from "../components/Receitas.vue";
import FluxoCaixa from "../components/FluxoCaixa.vue";
export default {
	name: "Financeiro",
	components: {
		MenuLateral,
		ContasPagar,
		PlanoContas,
		AgendaContas,
		CentroCustos,
		Extrato,
		Despesas,
		Receitas,
		Importacao,
		FluxoCaixa,
	},
	data(){
		return {
			mostrar: "ContasPagar"
		};
	},
	methods: {
		mudarMenu(menu) {
			this.mostrar = menu;
		},
		verificarMenuInQuery() {
			if (this.$route.query) {
				const { menu } = this.$route.query;
				if (menu) {
					this.mostrar = menu;
				}
			}
		}
	},

	mounted() {
		this.verificarMenuInQuery();
	},
};
</script>
<style scoped>
#grid-financeiro {
	width: 100%;
	height: auto;
	max-height: 92vh;
	display: grid;
	grid-template-columns: 120px 1fr;
	grid-template-rows: 1fr;
	grid-template-areas:
		"menu conteudo";
}

@media screen and (max-width: 768px){
	#grid-financeiro{
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 55px calc(100% - 55px);
		grid-template-areas: "menu"
							"conteudo";
		margin: 0px;
		width: 100%;
		height: 100%;
		position: relative;
	}

	#MenuLateral{
		width: 100vw;
		overflow-x: auto;
	}

}

</style>