<template>
    <div class="tela">
        <div class="dummy-text">
            <table>
                <thead>
                    <tr>
                        <th colspan="5" style="text-align: left;">
                            <i class="fas fa-times interno" style="color: var(--deletar); text-align: right; font-size: 18pt"
                            @click="$emit('fecharModal')" v-if="$route.name != 'SelecionaCaixa'"></i>
                            <span class="col w-100" style="text-align: center; font-size: 16pt">Selecione o caixa para trabalhar</span>
                        </th>
                    </tr>
                    <tr>
                        <td>Código</td>
                        <td>Identificador</td>
                        <td>Data do ultimo fechamento</td>
                        <td>Aberto</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(caixa, index) in Caixas" :key="index" @click="selecionarCaixa(caixa, index)">
                        <td><i class="fas fa-hand-point-right" style="color: var(--primario)" v-if="index == caixaSelecionado"></i> {{caixa.cdCaixa}}</td>
                        <td style="text-align: left">{{caixa.identificador}}</td>
                        <td style="text-align: center">
                            {{ caixa.dataUltimoFechamento ?
                                new Date(
                                    new Date(caixa.dataUltimoFechamento).getFullYear(),
                                    new Date(caixa.dataUltimoFechamento).getMonth(),
                                    new Date(caixa.dataUltimoFechamento).getDate() + 1,
                                ).toLocaleDateString("pt-BR")
                                : "Caixa novo"
                            }}
                        </td>
                        <td style="text-align: center">
                            <span v-if="caixa.aberto == 'S'"><i class="fas fa-store-alt" style="color: var(--confirmar)"></i> &nbsp; Aberto </span>
                            <span v-else > <i class="fas fa-store-alt-slash" style="color: var(--deletar)"></i> &nbsp; Fechado</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Modal 
			v-if="novoCaixa" 
			@fecharModal="solicitaConsulta" 
			:dimensoes="{width: '50%', height: 'auto'}"
			:idModal="'abertura-caixa'"
		>
            <AberturaCaixa 
				:caixa="novoCaixa" 
				@cancelar="solicitaConsulta" 
			/>
        </Modal>
    </div>
</template>

<script>
import Modal from "./Modal.vue";
import AberturaCaixa from "./AberturaCaixa.vue";

export default {
	name: "SelecionaCaixa",

	components: {
		Modal,
		AberturaCaixa
	},

	data() {
		return {
			novoCaixa: false,
			caixaSelecionado: 0,
			selecionouCaixa: false,
		};
	},

	computed: {
		Caixas(){
			return this.$route.name == "SelecionaCaixa" ? this.$store.state.Caixas.content.filter(caixa => caixa.cdEmpresa == this.$store.state.Empresa.id && caixa.cdcaixa > 0) : 
				this.$store.state.Caixas.content.filter(caixa => caixa.cdEmpresa == this.$store.state.Empresa.id && caixa.aberto == "S");
		}
	},

	methods: {
		async selecionarCaixa(caixa, index) {
			if (caixa.aberto == "S") {
				this.caixaSelecionado = index;
				this.selecionouCaixa = true;
				await this.$store.dispatch("buscarCaixaPorIdentificador", {...caixa});
				this.$emit("fecharModal");
			} else {
				this.novoCaixa = caixa;
			}
		},

		atalhosTeclado(e) {
			if (e.key === "Escape"){
				this.$emit("fecharModal");
			}
			if (this.selecionouCaixa) return;
			if (e.key == "ArrowUp") {
				if (this.caixaSelecionado != 0) {
					this.caixaSelecionado--;
				}
			}
			if (e.key == "ArrowDown") {
				if (this.caixaSelecionado < this.Caixas.length -1) {
					this.caixaSelecionado++;
				}
			}
			if (e.key == "Enter") {
				this.selecionarCaixa(this.Caixas[this.caixaSelecionado], this.caixaSelecionado);
			}
		},

		solicitaConsulta() {
			if (this.$store.state.Usuario.supervisor) {
				document.removeEventListener("keydown", this.atalhosTeclado);
				this.$store.commit("relatarErro", {
					mensagem: "Deseja acessar mesmo com o caixa fechado?",
					tipo: "polar",
					item: {...this.novoCaixa}
				});
			}
			this.novoCaixa = null;
		},

		entrarComCaixaFechado(item) {
			this.novoCaixa = null;
			this.$store.dispatch("buscarCaixaPorIdentificador", {...item});
		},

		reiniciarEventos() {
			document.addEventListener("keydown", this.atalhosTeclado);
		}
	},

	created(){
		document.addEventListener("keydown", this.atalhosTeclado);
		this.$bus.$on("confirmarPolar", this.entrarComCaixaFechado);
		this.$bus.$on("cancelarPolar", this.reiniciarEventos);
		this.$store.state.carregando = false;
	},

	destroyed(){
		document.removeEventListener("keydown", this.atalhosTeclado);
		this.$bus.$off("confirmarPolar", this.entrarComCaixaFechado);
		this.$bus.$off("cancelarPolar", this.reiniciarEventos);
	}
};
</script>

<style scoped >
thead tr th {
    font-size: 12pt;
}
tbody tr {
	line-height: 30px;
}
tbody tr td {
	font-size: 11pt;
}
table {
    background-color: var(--bg-app);
    border-radius: 10px;
    position: relative;
}

th {
    box-shadow: var(--shadow-secundari);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
}

.fas.fa-times.interno {
    color: var(--cancelar);
    font-size: var(--txt-grande);
    position: absolute;
    top: 2px;
    right: 2px;
}

@media screen and (max-width: 768px) {
    .tela, * {
        font-size: var(--txt-pequeno);
        overflow: auto;
    }
}
</style>