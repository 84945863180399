<template>
    <div id="CadastroGrupoProduto">
        <h1 class="titulo">Manutenção de Grupos</h1>
		<fieldset class="br-5">
			<legend>
				Grupo
				&nbsp;
				<i class="fas fa-trash" @click="deletarGrupo" style="color: var(--deletar)"></i>
			</legend>
			<div class="box-cadastro-linha">
				<div class="box-cadastro-linha-col">
					<div class="input-group box-cadastro-item">
						<input 
						id="descricao" @input="mascara" type="text" 
						v-model="grupoProdutoCadastrado.descricao" autocomplete="off"
						@keydown.enter="proximoCampo('ncm')" placeholder=" "
						>
						<label for="descricao" >Descrição&nbsp;</label>
					</div>
					<div class="input-group box-cadastro-item">
						<input 
						id="ncm" @input="mascaraInteger" type="text" v-model="grupoProdutoCadastrado.ncm"
						autocomplete="off" maxlength="8" placeholder=" "   
						@keydown.enter="proximoCampo(grupoProdutoCadastrado.subGrupos.length ? 'subGrupoInput-0' : 'descricao')"
						>
						<label for="ncm">NCM&nbsp;</label>
					</div>
				</div>
				<div class="box-cadastro-item-opcao">
					<div class="funcionalidade-atual" style="max-width: 160px">
						<label class="mensagem-opcao" for="tipo">
							Tipo:&nbsp;{{grupoProdutoCadastrado.tipo ? "Produto" : "Serviço"}}
						</label>
						<div class="checked">
							<div class="selecionar" style="max-width: 80px">
								<input v-model="grupoProdutoCadastrado.tipo" :value="true" id="tipo" type="checkbox">
								<label for="tipo" class="check"></label>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div class="box-cadastro-item">
				<fieldset class="br-5" style="margin-left: 10px;">
					<legend>
						Sub Grupos
						<i class="far fa-plus-square" v-if="grupoProdutoCadastrado.subGrupos.length == 0"
						@click="adicionarSubGrupo(0)" style="color: var(--confirmar)"></i>
						<span class="pl-5" v-else>{{grupoProdutoCadastrado.subGrupos.length}}</span>
					</legend>
						<div class="subgrupo-list">
							<fieldset 
								v-for="(subGrupo, indice) in grupoProdutoCadastrado.subGrupos" 
								:key="indice" class="br-5"
							>
								<legend style="text-align: center;">
									<label :for="`subGrupoInput-${indice}`">Subgrupo {{indice+1}}:</label>
									<i class="far fa-plus-square" @click="adicionarSubGrupo(indice)" style="color: var(--confirmar)"
									v-if="grupoProdutoCadastrado.subGrupos.length -1 == indice"></i>
									<i class="fas fa-backspace" @click="removerNovoSubGrupo(indice)" style="color: var(--deletar)"
									v-if="grupoProdutoCadastrado.subGrupos.length -1 == indice"></i>
									<i class="fas fa-trash" @click="removerSubGrupo(indice)" style="color: var(--deletar)"
									v-else></i>
								</legend>
									<div class="subgrupo-list-item">
										<div class="input-group box-cadastro-item">
											<input 
											@input="mascara" :id="`subGrupoInput-${indice}`" type="text" 
											v-model="subGrupo.descricao" @keydown.tab.prevent="proximoCampo(`ncm-${indice}`)"
											@keydown.enter="proximoCampo(`ncm-${indice}`)" autocomplete="off" maxlength="20"
											placeholder=" "
											>
											<label :for="`subGrupoInput-${indice}`">Descrição&nbsp;</label>
										</div>
										<div class="input-group box-cadastro-item">
											<input 
											@input="mascaraIntegerSubgrupo" :id="`ncm-${indice}`" type="text" v-model="subGrupo.ncm" 
											@keydown.tab.prevent="adicionarSubGrupo(indice)"
											@keydown.enter="adicionarSubGrupo(indice)" autocomplete="off" maxlength="8"
											placeholder=" "
											>
											<label :for="`ncm-${indice}`">NCM&nbsp;</label>
										</div>
									</div>
							</fieldset>
						</div>
				</fieldset>
			</div>
		</fieldset>
        <div id="botoes" class="botoes-controle">
            <button @click="cancelarEdicao" class="botao botao-cancelar">
                <i class="fas fa-times" style="color: var(--cancelar-borda);"></i>
                Cancelar
            </button>
            <button class="botao botao-cancelar" @click="deletarGrupo">
                <i class="fas fa-trash-alt" style="color: var(--cancelar-borda);"></i>
                Deletar
            </button>
            <button @click="editarGrupoProduto" class="botao botao-adicionar">
                <i class="fa fa-check" style="color: var(--adicionar-borda);"></i>
                Salvar
            </button>
        </div>
    </div>
</template>
<script>
export default {
	name: "CadastroGrupoProduto",

	props: {
		grupoProduto: Object,
	},
	computed: {
		grupoSelecionado(){
			return this.$store.state.grupoSelecionado;
		},
	},

	data(){
		return {
			grupoProdutoCadastrado: {
				cdGrupo: "",
				descricao: "",
				subGrupos: [
					{
						descricao: "",
						ncm: ""
					}
				],
				tipo: true,
				ncm: ""
			},
		};
	},

	methods: {
		mascara(evento){
			const id = evento.target.id;
			if (id == "descricao") {
				return this.grupoProdutoCadastrado[id] = this.grupoProdutoCadastrado[id].toUpperCase();
			}
			if (id.split("-")[0] == "subGrupoInput" ) {
				return this.grupoProdutoCadastrado.subGrupos[id.split("-")[1]].descricao = this.grupoProdutoCadastrado.subGrupos[id.split("-")[1]].descricao.toUpperCase();
			}
		},

		mascaraInteger(evento) {
			const id = evento.target.id;
			const regex = /[0-9]/;
			if (!regex.test(evento.data)) {
				return this.grupoProdutoCadastrado[id] = this.grupoProdutoCadastrado[id].substring(0,this.grupoProdutoCadastrado[id].length -1);
			}
			if (id == "ncm") {
				this.grupoProdutoCadastrado.subGrupos.forEach(sub => {
					sub.ncm = this.grupoProdutoCadastrado.ncm;
				});
			}
		},

		mascaraIntegerSubgrupo(evento) {
			const index = evento.target.id.split("-")[1];
			const campo = evento.target.id.split("-")[0];
			const regex = /[0-9]/;
			if (!regex.test(evento.data)) {
				return this.grupoProdutoCadastrado.subGrupos[index][campo] = this.grupoProdutoCadastrado.subGrupos[index][campo].substring(0,this.grupoProdutoCadastrado.subGrupos[index][campo].length -1);
			}
		},

		async editarGrupoProduto() {
			if (!this.grupoProdutoCadastrado.descricao || this.grupoProdutoCadastrado.descricao == " ") {
				return this.$store.commit("relatarErro", {mensagem: "Campo descrição não pode estar vazio"});
			}
			let deuErro = false;
			if(this.grupoProdutoCadastrado.subGrupos.length>1){
				this.grupoProdutoCadastrado.subGrupos.forEach(subgrupo=>{
					if (!subgrupo.descricao || subgrupo.descricao == " ") {
						deuErro = true;
						return;
					}
				});
			}
			if(deuErro){
				return this.$store.commit("relatarErro", {mensagem: "Campo descrição não pode estar vazio"});
			}
			this.grupoProdutoCadastrado.subGrupos = this.grupoProdutoCadastrado.subGrupos.filter(subGrupo => subGrupo.descricao != "");
			let grupoCadastrado = null;
			if (this.grupoProduto && this.grupoProduto.novo) {
				grupoCadastrado = await this.$store.dispatch("adicionarGrupoProduto", {...this.grupoProdutoCadastrado, tipo: this.grupoProdutoCadastrado.tipo ? "P" : "S"});
			} else if (JSON.stringify(this.grupoProdutoCadastrado) != JSON.stringify(this.grupoProduto)) {
				grupoCadastrado = await this.$store.dispatch("atualizarGrupoProduto", {...this.grupoProdutoCadastrado, tipo: this.grupoProdutoCadastrado.tipo ? "P" : "S"});
			}
			if (!grupoCadastrado) return;
			if (!grupoCadastrado.cdGrupo) return;
			this.$emit("cadastrouGrupo", grupoCadastrado);
			this.cancelarEdicao();
		},

		adicionarSubGrupo(index) {
			if (index == 0) {
				this.grupoProdutoCadastrado.subGrupos.push({ descricao: "", ncm: this.grupoProdutoCadastrado.ncm || ""});
				setTimeout(() => {
					document.getElementById(`subGrupoInput-${index}`).focus();
				}, 100);
			}
			if (!this.grupoProdutoCadastrado.subGrupos[index].descricao) {
				return;
			}
			if (this.grupoProdutoCadastrado.subGrupos.length -1 == index) {
				this.grupoProdutoCadastrado.subGrupos.push({ descricao: "", ncm: this.grupoProdutoCadastrado.ncm || "" });
				setTimeout(() => {
					document.getElementById(`subGrupoInput-${index + 1}`).focus();
				}, 50);
			}
			else {
				setTimeout(() => {
					document.getElementById(`subGrupoInput-${index + 1}`).focus();
				}, 100);
			}
		},

		deletarGrupo() {
			if (!this.grupoProdutoCadastrado.descricao) return;

			if (this.grupoProdutoCadastrado.cdGrupo) {
				this.$store.dispatch("deletarGrupoProdutos", {...this.grupoProdutoCadastrado});
			}
			this.cancelarEdicao();
		},

		async removerNovoSubGrupo(index) {
			let resposta;
			if (this.grupoProdutoCadastrado.cdGrupo && this.grupoProdutoCadastrado.subGrupos[index].cdsubgrupo) {
				resposta = await this.$store.dispatch("deletarSubGrupo", {
					cdGrupo: this.grupoProdutoCadastrado.cdGrupo,
					cdsubgrupo: this.grupoProdutoCadastrado.subGrupos[index].cdsubgrupo,
				});
			}
			if (!resposta.erro) {
				this.grupoProdutoCadastrado.subGrupos.splice(index, 1);	
			} else
				this.$store.commit("relatarErro", {mensagem: resposta.mensagem});
		},

		async removerSubGrupo(index) {
			if (this.grupoProdutoCadastrado.cdGrupo && this.grupoProdutoCadastrado.subGrupos[index].cdsubgrupo) {
				const resposta = await this.$store.dispatch("deletarSubGrupo", {
					cdGrupo: this.grupoProdutoCadastrado.cdGrupo,
					cdsubgrupo: this.grupoProdutoCadastrado.subGrupos[index].cdsubgrupo,
				});
				if (!resposta.erro) {
					this.grupoProdutoCadastrado.subGrupos.splice(index, 1);
				} else
					this.$store.commit("relatarErro", {mensagem: resposta.mensagem});
			}
		},

		proximoCampo(id) {
			const campo = document.getElementById(id);
			if (campo) {
				campo.focus();
				if (campo.select) {
					campo.select();
				}
			}
		},

		eventosTeclado(e) {
			if (e.key.toUpperCase() == "S" && e.altKey) {
				this.editarGrupoProduto();
			}
			if (e.key.toUpperCase() == "D" && e.altKey) {
				this.deletarGrupo();
			}
			if (e.key.toUpperCase() == "C" && e.altKey) {
				this.cancelarEdicao();
			}
		},

		cancelarEdicao() {
			this.grupoProdutoCadastrado = {
				cdGrupo: "",
				descricao: "",
				subGrupos: [],
				tipo: true,
				ncm: ""
			};
			this.$emit("voltarlista");
			this.$emit("fecharModal");
		},

		preencherData(grupo) {
			this.grupoProdutoCadastrado = {
				cdGrupo: grupo.cdGrupo,
				descricao: grupo.descricao,
				subGrupos: grupo.subGrupos,
				tipo: grupo.tipo == "P",
				ncm: grupo.ncm,
			};
		},

		async iniciarComponente() {
			if (this.grupoProduto.novo) return;
			this.$store.state.carregando = true;
			const grupo = await this.$store.dispatch("buscarUnicoGrupo", this.grupoProduto.grupo || this.grupoProduto.cdGrupo);
			setTimeout(() => {
				if (grupo?.cdGrupo) {
					this.preencherData(grupo);
				}
				this.$store.state.carregando = false;
				document.getElementById("descricao") .focus();
			},100);
		}
	},

	created() {
		this.iniciarComponente();
	},
};
</script>

<style scoped>

input {
	display: flex;
	flex-grow: 1;
	max-height: max-content;
}

#CadastroGrupoProduto {
    display: flex;
	flex-direction: column;
    width: 95%;
    height: auto;
    margin: 0px 5px;
    overflow: hidden;
	flex-grow: 1;
}
.box-cadastro-linha {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.box-cadastro-linha-col {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.box-cadastro-linha-col .box-cadastro-item {
	width: 100%;
} 
.box-cadastro-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
	margin-top: 5px;
    width: auto;
}
.box-cadastro-item:nth-child(3) {
	height: max-content;
	overflow-y: auto;
	height: 100%;
}
.box-cadastro-item-opcao {
	display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    margin-left: 6px;
    margin-right: 6px;
}
.box-cadastro-item label {
    width: 100px;
    text-align: right;
    user-select: none;
}
.funcionalidade-atual {
	margin: 0px;
}
#botoes {
    padding: 5px 0px;
    display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.subgrupo-list {
    display: flex;
    flex-direction: column;
    position: relative;
	flex-grow: 1;
}
.subgrupo-list span {
    user-select: none;
}
.subgrupo-list-item {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.subgrupo-list-item label {
    width: 100px;
}
.subgrupo-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

fieldset  {
	display: flex;
	flex-direction: column;
	padding: 3px 5px;
	justify-content: flex-start;
	width: 100%;
	flex-grow: 1;
	height: 100%;
	overflow-y: auto;
}

fieldset legend {
	margin-left: 1rem;
	padding: 0 .5rem;
	cursor: default;
	align-self: flex-start;
	align-items: flex-start;
	position: relative;
	text-align: left;
}

.br-5 {
	border: solid 1px rgba(107, 107, 107, 0.795);
	border-radius: 5px !important;
}

.input-group {
	height: auto;
}

.botoes-controle {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	gap: 15px;
	margin-bottom: 15px;
	margin-top: 5px;
}

.botao {
	padding: 2px 2px;
}

.botao-deletar {
	background-color:#e22828;
	color: #660c0c;
	border: 3px solid #9e1414;	
}

.input-group label {
	width: auto;
	text-align: start;
	height: 10px;
	line-height: 0px;
	margin-top: 9px;
}

@media screen and (max-width: 768px) {
    #botoes button {
        font-size: 12pt;
    }
    #botoes button i {
        display: none;
    }
}
@media screen and (max-width: 450px) {
    .subgrupo-list-item {
        flex-direction: column;
        position: relative;
        justify-content: flex-start;
    }
    .subgrupo-list-item label {
        text-align: left;
    }
	.titulo {
        font-size: 18pt;
    }
}
</style>
