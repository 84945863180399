<template>
	<div class="container-page">
		<div class="container-filtro">
			<div class="row">
				<div class="input-group">
					<input 
						type="date"
						id="dataI"
						placeholder=" "
						v-model="Filtro.dataI"
					>
					<label for="dataI">Data inicial</label>
				</div>
				<div class="input-group">
					<input 
						type="date"
						id="dataF"
						placeholder=" "
						v-model="Filtro.dataF"
					>
					<label for="dataF">Data termino</label>
				</div>
				<div class="input-group">
					<select v-model="Filtro.empresa" id="empresa">
						<option value="SEL" disabled>SELECIONE UMA OPÇÃO</option>
						<option v-for="(emp, index) in Empresas" :key="index" :value="emp.id">
							{{ emp.nome }}
						</option>
					</select>
					<label for="empresa">Empresa</label>
				</div>
				<button class="botao botao-acao" @click="filtrar" v-if="!loading">
					<span >
						Filtrar
					</span>
				</button>
				<button class="botao botao-acao" v-else>
					<div class="spinner-filtro"></div>
				</button>

			</div>
		</div>
		<div class="container-cards">
			<card-dashboard 
				v-for="(key, index) in Object.keys(Totais)" 
				:key="index" 
				:label="key"
				:value="Totais[key]"
				:loading="loading"
				@buscarVendasClienteDevedor="buscarVendasClienteDevedor"
				@buscarVendasDetalhadas="buscarVendasDetalhadas"
			/>
		</div>
		<div class="container-graphs">
			<div class="chart-pagamento" v-if="!loading && Totais['ITENS VENDIDO'] || Totais['ITENS VENDIDO'] === 0">
				<PieChartPagamentos class="pie-chart"/>
			</div>
			<div class="chart-pagamento-loader" v-else-if="loading">
				<Loader :width="'200px'" :height="'200px'" :idSpinner="'pagamento-loader'"/>
			</div>
			<div class="tabela-mais-vendidos" v-if="!loading && Totais['ITENS VENDIDO'] || Totais['ITENS VENDIDO'] === 0">
				<div class="header-tabela">
					<div class="linha-header-tabela">
						<div class="campo-tabela campo-menor">Código</div>
						<div class="campo-tabela campo-maior">Descrição</div>
						<div class="campo-tabela campo-menor">Qtd. Saída</div>
					</div>
				</div>
				<div class="body-tabela">
					<div class="linha-body-tabela" v-for="(produto, index) in Produtos" :key="index">
						<div class="campo-tabela campo-menor"> {{ produto.cdProduto }} </div>
						<div class="campo-tabela campo-maior"> {{ produto.descricao }} </div>
						<div class="campo-tabela campo-menor"> {{ produto.qtde }} </div>
					</div>
				</div>
			</div>
			<div class="tabela-mais-vendidos-loader" v-else-if="loading">
				<Loader :width="'200px'" :height="'200px'" :idSpinner="'mais-vendidos-loader'"/>
			</div>
			<div class="container-sem-info" v-else>
				<i class="fas fa-exclamation-triangle"
				style="color: yellow; font-size: 90pt" />
				<span>
					Consulta sem registros nesse periodo, por favor ajuste os parametros de busca,
					se persistir entre em contato com o suporte!
				</span>
			</div>
			<!-- <div class="container-sem-info" v-else>
				<Loader :width="'200px'" :height="'200px'" :idSpinner="'sem-info'"/>
			</div> -->
		</div>
		<Modal
			v-if="visualizarVendasClienteDevedor"
			@fecharModal="visualizarVendasClienteDevedor = false"
			:idModal="'modal-vendas-cliente-devedor'"
			:dimensoes="{width: '62%', height: '60%'}"
		>
			<div class="container-modal">
				<div class="container-filtro-modal">
					<div class="input-group">
						<select 
							id="filtroDevedores"
							placeholder=" "
							v-model="filtroDevedores"
						>
							<option value="SC">Sem crédito</option>
							<option value="DA">Dias em atraso</option>
							<option value="ALL">Todos</option>
						</select>
						<label for="filtroDevedores">Filtro por</label>
					</div>
					<button class="botao botao-acao" @click="filtrarDevedores">
						Filtrar
					</button>
				</div>
				<div class="container-table" v-if="(VendasClienteDevedor && VendasClienteDevedor.length > 0)">
					<table v-if="!loadingClienteDevedores">
						<thead>
							<tr>
								<th>Cód. Cliente</th>
								<th>Nome Cliente</th>
								<th>Crédito</th>
								<th>Crédito usado</th>
								<th>Dias em atraso</th>
								<th>Qtd. Condicional</th>
								<th>Qtd. Venda</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(cliente, index) in VendasClienteDevedor" :key="index">
								<td>
									{{ cliente.cdpessoa }}
								</td>
								<td>
									{{ cliente.nome }}
								</td>
								<td>
									{{ (cliente.credito || 0) | formatarParaReais }}
								</td>
								<td>
									{{ (cliente.creditousado || 0) | formatarParaReais }}
								</td>
								<td>
									{{ cliente.diasvencido }}
								</td>
								<td>
									{{ (cliente.qtdeCondicional || 0) }}
								</td>
								<td>
									{{ (cliente.qtdevenda || 0) }}
								</td>
							</tr>
						</tbody>
					</table>
					<div class="container-loader-devedores" v-else>
						<Loader :width="'200px'" :height="'200px'" :idSpinner="'devedor-loader'"/>
					</div>
				</div>
				<div class="container-sem-info" v-else>
					<i
						class="fas fa-exclamation-triangle"
						style="color: yellow; font-size: 90pt"
					/>
					<span>
						Consulta sem registros nesse periodo, por favor ajuste os parametros de busca,
						se persistir entre em contato com o suporte!
					</span>
				</div>
				<button
					class="botao botao-cancelar"
					@click="visualizarVendasClienteDevedor = false"
				>
					Fechar
				</button>
			</div>
		</Modal>
		<Modal
			v-if="visualizarVendasDetalhadas"
			@fecharModal="visualizarVendasDetalhadas = false"
			:idModal="'modal-vendas-detalhadas'"
			:dimensoes="{width: '60%', height: '60%'}"
		>
			<div class="container-modal">
				<div class="container-table" v-if="VendasDetalhadasDashboard && VendasDetalhadasDashboard.length > 0">
					<table>
						<thead>
							<tr>
								<th>Data</th>
								<th>Qtd. Itens</th>
								<th>Qtd. Vendas</th>
								<th>Vlr. Troca</th>
								<th>Vlr. Venda</th>
								<th class="pagamento">Pagamentos</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(venda, index) in VendasDetalhadasDashboard" :key="index">
								<td>
									{{ venda.data.split("-").reverse().join("/") }}
								</td>
								<td>
									{{ venda.qtdeItens }}
								</td>
								<td>
									{{ venda.qtdeVenda }}
								</td>
								<td>
									{{ (venda.valorTroca || 0) | formatarParaReais }}
								</td>
								<td>
									{{ (venda.valorVenda || 0) | formatarParaReais }}
								</td>
								<td class="pagamento">
									<span v-for="(pgto, index) in Object.keys(venda.pagamentos)" :key="index" class="pagamentos-for">
										<span>
											{{ pgto }}: {{ (venda.pagamentos[pgto] || 0) | formatarParaReais }}
										</span>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="container-sem-info" v-else>
					<i class="fas fa-exclamation-triangle"
					style="color: yellow; font-size: 90pt" />
					<span>
						Consulta sem registros nesse periodo, por favor ajuste os parametros de busca,
						se persistir entre em contato com o suporte!
					</span>
				</div>
				<button class="botao botao-cancelar" @click="visualizarVendasDetalhadas = false">
					Fechar
				</button>
			</div>
		</Modal>

		
	</div>
</template>
<script>
import CardDashboard from "../components/CardDashboard.vue";
import PieChartPagamentos from "../components/PieChartPagamentos.vue";
import Loader from "../components/Loader.vue";
import Modal from "../components/Modal.vue";
import { mapState } from "vuex";

import formatarParaReais from "../filters/formatarParaMoeda";
export default {
	name: "Dashboard",
	components: {
		CardDashboard,
		PieChartPagamentos,
		Loader,
		Modal
	},
	data() {
		return {
			Filtro: {
				empresa: "",
				dataI: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString("en-CA"),
				dataF: new Date().toLocaleDateString("en-CA"),
			},
			loading: true,
			visualizarVendasClienteDevedor: false,
			visualizarVendasDetalhadas: false,
			filtroDevedores: "ALL",
			VendasClienteDevedorOriginal: [],
			loadingClienteDevedores: false,
		};
	},
	mounted() {
		this.Filtro.empresa = this.Empresa.id;
		this.filtrar();
	},
	computed: {
		...mapState([
			"Empresa",
			"Empresas",
			"VendasClienteDevedor",
			"VendasDetalhadasDashboard"
		]),
		Totais() {
			return this.$store.state.Relatorios.Dashboard.Totais["ITENS VENDIDO"] || this.$store.state.Relatorios.Dashboard.Totais["ITENS VENDIDO"] === 0 ? this.$store.state.Relatorios.Dashboard.Totais : 
				{
					"S/INFORMAÇÃO ": 0,
					"S/INFORMAÇÃO  ": 0,
					"S/INFORMAÇÃO   ": 0,
					"S/INFORMAÇÃO    ": 0,
					"S/INFORMAÇÃO     ": 0,
					"S/INFORMAÇÃO      ": 0,
					"S/INFORMAÇÃO       ": 0,
					"S/INFORMAÇÃO        ": 0,
					"S/INFORMAÇÃO         ": 0,
					"S/INFORMAÇÃO          ": 0,
				};
		},
		Produtos() {
			return this.$store.state.Relatorios.Dashboard.Produtos;
		}
	},

	filters: {
		formatarParaReais
	},

	methods: {
		filtrarDevedores() {
			this.loadingClienteDevedores = true;
			const filtros = {
				"DA": this.VendasClienteDevedorOriginal.filter(cliente => cliente.diasvencido > 0), // DIAS EM ATRASO
				"SC": this.VendasClienteDevedorOriginal.filter(cliente => cliente.creditousado > cliente.credito), // SEM CREDITO
				"ALL": this.VendasClienteDevedorOriginal
			};
			this.$store.state.VendasClienteDevedor = filtros[this.filtroDevedores];
			setTimeout(() => {
				this.loadingClienteDevedores = false;
			}, 200);
		},

		async filtrar() {
			try {
				this.loading = true;
				await this.$store.dispatch("buscarRelatorioDashboard", {...this.Filtro});
				this.loading = false;
			} catch (error) {
				console.log(error);
			}
		},

		async buscarVendasClienteDevedor() {
			this.visualizarVendasClienteDevedor = true;
			const listaOriginal = await this.$store.dispatch("filtrarVendasClienteDevedor", {...this.Filtro});
			this.VendasClienteDevedorOriginal = listaOriginal;
		},

		async buscarVendasDetalhadas() {
			this.visualizarVendasDetalhadas = true;
			await this.$store.dispatch("filtrarVendasDetalhadas", {...this.Filtro});
		}
	},
};
</script>
<style scoped>
.input-group{
	height: 33px;
}
.input-group input{
	height: 33px;
	font-size: 13px;
}
.input-group select{
	height: 33px;
	font-size: 13px;
}
*{
	font-size: 13px;
}
.container-page {
	width: 90vw;
	height: 90vh;
	overflow-x: hidden;
	gap: 20px;
	padding: 5px 12px;
}
.container-cards{
	display: flex;
	width: 100%;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	flex-grow: 1;
}

.container-filtro {
	margin-top: 15px;
}

.botao-acao{
	width: 150px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner-filtro {
	border: 3px solid #FFF;
	border-left-color: var(--acao-background);
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 1s linear infinite;
}

.container-pagamentos {
	width: 300px;
}
.pie-chart {
	width: 550px;
	height: 350px;
}
.container-graphs{
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	padding: 10px;
	gap: 35px;
}
.chart-pagamento,
.chart-pagamento-loader {
	width: 42%;
	height: 350px;
	border: 2px solid #cecece;
}
.chart-pagamento-loader {
	display: flex;
	height: 400px;
	justify-content: center;
	align-items: center;
}

.chart-pagamento {
	transition: animation .5s ease-in;
	animation: marginLeft .5s;
}

.tabela-mais-vendidos{
	width: 55%;
	overflow: auto;
	height: 350px;
	display: flex;
	border: 2px solid #cecece;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	transition: animation .5s ease-in;
	animation: marginLeft .5s;
}
.pagamento{
	width: 325px !important;
}

.container-filtro-modal{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.container-loader-devedores{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

@keyframes marginLeft {
	from {
		margin-left: 200px;
		opacity: 0;
	}
	to {
		margin-left: 0px;
		opacity: 1;
	}
}
.tabela-mais-vendidos-loader {
	width: 55%;
	border: 2px solid #cecece;
	display: flex;
	justify-content: center;
	align-items: center;
}

.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 2px 5px;
}
.header-tabela, .body-tabela{
	font-size: 13px;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}

.campo-menor{
	width: 125px;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
}

.campo-maior{
	width: 350px;
	max-width: 425px !important;
	overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;
	margin-left: 15px;
	font-size: 14px;
}
.container-sem-info{
	width: 100%;
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.container-sem-info span {
	font-size: 18px;
	font-weight: bold;
	width: 40%;
	text-align: center;
}

.container-table {
	width: 100%;
	padding: 20px 0px;
	height: 50vh;
	overflow-y: auto;
}

.container-table table thead tr th,
.container-table table tbody tr td{
	width: 145px;
}

.container-modal{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.botao-cancelar {
	width: 100%;
}

.pagamentos-for{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0px 5px;
}
@media screen and (max-width: 1360px) {
	.container-graphs {
		gap: 20px;
	}
	.chart-pagamento {
		width: 50%;
	}
}

</style>