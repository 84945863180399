<template>
	<ContainerPage>
		<ContentPage>
			
			<Filtro
				@handleShowTable="handleShowTable"
				@filtrar="filtrar"
				@limpar="limpar"
			>
				<div class="column">
					<div class="row">
						<div class="input-group">
							<input 
								type="date"
								id="dataI"
								v-model="Filtro.dataI"
								placeholder=" "
							/>
							<label for="dataI">
								Data Início
							</label>
						</div>
						<div class="input-group">
							<input 
								type="date"
								id="dataF"
								v-model="Filtro.dataF"
								placeholder=" "
							/>
							<label for="dataF">
								Data Final
							</label>
						</div>
						<div class="input-group">
							<select v-model="Filtro.conta" id="conta">
								<option value="SEL" disabled>SELECIONE UMA CONTA</option>
								<option v-for="(conta, index) in Contas" :key="index" :value="{agencia : conta.agencia, banco : conta.banco, conta : conta.cc}">
									{{ conta.nome }}
								</option>
							</select>
							<label for="conta">
								Conta
							</label>
						</div>
					</div>
				</div>
			</Filtro>
			<div :style="{height:this.height}">
				<div class="container-tabela" v-if="Extrato.length > 0" >
					<div class="header-tabela">
						<div class="linha-header-tabela">					
							<div class="campo-tabela campo-codigo">
								<span>Nº Doc</span>
							</div>
							<div class="campo-tabela campo-data">
								<span>Lancamento</span>
							</div>
							<div class="campo-tabela campo-codigo">
								<span>Cliente</span>
							</div>
							<div class="campo-tabela campo-descricao">
								<span>Favorecido</span>
							</div>
							<div class="campo-tabela campo-data">
								<span>Baixa</span>
							</div>
							<div class="campo-tabela campo-menor">
								<span>Credito</span>
							</div>
							<div class="campo-tabela campo-menor">
								<span>Debito</span>
							</div>
							<div class="campo-tabela campo-menor">
								<span>Saldo</span>
							</div>
							<div class="campo-tabela campo-menor">
								<span>Tipo</span>
							</div>
							<div class="campo-tabela campo-medio">
								<span>Observação</span>
							</div>
						</div>
					</div>
					<div class="body-tabela">						
						<div v-for="(e, index) in Extrato" :key="index">
							<div class="linha-body-tabela" style="height: 5px;background-color: #cecece;" v-if="index>0?(Extrato[index].databaixa !== Extrato[index-1].databaixa):false">
							</div>
							<div class="linha-body-tabela">
								<div class="campo-tabela campo-codigo">
									{{e.doc?e.doc:""}}
								</div>
								<div class="campo-tabela campo-data">
									{{e.datalanc?dateFormatBR(e.datalanc):""}}
								</div>
								<div class="campo-tabela campo-codigo">
									{{e.cliente?e.cliente:""}}
								</div>
								<div class="campo-tabela campo-descricao">
									{{e.favorecido?e.favorecido:""}}
								</div>
								<div class="campo-tabela campo-data">
									{{e.databaixa?dateFormatBR(e.databaixa):""}}
								</div>
								<div class="campo-tabela campo-menor">
									{{e.credito!=null?e.credito.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}):""}}
								</div>
								<div class="campo-tabela campo-menor">
									{{e.debito!=null?e.debito.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}):""}}
								</div>
								<div class="campo-tabela campo-menor">
									{{e.saldo!=null?e.saldo.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}):""}}
								</div>
								<div class="campo-tabela campo-menor">
									{{e.moeda?e.moeda:""}}
								</div>
								<div class="campo-tabela campo-medio">
									{{e.observa?e.observa:""}}
								</div>
							</div>							
						</div>
					</div>
				</div>
			</div>
		</ContentPage>
	</ContainerPage>
</template>
<script>
import ContainerPage from "../components/ContainerPage.vue";
import Filtro from "../components/Filtro.vue";
import ContentPage from "../components/ContentPage.vue";
import { dateFormatBR } from "../utils/dateformat";
export default {
	name: "Extrato",
	components: {
		ContainerPage,
		ContentPage,
		Filtro
	},
	computed: {
		Contas(){
			return this.$store.state.Contas.filter(c => c.ativo === "A");
		},
		Extrato(){
			this.atualiza;
			if(this.$store.state.Extrato){
				return this.$store.state.Extrato;
			}
			else{
				return [];
			}
		},
		isMobileDevice() {
			const userAgent = navigator.userAgent;
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
		},
	},
	data() {
		return {
			dateFormatBR,
			atualiza:false,
			height: "",
			Filtro: {
				dataI: new Date(new Date().getFullYear(),new Date().getMonth(),1).toLocaleDateString("en-GB").split("/").reverse().join("-"),
				dataF: new Date(new Date().getFullYear(),new Date().getMonth()+1,0).toLocaleDateString("en-GB").split("/").reverse().join("-"),
				conta: "SEL",
			},
		};
	},
	methods: {
		handleShowTable() {
			if(this.isMobileDevice){
				if(this.height === "30vh") {
					this.height = "60vh";
				} else {
					this.height = "30vh";
				}
			} else {
				if(this.height === "70vh") {
					this.height = "80vh";
				} else {
					this.height = "70vh";
				}
			}			
		},

		async filtrar() {
			this.atualiza = true;
			if (this.Filtro.dataF < this.Filtro.dataI) {
				return this.$store.commit("relatarErro", {mensagem: "A data final não pode ser antes da data inicial, verifique!"});
			}
			if(this.Filtro.conta === "SEL"){
				this.atualiza = false;
				return this.$store.commit("relatarErro", {mensagem: "Conta inválida. Selecione a conta que deseja ver o extrato!"});
			}
			else{
				await this.$store.dispatch("filtrarExtrato", {
					...this.Filtro
				});
			}
			this.atualiza = false;
		},
		limpar(){
			this.Filtro = {
				dataI: new Date(new Date().getFullYear(),new Date().getMonth(),1).toLocaleDateString("en-GB").split("/").reverse().join("-"),
				dataF: new Date(new Date().getFullYear(),new Date().getMonth()+1,0).toLocaleDateString("en-GB").split("/").reverse().join("-"),
				conta: "SEL",
			};
			this.atualiza = true;
			this.$store.commit("atualizarExtrato");
			this.atualiza = false;
		}
	},

	beforeMount() {
		this.$store.dispatch("buscarContas");
		this.$store.commit("atualizarExtrato");
	},

	mounted(){
		this.handleShowTable();
	}
};
</script>
<style scoped>
.row .input-group:focus-within label,
.row .input-group input:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}
.row .input-group:focus-within label,
.row .input-group select:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}
.container-tabela{
	width: 95%;
	overflow: auto;
	height: 100%;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0px 5px;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}
.campo-tabela{
	width: 400px;
}
.campo-menor{
	width: 155px;
	text-align: center;
}
.campo-medio{
	width: 350px;
	text-wrap: wrap;
}
.campo-descricao{
	width: 300px;
	text-wrap: wrap;
}
.campo-data{
	width: 90px;
	text-align: center;
}
.campo-codigo{
	width: 85px;
	text-align: center;
}
.campo-tabela{
	font-size: 13px;
}
@media screen and (max-width: 425px){
	.row{
		flex-wrap: wrap;
	}
	.row .input-group{
		margin-top: 10px;
	}
}
</style>