<template>
	<div id="painelControle">
		<div class="row between w-100" style="justify-content: space-between;">
			<input type="text" readonly class="w-45 inputValor" style="text-align: left; font-weight: bold;"
			:value="`Valor Recebido: ${Number(valorRecebido).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
			<input type="text" readonly class="w-45 inputValor"  style="text-align: left; font-weight: bold;"
					:value="`Sub Total: ${Number(totalBruto).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
		</div>
		<SeletorVistaPrazo
			:itens="venda.itens"
			:valorReceber="valorReceber"
			v-on:recalcular="recalcularValores"
			@recalcularRecebidos="$emit('recalcularRecebidos')"
		/>

		<div class="row between w-100 mb-5" v-if="($store.state.situacaoCliente ? $store.state.situacaoCliente.consorcio ? $store.state.situacaoCliente.consorcio.length ?
		($store.state.situacaoCliente.consorcio.map(c => (c.credito || 0)).reduce((t, v) => t += v)) : 0 : 0 : 0) > 0">
			<div class="formaPagamento">
				<input type="checkbox" id="js-usar-credito" class="m-5 pointer" @click="usarCredito">
				<label for="js-usar-credito" class="pointer">Valor crédito:</label>
			</div>
			<input type="text" id="valorCredito" disabled class="w-50" :value="($store.state.situacaoCliente ? $store.state.situacaoCliente.consorcio ? $store.state.situacaoCliente.consorcio.length ?
			($store.state.situacaoCliente.consorcio.map(c => (c.credito || 0)).reduce((t, v) => t += v)) : 0 : 0 : 0) | formatarParaReais ">
		</div>
		<div class="row between w-100 mb-5" v-if="saldoCredito != creditoCliente && saldoCredito > 0">
			<div class="formaPagamento">
				<label for="saldoCredito">Saldo restante:</label>
			</div>
			<input type="text" id="saldoCredito" disabled class="w-50"
				:value="(saldoCredito) | formatarParaReais ">
		</div>

		<div class="row between w-100 mb-5" v-if="venda.valorTroca">
			<div class="formaPagamento">
				<label for="valorTroca">Valor troca:</label>
			</div>
			<input type="text" id="valorTroca" disabled class="w-50"
				:value="(totalTrocas || 0) | formatarParaReais ">
		</div>

		<div class="col w-100 mb-5" id="desconto-controle">
			<span style="align-self: center; cursor: pointer;" @click="mostrarDescontos = true">
				<span>Desconto</span>
				<span>
					{{ beneficioAplicado.includes("Promocional") ? "promocional" :
							beneficioAplicado.includes("Classificacao") ? "de classificação" :
								beneficioAplicado.includes("Permitido") ? "permitido" : 
								beneficioAplicado.includes("Método") ? "forma pagamento" : ""
					}}
					<i class="fas fa-comment-dollar" style="color: var(--confirmar); font-size: 14pt"
						@click="mostrarDescontos = true"></i>
				</span>
			</span>
			<div class="row between desconto">
				<div class="col center">
					<label for="descontoPercentual">Percentual:</label>
					<input
						type="text"
						autocomplete="off"
						class="w-90"
						id="descontoPercentual" 
						v-model="descontoPercentual"
						@keydown="mascaraFloat"
						@keydown.enter.prevent="calcularDesconto"
						@contextmenu.prevent=""
						:disabled="$store.state.Venda.financeiro.length > 0 || valorReceber <= 0"
					>
				</div>
				<div class="col center">
					<label for="descontoMoeda">Valor:</label>
					<input
						type="text"
						autocomplete="off"
						id="descontoMoeda"
						class="w-90"
						v-model="descontoMoeda"
						@keydown="mascaraFloat"
						@input="calcularDesconto"
						@contextmenu.prevent=""
						:disabled="$store.state.Venda.financeiro.length > 0 || valorReceber <= 0"
					>
				</div>
			</div>
		</div>

		<div class="row between w-100 mb-5">
			<label for="valorReceber">Valor a receber:</label>
			<input
				type="text"
				id="valorReceber"
				readonly
				v-if="!alterarValor" @dblclick="alterarValorManual"
				@keydown.tab.prevent="focarMetodo"
				@contextmenu.prevent="alterarValorManual"
				:value="Number(valorReceber) | formatarParaReais"
				class="w-50"
			>
			<input
				type="text"
				id="ajustarValor"
				ref="valorFinal"
				v-model="valorFinal" 
				@keydown.enter="ajustarValor(false)"
				@focusout="ajustarValor(false)"
				v-else
				class="w-50"
			>
		</div>

		<div class="row between w-100 mb-5" id="rodapeAcao">
			<button class="botao botao-cancelar w-45" @click="cancelarRecebimento">
				Cancelar (Alt+c)
			</button>
			<button class="botao botao-adicionar w-45" @click="confirmarRecebimento">
				Salvar (Alt+s)
			</button>
		</div>
		<Modal v-if="mostrarDescontos" v-on:fecharModal="mostrarDescontos = false"
			:dimensoes="{ width: '320px', height: 'auto' }">
			<div class="modal-desconto">
				<h3>Benefícios disponíveis</h3>
				<h3>Clique no valor para selecionar</h3>
				<table>
					<thead>
						<tr>
							<td style="text-align: left">Descrição</td>
							<td style="text-align: right">$ vista</td>
							<td style="text-align: right">$ prazo</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style="text-align: left;" @click="selecinouBeneficio('Bruto')">Valor bruto</td>
							<td style="text-align: right;" @click="selecinouBeneficio('totalBrutoVista', 'vista')">
								{{ (totalBrutoVista > 0 ? totalBrutoVista : 0).toLocaleString("pt-BR", {
										style: "decimal",
										maximumFractionDigits: 2, minimumFractionDigits: 2
									})
								}}</td>
							<td style="text-align: right;" @click="selecinouBeneficio('totalBrutoPrazo', 'prazo')">
								{{ (totalBrutoPrazo > 0 ? totalBrutoPrazo : 0).toLocaleString("pt-BR", {
									style: "decimal",
									maximumFractionDigits: 2, minimumFractionDigits: 2
								})}}
							</td>
						</tr>
						<tr
							v-if="totalComClassificacaoVista != totalBrutoVista || totalComClassificacaoPrazo != totalBrutoPrazo">
							<td style="text-align: left" @click="selecinouBeneficio('Classificacao')">Classificação</td>
							<td style="text-align: right"
								@click="selecinouBeneficio('totalComClassificacaoVista', 'vista')">
								{{ (totalComClassificacaoVista > 0 ? totalComClassificacaoVista :
										0).toLocaleString("pt-BR", {
											style: "decimal", maximumFractionDigits: 2,
											minimumFractionDigits: 2
										})
								}}</td>
							<td style="text-align: right"
								@click="selecinouBeneficio('totalComClassificacaoPrazo', 'prazo')">
								{{ (totalComClassificacaoPrazo > 0 ? totalComClassificacaoPrazo :
										0).toLocaleString("pt-BR", {
											style: "decimal", maximumFractionDigits: 2,
											minimumFractionDigits: 2
										})
								}}</td>
						</tr>
						<tr v-if="totalPromocionalVista != totalBrutoVista">
							<td style="text-align: left" @click="selecinouBeneficio('Promocional')">Valor promocional
							</td>
							<td style="text-align: right" @click="selecinouBeneficio('totalPromocionalVista', 'vista')">
								{{ (totalPromocionalVista > 0 ? totalPromocionalVista : 0).toLocaleString("pt-BR",
										{ style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 })
								}}</td>
							<td style="text-align: right" @click="selecinouBeneficio('totalPromocionalPrazo', 'prazo')">
								{{ (totalPromocionalPrazo > 0 ? totalPromocionalPrazo : 0).toLocaleString("pt-BR",
										{ style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 })
								}}</td>
						</tr>
						<tr>
							<td style="text-align: left" @click="selecinouBeneficio('Permitido')">Desconto permitido
							</td>
							<td style="text-align: right" @click="selecinouBeneficio('totalPermitidoVista', 'vista')">
								{{ (totalPermitidoVista > 0 ? totalPermitidoVista : 0).toLocaleString("pt-BR", {
										style:
											"decimal", maximumFractionDigits: 2, minimumFractionDigits: 2
									})
								}}</td>
							<td style="text-align: right" @click="selecinouBeneficio('totalPermitidoPrazo', 'prazo')">
								{{ (totalPermitidoPrazo > 0 ? totalPermitidoPrazo : 0).toLocaleString("pt-BR", {
										style:
											"decimal", maximumFractionDigits: 2, minimumFractionDigits: 2
									})
								}}</td>
						</tr>
						<tr v-if="aplicaDescontoFormaPagamento" @click="selecinouBeneficio('FormaPagamento')">
							<td style="text-align: left">
								Promoção forma pagamento
							</td>
							<td style="text-align: right">
								0,00
							</td>
							<td style="text-align: right"> 
								0,00
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Modal>
		<Autenticacao
			v-if="solicitouAutorizacao" 
			:mensagem="autenticacaoConfig.mensagem"
			:autorizar="autenticacaoConfig.autorizar"
			:negar="autenticacaoConfig.negar"
			:preVenda="true"
			:autorizacao="true"
		/>
	</div>
</template>

<script>
import SeletorVistaPrazo from "../components/SeletorVistaPrazo.vue";
import Modal from "./Modal.vue";
import Autenticacao from "./Autenticacao.vue";
import formatarParaReais from "../filters/formatarParaMoeda";

export default {
	name: "ControleFechamento",

	props: {
		venda: Object,
		valorReceber: Number,
		totalComDesconto: Number,
		valorRecebido: Number,
	},

	components: {
		SeletorVistaPrazo,
		Modal,
		Autenticacao,
	},

	data(){
		return {
			solicitouAutorizacao: false,
			autenticacaoConfig: {
				mensagem: "",
				autorizar() {},
				negar() {},
			},
			alterarValor: false,
			finalizarVenda: false,
			descontoPercentual: "",
			descontoMoeda: "",
			valorFinal: "",
			utilizarCredito: false,
			timerCalculo: null,
			mostrarDescontos: false,
			beneficioAplicado: "",

			promocaoFormaPagamentoAplicavel: false,
			percDescontoPgto: 0,
			
			aplicaDescontoFormaPagamento: false,
			formasPagamentoEmDesconto: []
		};
	},

	computed: {
		formasPagamento() {
			return this.$store.state.formasPgtoVenda;
		},

		Usuario() {
			return this.$store.state.Usuario;
		},
		creditoCliente() {
			return Number((this.$store.state.situacaoCliente ? this.$store.state.situacaoCliente.consorcio ? this.$store.state.situacaoCliente.consorcio.length ? 
				(this.$store.state.situacaoCliente.consorcio.map(c => (c.credito || 0)).reduce((t,v) => t+=v)) : 0 : 0 : 0).toFixed(2));
		},
		saldoCredito() {
			let preco = 0;
			if (this.$store.state.Venda.consorcio?.length) {
				this.$store.state.Venda.consorcio.forEach(fin => preco += fin.valor);
			}
			return this.utilizarCredito ? Number((this.creditoCliente - (preco || 0)).toFixed(2)) : this.creditoCliente;
		},
		existePromocao() {
			return this.venda.itens.find(item => (item.cdpromocao || item.promocaoAntiga) && (item.cdTipo != "T" && item.situacao != "D")) != null;
		},
		totalSaidaVenda() {
			let preco = 0;
			this.venda.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D" && item.situacao != "B") {
					preco += item.valorUnitario * item.quantidade;
				}
			});
			return preco;
		},
		totalTrocas() {
			let preco = 0;
			this.venda.itens.forEach(item => {
				if (item.cdTipo == "T") {
					preco += item.valorUnitario * item.quantidade;
				}
			});
			return preco;
		},
		totalBrutoVista() {
			let preco = 0;
			this.venda.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D") {
					preco += item.produto.valorVista * item.quantidade;
				} else {
					preco -= (item.valorUnitario * item.quantidade);
				}
			});
			return preco;
		},
		totalBrutoPrazo() {
			let preco = 0;
			this.venda.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D") {
					preco += item.produto.valorPrazo * item.quantidade;
				} else {
					preco -= (item.valorUnitario * item.quantidade);
				}
			});
			return preco;
		},
		totalPromocionalVista() {
			let preco = 0;
			this.venda.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D") {
					if (item.produto.promocaoVista && !this.$route.query.trocarProdutos) {
						preco += (item.produto.valorPromocaoVista) * item.quantidade;
					} else {
						preco += item.produto.valorVista * item.quantidade;
					}
				} else {
					preco -= (item.valorUnitario * item.quantidade);
				}
			});
			return preco;
		},
		totalPromocionalPrazo() {
			let preco = 0;
			this.venda.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D") {
					if (item.produto.promocaoPrazo && !this.$route.query.trocarProdutos) {
						preco += (item.produto.valorPromocaoPrazo) * item.quantidade;
					} else {
						preco += item.produto.valorPrazo * item.quantidade;
					}
				} else {
					preco -= (item.valorUnitario * item.quantidade);
				}
			});
			return preco;
		},
		totalComClassificacaoVista() {
			if (this.venda.cliente.classificacao?.descvista) {
				let preco = 0;
				this.venda.itens.forEach(item => {
					if (item.cdTipo != "T" && item.situacao != "D") {
						preco += item.valorUnitario * item.quantidade;
					}
				});
				return (preco - (preco * (this.venda.cliente.classificacao.descvista || 0) /100)) - this.totalTrocas;
			} else {
				return this.totalBrutoVista;
			}
		},
		totalComClassificacaoPrazo() {
			if (this.venda.cliente.classificacao?.descprazo) {
				let preco = 0;
				this.venda.itens.forEach(item => {
					if (item.cdTipo != "T" && item.situacao != "D") {
						preco += item.valorUnitario * item.quantidade;
					}
				});
				return (preco - (preco * (this.venda.cliente.classificacao.descprazo || 0) /100)) - this.totalTrocas;
			} else {
				return this.totalBrutoPrazo;
			}
		},
		totalPermitidoVista() {
			return (this.totalBrutoVista + this.totalTrocas) - ((this.totalBrutoVista + this.totalTrocas) * ((this.Usuario.maxPorcentagemDesconto || 0) /100)) - this.totalTrocas;
		},
		totalPermitidoPrazo() {
			return (this.totalBrutoPrazo + this.totalTrocas) - ((this.totalBrutoPrazo + this.totalTrocas) * ((this.Usuario.maxPorcentagemDesconto || 0) /100)) - this.totalTrocas;
		},
		totalVenda() {
			let preco = 0;
			this.venda.itens.forEach(item => {
				preco += item.valorUnitario * item.quantidade;
			});
			return preco;
		},
		totalBruto(){
			let preco = 0;
			// mudar aqui tambem
			this.$store.state.Venda.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D") {
					if (this.$store.state.Venda.valorVista) {
						const promocaoAplicavel = item.cdpromocao && item.produto.valorPromocaoVista > 0;
						const vlrunitario = item.valorUnitario;
						const { alteracao } = this.$route.query;
						const alterouVlrUnitario = !promocaoAplicavel
							? vlrunitario !== item.produto.valorVista && vlrunitario !== item.produto.valorPrazo
							: vlrunitario !== item.produto.valorPromocaoVista && vlrunitario !== item.produto.valorPromocaoPrazo;
						let vlrNormal = 0;

						if(alteracao && alterouVlrUnitario && promocaoAplicavel) {
							vlrNormal = vlrunitario;
						} else if(alteracao && !alterouVlrUnitario && promocaoAplicavel) {
							vlrNormal = item.produto.valorPromocaoVista;
						} else if(!alterouVlrUnitario && !promocaoAplicavel) {
							vlrNormal = item.produto.valorVista;
						} else if(alterouVlrUnitario && !promocaoAplicavel) {
							vlrNormal = vlrunitario;
						} else if(promocaoAplicavel && alterouVlrUnitario) {
							vlrNormal = item.produto.valorPromocaoVista;
						} else {
							vlrNormal = item.valorUnitario;
						}

						// const vlrNormal = item.cdpromocao ? item.produto.valorPromocaoVista : item.produto.valorVista;
						preco += Number(vlrNormal * item.quantidade);
					} else {
						const promocaoAplicavel = item.cdpromocao && item.produto.valorPromocaoPrazo > 0;
						const vlrunitario = item.valorUnitario;
						const { alteracao } = this.$route.query;
						const alterouVlrUnitario = !promocaoAplicavel
							? vlrunitario !== item.produto.valorVista && vlrunitario !== item.produto.valorPrazo
							: vlrunitario !== item.produto.valorPromocaoVista && vlrunitario !== item.produto.valorPromocaoPrazo;
						let vlrNormal = 0;
						if(alteracao && alterouVlrUnitario && promocaoAplicavel) {
							vlrNormal = vlrunitario;
						} else if(alteracao && !alterouVlrUnitario && promocaoAplicavel) {
							vlrNormal = item.produto.valorPromocaoPrazo;
						}
						else if(!alterouVlrUnitario && !promocaoAplicavel) {
							vlrNormal = item.produto.valorPrazo;
						} else if(alterouVlrUnitario && !promocaoAplicavel) {
							vlrNormal = vlrunitario;
						} else if(promocaoAplicavel && alterouVlrUnitario) {
							vlrNormal = item.produto.valorPromocaoPrazo;
						} else {
							vlrNormal = vlrunitario;
						}

						// const vlrNormal = item.cdpromocao ? item.produto.valorPromocaoPrazo : item.produto.valorPrazo;
						preco += Number(vlrNormal * item.quantidade);
					}
				}
			});
			return (preco - this.totalTrocas);
		},
	},

	filters: {
		formatarParaReais
	},

	methods: {
		cancelarPagamento(pagamento) {
			if(pagamento.desconto) {
				const valorParaDesconto = pagamento.valor === 0 ? this.valorReceber : pagamento.valor;
				const vlrDesconto = valorParaDesconto * (pagamento.desconto / 100);
				this.descontoMoeda = "";
				this.descontoPercentual = "";
				this.$store.state.Venda.descontoMoeda = "";
				this.$store.state.Venda.descontoPercentual = "";
				pagamento.vlrDesconto = vlrDesconto;
				this.beneficioAplicado = "";
				this.$emit("cancelarPagamentoComDesconto", pagamento);
				// this.totalComDesconto = 0;
			}
		},
		/*
			Responsavel por
			* Calcular totalizador Sub total;
			* Setar desconto no componente para visualização;
			* Setar o desconto no state da venda para ir no payload no cadastro da venda; 
		*/
		verificaPromocaoFormaPagamento() {
			let possuiDesconto = false;
			if(this.formasPagamento?.length > 0) {
				this.formasPagamento.forEach(pgto => {
					if(pgto.desconto > 0) {
						this.formasPagamentoEmDesconto.push(pgto.descricao);
						possuiDesconto = true;
					}
				});
			}
			
			if(possuiDesconto) {
				this.aplicaDescontoFormaPagamento = true;
			}

		},

		descontoFormaPagamento({descontoAplicado, vlrDesconto}) {
			/*Setando desconto no payload da venda*/
			this.$store.state.Venda.descontoMoeda = Number(this.$store.state.Venda.descontoMoeda) + Number(vlrDesconto || 0);
			// TO DO: percentual pegar o valor do desconto e fazer o percentual de acordo com o valor da venda
			// TO DO: Fazer o valor do desconto em forma de pagamento atualizar na tabela

			const descPercentual = Number(Number(this.$store.state.Venda.descontoMoeda) / Number(this.totalVenda) * 100);
			this.$store.state.Venda.descontoPercentual = descPercentual;
			
			/* Setando desconto nos campos de desconto do componente (para visualização) */
			this.descontoMoeda = this.$store.state.Venda.descontoMoeda.toLocaleString("pt-BR", {style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			});
			this.descontoPercentual = descPercentual.toLocaleString("pt-BR", {
				style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});

			/* Setando desconto para calculo de totalizador */
			this.promocaoFormaPagamentoAplicavel = descontoAplicado;
			this.percDescontoPgto = descPercentual;


			this.beneficioAplicado = "Método";
			this.venda.beneficio = this.beneficioAplicado;
			
		},

		stringToNumber(string) {
			return Number(`${string || ""}`.split(".").join("").replace(",", "."));
		},

		mascaraFloat(e) {
			const regex = /[0-9,]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
				return;
			}
			if (e.key === "," && `${e.target.value}`.includes(",")) {
				e.preventDefault();
				return;
			}
		},
		selecinouBeneficio(tipo, recalcular) {
			this.beneficioAplicado = tipo;
			this.venda.beneficio = this.beneficioAplicado;
			if (recalcular) this.recalcularValores(recalcular, true);
			setTimeout(() => {
				if (tipo == "Bruto") {
					this.valorFinal = this.venda.valorVista ? this.totalBrutoVista : this.totalBrutoPrazo;
				} else if (tipo == "Classificacao") {
					this.valorFinal = this.venda.valorVista ? this.totalComClassificacaoVista : this.totalComClassificacaoPrazo;
				} else if (tipo == "Promocional") {
					this.valorFinal = this.venda.valorVista ? this.totalPromocionalVista : this.totalPromocionalPrazo;
				} else if (tipo == "Permitido") {
					this.valorFinal = this.venda.valorVista ? this.totalPermitidoVista : this.totalPermitidoPrazo;
				} else {
					this.valorFinal = this[tipo];
				}
				if (this.valorFinal < 0) {
					this.valorFinal = 0;
				}
				if (tipo.includes("Bruto") || tipo.includes("Permitido") || tipo.includes("Classificacao")) {
					this.venda.itens.forEach(item => {
						if (item.cdTipo != "T" && (item.situacao == "A" || !item.situacao || item.situacao == "S")){
							item.valorUnitario = this.venda.valorVista ? Number(item.produto.valorVista) : Number(item.produto.valorPrazo);
							if (item.cdpromocao) {
								item.promocaoAntiga = item.cdpromocao;
								item.cdpromocao = null;
							}
						}
					});
				}
				if (tipo.includes("Promocional")) {
					this.venda.itens.forEach(item => {
						if (item.cdTipo != "T" && (item.situacao == "A" || !item.situacao || item.situacao == "S")) {
							if (item.promocaoAntiga) {
								item.valorUnitario = Number(item.produto.valorPrazo);
								item.cdpromocao = item.promocaoAntiga;
								item.promocaoAntiga = null;
							}
						}
					});
				}
				if(tipo === "FormaPagamento") {
					return this.$store.commit("relatarErro", {alerta: true, mensagem: `Para aplicar o desconto por forma de pagamento realize a venda em uma dessas condições de pagamento (${this.formasPagamentoEmDesconto})`});
				}
				this.valorFinal = this.valorFinal.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.$forceUpdate();
				this.ajustarValor(true);
				this.mostrarDescontos = false;
			},20);
		},

		focarMetodo() {
			document.getElementsByClassName("btnPagamento")[0].focus();
		},

		async recalcularValores(formaPagamento){
			if (this.$store.state.Venda.cliente.id != this.$store.state.ClientePadrao.id) {
				await this.$store.dispatch("buscarClientePorId", {id: this.$store.state.Venda.cliente.id, venda: true});
			}
			if (this.utilizarCredito) {
				this.$store.state.Venda.valorVista = formaPagamento == "vista";
				return setTimeout(() => {
					document.getElementById("js-usar-credito").click();
				},100);
			}
			// if (this.valorReceber <= 0) return;
			let preco = 0;
			this.$store.state.Venda.descontoMoeda = "";
			this.$store.state.Venda.descontoPercentual = "";
			let descontoPromocional = 0;
			const { alteracao } = this.$route.query;
			if (formaPagamento === "vista"){
				this.descontoMoeda = "";
				this.descontoPercentual = "";
				this.venda.valorVista = true;
				this.venda.itens.forEach(item => {
					if (item.cdTipo != "T" && (item.situacao == "A" || !item.situacao || item.situacao == "S")){
						if(alteracao) {
							const vlrUnitario = item.valorUnitario;
							const promocaoAplicavel = item.cdpromocao && item.produto.valorPromocaoVista > 0;
							const alterouVlrUnitario = !promocaoAplicavel 
								? (vlrUnitario !== item.produto.valorVista) && vlrUnitario !== item.produto.valorPrazo
								: (vlrUnitario !== item.produto.valorPromocaoPrazo && vlrUnitario !== item.produto.valorPromocaoVista);
							
							if(promocaoAplicavel && !alterouVlrUnitario) {
								item.valorUnitario = item.produto.valorPromocaoVista;
							}
							if (promocaoAplicavel && alterouVlrUnitario && !this.$route.query.trocarProdutos) {
								descontoPromocional += ((item.valorUnitario * (item.produto.promocaoVista /100)) * (item.quantidade || 1));
							}
							
							preco += Number(Number(Number(item.valorUnitario) * item.quantidade).toFixed(2));
						} else {
							item.valorUnitario = Number(item.produto.valorVista);
							if (item.cdpromocao && !this.$route.query.trocarProdutos) {
								descontoPromocional += ((item.produto.valorPrazo * (item.produto.promocaoVista /100)) * (item.quantidade || 1));
								item.valorUnitario = Number(item.produto.valorPrazo);
							}
							preco += Number(Number(Number(item.valorUnitario) * (item.quantidade || 1)).toFixed(2));
						}


					}
				});
				descontoPromocional = Number(descontoPromocional.toFixed(2));
				let totalComDesconto = Number(Number(preco - descontoPromocional).toFixed(2));
				this.venda.valorBruto = totalComDesconto;
				document.getElementById("aVista").checked = true;
				this.valorFinal = totalComDesconto - Number(this.venda.valorTroca || 0);
				this.$emit("atualizarTotal", this.valorFinal);

				if (this.beneficioAplicado.includes("Permitido")) {
					this.descontoPercentual = "0,00";
					this.calcularDesconto("porcentagem");
				}
				if (descontoPromocional && this.beneficioAplicado.includes("Promocional")) {
					this.descontoMoeda = (descontoPromocional || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maxmimumFractionDigits: 2});
					this.venda.descontoMoeda = descontoPromocional;
					const descontoPercentual = Number((Number(descontoPromocional) / Number(totalComDesconto + descontoPromocional)) * 100).toFixed(2);
					this.descontoPercentual = Number(descontoPercentual).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					this.venda.descontoPercentual = Number(descontoPercentual);
				}
				if (this.$store.state.Venda.cliente.classificacao && this.beneficioAplicado.includes("Classificacao")) {
					if (this.$store.state.Venda.cliente.classificacao.descvista > 0 && (this.descontoPercentual < this.$store.state.Venda.cliente.classificacao.descvista)) {
						this.descontoPercentual = (this.$store.state.Venda.cliente.classificacao.descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
						this.beneficioAplicado = "Classificacao";
						this.venda.beneficio = this.beneficioAplicado;
						this.calcularDesconto("porcentagem");
					}
				} 
				if (!this.$store.state.Usuario.permiteDescontoAvista && this.$store.state.Venda.valorVista) {
					this.descontoMoeda = "";
					this.descontoPercentual = "";
				}
				if(this.promocaoFormaPagamentoAplicavel) {
					this.descontoFormaPagamento(false, 0);
				}
			} else {
				this.descontoMoeda = "";
				this.descontoPercentual = "";
				this.venda.valorVista = false;
				this.venda.itens.forEach(item => {
					if (item.cdTipo != "T" && (item.situacao == "A" || !item.situacao || item.situacao == "S")) {
						if(alteracao) {
							const vlrUnitario = item.valorUnitario;
							const promocaoAplicavel = item.cdpromocao && item.produto.valorPromocaoPrazo > 0;
							const alterouVlrUnitario = !promocaoAplicavel 
								? (vlrUnitario !== item.produto.valorVista) && vlrUnitario !== item.produto.valorPrazo
								: (vlrUnitario !== item.produto.valorPromocaoPrazo && vlrUnitario !== item.produto.valorPromocaoVista);
							
							if(promocaoAplicavel && !alterouVlrUnitario) {
								item.valorUnitario = item.produto.valorPromocaoPrazo;
							}
							if (promocaoAplicavel && alterouVlrUnitario && !this.$route.query.trocarProdutos) {
								descontoPromocional += ((item.valorUnitario * (item.produto.promocaoPrazo /100)) * (item.quantidade || 1));
							}
							
							preco += Number(Number(Number(item.valorUnitario) * item.quantidade).toFixed(2));
						} else {
							item.valorUnitario = Number(item.produto.valorPrazo);
							if (item.cdpromocao && !this.$route.query.trocarProdutos) {
								descontoPromocional += ((item.produto.valorPrazo * (item.produto.promocaoPrazo /100)) * (item.quantidade || 1));
							}
							preco += Number(Number(Number(item.valorUnitario) * item.quantidade).toFixed(2));
						}
						
					}
				});
				descontoPromocional = Number(descontoPromocional.toFixed(2));
				let totalComDesconto = Number(Number(preco - descontoPromocional).toFixed(2));
				this.venda.valorBruto = totalComDesconto;
				document.getElementById("aPrazo").checked = true;
				this.$emit("atualizarTotal", totalComDesconto - this.totalTrocas);
				this.valorFinal = totalComDesconto - Number(this.totalTrocas || 0);
				if (this.beneficioAplicado.includes("Permitido")) {
					this.descontoPercentual = "0,00";
					this.calcularDesconto("porcentagem");
				}
				if (descontoPromocional && this.beneficioAplicado.includes("Promocional")) {
					this.descontoMoeda = (descontoPromocional || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maxmimumFractionDigits: 2});
					this.venda.descontoMoeda = descontoPromocional;
					const descontoPercentual = Number((Number(descontoPromocional) / Number(totalComDesconto + descontoPromocional)) * 100).toFixed(2);
					this.descontoPercentual = Number(descontoPercentual).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					this.venda.descontoPercentual = descontoPercentual;
				}
				if (this.$store.state.Venda.cliente.classificacao && this.beneficioAplicado.includes("Classificacao")) {
					if (this.$store.state.Venda.cliente.classificacao.descprazo > 0 && (this.descontoPercentual < this.$store.state.Venda.cliente.classificacao.descprazo)) {
						this.descontoPercentual = (this.$store.state.Venda.cliente.classificacao.descprazo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
						this.beneficioAplicado = "Classificacao";
						this.venda.beneficio = this.beneficioAplicado;
						this.calcularDesconto("porcentagem");
					}
				}
			}
			this.$forceUpdate();
		},

		gerarParcelasConsorcio() {
			let valorTotal = Number(this.valorReceber);
			let parcelas = [];
			this.$store.state.situacaoCliente.consorcio.forEach(consorcio => {
				if (consorcio.credito > 0 && valorTotal > 0) {
					if ((consorcio.credito - valorTotal) > 0) {
						parcelas.push({
							cdconsorcio: consorcio.cdconsorcio,
							valor: Number(valorTotal)
						});
						valorTotal = 0;
					} else {
						parcelas.push({
							cdconsorcio: consorcio.cdconsorcio,
							valor: Number(consorcio.credito)
						});
						valorTotal -= consorcio.credito;
					}
				}
			});
			return parcelas;
		},

		usarCredito(e) {
			if (!this.$store.state.situacaoCliente) {
				return setTimeout(() => e.target.checked = !e.target.checked, 100);
			}
			if (!this.utilizarCredito) {
				if (this.creditoCliente >= this.valorReceber) {
					this.$emit("confirmarPagamento", {parcelas: this.gerarParcelasConsorcio(), valorRecebido: Number(this.valorReceber), consorcio: true});
				} else {
					this.$emit("confirmarPagamento", {parcelas: this.gerarParcelasConsorcio(), valorRecebido: Number(this.creditoCliente), consorcio: true});
				}
			} else {
				if (this.creditoCliente >= this.valorReceber) {
					this.$emit("confirmarPagamento", {parcelas: [], valorRecebido: -Number(this.valorReceber), consorcio: true});
				} else {
					this.$emit("confirmarPagamento", {parcelas: [], valorRecebido: -Number(this.creditoCliente), consorcio: true});
				}
				setTimeout(() => {
					this.recalcularValores(this.$store.state.Venda.valorVista ? "vista" : "prazo");
				}, 50);
			}
			this.utilizarCredito = !this.utilizarCredito;
		},

		verificaDesconto(desconto) {
			let ponto = Number(typeof desconto == "string" ? desconto.split(".").join("").replace(",", ".") : desconto);
			if ((this.$store.state.Venda.liberacao?.desconto?.liberado) && (ponto <= (this.$store.state.Venda.liberacao?.desconto?.percentual || 0))) {
				return true;
			}
			if (this.existePromocao && this.beneficioAplicado.includes("Promocional")) {
				true;
			}
			if (ponto > this.Usuario.maxPorcentagemDesconto) {
				if (((this.venda.valorVista && (this.venda.cliente.classificacao?.descvista >= ponto)) || (!this.venda.valorVista && (this.venda.cliente.classificacao?.descprazo >= ponto)))) return true;
				this.$store.commit("relatarErro", {
					mensagem: "Desconto maior que o permitido, deseja solicitar autorização?",
					item: {
						liberaDesconto: true,
						desconto: Number(ponto),
						route: "Fechamento",
						mensagem: `${this.$store.state.Usuario.nome} está solicitando autorização para aplicar desconto de ${
							(ponto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})
						}%.`
					},
					tipo: "polar",
				});
				return false;
			} else {
				return true;
			}
		},

		calcularDesconto(tipo, param){
			if (typeof tipo != "string") {
				const id = tipo.target.id;
				const data = tipo.data;
				const regex = /[0-9,]/;
				if (!regex.test(data) && data) {
					this[id] = this[id].substring(0, this[id].length -1);
				}
			}
			const usuario = this.$store.state.Usuario;
			const venda = this.$store.state.Venda;
			const classiCliente = venda.cliente.classificacao;

			clearTimeout(this.timerCalculo);
			this.timerCalculo = setTimeout(() => {
				if (typeof tipo == "object") {
					if (tipo.target?.id) {
						tipo = tipo.target?.id;
						this.beneficioAplicado = "Permitido";
						this.venda.beneficio = this.beneficioAplicado;
						this.venda.itens.forEach(item => {
							if (item.cdpromocao) {
								item.promocaoAntiga = item.cdpromocao;
								item.cdpromocao = null;
							}
						});
					}
				}
				if (this.$route.params.novoDesconto) {
					this.$route.params.novoDesconto = false;
				}
				const totalProdutos = this.$store.state.itensVenda.map(item => {
					if (item.cdTipo != "T" && (item.situacao == "A" || !item.situacao || item.situacao == "S")){
						if (this.beneficioAplicado.includes("Promocional")) {
							if (item.promocaoAntiga) {
								item.cdpromocao = item.promocaoAntiga;
								item.promocaoAntiga = null;
							}
						}
						return Number(Number(Number(item.valorUnitario) * item.quantidade).toFixed(2));
					} else {
						return 0;
					}
				}).reduce((t, v) => t += v);
				let totalComDesconto = Number(totalProdutos - (this.totalTrocas || 0));
				if (
					(this.descontoPercentual || this.descontoMoeda) && 
					(
						!usuario.permiteDescontoAvista &&
						!classiCliente.bloqueiavendaprazo
					) && 
					venda.valorVista &&
					usuario.usuarioPrivilegiado &&
					!classiCliente?.descvista
				)  {
					
					this.descontoMoeda = "";
					this.descontoPercentual = "";
					this.$store.commit("relatarErro", {
						mensagem: "Não é permitido desconto no valor à vista!"
					});
				}
				if (tipo == "valor" || tipo == "descontoMoeda"){
					const descMoeda = this.stringToNumber(this.descontoMoeda || 0);

					this.descontoPercentual = Number((Number(descMoeda / Number(totalComDesconto)) * 100).toFixed(2));
					if (!this.verificaDesconto(this.descontoPercentual)) return;
					
					if ((totalComDesconto - descMoeda) < 0.001) {

						this.descontoMoeda = (Number(totalComDesconto)).toLocaleString("pt-BR", {
							style:"decimal",
							minimumFractionDigits: 2,
							maximumFractionDigits: 2
						});
						this.descontoPercentual = Number((descMoeda / Number(totalComDesconto) * 100 ).toFixed(2));
						totalComDesconto = Number(
							Number(totalComDesconto - descMoeda).toFixed(2)
						);
					} else {
						totalComDesconto = Number((totalComDesconto - descMoeda).toFixed(2));
					}
					this.$emit("atualizarTotal", totalComDesconto);
					this.valorFinal = Number((Number(totalComDesconto)).toFixed(2));
					this.$store.state.Venda.descontoMoeda = descMoeda;
					this.$store.state.Venda.descontoPercentual = Number(this.descontoPercentual);
					if (this.descontoMoeda == 0) {
						this.descontoMoeda = "";
						this.descontoPercentual = "";
					} else {
						this.descontoPercentual = Number(this.descontoPercentual).toLocaleString("pt-BR", {style:"decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					}
					return;
				}
				if (param) {
					if (param.porcentagem > 0) {
						this.descontoPercentual = `${param.porcentagem}`;
					} else if (!param.cliente) {
						this.descontoPercentual = "";
					}
				}
				if (!this.verificaDesconto(this.descontoPercentual)) return;

				const descPercentual = this.stringToNumber(this.descontoPercentual || 0);
				this.descontoMoeda = Number((Number(totalComDesconto) * (descPercentual / 100)).toFixed(2));
				
				if ((totalComDesconto - Number(this.descontoMoeda)) < 0.001) {
					this.descontoMoeda = (Number(totalComDesconto));
					this.descontoPercentual = Number(((Number(this.descontoMoeda) / Number(totalComDesconto)) * 100 ).toFixed(2));
					totalComDesconto = Number(Number(totalComDesconto - this.descontoMoeda).toFixed(2));
					this.descontoMoeda = this.descontoMoeda.toLocaleString("pt-BR", {
						style:"decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
					this.descontoPercentual = this.descontoPercentual.toLocaleString("pt-BR", {
						style:"decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				} else {
					totalComDesconto = Number(
						Number(
							(totalComDesconto) - ((totalComDesconto) * (descPercentual / 100))
						).toFixed(2)
					);
				}
				this.$emit("atualizarTotal", totalComDesconto);
				this.valorFinal = Number(Number(totalComDesconto).toFixed(2));
				this.$store.state.Venda.descontoMoeda = this.descontoMoeda;
				this.$store.state.Venda.descontoPercentual = descPercentual;
				if (this.descontoPercentual == 0) {
					this.descontoPercentual = "";
					this.descontoMoeda = "";
				} else {
					this.descontoMoeda = this.descontoMoeda.toLocaleString("pt-BR", {
						style:"decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			}, 800);
		},

		alterarValorManual() {
			if (
				this.valorReceber <= 0 ||
				(document.getElementById("descontoPercentual").disabled &&
				(!this.$store.state.Usuario.permiteDescontoAvista ||
				this.$store.state.Usuario.supervisor))
			) {
				return this.alterarValor = false;
			} else {
				this.valorFinal = Number(Number(this.valorReceber).toFixed(2)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.alterarValor = true;
				setTimeout(() => {
					document.getElementById("ajustarValor").focus();
					document.getElementById("ajustarValor").select();
				}, 10);
			}
		},

		ajustarValor(validarDesconto){
			let totalComDesconto = this.$store.state.itensVenda.map(item => {
				if (item.cdTipo != "T" && (item.situacao == "A" || !item.situacao || item.situacao == "S")){
					return Number(Number(Number(item.valorUnitario) * item.quantidade).toFixed(2));
				} else {
					return 0;
				}
			}).reduce((t, v) => t += v);
			const valorTotal = Number(this.valorFinal.split(".").join("").replace(",", "."));
			if (valorTotal > totalComDesconto + 5) {
				this.alterarValor = false;
				return this.$store.commit("relatarErro", {
					mensagem: "O valor a receber não pode ser maior que o valor total do pedido.\n Caso necessário altere o valor dos produtos!"
				});
			}
			let descontoPercentual = Number(totalComDesconto) - (valorTotal + Number(this.venda.valorTroca || 0));
			descontoPercentual = Number(Number(descontoPercentual / Number(totalComDesconto) * 100).toFixed(3));
			if (!validarDesconto) {
				this.beneficioAplicado = "Bruto";
				this.venda.beneficio = this.beneficioAplicado;
				if (!this.verificaDesconto(descontoPercentual)) return;
			}
			let descontoMoeda = Number(Number(totalComDesconto - (valorTotal + this.totalTrocas)).toFixed(2));
			this.$store.state.Venda.descontoMoeda = descontoMoeda;
			this.$store.state.Venda.descontoPercentual = descontoPercentual;
			this.descontoMoeda = descontoMoeda.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.descontoPercentual = descontoPercentual.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.alterarValor = false;
			this.valorFinal = valorTotal;
			this.$emit("atualizarTotal", (valorTotal));
		},

		cancelarRecebimento(){
			this.alterarValor = false;
			this.finalizarVenda = false;
			this.descontoPercentual = "";
			this.descontoMoeda = "";
			this.valorFinal = "";
			this.$emit("cancelarRecebimento");
		},

		confirmarRecebimento(){
			if (this.valorReceber > 0)  {
				return this.$store.commit("relatarErro", {mensagem: "Você ainda não recebeu o total da venda, favor informar os métodos de pagamento!"});
			}
			this.venda.descontoMoeda = Number(this.descontoMoeda.split(".").join("").replace(",", "."));
			this.venda.descontoPercentual = Number(this.descontoPercentual.split(".").join("").replace(",", "."));
			this.$emit("confirmarRecebimento");
		},

		autorizouDesconto(usuario) {
			try {
				this.solicitouAutorizacao = false;
				if (!usuario) return this.negouDesconto();
				if (!usuario.liberaDesconto) throw new Error("Usuário sem permissão para liberar desconto!");
				if (this.autenticacaoConfig.desconto > usuario.maxPorcentagemDesconto) throw new Error("Porcentagem de desconto maior que o permitido para autorizar!");
				if (!this.$store.state.Venda.liberacao) {
					this.$store.state.Venda.liberacao = {};
				}
				this.$store.state.Venda.liberacao.desconto = {
					liberado: true,
					percentual: this.autenticacaoConfig.desconto,
					cdUsuarioLiberacao: usuario.id,
				};
				this.descontoPercentual = (this.$store.state.Venda.liberacao.desconto.percentual || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.calcularDesconto("porcentagem");
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {mensagem: error.message});
				this.negouDesconto();
			}
		},
		negouDesconto() {
			this.solicitouAutorizacao = false;
			if (!this.$store.state.Venda.liberacao) {
				this.$store.state.Venda.liberacao = {};
			}
			this.$store.state.Venda.liberacao.desconto = {
				liberado: false,
				percentual: 0,
				cdUsuarioLiberacao: null,
			};
			this.$store.state.Venda.liberacao.desconto = {percentual: 0};
			this.descontoPercentual = (this.$store.state.Usuario.maxPorcentagemDesconto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.calcularDesconto("percentual");
		},
		solicitaAutorizacao(item) {
			if (item.liberaDesconto) {
				this.solicitouAutorizacao = true;
				this.autenticacaoConfig.desconto = item.desconto;
				this.autenticacaoConfig.mensagem = item.mensagem;
				this.autenticacaoConfig.autorizar = this.autorizouDesconto;
				this.autenticacaoConfig.negar = this.negouDesconto;
			}
			//this.$router.replace({name: "Autenticacao", query: {...this.$route.query,autorizacao: true}, params: {desconto: item.desconto, route: "Fechamento", novoDesconto: true}});
		},

		polarCancelado(item) {
			if (item.desconto) {
				this.alterarValor = false;
				if (this.$route.params.desconto) {
					if (this.$route.query.autorizacao) {
						this.descontoPercentual = Number(this.$route.params.desconto).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					} else {
						this.descontoPercentual = Number(this.Usuario.maxPorcentagemDesconto).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
						this.$route.params.desconto = "";
					}
					this.valorFinal = this.valorReceber;
				} else {
					this.beneficioAplicado = "Bruto";
					this.venda.beneficio = this.beneficioAplicado;
					this.descontoMoeda = "";
					this.descontoPercentual = "";
					this.venda.itens.forEach(item => {
						if (item.cdpromocao) {
							item.promocaoAntiga = item.cdpromocao;
							item.cdpromocao = null;
						}
					});
				}
				this.calcularDesconto("percentual");
			}
		},

		isClientePadrao(cliente) {
			const clientePadrao = this.$store.state.ClientePadrao;

			return clientePadrao.id === cliente.id ? true : false;
		}
	},

	created() {
		this.$bus.$on("recalcularValores", this.recalcularValores);
		this.$bus.$on("descontoFormaPagamento", this.descontoFormaPagamento);
		this.$bus.$on("confirmarPolar", this.solicitaAutorizacao);
		this.$bus.$on("cancelarPolar", this.polarCancelado);
		this.$bus.$on("cancelouPagamento/Desconto", this.cancelarPagamento);
	},
	
	mounted() {
		
		this.verificaPromocaoFormaPagamento();
		if(!this.isClientePadrao(this.$store.state.Venda.cliente)){
			this.$store.dispatch("verificaSituacaoCliente", {...this.$store.state.Venda.cliente});
		}
		this.$bus.$on("descontoCliente", this.calcularDesconto);
		this.valorFinal = Number(Number(this.totalSaidaVenda - this.totalTrocas).toFixed(2));
		this.$emit("atualizarTotal", this.valorFinal);
		this.venda.beneficio = "";

		const classiCliente = this.$store.state.Venda.cliente.classificacao;
		const descontoClassificacao = this.venda.valorVista ? 
				classiCliente?.descvista
			: classiCliente?.descprazo;
		if(!this.$store.state.LancarCusto){
			// Desconto por classificação
			if (
				classiCliente &&
				!this.isClientePadrao(this.$store.state.Venda.cliente) &&
				((this.$route.params.desconto < descontoClassificacao)
				|| !this.$route.params.desconto && !this.existePromocao)
			) {
				setTimeout(() => {
					if (classiCliente.descvista > 0 || classiCliente.descprazo > 0) {
						if (this.$store.state.Venda.valorVista) {
							this.descontoPercentual = Number(
								classiCliente?.descvista > 0 ? Number(classiCliente.descvista) : 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
						} else {
							this.descontoPercentual = Number(classiCliente?.descprazo > 0 ? Number(classiCliente.descprazo) : 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
						}
						this.beneficioAplicado = "Classificacao";
						this.venda.beneficio = this.beneficioAplicado;
						this.calcularDesconto("porcentagem");
					}
				}, 50);
			} else if (this.$store.state.Venda.descontoMoeda && this.$route.query.alteracao) {
				setTimeout(() => {
					this.descontoMoeda = typeof this.$store.state.Venda.descontoMoeda == "number" ? (this.$store.state.Venda.descontoMoeda).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}) : this.$store.state.Venda.descontoMoeda;
					this.calcularDesconto("valor");
				}, 50);
			} else if (
				// Desconto promocao
				this.existePromocao && 
					((this.$route.params.desconto < (this.venda.valorVista ? classiCliente?.descvista : classiCliente?.descprazo))
					|| !this.$route.params.desconto)
			) {
				if(!this.$store.state.LancarCusto){
					this.beneficioAplicado = "Promocional";
					this.venda.beneficio = this.beneficioAplicado;
					setTimeout(() => {
						this.recalcularValores(this.$store.state.Venda.valorVista ? "vista" : "prazo");
					}, 50);
				}
			}
		}
	},

	destroyed() {
		this.$bus.$off("cancelouPagamento/Desconto", this.cancelarPagamento);
		this.$bus.$off("descontoFormaPagamento", this.descontoFormaPagamento);
		this.$bus.$off("recalcularValores", this.recalcularValores);
		this.$bus.$off("descontoCliente", this.calcularDesconto);
		this.$bus.$off("confirmarPolar", this.solicitaAutorizacao);
		this.$bus.$off("cancelarPolar", this.polarCancelado);
	},

};
</script>

<style scoped>
.w-45 {
	width: 48%;
}

input {
	font-size: var(--txt-medio);
	text-align: right;
}

label,
span {
	font-size: var(--txt-medio);
}

.modal-desconto {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

@media screen and (max-width: 1024px) {
	.w-45 {
		width: 48%;
	}
}

@media screen and (max-width: 768px) {
	.btn.w-45 {
		height: auto;
		padding: 2px;
	}
}

@media screen and (max-width: 380px) {

	input,
	label,
	span,
	* {
		font-size: var(--txt-pequeno);
	}
}

@media screen and (max-height: 585px) {
	.btn {
		height: auto;
	}
}
</style>