import { dateFormatISO } from "../utils/dateformat";

class EntradaFinanceiroRetornoAPI {
    cddig;
    cddoc;
    cdtipopgto;
    contarc;
    databaixa;
    datadoc;
    datavenc;
    debito;
    observa;
    pagamento;
	cdagencia;
	cdbanco;
	cdconta;
	tipo;
	observacao;
}

export class EntradaFinanceiro {
	constructor(financeiro = new EntradaFinanceiroRetornoAPI()) {
		this.cddig = financeiro.cddig;
		this.cddoc = financeiro.cddoc;
		this.cdlan = financeiro.cdlan;
		this.cdmovi = financeiro.cdmovi;
		this.cdtipo = financeiro.cdtipo;
		this.contarc = financeiro.contarc;
		this.credito = financeiro.credito;
		this.databaixa = financeiro.databaixa;
		this.datadoc = financeiro.datadoc;
		this.datavenc = financeiro.datavenc;
		this.debito = financeiro.debito;
		this.observa = financeiro.observa;
		this.pagamento = financeiro.pagamento;
		this.cdagencia = financeiro.cdagencia;
		this.cdbanco = financeiro.cdbanco;
		this.cdconta = financeiro.cdconta;
		this.tipo = financeiro.tipo;
		this.observacao = financeiro.observacao;
	}
}

export class EntradaFinanceiroAPI {
	constructor(fin, cdcompra) {
		this.cddig = fin.cddig || 0,
		this.cddoc = cdcompra || "";
		this.cdtipopgto = fin.cdtipo || (fin.formaPagamento?.cdtipo || null);
		this.parcela = fin.parcela;
		this.pagamento = fin.pagamento || (fin.formaPagamento?.descricao);
		this.datadoc = dateFormatISO({date: null, format: "ISOdate"});
		this.datavenc =  dateFormatISO({date: fin.datavenc, format: "ISOdate"});
		if (fin.formaPagamento?.vistaprazo == 1 || fin.formaPagamento?.cartao == "S") {
			this.databaixa = dateFormatISO({
				date:fin.dataBaixa || new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					new Date().getDate(),
					new Date().getHours() -3,
					new Date().getMinutes(),
					new Date().getMilliseconds()
				).toISOString(),
				format: "ISOdate"
			});
		} else {
			this.databaixa = null;
		}
		const contarc = Number(typeof fin.contarc == "number" ? fin.contarc : Number(`${fin.contarc || 0}`.split("-").join("").replace(",", "."))) || 0;
		this.debito = (fin.formaPagamento?.vistaprazo == 1) ? contarc : 0;
		this.contarc = (fin.formaPagamento?.vistaprazo == 1) ? 0 : contarc;
		this.observa = fin.observa;
		this.observacao = fin.observacao;
	}
}