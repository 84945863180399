<template>
	<div class="container-page">
		<paginacao-filtro
			@filtrar="filtrar"
			@limpar="limparFiltro"
			@handleShowTable="handleShowTable"
			:ControlePaginacao="Paginacao"	
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="input-group select-group" style="min-width: 150px;">
								<select 
									v-model="tipoFiltro"
									id="tipoFiltro"
									@change="handleTipoFiltro"
								>
									<option v-for="(filtro, index) in Filtros" :key="index" :value="filtro">
										{{filtro}}
									</option>
								</select>
								<label for="tipoFiltro" style="background-color: #f8f8f8;">
									Opção Filtro
								</label>
							</div>
							<div class="input-group">
								<input 
									type="search" 
									id="textoPesquisa" 
									name="filtro" 
									placeholder=" " 
									autocomplete="off"
									v-model="filtroPesquisa"
								/>
								<label for="textoPesquisa" style="background-color: #f8f8f8;">
									Pesquisar
								</label>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<TabelaCidade
					@selecionarCidade="$emit('selecionarCidade', $event)"
					:showTable="showTable"
				/>
			</template>
		</paginacao-filtro>
	</div>
</template>
<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import TabelaCidade from "./TabelaCidade.vue";

export default{
	name: "ConsultaCidades",
	components: {
		PaginacaoFiltro,
		TabelaCidade
	},
	data() {
		return {
			tipoFiltro: "TODOS",
			Filtros: [
				"TODOS",
			],
			filtroPesquisa: "",
			showTable: true,
		};
	},

	computed: {
		Paginacao() {
			return {
				paginaAtual: this.$store.state.paginaCidadesAtual,
				totalPaginas: this.$store.state.totalPaginasCidades
			};
		}
	},

	methods: {
		handleShowTable() {
			this.showTable = !this.showTable;
		},

		limparFiltro() {
			this.tipoFiltro = "TODOS";
			this.filtroPesquisa = "";
			this.focarInputPesquisa();
			this.filtrar(null);
		},

		focarInputPesquisa() {
			setTimeout(() => {
				document.getElementById("textoPesquisa").focus();
			}, 50);
		},

		async filtrar(page) {			
			let filtro = {
				page: page || 0,				
				todos: this.tipoFiltro === "TODOS",
				query: this.filtroPesquisa,
			};
			await this.$store.dispatch("filtrarCidades", filtro);
		}
	},

	mounted() {		
		this.filtrar(); 
	}
};
</script>
<style scoped>
.container-page {
	width: 100%;
	height: 88vh;
	overflow: hidden;
}

.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;

}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
#textoPesquisa{
	width: 65vh;
}

.chipFilter{
	font-size: 14px;
	border-radius: 10px;
	text-transform: uppercase;
	white-space: nowrap;
	padding: 3px 10px;
	-webkit-box-shadow: 0px 5px 21px -4px rgba(133,133,133,1);
	-moz-box-shadow: 0px 5px 21px -4px rgba(133,133,133,1);
	box-shadow: 0px 5px 21px -4px rgba(133,133,133,1);
	display: flex;
	justify-content: center;
	align-items: center;
}
.chipFilter.selecionado{
	border: 2px solid var(--primario);
}
.checked {
    display: flex;
    align-content: center;
    justify-content: center;
}
.checked .selecionar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.check {
	display: block;
	position: relative;
	width: 60px;
	height: 30px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}


.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}
input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}


@media screen and (max-width: 769px) {

	#textoPesquisa{
		width: 42vh;
	}
}

@media screen and (max-width: 425px){
	#textoPesquisa{
		width: 100%;
	}
}
</style>