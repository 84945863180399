<template>
    <div class="lancamento-cheque-container">
        <h3>Lançamento dos dados do cheque</h3>
        <div class="lancamento-cheque">
            <div class="lancamento-cheque-col">
                <div class="input-group">
					<input type="text" id="valorCheque" v-model="cheque.valorCheque" class="text-right input-uppercase"
                    @keydown.enter="proximoCampo('qtdCheque')" :placeholder="` `" autocomplete="off"
                    @input="mascaraFloat">
					<label for="valorCheque">Valor</label>
                </div>
                <div class="input-group">
					<input type="text" id="qtdCheque" v-model="cheque.qtdCheque" @keydown.enter="proximoCampo('prazo')" class="input-uppercase"
                    placeholder=" " @input="mascaraIntegerCheque" :readonly="indexAlterarCheque >=0" autocomplete="off">
                    <label for="qtdCheque">Parcelas</label>
                </div>
                <div class="input-group">
					<input type="text" id="prazo" v-model="cheque.prazo" @keydown.enter="proximoCampo('vencimentoCheque')" class="input-uppercase"
                    placeholder=" " @input="calcularDataVencimento" autocomplete="off">
                    <label for="prazo">Prazo</label>
                </div>
            </div>
            <div class="lancamento-cheque-row">
                <div class="input-group" id="vencimento">
					<input type="date" id="vencimentoCheque" v-model="cheque.vencimentoCheque" class="input-uppercase"
                    placeholder="vencimento do cheque" @keydown.enter="proximoCampo('nominal')" @input="calcularDataVencimento"
					autocomplete="off">
                    <label for="vencimentoCheque" class="text-nowrap">{{cheque.qtdCheque > 1 ? "1º " : ""}}Vencimento</label>
                </div>
                <div class="input-group" id="nome">
					<input type="text" id="nominal" v-model="cheque.nominal" @keydown.enter="proximoCampo('numeroCheque')" class="input-uppercase"
                    placeholder=" " maxlength="40" autocomplete="off">
                    <label for="nominal">Nome</label>
                </div>
                <div class="input-group">
					<input type="text" id="numeroCheque" v-model="cheque.numeroCheque" @keydown.enter="proximoCampo('banco')" class="input-uppercase"
                    placeholder=" " maxlength="10" @input="mascaraIntegerCheque" autocomplete="off">
                    <label for="numeroCheque">Nº cheque</label>
                </div>
                <div class="input-group">
					<input type="text" id="banco" v-model="cheque.banco" @keydown.enter="proximoCampo('agencia')" class="input-uppercase"
                    placeholder=" " maxlength="10" @input="mascaraIntegerCheque" autocomplete="off">
                    <label for="banco">Nº Banco</label>
                </div>
                <div class="input-group">
					<input type="text" id="agencia" v-model="cheque.agencia" @keydown.enter="proximoCampo('conta')" class="input-uppercase"
                    placeholder=" " maxlength="10" @input="mascaraIntegerCheque" autocomplete="off">
                    <label for="agencia">Nº Agência</label>
                </div>
                <div class="input-group">
					<input type="text" id="conta" v-model="cheque.conta" @keydown.enter="proximoCampo('adicionarCheque')" class="input-uppercase"
                    placeholder=" " maxlength="10" @input="mascaraIntegerCheque" autocomplete="off">
                    <label for="conta">Nº Conta</label>
                </div>
            </div>
        </div>
        <div class="lancamento-cheque-acao">
            <button class="botao botao-acao" @click="adicionarCheque" id="adicionarCheque">{{indexAlterarCheque >= 0 ? "Alterar" : "Adicionar"}}</button>
            <button class="botao botao-cancelar" @click="cancelarAlteracaoCheque" id="cancelarAlteracaoCheque" v-if="indexAlterarCheque >= 0">Cancelar</button>
            <button class="botao botao-adicionar" @click="confirmarLancamentoCheque" id="confirmarLancamentoCheque" v-if="chequeLancado && indexAlterarCheque < 0">Concluir</button>
        </div>
        <div class="lancamento-cheque-lista w-100">
            <table v-if="cheques.length > 0" class="w-100">
                <thead>
                    <tr>
                        <td colspan="9" style="text-align: center; font-size: 18pt">Lista de Cheques</td>
                    </tr>
                    <tr>
                        <td style="text-align: center">Seq.</td>
                        <td style="text-align: center">Nominal</td>
                        <td style="text-align: center">Banco</td>
                        <td style="text-align: center">Agência</td>
                        <td style="text-align: center">Conta</td>
                        <td style="text-align: center">Cheque Nº</td>
                        <td style="text-align: right">Vencimento</td>
                        <td style="text-align: right">Valor</td>
                        <td class="text-center">Ação</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(cheque, index) in cheques" :key="index">
                        <td style="text-align: center">{{index + 1}}</td>
                        <td style="text-align: left">{{cheque.nominal}}</td>
                        <td style="text-align: right">{{cheque.banco}}</td>
                        <td style="text-align: right">{{cheque.agencia}}</td>
                        <td style="text-align: right">{{cheque.conta}}</td>
                        <td style="text-align: right">{{cheque.numeroCheque}}</td>
                        <td style="text-align: right">{{new Date(cheque.vencimentoCheque.split("-")).toLocaleDateString("pt-BR")}}</td>
                        <td style="text-align: right">{{Number(cheque.valorCheque ? cheque.valorCheque : 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}</td>
                        <td class="text-center">
                            <i class="fas fa-edit" style="color: var(--confirmar)" @click="editarCheque(index)"></i>
                            <i class="fas fa-trash-alt" style="color: var(--deletar)" @click="removerCheque(index)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { dateFormatISO } from "../utils/dateformat";
export default {
	name: "LancamentoCheque",
	props: {
		valorReceber: String,
		metodoRecebimento: Object
	},
	data() {
		return {
			lancamentoCheque: null,
			cheque: {
				banco: "",
				agencia: "",
				conta: "",
				numeroCheque: "",
				vencimentoCheque: "",
				nominal: "",
				valorCheque: "",
				qtdCheque: "1",
				prazo: "30"
			},
			cheques: [],			
			atualizaCheques: false,
			indexAlterarCheque: -1
		};
	},
	computed: {
		numberValorReceber() {
			return Number(`${this.valorReceber || 0}`.split(".").join("").replace(",", ".") || 0);
		},
		saldoLancamento() {
			this.atualizaCheques;
			let valor = 0;
			this.cheques.forEach(cheque => {				
				valor += Number(cheque.valorCheque);
			});
			return (this.numberValorReceber - valor);
		},
		chequeLancado() {
			let valor = 0;
			this.cheques.forEach(cheque => {
				valor += Number(cheque.valorCheque);
			});
			return (Number(this.numberValorReceber) - Number(valor.toFixed(2))) <= 0;
		}
	},
	methods: {
		proximoCampo(idCampo) {
			setTimeout(() => {
				const input = document.getElementById(idCampo);
				if (input) {
					input.focus();
					if (input.select) input.select();
				}
			},100);
		},
		calcularDataVencimento(e) {
			const id = e.target.id;
			if (id == "prazo") {
				this.mascaraIntegerCheque(e);
				this.cheque.vencimentoCheque = dateFormatISO({date: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					new Date().getDate() + Number(this.cheque.prazo)
				).toLocaleDateString("en-CA"), format: "ISOdate"});
			}
		},
		mascaraIntegerCheque(evento) {
			const id = evento.target.id;
			const data = evento.data;
			const valor = evento.target.value;
			const index = valor.indexOf(data);
			const regex = /[0-9]/;
			if (!regex.test(data) && data != null) {
				this.cheque[id] = `${this.cheque[id]}`.substring(0, index) + `${this.cheque[id]}`.substring(index+1, `${this.cheque[id]}`.length);
				setTimeout(() => {
					const input = document.getElementById(id);
					input.selectionStart = index;
					input.selectionEnd = index;
					input.focus();
				},0);
			}
		},
		mascaraFloat(evento = new InputEvent()) {
			const id = evento.target.id;
			const data = evento.data;
			const valor = evento.target.value;
			const index = valor.indexOf(data);
			const regex = /[0-9,]/;
			if (!regex.test(data) && data != null) {
				this.cheque[id] = `${this.cheque[id]}`.substring(0, index) + `${this.cheque[id]}`.substring(index+1, `${this.cheque[id]}`.length);
				setTimeout(() => {
					const input = document.getElementById(id);
					input.selectionStart = index;
					input.selectionEnd = index;
					input.focus();
				},0);
			}
		},        
		adicionarCheque() {
			try {
				let numeroParcelas = Number(this.cheque.qtdCheque);
				if(this.metodoRecebimento.qtdeParcela < this.cheque.qtdCheque) {
					throw new Error("O número de parcelas passou do limite vinculado ao método de recebimento, verifique!");
				}
				if (this.chequeLancado && this.indexAlterarCheque < 0) {
					throw new Error("Parece que a somatória dos cheques já corresponde ao valor a receber neste método de pagamento, favor verificar o valor e tente novamente!");
				}
				let valorCheque = Number(`${this.cheque.valorCheque || 0}`.split(".").join("").replace(",", ".") || 0);
				if (!valorCheque || isNaN(valorCheque)) {
					throw new Error("Favor verificar o valor do cheque e tente novamente!");
				}
				if (!this.cheque.nominal) {
					throw new Error("Favor informar o nome do pagador do cheque e tente novamente!");
				}
				if (!this.cheque.banco) {
					throw new Error("Favor informar o número do banco do cheque e tente novamente!");
				}
				if (!this.cheque.agencia) {
					throw new Error("Favor informar o número da agência do cheque e tente novamente!");
				}
				if (!this.cheque.conta) {
					throw new Error("Favor informar o número da conta do cheque e tente novamente!");
				}
				if (!this.cheque.vencimentoCheque) {
					throw new Error("Favor informar o vencimento do cheque e tente novamente!");
				}
				if (!this.cheque.numeroCheque) {
					throw new Error("Favor informar o número do cheque e tente novamente!");
				}
				if (isNaN(numeroParcelas)) {
					throw new Error("Favor verificar a quantidade de parcelas do cheque e tente novamente!");
				}
				let valorRestante = Number(valorCheque);
				if (this.indexAlterarCheque < 0) {
					if ((valorCheque > this.saldoLancamento)) {
						throw new Error("Parece que a somatória dos cheques ficará maior que o valor a receber neste método de pagamento, favor verificar o valor e tente novamente!");
					}
					for (let i = 0; i < numeroParcelas; i++) {
						let valorParcela = Number((valorCheque / numeroParcelas).toFixed(2));
						this.cheques.push({
							valorCheque: i+1 == numeroParcelas ? Number(valorRestante.toFixed(2)) : Number(valorParcela).toFixed(2),
							nominal: this.cheque.nominal.toUpperCase(),
							numeroCheque: Number(this.cheque.numeroCheque) + i,
							banco: this.cheque.banco,
							agencia: this.cheque.agencia,
							conta: this.cheque.conta,
							vencimentoCheque: i == 0 ? this.cheque.vencimentoCheque : dateFormatISO({ date:new Date(
								new Date(`${this.cheque.vencimentoCheque}`.split("-")).getFullYear(),
								new Date(`${this.cheque.vencimentoCheque}`.split("-")).getMonth(),
								(new Date(`${this.cheque.vencimentoCheque}`.split("-")).getDate() + (i * Number(this.cheque.prazo)))
							).toLocaleDateString("en-CA"), format: "ISOdate"}),
						});
						valorRestante -= valorParcela;
					}
				} else {
					if (valorCheque > this.cheques[this.indexAlterarCheque].valorCheque) {
						if ((valorCheque - this.cheques[this.indexAlterarCheque].valorCheque) > this.saldoLancamento) {
							throw new Error("O valor informado irá ultrapassar o valor restante, verifique!");
						}
					}
					this.cheques[this.indexAlterarCheque] = {
						valorCheque,
						nominal: this.cheque.nominal.toUpperCase(),
						numeroCheque: this.cheque.numeroCheque,
						banco: this.cheque.banco,
						agencia: this.cheque.agencia,
						conta: this.cheque.conta,
						vencimentoCheque: this.cheque.vencimentoCheque,
					};
					this.indexAlterarCheque = -1;
					this.cheques = this.cheques.filter(cheque => cheque.valorCheque > 0);
				}
				Object.keys(this.cheque).forEach(key => this.cheque[key] = "");
				this.cheque.valorCheque = (this.saldoLancamento.toFixed(2)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(".",",");
				this.cheque.qtdCheque = "1";
				this.cheque.prazo = "30";
				this.cheque.vencimentoCheque = dateFormatISO({date: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					new Date().getDate() + Number(this.cheque.prazo || 30)
				).toLocaleDateString("en-CA"), format: "ISOdate"});
				this.proximoCampo("valorCheque");
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},
		cancelarAlteracaoCheque() {
			this.cheque.valorCheque = (this.saldoLancamento).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.cheque.qtdCheque = "1";
			this.cheque.prazo = "30";
			this.cheque.vencimentoCheque = dateFormatISO({date: new Date(
				new Date().getFullYear(),
				new Date().getMonth(),
				new Date().getDate() + Number(this.cheque.prazo || 30)
			).toLocaleDateString("en-CA"), format: "ISOdate"});
			this.proximoCampo("valorCheque");
			this.indexAlterarCheque = -1;
		},
		confirmarLancamentoCheque() {
			const parcela = {
				numeroParcela: 1,
				valorParcela: Number(this.numberValorReceber.toFixed(6)),
				dataVencimento: new Date().toLocaleDateString("pt-BR"),
				dataEmissao: new Date().toLocaleDateString("pt-BR"),
				metodoParcela: this.metodoRecebimento.descricao,
				cdTipo: this.metodoRecebimento.cdTipo,
				cartao: this.metodoRecebimento.cartao,
				cheque: this.metodoRecebimento.cheque,
				valorVista: this.metodoRecebimento.valorVista,
				vistaprazo: this.metodoRecebimento.vistaprazo,
				bandeira: null,
				doccartao: null,
				nsucartao: null,
				observacao: this.observacao,
				cheques: this.cheques || []
			};
			this.$emit("confirmarParcelas", parcela);
		},
		editarCheque(index) {
			this.indexAlterarCheque = index;
			const hoje = new Date(new Date().toLocaleDateString("en-CA")).getTime();
			const venc = new Date(this.cheques[index].vencimentoCheque).getTime();
			const diffMilissegundos = venc - hoje;
			const diffSegundos = diffMilissegundos / 1000;
			const diffMinutos = diffSegundos / 60;
			const diffHoras = diffMinutos / 60;
			const diffDias = diffHoras / 24;
			this.cheque = {
				valorCheque: Number(this.cheques[index].valorCheque).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
				nominal: this.cheques[index].nominal.toUpperCase(),
				numeroCheque: this.cheques[index].numeroCheque,
				banco: this.cheques[index].banco,
				agencia: this.cheques[index].agencia,
				conta: this.cheques[index].conta,
				vencimentoCheque: this.cheques[index].vencimentoCheque,
				prazo: diffDias,
				qtdCheque: "1"
			};
			this.proximoCampo("valorCheque");
		},
		removerCheque(index) {
			this.cheques.splice(index, 1);			
			this.atualizaCheques = !this.atualizaCheques;
			this.cheque.valorCheque = (this.saldoLancamento.toFixed(2)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(".",",");
		},
		lancarCheque(index) {
			this.parcelas[index].valorCheque = Number(this.parcelas[index].valorParcela).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.parcelas[index].nominal = this.$store.state.Venda.cliente?.nome || "";
			this.parcelas[index].numeroCheque = "";
			this.parcelas[index].banco = "";
			this.parcelas[index].agencia = "";
			this.parcelas[index].conta = "";
			this.parcelas[index].vencimentoCheque = dateFormatISO({date: new Date(
				new Date().getFullYear(),
				new Date().getMonth(),
				new Date().getDate() + Number(this.cheque.prazo)
			).toLocaleDateString("en-CA"), format: "ISOdate"});
			this.lancamentoCheque = index;
			this.proximoCampo("valorCheque");
		},
	},
	mounted() {
		this.cheque.valorCheque = this.valorReceber;
		this.cheque.vencimentoCheque = new Date(
			new Date().getFullYear(),
			new Date().getMonth(),
			new Date().getDate() + Number(this.cheque.prazo)
		).toLocaleDateString("en-CA");
		this.cheque.vencimentoCheque = dateFormatISO({date: this.cheque.vencimentoCheque, format: "ISOdate"});
		this.proximoCampo("valorCheque");
	}
};
</script>

<style scoped>
#adicionarCheque {
	font-size: 12pt;
}
.input-group label {
	height: 13px;
}
.lancamento-cheque-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.lancamento-cheque {
    display: flex;
    flex-direction: row;
    width: 100%;
	margin-top: 10px;
}

.lancamento-cheque-col {
    display: flex;
    flex-direction: column;
	gap: 10px;
	width: 15%;
}

#qtdCheque, #prazo {
	text-align: center;
}

.lancamento-cheque-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.lancamento-cheque-item {
    display: flex;
    flex-direction: row;
    margin: 5px;
    align-items: center;
    height: max-content;
}
.lancamento-cheque-item label {
    width: 120px;
    text-align: right;
}

.lancamento-cheque-acao {
    display: flex;
    margin: 15px 5px 5px 5px;
    align-items: center;
    justify-content: center;
}

.lancamento-cheque-acao button {
    font-size: 11pt;
    height: auto;
    width: 100%;
    margin: 0px 5px;
}

@media screen and (max-width: 1024px) {
	.lancamento-cheque-col {
		width: 25%;
	}
}

@media screen and (max-width: 768px) {
	.lancamento-cheque-col {
		width: 45%;
	}

	.lancamento-cheque-row {
		gap: 10px;
	}
}

@media screen and (max-width: 440px) {
	.lancamento-cheque {
		flex-wrap: wrap;
		gap: 10px;
	}

	.lancamento-cheque-col {
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		justify-content: center;
	}

	.lancamento-cheque-col .input-group {
		width: 30%;
		margin: 0;
	}

	.lancamento-cheque-row {
		flex-direction: row;
		flex-wrap: wrap;
		gap: 10px;
		justify-content: center;
	}

	.lancamento-cheque-row .input-group {
		width: 43%;
	}

	#nome {
		width: 100%;
	}
}

@media screen and (max-width: 375px) {
	.lancamento-cheque-row .input-group {
		width: 42%;
	}
}

@media screen and (max-width: 320px) {
	.lancamento-cheque-row .input-group {
		width: 40%;
	}

	#vencimento {
		width: 50%;
	}
}
</style>