<template>
	<div class="container-page">
		<paginacao-filtro
			@filtrar="filtrar"
			@limpar="limparFiltro"
			:mostrarBotaoNovo="mostrarBotaoNovo"
			@adicionarNovo="$emit('adicionarNovo')"
			@handleShowTable="handleShowTable"
			:ControlePaginacao="Paginacao"			
			:habilitaFiltroGrupo="true"
			:modal="modal"
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="input-group select-group">
								<select 
									v-model="tipoFiltro"
									id="tipoFiltro"
									@change="handleTipoFiltro"
								>
									<option v-for="(filtro, index) in Filtros" :key="index" :value="filtro">
										{{filtro}}
									</option>
								</select>
								<label for="tipoFiltro" style="background-color: #f8f8f8;">
									Opção Filtro
								</label>
							</div>
							<div class="input-group">
								<input 
									type="search" 
									id="textoPesquisa" 
									name="filtro" 
									placeholder=" " 
									autocomplete="off"
									v-model="filtroPesquisa"
								/>
								<label for="textoPesquisa" style="background-color: #f8f8f8;">
									Pesquisar
								</label>
							</div>
							<div 
								class="chipFilter" 
								id="todasEmpresas" 
								@click="activeTodasEmpresas('todasEmpresas')"
								v-if="Empresas && Empresas.length > 1"
							>
								<input 
									type="checkbox" 
									v-model="todasEmpresas" 
									id="todasEmpresas"
								/>
								<label>Todas Empresas</label>
							</div>
							<div class="checked">
								<div class="selecionar">
									<label for="js-checkbox-pesquisa" v-if="checkboxPesquisa" class="pointer">
										&nbsp;
										Saldo
									</label>
									<label for="js-checkbox-pesquisa" v-else class="pointer">
										&nbsp;
										Ambos
									</label>
									&nbsp;
									<input
										type="checkbox"
										id="js-checkbox-pesquisa"
										@change="checkboxPesquisa = !checkboxPesquisa"
										:checked="checkboxPesquisa"

									/>
									<label class="check" for="js-checkbox-pesquisa"></label>
								</div>
							</div>
						</div>
						<div class="row-filtro" style="justify-content: center;">
							<div class="buscar-todos" v-if="buscarTodosPorDescricao" >
								<label for="selectAll">Incluir Todos</label>
								<div class="checked">
									<div class="selecionar">
										<input type="checkbox"  id="selectAll"  v-model="selectAll" @change="selecionarTodos" />
										<label class="check" for="selectAll"></label>
										<span v-if="selectAll">&nbsp;SIM</span>
										<span v-else>&nbsp;NÃO</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<listagem-produtos 
					:Produtos="Produtos"
					:height="height ? height : '60vh'"
					@selecionarProduto="$emit('selecionarProduto', $event)"
					:showTable="showTable"
					:trocaListagem="trocaListagem"					
					:troca="troca"
					:trocaBuscaVenda="trocaBuscaVenda"
					@fecharModal="$emit('fecharModal', $event)"
					:desabilitarAcao="desabilitarAcao || false"
				/>
			</template>
		</paginacao-filtro>
	</div>
</template>
<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import ListagemProdutos from "../components/ListagemProdutos.vue";

export default{
	name: "ConsultaProdutos",
	components: {
		PaginacaoFiltro,
		ListagemProdutos
	},
	props: {
		mostrarBotaoNovo: Boolean,
		Produtos: Array,
		trocaListagem: Boolean,
		troca: Boolean,
		trocaBuscaVenda: Boolean,
		height: String,
		modal: Boolean,
		buscarTodosPorDescricao: Boolean,
		desabilitarAcao: Boolean,
	},
	data() {
		return {
			tipoFiltro: "TODOS",
			Filtros: [
				"TODOS",
				"DESCRIÇÃO",
				"CÓDIGO",
				"CÓDIGO DE BARRAS",
				"REFERÊNCIA"
			],
			filtroPesquisa: "",
			todasEmpresas: false,
			checkboxPesquisa: true,
			showTable: true,
			selectAll: false,
			filtro:{},
		};
	},

	computed: {
		listadeproduto() {
			return this.$store.state.Produtos;
		},
		Empresas() {
			return this.$store.state.Empresas;
		},
		Paginacao() {
			return {
				paginaAtual: this.$store.state.paginaProdutosAtual,
				totalPaginas: this.$store.state.totalPaginasProdutos
			};
		}
	},

	methods: {
		handleShowTable() {
			this.showTable = !this.showTable;
		},

		limparFiltro() {
			this.filtro = {};
			this.selectAll = false;
			this.tipoFiltro = "TODOS";
			this.filtroPesquisa = "";
			this.checkboxPesquisa = true;
			this.focarInputPesquisa();
			this.todasEmpresas = false;
			const element = document.getElementById("todasEmpresas");
			if(element.classList.contains("selecionado")){
				element.classList.remove("selecionado");
			}
		},

		activeTodasEmpresas(id) {
			const element = document.getElementById(`${id}`);
			element.classList.toggle("selecionado");
			this.todasEmpresas = !this.todasEmpresas;
		},

		handleTipoFiltro() {
			this.filtroPesquisa = "";
			this.focarInputPesquisa();
		},

		focarInputPesquisa() {
			setTimeout(() => {
				document.getElementById("textoPesquisa").focus();
			}, 50);
		},

		async filtrar(page) {
			this.selectAll = false;
			this.$bus.$emit("buscarProdutosTodasEmpresas", this.todasEmpresas);
			this.$store.state.PrimeiraListagemProduto = false;
			let filtro = {
				page: page || 0,
				checkboxPesquisa: this.checkboxPesquisa,
				descricao: this.tipoFiltro === "DESCRIÇÃO" ? this.filtroPesquisa : null,
				buscaTodasEmpresas: this.todasEmpresas,
				todos: this.tipoFiltro === "TODOS",
				query: this.filtroPesquisa,
				cdProduto: this.tipoFiltro === "CÓDIGO" ? this.filtroPesquisa : null,
				codbarra: this.tipoFiltro === "CÓDIGO DE BARRAS" ? this.filtroPesquisa : null,
				Cdreferencia: this.tipoFiltro === "REFERÊNCIA" ? this.filtroPesquisa : null,
			};
			this.filtro = filtro;
			if(this.$store.state.Filtro?.retorno){
				filtro = this.$store.state.Filtro.filtro;
				this.$store.state.Filtro = {...this.$store.state.Filtro, retorno:false};
			}
			else{
				this.$store.state.Filtro = {filtro:filtro, model:{tipoFiltro:this.tipoFiltro,filtroPesquisa:this.filtroPesquisa,todasEmpresas:this.todasEmpresas,checkboxPesquisa:this.checkboxPesquisa}, retorno:false};
			}
			await this.$store.dispatch("filtrarProdutos", filtro);
		},

		selecionarTodos () {
			this.selectAll = this.selectAll ? true : false;
			if(this.listadeproduto.length == 0){
				return this.$store.commit("relatarErro", {mensagem: "Não ha produtos para adicionar á promoção"});
			}
			if(this.selectAll){

				this.$emit("selecionarTodos", this.filtro, () => {this.selectAll = false;});
			}
			this.$forceUpdate();
		},
	},

	mounted() {
		this.$store.state.PrimeiraListagemProduto = true;
		this.$store.state.Produtos = [];
		this.$store.state.paginaProdutosAtual = 0;
		this.$store.state.totalPaginasProdutos = 0;
		if(this.$store.state.Filtro?.retorno){
			this.tipoFiltro=this.$store.state.Filtro.model.tipoFiltro;			
			this.filtroPesquisa=this.$store.state.Filtro.model.filtroPesquisa;
			this.todasEmpresas=this.$store.state.Filtro.model.todasEmpresas;
			this.checkboxPesquisa=this.$store.state.Filtro.model.checkboxPesquisa;
			if(this.todasEmpresas){
				const element = document.getElementById("todasEmpresas");
				element.classList.toggle("selecionado");
			}
			this.filtrar();
		}
	}
};
</script>
<style scoped>
.container-page {
	width: 100%;
	overflow: hidden;
}

.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;

}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
#textoPesquisa{
	width: 65vh;
}

.chipFilter{
	font-size: 14px;
	border-radius: 10px;
	text-transform: uppercase;
	white-space: nowrap;
	padding: 3px 10px;
	-webkit-box-shadow: 0px 5px 21px -4px rgba(133,133,133,1);
	-moz-box-shadow: 0px 5px 21px -4px rgba(133,133,133,1);
	box-shadow: 0px 5px 21px -4px rgba(133,133,133,1);
	display: flex;
	justify-content: center;
	align-items: center;
}
.chipFilter.selecionado{
	border: 2px solid var(--primario);
}
.checked {
    display: flex;
    align-content: center;
    justify-content: center;
}
.checked .selecionar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.check {
	display: block;
	position: relative;
	width: 60px;
	height: 30px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}


.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}
input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}


@media screen and (max-width: 769px) {

	#textoPesquisa{
		width: 42vh;
	}
}

@media screen and (max-width: 425px){
	#textoPesquisa{
		width: 100%;
	}
}
</style>