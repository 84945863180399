<template>
	<div class="w-100">
		<div id="finalizarVenda">
			<MetodosPagamento
				id="inferiorDireita"
				:totalVenda="totalComDesconto"				
				@selecionarPagamento="selecionarPagamento"
				@apagaPagamento="apagaPagamento"
				@liberarPagamento="liberarPagamento"
				@verificarPix="verificarPix"
				:valorReceber="valorReceber"
			/>
			<ControleFechamento :venda="venda" :valorReceber="valorReceber" :valorRecebido="valorRecebido"
				v-on:cancelarRecebimento="cancelarRecebimento" :totalComDesconto="totalComDesconto"
				v-on:confirmarPagamento="confirmarPagamento" v-on:desabilitarDesconto="desabilitarDesconto"
				v-on:confirmarRecebimento="inserirNovaVenda" v-on:atualizarTotal="atualizarTotal"
				v-on:habilitarDesconto="habilitarDesconto" @recalcularRecebidos="recalcularRecebidos()"
				@cancelarPagamentoComDesconto="cancelarPagamentoComDesconto"/>
			<IdentificadorVendedorCliente v-on:trocarCliente="trocarCliente"
				v-on:trocarVendedor="listarVendedores = true" v-on:trocarCaixa="listarCaixas = true"
				v-on:salvarInformacoes="salvouInformacoes" v-on:consultarContas="consultarContas" />
		</div>

		<Modal v-if="vendaFinalizada" v-on:fecharModal="fecharModal" :dimensoes="{width: '95vw', height: 'auto'}">
			<PreImpressao
				:vendaFinalizada="vendaFinalizada"
				v-on:fecharModal="fecharModal"
				v-on:alterarVenda="alterarVenda"
				:troco="troco"
				@keydown="atalhosTeclado"
			/>
		</Modal>

		<Modal v-if="listarParcelas" v-on:fecharModal="cancelouPagamento">
			<ParcelarPagamento :valorReceber="recebendoValor" :metodoRecebimento="metodoRecebimento ? metodoRecebimento : {}"
				v-on:confirmarParcelas="confirmarPagamento" />
		</Modal>

		<Modal v-if="listarClientes" v-on:fecharModal="fecharModal">
			<consulta-cliente  
				@selecionarPessoa="selecionarCliente" 
				v-on:consultarContas="consultarContas"
				:mostrarAcao="true"
				v-on:adicionarNovo="cadastrandoCliente = {novo: true}"
				:mostrarBotaoNovo="true"
				:height="'50vh'"
				v-if="!cadastrandoCliente"
			/>
			<!-- <Paginacao tipoConteudo="Clientes" v-if="!cadastrandoCliente">
				<TabelaPessoas
					:mostrarAcao="true"
					tipoConteudo="Clientes"
					v-on:fecharModal="fecharModal"
					v-on:consultarContas="consultarContas"
					v-on:selecionarPessoa="selecionarCliente"
				/>
			</Paginacao> -->
			<CadastroCliente
				v-else
				:cliente="cadastrandoCliente"
				@cancelarEdicao="cadastrandoCliente = null"
				@clienteCadastrado="alterarClienteVenda"
			/>
		</Modal>
		<Modal v-if="listarVendedores" v-on:fecharModal="fecharModal">
			<consulta-vendedores 
				@selecionarPessoa="selecionarVendedor"
			/>
			<!-- <Paginacao tipoConteudo="Vendedores" :desabilitarNovo="true">
				<TabelaPessoas tipoConteudo="Vendedores" v-on:selecionarPessoa="selecionarVendedor" />
			</Paginacao> -->
		</Modal>

		<SelecionaCaixa v-if="listarCaixas" v-on:fecharModal="fecharModal" />

		<Modal v-if="receberConta" v-on:fecharModal="fecharConsultaContas">
			<ContasReceber v-on:fecharContasReceber="fecharConsultaContas" :clienteProps="consultarPessoa" />
		</Modal>
		<Modal v-if="abrirModal" @fecharModal="fecharModal('pix')" :dimensoes="{width: '40%', height: 'auto'}">
			<PixCobranca :dados="form" :pagamento="pagamento" :valorPix="valorPix"  @finalizarVenda="selecionarPagamento" @fecharModal="abrirModal = false;"/>
		</Modal>
		<Modal 
			v-if="deposito.modal"
			@fecharModal="cancelaDeposito(deposito.metodo)"
			:dimensoes="{width: '400px', height: '150px'}"
			:idModal="'depositoModal'"
		>
			<div class="input-group" style="margin-top:20px">
				<select v-model="conta" id="conta">
					<option value="SEL" disabled>
						SELECIONE UMA CONTA
					</option>
					<option 
						v-for="(conta, index) in ContasPorEmpresa"
						:key="index"
						:value="{agencia: conta.agencia, banco: conta.banco, conta: conta.cc}"
					>
						{{ conta.nome }}
					</option>
				</select>
				<label>Conta do Recebimento</label>
			</div>
			<div class="botoes" style="justify-content: space-between;margin-top: 20px;">
				<button class="botao botao-cancelar" @click="cancelaDeposito(deposito.metodo)">Cancelar</button>
				<button class="botao botao-adicionar" @click="confirmaDeposito">Continuar</button>
			</div>
		</Modal>
		<Autenticacao v-if="solicitouAutorizacao" :mensagem="autenticacaoConfig.mensagem"
			:autorizar="autenticacaoConfig.autorizar" :negar="autenticacaoConfig.negar" :preVenda="autenticacaoConfig.preVenda" :autorizacao="true" />
	</div>
</template>

<script>
import IdentificadorVendedorCliente from "../components/IdentificadorVendedorCliente.vue";
// import TabelaPessoas from "../components/TabelaPessoas.vue";
// import Paginacao from "../components/Paginacao.vue";
import Modal from "../components/Modal.vue";
import MetodosPagamento from "../components/MetodosPagamento.vue";
import ParcelarPagamento from "../components/ParcelarPagamento.vue";
import ControleFechamento from "../components/ControleFechamento.vue";
import PreImpressao from "../components/PreImpressao.vue";
import SelecionaCaixa from "../components/SelecionaCaixa.vue";
import ContasReceber from "../pages/ContasReceber.vue";
import Autenticacao from "../components/Autenticacao.vue";
import CadastroCliente from "../components/CadastroCliente.vue";
import PixCobranca from "../components/PixCobranca.vue";
import ConsultaCliente from "../components/ConsultaCliente.vue";
import ConsultaVendedores from "../components/ConsultaVendedores.vue";

export default {
	name: "FinalizarVenda",

	components: {
		IdentificadorVendedorCliente,
		Modal,
		// TabelaPessoas,
		// Paginacao,
		MetodosPagamento,
		ParcelarPagamento,
		ControleFechamento,
		PreImpressao,
		SelecionaCaixa,
		ContasReceber,
		Autenticacao,
		CadastroCliente,
		PixCobranca,
		ConsultaCliente, 
		ConsultaVendedores,
	},

	data() {
		return {
			conta:"SEL",
			deposito:{modal: false},
			solicitouAutorizacao: false,
			autenticacaoConfig: {},
			listarParcelas: false,
			listarClientes: false,
			cadastrandoCliente: null,
			listarVendedores: false,
			listarCaixas: false,
			vendaFinalizada: null,
			recebidos: 0,
			diaVencimento: "",
			primeiraParcela: "",
			metodoRecebimento: null,
			parcelas: [],
			totalComDesconto: 0,
			receberConta: false,
			consultarPessoa: null,
			abrirModal: false,
			form: {},
			valorRestanteDesconto: 0,
		};
	},

	computed: {
		totalBruto(){
			let preco = 0;
			// mudar aqui tambem
			this.$store.state.Venda.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D") {
					if (this.$store.state.Venda.valorVista) {
						const promocaoAplicavel = item.cdpromocao && item.produto.valorPromocaoVista > 0;
						const vlrunitario = item.valorUnitario;
						const { alteracao } = this.$route.query;
						const alterouVlrUnitario = !promocaoAplicavel
							? vlrunitario !== item.produto.valorVista && vlrunitario !== item.produto.valorPrazo
							: vlrunitario !== item.produto.valorPromocaoVista && vlrunitario !== item.produto.valorPromocaoPrazo;
						let vlrNormal = 0;

						if(alteracao && alterouVlrUnitario && promocaoAplicavel) {
							vlrNormal = vlrunitario;
						} else if(alteracao && !alterouVlrUnitario && promocaoAplicavel) {
							vlrNormal = item.produto.valorPromocaoVista;
						} else if(!alterouVlrUnitario && !promocaoAplicavel) {
							vlrNormal = item.produto.valorVista;
						} else if(alterouVlrUnitario && !promocaoAplicavel) {
							vlrNormal = vlrunitario;
						} else if(promocaoAplicavel && alterouVlrUnitario) {
							vlrNormal = item.produto.valorPromocaoVista;
						} else {
							vlrNormal = item.valorUnitario;
						}

						// const vlrNormal = item.cdpromocao ? item.produto.valorPromocaoVista : item.produto.valorVista;
						preco += Number(vlrNormal * item.quantidade);
					} else {
						const promocaoAplicavel = item.cdpromocao && item.produto.valorPromocaoPrazo > 0;
						const vlrunitario = item.valorUnitario;
						const { alteracao } = this.$route.query;
						const alterouVlrUnitario = !promocaoAplicavel
							? vlrunitario !== item.produto.valorVista && vlrunitario !== item.produto.valorPrazo
							: vlrunitario !== item.produto.valorPromocaoVista && vlrunitario !== item.produto.valorPromocaoPrazo;
						let vlrNormal = 0;
						if(alteracao && alterouVlrUnitario && promocaoAplicavel) {
							vlrNormal = vlrunitario;
						} else if(alteracao && !alterouVlrUnitario && promocaoAplicavel) {
							vlrNormal = item.produto.valorPromocaoPrazo;
						}
						else if(!alterouVlrUnitario && !promocaoAplicavel) {
							vlrNormal = item.produto.valorPrazo;
						} else if(alterouVlrUnitario && !promocaoAplicavel) {
							vlrNormal = vlrunitario;
						} else if(promocaoAplicavel && alterouVlrUnitario) {
							vlrNormal = item.produto.valorPromocaoPrazo;
						} else {
							vlrNormal = vlrunitario;
						}

						// const vlrNormal = item.cdpromocao ? item.produto.valorPromocaoPrazo : item.produto.valorPrazo;
						preco += Number(vlrNormal * item.quantidade);
					}
				}
			});
			return (preco - this.totalTrocas);
		},

		Contas(){
			return this.$store.state.Contas.filter(c => c.ativo === "A" && c.aceitapix);
		},

		Empresa() {
			return this.$store.state.Empresa;
		},

		ContasPorEmpresa() {
			return this.Contas.length > 0 
				? this.Contas.filter(conta => conta.cdempresa === this.Empresa.id) 
				: [];
		},

		Usuario(){
			return this.$store.state.Usuario;
		},
		
		venda() {
			return this.$store.state.Venda;
		},
		totalSaidaVenda() {
			let preco = 0;
			this.venda.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D" && item.situacao != "B") {
					preco += item.valorUnitario * item.quantidade;
				}
			});
			return preco;
		},
		totalTrocas() {
			let preco = 0;
			this.venda.itens.forEach(item => {
				if (item.cdTipo == "T") {
					preco += item.valorUnitario * item.quantidade;
				}
			});
			return preco;
		},
		valorRecebido() {
			let preco = Number(Number(this.recebidos).toFixed(2));
			return preco;
		},
		valorReceber() {
			let preco = Number(Number((this.totalComDesconto) - this.valorRecebido));
			return preco < 0 ? 0 : preco;
		},
		formasPagamento() {
			return this.$store.state.formasPagamento;
		},
		troco() {
			let preco = Number(Number(this.valorRecebido - this.totalComDesconto).toFixed(2));
			return preco > 0 ? preco : 0;
		},
	},

	methods: {
		confirmaDeposito(){
			try{
				if(this.conta === "SEL"){
					throw new Error("Conta inválida. Selecione a conta que deseja receber o depósito!");
				}
				this.venda.financeiro.push({
					valorParcela: this.deposito.valorParcela,
					cdTipo: this.metodoRecebimento.cdTipo || this.metodoRecebimento.cdtipo,
					valorVista: this.metodoRecebimento.valorVista,
					vistaprazo: this.metodoRecebimento.vistaprazo,
					metodoParcela: this.metodoRecebimento.cdTipo || this.metodoRecebimento.cdtipo,
					txid: this.metodoRecebimento.txid,
					ehPix: this.metodoRecebimento.ehPix,
					numeroParcela: this.venda.financeiro.length + 1,
					dataEmissao: new Date().toLocaleDateString("pt-BR"),
					dataVencimento: new Date().toLocaleDateString("pt-BR"),
					contaCorrente: {
						cdagencia: this.conta.agencia,
						cdbanco: this.conta.banco,
						cdconta: this.conta.conta,
					},
				});
				this.recebidos += this.deposito.valorParcela;
				this.incluirPagamento();				
				this.conta="SEL";
				this.deposito.modal = false;
			}
			catch (error){
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}
		},
		cancelaDeposito(metodo){
			const tipo = metodo.cdTipo? metodo.cdTipo:metodo.cdtipo;
			const input = document.getElementById("metodo-"+tipo);
			input.value = null;
			this.conta="SEL";
			this.deposito.modal = false;
		},
		async verificarPix(pagamento){
			this.valorPix = pagamento.valor ? Number(pagamento?.valor?.replace(",", ".")) : Number(this.valorReceber);
			if(this.$store.state.ConfiguracaoPixEmpresa.length === 0){
				this.selecionarPagamento(pagamento);
			}
			if(this.$store.state.ConfiguracaoPixEmpresa[0]){
				this.form = {...this.$store.state.ConfiguracaoPixEmpresa[0], valor: pagamento?.valor || this.totalVenda};
				this.pagamento = {...pagamento};
				return this.abrirModal = true;
			}
		},
		trocarCliente() {
			if (this.$store.state.Venda.cliente?.id != this.$store.state.ClientePadrao.id) {
				this.cadastrandoCliente = this.$store.state.Venda.cliente;
			}
			this.listarClientes = true;
		},
		consultarContas(pessoa) {
			this.receberConta = true;
			this.consultarPessoa = pessoa;
		},
		fecharConsultaContas() {
			this.receberConta = false;
			this.consultarPessoa = null;
		},
		autorizouPagamento(usuario) {
			const pagamento = this.autenticacaoConfig.pagamento;
			try {
				this.solicitouAutorizacao = false;
				if (!usuario) {
					this.negouPagamento();
				} else {
					if (!usuario.liberaCliente) throw new Error("Usuário sem permissão para liberar a venda para o cliente!");
					if (!this.$store.state.Venda.liberacao) {
						this.$store.state.Venda.liberacao = {};
					}
					this.$store.state.Venda.liberacao.pagamento = {
						pagamento,
						liberado: true,
						cdusuarioliberacao: usuario.id,
						cliente: this.$store.state.Venda.cliente
					};
					pagamento.autorizado = true;
					this.selecionarPagamento(pagamento);
					this.$store.state.Venda.somenteVista = false;
				}
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", { mensagem: error.message });
				this.negouPagamento();
			}
		},
		negouPagamento() {
			this.solicitouAutorizacao = false;
			if (!this.$store.state.Venda.liberacao) return this.$store.state.Venda.liberacao = {pagamento: {}};
			this.$store.state.Venda.liberacao.pagamento = {
				liberado: false,
				cdusuarioliberacao: null
			};
			this.$store.state.Venda.somenteVista = true;
			setTimeout(() => {
				this.$store.commit("relatarErro", { mensagem: "Não será possível selecionar uma condição de pagamento a prazo!", alerta: true });
			}, 100);
		},
		// Autorização Cliente
		autorizouCliente(usuario) {
			const cliente = this.autenticacaoConfig.cliente;
			let vendaState = this.$store.state.Venda;
			try {
				this.solicitouAutorizacao = false;
				if (!usuario) {
					this.negouCliente(cliente);
				} else {
					if (!usuario.liberaCliente) throw new Error("Usuário sem permissão para liberar cliente!");
					if (!vendaState.liberacao) vendaState.liberacao = {cliente: {}};
					vendaState.liberacao.cliente = {
						...cliente,
						liberado: true,
						cdusuarioliberacao: usuario.id
					};
					cliente.autorizado = true;
					this.selecionarCliente(cliente);
					vendaState.somenteVista = false;
				}
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", { mensagem: error.message });
				this.negouCliente(cliente);
			}
		},
		negouCliente(cliente) {
			this.solicitouAutorizacao = false;
			let vendaState = this.$store.state.Venda;
			if (!vendaState.liberacao) return vendaState.liberacao = {cliente: {}};
			vendaState.liberacao.cliente = {
				liberado: false,
				cdusuarioliberacao: null
			};
			vendaState.somenteVista = true;
			this.alterarClienteVenda({ ...cliente, autorizado: false, bloqueio: true });
			setTimeout(() => {
				this.$store.commit("relatarErro", { mensagem: "Não será possível selecionar uma condição de pagamento a prazo!", alerta: true });
			}, 50);
		},
		async alterarClienteVenda(cliente) {
			let vendaState = this.$store.state.Venda;

			if (cliente.id != this.$store.state.ClientePadrao.id) {
				await this.$store.dispatch("buscarClientePorId", { id: cliente.id, venda: true });
			} else {
				this.$store.commit("atualizarConsumidorVenda", { ...cliente });
			}
			this.listarClientes = false;
			this.$bus.$emit("atualizadoConsumidorOuVendedor");
			this.$forceUpdate();
			if (vendaState.valorVista && !this.$route.params.desconto) {
				this.$bus.$emit("descontoCliente", "porcentagem", { porcentagem: vendaState.cliente.classificacao.descvista });
			} else {
				this.$bus.$emit("descontoCliente", "porcentagem", { porcentagem: vendaState.cliente.classificacao.descprazo });
			}
			if (cliente.mensagemVenda) {
				this.$store.commit("relatarErro", { mensagem: cliente.mensagemVenda, alerta: true });
			}
		},

		async selecionarCliente(cliente) {
			if (cliente.ativo != "A") {
				return this.$store.commit("relatarErro", { mensagem: "O cliente selecionado está inativo. Verifique!" });
			}
			if (((cliente.id == this.$store.state.ClientePadrao.id) && (this.$route.query.tipoVenda == "C" || (this.listarClientes != true ? this.listarClientes.selecionarCliente : false))) && !this.$store.state.Usuario.condicionalConsumidorFinal) {
				return this.$store.commit("relatarErro", { mensagem: "Não é possível vender condicional para consumidor final." });
			}
			if (!cliente.autorizado && (cliente.id != this.$store.state.ClientePadrao.id)) {
				if (!this.$store.state.Venda.liberacao?.cliente?.liberado || (this.$store.state.liberacao?.cliente?.id != cliente.id)) {
					const liberado = await this.$store.dispatch("verificaSituacaoCliente", { ...cliente });
					if (!liberado) return;
				}
			}
			if ((cliente.id != this.$store.state.ClientePadrao.id)) {
				this.cadastrandoCliente = cliente;
				return this.listarClientes = true;
			} else {
				await this.alterarClienteVenda(cliente);
			}
		},

		selecionarVendedor(vendedor) {
			this.$store.commit("atualizarVendedorVenda", { ...vendedor });
			this.listarVendedores = false;
		},

		desabilitarDesconto() {
			const percentual = document.getElementById("descontoPercentual");
			percentual.disabled = true;
			const valor = document.getElementById("descontoMoeda");
			valor.disabled = true;
		},

		habilitarDesconto() {
			const percentual = document.getElementById("descontoPercentual");
			percentual.disabled = false;
			const valor = document.getElementById("descontoMoeda");
			valor.disabled = false;
		},

		usuarioLiberarCliente(e, formaPagamento) {
			let vendaState = this.$store.state.Venda;
			let usuarioState = this.$store.state.Usuario;
			const valorPagamento = !e.target.value ? this.valorReceber : Number(`${e.target.value}`.split(".").join("").replace(",", "."));
			if (vendaState.liberacao?.liberado) {
				return vendaState.liberacao?.liberado;
			}
			if (vendaState.cliente.limitecredito == 0 || !vendaState.cliente.limitecredito) {
				if (usuarioState.liberaCliente) {
					if (!vendaState.liberacao) vendaState.liberacao = {};
					vendaState.liberacao.liberado = true;
					vendaState.liberacao.cdUsuarioLiberacao = usuarioState.id;
					vendaState.cdUsuarioLiberacao = usuarioState.id;
				}
				setTimeout(() => {
					this.$store.commit("relatarErro", {
						mensagem: `Cliente sem o limite de crédito.
						${!usuarioState.liberaCliente ? "Deseja solicitar autorização?" : ""}`,
						tipo: !usuarioState.liberaCliente ? "polar" : null,
						item: {
							liberarPagamento: true,
							clienteDevedor: { ...vendaState.cliente },
							...this.$store.state.situacaoCliente,
							mensagem: `${usuarioState.nome} está solicitando autorização para venda a prazo para o cliente com limite de crédito de ${vendaState.cliente.limitecredito.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}.`
						}
					});
				}, 100);
				return (usuarioState.liberaCliente);
			} else if (vendaState.cliente.limitecredito > 0) {
				if (formaPagamento.cartao != "S" && formaPagamento.vistaprazo != 1) {
					if ((Number(this.$store.state.situacaoCliente.creditoUsado || 0) + valorPagamento) >= vendaState.cliente.limitecredito) {
						setTimeout(() => {
							this.$store.commit("relatarErro", {
								mensagem: `Cliente atingiu o limite de crédito disponível no valor de ${vendaState.cliente.limitecredito.toLocaleString("pt-BR", {
									style: "currency", currency: "BRL"
								})}.
								${!usuarioState.liberaCliente ? "Deseja solicitar autorização?" : ""}`,
								tipo: !usuarioState.liberaCliente ? "polar" : null,
								item: {
									liberarPagamento: true,
									pagamento: formaPagamento,
									...this.$store.state.situacaoCliente,
									clienteDevedor: { ...vendaState.cliente },
									mensagem: `${usuarioState.nome} está solicitando autorização para venda a prazo para o cliente com limite de crédito de ${vendaState.cliente.limitecredito.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}.`
								}
							});
						}, 100);
						return (usuarioState.liberaCliente);
					}
				}
			}
			return ((this.valorReceber <= vendaState.cliente.limitecredito) || (usuarioState.liberaCliente));
		},

		incluirPagamento() {
			// this.recebendoValor = "";
			// if (this.metodoRecebimento.elementoHTML) {
			// 	this.metodoRecebimento.elementoHTML.readOnly = true;
			// 	this.metodoRecebimento.elementoHTML.style.border = "1px solid var(--confirmar)";
			// 	this.metodoRecebimento.elementoHTML.style.color = "var(--confirmar)";
			// }
			// this.desabilitarDesconto();
			// this.metodoRecebimento = null;
			if (Number(this.valorReceber.toFixed(2)) === 0) {
				this.inserirNovaVenda();
			}
		},

		adicionarParcela() {

			const existePagamento = this.venda.financeiro.find(fin => fin.cdTipo === this.metodoRecebimento?.cdTipo);

			if (existePagamento >= 0) {
				this.cancelouPagamento(this.metodoRecebimento);
			}

			const recebendoValor = Number(`${this.recebendoValor || ""}`.split(".").join("").replace(",", ".") || 0);

			const valorParcela = (recebendoValor <= this.valorReceber && !existePagamento) ? recebendoValor : Number(this.valorReceber);

			if (!this.metodoRecebimento.valorVista || (this.metodoRecebimento.vistaprazo != 1 || this.metodoRecebimento.cartao == "S" || this.metodoRecebimento.cheque == "S")) {
				this.listarParcelas = true;
				return this.$route.query.parcelas = true;
			}

			if(this.metodoRecebimento.deposito === "S" && this.ContasPorEmpresa.length > 1){
				this.deposito.modal = true;
				this.deposito.metodo = this.metodoRecebimento;
				this.deposito.valorParcela = valorParcela;
				return;
			}

			const contaEmpresaAtual = this.ContasPorEmpresa.length === 1 ? {
				cdagencia: this.ContasPorEmpresa[0].agencia,
				cdbanco: this.ContasPorEmpresa[0].banco,
				cdconta: this.ContasPorEmpresa[0].cc,
			} : null;
			this.venda.financeiro.push({
				valorParcela,
				cdTipo: this.metodoRecebimento.cdTipo || this.metodoRecebimento.cdtipo,
				valorVista: this.metodoRecebimento.valorVista,
				vistaprazo: this.metodoRecebimento.vistaprazo,
				metodoParcela: this.metodoRecebimento.cdTipo || this.metodoRecebimento.cdtipo,
				txid: this.metodoRecebimento.txid,
				ehPix: this.metodoRecebimento.ehPix,
				numeroParcela: this.venda.financeiro.length + 1,
				dataEmissao: new Date().toLocaleDateString("pt-BR"),
				dataVencimento: new Date().toLocaleDateString("pt-BR"),
				contaCorrente: contaEmpresaAtual?.cdagencia ? contaEmpresaAtual : null
			});
			

			this.recebidos += valorParcela;
			this.incluirPagamento();
		},

		validarAutorizacaoPagamento(pagamento) {
			const valorPagamento = pagamento.valor || this.valorReceber;
			let vendaState = this.$store.state.Venda;
			let usuarioState = this.$store.state.Usuario;
			const situacaoCliente = this.$store.state.situacaoCliente;
			if (vendaState.liberacao?.pagamento) {
				let valorMenorLiberado = false;
				if(vendaState.liberacao.pagamento.pagamento){
					const ehDescricaoIgual = vendaState.liberacao.pagamento.pagamento.descricao == pagamento.descricao;
					const ehValorhMenor = vendaState.liberacao.pagamento.pagamento.valor < pagamento.valor;
					valorMenorLiberado = ehDescricaoIgual && ehValorhMenor;
				}
				if (!valorMenorLiberado && vendaState.liberacao?.pagamento?.liberado && (vendaState.liberacao?.pagamento?.cliente?.id == vendaState.cliente.id)) {
					return vendaState.liberacao?.pagamento?.liberado;
				}
			}
			if(situacaoCliente.diasvencido > this.Empresa.carenciaCliente) {
				setTimeout(() => {
					this.$store.commit("relatarErro", {
						mensagem: `Cliente com ${situacaoCliente.diasvencido} dias em atraso.
						${!usuarioState.liberaCliente ? "Deseja solicitar autorização?" : ""}`,
						tipo: !usuarioState.liberaCliente ? "polar" : null,
						item: {
							pagamento,
							liberarPagamento: true,
							...situacaoCliente,
							clienteDevedor: { ...vendaState.cliente },
							mensagem: `${usuarioState.nome} está solicitando autorização para venda a prazo para o cliente com limite de crédito
							de ${Number(vendaState.cliente.limitecredito || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}.`
							
						}
					});
				}, 100);
				return usuarioState.liberaCliente;
			}
			if (vendaState.cliente.limitecredito == 0 || !vendaState.cliente.limitecredito) {
				if (usuarioState.liberaCliente) {
					if (!vendaState.liberacao) vendaState.liberacao = {pagamento: {}};
					if (!vendaState.liberacao?.pagamento) vendaState.liberacao.pagamento = {};
					vendaState.liberacao.pagamento.cliente = vendaState.cliente;
					vendaState.liberacao.pagamento.liberado = true;
					vendaState.liberacao.pagamento.cdUsuarioLiberacao = usuarioState.id;
					vendaState.cdUsuarioLiberacao = usuarioState.id;
				}
				setTimeout(() => {
					this.$store.commit("relatarErro", {
						mensagem: `Cliente sem o limite de crédito.
						${!usuarioState.liberaCliente ? "Deseja solicitar autorização?" : ""}`,
						tipo: !usuarioState.liberaCliente ? "polar" : null,
						item: {
							pagamento,
							liberarPagamento: true,
							...situacaoCliente,
							clienteDevedor: { ...vendaState.cliente },
							mensagem: `${usuarioState.nome} está solicitando autorização para venda a prazo para o cliente com limite de crédito
							de ${Number(vendaState.cliente.limitecredito || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}.`
							
						}
					});
				}, 100);
				return (usuarioState.liberaCliente);
			} else if (vendaState.cliente.limitecredito > 0) {
				if ((Number(situacaoCliente.creditoUsado || 0) + valorPagamento) >= vendaState.cliente.limitecredito) {
					setTimeout(() => {
						this.$store.commit("relatarErro", {
							mensagem: `Cliente atingiu o limite de crédito disponível no valor de ${vendaState.cliente.limitecredito.toLocaleString("pt-BR", {
								style: "currency", currency: "BRL"
							})}.
							${!usuarioState.liberaCliente ? "Deseja solicitar autorização?" : ""}`,
							tipo: !usuarioState.liberaCliente ? "polar" : null,
							item: {
								pagamento,
								liberarPagamento: true,
								...situacaoCliente,
								clienteDevedor: { ...vendaState.cliente },
								mensagem: `${usuarioState.nome} está solicitando autorização para vender em ${pagamento.descricao} para o cliente com limite de crédito de ${(vendaState.cliente.limitecredito || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} e o cliente já utilizou ${(this.$store.state.situacaoCliente.creditoUsado || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}.`
							}
						});
					}, 100);
					return (usuarioState.liberaCliente);
				}
			}
			return ((
				vendaState.cliente.limitecredito === - 1 ? true : valorPagamento <= vendaState.cliente.limitecredito) || (usuarioState.liberaCliente
			));
		},

		recalcularRecebidos() {
			this.recebidos = 0;
			this.venda.financeiro.forEach(fin => {
				this.recebidos += fin.valorParcela;
			});
			this.venda.consorcio.forEach(fin => {
				this.recebidos += fin.valor;
			});
		},

		cancelouPagamento(pagamento) {
			this.listarParcelas = false;
			delete this.$route.query.parcelas;
			this.venda.financeiro = this.venda.financeiro.filter(fin => fin?.cdTipo != pagamento?.cdTipo);
			this.recalcularRecebidos();
			setTimeout(() => {
				this.$bus.$emit("cancelouPagamento", pagamento ? pagamento : this.metodoRecebimento);
				this.metodoRecebimento = null;
			}, 50);
		},

		async selecionarPagamento(pagamento) {
			
			if (!pagamento.autorizado && (this.$store.state.Venda.cliente.id != this.$store.state.ClientePadrao.id)) {
				if (pagamento.cartao != "S" && pagamento.verificaSituacao != "N" && pagamento.deposito != "S" && !pagamento.valorVista) {
					if (!this.$store.state.situacaoCliente) {
						const liberado = await this.$store.dispatch("verificaSituacaoCliente", { ...this.$store.state.Venda.cliente });
						if (!liberado) return;
					}
					if (!this.validarAutorizacaoPagamento(pagamento)) return;
				}
			}
			if(
				!pagamento.autorizado 
				&& this.venda.valorVista
				&& (pagamento.vistaprazo == 3 && pagamento.quantidadediasdeprazo !== 0)
			){
				setTimeout(() => {
					this.$store.commit("relatarErro", {
						mensagem: `Pagamento à prazo com valor à vista.
						${!this.$store.state.Usuario.liberaCliente ? "Deseja solicitar autorização?" : ""}`,
						tipo: !this.$store.state.Usuario.liberaCliente ? "polar" : null,
						item: {
							pagamento,
							liberarPagamento: true,
							...this.$store.state.situacaoCliente,
							clienteDevedor: { ...this.$store.state.Venda.cliente },
							mensagem: `${this.$store.state.Usuario.nome} está solicitando autorização para venda a prazo com valor à vista.`
							
						}
					});
				}, 100);
				if(!this.$store.state.Usuario.liberaCliente) return;
			}
			/*
				QUANDO NAO RECEBE O VALOR TOTAL DA VENDA, A PROPRIEDADE DE PAGAMENTO.VALOR VEM PREENCHIDA
				QUANDO RECEBE O TOTAL DA VENDA ELE PEGA A VARIAVEL VALOR RECEBER

			*/
			if(pagamento.desconto) {
				/*
					QUANDO HOUVER PAGAMENTO.DESCONTO SABE-SE QUE TEM DESCONTO PELA FORMA DE PAGAMENTO
					O DESCONTO DEVE SER APLICADO A PARTIR DO VALOR QUE O USUARIO ESTA INSERINDO E NAO SOBRE
					O VALOR TOTAL DA VENDA

					PARA ATUALIZAR O VALOR DA VENDA (VALOR A RECEBER)
					const vlrDesconto = valorParaDesconto * (pagamento.desconto / 100);
					this.totalComDesconto = this.totalComDesconto - vlrDesconto;

					PARA ATUALIZAR O SUB TOTAL DA VENDA
					this.promocaoFormaPagamentoAplicavel = true;
					this.descontoFormaPagamento = pagamento.desconto;
					this.$bus.$emit("descontoFormaPagamento", {
						descontoAplicado: true,
						percDesconto: pagamento.desconto,
						vlrDesconto
					});	
				*/
				if(pagamento.valor > 0 && pagamento.valor > this.valorReceber) {
					return this.$store.commit("relatarErro", {mensagem: "Você esta tentando receber um valor maior que o valor da venda, por favor verifique"});
				}
				const valorParaDesconto = pagamento.valor === 0 ? this.valorReceber : pagamento.valor;

				const vlrDesconto = valorParaDesconto * (pagamento.desconto / 100);
				this.totalComDesconto = this.totalComDesconto - vlrDesconto;
				this.promocaoFormaPagamentoAplicavel = true;
				this.descontoFormaPagamento = pagamento.desconto;
				this.$bus.$emit("descontoFormaPagamento", {
					descontoAplicado: true,
					percDesconto: pagamento.desconto,
					vlrDesconto
				});
					
				/*
					QUANDO A VENDA FOR FEITA COM MAIS QUE UMA FORMA DE PAGAMENTO EM PROMOÇÃO
					TOTAL SEM DESCONTO - VALOR JA RECEBIDO x DESCONTO 2° FORMA DE PAGAMENTO
				*/
				// else {
				// 	if(pagamento.desconto && pagamento.desconto > 0) {
						
				// 		const vlrParaDesconto = !pagamento.valor || !pagamento.valor === 0 ? this.totalBruto : pagamento.valor;
				// 		console.log(vlrParaDesconto, "vlrParDesconto");
				// 		console.log(pagamento.desconto, "percDesconto");
				// 		const vlrDesconto = vlrParaDesconto * (pagamento.desconto / 100);
				// 		console.log(this.totalBruto - vlrDesconto);
						
				// 		const totalReceber = this.totalBruto - this.valorRecebido;
				// 		console.log(totalReceber, "total receber");
				// 		console.log(vlrDesconto, "vlr desconto");	
				// 		this.totalComDesconto = totalReceber - vlrDesconto;
				// 		console.log(this.totalComDesconto);
				// 	}
				// }
			}
			

			this.recebendoValor = (pagamento.valor || this.valorReceber).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			if (pagamento.valor == 0 && this.valorReceber + this.recebidos == this.totalComDesconto) {
				this.recebendoValor = this.valorReceber.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
			this.metodoRecebimento = {...pagamento};
			this.adicionarParcela();
		},
		apagaPagamento(cdTipo){
			const index = this.venda.financeiro.findIndex(fin => fin.cdTipo === cdTipo);
			if(index!=-1){
				this.recebidos -= this.venda.financeiro[index].valorParcela;
				this.venda.financeiro.splice(index,1);
			}
		},
		liberarPagamento(pagamento) {
			if (pagamento.elementoHTML) {
				pagamento.elementoHTML.readOnly = false;
				pagamento.elementoHTML.style.border = "1px solid #a9a9a9";
				pagamento.elementoHTML.style.color = "#292929";
			}
			this.cancelouPagamento(pagamento);
		},

		confirmarPagamento({ parcelas, valorRecebido, consorcio }) {
			this.recebidos = Number(Number(this.recebidos + valorRecebido).toFixed(2));
			if (!parcelas) return;
			if (consorcio) {
				if (parcelas.length) {
					this.venda.consorcio.push(...parcelas);
				} else {
					this.venda.consorcio = [];
				}
				return this.cancelouPagamento({cdTipo: null});
			}
			this.venda.financeiro.push(...parcelas); 
			this.listarParcelas = false;
			this.incluirPagamento();
		},

		cancelarRecebimento() {
			this.listarParcelas = false;
			this.recebendoValor = "";
			this.recebidos = 0;
			this.nParcelas = 1;
			this.diaVencimento = "";
			this.primeiraParcela = "";
			this.metodoRecebimento = null;
			const usuario = this.$store.state.Usuario;
			this.parcelas = [];
			if (!usuario?.supervisor && !usuario?.supervisor) {
				if (usuario?.caixa && !usuario?.alterarVenda) {
					if (this.$store.state.Venda.cdUsuarioCaixa != usuario?.id) {
						return this.$router.replace({ name: "Principal" });
					}
				}
			}
			this.$router.replace({ name: "Lancamento", query: { ...this.$route.query } });
		},

		async inserirNovaVenda() {
			try {
				let vendaState = this.$store.state.Venda; 

				if(Number(this.valorRecebido.toFixed(2)) > Number(vendaState?.valorBruto.toFixed(2))) {
					throw new Error("Não é possivel receber um valor maior que o valor da venda, por favor verifique");
				}

				if (!vendaState.vendedor || !vendaState.vendedor.id) {
					this.listarVendedores = true;
					throw new Error("É necessário informar o vendedor para prosseguir!");
				}
				if (Number(this.valorReceber.toFixed(2)) > 0) {
					throw new Error("Você ainda não recebeu o total da venda, favor informar os métoos de pagamento!");
				}
				if (this.venda.vendedor.id == 0) {
					throw new Error("Você ainda não selecionou o vendedor.");
				}
				let venda;
				if (!this.venda.cdVenda) {
					venda = await this.$store.dispatch("inserirVendaComPagamento", { ...this.venda });
				} else {
					venda = await this.$store.dispatch("inserirPagamentoVenda", { ...this.venda });
				}
				if (!venda) return;
				if (this.$route.query.baixarCondicional) {
					const vendasOrigem = vendaState.vendasOrigem;
					Object.keys(vendasOrigem).forEach(key => {
						vendasOrigem[key].baixarCondicional = true;
					});
					await this.$store.dispatch("alterarEmLote", {...vendasOrigem});
				}
				if (venda) {
					this.vendaFinalizada = { ...venda };
					this.$forceUpdate();
				}
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", { mensagem: `${error.message}` });
			}
		},
		atalhosTeclado(e) {
			if(e.key == "Tab"){
				e.preventDefault();
			}
			if (e.key == "Escape") {
				if (this.listarClientes) {
					this.listarClientes = false;
				}
				if (this.listarVendedores) {
					this.listarVendedores = false;
				}
				if (this.listarParcelas) {
					this.parcelas = [];
					return this.listarParcelas = false;
				}
			}
			if (e.key == "F2") {
				if (this.$route.query.baixarCondicional || this.$route.query.trocarProdutos) {
					return;
				}
				this.listarClientes = true;
			}
			if (`${e.key}`.toUpperCase() == "S" && e.altKey) {
				if (this.listarClientes) return;
				if (this.vendaFinalizada) return;
				return this.inserirNovaVenda();
			}
			if (`${e.key}`.toUpperCase() == "C" && e.altKey) {
				if (this.listarClientes) return;
				if (this.vendaFinalizada) return;
				return this.cancelarRecebimento();
			}
		},

		fecharModal(modal) {
			if(modal == "pix"){
				this.abrirModal = false;
				if(this.$store.state.CobrancaPix?.txid){
					return this.$store.dispatch("cancelarCobrancaPix", this.form);
				}
			}
			this.listarParcelas = false;
			delete this.$route.query.parcelas;
			this.listarClientes = false;
			this.listarVendedores = false;
			this.listarCaixas = false;
			if (this.vendaFinalizada) {
				this.vendaFinalizada = null;
				if (!this.$store.state.Usuario.caixa || !this.$store.state.Usuario.supervisor) {
					this.$store.dispatch("logOut");
				} else {
					if(this.$route.query.baixarCondicional || this.$store.state.Usuario.caixa){
						return this.$router.replace({name: "Principal"});
					}
					this.limparVenda();
				}
			}
		},
		limparVenda(){
			this.$store.commit("limparStateVenda");
			this.$router.replace({ name: "Lancamento", query: {tipoVenda: "S"}});
			this.$route.query.tipoVenda = "S";
			this.$forceUpdate();
		},
		atualizarTotal(total) {
			this.totalComDesconto = total;
			this.$store.state.Venda.valorBruto = this.totalComDesconto;
		},
		polarConfirmado(item) {
			if (item.liberaCliente) {
				this.listarClientes = false;
				this.solicitouAutorizacao = true;
				this.autenticacaoConfig.cliente = item.clienteDevedor;
				this.autenticacaoConfig.mensagem = item.mensagem;
				this.autenticacaoConfig.autorizar = this.autorizouCliente;
				this.autenticacaoConfig.negar = () => {this.negouCliente(item.clienteDevedor);};
			}
			if (item.liberarPagamento) {
				this.solicitouAutorizacao = true;
				this.autenticacaoConfig.pagamento = item.pagamento;
				this.autenticacaoConfig.mensagem = item.mensagem;
				this.autenticacaoConfig.autorizar = this.autorizouPagamento;
				this.autenticacaoConfig.negar = () => {
					this.solicitouAutorizacao = false;
				};
				this.autenticacaoConfig.preVenda = true;
			}

		},
		polarCancelado(item) {
			if (item?.liberarCondicional) return;
			if (item?.liberaCliente) return this.negouCliente(item.clienteDevedor);
			if (item?.liberarPagamento) return this.negouPagamento();
		},

		alterarVenda(venda) {
			this.$store.state.LancarCusto = false;
			this.$store.state.Venda = null;
			this.$store.state.Venda = {
				cliente: venda.cliente,
				vendedor: venda.vendedor,
				cdVenda: venda.cdVenda,
				dataEmissao: venda.dataEmissao,
				dataEntradaSaida: venda.dataEntradaSaida,
				cdUsuarioLiberacao: venda.cdUsuarioLiberacao,
				cdUsuarioCaixa: venda.cdUsuarioCaixa,
				nomeCliente: venda.nomeCliente,
				foneCliente: venda.foneCliente,
				enderecoCliente: venda.enderecoCliente,
				bairroCliente: venda.bairroCliente,
				cepCliente: venda.cepCliente,
				cidadeCliente: venda.cidadeCliente,
				cnpjCliente: venda.cnpjCliente,
				ieCliente: venda.ieCliente,
				ufCliente: venda.ufCliente,
				nascimentoCliente: venda.nascimentoCliente,
				numeroCliente: venda.numeroCliente,
				observacao: venda.observacao,
				identificador: venda.identificador,
				financeiro: venda.financeiro
			};
			this.$store.state.itensVenda = [];
			venda.itens.map(item => {
				item.vendaOrigem = venda.cdVenda;
				item.dataOrigem = venda.dataEmissao;
			});
			this.$store.state.itensVenda = [...venda.itens];
			this.$router.push({
				name: "Lancamento", query: {
					tipoVenda: venda.valorCondicional > 0 ? "C" : "S",
					alteracao: true,
					baixarCondicional: venda.valorCondicional > 0
				}
			});
		},

		async salvouInformacoes(informacoes) {
			Object.keys(informacoes).forEach(key => {
				this.$store.state.Venda[key] = informacoes[key];
			});
			if(this.Usuario.solicitaNomeConsumidorFinal){
				this.$store.state.Venda.cliente.nome = informacoes?.nomeCliente; 
				this.$bus.$emit("atualizadoConsumidorOuVendedor");
				return;
			}
			if (informacoes.cdCliente) {
				this.$store.state.carregando = true;
				const classificacao = await this.$store.dispatch("buscarClassificacaoPadrao");
				// const cliente = await this.$store.dispatch("buscarClientePorId", {id: informacoes.cdCliente, venda: true});
				const form = this.montarPayload(informacoes, classificacao); 
				await this.$store.dispatch("atualizarCliente", form);
				await this.$store.dispatch("buscarClientePorId", {id: informacoes.cdCliente, venda: true});
				this.$store.state.carregando = true;
				setTimeout(() => {
					this.$bus.$emit("atualizadoConsumidorOuVendedor");
					this.$store.state.carregando = false;
				},50);
			} else {
				this.$store.state.carregando = true;
				const classificacao = await this.$store.dispatch("buscarClassificacaoPadrao");				
				const form = this.montarPayload(informacoes, classificacao); 
				const cliente = await this.$store.dispatch("adicionarCliente", form);
				await this.$store.dispatch("buscarClientePorId", {id: cliente.id, venda: true});
				setTimeout(async () => {
					this.$bus.$emit("atualizadoConsumidorOuVendedor");
					this.$store.state.carregando = false;
				},50);
				this.$forceUpdate();
			}
		},
		montarPayload(informacoes, classificacao){
			return {
				id: informacoes.cdCliente ? informacoes.cdCliente : "",
				bairro: informacoes.bairroCliente ? informacoes.bairroCliente : "",
				cep: informacoes.cepCliente ? informacoes.cepCliente : "",
				cidade: {
					nomecidade: informacoes.cidadeCliente ? informacoes.cidadeCliente : "",
					uf: informacoes.ufCliente ? informacoes.ufCliente : "",
					cdcidade: informacoes.cdcidade,
				},
				dataNascimento: informacoes.dataNascimento ? informacoes.dataNascimento : "",
				classificacao: classificacao ? {...classificacao} : "",
				tipoPessoa: informacoes.tipoPessoa ? informacoes.tipoPessoa : "",
				fone: informacoes.foneCliente ? informacoes.foneCliente : "",
				cnpj: informacoes.cnpjCliente ? informacoes.cnpjCliente : "",
				endereco: informacoes.enderecoCliente ? informacoes.enderecoCliente : "",
				numero: informacoes.numeroEndereco ? informacoes.numeroEndereco : "",
				ie: informacoes.ie ? informacoes.ie : "",
				nome: informacoes.nomeCliente ? informacoes.nomeCliente : "",
				ativo: "A"
			};
		},
		cancelarPagamentoComDesconto(pagamento) {
			if(pagamento.vlrDesconto) {
				this.totalComDesconto = this.totalComDesconto + pagamento.vlrDesconto;
			}
		}
	},

	created() {
		document.addEventListener("keydown", this.atalhosTeclado);
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		this.$bus.$on("cancelarPolar", this.polarCancelado);
	},

	mounted() {
		this.venda.financeiro = [];
		this.venda.consorcio = [];
		this.totalComDesconto = Number((this.totalSaidaVenda || 0).toFixed(2));
		this.recebidos = 0;
		if ((this.$store.state.Caixa.caixa.cdaixa >= 0 ? this.$store.state.Caixa.caixa.cdaixa : this.$store.state.Caixa.caixa.cdCaixa) < 1 && !this.$store.state.Usuario.somenteVendaSemCaixa) {
			if (this.$store.state.Caixas.content.length == 1) {
				this.$store.dispatch("buscarCaixaPorIdentificador", { ...this.$store.state.Caixas.content[0] });
			} else {
				setTimeout(() => {
					this.listarCaixas = true;
				}, 20);
			}
		}
		if(this.valorReceber === 0){
			this.inserirNovaVenda();
		}
	},

	destroyed() {
		document.removeEventListener("keydown", this.atalhosTeclado);
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
		this.$bus.$off("cancelarPolar", this.polarCancelado);

	}

};
</script>

<style scoped >
#finalizarVenda {
	display: grid;
	grid-template-rows: 25vh 32.5vh 32.5vh;
	grid-template-columns: 40vw 1fr 1fr;
	margin: auto 0px;
	height: auto;
	max-width: 100vw;
}

#inferiorDireita {
	grid-row: 1/4;
	grid-column: 2/4;
}

#painelControle {
	grid-row: 2/4;
	grid-column: 1/2;
	display: flex;
	flex-direction: column;
	margin: 5px;
	background-color: var(--bg-secundario);
	padding: 10px;
	box-shadow: 1px 1px 3px #292929;
	justify-content: space-around;
	height: auto;
}

#desconto {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: max-content;
}

h3,
h1 {
	margin: 0px;
}


.w-100.col.between {
	height: 90vh;
}

.box {
	box-shadow: 2px 2px 5px #292929;
	margin: 10px 0px;
	padding: 20px 0px;
	border-radius: 20px;
}

.box input {
	font-size: 12pt;
	padding: 2px;
}

@media screen and (max-width: 930px) {
	#finalizarVenda {
		display: grid;
		grid-template-rows: auto auto auto;
		grid-template-columns: 1fr;
		max-width: 100vw;
		max-height: 92vh;
		overflow-x: hidden;
		overflow-y: auto;
	}

	#painelControle {
		grid-row: 1/2;
		grid-column: 1/2;
		display: flex;
	}

	#inferiorDireita {
		grid-row: 2/3;
		grid-column: 1/2;
		display: flex;
	}

	#identificadorEmpresa {
		grid-row: 3/4;
		grid-column: 1/2;
		display: flex;
	}

	.row.between.w-100 {
		flex-direction: column;
	}

	.desconto {
		flex-direction: column;
	}

	#rodapeAcao {
		flex-direction: row;
		margin: 5px 0px;
	}

	.btnPagamento {
		width: 40%;
	}

	.metodoPagamento label {
		width: 60%;
	}
}
</style>
