<template>
	<container-page>
		<content-page>

			<paginacao-filtro
				:itensNovo="['Lançar Título','Lançar Transferência']"
				:ControlePaginacao="Paginacao"
				:mostrarBotaoNovo="true"
				@adicionarNovo="novo"
				@filtrar="filtrar"
			>
				<template v-slot:filtro>
					<div class="filtro-content">
						<div class="column-filtro">
							<div class="row-filtro">
								<div class="input-group select-group">
									<select 
										v-model="tipoFiltro" id="tipoFiltro" 
										@change="handleFocusInput(tipoFiltro)"
									>
										<option value="SEL">SELECIONE UMA OPÇÃO</option>
										<option 
											v-for="(option, index) in optionsFiltro" 
											:value="option.value" :key="index">
											{{ option.text }}
										</option>
									</select>
									<label for="tipoFiltro">
										Filtrar por
									</label>
								</div>
							</div>
						</div>
						<div class="column-filtro">
							<div class="row-filtro" v-if="tipoFiltro === 'valor'">
								<div class="input-group">
									<input 
										type="text"
										placeholder=" "
										id="valor"
										v-model="FiltroContasPagar.valor"
										v-mascaraFloat
										autocomplete="off"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="valor">
										Valor
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'cdNota'">
								<div class="input-group">
									<input 
										type="text"  
										placeholder=" "
										id="cdnota"
										v-model="FiltroContasPagar.cdnota"
										autocomplete="off"
										v-mascaraInteger
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="cdnota">
										Cód. Compra
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'empresa'">
								<div class="input-group">
									<select 
										v-model="FiltroContasPagar.empresa" 
										id="empresa"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									>
										<option disabled value="SEL">
											SELECIONE UMA OPÇÃO
										</option>
										<option value="T">
											TODAS EMPRESAS
										</option>
										<option 
											v-for="(emp, index) in Empresas" 
											:key="index"
											:value="emp.id"
										>
											{{ emp.id }} - {{ emp.nome }}
										</option>
									</select>
									<label for="empresa">
										Empresa
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'cdPedido'">
								<div class="input-group">
									<input 
										type="text"  
										placeholder=" "
										id="cdpedido"
										v-model="FiltroContasPagar.cdpedido"
										v-mascaraInteger
										autocomplete="off"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									
									/>
									<label for="cdpedido">
										Cód. Pedido
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'fornecedor'">
								<div class="input-group">
									<input 
										type="search"  
										placeholder=" "
										id="fornecedor"
										ref="fornecedor"
										autocomplete="off"
										list="lista-fornecedor"
										v-model="FiltroContasPagar.textoBuscaFornecedor"
										@input="selecionarFornecedor"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="fornecedor">
										Fornecedor
									</label>
									<datalist id="lista-fornecedor">
										<option 
											v-for="(pessoa, index) in Pessoas" 
											:key="index" :value="pessoa.nome"
										>
										</option>
									</datalist>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'dataEmissao'">
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataEmissaoI" 
										v-model="FiltroContasPagar.dataEmissaoI"
										@keydown.enter="proximoCampo('dataEmsisaoF')"
									/>
									<label for="dataEmissaoI">
										Emissão de
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataEmsisaoF" 
										v-model="FiltroContasPagar.dataEmissaoF"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="dataEmsisaoF">
										Emissão até
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'dataBaixa'">
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataBaixaI" 
										v-model="FiltroContasPagar.dataBaixaI"
										@keydown.enter="proximoCampo('dataBaixaF')"
									/>
									<label for="dataBaixaI">
										Baixa de
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataBaixaF" 
										v-model="FiltroContasPagar.dataBaixaF"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="dataBaixaF">
										Baixa até
									</label>
								</div>
							</div>
							<div class="row-filtro" v-if="tipoFiltro === 'dataVenc'">
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataVencI" 
										v-model="FiltroContasPagar.dataVencI"
										@keydown.enter="proximoCampo('dataVencF')"
									/>
									<label for="dataVencI">
										{{ isMobileDevice ? 'Venc. de' : 'Vencimento de' }}
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataVencF" 
										v-model="FiltroContasPagar.dataVencF"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
										@blur="validarData('dataVencF')"
									/>
									<label for="dataVencF">
										{{ isMobileDevice ? 'Venc. até' : 'Vencimento até' }}   
									</label>
								</div>
							</div>
						</div>
						<div class="colum-filtro" v-if="tipoFiltro === 'fornecedor' || tipoFiltro === 'empresa'">
							<div class="row-filtro">
								<div class="input-group select-group">
									<select v-model="dataFiltro" id="dataFiltro">
										<option value="SEL">
											SELECIONE UMA OPÇÃO
										</option>
										<option 
											v-for="(data, index) in DatasFiltroSeparadas"
											:key="index"
											:value="data.value"
										>
											{{ data.text }}
										</option>
									</select>
									<label for="dataFiltro">
										Filtrar por data
									</label>
								</div>
							</div>
						</div>
						<div class="colum-filtro">
							<div class="row-filtro"
								v-if="dataFiltro === 'dataEmissao' && (tipoFiltro === 'fornecedor' || tipoFiltro === 'empresa')"
							>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataEmissaoI" 
										v-model="FiltroContasPagar.dataEmissaoI"
										@keydown.enter="proximoCampo('dataEmsisaoF')"
									/>
									<label for="dataEmissaoI">
										Emissão de
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataEmsisaoF" 
										v-model="FiltroContasPagar.dataEmissaoF"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="dataEmsisaoF">
										Emissão até
									</label>
								</div>
							</div>
							<div 
								class="row-filtro" 
								v-if="dataFiltro === 'dataBaixa' && (tipoFiltro === 'fornecedor' || tipoFiltro === 'empresa')"
							>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataBaixaI" 
										v-model="FiltroContasPagar.dataBaixaI"
										@keydown.enter="proximoCampo('dataBaixaF')"
									/>
									<label for="dataBaixaI">
										Baixa de
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataBaixaF" 
										v-model="FiltroContasPagar.dataBaixaF"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="dataBaixaF">
										Baixa até
									</label>
								</div>
							</div>
							<div 
								class="row-filtro" 
								v-if="dataFiltro === 'dataVenc' && (tipoFiltro === 'fornecedor' || tipoFiltro === 'empresa')"
							>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataVencI" 
										v-model="FiltroContasPagar.dataVencI"
										@keydown.enter="proximoCampo('dataVencF')"
									/>
									<label for="dataVencI">
										{{ isMobileDevice ? 'Venc. de' : 'Vencimento de' }}
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date" 
										placeholder=" " 
										id="dataVencF" 
										v-model="FiltroContasPagar.dataVencF"
										@keydown.enter.prevent="proximoCampo('botao-filtrar')"
									/>
									<label for="dataVencF">
										{{ isMobileDevice ? 'Venc. até' : 'Vencimento até' }}   
									</label>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-slot:tabela>
					<div 
						class="container-tabela tabela-container"  
						v-if="ContasPagar.length > 0 && !loadingTable" 
						id="table"
					>
						<div class="header-tabela">
							<div class="linha-header-tabela">
								<div class="campo-tabela campo-acao">
									<span>Ação</span>
								</div>
								<div class="campo-tabela campo-menor-emp">
									<span>Emp</span>
									<i class="fas fa-sort" @click="reordenar('cdempresa')" v-if="FiltroContasPagar.sort != 'cdempresa'"></i>
									<i class="fas fa-sort-up icon" @click="reordenar('cdempresa')" v-else-if="FiltroContasPagar.sortOrder == 'ASC'"></i>
									<i class="fas fa-sort-down" @click="reordenar('cdempresa')" v-else></i>
								</div>
								<div class="campo-tabela campo-menor">
									<span>Número</span>
									<i class="fas fa-sort"></i>
								</div>
								<div class="campo-tabela campo-medio" id="pessoa">
									<span>Pessoa</span>
									<i class="fas fa-sort" @click="reordenar('nome')" v-if="FiltroContasPagar.sort != 'nome'"></i>
									<i class="fas fa-sort-up icon" @click="reordenar('nome')" v-else-if="FiltroContasPagar.sortOrder == 'ASC'"></i>
									<i class="fas fa-sort-down" @click="reordenar('nome')" v-else></i>
								</div>
								<div class="campo-tabela valor">
									<span>Valor Título</span>
								</div>						
								<div class="campo-tabela data row">
									<span>Emissão</span>
									<i class="fas fa-sort" @click="reordenar('datadoc')" v-if="FiltroContasPagar.sort != 'datadoc'"></i>
									<i class="fas fa-sort-up icon" @click="reordenar('datadoc')" v-else-if="FiltroContasPagar.sortOrder == 'ASC'"></i>
									<i class="fas fa-sort-down" @click="reordenar('datadoc')" v-else></i>
								</div>
								<div class="campo-tabela data row">
									<span>Vencimento</span>
									<i class="fas fa-sort" @click="reordenar('datavenc')" v-if="FiltroContasPagar.sort != 'datavenc'"></i>
									<i class="fas fa-sort-up icon" @click="reordenar('datavenc')" v-else-if="FiltroContasPagar.sortOrder == 'ASC'"></i>
									<i class="fas fa-sort-down" @click="reordenar('datavenc')" v-else></i>
								</div>
								<div class="campo-tabela valor">
									<span>V. Parcela</span>
		
								</div>
								<div class="campo-tabela valor-pago">
									<span>V. Pago</span>
								</div>
								<div class="campo-tabela valor">
									<span>V. Restante</span>
								</div>
								<div class="campo-tabela data">
									<span>Baixa</span>
									<i class="fas fa-sort" ></i>		
								</div>
								<div class="campo-tabela campo-medio">
									<span>Forma Pgto</span>
								</div>
								<div class="campo-tabela campo-medio" id="observacao">
									<span>Observação</span>
								</div>
							</div>
						</div>
						<div class="body-tabela">
							<div 
								class="linha-body-tabela" 
								v-for="(conta, index) in ContasPagar" 
								:key="index" :id="`linha-${index}`"
								:class="contaVencida(conta.dataVenc)  && conta.situacao != 'Baixado' 
									? 'campo-tabela data linha-vermelha' 
									: 'campo-tabela data'"
							>
								<div class="campo-tabela campo-acao">
									<i class="fas fa-edit" style="color: var(--acao)"
										@click="abrirModalEditarTitulo(conta)"
									>
									</i>
									<i 
										class="fas fa-undo"
										style="color: var(--acao)"
										@click="estornarTituloBaixado(conta)"
										v-if="conta.situacao === 'Baixado'"
									></i>
								</div>
								<div class="campo-tabela campo-menor-emp">
									{{ conta.cdempresa }}
								</div>
								<div class="campo-tabela campo-menor">
									{{ conta.cdmovi }}
								</div>
								<div class="campo-tabela campo-medio" id="pessoa">
									{{ conta.nome }}
								</div>
								<div class="campo-tabela valor">
									{{ (conta.valorTitulo || 0) | formatarParaReais }}
								</div>
								<div class="campo-tabela data">
									{{ conta.dataEmissao }}
								</div>
								<div class="campo-tabela data">
									{{ conta.dataVenc }}
								</div>
								<div class="campo-tabela valor">
									{{ (conta.valorParcela || conta.debito || 0) | formatarParaReais }}
								</div>
								<div class="campo-tabela valor-pago">
									{{ (conta.valorPago || 0) | formatarParaReais }}
								</div>
								<div class="campo-tabela valor">
									{{ (conta.restante || 0) | formatarParaReais }}
								</div>
								<div class="campo-tabela data">
									{{ conta.dataBaixa }}
								</div>
								<div class="campo-tabela campo-medio">
									{{ conta.formaPagamento }}
								</div>
								<div class="campo-tabela campo-medio" id="observacao">
									{{ conta.observacaoCompra }}
								</div>
							</div>
						</div>
					</div>
					<div 
						v-else-if="loadingTable" 
						class="col center w-100 h-47vh"
					>
						<Loader 
							:width="'15vh'"
							:height="'15vh'"
						/>
					</div>
					<div 
						v-else
						class="col center w-100 triangle"
						id="table"
					>
						<i 
							class="fas fa-exclamation-triangle"
							style="color: yellow; font-size: 90pt"
						></i>
						<h3>
							Nenhuma conta encontrada no sistema.
						</h3>
						<h3>
							Tente alterar ou diminiur os parâmetros de busca.
						</h3>
					</div>
					<div class="row container-total" style="justify-content: center;">
						<!-- <div class="input-group">
							<input type="text" readonly class="input-valor"
							:value="`${TotalValorTitulo.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
							<label>Total Título</label>
						</div> -->
						<div class="input-group">
							<input type="text" readonly class="input-valor"
							:value="`${TotalValorParcela.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
							<label>Total Parcela</label>
						</div>
						<div class="input-group">
							<input type="text" readonly class="input-valor"
							:value="`${TotalValorPago.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
							<label>Total Pago</label>
						</div>
						<div class="input-group">
							<input type="text" readonly class="input-valor"
							:value="`${TotalValorRestante.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
							<label>Total Restante</label>
						</div>
					</div>
				</template>
			</paginacao-filtro>
			<!-- <Filtro 
				:habilitaDropdown="true" 
				:mostrarBotaoNovo="true"
				:itensNovo="['Lançar Título','Lançar Transferência']"
				:DropDownItens="dropDownItens"
				@adicionarNovo="novo"
				@abrirModalAdicionarDespesa="modalTitulo = true"
				@abrirModalAdicionarTransferencia="modalTransferencia = true"
			>
			</Filtro> -->
		</content-page>
		<Modal 
			v-if="modalTitulo"
			@fecharModal="fecharModal"
			:dimensoes="{
				width: '90%',
				height: '90%'
			}"
			:idModal="'adicionar-despesa'"
		>
			<AdicionarTitulo 
				:situTitulo="situacaoTitulo"
				:tipoTitulo="'despesa'"
				:Titulo="tituloEditando"
				@fecharLancamentoTitulo="fecharModal"
			/>
		</Modal>
		<Modal
			v-if="modalTransferencia"
			@fecharModal="modalTransferencia = false"
			:dimensoes="{
				width: '90%',
				height: '80%'
			}"
			:idModal="'adicionar-transferencia'"
		>
			<AdicionarTransferencia
				@fecharTransferencia="modalTransferencia = false"
			/>
		</Modal>
	</container-page>
</template>
<script>
import { proximoCampo } from "../utils/utils";
import { dateFormatBR } from "../utils/dateformat";
import formatarParaReais from "../filters/formatarParaMoeda";

import ContainerPage from "../components/ContainerPage.vue";
import ContentPage from "../components/ContentPage.vue";
// import Filtro from "../components/Filtro.vue";
import Modal from "../components/Modal.vue";
import AdicionarTitulo from "../components/AdicionarTitulo.vue";
import AdicionarTransferencia from "../components/AdicionarTransferencia.vue";
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import Loader from "../components/Loader.vue";


export default {
	name: "Despesas",
	components: {
		ContainerPage,
		ContentPage,
		// Filtro,
		Modal,
		AdicionarTitulo,
		AdicionarTransferencia,
		PaginacaoFiltro,
		Loader
	},
	data() {
		return {
			modalTitulo: false,
			modalTransferencia: false,
			activeDropDown: false,
			dropDownItens: [
				{
					event: "abrirModalAdicionarDespesa",
					title: "Lançar Título"
				},
				{
					event: "abrirModalAdicionarTransferencia",
					title: "Transferência"
				}
			],
			optionsFiltro: [
				{
					value: "dataBaixa",
					text: "Data baixa"
				},
				{
					value: "dataEmissao",
					text: "Data emissão"
				},
				{
					value: "fornecedor",
					text: "Fornecedor"
				},
				{
					value: "cdPedido",
					text: "Código pedido"
				},
				{
					value: "cdNota",
					text: "Código Compra"
				},
			],
			FiltroContasPagar: {
				dataEmissaoI: "",
				dataEmissaoF: "",
				dataBaixaI: "",
				dataBaixaF: "",	
			},
			proximoCampo,
			dateFormatBR,
			dataFiltro: "SEL",
			tipoFiltro: "SEL",
			loadingTable: false,
			situacaoTitulo: "B",
			tituloEditando: {}
		};
	},

	filters: {
		formatarParaReais
	},

	computed: {
		TotalValorTitulo(){
			let preco = 0;
			this.ContasPagar.map(item => {
				preco += (item.valorTitulo || 0);
			});
			return preco;
		},
		TotalValorParcela(){
			let preco = 0;
			this.ContasPagar.map(item => {
				preco += (item.valorParcela || 0);
			});
			return preco;
		},
		TotalValorPago(){
			let preco = 0;
			this.ContasPagar.map(item => {
				preco += (item.valorPago || 0);
			});
			return preco;
		},
		TotalValorRestante(){
			let preco = 0;
			this.ContasPagar.map(item => {
				preco += (item.restante || 0);
			});
			return preco;
		},

		Fornecedor() {
			return this.Pessoas.find(pessoa => 
				pessoa.nome === this.FiltroContasPagar?.textoBuscaFornecedor?.toUpperCase()
			);
		},

		Pessoas() {
			return this.$store.state.Clientes;
		},

		Paginacao(){
			return this.$store.state.ContasPagar.paginacao;
		},

		DatasFiltroSeparadas() {
			if(this.optionsFiltro?.length > 0) {
				const arrDatas = [];
				for(let i=0; i < this.optionsFiltro.length; i++) {
					const optFiltro = this.optionsFiltro[i];
					if(optFiltro.value.includes("data")) {
						arrDatas.push(optFiltro);
					}
				}
				return arrDatas;
			}
			return [];
		},

		ContasPagar(){
			return this.$store.state.ContasPagar.ListaContas;
		},
	},

	mounted() {
		this.montarDataFiltro();
		this.filtrar();
	},

	methods: {
		async estornarTituloBaixado(titulo){
			const formEstorno = {
				cdagencia: titulo.cdagencia?.trim(),
				cdbanco: titulo.cdbanco?.trim(),
				cdconta: titulo.cdconta?.trim(),	
				cddig: titulo.cddig,
				tipo: titulo.tipo,
			};
			await this.$store.dispatch("estornarTituloBaixado", formEstorno);
		},

		async buscarUnicaConta(titulo){
			const res = await this.$store.dispatch("buscarContaUnica", titulo);
			if(res && (!res.message || !res.erro)){
				return res;
			}
		},

		async abrirModalEditarTitulo(titulo){
			this.modalTitulo = true;
			this.tituloEditando = await this.buscarUnicaConta(titulo);
			this.tituloEditando.editando = true;
		},

		montarDataFiltro() {
			const anoAtual = new Date().getFullYear();
			const mesAtual = new Date().getMonth();
			const diaAtual = new Date().getDate();
			this.tipoFiltro = "dataBaixa";
			this.FiltroContasPagar.dataBaixaI = new Date(anoAtual, mesAtual, 1).toLocaleDateString("en-ca");
			this.FiltroContasPagar.dataBaixaF = new Date(anoAtual, mesAtual, diaAtual).toLocaleDateString("en-ca");
		},

		contaVencida(dataVencimento){
			const dataVencimentoSeparada = dataVencimento.split("/").reverse().join("-");
			const dataVencimentoObj = new Date(dataVencimentoSeparada);
			const dataHojeObj = new Date();
			return dataVencimentoObj.getTime() < dataHojeObj.getTime();
		},
		
		selecionarFornecedor() {
			setTimeout( async () => {
				await this.$store.dispatch("filtrarClientesPorNome", {
					query: this.FiltroContasPagar.textoBuscaFornecedor,
				});
			}, 300);
		},

		handleFocusInput(tipoFiltro) {
			const campoMapeamento = {
				"dataBaixa": "dataBaixaI",
				"dataEmissao": "dataEmissaoI",
				"dataVenc": "dataVencI",
				"fornecedor": "fornecedor",
				"cdPedido": "cdpedido",
				"cdNota": "cdnota",
				"empresa": "empresa",
			};
			const campo = campoMapeamento[tipoFiltro];
			if(campo) {
				this.proximoCampo(campo);
			}
			this.limpar();
		},

		limpar(limparOpcoesFiltro) {
			this.FiltroContasPagar = {
				dataVencI: "",
				dataVencF: "",
				dataBaixaI: "",
				dataBaixaF: "",
				dataEmissaoI: "",
				dataEmissaoF: "",
				textoBuscaFornecedor: "",
				empresa: "SEL",
				status: "A",
				valor: "",
				cdnota: "",
				cdpedido: "",				
				sort: null,
				sortOrder: "ASC",
			};
			if(limparOpcoesFiltro) {
				this.tipoFiltro = "SEL";
				this.dataFiltro = "SEL";
			}
		},

		fecharModal() {
			this.modalTitulo = false;
			this.tituloEditando = {};
			this.filtrar();
		},

		novo(item){
			if(item == "Lançar Título"){
				this.modalTitulo = true;
			}
			if(item == "Lançar Transferência"){
				this.modalTransferencia = true;
			}
		},

		async filtrar(page) {
			try {
				this.loadingTable = true;
				setTimeout( async () => {
					const filtro = this.montarOpcoesFiltro(this.FiltroContasPagar, page);
					const sort = this.FiltroContasPagar.sort ? this.FiltroContasPagar.sort : this.tipoFiltro === "dataEmissao" ? "datadoc" : "datavenc" ;

					await this.$store.dispatch("filtrarContasPagar", {...filtro, sort, sortOrder:this.FiltroContasPagar.sortOrder});
					this.loadingTable = false;
				}, 50);
			} catch (error) {
				console.error(error);
			}
		},

		montarOpcoesFiltro(options, page) {
			return {
				dataBaixaI: options.dataBaixaI || null,
				dataBaixaF: options.dataBaixaF || null,
				dataDocI: options.dataEmissaoI || null,
				dataDocF: options.dataEmissaoF || null,
				cdnota: options.cdnota || null,
				cdPedido: options.cdpedido || null,
				cdfornecedor: options.textoBuscaFornecedor ? this.Fornecedor?.id : "",
				condicao: "B",
				// valor: typeof options.valor === "string" ? parseFloat(options.valor.replace(".", "").replace(",", ".")) : Number(options.valor) || null,
				// cdempresa: options.empresa === "T" ? null : options.empresa === "SEL" ? null : options.empresa,
				query: !this.Fornecedor?.id ? options.textoBuscaFornecedor : null,
				page: page || 0,
			};
		},
	}
};
</script>
<style scoped>

.campo-medio{
	min-width: 150px;
}
.dragging{
	opacity: 0.5;
	cursor: grab;
}
.linhaSelecionada{
	background-color:  #6170f779 !important;
	color: #FFF;
}
.botao span{
	font-size: 15px;
}
.container-tabela{
	width: 89vw;
	overflow: auto;
	height: 55vh;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.container-tabela.fullTable {
	height: 65vh;
}
.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 2px 5px;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}

.campo-acao {
	min-width: 45px;
	text-align: center;
}
.campo-menor{
	width: 100px;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
}
.campo-menor-emp{
	width: 70px;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
}

.data{
	min-width: 120px;
	text-align: center;
}
#pessoa{
	width: 250px;
	max-width: 275px !important;
	overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;
	margin-left: 15px;
}
#observacao{
	width: 400px;
	text-align: left;
}
.valor{
	min-width: 135px;
	text-align: center;
}
.valor-pago{
	min-width: 125px;
	text-align: center;
}
.linha-header-tabela .campo-tabela i{
	margin-left: 10px;
	color: #7a7a7a;
}
.linha-vermelha{
	background-color: #ff0101c4;
	color: #FFF;
}
#baixar{
	cursor: grab;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	text-align: center;
	position: absolute;
	bottom: 30px;
	left: 150px;
	border: none;	
	outline: none;
	background-color: var(--acao-background);
}
#baixar i {
	font-size: 20px;
}
#relatorio{
	width: 50px;
	height: 50px;
	border-radius: 50%;
	text-align: center;
	position: absolute;
	bottom: 15px;
	right: 30px;
	border: none;	
	outline: none;
	background-color: var(--acao-background);
}
#relatorio i {
	font-size: 20px;
}
.triangle{
	height: 40vh;
}
.tableFull.triangle {
	height: 78vh;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 10px;
}
.column-filtro{
	display: flex;
	flex-direction: column;
}
.filtro-content{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
#fornecedor{
	width: 45vh;
}
#empresa {
	width: 45vh;
}
#changeFornecedor:hover{
	transform: rotate(180deg);
}
.row-filtro .input-group:focus-within label,
.row-filtro .input-group input:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}
.row-filtro .input-group:focus-within label,
.row-filtro .input-group select:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}
.h-47vh{
	height: 47vh;
}
.campo-tabela{
	font-size: 13px;
}
.container-total{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
@media screen and (max-width: 425px) {
	.row-filtro {
		width: 100%;
	}
	.column-filtro {
		width: 100%;
	}
	.row-filtro .input-group {
		width: 50%;
	}
	#fornecedor, #empresa {
		width: 100%;
	}
	.container-tabela {
		height: 33vh;
		width: 97vw;
	}
	.container-tabela.fullTable {
		height: 67vh;
	}
	#adicionar{
		position: absolute;
		left: 325px;
		bottom: 50px;
	}
	#baixar {
		position: absolute;
		left: 15px;
		bottom: 50px;
	}
}
@media screen and (max-height: 600px) {
	.container-tabela {
		height: 52vh;
	}
}
</style>
