import Venda from "../service/Venda.js";
import Financeiro from "../service/Financeiro.js";
import Usuario from "../service/Usuario.js";
import Vendedor from "../service/Vendedor.js";
import Empresa from "../service/Empresa.js";
import Cliente from "../service/Cliente.js";
import MetodosPagamento from "../service/MetodosPagamento.js";
import Produto from "../service/Produto.js";
import Caixa from "../service/Caixa.js";
import Contas from "../service/Conta.js";
import Relatorio from "../service/Relatorio.js";
import GrupoProduto from "../service/GrupoProduto.js";
import Bandeira from "../service/Bandeira.js";
import Cfop from "../service/Cfop.js";
import Grade from "../service/Grade.js";
import entradas from "../service/Entradas.js";
import Promocao from "../service/Promocao.js";
import Consorcio from "../service/Consorcio.js";
import Cidade from "../service/Cidade.js";
import Paginacao from "../service/Paginacao.js";
import Perfil from "../service/Perfil.js";
import Cargo from "../service/Cargo.js";
import Transferencia from "../service/Transferencia.js";
import Log from "../service/Log.js";
import Pix from "../service/Pix.js";
import Conciliadora from "../service/Conciliadora.js";
import ContaPagar from "../service/ContaPagar.js";
import PrevisaoCompra from "../service/PrevisaoCompra.js";
import PlanoContas from "../service/PlanoContas.js";
import Agenda from "../service/Agenda.js";
import CentroCusto from "../service/CentroCusto.js";
import Dashboard from "../service/Dashboard.js";
import Ncm from "../service/Ncm.js";
import FluxoCaixa from "../service/FluxoCaixa.js";


const mutations = {
	relatarErro(state, payload){
		state.mensagemErro = {...payload};
	},
	alterarInformativo(state, payload){
		if (!payload) {
			return state.Informativo = null;
		}
		state.Informativo = {
			tipo: payload.tipo, //"sucesso" || "erro"
			mensagem: payload.mensagem,
			erro: payload.erro // Boolean
		};
	},
	alterarCheckboxPesquisa(state, payload) {
		state.checkboxPesquisa = payload;
	},
	incluirCodigoBarras(state, payload) {
		if (payload.length) {
			payload.forEach(item => {
				state.etiquetas.push(item);
			});
		}
	},
	limparEtiquetas(state) {
		state.etiquetas = [];
	},
	...Venda.mutations,
	...Financeiro.mutations,
	...Usuario.mutations,
	...Vendedor.mutations,
	...Empresa.mutations,
	...Cliente.mutations,
	...MetodosPagamento.mutations,
	...Produto.mutations,
	...Caixa.mutations,
	...Relatorio.mutations,
	...GrupoProduto.mutations,
	...Bandeira.mutations,
	...Contas.mutations,
	...Cfop.mutations,
	...Grade.mutations,
	...entradas.mutations,
	...Promocao.mutations,
	...Consorcio.mutations,
	...Cidade.mutations,
	...Paginacao.mutations,
	...Perfil.mutations,
	...Cargo.mutations,
	...Transferencia.mutations,
	...Log.mutations,
	...Pix.mutations,
	...Conciliadora.mutations,
	...ContaPagar.mutations,
	...PrevisaoCompra.mutations,
	...PlanoContas.mutations,
	...Agenda.mutations,
	...CentroCusto.mutations,
	...Dashboard.mutations,
	...Ncm.mutations,
	...FluxoCaixa.mutations,
};

export default mutations;
