
<template>
    <div class="ProdutoLote">
		<div class="produtolote-conteudo">
			<div class="row center w-100 header-produto">
				<h3 style="color: var(--primario);"><strong>{{produtoSelecionado.id}}</strong></h3>
				<h3 style="color: var(--primario)" id="separacaoTitulo">&nbsp; - &nbsp;</h3> 
				<h3 style="color: var(--primario);"><strong>{{produtoSelecionado.descricao}}</strong></h3>
				<h3 style="color: var(--primario)" id="separacaoTitulo" v-if="inserindoCompra">&nbsp; - &nbsp;</h3> 
				<h3 style="color: var(--primario)" v-if="inserindoCompra">&nbsp; Saldo {{Saldo}} &nbsp;</h3> 
			</div>
			<div class="containerFiltro">
				<div class="input-group" style="min-width: 100px;">
					<input
					type="text" v-model="Lote.lote" id="js-lote" placeholder=" "
					class="inputControle sm" tabindex="1" @keydown.up.prevent="" @keydown.down.prevent=""
					@keydown.enter="proximoCampo(inserindoCompra ? 'js-fabricacao' : 'js-compra')" :disabled = "editar"
					>
					<label for="js-lote">Lote</label>
				</div> 
				<div class="input-group" style="min-width: 130px;">
					<input
						type="text" v-model="Lote.cdCompra" id="js-compra" placeholder=" "
						class="inputControle sm" tabindex="1" @keydown.up.prevent="" @keydown.down.prevent=""
						@keydown.enter="proximoCampo('js-fabricacao')" v-mascaraInteger
						:disabled="inserindoCompra"
					>
					<label for="js-compra">Cód. Compra</label>
				</div> 
				<div class="input-group">
					<input
					type="date" v-model="Lote.fabricacao" id="js-fabricacao" placeholder=" " 
					class="inputControle sm" tabindex="1" @keydown.up.prevent="" @keydown.down.prevent=""
					@keydown.enter="proximoCampo('js-vencimento')" @blur="saiuData('fabricacao')"
					>
					<label for="js-fabricacao">Dt. Fabricação</label>
				</div> 
				<div class="input-group">
					<input
					type="date" v-model="Lote.dataVenc" id="js-vencimento" placeholder=" " 
					class="inputControle sm" tabindex="1" @keydown.up.prevent="" @keydown.down.prevent=""
					@keydown.enter="proximoCampo('js-entrada')" @blur="saiuData('dataVenc')"
					>
					<label for="js-vencimento">Dt. Vencimento</label>
				</div> 
				<div class="input-group" id="quantidade" style="min-width: 100px;">
					<input
						style="text-align: right;" type="text" v-model="Lote.quantidade" id="js-entrada" min="1" class="inputControle sm"
						tabindex="2" v-mascaraInteger placeholder=" " autocomplete="off" enterkeyhint="done" @keydown.enter="proximoCampo('js-saida')"
					>
					<label for="js-entrada" autocomplete="off">Entrada</label>
				</div>
				<div class="input-group" id="quantidade" style="min-width: 100px;">
					<input
						style="text-align: right;" type="text" v-model="Lote.saida" id="js-saida" min="1" class="inputControle sm"
						tabindex="2" v-mascaraInteger placeholder=" " autocomplete="off" enterkeyhint="done" @keydown.enter="proximoCampo('saldo')"
					>
					<label for="js-saida" autocomplete="off">Saida</label>
				</div>
				<div class="input-group" id="quantidade" style="min-width: 140px;">
					<select 
							id="saldo" 
							v-model="saldo"
							@keydown.enter="proximoCampo('botaoAdicionar')"
							@change="buscarLotes"
						>
							<option value="S">
								Com Saldo
							</option>
							<option value="N">
								Sem Saldo
							</option>
							<option value="T">
								Todos
							</option>
						</select>
						<label for="saldo">
							Saldo
						</label>
				</div>
				<button class="botao botao-adicionar" @click="salvarLote" style="width: 45px;margin-right: 10px;">
					<i class="fa fa-check" style="color: var(--salvar-texto)"></i>
				</button>
				<button class="botao botao-cancelar" @click="limparLote" style="width: 45px;margin-right: 10px;">
					<i class="fas fa-brush" style="rotate: 220deg; color: var(--cancelar-texto)"></i>
				</button>
			</div>
			<div class="tabela">
				<table class="w-100" v-if="lotes && lotes.length > 0?true:false">
					<thead>
						<tr>
							<td class="center">Lote</td>
							<td class="center">Cód. Compra</td>
							<td class="center">Dt. Fabricação</td>
							<td class="center">Dt. Vencimento</td>
							<td class="center">Entrada</td>
							<td class="center">Saida</td>
							<td class="center">Saldo</td>
							<td class="center">Ação</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(loteFor, index) in lotes" :key="index" class="itemtable" style="max-height: 15px;" :id="`item-${index}`"
							:tabindex="8 + loteFor.lote">
							<td class="center">{{loteFor.lote}}</td>
							<td class="center">{{loteFor.cdcompra}}</td>                                   
							<td class="center">{{loteFor.fabricacao.split("-").reverse().join("/")}}</td>
							<td class="center">{{loteFor.dataVenc.split("-").reverse().join("/")}}</td>
							<td class="center">{{loteFor.quantidade}}</td>
							<td class="center">{{loteFor.saida}}</td>
							<td class="center">{{loteFor.quantidade-loteFor.saida}}</td>
							<td class="center">
								<i class="fas fa-edit" id="botaoEditarProduto" style="color: var(--confirmar)" 
									@click="editarItem(loteFor, index)"></i>
								<i class="fas fa-trash-alt" id="botaoRemoverProduto" style="color: var(--deletar)" 
									@click="removerItem(loteFor, index)"></i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="botoes">             
			<button class="botao botao-cancelar" @click="cancelarEdicao"> 
				<label for="botaoCancelar" class="lg-view">Cancelar [Alt + C]</label>
				<label for="botaoCancelar" class="sm-view">Cancelar</label>
			</button>
			<button  class="botao botao-adicionar" id="botaoAdicionar" @click="handleConfirmar">
				<label for="botaoAdicionar" class="lg-view" v-if="editar">Salvar [Alt + S]</label>
				<label for="botaoAdicionar" class="lg-view" v-else>Salvar [Alt + S]</label>
				<label for="botaoAdicionar" class="sm-view" v-if="editar">Salvar</label>
				<label for="botaoAdicionar" class="sm-view" v-else>Salvar</label>
			</button>            
		</div>
    </div>
</template>

<script>

export default {
	name: "ProdutoLote", 
	computed: {
		produtoSelecionado(){
			return this.$store.state.produtoSelecionado;
		},
		lotes(){
			let lotes = [];
			if(!this.inserindoCompra){
				lotes = this.$store?.state?.Lotes || [];
			}
			else{
				lotes = this.$store?.state?.Lotes.filter(lote => lote.cdcompra === this.CdCompra) || [];
			}
			return lotes;
		},
		Saldo(){
			return Number(this.PropProduto.qtd.replace(",",".")) - this.lotes.reduce((total, obj) => total + obj.quantidade, 0);
		}
	},
	components: {
	},

	props: {
		inserindoCompra: Boolean,
		CdCompra: Number,
		PropProduto: Object,
	},

	data(){
		return {
			// atualiza: false,
			editar: false,
			saldo: "S",
			Lote: {
				lote:"",
				cdCompra:"",
				cdEmpresa: this.$store.state.Empresa.id,
				fabricacao: new Date().toLocaleDateString("en-CA"),
				dataVenc: new Date().toLocaleDateString("en-CA"),
				quantidade: 0,
				saida: 0,
				cdProduto: this.$store.state.produtoSelecionado.id,
				cdlote: null,
			},
			loteSelecionados: []
		};        
	},
	methods: {
		handleConfirmar() {
			try {
				if(!this.inserindoCompra) {
					this.$emit("cancelarEdicao");
				} else {
					if(!this.verificarSelecionarLote()) return;
					if(this.loteSelecionados.length > 0) {
						this.$store.state.produtoSelecionado.cdlote = this.loteSelecionados[0].cdlote;
					}
					this.$emit("salvarProduto", true);
					this.loteSelecionados = [];
				}	
			} catch (error) {
				console.error("Erro ao confimar lote:", error);
			}
		},

		verificarSelecionarLote() {
			try {
				this.loteSelecionados = this.lotes.filter(lote => lote.loteSelecionado);
				
				if(this.loteSelecionados.length > 1) {
					this.$store.commit("relatarErro", {
						mensagem: "Só é possível vincular um lote ao produto, por favor verifique"
					});
					return false;
				}
				return true;
			} catch (error) {
				console.log("Erro ao verificar produto para selecionar", error);
				return false;
			}
		},

		saiuData(target){
			if(this.Lote[target] == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.Lote[target] = new Date().toLocaleDateString("en-CA");
			}
		},
		async salvarLote(){
			var loteEditar = this.lotes?.find(lote => lote.cdlote === this.Lote.cdlote);
			if(this.validaCampos()){
				if(loteEditar){
					await this.$store.dispatch("alterarLote",this.Lote);
					await this.buscarLotes();
				}
				else{
					const { cdlote } = await this.$store.dispatch("salvarLote", this.Lote);
					await this.buscarLotes();
					if(this.inserindoCompra) {
						this.selecionarLoteCompra(cdlote);
					}
				}
				this.limparLote();
			}
		},
		selecionarLoteCompra(cdlote) {
			try {
				const loteSelecionados = [];
				this.lotes.forEach(lote => {
					if(lote.loteSelecionado) {
						loteSelecionados.push(lote);
					}
				});
				if(loteSelecionados.length === 1) return;
				const encontrado = this.lotes.find(lote => lote.cdlote === cdlote);
				if(encontrado) {
					encontrado.loteSelecionado = true;
				}
			} catch (error) {
				console.log("Erro ao selecionar um lote na compra após estar cadastrado", error);
			}
		},

		cancelarEdicao() {
			this.$emit("mudarAba", {target: {id: "EstoqueETributacao" }});
		},
		editarItem(item){
			this.Lote.lote = item.lote;
			this.Lote.cdCompra = item.cdcompra;
			this.Lote.cdEmpresa = item.cdempresa;
			this.Lote.cdProduto = item.cdproduto;
			this.Lote.dataVenc = item.dataVenc;
			this.Lote.fabricacao = item.fabricacao;
			this.Lote.quantidade = item.quantidade;
			this.Lote.saida = item.saida;
			this.Lote.cdlote = item.cdlote;
			this.editar = true;
		},
		proximoCampo(id) {
			setTimeout(() => {
				document.getElementById(id).focus();
				document.getElementById(id).select();                
			}, 50);
		},
		removerItem(item){
			this.$store.commit("relatarErro", {
				mensagem: "Tem certeza que deseja remover este lote?", 
				tipo: "polar",
				item: item,
			});			
		},
		async polarConfirmado(item){
			await this.$store.dispatch("removerLote",item);
			await this.buscarLotes();
		},
		polarCancelado(){
			return null;
		},
		async buscarLotes(){
			try {
				await this.$store.dispatch("buscarLotes", {
					cdProduto: this.Lote.cdProduto,
					empresa: this.Lote.cdEmpresa,
					saldo: this.saldo,
				});
				setTimeout(() => {
					if(this.inserindoCompra) {
						this.preencherDadosCompra();
					}
				}, 50);
			} catch (error) {
				console.error("Erro ao buscar Lotes", error);
			}
		},
		limparLote(){
			this.Lote.lote = "";
			this.Lote.cdEmpresa = this.$store.state.Empresa.id;
			this.Lote.cdProduto = this.$store.state.produtoSelecionado.id;
			this.Lote.dataVenc = new Date().toLocaleDateString("en-CA");
			this.Lote.fabricacao = new Date().toLocaleDateString("en-CA");
			this.Lote.quantidade = 0;
			this.Lote.saida = 0;
			this.editar = false;
			if(this.inserindoCompra) return;
			this.Lote.cdCompra = "";
		},
		validaCampos(){
			try{
				if(this.PropProduto?.tipo === "V" || this.PropProduto?.variacao) {
					throw new Error("Não é possível adicionar lote para produtos variados!");
				}
				if(this.Lote.lote === ""){
					throw new Error(
						"O lote não deve estar vazio!"
					);
				}
				if(this.Lote.dataVenc === ""){
					throw new Error(
						"A data de vencimento não deve estar vazia!"
					);
				}
				if(this.Lote.fabricacao === ""){
					throw new Error(
						"A data de fabricação não deve estar vazia!"
					);
				}
				if(this.Lote.quantidade < 1){
					throw new Error(
						"A quantidade de entrada deve ser maior que 0!"
					);
				}
				if(Number(this.Lote.quantidade) < Number(this.Lote.saida)){
					throw new Error(
						"A quantidade de entrada não pode ser menor que a quantidade de saida!"
					);
				}
				if(this.Lote.dataVenc < this.Lote.fabricacao){
					throw new Error(
						"A data de fabricação não pode ser maior que a data de vencimento!"
					);
				}
				if(this.inserindoCompra && this.Lote.quantidade > this.Saldo){
					throw new Error(
						"A quantidade de entrada não pode ser maior que o saldo na compra!"
					);
				}
				return true;
			}
			catch(error){
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
				return false;
			}
		},
		preencherDadosCompra() {
			this.Lote.cdCompra = this.CdCompra;
			const { cdlote } = this.PropProduto;
			if(this?.lotes?.length > 0) {
				const encontrado = this.lotes?.find(lote => lote.cdlote === cdlote);
				if(encontrado) {
					encontrado.loteSelecionado = true;
				}
			}
		}

	},
	mounted() {        
		setTimeout(() => {
			this.buscarLotes();
		}, 50);
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		this.$bus.$on("cancelarPolar", this.polarCancelado);
	},
	destroyed(){
		this.$bus.$off("confirmarPolar",this.polarConfirmado);
		this.$bus.$off("cancelarPolar", this.polarCancelado);
	},
};
</script>
<style scoped>
.input-group{
	margin-bottom: 10px;
}
.botao{
	margin-bottom: 10px;
}
.ProdutoLote {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow-y: hidden;
}
.produtolote-conteudo{
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.tabela{
    display: flex;
    overflow: auto;
    width: 100%;
	max-height: 62vh;
	padding: 0px 3px 0px 3px;
}
.tabela table {
    max-height: max-content;
	white-space: nowrap;
	height: 100%;
}
.itemtable{
    min-height: 10px !important;
    max-height: 10px !important;
}
.input-group label {
	height: 13px;
}
.containerFiltro {
	margin: 10px 0px 0px 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.sm-view {
	display: none;
}
.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}
.botoes .botao {
	width: 45%;
}
@media screen and (max-width: 1100px){
	.containerFiltro{
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
@media screen and (max-width: 1080px){
	.lg-view {
		display: none;
	}	
	.sm-view {
		display: inline-block;
	}	
	#separacaoTitulo {
		display: inline-block;
	}	
	#quantidade, #quantidadeTotal {
		width: 17vw;
	}
	#produtoSeSelecionado {
		margin-left: 10px;
	}
}

@media screen and (max-width: 440px) {
	.ProdutoLote {
		overflow: auto;
	}
	.header-produto{
		display: block;	
	}
	.containerFiltro {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		gap: 15px;
	}
	#separacaoTitulo {
		display: none;
	}
	.botao{
		margin-left: 10px;
	}
}
</style>