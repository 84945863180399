<template>
	<div :class="`containerTabela ${!showTable ? 'table-full' : ''}`" id="listaModal" v-if="listaConsulta.length > 0">
		<table>
			<thead>
				<tr>
					<th>TXID</th>
					<th>Data</th>
					<th>Pagador</th>
					<th>CPF/CNPJ Pagador</th>
					<th>Valor</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="pix in listaConsulta" :key="pix.txid" @click="$emit('selecionarCobranca',pix)">
					<td>{{pix.txid}}</td>
					<td>{{pix.data.split('-').reverse().join('/')}}</td>
					<td>{{pix.nomepagador}}</td>
					<td>{{pix.cpfcnpj}}</td>
					<td>{{(pix.total || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"})}}</td>
					<td >
						<span :class="pix.statusdevolucao == 'DEVOLVIDO' ? 'devolvida' : pix.statuscobranca == 'REMOVIDA_PELO_USUARIO_RECEBEDOR' ? 'cancelada' : pix.statuscobranca == 'ATIVA' ? 'ativa' : 'concluida'">
							{{pix.statusdevolucao == "DEVOLVIDO" ? "DEVOLVIDA" : pix.statuscobranca == "REMOVIDA_PELO_USUARIO_RECEBEDOR" ? "CANCELADA" : pix.statuscobranca }}
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div v-else :class="`col center containerExclamation ${!showTable ? 'table-full' : ''}`" id="listaModal">
		<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
		<h1>Empresa sem cobranças pendentes ou ativas.</h1>
	</div>
</template>

<script>
export default {
	name: "TabelaPix",
	props: {
		showTable: Boolean,
	},
	computed:{
		listaConsulta(){
			return this.$store.state.ConsultarPix?.listaConsulta;
		},
	}
};
</script>

<style scoped>
.containerTabela{
	height: 76vh;
	overflow: auto;
}

table thead tr th,
table tbody tr td{
	text-align: center;
	white-space: nowrap;
}
table{
	min-width: 90vw;
	max-height: 5vh !important;
	min-height: 5vh !important;
}
.containerExclamation{
	height: 90vh;
}

.cancelada{
	background-color: red;
	padding: 0px 6px;
	color: #FFF;
	border-radius: 12px;
}
.ativa{
	background-color: var(--primario);
	color: #FFF;
	padding: 0px 6px;
	border-radius: 12px;
}
.concluida{
	background-color: green;
	color: #FFF;
	padding: 0px 6px;
	border-radius: 12px;
}
.devolvida{
	background-color: rgb(243, 239, 3);
	padding: 0px 6px;
	border-radius: 12px;
}
#listaModal {
    width: 95%;
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;
	height: 65vh !important;
}
#listaModal.table-full{
	height: 80vh !important;
}
@media screen and (max-width: 768px){
	#listaModal.table-full{
		height: 68vh !important;
	}

	#listaModal{
		height: 43vh !important;
	}
}
@media screen and (max-width: 425px){
	#listaModal.table-full{
		height: 71vh !important;
	}

	#listaModal{
		height: 30vh !important;
	}
}
@media screen and (max-width: 420px){
	.containerTabela{
		height: 40vh;
	}
}
</style>