<template>
	<div id="fechamento-caixa-container">
		<i class="fa fa-file-invoice-dollar extrato-botao"
			v-if="$store.state.Usuario.usuarioPrivilegiado || $store.state.Usuario.supervisor"
			@click="mostarExtrato = true"></i>
		<i class="fas fa-money-check-alt conciliar-botao"
			v-if="$store.state.Usuario.usuarioPrivilegiado || $store.state.Usuario.supervisor"
			@click="consultarIntegracao = true">
		</i>
		<div class="grid-header">
			<div class="header-item header-info">
				<div class="row w-100">
					<label for="js-data-movimento">
						<h4>Data movimentação</h4>
					</label>
					<input type="date" id="js-data-movimento" :value="Caixa.data"
						:disabled="!$store.state.Usuario.supervisor" @keydown.enter="atualizarDataCaixa">
					<button class="botao botao-acao" style="width:30px;height:30px;padding:0" @click="atualizarDataCaixa">
						<i
							class="fas fa-search"
							for="cdproduto"
							style="color: var(--acao-borda)"
						></i>
					</button>
				</div>
				<div class="row w-100">
					<label for="observacao-abertura">
						<h4>Observação de abertura</h4>
					</label>
					<input type="text" id="observacao-abertura" :value="Caixa.observacaoAbertura" :readonly="!($store.state.Usuario.usuarioPrivilegiado)" @keydown.enter="editarObservacao()">
				</div>
				<div class="row w-100">
					<label for="observacao-fechamento">
						<h4>Observação de fechamento</h4>
					</label>
					<input type="text" id="observacao-fechamento" :value="Caixa.observacaoFinal" :readonly="!($store.state.Usuario.usuarioPrivilegiado)" @keydown.enter="editarObservacao()">
				</div>
			</div>
			<div class="header-item header-movimento">
				<h4>
					{{ Caixa.vendasCaixa.qtdevenda - Caixa.vendasCaixa.qtdecancelada}} &nbsp;-
					{{ Caixa.vendasCaixa.qtdevenda - Caixa.vendasCaixa.qtdecancelada == 1 ? "Venda" : "Vendas" }} no total de:
					{{ (Caixa.vendasCaixa.valorpago || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) }}
				</h4>
				<h4>
					{{ Caixa.vendasCaixa.qtdecondicional }} &nbsp;-
					{{ Caixa.vendasCaixa.qtdecondicional == 1 ? "Condicional" : "Condicionais" }} no total de:
					{{ (Caixa.vendasCaixa.valorcondicional || 0).toLocaleString("pt-BR", {
							style: "currency", currency:
								"BRL"
						})
					}}
				</h4>
				<h4>
					{{ Caixa.vendasCaixa.qtdetroca }} &nbsp;-
					{{ Caixa.vendasCaixa.qtdetroca == 1 ? "Troca" : "Trocas" }} no total de:
					{{ (Caixa.vendasCaixa.valortroca || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) }}
				</h4>
				<h4>
					{{ Caixa.vendasCaixa.qtdecancelada }} &nbsp;-
					{{ Caixa.vendasCaixa.qtdecancelada == 1 ? "Cancelado" : "Cancelados" }} no total de:
					{{ (Caixa.vendasCaixa.valorcancelado || 0).toLocaleString("pt-BR", {
							style: "currency", currency:
								"BRL"
						})
					}}
				</h4>
				<i class="fas fa-clipboard-list extrato-ajuste" @click="buscarLogAjustes"
					v-if="$store.state.Usuario.usuarioPrivilegiado && Caixa.cdFechamento"></i>
			</div>
		</div>
		<div class="grid-aba-controle">
			<div id="aba-vista" class="js-aba js-aba-selecionada" @click="mudarAba(0)">
				<h3>Vista</h3>
			</div>
			<div id="aba-prazo" class="js-aba" @click="mudarAba(1)">
				<h3>Prazo</h3>
			</div>
		</div>
		<div class="js-grid-aba grid-vista">
			<div class="center w-100">
				<h4>Movimento a Vista</h4>
			</div>
			<div class="caixa-item">
				<label for="js-saldo-anterior">
					<h4>Saldo anterior</h4>
				</label>
				<i class="fas fa-pause-circle" style="color: var(--confirmar); transform: rotate(90deg);"></i>
				<input type="text" id="js-saldo-anterior" class="text-right" readonly
					:value="(Caixa.saldoAnterior || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })">
			</div>
			<div class="caixa-item text-right"
				v-if="Caixa.movimentacaoVista.filter(metodo => metodo.valorManual > 0 || metodo.valor > 0).length > 0">
				<h4 class="w-30">Vendas</h4>
				<h4 class="w-20">Sistema</h4>
				<h4 class="w-20">Manual</h4>
				<h4 class="w-20">Diferença</h4>
			</div>
			<MovimentacaoCaixa
				v-for="movimento in Caixa.movimentacaoVista.filter(metodo => metodo.valorManual > 0 || metodo.valor > 0)"
				:key="`${movimento.cdtipo}V`"
				:movimento="movimento"
				@buscarMovimento="buscarMovimento"
				@alterarValorInformado="alterarValorInformado"
			/>
			<div class="caixa-item text-right"
				v-if="Caixa.movimentacaoRecebimento.filter(metodo => metodo.vistaprazo != 3 && (metodo.valorManual > 0 || metodo.valor > 0)).length > 0">
				<h4 class="w-30">Recebimentos</h4>
				<h4 class="w-20">Sistema</h4>
				<h4 class="w-20">Manual</h4>
				<h4 class="w-20">Diferença</h4>
			</div>
			<MovimentacaoCaixa
				v-for="movimento in Caixa.movimentacaoRecebimento.filter(metodo => metodo.vistaprazo != 3 && (metodo.valorManual > 0 || metodo.valor > 0))"
				:key="`${movimento.cdtipo}R`" :movimento="movimento"
				@buscarMovimento="buscarMovimento"
				@alterarValorInformado="alterarValorInformado"
			/>

			<div class="caixa-item">
				<label for="js-recarga">
					<h4>Recargas</h4>
				</label>
				<i class="fas fa-plus-circle" style="color: var(--confirmar);"
					@click="buscarMovimento('', 'M', 'credito')"></i>
				<input type="text" id="js-recarga" class="text-right" readonly
					:value="(Caixa.movimentoCaixa.recarga || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })">
			</div>
			<div class="caixa-item">
				<label for="js-pagamentos">
					<h4>Pagamentos</h4>
				</label>
				<i class="fas fa-minus-circle" style="color: var(--deletar);"
					@click="buscarMovimento('', 'M', 'debito')"></i>
				<input type="text" id="js-pagamentos" class="text-right" readonly
					:value="(Caixa.movimentoCaixa.pagamento || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })">
			</div>
			<div class="caixa-item">
				<label for="js-transferencias">
					<h4>Transferências</h4>
				</label>
				<i class="fas fa-minus-circle" style="color: var(--deletar);"></i>
				<input type="text" id="js-transferencias" class="text-right" readonly
					:value="(Caixa.movimentoCaixa.transferencias || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })">
			</div>
			<div class="caixa-item">
				<label for="js-saldo-final">
					<h4>Saldo final</h4>
				</label>
				<i class="fas fa-pause-circle" style="color: var(--confirmar); transform: rotate(90deg);"></i>
				<input type="text" id="js-saldo-final" :class="`text-right ${valorSaldoFinal}`" readonly
					:value="(Caixa.saldoFinal || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })">
			</div>
			<div class="caixa-item">
				<label for="js-saldo-deposito">
					<h4>Saldo depósito</h4>
				</label>
				<i class="fas fa-pause-circle" style="color: var(--bg-primario); transform: rotate(90deg);"></i>
				<input type="text" id="js-saldo-deposito" class="text-right" readonly
					:value="(Caixa.saldoDeposito || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })">
			</div>
		</div>
		<div class="js-grid-aba grid-prazo">
			<div class="center w-100">
				<h4>Movimento a Prazo</h4>
			</div>
			<!-- movimentacao Prazo -->
			<div class="caixa-item text-right"
				v-if="Caixa.movimentacaoPrazo.filter(metodo => metodo.valorManual > 0 || metodo.valor > 0).length > 0">
				<h4 class="w-30">Vendas</h4>
				<h4 class="w-20">Sistema</h4>
				<h4 class="w-20">Manual</h4>
				<h4 class="w-20">Diferença</h4>
			</div>
			<div >
				<MovimentacaoCaixa
					v-for="movimento in Caixa.movimentacaoPrazo.filter(metodo => metodo.valorManual > 0 || metodo.valor > 0)"
					:key="`${movimento.cdtipo}V`"
					:movimento="movimento"
					@buscarMovimento="buscarMovimento"
					@alterarValorInformado="alterarValorInformado"
				/>
			</div>

			<!-- Recebimentos -->
			<div class="caixa-item text-right"
				v-if="Caixa.movimentacaoRecebimento.filter(metodo => metodo.vistaprazo == 3 && (metodo.valorManual > 0 || metodo.valor > 0)).length > 0">
				<h4 class="w-30">Recebimentos</h4>
				<h4 class="w-20">Sistema</h4>
				<h4 class="w-20">Manual</h4>
				<h4 class="w-20">Diferença</h4>
			</div>
			<MovimentacaoCaixa
				v-for="movimento in Caixa.movimentacaoRecebimento.filter(metodo => metodo.vistaprazo == 3 && (metodo.valorManual > 0 || metodo.valor > 0))" :movimento="movimento"
				:key="`${movimento.cdtipo}R`"
				@buscarMovimento="buscarMovimento"
				@alterarValorInformado="alterarValorInformado"
			/>
		</div>
		<div class="fechamento-acao">
			<button class="botao botao-adicionar w-45" @click="imprimirFechamento(false)">
				Imprimir completo
			</button>
			<button class="botao botao-adicionar w-45" @click="imprimirFechamento(true)">
				Imprimir resumido
			</button>
		</div>

		<Modal v-if="mostrarAjustes" v-on:fecharModal="mostrarAjustes = false">
			<div class="modal-container w-100 my-10">
				<table class="w-100">
					<thead>
						<th>Forma de pagamento</th>
						<th>Movimento</th>
						<th>Usuário</th>
						<th>Descrição</th>
						<th>Valor anterior</th>
						<th>Valor atual</th>
						<th>Valor sistema</th>
					</thead>
					<tbody>
						<tr v-for="(ajuste, index) in logAjustes" :key="index">
							<td class="text-left text-nowrap">{{ ajuste.formapagamento }}</td>
							<td class="text-center">{{ ajuste.tipo }}</td>
							<td class="text-left">{{ ajuste.username }}</td>
							<td class="text-left">{{ ajuste.descricao }}</td>
							<td class="text-right">{{ Number(ajuste.valoranterior || 0).toLocaleString("pt-BR", {
									style:
										"decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
								})
							}}</td>
							<td class="text-right">{{ Number(ajuste.valormanual || 0).toLocaleString("pt-BR", {
									style:
										"decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
								})
							}}</td>
							<td class="text-right">{{ Number(ajuste.valorsistema || 0).toLocaleString("pt-BR", {
									style:
										"decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
								})
							}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Modal>

		<Modal v-if="alterarMovimento" v-on:fecharModal="fecharModal">
			<div class="col around m-auto w-80">
				<div class="col between w-100">
					<h2>Preencha os campos para alterar o valor informado</h2>
					<h4>Você está alterando o valor informado em {{ alterarMovimento.descricao }}</h4>
				</div>
				<div class="col center w-100 my-5">
					<label for="observacao">Justificativa</label>
					<input type="text" id="observacao" class="w-80 p-5" autocomplete="off"
						placeholder="Motivo de alteração" @input="mascara" v-model="observacao" maxlength="80">
				</div>
				<div class="col center w-100 my-5">
					<label for="valorInformado">Novo valor informado</label>
					<input type="text" id="valorInformado" class="w-80 p-5" autocomplete="off" placeholder="R$ 0,00"
						@input="mascara" v-model="valorInformado" @keydown.enter="confirmarAlteracao">
				</div>
				<div class="row center w-100 my-5">
					<button class="btn btn-cancelar m-auto w-40" @click="fecharModal">Cancelar</button>
					<button class="btn btn-confirmar m-auto w-40" @click="confirmarAlteracao">Confirmar</button>
				</div>
			</div>
		</Modal>

		<Modal v-if="mostarExtrato" v-on:fecharModal="mostarExtrato = false"
			:dimensoes="{ width: 'auto', height: 'auto' }">
			<ExtratoVista :Caixa="Caixa" />
		</Modal>
		<Modal v-if="abrirModal" v-on:fecharModal="abrirModal = false">
			<RelatorioCaixasModal v-bind:tipoMovimento="tipoMovimento" />
		</Modal>
		<Modal v-if="consultarIntegracao" @fecharModal="consultarIntegracao = false">
			<Conciliadora @fecharCaixa="consultarIntegracao = false"></Conciliadora>
		</Modal>
	</div>
</template>

<script>
import Modal from "../components/Modal.vue";
import ExtratoVista from "../components/ExtratoVista.vue";
import MovimentacaoCaixa from "../components/MovimentacaoCaixa.vue";
import RelatorioCaixasModal from "../components/RelatorioCaixasModal.vue";
import Conciliadora from "../components/Conciliadora.vue";

export default {
	name: "FecharCaixa",

	components: {
		Modal,
		ExtratoVista,
		RelatorioCaixasModal,
		MovimentacaoCaixa,
		Conciliadora
	},

	data() {
		return {
			timerFiltro: null,
			alterarMovimento: null,
			dataConsulta: "",
			observacao: "",
			valorInformado: "",
			abaSelecionada: "grid-vista",
			mostarExtrato: false,
			abrirModal: false,
			mostrarAjustes: false,
			consultarIntegracao: false
		};
	},

	computed: {
		Caixa() {
			return this.$store.state.Caixa;
		},
		logAjustes() {
			return this.$store.state.logAjustes;
		},
		valorSaldoFinal(){
			if (this.Caixa.saldoFinal < 0){
				return "negativo";
			}
			return "";
		},

	},

	methods: {
		async editarObservacao(){
			if(!this.$store.state.Usuario.usuarioPrivilegiado) return;
			const obsAbertura = document.getElementById("observacao-abertura").value;
			const obsFechamento = document.getElementById("observacao-fechamento").value;
			try {
				if (!this.Caixa.cdFechamento) {
					throw new Error("O caixa não foi aberto nem fechado nesta data!");
				}
				await this.$store.dispatch("atualizarObservacaoCaixa", { cdfechamento: this.Caixa.cdFechamento, descricaoFinal: obsFechamento, descricaoInicial: obsAbertura });
			} catch (error) {
				this.$store.commit("relatarErro", { mensagem: error.message });
			}
		},
		async buscarLogAjustes() {
			try {
				if (!this.Caixa.cdFechamento) {
					throw new Error("O caixa não foi aberto nem fechado nesta data!");
				}
				await this.$store.dispatch("buscarLogAjustes", { cdfechamento: this.Caixa.cdFechamento });
				if (!this.logAjustes.length) return;
				this.mostrarAjustes = true;
			} catch (error) {
				this.$store.commit("relatarErro", { mensagem: error.message });
			}
		},
		async buscarMovimento(cdtipo, tipo, tipoMovimento) {
			await this.$store.dispatch("buscarMovimentoCaixa", {
				cdidentificador: this.Caixa.caixa.cdcaixa,
				cdTipoPgto: cdtipo,
				condicao: tipo,
				dataI: this.dataConsulta,
				dataF: this.dataConsulta,
			});
			if (tipoMovimento) {
				this.tipoMovimento = tipoMovimento;
			}
			else {
				this.tipoMovimento = "";
			}
			this.abrirModal = true;
		},

		async atualizarInformacoesCaixa({ cdcaixa, data }) {
			this.$store.state.carregando = true;
			await this.$store.dispatch("buscarCaixaPorIdentificador", { cdcaixa, data });
			this.$store.state.carregando = false;
		},

		atualizarDataCaixa() {
			clearTimeout(this.timerFiltro);
			const data = document.getElementById("js-data-movimento").value;
			this.dataConsulta = data;
			this.timerFiltro = setTimeout(() => {
				this.atualizarInformacoesCaixa({ cdcaixa: this.Caixa.caixa.cdcaixa, data: data });
			}, 500);
		},

		alterarValorInformado(movimentoInformado) {
			if (!this.$store.state.Usuario.usuarioPrivilegiado && !this.$store.state.Usuario.supervisor) {
				return this.$store.commit("relatarErro", {
					mensagem: "Usuário sem permissão para alteração do caixa!"
				});
			}
			this.alterarMovimento = movimentoInformado;
			setTimeout(() => {
				document.getElementById("observacao").focus();
			}, 10);
		},

		mascara(e) {
			const id = e.target.id;
			if (id == "observacao") {
				return this[id] = this[id].toUpperCase();
			}
			var er = /[^0-9.,]/;
			er.lastIndex = 0;
			if (er.test(e.target.value)) {
				return this[id] = this[id].split(e.data)[0];
			}
		},

		alterarValor({ cdFormPgto, observacao, valor, tipo }) {
			this.$store.dispatch("atualizarValorInformado", {
				cdfechamento: this.Caixa.cdFechamento,
				cdcaixa: this.Caixa.caixa.cdcaixa,
				cdFormPgto,
				observacao,
				valor,
				dataConsulta: this.$route.query.data || new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
				tipo: tipo || this.alterarMovimento.tipo
			});
		},

		confirmarAlteracao() {
			if (!this.observacao) {
				return this.$store.commit("relatarErro", { mensagem: "É necessário informar o motivo!" });
			}
			if (this.valorInformado == "" || this.valorInformado < 0) {
				return this.$store.commit("relatarErro", { mensagem: "É necessário informar o novo valor!" });
			}
			this.alterarValor({
				cdFormPgto: this.alterarMovimento.cdtipo,
				observacao: this.observacao,
				valor: Number(Number(this.valorInformado.replace(",", ".")).toFixed(2)),
				tipo: this.alterarMovimento.tipo
			});
			this.fecharModal();
			this.atualizarDataCaixa();
		},

		fecharModal() {
			this.alterarMovimento = null;
			this.observacao = "";
			this.valorInformado = "";
		},

		mudarAba(index) {
			const aba = document.getElementsByClassName("js-aba")[index];
			const abaSelecionada = document.getElementsByClassName("js-aba-selecionada")[0];
			if (aba != abaSelecionada) {
				this.abaSelecionada = aba.getAttribute("id").split("aba-")[1];
				aba.classList.add(["js-aba-selecionada"]);
				abaSelecionada.classList.remove(["js-aba-selecionada"]);

				document.querySelectorAll("div.js-grid-aba").forEach(el => {
					if (!el.getAttribute("class").includes(this.abaSelecionada)) {
						el.classList.add(["esconder"]);
					} else {
						el.classList.remove(["esconder"]);
					}
				});
			}
		},

		imprimirFechamento(resumido) {
			this.$store.dispatch("relatorioFechamentoCaixa", { Caixa: this.Caixa, resumido });
		},

		consultarMovimentos(movimento) {
			if (movimento.tipo == "V" && Number((Number(movimento.valorManual) - Number(movimento.valor)).toFixed(2)) != 0.00) {
				this.$router.push({ name: "Vendas", params: { conferir: movimento.cdtipo, data: this.dataConsulta } });
			}
		},

		async iniciarComponent() {
			await this.$store.dispatch("buscarFormasPagamento");
			if (this.$route.query.cdcaixa) {
				this.dataConsulta = this.$route.query.data || new Date().toLocaleDateString("en-GB").split("/").reverse().join("-");
				this.atualizarInformacoesCaixa({
					cdcaixa: this.$route.query.cdcaixa || this.$store.state.Caixa.caixa.cdcaixa,
					data: this.dataConsulta
				});
			} else if (this.$route.query.data) {
				this.dataConsulta = this.$route.query.data || new Date().toLocaleDateString("en-GB").split("/").reverse().join("-");
				this.atualizarInformacoesCaixa({
					cdcaixa: this.$route.query.cdcaixa || this.$store.state.Caixa.caixa.cdcaixa,
					data: this.dataConsulta
				});
			} else {	
				this.dataConsulta = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-");
				this.atualizarInformacoesCaixa({
					cdcaixa: this.$store.state.Caixa.caixa.cdcaixa,
					data: this.dataConsulta
				});
			}
		}
	},
	beforeMount() {
		this.iniciarComponent();
	},
	mounted() {
		document.querySelectorAll("div.js-grid-aba").forEach(el => {
			if (!el.getAttribute("class").includes(this.abaSelecionada)) {
				el.classList.add(["esconder"]);
			} else {
				el.classList.remove(["esconder"]);
			}
		});
	}
};
</script>

<style scoped>
#fechamento-caixa-container {
	display: grid;
	width: 100%;
	height: 100%;
	background-color: var(--bg-secundario);
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 117px auto 40px;
	grid-template-areas:
		"header header"
		"vista prazo"
		"footer footer";
}

.grid-vista {
	grid-area: vista;
	overflow: auto;
	position: relative;
}

.grid-prazo {
	grid-area: prazo;
	overflow: auto;
	position: relative;
}

.grid-header {
	grid-area: header;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-template-areas:
		"info movimento";
}

.header-item {
	padding: 5px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	background-color: var(--bg-app);
	margin: 5px;
	box-shadow: var(--shadow-secundario);
	border-radius: 10px;
}

.header-item input {
	text-align: left;
	width: 50%;
	margin: 0px 2px;
}

.header-item label {
	width: 50%;
	text-align: right;
	margin: 0px 2px;
}

.header-info {
	grid-area: info;
	flex-direction: column;
}

.header-movimento {
	grid-area: movimento;
	flex-direction: column;
	align-items: center;
}

.header-movimento h4 {
	width: 100%;
	padding-left: 25%;
}

.caixa-header {
	width: 100%;
	margin: 0px 2px;
	word-wrap: normal;
}

.js-grid-aba {
	background-color: var(--bg-app);
	margin: 5px;
	box-shadow: var(--shadow-secundario);
	border-radius: 10px;
}

.caixa-item {
	display: flex;
	flex-direction: row;
	padding: 2px;
	align-items: center;
	position: relative;
}

.caixa-item i {
	position: absolute;
	left: 31%;
	font-size: 14pt;
}

.caixa-financeiro-item {
	display: flex;
	padding: 2px;
	position: relative;
}

.caixa-financeiro-item i {
	position: absolute;
	left: 31%;
	top: 5px;
	font-size: 14pt;
}

.caixa-financeiro-item label,
.caixa-item label {
	width: 30%;
	margin: 0px 2px;
	text-align: right;
	word-wrap: normal;
	font-size: 10pt;
}

h4 {
	font-size: 12pt;
}

.caixa-item input,
.caixa-financeiro-item input {
	width: 20%;
	margin: 0px 2px;
	text-align: right;
}

#js-saldo-anterior,
#js-pagamentos,
#js-recarga,
#js-transferencias,
#js-saldo-final,
#js-saldo-deposito {
	width: 61%;
}

.fechamento-acao {
	grid-area: footer;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.fechamento-acao button {
	margin: 5px auto;
	height: 40px;
	margin-bottom: 7px;
}

.grid-aba-controle {
	display: none;
	grid-area: aba-controle;
}

.extrato-botao {
	position: absolute;
	top: 12vh;
	left: 2vh;
	font-size: 22pt;
	color: #292929;
}

.conciliar-botao{
	position: absolute;
	top: 12vh;
	left: 10vh;
	font-size: 22pt;
	color: #292929;
}

.extrato-ajuste {
	position: absolute;
	top: 12vh;
	right: 2vh;
	font-size: 22pt;
	color: #292929;
}

.negativo{
	color: red;
	border: 1px solid red;
	cursor: pointer;
}

@media screen and (max-width: 1075px) and (min-width: 941px) {

	.grid-vista,
	.grid-prazo {
		background-color: #fff;
	}

	.caixa-financeiro-item i,
	.caixa-item i {
		font-size: 12pt !important;
	}
}

@media screen and (max-width: 940px) {
	#fechamento-caixa-container {
		min-width: 325px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto auto auto 1fr auto;
		grid-template-areas:
			"header"
			"aba-controle"
			"aba"
			"footer";
	}

	.grid-vista,
	.grid-prazo {
		margin: 0px 5px;
		grid-area: aba;
	}

	.js-grid-aba {
		box-shadow: none;
	}

	.grid-header {
		grid-area: header;
		display: grid;
		grid-template-columns: 55% 45%;
		grid-template-rows: auto;
		grid-template-areas:
			"info movimento";
	}

	.grid-header * {
		font-size: var(--txt-pequeno) !important;
	}

	.header-movimento h4 {
		padding-left: 0px;
	}

	.grid-aba-controle {
		margin: 0px 12px;
		display: grid;
		grid-template-columns: 150px 150px;
		grid-template-rows: auto;
		grid-template-areas:
			"aba-vista aba-prazo";
		align-content: flex-end;
	}

	#aba-vista {
		grid-area: aba-vista;
	}

	#aba-prazo {
		grid-area: aba-prazo;
	}

	.js-aba {
		background-color: none;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
	}

	.js-aba-selecionada {
		background-color: var(--bg-app);
		cursor: default;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		box-shadow: 1px 2px 3px #292929;
	}

	.esconder {
		display: none;
	}

	.extrato-botao {
		font-size: 18pt;
	}
}

@media screen and (max-width: 678px) {
	.grid-header {
		grid-area: header;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
		grid-template-areas:
			"info"
			"movimento";
	}

	.header-item {
		align-items: flex-start;
	}

	.header-item label {
		width: 180px;
		text-align: right;
		margin: 0px 2px;
	}
}

@media screen and (max-width: 500px) {
	* {
		font-size: var(--txt-pequeno) !important;
	}

	i {
		font-size: 12pt !important;
	}

	.caixa-financeiro-item i,
	.caixa-item i {
		display: none;
	}
}
</style>
