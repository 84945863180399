<template>
    <div class="col w-30 center">
        <div class="titulo row between w-100 row-column-responsividade">
            <div class="row around w-100" v-if="!pesquisarItem">
                <h3 class="m-0 p-0">{{titulo}}</h3>
                <span class="m-0 p-0">{{ListaVendas.totalItens}}</span>
				<div class="input-group select-mobile">
					<select id="size" v-model="size" @change="atualizarListaVendas">
						<option :value="25">25 itens</option>
						<option :value="50">50 itens</option>
						<option :value="75">75 itens</option>
						<option :value="100">100 itens</option>
					</select>
				</div>
            </div>
            <div class="paginacao w-100" v-if="ListaVendas.totalPaginas > 0">
                <span v-if="ListaVendas.paginaAtual > 1" @click="mudarPagina(0)">{{1}}</span>
                <span @click="mudarPagina(ListaVendas.paginaAtual-1)" v-if="ListaVendas.paginaAtual > 0" >
                    {{ListaVendas.paginaAtual}}
                </span>
                <span class="ativa">
                    {{ListaVendas.paginaAtual+1}}
                </span>
                <span @click="mudarPagina(ListaVendas.paginaAtual+1)" v-if="ListaVendas.paginaAtual +1 < ListaVendas.totalPaginas" >
                    {{ListaVendas.paginaAtual +2}}
                </span>
                <span v-if="ListaVendas.paginaAtual+2 < ListaVendas.totalPaginas" @click="mudarPagina(ListaVendas.totalPaginas-1)">{{ListaVendas.totalPaginas-1}}</span>
            </div>

            <i class="fas fa-sync-alt" @click="atualizarListaVendas"></i>
            <!-- <i class="fas fa-search" @click="pesquisarItem = !pesquisarItem"></i> -->
            <i class="fas fa-cash-register" @click="verFormasPagameno = !verFormasPagameno" v-if="verFormasPagameno && formasPagamento"></i>
            <i class="fas fa-money-check-alt" @click="verFormasPagameno = !verFormasPagameno" v-else-if="!verFormasPagameno && formasPagamento"></i>
            <i class="fas fa-cash-register" @click="verFormasPagameno = !verFormasPagameno" v-if="verFormasPagameno && contasRecebidas"></i>
            <i class="fas fa-money-check-alt" @click="verFormasPagameno = !verFormasPagameno" v-else-if="!verFormasPagameno && contasRecebidas"></i>
        </div>
        <div class="scrollable row w-100" v-if="!verFormasPagameno && ListaVendas.content.length > 0">
            <div class="itemLista col p-5 w-30" v-for="item in ListaVendas.content" :key="item.cdVenda" @click="selecionarItem(item)" :style="(item.valorCondicional > 0) ? 'background-color: rgba(250, 250, 0, .7);' : ((item.financeiro.length <= 0 && (item.valorBruto - (item.descontoMoeda || 0) - (item.valorTroca || 0) - (item.valorConsorcio > 0 ? item.valorConsorcio + item.valorPago : 0 ) > 0)) ? 'background-color: rgba(255, 0, 0, .8);' : '')">
                <h3 :style="`text-align: center; ${(item.financeiro.length <= 0 && (item.valorBruto - (item.descontoMoeda || 0) - (item.valorTroca || 0) - (item.valorConsorcio > 0 ? item.valorConsorcio + item.valorPago : 0 ) > 0)) && item.valorCondicional <=0 ? 'color: #fff; border-bottom: 1px solid #fff;' : 'color: #3B4599; border-bottom: 1px solid #292929;'} `">{{item.cdVenda}}</h3>
                <h4 :style="`text-align: left; ${(item.financeiro.length <= 0 && (item.valorBruto - (item.descontoMoeda || 0) - (item.valorTroca || 0) - (item.valorConsorcio > 0 ? item.valorConsorcio + item.valorPago : 0 ) > 0)) && item.valorCondicional <=0 ? 'color: #fff' : ''};`"> {{(item.cliente.id == $store.state.ClientePadrao.id) && item.nomeCliente ?  (item.foneCliente ? `${item.nomeCliente} ${item.foneCliente}` : `${item.nomeCliente}`) : item.cliente.nome}}</h4>
                <div class="row between">
                    <h5 :style="`${(item.financeiro.length <= 0 && (item.valorBruto - (item.descontoMoeda || 0) - (item.valorTroca || 0) - (item.valorConsorcio > 0 ? item.valorConsorcio + item.valorPago : 0 ) > 0)) && item.valorCondicional <=0 ? 'color: #fff' : ''};`">
                        {{item.itens.map(item => item.quantidade).length > 0 ? 
                        item.itens.map(item => item.quantidade).reduce((t, v) => t+= v)
                        .toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}) : item.itens.length}} itens
                    </h5>
                    <h3 :style="`color: ${(item.financeiro.length <= 0 && (item.valorBruto - (item.descontoMoeda || 0) - (item.valorTroca || 0) - (item.valorConsorcio > 0 ? item.valorConsorcio + item.valorPago : 0 ) > 0)) && item.valorCondicional <= 0 ? '#fff' : 'green'}; text-align: right;`">
						{{(item.valorBruto - ((item.descontoMoeda || 0) + (item.valorTroca || 0))).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}
					</h3>
                </div>
                <h4 :style="`text-align: left; color: ${(item.financeiro.length <= 0 && (item.valorBruto - (item.descontoMoeda || 0) - (item.valorTroca || 0) - (item.valorConsorcio > 0 ? item.valorConsorcio + item.valorPago : 0 ) > 0)) && item.valorCondicional <=0 ? '#fff' : ''};`">
                    Data: {{new Date(`${item.dataEmissao}`).toLocaleString("pt-BR")}}
                </h4>
                <h4 :style="`text-align: left; ${(item.financeiro.length <= 0 && (item.valorBruto - (item.descontoMoeda || 0) - (item.valorTroca || 0) - (item.valorConsorcio > 0 ? item.valorConsorcio + item.valorPago : 0 ) > 0)) && item.valorCondicional <=0 ? 'color: #fff' : ''};`" v-if="$store.state.Usuario.supervisor">
                    Caixa: {{item.cdIdentificador > 0 ? $store.state.Caixas.content.find(caixa => caixa.cdcaixa == item.cdIdentificador).identificador : "Caixa geral"}}
                </h4>
                <div class="row between">
                    <h4 class="row" v-for="(parcela) in new Set(item.financeiro.map(fin => fin.cdTipoPgto))" :key="`${parcela}`" :style="pgtoSelecionado && pgtoSelecionado.cdtipo == item.financeiro.find(parc => parc.cdTipoPgto == parcela).cdTipoPgto ? 'text-decoration: underline' : ''">
                        <span class="text-left">
                            {{formaPagamento.find(pag => pag.cdtipo == parcela).descricao}}: &nbsp;
                        </span>
                        <span class="text-left">
                            {{
                                item.financeiro.filter(parc => parc.cdTipoPgto == parcela).length > 0 ?
                                item.financeiro.map(parc => parc.cdTipoPgto == parcela ? 
                                (parc.credito > 0 ? parc.credito : parc.contarc > 0 ? parc.contarc : 0) : 0)
                                .reduce((t, v) => t+=v)
                                .toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) :
                                "R$ 0,00"
                            }}
                        </span>
                    </h4>
                </div>
            </div>
        </div>
        <div class="scrollable col w-100" v-else-if="verFormasPagameno && ListaVendas.content.length > 0">
            <div class="row w-100 between p-5 shadow" v-for="metodo in formasPagamento" :key="metodo.cdtipo">
                <h4 class="w-40">{{metodo.descricao}}</h4>
                <div class="row center w-60">
                    <div class="col center">
                        <label :for="metodo.cdtipo + 'M'">Manual</label>
                        <input type="number" :id="metodo.cdtipo + 'M'" class="w-80" placeholder="R$ 00,00" style="text-align: right"
                        @input="recalcularTotal">
                    </div>
                    <div class="col center">
                        <label :for="metodo.cdtipo + 'S'">Sistema</label>
                        <input type="number" :id="metodo.cdtipo + 'S'" class="w-80" placeholder="R$ 00,00" style="text-align: right"
                        disabled>
                    </div>
                </div>
            </div>
        </div>
        <div class="scrollable col w-100" v-else>
            <div class="col w-100 center p-5 shadow">
                <h2 class="center">Nenhuma venda encontrada.</h2>
            </div>
        </div>
        <div class="row w-100 around p-5" v-if="!verFormasPagameno && ListaVendas.content.length > 0 && anexoVendas">
            <div class="input-group grande" v-if="anexoVendas && anexoVendas.valorpago > 0">
                <input type="text" style="font-size: 12pt;margin-top:2px" readonly class="w-100 text-right"
                :value="(anexoVendas.valorbruto || 0).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})">
                <label style="font-size: 11pt">Total bruto: </label>
            </div>
            <div class="input-group grande" v-if="anexoVendas && anexoVendas.valorpago > 0">
                <input type="text" style="font-size: 12pt;margin-top:2px" readonly class="w-100 text-right"
                :value="(anexoVendas.valordesconto || 0).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})">
                <label style="font-size: 11pt">Total descontos: </label>
            </div>
            <div class="input-group grande" v-if="anexoVendas && anexoVendas.valorpago > 0">
                <input type="text" style="font-size: 12pt;margin-top:2px" readonly class="w-100 text-right"
                :value="(anexoVendas.valortroca || 0).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})">
                <label style="font-size: 11pt">Total troca: </label>
            </div>
            <div class="input-group grande" v-if="anexoVendas && anexoVendas.valorpago > 0">
                <input type="text" style="font-size: 12pt;margin-top:2px" readonly class="w-100 text-right"
                :value="(anexoVendas.valorpago || 0).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})">
                <label style="font-size: 11pt">Total pago: </label>
            </div>
            <div class="input-group grande" v-if="anexoVendas.valorcondicional > 0">
                <input type="text" style="font-size: 12pt" readonly class="w-100 text-right"
                :value="(anexoVendas.valorcondicional || 0).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})">
                <label style="font-size: 11pt">Total condicional: </label>
            </div>
            <!-- <div class="input-group grande" v-if="pgtoSelecionado">
                <input type="text" style="font-size: 12pt" readonly class="w-100 text-right"
                :value="(anexoFinanceiro[anexoFinanceiro && anexoFinanceiro.vista > 0 ? 'vista' : 'prazo'] || 0
                ).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})">
                <label style="font-size: 11pt">
                    {{pgtoSelecionado.descricao.slice(0,1) + pgtoSelecionado.descricao.slice(1).toLowerCase()}}: 
                </label>
            </div> -->
            <div class="input-group pequeno">
                <input type="text" style="font-size: 12pt" readonly class="w-100 text-right"
                :value="(anexoVendas[anexoVendas && anexoVendas.valorpago > 0 ? 'valorpago' : 'valorcondicional'] || 0
                ).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})">
                <label style="font-size: 11pt">Total {{anexoVendas && anexoVendas.valorpago > 0 ? 'pago' : "condicional"}}: </label>
            </div>
            <!-- <div class="input-group pequeno" v-if="pgtoSelecionado">
                <input type="text" style="font-size: 12pt" readonly class="w-100 text-right"
                :value="(anexoFinanceiro[anexoFinanceiro && anexoFinanceiro.vista > 0 ? 'vista' : 'prazo'] || 0
                ).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})">
                <label style="font-size: 11pt">{{pgtoSelecionado.descricao}}: </label>
            </div> -->
        </div>
        <div class="row between w-100 row-pequeno" v-if="verFormasPagameno && ListaVendas.content.length > 0">
            <div class="input-group w-100">
                <input type="text" :id="'totalManual'+titulo" style="text-align: right;" readonly class="w-100"
                :value="totalManual.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})">
                <label :for="'totalManual'+titulo">Total manual:</label>
            </div>
			<div class="row">
				<div class="input-group w-100">
					<input type="text" :id="'totalSistema'+titulo" style="text-align: right;" readonly class="w-100"
					:value="totalSistema.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})">
					<label :for="'totalSistema'+titulo">Total sistema:</label>
				</div>
				<div class="input-group w-100">
					<input type="text" :id="'diferenca'+titulo" style="text-align: right;" readonly class="w-100">
					<label :for="'diferenca'+titulo">Diferença:</label>
				</div>
			</div>
        </div>
    </div>
</template>

<script>
export default {
	name: "ListaVendas",

	data() {
		return {
			pesquisarItem: false,
			verFormasPagameno: false,
			totalManual: 0,
			size: 50
		};
	},

	props: {
		titulo: String,
		itens: Array,
		formasPagamento: Array,
		contasRecebidas: Array,
		Paginacao: Object,
		totalItens: Number,
		Size: Number,
		pgtoSelecionado: Object,
		ListaVendas: Object
	},

	computed: {
		totalSistema() {
			let preco = 0;
			this.ListaVendas.content.forEach(item => {
				preco += Number(Number(item.valorBruto).toFixed(2));
				preco -= Number(Number(item.valorTroca).toFixed(2));
				preco -= Number(Number(item.descontoMoeda).toFixed(2));
			});
			return Number(preco.toFixed(2));
		},
		anexoVendas() {
			return this.$store.state.ListaVendas.anexo;
		},
		anexoFinanceiro() {
			return this.$store.state.ListaVendas.anexoFinanceiro;
		},
		formaPagamento() {
			return this.$store.state.formasPagamento;
		}
	},

	methods: {
		recalcularTotal() {
			let preco = 0;
			this.formasPagamento.forEach(metodo => {
				preco += Number(document.getElementById(`${metodo.cdtipo}M`).value);
			});
			return this.totalManual = preco;
		},

		selecionarItem(item) {
			this.$emit("selecionarItem", {...item});
		},
		atualizarListaVendas() {
			this.$emit("atualizarLista", {size: this.size});
		},
		mudarPagina(page) {
			this.$emit("atualizarLista", {size: this.Size, page});
		}
	}
};
</script>

<style scoped>

.col.w-30.center {
    margin: 0px auto;
    border-radius: 10px;
    max-height: 100%;
    background-color: var(--bg-app);
    box-shadow: var(--shadow-primario);
}

.titulo {
    padding: 5px 10px;
}

.pequeno {
    display: none;
}

.scrollable {
    display: flex;
    overflow-y: auto;
    align-items: flex-start;
    justify-content: space-around;
    height: 100%;
    flex-wrap: wrap;
}

.itemLista {
    box-shadow: var(--shadow-secundario);
    background-color: var(--bg-secundario);
    margin: 5px;
    justify-content: space-around;
    cursor: pointer;
    border-radius: 10px;
    font-size: var(--txt-pequeno);
    min-height: 80px;
    width: 32%;
}

i {
    font-size: var(--txt-grande);
    cursor: pointer;
    color: #000;
}


@media screen and (max-width: 1325px) and (min-width: 769px) {
    .row.w-100.around.p-5 .row.center {
        flex-direction: column;
    }
}

@media screen and (max-width: 1200px) {
    .itemLista {
        width: 45%;
    }
}

@media screen and (max-width: 768px) {
    .pequeno {
        display: flex;
        width: 100%;
    }
    .grande {
        display: none;
    }
}

@media screen and (max-width: 620px) {
    .itemLista {
        width: 90%;
    }
}

@media screen and (max-width: 425px) {
	.row-column-responsividade{
		flex-direction: column;
	}
    .itemLista {
        width: 90%;
    }
	.select-mobile{
		min-width: 120px;
	}
	.row-pequeno{
		flex-direction: column;
	}
	.row-pequeno .input-group{
		width:90%;
		margin: 5px;
	}
}
</style>