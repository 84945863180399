const storage = [];

export const localStorageManage = (name, value) => {
	
	window.localStorage.setItem(name, JSON.stringify({...value}));
	const existe = storage.find(key => key == name);
	if(existe) return;
	storage.push(name);
};

export const localStorageRemoveItem = (name, value) => {
	const itensVenda = JSON.parse(window.localStorage.getItem(name));
	let newItens = [];
	if(window.localStorage.itensVenda){
		Object.keys(itensVenda).forEach(key => {
			newItens.push(itensVenda[key]);
		});
		newItens.splice(value, 1);
		window.localStorage.setItem(name, JSON.stringify(newItens));
	}
};

export const localStorageReset = () => {
	window.localStorage.removeItem("itensVenda");
	window.localStorage.removeItem("venda");
};

export const localStorageGetItem = (name) => {
	const item = JSON.parse(window.localStorage.getItem(name));

	return item;
};