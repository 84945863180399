<template>
    <div class="modal">
        <div class="modalInterno col between p-10">
            <div id="texto" class="d-flex m-10 h-50 center">
                <p>{{mensagemPergunta || "Você tem certeza?"}}</p>
            </div>
            <div class="center w-90">
                <button class="btn btn-cancelar m-5" @click="cancelar" >{{mensagemCancelar || "NÃO"}}</button>
                <button class="btn btn-confirmar m-5" @click="confirmar">{{mensagemConfirmar || "SIM"}}</button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
	name: "Confirmar",

	props: ["mensagemPergunta", "mensagemCancelar", "mensagemConfirmar", "acaoConfirmar", "acaoCancelar"],
    
	methods: {
		cancelar() {
			return this.$emit("cancelado", this.acaoCancelar);
		},
        
		confirmar(){
			return this.$emit("confirmado", this.acaoConfirmar);
		}
	},
};
</script>

<style scoped>
.modal {
    min-height: 100vh;
    height: auto;
    z-index: 8;
}

.modalInterno {
    background-color: var(--bg-app);
    box-shadow: var(--shadow-primario);
    min-width: 40%;
    width: auto;
    border-radius: 25px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

#texto {
    word-wrap: break-word;
}

.btn {
    width: 90%;
    padding: 3px;
}


@media screen and (max-width: 1250px){
    .modalInterno {
        min-width: 50%;
    }
}

@media screen and (max-width: 900px){
    .modalInterno {
        min-width: 60%;
    }
}

@media screen and (max-width: 768px){
    .modalInterno {
        min-width: 80%;
    }
}

@media screen and (max-width: 400px){
    .modalInterno {
        min-width: 90%;
    }
}
  
</style>