<template>
    <div id="listaModal" :class="`col w-95 ${!showTable? 'table-full' : ''}`" v-if="FormasPagamento.length >= 1">
        <table class="w-100">
            <thead>
                <tr>
                    <td style="color: blue; text-align: center;">Cód.</td>
                    <td style="color: blue; text-align: center;">Descrição</td>
                    <td class="text-center text-nowrap">Venda</td>
                    <td class="text-center text-nowrap">Recebimento</td>
                    <td class="text-center text-nowrap">Compra</td>
                    <td class="text-center text-nowrap">Depósito</td>
                    <td class="text-center text-nowrap">Calcular juros</td>
                    <td class="text-center text-nowrap">Consumidor final</td>
                    <td class="text-center text-nowrap">Pendências</td>
                    <td class="text-center text-nowrap">Cartão</td>
                    <td class="text-center text-nowrap">Consorcio</td>
                    <td class="text-center text-nowrap">Cheque</td>
                    <td class="text-center text-nowrap">Ativo</td>
                </tr>
            </thead>
            <tbody>
                <tr class="itemLista" v-for="(fpgto, index) in FormasPagamento" :key="index" @click="selecionarFormaPagamento(fpgto)">
                    <td style="color: blue; text-align: center;">
                        <button :id="index" class="hand-button" v-if="index == indexFormasPagamentosSelecionada">
                            <i class="fas fa-hand-point-right" style="color: var(--primario)" >
                            </i>
                        </button>
                        {{fpgto.cdtipo}}
                    </td>
                    <td style="text-align: center;">
                        {{fpgto.descricao}}
                    </td>
                    <td style="text-align: center;">
                        <span v-if="fpgto.mostravenda == 'S'">SIM</span>
                        <span v-else>NÃO</span>
                    </td>
                    <td style="text-align: center;">
                        <span v-if="fpgto.mostrarecebimento == 'S'">SIM</span>
                        <span v-else>NÃO</span>
                    </td>
                    <td style="text-align: center;">
                        <span v-if="fpgto.mostracompra == 'S'">SIM</span>
                        <span v-else>NÃO</span>
                    </td>
                    <td style="text-align: center;">
                        <span v-if="fpgto.deposito == 'S'">SIM</span>
                        <span v-else>NÃO</span>
                    </td>
                    <td style="text-align: center;">
                        <span v-if="fpgto.calculaJuros == 'S'">SIM</span>
                        <span v-else>NÃO</span>
                    </td>
                    <td style="text-align: center;">
                        <span v-if="fpgto.permiteconsumidorfinal == 'S'">SIM</span>
                        <span v-else>NÃO</span>
                    </td>
                    <td style="text-align: center;">
                        <span v-if="fpgto.verificaSituacao == 'S'">SIM</span>
                        <span v-else>NÃO</span>
                    </td>
                    <td style="text-align: center;">
                        <span v-if="fpgto.cartao == 'S'">SIM</span>
                        <span v-else>NÃO</span>
                    </td>
                    <td style="text-align: center;">
                        <span v-if="fpgto.consorcio == 'C'">SIM</span>
                        <span v-else>NÃO</span>
                    </td>
                    <td style="text-align: center;">
                        <span v-if="fpgto.cheque == 'S'">SIM</span>
                        <span v-else>NÃO</span>
                    </td>
					<td style="text-align: center;">
						{{fpgto.ativo == "A" ? "SIM" : "NÃO"}}
					</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div v-else class="col center">
        <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt" @click="fecharModal"></i>
        <h1>Nenhum registro encontrado.</h1>
        <h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
    </div>
</template>
<script>
export default {
	name: "TabelaFormasPagamento",
	props: {
		showTable: Boolean, 
	},
	computed: {
		FormasPagamento() {
			return this.$store.state.formasPagamento;
		}
	},
	data() {
		return {
			indexFormasPagamentosSelecionada: 0
		};
	},
	methods: {
		selecionarFormaPagamento(fpgto) {
			return this.$emit("selecionarFormaPagamento", fpgto);
		},
		fecharModal(){
			this.$emit("fecharModal");
		},
		eventosTeclado(e) {
			if (this.$store.state.mensagemErro || this.$store.state.carregando) {
				return;
			}
			if (e.key == "ArrowUp") {
				if (this.indexFormasPagamentosSelecionada > 0) {
					this.indexFormasPagamentosSelecionada--;
				}
				return setTimeout(() => {
					document.getElementById(this.indexFormasPagamentosSelecionada).focus();
				}, 10);
			}
			if (e.key == "ArrowDown") {
				if (this.indexFormasPagamentosSelecionada < this.FormasPagamento.length -1) {
					this.indexFormasPagamentosSelecionada++;
				}
				return setTimeout(() => {
					document.getElementById(this.indexFormasPagamentosSelecionada).focus();
				}, 10);
			}
			if (e.key == "Enter") {
				return this.selecionarFormaPagamento(this.FormasPagamento[this.indexFormasPagamentosSelecionada]);
			}
			if (e.key == "Tab") {
				this.indexFormasPagamentosSelecionada = 0;
				return setTimeout(() => {
					document.getElementById("textoPesquisa").focus();
				}, 50);
			}
		}
	},
	mounted(){
		document.addEventListener("keydown", this.eventosTeclado);
	},
	destroyed() {
		document.removeEventListener("keydown", this.eventosTeclado);
	}
};
</script>

<style scoped>
#listaModal {
	overflow: auto;
    margin: 0 auto;
    font-size: var(--txt-normal);
	height: 65vh;
}

label {
    cursor: pointer;
}

.hand-button {
    background: none;
    border: none;
    outline: none;
    clear: both;
}

#listaModal.table-full{
	height: 77vh !important;
}
@media screen and (max-width: 768px) {
	#listaModal.table-full{
		height: 68vh !important;
	}
	#listaModal{
		height: 43vh !important;
	}
}
@media screen and (max-width: 425px) {
	#listaModal.table-full{
		height: 71vh !important;
	}
	#listaModal{
		height: 30vh !important;
	}
}
</style>
