<template>
    <div>
		<!-- <h2 v-if="consultandoProduto">
            Buscando Produto<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </h2> -->

        <h2 v-if="!item">
            Insira o código do produto ou aperte F4 para selecionar um produto
        </h2>
		
        <div v-else-if="item == 'naoencontrado'" class="col center w-100">
            <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 30pt" ></i>
            <h3>Produto não encontrado no banco de dados da loja.</h3>
            <h3>Tente alterar ou diminiur os parâmetros de busca.</h3>
        </div>
        <div v-else-if="item == 'cadastroincorreto'" class="col center w-100">
            <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 30pt" ></i>
            <h3>Produto cadastrado incorretamente.</h3>
            <h3>Favor solicitar verificação de cadastro.</h3>
        </div>
        <div v-else-if="Produto" style="position: relative; width: 100%">
            <img :src="Produto.imagem" alt="imagem do produto" v-if="Produto.imagem">
            <div class="col w-100">
                <h2 style="color: blue; margin-bottom: 2px;">
					{{
						ProdutoDescricao
					}}
				</h2>
                <h4>
                    Saldo: 
					{{
						Produto.saldo ? Produto.saldo : (Produto.produto ? Produto.produto.saldo : 0)
					}}
					{{
						Produto.unidade ? Produto.unidade : Produto.produto ? Produto.produto.unidade : ""
					}}
                </h4>
                <h4>
                    Valor a vista: 
					{{ 
						(ValorVistaProduto || 0) | formatarParaReais
					}}
                </h4>
                <h3 v-if="Produto ? (Produto.promocaoVista && !$route.query.trocarProdutos) : false" class="promocao-texto">
                    Promoção a vista: 
                    <span>
                        {{ 
							(ValorPromocaoVista || 0) | formatarParaReais
						}}
                    </span>
                </h3>
                <h4>
                    Valor a prazo: 
					{{ 
						(ValorPrazoProduto || 0) | formatarParaReais
					}}
                </h4>
                <h3 v-if="Produto ? (Produto.promocaoPrazo && !$route.query.trocarProdutos) : false" class="promocao-texto">
                    Promoção a prazo:
                    <span>
                        {{
                            (ValorPromocaoPrazo || 0) | formatarParaReais
                        }}
                    </span>
                </h3>
            </div>
            <h3 v-if="(Produto.produto ? Produto.produto.nomePromocao : Produto.nomePromocao) && !$route.query.trocarProdutos" class="produto-promocao">
                {{
					Produto.nomePromocao || Produto.produto.nomePromocao
				}}
            </h3>
			
        </div>
    </div>
</template>

<script>
import formatarParaReais from "../filters/formatarParaMoeda";
export default {
	name: "ItemProduto",
	props: ["item"],
	
	computed: {
		ProdutoDescricao() {
			return this.Produto && this.Produto.descricao ? 
				this.Produto.descricao 
				: this.Produto.produto.descricao ? 
					this.Produto.produto.descricao 
					: "";
		},
		ValorPromocaoPrazo() {
			return this.Produto && this.Produto?.valorPrazo ? 
				this.Produto.valorPromocaoPrazo : 
					this.Produto?.produto && this.Produto?.produto?.valorPromocaoPrazo ?
						this.Produto?.produto?.valorPromocaoPrazo : 0;
		},

		ValorPromocaoVista() {
			return this.Produto && this.Produto?.valorVista ?
				this.Produto.valorPromocaoVista : 
					this.Produto?.produto && this.Produto.produto?.valorPromocaoVista
						? this.Produto.produto.valorPromocaoVista : 0;
		},

		ValorPrazoProduto() {
			return this.Produto && this.Produto?.valorPrazo ?
				this.Produto.valorPrazo : 
				this.Produto?.produto && this.Produto?.produto?.valorPrazo ?
					this.Produto.produto.valorPrazo : 0;
		},

		ValorVistaProduto() {
			return this.Produto && this.Produto?.valorVista ?
				this.Produto.valorVista : 
					this.Produto?.produto && this.Produto.produto.valorVista ?
						this.Produto.produto.valorVista : 0;
		},

		consultandoProduto() {
			return this.$store.state.consultandoProduto;
		}
	},

	filters: {
		formatarParaReais
	},

	data() {
		return {
			Produto: {}
		};
	},

	mounted() {
		if(this.item?.id) {
			this.Produto = this.item;
		}
	},

	watch: {
		item(newV) {
			if(newV) {
				this.Produto = newV;
			}
		}
	}
};
</script>

<style scoped>
.produto-promocao {
    color: #fff;
    position: absolute;
    right: 10px;
    bottom: 45px;
    text-shadow: 1px 1px 1px #292929;
    font-size: 12pt;
    transform: rotate(-15deg);
    background-color: red;
    border-radius: 50%;
    padding: 2px;
    box-shadow: 0px 3px 10px yellow;
    text-align: center;
    display: flex;
    max-width: 165px;
    word-break: normal;
}
.promocao-texto {
    color: var(--confirmar);
    font-size: 12pt;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 0px;
  height: 45px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@media screen and (max-width: 1150px) {
    .produto-promocao {
        bottom: 10px;
    }
}
@media screen and (max-width: 530px) {
    .produto-promocao {
        font-size: 10pt;
        max-width: 120px;
    }
}
@media screen and (max-width: 480px) {
    .produto-promocao {
        font-size: 8pt;
        max-width: 80px;
        bottom: 45%;
    }
}
</style>