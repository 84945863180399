<template>
    <div class="cadastro-produto-aba">
        <div class="cadastro-produto-conteudo" v-if="mudaraba">
			<div class="cadastro-produto-cabecalho" v-if="PropProduto">
				<h3 style="color: var(--primario);">
					{{PropProduto ? PropProduto.id : ""}}
					&nbsp; - &nbsp;
					{{PropProduto ? PropProduto.descricao : ""}}
				</h3>
			</div>

			<div class="conteudo-empresa-variacao">
				<div class="tabela-empresas">
					<table class="tabela-empresa">
						<thead>
							<tr>
								<td style="color: blue; text-align: center;">Cód.</td>
								<td style="text-align: left;">Empresa</td>
								<td style="text-align: center;">Ação</td>
							</tr>
						</thead>
						<tbody>
							<tr 
								class="itemLista" 
								v-for="(empresa, index) in Empresas" 
								:key="index" 
								@click="marcarEmpresa(index)"
							>
								<td class="text-center text-nowrap" style="color: var(--primario);">
									<button 
										:id="index" 
										class="hand-button" 
										v-if="index == indexAtual" 
										@keydown.tab.prevent="" 
									>
										<i class="fas fa-hand-point-right" style="color: var(--primario)" >
										</i>
									</button>
									{{empresa.id}}
								</td>
								<td class="text-left text-nowrap">
									{{empresa.nome}}
								</td>
								<td style="text-align: center;">
									<input 
										type="checkbox" 
										:id="empresa.id" 
										v-model="empresa.selecionada"
									>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="selecionar-empresa">
					<div class="mostrar-empresa-selecionada">
						<div 
							class="title" 
							v-if="this.selecionarEmpresa.id"
						>
							<h2 style="color: var(--primario)">
								Empresa Selecionada
							</h2>
						</div>
						<h3>
							{{this.selecionarEmpresa.id}} 
							<span v-if="this.selecionarEmpresa.id">
								-
							</span> 
							{{this.selecionarEmpresa.nome}}
						</h3>
					</div>
					<button 
						class="btn btn-acao" 
						@click="abrirModalEmpresa"
					>
						<span v-if="!this.selecionarEmpresa.id">
							Selecionar
						</span> 
						<span v-else>
							Trocar
						</span> Empresa
					</button>
				</div>
				<Modal 
					v-if="modalEmpresa" 
					:dimensoes="{ width: 'auto', height: 'auto'}" 
					v-on:fecharModal="fecharModal"
				>
					<div class="empresas-modal">
						<table class="tabela-empresa" style="padding: 10px">
							<thead>
								<tr>
									<td style="color: blue; text-align: center;">
										Cód.
									</td>
									<td style="text-align: left;">
										Empresa
									</td>
									<td style="text-align: center;">
										Ação
									</td>
								</tr>
							</thead>
							<tbody>
								<tr 
									class="itemLista" 
									v-for="(empresa, index) in Empresas" 
									:key="index" 
									@click="selecionarEmpresaModal(empresa)"
								>
									<td class="text-center text-nowrap" style="color: var(--primario);">
										<button :id="index" class="hand-button" v-if="index == indexAtual" @keydown.tab.prevent="" >
											<i class="fas fa-hand-point-right" style="color: var(--primario)" >
											</i>
										</button>
										{{empresa.id}}
									</td>
									<td class="text-left text-nowrap">
										{{empresa.nome}}
									</td>
									<td style="text-align: center;">
										<input type="checkbox" :id="empresa.id">
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<button class="btn btn-cancelar" @click="fecharModal">
						Cancelar
					</button>
				</Modal>

				<div class="tabela-de-produtos">
					<table v-if="ProdutoFilhos.length >= 1">
						<thead>
							<tr>
								<td style="color: blue; text-align: center;">Cód.</td>
								<td class="text-left">
									Produto
									<i
										class="fas fa-barcode"
										style="color: black"
										@click="solicitarCodigoBarra"
										@keydown.space="solicitarCodigoBarra"
									></i>
								</td>
								<td class="text-center">Saldo</td>
								<td class="text-right text-nowrap">
									$ Custo
								</td>
								<td class="text-right text-nowrap">
									$ Margem
								</td>
								<td class="text-right text-nowrap">
									$ Médio
								</td>
								<td class="text-right text-nowrap">
									$ Prazo
								</td>
								<td class="text-right text-nowrap">
									$ Vista
								</td>
								<td class="text-center">
									Depende
								</td>
								<td class="text-center">
									<div class="row center text-nowrap">
										<span>
											Ação
										</span>
										&nbsp;
										<i
											class="fas fa-barcode"
											style="color: black"
											@click="solicitarCodigoBarra"
											@keydown.space="solicitarCodigoBarra"
										></i>
									</div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr 
								class="itemProd"
								v-for="(profilho, index) in ProdutoFilhos" 
								:key="index"
							>
								<td class="text-center">
									{{ profilho.id }}
								</td>
								<td class="text-left text-nowrap">
									<div class="flex-descript">
										<span>
											{{
												profilho.descricao || profilho.descresumido
											}}
										</span>
										<div class="tooltip">
											<span class="tooltiptext">
												Gerar etiqueta única
											</span>
											<i 
												class="fas fa-barcode" 
												style="color: #000" 
												@click="gerarEtiquetaUnica(profilho)"
											></i>
										</div>
									</div>
								</td>
								<td class="text-center">
									{{profilho.saldo}}
								</td>
								<td class="text-right">
									{{(profilho.custo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}
								</td>
								<td class="text-right">
									{{(profilho.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}
								</td>
								<td class="text-right">
									{{(profilho.customedio || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}
								</td>
								<td class="text-right">
									{{(profilho.pontoequi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}
								</td>
								<td class="text-right">
									{{(profilho.vlrvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}
								</td>
								<td class="text-center">
									<input
										:value="true"
										type="checkbox"
										style="cursor: pointer;"
										v-model="profilho.depende"
										:disabled="!profilho.id"
										@change.prevent="solicitaRemoverDependencia(profilho)"
									>
								</td>
								<td class="text-center">
									<i
										class="fas fa-edit"
										style="color: var(--confirmar)"
										@click="mudarpreco(profilho)"
										@keydown.space="mudarpreco(profilho)"
									></i>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
            </div>
            <div id="botoes" class="botoes">
                <button class="botao botao-cancelar" @click="retornotela">
                    <i class="fas fa-times ocultar-icone" style="color:var(--cancela-texto)"></i>
                    Cancelar
                    <span class="ocultar-atalho">
						[Alt+c]
					</span>
                </button>
                <button class="botao botao-acao" @click="carregarvariacao" v-if="!PropProduto.pai">
                    <i class="fas fa-superscript ocultar-icone" ocultar-icone style="color:var(--acao-texto)"></i>
                    Formular
                    <span class="ocultar-atalho">
						[Alt+f]
					</span>
                </button>
                <button class="botao botao-adicionar" @click="salvarvariacao">
                    <i class="fa fa-check ocultar-icone" style="color:var(--adicionar-texto)"></i>
                    Salvar
                    <span class="ocultar-atalho">
						[Alt+s]
					</span>
                </button>
            </div>
        </div>
        <div v-else class="mostraVariacao">
            <GeraVariacao 
				:produtoPai="PropProduto" 
				v-on:cancelarVariacao="carregarvariacao" 
				v-on:gerarVariacoes="GerarVariacao"
				:geraVariacaoCompra="false"
			/>
        </div>
        <Modal 
			v-if="codigoGerar" 
			:dimensoes="{ width: '80%', height: '80%' }" 
			:idModal="'codigo-barras'"
			@fecharModal="codigoGerar = null"
		>
			<CodigoBarra 
				v-bind:codiBarra="codigoGerar" 
				v-on:fechartela="codigoGerar = null"
			/>
		</Modal>
		<Modal 
			v-if="produtoemPedido" 
			@fecharModal="produtoemPedido = false"
			:idModal="'produtos-pedido'"
			:dimensoes="{width: '50%', height: 'auto'}"
		>
			<div class="pedidosContainer">
				<h2 class="text-center">
					Consulta Pedidos
				</h2>
				<table>
					<thead>
						<tr>
							<td>
								Cód. Pedido
							</td>
							<td>
								Cód. Produto
							</td>
							<td>
								Quantidade Produto
							</td>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(venda, index) in listaPedidos" 
							:key="index"
						>
							<td style="color:blue;">
								{{venda.cdvenda}}
							</td>
							<td>
								{{produtoNoPedido.id}}
							</td>
							<td>
								{{produtoNoPedido.qtd}}
							</td>
						</tr>
					</tbody>
				</table>
				<button 
					class="botao botao-acao" 
					@click="produtoemPedido = !produtoemPedido"
					style="align-self: center;"
				>
					Ocultar Pedidos
				</button>
			</div>
		</Modal>
    </div>
</template>
<script>
import { FormacaoPreco } from "../models/Produto";
import CodigoBarra from "./CodigoBarra.vue";
import GeraVariacao from "./GeraVariacao.vue";
import Modal from "./Modal.vue";

export default {
	name: "ProdutoVariacao",

	props: {
		PropProduto: Object,
	},

	components: {
		CodigoBarra,
		GeraVariacao,
		Modal
	},

	data(){
		return {
			mudaraba : true,
			vetorvariacoes : [],
			vetorgrade : [],
			indexAtual: 0,
			variacao : "",
			grade : "",
			abrirModal: false,
			modalEmpresa: false,
			selecionarEmpresa: "",
			check: false,
			listagrade: [],
			telaBarra: false,
			salvouVariacao: false,
			produtoemPedido: false,
			listaPedidos: [],
			visualizar: false,
			produtoNoPedido: [],
			codigoGerar: null,
		};
	},

	computed: {
		ProdutoFilhos(){
			return this.$store.state.produtoFilhos;
		},

		Empresas() {
			return [...this.$store.state.Empresas];
		},
		EmpresaSel() {
			return this.$store.state.Empresa;
		},
	},

	mounted() {
		if (!this.PropProduto) {
			this.$emit("mudarAba", {target: {id: "dados" }});
			return this.$store.commit("relatarErro", {mensagem: "É necessário cadastrar dados do produto!"});
		}
		this.$bus.$on("confirmarPolar", this.confirmarPolar);
		this.$bus.$on("cancelarPolar", this.cancelarPolar);
		this.$store.dispatch("buscarGrades");
		document.addEventListener("keydown", this.eventosTeclado);
		this.prencehervariacao();
		this.buscarempresas();
	},

	destroyed() {
		this.$bus.$off("confirmarPolar", this.confirmarPolar);
		this.$bus.$off("cancelarPolar", this.cancelarPolar);
		document.removeEventListener("keydown", this.eventosTeclado);
		this.$store.commit("atualizarProdutosAll", []);
	},

	methods: {

		//METODOS ETIQUETA
		gerarEtiquetaUnica(filho){
			this.codigoGerar = {
				barraunico: filho,
			};
		},
		proximaEtiqueta (index) {
			if (index +1 === this.ProdutoFilhos.length) {
				this.solicitarCodigoBarra();
			} else {
				this.proximoCampo(`${index + 1}-qtd-etiqueta`);
			}
		},
		
		//METODOS COD BARRA
		solicitarCodigoBarra () {
			try {
				const codigos = [];
				this.ProdutoFilhos.forEach(filho => {
					if (!filho.id) throw new Error(`Produto ${filho.descricao} sem código! Favor salvar as variações primeiro.`);
					codigos.push(filho);
				});
				this.codigoGerar = {
					barraunico: this.PropProduto,
					barravariacao: codigos,
				};
			} catch (error) {
				return this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},

		fechatelabarra(){
			this.telaBarra = false;
			this.CodiBarra = {};
		},

		//METODOS EMPRESA
		abrirModalEmpresa(){
			this.modalEmpresa = true;
		},

		selecionarEmpresaModal(empresa) {
			this.selecionarEmpresa = empresa;
			this.fecharModal();
		},

		marcarEmpresa(index) {
			this.Empresas[index].selecionada = !this.Empresas[index].selecionada;
			this.indexAtual = index;
		},
		
		async buscarempresas(){
			if (this.Empresas.length < 1) {
				await this.$store.dispatch("buscarEmpresas");
			}
			setTimeout(() => {
				this.Empresas.forEach((emp, index) => {
					emp.selecionada = false;
					if (emp.id == this.EmpresaSel.id) {
						emp.selecionada = true;
						this.indexAtual = index;
					}
				});
			}, 100);
		},

		//GERAL
		retornotela(){
			this.$emit("mudarAba", {target: {id: "EstoqueETributacao" }});
		},
		
		fecharModal(){
			this.modalEmpresa = false;
		},

		eventosTeclado(e) {
			if(!this.mudaraba) return;
			if (`${e.key}`.toUpperCase() == "C" && e.altKey && this.mudaraba) {
				e.preventDefault();
				this.retornotela();
			}
			if (`${e.key}`.toUpperCase() == "F" && e.altKey && this.mudaraba) {
				e.preventDefault();
				this.carregarvariacao();
			}
			if (`${e.key}`.toUpperCase() == "S" && e.altKey && this.mudaraba) {
				e.preventDefault();
				this.salvarvariacao();
			}
			if (e.key == "ArrowUp") {
				if (this.indexAtual > 0) {
					this.indexAtual--;
				}
				setTimeout(() => {
					document.getElementById(this.indexAtual).focus();
				}, 10);
			}
			if (e.key == "ArrowDown") {
				if (this.indexAtual < this.Empresas.length -1) {
					this.indexAtual++;
				}
				setTimeout(() => {
					document.getElementById(this.indexAtual).focus();
				}, 10);
			}
		},

		focusout(evento){
			const id = evento.target.id;
			if (this[id].indexOf(",") == -1){
				this[id] =  Number(this[id]).toFixed(2).replace(".",",");
			}
		},

		proximoCampo(id) {
			document.getElementById(id).focus();
			document.getElementById(id).select();
		},

		confirmarPolar(item) {
			if (item.acao && typeof item.acao == "function") {
				item.acao();
			}
		},

		cancelarPolar(item) {
			if (item.alterarDepende) {
				this.buscarFilhos();
			}
		},

		//METODOS PRODUTO FILHOS 
		solicitaRemoverDependencia(filho) {
			if (filho.depende) {
				this.$store.commit("relatarErro", {
					mensagem: `Tem certeza que deseja marcar que a variação ${filho.variacao}${filho.grade ? ` e grade ${filho.grade}` : ""} com dependência do produto pai? Os valores de custo e venda serão atualizados!`,
					tipo: "polar",
					item: {
						alterarDepende: true,
						filho,
						acao: () => {
							this.atualizarDependenciaFilho(filho);
						}
					}
				});
			} else {
				this.$store.commit("relatarErro", {
					mensagem: `Tem certeza que deseja remover a variação ${filho.variacao}${filho.grade ? ` e grade ${filho.grade}` : ""} com dependência do produto pai?`,
					tipo: "polar",
					item: {
						alterarDepende: true,
						filho,
						acao: async () => {
							this.atualizarDependenciaFilho(filho);
							setTimeout(() => {
								this.$store.commit("relatarErro", {
									mensagem: `Alteração salva com sucesso, deseja alterar o cadastro da variação ${filho.variacao}${filho.grade ? ` e grade ${filho.grade}` : ""}?`,
									tipo: "polar",
									item: {
										acao: () => {
											this.irCadastroFilho(filho);
										},
									}
								});
							}, 100);
						}
					}
				});
			}
		},

		async irCadastroFilho (filho) {
			await this.$store.dispatch("selecionarProdutoPorId", {id: filho.id || filho.cdproduto });
			this.$emit("mudarAba", {target: {id: "formacaoPreco" }});
		},

		verificarFilhosDepende() {
			this.ProdutoFilhos.forEach((filho, index) => {
				filho.depende = (filho.ecf);
				filho.index = index;
			});
			this.$forceUpdate();
		},

		async buscarFilhos() {
			this.$store.state.carregando = true;
			await this.$store.dispatch("buscarFilhos", {
				pai: this.PropProduto.pai != null ? this.PropProduto.pai : this.PropProduto.id
			});
			this.verificarFilhosDepende();
			this.$forceUpdate();
			this.$store.state.carregando = false;
		},

		async mudarpreco(filho){
			if(!filho?.id){
				return this.$store.commit("relatarErro", {mensagem: "É necessário salvar a variação antes de formular o preço"});
			}
			this.irCadastroFilho(filho);
		},

		async atualizarDependenciaFilho(filho) {
			try {
				let formacaoPreco = {};
				if (filho.depende) {
					this.Empresas.forEach(empresa => {
						if (empresa.selecionada) {
							formacaoPreco[empresa.id] = new FormacaoPreco({
								...filho,
								ecf: true,
								ultcusto: filho.custofixo,
								custo: this.PropProduto.custo,
								custofixo: this.PropProduto.custofixo,
								customedio: this.PropProduto.customedio,
								markup: this.PropProduto.markup,
								markupseg: this.PropProduto.markupseg,
								pontoequi: this.PropProduto.pontoequi,
								descvista: this.PropProduto.descvista,
								vlrvista: this.PropProduto.vlrvista,
								fator: this.PropProduto.fator,
								ncm: this.PropProduto.ncm,
								cofins: this.PropProduto.cofins,
								cofinssittrib: this.PropProduto.cofinssittrib,
								cofinstributacao: this.PropProduto.cofinstributacao,
								frete: this.PropProduto.frete,
								icmsest: this.PropProduto.icmsest,
								icmsestsittrib: this.PropProduto.icmsestsittrib,
								icmsint: this.PropProduto.icmsint,
								icmsintsittrib: this.PropProduto.icmsintsittrib,
								icmsst: this.PropProduto.icmsst,
								ipi: this.PropProduto.ipi,
								ipisittrib: this.PropProduto.ipisittrib,
								ipitributacao: this.PropProduto.ipitributacao,
								outros: this.PropProduto.outros,
								percentualacrescimofisica: this.PropProduto.percentualacrescimofisica,
								pis: this.PropProduto.pis,
								pissittrib: this.PropProduto.pissittrib,
								pistributacao: this.PropProduto.pistributacao,
								valorcofins: this.PropProduto.valorcofins,
								valorfrete: this.PropProduto.valorfrete,
								valoricmsst: this.PropProduto.valoricmsst,
								valoripi: this.PropProduto.valoripi,
								valormarkupseg: this.PropProduto.valormarkupseg,
								valoroutros: this.PropProduto.valoroutros,
								valorpis: this.PropProduto.valorpis,
								cfopintercompra: this.PropProduto.cfopintercompra,
								cfopestacompra: this.PropProduto.cfopestacompra,
							});
						}
					});
					await this.$store.dispatch("formarPreco", {formacaoPreco, id: filho.id || filho.cdproduto, manterItem: true});
				} else {
					this.Empresas.forEach(empresa => {
						if (empresa.selecionada) {
							formacaoPreco[empresa.id] = new FormacaoPreco({
								...filho,
								ecf: false
							});
						}
					});
					await this.$store.dispatch("formarPreco", {formacaoPreco, id: filho.id || filho.cdproduto, manterItem: true});
				}
				await this.buscarFilhos();
			} catch (error) {
				console.log(error);
			}
		},

		//METODOS VARIACAO
		async salvarvariacao(){
			await this.$store.dispatch("salvarvariacoes", {
				produtoFilhos : this.ProdutoFilhos
			});
			this.verificarFilhosDepende();

			/*
				PEDIRAM PARA REMOVER A LINHA ABAIXO PARA QUE AO SAIR DA VARIACAO CONTINUAR NA TELA 
				DE CADASTRO DO PRODUTO
				this.$emit("cancelarEdicao");
			*/
		},

		async GerarVariacao(produtos){
			this.$store.state.produtoFilhos = [...produtos];
			this.$store.state.carregando = true;
			await this.salvarvariacao();
			this.$store.state.carregando = false;

			this.carregarvariacao();
			// this.verificarFilhosDepende();
		},

		async produtoPossuiLotes() {
			try {
				
				const lotes = await this.$store.dispatch("buscarLotes", {
					empresa: this.$store.state.Empresa.id,
					cdProduto: this.PropProduto?.id
				});
				
				if(lotes && lotes?.length > 0) {
					this.$store.commit("relatarErro", {
						mensagem: "Este produto possui lotes lançados e não é possível realizar a variação!",
					});
					return true;
				}

				return false;
			} catch (error) {
				console.error(`Erro ao buscar lotes do produto: ${error.message}`);
				return false;
			}
		},

		async carregarvariacao(){
			if(await this.produtoPossuiLotes()) return;
			this.$store.state.carregando = true;
			if(this.PropProduto.servprod == "S"){
				this.$store.state.carregando = false;
				return this.$store.commit("relatarErro", {mensagem: "Não é possivel formular variações de um serviço."});
			}
			const data = await this.$store.dispatch("verificarVariacaoProduto", {cdproduto: this.PropProduto.id});
			this.$store.state.carregando = false;
			if(!data.mensagem){
				this.$store.commit("relatarErro", {mensagem: "Não é possivel gerar variação deste produto pois esta em algum pedido em aberto, finalize o pedido para gerar uma nova variação"});
				this.listaPedidos = data;
				this.listaPedidos.forEach(pedido => {
					pedido.itens.forEach(produto => {
						if(this.PropProduto.id == produto.produto.cdproduto){
							this.produtoNoPedido = {
								id: produto.produto.cdproduto,
								qtd: produto.quantidade
							};
						}
					});
				});
				return setTimeout(async () => {
					this.produtoemPedido = true;
				}, 1000);
			}

			this.mudaraba = !this.mudaraba;
			setTimeout(() => {
				if (this.mudaraba){
					document.getElementById(this.EmpresaSel.id).checked = true;
				}
			}, 50);
		},
		
		async prencehervariacao(){
			let cdempresa;
			if(this.PropProduto.empresas?.length > 0){
				cdempresa = this.PropProduto.empresas[0].cdempresa; 
			}
			await this.$store.dispatch("selecionarProdutoPorEmpresa", {id: this.PropProduto.id, cdempresa: cdempresa});
			this.vetorvariacoes = [...new Set(this.ProdutoFilhos.map(prod => prod.variacao))];
			this.vetorgrade = [...new Set(this.ProdutoFilhos.map(prod => prod.grade))];
			await this.buscarFilhos();
		},
		
		IncluirGrade() {
			if (!this.grade) return;
			const existe = this.vetorgrade.find(varia => varia == this.grade);
			if (existe) return this.$store.commit("relatarErro",{mensagem: "Esta grade já existe, verifique!."});
			this.vetorgrade.push(this.grade);
			this.grade = "";
		},

		IncluirVariacao() {
			if (!this.variacao) return;
			const existe = this.vetorvariacoes.find(varia => varia == this.variacao);
			if (existe) return this.$store.commit("relatarErro",{mensagem: "Esta variação já existe, verifique!."});
			this.vetorvariacoes.push(this.variacao);
			this.variacao = "";
		},

		alterar(tipo,indice,campoatual){
			if (tipo == "variacao"){
				this.variacao = campoatual;
			} else {
				this.grade = campoatual;
			}
			this.proximoCampo(tipo);
		},

		remover(tipo,indice){
			if (tipo == "variacao"){
				this.vetorvariacoes.splice(indice,1);
			} else {
				this.vetorgrade.splice(indice,1);
			}
		},
	},

};
</script>

<style scoped>
.cadastro-produto-cabecalho {
    display: flex;
    flex-direction: column;
    justify-content: center;
	align-items: center;
    background-color: var(--bg-app);
    width: 100%;
}
.cadastro-produto-cabecalho h3 {
    margin: 5px;
}
.cadastro-produto-conteudo {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
	overflow: hidden;
}
.conteudo-empresa-variacao {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
	flex-grow: 1;
	overflow: hidden;
}

.tabela-empresas{
	height: 100%;
	max-height: max-content;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	max-width: 500px;
	margin-left: 10px;
	overflow-y: auto;
	overflow-x: hidden;
}
.tabela-empresa{
	/* width: 400px; */
	border: 2px solid slategrey;
	padding: 10px 10px;
}
.tabela-de-produtos{
	display: flex;
	flex-direction: column;
	overflow-x: auto;
	height: max-content;
	max-height: 72vh;
	margin: 0px 5px;
}
.tabela-empresa,
.tabela-de-produtos {
    margin: 5px;
}
.selecionar-empresa{
	display: none;
}

.flex-descript{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

#botoes {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 5px 0px;
}
#botoes button {
    width: 100%;
    margin: 0px 5px;
}

.itemLista td {
    max-height: 20px;
}
.mostraVariacao {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    flex-grow: 1;
}

.empresas-modal{
	width: 100%;
	overflow-x:auto;
	height: max-content;
	margin-bottom: 15px;
}

.pedidosContainer{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	margin-top: 30px;
}
.pedidosContainer button{
	margin-top: 10px;
}
@media screen and (max-width: 993px){
	.conteudo-empresa-variacao
	{
		display: flex;
		flex-direction: column;
	}
	.tabela-empresa thead tr{
		width: 300px;
	}
	.tabela-empresa
	{
		margin: auto;
		width: 100%;
		max-width: 50%;
	}
}
@media screen and (max-width: 575px){
	.ocultar-atalho{
		display: none;
	}
    .cadastro-produto-cabecalho{
		height: 30px;
		font-size: 10pt;
	}
}
@media screen and (max-width: 475px){

	.ocultar-icone{
		display: none;
	}
	.cadastro-produto-conteudo {
		overflow-y: auto;
	}
	.conteudo-empresa-variacao{
		display: flex;
		overflow-y: auto;
		flex-direction: column-reverse;
	}
	.tabela-empresas{
		display: none;
	}
	.tabela-empresa{
		width: 100%;
		padding: 0px;
		overflow-x: auto;
		margin: 30px auto;
	}
	.selecionar-empresa{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.selecionar-empresa .mostrar-empresa-selecionada{
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		width: 100%;
	}
}
</style>
