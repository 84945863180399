import { EntradaFinanceiro, EntradaFinanceiroAPI } from "./EntradaFinanceiro.js";
import {EntradaEstoque, EntradaEstoqueAPI, EntradaItem, EntradaItemAPI} from "./EntradaItem.js";

export class Entrada {
	constructor(entrada) {
		if (!entrada) return;
		this.aumento = entrada.aumento;
		this.baseicms = entrada.baseicms;
		this.basesubst = entrada.basesubst;
		this.cdcompra = entrada.cdcompra;
		this.cdempresa = entrada.cdempresa;
		this.cdpagto = entrada.cdpagto;
		this.cfop = entrada.cfop;
		this.ciffob = entrada.ciffob;
		this.confirmada = entrada.confirmada;
		this.datacompra = entrada.datacompra;
		this.datapedcompra = entrada.datapedcompra;
		this.desconto = entrada.desconto;
		this.itens = entrada.itens?.length ? [...entrada.itens.map(item => (new EntradaItem({...item})))] : [];
		if (!entrada.estoque?.length) {
			this.estoque = entrada.itens?.length ? [...entrada.itens.map((item, index) => (new EntradaItem({...item, cdseq: (index+1)})))] : [];
		} else {
			this.estoque = entrada.estoque.map(item => new EntradaEstoque(item));
			this.estoque.filter(item => item.cdpai).forEach(item => {
				const index = this.estoque.map(it => it.cdproduto).indexOf(item.cdpai);
				if (index >= 0) {
					if (!this.estoque[index].filhos) {
						this.estoque[index].filhos = [];
					} 
					this.estoque[index].filhos.push(item);
					if(!this.estoque[index].quantidadeInicialConsiderada) {
						this.estoque[index].quantidade = 0;
						this.estoque[index].quantidadeInicialConsiderada = true;
					}
					this.estoque[index].quantidade += item.quantidade;
				}
			});
			if (this.confirmada == "N") {
				this.itens.filter(item => !item.cdproduto).forEach(item => this.estoque.push({...item}));
			}
		}
		this.estoque = this.estoque.filter(item => !item.cdpai);
		this.financeiro = entrada.financeiro ? entrada.financeiro.length ? entrada.financeiro.map(financa => new EntradaFinanceiro(financa)) : [] : [];
		this.fornecedor = entrada.fornecedor;
		this.frete = entrada.frete;
		this.outros = entrada.outros;
		this.icms = entrada.icms || 0;
		this.modelo = entrada.modelo;
		this.nfeid = entrada.nfeid;
		this.nfechave = entrada.nfechave;
		this.observa = entrada.observa;
		this.seguro = entrada.seguro;
		this.serie = entrada.serie;
		this.tipo = entrada.tipo;
		this.transporte = entrada.transporte;
		this.qtdvolumetransporte = entrada.qtdvolumetransporte;
		this.embalagemtransporte = entrada.embalagemtransporte;
		this.pesobrutotransporte = entrada.pesobrutotransporte;
		this.pesoliquidotransporte = entrada.pesoliquidotransporte;
		this.valorbrutocompra = entrada.valorbrutocompra;
		this.valorbrutopedido = entrada.valorbrutopedido;
		this.vlrfcp = entrada.vlrfcp;
		this.vlripi = entrada.vlripi;
		this.vlrsubst = entrada.vlrsubst;
	}
}

export class EntradaAPI {
	constructor(entrada) {
		this.cdempresa = entrada.cdempresa;
		this.cdcompra = Number(entrada.cdcompra);
		this.tipo = entrada.tipo || "C";
		this.cdfornecedor = entrada.fornecedor?.id;
		this.confirmada = entrada.confirmada;
		this.nomeFornecedor = entrada.fornecedor?.nome;
		this.datapedcompra = entrada.datapedcompra;
		this.datacompra = entrada.datacompra;
		this.cdtransporte = entrada.transporte?.id;
		this.qtdvolumetransporte = entrada.qtdvolumetransporte;
		this.embalagemtransporte = entrada.embalagemtransporte;
		this.pesobrutotransporte = entrada.pesobrutotransporte;
		this.pesoliquidotransporte = entrada.pesoliquidotransporte;
		this.naturezaoperacao = entrada.naturezaoperacao;
		this.cdpedcompra = entrada.cdpedcompra;
		this.seguro = entrada.seguro;
		this.vlrbrutocompra = entrada.vlrbrutocompra;
		this.valorbrutopedido = typeof entrada.valorbrutopedido == "number" ? entrada.valorbrutopedido : Number(`${entrada.valorbrutopedido}`.replace(".", "").replace(",", ".") || 0);
		this.desconto = entrada.desconto || 0;
		this.frete = entrada.frete || 0;
		this.outros = (typeof entrada.outros == "number" ? entrada.outros : Number(`${entrada.outros}`.replace(".", "").replace(",", ".") || 0)) || 0;
		this.aumento = entrada.aumento || 0;
		this.observa = entrada.observa;
		this.basesubst = (typeof entrada.basesubst == "number" ? entrada.basesubst : Number(`${entrada.basesubst}`.replace(".", "").replace(",", ".") || 0)) || 0;
		this.vlrsubst = (typeof entrada.valorICMSst == "number" ? entrada.valorICMSst : Number(`${entrada.valorICMSst}`.replace(".", "").replace(",", ".") || 0)) || 0;
		this.cfopsubst = entrada.cfopsubst;
		this.baseicms = (typeof entrada.baseicms == "number" ? entrada.baseicms : Number(`${entrada.baseicms}`.replace(".", "").replace(",", ".") || 0)) || 0;
		this.icms = (typeof entrada.valorICMS == "number" ? entrada.valorICMS : Number(`${entrada.valorICMS}`.replace(".", "").replace(",", ".") || 0) || 0);
		this.vlripi = (typeof entrada.vlripi == "number" ? entrada.vlripi : Number(`${entrada.vlripi}`.replace(".", "").replace(",", ".") || 0)) || 0;
		this.nfechave = entrada.nfechave;
		this.nfeid = entrada.nfeid;
		this.modelo = "55";
		this.serie = "001";
		this.cfop = entrada.cfop;
		this.itens = entrada.itens.map(item => new EntradaItemAPI({...item}));
		this.estoque = entrada.estoque.map(item => new EntradaEstoqueAPI({...item}));
		if (entrada.estoque ? entrada.estoque.length : false) {
			entrada.estoque.forEach(item => {
				if (item.filhos) {
					this.estoque.push(...item.filhos.map((filho, index) => new EntradaEstoqueAPI({...filho, cdseq: (index+1), cdpai: item.cdproduto})));
				}
			});
		}
		this.financeiro = entrada.financeiro.map(pgto => new EntradaFinanceiroAPI({
			...pgto,
			observa: `Ref. Compra ${entrada.cdcompra} - Tipo ${entrada.tipo}. Feito em ${new Date().toLocaleString("pt-BR")}.`
		}));
	}
}

export class XML010 {
	constructor(xml) {
		this.cdempresa = xml.cdempresa;
		this.chave = xml.chave;
		this.cnpj = xml.cnpj;
		this.nomeFornecedor = xml.nomeFornecedor;
		this.ie = xml.ie;
		this.emissao = xml.emissao;
		this.protocolo = xml.protocolo;
		this.docxml = xml.docxml;
		this.status = xml.status;
		this.importado = xml.importado;
		this.userId = xml.userId;
		this.ativo = xml.ativo;
	}
}