<template>
	<div id="TelaBandeira">
		<h1 class="titulo">Dados da Bandeira</h1>
		<div class="container-form">
			<div class="linha-cadastro">
				<div class="input-group">
					<input class="inputControle" type="text" placeholder="0" id="cod"
						v-model="vCodigo" disabled>
					<label class="inputLabel" for="cod">Código:&nbsp;</label>
				</div>
				<div class="input-group">
					<input 
						class="inputControle" type="text" placeholder=" " id="adm"
						v-model="vAdm" @input="mascara" @keydown.enter="proximoCampo('txd')"
						autocomplete="off"
					>
					<label class="inputLabel" for="adm">Bandeira</label>
				</div>
				<div class="input-group">
					<input 
						class="inputControle" type="text" id="codConci" 
						v-model="vCodConciliadora" placeholder=" "
					/>
					<label class="inputLabel" for="codConci">Cód. Conciliadora&nbsp;</label>
				</div>
				<div class="input-group">
					<input class="inputControle" type="text" placeholder="Taxa do débito" id="txd"
					v-model="vTxd" @input="mascara" autocomplete="off"
					@keydown.enter="proximoCampo('txc')"
					>
					<label class="inputLabel" for="txd">Tx. Débito:&nbsp;</label>
				</div>
				<div class="input-group">
					<input class="inputControle" type="text" placeholder="Taxa do crédito" id="txc"
						v-model="vTxc" @input="mascara" autocomplete="off">
					<label class="inputLabel" for="txc">Tx. Crédito:&nbsp;</label>
				</div>
				<div
					id="ativo"
					@click="mudarAtivo()"
					class="campos"
				>
					<label class="mx-5 text-right w-auto" for="ativo"
						>Ativo:
					</label>
					<i
						class="far fa-times-circle w-100 text-left"
						v-if="!vAtivo"
						style="color: red;"
					>
						Não</i
					>
					<i
						class="far fa-check-circle w-100 text-left"
						v-else
						style="color: green"
					>
						Sim</i
					>
				</div>
			</div>
		</div>
		<div class="botoes">
			<button @click="cancelarEdicao" class="botao botao-cancelar">
				<span class="noCel">Cancelar [Alt + C]</span>
				<span class="cel">Cancelar</span>
			</button>
			<button @click="removerB()" class="botao botao-cancelar" @keydown.space="removerB()">
				<span class="noCel">Deletar [Alt + D]</span>
				<span class="cel">Deletar</span>
			</button>
			<button @click="salvarBandeira" class="botao botao-adicionar">
				<span class="noCel">Salvar [Alt + S]</span>
				<span class="cel">Salvar</span>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "CadastroBandeira",
	props: {
		bandeiraSelecionada: Object
	},
	computed: {
		CamposBandeira(){
			return this.$store.state.CadastroB;
		}
	},
	data(){
		return {
			vCodigo : 0,
			vAdm    : "",
			vTxd    : 0,
			vTxc    : 0,
			vModalidade: "",
			vCodConciliadora: "",
			vAtivo: true
		};
	},

	methods: {
		proximoCampo(id) {
			return document.getElementById(id).focus();
		},
		mudarAtivo() {
			this.vAtivo = !this.vAtivo;
		},
		salvarBandeira() {
			if (this.vAdm != ""){
				if (this.bandeiraSelecionada.novo){
					this.$store.dispatch("salvarBandeira", {
						adm: this.vAdm,
						txdebito: Number(this.vTxd .replace(",", ".")),
						txcredito: Number(this.vTxc .replace(",", ".")),
						modalidade: this.vModalidade,
						cdConciliadora: this.vCodConciliadora,
						ativo: this.vAtivo
					});
				} else {
					this.$store.dispatch("editarBandeira", {
						codigo: this.vCodigo,
						adm: this.vAdm,
						txdebito:  Number(this.vTxd .replace(",", ".")),
						txcredito: Number(this.vTxc .replace(",", ".")),
						modalidade: this.vModalidade,
						cdConciliadora: this.vCodConciliadora,
						ativo: this.vAtivo
					});
				}
				this.cancelarEdicao();
			}
		},

		cancelarEdicao() {
			this.vCodigo = 0;
			this.vAdm    = "";
			this.vTxd    = 0;
			this.vTxc    = 0;
			this.$store.state.CadastroB = null;
			this.$emit("cancelarEdicao");
		},

		deletarAcao() {
			this.$emit("cancelarEdicao");
		},

		removerB() {
			this.$store.dispatch("deletarBandeira", {
				codigo :this.vCodigo
			});
			this.cancelarEdicao();
		},

		mascara(evento){
			const id = evento.target.id;
			const input = document.getElementById(id);
			const v = input.value;
			if (id == "adm") {
				return this.vAdm =  this.vAdm.toUpperCase();
			}
			var er = /[^0-9.,]/;
			er.lastIndex = 0;
			if (er.test(v)) {
				this[id == "txd" ? "vTxd" : "vTxc"] =  this[id == "txd" ? "vTxd" : "vTxc"].substring(0, v.length-1);
				return;
			}
		},

		eventosTeclado(e) {
			if (e.key.toUpperCase() == "S" && e.altKey) {
				this.salvarBandeira();
			}
			if (e.key.toUpperCase() == "D" && e.altKey) {
				this.removerB();
			}
			if (e.key.toUpperCase() == "C" && e.altKey) {
				this.cancelarEdicao();
			}
		},
	},

	beforeMount() {
		if (!this.bandeiraSelecionada.novo) {
			this.$store.state.carregando = true;
			this.$store.dispatch("buscarUnicaBandeira", this.bandeiraSelecionada.codigo);
			setTimeout(() =>{
				if (this.CamposBandeira){
					this.vCodigo = this.CamposBandeira.codigo;
					this.vAdm = this.CamposBandeira.adm;
					this.vTxd = (`${this.CamposBandeira.txdebito || 0}`).replace(".", ",");
					this.vTxc = (`${this.CamposBandeira.txcredito || 0}`).replace(".", ",");
					this.vCodConciliadora = this.CamposBandeira.codconciliadora;
					this.vAtivo = this.CamposBandeira.ativo;
				}
				this.$store.state.carregando = false;
			},100);
		}
	},

	mounted(){
		document.getElementById("adm") .focus();
		document.addEventListener("keydown", this.eventosTeclado);
	},

	destroyed() {
		document.removeEventListener("keydown", this.eventosTeclado);
	}
};
</script>

<style scoped>
.cel{
	display:none;
}
#TelaBandeira{
	display: flex;
	flex-direction: column;
	width: 95%;
	height: 100%;
	margin: 0px auto;
}

.inputLabel {
	display: flex;
	height: 13px;
	white-space: nowrap;
	justify-content: flex-end;
	padding-bottom: 0%;
}

.inputControle {
	height: 30px;
	display: flex;
}

.campos {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 10px 5px;
}

.box-cadastro {
	display: flex;
	flex-grow: 1;
}
.linha-cadastro{
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	gap: 15px;
}
#selectModalidade{
	height: 30px;
	width: 200px;
}
.botoes-controle {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	gap: 15px;
	margin-bottom: 20px;
}

.botao-deletar {
	background-color:#e22828;
	color: #660c0c;
	border: 3px solid #9e1414;	
}

.container-form {
	height: 92vh;
	padding-top: 10px;
}

.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}

.botoes .botao {
	width: 45%;
}
@media screen and (max-width: 425px) {
	.container-form{
		height: 60vh;
	}
	.noCel{
		display: none;
	}
	.cel{
		display: inline-block;
	}
	.botoes-controle .btn i,
	.botoes-controle .btn span {
		display: none;
	}
	.titulo {
		font-size: 18pt;
	}
	.linha-cadastro{
		justify-content: flex-start;
	}
	.campos input{
		width: 160px;
	}

}

@media screen and (max-width: 400px){
	.botao i{
		display: none;
	}
}
</style>
