const userApi = {
	password: process.env.VUE_APP_API_PASSWORD,
	userName: process.env.VUE_APP_API_USERNAME,
};

const parseJwt = (token) => {
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
		return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(""));
	return jsonPayload;
};

const isTokenExpired = () => {
	const localJwt = sessionStorage.getItem("jwt-decoded");
	if (!localJwt) {
		sessionStorage.clear();
		return true;
	}
	const decodedJwt = JSON.parse(localJwt);
	if (!decodedJwt) {
		sessionStorage.clear();
		return true;
	}
	return decodedJwt.exp < new Date().getTime()/1000 ? true : false;
};

const getAuthorizationApi = async (commit, API_URL) => {
	try {
		const body = JSON.stringify(userApi);
		const response = await fetch(`${API_URL}/auth`, {
			method: "post",
			headers: {
				"Content-Type": "application/json"
			},
			body,
			cache: "no-cache"
		});
		const data = await response.json();
		if (!data.token || data.token == undefined){
			return commit("relatarErro", {mensagem: "Erro ao autenticar com API"});
		}
		saveAuthorizationHeaders(data);
		return {token: data.token, tipo: data.tipo};
	} catch (error) {
		console.log(error);
	}
};

export const getAuthorizationApiIntercept = async (commit, API_URL, BASE_URL) => {
	try {
		const body = JSON.stringify({
			data: userApi,
			method: "post",
			url: `${API_URL}/auth`,
			headers: {
				"Content-Type": "application/json"
			}
		});
		const response = await fetch(`${BASE_URL}/interceptor`, {
			method: "post",
			headers: {
				"Content-Type": "application/json"
			},
			body,
			cache: "no-cache"
		});
		const data = await response.json();
		if (!data.token || data.token == undefined){
			return commit("relatarErro", {mensagem: "Erro ao autenticar com API"});
		}
		saveAuthorizationHeaders(data);
		return {token: data.token, tipo: data.tipo};
	} catch (error) {
		console.log(error);
	}
};

const saveAuthorizationHeaders = ({token, tipo}) => {
	sessionStorage.setItem("jwt-decoded", parseJwt(token));
	sessionStorage.setItem("jwt-token", token);
	sessionStorage.setItem("jwt-tipo", tipo);
};

export default {
	getAuthorizationHeaders: async (commit, BASE_URL, API_URL) => {
		try {
			const token = sessionStorage.getItem("jwt-token");
			const tipo = sessionStorage.getItem("jwt-tipo");
			if (!token || !tipo) {
				return await getAuthorizationApi(commit, BASE_URL, API_URL);
			}
			if (isTokenExpired()){
				return await getAuthorizationApi(commit, BASE_URL, API_URL);
			}
			return {token, tipo};
		} catch (error){
			console.log(error);
		}
	}

};