
<template>
	<!-- COMPONENTE RESPONSAVEL POR AGRUPAR A PAGINACAO E O FILTRO
		RECEBE 2 SLOT:
		- CONTEUDO FILTRO
		- CONTEUDO TABELA
		mostrarBotaoNovo - Esta fixo mas pode vir do componente pai
	-->
	<div>
		<Filtro 
			@filtrar="$emit('filtrar')"
			@handleShowTable="$emit('handleShowTable')"
			:itensNovo="itensNovo"
			:mostrarBotaoNovo="mostrarBotaoNovo"
			@adicionarNovo="$emit('adicionarNovo', $event)"
			@limpar="$emit('limpar')"
			:modal="modal"
		>
			<slot id="filtro-content" name="filtro" />
		</Filtro>
		<div class="container-tabela">
			<slot name="tabela" />
		</div>
		<paginacao-controle 
			:Paginacao="ControlePaginacao"
			@alterarPaginacao="$emit('filtrar', $event)"
			:habilitaFiltroGrupo="habilitaFiltroGrupo"
			@mudarModal="$emit('mudarModal')"
			:grupos="grupos"
		/>
	</div>
</template>
<script>
//RECEBE 2 PROPS
// MOSTRAR BOTAO NOVO - PARA CONTROLAR SE O BOTAO NOVO VAI APARECER OU NAO
// CONTROLE PAGINACAO - PARA PASSAR A PAGINACAO PRO COMPONENTE DE PAGINACAO-CONTROLE
import Filtro from "../components/Filtro.vue";
import PaginacaoControle from "../components/PaginacaoControle.vue";

export default {
	name: "PaginacaoFiltro",

	props: {
		mostrarBotaoNovo: Boolean,
		ControlePaginacao: Object,
		habilitaFiltroGrupo: Boolean,
		modal: Boolean,
		grupos: Boolean,
		itensNovo: Array,
	},
	
	components: {
		Filtro,
		PaginacaoControle
	},

};
</script>
