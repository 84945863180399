import {doRequest} from "./http.js";

export default {
	state: {
		VendasClienteDevedor: [],
		VendasDetalhadasDashboard: []
	},

	actions: {
		async filtrarVendasClienteDevedor({commit}, payload) {
			try {
				const { dataI, dataF, empresa } = payload;
				if(!empresa) throw new Error("Empresa não fornecedida, por favor verifique");
				if(!dataI || !dataF) throw new Error("datas não fornecidas, por favor verifique");
				const url = `dashboard/vendas-devedor?datai=${dataI}&dataf=${dataF}&empresa=${empresa}`;
				const data = await doRequest(url, {
					method: "GET"
				}, commit);
				if(data && (!data.error || !data.message || !data.mensagem)) {
					commit("atualizarListaVendasClienteDevedor", data);
					return data;
				}
				throw new Error({message: data});
			} catch (error) {
				console.error(error);
				commit("relatarErro", {
					mensagem: error.message
				});
			}
		},
		async filtrarVendasDetalhadas({commit }, payload) {
			try {
				const { dataI, dataF, empresa } = payload; 
				if(!empresa) throw new Error("Empresa não fornecedida, por favor verifique");
				if(!dataI || !dataF) throw new Error("datas não fornecidas, por favor verifique");
				const url = `dashboard/vendas?datai=${dataI}&dataf=${dataF}&empresa=${empresa}`;
				const data = await doRequest(url, {
					method: "GET"
				}, commit);
				if(data && (!data.error || !data.message || !data.mensagem)) {
					commit("atualizarVendasDetalhadas", data);
					return;
				}
				throw new Error({message: data});
			} catch (error) {
				console.error(error);
				commit("relatarErro", {
					mensagem: error.message
				});
			}
		}
	},

	mutations: {
		atualizarListaVendasClienteDevedor(state, payload) {
			state.VendasClienteDevedor = payload;
		},
		atualizarVendasDetalhadas(state, payload) {
			state.VendasDetalhadasDashboard = payload;

		}

	}
};