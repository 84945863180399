<template>
	<div id="grid-vendedor" class="conteudo-item" v-if="relatorioExibir == 'comissao'">
		<div class="informacoes-conteudo">
			<i class="fas fa-print" id="btn-relatorio" @click="solicitarImpressao"
				v-if="Relatorios.comissao.length > 0"></i>
			<div class="informacoes-item" v-if="this.visualizarTudo">
				Clique no nome do vendedor para visualizar mais informações!
			</div>
			<div class="informacoes-item">
				<div class="input-group">
					<input type="date" id="dataI" v-model="dataI" @blur="saiuData"/>
					<label for="dataI">Data inicial</label>
				</div>
				<div class="input-group">
					<input type="date" id="dataF" v-model="dataF" @blur="saiuData"/>
					<label for="dataF">Data final</label>
				</div>
				<button class="botao botao-acao" style="margin-right:10px" @click="buscarRelatorioComissao">
					<i
						class="fas fa-search"
						for="cdproduto"
						style="color: var(--acao-borda)"
					></i>
					<span id="textoFiltrar">Filtrar</span>
				</button>
			</div>
		</div>
		<div class="conteudo-filtro" v-if="this.visualizarTudo">
			<div id="filtro-vendedores">
				<div class="vendedor-item vendedor-ativo" id="vendedor-item-todos"
					@click="consultarVendedor({ cdvendedor: 'todos' })">
					TODOS
				</div>
				<div class="vendedor-item" v-for="vendedor in Comissao" :key="vendedor.cdvendedor"
					:id="`vendedor-item-${vendedor.cdvendedor}`" @click="consultarVendedor({ ...vendedor })">
					{{ vendedor.nome.toUpperCase() }}
				</div>
			</div>
		</div>
		<div class="conteudo-subitem">
			<div v-if="!comissaoVendedor && this.visualizarTudo" class="relatorio-todos">
				<table>
					<thead>
						<tr>
							<th>Cód.</th>
							<th>Nome</th>
							<th>Total vendido</th>
							<th>Total consórcio</th>
							<th>Total a vista</th>
							<th>Comissão a vista</th>
							<th>Comissão consórcio</th>
							<th>Total a prazo</th>
							<th>Comissão a prazo</th>
							<th v-for="metodo in $store.state.formasPagamento.filter(m => m.mostravenda == 'S' || m.mostrarecebimento == 'S')"
								:key="metodo.cdtipo">{{ metodo.descricao }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="vendedor in Relatorios.comissao" :key="vendedor.cdvendedor">
							<td>{{ vendedor.cdvendedor }}</td>
							<td>{{ vendedor.nome }}</td>
							<td>
								{{
										(vendedor.valorpago || 0).toLocaleString(
											"pt-BR",
											{
												style: "currency",
												currency: "BRL",
											}
										)
								}}
							</td>
							<td>
								{{
										(vendedor.valorconsorcio || 0).toLocaleString(
											"pt-BR",
											{
												style: "currency",
												currency: "BRL",
											}
										)
								}}
							</td>
							<td>
								{{
										(vendedor.valorvista || 0).toLocaleString(
											"pt-BR",
											{
												style: "currency",
												currency: "BRL",
											}
										)
								}}
							</td>
							<td>
								{{
										(
											vendedor.comissaovista || 0
										).toLocaleString("pt-BR", {
											style: "currency",
											currency: "BRL",
										})
								}}
							</td>
							<td>
								{{
										(
											vendedor.comissaoconsorcio || 0
										).toLocaleString("pt-BR", {
											style: "currency",
											currency: "BRL",
										})
								}}
							</td>
							<td>
								{{
										(vendedor.valorPrazo || 0).toLocaleString(
											"pt-BR",
											{
												style: "currency",
												currency: "BRL",
											}
										)
								}}
							</td>
							<td>
								{{
										(
											vendedor.comissaoprazo || 0
										).toLocaleString("pt-BR", {
											style: "currency",
											currency: "BRL",
										})
								}}
							</td>
							<td v-for="metodo in $store.state.formasPagamento.filter(m => m.mostravenda == 'S' || m.mostrarecebimento == 'S')"
								:key="metodo.cdtipo">
								{{
										(Relatorios.movimentoVendedor.find(item => Object.keys(item)[0] == vendedor.nome) ?
											(Relatorios.movimentoVendedor.find(item => Object.keys(item)[0] ==
												vendedor.nome)[vendedor.nome].find(item => item.cdTipoPgto == metodo.cdtipo) ?
												Relatorios.movimentoVendedor.find(item => Object.keys(item)[0] ==
													vendedor.nome)[vendedor.nome].find(item => item.cdTipoPgto == metodo.cdtipo).valor : 0)
											: 0).toLocaleString("pt-BR", {
												style: "currency", currency: "BRL"
											})
								}}
							</td>
						</tr>
					</tbody>
				</table>
				<GraficoPizza :chartData="graficoTotalBrutoVendedor" class="grafico-bruto" :options="{
					responsive: true,
					maintainAspectRatio: false,
					title: {
						display: true,
						position: 'top',
						text: `Total vendido ${(Relatorios.comissao.length >
							0
							? Relatorios.comissao
								.map(
									(vendedor) =>
										Number(
											Number(
												vendedor.valorpago || 0
											).toFixed(6)
										) || 0
								)
								.reduce((t, v) => (t += v))
							: 0
						).toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL',
						})}`,
					},
				}" />
				<GraficoPizza :chartData="graficoTotalComissaoVendedor" class="grafico-comissao" :options="{
					responsive: true,
					maintainAspectRatio: false,
					title: {
						display: true,
						position: 'top',
						text: `Total de comissões ${(Relatorios.comissao
							.length > 0
							? Relatorios.comissao
								.map((vendedor) =>
									Number(
										Number(
											Number(
												vendedor.comissaovista ||
												0
											) +
											Number(
												vendedor.comissaoprazo ||
												0
											)
										).toFixed(2)
									)
								)
								.reduce((t, v) => (t += v))
							: 0
						).toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL',
						})}`,
					},
				}" />
			</div>
			<div id="grid-vendedor-relatorio" v-else-if="
				Relatorios.comissao.length > 0 &&
				Relatorios.comissao.find(
					(vendedor) => vendedor.nome == comissaoVendedor
				)
			">
				<div class="vendedor-relatorio">
					<div class="vendedor-relatorio-item">
						<span>Vendas</span>
						<span>{{
								(Relatorios.comissao.find(
									(vendedor) => vendedor.nome == comissaoVendedor
								).qtdevenda || 0) - (Relatorios.comissao.find(
									(vendedor) => vendedor.nome == comissaoVendedor
								).qtdecondicional || 0) - (Relatorios.comissao.find(
									(vendedor) => vendedor.nome == comissaoVendedor
								).qtdeCancelada || 0)
						}}</span>
						<span>
							{{
									(
										Relatorios.comissao.find(
											(vendedor) =>
												vendedor.nome == comissaoVendedor
										).valorpago || 0
									).toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL",
									})
							}}
						</span>
					</div>
					<div class="vendedor-relatorio-item">
						<span>Consórcio</span>
						<span>{{
								Relatorios.comissao.find(
									(vendedor) => vendedor.nome == comissaoVendedor
								).qtdeconsorcio || 0
						}}</span>
						<span>
							{{
									(
										Relatorios.comissao.find(
											(vendedor) =>
												vendedor.nome == comissaoVendedor
										).valorconsorcio || 0
									).toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL",
									})
							}}
						</span>
					</div>
					<div class="vendedor-relatorio-item">
						<span>Condicionais</span>
						<span>{{
								Relatorios.comissao.find(
									(vendedor) => vendedor.nome == comissaoVendedor
								).qtdecondicional || 0
						}}</span>
						<span>
							{{
									(
										Relatorios.comissao.find(
											(vendedor) =>
												vendedor.nome == comissaoVendedor
										).valorcondicional || 0
									).toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL",
									})
							}}
						</span>
					</div>
					<div class="vendedor-relatorio-item">
						<span>Trocas</span>
						<span>{{
								Relatorios.comissao.find(
									(vendedor) => vendedor.nome == comissaoVendedor
								).qtdeTroca || 0
						}}</span>
						<span>
							{{
									(
										Relatorios.comissao.find(
											(vendedor) =>
												vendedor.nome == comissaoVendedor
										).valorTroca || 0
									).toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL",
									})
							}}
						</span>
					</div>
					<div class="vendedor-relatorio-item">
						<span>Cancelamentos</span>
						<span>{{
								Relatorios.comissao.find(
									(vendedor) => vendedor.nome == comissaoVendedor
								).qtdeCancelada || 0
						}}</span>
						<span>
							{{
									(
										Relatorios.comissao.find(
											(vendedor) =>
												vendedor.nome == comissaoVendedor
										).valorcancelado || 0
									).toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL",
									})
							}}
						</span>
					</div>
				</div>
				<div class="grafico-vendas">
					<GraficoPizza :chartData="graficoComissaoVendedorPizza" :options="{
						responsive: true,
						maintainAspectRatio: false,
						title: {
							display: true,
							position: 'top',
							text: `Total vendido por ${comissaoVendedor}`,
						},
					}" v-if="tipoGraficoVendedor == 'Pizza'" />
					<GraficoBarra :chartData="graficoComissaoVendedorBarra" :options="{
						responsive: true,
						maintainAspectRatio: false,
						title: {
							display: true,
							position: 'top',
							text: `Total vendido por ${comissaoVendedor}`,
						},
					}" v-else />
					<i class="fas fa-chart-pie" v-if="tipoGraficoVendedor != 'Pizza'"
						@click="alterarTipoGrafico('Vendedor', 'Pizza')"></i>
					<i class="far fa-chart-bar" v-else @click="alterarTipoGrafico('Vendedor', 'Barra')"></i>
				</div>
				<div class="vendedor-relatorio-comissao">
					<div class="vendedor-relatorio-comissao-item">
						<h4>Comissão</h4>
						<div>
							<span>
								<span>A vista:</span>
								<span>
									{{
											(
												Relatorios.comissao.find(
													(vendedor) =>
														vendedor.nome ==
														comissaoVendedor
												).comissaovista || 0
											).toLocaleString("pt-BR", {
												style: "currency",
												currency: "BRL",
											})
									}}
								</span>
							</span>
							<span>
								<span>A prazo: </span>
								<span>
									{{
											(
												Relatorios.comissao.find(
													(vendedor) =>
														vendedor.nome ==
														comissaoVendedor
												).comissaoprazo || 0
											).toLocaleString("pt-BR", {
												style: "currency",
												currency: "BRL",
											})
									}}
								</span>
							</span>
							<span>
								<span>Consórcio: </span>
								<span>
									{{
											(
												Relatorios.comissao.find(
													(vendedor) =>
														vendedor.nome ==
														comissaoVendedor
												).comissaoconsorcio || 0
											).toLocaleString("pt-BR", {
												style: "currency",
												currency: "BRL",
											})
									}}
								</span>
							</span>
							<span>
								<span>Total comissão: </span>
								<span>
									{{
											(
												(Relatorios.comissao.find(
													(vendedor) =>
														vendedor.nome ==
														comissaoVendedor
												).comissaovista || 0) +
												(Relatorios.comissao.find(
													(vendedor) =>
														vendedor.nome ==
														comissaoVendedor
												).comissaoprazo || 0)
												+
												(Relatorios.comissao.find(
													(vendedor) =>
														vendedor.nome ==
														comissaoVendedor
												).comissaoconsorcio || 0)
											).toLocaleString("pt-BR", {
												style: "currency",
												currency: "BRL",
											})
									}}
								</span>
							</span>
						</div>
					</div>
					<div class="vendedor-relatorio-comissao-item">
						<h4>Métricas</h4>
						<div>
							<span>
								<span>Valor por venda:</span>
								<span>
									<!-- //Cálculo do ticket médio -->
									{{
											(
												(Relatorios.comissao.find(
													(vendedor) =>
														vendedor.nome ==
														comissaoVendedor
												).valorpago || 1) /
												(Relatorios.comissao.find(
													(vendedor) =>
														vendedor.nome ==
														comissaoVendedor
												).qtdevenda || 0)
											).toLocaleString("pt-BR", {
												style: "currency",
												currency: "BRL",
											})
									}}
								</span>
							</span>
							<span>
								<span>Produtos por venda: </span>
								<!-- //Cálculo do produtos/venda -->
								<span>
									{{
											(Relatorios.comissao.find(
												(vendedor) =>
													vendedor.nome ==
													comissaoVendedor
											).produtosporvenda || 0).toFixed(2).replace(".", ",")
									}}
								</span>
							</span>
							<div>
								<span>
									<span>Total atendimentos: </span>
									<span>
										{{
												(Relatorios.comissao.find(
													(vendedor) =>
														vendedor.nome ==
														comissaoVendedor
												).qtdevenda || 0) - (Relatorios.comissao.find(
													(vendedor) =>
														vendedor.nome ==
														comissaoVendedor
												).qtdeCancelada || 0)
										}}
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="center" style="width: auto; height: auto; padding: 20px 0px" v-else>
				<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"
					@click="$router.go(-1)"></i>
				<h2>Nenhuma movimentação encontrada!</h2>
			</div>
		</div>
	</div>
</template>
<script>
import GraficoPizza from "./GraficoPizza.vue";
import GraficoBarra from "./GraficoBarra.vue";
import { mapState } from "vuex";
import { dateFormatISO } from "../utils/dateformat";
import { gerarRelatorio } from "../utils/Relatorio/impressaorelatorio";

export default {
	name: "RelatorioComissao",

	components: {
		GraficoPizza,
		GraficoBarra,
	},

	data() {
		return {
			relatorioExibir: "comissao",
			dataI: "",
			dataF: "",
			comissaoVendedor: null,
			tipoGraficoVendedor: "Pizza",
			graficoComissaoVendedorPizza: {},
			graficoComissaoVendedorBarra: {},
			graficoTotalBrutoVendedor: {},
			graficoTotalComissaoVendedor: {},
			relatorioMenu: false,
			timerFiltro: false,
		};
	},

	computed: {
		Usuario() {
			return this.$store.state.Usuario;
		},

		visualizarTudo() {
			return this.Usuario.verTodasVendas;
		},

		Comissao() {
			if(this.visualizarTudo) {
				return this.Relatorios.comissao;
			} else {
				return this.Relatorios.comissao.filter(rel => rel.cdvendedor === this.Usuario.id);
			}
		},

		...mapState(["Vendedores", "Relatorios"]),
	},

	methods: {
		saiuData(){
			if(this.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataF = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		consultarVendedor(vendedor) {
			if (!vendedor) {
				return;
			}
			const itensVendedor =
				document.querySelectorAll("div.vendedor-item");
			if (itensVendedor.length > 0) {
				itensVendedor.forEach((el) => {
					el.classList.remove(["vendedor-ativo"]);
				});
			}
			if (vendedor.nome) {
				this.graficoComissaoVendedorPizza = {
					labels: [
						"A vista",
						"A prazo",
						"Consórcio",
						"Condicional",
						"Troca",
						"Desconto",
						"Cancelado",
					],
					datasets: [
						{
							backgroundColor: [
								"green",
								"#5ec2f5",
								"#6e3382",
								"#f3934b",
								"#edaa32",
								"#534dae",
								"red",
							],
							data: [
								Number((vendedor.valorvista || 0).toFixed(2)),
								Number((vendedor.valorPrazo || 0).toFixed(2)),
								Number((vendedor.valorconsorcio || 0).toFixed(2)),
								Number(
									(vendedor.valorcondicional || 0).toFixed(2)
								),
								Number((vendedor.valorTroca || 0).toFixed(2)),
								Number(
									(vendedor.valordesconto || 0).toFixed(2)
								),
								Number(
									(vendedor.valorcancelado || 0).toFixed(2)
								),
							],
						},
					],
				};
				this.graficoComissaoVendedorBarra = {
					labels: [
						"A vista",
						"A prazo",
						"Consórcio",
						"Condicional",
						"Troca",
						"Desconto",
						"Cancelado",
					],
					datasets: [
						{
							label: `Total vendido ${(
								vendedor.valorpago || 0
							).toLocaleString("pt-BR", {
								style: "currency",
								currency: "BRL",
							})}`,
							backgroundColor: [
								"green",
								"#5ec2f5",
								"#6e3382",
								"#f3934b",
								"#edaa32",
								"#534dae",
								"red",
							],
							data: [
								Number((vendedor.valorvista || 0).toFixed(2)),
								Number((vendedor.valorPrazo || 0).toFixed(2)),
								Number((vendedor.valorconsorcio || 0).toFixed(2)),
								Number(
									(vendedor.valorcondicional || 0).toFixed(2)
								),
								Number((vendedor.valorTroca || 0).toFixed(2)),
								Number(
									(vendedor.valordesconto || 0).toFixed(2)
								),
								Number(
									(vendedor.valorcancelado || 0).toFixed(2)
								),
							],
						},
					],
				};
				this.atualizarChartData();
			}
			this.comissaoVendedor = vendedor.nome;
			if (itensVendedor.length > 0) {
				document
					.getElementById(`vendedor-item-${vendedor.cdvendedor}`)
					.classList.add(["vendedor-ativo"]);
			}
		},

		async buscarRelatorioComissao() {
			if (this.timerFiltro) {
				clearTimeout(this.timerFiltro);
			}
			this.timerFiltro = setTimeout(async () => {
				await this.$store.dispatch("buscarRelatorioComissao", {
					dataI: this.dataI,
					dataF: this.dataF,
				});
				await this.$store.dispatch("buscarMovimentoVendedor", {
					dataI: this.dataI,
					dataF: this.dataF,
				});
				if (!this.visualizarTudo) {
					// if (!this.Usuario?.cdVendedor) {
					// 	this.$store.commit("relatarErro", {
					// 		mensagem:
					// 			"Usuário sem código do vendedor vinculado. Favor entrar em contato com o suporte!",
					// 	});
					// 	return this.$router.go(-1);
					// }
					this.consultarVendedor(
						this.Relatorios[this.relatorioExibir].find(
							(vendedor) =>
								vendedor.cdvendedor ==
								this.Usuario.id // estava comparando com cdVendedor(mas quando o usuario é caixa nao deixa visualizar)
						)
					);
				}
				this.atualizarChartData();
			}, 500);
		},

		alterarTipoGrafico(tipoRelatorio, tipoGrafico) {
			this[`tipoGrafico${tipoRelatorio}`] = tipoGrafico;
		},

		atualizarChartData() {
			this.graficoTotalBrutoVendedor = {
				labels: this.Relatorios.comissao.map(
					(vendedor) => vendedor.nome
				),
				datasets: [
					{
						backgroundColor: this.Relatorios.comissao.map(
							() =>
								`#${(0x1000000 + Math.random() * 0xffffff)
									.toString(16)
									.substr(1, 6)}`
						),
						data: this.Relatorios.comissao.map((vendedor) =>
							Number(Number(vendedor.valorbruto || 0).toFixed(2))
						),
					},
				],
			};
			this.graficoTotalComissaoVendedor = {
				labels: this.Relatorios.comissao.map(
					(vendedor) => vendedor.nome
				),
				datasets: [
					{
						backgroundColor: this.Relatorios.comissao.map(
							() =>
								`#${(0x1000000 + Math.random() * 0xffffff)
									.toString(16)
									.substr(1, 6)}`
						),
						data: this.Relatorios.comissao.map((vendedor) =>
							Number(
								Number(
									Number(vendedor.comissaovista) +
									Number(vendedor.comissaoprazo)
								).toFixed(2)
							)
						),
					},
				],
			};
		},

		solicitarImpressao() {
			if ((this.visualizarTudo) && !this.comissaoVendedor) {
				this.$store.commit("relatarErro", { mensagem: "Deseja imprimir em A4?", tipo: "polar", item: { impressao: "A4" } });
			} else {
				this.imprimirRelatorio();
			}
		},

		imprimirRelatorio(pageWidth) {
			gerarRelatorio({
				filtro: {
					relatorio: !this.comissaoVendedor
						? this.Relatorios[this.relatorioExibir]
						: this.Relatorios[this.relatorioExibir].filter(
							(item) => item.nome == this.comissaoVendedor
						),
					tipo: this.relatorioExibir,
					resumido: !this.comissaoVendedor,
					dataI: this.dataI,
					dataF: this.dataF,
					movimento: this.Relatorios.movimentoVendedor,
					pageWidth
				},
				acao: "relatorioVendedor"
			});
		},

		abrirRelatorioMenu() {
			document.querySelector("i.fas.fa-eject").style.transform = document
				.querySelector("div#relatorio-menu")
				.classList.contains("open-menu")
				? "rotate(90deg)"
				: "rotate(-90deg)";
			document
				.querySelector("div#relatorio-menu")
				.classList.toggle("open-menu");
		},

		polarConfirmado(item) {
			if (item.impressao) {
				this.imprimirRelatorio(item.impressao);
			}
		},

		polarCancelado() {
			this.imprimirRelatorio(null);
		}
	},

	beforeMount() {
		if(this.$route?.query?.menu === "Comissao") {
			this.$store.dispatch("buscarFormasPagamento");
			this.dataI = dateFormatISO({
				date: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1
				).toLocaleDateString("en-CA"),
				format: "ISOdate"
			});
			this.dataF = dateFormatISO({date: null, format: "ISOdate"});
		}
	},

	mounted() {
		
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		this.$bus.$on("cancelarPolar", this.polarCancelado);
		if ((!this.visualizarTudo) && this.$store.state.Usuario.cdVendedor && !(this.$store.state.Usuario.supervisor || this.$store.state.Usuario.usuarioPrivilegiado)) {
			this.Relatorios.comissao = [];
			this.comissaoVendedor = this.$store.state.Usuario.nome;
			const relatorioMenu = document.getElementById("MenuLateral");
			if (relatorioMenu) {
				relatorioMenu.style.display = "none";
			}
			const gridRelatorio = document.getElementById("grid-relatorio");
			if (gridRelatorio) {
				gridRelatorio.style.gridTemplateColumns = "1fr";
				gridRelatorio.style.gridTemplateAreas = "'conteudo conteudo'";
			}
			const gridVendedor = document.getElementById("grid-vendedor");
			if (gridVendedor) {
				gridVendedor.style.gridTemplateRows = "auto 1fr";
				gridVendedor.style.gridTemplateAreas =
					"'info-conteudo'\n'sub-item'";
				gridVendedor.style.height = "auto";
				const conteudoFiltro =
					document.getElementsByClassName("conteudo-filtro")[0];
				if (conteudoFiltro) {
					conteudoFiltro.style.display = "none";
				}
				const informacoes =
					document.getElementsByClassName("informacoes-item")[0];
				if (informacoes) {
					informacoes.style.marginBottom = "10px";
					informacoes.style.width = "100%";
					document
						.querySelectorAll("input[type='date']")
						.forEach((el) => (el.style.width = "auto"));
				}
			}
			this.$forceUpdate();
		}
		if (document.querySelector("div#relatorio-menu")) {
			document
				.querySelector("div#relatorio-menu")
				.addEventListener("click", (e) => {
					if (
						e.layerX > 99 &&
						document
							.querySelector("div#relatorio-menu")
							.classList.contains("open-menu")
					) {
						this.abrirRelatorioMenu();
					}
				});
		}
	},

	destroyed() {
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
		this.$bus.$off("cancelarPolar", this.polarCancelado);
	}
};
</script>
<style scoped>
#grid-vendedor {
	display: grid;
	grid-template-columns: 1fr;
	height: 100%;
	grid-template-rows: auto auto 1fr;
	grid-template-areas:
		"info-conteudo"
		"filtro-vendedores"
		"sub-item";
}

.informacoes-conteudo {
	display: flex;
	flex-direction: row;
	grid-area: info-conteudo;
	align-items: center;
	justify-content: space-between;
	padding: 0px 10px;
	margin-top: 5px;
}

.informacoes-item {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

}

.informacoes-item label {
	display: flex;
	justify-content: center;
	align-items: center;
}

.informacoes-item input[type="date"] {
	max-width: 140px;
}

#dataI {
	margin-right: 5px;
}

#filtro-vendedores {
	display: flex;
	flex-direction: row;
	overflow-x: auto;
	padding: 8px 0px;
	max-width: 98vw;
	grid-area: filtro-vendedores;
}

.vendedor-item {
	padding: 5px;
	margin: 0px 5px;
	cursor: pointer;
	border: 3px solid var(--border);
	border-radius: 5px;
	background-color: #bbbbbb63;
}

.vendedor-ativo {
	background-color: var(--acao-background);
	border-radius: 5px;
	border: 3px solid var(--acao-borda);
	color: var(--acao-borda);
	cursor: default;
	box-shadow: var(--shadow-secundario);
	font-weight: 500;
}

.conteudo-subitem {
	border-radius: 10px;
	box-shadow: var(--shadow-secundario);
	overflow-y: auto;
	height: 70vh;
	grid-area: sub-item;
	position: relative;
}

.relatorio-todos {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas:
		"tabela-vendedores tabela-vendedores"
		"grafico-bruto grafico-comissao";
}

.grafico-bruto {
	grid-area: grafico-bruto;
	width: 400px;
	margin: 0px auto;
}

.grafico-comissao {
	grid-area: grafico-comissao;
	width: 400px;
	margin: 0px auto;
}

table {
	grid-area: tabela-vendedores;
	background: #ddd;
	width: 100%;
	cursor: default;
}

td,
th {
	padding: 5px;
	cursor: default;
}

td:nth-child(1),
th:nth-child(1) {
	text-align: center;
}

td:nth-child(2),
th:nth-child(2) {
	text-align: left;
}

td:nth-child(3),
th:nth-child(3),
td:nth-child(4),
th:nth-child(4),
td:nth-child(5),
th:nth-child(5),
td:nth-child(6),
th:nth-child(6),
td:nth-child(7),
th:nth-child(7) {
	text-align: right;
}

#grid-vendedor-relatorio {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas:
		"vendedor-relatorio vendedor-relatorio"
		"grafico-vendas relatorio-comissao";
}

.vendedor-relatorio {
	grid-area: vendedor-relatorio;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-around;
}

.vendedor-relatorio-item {
	display: flex;
	flex-direction: column;
	height: 160px;
	width: 160px;
	box-shadow: var(--shadow-primario);
	margin: 10px;
	border-radius: 10px;
	justify-content: space-around;
	align-items: center;
	cursor: default;
}

.vendedor-relatorio-item span:nth-child(2) {
	background-color: var(--bg-primario);
	color: #fff;
	padding: 10px;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.vendedor-relatorio-item span:nth-child(3) {
	font-size: 14pt;
	color: var(--bg-primario);
}

.grafico-vendas {
	display: flex;
	justify-content: center;
	grid-area: grafico-vendas;
	box-shadow: var(--shadow-primario);
	margin: 5px;
	border-radius: 10px;
	max-height: 380px;
	position: relative;
}

.grafico-vendas i {
	color: var(--bg-primario);
	font-size: 28pt;
	position: absolute;
	top: 0;
	right: 0;
}

.vendedor-relatorio-comissao {
	grid-area: relatorio-comissao;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 10px;
}

.vendedor-relatorio-comissao-item {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 10px;
	height: 48%;
	box-shadow: var(--shadow-primario);
}

.vendedor-relatorio-comissao-item h4 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	margin: 0px auto;
	color: var(--bg-primario);
	border-bottom: 1px solid var(--bg-primario);
}

.vendedor-relatorio-comissao-item div {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	height: 100%;
}

.vendedor-relatorio-comissao-item div span {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

@media screen and (max-width: 1125px) {
	.informacoes-conteudo {
		flex-direction: row;
		align-items: flex-start;
	}

	.informacoes-item {
		max-width: 100%;
		width: auto;
		justify-content: flex-start;
	}

	.informacoes-item input[type="date"] {
		max-width: 150px;
	}

	.vendedor-relatorio-comissao-item div {
		flex-direction: column;
		padding: 5px;
	}

	.vendedor-relatorio-comissao-item div span {
		flex-direction: row;
		justify-content: center;
		width: 100%;
		text-align: right;
	}

	.vendedor-relatorio-comissao-item div span span:nth-child(1) {
		width: 60%;
		display: flex;
		justify-content: flex-end;
		text-align: right;
		margin: 0px 2px;
	}

	.vendedor-relatorio-comissao-item div span span:nth-child(2) {
		width: 40%;
		display: flex;
		justify-content: flex-start;
		text-align: right;
		margin: 0px 2px;
	}
}

@media screen and (max-width: 864px) {
	#relatorio-menu i {
		font-size: 18pt;
	}

	#relatorio-menu span {
		font-size: 11pt;
	}

	#relatorio-conteudo {
		font-size: 11pt;
	}

	.vendedor-relatorio-comissao-item div {
		justify-content: flex-start;
	}
}

@media screen and (max-width: 768px) {
	#grid-relatorio {
		width: 100%;
		height: auto;
		max-height: 92vh;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 100px 1fr;
		grid-template-areas:
			"menu"
			"conteudo";
	}

	#MenuLateral {
		top: 0vh;
		left: 0px;
		right: 0px;
		bottom: unset;
		height: 90px;
		position: relative;
		width: 100%;
		display: flex;
		padding: 0px 10px;
		overflow-x: auto;
		z-index: 2;
	}

	#MenuLateral {
		display: flex;
		flex-direction: row !important;
	}

	#grid-vendedor-relatorio {
		width: 100%;
		display: grid;
		max-height: 77.5vh;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto auto 1fr;
		grid-template-areas:
			"vendedor-relatorio vendedor-relatorio"
			"relatorio-comissao relatorio-comissao"
			"grafico-vendas grafico-vendas";
	}

	.vendedor-relatorio-comissao {
		flex-direction: row;
	}

	.vendedor-relatorio-comissao-item {
		height: auto;
		width: 48%;
	}

	.vendedor-relatorio-item {
		width: 120px;
	}

	#btn-relatorio {
		left: 0;
	}
}

@media screen and (max-width: 695px) {
	.informacoes-conteudo .informacoes-item:nth-child(1) {
		display: none;
	}

	.vendedor-relatorio {
		flex-wrap: wrap;
	}

	.vendedor-relatorio-comissao {
		flex-direction: column;
	}

	.vendedor-relatorio-comissao-item {
		height: auto;
		width: 100%;
		margin-bottom: 10px;
	}

	.vendedor-relatorio-comissao-item div {
		flex-direction: row;
		padding: 5px;
	}

	.vendedor-relatorio-comissao-item div span {
		flex-direction: column;
		justify-content: center;
		width: 100%;
		text-align: right;
	}

	.vendedor-relatorio-comissao-item div span span:nth-child(1) {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		text-align: center;
		margin: 0px 2px;
	}

	.vendedor-relatorio-comissao-item div span span:nth-child(2) {
		display: flex;
		justify-content: flex-start;
		text-align: center;
		margin: 0px 2px;
	}
}

@media screen and (max-width: 600px) {
	.fas.fa-eject {
		display: block;
	}

	#relatorio-menu {
		background-color: var(--bg-app);
		box-shadow: 0px 5px 5px #292929;
		position: fixed;
		visibility: hidden;
		opacity: 0;
		animation-name: fadeOutOpacity;
		animation-iteration-count: 1;
		animation-timing-function: ease-in;
		transition: 1s;
		padding-top: 30px;
	}

	#relatorio-menu::after {
		content: "";
		position: absolute;
		top: 0;
		left: 98px;
		width: 100vw;
		height: 92vh;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 5;
		animation-name: fadeInOpacity;
		animation-iteration-count: 1;
		animation-timing-function: ease-in;
		animation-duration: 0.5s;
		opacity: 1;
	}

	#relatorio-menu.open-menu {
		visibility: visible !important;
		transition: 1s;
		animation-name: fadeInOpacity;
		animation-iteration-count: 1;
		animation-timing-function: ease-in;
		opacity: 1;
	}

	#grid-relatorio {
		grid-template-columns: 1fr;
	}

	.informacoes-conteudo {
		align-items: flex-end;
	}
}

@media screen and (max-width: 520px) {
	.informacoes-conteudo {
		align-items: center;
		flex-direction: column;
		gap: 10px;
	}

	.informacoes-item {
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	.informacoes-item div {
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.informacoes-item div input[type="date"] {
		width: 120px;
	}

	.informacoes-item div input[type="date"]::-webkit-calendar-picker-indicator {
		display: none;
	}
}

@media screen and (max-width: 440px){
	#textoFiltrar{
		display:none;
	}
}
</style>