import { Financeiro } from "../models/Financeiro";
import { doRequest } from "./http";

export default {
	state: {
		Relatorios: {
			comissao: [],
			caixa: [],
			movimentoCaixa: [],
			movimentoVendedor: [],
			movimentoProduto: [],
			entradaProduto: [],
			contasBaixadas: [],
			lucroProduto: [],
			saldoEstoque: [],
			vendaFornecedor: {},
			paginacao: {
				totalPages: 0,
				page: 0,
				totalElements: 0,
				size: 25,
			},
			HistoricoProduto: [],
			totalRecebimentoContasReceber: 0,
			Dashboard: {
				Totais: {},
				Financeiro: [],
				Produtos: []
			},
			PosicaoEstoque: [],
			FaturamentoDiasXHoras: [],
		},
	},

	actions: {
		async filtrarSaldoEstoque ({state, commit}, payload) {
			state.carregando = true;
			try {
				let url = `Relatorios/saldoEstoque?empresa=${state.Empresa.id}`;
				if (payload) {
					url += payload.comsaldo ? `&comsaldo=${payload.comsaldo}` : "&comsaldo=S";
					url += payload.descricao ? `&filtro=${payload.descricao}` : "";
					url += payload.fornecedor ? `&cdfornecedor=${payload.fornecedor}` : "";
					const resposta = await doRequest(url, {
						method: "get",
						cache: "no-cache",
					}, commit);
					if (resposta.mensagem || resposta.message) {
						commit("atualizarRelatorio", {
							campo: "saldoEstoque",
							valor: [],
						});
						state.carregando = false;
						return ;
					}
					commit("atualizarRelatorio", {
						campo: "saldoEstoque",
						valor: resposta,
					});
					state.carregando = false;
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}

		},
		async buscarRelatorioVendaFornecedor({state, commit}, payload){
			try {
				const {
					dataI,
					dataF,
					paginaAtual,
					sizeSearch,
				} = payload;

				let url = `Relatorios/venda-agrupada-fornecedor?empresa=${state.Empresa.id}`;

				url += `${dataI ? `&dataInicio=${dataI}` : ""}`;
				url += `${dataF ? `&dataFinal=${dataF}` : ""}`;
				url += `${paginaAtual ? `&page=${paginaAtual}` : ""}`;
				url += `${sizeSearch ? `&size=${sizeSearch}` : ""}`;

				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				
				if (data.mensagem || data.message || data.error) {
					commit("atualizarRelatorio", {
						campo: "vendaFornecedor",
						valor: []
					});
					return;
				}
				const {totalElements, page, size, totalPages } = data;
				commit("atualizarRelatorio", {
					campo: "vendaFornecedor",
					valor: {
						...data,
					},
					paginacao: {
						totalElements,
						page,
						size,
						totalPages
					}
				});
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
		},

		async buscarRelatorioEntradaProduto({commit, state}, payload){
			try {
				state.carregando = true;
				const {
					dataI,
					dataF,
					grupo,
					subg,
					cdfornecedor
				} = payload;
				const url = `Relatorios/entradaProduto?dataFinal=${dataF}&dataInicio=${dataI}&empresa=${state.Empresa.id}
				${grupo ? `&grupo=${grupo}` : ""}${subg ? `&subgrupo=${subg}` : ""}${cdfornecedor ? `&cdfornecedor=${cdfornecedor}` : ""}`;

				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if ((data.mensagem || data.message || data.error)) {
					commit("atualizarRelatorio", {
						campo: "entradaProduto",
						valor: []
					});
					return commit("relatarErro", {mensagem: data.mensagem || JSON.stringify(data)});
				}
				commit("atualizarRelatorio", {
					campo: "entradaProduto",
					valor: data
				});
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
		},
		async buscarRelatorioComissao({commit, state}, payload) {
			try {
				state.carregando = true;
				const {dataI, dataF} = payload;
				let url = "Relatorios/relatorioComissao";
				url += `?dataFinal=${dataF}&dataInicio=${dataI}`;
				url += `&empresa=${state.Empresa.id}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.mensagem || data.message) {
					commit("atualizarRelatorio", {
						campo: "comissao",
						valor: [],
					});
					return commit("relatarErro", {mensagem: data.mensagem || JSON.stringify(data)});
				}
				commit("atualizarRelatorio", {
					campo: "comissao",
					valor: data,
				});
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
		},

		async buscarRelatorioCaixa({commit, state}, payload) {
			try {
				const {
					cdidentificador,
					dataI,
					dataF,
					empresa,
				} = payload;
				state.carregando = true;
				let url = "Relatorios/relatorioCaixa";
				url += `?cdIdentificador=${cdidentificador}`;
				url += `&dataFinal=${dataF}&dataInicio=${dataI}`;
				url += `&empresa=${empresa}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.mensagem || data.message) {
					commit("atualizarRelatorio", {
						campo: "caixa",
						valor: [],
					});
					if (data.erro) {
						return commit("relatarErro", {mensagem: data.mensagem || JSON.stringify(data)});
					}
				} else {
					commit("atualizarRelatorio", {
						campo: "caixa",
						valor: data,
					});
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
		},

		async buscarRelatorioFaturamentoDiasXHoras({state, commit}, payload){

			try {
				state.carregando = true;
				const {
					datai,
					dataf,
					empresa,
					notafiscal
				} = payload;

				let url = `Relatorios/faturamento-dias-horas?dataFinal=${dataf}&dataInicio=${datai}&empresa=${empresa}&notafiscal=${notafiscal}`;

				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				
				state.carregando = false;
				if (data.mensagem || data.message || data.error) {
					commit("atualizarRelatorio", {
						campo: "FaturamentoDiasXHoras",
						valor: []
					});
					return commit("relatarErro", {mensagem: data.mensagem || JSON.stringify(data)});
				}
				commit("atualizarRelatorio", {
					campo: "FaturamentoDiasXHoras",
					valor: data
				});
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
		},

		async buscarMovimentoCaixa({commit, state}, payload) {
			state.carregando = true;
			const {
				cdidentificador,
				cdTipoPgto,
				condicao,
				dataI,
				dataF,
			} = payload;
			try {
				let url = "Relatorios/movimentoCaixa";
				url += `?cdIdentificador=${cdidentificador}`;
				url += `&condicao=${condicao}`;
				url += `&dataFinal=${dataF}&dataInicio=${dataI}`;
				url += `&empresa=${state.Empresa.id}`;
				if (cdTipoPgto) {
					url += `&cdTipoPgto=${cdTipoPgto}`;
				}
				const resposta = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (resposta.mensagem || resposta.message) {
					commit("atualizarRelatorio", {
						campo: "movimentoCaixa",
						valor: [],
					});
					return commit("relatarErro", {mensagem: resposta.mensagem || JSON.stringify(resposta)});
				}
				commit("atualizarRelatorio", {
					campo: "movimentoCaixa",
					valor: resposta,
				});
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
			state.carregando = false;
		},

		async buscarMovimentoVendedor({commit, state}, payload) {
			state.carregando = true;
			const {
				dataI,
				dataF,
			} = payload;
			try {
				let url = "Relatorios/relatorioMovimentoVendedor";
				url += `?empresa=${state.Empresa.id}`;
				url += `&dataFinal=${dataF}&dataInicio=${dataI}`;
				const resposta = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (resposta.mensagem || resposta.message) {
					commit("atualizarRelatorio", {
						campo: "movimentoVendedor",
						valor: [],
					});
					return commit("relatarErro", {mensagem: resposta.mensagem || JSON.stringify(resposta)});
				}
				commit("atualizarRelatorio", {
					campo: "movimentoVendedor",
					valor: resposta,
				});
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
			state.carregando = false;
		},

		async filtrarMovimentoProduto({commit, state}, payload) {
			state.carregando = true;
			const {
				dataI,
				dataF,
				tipoConsulta,
				cliente,
				vendedor,
				sortOrder,
				grupo,
				subg
			} = payload;
			try {
				let url = "Relatorios/movimentoProduto";
				url += `?empresa=${state.Empresa.id}`;
				url += `&dataFinal=${dataF}&dataInicio=${dataI}`;
				if (tipoConsulta == "Clientes") {
					url += `${cliente ? `&cdcliente=${cliente.id}` : ""}`;
				} else {
					url += `${cliente ? `&cdfornecedor=${cliente.id}` : ""}`;
				}
				url += `${vendedor ? `&cdvendedor=${vendedor.id}` : ""}`;
				url += `&sortOrder=${sortOrder || "desc"}`;
				url += `${grupo ? `&grupo=${grupo}` : ""}`;
				url += `${subg ? `&subgrupo=${subg}` : ""}`;
				const resposta = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (resposta.mensagem || resposta.message) {
					commit("atualizarRelatorio", {
						campo: "movimentoProduto",
						valor: [],
					});
                    
					return ;
				}
				commit("atualizarRelatorio", {
					campo: "movimentoProduto",
					valor: resposta,
				});
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
			state.carregando = false;
		},

		async buscarRelatorioContasBaixadas({commit, state}, payload) {
			state.carregando = true;
			const {
				dataI,
				dataF,
				cliente,
				outraEmpresa,
				page,
				size,
				sort,
				tipoPagamento
			} = payload;
			try {
				let url = "Relatorios/contasBaixadas";
				url += `?empresa=${state.Empresa.id}`;
				url += `&dataFinal=${dataF}&dataInicio=${dataI}`;
				url += `${cliente ? `&cdcliente=${cliente.id}` : ""}`;
				url += `${page ? `&page=${page || 0}` : ""}`;
				url += `${size ? `&size=${size || 25}` : ""}`;
				url += `${outraEmpresa ? "&outraEmpresa=S" : ""}`;
				url += `${tipoPagamento && tipoPagamento != "SEL" ? `&cdTipoPagamento=${tipoPagamento}` : ""}`;
				url += `${sort ? `&sort=${sort}` : "&sort=cdpessoa"}`;
				url += "&sortOrder=asc";

				const resposta = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (resposta.mensagem || resposta.message) {
					commit("atualizarRelatorio", {
						campo: "contasBaixadas",
						valor: [],
					});
                    
					return commit("relatarErro", {mensagem: resposta.mensagem || JSON.stringify(resposta)});
				}
				commit("atualizarRelatorio", {
					campo: "contasBaixadas",
					valor: resposta.content.map(conta => new Financeiro(conta)),
					paginacao: {
						totalPages: resposta?.totalPages || 0,
						totalElements: resposta?.totalElements || 0,
						size: resposta?.size || 25,
						page: resposta?.page >= 0 ? resposta?.page : -1
					},
					totalizadores: resposta?.anexo
				});
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error});
			}
			state.carregando = false;
		},

		async buscarDesempenhoProdutos({commit, state}, payload) {
			state.carregando = true;
			const {
				dataI,
				dataF,
				condicao
			} = payload;
			try {
				let url = "Relatorios/desempenhoProduto";
				url += `?empresa=${state.Empresa.id}`;
				url += `&dataFinal=${dataF}&dataInicio=${dataI}`;
				url += `&condicao=${condicao}`;
				const resposta = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (resposta.mensagem || resposta.message) {
					commit("atualizarRelatorio", {
						campo: "lucroProduto",
						valor: [],
					});
                    
					return ;
				}
				commit("atualizarRelatorio", {
					campo: "lucroProduto",
					valor: resposta,
				});
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
			state.carregando = false;
		},

		async buscarSaldoEstoque({commit, state}, payload) {
			state.carregando = true;
			try {
				const {comsaldo} = payload;
				let url = `Relatorios/saldoEstoque?empresa=${state.Empresa.id}`;
				if (comsaldo) url += `&comsaldo=${comsaldo}`;
				const resposta = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (resposta.mensagem || resposta.message) {
					commit("atualizarRelatorio", {
						campo: "saldoEstoque",
						valor: [],
					});
                    
					return commit("relatarErro", {mensagem: resposta.mensagem || JSON.stringify(resposta)});
				}
				commit("atualizarRelatorio", {
					campo: "saldoEstoque",
					valor: resposta,
				});
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
			state.carregando = false;
		},

		async buscarRelatorioHistoricoProduto({state, commit}, payload) {
			try {
				
				state.carregando = true;
				const  {
					empresa,
					datai,
					dataf,
					cdproduto
				} = payload;

				let url = "consultaProduto/movimentacao-produto";

				url += `${empresa ? `?empresa=${empresa}` : "?empresa=ALL"}`;
				url += `${datai ? `&datai=${datai}` : ""}`;					
				url += `${dataf ? `&dataf=${dataf}` : ""}`;				
				url += `${cdproduto ? `&cdProduto=${cdproduto}` : ""}`;				

				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache",
				}, commit);
				state.carregando = false;
				if(data && !data.erro && data.mensagem) {
					commit("atualizarRelatorio", {
						campo: "HistoricoProduto",
						valor: []
					});
					throw new Error(data.mensagem);
				}

				commit("atualizarRelatorio", {
					campo: "HistoricoProduto",
					valor: data,
				});


			} catch (error) {
				console.log(error);
				commit("relatarErro", {
					mensagem: error.message
				});
			}
		},

		async buscarRelatorioDashboard({commit}, payload) {
			try {
				const {dataI, dataF, empresa} = payload;
				let url = `dashboard?datai=${dataI}&dataf=${dataF}&empresa=${empresa}`;
				const data = await doRequest(url, {method: "GET"}, commit);

				if(data?.total) {
					commit("atualizarTotaisDashboard", data.total);
				}
				if(data?.financeiro) {
					commit("atualizarFinanceiroDashboard", data.financeiro);
				}
				if(data?.produtos) {
					commit("atualizarProdutosDashboard", data.produtos);
				}
				
			} catch (error) {
				console.log(error);
				return commit("relatarErro", {
					mensagem: error.mensagem
				});
			}
		},

		async filtrarProdutoPosicaoEstoque({commit}, payload) {
			const { 
				cdreferencia,
				codbarra,
				dataInicio,
				dataFinal,
				descricao,
				empresa,
			} = payload;
			try {
				let url = `estoque/posicao-estoque?empresa=${empresa && empresa !== "SEL" ? empresa : "ALL"}`;
				url +=  `${cdreferencia ? `&cdreferencia=${cdreferencia}` : ""}` ;
				url +=  `${codbarra ? `&codbarra=${codbarra}` : ""}` ;
				url +=  `${dataInicio ? `&dataInicio=${dataInicio}` : ""}` ;
				url +=  `${dataFinal ? `&dataFinal=${dataFinal}` : ""}` ;
				url +=  `${descricao ? `&descricao=${descricao}` : ""}` ;

				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache"
				}, commit);
				
				if(data && (!data.error || !data.mensagem)) {
					commit("atualizarRelatorio", {
						campo: "PosicaoEstoque",
						valor: data
					});
					return;
				}
				commit("atualizarRelatorio", {
					campo: "PosicaoEstoque",
					valor: []
				});

			} catch (error) {
				console.error(error);
				commit("relatarErro", {
					mensagem: error.message
				});
			}
		}

	},

	mutations: {
		atualizarTotaisDashboard(state, payload) {
			const objetoTransformado = {
				"TICKET MÉDIO": (payload.ticketMedio || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"}),
				"PRODUTOS P/ATENDIMENTO": (payload.mediaProdutoAtendimento || 0).toLocaleString("pt-BR", {style: "decimal",
					minimumFractionDigits: 2, maximumFractionDigits: 2
				}),
				"TOTAL VENDAS": (payload.totalVendas || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"}),
				"TOTAL NOTAS EMITIDAS": (payload.totalNotas || 0).toLocaleString("pt-BR", {
					style: "currency",
					currency: "BRL"
				}),
				"ITENS VENDIDO": payload.itensVendido,
				"QUANT. DEVOLUÇÃO": payload.qtdeDevolucao,
				"QUANT. CONDICIONAL": payload.qtdeCondicional,
				"QUANT. VENDAS": payload.qtdeVendas,
				"VENDAS CLIENTE DEVEDOR": payload.qtdeVendasComPendencia,
				"NOVOS CLIENTES": payload.novosClientes,
				"VENDA S/CADASTRO": payload.vendaSemCadastro,
				
				
			};
			state.Relatorios.Dashboard.Totais = objetoTransformado;
		},

		atualizarFinanceiroDashboard(state, payload) {
			state.Relatorios.Dashboard.Financeiro = payload;
		},

		atualizarProdutosDashboard(state, payload) {
			state.Relatorios.Dashboard.Produtos = payload;
		},

		atualizarRelatorio(state, payload) {
			state.Relatorios[payload.campo] = [];
			if(payload.campo === "contasBaixadas" || payload.campo === "vendaFornecedor"){
				state.Relatorios.paginacao = {
					totalPages: payload?.paginacao?.totalPages,
					page: payload?.paginacao?.page,
					totalElements: payload?.paginacao?.totalElements,
					size: payload?.paginacao?.size,
				};
				if(payload.totalizadores){
					state.Relatorios.totalRecebimentoContasReceber = payload?.totalizadores.totalRecebido || 0;
				}
			}
			if (payload.campo != "movimentoVendedor") {
				state.Relatorios[payload.campo] = payload.valor;
			} else {
				if (payload.valor.length > 0) {
					payload.valor.forEach((vendedor) => {
						if (!state.Relatorios[payload.campo]?.map(v => Object.keys(v)[0]).includes(vendedor.nome)){
							state.Relatorios[payload.campo].push({
								[vendedor.nome]: [{...vendedor}]
							});
						} else {
							state.Relatorios[payload.campo][state.Relatorios[payload.campo].map(v => Object.keys(v)[0]).indexOf(vendedor.nome)][vendedor.nome].push(vendedor);
						}
					});
				}
			}
		}
	}
};