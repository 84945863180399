<template>
	<ContainerPage>
		<ContentPage>
			<paginacao-filtro
				@filtrar="filtrar"
				:ControlePaginacao="CentroCusto.paginacao"
				:mostrarBotaoNovo="true"		
				@adicionarNovo="handleModalAddCentroCusto"
			>
				<template v-slot:filtro>
					<div class="filtro-content">
						<div class="column-filtro">
							<div class="row-filtro">
								<div class="input-group">
									<input
										type="text"
										placeholder=" "
										autocomplete="off"
										id="pesquisaLivre"
										v-model="Filtro.descricao"
									/>
									<label label="pesquisaLivre">
										Busca por descrição
									</label>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-slot:tabela>
					<div class="container-tabela" v-if="CentroCusto.Lista.length > 0">
						<div class="header-tabela">
							<div class="linha-header-tabela">
								<div class="campo-tabela campo-menor">
									<span>Ação</span>
								</div>
								<div class="campo-tabela campo-menor">
									<span>Código</span>
								</div>
								<div class="campo-tabela campo-medio">
									<span>Descrição</span>
								</div>
								<div class="campo-tabela campo-menor">
									<span>Ativo</span>
								</div>
							</div>
						</div>
						<div class="body-tabela">
							<div 
								class="linha-body-tabela"
								v-for="(cc, index) in CentroCusto.Lista"
								:key="index"
							>

								<div class="campo-tabela campo-menor">
									<i 
										class="fas fa-edit" 
										style="color: var(--primario)"  
										@click="handleEditCentroCusto(cc)"
									/>
								</div>
								<div class="campo-tabela campo-menor">
									{{ cc.cdcusto }}
								</div>
								<div class="campo-tabela campo-medio">
									{{ cc.descricao }}
								</div>
								<div class="campo-tabela campo-menor">
									<i 
										class="fas fa-check-circle" 
										v-if="cc.ativo === 'A'"
										style="color: var(--salvar-borda)"
									/>
									<i 
										class="fas fa-times-circle" 
										style="color: var(--cancelar-borda)"
										v-else
									/>
								</div>
							</div>
						</div>
					</div>
				</template>
			</paginacao-filtro>

			<Modal 
				v-if="adicionarCentroCusto" 
				@fecharModal="fecharModal"
				:idModal="'adicionar-centro-custo'"
				:dimensoes="isMobileDevice ? {width: '95%', height: '45vh'} : {width: '70%', height: 'auto'}"
			>
				<div class="container-formulario" v-if="!loadingForm">
					<div class="container-input">
						<div class="input-group small-input">
							<input 
								type="text" 
								v-model="form.cdcusto" 
								id="cdcentrocusto"
								autocomplete="off"
							/>
							<label for="cdcentrocusto">
								Cód. Centro Custo
							</label>
						</div>
						<div class="input-group group-descricao">
							<input 
								type="text" 
								v-model="form.descricao" 
								id="descricao"
								placeholder=" "
								autocomplete="off"
							/>
							<label for="descricao">
								Descrição
							</label>
						</div>
						<div class="input-group-switch">
							<label class="label-switch">Ativo</label>
							<label class="container">
								<input
									type="checkbox"
									v-model="form.ativo"
									class="esconder-input"
									id="esconder-input"
								/>
								<span class="thumb-container">
									<span class="thumb"></span>
								</span>
									<span class="track"></span>
							</label>
						</div>
					</div>
				</div>
				<div class="container-formulario container-loader" v-else>
					<Loader 
						:width="'125px'"
						:height="'125px'"
					/>
				</div>
				<div class="botoes">
					<button class="botao botao-cancelar" @click="fecharModal">
						Cancelar
					</button>
					<button class="botao botao-acao" @click="addCentroCusto">
						Salvar e fechar
					</button>
				</div>
				
			</Modal>
		</ContentPage>
	</ContainerPage>
</template>
<script>
import ContainerPage from "../components/ContainerPage.vue";
import ContentPage from "../components/ContentPage.vue";
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import Loader from "../components/Loader.vue";
import Modal from "../components/Modal.vue";
import { CentroCusto } from "../models/ContaPagar";

export default {
	name: "CentroCustos",
	components: {
		ContainerPage,
		ContentPage,
		Modal,
		PaginacaoFiltro,
		Loader
	},
	data() {
		return {
			form: new CentroCusto({
				ativo: true
			}),
			adicionarCentroCusto: false,
			Filtro: {
				descricao: ""
			},
			loadingForm: true,
		};
	},

	computed: {
		CentroCusto() {
			return this.$store.state.CentroCustos;
		},

		// VERIFICAR DEIVCE
		isMobileDevice() {
			const userAgent = navigator.userAgent;
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
		},
	},

	methods: {
		fecharModal() {
			this.adicionarCentroCusto = false,
			this.form = new CentroCusto({
				ativo: true
			});
			this.filtrar();
		},

		handleEditCentroCusto(centroCusto) {
			this.form = centroCusto.convert();
			this.form.editando = true;
			this.adicionarCentroCusto = true;
			setTimeout(() => {
				this.loadingForm = false;
			}, 250);
		},

		validarCampos() {
			try {
				if(!this.form.descricao) {
					throw new Error("Para cadastrar o centro de custo, é necessário informar uma descrição!");
				}
				return true;
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});

				return false;
			}
		},

		async addCentroCusto() {
			if(!this.validarCampos()) return;

			
			if(this.form.editando) {
				const form = new CentroCusto(this.form).convert();
				const res = await this.$store.dispatch("atualizarCentroCusto", form);
				this.fecharModal();
				if(!res) return;
				return;
			}

			const form = this.form.convert();
			const res = await this.$store.dispatch("adicionarCentroCusto", form);
			if(!res) return;
			this.fecharModal();
		},

		async filtrar() {
			await this.$store.dispatch("buscarCentroCusto", this.Filtro);
		},

		handleModalAddCentroCusto() {
			this.adicionarCentroCusto = true;
			this.loadingForm = true;
			setTimeout(() => {
				this.loadingForm = false;
			}, 250);
		},


	}
};
</script>
<style scoped>
.filtro-content {
	width: 100%;
}
.container-formulario{
	width: 100%;
	height: 25vh;
	overflow: auto;
	padding: 10px;
	border: solid 2px #cecece;
	display: flex;
	flex-direction: column;	
}
.container-formulario .container-input{
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: row;
	gap: 20px;
	border-bottom: 1px solid #cecece;
}

.container-loader {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.small-input {
	width: 25%;
}

.botoes{
	width: 100%;
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
}

.column-filtro {
	width: 55vw;
}
.row-filtro .input-group:focus-within label,
.row-filtro .input-group input:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}
.container-tabela{
	width: 75%;
	overflow: auto;
	height: 70vh;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 2px 5px;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}
.campo-tabela{
	width: 400px;
}
.campo-menor{
	width: 155px;
	text-align: center;
}
.campo-medio{
	width: 350px;
}
.group-descricao {
	width: 33vw;
}
.campo-tabela{
	font-size: 13px;
}
@media screen and (max-width: 425px) {
	.container-tabela {
		width: 98%;
	}

	.container-formulario {
		height: 35vh;
		margin-top: 5px;
	}
	.container-formulario .container-input{
		flex-wrap: wrap;
	}

	.container-input .input-group {
		width: 100%;
	}
}
</style>