<template>
	<div class="container-page">
		<paginacao-filtro
			@filtrar="filtrar"
			@limpar="limparFiltro"
			:mostrarBotaoNovo="mostrarBotaoNovo"
			@adicionarNovo="$emit('adicionarNovo')"
			@handleShowTable="handleShowTable"
			:ControlePaginacao="Paginacao"
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="input-group select-group">
								<select 
									v-model="tipoFiltro"
									id="tipoFiltro"
									@change="handleTipoFiltro"
								>
									<option disabled value="SEL">
										SELECIONE UMA OPÇÃO
									</option>
									<option v-for="(filtro, index) in Filtros" :key="index" :value="filtro">
										{{filtro}}
									</option>
								</select>
								<label for="tipoFiltro" style="background-color: #f8f8f8;">
									Opção Filtro
								</label>
							</div>
							<div class="input-group">
								<input 
									type="search" 
									id="textoPesquisa" 
									name="filtro" 
									placeholder=" " 
									autocomplete="off"
									v-model="filtroPesquisa"
									@keydown.enter.prevent="filtrar(null)"
								/>
									<label for="textoPesquisa" style="background-color: #f8f8f8;">
									Pesquisar
								</label>
							</div>
							<div class="checked">
								<div class="selecionar">
									<label for="js-checkbox-pesquisa" v-if="checkboxPesquisa" class="pointer">
										&nbsp;
										Ativos
									</label>
									<label for="js-checkbox-pesquisa" v-else class="pointer">
										&nbsp;
										Ambos
									</label>
									&nbsp;
									<input
										type="checkbox"
										id="js-checkbox-pesquisa"
										@change="checkboxPesquisa = !checkboxPesquisa"
										:checked="checkboxPesquisa"

									/>
									<label class="check" for="js-checkbox-pesquisa"></label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<tabela-pessoas 
					:tipoConteudo="'Vendedores'"
					:height="height?height:'60vh'"
					@selecionarPessoa="$emit('selecionarPessoa', $event)"
					:showTable="showTable"
				/>
			</template>
		</paginacao-filtro>
	</div>
</template>
<script>
import PaginacaoFiltro from "./PaginacaoFiltro.vue";
import TabelaPessoas from "./TabelaPessoas.vue";
export default {
	name: "ConsultaVendedores",
	components: {
		PaginacaoFiltro,
		TabelaPessoas
	},

	props: {
		mostrarBotaoNovo: Boolean,
		height: String,
	},

	data() {
		return {
			tipoFiltro: "TODOS",
			Filtros: ["TODOS", "NOME"],
			filtroPesquisa: "",
			checkboxPesquisa: true,
			showTable: false,
		};
	},

	methods: {
		limparFiltro() {
			this.tipoFiltro = "TODOS";
			this.filtroPesquisa = "";
			this.checkboxPesquisa = true;
			this.focarInputPesquisa();
			this.filtrar(null);
		},

		async filtrar(page) {
			const filtro = {
				page: page || 0,
				query: this.filtroPesquisa,
				checkboxPesquisa: this.checkboxPesquisa,
			};
			await this.$store.dispatch("filtrarVendedores", filtro);
		},

		handleTipoFiltro() {
			this.filtroPesquisa = "";
			this.focarInputPesquisa();
		},

		focarInputPesquisa() {
			setTimeout(() => {
				document.getElementById("textoPesquisa").focus();
			}, 50);
		},

		handleShowTable() {
			this.showTable = !this.showTable;
		}
	},

	computed: {
		Paginacao() {
			return {
				paginaAtual: this.$store.state.paginaVendedoresAtual,
				totalPaginas: this.$store.state.totalPaginasVendedores
			};
		}
	},

	mounted() {
		this.filtrar();
	}
};
</script>
<style scoped>
.container-page {
	width: 100%;
	overflow: hidden;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;

}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}


#textoPesquisa{
	width: 65vh;
}


.checked {
    display: flex;
    align-content: center;
    justify-content: center;
}
.checked .selecionar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.check {
	display: block;
	position: relative;
	width: 60px;
	height: 30px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}


.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}
input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}

@media screen and (max-width: 769px) {

	#textoPesquisa{
		width: 42vh;
	}
}
</style>