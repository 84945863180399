<template>
	<div :class="`MontarSubG ${!showTable? 'table-full' : ''}`" id="listaModal" v-if="Grupos.length" :style="`height: ${tableHeight}`">
		<div class="tabela-scroll">
			<table>
				<thead>
					<tr>
						<td style="color: blue; text-align: center; width :95px">Cód.</td>
						<td class="text-left">Grupo</td>
						<td class="text-center">Tipo</td>
						<td class="text-center" v-if="!desabilitarAcao">Ação</td>
					</tr>
				</thead>
				<tbody>
					<tr class="itemLista" v-for="(grupos, index) in Grupos" :key="index">
						<td style="color: blue; text-align: center;" @click="selecionarGrupos(grupos, index)">
							<button :id="index" class="hand-button" v-if="index == indexAtualGrupo" @keydown.tab.prevent="" ><i class="fas fa-hand-point-right" style="color: var(--primario)" ></i></button>
							{{grupos.cdGrupo}}
						</td>
						<td class="text-left text-nowrap" @click="selecionarGrupos(grupos, index)">{{grupos.descricao}}</td>
						<td class="text-center" @click="selecionarGrupos(grupos, index)">{{grupos.tipo == "P" ? "PRODUTOS" : grupos.tipo == "S" ? "SERVIÇOS" : ""}}</td>
						<td class="text-center" v-if="!desabilitarAcao">
							<i class="fas fa-pencil-alt" style="color: var(--confirmar)" @click="editarGrupo(grupos)" 
							@keydown.space="editarGrupo(grupos)"></i>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="tabela-scroll">
			<table v-if="Grupos[indexAtualGrupo].subGrupos.length>0">
				<thead>
					<tr>
						<td style="color: blue; text-align: center; width :95px">Cód.</td>
						<td class="text-left">Subgrupo</td>
					</tr>
				</thead>
				<tbody>
					<tr class="itemLista" v-for="(subGrupo, index) in Grupos[indexAtualGrupo].subGrupos" :key="index" @click="selecionarsubGrupos(Grupos[indexAtualGrupo].cdGrupo, subGrupo.cdsubgrupo)">
						<td style="color: blue; text-align: center;">
							<button :id="index" class="hand-button" v-if="index == indexAtualSubGrupo" @keydown.tab.prevent="" ><i class="fas fa-hand-point-right" style="color: var(--primario)" ></i></button>
							{{subGrupo.cdsubgrupo}}
						</td>
						<td class="text-left text-nowrap">{{subGrupo.descricao}}</td>
					</tr>
					<tr class="itemLista" @click="selecionarsubGrupos(Grupos[indexAtualGrupo].cdGrupo, null)">
						<td style="color: blue; text-align: center;">N/A</td>
						<td>SEM SUBGRUPO</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div v-else class="col center w-100">
		<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt" @click="fecharModal"></i>
		<h1>Grupo não encontrado no banco de dados da loja.</h1>
		<h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
	</div>
</template>

<script>
export default {
	name: "TabelaGrupoProduto",

	props: {
		tipoConteudo: String,
		desabilitarAcao: Boolean,
		permiteEditar: Boolean,
		showTable: Boolean,
		tableHeight: String,
	},

	computed: {
		Grupos() {
			return this.$store.state.grupoProduto;
		}
	},

	data() {
		return {
			indexAtualGrupo: 0,
			indexAtualSubGrupo: 0,
			tipoIndex: "Grupo"
		};
	},

	methods: {
		selecionarBandeira(bandeira){
			return this.$emit("selecionarBandeira", bandeira);
		},

		fecharModal(){
			this.$emit("fecharModal");
		},
		
		selecionarGrupos(grupos, indice){
			if (!grupos.subGrupos.length){
				return this.$emit("selecionarGrupos", {grupo: grupos.cdGrupo, subgrupo: null});
			}
			this.indexAtualGrupo = indice;
		},

		selecionarsubGrupos(grupo, subgrupo){
			this.$emit("selecionarGrupos", {grupo, subgrupo});
		},

		editarGrupo(grupos){
			if (this.permiteEditar) {
				this.$emit("editarGrupo", {grupo:grupos.cdGrupo, subgrupo:null});
			} else {
				this.$emit("selecionarGrupos", {grupo:grupos.cdGrupo, subgrupo:null});
			}
		},

		eventosTeclado(e) {
			if (e.key == "ArrowRight") {
				if (!this.Grupos[this.indexAtualGrupo].subGrupos.length) return;
				this.tipoIndex = "SubGrupo";
				setTimeout(() => {
					document.getElementById(this[`indexAtual${this.tipoIndex}`]).focus();
				}, 10);
			}
			if (e.key == "ArrowLeft") {
				this.tipoIndex = "Grupo";
				setTimeout(() => {
					document.getElementById(this[`indexAtual${this.tipoIndex}`]).focus();
				}, 10);
			}
			if (e.key == "ArrowUp") {
				if (this.tipoIndex == "Grupo") {
					this.indexAtualSubGrupo = 0;
				}
				if (this[`indexAtual${this.tipoIndex}`] > 0) {
					this[`indexAtual${this.tipoIndex}`]--;
				} else if (this.tipoIndex == "SubGrupo") {
					this.tipoIndex = "Grupo";
				}
				setTimeout(() => {
					document.getElementById(this[`indexAtual${this.tipoIndex}`]).focus();
				}, 10);
			}
			if (e.key == "ArrowDown") {
				if (this.tipoIndex == "Grupo") {
					if (this[`indexAtual${this.tipoIndex}`] < this.Grupos.length -1) {
						this[`indexAtual${this.tipoIndex}`]++;
					}
					this.indexAtualSubGrupo = 0;
				} else if (this.tipoIndex == "SubGrupo") {
					if (this[`indexAtual${this.tipoIndex}`] < this.Grupos[this.indexAtualGrupo].subGrupos.length -1) {
						this[`indexAtual${this.tipoIndex}`]++;
					} else {
						this.tipoIndex = "Grupo";
						setTimeout(() => {
							document.getElementById(this[`indexAtual${this.tipoIndex}`]).focus();
						}, 10);
					}
				}
				setTimeout(() => {
					document.getElementById(this[`indexAtual${this.tipoIndex}`]).focus();
				}, 10);
			}
			if (e.key == "Enter") {
				if(e.target.id === "textoPesquisa")	return;
				this.selecionarGrupos(this.Grupos[this.indexAtualGrupo], this.indexAtualGrupo);
			}
			if (e.key == "Tab") {
				this[`indexAtual${this.tipoIndex}`] = 0;
				setTimeout(() => {
					document.getElementById("textoPesquisa").focus();
				}, 50);
			}
		},
		adicionarClassSlot() {
			document.querySelector("div.col.between.w-100")?.children.forEach(element => {
				if (element?.classList.contains("slot")) {
					element.classList.add("no-slot");
				}
			});
		},
		removerClassSlot() {
			document.querySelector("div.no-slot")?.classList.remove("no-slot");
		}
	},

	beforeMount() {
		this.$store.dispatch("buscarGrupos");
	},

	mounted(){
		document.addEventListener("keydown", this.eventosTeclado);
		this.adicionarClassSlot();
		this.indexAtualGrupo = 0;
	},

	destroyed() {
		document.removeEventListener("keydown",this.eventosTeclado);
		this.removerClassSlot();
	}
};
</script>

<style scoped>
#listaModal {
	margin: 0px auto;
	font-size: var(--txt-normal);
}

label {
	cursor: pointer;
}

.hand-button i::before {
	color: rgba(0, 0, 0, 0.6);
}
.hand-button:focus i::before {
	color: var(--primario);
}

.MontarSubG {
	display: flex;
	justify-content: space-around;
	overflow: hidden;
	width: 100%;
}
#listaModal{
	width: 95%;
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;
}
#listaModal.table-full{
	height: 77vh !important;
}

table {
	height: max-content;
	width: 100%;
}

.itemLista {
	height: auto;
	max-height: 30px;
}
.tabela-scroll {
	display: flex;
	overflow: auto;
	height: 100%;
}

@media screen and (max-width: 768px) {
	#listaModal.table-full{
		height: 68vh !important;
	}
	#listaModal{
		height: 43vh !important;
	}
}

@media screen and (max-width: 425px) {
	#listaModal.table-full{
		height: 71vh !important;
	}
	#listaModal{
		height: 30vh !important;
	}
}
</style>