<template>
    <div class="gerar-variacao-controle" v-if="!geraVariacaoCompra || produtosAgrupados.length === 0">
		<div class="cadastro-produto-cabecalho" >
			<h3 style="color: var(--primario);">
				{{produtoPai ? produtoPai.id : ""}}
				&nbsp; - &nbsp;
				{{produtoPai ? produtoPai.descricao : ""}}
			</h3>
			<h3 style="color: var(--primario);">
				Quantidade a distribuir: {{ (saldoProdPai || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}
			</h3>
		</div>
        <div class="variacao-controle">
			<div class="box-variacao">
                <div class="row w-100">
					<div class="input-group">
						<input id="variacao" type="text" @keydown.enter="IncluirVariacao" v-model="variacao" autocomplete="off" @input="mascarauppercase">
						<label for="variacao" style="background-color: #dddddd;">
							Variação
						</label>
					</div>
                    <i class="far fa-plus-square icones plus" style="color:#000"  @click="IncluirVariacao"></i>
                </div>
				<div class="row w-100">
					<div class="input-group" style="margin-top: 5px; width: 100%;">
						<select @change="selecionarGrade(gradeSelecionada)" v-model="gradeSelecionada" class="row w-100">
							<option :value="{novo: true}">Selecionar</option>
							<option v-for="grade in grades" :key="grade.codigo" :value="grade">{{grade.identificacao}}</option>
						</select>
					</div>
                    <i v-if="gradeSelecionada.novo" class="far fa-plus-square icones"  style="color: var(--confirmar)" @click="abrirModal = true"></i>
                    <i v-else class="fas fa-pencil-alt" style="color :var(--primario)" @click="editarVariacao"></i>
                </div>
                <div class="drop-down">
                    <div class="drop-down-header">
                        <i class="fas fa-caret-right drop-down-icon active" @click="abrirDropDown"></i>
                        <i class="fas fa-sort-down drop-down-icon" @click="abrirDropDown"></i>
                        <span class="drop-down-title" @click="abrirDropDown">Configurações</span>
                    </div>
                    <div class="drop-down-content">
                        <div class="row w-100">
                            <button class="botao botao-acao" @click="replicarSelecionado" style="width: 100%;">Replicar selecionado</button>
                        </div>
                    </div>
                </div>
                <div class="linha"  v-for="(variacao, index) in Object.keys(vetorVariacoes)" :key="variacao" id="variacaoLinha">
                    <div class="variacao-item" :style="`${index % 2 > 0 ? 'background-color : #dddddd' : 'background-color : #fff'}`">
						<div class="container-linha">
							<input type="checkbox" :id="`${variacao}-checkbox`" @change="selecionarVariacao(variacao)" class="pointer mx-5">
							<label :for="`${variacao}-checkbox`" class="pointer">{{variacao}}</label>
						</div>
                        <input
							type="text"
							:id="variacao"
							class="input-quantidade"
							@input="recalcularGrades(variacao)"
							v-mascaraInteger
							@focus="selecionarVariacao(variacao)"
							@keydown.enter="proximoCampo('grade')"
							v-model="vetorVariacoes[variacao].quantidade"
						>
                    </div>
                    <i class="fas fa-pencil-alt icones" style="color: var(--primario)" @click="alterarVariacao({...vetorVariacoes[variacao], identificacao: variacao})"></i>
                    <i class="fas fa-trash-alt icones" style="color: var(--deletar)" @click="removerVariacao(variacao)"></i>
                </div>
            </div>
            <div class="box-variacao" v-if="vetorVariacoes[variacaoSelecionada]">
                <div class="row w-100">
                    <label for="grade">Grade:</label>
                    <input id="grade" type="text" @keydown.enter="IncluirGrade" v-model="grade" autocomplete="off" @input="mascarauppercase">
                    <i class="far fa-plus-square icones plus"  style="color:#000"  @click="IncluirGrade"></i>
                </div>
                <div class="linha" v-for="(grade, index) in vetorVariacoes[variacaoSelecionada].grades" :key="index">
                    <div class="variacao-item" :style="`${index % 2 > 0 ? 'background-color : #dddddd' : 'background-color : #fff'}`">
                        <label :for="`${index}-${grade.grade}`" class="pointer mx-5">{{grade.grade}}</label>
                        <input
							type="number"
							ref="inputQtdGrade"
							:id="`${index}-${grade.grade}`"
							v-mascaraInteger
							@input="alterouQuantidadeVariacao(index)"
							@focusout="recalcularInput(grade)"
							@focus="e => e.target.select()"
							v-model="grade.quantidade"
							class="input-quantidade"
							@keydown.enter="proximoCampoGrade(index)"
						>
                    </div>
                    <i class="fas fa-pencil-alt icones" style="color :var(--primario)" @click="alterarGrade(index)"></i>
                    <i class="fas fa-trash-alt icones" style="color: var(--deletar)" @click="removerGrade(index)"></i>
                </div>
            </div>
        </div>
        <div class="botoes">
            <button class="botao botao-cancelar" @click="cancelarVariacao">
                <i class="fas fa-times" style="color:var(--texto-cancelar)"></i>
                Cancelar
                <span>[Alt+c]</span>
            </button>
            <button class="botao botao-acao"  @click="LimparVariacao">
                <i class="fab fa-digital-ocean" style="color:var(--texto-acao)"></i>
                Limpar
                <span>[Alt+L]</span>
            </button>
            <button class="botao botao-adicionar" @click="GerarVariacao">
                <i class="fa fa-check" style="color:var(--texto-adicionar)"></i>
                Gerar
                <span>[Alt+s]</span>
            </button>
        </div>
		<Modal 
			v-if="abrirModal" 
			:dimensoes="{
				width: 'auto',
				height: 'auto'
			}" 
			@fecharModal="abrirModal = false"
			:idModal="'cadastro-grade'"
		>
            <CadastroGrade
				v-on:excluirVariacao="removerVariacao"
				v-bind:GradeAtualiza="gradeSelecionada"
				v-on:fecharModal="abrirModal = false"
				v-on:inserirGrade="atualizaVariacao"
			/>
        </Modal>
    </div>
	<div
		v-else
		class="gera-variacao-compra-container" 
	>
		
		<div class="gera-variacao-compra-content"> 
			<div class="container-titulo-produto-compra">
				<h3 style="color: var(--primario);">
					{{produtoPai ? produtoPai.id : ""}}
					&nbsp; - &nbsp;
					{{produtoPai ? produtoPai.descricao : ""}}
				</h3>
				<h3 style="color: var(--primario);">
					Quantidade a distribuir: {{ (saldoProdPai || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}
				</h3>
			</div>
			<div class="variacao-controle-compra">
				<div class="tabela-nota-container">
					<table>
						<thead>
							<tr>
								<th>Seq. nota</th>
								<th>Referência</th>
								<th class="align-left">Descrição</th>
								<th>Qtd.</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(prod, index) in produtosAgrupados" :key="index">
								<td>
									{{ prod.cdseq }}
								</td>
								<td>
									{{ prod.cdreferencia }}
								</td>
								<td class="align-left">
									{{ prod.descricao }}
								</td>
								<td>
									{{ prod.quantidade }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="box-variacao">
					<div class="row w-100">
						<div class="input-group">
							<input id="variacao" type="text" @keydown.enter="IncluirVariacao" v-model="variacao" autocomplete="off" @input="mascarauppercase">
							<label for="variacao" style="background-color: #dddddd;">
								Variação
							</label>
						</div>
						<i class="far fa-plus-square icones plus" style="color:#000"  @click="IncluirVariacao"></i>
					</div>
					<div class="row w-100">
						<div class="input-group" style="margin-top: 5px; width: 100%;">
							<select @change="selecionarGrade(gradeSelecionada)" v-model="gradeSelecionada" class="row w-100">
								<option :value="{novo: true}">Selecionar</option>
								<option v-for="grade in grades" :key="grade.codigo" :value="grade">{{grade.identificacao}}</option>
							</select>
						</div>
						<i v-if="gradeSelecionada.novo" class="far fa-plus-square icones"  style="color: var(--confirmar)" @click="abrirModal = true"></i>
						<i v-else class="fas fa-pencil-alt" style="color :var(--primario)" @click="editarVariacao"></i>
					</div>
					<div class="drop-down">
						<div class="drop-down-header">
							<i class="fas fa-caret-right drop-down-icon active" @click="abrirDropDown"></i>
							<i class="fas fa-sort-down drop-down-icon" @click="abrirDropDown"></i>
							<span class="drop-down-title" @click="abrirDropDown">Configurações</span>
						</div>
						<div class="drop-down-content">
							<div class="row w-100">
								<button class="botao botao-acao" @click="replicarSelecionado" style="width: 100%;">Replicar selecionado</button>
							</div>
						</div>
					</div>
					<div class="linha"  v-for="(variacao, index) in Object.keys(vetorVariacoes)" :key="variacao" id="variacaoLinha">
						<div class="variacao-item" :style="`${index % 2 > 0 ? 'background-color : #dddddd' : 'background-color : #fff'}`">
							<div class="container-linha">
								<input type="checkbox" :id="`${variacao}-checkbox`" @change="selecionarVariacao(variacao)" class="pointer mx-5">
								<label :for="`${variacao}-checkbox`" class="pointer">{{variacao}}</label>
							</div>
							<input
								type="text"
								:id="variacao"
								class="input-quantidade"
								@input="recalcularGrades(variacao)"
								v-mascaraInteger
								@focus="selecionarVariacao(variacao)"
								@keydown.enter="proximoCampo('grade')"
								v-model="vetorVariacoes[variacao].quantidade"
							>
						</div>
						<i class="fas fa-pencil-alt icones" style="color: var(--primario)" @click="alterarVariacao({...vetorVariacoes[variacao], identificacao: variacao})"></i>
						<i class="fas fa-trash-alt icones" style="color: var(--deletar)" @click="removerVariacao(variacao)"></i>
					</div>
				</div>
				<div class="box-variacao" v-if="vetorVariacoes[variacaoSelecionada]">
					<div class="row w-100">
						<label for="grade">Grade:</label>
						<input id="grade" type="text" @keydown.enter="IncluirGrade" v-model="grade" autocomplete="off" @input="mascarauppercase">
						<i class="far fa-plus-square icones plus"  style="color:#000"  @click="IncluirGrade"></i>
					</div>
					<div class="linha" v-for="(grade, index) in vetorVariacoes[variacaoSelecionada].grades" :key="index">
						<div class="variacao-item" :style="`${index % 2 > 0 ? 'background-color : #dddddd' : 'background-color : #fff'}`">
							<label :for="`${index}-${grade.grade}`" class="pointer mx-5">{{grade.grade}}</label>
							<input
								type="number"
								ref="inputQtdGrade"
								:id="`${index}-${grade.grade}`"
								v-mascaraInteger
								@input="alterouQuantidadeVariacao(index)"
								@focusout="recalcularInput(grade)"
								@focus="e => e.target.select()"
								v-model="grade.quantidade"
								class="input-quantidade"
								@keydown.enter="proximoCampoGrade(index)"
							>
						</div>
						<i class="fas fa-pencil-alt icones" style="color :var(--primario)" @click="alterarGrade(index)"></i>
						<i class="fas fa-trash-alt icones" style="color: var(--deletar)" @click="removerGrade(index)"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="botoes">
			<button class="botao botao-cancelar" @click="cancelarVariacao">
				<i class="fas fa-times" style="color:var(--texto-cancelar)"></i>
				Cancelar
				<span>[Alt+c]</span>
			</button>
			<button class="botao botao-acao"  @click="LimparVariacao">
				<i class="fab fa-digital-ocean" style="color:var(--texto-acao)"></i>
				Limpar
				<span>[Alt+L]</span>
			</button>
			<button class="botao botao-adicionar" @click="GerarVariacao">
				<i class="fa fa-check" style="color:var(--texto-adicionar)"></i>
				Gerar
				<span>[Alt+s]</span>
			</button>
		</div>
	</div>
</template>
<script>
import Modal from "./Modal.vue";
import CadastroGrade from "./CadastroGrade.vue";
import { FormacaoPreco } from "../models/Produto.js";
export default {
	name: "GeraVariacao",

	props: {
		produtoPai: Object,
		geraVariacaoCompra: Boolean,
		produtosAgrupados: Array
	},

	components: {
		Modal,
		CadastroGrade
	},

	computed: {
		ProdutoFilhos(){
			return this.$store.state.produtoFilhos;
		},
		grades(){
			return this.$store.state.Grades;
		},
		saldoPai () {
			return this.geraVariacaoCompra ? this.produtoPai.quantidade || this.produtoPai.saldo : (this.produtoPai.saldo || this.produtoPai.quantidade);
		},
		saldoProdPai() {
			let qtdAtual = this.geraVariacaoCompra ? (this.produtoPai.quantidade || this.produtoPai.saldo) : (this.produtoPai.saldo || this.produtoPai.quantidade);
			qtdAtual -= Number(this.qtdVariacao || 0);
			return Number(qtdAtual);
		},
	},

	data() {
		return {
			variacao: "",
			alterandoVariacao: null,
			variacaoSelecionada: null,
			vetorVariacoes: {},
			grade: "",
			alterandoGradeIndex: -1,
			timer: null,
			gradeSelecionada: {novo: true},
			abrirModal: false,
			listagrade: [],
			qtdVariacao: 0,
		};
	},

	methods: {
		editarVariacao(){
			this.abrirModal = true;
		},
		alterouQuantidadeVariacao (index) {
			let qtd = 0;
			Object.keys(this.vetorVariacoes).forEach(variacao => {
				this.vetorVariacoes[variacao].grades.forEach(grade => {
					qtd += Number(grade.quantidade || 0);
				});
			});
			if (qtd > this.saldoPai) {
				setTimeout(() => {
					this.vetorVariacoes[this.variacaoSelecionada].grades[index].quantidade = "0";
					this.$forceUpdate();
					this.$store.commit("relatarErro",{
						mensagem: `Você digitou na grade ${this.vetorVariacoes[this.variacaoSelecionada].grades[index].grade} um valor que resulta em variações maiores que o saldo do produto pai!`,
						alerta: true ,
					});
					this.recalcularVariacao();
				}, 10);
			} else {
				this.recalcularVariacao();
			}
		},

		recalcularInput(grade) {
			if(!grade.quantidade){
				grade.quantidade = "0";
				this.recalcularVariacao();
				this.$forceUpdate();
			}
		},
		replicarSelecionado() {
			Object.keys(this.vetorVariacoes).filter(variacao => variacao != this.variacaoSelecionada).forEach(variacao => {
				if((this.vetorVariacoes[variacao]?.quantidade + this.vetorVariacoes[this.variacaoSelecionada].quantidade) * Object.keys(this.vetorVariacoes).length > this.saldoPai){
					return this.$store.commit("relatarErro",{ mensagem: "Quantidade maior que o saldo do pai!", alerta: true });
				} else {
					this.vetorVariacoes[variacao].quantidade = this.vetorVariacoes[this.variacaoSelecionada].quantidade;
					this.vetorVariacoes[variacao].grades = [];
					this.vetorVariacoes[this.variacaoSelecionada].grades.forEach(grade => {
						this.vetorVariacoes[variacao].grades.push({...grade});
					});
				}
			});
			this.calcularSaldoPai();
			this.$forceUpdate();
		},
		selecionarVariacao(variacao) {
			const input = document.getElementById(`${variacao}`);
			if(input){
				input.select();
			}
			document.querySelectorAll("input[type='checkbox'].pointer").forEach(input => input.checked = false);
			this.variacaoSelecionada = variacao;
			if(!this.gradeSelecionada.novo && this.vetorVariacoes[this.variacaoSelecionada].codigo){
				if (this.vetorVariacoes[this.variacaoSelecionada].codigo != this.gradeSelecionada.codigo) {
					this.gradeSelecionada = this.grades.find(grade => grade.codigo == this.vetorVariacoes[this.variacaoSelecionada].codigo);
				}
			}
			setTimeout(() => {
				document.getElementById(`${variacao}-checkbox`).checked = true;
			}, 10);
			this.variacao = "";
		},
		IncluirVariacao() {
			if (!this.variacao) return;
			if (this.alterandoVariacao) {
				delete this.vetorVariacoes[this.alterandoVariacao];
				this.vetorVariacoes[this.variacao] = {
					quantidade: this.novaGrade.quantidade,
					grades: this.novaGrade.grades,
					codigo: this.novaGrade.codigo
				};
				this.alterandoVariacao = null;
				this.gradeSelecionada = {novo: true};
				return this.selecionarVariacao(this.variacao);
			} else {
				const existe = Object.keys(this.vetorVariacoes).find(varia => varia == this.variacao);
				if (existe) {
					return this.$store.commit("relatarErro",{
						mensagem: "Esta variação já existe, verifique!",
						alerta: true,
					});
				}
				if (this.saldoProdPai <= 0) {
					return this.$store.commit("relatarErro",{
						mensagem: "Saldo do produto pai não permite incluir mais variações!",
						alerta: true,
					});
				}
				this.incluir = true;
				this.vetorVariacoes[this.variacao] = {
					quantidade: "0",
					grades: []
				};
				this.proximoCampo("grade");
			}
			this.selecionarVariacao(this.variacao);
			if(!this.gradeSelecionada.novo){
				this.preencherGradeSelecionada();
			}
			this.variacao = "";
			this.$forceUpdate();
		},

		alterarVariacao(variacao) {

			this.variacao = variacao.identificacao;
			this.alterandoVariacao = variacao.identificacao;
			this.novaGrade = {
				...variacao
			};
			const grade = this.grades.find(grade => grade.codigo == variacao.codigo);
			if(grade){
				this.gradeSelecionada = {
					...grade
				};
			}
			this.proximoCampo("variacao");
			this.$forceUpdate();
		},
		atualizaVariacao (novaVariacao) {
			novaVariacao.grades.forEach(grade => {
				if(!grade.quantidade){
					grade.quantidade = "0";
				}
			});
			this.vetorVariacoes[novaVariacao.identificacao] = {
				codigo: novaVariacao.codigo,
				grades: novaVariacao.grades,
				quantidade: "0",
			};

			this.selecionarVariacao(novaVariacao.identificacao);
			this.$forceUpdate();
		},
		removerVariacao(variacao) {
			let variacaoAnterior = null;
			let passouVariacao = false;
			Object.keys(this.vetorVariacoes).forEach(key => {
				if (passouVariacao) return;
				if (key === variacao) {
					passouVariacao = true;
					return;
				}
				variacaoAnterior = key;
			});
			delete this.vetorVariacoes[variacao];
			if (variacaoAnterior != null) {
				this.selecionarVariacao(variacaoAnterior);
			} else {
				this.variacaoSelecionada = null;
			}
			
			if (!this.variacaoSelecionada){
				this.gradeSelecionada = {novo: true};
			}
			this.alterandoVariacao = null;
			this.calcularSaldoPai();
			this.proximoCampo("variacao");
			this.$forceUpdate();
		},
		preencherGradeSelecionada() {
			this.gradeSelecionada.grades.forEach(grade => {
				this.preencherVariacao({
					grade: grade.grade,
					quantidade: "0",
				});
			});
			this.vetorVariacoes[this.variacaoSelecionada].codigo = this.gradeSelecionada.codigo;
			this.$forceUpdate();
		},
		selecionarGrade(grade) {
			if(grade == null) return;
			if (grade) {
				this.gradeSelecionada = grade;
			}

			this.vetorVariacoes[grade.identificacao] = {
				quantidade: "0",
				grades: [...grade.grades],
				codigo: grade.codigo
			};
			this.selecionarVariacao(grade.identificacao);

			if (this.gradeSelecionada.codigo >= 0) {
				this.vetorVariacoes[this.variacaoSelecionada].grades = [];
				this.preencherGradeSelecionada();
			} else {
				this.vetorVariacoes[this.variacaoSelecionada].grades = [];
				this.vetorVariacoes[this.variacaoSelecionada].quantidade = "0";
			}
			this.gradeSelecionada = { novo: true };
			this.$forceUpdate();
		},
		preencherVariacao(grade){
			this.vetorVariacoes[this.variacaoSelecionada].grades.push(grade);
			this.recalcularVariacao();
		},
		recalcularVariacao (param) {
			if(this.vetorVariacoes[this.variacaoSelecionada] && this.variacaoSelecionada){
				let qtd = 0;
				this.vetorVariacoes[this.variacaoSelecionada].grades.forEach(grade => {
					qtd += Number(grade.quantidade);
				});
				this.vetorVariacoes[this.variacaoSelecionada].quantidade = Number(qtd);
			}
			if (param) {
				if(this.alterandoVariacao){
					this.IncluirVariacao();
				}
			}
			this.calcularSaldoPai();
			this.$forceUpdate();
		},
		calcularSaldoPai() {
			let quant = 0;
			Object.keys(this.vetorVariacoes).forEach(variacao => {
				quant += Number(this.vetorVariacoes[variacao].quantidade || 0);
			});
			this.qtdVariacao = Number(quant);
			this.$forceUpdate();

			// if(param){
			// 	if(param.del){
			// 		return;
			// 	}
			// 	if(param?.block){
			// 		this.grade = "";
			// 		return this.$store.commit("relatarErro", {mensagem: "Saldo em estoque insuficiente, por favor verifique!", alerta: true});
			// 	}
			// }
			// if (quant > this.saldoProdPai){
			// 	let qtd = 0;
			// 	this.vetorVariacoes[this.variacaoSelecionada].grades.forEach(grade => {
			// 		grade.quantidade = 0;
			// 		qtd += Number(grade.quantidade);
			// 	});
			// 	this.vetorVariacoes[this.variacaoSelecionada].quantidade = Number(qtd);
			// 	this.qtdVariacao = Number(qtd);
			// 	return this.$store.commit("relatarErro", {mensagem: "Saldo em estoque insuficiente, por favor verifique", alerta: true});
			// }
		},
		recalcularGrades(variacao) {
			let qtd = 0;
			Object.keys(this.vetorVariacoes).forEach(key => {
				qtd += Number(this.vetorVariacoes[key].quantidade || 0);
				// if (key !== variacao) {
				// }
			});
			if(qtd == 0 && this.vetorVariacoes[variacao].quantidade.length == 0) {
				setTimeout( () => {
					this.vetorVariacoes[variacao].quantidade = 0;
					this.calcularSaldoPai();
				}, 400);
			}
			if(qtd > this.saldoPai){
				this.$store.commit("relatarErro", {
					mensagem: "Você está tentando gerar variações com saldo maior que o produto pai, verifique!",
					alerta: true,
				});
				return setTimeout(() => {
					let qtd = 0;
					this.vetorVariacoes[this.variacaoSelecionada].grades.forEach(grade => {
						qtd += Number(grade.quantidade || 0);
					});
					this.vetorVariacoes[this.variacaoSelecionada].quantidade = qtd.toString();
					this.calcularSaldoPai();
					this.$forceUpdate();
				}, 50);
			}
			clearTimeout(this.timer);
			this.calcularSaldoPai();
			if (!this.vetorVariacoes[this.variacaoSelecionada].grades.length) return;
			this.timer = setTimeout(() => {
				let quantidadeVariacoes = Number(this.vetorVariacoes[this.variacaoSelecionada].quantidade);
				this.vetorVariacoes[this.variacaoSelecionada].grades.forEach((grade) => {
					grade.quantidade = 0;
				});
				let i = 0;
				while (quantidadeVariacoes > 0) {
					this.vetorVariacoes[this.variacaoSelecionada].grades[i].quantidade = (Number(this.vetorVariacoes[this.variacaoSelecionada].grades[i].quantidade) + 1).toString();
					if ((i + 1) == this.vetorVariacoes[this.variacaoSelecionada].grades.length) {
						i = 0;
					} else {
						i++;
					}
					quantidadeVariacoes -= 1;
				}
				this.calcularSaldoPai();
				this.timer = null;
				this.$forceUpdate();
			},420);
		},
		IncluirGrade() {
			if ((this.saldoProdPai || 0) < 1) {
				this.$store.commit("relatarErro", {
					mensagem: "Saldo do produto pai não permite incluir mais grades!",
					alerta: true,
				});
				this.calcularSaldoPai();
				return;
			}
			if (!this.grade) return this.proximoCampoGrade(-1);
			if (!this.variacaoSelecionada) {
				return this.$store.commit("relatarErro", {mensagem: "Favor selecionar uma variação!"});
			}
			if (this.alterandoGradeIndex >= 0) {
				if(!this.vetorVariacoes[this.variacaoSelecionada].grades[this.alterandoGradeIndex]){
					this.vetorVariacoes[this.variacaoSelecionada].grades.push({
						grade: this.grade,
						quantidade: "1",
					});
				}
				
				const existe = this.vetorVariacoes[this.variacaoSelecionada].grades.find(grade => grade.grade === this.grade);
				if(existe) {
					return this.$store.commit("relatarErro", {
						mensagem: "Já existe uma grade com essa descrição, favor verificar"
					});
				}
				this.vetorVariacoes[this.variacaoSelecionada].grades[this.alterandoGradeIndex].grade = this.grade;
				this.proximoCampo(`${this.alterandoGradeIndex}-${this.grade}`);
			} else {
				const existe = this.vetorVariacoes[this.variacaoSelecionada].grades.find(item => item.grade == this.grade);
				if (existe) {
					return this.$store.commit("relatarErro",{mensagem: "Esta grade já existe, verifique!."});
				}
				this.vetorVariacoes[this.variacaoSelecionada].grades.push({
					grade: this.grade,
					quantidade: "1",
				});
				this.proximoCampo(`${this.vetorVariacoes[this.variacaoSelecionada].grades.length-1}-${this.grade}`);
			}
			this.recalcularVariacao();
			this.grade = "";
			this.alterandoGradeIndex = -1;
			this.$forceUpdate();
		},

		alterarGrade(index) {
			if(!this.vetorVariacoes[this.variacaoSelecionada].grades[index].grade){
				return;
			}
			this.alterandoGradeIndex = index;
			this.grade = this.vetorVariacoes[this.variacaoSelecionada].grades[index].grade;
			this.proximoCampo("grade");
		},

		proximoCampoGrade (index) {
			if (index +1 < this.vetorVariacoes[this.variacaoSelecionada].grades.length) {
				this.proximoCampo(`${index+1}-${this.vetorVariacoes[this.variacaoSelecionada].grades[index+1].grade}`);
			} else {
				this.proximoCampo("grade");
			}
		},

		proximoCampo(id) {
			setTimeout(() => {
				const input = document.getElementById(id);
				if (input) {
					input.focus();
					if (input.select) input.select();
				}
			},10);
		},

		mascarauppercase(evento){
			const id = evento.target.id;
			const input = document.getElementById(id);
			if (!input) return console.log("Id não encontrado");
			this[id] = this[id].toUpperCase();
		},

		mascaraInteger(e) {
			const regex = /[0-9]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
		},
		mascaraIntegerGrade(evento) {
			const id = evento.target.id;
			const index = id.split("-")[0];
			const regex = /[0-9]/;
			if (!regex.test(evento.data) && evento.data) {
				const novoValor = this.vetorVariacoes[this.variacaoSelecionada].grades[index].quantidade.slice(0, this.vetorVariacoes[this.variacaoSelecionada].grades[index].quantidade.length - 1);
				delete this.vetorVariacoes[this.variacaoSelecionada].grades[index].quantidade;
				return this.vetorVariacoes[this.variacaoSelecionada].grades[index].quantidade = novoValor;
			}
			this.recalcularVariacao();
		},
		cancelarVariacao() {
			this.$emit("cancelarVariacao");
		},
		LimparVariacao() {
			this.variacao = "";
			this.alterandoVariacao = null;
			this.variacaoSelecionada = null;
			this.vetorVariacoes = {};
			this.grade = "";
			this.qtdVariacao = 0;
			this.alterandoGradeIndex = -1;
			this.$emit("LimparVariacao");
			this.$forceUpdate();
		},
		removerGrade(index){
			this.vetorVariacoes[this.variacaoSelecionada].grades.splice(index, 1);
			this.recalcularVariacao({del:true});
			this.$forceUpdate();
		},
		abrirDropDown() {
			[...document.getElementsByClassName("drop-down-icon")].forEach(icon => {
				if (icon.classList.contains("active")) {
					icon.classList.remove("active");
				} else {
					icon.classList.add("active");
				}
			});
			const dropDownContent = document.getElementsByClassName("drop-down-content")[0];
			if (dropDownContent.classList.contains("active")) {
				dropDownContent.classList.remove("active");
				setTimeout(() => {
					dropDownContent.style.display = "none";
				}, 300);
			} else {
				dropDownContent.style.display = "flex";
				setTimeout(() => {
					dropDownContent.classList.add("active");
				}, 10);
			}
		},
		GerarVariacao(){
			try {
				let produtos = [];
				Object.keys(this.vetorVariacoes).forEach(key => {
					if (this.vetorVariacoes[key].grades.length > 0) {
						this.vetorVariacoes[key].grades.forEach(grade => {
							let variacao = ` ${key} [${grade.grade}]`;
							const descricao = `${this.produtoPai.descricao};${variacao}`;
							if (descricao.length > 100) {
								throw new Error("A descrição do produto não pode ser maior que 100 caracteres, favor corrigir!");
							}
							const existe = this.ProdutoFilhos.find(prod => prod.variacao == key && prod.grade == grade.grade);
							if (!existe && grade.quantidade >= 1) {
								produtos.push({
									grade: grade.grade,
									quantidade: grade.quantidade,
									variacao: key,
									pai: this.produtoPai.id,
									descricao,
									cdlinha: this.produtoPai.cdgrupo,
									cdsubgrupo: this.produtoPai.cdsubgrupo,
									cdfornecedor: this.produtoPai.cdfornecedor || this.produtoPai.cdFornecedor,
									cdReferencia: this.produtoPai.cdReferencia || this.produtoPai.cdreferencia || this.produtoPai.id,
									embalagem: this.produtoPai.embalagem || this.produtoPai.unidade,
									unidade: this.produtoPai.unidade,
									qteEmbalagem: this.produtoPai.qteEmbalagem,
									estoqueMaximo: this.produtoPai.estoqueMaximo,
									estoqueMinimo: this.produtoPai.estoqueMinimo,
									vlrunitario: this.produtoPai.vlrunitario || (this.produtoPai.custo),
									formacaoPreco : {
										[this.$store.state.Empresa.id]: {
											...this.gerarpreco(grade.quantidade),
										}
									},
									...this.gerarpreco(grade.quantidade),
								});
							} else if(grade.quantidade >= 1) {
								produtos.push({
									grade: grade.grade,
									quantidade: grade.quantidade,
									variacao: key,
									cdproduto: existe.cdproduto || existe.id,
									descricao,
									codinter: existe.codinter,
									codbarra: existe.codbarra,
									codBarra: existe.codbarra,
									pai: this.produtoPai.id,
									cdlinha: this.produtoPai.cdgrupo,
									cdsubgrupo: this.produtoPai.cdsubgrupo,
									cdfornecedor: this.produtoPai.cdfornecedor || this.produtoPai.cdFornecedor,
									cdReferencia: this.produtoPai.cdReferencia || this.produtoPai.cdreferencia || existe.cdproduto || existe.id,
									embalagem: this.produtoPai.embalagem || this.produtoPai.unidade,
									unidade: this.produtoPai.unidade,
									qteEmbalagem: this.produtoPai.qteEmbalagem,
									estoqueMaximo: this.produtoPai.estoqueMaximo,
									estoqueMinimo: this.produtoPai.estoqueMinimo,
									vlrunitario: this.produtoPai.vlrunitario || (this.produtoPai.custo),
									formacaoPreco: {
										[this.$store.state.Empresa.id]: {
											...this.gerarpreco(grade.quantidade),
										}
									},
									...this.gerarpreco(grade.quantidade),
								});
							}
						});
					} else {
						const existe = this.ProdutoFilhos.find(prod => prod.variacao == key && prod.grade == null);
						if (!existe && this.vetorVariacoes[key].quantidade >= 1) {
							produtos.push({
								pai: this.produtoPai.id,
								descricao: `${this.produtoPai.descricao}; ${key}`,
								cdlinha: this.produtoPai.cdgrupo,
								cdsubgrupo: this.produtoPai.cdsubgrupo,
								grade: null,
								variacao: key,
								cdfornecedor: this.produtoPai.cdfornecedor || this.produtoPai.cdFornecedor,
								cdReferencia: this.produtoPai.cdReferencia || this.produtoPai.cdreferencia || this.produtoPai.id,
								unidade: this.produtoPai.unidade,
								embalagem: this.produtoPai.embalagem || this.produtoPai.unidade,
								qteEmbalagem: this.produtoPai.qteEmbalagem,
								estoqueMaximo: this.produtoPai.estoqueMaximo,
								estoqueMinimo: this.produtoPai.estoqueMinimo,
								vlrunitario: this.produtoPai.vlrunitario || (this.produtoPai.custo),
								formacaoPreco: {
									[this.$store.state.Empresa.id]: {
										...this.gerarpreco(this.vetorVariacoes[key].quantidade),
									}
								},
								...this.gerarpreco(this.vetorVariacoes[key].quantidade),
							});
						} else if (this.vetorVariacoes[key].quantidade >= 1) {
							produtos.push({
								grade: null,
								variacao: key,
								cdproduto: existe.cdproduto,
								descricao: `${this.produtoPai.descricao}; ${key}`,
								codinter: existe.codinter,
								codbarra: existe.codbarra,
								codBarra: existe.codbarra,
								cdReferencia: existe.cdReferencia || existe.cdreferencia || existe.cdproduto,
								pai: this.produtoPai.id,
								cdlinha: this.produtoPai.cdgrupo,
								cdsubgrupo: this.produtoPai.cdsubgrupo,
								cdfornecedor: this.produtoPai.cdfornecedor || this.produtoPai.cdFornecedor,
								unidade: this.produtoPai.unidade,
								embalagem: this.produtoPai.embalagem || this.produtoPai.unidade,
								qteEmbalagem: this.produtoPai.qteEmbalagem,
								estoqueMaximo: this.produtoPai.estoqueMaximo,
								estoqueMinimo: this.produtoPai.estoqueMinimo,
								vlrunitario: this.produtoPai.vlrunitario || (this.produtoPai.custo),
								formacaoPreco: {
									[this.$store.state.Empresa.id]: {
										...this.gerarpreco(this.vetorVariacoes[key].quantidade),
									}
								},
								...this.gerarpreco(this.vetorVariacoes[key].quantidade),
							});
						}
					}
				});
				let saldo = 0;
				produtos.forEach(prod => {saldo += prod.saldo;});
				if (saldo > (this.saldoPai)) {
					throw new Error("Você está tentando gerar variações com saldo maior que o produto pai, verifique!");
				}
				this.$emit("gerarVariacoes", produtos);
			} catch (error) {
				return this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},

		gerarpreco(saldo) {
			return new FormacaoPreco({
				saldo: saldo || 0,
				cdorigem: this.produtoPai.cdorigem,
				custo: this.produtoPai.custo || this.produtoPai.vlrunitario,
				customedio: this.produtoPai.custoMedio,
				custofixo: this.produtoPai.custofixo || this.produtoPai.custo,
				ultcusto: this.produtoPai.ultimoCusto,
				cfopesta: this.produtoPai.cfopesta,
				cfopinter: this.produtoPai.cfopinter,
				cofins: this.produtoPai.cofins,
				cofinssittrib: this.produtoPai.cofinssittrib,
				cofinstributacao: this.produtoPai.cofinstributacao,
				curvaabc: this.produtoPai.curvaabc,
				descvista: this.produtoPai.descvista,
				frete: this.produtoPai.frete,
				icmsest: this.produtoPai.icmsest,
				icmsestsittrib: this.produtoPai.icmsestsittrib,
				icmsint: this.produtoPai.icmsint,
				icmsintsittrib: this.produtoPai.icmsintsittrib,
				icmsst: this.produtoPai.icmsst,
				ipi: this.produtoPai.ipi,
				ipisittrib: this.produtoPai.ipisittrib,
				ipitributacao: this.produtoPai.ipitributacao,
				markup: this.produtoPai.novoMarkup || this.produtoPai.markup || this.produtoPai.margem,
				markupseg: this.produtoPai.markupseg,
				ncm: this.produtoPai.ncm,
				outros: this.produtoPai.outros,
				percentualacrescimofisica: this.produtoPai.percentualacrescimofisica,
				pis: this.produtoPai.pis,
				pissittrib: this.produtoPai.pissittrib,
				pistributacao: this.produtoPai.pistributacao,
				pontoequi: this.produtoPai.pontoequi || this.produtoPai.prazo || this.produtoPai.valorPrazo,
				valorcofins: this.produtoPai.valorcofins,
				valorfrete: this.produtoPai.valorfrete,
				valoricmsst: this.produtoPai.valoricmsst,
				valoripi: this.produtoPai.valoripi,
				valormarkupseg: this.produtoPai.valormarkupseg,
				valoroutros: this.produtoPai.valoroutros,
				valorpis: this.produtoPai.valorpis,
				vlrvista: this.produtoPai.vista || this.produtoPai.valorVista,
				ecf: true
			});
		},
		async montarVariacao() {
			this.$store.state.carregando = true;
			setTimeout(() => {
				if (this.ProdutoFilhos.length) {
					this.ProdutoFilhos.forEach(prod => {
						if(!Object.keys(this.vetorVariacoes).includes(prod.variacao)){
							this.vetorVariacoes[prod.variacao] = {
								grades: prod?.grade_varia_manual ? [
									{
										grade: prod?.grade_varia_manual?.grade,
										quantidade: prod?.grade_varia_manual?.quantidade || "0"
									}
								] : prod.grade ? [
									{
										grade: prod.grade,
										quantidade: this.$route.query.menu === "Compras" ? (prod?.quantidade || "0") : prod.saldo.toString() || "0"
									}
								] : [],
							};
						} else {
							this.vetorVariacoes[prod.variacao].grades.push(
								prod?.grade_varia_manual ? {
									grade: prod?.grade_varia_manual?.grade || prod.grade,
									quantidade: prod?.grade_varia_manual?.quantidade || "0"
								} : {
									grade: prod.grade,
									quantidade: this.$route.query.menu === "Compras" ? (prod?.quantidade || "0") : prod.saldo.toString() || "0"
								}
							);
						}
						let qtd = 0;
						this.vetorVariacoes[prod.variacao].grades.forEach(grade => {
							if(grade.quantidade.includes(",")){
								grade.quantidade = grade.quantidade.split(",")[0];
							}
							qtd += Number(`${grade?.quantidade}`.replace(",", "."));
						});
						this.vetorVariacoes[prod.variacao].quantidade = `${qtd}` || "0";
					});
				}
				this.$forceUpdate();
				this.calcularSaldoPai();
				this.variacaoSelecionada = Object.keys(this.vetorVariacoes)[0];
				const checkbox = document.getElementById(this.variacaoSelecionada);
				if (checkbox) checkbox.checked = true;
				this.$store.state.carregando = false;
			}, 50);
		},
		eventosTeclado(e) {
			if (`${e.key}`.toUpperCase() == "C" && e.altKey) {
				e.preventDefault();
				this.cancelarVariacao();
			}
			if (`${e.key}`.toUpperCase() == "L" && e.altKey) {
				e.preventDefault();
				this.LimparVariacao();
			}
			if (`${e.key}`.toUpperCase() == "S" && e.altKey) {
				e.preventDefault();
				this.GerarVariacao();
			}
		},

		async buscarFilhos() {
			await this.$store.dispatch("buscarFilhos", {
				pai: this.produtoPai.cdproduto? this.produtoPai.cdproduto : this.produtoPai.id,
			});
		}

	},
	mounted() {
		const produtosFilhosOld = this.ProdutoFilhos;
		this.$store.state.carregando = true;
		this.buscarFilhos().then(() => {
			if(produtosFilhosOld?.length > 0) {
				produtosFilhosOld.forEach(prod => {
					const existe = this.ProdutoFilhos.find(p => p.id === prod.cdproduto);
					if(existe) {
						if(prod?.grade_varia_manual?.quantidade > 1) {
							existe.grade_varia_manual = prod.grade_varia_manual;
						}
					}
				});
			}

			this.montarVariacao();
			document.addEventListener("keydown", this.eventosTeclado);
			this.$store.state.carregando = false;
		});
	},

	updated () {
		// this.qtdVariacao = 0;
		// let qtdVariacao = 0;
		// Object.keys(this.vetorVariacoes).forEach(variacao => {
		// 	let qtdGrade = 0;
		// 	if (this.vetorVariacoes[variacao].grades?.length) {
		// 		this.vetorVariacoes[variacao].grades.forEach(grade => {
		// 			qtdGrade += Number(grade.grade || 0);
		// 		});
		// 	}
		// 	this.vetorVariacoes[variacao].quantidade = qtdGrade.toString();
		// 	qtdVariacao += qtdGrade;
		// });
		// this.qtdVariacao = qtdVariacao;
	},
	destroyed() {
		document.removeEventListener("keydown", this.eventosTeclado);
	}
};
</script>

<style scoped>
.botoes{
	justify-content: space-between;
}
.botao{
	width: 30%;
}
.gerar-variacao-controle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    flex-grow: 1;
}
.cadastro-produto-cabecalho {
    display: flex;
    flex-direction: column;
    justify-content: center;
	align-items: center;
    background-color: var(--bg-app);
    width: 100%;
}
.cadastro-produto-cabecalho h3 {
    margin: 5px;
}
.variacao-controle {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
	flex-wrap: wrap;
}
.box-variacao {
    display: flex;
    flex-grow: 1;
    margin: 5px auto;
    padding: 10px 5px 20px 5px;
    max-width: max-content;
	height: 55vh;
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;
    border: 2px solid steelblue;
    background-color : #dddddd;
    transition-duration: 300ms;
    transition-property: all;
    transition-timing-function: ease-in-out;
}
.icones {
    background-color: rgba(0, 0, 0, 0.);
    display: flex;
    margin: auto;
    padding: 2px;
}
.linha {
    display: flex;
    width: 100%;
    margin: 2px auto;
    flex-direction: row;
    align-items: center;
}
.variacao-item {
    border: 1px solid #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
	white-space: nowrap;
    overflow: hidden;
	text-overflow: ellipsis;
	width: 230px;
    height: 25px;
}
.container-linha{

	white-space: nowrap;
    overflow: hidden;
	text-overflow: ellipsis;
	width: 230px;
}
.input-quantidade {
    width: 60px;
}
#botoes {
   margin: 5px auto;
}
.drop-down {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.drop-down-header {
    display: flex;
    flex-direction: row;
}
.drop-down-title {
    font-size: 10pt;
    text-align: left;
    cursor: pointer;
    text-shadow: 0px 0px 0.5px #292929;
    user-select: none;
}
.drop-down-content {
    opacity: 0;
    display: none;
    flex-direction: column;
    margin-bottom: 5px;
    transition-duration: 300ms;
    transition-property: all;
    transition-timing-function: ease-in-out;
}
.drop-down-content.active {
    opacity: 1;
    display: flex;
}
.drop-down-icon {
    font-size: 12pt;
    color: var(--primario);
    display: none;
}
.drop-down-icon.active {
    display: flex;
}
.drop-down-button {
    font-size: 9pt;
    background-color: var(--primario);
    color: var(--branco);
    border-radius: 10px;
    border: 1px solid var(--primario);
    padding: 2px;
    width: 100%;
    transition-duration: 120ms;
    transition-property: all;
    transition-timing-function: ease-in-out;
    box-shadow: 1px 1px 1px 1px var(--primario);
}
.drop-down-button:hover {
    box-shadow: 0px 2px 2px 1px #292929;
}
.drop-down-button:active {
    transform: translateY(1px);
    box-shadow: 1px 1px 1px 1px var(--primario);
}
.buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 5px 0px;
}

.buttons button {
	width: 100%;
	margin: 0px 5px;
}

@media screen and (max-width: 768px){
	.buttons span {
		display: none;
	}
}

@media screen and (max-width: 576px){
	.buttons i {
		display: none;
	}
}

@media screen and (max-width: 440px){
	.botao i{
		display: none;
	}
	.botao span{
		display: none;
	}
}

/*
	GERA VARIACAO NA COMPRA
*/

.gera-variacao-compra-container{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
}
.gera-variacao-compra-content{
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}

.variacao-controle-compra {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.tabela-nota-container table{
	width: 100%;
}
.tabela-nota-container{
	height: 55vh;
	overflow: auto;
	width: 50%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.container-titulo-produto-compra{
	width: 100%;
	margin: 10px 0px;
}

.gera-variacao-compra-container .botoes {
	margin-top: 25px;
}
.align-left {
	text-align: left;
}
</style>
