<template>
	<div :class="`scroll-table ${!showTable ? 'table-full' : ''}`">
		<table v-for="(movi, index) in ListaMovimentacoes" :key="index">
			<thead v-if="index === 0">
				<tr>
					<th class="small-field ">
						Ação
					</th>
					<th class="small-field">
						Bandeira
					</th>
					<th class="large-field">
						Produto Conciliadora
					</th>
					<th class="small-field">
						Nsu Cartão
					</th>
					<th class="mid-field">
						N° Autorização
					</th>
					<th class="large-field">
						Vlr. Parcela ajustada
					</th>
					<th class="large-field">
						Vlr. Parcela sistema
					</th>
					<th class="mid-field">
						Vlr. Título
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td  class="small-field center-field" style="width: 100px;">
						<i class="fas fa-caret-square-down" @click="handleMoreInfos(movi)" v-if="!movi.info"></i>
						<i class="fas fa-caret-square-up" @click="handleMoreInfos(movi)" v-else></i>
						<i class="fas fa-edit" @click="$emit('handleEditMovi',movi)" />
					</td>
					<td class="small-field center-field">
						{{ movi.cdbandeira }}
					</td>
					<td class="large-field center-field">
						{{ movi.codprodutoconciliadora }}
					</td>
					<td class="small-field center-field">
						{{ movi.nsucartao }}
					</td>
					<td class="mid-field center-field">
						{{ movi.doccartao }}
					</td>
					<td class="large-field value-field">
						{{ (movi.valorParcelaAjustada || 0) | formatarParaReais }}
					</td>
					<td class="large-field value-field">
						{{ (movi.valorParcelaSistema || 0) | formatarParaReais }}
					</td>
					<td class="mid-field value-field">
						{{ (movi.valorTitulo || 0) | formatarParaReais }}
					</td>
				</tr>
				<tr v-if="movi.info" >
					<td colspan="8">
						<table class="table-infos">
							<thead>
								<tr>
									<th class="small-field">
										Códgio
									</th>
									<th class="large-field">
										Cliente
									</th>
									<th class="small-field">
										Data
									</th>
									<th class="large-field">
										Vlr. Parcela ajustada
									</th>
									<th class="large-field">
										Vlr. Parcela sistema
									</th>
									<th class="mid-field">
										Vlr. Título
									</th>
									<th class="small-field">
										Parcela
									</th>
									<th class="mid-field">
										Pagamento
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(detalhe, indexDetalhe) in movi.detalhes"
									:key="indexDetalhe"
								>
									<td  class="small-field center-field">
										{{ detalhe.cddig }}
									</td>
									<td class="large-field center-field">
										{{ detalhe.cdpessoa }} - {{ detalhe.nome }}
									</td>
									<td class="small-field center-field">
										{{ detalhe.datadoc ? `${detalhe.datadoc}`.split("-").reverse().join("/") : ''}}
									</td>
									<td class="large-field value-field">
										{{ (detalhe.valorparcelaajustado || 0) | formatarParaReais }}
									</td>
									<td class="large-field value-field">
										{{ (detalhe.valorparcelasistema || 0) | formatarParaReais }}
									</td>
									<td class="mid-field value-field">
										{{ (detalhe.valortitulo || 0) | formatarParaReais }}
									</td>
									<td  class="small-field center-field">
										{{ detalhe.parcela }}
									</td>
									<td class="mid-field center-field">
										{{ detalhe.pagamento }}
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</tbody>
		</table>					
	</div>
</template>
<script>
import formatarParaReais from "../filters/formatarParaMoeda";

export default{
	name: "TabelaConciliadora",
	props: {
		showTable: Boolean,
	},
	computed: {
		ListaMovimentacoes(){
			return this.$store.state.Conciliadora.vendasPendentes.vendas;
		},
	},
	filters: {
		formatarParaReais
	},
	methods:{
		handleMoreInfos(movi){
			movi.info = !movi.info;
			this.$forceUpdate();
		},
	},
};
</script>
<style scoped>
.scroll-table {
	width: 100%;
	padding: 10px;
	overflow: auto;
	height: 60vh;
}
.table-full{
	height: 70vh !important;
}
.fas{
	color: #000;
	font-size: 25px;
}
.small-field{
	width: 100px;
	max-width: 100px;
	min-width: 100px;
}
.mid-field{
	width: 150px;
	max-width: 150px;
	min-width: 150px;
}
.large-field{
	width: 225px;
	max-width: 225px;
	min-width: 225px;
}
.table-infos {
	padding: 5px 0px;
}
.value-field{
	text-align: right;
}
.center-field {
	text-align: center;
}
@media screen and (max-width: 768px) {
	.scroll-table{
		height: 43vh !important;
	}
	.table-full{
		height: 68vh !important;
	}	
}
@media screen and (max-width: 425px) {
	.scroll-table{
		height: 30vh !important;
	}
	.table-full{
		height: 71vh !important;
	}	
}
</style>