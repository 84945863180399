import { render, staticRenderFns } from "./CadastroGrupoProduto.vue?vue&type=template&id=3cf5b576&scoped=true"
import script from "./CadastroGrupoProduto.vue?vue&type=script&lang=js"
export * from "./CadastroGrupoProduto.vue?vue&type=script&lang=js"
import style0 from "./CadastroGrupoProduto.vue?vue&type=style&index=0&id=3cf5b576&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cf5b576",
  null
  
)

export default component.exports