<script>
import { Bar, mixins } from "vue-chartjs";
const {reactiveProp} = mixins;
export default {
	name: "GraficoPizza",
	extends: Bar,
	mixins: [reactiveProp],
	props: ["options"],
	mounted () {
		this.renderChart(this.chartData, this.options);
	},
	beforeUpdate() {
		this._data._chart.destroy();
		this.renderChart(this.chartData, this.options);
	},
	watch: {
		chartData: function() {
			this._data._chart.destroy();
			this.renderChart(this.chartData, this.options);
		},
		options: function() {
			this._data._chart.destroy();
			this.renderChart(this.chartData, this.options);
		}
	},
	destroyed() {
		this._data._chart.destroy();
	}
};
</script>

<style>
</style>
