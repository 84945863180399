<template>
	<div class="main-cadastro-empresa">
		<div id="aba-controle" class="row between">
			<h3 @click="mudarAba(0, 'dados')" class="aba abaSelecionada">Dados</h3>
			<h3 @click="mudarAba(1, 'config')" class="aba">Configurações</h3>
			<h3 @click="mudarAba(2, 'nfe')" class="aba">Configuração NF-e</h3>
		</div>
		<EmpresaDados class="cadastro-empresa-aba" v-if="mostrar == 'dados'" :Empresa="Empresa" v-on:salvarDados="cadastrarOuAtualizarEmpresa"
		v-on:cancelarEdicao="cancelarEdicao"/>
		<EmpresaConfig class="cadastro-empresa-aba" v-else-if="mostrar == 'config'" :Empresa="Empresa" v-on:salvarConfiguracoes="cadastrarOuAtualizarEmpresa"
		v-on:cancelarEdicao="cancelarConfiguracao"/>
		<NFeConfigEmpresa class="cadastro-empresa-aba" v-else-if="mostrar == 'nfe'" :Empresa="Empresa" v-on:salvarConfigFiscal="cadastrarOuAtualizarEmpresa" 
		v-on:cancelarEdicao="cancelarConfiguracao"/>
	</div>
</template>
<script>
import EmpresaDados from "./EmpresaDados.vue";
import EmpresaConfig from "./EmpresaConfig.vue";
import NFeConfigEmpresa from "./NFeConfigEmpresa.vue";
export default {
	name: "CadastroEmpresa",
	components: {
		EmpresaDados,
		EmpresaConfig,
		NFeConfigEmpresa
	},
	props: {
		Empresa: Object,
	},
	data() {
		return {
			mostrar: "dados",
		};
	},

	methods: {

		mudarAba(index, mostrar) {
			if(!this.podeMudarAba()) return;
			// if(!salvarEmpresa && mostrar == "config") return this.$bus.$emit("executarAcao");
			this.mostrar = mostrar;
			const aba = document.getElementsByClassName("aba")[index];
			const abaSelecionada = document.getElementsByClassName("abaSelecionada")[0];
			if (aba != abaSelecionada) {
				aba.classList.add(["abaSelecionada"]);
				abaSelecionada.classList.remove(["abaSelecionada"]);
			}
		},
		podeMudarAba(){
			try {
				if(this.Empresa ? !this.Empresa.id : true) {
					this.$bus.$emit("executarAcao");
					return false;
				}

				return true;
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
				return false;
			}
		},
		async cadastrarOuAtualizarEmpresa(empresa) {
			try {
				if (!this.Empresa) throw new Error("Ops, parece que você não selecionou nenhuma empresa, verifique!");
				let empresaCadastrada = null;
				if (this.Empresa.novo) {
					empresaCadastrada = await this.$store.dispatch("cadastrarEmpresa", {...empresa});
				} else if (this.Empresa.id) {
					empresaCadastrada = await this.$store.dispatch("alterarEmpresa", {...this.Empresa, ...empresa, id: this.Empresa.id});
				}
				if (!empresaCadastrada) return;
				if (!empresaCadastrada.id) {
					throw new Error(empresaCadastrada.mensagem || (empresaCadastrada.details ? JSON.stringify(empresaCadastrada.details) : JSON.stringify(empresaCadastrada)));
				}
				this.$emit("atualizarEmpresaSelecionada", {...empresaCadastrada});
				if (this.mostrar === "dados") {
					this.mudarAba(1, "config", true);
				} else if(this.mostrar === "config") {
					this.mudarAba(2, "nfe", true);
				} else {
					this.cancelarEdicao();
				}
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},
		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},
		cancelarConfiguracao() {
			this.mudarAba(0, "dados");
		}
	},

};
</script>
<style scoped>
.main-cadastro-empresa {
    display: flex;
    flex-direction: column;
    overflow: hidden;
	padding: 0px;
	margin: 0px;
	flex-grow: 1;
}
#aba-controle {
	background-color: var(--bg-secundario);
	display: flex;
	min-height: 60px;
	height: 60px;
	max-height: 60px;
	padding: 10px 0px 0px 0px;
	width: 100%;
	overflow-x: auto;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}
#aba-controle::-webkit-scrollbar {
	display: none;
}
#aba-controle h3 {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	vertical-align: middle;
	min-width: max-content;
	margin: 0px 5px;
	padding: 5px;
}
.aba {
	background-color: var(--bg-secundario);
	text-align: center;
	cursor: pointer;
	width: 45%;
	height: 100%;
}
.abaSelecionada {
	cursor: default;
	background-color: var(--bg-app);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.cadastro-empresa-aba {
	display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    width: 100%;
}
</style>
