<template>
	<div class="containerPix">
		<div class="containerHeaderPix">
			<div class="timerCobranca" v-if="$store.state.CobrancaPix.txid">
				<p>Tempo da cobrança:</p>
				<p style="color: var(--primario);">{{ timer }}</p>
			</div>
			<div class="valorPix" v-if="$store.state.CobrancaPix.txid">
				<div>
					<p>Valor da cobrança:</p>
					{{Number(`${valorPix}`).toLocaleString("pt-BR", {style: "currency", currency:"BRL"})}}
				</div>
				<i class="fas fa-print" @click="solicitarImpressaoQRCode" style="color: var(--primario); font-size: 30px;" v-if="$store.state.CobrancaPix.txid && !$store.state.carregamento"></i>
			</div>
		</div>
		<div class="title" v-if="!$store.state.CobrancaPix.txid">
			<h3>Informações do cliente</h3>
		</div>
		<div :class="alert ? 'alertMessage' : statuscobranca == 'ATIVA' ? 'cobrancaAtiva' : 'alertMessage'" v-if="mensagem">
			<i class="icon fas fa-exclamation-triangle" v-if="alert"></i>
			<p>Atenção: {{ mensagem }}</p>
		</div>
		<div class="clienteInfoContainer" v-if="!$store.state.CobrancaPix.txid && !$store.state.carregamento">

			<div class="campos">
				<label for="inputNomeCliente">Nome:</label>
				<input type="text" placeholder="Informe o nome" id="inputNomeCliente" v-model="form.inputNome" />
				<label for="inputCPFCliente">CPF:</label>
				<input type="text" placeholder="Informe o CPF" autocomplete="off" id="inputCPFCliente" v-model="form.inputCPF" />
			</div>
			<div class="campos">
				<label for="inputMensagem">Mensagem:</label>
				<input type="text"  id="inputMensagem" v-model="form.inputMensagem" />
			</div>
		</div>
		<div v-if="$store.state.carregamento" class="loader">
			<Loader></Loader>
		</div>
		<div id="qrcode" v-else>
			<img :src="url" />
		</div>
		<div class="buttons">
			<button class="btn btn-cancelar" @click="fecharModal">
				Cancelar
			</button>
			<button class="btn btn-acao" @click="fazerCobrancaPix" v-if="!$store.state.CobrancaPix.txid">
				Realizar Cobrança
			</button>
			<button class="btn btn-acao" @click="fazerConsultaPix" v-else>
				Consultar Cobrança
			</button>
		</div>
	</div>
</template>
<script>
import QRCode from "qrcode";
import Loader from "../components/Loader.vue";
export default{
	name: "PixCobranca",
	props: {
		dados: Object,
		pagamento: Object,
		contaBaixada: Object,
		valorPix: Number
	},
	components: {Loader},
	data(){
		return{
			carregando: false,
			timer: 30,
			alert: false,
			statuscobranca: "",
			mensagem: "",
			contar: null,
			url: "",
			form: {
				inputNome: "",
				inputCPF: "",
				inputMensagem: ""
			},
			cobrancaGerada: {}
		};
	},
	computed: {

	},
	methods: {
		solicitarImpressaoQRCode(){
			this.$store.dispatch("impressaoPix", this.valorPix);
			this.$router.push({name: "Fechamento", query: {impressaoPix: true, tipoVenda: this.$store.state.Venda.tipoVenda}});
		},
		fecharModal(){
			clearTimeout(this.timer);
			this.$emit("fecharModal");
			if(!this.$store.state.CobrancaPix.txid) return this.$emit("fecharModal");
			this.$store.dispatch("cancelarCobrancaPix", {...this.dados, txid: this.cobrancaGerada.txid});
		},
		async fazerConsultaPix(){
			const data = await this.$store.dispatch("consultarCobrancaPix", {...this.dados, txid: this.cobrancaGerada.txid});
			if(data ? !data.txid : false){
				return this.$store.commit("relatarErro", {mensagem: data?.mensagem || (data.message || JSON.stringify(data))});
			}
			if(data){
				if(data.statuscobranca == "ATIVA"){
					this.statuscobranca = data.statuscobranca;
					this.message("A cobrança ainda permanece ativa", "ativa");
				}
				if(data.statuscobranca == "CONCLUIDA"){
					this.$store.commit("zerarCobrancaState");
					this.$emit("fecharModal");
					if(this.$route.path == "/fechamento"){
						this.$emit("finalizarVenda", {...this.pagamento, txid: data.txid});
					}
					if(this.$route.path == "/contasreceber"){

						this.$emit("baixarConta", this.contaBaixada);
					}
					this.$store.commit("relatarErro", {mensagem: "Pix Realizado com sucesso", sucess: true});
				}
			}
		},
		timerPix(){
			this.contar = setTimeout(() =>{
				if(this.timer == 0){
					if(!this.$store.state.CobrancaPix.txid) return this.timerPix();
					this.fazerConsultaPix();
					this.timer = 30;
				}
				this.timer--;
				this.timerPix();
			}, 1000);
		},
		async fazerCobrancaPix(){
			if(this.form.inputNome.length != 0 && this.form.inputCPF.length == 0){
				return this.$store.commit("relatarErro", {mensagem: "O campo CPF Deve ser preenchido"});
			}
			if(this.form.inputCPF.length != 0){
				if(this.form.inputCPF.length > 14){
					if(!this.verificaCNPJ(this.form.inputCPF)){
						return this.$store.commit("relatarErro", {mensagem: "CNPJ inválido"});
					}
				}
				if(!this.verificaCPF(this.form.inputCPF)){
					return this.$store.commit("relatarErro", {mensagem: "CPF inválido"});
				}
			}
			this.cobrancaGerada = await this.$store.dispatch("gerarCobrancaPix",{
				ambiente: this.dados.ambiente,
				banco: this.dados.banco,
				chave: this.dados.chave,
				nome: this.form.inputNome,
				cpf: this.form.inputCPF,
				mensagem: this.form.inputMensagem,
				valor: this.valorPix
			});
			this.gerarQR(this.cobrancaGerada.qrcode);
		},
		async gerarQR(text){
			this.url = await QRCode.toDataURL(text);
		},
		message(text, tipo){
			if(tipo == "alerta") {
				this.mensagem = text;
				this.alert = true;
				setTimeout(() =>{
					this.alert = false;
					this.mensagem = "";
				}, 3500);
			}
			if(tipo == "ativa"){
				this.alert = false;
				this.mensagem = text;
				setTimeout(() =>{
					this.mensagem = "";
				}, 5000);
			}
		},
		verificaCNPJ(cnpj) {

			cnpj = cnpj.replace(/[^\d]+/g, "");

			if (cnpj == "") return false;

			if (cnpj.length != 14)
				return false;

			if (cnpj == "00000000000000" ||
				cnpj == "11111111111111" ||
				cnpj == "22222222222222" ||
				cnpj == "33333333333333" ||
				cnpj == "44444444444444" ||
				cnpj == "55555555555555" ||
				cnpj == "66666666666666" ||
				cnpj == "77777777777777" ||
				cnpj == "88888888888888" ||
				cnpj == "99999999999999")
				return false;

			let tamanho = cnpj.length - 2;
			let numeros = cnpj.substring(0, tamanho);
			let digitos = cnpj.substring(tamanho);
			let soma = 0;
			let pos = tamanho - 7;
			let i;
			for (i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
					pos = 9;
			}
			let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(0))
				return false;

			tamanho = tamanho + 1;
			numeros = cnpj.substring(0, tamanho);
			soma = 0;
			pos = tamanho - 7;
			for (i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
					pos = 9;
			}
			resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(1))
				return false;

			return true;
		},
		verificaCPF(strCPF) {
			strCPF = strCPF.replace(/[^\d]+/g, "");

			if (strCPF.length != 11) return false;

			if (strCPF == "00000000000" ||
				strCPF == "11111111111" ||
				strCPF == "22222222222" ||
				strCPF == "33333333333" ||
				strCPF == "44444444444" ||
				strCPF == "55555555555" ||
				strCPF == "66666666666" ||
				strCPF == "77777777777" ||
				strCPF == "88888888888" ||
				strCPF == "99999999999")
				return false;

			let Soma;
			let Resto;
			Soma = 0;
			if (strCPF == "00000000000") return false;
			for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
			Resto = (Soma * 10) % 11;
			if ((Resto == 10) || (Resto == 11)) Resto = 0;
			if (Resto != parseInt(strCPF.substring(9, 10))) return false;
			Soma = 0;
			for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
			Resto = (Soma * 10) % 11;
			if ((Resto == 10) || (Resto == 11)) Resto = 0;
			if (Resto != parseInt(strCPF.substring(10, 11))) return false;
			return true;
		}
	},
	mounted(){
		this.timerPix();
		this.message("Ao preencher o nome do cliente o campo CPF é obrigatório", "alerta");
		const emp = this.$store.state.Empresa.fantasia;
		if(this.$route.path == "/fechamento"){
			this.form.inputMensagem = `Venda referente a empresa ${emp}`;
		}
		if(this.$route.path == "/contasreceber"){
			this.form.inputMensagem = `Recebimento referente a empresa ${emp}`;

		}
	},
	destroyed(){
		clearTimeout(this.contar);
	}
};
</script>
<style scoped>
.containerPix{
	width: 100%;
}
.loader{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.buttons{
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 10px;
	margin-top: 10px;
}
button{
	width: 45%;
}
.btnImprimir{
	font-size: 30px;
	color: var(--primario);
}

.clienteInfoContainer{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.valorPix{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.valorPix div{
	display: flex;
	flex-direction: row;
	gap: 20px;
}
.campos{
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	margin-top: 10px;
	width: 450px;
}
.campos input{
	width: 100%;
}

.timerCobranca{
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 30px;
}
.alertMessage i{
	font-size: 30px;
	color: #ff0000;
}
.alertMessage{
	display: flex;
	flex-direction: row;
	gap: 20px;
	background: rgba(255,0,0,0.4066001400560224);
	border: 2px solid #ff0000;
	border-radius: 5px;
	padding: 5px;
	opacity: 0.5;
	animation: pisca 1.3s infinite ease-in;
}

.cobrancaAtiva{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	background: rgba(0,184,21,0.3309698879551821);
	border: 2px solid #00b815;
	border-radius: 5px;
	padding: 5px;
	margin-top: 10px;
	opacity: 0.5;
	animation: pisca 1.3s infinite ease-in;

}
.containerHeaderPix{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
@keyframes pisca {
	0%{
		opacity: 0.5;
	}
	50%{
		opacity: 1;
	}
	100%{
		opacity: 0.5;
	}
}
</style>
