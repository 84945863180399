<template>
	<table class="tabela-produto">
		<thead>
			<tr>
				<td style="color: var(--primario)">Seq.</td>
				<td style="color: var(--primario)">CFOP</td>
				<td style="color: var(--primario)" >Codigo</td>
				<td style="color: var(--primario)">Referência</td>
				<td style="text-align: left;">Produtos</td>
				<td class="center">Unid.</td>
				<td class="center">NCM</td>
				<td class="center">Qtd.</td>
				<td class="center text-nowrap">$ Unit.</td>
				<td class="center">SubTotal</td>
				<td class="center text-nowrap">% Frete</td>
				<td class="center text-nowrap">$ Frete</td>
				<td class="center text-nowrap">% Outros</td>
				<td class="center text-nowrap">$ Outros</td>
				<td class="center text-nowrap">% IPI</td>
				<td class="center text-nowrap">$ IPI</td>
				<td class="center text-nowrap">% ICMSst</td>
				<td class="center text-nowrap">$ ICMSst</td>
				<td class="center text-nowrap" v-if="totalFCPST">% FCPST</td>
				<td class="center text-nowrap" v-if="totalFCPST">$ FCPST</td>
				<td class="center text-nowrap">% Desc</td>
				<td class="center text-nowrap">$ Desc</td>
				<td class="center text-nowrap">$ Custo</td>
				<td class="center">Barra</td>
			</tr>
		</thead>
		<tbody>
			<tr 
				v-for="(prod, index) in Itens" 
				:key="index"
				:style="prod.bgcolor ? `background-color: ${prod.bgcolor}` : ''"
			>
				<td style="color: var(--primario)" class="center">{{ prod.cdseq }}</td>
				<td style="color: var(--primario)" class="center">{{ prod.cfop }}</td>
				<td style="color: var(--primario)" class="center">{{ prod.cdproduto }}</td>
				<td style="color: var(--primario)" class="center text-nowrap">{{ prod.cdreferencia }}</td>
				<td class="text-left text-nowrap">{{ prod.descricao }}</td>
				<td class="center">{{ prod.unidade }}</td>
				<td class="center">{{ prod.ncm }}</td>
				<td class="text-right">{{ ((prod.quantidade || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{ ((prod.vlrunitario || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{ (((prod.vlrunitario || 0) * (prod.quantidade ||1)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{ ((prod.frete || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{ ((prod.valorfrete || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{ ((prod.outros || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{((prod.valoroutros || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{ ((prod.ipi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{ ((prod.valoripi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{ ((prod.icmsst || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{ ((prod.valoricmsst || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right" v-if="prod.valorFCPST">{{ ((prod.FCPST || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4 }))}}</td>
				<td class="text-right" v-if="prod.valorFCPST">{{ ((prod.valorFCPST || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{ ((prod.percDesc || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{ ((prod.desconto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="text-right">{{ ((prod.custo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4}))}}</td>
				<td class="center text-nowrap">{{ prod.codbarraFornecedor }}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: "TabelaNotaCompra",
	props: {
		Itens: {
			type: Array,
			default: () => []
		},
		totalFCPST: {
			type: String,
			default: ""
		}
	}
};
</script>
