<template>
	<div class="containerPage">
		<div class="containerInformacoes">
			<fieldset>
				<legend>Informações</legend>
				<div class="fieldsetLinha">
					<div class="input-group">
						<input type="date" id="dataCobranca" style="text-align: right;" v-model="dataCobranca" readonly/>
						<label for="dataCobranca">Data da Cobrança:</label>
					</div>
					<div class="input-group">
						<input type="text" id="nomePagador" :value="cobrancaAtualizada.nomepagador || 'Nome Pagador Não informado'" readonly />
						<label for="nomePagador">Nome Pagador:</label>
					</div>
					<div class="fieldsetItem">
						<label for="nomePagador">Status Cobrança</label>
						<span :class="cobrancaAtualizada.statusdevolucao == 'DEVOLVIDO' ? 'devolvida' : cobrancaAtualizada.statuscobranca == 'REMOVIDA_PELO_USUARIO_RECEBEDOR' ? 'cancelada' : cobrancaAtualizada.statuscobranca == 'ATIVA' ? 'ativa' : 'concluida'">{{cobrancaAtualizada.statusdevolucao == "DEVOLVIDO" ? "DEVOLVIDA" : cobrancaAtualizada.statuscobranca == "REMOVIDA_PELO_USUARIO_RECEBEDOR" ? "CANCELADA" : cobrancaAtualizada.statuscobranca}}</span>
					</div>
				</div>
				<div class="fieldsetLinha">
					<div class="input-group">
						<input id="cpfCnpj" type="text" :value="cobrancaAtualizada.cpfcnpj || 'CPF OU CNPJ  Não informado'" readonly />
						<label for="cpfCnpj">CPF/CNPJ Pagador:</label>
					</div>
					<div class="input-group">
						<input id="txid" type="text" :value="cobrancaAtualizada.txid" readonly />
						<label for="txid">TXID Cobrança:</label>
					</div>
					<div class="input-group">
						<input id="valorTotal" class="inputPequeno" type="text" :value="(cobrancaAtualizada.total || 0).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})" readonly />
						<label for="valorTotal">Valor Cobrança:</label>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="containerQRCode">
			<h3 class="viewQR">VISUALIZAR</h3><h3>QRCODE</h3>
			<div id="qrcode">
				<img :src="url" />
			</div>
			<i class="fas fa-edit viewQR" style="color: var(--primario)" @click="modalQRcode = true"></i>
		</div>

		<Modal v-if="modalQRcode" @fecharModal="modalQRcode = false" :dimensoes="{width: 'auto', height: 'auto'}">
			<h3>QRCODE</h3>
			<div>
				<img :src="url" />
			</div>
			<div class="botoes">
				<button class="botao botao-cancelar" id="botao-modal" @click="modalQRcode = false">
					Fechar
				</button>
			</div>
		</Modal>
		<div class="botoes">
			<button class="botao botao-cancelar" @click="voltarConsulta">
				<span>Voltar</span> <span v-if="this.$route.path != '/vendas'">a consulta</span>
			</button>
			<button class="botao botao-acao" @click="solicitarEstorno">
				<span>Estornar</span> <span>cobrança</span>
			</button>
		</div>
		<Modal v-if="abrirModal" :dimensoes="{width: 'auto', height: 'auto'}" @fecharModal="abrirModal = false">
			<div class="containerModal">
				<h3>Informe o motivo e o valor que deseja Estornar</h3>
				<div class="containerContent">
					<div>
						<label for="motivo">Motivo estorno:</label>
						<input type="text" id="motivo" v-model="motivoEstorno" placeholder="Motivo do estorno" />
					</div>
					<div>
						<label for="valor">Valor estorno:</label>
						<input type="text" id="valor" v-model="valorEstorno" readonly/>
					</div>
				</div>
				<div class="buttons">
					<button class="btn btn-cancelar" @click="abrirModal = false">
						Voltar
					</button>
					<button class="btn btn-acao" @click="estornarCobranca" v-if="$store.state.Usuario.supervisor || $store.state.Usuario.usuarioPrivilegiado">
						Realizar Estorno
					</button>
				</div>
			</div>
		</Modal>
	</div>
</template>
<script>
import QRCode from "qrcode";
import Modal from "./Modal.vue";
export default{
	name:"CobrancaSelecionado",
	props: { cobranca: Object },
	components: { Modal },
	data(){
		return{
			cobrancaAtualizada: {},
			url: "",
			abrirModal: false,
			valorEstorno: "",
			motivoEstorno: "",
			modalQRcode: false,
			dataCobranca: ""
		};
	},
	methods: {
		async estornarCobranca(){
			let form = {
				valor: (this.valorEstorno || 0).replace(",", "."),
				descricao: this.motivoEstorno
			};
			if(!this.motivoEstorno || this.motivoEstorno.length == 0){
				return this.$store.commit("relatarErro", {mensagem: "Para realizar o estorno é necessário o motivo"});
			}
			const res = await this.$store.dispatch("estornarPixCobranca", {valor: Number(form.valor), descricao: form.descricao, e2E: this.cobrancaAtualizada.e2e});
			if(res){
				if(res.statusdevolucao == "EM_PROCESSAMENTO"){
					this.abrirModal = false,
					this.$store.commit("relatarErro", {mensagem: "O estorno esta em processamento, por favor consulte daqui alguns minutos", alerta: true});
					if(this.$route.path == "/vendas"){
						return this.$emit("realizarCancelamento");
					}
					this.voltarConsulta();
				}
				if(res.statusdevolucao == "DEVOLVIDO"){
					this.abrirModal = false;
					this.$store.commit("relatarErro", {mensagem: "O estorno foi processado corretamente", sucess: true});
					if(this.$route.path == "/vendas"){
						return this.$emit("realizarCancelamento");
					}
					this.$store.commit("relatarErro", {mensagem: "O estorno foi processado corretamente", sucess: true});
					this.voltarConsulta();
				}
			}
		},
		async solicitarEstorno(){
			const dataAtual = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime();
			const dataCobrancaSplit = this.dataCobranca.split("-");
			const dataCobranca = new Date(dataCobrancaSplit).getTime();
			const data = await this.$store.dispatch("validarDataEstorno", {txid: this.cobrancaAtualizada.txid});
			if(this.$route.path == "/vendas"){
				this.abrirModal = true;
				return this.valorEstorno = (this.cobrancaAtualizada.total || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
			if(dataCobranca < dataAtual){
				return this.$store.commit("relatarErro", {mensagem: "Não é possivel estornar uma cobrança que não foi gerada hoje."});
			}
			if(data.mensagem){
				return this.$store.commit("relatarErro", {mensagem: data.mensagem});
			}

			if(!this.cobrancaAtualizada.e2e || this.cobrancaAtualizada.statusdevolucao != ""){
				return this.$store.commit("relatarErro", {mensagem: "Para estornar a cobrança é necessário que ela tenha sido concluída."});
			}
			this.abrirModal = true;
			this.valorEstorno = (this.cobrancaAtualizada.total || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		},
		voltarConsulta(){
			this.$emit("voltarConsulta");
			this.$forceUpdate();
		},
		async buscarCobrancaAtualizada(){
			this.$store.state.carregando = true;
			const banco = this.$store.state.ConfiguracaoPixEmpresa[0];

			this.cobrancaAtualizada = await this.$store.dispatch("consultarCobrancaPix", {...banco, txid: this.cobranca.txid});
			this.dataCobranca = this.cobrancaAtualizada.data;
			this.gerarQRCode(this.cobrancaAtualizada.qrcode);
			this.$store.state.carregando = false;
		},
		async gerarQRCode(text){
			this.url = await QRCode.toDataURL(text);
		},
	},
	mounted(){
		this.buscarCobrancaAtualizada();
	}
};
</script>
<style scoped>
.botoes{
	justify-content: space-between;
}
.botoes .botao{
	width: 45%;
}.input-group{
	width: 30%;
}
.containerPage{
	width: 100%;
	height: auto;
	overflow: auto;
}
.containerInformacoes{
	width: 100%;
	padding: 10px;
	margin: auto;
}

.fieldsetLinha{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 10px;
	margin: auto;
}
.fieldsetLinha .inputPequeno{
	width: 120px;
	text-align: right;
}
.fieldsetItem{
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	width: 30%;
	margin: 0px 10px;
}
.fieldsetItem input{
	width: 100%;
}
.fieldsetItem label,
.fieldsetItem input,
.fieldsetItem span{
	margin-left: 10px;
}

.containerQRCode{
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 60vh;
}
.buttons{
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.buttons button{
	width: 50%;
}

.containerModal{
	width: 100%;
	padding: 10px;
}
.containerContent{
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.containerModal .buttons{
	margin-top: 10px;
}
#motivo{
	width: 350px;
	margin-left: 5px;
}
#valor{
	width: 140px;
	margin-left: 5px;
	text-align: right;
}
.cancelada{
	background-color: red;
	padding: 0px 6px;
	color: #FFF;
	border-radius: 12px;
}
.ativa{
	background-color: var(--primario);
	color: #FFF;
	padding: 0px 6px;
	border-radius: 12px;
}
.concluida{
	background-color: green;
	color: #FFF;
	padding: 0px 6px;
	border-radius: 12px;
}
.devolvida{
	background-color: rgb(243, 239, 3);
	padding: 0px 6px;
	border-radius: 12px;
}
.containerModalQR{
	display: none;
}
.viewQR{
	display: none;
}
@media screen and (max-width: 1200px){
	.fieldsetItem{
		display: flex;
		flex-direction: row;
		white-space: nowrap;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 5px;
	}
}
@media screen and (max-width: 764px){
	.containerQRCode{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 20px;
		height: 6vh;
	}
	#qrcode{
		display: none;
	}
	.viewQR{
		display: inline;
	}
	.containerModalQR{
		display: inline;
	}
}
@media screen and (max-width: 640px){
	.fieldsetLinha{
		flex-wrap: wrap;
	}
	.containerContent{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

}

@media screen and (max-width: 425px){
	.fieldsetLinha{
		flex-direction: column;
		align-items: center;
	}

	.fieldsetLinha .input-group{
		width: 90%;
		margin:10px;
	}

	.fieldsetLinha .fieldsetItem{
		width: 90%;
	}

	.botoes #botao-modal{
		width: 100% !important;
	}
}
</style>
