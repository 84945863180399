<template>
    <div class="cadastro-produto-aba">
        <div class="cadastro-produto-cabecalho" v-if="PropProduto">
            <h3 style="color: var(--primario);">
                {{PropProduto ? PropProduto.id : ""}}
                &nbsp; - &nbsp;
                {{PropProduto ? PropProduto.descricao : ""}}
            </h3>
        </div>
        <div class="cadastro-produto-lancamento">
			<div class="table-empresas">
				<table>
					<thead>
						<tr>
							<td style="color: blue; text-align: center;">Cód.</td>
							<td style="text-align: left;">Empresa</td>
							<td style="text-align: center;">Ação</td>
						</tr>
					</thead>
					<tbody>
						<tr class="itemLista" v-for="(empresa, index) in Empresas" :key="index">
							<td style="color: blue; text-align: center;"  @click="incluirEmpresaFormacaoPreco(empresa, index)">
								<button :id="index" class="hand-button" v-if="index == indexAtual" @keydown.tab.prevent="" >
									<i class="fas fa-hand-point-right" style="color: var(--primario)" ></i>
								</button>
								{{empresa.id}}
							</td>
							<td style="text-align: left;"  @click="incluirEmpresaFormacaoPreco(empresa, index)">{{empresa.nome}}</td>
							<td style="text-align: center;">
								<input type="checkbox" :id="empresa.id" :checked="empresa.selecionada"  @click="incluirEmpresaFormacaoPreco(empresa, index)">
							</td>
						</tr>
					</tbody>
				</table>
			</div>
            <div class="box-cadastro-tributacao">
                <div class="box-cadastro-container">
                    <div class="box-cadastro-titulo">
                        <h3>Dados</h3>
                    </div>
                    <div class="box-cadastro-dados">
                        <div class="box-cadastro-linha">
                            <div class="input-group">					
                                <input 
									v-model="estETribCadastrado.ncm"
									id="ncm"
									type="text"
									maxlength="8"
									@keydown="mascaraInteger"
									@keydown.enter.prevent="proximoCampo('curvaabc')"
									placeholder=" "
								>
								<label for="ncm">
									NCM
								</label>
                            </div>
                            <div class="input-group">
                                <select
									v-model="estETribCadastrado.curvaabc" 
									id="curvaabc"
									@keydown.enter.prevent="proximoCampo('cdorigem')"
									placeholder=" "
								>
                                    <option :value="null" selected disabled>
										Selecionar
									</option>
                                    <option value="A">
										Curva A
									</option>
                                    <option value="B">
										Curva B
									</option>
                                    <option value="C">
										Curva C
									</option>
                                </select>
								<label for="curvaabc">
									Curva ABC
								</label>
                            </div>
                        </div>
                        <div class="box-cadastro-linha">
                            <div class="input-group" id="saldo-div">
								<div class="icone-correcao">
									<i 
										class="fas fa-cash-register icone" 
										@click="opcaoModal('abrir')"
										v-if="Usuario.usuarioPrivilegiado || Usuario.alteraEstoque"
									> 
									</i>
								</div>
                                <input 
									v-model="estETribCadastrado.saldo" 
									id="saldo" 
									type="text" 
									disabled
								>								
								<label for="saldo">
									Saldo
								</label>								
                            </div>
							
							<div class="input-group">
                                <input 
									v-model="estETribCadastrado.ultsaldo" 
									id="ultsaldo"
									type="text"
									disabled
								>
								<label for="ultsaldo">
									Saldo Anterior
								</label>
                            </div>
							<div class="input-group">
                                <input 
									v-model="estETribCadastrado.qtdcondicional"
									id="saldoCondicional"
									type="text"
									disabled
								>
								<label for="saldoCondicional">
									Condicional
								</label>
                            </div>
                        </div>
                        <div class="box-cadastro-linha">
                            <div class="input-group">
                                <select 
									v-model="estETribCadastrado.cdorigem" 
									id="cdorigem"
									@keydown.enter.prevent="proximoCampo('icmsestsittrib')"
								>
                                    <option :value="null">
										Selecionar
									</option>
                                    <option value="0">
										Nacional, exceto as indicadas nos códigos 3 a 5
									</option>
                                    <option value="1">
										Estrageira Importação direta, exceto a indicada no código 6
									</option>
                                    <option value="2">
										Estrageira Adiquirida no mercado interno, exceto a indicada no código 7
									</option>
                                    <option value="3">
										Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%
									</option>
                                    <option value="4">
										Nacional, cuja produção tenha sido feita em 
										conformidade com os Processos Produtivos Básicos
									</option>
                                    <option value="5">
										Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%
									</option>
                                    <option value="6">
										Estrageira Importação direta, sem similar nacional, 
										constante em lista de Redução CAMEX
									</option>
                                    <option value="7">
										Estrageira Adiquirida no mercado interno, sem similar nacional, 
										constante em lista de Resolução CAMEX
									</option>
                                </select>
								<label for="cdorigem">
									Origem
								</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cadastro-produto-tributacao">
            <div class="box-cadastro-tributacao">
                <div class="box-cadastro-container">
                    <div class="box-cadastro-titulo">
                        <h3>ICMS no Estado</h3>
                    </div>
                    <div class="box-cadastro-dados">
                        <div class="box-cadastro-linha">
                            <div class="input-group">
                                <input
									v-model="estETribCadastrado.icmsestsittrib"
									id="icmsestsittrib"
									type="text"
									maxlength="3"
									class="input-imposto"
									@keydown.enter.prevent="proximoCampo('cfopesta')"
									@keydown="mascaraInteger"
								>
								<label for="icmsestsittrib">
									Situação Tributária
								</label>
                            </div>
                        </div>
                        <div class="box-cadastro-linha">
                            <div class="input-group">
                                <input 
									v-model="estETribCadastrado.cfopesta"
									id="cfopesta"
									type="text"
									maxlength="4"
									class="input-imposto"
									@keydown.enter.prevent="proximoCampo('icmsest')"
									@keydown="mascaraInteger"
								>
								<label for="cfopesta">
									CFOP
								</label>
                            </div>
                            <div class="input-group">
                                <input
									v-model="estETribCadastrado.icmsest"
									id="icmsest"
									type="text"
									class="input-imposto"
									@keydown.enter.prevent="proximoCampo('icmsintsittrib')"
									@keydown="mascaraFloat"
								>
								<label for="icmsest">
									ICMS
								</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-cadastro-container">
                    <div class="box-cadastro-titulo">
                        <h3>
							ICMS INTERESTADUAL
						</h3>
                    </div>
                    <div class="box-cadastro-dados">
                        <div class="box-cadastro-linha">
                            <div class="input-group">
                                <input
									v-model="estETribCadastrado.icmsintsittrib"
									id="icmsintsittrib"
									type="text"
									maxlength="3"
									class="input-imposto"
									@keydown.enter.prevent="proximoCampo('cfopinter')"
									@keydown="mascaraInteger"
								>
								<label for="icmsintsittrib">
									Situação Tributária
								</label>
                            </div>
                        </div>
                        <div class="box-cadastro-linha">
                            <div class="input-group">
                                <input 
									v-model="estETribCadastrado.cfopinter"
									id="cfopinter"
									type="text"
									maxlength="4"
									class="input-imposto"
									@keydown.enter.prevent="proximoCampo('icmsint')"
									@keydown="mascaraInteger"
								>
								<label for="cfopinter">
									CFOP
								</label>
                            </div>
                            <div class="input-group">
                                <input
									v-model="estETribCadastrado.icmsint"
									@keydown="mascaraFloat"
									id="icmsint"
									type="text"
									class="input-imposto"
									@keydown.enter.prevent="proximoCampo('pissittrib')"
								>
								<label for="icmsint">
									ICMS
								</label>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-cadastro-tributacao">
                <div class="box-cadastro-container">
                    <div class="box-cadastro-titulo">
                        <h3>IMPOSTO FEDERAL</h3>
                    </div>
                    <div class="box-cadastro-dados">
                        <div class="box-cadastro-linha">
                            <div class="input-group">                                
                                <input
									v-model="estETribCadastrado.pissittrib"
									id="pissittrib"
									type="text"
									maxlength="3"
									class="input-imposto"
									@keydown.enter.prevent="proximoCampo('pistributacao')"
									@keydown="mascaraInteger"
								>
								<label for="pissittrib">
									Sit. Trib. PIS
								</label>
							</div>
                            <div class="input-group">                                
                                <input 
									v-model="estETribCadastrado.pistributacao"
									@keydown="mascaraFloat"
									id="pistributacao"
									type="text"
									class="input-imposto"
									@keydown.enter.prevent="proximoCampo('cofinssittrib')"
								>
								<label for="pistributacao">
									PIS
								</label>
                            </div>
                        </div>
                        <div class="box-cadastro-linha">
                            <div class="input-group">
                                <input
									v-model="estETribCadastrado.cofinssittrib"
									id="cofinssittrib"
									type="text"
									maxlength="3"
									class="input-imposto"
									@keydown.enter.prevent="proximoCampo('cofinstributacao')"
									@keydown="mascaraInteger"
								>
								<label for="cofinssittrib">
									Sit. Trib. COFINS
								</label>
                            </div>
                            <div class="input-group">
                                <input 
									v-model="estETribCadastrado.cofinstributacao"
									@keydown="mascaraFloat"
									id="cofinstributacao"
									type="text"
									class="input-imposto"
									@keydown.enter.prevent="proximoCampo('ipisittrib')"
								>
								<label for="cofinstributacao">
									COFINS
								</label>
                            </div>
                        </div>
                        <div class="box-cadastro-linha">
                            <div class="input-group">
                                <input 
									v-model="estETribCadastrado.ipisittrib"
									id="ipisittrib"
									type="text"
									maxlength="3"
									class="input-imposto"
									@keydown.enter.prevent="proximoCampo('ipitributacao')"
									@keydown="mascaraInteger"
								>
								<label for="ipisittrib">
									Sit. Trib. IPI
								</label>
                            </div>
                            <div class="input-group">
                                <input 
									v-model="estETribCadastrado.ipitributacao"
									@keydown="mascaraFloat"
									id="ipitributacao"
									type="text"
									class="input-imposto"
									@keydown.enter.prevent="proximoCampo('btn-salvar-estoque')"
								>
								<label for="ipitributacao">
									IPI
								</label>                                
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="botoes">
            <button @click="cancelarEdicao" class="botao botao-cancelar">
				<label for="botaoCancelar" class="lg-view">Cancelar [Alt + C]</label>
				<label for="botaoCancelar" class="sm-view">Cancelar</label>
            </button>
            <button @click="adicionarEstoqueETributacao" id="btn-salvar-estoque" class="botao botao-adicionar">
				<label for="botaoAdicionar" class="lg-view">Salvar [Alt + S]</label>
				<label for="botaoAdicionar" class="sm-view">Salvar</label>
            </button>
        </div>
		<Modal v-if="modal == 1" v-on:fecharModal="opcaoModal" :idModal="'saldoModal'" :dimensoes="{width: '400px', height: '220px'}">
			<div class="modal-container">
				<div class="content">
					<div class="title">
						<label for="alteraSaldo">Alterar Saldo</label>
					</div>
					<div class="input-container">
						<i class="fas fa-minus botao botao-acao" @click="plusLess('menos')" style="user-select: none;margin-top: 0px;margin-bottom: 0px"></i>
						<input type="text" style="text-align: center;" maxlength="10" v-model="alteraSaldo"
						@keydown="mascaraFloat" id="alteraSaldo" @focus="e => e.target.select()"
						@keydown.up.prevent="plusLess('mais')" @keydown.down.prevent="plusLess('menos')">
						<i class="fas fa-plus botao botao-acao" @click="plusLess('mais')" style="user-select: none;margin-top: 0px;margin-bottom: 0px"></i>
					</div>
					<div class="botoes" style="display: flex; flex-direction: row;gap:20px; width: 300px;">
						<button class="botao botao-cancelar"  @click="opcaoModal('fechar')" style="margin-right: 0px;margin-left: 0px;">Cancelar</button>
						<button class="botao botao-adicionar"  @click="salvarSaldo" style="margin-right: 0px;margin-left: 0px;">Alterar</button>
					</div>
				</div>
			</div>
		</Modal>
    </div>
</template>
<script>
import { FormacaoPreco } from "../models/Produto.js";

import Modal from "./Modal.vue";
export default {
	name: "EstoqueETributacao",
    
	props: {
		PropProduto: Object,
	},
	components:{
		Modal,
	},
	computed: {
		Empresas() {
			return this.$store.state.Empresas;
		},
		Empresa() {
			return this.$store.state.Empresa;
		},

		Usuario() {
			return this.$store.state.Usuario;
		},

		Precos() {
			return this.$store.state.PrecoAll;
		}
	},

	data(){
		return {
			modal: 0,
			alteraSaldo: "",
			estETribCadastrado: {
				ncm: "",
				saldo: "",
				saldoCondicional: "",
				ultsaldo: "",
				novoultsaldo: "",
				curvaabc: null,
				cdorigem: 0,
				icmsestsittrib: "",
				cfopesta: "",
				icmsest: "",
				icmsintsittrib: "",
				cfopinter: "",
				icmsint: "",
				pissittrib: "",
				pistributacao: "",
				cofinssittrib: "",
				cofinstributacao: "",
				ipisittrib: "",
				ipitributacao: "",
				cfopintercompra: "",
				cfopestacompra: "",
				qtdcondicional: ""
			},
			indexAtual: 0
		};
	},

	methods: {
		proximoCampo(id) {
			try {
				if (!id) {
					throw new Error("Falta o atribuito id!");
				}
				setTimeout(() => {
					const input = document.getElementById(id);
					if (input) {
						input.focus();
						if (input.select) {
							input.select();
						}
					}
				},20);
			} catch (error) {
				console.log(error);
			}
		},
		mascaraInteger(e) {
			const regex = /[0-9]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
		},
		mascaraFloat(e) {
			const regex = /[0-9,.]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
		},
		plusLess(element){
			let saldo = Number(`${this.alteraSaldo || 0}`.split(".").join("").replace(",", ".")) || 0;
			if (element == "menos") {
				if (saldo - 1 < 0) return;
				saldo --;
			}
			if (element == "mais") {
				saldo ++;
			}
			this.alteraSaldo = saldo.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		},
		async salvarSaldo(){
			const saldo = Number(`${this.alteraSaldo || 0}`.split(".").join("").replace(",", ".")) || 0;
			const data = await this.$store.dispatch("alterarSaldo", {saldo, cdproduto: this.PropProduto.id});
			this.estETribCadastrado.ultsaldo = data.ultsaldo;
			this.estETribCadastrado.saldo = data.saldo;
			this.modal = 0;
		},
		opcaoModal(modal){
			if (modal == "abrir") {
				this.modal = 1;
				this.alteraSaldo = Number(this.estETribCadastrado.saldo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			} else {
				this.modal = 0;
			}
			
		},
		async adicionarEstoqueETributacao() {
			if (!this.estETribCadastrado.ncm) {
				return this.$store.commit("relatarErro", {mensagem: "Campo NCM não pode estar vazio", alerta: true});
			}
			let formacaoPreco = {};
			this.Empresas.forEach(empresa => {
				if (document.getElementById(empresa.id).checked) {
					formacaoPreco[empresa.id] = new FormacaoPreco({
						...this.PropProduto,
						...this.estETribCadastrado,
						ultsaldo: this.estETribCadastrado.novoultsaldo != this.estETribCadastrado.saldo ? (this.estETribCadastrado.novoultsaldo || 0) : (this.estETribCadastrado.ultsaldo || 0)
					});
				}
			});
			await this.$store.dispatch("formarPreco", {formacaoPreco, id: this.PropProduto.id});
			setTimeout(() => {
				this.$emit("salvarProduto", true);
			}, 50);
		},

		cancelarEdicao() {
			this.$emit("mudarAba", {target: {id: "formacaoPreco" }});
		},

		async preencherNcm(produto) {
			if (!produto.ncm && produto.cdgrupo) {
				const grupoBanco = await this.$store.dispatch("buscarUnicoGrupo", produto.cdgrupo);
				if (!grupoBanco) {
					this.$store.commit("relatarErro", {mensagem: "Grupo não encotrado!"});
				} else {
					if (produto.cdsubgrupo) {
						const subgrupo = grupoBanco.subGrupos.find(sub => sub.cdsubgrupo == produto.cdsubgrupo);
						if (subgrupo?.ncm) {
							produto.ncm = subgrupo.ncm;
						}
					}
					if (!produto.ncm && grupoBanco.ncm) {
						produto.ncm = grupoBanco.ncm;
					}
				}
			}
		},

		async preencherSituacaoTributaria (produto) {
			// Pegar dados de tributação do padrão da empresa
			// Estadual
			if (!produto.icmsestsittrib && this.Empresa.sittribest) {
				produto.icmsestsittrib = this.Empresa.sittribest;
			}
			if (!produto.cfopesta && this.Empresa.cfopesta) {
				produto.cfopesta = this.Empresa.cfopesta;
			}
			if (!produto.icmsest && (this.Empresa.icmsest >= 0)) {
				produto.icmsest = this.Empresa.icmsest;
			}
			if (produto.icmsestsittrib == "103") {
				produto.icmsest = 0;
			}
			// Interestadual
			if (!produto.icmsintsittrib && this.Empresa.sittribint) {
				produto.icmsintsittrib = this.Empresa.sittribint;
			}
			if (!produto.cfopinter && this.Empresa.cfopinter) {
				produto.cfopinter = this.Empresa.cfopinter;
			}
			if (!produto.icmsint && (this.Empresa.icmsint >= 0)) {
				produto.icmsint = this.Empresa.icmsint;
			}
			if (produto.icmsintsittrib == "103") {
				produto.icmsint = 0;
			}
			// Federal
			if (!produto.pissittrib && this.Empresa.pissittrib) {
				produto.pissittrib = this.Empresa.pissittrib;
			}
			if (!produto.pistributacao && (this.Empresa.pis >= 0)) {
				produto.pistributacao = this.Empresa.pis;
			}
			if (!produto.cofinssittrib && this.Empresa.cofinssittrib) {
				produto.cofinssittrib = this.Empresa.cofinssittrib;
			}
			if (!produto.cofinstributacao && (this.Empresa.cofins >= 0)) {
				produto.cofinstributacao = this.Empresa.cofins;
			}
			if (!produto.ipisittrib && this.Empresa.ipisittrib) {
				produto.ipisittrib = this.Empresa.ipisittrib;
			}
			if (!produto.ipitributacao && (this.Empresa.ipi >= 0)) {
				produto.ipitributacao = this.Empresa.ipi;
			}
			if (!produto.cfopintercompra && this.Empresa.cfopintercompra) {
				produto.cfopintercompra = this.Empresa.cfopintercompra;
			}
			if (!produto.cfopestacompra && this.Empresa.cfopestacompra) {
				produto.cfopestacompra = this.Empresa.cfopestacompra;
			}
		},

		async montarDados() {
			this.$store.state.carregando = true;
			let cdempresa;
			if(this.PropProduto.empresas?.length > 0){
				cdempresa = this.PropProduto.empresas[0].cdempresa; 
			}
			const produto = await this.$store.dispatch("selecionarProdutoPorEmpresa", {id: this.PropProduto.id, cdempresa: cdempresa? cdempresa : this.PropProduto.cdEmpresa});
			if (!produto) {
				return this.$store.state.carregando = false;
			}
			await this.preencherNcm(produto);
			await this.preencherSituacaoTributaria(produto);
			setTimeout(async () => {
				this.estETribCadastrado = {
					ncm: produto.ncm,
					saldo: produto.saldo,
					ultsaldo: produto.ultsaldo,
					novoultsaldo: produto.saldo,
					curvaabc: produto.curvaabc,
					cdorigem: produto.cdorigem || 0,
					icmsestsittrib: produto.icmsestsittrib,
					cfopesta: produto.cfopesta,
					icmsest: produto.icmsest,
					icmsintsittrib: produto.icmsintsittrib,
					cfopinter: produto.cfopinter,
					icmsint: produto.icmsint,
					pissittrib: produto.pissittrib,
					pistributacao: produto.pistributacao,
					cofinssittrib: produto.cofinssittrib,
					cofinstributacao: produto.cofinstributacao,
					ipisittrib: produto.ipisittrib,
					ipitributacao: produto.ipitributacao,
					cfopintercompra: produto.cfopintercompra,
					cfopestacompra: produto.cfopestacompra
				};
				this.buscarFilhos(this.PropProduto).then(()=>{
					if(this.$store.state.produtoFilhos.length > 0){
						let saldo = 0;
						this.$store.state.produtoFilhos.forEach((produto)=>{
							saldo += produto.saldo;
						});
						this.estETribCadastrado.saldo = saldo;
					}
				});
				this.$store.state.carregando = false;
				await this.preencherPrecos();
			}, 500);
		},

		incluirEmpresaFormacaoPreco(empresa, index) {
			this.Empresas[index].selecionada = !this.Empresas[index].selecionada;
			this.indexAtual = index;
			this.$forceUpdate();
		},

		capturarEventosTeclado(e) {
			if (`${e.key}`.toUpperCase() == "S" && e.altKey) {
				e.preventDefault();
				this.adicionarEstoqueETributacao();
			} else if (`${e.key}`.toUpperCase() == "C" && e.altKey) {
				e.preventDefault();
				this.cancelarEdicao();
			} else if (e.key == "ArrowUp") {
				if (this.indexAtual > 0) {
					this.indexAtual--;
				}
				e.preventDefault();
				setTimeout(() => {
					document.getElementById(this.indexAtual).focus();
				}, 10);
			} else if (e.key == "ArrowDown") {
				if (this.indexAtual < this.Empresas.length -1) {
					this.indexAtual++;
				}
				e.preventDefault();
				setTimeout(() => {
					document.getElementById(this.indexAtual).focus();
				}, 10);
			}
		},
		
		async preencherPrecos() {
			await this.$store.dispatch("buscarProdutoPorIdAllEmp", this.PropProduto.id);
			this.estETribCadastrado.qtdcondicional = this.Precos.find(preco => preco.cdempresa === this.PropProduto.cdEmpresa);
			this.preencherQtdCondicionalEmpresaConectada();
		},

		preencherQtdCondicionalEmpresaConectada() {
			this.estETribCadastrado.qtdcondicional = this.Precos.find(preco => preco.cdempresa === this.PropProduto.cdEmpresa).granulado;
		},

		async buscarFilhos(produto) {
			this.$store.state.carregando = true;
			await this.$store.dispatch("buscarFilhos", {
				pai: produto.id
			});
			this.$forceUpdate();
			this.$store.state.carregando = false;
		},
	},

	beforeMount() {
		if (this.Empresas.length < 1) {
			this.$store.dispatch("buscarEmpresas");	
		}
	},

	mounted() {
		if (!this.PropProduto?.id) {
			this.$emit("mudarAba", {target: {id: "dados" }});
			return this.$store.commit("relatarErro", {mensagem: "É necessário cadastrar dados do produto!", alerta: true});
		}
		this.montarDados();
		document.addEventListener("keydown", this.capturarEventosTeclado);
		setTimeout(() => {
			this.indexAtual = this.Empresas.map(emp => `${emp.id}`).indexOf(`${this.$store.state.Empresa.id}`);
			if (!this.indexAtual >= 0) {
				this.Empresas[this.indexAtual].selecionada = true;
			}
			this.proximoCampo("ncm");
		}, 150);
		setTimeout(() => {
			// if(this.Precos?.length > 0) {
			// 	const prod = this.Precos?.find(p => p.cdempresa === this.EmpresaSel.id);
			// 	console.log(prod, "aqui dentro");
			// 	if(prod){
			// 		this.SelecionaDados(prod);
			// 	} else {
			// 		this.SelecionaDados(this.PropProduto);
			// 	}
			// }
		}, 200);
	},

	destroyed() {
		document.removeEventListener("keydown", this.capturarEventosTeclado);
		this.$store.commit("atualizarProdutosAll", []);

	}
};
</script>

<style scoped>
.input-group{
	margin:5px;
}
.icone-correcao{
	position: absolute;
	margin-left: 80%;
	border:none;
	margin-top:10px;
}
.botao{	
	margin:10px;
}
.cadastro-produto-cabecalho {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--bg-app);
    width: 100%;
}
.cadastro-produto-cabecalho h3 {
    margin: 5px;
}
.cadastro-produto-lancamento {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0px;
    width: 100%;

}
.cadastro-produto-lancamento .table-empresas {
	display: flex;
    border: 2px solid slategrey;
	padding: 5px;
	justify-content: center;
	align-items: flex-start;
	overflow-y: auto;
	flex-grow: 1;
    margin: 5px;
    order: 2;
}

table{
	width: 100%;
}
.cadastro-produto-lancamento .box-cadastro-tributacao {
    width: 50%;
}
.cadastro-produto-tributacao {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.box-cadastro-tributacao {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align:  center;
}

.box-cadastro-container {
    display: flex;
    flex-direction: column;
    width: 100%;
	height: auto;
    text-align:  center;
    border: 2px solid slategrey;
    margin: 5px;
}
.box-cadastro-titulo {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
}
.box-cadastro-titulo h3 {
    margin: 0px auto;
}
.box-cadastro-linha {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
.box-cadastro-dados {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.cadastro-dados {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 5px;
}
.cadastro-dados label {
    white-space: nowrap;
}
.cadastro-dados input,
.cadastro-dados select {
    width: 100%;
}
.cadastro-dados .input-imposto {
    min-width: 65px;
    width: 100%;
}
.cadastro-produto-lancamento {
    display: flex;
    flex-direction: row;
}

.modal-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 10px;
	max-width: 240px;
	margin: 5px auto;
}
.modal-container .content {
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: space-between;
}
.modal-container .buttons{
	gap: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	margin-top: 20px;
}

.modal-container .buttons .button{
	padding: 5px;
	display: flex;
	width: 100%;
	align-items: center;
	align-content: center;
	text-align: center;
}
.input-container{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.input-container input{
	width: 60px;
	border-top: none;
	border-left: none;
	border-right: none;
	outline: none;
}

.input-container .icone{
	width: 50px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	height: 30px;
	border-radius: 10px;
	color: #fff;
	background-color: var(--primario);
}

.modal-container .title{
	text-align: center;
	position: relative;
	top: -20px;
}
.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}

.botoes .botao {
	width: 45%;
}

.lg-view {
	display: inline-block;
}

.sm-view {
	display: none;
}
@media screen and (max-width: 1023px) {
    .cadastro-produto-lancamento,
    .cadastro-produto-tributacao {
        flex-direction: column;
    }
    .cadastro-produto-lancamento table {
        order: 1;
    }
    .cadastro-produto-lancamento .box-cadastro-tributacao {
        width: 100%;
        order: 2
    }
    .cadastro-produto-lancamento {
        flex-direction: column;
    }
    .box-cadastro-tributacao{
		margin-top: 10px;
	}
}
@media screen and (max-width:768px) {
	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}
}
@media screen and (max-width: 530px) {
    .cadastro-produto-tributacao .box-cadastro-tributacao {
        flex-wrap: wrap;
    }
    .botoes span {
        display: none;
    }
    .botoes button {
        font-size: 12pt;
    }
	.box-cadastro-container{
		height: auto;
	}
}

@media screen and (max-width: 442px) {
    .box-cadastro-linha {
        flex-wrap: wrap;
    }
    .cadastro-produto-lancamento .box-cadastro-container .box-cadastro-linha:nth-child(3) .cadastro-dados select {
        min-width: 120px !important;
    }
    .botoes i {
        display: none;
    }
	.botoes{
		margin-bottom: 20px;
	}
	#cdorigem{
		min-width: 280px !important;
	}
}




.campos {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.imposto {
    width: 50%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.dados {
    width: 50%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}


.TelaPreco{
    margin: 10px 5px 20px 5px;
    padding: 10px 5px 20px 5px;    
    height: auto;
    display: flex;
    flex-direction: row;    
    flex-flow: wrap;
    border: 2px solid steelblue; 

}

.icone {
	color: var(--primario);
	font-size: 18px;
}

.margem {
    margin: 10px 5px 20px 5px;
    padding: 10px 5px 20px 5px;    
}
</style>