export class Usuario {
	constructor(usuario) {
		this.id = usuario.id;
		this.cdperfil = usuario.cdperfil;
		this.nome = usuario.nome;
		this.empresa = usuario.empresa;
		this.ativo = usuario.ativo;
		this.login = usuario.login;
		this.cargo = usuario.cargo;
		this.maxDiasTroca = usuario.maxDiasTroca;
		this.maxNumeroParcelas = usuario.maxNumeroParcelas;
		this.maxPorcentagemDesconto = usuario.maxPorcentagemDesconto;
		this.minValorParcela = usuario.minValorParcela;
		this.cdVendedor = usuario.cdVendedor;
		if (usuario.parametros) {
			Object.keys(usuario.parametros).forEach(key => {
				this[key] = usuario.parametros[key] == "S";
			});
		}
		if (this.usuarioPrivilegiado) {
			this.supervisor = true;
			this.caixa = true;
		} else if (this.supervisor) {
			this.caixa = true;
		}
	}
}

export class Usuarios {
	constructor (usuario) {
		this.id = usuario.id;
		this.cdperfil = usuario.cdperfil;
		this.nome = usuario.nome;
		this.empresa = usuario.empresa;
		this.ativo = usuario.ativo;
		this.login = usuario.login;
		this.cargo = usuario.cargo;
		this.maxDiasTroca = usuario.maxDiasTroca;
		this.maxNumeroParcelas = usuario.maxNumeroParcelas;
		this.maxPorcentagemDesconto = usuario.maxPorcentagemDesconto;		
		this.comissaoVista = usuario.comissaoVista;
		this.comissaoPrazo = usuario.comissaoPrazo;
		this.comissaoConsorcio = usuario.comissaoConsorcio;
		this.minValorParcela = usuario.minValorParcela;
		this.cdVendedor = usuario.cdVendedor;
		this.parametros = {};
		if (usuario.parametros) {
			Object.keys(usuario.parametros).forEach(key => {
				this.parametros[key] = usuario.parametros[key];
			});
		}
	}
}

export class ListaUsuarios {
	content = [];
	size = 25;
	paginaAtual = 0;
	totalPaginas = 0;

	constructor(body) {
		if (body?.content) {
			this.content = body.content.map(usuario => new Usuarios(usuario));
			this.size = body.size;
			this.paginaAtual = body.page;
			this.totalPaginas = body.totalPages;
		}
	}
}

export class UsuarioAPI {
	constructor(usuario = new Usuarios({})) {
		this.ativo = usuario.ativo;
		this.cdperfil = usuario?.cdperfil;
		this.cargo = usuario.cargo;
		this.empresa = usuario.empresa;
		this.nome = `${usuario.nome}`.toUpperCase();
		this.login = `${usuario.login}`.toUpperCase();
		this.maxDiasTroca = Number(`${usuario.maxDiasTroca}`.replace(".", ""));
		this.maxNumeroParcelas = Number(`${usuario.maxNumeroParcelas}`.replace(".", ""));
		this.maxPorcentagemDesconto = Number(`${usuario.maxPorcentagemDesconto}`.replace(".", "").replace(",", "."));
		this.minValorParcela = Number(`${usuario.minValorParcela}`.replace(".", "").replace(",", "."));
		this.comissaoVista = Number(`${usuario.comissaoVista}`.replace(".", "").replace(",", "."));
		this.comissaoPrazo = Number(`${usuario.comissaoPrazo}`.replace(".", "").replace(",", "."));
		this.comissaoConsorcio = Number(`${usuario.comissaoConsorcio}`.replace(".", "").replace(",", "."));
		if (usuario.parametros) {
			Object.keys(usuario.parametros).forEach(key => {
				this[key] = typeof usuario.parametros[key] == "boolean" ? usuario.parametros[key] ? "S" : "N" : usuario.parametros[key];
			});
		}
	}
}