<template>
	<div id="gridPrincipalCompras">
		<div :class="`${!showTable ? 'table-full' : ''}`" id="listaModal">
			<div v-if="listaconsorcio.length">
				<div class="tabela-consorcio">
					<table class="w-100">
						<thead>
							<tr>
								<td style="text-align: center; min-width: 60px; max-width: 60px;">Cód.</td>
								<td style="color: var(--primario)">Data</td>
								<td style="min-width: 200px;">Cliente</td>
								<td>Vendedor</td>
								<td>Status</td>
								<td>Parcelas</td>
								<td style="white-space: nowrap">Nº Pagas</td>
								<td class="center">Total</td>
								<td class="center">Recebido</td>
								<td class="center">Liberado</td>
								<td class="center">Utilizado</td>
								<td class="center">Ação</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(Consorcio, index) in listaconsorcio" :key="index" class="itemtable"
								style="max-height: 15px;">
								<td style="text-align: center;">{{ Consorcio.cdconsorcio }}</td>
								<td style="text-align: left;">
									{{ new Date(new Date(Consorcio.data).getFullYear(), new
											Date(Consorcio.data).getMonth(), new
												Date(Consorcio.data).getDate() + 1).toLocaleDateString("pt-BR")
									}}
								</td>
								<td style="text-align: left; white-space: nowrap;">{{ `${Consorcio.cliente.id} -
																${Consorcio.cliente.nome}`
								}}</td>
								<td style="text-align: left; white-space: nowrap;"
									@click="solicitaAlterarVendedor(Consorcio)">{{ `${Consorcio.vendedor.id} -
																	${Consorcio.vendedor.nome}`
									}}</td>
								<td style="text-align: left;">
									{{ Consorcio.status == "A" ? "Aberto" : Consorcio.status == "C" ? "Cancelado" :
											Consorcio.status == "L" ? "Liberado" : Consorcio.status == "R" ? "Recebeu" :
												Consorcio.status
									}}
								</td>
								<td style="text-align: right;">{{ (Number(Consorcio.qtdeParcela ||
										0).toFixed(2).toString().replace(".", ","))
								}}</td>
								<td style="text-align: right;">{{ (Number(Consorcio.parcelasPago ||
										0).toFixed(2).toString().replace(".", ","))
								}}</td>
								<td style="text-align: right;">{{ (Number(Consorcio.valor ||
										0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
								}}</td>
								<td style="text-align: right;">{{ (Number(Consorcio.valorPago ||
										0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
								}}</td>
								<td style="text-align: right;">{{ (Number(Consorcio.liberado ||
										0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
								}}</td>
								<td style="text-align: right;">{{ (Number(Consorcio.utilizado ||
										0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
								}}</td>
								<td style="text-align: center; white-space: nowrap;">
									<i class="fas fa-print" style="color: var(--primario)"
										@click="solicitarImpressao(Consorcio)"></i>
									<i class="fas fa-edit" style="color: var(--confirmar)"
										@click="solicitaEditarConsorcio(Consorcio)"></i>
									<i class="fas fa-trash-alt" style="color: var(--deletar)"
										@click="solicitaCancelarConsorcio(Consorcio)"></i>
								</td>
							</tr>
						</tbody>
					</table>
				</div>			
			</div>
			<div v-else id="conteudo">
				<div class="tela-consulta-vazia">
					<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
					<h1>Nenhum consóricio encontrado.</h1>
					<h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
				</div>
			</div>
		</div>
		<div class="total-valor" >
			<div class="input-group" style="max-width: 200px;">
				<input type="text" readonly :value="`${totalConsorcio.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
				<label>Total: </label>
			</div>
		</div>
		<Modal v-if="consorcioLiberar" v-on:fecharModal="consorcioLiberar = null"
			:dimensoes="{ width: '280px', height: '350px' }">
			<div class="modal-liberar-consorcio">
				<div class="col w-100">
					<h3>Consórcio: {{ consorcioLiberar.cdconsorcio }}</h3>
					<h3>Valor total: {{ (consorcioLiberar.valor ||
							0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
					}}</h3>
					<h3>Valor liberado: {{ (consorcioLiberar.liberado ||
							0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
					}}</h3>
				</div>
				<div class="liberar-consorcio input-group">
					<input type="text" id="valorLiberar"
						v-model="valorLiberar" @input="mascara" class="text-right" style="font-size: 12pt"
						@keydown.enter="liberarConsorcio">
					<label for="valorLiberar">Valor a liberar:</label>
				</div>
				<div class="botoes botoes-modal">
					<button class="botao botao-adicionar botao-modal" @click="liberarConsorcio">Liberar</button>
					<button class="botao botao-cancelar botao-modal"
						@click="consorcioLiberar = null; valorLiberar = ''">Cancelar</button>
				</div>
			</div>
		</Modal>		
	</div>
</template>


<script>
import Modal from "./Modal.vue";
import { Financeiro } from "../models/Financeiro.js";
export default {
	name: "TabelaConsorcio",	
	props: {
		showTable: Boolean,
	},
	data() {
		return {			
			consorcioLiberar: null,
			valorLiberar: "",
		};

	},
	computed: {
		listaconsorcio() {
			return this.$store.state.consorcio;
		},
		totalConsorcio() {
			let preco = 0;
			this.listaconsorcio.forEach(item => {
				preco += item.valor;
			});
			return preco;
		}
	},

	components: {
		Modal,
	},


	methods: {		
		liberarConsorcio() {
			if (!this.valorLiberar) return;
			this.valorLiberar = typeof (this.valorLiberar) == "string" ? Number(this.valorLiberar.replace(",", ".")) : this.valorLiberar;
			if (this.valorLiberar > this.consorcioLiberar.valor) {
				return this.$store.commit("relatarErro", { mensagem: "Você está tentando liberar um valor maior do que o total, verifique!" });
			}
			this.$store.dispatch("liberarValorConsorcio", {
				cdconsorcio: this.consorcioLiberar.cdconsorcio,
				cdempresa: this.consorcioLiberar.cdempresa,
				valorLiberar: this.valorLiberar
			});
			this.consorcioLiberar = null;
			this.valorLiberar = "";
			this.$emit("filtrarConsorcio");
		},
		solicitaEditarConsorcio(consorcio) {
			if (consorcio.status == "C") {
				return setTimeout(() => {
					this.$store.commit("relatarErro", { mensagem: "Você está tentando liberar um consórcio que está cancelado, verifique!" });
				}, 50);
			}
			this.consorcioLiberar = consorcio;
			setTimeout(() => {
				document.getElementById("valorLiberar").focus();
			}, 50);
		},
		cancelarConsorcio(consorcio) {
			if (consorcio.valor == consorcio.utilizado) {
				return this.$store.commit("relatarErro", { mensagem: "Consórcio já foi totalmente utilizado, verifique!" });
			}
			this.$store.dispatch("cancelarConsorcio", consorcio);
			this.$emit("filtrarConsorcio");
		},
		mascara(evento) {
			const id = evento.target.id;
			const input = document.getElementById(id);
			const v = input.value;
			var er = /[^0-9.,]/;
			er.lastIndex = 0;
			if (er.test(v)) {
				return this[id] = this[id].substring(0, v.length - 1);
			}
		},
		solicitaCancelarConsorcio(consorcio) {
			if (consorcio.utilizado > consorcio.valorPago) {
				return setTimeout(() => {
					this.$store.commit("relatarErro", { mensagem: "O valor utilizado é maior que o valor pago pelo cliente, impossível cancelar!" });
				}, 50);
			}
			if (consorcio.status == "C") {
				return setTimeout(() => {
					this.$store.commit("relatarErro", { mensagem: "Consórcio já está cancelado, verifique!" });
				}, 50);
			}
			this.$store.commit("relatarErro", {
				mensagem: `Tem certeza que deseja cancelar o consórcio código: ${consorcio.cdconsorcio}?`,
				item: {
					consorcio,
					cancelarConsorcio: true
				},
				tipo: "polar"
			});
		},
		
		confirmarPolar(item) {
			if (item.cancelarConsorcio) {
				this.cancelarConsorcio(item.consorcio);
			}
		},
		solicitarImpressao(consorcio) {
			this.$store.dispatch("gerarComprovanteConsorcio", {
				...consorcio,
				empresa: this.$store.state.Empresa,
				parcelas: consorcio.parcelas.map(conta => new Financeiro(conta)),
			});
		}
	},

	mounted() {
		this.$bus.$on("confirmarPolar", this.confirmarPolar);
	},

	destroyed() {
		this.$bus.$off("confirmarPolar", this.confirmarPolar);
	}
};


</script>
<style scoped>
.input-group input[type="date"]{
	margin-top:2px;
}
.input-group{
	width:100%;
}
.botao-modal{
	width:auto !important;
}
.botoes-modal{
	justify-content:space-between;
}
.tabela-consorcio {
	flex-grow: 1;
	width: 100%;
}
.modal-liberar-consorcio {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
}
.liberar-consorcio {
	display: flex;
	flex-direction: column;
	width: auto;
}
.total-valor {
	display: flex;
    height: max-content;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
}
.total-valor input {
	font-size: 12pt;
	font-weight: bold;
	text-align: right;
	margin: 5px;
}
#listaModal {
    width: 100%;
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;
	height: 60vh !important;
}
#listaModal.table-full{
	height: 77vh !important;
}

@media screen and (max-width: 830px) {
	.filtros {
		position: initial;
	}

	.novo-botao {
		top: unset;
		right: unset;
		bottom: 5px;
		left: 10px;
	}
}

@media screen and (max-width: 768px){
	#listaModal.table-full{
		height: 68vh !important;
	}

	#listaModal{
		height: 43vh !important;
	}
}

@media screen and (max-width: 655px) {
	#gridPrincipalCompras {
		grid-template-rows: 100px auto;
	}

	#painel-controle {
		justify-content: flex-start;
	}

	.filtros {
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.filtro-item {
		justify-content: flex-start;
	}

	.datas {
		width: 100%;
		justify-content: space-between;
	}

	.datas .row.center {
		justify-content: flex-end;
	}

	#campo-filtro-consorcio {
		width: auto;
		flex-grow: 1;
	}
}

@media screen and (max-width: 425px) {
	#campo-filtro-consorcio{
		width: 100%;
	}
	.input-group{
		margin: 5px;
	}
	#gridPrincipalCompras {
		grid-template-rows: 200px auto;
	}
	#listaModal.table-full{
		height: 71vh !important;
	}

	#listaModal{
		height: 30vh !important;
	}
}
@media screen and (max-width: 420px){
	.containerTabela{
		height: 40vh;
	}
}
</style>