import {doRequest} from "./http.js";
import {Vendedor} from "../models/Vendedor.js";
export default {
	state: {
		Vendedores: [],
		paginaVendedoresAtual: -1,
		totalPaginasVendedores: 0,
	},

	actions: {
		async buscarVendedores({commit, state}, payload){
			try {
				let url = `vendedor/all?empresa=${state.Empresa.id}&page=${payload?.page || 0}&size=25&sort=nome&sortOrder=asc`;
				if (!state.Usuario.usuarioPrivilegiado) {
					url += "&cargo=VENDEDOR";
				}
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.content) {
					commit("atualizarListaVendedores", {...data, checkboxPesquisa: payload ? payload?.checkboxPesquisa : true});
				} else {
					commit("atualizarListaVendedores", {
						content: [],
						page: -1,
						totalPages: 0
					});
				}
			} catch (error) {
				console.log(error);
			}
		},
    
		async filtrarVendedores({commit, state}, payload){
			try {
				const {query, page, checkboxPesquisa, nome} = payload;
				let url = `vendedor/filter?empresa=${state.Empresa.id}&`;
				url += `page=${page || 0}`;
				url += "&size=25";
				url += "&sort=nome";
				url += `${query ? `&query=${query}` : "&query="}`;
				if(nome) {
					url += `&query=${nome}`;
				}
				// if (!state.Usuario.usuarioPrivilegiado) {
				// 	url += "&cargo=VENDEDOR";
				// }
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.content) {
					commit("atualizarListaVendedores", {...data, checkboxPesquisa});
				} else {
					commit("atualizarListaVendedores", {
						content: [],
						page: -1,
						totalPages: 0
					});
				}
			} catch (error) {
				console.log(error);
			}
		},

		async adicionarVendedor({commit, state}, payload) {
			try {
				state.carregando = true;
				const body = JSON.stringify(payload);
				let url = "vendedor";
				const data = await doRequest(url, {method: "post", body, cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.id) {
					commit("relatarErro", {mensagem: data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data))});
					return null;
				}
				return new Vendedor(data);
			} catch (error) {
				console.log(error);
				state.carregando = false;
			}
		},

		async atualizarVendedor({commit, state}, payload) {
			try {
				state.carregando = true;
				const body = JSON.stringify(payload);
				let url = `vendedor?id=${payload.id}`;
				const data = await doRequest(url, {method: "put", body,cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.id) {
					commit("relatarErro", {mensagem: data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data))});
					return null;
				}
				return new Vendedor(data);
			} catch (error) {
				console.log(error);
				state.carregando = false;
			}
		},

		async buscarVendedorPorId({state, commit}, payload) {
			try {
				let url = `vendedor?id=${payload}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (!data.id) {
					throw new Error(data.mensagem || (data.message || JSON.stringify(data)));
				}
				return new Vendedor(data);
			} catch (error) {
				console.log(error);
				state.carregando = false;
				return null;
			}
		}
	},

	mutations: {
		atualizarListaVendedores(state, payload){
			try {
				state.Vendedores = [];
				state.Vendedores = !payload.checkboxPesquisa ? payload.content :
					payload.content.filter(vendedor => vendedor.ativo == "A").map(vendedor => new Vendedor(vendedor));
				state.paginaVendedoresAtual = payload.page;
				state.totalPaginasVendedores = payload.totalPages;
			} catch (error) {
				state.mensagemErro = {mensagem: `${error.message} ${error.stack}`};
				console.log(error);
			}
		},

		adicionarVendedor(state, payload) {
			state.Vendedores.push(payload);
		}
	}
};