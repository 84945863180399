<template>
	<section :class="`companies ${!showTable ? 'table-full' : ''}`" id="listaModal">
		<div class="allcompanies">
			<div class="company" v-for="(emp, index) in Empresas" :key="emp.id">
				<div class="image">
					<img src="../assets/logo-transp-tidoc.png" alt="logo-empresa">
				</div>
				<div class="dice">
					<p>{{ emp.id }} - {{ emp.nome }}</p>
					<p>{{ emp.fantasia }}</p>
					<p>{{ emp.cnpj }}</p>
				</div>
				<div class="icon">
					<i class="fas fa-pencil-alt" @click="alterarEmpresa(emp, index)"></i>
					<i v-if="emp.ativo = 'A'" class="fas fa-eye"></i>
					<i v-else class="fa fa-eye-slash"></i>
					<i v-if="emp.id == Empresa.id" class="fas fa-sign-out-alt" @click="sairEmpresa"></i>
					<i v-else class="fas fa-sign-in-alt" @click="loginEmpresa(emp)"></i>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { mapState } from "vuex";
export default {
	name: "ListaEmpresas",
	computed: {
		...mapState([
			"Empresas",
			"Empresa"
		])
	},

	props:{		
		showTable: Boolean,
	},

	methods: {
		alterarEmpresa(empresa, index) {
			this.$emit("solicitouAlterarEmpresa", empresa, index);
		},
		sairEmpresa() {
			this.$emit("solicitouSairEmpresa");
		},
		loginEmpresa(empresa) {
			this.$emit("solicitouLoginEmpresa", empresa);
		}
	},
	beforeMount() {
		this.$store.dispatch("buscarEmpresas");
	}
};
</script>

<style scoped>
#listaModal {
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;
	height: 55vh;
}

#listaModal.table-full{
	height: 70vh !important;
}
.companies {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.companies .allcompanies {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
	padding: 1rem;
	width: calc(100 / 3);
	gap: 1rem;
	overflow: auto;
	overflow-x: hidden;
}
.companies .allcompanies .company {
	display: grid;
	grid-template-columns: 8rem auto 2rem;
	box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.3);
	border-radius: 1rem;
	height: 8rem;
	gap: 0.5rem;
	overflow: hidden;
}
.companies .allcompanies .company .image {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.25rem 0;
	width: 8rem;
}
.companies .allcompanies .company .image img {
	width: 8rem;
}
.companies .allcompanies .company .dice {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: flex-start;
	font-size: 1rem;
	margin: auto 0;
}
.companies .allcompanies .company .icon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 2rem;
}
.companies .allcompanies .company .icon i {
	padding: 0.25rem 0;
	font-size: 1.5rem;
	color: var(--primario);
	display: flex;
	justify-content: flex-end;
}
.companies .allcompanies .company .icon i:nth-child(1) {
	color: var(--confirmar);
}
.companies .allcompanies .company .icon i:nth-child(2) {
	color: var(--confirmar);
}
@media screen and (min-width: 650px) and (max-width: 1335px) {
	.companies .allcompanies {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	.companies .allcompanies .company .dice {
		font-size: 0.9rem;
	}
}

@media screen and (max-width: 800px){
	.companies .allcompanies{
		display: block;
	}
	.company{
		margin-top: 10px;
	}
}

@media screen and (max-width: 768px) {
	#listaModal.table-full{
		height: 68vh !important;
	}

	#listaModal{
		height: 42vh !important;
	}
}

@media screen and (max-width: 500px) {
	.companies {
		align-content: flex-start;
	}
	.companies .allcompanies .company .dice {
		font-size: 1rem;
	}
	.companies .allcompanies .company .image img {
		width: 5rem;
	}
	
}

@media screen and (max-width: 425px) {
	#listaModal.table-full{
		height: 71vh !important;
	}

	#listaModal{
		height: 30vh !important;
	}
}

@media screen and (max-width: 320px) {
	.company {
		overflow-x: auto !important;
	}
}
</style>