class FormaPagamentoAPI {
  ativo;
  calculaJuros;
  cartao;
  consorcio;
  deposito;
  descricao;
  mostraCompra;
  mostraRecebimento;
  mostraVenda;
  permiteConsumidorFinal;
  verificaSituacao;
  vistaPrazo;
  cheque;
  ehPix;
  qtdeParcela;
  quantidadediasdeprazo;
  planoContasFixo;
}
export class FormaPagamento{
	constructor(altera = new FormaPagamentoAPI()) {
		this.ativo = altera.ativo || "A";
		this.calculaJuros = (altera.calculaJuros) ? "S" : "N";
		this.cartao = (altera.cartao) ? "S" : "N";
		this.consorcio = (altera.consorcio) ? "C" : "N";
		this.deposito = (altera.deposito) ? "S" : "N";
		this.descricao = altera.descricao;
		this.mostraCompra = (altera.mostraCompra) ? "S" : "N";
		this.mostraRecebimento = (altera.mostraRecebimento) ? "S" : "N";
		this.mostraVenda = (altera.mostraVenda) ? "S" : "N";
		this.permiteConsumidorFinal = (altera.permiteConsumidorFinal) ? "S" : "N";
		this.verificaSituacao = (altera.verificaSituacao) ? "S" : "N";
		this.vistaPrazo = (altera.vistaPrazo) ? "1" : "3";
		this.cheque = (altera.cheque) ? "S" : "N";
		this.ehPix = (altera.ehPix) ? "S" : "N";
		this.qtdeParcela = altera.qtdeParcela;
		this.quantidadediasdeprazo = altera.qtdDiasPrazo ? 1 : 0;
		this.valor = altera.valor || 0;
		this.planoContasFixo = altera.planoContasFixo;

	}
}
