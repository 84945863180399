<template>
    <div class="modal">
        <div class="modalInterno col between p-10">
			<div :class="`modalTop ${Conteudo.alerta == true  ? 'mudarBg' : (Conteudo.sucess ? 'sucess' : (Conteudo.tipo != 'polar' ? 'mudarErro' : ''))}`">
				<div :class="`iconClose ${Conteudo.alerta == true ? 'mudarBg' : (Conteudo.sucess  ? 'sucess' : (Conteudo.tipo != 'polar' ? 'mudarErro' : ''))}`" @click="fecharErro(cancelou = true)" >
					<i class="fas fa-times" style="font-size: 18px;"></i>
				</div>
				&nbsp;
				<span v-if="Conteudo.alerta">Mensagem de alerta!</span>
				<span v-else-if="!Conteudo.alerta && Conteudo.sucess">Mensagem de sucesso!</span>
				<span v-else-if="Conteudo.alerta != true && Conteudo.tipo != 'polar'">Mensagem de Erro!</span>
				<span v-else>Confirmação!</span>
			</div>
			<div class="mensagem-container">
				<i class="icone-sucess icon center far fa-check-circle" v-if="Conteudo.sucess"></i>
				<i  class="icone-erro" v-else :class="`center ${Conteudo.alerta == true ?'icon fas fa-times-circle' : (Conteudo.tipo != 'polar' ? 'icon fas fa-exclamation-triangle ' : 'icon fas fa-exclamation-triangle mudarIcon')} ${ Conteudo.alerta == true ? 'alerta' : ''}` "></i>
				<div class="mensagem-texto">
					<p>{{Conteudo.mensagem}}</p>
				</div>
			</div>
            <div class="center w-90" v-if="Conteudo.tipo != 'polar'">
                <button class="botao botao-acao" @click="fecharErro" id="fecharButton">
					<span v-if="!Conteudo.alerta">
						Entendi
					</span>
					<span v-else>
						OK
					</span>
				</button>
            </div>
            <div class="center w-90 polarButton" v-else>
                <button class="botao botao-adicionar m-5" @click="confirmar" id="fecharButton">SIM</button>
                <button class="botao botao-cancelar m-5" @click="fecharErro(cancelou = false)" >NÃO</button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
	name: "Erros",

	props: {
		Conteudo: {
			tipo: String,
			mensagem: String,
			item: Object,
			alerta: Boolean,
			sucess: Boolean,
		}
	},

	methods: {
		fecharErro(cancelou) {
			setTimeout(() => {
				switch (this.$route.name) {
				case "Lancamento": {
					let input = null;
					if (this.Conteudo.mensagem == "É necessário informar o nome do consumidor final") {
						setTimeout(() => {document.getElementById("nomeCliente").focus();},30);
					} else if (this.Conteudo.mensagem == "Não é possível vender condicional para consumidor final." &&
                        this.$route.query.tipoVenda == "C") {
						input = document.getElementById("textoPesquisa");
					} else if (this.Conteudo.mensagem == "Produto não encontrado") {
						input = document.getElementById("js-id-produto");
					} else if (this.Conteudo.mensagem == "Cliente atingiu o limite de produtos em condicional.") {
						input = document.getElementById("js-id-produto");
					} else if (this.Conteudo.mensagem == "Você está devolvendo uma quantidade maior que a retirada da loja") {
						input = document.getElementById("js-quant");
					} else if (this.Conteudo.mensagem == "Não há mais deste produto a ser devolvido, Verifique!") {
						input = document.getElementById("js-id-produto");
					} else if (this.Conteudo.mensagem == "Produto com cadastro incorreto, Verifique!") {
						input = document.getElementById("js-id-produto");
					}
					if (input) {
						input.focus();
						input.select();
					}
					break;
				}
				case "Fechamento": {
					let input = null;
					if (document.getElementById("nParcelas")) {
						input = document.getElementById("nParcelas");
					} else if (document.getElementsByClassName("btnPagamento")[0]) {
						input = document.getElementsByClassName("btnPagamento")[0];
					}
					if (input) {
						input.focus();
						input.select();
					}
					break;
				}
				case "Autenticacao": {
					let input = null;
					if (this.Conteudo.mensagem == "Por favor informar seu usuário!") {
						input = document.getElementById("usuario");
					} else {
						input = document.getElementById("senha");
					}
					if (input) {
						input.focus();
						input.select();
					}
					break;
				}
				case "Gerenciamento": {
					if (this.Conteudo.mensagem == "Produto não encontrado!") {
						const input = document.getElementById("cdproduto");
						if (input) {
							input.focus();
							if (input.select) {
								input.select();
							}
						}
					}
					break;
				}
				}
				return this.$store.state.mensagemErro = null;
			},10);
			if (this.Conteudo.tipo == "polar") {
				if(cancelou){
					this.$bus.$emit("cancelarPolar", this.Conteudo.item);
				} else {
					this.$bus.$emit("cancelarPolar", this.Conteudo.item);
				}
				
			}
		},
        
		confirmar(){
			this.$bus.$emit("confirmarPolar", this.Conteudo.item);
			return this.$store.state.mensagemErro = null;
		}
	},

	mounted(){
		document.removeEventListener("keydown", function(e) {
			if (e.key === "Escape"){
				this.$emit("fecharModal");
			}
			if (e.key == "ArrowUp") {
				if (this.caixaSelecionado != 0) {
					this.caixaSelecionado--;
				}
			}
			if (e.key == "ArrowDown") {
				if (this.caixaSelecionado < this.Caixas.length -1) {
					this.caixaSelecionado++;
				}
			}
			if (e.key == "Enter") {
				this.selecionarCaixa(this.Caixas[this.caixaSelecionado]);
			}
		});
		setTimeout(() => {
			document.querySelector("button[id='fecharButton']").focus();
		}, 45);
	},

};
</script>

<style scoped>
.modal {
    min-height: 100vh;
    height: auto;
    z-index: 8;
}

.modalInterno {
    background-color: var(--bg-app);
    box-shadow: var(--shadow-primario);
    min-width: 40%;
    width: auto;
	max-height: 100%;
    border-radius: 25px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
	display: flex;
	flex-direction: column;
}

.modalTop {
	position: absolute;
	top: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	width: 100%;
	height: 25px;
	background-color: var(--primario);
	color: #fff;
	align-items: center;
}
.iconClose{
	position: relative;
	display: flex;
	top: -2px;
	right: 15px;
	background-color: var(--primario);
	width: 40px;
	height: 40px;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
}
.mensagem-container {
	display: flex;
	flex-direction: row;
	padding: 15px;
	align-items: center;
	justify-content: flex-start;
	margin-top: 20px;
	height: auto;
	width: 100%;
	max-width: 95vw;
}
.mudarBg{
	background-color: var(--alerta) !important;
	color: var(--primary-text-color);
}

.sucess{
	background-color: var(--confirmar) !important;
	color: #FFF !important;
}
.mudarIcon{
	color: var(--alerta) !important;
}
.mudarErro{
	background-color: var(--deletar) !important;
	
}

.icone-erro {
    color: var(--deletar);
    font-size: 40px;
}
.icone-sucess {
	color: var(--confirmar);
	font-size: 40px;
}
.mensagem-texto {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	overflow: auto;
	text-align: left;
}

.btn {
    width: 90%;
    padding: 3px;
}

.alerta{
	color: var(--alerta) !important;
	font-size: 40px !important;
}
.polarButton{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}
@media screen and (max-width: 1250px){
    .modalInterno {
        min-width: 50%;
    }
}

@media screen and (max-width: 900px){
    .modalInterno {
        min-width: 60%;
    }
}

@media screen and (max-width: 768px){
    .modalInterno {
        min-width: 80%;
    }
}

@media screen and (max-width: 400px){
    .modalInterno {
        min-width: 90%;
    }
}
  
</style>