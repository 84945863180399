<template>
	<div id="gridTelaPrincipal">
		<MenuLateral tipo="Principal" v-on:consultarVendasEmAberto="listarCaixas = false"
			v-on:consultarIniciarVenda="atalhosTeclado({ code: 'KeyV', altKey: true })"
			v-on:consultarIniciarCondicional="atalhosTeclado({ code: 'KeyC', altKey: true })"
			v-on:consultarIniciarTroca="atalhosTeclado({ code: 'KeyT', altKey: true })"
			v-on:consultarContasReceber="atalhosTeclado({ code: 'KeyR', altKey: true })"
			v-on:consultarSangria="iniciarSangriaRecarga('Sangria')"
			v-on:consultarRecarga="iniciarSangriaRecarga('Recarga')" v-on:consultarCaixa="fecharCaixa"
			v-on:consultarGerenciarVendas="$router.push({ name: 'Vendas' })"
			v-on:consultarProdutos="listarProdutos = true" v-on:consultarClientes="listarClientes = true" />

		<div id="dashboard" v-if="Usuario && !listarCaixas">
			<div id="aba-controle" class="aba-controle row between w-100">
				<h2 :class="`aba ${$store.state.Usuario.supervisor || $store.state.Usuario.caixa ? 'abaSelecionada' : ''}`"
					@click="mudarAba(0)" v-if="$store.state.Usuario.supervisor || $store.state.Usuario.caixa">Sem
					financeiro</h2>
				<h2 :class="`aba ${$store.state.Usuario.supervisor || $store.state.Usuario.caixa ? '' : 'abaSelecionada'}`"
					@click="mudarAba(1)">Condicional</h2>
				</div>

			<div class="lista-vendas col w-100" v-if="!verCondicionais && Empresa.tiposistema === 'C'">
				<div class="scrollable" v-if="VendasAberto.length > 0">
					<div class="lista-vendas-item col" @click="(e) => iniciarConferencia(e, venda)" v-for="venda in VendasAberto"
						:key="venda.cdVenda"
						:style="!venda.cdIdentificador ? 'background-color: rgba(255, 0, 0, .8);' : ''">
						<h3
							:style="`text-align: center; ${!venda.cdIdentificador ? 'color: #fff; border-bottom: 1px solid #fff;' : 'color: #3B4599; border-bottom: 1px solid #292929;'} `">
							{{ venda.mesa === "S" ? (venda.cliente.id == $store.state.ClientePadrao.id) && venda.nomeCliente ?
									venda.nomeCliente + " " +
									(venda.foneCliente ? venda.foneCliente : "") : venda.cliente.nome : venda.cdVenda}}
						</h3>
						<h3 :style="`${!venda.cdIdentificador ? 'color: #fff' : ''};`">
							{{ venda.mesa === "S" ? venda.cdVenda : (venda.cliente.id == $store.state.ClientePadrao.id) && venda.nomeCliente ?
							venda.nomeCliente + " " +
							(venda.foneCliente ? venda.foneCliente : "") : venda.cliente.nome
							}}
						</h3>
						<div class="row between">
							<h5 :style="`${!venda.cdIdentificador ? 'color: #fff' : ''};`">
								{{ venda.itens.map(item => item.quantidade).length > 0 ?
										venda.itens.map(item => item.quantidade).reduce((t, v) => t += v) : venda.itens.length
								}}
								itens
							</h5>
							<h3 :style="`color: ${!venda.cdIdentificador ? '#fff' : 'green'}; `">
								{{ (venda.valorBruto - (venda.descontoMoeda || 0)).toLocaleString("pt-BR", {
										style:
											"currency", currency: "BRL"
									})
								}}
							</h3>
						</div>
						<div class="row between data-impressao">
							<h3 :style="`color: ${!venda.cdIdentificador ? '#fff' : ''}; `">
								Data: {{ new Date(`${venda.dataEmissao}`).toLocaleString("pt-BR") }}
							</h3>
						</div>
						<h4 :style="`text-align: left; ${venda.cdIdentificador == 0 ? 'color: #fff;' : ''}`"
							v-if="$store.state.Usuario.supervisor">
							Caixa: {{ venda.cdIdentificador > 0 ? $store.state.Caixas.content.find(caixa => caixa.cdcaixa
									== venda.cdIdentificador).identificador : "Caixa geral"
							}}
						</h4>
					</div>
				</div>
				<div class="scrollable" v-else>
					<div class="scrollable-vazio">
						<i class="fas fa-cart-plus" style="font-size: 100px"></i>
						<h3 class="col center">Nenhum pedido em aberto.</h3>
						<h3>{{ "Hoje: " + agora }} <i class="fas fa-sync"
								@click="$store.dispatch('buscarStateInicial', { atualizar: true })"></i></h3>
					</div>
				</div>
			</div>
			<div class="lista-vendas col w-100" v-else-if="verCondicionais">
				<div class="scrollable" v-if="VendasCondicional.length > 0">
					<label :for="venda.cdVenda" class="lista-vendas-item col" v-for="venda in VendasCondicional"
						:key="venda.cdVenda" :style="new Date(venda.dataEmissao) < new Date(new Date().getFullYear(), new Date().getMonth(),
						new Date().getDate() - 2) ? 'background-color: rgba(250, 250, 0, .7);' : ''">
						<h3 :style="`text-align: center; border-bottom: 1px solid #292929; color: var(--primario);`">
							<input type="checkbox" class="pointer" :id="venda.cdVenda"
								@click="selecionarVendaBaixar({ ...venda })">
							{{ venda.cdVenda }}
						</h3>
						<h3>
							{{ (venda.cliente.id == $store.state.ClientePadrao.id) ? `${venda.nomeCliente + "" + (venda.foneCliente && venda.foneCliente != "0000000000" ? venda.foneCliente : "")}` :
														venda.cliente.nome
							}}
						</h3>
						<div class="row between">
							<h5>

								
								{{
									venda.itens
										.filter(item => item.situacao !== "D")
										.reduce((total, item) => total + item.quantidade, 0) || 0
								}}
								itens
							</h5>
							<h3 style="text-align: right;">
								{{ (venda.valorBruto - (venda.descontoMoeda || 0)).toLocaleString("pt-BR", {
										style:
											"currency", currency: "BRL"
									})
								}}
							</h3>
						</div>
						<div class="row between">
							<h3>
								Data: {{ new Date(`${venda.dataEmissao}`).toLocaleString("pt-BR") }}
							</h3>
						</div>
						<h4 :style="`text-align: left; ${new Date(venda.dataEmissao) < new Date(new Date().getFullYear(), new Date().getMonth(),
						new Date().getDate() - 2)}`" v-if="$store.state.Usuario.supervisor">
							Caixa: {{ venda.cdIdentificador > 0 ? $store.state.Caixas.content.find(caixa => caixa.cdcaixa
									== venda.cdIdentificador).identificador : "Caixa geral"
							}}
						</h4>
					</label>
				</div>
				<div class="scrollable" v-else>
					<div class="scrollable-vazio">
						<i class="fas fa-cart-plus" style="font-size: 100px"></i>
						<h3 class="col center">Nenhum condicional em aberto.</h3>
						<h3>{{ "Hoje: " + agora }} <i class="fas fa-sync"
								@click="$store.dispatch('buscarStateInicial', { atualizar: true })"></i></h3>
					</div>
				</div>
				<div class="row w-100 between">
					
					<div class="botoes botoes-minusculo" v-if="VendasCondicional.length > 0">
						<button class="botao botao-acao" @click="receberCondicional(vendaBaixar, 'S')">Baixar selecionados</button>
						<button class="botao botao-acao" @click="receberCondicional(vendaBaixar, 'C')">
							Baixar parcial
						</button>

						<button class="botao botao-acao" @click="imprimirCondicional(vendaBaixar)">
							Imprimir Condicional
						</button>
					</div>
				</div>
			</div>
			<div class="lista-mesas-aberto" v-else>
				<div class="container-mesas" v-if="VendasAberto.length > 0">
					<div class="card-mesa" v-for="venda in VendasAberto" :key="venda.cdVenda" @click="(e) => iniciarConferencia(e, venda)">
						<div class="header-card-mesa">
							<h3>
								{{ venda.nomeCliente ? venda.nomeCliente : venda.cliente.nome }}
							</h3>
						</div>
						<hr />
						<div class="body-card-mesa">
							<div class="row-body-mesa">
								<span class="data">
									Data: {{ new Date(`${venda.dataEmissao}`).toLocaleString("pt-BR") }}
								</span>
							</div>
							<div class="row-body-mesa">
								<span>
									{{ (venda.valorBruto || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) }}
								</span>
								<i id="impressao" class="fas fa-print" style="color: var(--primario)" @click="solicitarImpressaoProdutos(venda)" />
							</div>
						</div>
					</div>
				</div>
				<div class="container-mesas" v-else>
					<div class="scrollable-vazio">
						<i class="fas fa-cart-plus" style="font-size: 100px"></i>
						<h3 class="col center">Nenhum mesa em aberto.</h3>
						<h3>{{ "Hoje: " + agora }} <i class="fas fa-sync"
								@click="$store.dispatch('buscarStateInicial', { atualizar: true })"></i></h3>
					</div>
				</div>
			</div>
		</div>
		<div id="dashboard" class="col box between p-5" v-if="Usuario && listarCaixas">
			<div class="scrollable">
				<div class="itemLista col m-10" v-for="(caixa, index) in Caixas" :key="index"
					@click="selecionarCaixa(caixa)">
					<h3 style="text-align: center; border-bottom: 1px solid #292929; color: #3B4599">
						{{ caixa.identificador }}</h3>
					<h4>Situação:
						<span v-if="caixa.aberto == 'S'"><i class="fas fa-store-alt"
								style="color: var(--confirmar)"></i> &nbsp; Aberto </span>
						<span v-else> <i class="fas fa-store-alt-slash" style="color: var(--deletar)"></i> &nbsp;
							Fechado</span>
					</h4>
					<h4 v-if="$store.state.Usuario.usuarioPrivilegiado">Saldo atual: {{ Number(caixa.info ? caixa.info.saldoFinal || 0 : 0).toLocaleString("pt-BR", {
								style: "currency", 
								currency: "BRL"
							})
					}}</h4>
					<h4 v-if="$store.state.Usuario.usuarioPrivilegiado">Movimento a prazo:
						{{
								(caixa.info ? caixa.info.movimentacaoPrazo.length > 0 ?
									caixa.info.movimentacaoPrazo.map(movi => movi.valor).reduce((total, vlr) => total += vlr)
									: 0 : 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
						}}
					</h4>
					<h4>Ultimo fechamento: {{ caixa.dataUltimoFechamento ?
								new Date(
								new Date(caixa.dataUltimoFechamento).getFullYear(),
								new Date(caixa.dataUltimoFechamento).getMonth(),
								new Date(caixa.dataUltimoFechamento).getDate() + 1,
								).toLocaleDateString("pt-BR") : "Caixa novo"
					}}</h4>
				</div>
			</div>
		</div>

		<!-- <div id="rodapeAcao" class="col around box" v-if="Usuario">
			<div class="row between w-100">
				<button class="btn m-5" @click="listarProdutos = true">[F4] -> Consultar produtos</button>
				<button class="btn m-5" @click="listarClientes = true">[F2] -> Consultar clientes</button>
				<button class="btn m-5" @click="fecharCaixa" >
					{{!this.listarCaixas ? (Usuario.supervisor ? "Ver caixas" : "Fechar caixa") : "Ver vendas"}}
				</button>
			</div>

			<div class="row between w-100">
				<button class="btn m-5" @click="$router.push({name: 'Vendas'})">Gerenciar vendas</button>

			</div>
		</div> -->
		<Modal v-if="listarVendas" v-on:fecharModal="fecharModal">
			<div v-if="!vendaSelecionada">
				<consulta-vendas-troca
					:VendasTroca="VendasTroca" 
					v-on:selecionarVenda="selecionarVenda" 
					:height="'65vh'"
					v-if="trocaBuscaVenda"
				/>
				<consulta-produtos 
					:Produtos="Produtos"
					:troca="true"
					:trocaBuscaVenda="trocaBuscaVenda"
					v-on:cancelarTroca="atalhosTeclado({ code: 'KeyT', altKey: true})"
					v-on:selecionarProduto="preTrocaProduto"
					:height="'65vh'"
					v-else
				/>
			</div>
			<!-- <FiltroPaginacao :conteudo="trocaBuscaVenda ? 'VendasTroca' : 'Produtos'" v-if="!vendaSelecionada" :visaoTodasEmpresas="true">
				<ListagemVendasTroca :Vendas="VendasTroca" v-on:selecionarVenda="selecionarVenda" v-if="trocaBuscaVenda" />
				<ListagemProdutos :Produtos="Produtos" :troca="true" :trocaBuscaVenda="trocaBuscaVenda" v-else
					v-on:cancelarTroca="atalhosTeclado({ code: 'KeyT', altKey: true})"
					v-on:selecionarProduto="trocarProduto" />
			</FiltroPaginacao> -->
			<ListagemProdutos :Produtos="vendaSelecionada.itens" :troca="true" :trocaBuscaVenda="trocaBuscaVenda" :trocaPorVenda="true" v-else
				v-on:trocarProdutos="trocarProdutos"
				v-on:cancelarTroca="atalhosTeclado({ code: 'KeyT', altKey: true, voltar: true })" />
			<div id="switch-controle" v-if="$store.state.Usuario.multiTroca"><!-- Deve depender apenas do parametro -->
				<span @click="trocaBuscaVenda = !trocaBuscaVenda">{{ trocaBuscaVenda ? "Vendas" : "Produtos" }}</span>
				<i class="fas fa-toggle-on switch-icon" style="color: var(--confirmar)" v-if="trocaBuscaVenda"
					@click="trocaBuscaVenda = !trocaBuscaVenda"></i>
				<i class="fas fa-toggle-off switch-icon" style="color: rgba(0,0,0,0.2)" v-else
					@click="trocaBuscaVenda = !trocaBuscaVenda"></i>
			</div>
		</Modal>
		<Modal v-if="modalVistaPrazoTroca" v-on:fecharModal="fecharModalVistaPrazoTroca" 
			:idModal="'modalVistaPrazoTroca'" :dimensoes="{ width: '450px', height: '100px'}">
			Qual valor deseja utilizar?
			<div class="botoes">
				<button class="botao botao-acao" @click="tipoValorTroca('p')">prazo: {{ this.produtoModalVistaPrazoTroca.valorPrazo.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</button>
				<button class="botao botao-acao" @click="tipoValorTroca('v')">vista: {{ this.produtoModalVistaPrazoTroca.valorVista.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</button>
			</div>
		</Modal>

		<Modal v-if="listarProdutos" v-on:fecharModal="fecharModal" >
			<consulta-produtos 
				:Produtos="Produtos"
				:mostrarBotaoNovo="false"
				@selecionarProduto="selecionarProduto"
				:height="'65vh'"
			/>
		</Modal>

		<Modal 
			v-if="listarClientes && !solicitouTroca"
			@fecharModal="fecharModal" 
			:dimensoes="{ width: '95vw', height: '90vh' }"
			:idModal="'consulta-cliente'"
		>
			<ConsultaCliente
				@selecionarPessoa="selecionarCliente"
				:mostrarBotaoNovo="false"
				:mostrarAcao="true"
				@consultarContas="consultarContas"
				:height="'60vh'"
			/>
		</Modal>

		<Modal 
			v-if="listarClientes && solicitouTroca" 
			@fecharModal="fecharModal"
		>
			<ConsultaCliente
				@selecionarPessoa="selecionarCliente"
				:height="'60vh'"
				v-if="VendasTroca.length < 1"
			/>
			<div v-else class="col scrollable w-100">
				<table class="w-100">
					<thead>
						<tr>
							<td>Venda</td>
							<td>NFC-E</td>
							<td>Situacao</td>
							<td>CDtipo</td>
							<td>Descricao</td>
							<td>Quantidade</td>
							<td>Valor unitário</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(itens, index) in VendasTroca.map(vend => vend.itens)" :key="index">
							<div v-for="item in itens"
								:key="`${item.produto.id}-${index}-${VendasTroca[index].cdVenda}-${item.quantidade}`">
								<td>{{ VendasTroca[index].cdVenda }}</td>
								<td>{{ VendasTroca[index] }}</td>
								<td>{{ item.situacao }}</td>
								<td>{{ item.cdTipo }}</td>
								<td>{{ item.produto.id }} - {{ item.produto.descricao }}</td>
								<td>{{ item.quantidade }}</td>
								<td>{{ item.valorUnitario }}</td>
							</div>
						</tr>
					</tbody>
				</table>
			</div>
		</Modal>

		<Modal 
			v-if="conferirVenda" 
			@fecharModal="fecharModal" 
			:dimensoes="{ width: '80%', height: 'auto' }"
			:idModal="'preimpressao-venda'"
		>
			<PreImpressao 
				:vendaFinalizada="conferirVenda" 
				@fecharModal="fecharModal" 
				:conferir="true"
				:permiteCancelar="true" 
				@receberVenda="receberVenda" 
				@alterarVenda="alterarVenda"
				@confirmarVenda="receberVenda" 
			/>
		</Modal>

		<Modal 
			v-if="sangrarReforcar" 
			@fecharModal="fecharModal" 
			:dimensoes="{ width: 'auto', height: 'auto' }"
			:idModal="'sangria-recarga'"
		>
			<SangriaRecarga 
				@fecharModal="fecharModal"
				:Caixa="Caixa"
				:Usuario="Usuario"
				:tipo="sangrarReforcar"
			/>
		</Modal>

		<Modal v-if="consultarCaixa" v-on:fecharModal="fecharModal" :dimensoes="{ width: '50%', height: 'auto' }">
			<div class="col around h-60 m-auto w-90">
				<div class="col between w-100 m-5">
					<h2>Qual data deseja consultar o caixa?</h2>
				</div>
				<div class="row center w-100 m-5">
					<div class="col between w-100">
						<label for="dataConsulta">Data</label>
						<input type="date" id="dataConsulta" class="w-90 p-5" v-model="dataConsulta" autocomplete="off"
							@keydown.enter="consultarCaixaPorData" @keydown.tab.prevent="consultarCaixaPorData">
					</div>
				</div>
				<div class="row center w-90 m-5">
					<button class="btn btn-cancelar m-auto mx-5" @click="fecharModal">Cancelar</button>
					<button class="btn btn-confirmar m-auto mx-5" @click="consultarCaixaPorData">Confirmar</button>
				</div>
			</div>
		</Modal>

		<Modal 
			v-if="abrirCaixa" 
			@fecharModal="fecharModal" 
			:dimensoes="{ width: '65%', height: 'auto' }"
			:idModal="'abertura-caixa'"
		>
			<AberturaCaixa
				@caixaAberto="fecharModal"
				:caixa="caixaSelecionado"
				@cancelar="fecharModal"
			/>
		</Modal>
		<Modal 
			v-if="produtoVariado" 
			@fecharModal="produtoVariado = false" 
			:dimensoes="{width: '85%', height: 'auto'}"
			:idModal="'produto-variados'"
		>
			<span>
				Produtos que foram adicionados ao pedido mas estão com variação, selecione a variação desejada
			</span>
			<div class="tabela-produto" v-for="(pai, index) in produtoPai" :key="index">
				<h3>Seq.{{pai.seq}} - Cód.{{pai.id}} - {{pai.desc}}</h3>
				<table >
					<thead>
						<tr>
							<td class="text-center">Selecione</td>
							<td style="color: blue; text-align: center;">Cód.</td>
							<td class="text-left">Produto</td>
							<td class="text-center">Saldo</td>
							<td class="text-right text-nowrap">$ Custo</td>
							<td class="text-right text-nowrap">$ Margem</td>
							<td class="text-right text-nowrap">$ Médio</td>
							<td class="text-right text-nowrap">$ Prazo</td>
							<td class="text-right text-nowrap">$ Vista</td>

						</tr>
					</thead>
					<tbody>
						<tr  v-for="(item, index) in listaProdutosVariados" :key="index">
							<td for="inputSelect">
								<input type="checkbox" v-model="item.checked" id="inputSelect"/>
							</td>
							<td class="text-center">{{ item.cdproduto }}</td>
							<td class="text-left text-nowrap">{{ item.descricao }}</td>
							<td class="text-center"> {{ (item.saldo || 1) }}</td>
							<td class="text-right">{{ (item.custo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
							<td class="text-right">{{ (item.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
							<td class="text-right">{{ (item.custoMedio || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
							<td class="text-right">{{ (item.pontoequi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
							<td class="text-right">{{ (item.vlrvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
						</tr>
					</tbody>
				</table>
				<button class="btn btn-confirmar text-center" @click="selecionouVariacao(pai)">
					<span>Continuar</span>
				</button>
			</div>
		</Modal>

	</div>
</template>

<script>
import ConsultaVendasTroca from "../components/ConsultaVendasTroca.vue";
import ConsultaProdutos from "../components/ConsultaProdutos.vue";
import ConsultaCliente from "../components/ConsultaCliente.vue";
import Modal from "../components/Modal.vue";
// import FiltroPaginacao from "../components/FiltroPaginacao.vue";
// import Paginacao from "../components/Paginacao.vue";
// import ListagemVendas from "../components/ListagemVendas.vue";
import ListagemProdutos from "../components/ListagemProdutos.vue";
// import TabelaPessoas from "../components/TabelaPessoas.vue";
import PreImpressao from "../components/PreImpressao.vue";
import SangriaRecarga from "../components/SangriaRecarga.vue";
import MenuLateral from "../components/MenuLateral.vue";
import AberturaCaixa from "../components/AberturaCaixa.vue";
// import ListagemVendasTroca from "../components/ListagemVendasTroca.vue";

import { dateFormatISO } from "../utils/dateformat";


export default {
	name: "Principal",

	components: {
		ConsultaVendasTroca,
		ConsultaProdutos,
		ConsultaCliente, 
		Modal,
		// Paginacao,
		// ListagemVendas,
		ListagemProdutos,
		// TabelaPessoas,
		PreImpressao,
		SangriaRecarga,
		MenuLateral,
		AberturaCaixa,
		// ListagemVendasTroca,
		// FiltroPaginacao
	},

	data() {
		return {
			listarProdutos: false,
			listarClientes: false,
			receberConta: false,
			verCondicionais: false,
			listarVendas: false,
			listarCaixas: false,
			abrirCaixa: false,
			consultarCaixa: false,
			vendaSelecionada: null,
			agora: dateFormatISO({
				date: null,
				format: "ISOdate"
			}),
			atualizarEm: 60,
			conferirVenda: null,
			sangrarReforcar: null,
			dataConsulta: null,
			caixaSelecionado: null,
			vendaBaixar: null,
			vendasOrigem: {},
			solicitouTroca: false,
			trocaBuscaVenda: true,
			textoLeitor: "",

			produtoVariado: false,
			listaProdutosVariados: [],
			produtoPai: [],

			modalVistaPrazoTroca: false,
			produtoModalVistaPrazoTroca: {},
		};
	},

	computed: {
		Event() {
			return this.$store.state.EventSSE;
		},

		Usuario() {
			return this.$store.state.Usuario;
		},

		Empresa() {
			return this.$store.state.Empresa;
		},

		VendasAberto() {
			return this.$store.state.VendasAberto;
		},

		VendasFiltradas() {
			return this.$store.state.VendasFiltradas;
		},

		VendasTroca() {
			return this.$store.state.VendasTroca;
		},

		VendasCondicional() {
			return this.$store.state.VendasCondicional;
		},

		PaginaAtualCondicional() {
			return this.$store.state.PaginaAtualCondicional;
		},

		PaginaMaxCondicional() {
			return this.$store.state.PaginaMaxCondicional;
		},

		Produtos() {
			return this.$store.state.Produtos;
		},

		Caixas() {
			return this.Usuario.supervisor ? this.$store.state.Caixas.content.filter(caixa => caixa.cdEmpresa == this.$store.state.Empresa.id) :
				this.$store.state.Caixas.content.filter(caixa => caixa.identificador == this.Caixa.caixa.identificador);
		},

		Caixa() {
			return this.$store.state.Caixa;
		},
	},

	methods: {
		async createConectionSSE() {
			try {
				if(!this.Event && this.Event === null) {
					await this.$store.dispatch("createSSEConection");
					return;
				}
			} catch (error) {
				console.log(error);	
			}
		},

		async solicitarImpressaoProdutos(venda) {
			await this.$store.dispatch("gerarCupomLanchonete", {
				venda
			});
		},

		async imprimirCondicional(venda) {
			if(!venda) return;
			await this.$store.dispatch("gerarCupom", {
				empresa: this.$store.state.Empresa,
				venda: venda
			});
		},


		consultarContas(pessoa) {
			this.receberConta = true;
			if (!this.$store.state.Usuario.caixa && !this.$store.state.Usuario.supervisor) {
				return this.$store.dispatch("solicitarImpressaoExtrato", {
					cliente: pessoa,
				});
			}
			this.selecionarCliente(pessoa);
		},

		selecionarCondicional(e) {
			const regex = /[0-9]/;
			if (e.key == "Enter") {
				const venda = document.getElementById(this.textoLeitor);
				if (venda) {
					venda.click();
				}
				this.textoLeitor = "";
			}
			if (regex.test(e.key)) {
				this.textoLeitor += e.key;
			}
		},
		fecharModal() {
			this.listarProdutos = false;
			this.listarClientes = false;
			this.listarVendas = false;
			this.listarCaixas = false;
			this.abrirCaixa = false;
			this.vendaSelecionada = null;
			this.caixaSelecionado = null;
			this.conferirVenda = null;
			this.sangrarReforcar = false;
			this.$bus.$emit("mudarItemMenu", "VendasEmAberto");
			this.$store.state.VendasTroca = [];
		},

		mudarAba(index) {
			if(index === 0){
				this.vendaBaixar = null;
			}
			const aba = document.getElementsByClassName("aba")[index];
			const abaSelecionada = document.getElementsByClassName("abaSelecionada")[0];
			if (!aba || !abaSelecionada) return;
			if (aba != abaSelecionada) {
				aba.classList.add(["abaSelecionada"]);
				abaSelecionada.classList.remove(["abaSelecionada"]);
				setTimeout(() => {
					this.verCondicionais = !this.verCondicionais;
				}, 30);
			}
		},

		receberVenda(venda) {
			this.$store.state.Venda = null;
			this.$store.state.Venda = {
				...venda,
			};

			this.$store.state.itensVenda = venda.itens;

			if ((Number(this.$store.state.Venda.valorPago + this.$store.state.Venda.descontoMoeda)) >= Number(this.$store.state.Venda.valorBruto)) {
				this.confirmarVenda(venda);
			} else {
				if(this.Empresa?.tiposistema === "C") {
					this.$router.push({ name: "Fechamento" });
				} else if(this.Empresa?.tiposistema === "L") {
					this.$router.push({ name: "FechamentoMesa" });
				}
			}
		},

		async selecionouVariacao(pai){
			await this.$store.dispatch("buscarStateInicial", { atualizar: true });
			let produtoChecked = this.listaProdutosVariados.filter(produto => produto.checked);
			this.vendaBaixar.itens.forEach(async (item, index) => {
				if(item.seq == pai.seq){
					produtoChecked.forEach(produto => {
						this.vendaBaixar.itens.splice(index, 1, {
							...item,
							cdTipo: item.cdTipo,
							produto: {
								...produto,
								id: produto.cdproduto,
								valorPrazo: item.valorPrazo,
								valorVista: item.valorVista
							},
						});
					});
				}
			});
			await this.$store.dispatch("atualizarVenda", {...this.vendaBaixar, variacao: true});
			this.receberCondicional(this.vendaBaixar);
			this.produtoVariado = false;
		},

		async receberCondicional(venda, tipo) {
			try {
				if (this.$store.state.Caixa.caixa.aberto != "S"){
					return this.$store.commit("relatarErro", {
						mensagem: "O caixa está fechado, deseja abrir o caixa para iniciar as movimentações?",
						tipo: "polar",
						item: {
							solicitarCaixa: true,
						}
					});
				}
				if (!venda) return;
				// if (Object.keys(this.vendasOrigem || {}).length > 1 && tipo == "C") {
				// 	return this.$store.commit("relatarErro", {mensagem: "Não é possível alterar mais de um condicional, selecione apenas um para alterar!"});
				// }
				this.$store.state.carregando = true;
				this.$store.state.Venda = null;
				this.produtoPai = [];
				this.$store.state.Venda = {
					cliente: venda.cliente,
					vendedor: venda.vendedor,
					nomeCliente: venda.nomeCliente,
					foneCliente: venda.foneCliente,
					vendasOrigem: { ...this.vendasOrigem },
					tipoVenda: tipo
				};
				venda.itens.forEach( async (item)=> {
					if(!item.produto){
						return this.$store.commit("relatarErro", {mensagem: `Produto Seq. ${item.seq} - Cód. ${item.produto.id} - ${item.produto.descricao}, consta no pedido mas não foi encontrado no cadastro de produto`});
					}
					if(item.produto.tipo == "V"){
						this.produtoPai.push({
							id: item.produto.id,
							desc: item.produto.descricao,
							seq: item.seq,
							cdTipo: item.cdTipo,
							situacao: item.situacao
						});
						const response = await this.$store.dispatch("getFilhos", {pai: item.produto.id});
						if(item.produto.id != response.id){
							this.listaProdutosVariados = response.content;
						}
						setTimeout(() => {
							this.produtoVariado = true;
						}, 1000);
						return this.$store.commit("relatarErro", {mensagem: `Produto Seq. ${item.seq} - Cód.${item.produto.id} - ${item.produto.descricao} foi gerada variação deste produto, entrar em contato com o suporte`});
					}
					this.produtoVariado = false;
					return;
				});
				this.$store.state.itensVenda = [];
				const itens = [];
				let i = 0;
				for (let item of venda.itens.filter(it => it.situacao == "A")) {
					const data = await this.$store.dispatch("buscarProdutoPorId", item.produto.id);
					itens.push({
						...item,
						cdTipo: tipo,
						situacao: tipo == "C" ? item.situacao : "S",
						seq: i + 1,
						cdpromocao: data?.dataPromocaofim >= new Date().toLocaleDateString("en-GB").split("/").reverse().join("-") ? data.cdpromocao : null,
						produto: {
							...item.produto,
							promocaoPrazo: data?.dataPromocaofim >= new Date().toLocaleDateString("en-GB").split("/").reverse().join("-") ? data.promocaoPrazo : null,
							promocaoVista: data?.dataPromocaofim >= new Date().toLocaleDateString("en-GB").split("/").reverse().join("-") ? data.promocaoVista : null,
							nomePromocao: data?.dataPromocaofim >= new Date().toLocaleDateString("en-GB").split("/").reverse().join("-") ? data.nomePromocao : null,
							cdpromocao: data?.dataPromocaofim >= new Date().toLocaleDateString("en-GB").split("/").reverse().join("-") ? data.cdpromocao : null,
						},
					});
					i++;
				}
				this.produtoVariado = false;
				this.$store.state.itensVenda = [...itens];
				this.vendaBaixar = null;
				this.vendasOrigem = {};
				this.$store.state.carregando = false;
				await this.$router.push({ name: "Lancamento", query: { tipoVenda: `${tipo}`, baixarCondicional: true, alteracao: tipo == "C" } });
			} catch (error) {
				console.log(error);
				this.$store.state.carregando = false;
			}
		},

		async selecionarVenda(venda) {
			try {
				const data = await this.$store.dispatch("buscarVendaPorId", {
					cdVenda: venda.cdVenda, 
					cdEmpresa: this.$store.state.Empresa.id
				});
				const existePromocao = data.itens.filter(item => item.cdpromocao).length > 0;
				
				for (let index = 0; index < data.itens.length; index++) {
					if (data.itens[index].cdpromocao) {
						data.itens[index].valorUnitario = (data.itens[index].valorUnitario - (data.itens[index].valorUnitario * data.itens[index].percpromocao / 100));
					} else if (data.itens[index].percdesc) {
						data.itens[index].valorUnitario = (data.itens[index].valorUnitario - (data.itens[index].valorUnitario * data.itens[index].percdesc / 100));
					} else if (!existePromocao) {
						data.itens[index].valorUnitario = (data.itens[index].valorUnitario - (data.itens[index].valorUnitario * data.descontoPercentual / 100));
					}
				}
				this.vendaSelecionada = data;
				this.vendaSelecionada.itens = data.itens.filter(item => item.cdTipo == "S");
				setTimeout(() => {
					document.getElementsByClassName("troca")[0].focus();
				}, 10);
			} catch (error) {
				console.log(error);
			}
		},
		preTrocaProduto(produto){
			this.modalVistaPrazoTroca = true;
			this.produtoModalVistaPrazoTroca = produto;
		},
		fecharModalVistaPrazoTroca(){
			this.modalVistaPrazoTroca = false;
		},
		tipoValorTroca(tipo){
			this.produtoModalVistaPrazoTroca.tipoValorTroca = tipo;
			this.modalVistaPrazoTroca = false;
			this.trocarProduto(this.produtoModalVistaPrazoTroca);
		},
		trocarProduto(produto) {
			produto.cdTipo = "T";
			produto.situacao = null;
			produto.valorUnitario = produto.tipoValorTroca && produto.tipoValorTroca == "p"? produto.valorPrazo:produto.valorVista;
			if (!produto["produto"]) {
				produto["produto"] = { ...produto };
			}
			produto.seq = (this.$store.state.itensVenda.length + 1);
			produto.quantidade = produto.quantidade || 1;
			this.$store.state.itensVenda.push(produto);
			this.listarVendas = false;
			this.$router.push({ name: "Lancamento", query: { tipoVenda: "S", trocarProdutos: true } });
		},

		trocarProdutos(produtos) {
			this.$store.state.Venda = {
				cliente: this.vendaSelecionada ? this.vendaSelecionada.cliente : null,
				vendedor: this.Usuario.cdVendedor ? this.Usuario : null,
			};
			let produtosTroca = [];
			let i = 1;
			produtos.forEach(item => {
				item.cdTipo = "T";
				item.situacao = null;
				item.seq = i;
				item.cdVenda = this.vendaSelecionada.cdVenda;
				i++;
				if (!item["produto"]) {
					item["produto"] = { ...item };
				}
				item.quantidade = item.quantidade || 1;
				item.valorUnitario = item.valorUnitario || item.valorVista;
				produtosTroca.push({ ...item });
			});
			this.$store.state.itensVenda = [...produtosTroca];
			this.$router.push({ name: "Lancamento", query: { tipoVenda: "S", trocarProdutos: true } });
		},

		selecionarCliente(cliente) {
			if (this.receberConta) {
				this.$router.push({ name: "ContasReceber", params: { cliente } });
			}
			else if (!this.solicitouTroca) {
				this.$router.push({ name: "CadastroCliente", params: { cliente, route: "Principal" } });
			} else {
				this.$store.dispatch("buscarVendasParaTroca", { cliente, size: 25 });
			}
		},

		selecionarProdutoParaTroca(produto) {
			let index = this.$store.state.ProdutosTroca.map(prod => prod.id).indexOf(produto.id);
			if (index >= 0) {
				this.$store.state.ProdutosTroca.splice(index, 1);
			} else {
				this.$store.state.ProdutosTroca.push({ ...produto, produto });
			}
		},

		async selecionarProduto(produto) {
			if (!this.Usuario.supervisor && !this.Usuario.usuarioPrivilegiado) return;
			this.$store.state.carregando = true;
			if (produto.pai) {
				produto = await this.$store.dispatch("selecionarProdutoPorEmpresa", {id: produto.pai, cdempresa: produto.cdEmpresa});
			}
			this.$router.push({
				name: "Gerenciamento",
				query: {
					menu: "Produtos",
					cadastro: true
				},
				params: {
					produto
				}
			});
			this.$store.state.carregando = false;
		},

		async fecharCaixa() {
			if (this.Caixa.caixa.aberto == "N" || this.Usuario.supervisor) {
				if (!this.listarCaixas) {
					await this.$store.dispatch("buscarStateInicial", { atualizar: true });
					this.atualizarEm = 60;
				} else {
					return this.fecharModal();
				}
				this.verCondicionais = false;
				return this.listarCaixas = !this.listarCaixas;
			}
			if (this.VendasAberto.length > 0 && !this.$store.state.Usuario.supervisor) {
				return this.$store.commit("relatarErro", { 
					mensagem: "Exitem vendas sem financeiro, favor verificar!" 
				});
			}
			if(!this.Usuario.supervisor) {
				this.$store.dispatch("atualizarInformacoesCaixa", { cdCaixa: this.Caixa.caixa.cdcaixa });
			}
			this.$router.push({ name: "Caixa" });
		},

		selecionarCaixa(caixa) {
			if (caixa.aberto == "S") {
				if (this.VendasAberto.filter(venda => venda.cdIdentificador == caixa.cdcaixa || venda.cdIdentificador == 0).length > 0) {
					return this.$store.commit("relatarErro", { mensagem: "Exitem vendas sem financeiro, favor verificar!" });
				}
				this.$store.dispatch("atualizarInformacoesCaixa", { cdCaixa: caixa.cdcaixa });
				this.$router.push({ name: "Caixa" });
			} else {
				this.caixaSelecionado = caixa;
				this.abrirCaixa = true;
			}
		},

		verificarCaixaAberto() {
			if (this.Caixa.caixa.aberto == "N" || !this.Caixa.caixa.aberto) {
				this.$store.commit("relatarErro", {
					mensagem: "O caixa está fechado, deseja abrir o caixa para iniciar as movimentações?",
					tipo: "polar",
					item: {
						solicitarCaixa: true,
					}
				});
				return false;
			} else {
				return true;
			}
		},

		async atalhosTeclado(e) {
			
			// REMOVI SOMENTE A ESSA VERIFICAÇÃO DE LISTAR VENDAS.
			// if (this.listarVendas) return;
			const usaCelular = this.Usuario.usaCelular;

			if (e.code == "KeyV" && e.altKey && this.verificarCaixaAberto()) {
				this.$router.push({ name: "Lancamento", query: { tipoVenda: "S" } });
				if(usaCelular){
					this.$router.push({ name: "LancamentoMesa" });
				} else if (!usaCelular && this.Empresa.tiposistema === "L"){
					this.$router.push({ name: "LancamentoLanchonete" });
				}
			}
			if (e.code == "KeyC" && e.altKey && this.verificarCaixaAberto()) {
				this.$router.push({ name: "Lancamento", query: { tipoVenda: "C" } });
			}
			if (e.code == "KeyT" && e.altKey && this.verificarCaixaAberto()) {
				this.trocaBuscaVenda = true;
				this.vendaSelecionada = null;
				if(!e.voltar){
					this.listarVendas = !this.listarVendas;
				} else {
					this.listarVendas = true;
				}
			}
			if (e.code == "KeyR" && e.altKey) {
				return this.atalhosTeclado({ key: "F2", altKey: e.altKey });
			}
			if (e.key === "F4") {

				this.listarClientes = false;
				return this.listarProdutos = !this.listarProdutos;
			}
			if (e.key === "F2") {
				this.listarProdutos = false;
				if (e.altKey) {
					this.receberConta = true;
				}
				return this.listarClientes = !this.listarClientes;
			}
			if (e.key == "Escape") {
				if (this.listarProdutos || this.listarClientes || this.listarVendas) {
					return this.fecharModal();
				}
			}
			if (this.verCondicionais) {
				this.selecionarCondicional(e);
			}
		},

		atualizarHora() {
			if (this.$route.path != "/") return;
			this.agora = new Date().toLocaleString("pt-BR");
			if (this.atualizarEm == 1) {
				this.atualizarListaVendas();
			} else if (this.atualizarEm < 0) {
				this.atualizarEm = 60;
			} else {
				this.atualizarEm -= 1;
			}
			setTimeout(this.atualizarHora, 1000);
		},

		atualizarListaVendas() {
			this.atualizarEm = 60;
			if (this.listarProdutos || this.listarClientes || this.vendaSelecionada || this.conferirVenda ||
				this.receberConta || this.sangrarReforcar || this.dataConsulta || this.listarVendas || this.abrirCaixa) {
				return;
			}
			this.$store.dispatch("buscarStateInicial", { atualizar: true });
			this.atualizarEm = 60;
		},

		iniciarConferencia(e, venda) {
			if(e?.target?.id === "impressao") return;
			this.conferirVenda = { ...venda };
		},

		alterarVenda(venda) {
			this.$store.state.Venda = null;
			this.$store.state.Venda = {
				...venda,
				cliente: venda.cliente,
				vendedor: venda.vendedor,
				cdVenda: venda?.cdVenda,
				dataEmissao: venda.dataEmissao,
				dataEntradaSaida: venda.dataEntradaSaida,
				cdUsuarioLiberacao: venda.cdUsuarioLiberacao,
				cdUsuarioCaixa: venda.cdUsuarioCaixa,
				descontoPercentual: venda.descontoPercentual,
				descontoMoeda: venda.descontoMoeda,
				financeiro: venda?.financeiro,
				nomeCliente: venda?.nomeCliente,

			};
			this.$store.state.itensVenda = [];
			this.$store.state.itensVenda = [...venda.itens];
			if(this.Empresa.tiposistema === "L" && !this.Usuario.usaCelular) {
				this.$router.push({ name: "LancamentoLanchonete", query: { alteracao: true } });
				return;
			}
			this.$router.push({ name: "Lancamento", query: { tipoVenda: "S", alteracao: true } });
		},

		async mudarEmpresa() {
			if (!this.Usuario.empresa) {
				await this.$store.dispatch("buscarEmpresas");
				this.$router.replace({ name: "SelecionaEmpresa" });
				this.$store.state.Empresa = null;
			}
		},

		async mudarCaixa() {
			localStorage.removeItem("caixaLocal");
			this.$router.replace({ name: "SelecionaCaixa" });
			this.$store.state.Caixa = null;
		},

		confirmarVenda(venda) {
			this.conferirVenda = null;
			this.$store.dispatch("confirmarVenda", { ...venda });
		},
		iniciarSangriaRecarga(tipo) {
			if (this.verificarCaixaAberto()) {
				this.sangrarReforcar = tipo;
			}
		},

		solicitaAberturaCaixa(item) {
			if(item.solicitarCaixa){
				this.caixaSelecionado = this.Caixa.caixa;
				return setTimeout(() => {
					this.abrirCaixa = true;
				}, 20);
			}
			this.$router.push({ name: "SelecionaCaixa" });
		},

		polarCancelado (item) {
			if(item?.solicitarCaixa){
				return this.fecharModal();
			}
		},

		consultarCaixaPorData() {
			this.$router.push({ name: "FecharCaixa", query: { data: this.dataConsulta, cdCaixa: this.caixaSelecionado.cdcaixa } });
		},
		mudarPagina(page) {
			this.$store.dispatch("buscarVendasCondicionalEmAberto", { page });
		},
		selecionarVendaBaixar(venda) {
			if (!document.getElementById(venda.cdVenda).checked) {
				this.vendaBaixar.itens = this.vendaBaixar.itens.filter(item => item.vendaOrigem != venda.cdVenda);
				if (this.vendaBaixar.itens.length < 1) {
					this.vendaBaixar = null;
				}
				return delete this.vendasOrigem[venda.cdVenda];
			}
			if (!this.vendaBaixar) {
				let itens = [];
				venda.itens.forEach(item => {
					itens.push({
						vendaOrigem: venda.cdVenda,
						dataOrigem: venda.dataEmissao,
						valorUnitario: item.valorUnitario - (item.valorUnitario * venda.descontoPercentual / 100),
						seqOrigem: item.seq,
						...item
					});
				});
				this.vendaBaixar = { ...venda, itens };
				this.vendasOrigem[venda.cdVenda] = { ...venda };
			} else {
				if (venda.cliente.id != this.vendaBaixar.cliente.id) {
					document.getElementById(venda.cdVenda).checked = false;
					return this.$store.commit("relatarErro", { mensagem: "Cliente diferente do primeiro selecionado." });
				} if (venda.cliente.id == this.$store.state.ClientePadrao.id) {
					document.getElementById(venda.cdVenda).checked = false;
					return this.$store.commit("relatarErro", { mensagem: "Para o cliente consumidor final é necessário baixar um por um." });
				} else {
					let itens = [];
					venda.itens.forEach(item => {
						itens.push({
							vendaOrigem: venda.cdVenda,
							dataOrigem: venda.dataEmissao,
							valorUnitario: item.valorUnitario - (item.valorUnitario * venda.descontoPercentual / 100),
							seqOrigem: item.seq,
							...item
						});
					});
					this.vendaBaixar.itens.push(...itens);
					this.vendasOrigem[venda.cdVenda] = { ...venda };
				}
			}
		}
	},

	created() {
		this.$bus.$on("confirmarPolar", this.solicitaAberturaCaixa);
		this.$bus.$on("cancelarPolar", this.polarCancelado);
		document.addEventListener("keydown", this.atalhosTeclado);
		setTimeout(this.atualizarHora, 2000);
		if (!this.$store.state.Usuario.supervisor && !this.$store.state.Usuario.caixa) {
			this.verCondicionais = true;
		}
	},
	
	destroyed() {
		if(this.Event) {
			this.Event.removeEventListener("NOVOPEDIDO", () => {return;});
			this.Event.close();
			this.$store.state.EventSSE = null;
		}
		this.$bus.$off("confirmarPolar", this.solicitaAberturaCaixa);
		this.$bus.$off("cancelarPolar", this.polarCancelado);
		document.removeEventListener("keydown", this.atalhosTeclado);
		document.removeEventListener("mousemove", this.logOutUsuario);
		clearTimeout(this.timerLogOut);

	},
	mounted() {
		if(this.Empresa.tiposistema === "L") {
			this.createConectionSSE();
			this.$store.dispatch("buscarVendasAberto");
		}
		this.VendasCondicional.forEach(venda => {
			if (venda.checked) {
				venda.checked = false;
			}
		});
		this.fecharModal();
	}

};
</script>

<style scoped>
.botoes .botao{
	min-width: 200px;
	margin:5px;
}
.btn-acao {
	margin: auto;
}

#gridTelaPrincipal {
	display: grid;
	grid-template-columns: 120px 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: "MenuLateral dashboard";
	margin: 0px;
	width: 100vw;
	height: 100%;
	max-height: 92vh;
	/* background-color: var(--bg-secundario); */
	background-color: #FFF;
}

#MenuLateral {
	grid-area: MenuLateral;
	position: absolute;
	left: 0;
	/* width: 175px; */
}

.painel-item {
	width: 90%;
	height: 35px;
	display: flex;
	margin-bottom: 5px;
	border: 1px solid var(--primario);
}

#dashboard {
	grid-area: dashboard;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
}

#aba-controle {
	display: flex;
	min-height: 60px;
	max-height: 60px;
	width: 90%;
	margin: 10px auto 0px auto;
}

#aba-controle h2 {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	vertical-align: middle;
}

.aba {
	text-align: center;
	cursor: pointer;
	width: 45%;
	height: 100%;
	transition-property: all;
	transition-duration: 300ms;
	transition-timing-function: ease-in-out;
}

.abaSelecionada {
	text-align: center;
	height: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	width: 100%;
}

.lista-vendas {
	align-items: center;
	height: 100%;
	overflow: hidden;
}

.lista-mesas-aberto{
	align-items: center;
	height: 100%;
	overflow: hidden;
}

.scrollable {
	padding: 0px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-around;
	overflow-y: auto;
	height: 100%;
	flex-wrap: wrap;
	background-color: var(--bg-app);
	border-radius: 10px;
	width: 98%;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	/* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
	margin-bottom: 10px;
}

.scrollable-vazio {
	height: 300px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
}

i {
	color: black;
}

.itemLista {
	border-radius: 20px;
	padding: 5px;
	/*box-shadow: var(--shadow-secundario);*/
	
	background-color: var(--bg-secundario);
	cursor: pointer;
}

.paginacao {
	margin-top: 5px;
}

.tabela-produto{
	width: 100%;
	text-align: center;
	display: flex;
    flex-direction: column;
    justify-content: center;
	align-content: center;
	margin-top: 10px;
}
.tabela-produto table{
	width: 100%;
	height: 40vh;
}
#switch-controle{
	position: absolute;
	bottom: 15px;
	right: 10px;
}

.data-impressao{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.lista-mesas-aberto{
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.container-mesas{
	width: 98%;
	height: 100%;
	padding: 10px;

	background-color: var(--bg-app);
	border-radius: 10px;
	box-shadow: 1px 3px 2px 1px #292929;


	overflow-y: auto;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;	
	gap: 15px;

}

.card-mesa{
	display: flex;
	flex-direction: column;

	width: 150px;

	cursor: pointer;

	background: red;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	border-radius: 10px;
	padding: 10px;
}

.card-mesa:hover{
	transform: scale(1.1);
	transition: all .2s;
}

.header-card-mesa h3{
	text-align: center;
	color: #FFF;
}


.row-body-mesa{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 5px;
}

.row-body-mesa .data{
	font-size: 14px;
}

.row-body-mesa span{
	color: #FFF;
	font-weight: bold;
}

.fa-print{
	background-color: #FFF;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-size: 20px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

@media screen and (max-width: 768px) {
	#routerView {
		background-color: var(--bg-secundario);
		height: 100%;
	}

	#gridTelaPrincipal {
		margin: 0px;
		width: 100%;
		height: 100%;
		max-height: 92vh;
		grid-template-columns: 1fr;
		grid-template-rows: 90px 1fr;
		grid-template-areas: "MenuLateral"
			"dashboard";
		overflow-y: auto;
	}

	#MenuLateral {
		top: 0vh;
		left: 0px;
		right: 0px;
		bottom: unset;
		height: 90px;
		position: relative;
		width: 100%;
		display: flex;
		padding: 0px 10px;
		overflow-x: auto;
		z-index: 2;
	}

	#MenuLateral {
		display: flex;
		flex-direction: row !important;
	}

	#dashboard {
		display: flex;
		height: auto;
		bottom: 0px;
	}

	.lista-vendas-item {
		width: 45%;
	}

	h1,
	h2 {
		font-size: var(--txt-medio);
	}

	.btn {
		height: auto;
	}
}


@media screen and (max-width: 425px) {
	.botoes .botao{
		min-width: auto;
		width: 100% !important;
	}

	#dashboard {
		display: flex;
		height: auto;
	}

	.lista-vendas-item {
		min-width: 90%;
	}
}

@media screen and (max-height: 650px) {
	.fas.fa-user::before {
		font-size: 25pt;
	}

	.btn {
		min-height: 20px !important;
	}
}

@media screen and (max-height: 580px) {
	#gridTelaPrincipal * {
		font-size: var(--txt-pequeno);
	}
}
@media screen and (max-width: 360px){
	.botoes .botao{
		font-size:11px!important;
	}
}
@media screen and (max-width: 300px){
	.botoes-minusculo{
		padding: 0px;
	}
}
</style>
