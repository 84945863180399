<template>
	<div class="container-formulario">
		<div class="form-rateio">
			<div class="input-group">
				<input 
					type="month"
					id="competenciaRateio"
					v-model="competenciaRateio"
				>
				<label for="competenciaRateio">
					Competência
				</label>
			</div>
			<div class="input-group">
				<select 
					id="centroCustoRateio"
					v-model="centroCustoRateio"
				>	
					<option 
						v-for="centro in CentroCustos"
						:key="centro.cdcusto"
						:value="centro.cdcusto"
					>
						{{ centro.cdcusto }} - {{ centro.descricao }}
					</option>
				</select>
				<label for="centroCustoRateio">
					Centro Custo
				</label>
			</div>
			<div class="input-group">
				<input 
					type="text"
					id="planocontas" 
					autocomplete="off"
					@focus="listaPlanoContasFoco"
					@input="filtraPlanoContas"
					@keydown.tab="listaPlanoContas = false"
					:placeholder="' '"
					v-model="planoContaFiltrar">							
				<ListaBuscas 
					v-show="listaPlanoContas" 
					ref="listaPlanoContas"
					:Lista="PlanoContasFiltrado"
					:tipoLista="'PlanoContas'"
					@selecionarElemento="selecionaPlanoContas"
				/>
				<label for="planoContasRateio">
					Plano Contas
				</label>
			</div>
			<div class="input-group">
				<input 
					type="text"
					v-model="valorRateio"
					id="valorRateio"
					autocomplete="off"
					@input="calcularPercentualRateio"
				>
				<label for="valorRateio">
					Valor
				</label>
			</div>
			<div class="input-group">
				<input 
					type="text"
					v-model="percentualRateio"
					id="percentualRateio"
					autocomplete="off"
					@input="calcularValorRateio"
					@keydown.enter="$emit('clicarBotao', 'addRateio')"
				>
				<label for="percentualRateio">
					Percentual
				</label>
			</div>
		</div>
		<div class="valor-adicionar">
			<div class="container-valores">
				<span>
					$ Rateio: {{ (vlrTotalRateio || 0) | formatarParaReais }}
				</span>
				<span>
					$ Título: {{ (valorTitulo || 0) | formatarParaReais }}
				</span>
				<span>
					$ Restante: {{ (restanteRateio || 0) | formatarParaReais }}
					<i class="fas fa-external-link-alt" style="color: var(--primario)" @click="setRestanteToValue"></i>
				</span>
			</div>
			<div>
				<button 
					class="botao botao-acao"
					@click="adicionarRateio"
					id="addRateio"
				>
					<span>
						<span v-if="!editandoRateio">
							Adicionar
						</span>
						<span v-else>
							Alterar
						</span> rateio
					</span>
				</button>
			</div>
		</div>
		<div
			class="container-tabela container-rateio"
			v-if="Rateio.length && Rateio.length > 0"
		>
			<div class="header-tabela">
				<div class="linha-header-tabela">
					<div class="campo-tabela">Competência</div>
					<div class="campo-tabela">Centro de Custo</div>
					<div class="campo-tabela">Plano de Contas</div>
					<div class="campo-tabela">Valor (R$)</div>
					<div class="campo-tabela campo-menor">Percentual</div>
					<div class="campo-tabela campo-menor">Ação</div>
				</div>
			</div>
			<div class="body-tabela">
				<div 
					class="linha-body-tabela" 
					v-for="(rateio, index) in Rateio"
					:key="index"
				>
					<div class="campo-tabela">
						{{ 
							rateio.competencia 
							? rateio.competencia.split("-").reverse().join("/")
							: ""
						}}									
					</div>
					<div class="campo-tabela">
						{{ 
							rateio.centroCusto.cdcusto
						}}
						-
						{{ 
							rateio.centroCusto.descricao
						}}
					</div>
					<div class="campo-tabela">
						
						{{ 
							rateio.planoConta.nome
						}}
					</div>
					<div class="campo-tabela">
						{{
							rateio.valor | formatarParaReais
						}}
					</div>
					<div class="campo-tabela campo-menor">
						{{ 
							rateio.percentual
						}}%
					</div>
					<div class="campo-tabela campo-menor">
						<i 
							class="fas fa-trash"
							style="color: var(--acao-texto)"
							@click="removerRateio(index)"
						/>
						<i 
							class="fas fa-edit"
							style="color: var(--acao-texto)"
							@click="editarRateio(rateio, index)"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import formatarParaReais from "../filters/formatarParaMoeda";
import { Titulo } from "../models/ContaPagar";
import ListaBuscas from "./ListaBuscas.vue";
export default{
	name: "RateioConta",
	components: {
		ListaBuscas,
	},
	props: {
		Rateio: Array,
		valorTitulo: [String, Number],
		situacaoTitulo: {
			type: String,
			default: ""
		}
	},

	data(){
		return {
			competenciaRateio: null,
			centroCustoRateio: null,
			planoContasRateio: null,
			planoContaFiltrar: "",
			listaPlanoContas: false,
			PlanoContasFiltrado:[],
			valorRateio: null,
			percentualRateio: null,
			editandoRateio: null,
			indexAlterando: null
		};
	},
	computed: {
		restanteRateio() {
			const { convertNumber } = new Titulo({});
			const total = Number(convertNumber(this.valorTitulo) - convertNumber(this.vlrTotalRateio));
			return total;
		},

		PlanoContas() {
			const { ListaPlanoContasVisualizacao } = this.$store.state.PlanoContas;
			return this.situacaoTitulo === "A" || this.situacaoTitulo === "B" 
				? ListaPlanoContasVisualizacao.filter(pc => 
					pc.tconta === "(-)"
				) 
				: ListaPlanoContasVisualizacao.filter(pc => 
					pc.tconta === "(+)"
				);
		},

		CentroCustos(){
			return this.$store.state.CentroCustos.Lista;
		},

		vlrTotalRateio(){
			let vlr = 0;
			this.Rateio?.forEach(rateio => {
				vlr += this.verificarVirgula(rateio.valor);
			});
			return vlr;
		},
	},
	
	filters: {
		formatarParaReais
	},

	mounted() {
		this.PlanoContasFiltrado = this.PlanoContas;
	},

	methods: {
		selecionaPlanoContas(plano){
			this.planoContasRateio = plano;
			this.planoContaFiltrar = plano.nome;
			this.listaPlanoContas = false;
		},

		listaPlanoContasFoco(){
			setTimeout(() => {
				this.listaPlanoContas = true;
			}, 200);
		},

		filtraPlanoContas(){
			this.PlanoContasFiltrado = this.PlanoContas.filter((plano)=>{
				return plano.nome.includes(this.planoContaFiltrar.toUpperCase());
			});			
			this.listaPlanoContas = true;
			if(this.planoContaFiltrar.length < 0){
				this.planoContasRateio = null;
			}
		},

		setRestanteToValue() {
			this.valorRateio = this.restanteRateio.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.calcularPercentualRateio();
		},

		validarCamposRateio(){
			const form = new Titulo({});
			try {
				if(!this.competenciaRateio){
					throw new Error("Por favor adicione uma data de competência.");
				}
				if(!this.centroCustoRateio){
					throw new Error("Por favor selecione um centro de custo.");
				}
				if(!this.planoContasRateio){
					throw new Error("Por favor selecione um plano de contas.");
				}
				if(!this.valorRateio){
					throw new Error("Por favor informe o valor do rateio.");
				}
				if(!this.valorTitulo){
					throw new Error("Por favor informe o valor do título.");
				}
				const valorTotalRateio = this.vlrTotalRateio || 0;
				const valorIncluindoAlterando = Number(form?.convertNumber(this.valorRateio).toFixed(2));
				const totalRateioMaisAlterando = !this.editandoRateio 
					? (valorTotalRateio + valorIncluindoAlterando) 
					: (valorTotalRateio + valorIncluindoAlterando) - this.Rateio[this.indexAlterando].valor;
				if(
					(this.Rateio.length > 0 && totalRateioMaisAlterando) > Number(form?.convertNumber(this.valorTitulo).toFixed(2))
				){
					throw new Error("A soma dos valores do rateio deve ser igual o valor total do título, verifique");
				}
				return true;
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
				return false;
			}
		},

		adicionarRateio() {
			if(!this.validarCamposRateio()) return;
			if(this.editandoRateio){
				const alteracao = this.Rateio[this.indexAlterando];
				alteracao.competencia = this.competenciaRateio; 
				alteracao.valor = this.verificarVirgula(this.valorRateio);
				alteracao.percentual = this.verificarVirgula(this.percentualRateio);
				
				// alteracao.planoConta = this.PlanoContas.find(pc => pc.cod == this.planoContasRateio);
				// alteracao.centroCusto = this.CentroCustos.find(cc => cc.cdcusto == this.centroCustoRateio);
				this.editandoRateio = false;
				this.limparForm();
				return;
			}
			const form = {
				competenciaRateio: this.competenciaRateio,
				centroCustoRateio: this.centroCustoRateio,
				planoContasRateio: this.planoContasRateio,
				valorRateio: this.verificarVirgula(this.valorRateio),
				percentualRateio: this.verificarVirgula(this.percentualRateio) 
			};
			this.limparForm();

			this.$emit("adicionarRateio", form);
		},

		limparForm() {
			this.competenciaRateio = "";
			this.centroCustoRateio = "";
			this.planoContasRateio = "";
			this.valorRateio = "";
			this.percentualRateio = "";
		},

		verificarVirgula(str){
			const numeroFormatado = typeof str === "string" ? str.replace(".", "").replace(",", ".") : str;
			const number = parseFloat(numeroFormatado);
			return number;
		},

		calcularPercentualRateio(){
			if(!this.valorTitulo) return;
			const valor = this.verificarVirgula(this.valorRateio || 0);
			const valorTotal = this.verificarVirgula(this.valorTitulo);
			const novoPercentual = Number((valor / valorTotal) * 100);
			this.percentualRateio = novoPercentual.toLocaleString("pt-BR",{
				style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			});
		},

		calcularValorRateio(){
			const percentual = this.verificarVirgula(this.percentualRateio);
			const novoValorRateio = Number(percentual / 100) * Number(this.verificarVirgula(this.valorTitulo));
			this.valorRateio = novoValorRateio.toLocaleString("pt-BR",{
				style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			});
		},

		removerRateio(index){
			if(this.editandoRateio){
				this.$store.commit("relatarErro", {mensagem: "Você removeu o rateio que estava alterando"});
				this.editandoRateio = false;
				this.limparForm();
				return;
			}
			this.Rateio.splice(index, 1);
		},

		editarRateio(rateio, index){
			this.competenciaRateio = rateio.competencia;
			this.centroCustoRateio = rateio.centroCusto.cdcusto;
			this.planoContasRateio = rateio.planoConta;
			this.valorRateio = rateio.valor;
			this.percentualRateio = rateio.percentual;
			this.indexAlterando = index;
			this.editandoRateio = true;
		}
	}
};
</script>
<style scoped>
.container-formulario{
	width: 100%;
	height: 40vh;
	overflow: auto;
	padding: 10px;
	border: solid 2px #cecece;
	display: flex;
	flex-direction: column;	
}

.form-rateio{
	width: 100%;
	display: flex;
	flex-direction: row;
}
.form-rateio .input-group{
	width: 20%;
}
.valor-adicionar{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin-top: 20px;
	width: 100%;
	gap: 10px;
}

.valor-adicionar div{
	width: 50%;
}

.valor-adicionar div button {
	width: 35%;
}

.container-rateio .campo-tabela{
	width: 200px !important;
}
#valorRateio, #percentualRateio{
	text-align: right;
}

.botao span{
	font-size: 15px;
}


.container-tabela{
	width: 89vw;
	overflow: auto;
	height: 41vh;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 7px 5px;
}

.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}
.campo-menor{
	min-width: 100px;
	text-align: center;
}

.container-rateio{
	width: 100% !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.container-valores{
	display: flex;
	flex-direction: row;
	align-items: center;
}

.container-valores span {
	padding: 0px 6px;
	border-right: 2px solid #cecece;
}

@media screen and (max-width: 425px) {
	.form-rateio .input-group {
		width: 100%;
		margin-top: 10px;
	}
	.form-rateio{
		flex-direction: column;
	}
	.valor-adicionar{
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.valor-adicionar div{
		width: auto;
	}
	.valor-adicionar div button{
		width: auto;
	}
	.container-rateio{
		padding-top: 60px;
		padding-bottom: 60px;
	}
}
</style>