<template>
	<div class="container-page">
		<Filtro
			:mostrarBotaoNovo="true"
			@adicionarNovo="editarConsorcio({ novo: true })"
			@handleShowTable="handleShowTable()"
			@filtrar="filtrarConsorcio"
			@limpar="limparFiltro"
		>
			<div class="filtro-content">
				<div class="column-filtro">
					<div class="row-filtro">
						<div class="row">
							<div class="input-group">
								<input type="date" id="dataI" v-model="dataI" @blur="saiuData">
								<label for="dataI" class="text-right m-2" style="background-color:#f8f8f8">Início</label>
							</div>
							<div class="input-group">
								<input type="date" id="dataF" v-model="dataF" @blur="saiuData">
								<label for="dataF" class="text-right m-2" style="background-color:#f8f8f8">Fim</label>
							</div>
						</div>
						<div style="display: flex;flex-direction: row;">
							<div class="input-group">
								<select id="statusPesquisa" class="mx-2" v-model="campostatus" @input="filtrarConsorcio">
									<option value="">Todos</option>
									<option value="A">Aberto</option>
									<option value="C">Cancelado</option>
									<option value="L">Liberado</option>
									<option value="R">Recebeu</option>
								</select>
								<label for="statusPesquisa" class="m-2" style="background-color:#f8f8f8">Status</label>
							</div>
							<div class="input-group">
								<input class="nparcela" v-model.number="nroparcela" type="number" id="nroparcela" autocomplete="off"
									@input="filtrarConsorcio">
								<label for="nroparcela" class="text-right m-2" style="background-color:#f8f8f8">Nº pagas</label>
							</div>
						</div>
					</div>
					<div class="row-filtro">
						<div class="row">
							<div class="input-group" id="campoPesquisa">
								<select class="m-2" @change="selecionaCampoPesquisa" v-model="campopesquisar">
									<option value="cdConsorcio">Código do Consorcio</option>
									<option value="Cliente">Cliente</option>
									<option value="Vendedor">Vendedor</option>
								</select>
							</div>
							<div class="input-group">
								<input type="search" id="campo-filtro-consorcio" class="m-2" autocomplete="off"
									:value="campofiltro ? campofiltro.id + ' - ' + campofiltro.nome : ''"
									:placeholder="`Filtrar por ${pesquisar}`" @keydown="selecionarPesquisa" @select="selecionarPesquisa"
									@click="selecionarPesquisa">
							</div>			
						</div>
					</div>
				</div>
			</div>
		</Filtro>
		<TabelaConsorcio 				
			:showTable="showTable"
			@filtrarConsorcio="filtrarConsorcio()"
		/>
		<Modal v-if="ListarVendedores" v-on:fecharModal="ListarVendedores = false">
			<consulta-vendedores 
				@selecionarPessoa="selecionarVendedor"
				:mostrarBotaoNovo="false"
				:height="'65vh'"
			/>
			<!-- <Paginacao tipoConteudo="Vendedores" :desabilitarNovo="true">
				<TabelaPessoas tipoConteudo="Vendedores" v-on:selecionarPessoa="selecionarVendedor" />
			</Paginacao> -->
		</Modal>
		<Modal v-if="listarClientes" v-on:fecharModal="listarClientes = false">
			<consulta-cliente  
				@selecionarPessoa="selecionarCliente" 
				:mostrarBotaoNovo="false"
				:height="'65vh'"
			/>
		</Modal>
	</div>
</template>
<script>
import Modal from "./Modal.vue";
import Filtro from "../components/Filtro.vue";
import TabelaConsorcio from "../components/TabelaConsorcio.vue";
import { dateFormatISO } from "../utils/dateformat";
import ConsultaCliente from "../components/ConsultaCliente.vue";
import ConsultaVendedores from "../components/ConsultaVendedores.vue";

export default{
	name: "ConsultaConsorcio",
	components: {
		Filtro,
		TabelaConsorcio,
		ConsultaCliente,
		ConsultaVendedores,
		Modal,
	},
	data() {
		return {
			dataI: dateFormatISO({
				date: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1,
				).toLocaleDateString("en-CA"),
				format: "ISOdate"
			}),
			dataF: dateFormatISO({
				date: new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).toLocaleDateString("en-CA"),
				format: "ISOdate"
			}),
			campopesquisar: "Cliente",
			pesquisar: "Cliente",
			campofiltro: "",
			nroparcela: "",
			campostatus: "A",
			ProdutoSel: null,
			showTable: true,
			listarClientes: false,
			ListarVendedores: false,
		};

	},

	computed: {
		Empresas() {
			return this.$store.state.Empresas;
		},
	},

	methods: {
		saiuData(){
			if(this.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataF = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth() + 1,
						0
					).toLocaleDateString("en-CA"), 
					format: "ISOdate"
				});
			}
		},
		handleShowTable() {
			this.showTable = !this.showTable;
		},
		limparFiltro() {
			this.dataI = dateFormatISO({
				date: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1,
				).toLocaleDateString("en-CA"),
				format: "ISOdate"
			});
			this.dataF = dateFormatISO({
				date: new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).toLocaleDateString("en-CA"),
				format: "ISOdate"
			});		
			this.campopesquisar = "Cliente";
			this.campofiltro = "";
			this.filtrarConsorcio(null);
		},
		filtrarConsorcio() {
			clearTimeout(this.timerFiltro);
			this.timerFiltro = setTimeout(() => {
				let filtro = {};
				filtro.cdempresa = this.$store.state.Empresa.id;
				if (this.campoPesquisa != "cdConsorcio" || this.campofiltro == "") {
					filtro.datai = dateFormatISO({date: this.dataI, format: "ISOdate"});
					filtro.dataf = dateFormatISO({date: this.dataF, format: "ISOdate"});
				}
				if (this.campopesquisar == "Cliente") {
					filtro.cdcliente = this.campofiltro.id;
				} else if (this.campopesquisar == "Vendedor") {
					filtro.cdvendedor = this.campofiltro.id;
				}
				filtro.status = this.campostatus;
				filtro.parcelasPago = this.nroparcela;
				this.$store.dispatch("FiltrarConsorcios", filtro);
			}, 450);
		},
		selecionarPesquisa() {
			if (this.campopesquisar != "cdConsorcio") {
				this.efetuarpesquisa();
			} else {
				this.filtrarConsorcio();
			}
		},
		selecionaCampoPesquisa() {
			this.campofiltro = "";
			this.pesquisar = document.querySelector(`option[value=${this.campopesquisar}]`).innerText;
			setTimeout(() => {
				document.getElementById("campo-filtro-consorcio").focus();
			}, 50);
		},
		selecionarCliente(cliente) {
			this.listarClientes = false;
			this.campofiltro = cliente;
			this.filtrarConsorcio();
		},
		selecionarVendedor(vendedor) {
			this.ListarVendedores = false;
			this.campofiltro = vendedor;
			this.filtrarConsorcio();
		},				
		editarConsorcio(consorcio) {
			this.$emit("selecionouConsorcio", consorcio);
		},
		efetuarpesquisa() {
			if (this.campopesquisar == "Vendedor") {
				this.ListarVendedores = true;
			}
			if (this.campopesquisar == "Cliente") {
				this.listarClientes = true;
			}
		},
	},

	mounted() {
		this.filtrarConsorcio();
	}
};
</script>
<style scoped>
.container-page {
	width: 100%;
	overflow: hidden;
}
.filtro-content{
	flex-direction: column;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;
	margin:5px;

}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}

@media screen and (max-width: 769px) {
	#textoPesquisa{
		width: 42vh;
	}
}
</style>