<template>
    <div :class="`informativo-flutuante fade-out ${Informativo ? Informativo.tipo : ''}`">
        <span v-if="Informativo && Informativo.mensagem">{{Informativo.mensagem}}</span>
        <i v-if="Informativo && !Informativo.erro" class="fas fa-times" @click="fecharInformativo"></i>
    </div>
</template>

<script>
export default {
	name: "InformativoFlutuante",

	computed: {
		Informativo() {
			return this.$store.state.Informativo;
		}
	},

	data() {
		return {
			timerFecharInformativo: null
		};
	},

	methods: {
		abrirInformativo() {
			if (!this.Informativo) return this.fecharInformativo();
			document.querySelector("div.informativo-flutuante").classList.remove("fade-out");
			document.querySelector("div.informativo-flutuante").classList.add("fade-in");
			if (!this.Informativo.erro) {
				clearTimeout(this.timerFecharInformativo);
				this.timerFecharInformativo = setTimeout(() => {
					this.fecharInformativo();
					this.timerFecharInformativo = null;
				}, 4000);
			}
		},
		fecharInformativo() {
			const informativo = document.querySelector("div.informativo-flutuante");
			if (informativo) {
				informativo.classList.remove("fade-in");
				informativo.classList.add("fade-out");
			}
			clearTimeout(this.timerFecharInformativo);
			this.timerFecharInformativo = setTimeout(() => {
				this.$store.commit("alterarInformativo", null);
				this.timerFecharInformativo = null;
			}, 500);
		}
	},

	updated() {
		if (this.timerFecharInformativo) {
			setTimeout(() => {
				this.abrirInformativo();
			}, 550);
		} else {
			this.abrirInformativo();
		}
	}
};
</script>

<style scoped>
.informativo-flutuante {
    position: absolute;
    bottom: 10px;
    display: flex;
    width: auto;
    min-height: 35px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 3px 5px 4px #292929;
    color: #fff;
    left: 20%;
    right: 20%;
    z-index: 11;
    visibility: hidden;
    animation-duration: 600ms;
    animation-timing-function: ease-in-out;
    border-radius: 20px;
}

.fade-in {
    visibility: visible;
    animation-name: fadeInOpacity;
    animation-duration: 600ms;
    animation-timing-function: ease-in-out;
}

.fade-out {
    opacity: 1;
    animation-name: fadeOutOpacity;
    animation-duration: 600ms;
    animation-timing-function: ease-in-out;
}

.sucesso {
    background-color: var(--confirmar);
}

.erro {
    background-color: var(--cancelar);
}

.informativo-flutuante span {
    position: relative;
    display: flex;
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.fa-times {
    position: absolute;
    color: #fff;
    right: 8px;
    top: 9px;
    margin: auto 0px;
}
</style>