<template>
    <div id="grid-caixa" class="conteudo-item">
        <i
            class="fas fa-print"
            id="btn-relatorio"
            @click="soliciarRelatorio"
            v-if="Relatorios.saldoEstoque.length"
        ></i>
        <div class="conteudo-filtro">
			<div class="row between m-2 " >
				<input type="radio" id="comsaldo-V" class="pointer m-5" name="comsaldo" value="S"
				v-model="comsaldo" @click="buscarSaldoEstoque">
				<label for="comsaldo-V" class="w-100 pointer" >Saldo positivo</label>
			</div>
			<div class="row between m-2 " >
				<input type="radio" id="comsaldo-N" class="pointer m-5" name="comsaldo" value="N"
				v-model="comsaldo" @click="buscarSaldoEstoque">
				<label for="comsaldo-N" class="w-100 pointer" >Saldo negativo</label>
			</div>
			<div id="filtroDescricao" class="input-group">
				<input id="descricaoProduto" type="text" placeholder=" " v-model="descricaoFiltro" 
					@keydown.enter="filtrarEstoque" autocomplete="off"> 
				<label for="descricaoProduto">Descrição</label>
			</div>
			<div class="selRemFornecedor">
				<i v-if="fornecedorSelecionado" class="fas fa-user-slash" @click="limparFornecedor"></i>
				<i v-else class="fas fa-user" @click="listarFornecedores = true"></i>
			</div>
			<div class="input-group" id="filtroFornecedor">
				<input id="nomeFornecedor" type="text" placeholder=" " v-model="fornecedorFiltro" 
					@keydown.enter="filtrarEstoque" autocomplete="off" @click="listarFornecedores = true">
				<label for="nomeFornecedor">Fornecedor</label>
			</div>
			<button id="botaoFiltrar" class="botao botao-acao" @keydown.enter="filtrarEstoque" @click="filtrarEstoque">
				<i class="fas fa-search"></i>
				<label for="botaoFiltrar" class="lg-view">Filtrar</label>
			</button>
        </div>
		<div v-if="Relatorios.saldoEstoque.length>0" style="overflow: hidden;">
			<div class="conteudo-subitem">
				<div class="scrollable">
					<table class="m-0 w-100">
						<thead>
							<tr>
								<td style="text-align: center; color: var(--primario);">Cód.</td>
								<td class="text-nowrap">Descrição</td>
								<td class="text-center">Saldo</td>
								<td class="text-right text-nowrap">$ Custo</td>
								<td class="text-right text-nowrap">$ Vista</td>
								<td class="text-right text-nowrap">$ Prazo</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in Relatorios.saldoEstoque" :key="index">
								<td style="text-align: center;">{{item.cdproduto}}</td>
								<td class="text-nowrap">{{item.descricao}}</td>
								<td style="text-align: center;">{{(item.saldo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
								<td style="text-align: right;">{{(item.custo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
								<td style="text-align: right;">{{(item.vista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
								<td style="text-align: right;">{{(item.prazo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="input-valor-controle">
					<div class="input-valor-item input-group">
						<input type="text" readonly class="input-valor"
						:value="`${totalSaldo.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}`">
						<label>Saldo</label>
					</div>
					<div class="input-valor-item input-group">
						<input type="text" readonly class="input-valor"
						:value="`${totalCusto.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
						<label>Custo</label>
					</div>
					<div class="input-valor-item input-group">
						<input type="text" readonly class="input-valor"
						:value="`${totalVista.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
						<label>Vista</label>
					</div>
					<div class="input-valor-item input-group">
						<input type="text" readonly class="input-valor"
						:value="`${totalPrazo.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
						<label>Prazo</label>
					</div>
				</div>
			</div>		
			<Modal v-if="listarFornecedores" v-on:fecharModal="listarFornecedores = false">
				<consulta-cliente  
					@selecionarPessoa="selecionarFornecedor" 
					:height="'65vh'"
				/>
				<!-- <Paginacao tipoConteudo="Clientes" :desabilitarNovo="true">
					<TabelaPessoas tipoConteudo="Clientes" 
						v-on:selecionarPessoa="selecionarFornecedor"
						v-on:fecharModal="listarFornecedores = false" />
				</Paginacao> -->
			</Modal>
		</div>
		<div v-else class="col center" id="warning">
			<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
			<h1>Nenhum registro encontrado.</h1>
			<h2>Tente alterar ou diminuir os parâmetros de busca.</h2>
		</div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { gerarRelatorio } from "../utils/Relatorio/impressaorelatorio";
// import TabelaPessoas from "./TabelaPessoas.vue";
// import Paginacao from "./Paginacao.vue";
import Modal from "./Modal.vue"; 
import ConsultaCliente from "./ConsultaCliente.vue";

export default {
	name: "RelatorioSaldoEstoque",
	
	components: {
		Modal,
		// TabelaPessoas,
		// Paginacao,
		ConsultaCliente,
	},

	data() {
		return {
			dataI: "",
			dataF: "",
			timerFiltro: null,
			comsaldo: "S",
			descricaoFiltro: "",
			fornecedorFiltro: "",
			fornecedorSelecionado: false,
			listarFornecedores: false,
			fornecedor: {},
		};
	},

	computed: {
		...mapState(["Relatorios"]),
        
		totalCusto() {
			let preco = 0;
			this.Relatorios.saldoEstoque.map(item => {
				preco += (item.custo || 0);
			});
			return preco;
		},

		totalSaldo() {
			let preco = 0;
			this.Relatorios.saldoEstoque.map(item => {
				preco += (item.saldo || 0);
			});
			return preco;
		},

		totalVista() {
			let preco = 0;
			this.Relatorios.saldoEstoque.map(item => {
				preco += (item.vista || 0);
			});
			return preco;
		},

		totalPrazo() {
			let preco = 0;
			this.Relatorios.saldoEstoque.map(item => {
				preco += (item.prazo || 0);
			});
			return preco;
		},
	},

	methods: {
		selecionarFornecedor(cliente) {
			this.fornecedor = cliente ? cliente : null;
			this.fornecedorFiltro = this.fornecedor ? this.fornecedor.nome : "";

			this.fornecedorSelecionado = this.fornecedor ? true : false;
			this.listarFornecedores = false;

			this.filtrarEstoque();
		},
		limparFornecedor() {
			this.fornecedorSelecionado = false;
			this.fornecedorFiltro = "";
			this.fornecedor = null;
		},
		filtrarEstoque() {
			this.$store.dispatch("filtrarSaldoEstoque", {
				comsaldo: this.comsaldo,
				descricao: this.descricaoFiltro,
				fornecedor: this.fornecedor.id,
			});
		},
		proximoCampo(idCampo) {
			return document.getElementById(idCampo).focus();
		},
		soliciarRelatorio() {
			this.$store.commit("relatarErro", {
				mensagem: "Deseja imprimir o relatório completo?",
				tipo: "polar",
				item: {
					relatorio: true,
					route: this.$route.name
				}
			});
		},
		imprimirRelatorio({completo}) {
			this.$store.state.carregando = true;
			setTimeout(() => {
				gerarRelatorio({
					filtro: {
						empresa: this.$store.state.Empresa,
						itens: this.Relatorios.saldoEstoque,
						completo,
					},
					acao: "gerarRelatorioSaldoEstoque"
				});
			},15);
		},
		buscarSaldoEstoque() {
			if (this.timerFiltro) {
				clearTimeout(this.timerFiltro);
			}
			this.timerFiltro = setTimeout(() => {
				this.$store.dispatch("buscarSaldoEstoque", {comsaldo: this.comsaldo});
			}, 500);
		},

		confirmarPolar(item) {
			if (item.relatorio) {
				this.imprimirRelatorio({
					completo: true
				});
			}
		},

		cancelarPolar(item) {
			if (item.relatorio) {
				this.imprimirRelatorio({
					completo: false
				});
			}
		},
	},

	beforeMount() {
		this.dataI = this.dataI = new Date(
			new Date().getFullYear(),
			new Date().getMonth(),
			1
		).toLocaleDateString("en-CA");
		this.dataF = new Date().toLocaleDateString("en-CA");
		this.filtrarEstoque();
		// this.buscarSaldoEstoque();
	},

	mounted() {
		this.$bus.$on("confirmarPolar", this.confirmarPolar);
		this.$bus.$on("cancelarPolar", this.cancelarPolar);
	},

	destroyed() {
		this.$bus.$off("confirmarPolar", this.confirmarPolar);
		this.$bus.$off("cancelarPolar", this.cancelarPolar);
	}
};
</script>
<style scoped>
.selRemFornecedor {
	display: flex;
	align-items: center;
	justify-content: right;
}
.fa-user, .fa-user-slash {
	color: var(--acao-borda);
}
.botao {
	display: flex;
	flex-direction: row;
	width: auto;
	align-items: center;
	justify-content: center;
	height: 40px;
}

.botao label {
	cursor: pointer;
}

.botao i {
	color: var(--borda);
}

.conteudo-filtro {
	margin: 5px 0px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.conteudo-filtro .input-group {
	width: 30%;
}

.conteudo-filtro label {
	white-space: nowrap;
}

#grid-caixa {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
        "info-conteudo"
        "filtro-contas"
        "sub-item";
    height: 92vh;
    width: 100%;
}

.conteudo-subitem {
    border-radius: 10px;
    box-shadow: var(--shadow-secundario);
    overflow: hidden;
    height: 100%;
    grid-area: sub-item;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.scrollable {
    overflow: auto;
	height: 80vh;
}

.input-valor-controle {
    display: flex;
    flex-direction: row;
    width: auto;
    margin: 2px 10px;
	margin-top: 8px;
    flex-wrap: wrap;
	gap: 15px;
    justify-content: flex-end;
}
.input-valor-item {
    margin: 2px;
}

.input-valor-item input {
	width: 100%;
}

.input-valor {
    width: auto;
    text-align: right;
}
.informacoes-conteudo {
    display: flex;
    flex-direction: row;
    grid-area: info-conteudo;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
}

.informacoes-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.informacoes-item label {
    display: flex;
    justify-content: center;
    align-items: center;
}

.informacoes-item input[type="date"] {
    max-width: 140px;
}

#dataI {
    margin-right: 5px;
}

.filtro-contas {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    padding: 8px 0px;
    max-width: 98vw;
    width: 100%;
    grid-area: filtro-contas;
}

#warning {
	margin-top: 10vh;
}

@media screen and (max-width: 1125px) {
    .informacoes-item {
        max-width: 100%;
        width: auto;
        justify-content: flex-start;
    }

    .informacoes-item input[type="date"] {
        max-width: 150px;
    }

	.input-valor-controle .input-group {
		width: 20%;
	}

}

@media screen and (max-width: 900px) {
    .informacoes-conteudo {
        flex-direction: column;
        align-items: flex-start;
    }

    .input-valor {
        font-size: 10pt;
    }
}

@media screen and (max-width: 768px) {
    #btn-relatorio {
        left: 0;
    }
}

@media screen and (max-width: 695px) {
    .informacoes-conteudo .informacoes-item:nth-child(1) {
        display: none;
    }
    #btn-relatorio {
        left: 0;
    }
}

@media screen and (max-width: 600px) {
    .informacoes-conteudo {
        align-items: flex-end;
    }
}

@media screen and (max-width: 520px) {
    .informacoes-conteudo {
        align-items: center;
    }
    .informacoes-item {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .informacoes-item div {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .informacoes-item div input[type="date"] {
        width: 120px;
    }

    .informacoes-item
        div
        input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
    }

	.conteudo-filtro {
		flex-wrap: wrap;
		gap: 10px;
	}

	.conteudo-filtro .input-group {
		width: 90%;
	}

	#filtroFornecedor {
		width: 70%;
	}

	.input-valor-controle {
		flex-wrap: wrap;
	}

	.input-valor-controle .input-group {
		width: 40%;
	}

	.lg-view {
		display: none;
	}
}

@media screen and (max-width: 440px) {
	#filtroFornecedor {
		margin: 0;
	}

	#filtroDescricao {
		width: 100%;
	}
}

@media screen and (max-width: 375px) {
	#filtroFornecedor {
		width: 65%;
	}
}

@media screen and (max-width: 320px) {
	#filtroFornecedor {
		width: 82%;
	}

	#botaoFiltrar {
		width: 93%;
	}
}
</style>