<template>
	<ContainerPage id="container-plano-contas">
		<ContentPage class="body-page">
			<Filtro
				@filtrar="buscarPlanoContasView()"
				@limpar="limparFiltro"
				:mostrarBotaoNovo="true"		
				@adicionarNovo="abrirModalAdiconarPlano()"
			>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="input-group group-pesquisar">
								<input
									type="text"
									placeholder=" "
									autocomplete="off"
									id="pesquisaLivre"
									v-model="query"
								/>
								<label label="pesquisaLivre">
									Buscar por descrição
								</label>
							</div>
						</div>
					</div>
				</div>
			</Filtro>
			<div
				v-if="PlanoContasVisualizacao.length && PlanoContasVisualizacao.length > 0"
				class="container-list"
			>
				<span 
					v-for="(pc, index) in PlanoContasVisualizacao"
					:key="index"
					class="card-pc"
					:style="`margin-left:${
						PlanoContasVisualizacao.length && PlanoContasVisualizacao.length > 0 
							? verificaPonto(pc, index) : '0'
					}px;`"
				>
					<div class="pc-nome">
						{{ pc.nome }}
						<i 
							class="fas fa-edit"
							style="color: var(--primario)"
							@click="editarPlanoConta(pc)" 
						/>
					</div>

					<span>
						{{ 
							pc.valor ? pc.valor.toLocaleString("pt-BR", {
								style: "currency",
								currency: "BRL"
							}) : null 
						}}
					</span>
					
				</span>
			</div>
			<div class="totalizadores" v-if="PlanoContasVisualizacao && PlanoContasVisualizacao.length > 0">
				<div class="input-group">
					<input 
						type="text"
						:value="(TotalPrevisaoReceita || 0) | formatarParaReais"
						id="total-receita"
						class="input-value"
						readonly
					/>
					<label for="total-receita">
						Total Receita
					</label>
				</div>
				<div class="input-group">
					<input 
						type="text"
						:value="(TotalPrevisaoDespesa || 0) | formatarParaReais"
						id="total-despesa"
						class="input-value"
						readonly
					/>
					<label for="total-despesa">
						Total Despesa
					</label>
				</div>
				<div class="input-group">
					<input 
						type="text"
						:value="(TotalPrevisaoAmbos || 0) | formatarParaReais"
						id="total-ambos"
						class="input-value"
						readonly
					/>
					<label for="total-ambos">
						Total Ambos
					</label>
				</div>
			</div>
			<Modal 
				v-show="adicionarPlano" 
				@fecharModal="fecharModal"
				:idModal="'adicionar-plano-conta'"
				:dimensoes="
					isMobileDevice ? {
						width: '98vw',
						height: '60vh'
					} : {
						width: '75vw',
						height: 'auto'
					}
				"
			>
				<AdicionaPlanoContas 
					v-if="adicionarPlano"
					:desabilitaTipoConta="disableInputTipoConta" 
					:EditandoConta="editandoConta"
					:PlanoContasProp="conta"
					@fecharModal="fecharModal"
				/>
			</Modal>
		</ContentPage>
	</ContainerPage>
</template>
<script>
import Filtro from "../components/Filtro.vue";
import Modal from "../components/Modal.vue";
import ContentPage from "../components/ContentPage.vue";
import ContainerPage from "../components/ContainerPage.vue";
import formatarParaReais from "../filters/formatarParaMoeda";
import AdicionaPlanoContas from "../components/AdicionaPlanoContas.vue";
export default{
	name: "PlanoContas",
	components: {
		Modal,
		Filtro,
		ContentPage,
		ContainerPage,
		AdicionaPlanoContas,
	},
	data(){
		return {
			editandoConta: false,
			mostrarFiltro: true,
			adicionarPlano: false,
			query: "",
			//props adicionar plano contas
			disableInputTipoConta: false,
			conta:{},
		};
	},
	computed: {
		TotalPrevisaoAmbos() {
			const Ambos = this.PlanoContasVisualizacao && this.PlanoContasVisualizacao.length > 0 ?
			this.PlanoContasVisualizacao?.filter(pc => pc.tconta === null) : null;
			let total = 0;
			if(Ambos?.forEach) {
				Ambos.forEach(ab => {
					total += ab.valor;
				});
			}
			return total;
		},

		TotalPrevisaoDespesa() {
			const Despesas = this.PlanoContasVisualizacao && this.PlanoContasVisualizacao?.length > 0 ?
			this.PlanoContasVisualizacao?.filter(pc => pc.tconta === "(-)") : null;
			let total = 0;
			if(Despesas?.forEach) {
				Despesas.forEach(dp => {
					total += dp.valor;
				});
			}
			return total;
		},

		TotalPrevisaoReceita() {
			const Receitas = this.PlanoContasVisualizacao && this.PlanoContasVisualizacao?.length > 0 ? 
			this.PlanoContasVisualizacao?.filter(pc => pc.tconta === "(+)") : null;
			let total = 0;
			if(Receitas?.forEach) {
				Receitas.forEach(rc => {
					total += rc.valor;
				});
			}
			return total;
		},

		PlanoContasVisualizacao() {
			return this.$store.state.PlanoContas?.ListaPlanoContasVisualizacao || [];
		},

		PlanoContasSelecionar() {
			return this.PlanoContasVisualizacao && this.PlanoContasVisualizacao.length > 0 ?
			this.PlanoContasVisualizacao?.filter(pc => pc.alterarValor !== 1) : [];
		},


		PlanoContas() {
			return this.idConta 
				? this.$store.state?.PlanoContas?.ListaPlanoContas?.filter(pc => pc.cod !== this.idConta) 
				: this.$store.state?.PlanoContas?.ListaPlanoContas;
		},

		isMobileDevice() {
			const userAgent = navigator.userAgent;
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
		},
	},
	mounted(){
		this.buscarPlanoContasView();
	},

	filters: {
		formatarParaReais
	},

	methods: {
		verificaPonto(pc) {
			if(!pc) {
				console.log("falta o plano de contas");
				return;
			}
			const stringSeparada = pc?.nome?.split(".");
			if(!stringSeparada) {
				console.log("faltou a string");
				return;
			}
			const qtdPontos = stringSeparada?.length - 1;
			const margins = {
				0: 5,
				1: 25,
				2: 55,
				3: 75,
				4: 95,
			};
			return margins[qtdPontos];
		},

		limparFiltro(){
			this.query = "";
		},
		
		//CONSULTAS
		async buscarPlanoContasView(){
			await this.$store.dispatch("buscarPlanoContasVisualizar");
			this.$forceUpdate();
		},

		async buscarPlanoContas() {
			await this.$store.dispatch("buscarPlanoContas", {
				somentePai: false,
			});
		},

		//MODAL,
		abrirModalAdiconarPlano(conta){
			if(conta){
				this.conta = conta;
			}
			this.adicionarPlano = true;
			this.buscarPlanoContas(false);
		},

		async editarPlanoConta(pc) {
			const contaAtualizada = await this.buscarContaPorId(pc.vid);
			this.editandoConta = true;
			this.disableInputTipoConta = true;
			this.abrirModalAdiconarPlano(contaAtualizada);
		},

		async fecharModal(){
			this.conta = {};
			this.disableInputTipoConta = false;
			this.adicionarPlano = false;
		},

		async buscarContaPorId(id) {
			try {
				const data = await this.$store.dispatch("buscarPlanoContaPorId", {id});
				if(data) {
					return data;
				}
				throw new Error("Alguma coisa deu errado, por favor contate o suporte!");
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}
		}
	}
};
</script>
<style scoped>
.container-list{
	display: flex;
	width: 100%;
	flex-direction: column;
	height: 62vh;
	overflow-y: auto;
}
.group-pesquisar{
	width: 45vw;
}
.input-value {
	text-align: right;
}
.pc-nome{
	min-width: 250px;	
	display: flex;
	align-items: center
}
.card-pc{
	display: flex;
	align-items: center;
}
.totalizadores{
	display: flex;
	margin-top: 10px;
}
</style>
