<template>
	<div class="container-page">
		<paginacao-filtro
			@filtrar="filtrar"
			@limpar="limparFiltro"
			:mostrarBotaoNovo="mostrarBotaoNovo"
			@adicionarNovo="$emit('adicionarNovo')"
			@handleShowTable="handleShowTable"
			:ControlePaginacao="Paginacao"	
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="input-group container-input-promocao">
								<input 	type="search" 
									id="textoPesquisa" 
									name="filtro" 
									placeholder=" " 
									autocomplete="off"
									v-model="filtroPesquisa"
									@keydown.enter.prevent="filtrar"
								/>
								<label for="textoPesquisa" style="background-color: #f8f8f8;">Nome promoção</label>
							</div>
							<div class="input-group select-group">
								<select 
									v-model="tipoFiltro"
									id="tipoFiltro"
								>
									<option v-for="(filtro, index) in Filtros" :key="index" :value="filtro">
										{{filtro}}
									</option>
								</select>
								<label for="tipoFiltro" style="background-color: #f8f8f8;">
									Tipo do Filtro
								</label>
							</div>
							<div class="row datas-filtro" v-if="tipoFiltro !== 'STATUS'">
								<div class="input-group">
									<input type="date" id="datainicio" v-model="dataI" style="margin-top:2px;max-width:130px" @blur="saiuData">
									<label for="datainicio" class="text-right m-2" style="background-color: #f8f8f8;">Início</label>
								</div>
								<div class="input-group">
									<input type="date" id="datafinal" v-model="dataF" style="margin-top:2px;max-width:130px" @blur="saiuData">
									<label for="datafinal" class="text-right m-2" style="background-color: #f8f8f8;">Fim</label>
								</div>
							</div>
							<div class="status-filtro" v-else>
								<div class="input-group">
									<select v-model="campoFiltroStatus">
										<option value="V">Vigente</option>
										<option value="N">Não Vigente</option>
										<option value="A">Ambos</option>
									</select>
									<label class="text-right m-2" style="background-color: #f8f8f8;">Status</label>
								</div>
							</div>		
							
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<TabelaPromocao 
					@selecionarPromocao="$emit('selecionarPromocao', $event)" 
					@filtrarPromocoes="filtrar(null)"
					:showTable="showTable"
				/>
			</template>
		</paginacao-filtro>
	</div>
</template>
<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import TabelaPromocao from "../components/TabelaPromocao.vue";
import { dateFormatISO } from "../utils/dateformat";

export default{
	name: "ConsultaPromocao",
	components: {
		PaginacaoFiltro,
		TabelaPromocao
	},
	props: {
		mostrarBotaoNovo: Boolean,
		Produtos: Array,
		trocaListagem: Boolean,
		troca: Boolean,
		trocaBuscaVenda: Boolean,
		height: String,
	},
	data() {
		return {
			dataI: dateFormatISO({
				date: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1
				).toLocaleDateString("en-CA"),
				format: "ISOdate"
			}),
			dataF: dateFormatISO({
				date: new Date(
					new Date().getFullYear(),
					new Date().getMonth() +1,
					new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						new Date().getDate() -1,
					).getDate() -1
				).toLocaleDateString("en-CA"),
				format: "ISOdate"
			}),
			tipoFiltro: "DATA INICIAL",
			Filtros: [
				"DATA INICIAL",
				"DATA FINAL",
				"STATUS",
			],
			campoFiltroStatus: "A",
			filtroPesquisa: "",
			showTable: true,
		};
	},

	computed: {
		Empresas() {
			return this.$store.state.Empresas;
		},
		Paginacao() {
			return {
				paginaAtual: this.$store.state.paginaPromocaoAtual,
				totalPaginas: this.$store.state.totalPaginasPromocao
			};
		}
	},

	methods: {
		saiuData(){
			if(this.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataF = dateFormatISO({date: new Date(
					new Date().getFullYear(),
					new Date().getMonth() +1,
					new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						new Date().getDate() -1,
					).getDate() -1
				).toLocaleDateString("en-CA"), format: "ISOdate"});
			}
		},
		handleShowTable() {
			this.showTable = !this.showTable;
		},

		limparFiltro() {
			this.tipoFiltro = "DATA INICIAL";
			this.filtroPesquisa = "";
			this.campoFiltroStatus = "A",
			this.focarInputPesquisa();
			this.filtrar(null);
		},

		focarInputPesquisa() {
			setTimeout(() => {
				document.getElementById("textoPesquisa").focus();
			}, 50);
		},

		async filtrar(page) {
			try {
				if (this.dataF < this.dataI) {
					throw new Error("A data final da promoção não pode ser antes da data inicial, verifique!");
				}
				const filtrar = this.tipoFiltro === "DATA INICIAL"?"dataI":(this.tipoFiltro === "DATA FINAL"?"dataF":"status");
				const filtro = {
					page: page || 0,
					nome: this.filtroPesquisa,
					datainicio: dateFormatISO({date: this.dataI, format: "ISOdate"}) ,
					datafinal: dateFormatISO({date: this.dataF, format: "ISOdate"}),
					campoFiltro: filtrar,
					status: this.campoFiltroStatus
				};
				await this.$store.dispatch("filtrarPromocao", filtro);
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		}
	},

	mounted() {
		this.filtrar();
	}
};
</script>
<style scoped>
.container-page {
	width: 100%;
	overflow: hidden;
}

.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;

}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
#textoPesquisa{
	width: 65vh;
}

@media screen and (max-width: 769px) {

	#textoPesquisa{
		width: 42vh;
	}
}

@media screen and (max-width: 390px){
	.row{
		flex-direction: column;
	}
	.row .input-group:nth-child(1){
		margin-bottom: 8px;
	}
}
</style>