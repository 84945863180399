<template>
	<div class="container-page">
		<paginacao-filtro
			@filtrar="filtrarCobrancas"
			@limpar="limparFiltro"
			@handleShowTable="handleShowTable"
			:ControlePaginacao="Paginacao"
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="itemLinha">
								<div class="input-group">
									<input type="date" id="inputDate" v-model="filtro.dataI" @blur="saiuData"/>
									<label 
										for="inputDate" 
										style="background-color: #f8f8f8;"
									>
										Data Inicio
									</label>
								</div>
								<div class="input-group">
									<input 
										type="date"
										id="inputDate"
										v-model="filtro.dataF"
										@blur="saiuData"
									/>
									<label 
										for="inputDate"
										style="background-color: #f8f8f8;"
									>
										Data Final
									</label>
								</div>
							</div>
							<div class="itemLinha">
								<div class="input-group">
									<select v-model="filtro.selectStatus" >
										<option value="SELECIONAR">SELECIONAR</option>
										<option value="CONCLUIDA">CONCLUIDAS</option>
										<option value="REMOVIDA_PELO_USUARIO_RECEBEDOR">CANCELADAS</option>
										<option value="ATIVA">ATIVAS</option>
									</select>
									<label style="background-color: #f8f8f8;">Status</label>
								</div>					
								<div>
									<input
										type="checkbox"
										id="devolvida"
										v-model="filtro.devolvida"
										@change="alternaCheckbox()"
									/>
									<label for="devolvida">
										DEVOLVIDAS
									</label>
								</div>
							</div>
						</div>
						<div class="row-filtro">
							<div class="itemLinha">
								<div class="input-group">
									<select 
										v-model="filtro.select"
										@change="filtro.campoPesquisa = ''"
									>
										<option value="TXID" selected>TXID</option>
										<option value="CPF/CNPJ">CPF/CNPJ</option>
										<option value="Nome Pagador">Nome Pagador</option>
									</select>
								</div>
								<div class="input-group">
									<input 
										type="text"
										id="pesquisaDinamica"
										:placeholder="`Pesquisar por ${filtro.select}`"
										v-model="filtro.campoPesquisa"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<TabelaPix
					@selecionarCobranca="selecionarCobranca"
					:showTable="showTable"
				/>
			</template>
		</paginacao-filtro>
	</div>
</template>
<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import TabelaPix from "../components/TabelaPix.vue";

export default {
	name:"ConsultarPix",	
	components: {
		PaginacaoFiltro,
		TabelaPix,
	},
	data (){
		return{
			filtro:{
				select: "TXID",
				ativo: false,
				dataI: "",
				dataF: "",
				campoPesquisa: "",
				selectStatus: "SELECIONAR",
				devolvida: false,
			},
			showTable: true,
			timerFiltro: null
		};
	},
	computed: {
		Paginacao(){
			return {
				paginaAtual: this.$store.state.ConsultarPix.paginacao.page,
				totalPaginas: this.$store.state.ConsultarPix.paginacao.totalPages
			};
		}
	},
	methods: {
		saiuData(){
			if(this.filtro.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataI = " ";
			}
			if(this.filtro.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.filtro.dataF = " ";
			}
		},
		alternaCheckbox(){
			this.filtro.selectStatus = this.filtro.devolvida?"CONCLUIDA":"SELECIONAR";
			this.filtrarCobrancas(null);
		},
		async filtrarCobrancas(novaPage){
			clearTimeout(this.timerFiltro);	
			this.timerFiltro = setTimeout(async () => {
				let filtro = {
					page: novaPage >= 0 ? novaPage : this.Paginacao.paginaAtual,
					size: 25,
					datai: this.filtro.dataI,
					dataf: this.filtro.dataF,
					filtroDinamico: this.filtro.campoPesquisa,
					tipoPesquisa: this.filtro.select,
					statusCobranca: this.filtro.selectStatus,
					statusDevolucao: this.filtro.devolvida ? "DEVOLVIDO" : "",
				};
				await this.$store.dispatch("filtrarListaPix", filtro);
			}, 1200);
		},
		async buscarLista(){
			await this.$store.dispatch("buscarListaPix");
		},
		selecionarCobranca(pix){
			if(typeof this.$store.state.ConfiguracaoPixEmpresa[0] === "undefined"){
				this.$store.commit("relatarErro", {
					mensagem: "Não existe banco cadastrado para pix nesta empresa!"
				});
			} else {
				this.$emit("selecionarCobranca", pix);
			}			
		},		
		handleShowTable() {
			this.showTable = !this.showTable;
		},
		limparFiltro() {
			this.filtro = {
				select: "TXID",
				ativo: false,
				dataI: "",
				dataF: "",
				campoPesquisa: "",
				selectStatus: "SELECIONAR",
				devolvida: false,
			};
			this.filtrarCobrancas(null);
		},
	},
	mounted(){
		this.buscarLista();
	}
};

</script>
<style scoped>
.container-page {
	width: 100%;
	overflow: hidden;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;
	margin: 5px;

}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
.itemLinha{
	display: flex;
	flex-direction: row;
	align-items: center;
}

.itemLinha .input-group {
	width: 200px;
}

label{
	margin-left: 5px;
}

@media screen and (max-width: 900px){
	.itemLinha{
		display: flex;
		flex-direction: row;
		align-items: center;
		white-space: nowrap;
		justify-content: center;
	}
	.itemLinha div{
		margin-top: 5px;
	}
	.input-group{
		margin:5px;
	}
}

@media screen and (max-width: 440px){
	.botao{
		margin-top: 5px;
		width: 43px;
		height: 43px;
	}
	#filtrar-texto{
		display:none;
	}
}
@media screen and (max-width: 425px){
	.input-group{
		margin:5px;
	}
	.itemLinha .input-group input[type="Date"]{
		width: auto;
	}
}
@media screen and (max-width: 420px){
	.containerTabela{
		height: 40vh;
	}
	.input-group{
		width:90vw;
	}
}
</style>
