import auth from "./auth.js";
import store from "../store/index.js";

export const API_PATH = `${process.env.VUE_APP_API_PATH}`;
export const BASE_URL = "api.lojaspeb.com.br";

export async function doRequest (path, {method, body, cache, signal, contentType}, commit) {
	let response = null;
	try {
		const authHeader = await auth.getAuthorizationHeaders(commit, store.state.API_URL);
		if (!authHeader) throw new Error("Erro de autenticação com a API, favor verificar as credenciais do APP!");
		const {token, tipo} = authHeader;
		const headers = {
			"Authorization": `${tipo} ${token}`
		};
		if(contentType != "off" && contentType){
			headers["Content-Type"] = contentType;
		}else if(!contentType){
			headers["Content-Type"] = "application/json";
		}
		response = await fetch(`${store.state.API_URL}/${path}`, {
			headers,
			method,
			body,
			cache,
			signal
		});
		if (!response) {
			commit("relatarErro", {mensagem: "Não foi possível conectar com a API."});
			return {};
		}
		const responseContentType = `${response.headers.get("content-type")}`;
		if (responseContentType && (responseContentType.indexOf("application/json") != -1)) {
			const data = await response.json();
			return data;
		}
		return {};
	} catch (error) {
		console.log(error);
		if (error?.message == "Failed to fetch") {
			commit("relatarErro", {mensagem: "Erro ao conectar com a API! Favor verificar se a API está disponível ou se API_URL está configurado!"});
			return {};
		}
		if (!response) {
			commit("relatarErro", {mensagem: error.message});
			return {};
		}
		const responseContentType = response.headers.get("content-type");
		if (responseContentType && responseContentType.indexOf("application/json") != -1) {
			const data = await response.json();
			return data;
		}
		return response;
	}
}

export async function doRequestIntercept (path, {method, body, cache}, commit) {
	let response = null;
	try {
		const {token, tipo} = await auth.getAuthorizationHeaders(commit, store.state.API_URL, BASE_URL);
		const form = JSON.stringify({
			method,
			data: body,
			url: `${store.state.API_URL}/${path}`,
			headers: {
				"Content-Type": "application/json",
				"Authorization": `${tipo} ${token}`
			},
		});
		response = await fetch(`${BASE_URL}/interceptor`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `${tipo} ${token}`
			},
			method: "post",
			body: form,
			cache: cache
		});
		if (!response.ok) {
			commit("relatarErro", {mensagem: `Erro inesperado ao realizar a requisição para o servidor,
			Favor entrar em contato com o suporte.\n` +JSON.stringify(response)});
		} else {
			const data = await response.json();
			return data;
		}
	} catch (error) {
		console.error(error);
		commit("relatarErro", {mensagem: JSON.stringify(error)});
		return null;
	}
}

export async function buscarEmpresaAPI ({empresa, id}, commit) {
	let response = null;
	try {
		response = await fetch(`${BASE_URL}/all${empresa ? `?login=${empresa}` : ""}${id ? empresa ? `&id=${id}` : `?id=${id}` : ""}`, {
			headers: {
				"Content-Type": "application/json",
			},
			method: "get",
		});
		if (!response.ok) {
			commit("relatarErro", {mensagem: `Erro inesperado ao realizar a requisição para o servidor,
			Favor entrar em contato com o suporte.\n` +JSON.stringify(response)});
		} else {
			const data = await response.json();
			return data;
		}
	} catch (error) {
		console.error(error);
		commit("relatarErro", {mensagem: error});
		return null;
	}
}