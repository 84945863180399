<template>
    <div id="listaModal" :class="`col tableFixHead ${showTable ? 'table-full' : ''}`" v-if="Pessoas.length >= 1" :style="{'--height':height}">
        <table>
            <thead>
                <tr>
                    <th style="color: blue; text-align: center;">Cód.</th>
                    <th class="text-left ordered" @click="ordenarTabela('nome')">
						Nome
							<i class="fas fa-arrow-up" v-show="ordemTabela == 'asc' && ordemNome && tipoConteudo == 'Clientes'" style="justify-self: right;"></i>
							<i class="fas fa-arrow-down" v-show="ordemTabela == 'desc' && ordemNome && tipoConteudo == 'Clientes'"></i>
					</th>
                    <th class="text-center text-nowrap" v-show="tipoConteudo == 'Clientes'">CPF / CNPJ</th>
                    <th class="text-center text-nowrap ordered" v-show="tipoConteudo == 'Clientes'" 
						@click="ordenarTabela('limitecredito')" style="cursor: pointer;"
					>
						Crédito
							<i class="fas fa-arrow-up" v-show="ordemTabela == 'asc' && ordemCredito && tipoConteudo == 'Clientes'" style="justify-self: right;"></i>
							<i class="fas fa-arrow-down" v-show="ordemTabela == 'desc' && ordemCredito && tipoConteudo == 'Clientes'"></i>
					</th>
                    <th class="text-center" v-show="tipoConteudo == 'Clientes'">Telefones</th>
                    <th class="text-center text-nowrap" v-show="tipoConteudo == 'Clientes'">Data Nasc.</th>
					<th class="text-center text-nowrap ordered" v-show="tipoConteudo == 'Clientes'"
						@click="ordenarTabela('dataCadastro')"
					>
						Data Cadastro
						<i class="fas fa-arrow-up" v-show="ordemTabela == 'asc' && ordemCadastro && tipoConteudo == 'Clientes'" style="justify-self: right;"></i>
						<i class="fas fa-arrow-down" v-show="ordemTabela == 'desc' && ordemCadastro && tipoConteudo == 'Clientes'"></i>
					</th>
                    <th class="text-center" v-show="tipoConteudo == 'Clientes' && mostrarAcao">Ação</th>
				</tr>
            </thead>
            <tbody>
                <tr class="itemLista" v-for="(pessoa, index) in Pessoas" :key="index">
                    <td style="color: blue; text-align: center;" class="text-nowrap" @click="selecionarPessoa(pessoa)">
                        <button :id="index" class="hand-button" v-if="index == indexPessoaSelecionada" @keydown.tab.prevent="" > 
                            <i class="fas fa-hand-point-right" style="color: var(--primario)" >
                            </i>
                        </button>
                        {{pessoa.id}}
                    </td>
                    <td class="text-left text-nowrap campo-nome">
						<span @click="selecionarPessoa(pessoa)" >{{pessoa.nome}}</span>
						<span v-if="temDependentes(pessoa)" class="campo-nome">
							<span v-for="(depende, index) in pessoa.dependentes" :key="index" @click="selecionarPessoa(depende)" class="dependente">
								{{ depende.cdcliente }} - {{ depende.nome }}
							</span>
						</span>
					</td>
                    <td class="text-left text-nowrap" v-show="tipoConteudo == 'Clientes'" @click="selecionarPessoa(pessoa)">{{pessoa.cnpj || "N/C"}}</td>
                    <td v-if="pessoa.limitecredito == -1" class="text-center text-nowrap" v-show="tipoConteudo == 'Clientes'" @click="selecionarPessoa(pessoa)">SEM LIMITE</td>
                    <td v-else class="text-center text-nowrap" v-show="tipoConteudo == 'Clientes'" @click="selecionarPessoa(pessoa)">
                        {{Number(pessoa.limitecredito || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}}
                    </td>
                    <td class="text-nowrap" v-show="tipoConteudo == 'Clientes'" @click="selecionarPessoa(pessoa)">{{`${pessoa.celular || ""} ${pessoa.fone || ""}`}}</td>
                    <td class="text-nowrap" v-show="tipoConteudo == 'Clientes'" @click="selecionarPessoa(pessoa)">
                        {{
                            pessoa.dataNascimento ? pessoa.dataNascimento.split("-")[2] + "/" +
                            pessoa.dataNascimento.split("-")[1] + "/" +
                            pessoa.dataNascimento.split("-")[0] : ""
                        }}
                    </td>
					<td class="text-nowrap" v-show="tipoConteudo == 'Clientes'">
						{{ 
							pessoa.dataCadastro ? pessoa.dataCadastro.split("T")[0].split("-")[2] + "/" +
							pessoa.dataCadastro.split("T")[0].split("-")[1] + "/" +
							pessoa.dataCadastro.split("T")[0].split("-")[0] : ""
						}}
					</td>
                    <td class="center text-nowrap" v-show="tipoConteudo == 'Clientes' && mostrarAcao">
                        <i class="fas fa-edit" style="color: var(--confirmar)" @click="selecionarPessoa(pessoa)"></i>
                        <i class="fas fa-wallet" style="color: var(--primario)" @click="consultarContasPendentes(pessoa)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div v-else class="col center">
        <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt" @click="fecharModal"></i>
        <h1>Nenhum registro encontrado.</h1>
        <h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
    </div>
</template>

<script>
// TABELA PESSOAS RECEBE 2 NOVAS PROPS
// HEIGHT - CONTROLAR ALTURA DA TABELA
// SHOWTABLE - CONTROLAR SE A TABELA ESTA VISIVEL OU NAO
export default {
	name: "TabelaPessoas",

	props: {
		tipoConteudo: String,
		mostrarAcao: Boolean,
		height: String,
		showTable: Boolean,
	},

	computed: {
		Pessoas() {
			return this.$store.state[this.tipoConteudo];
		}
	},

	data() {
		return {
			indexPessoaSelecionada: 0,
			ordemTabela: false,
			ordenNome: false,
			ordemCredito: false,
			ordemNascimento: false,
			ordemCadastro: false,
		};
	},

	methods: {
		temDependentes(pessoa){
			return pessoa.dependentes && pessoa.dependentes.length > 0;
		},
		ordenarTabela(campo) {
			if (this.ordemTabela == null || this.ordemTabela == "asc") {
				this.ordemTabela = "desc";
			} else {
				this.ordemTabela = "asc";
			}

			if (campo == "nome") {
				this.ordemNome = true;
				this.ordemCredito = false;
				this.ordemCadastro = false;
			}

			if (campo == "limitecredito") {
				this.ordemNome = false;
				this.ordemCredito = true;
				this.ordemCadastro = false;
			}
			
			if (campo == "dataCadastro") {
				this.ordemNome = false;
				this.ordemCredito = false;
				this.ordemCadastro = true;
			}
			this.$store.state.carregando = true;
			this.$store.dispatch("ordenarTabelaCliente", 
				{
					campoTabela : campo,
					ordem: this.ordemTabela,
					ativo: this.$store.state.checkboxPesquisa,
				}
			);
			this.$store.state.carregando = false;
		},

		consultarContasPendentes(pessoa) {
			return this.$emit("consultarContas", pessoa);
		},
		selecionarPessoa(pessoa){
			if(pessoa.cdcliente) {
				pessoa.id = pessoa.cdcliente;
			}
			return this.$emit("selecionarPessoa", pessoa);
		},

		fecharModal(){
			if (this.$route.query.tipoVenda == "C" && this.$store.state.Venda.cliente.id == this.$store.state.ClientePadrao.id){
				return this.$store.commit("relatarErro", "É necessário informar o cliente para iniciar a venda condicional");
			}
			this.$emit("fecharModal");
		},

		eventosTeclado(e) {
			if (this.$store.state.mensagemErro || this.$store.state.carregando) {
				return;
			}
			if (e.key == "ArrowUp") {
				if (this.indexPessoaSelecionada > 0) {
					this.indexPessoaSelecionada--;
				}
				return setTimeout(() => {
					document.getElementById(this.indexPessoaSelecionada).focus();
				}, 10);
			}
			if (e.key == "ArrowDown") {
				if (this.indexPessoaSelecionada < this.Pessoas.length -1) {
					this.indexPessoaSelecionada++;
				}
				return setTimeout(() => {
					document.getElementById(this.indexPessoaSelecionada).focus();
				}, 10);
			}
			if (e.key == "Enter") {
				e.preventDefault();
				return this.selecionarPessoa(this.Pessoas[this.indexPessoaSelecionada]);
			}
			if (e.key == "Tab") {
				this.indexPessoaSelecionada = 0;
				return setTimeout(() => {
					document.getElementById("textoPesquisa")?.focus();
				}, 80);
			}
		}
	},

	mounted(){
		document.addEventListener("keydown", this.eventosTeclado);
		const elemento = document.getElementById("textoPesquisa");
		if(elemento){
			elemento.addEventListener("focus", () => {
				this.indexPessoaSelecionada = 0;
			});
		}
	},

	destroyed() {
		document.removeEventListener("keydown",this.eventosTeclado);
		this.$store.commit(`atualizarLista${this.tipoConteudo}`, {content: []});
	}
};
</script>

<style scoped>
.campo-nome {
	display: flex;
	flex-direction: column;
}

#listaModal.table-full{
	height: 78vh !important;
}

#listaModal {
    margin: 0 auto;
    font-size: var(--txt-normal);
	height: var(--height);
}
.fa-arrow-up, .fa-arrow-down {
	color: var(--primario);
}

.ordered {
	cursor: pointer;
}


label {
    cursor: pointer;
}

.hand-button {
    background: none;
    border: none;
    outline: none;
    clear: both;
}

thead td:nth-child(1),
tbody td:nth-child(1) {
    width: 100px;
}

.tableFixHead {
	overflow-y: auto;
	height: 74vh;
	width: 98%;
}

.tableFixHead thead th {
	position: sticky; 
	top: 0px;
	font-weight: normal;
}

.dependente{
	color: #f00;
	font-weight: bold;
	font-size: 13px;
	line-height: 20px;
}

@media screen and (max-width: 1024px) {
	.tableFixHead {
		height: 72vh;
	} 
}

@media screen and (max-width: 768px) {
	.tableFixHead {
		height: 46vh;
	}

	#listaModal.table-full{
		height: 67vh !important;
	}

	#listaModal{
		height: 45vh;
	}
}

@media screen and (max-width: 425px) {
	

	#listaModal.table-full{
		height: 71vh !important;
	}

	#listaModal{
		height: 43vh;
	}
}
</style>