<template>
	<div class="container-page">
		<paginacao-filtro
			@filtrar="buscarContasReceber"
			@limpar="limparFiltro"
			@handleShowTable="handleShowTable"
			:ControlePaginacao="Paginacao"
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="informacoes-item" id="dataVencimento">
								<span>Filtrar por data {{ condicao == "A" ? "de vencimento" : "da baixa" }}</span>
								<div class="row center mx-5" v-if="condicao == 'B'">
									<input type="checkbox" id="outraEmpresa" :value="true" v-model="outraEmpresa" class="mx-2 pointer">
									<label for="outraEmpresa" class="pointer">Baixados em outra empresa</label>
								</div>
							</div>
							<div class="containerTipo">
								<div class="row between m-2 ">
									<input type="radio" id="aberto" class="pointer m-5" name="tipoContas" value="A"
										v-model="condicao" @change="changeCondicao">
									<label for="aberto" class="w-100 pointer">Aberto</label>
								</div>
								<div class="row between m-2 ">
									<input type="radio" id="baixado" class="pointer m-5" name="tipoContas" value="B"
										v-model="condicao" @change="changeCondicao">
									<label for="baixado" class="w-100 pointer">Baixado</label>
								</div>
							</div>
							<div class="input-group">
								<input v-model="dataI" type="date" id="dataI" @blur="saiuData"/>
								<label for="dataI" style="background:#f8f8f8">Data Inicial</label>
							</div>
							<div class="input-group">
								<input v-model="dataF" type="date" id="dataF" @blur="saiuData"/>
								<label for="dataF" style="background:#f8f8f8">Data final</label>
							</div>
							<div class="row exibicao">
								<div class="informacoes-item exibicaoItens" v-if="condicao !== 'B'">
									<div class="input-group">
										<select v-model="size" id="sizeExibicao">
											<option :value="25">
												25 itens
											</option>
											<option :value="50">
												50 itens
											</option>
											<option :value="150">
												150 itens
											</option>
										</select>
										<label for="sizeExibicao" style="background:#f8f8f8">Exibição</label>
									</div>
									<div class="input-group">
										<select v-model="sort" id="ordenarPor">
											<option value="nome">
												Nome
											</option>
											<option value="cdpessoa">
												N° Ficha
											</option>
											<option value="datavenc">
												Data vencimento
											</option>
										</select>
										<label for="ordenarPor" style="background:#f8f8f8">Ordenar por</label>
									</div>					
									<div class="ordem">
										<i class="fas fa-sort-amount-down" style="color: blue; font-size: 20px;" v-if="ordem == 'desc'"
											@click="ordenar('asc')"></i>
										<i class="fas fa-sort-amount-down-alt" style="color: blue; font-size: 20px;" v-else
											@click="ordenar('desc')"></i>
									</div>
								</div>
								<div class="informacoes-item exibicaoItens" v-else>
									<div class="input-group">
										<select v-model="size" id="sizeExibicao">
											<option :value="25">
												25 itens
											</option>
											<option :value="50">
												50 itens
											</option>
											<option :value="150">
												150 itens
											</option>
										</select>
										<label for="sizeExibicao">Exibição</label>
									</div>		
									<div class="input-group">
										<select v-model="sort" id="ordenarPor">
											<option value="nome">
												Nome
											</option>
											<option value="cdpessoa">
												N° Ficha
											</option>
											<option value="datavenc">
												Data vencimento
											</option>
										</select>
										<label for="ordenarPor" style="background:#f8f8f8">Ordenar por</label>
									</div>										
									<div class="ordem">
										<i class="fas fa-sort-amount-down" style="color: blue; font-size: 20px;" v-if="ordem == 'desc'"
											@click="ordenar('asc')"></i>
										<i class="fas fa-sort-amount-down-alt" style="color: blue; font-size: 20px;" v-else
											@click="ordenar('desc')"></i>
									</div>
								</div>
							</div>	
						</div>
						<div class="row-filtro">
							<div class="selecionarCliente">
								<i class="fas fa-address-book" @click="listarClientes = true"
									style="color: var(--primario); font-size: 20px;" v-if="!Cliente"></i>
								<i class="fas fa-user-slash" @click="limpaClinete"
									style="color: var(--primario); font-size: 20px;" v-else></i>
								<div class="input-group">
									<input type="search" class="w-50" autocomplete="off" readonly @input="listarClientes = true"
										id="input-nome-cliente" :value="Cliente ? `${Cliente.id} - ${Cliente.nome}` : 'Selecionar cliente'"
										@focus="listarClientes = true">
								</div>
							</div>
							<div class="row half" v-if="condicao === 'A'">
								<div class="input-group">
									<select
										id="empresa"
										v-model="filtrarEmpresa"
									>
										<option 
											v-for="empresa in Empresas" 
											:key="empresa.id"
											:value="empresa.id"
										>
											{{ empresa.id }} - {{ empresa.nome }}
										</option>
									</select>
									<label for="empresa" style="background:#f8f8f8">
										Empresa
									</label>
								</div>
							</div>
							<div class="row half">
								<div class="input-group">						
									<select id="select-metodo" v-model="tipoPagamento">
										<option disabled value="SEL">
											SELECIONE UMA OPÇÃO
										</option>
										<option 
											v-for="(pgto, index) in formasPagamento" 
											:key="index"
											:value="pgto.cdtipo"
											>
											{{  pgto.descricao }}
										</option>
									</select>
									<label for="select-metodo" style="background:#f8f8f8">
										Recebimento
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<TabelaContasReceber
					:Contas="condicao == 'B' ? Relatorios.contasBaixadas : Financeiro.contasReceber.contas"
					:condicao="condicao" :selecionar="false" :relatorio="true" :showTable="showTable"
					v-if="(condicao == 'B' && Relatorios.contasBaixadas.length > 0) || (condicao == 'A' && Financeiro.contasReceber.contas.length > 0)"
				/>
				<div v-else class="col center" id="warning">
					<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
					<h1>Nenhum registro encontrado.</h1>
					<h2>Tente alterar ou diminuir os parâmetros de busca.</h2>
				</div>				
			</template>			
		</paginacao-filtro>
		<div class="conteudo-subitem">
			<div class="input-valor-controle" v-if="condicao == 'A' && Financeiro.contasReceber.contas.length > 0">
				<div class="input-group">
					<input type="text" id="valorTotal" readonly class="input-valor" :value="`${(Totalizador.totalTitulos || 0).toLocaleString('pt-BR', {
						style: 'currency',
						currency: 'BRL'
					})}`">
					<label>Títulos</label>
				</div>
				<div class="input-group">
					<input type="text" id="recebido" readonly class="input-valor" :value="`${(Totalizador.totalValorRecebido || 0).toLocaleString('pt-BR', {
						style: 'currency',
						currency: 'BRL'
					})}`">
					<label>Recebido</label>
				</div>
				<div class="input-group">
					<input type="text" id="totalJuros" readonly class="input-valor" :value="`${(totalJurosCarencia || 0).toLocaleString('pt-BR', {
						style: 'currency',
						currency: 'BRL'
					})}`">
					<label>Juros</label>
				</div>
				<div class="input-group">
					<input type="text" id="totalGeral" readonly class="input-valor" :value="`${(totalGeral || 0).toLocaleString('pt-BR', {
						style: 'currency',
						currency: 'BRL'
					})}`">
					<label>Total</label>
				</div>
			</div>
			<div class="input-valor-controle" v-else-if="condicao == 'B' && Relatorios.contasBaixadas.length > 0">
				<div class="input-group">
					<input type="text" readonly class="input-valor" :value="`${
						(totalGeralBaixadas || 0).toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL'
						})
					}`">
					<label>Total</label>
				</div>
			</div>
			<i class="fas fa-print" id="btn-relatorio" @click="soliciarRelatorio"
				v-if="Financeiro.contasReceber.contas.length > 0">
			</i>
		</div>
		<Modal v-if="listarClientes" v-on:fecharModal="listarClientes = false">
			<consulta-cliente  
				@selecionarPessoa="selecionarCliente" 
				:height="'65vh'"
			/>
			<!-- <Paginacao :tipoConteudo="'Clientes'" :desabilitarNovo="true">
				<TabelaPessoas :tipoConteudo="'Clientes'" v-on:selecionarPessoa="selecionarCliente" />
			</Paginacao> -->
		</Modal>
	</div>
</template>

<script>
import { mapState } from "vuex";
import TabelaContasReceber from "../components/TabelaContasReceber.vue";
import Modal from "../components/Modal.vue";
// import Paginacao from "../components/Paginacao.vue";
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
// import TabelaPessoas from "../components/TabelaPessoas.vue";
import { dateFormatISO } from "../utils/dateformat";
import formatarParaReais from "../filters/formatarParaMoeda";
import { gerarRelatorio } from "../utils/Relatorio/impressaorelatorio";
import ConsultaCliente from "../components/ConsultaCliente.vue";

export default {
	name: "RelatorioContasReceber",

	components: {
		TabelaContasReceber,
		Modal,
		// Paginacao,
		PaginacaoFiltro,
		// TabelaPessoas,
		ConsultaCliente
	},

	data() {
		return {
			dataI: "",
			dataF: "",
			abrirModal: false,
			caixaController: "",
			tipoMovimento: "",
			condicao: "A",
			listarClientes: false,
			Cliente: null,
			metodoOrdem: "datavenc",
			ordem: "asc",
			timerFiltro: null,
			outraEmpresa: null,
			filtrarEmpresa: null,
			formatarParaReais,
			size: 25,
			tipoPagamento: "CART",
			recalcula: true,
			showTable: true,
			sort: "nome"
		};
	},
	filters: {
		formatarParaReais
	},
	
	computed: {
		...mapState(["Financeiro", "Relatorios"]),

		totalJurosCarencia(){
			let total = 0,contas;
			if(this.condicao == "B"){
				contas = this.Relatorios.contasBaixadas;
			} else {
				contas = this.Financeiro.contasReceber.contas;
			}			
			contas.forEach(conta => {
				let Empresa;
				this.$store.state.Empresas.forEach(empresa => {
					if (empresa.id === conta.cdEmpresa){
						Empresa = empresa;
					}
				});
				if(conta.diasEmAtraso > Empresa.carenciaJuros){
					total += Number((conta.jurosCalculados + (conta.jurosAcumulado || 0)).toFixed(2));
				}
			});
			return total;
		},
		totalGeralBaixadas() {
			return this.Relatorios.totalRecebimentoContasReceber;
		},
		
		PaginacaoBaixadas() {
			return this.Relatorios.paginacao;
		},

		Empresas () {
			return this.$store.state.Empresas;
		},

		formasPagamento () {
			const formasPgto = this.$store.state.formasPagamento.filter(forma => forma.mostrarecebimento == "S");
			if(this.condicao === "B") {
				return formasPgto.filter(pgto => pgto.vistaprazo === 1 || pgto.cartao === "S");	
			} 
			return formasPgto.filter(pgto => pgto.vistaprazo === 3 || pgto.cartao === "S");
		},

		Totalizador () {
			return this.$store.state.Totalizadores;
		},

		// totalGeralBaixadas(){
		// 	let preco = 0;
		// 	this.Relatorios.contasBaixadas.forEach(conta => {
		// 		preco += conta.valor;
		// 	});
		// 	return preco.toLocaleString("pt-BR", {style: "currency", currency: "BRL"});
		// },

		totalGeral() {
			this.recalcula;
			const totalTitulos = this.Totalizador.totalTitulos;
			const totalRecebido = this.Totalizador.totalValorRecebido;
			const totalJuros = this.Totalizador.totalJuros;
			return Number(Number(totalTitulos) - Number(totalRecebido) + Number(totalJuros));
		},
		Paginacao() {
			if(this.condicao === "B"){
				return {
					paginaAtual: this.$store.state.Relatorios.paginacao.page,
					totalPaginas: this.$store.state.Relatorios.paginacao.totalPages,
					totalElements: this.$store.state.Relatorios.paginacao.totalElements,
				};
			}
			else{
				return {
					paginaAtual: this.$store.state.Financeiro.paginacao.page,
					totalPaginas: this.$store.state.Financeiro.paginacao.totalPages,
					totalElements: this.$store.state.Financeiro.paginacao.totalElements,
				};
			}
		},
	},

	methods: {
		saiuData(){
			if(this.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataF = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		handleShowTable() {
			this.showTable = !this.showTable;
		},
		changeCondicao() {
			if(this.condicao === "B") this.tipoPagamento = "SEL";
		},

		soliciarRelatorio() {
			this.$store.commit("relatarErro", {
				mensagem: "Deseja imprimir o relatório em A4?",
				tipo: "polar",
				item: {
					relatorio: true,
					route: this.$route.name
				}
			});
		},
		async imprimirRelatorio({ pageWidth }) {
			try {
				if(this.condicao === "B") {
					await this.$store.dispatch("buscarRelatorioContasBaixadas", {
						dataI: this.dataI,
						dataF: this.dataF,
						cliente: this.Cliente,
						outraEmpresa: this.outraEmpresa,
						size: this.Paginacao.totalElements,
						page: 0,
						sort: this.sort || "cdpessoa",
						tipoPagamento: this.tipoPagamento !== "SEL" ? this.tipoPagamento : "",
					});
				} else {
					await this.$store.dispatch("filtrarContasReceber", {
						dataVencI: this.dataI,
						dataVencF: this.dataF,
						condicao: this.condicao,
						sort: this.sort,
						sortOrder: this.ordem,
						cliente: this.Cliente,
						empresa: this.filtrarEmpresa,
						size: this.Paginacao.totalElements,
						page: 0,
						tipoPagamento: this.tipoPagamento !== "SEL" ? this.tipoPagamento : "",
					});
				}
			} catch (error) {
				console.log(error);
			}
			
			gerarRelatorio({
				filtro: {
					empresa: this.Empresas.filter(emp => emp.id == this.filtrarEmpresa)[0],
					parcelas: this.condicao == "A" ? this.Financeiro.contasReceber.contas : this.Relatorios.contasBaixadas,
					dataI: this.dataI,
					dataF: this.dataF,
					cliente: this.Cliente,
					condicao: this.condicao,
					pageWidth,
				},
				acao: "gerarExtratoContasReceber"
			});
		},
		limparFiltro(){
			this.condicao = "B";
			this.outraEmpresa = null;
			this.dataI = dateFormatISO({
				date: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1
				).toLocaleDateString("en-CA"),
				format: "ISOdate"
			});
			this.dataF = dateFormatISO({date: null, format: "ISOdate"});
			this.size = 25;
			this.ordem = "asc";
			this.Cliente = null; 
			this.filtrarEmpresa = this.$store.state.Empresa.id;
			const carteira = this.formasPagamento.find(pgto => pgto.cdtipo === "CART");
			if(carteira) {
				this.tipoPagamento = "CART";
			} else {
				this.tipoPagamento = "DP";
			}
			this.buscarContasReceber(null);
			this.condicao = "A";
			this.buscarContasReceber(null);
		},
		limpaClinete(){
			this.Cliente = null; 
		},
		buscarContasReceber(page) {
			if (this.timerFiltro) {
				clearTimeout(this.timerFiltro);
			}
			this.timerFiltro = setTimeout(() => {
				if (this.condicao == "B") {
					this.$store.dispatch("buscarRelatorioContasBaixadas", {
						dataI: this.dataI,
						dataF: this.dataF,
						cliente: this.Cliente,
						outraEmpresa: this.outraEmpresa,
						size: this.size,
						page: page || 0,
						sort: this.sort || "cdpessoa",
						tipoPagamento: this.tipoPagamento !== "SEL" ? this.tipoPagamento : "",
					});
				} else {
					this.$store.dispatch("filtrarContasReceber", {
						dataVencI: this.dataI,
						dataVencF: this.dataF,
						condicao: this.condicao,
						sort: this.sort,
						sortOrder: this.ordem,
						cliente: this.Cliente,
						empresa: this.filtrarEmpresa,
						size: this.size,
						page: page || 0,
						tipoPagamento: this.tipoPagamento !== "SEL" ? this.tipoPagamento : "",
					});
				}
				this.recalcula = !this.recalcula;
			}, 500);
		},

		ordenar(ordem) {
			this.ordem = ordem;
		},

		selecionarCliente(cliente) {
			this.listarClientes = false;
			this.Cliente = cliente;
		},

		confirmarPolar(item) {
			if (item.relatorio) {
				this.imprimirRelatorio({
					pageWidth: "A4"
				});
			}
		},

		cancelarPolar(item) {
			if (item.relatorio) {
				this.imprimirRelatorio({
					pageWidth: null
				});
			}
		},
	},

	beforeMount() {
		this.dataI = dateFormatISO({
			date: new Date(
				new Date().getFullYear(),
				new Date().getMonth(),
				1
			).toLocaleDateString("en-CA"),
			format: "ISOdate"
		});
		this.dataF = dateFormatISO({date: null, format: "ISOdate"});
		this.limparFiltro();
	},

	mounted() {
		this.filtrarEmpresa = this.$store.state.Empresa.id;
		this.$bus.$on("confirmarPolar", this.confirmarPolar);
		this.$bus.$on("cancelarPolar", this.cancelarPolar);
		const carteira = this.formasPagamento.find(pgto => pgto.cdtipo === "CART");
		if(carteira) {
			this.tipoPagamento = "CART";
		} else {
			this.tipoPagamento = "DP";
		}
	},

	destroyed() {
		this.$bus.$off("confirmarPolar", this.confirmarPolar);
		this.$bus.$off("cancelarPolar", this.cancelarPolar);
	}
};
</script>
<style scoped>
.container-page {
	width: 100%;
	height: 92vh;
	overflow: hidden;
}
.input-group{
	margin:5px;
}
.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
.conteudo-subitem {
	width: 100%;
}
#btn-relatorio {
	position: absolute;
}

.informacoes-item {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.exibicaoItens {
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.selecionarCliente {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.selecionarEmpresa {
	width: 45%;
}
.input-valor-controle {
	display: flex;
	justify-content: right;
	margin-top: 10px;
}
.input-valor-controle .input-group {
	width: 20%;
}

.input-valor-controle .input-group input {
	text-align: right;
}
#warning {
	margin-top: 10vh;
}

@media screen and (max-width: 1024px) {
	.exibicaoItens {
		width: 95%;
		justify-content: right;
	}
}

@media screen and (max-width: 768px) {
	::-webkit-calendar-picker-indicator {
		display: none;
	}
	#dataVencimento {
		width: 40%;
	}
	
	.containerTipo {
		display: flex;
		flex-direction: row;
		padding-bottom: 5px;
	}
	.exibicaoItens {
		width: auto;
	}
}

@media screen and (max-width: 440px) {
	#dataVencimento {
		white-space: nowrap;
		width: auto;
		height: auto;
	}
	.selecionarCliente {
		width: 100%;	
	}
	.input-valor-controle {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 10px;
	}
	.input-valor-controle  .input-group{
		width: 35%;
	}
	#btn-relatorio {
		position: fixed; 
		left: 0;
		bottom: 5;
	}
	.exibicaoItens {
		width: 100%;
		justify-content: center;
	}
	.container-page {
		width: 100vw;
	}
	.half{
		max-width: 50%;
	}
	.input-valor-controle {
		gap: 0px;
	}
}

@media screen and (max-width: 375px) {
	.tipoFiltro {
		white-space: normal;
	}
}
</style>