<template>
	<div class="containerPage">
		<PaginacaoFiltro
			@filtrar="filtrar"
			@limpar="limparFiltro"
			@handleShowTable="handleShowTable"
			:ControlePaginacao="{totalPaginas:0}"
		>
			<template v-slot:filtro>
				<div class="input-group">
					<input 
						type="date" 
						id="data"
						v-model="dataMovimentacao"
						placeholder=" " 
						@keydown.enter.prevent="proximoCampo('pesquisar')"
						@blur="saiuData"
					/>
					<label for="data" style="background-color: #f8f8f8;">
						Data
					</label>
				</div>
			</template>
			<template v-slot:tabela>
				<TabelaConciliadora 
					@handleEditMovi="handleEditMovi"
					:showTable="showTable"
				/>				
			</template>			
			
		</PaginacaoFiltro>
		<div style="display:flex;flex-direction:row;justify-content:flex-end">
			<button class="botao botao-acao" @click="solicitarEnviarVendas()" style="margin-right: 10px">
				Enviar Vendas
			</button>
		</div>
		<transition name="fade">
				<Modal
					v-if="modalEnviarVendas"
					:dimensoes="{width: 'auto', height: 'auto'}"
					@fecharModal="modalEnviarVendas = false"
				>
					<div class="containerModal">
						<div class="modalTtile">
							<h3>CONFIGURAÇÃO PARA ENVIAR VENDAS</h3>
						</div>
						<div class="containerForm">
							<div class="rowForm">
								<div class="colForm">
									<div class="input-group">
										<input 
											placeholder=" "
											v-model="dataEnviarMovimentacao"
											id="dataMovimentacao"
											type="date"
										/>
										<label for="dataMovimentacao">
											Data para conciliar vendas
										</label>
									</div>
								</div>
							</div>
							<div class="rowForm">
								<button class="botao botao-adicionar" @click="enviarMovimentacoes">
									Enviar
								</button>
							</div>
						</div>
					</div>
				</Modal>
		</transition>
		<transition name="fade">
			<Modal 
				v-if="editandoMovimentacoes" 
				@fecharModal="editandoMovimentacoes = false"
				:dimensoes="{width: '80%', height: 'auto'}"
			>
				<div class="containerModal">
					<div class="modalTitle">
						<h3>CORRIGINDO MOVIMENTAÇÕES</h3>
					</div>
					<div class="containerForm">
						<div class="rowForm">
							<div class="colForm">
								<div class="input-group">
									<input
										placeholder=" "
										v-model="form.bandeira"
										type="text"
										id="bandeiraForm"
										list="bandeiras"
									/>
									<label for="bandeiraForm">
										Bandeira
									</label>
									<datalist id="bandeiras" name="bandeiras">
										<option :value="bandeira.adm" v-for="bandeira in bandeiras" :key="bandeira.codigo">
										</option>
									</datalist>
								</div>
							</div>
							<div class="colForm">
								<div class="input-group">
									<input
										placeholder=" "
										v-model="form.produtoConciliadora"
										type="text"
										id="produtoForm"
										list="produtos"
									/>
									<label for="produtoForm">
										Produto Conciliadora
									</label>
									<datalist id="produtos" name="produtos">
										<option :value="produto.nome" v-for="produto in listaprodutos" :key="produto.id">
										</option>
									</datalist>
								</div>
							</div>
							<div class="colForm">
								<div class="input-group">
									<input
										placeholder=" "
										v-model="form.nsuCartao"
										type="text"
										id="nsuForm"
									/>
									<label for="nsuForm">
										Nsu Cartão
									</label>
								</div>
							</div>
							<div class="colForm smallCol">
								<div class="input-group">
									<input
										placeholder=" "
										v-model="form.docCartao"
										type="text"
										id="authForm"
									/>
									<label for="authForm">
										N° Autorização
									</label>
								</div>
							</div>
						</div>
						<div class="rowForm">
							<div class="colForm">
								<div class="input-group">
									<input
										placeholder=" "
										v-model="form.vlrTitulo"
										class="input-valor"
										type="text"
										id="vlrTituloForm"
										readonly
									/>
									<label for="vlrTituloForm">
										Valor Título
									</label>
								</div>
							</div>
							<div class="colForm">
								<div class="input-group">
									<input
										placeholder=" "
										v-model="form.vlrParcelaSistema"
										type="text"
										class="input-valor"
										id="vlrParcelaSistemaForm"
										readonly

									/>
									<label for="vlrParcelaSistemaForm">
										Valor Parcela Sistema
									</label>
								</div>
							</div>
							<div class="colForm ">
								<div class="input-group">
									<input 
										placeholder=" "
										v-model="form.vlrParcelaAjustada"
										class="input-valor"
										type="text"
										id="vlrParcelaAjustadaForm"
									/>
									<label for="vlrParcelaAjustadaForm">
										Valor Parcela Ajustada
									</label>
								</div>
							</div>
						</div>
					</div>
					<div class="containerButtons">
						<button id="cancelar" class="botao botao-cancelar" @click="cancelEditMovimentacao">
							Cancelar
						</button>
						<button id="salvar" class="botao botao-adicionar" @click="saveMovimentacao">
							Salvar
						</button>
					</div>
				</div>
			</Modal>
		</transition>
	</div>
</template>
<script>
import Modal from "../components/Modal.vue";
import TabelaConciliadora from "../components/TabelaConciliadora.vue";
import PaginacaoFiltro from "./PaginacaoFiltro.vue";
import { dateFormatISO } from "../utils/dateformat";


export default{
	name: "ConsultarIntegracao",
	data(){
		return{
			dataMovimentacao: new Date().toLocaleDateString("en-CA"),
			dataEnviarMovimentacao: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1).toLocaleDateString("en-CA"),
			enviadas: true,

			pendentes: true,
			modalEnviarVendas: false,

			editandoMovimentacoes: false,

			form: {
				bandeira: "",
				produtoConciliadora: "",
				nsuCartao: "",
				docCartao: "",
				vlrParcelaAjustada: "",
				vlrParcelaSistema: "",
				vlrTitulo: "",
				detalhes: [],
			},
			valorParcelaAjustadaOriginal: "",
			showTable: true,
		};
	},

	components: {
		Modal,
		PaginacaoFiltro,
		TabelaConciliadora,
	},

	computed: {
		paginacao(){
			return this.$store.state.Conciliadora.vendasPendentes.paginacao;
		},
		listaprodutos(){
			return this.$store.state.Conciliadora.conteudo;
		},
		bandeiras() {
			return this.$store.state.Bandeiras.content;
		},
	},

	methods: {
		saiuData(){
			if(this.dataMovimentacao == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataMovimentacao = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		async filtrar(page){
			await this.$store.dispatch("filtrarMovimentacao", {page: page, data: this.dataMovimentacao});					
			this.$forceUpdate();
			return ;
		},
		limparFiltro(){
			this.dataMovimentacao = new Date().toLocaleDateString("en-CA");
			this.filtrar(null);
		},
		handleShowTable(){
			this.showTable = !this.showTable;
		},
		async enviarMovimentacoes() {
			this.modalEnviarVendas = false;
			await this.$store.dispatch("enviarMovimentacoesPendentes", {data: this.dataEnviarMovimentacao});
		},
		solicitarEnviarVendas() {
			this.modalEnviarVendas = true;
		},
		conciliarMovimentacoes() {
			console.log("falta implementar");
		},

		handleEditMovi(movi){
			this.startForm(movi);
			this.editandoMovimentacoes = true;
		},

		findBandeiraById(cdbandeira) {
			const bandeira = this.bandeiras.find(b => b.codigo == cdbandeira);
			return bandeira;
		},

		findProdutoById(codprodutoconciliadora) {
			const produto = this.listaprodutos.find(p => p.id == codprodutoconciliadora);
			return produto;
		},
		cancelEditMovimentacao(){
			this.editandoMovimentacoes = false;
			this.form = {};
		},
		startForm(movi) {
			this.form.bandeira = this.findBandeiraById(movi.cdbandeira).adm;
			this.form.produtoConciliadora = this.findProdutoById(movi.codprodutoconciliadora)?.nome;
			this.form.nsuCartao = movi.nsucartao;
			this.form.docCartao = movi.doccartao;
			this.form.vlrParcelaAjustada = movi.convertNumberToDecimal(movi.valorParcelaAjustada);
			this.valorParcelaAjustadaOriginal = this.form.vlrParcelaAjustada;
			this.form.vlrParcelaSistema = movi.convertNumberToDecimal(movi.valorParcelaSistema);
			this.form.vlrTitulo = movi.convertNumberToDecimal(movi.valorTitulo);
			this.form.detalhes = movi.detalhes;
		},

		async saveMovimentacao() {
			this.calcularMovimentacoes();
			await this.$store.dispatch("corrigirVendasConciliadora", {...this.form});
			this.editandoMovimentacoes = false;
			setTimeout(() => {
				this.filtrarMovimentacoes();
			}, 150);
		},

		calcularMovimentacoes() {
			const valorParcelaAjustada = Number(Number(`${this.form.vlrParcelaAjustada}`.replace(/\./g, "").replace(",", ".")));
			const vlrParcelaAjustadaOriginal = Number(Number(`${this.valorParcelaAjustadaOriginal}`.replace(/\./g, "").replace(",", ".")));
			const vlrParcelaSistema = Number(Number(`${this.form.vlrParcelaSistema}`.replace(/\./g, "").replace(",", ".")));
			const diferenca = Number((valorParcelaAjustada - vlrParcelaAjustadaOriginal).toFixed(2));

			const valorParcelaAjustadaCalculada = Number(((valorParcelaAjustada - vlrParcelaSistema) / this.form.detalhes.length).toFixed(2));

			if(diferenca == 0.01) {
				this.form.detalhes[0].valorparcelaajustado += diferenca;
			} else {
				this.form.detalhes.forEach(del => {
					del.valorparcelaajustado = Number((del.valorparcelasistema + valorParcelaAjustadaCalculada).toFixed(2));
				});
			}
		},

		async filtrarMovimentacoes(page){
			clearTimeout(this.timer);
			this.timer = setTimeout( async () => {
				let filtro = {
					data: new Date(),
					size: 25,
					page: page ? page : 0,
					enviadas: this.pendentes && !this.enviadas ? "N" : this.enviadas && !this.pendentes ? "S" : this.enviadas && this.pendentes ? "" : ""
				};
				await this.$store.dispatch("filtrarMovimentacao", {...filtro});
			}, 1000);
		},
	},
	mounted(){
		this.$store.dispatch("buscarBandeiras");
		this.$store.dispatch("buscarProdutosConciliadora");
		this.filtrar();
	}
};
</script>
<style scoped>
.containerPage{
	width: 100%;
	overflow: hidden;
}
.expanded-table{
	padding: 15px 0px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.containerForm{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	padding: 10px 5px;
	gap: 20px;
}

.rowForm {
	display: flex;
	gap: 10px;
	width: 100%;
}
.rowForm button{
	width: 100%;
}
.colForm{
	width: 300px;
}
.colForm .input-group{
	width: 100%;
}

.containerButtons{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 10px;
}
.smallCol{
	width: 200px;
}
.input-valor{
	text-align: right;
}
</style>
