<template>
    <div class="tela-consulta-vazia">
        <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt" @click="$emit('fecharModal')"></i>
        <h1>{{mensagem}}</h1>
        <h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
    </div>
</template>

<script>
export default {
	name: "AlertaConsultaVazia",

	props: {
		mensagem: String
	}
};
</script>