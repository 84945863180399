<template>
    <div id="CadastroTabelaGrade">
        <h1 class="titulo w-100 text-center my-5">Manutenção de Variação</h1>
        <div class="box-cadastro">
            <div class="row mb-5 h-30px w-100 center">
                <h3>Título</h3>
                <i v-if="!GradeAtualiza.novo && GradeAtualiza.codigo" @click="excluirGrades" class="fas fa-trash" style="color: var(--deletar)"></i>
            </div>
            <div class="input-group" style="margin-bottom: 5px;">
                <input maxlength="50" id="identificacao" type="text" v-model="gradeCadastrada.identificacao" style="text-transform: uppercase"
                autocomplete="off" @keydown.enter.prevent="proximoCampo('subGrupoInput-0')">
                <label for="identificacao">Descrição: </label>
            </div>
            <hr>
            <div class="row mb-5 h-30px w-100 center">
                <h3>Grade</h3>
                <i class="far fa-plus-square" v-if="gradeCadastrada.grades.length == 0"
					@click="adicionarGrade(0)"
					style="color: var(--confirmar)"
				></i>
                <span class="pl-5">{{gradeCadastrada.grades.length}}</span>
            </div>
            <div style="overflow: auto;">
                <div class="row mb-5 w-100 linha" v-for="(subGrupo, indice) in gradeCadastrada.grades" :key="indice">
                    <div class="input-group" style="margin-top: 5px;">
						<input maxlength="10" :id="`subGrupoInput-${indice}`" type="text" v-model="subGrupo.grade" style="text-transform: uppercase"
						@keydown.tab.prevent="adicionarGrade(indice)" @keydown.enter="adicionarGrade(indice)" autocomplete="off">
						<label :for="`subGrupoInput-${indice}`">Descrição da grade: </label>
                    </div>
					<i class="far fa-plus-square" @click="adicionarGrade(indice)" style="color: var(--confirmar); align-items: center;"
                    v-if="gradeCadastrada.grades.length -1 == indice"></i>
					<div class="icons">
						<i class="fas fa-sort-up icon" @click="ordenarGrade(indice, 'cima')" style="color: var(--btn)"></i>
						<i class="fas fa-sort-down" @click="ordenarGrade(indice, 'baixo')" style="color: var(--btn)"></i>
					</div>
                    <!-- <i class="far fa-minus-square" @click="removerGrade(indice)" style="color: var(--deletar)"></i> -->
                    <i class="fas fa-backspace" @click="removerGrade(indice)" style="color: var(--deletar)"></i>
                </div>
            </div>
        </div>
        <div id="botoes" class="botoes">
            <button @click="cancelarEdicao" class="botao botao-cancelar">
                <i class="fas fa-times" style="color: var(--texto-cancelar);"></i>
                Cancelar
            </button>
            <button @click="excluirGrades" class="botao botao-cancelar" v-if="!GradeAtualiza.novo && GradeAtualiza.codigo">
                <i class="fas fa-trash-alt" style="color: var(--texto-cancelar);"></i>
                Deletar
            </button>
            <button @click="validarPreSave" class="botao botao-adicionar">
                <i class="fa fa-check" style="color: var(--texto-adicionar);"></i>
                Salvar
            </button>
        </div>
    </div>
</template>
<script>
import  {Grade} from "../models/Grade.js";
export default {
	name: "CadastroGrade",

	props: {
		GradeAtualiza: Object,
	},

	data(){
		return {
			gradeCadastrada: {
				identificacao: "",
				grades: [{
					grade: "",
				}],
			},
		};
	},
	computed: {
		Grades() {
			return this.$store.state.Grades;
		},
	},

	methods: {
		proximoCampo(id) {
			setTimeout(() => {
				document.getElementById(id).focus();
				document.getElementById(id).select();
			},10);
		},
		ordenarGrade (index, direct){
			if (direct == "cima") {
				const anterior = this.gradeCadastrada.grades[index].grade;
				if(index == 0) return;
				this.gradeCadastrada.grades[index].grade = this.gradeCadastrada.grades[index - 1].grade;
				this.gradeCadastrada.grades[index - 1].grade = anterior;
			}
			if(direct == "baixo") {
				const anterior = this.gradeCadastrada.grades[index].grade;
				if(this.gradeCadastrada.grades.length == index + 1) return;
				this.gradeCadastrada.grades[index].grade = this.gradeCadastrada.grades[index + 1].grade;
				this.gradeCadastrada.grades[index + 1].grade = anterior;
			}
		},
		async salvarGrade() {
			try {
				this.gradeCadastrada.grades = this.gradeCadastrada.grades.map(e => e.grade);
				let gradeBanco = null;
				if (this.GradeAtualiza && this.GradeAtualiza.novo) {
					gradeBanco = await this.$store.dispatch("adicionarGrade", {...this.gradeCadastrada});
				} else {
					gradeBanco = await this.$store.dispatch("editarGrade", {...this.gradeCadastrada, codigo: this.GradeAtualiza.codigo});
				}
				if (gradeBanco) {
					this.informarCadastroGrade(gradeBanco);
					this.cancelarEdicao();
				}
				this.$emit("inserirGrade", gradeBanco);
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},

		informarCadastroGrade(adicionarGrade) {
			this.$emit("inserirGrade", new Grade({...adicionarGrade}));
		},

		async validarPreSave() {
			if (!this.gradeCadastrada.identificacao) {
				return this.$store.commit("relatarErro", {mensagem: "Campo descrição não pode estar vazio"});
			}

			this.gradeCadastrada.grades = this.gradeCadastrada.grades.filter(subGrupo => subGrupo.grade != "");

			return this.salvarGrade();
		},

		adicionarGrade(index) {
			let repetido = false;

			this.gradeCadastrada.grades.forEach((element, indice) => {
				if ((index != 0) && (element.grade == this.gradeCadastrada.grades[index].grade) && (index != indice)) {
					return repetido = true;
				}
			});

			if (repetido) {
				this.gradeCadastrada.grades[index].grade = "";
				return this.$store.commit("relatarErro", {mensagem: "Item da grade repetido!"});
			}

			if (!this.gradeCadastrada.grades[index].grade) {
				return;
			}

			if (this.gradeCadastrada.grades.length -1 == index) {
				this.gradeCadastrada.grades.push({ grade: "" });
			}

			this.proximoCampo(`subGrupoInput-${index + 1}`);
		},

		async excluirGrades() {
			try {
				this.$emit("excluirVariacao", this.GradeAtualiza.identificacao);
				let excluirGrades = await this.$store.dispatch("excluirGrades", this.GradeAtualiza.codigo);
				if (excluirGrades) {
					this.informarCadastroGrade({});
					this.cancelarEdicao();
				}
			} catch (error) {
				console.log(error);
			}
		},

		async excluirGrade(obj) {
			try {
				await this.$store.dispatch("excluirGrade", {
					...obj
				});
				this.informarCadastroGrade({...this.gradeCadastrada, ...obj.codigo});
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}
		},

		async removerGrade(index) {
			if (this.gradeCadastrada.grades.length != 1) {
				this.gradeCadastrada.grades.splice(index, 1);
			}
			if (this.GradeAtualiza.codigo && this.GradeAtualiza.grades[index].grade) {
				const obj = {
					grade:this.GradeAtualiza.grades[index].grade,
					codigo: this.GradeAtualiza.codigo
				};
				await this.excluirGrade({...obj});
			}
			this.proximoCampo(`subGrupoInput-${this.gradeCadastrada.grades.length -1}`);
		},

		cancelarEdicao() {
			this.$emit("fecharModal");
		},
	},
	mounted() {

		this.$store.state.carregando = true;
		setTimeout(() => {
			if (this.GradeAtualiza) {
				if (this.GradeAtualiza.novo) {
					this.gradeCadastrada = {
						codigo: null,
						identificacao: "",
						grades: [{
							grade: "",
						}],
					};
				}
				else if (this.GradeAtualiza.codigo){
					this.gradeCadastrada = {
						codigo: this.GradeAtualiza.codigo,
						identificacao: this.GradeAtualiza.identificacao,
						grades: [...this.GradeAtualiza.grades],
					};
				}
			}
			this.$store.state.carregando = false;
			if (this.gradeCadastrada.grades.length == 0 ) {
				this.gradeCadastrada.grades.push({ grade: "" });
			}
			this.proximoCampo("identificacao");
		},100);
	},
};
</script>

<style scoped>
.botoes{
	justify-content: space-between;
}
.botao{
	width: 45%;
}
label {
    margin-right: 5px;
    width: auto;
    white-space: nowrap;
    text-align: right;
}

input {
    width: 100%;
}

.icons{
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	position: relative;
	top: -5px;
}

.icons .icon{
	position: relative;
	top: 10px;
}
.linha{
	display: flex;
	flex-direction: row;
	align-items: center;
}

hr {
    color: black;
    background: black;
    width: 100%;
    height: 3px;
}

#CadastroTabelaGrade {
    display: grid;
    width: 95%;
    height: 100%;
    max-height: 92vh;
    margin: 0px auto;
    grid-template-columns: 1fr;
    grid-template-rows: 80px auto auto;
    grid-template-areas:
       "titulo"
       "cadastro"
       "botoes";
    overflow: hidden;
}

.box-cadastro {
    grid-area: cadastro;
    max-height: 100%;
    overflow: auto;
    padding: 0px 5px 5px 5px;
}

#botoes {
    grid-area: botoes;
    margin-bottom: 10px;
}
</style>
