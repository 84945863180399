import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
Vue.prototype.$bus = new Vue({});

import actions from "./actions.js";
import mutations from "./mutations.js";
import state from "./state.js";

const store = new Vuex.Store({
	state,
	actions,
	mutations,
});

store.dispatch("iniciarApp");

export default store;