<template>
    <div class="modal center">
        <div class="dummy-text p-25 m-20">
            <div class="logo">
                <img id="principalImg" 
				:src="$store.state.Empresa ? $store.state.Empresa.logo ? `data:image/png;base64,${$store.state.Empresa.logo}` : require('../assets/logo-transp-tidoc.png') : require('../assets/logo-transp-tidoc.png')">
            </div>
            <form id="login" @submit.prevent="(evt) => {evt.preventDefault();}" autocomplete="off">
                <div class="loginDiv" >
                    <label for="usuario">Usuário:</label><br/>
                    <input type="text" id="usuario" v-model="usuario" readonly tabindex="1"
                    placeholder="Digite seu usuário">
                </div>

                <div class="loginDiv" >
                    <label for="js-senha-antiga">Senha atual:</label><br/>
                    <input type="password" id="js-senha-antiga" placeholder="Digite sua senha atual"
                    v-model="antigaSenha" tabindex="2">
                </div>

                <div class="loginDiv" >
                    <label for="js-senha">Nova senha:</label><br/>
                    <input type="password" id="js-senha" placeholder="Digite sua nova senha" 
                    v-model="senha" tabindex="3">
                </div>
                <div class="loginDiv" >
                    <label for="senha2">Repita a nova senha:</label><br/>
                    <input type="password" id="senha2" placeholder="Repita sua nova senha"
                    v-model="senha2" tabindex="4">
                </div>
                <div class="botoes">
                    <button class="botao botao-adicionar" id="btnEntrar" @click="trocarSenha" type="submit" tabindex="6">
                        <span>Trocar senha</span>
                    </button>
                    <button class="botao botao-cancelar" id="btnCancelar" @click="cancelar" tabindex="5">
                        <span>Cancelar</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
	name: "TrocarSenha",

	data() {
		return {
			usuario: "",
			senha: "",
			senha2: "",
			antigaSenha: "",
		};
	},

	methods: {
		async trocarSenha() {
			if (!this.usuario || this.usuario == -1){
				return this.$store.commit("relatarErro", {mensagem: "Por favor informar seu usuário!"});
			}
			if (!this.senha) {
				return this.$store.commit("relatarErro", {mensagem: "Sua nova senha não pode ser nula!"});
			}
			if (!this.senha2) {
				return this.$store.commit("relatarErro", {mensagem: "Favor confirmar sua senha!"});
			}
			if (this.senha != this.senha2) {
				return this.$store.commit("relatarErro", {mensagem: "As senhas não são iguais, favor conferir!"});
			}
			const senhaAlterada = await this.$store.dispatch("trocarSenha", {
				login: this.usuario,
				novaSenha: this.senha,
				senha: this.antigaSenha,
			});
			if (!senhaAlterada) return;
			this.senha = "";
			this.senha2 = "";
			this.antigaSenha = "";
		},

		cancelar() {
			if (this.$route.query.necessarioTrocarSenha) {
				return this.$store.commit("relatarErro", {mensagem: "Em seu primeiro acesso, é necessário que você altere sua senha!"});
			}
			this.$router.go(-1);
		}
	},

	mounted() {
		if (this.$route.params.usuario) {
			this.usuario = this.$route.params.usuario.login;
			document.getElementById("js-senha-antiga").disabled = true;
			document.getElementById("js-senha").focus();
		} else {
			this.usuario = this.$store.state.Usuario.login;
			document.getElementById("js-senha-antiga").focus();
		}
	}
};
</script>

<style scoped >
.dummy-text{
	background-color: #f2f2f2;
}

.loginDiv select, input {
    width: 90%;
    margin: 5px;
    border-radius: 5px;
    padding: 5px;
	border: 2px solid darkgray;
}

.logo #principalImg {
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    max-width: 250px;
    max-height: 150px;
}

.dummy-text{
	width: 285px;
}
.botoes {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 5px;
}

.botoes .botao{
	width: 100%;
}

</style>