<template>
	<div id="gridPrincipal">
		<div class="tabela-atual" v-if="empresaSelecionada">
			<CadastroEmpresa :Empresa="empresaSelecionada" v-on:atualizarEmpresaSelecionada="selecionarEmpresa" v-on:cancelarEdicao="cancelarEmpresa"/>
		</div>
		<div class="tabela-atual" v-else>
			<ConsultaEmpresas
				:mostrarBotaoNovo="true"
				@adicionarNovo="selecionarEmpresa({novo: true})"
				@selecionarEmpresa="selecionarEmpresa"
				@solicitouAlterarEmpresa="alterarEmpresa" 
				@solicitouSairEmpresa="sairEmpresa" 
				@solicitouLoginEmpresa="loginEmpresa"
			/>
			<!-- <Paginacao tipoConteudo="Empresas" v-on:adicionarNovo="selecionarEmpresa({novo: true})">
				<ListaEmpresas v-on:selecionarEmpresa="selecionarEmpresa" v-on:solicitouAlterarEmpresa="alterarEmpresa" @solicitouSairEmpresa="sairEmpresa" @solicitouLoginEmpresa="loginEmpresa"/>
			</Paginacao> -->
		</div>
	</div>
</template>
<script>
import CadastroEmpresa from "../components/CadastroEmpresa.vue";
// import ListaEmpresas from "../components/ListaEmpresas.vue";
// import Paginacao from "../components/Paginacao.vue";
import { PaginacaoConfig, PaginacaoOption } from "../models/Paginacao";
import ConsultaEmpresas from "../components/ConsultaEmpresas.vue";
export default {
	name: "Empresas",
	components: {
		CadastroEmpresa,
		// ListaEmpresas,
		// Paginacao,
		ConsultaEmpresas,
	},
	data() {
		return {
			empresaSelecionada: null,
			configPaginacao: new PaginacaoConfig(
				[
					new PaginacaoOption(
						"nomeEmpresa",
						this.tipoConteudoPaginacao,
						"Empresa"
					),
				],
				false
			),
		};
	},
	methods: {
		selecionarEmpresa(Empresa) {
			this.empresaSelecionada = { ...Empresa };
		},
		cancelarEmpresa() {
			this.empresaSelecionada = null;
		},
		async alterarEmpresa(empresa, index) {
			try {
				const emp = await this.$store.dispatch("buscarEmpresaId", {id: empresa.id});
				if(!emp) throw new Error("Empresa não encontrada, por favor verifique");
				this.empresaSelecionada = { ...emp, index, novo: false };
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
				});
			}
		},
		sairEmpresa() {
			this.$router.push({ name: "SelecionaEmpresa" });
		},
		loginEmpresa(empresa) {
			this.$router.push({
				name: "SelecionaEmpresa",
				query: { empresaId: empresa.id },
			});
		},
		registrarPaginacao() {
			this.$store.commit("registrarPaginacao", this.configPaginacao);
		},
		removerPaginacao() {
			this.$store.commit("removerPaginacao");
		},
	},
	beforeMount() {
		this.registrarPaginacao();
	},
	beforeDestroy() {
		this.removerPaginacao();
	},
};
</script>
<style scoped>
#gridPrincipal {
	margin: 0px;
	display: grid;
	width: 100%;
	grid-template-rows: 90vh;
}
.tabela-atual {
	display: flex;
	flex-grow: 1;
	height: 100%;
	max-height: 92vh;
	width: 100%;
	max-width: 100vw;
	margin: 0px;
	overflow: auto;
    overflow-x: hidden;
}
</style>
