import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";
import "./registerServiceWorker";
import "./plugins/Mascara.js";

import formatarParaMoeda from "./filters/formatarParaMoeda";




Vue.config.productionTip = false;

Vue.filter("formatarParaReais", formatarParaMoeda.formatarParaReais);

new Vue({
	render: h => h(App),
	router,
	store
}).$mount("#app");
