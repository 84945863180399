import { doRequest } from "./http";

class Perfil {
	constructor(perfil) {
		this.id = perfil.id;
		this.descricao = `${perfil.descricao}`.toUpperCase();
		this.ativo = perfil.ativo;
		this.maxDiasTroca = perfil.maxDiasTroca;
		this.maxNumeroParcelas = perfil.maxNumeroParcelas;
		this.maxPorcentagemDesconto = perfil.maxPorcentagemDesconto;
		this.minValorParcela = perfil.minValorParcela;
		if (perfil.parametros) {
			this.parametros = {};
			Object.keys(perfil.parametros).forEach(key => {
				this.parametros[key] = perfil.parametros[key];
			});
		}
	}
}

class PerfilAPI {
	constructor(perfil) {
		this.descricao = `${perfil.descricao}`.toUpperCase();
		this.ativo = perfil.ativo;
		this.maxDiasTroca = Number(`${perfil.maxDiasTroca}`.replace(".", "").replace(",", "."));
		this.maxNumeroParcelas = Number(`${perfil.maxNumeroParcelas}`.replace(".", "").replace(",", "."));
		this.maxPorcentagemDesconto = Number(`${perfil.maxPorcentagemDesconto}`.replace(".", "").replace(",", "."));
		this.minValorParcela = Number(`${perfil.minValorParcela}`.replace(".", "").replace(",", "."));
		if (perfil.parametros) {
			Object.keys(perfil.parametros).forEach(key => {
				this[key] = perfil.parametros[key] ? "S" : "N";
			});
		}
	}
}

export default {
	state: {
		ListaPerfis: {
			content: [],
			size: 25,
			paginaAtual: 0,
			totalPaginas: 0
		},
	},

	actions: {
		async cadastrarPerfil({state, commit}, payload) {
			try {
				state.carregando = true;
				const url = "perfil";
				const body = JSON.stringify(new PerfilAPI(payload));
				const data = await doRequest(url, { method: "post", body, cache: "no-cache" }, commit);
				state.carregando = false;
				if (!data) return null;
				if (!data.id) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				state.ListaPerfis.content.push(new Perfil(data));
				return new Perfil(data);
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.mensagem || (error.details ? JSON.stringify(error.details) : JSON.stringify(error)))});
				return null;
			}
		},

		async atualizarPerfil({state, commit}, payload) {
			try {
				state.carregando = true;
				const url = `perfil?id=${payload.id}`;
				const body = JSON.stringify(new PerfilAPI(payload));
				const data = await doRequest(url, { method: "put", body, cache: "no-cache" }, commit);
				state.carregando = false;
				if (!data) return null;
				if (!data.id) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				const index = state.ListaPerfis.content.map(p => p.id).indexOf(payload.id);
				if (index >= 0) {
					state.ListaPerfis.content.splice(index, 1);
				}
				state.ListaPerfis.content.push(new Perfil(data));
				return new Perfil(data);
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.mensagem || (error.details ? JSON.stringify(error.details) : JSON.stringify(error)))});
				return null;
			}
		},

		async buscarPerfis({state, commit}, payload) {
			try {
				state.carregando = true;
				let url = "perfil/all?sortOrder=asc&sort=descricao";
				if (payload) {
					const {page, size} = payload;
					url += `&page=${page || 0}`;
					url += `&size=${size || state.ListaPerfis.size}`;
				} else {
					url += `&size=${state.ListaPerfis.size}`;
					url += "&page=0";
				}
				const data = await doRequest(url, { method: "get", cache: "no-cache" }, commit);
				state.carregando = false;
				if (!data) {
					commit("atualizarListaPerfis", null);
					return null;
				}
				if (!data.content) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				commit("atualizarListaPerfis", data);
			} catch (error) {
				state.carregando = false;
				commit("atualizarListaPerfis", null);
				return null;
			}
		},

		async filtrarPerfis({state, commit}, payload) {
			try {
				state.carregando = true;
				let url = "perfil/filter?sortOrder=asc&sort=descricao";
				const {page, size, query, filtroDinamico} = payload;
				url += `&page=${page || 0}`;
				url += `&size=${size || state.ListaUsuarios.size}`;
				if (query) {
					url += `&query=${query}`;
				}
				if (Object.keys(filtroDinamico).length > 0) {
					Object.keys(filtroDinamico).forEach(key => {
						url += `&${key}=${filtroDinamico[key]}`;
					});
				}
				const data = await doRequest(url, { method: "get", cache: "no-cache" }, commit);
				state.carregando = false;
				if (!data) {
					commit("atualizarListaPerfis", null);
					return null;
				}
				if (!data.content) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				commit("atualizarListaPerfis", data);
			} catch (error) {
				state.carregando = false;
				commit("atualizarListaPerfis", null);
				return null;
			}
		},

		async deletarPerfil({state, commit}, payload) {
			try {
				state.carregando = true;
				let url = `perfil?id=${payload.id}`;
				const data = await doRequest(url, { method: "delete", cache: "no-cache" }, commit);
				state.carregando = false;
				if (!data) return;
				if (data.mensagem != "Perfil excluído com sucesso!") {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				state.ListaPerfis.content.splice(state.ListaPerfis.content.map(p => `${p.id}`).indexOf(`${payload.id}`), 1);
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.mensagem || (error.details ? JSON.stringify(error.details) : JSON.stringify(error)))});
			}
		}
	},

	mutations: {
		atualizarListaPerfis(state, payload) {
			state.ListaPerfis = {
				content: [],
				size: 25,
				paginaAtual: 0,
				totalPaginas: 0
			};
			if (!payload) return;
			state.ListaPerfis = {
				content: payload.content.map(perfil => new Perfil(perfil)),
				size: payload.size,
				paginaAtual: payload.page,
				totalPaginas: payload.totalPages
			};
		},

		limparListaPerfis(state) {
			state.ListaPerfis = {
				content: [],
				size: 25,
				paginaAtual: 0,
				totalPaginas: 0
			};
		}
	}
};