import { doRequest } from "./http";
import { Movimentacao } from "../models/Conciliadora";
export default {
	state: {
		Conciliadora: {
			conteudo: [],
			paginacao: {
				last: false,
				page: 0,
				size: 25,
				totalElements: 0,
				totalPages: 0
			},
			vendasPendentes: {
				vendas: [],
				paginacao: {
					last: false,
					page: 0,
					size: 25,
					totalElements: 0,
					totalPages: 0
				}
			}
		}
	},
	actions: {

		async corrigirVendasConciliadora({state, commit}, payload) {
			let url = "conciliadora/corrigir-venda";
			const form = new Movimentacao(payload).convertDetails(payload.detalhes);
			const body = JSON.stringify(form);

			state.carregando = true;
			try {
				const data = await doRequest(url, {
					method: "PUT",
					body,
					cache: "no-cache"
				}, commit);
				state.carregando = false;
				if(data && !data.erro) {
					return commit("relatarErro", {
						mensagem: data.mensagem,
						sucess: true
					});
				}
				state.carregando = false;
			} catch (error) {
				console.log(error);
				commit("relatarErro", {
					mensagem: error.message,
				});
				return null;
			}
			
		},

		
		
		async buscarProdutosConciliadora({state, commit}, payload){
			try {
				if(!state.Empresa.conciliadora.apiKeyConciliadora) {
					return [];
				}
				state.carregando = true;
				const url = `conciliadora/produtos/filter?${payload?.page ? `page=${payload.page}` : "page=0"}${payload?.size ? `&size=${payload.size}` : "&size=9999"}&sort=nome${payload?.query ? `&query=${payload.query}` : ""}${payload?.cdtipo ? `&cdtipo=${payload.cdtipo}` : ""}`;
				
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if(data.erro){
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				if(data){
					commit("atualizarStateConciliadora", {...data});
					return data.content;
				}
			} catch (error) {
				// commit("relatarErro", {mensagem: error.message}); caso haja algum erro tratado alem do nenhum produto encontrado reavaliar esse comentario
				commit("atualizarStateConciliadora", []);
				return [];
			}

		},
		async vincularCdtipoProdutos({state, commit}, payload){
			try {
				state.carregando = true;
				const form = payload.produtosSelecionados;
				const url = `conciliadora/produtos?cdtipo=${payload.cdtipo}`;
				const body = JSON.stringify(form);

				const data = await doRequest(url, {
					method: "PUT",
					body: body,
					cache: "no-cache"
				}, commit);
				if(data.erro){
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				state.carregando = false;
				return data;

			} catch (error) {
				commit("relatarErro", {mensagem: error.message});
			}

		},
		async desvincularProdutoConciliadora({state, commit, dispatch}, payload){
			try {
				state.carregando = true;
				const url = `conciliadora/produtos?cdtipo=${payload.cdtipo}&codigo=${payload.codigo}`;
				const req = await doRequest(url, {method: "DELETE", }, commit);
				state.carregando = false;
				if(req){
					dispatch("buscarProdutosConciliadora", {cdtipo: payload.cdtipo});
				}
				return req;
			} catch (error) {
				console.log(error);
			}
		},
		async filtrarMovimentacao({state, commit}, payload){
			try {
				state.carregando = true;

				let url =  `conciliadora/vendas-pendente?cdempresa=${state.Empresa.id}${payload.page ? `&page=${payload.page}` : ""}${payload.enviadas ? `&enviada=${payload.enviadas}` : ""}&sort=datadoc`;
				
				url += `${payload?.data ? `&dataI=${payload.data}` : ""}${payload?.data ? `&dataF=${payload?.data}` : ""}`;
				
				const data = await doRequest(url, {
					method: "get",
					cache: "no-cache"
				}, commit);
				if(data && data.erro){
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				commit("atualizarVendasPendentes", data);
				state.carregando = false;
			} catch (error) {
				console.log(error);
				state.carregando = false;
				commit("atualizarVendasPendentes", []);
				return;
			}
		},
		async enviarMovimentacoesPendentes({state, commit}, payload){
			try {
				state.carregando = true;
				const url = `
					conciliadora/enviar-vendas?cdempresa=${state.Empresa.id}
					${payload.data ? `&dataI=${payload.data}` : ""}
					${payload.data ? `&dataF=${payload.data}` : ""}
				`;
				const data = await doRequest(url, {
					method: "PUT",
					cache: "no-cache",
				}, commit);
				state.carregando = false;
				if(data && data.codigo == -1 || data.mensagem){
					commit("relatarErro", {mensagem: data.mensagem});
				}
				if(data && data.mensagem && data.codigo == 0){
					throw new Error(data.mensagem);
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message, sucess: true});
			}
		},
		async integrarAdquirentesConciliadora({state, commit}){
			try {
				state.carregando = true;
				const url = `conciliadora/adquirentes?cdempresa=${state.Empresa.id}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"});
				state.carregando = false;
				if(data && data.erro){
					throw new Error(data.mensagem);
				}
				commit("atualizarListaBandeiras", data);
			} catch (error) {
				commit("relatarErro", {mensagem: error.message});
			}
		},
		async integrarProdutosConciliadora({state, commit}){
			try {
				state.carregando = true;
				const url = `conciliadora/produtos?cdempresa=${state.Empresa.id}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"});
				state.carregando = false;
				if(data && data.erro){
					throw new Error(data.mensagem);
				}
				commit("atualizarStateConciliadora", data);
			} catch (error) {
				commit("relatarErro", {mensagem: error.message});
			}

		}
	},
	mutations: {
		atualizarStateConciliadora(state, payload){
			state.Conciliadora.conteudo = payload.content?.map(produto => produto);
			state.Conciliadora.paginacao = {
				last: payload.last || false,
				page: payload.page || 0,
				size: payload.size || 25,
				totalElements: payload.totalElements || 0,
				totalPages: payload.totalPages || 0
			};
		},
		atualizarVendasPendentes(state, payload){
			state.Conciliadora.vendasPendentes.vendas = payload ? payload?.map(movi => new Movimentacao(movi)) : [];
			state.Conciliadora.vendasPendentes.paginacao = {
				last: payload.last || false,
				page: payload.page || 0,
				size: payload.size || 25,
				totalElements: payload.totalElements || 0,
				totalPages: payload.totalPages || 0
			};
		}
	}
};


