import {PaginacaoConfig} from "../models/Paginacao";

const Paginacao = new PaginacaoConfig();
const state = {
	Paginacao   
};
const actions = {};
const mutations = {
	registrarPaginacao(state, payload) {
		state.Paginacao = payload;
	},
	removerPaginacao(state) {
		state.Paginacao.options = [];
	}
};
export default {
	state,
	actions,
	mutations
};