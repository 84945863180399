<template>
	<div class="col center flex-grow">
		<div class="formaPagamento w-100">
			<input type="radio" id="aPrazo" name="formaPagamento" @click="solicitarRecalcularValores('prazo')">
			<input
				type="text"
				id="valorPrazo"
				readonly
				class="inputControle pointer w-100"
				@click="solicitarRecalcularValores('prazo')"
				:value="`Valor a prazo: ${totalPrazo.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`"
				:style="totalTroca > 0 ? totalPrazo < 0 ? 'color: var(--deletar)' : 'color: var(--confirmar)' : ''"
			>
		</div>
		<div class="formaPagamento w-100">
			<input type="radio" id="aVista" name="formaPagamento" @click="solicitarRecalcularValores('vista')">
			<input 
				type="text"
				id="valorVista"
				readonly
				class="inputControle pointer w-100"
				@click="solicitarRecalcularValores('vista')"
				:value="`Valor a vista: ${totalVista.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`"
				:style="totalTroca > 0 ? totalVista < 0 ? 'color: var(--deletar)' : 'color: var(--confirmar)' : ''"
			>
		</div>
	</div>
</template>

<script>
export default {
	name: "SeletorVistaPrazo",

	props: {
		itens: Array,
		valorReceber: Number
	},

	data() {
		return {
			promocaoFormaPagamentoAplicavel: false,
			percDescontoPgto: 0,
		};
	},

	computed: {
		totalTroca(){
			let preco = 0;
			this.itens.forEach(item => {
				if (item.cdTipo == "T") {
					preco += Number(item.valorUnitario * item.quantidade);
				}
			});
			return preco;
		},


		totalVista() {
			let preco = 0;
			/*
				COMO DEVE SE COMPORTAR O TOTAL A PRAZO DE ACORDO COM AS NOVAS ALTERAÇÕES
				- LANCAMENTO DE PRODUTO SEM ALTERAÇÃO NO VALOR UNITÁRIO E SEM PROMOÇÃO NO PRODUTO
					- DEVE CALCULAR O TOTAL A PARTIR DO VALOR DE ACORDO COM CADASTRO DO PRODUTO (item.produto.valorVista)
					- NA ALTERAÇÃO DEVE CARREGAR O TOTAL A PRAZO DE ACORDO COM CADASTRO DO PRODUTO (item.produto.valorVista)
				- LANCAMENTO DE PRODUTO COM ALTERAÇÃO NO VALOR UNITÁRIO E SEM PROMOÇÃO NO PRODUTO
					- DEVE CALCULAR O TOTAL A PARTIR DO VALOR UNITÁRIO ALTERADO, TANTO NA INCLUSÃO QUANTO NA ALTERAÇÃO (tem.valorUnitario)
				- LANCAMENTO DE PRODUTO SEM ALTERAÇÃO NO VALOR UNITÁRIO E COM PROMOÇÃO NO PRODUTO
					- DEVE SER CALCULADO O TOTAL A PARTIR DO VALOR DE PROMOÇÃO DO PRODUTO (item.produto.valorPromocaoVista)
				- LANCAMENTO DE PRODUTO COM ALTERAÇÃO NO VALOR UNITÁRIO E COM PROMOÇÃO NO PRODUTO		
					- DEVE CALCULAR O TOTAL A PARTIR DO VALOR UNITÁRIO ALTERADO, TANTO NA INCLUSÃO QUANTO NA ALTERAÇÃO (tem.valorUnitario)
			*/
			const temTroca = this.$route.query.trocarProdutos;
			const lancaCusto = this.$store.state.LancarCusto;
			const {alteracao} = this.$route.query;
			this.itens.forEach(
				item => {
					const vlrUnitario = item.valorUnitario;
					const vlrPromocao = item.produto.valorPromocaoVista;
					const promocaoAplicavel = item.cdpromocao && vlrPromocao && !temTroca;
					const valorNormal = item.produto.valorVista;

					const alterouVlrUnitario = !promocaoAplicavel
						? (vlrUnitario !== item.produto.valorPrazo) && vlrUnitario !== valorNormal
						: vlrUnitario !== item.produto.valorPromocaoPrazo && vlrUnitario !== vlrPromocao;
					if(alteracao) {
						if(promocaoAplicavel && alterouVlrUnitario) {
							preco += item.valorUnitario * item.quantidade;
						} else if(promocaoAplicavel && !alterouVlrUnitario) {
							preco += vlrPromocao * item.quantidade;
						} else if(!alterouVlrUnitario && !promocaoAplicavel) {
							preco += valorNormal * item.quantidade;
						} else {
							preco += vlrUnitario * item.quantidade;
						}
					} else {
						if (!lancaCusto) { // VERIFICAR COMO VAI FICAR QUANDO LANCAR NO CUSTO
							if(item.cdTipo !== "D" && item.cdTipo !== "T") {
								if(promocaoAplicavel) {
									preco += vlrPromocao * item.quantidade;
								} else if(promocaoAplicavel && alterouVlrUnitario) {
									preco += vlrUnitario * item.quantidade;
								} else if(!promocaoAplicavel && alterouVlrUnitario) {
									preco += vlrUnitario * item.quantidade;
								} else {
									preco += valorNormal * item.quantidade;
								}
							} else if(item.cdTipo === "T") {
								preco -= Number(item.valorUnitario * item.quantidade);
							}
						}
					}
				}
			);
			if(this.promocaoFormaPagamentoAplicavel) {
				const vlrDesconto = preco * (this.percDescontoPgto / 100);
				const total = preco - vlrDesconto;
				return total;
			}
			return preco;
		},

		totalPrazo() {
			let preco = 0;
			/*
				COMO DEVE SE COMPORTAR O TOTAL A PRAZO DE ACORDO COM AS NOVAS ALTERAÇÕES
				- LANCAMENTO DE PRODUTO SEM ALTERAÇÃO NO VALOR UNITÁRIO E SEM PROMOÇÃO NO PRODUTO
					- DEVE CALCULAR O TOTAL A PARTIR DO VALOR DE ACORDO COM CADASTRO DO PRODUTO (item.produto.valorPrazo)
					- NA ALTERAÇÃO DEVE CARREGAR O TOTAL A PRAZO DE ACORDO COM CADASTRO DO PRODUTO (item.produto.valorPrazo)
				- LANCAMENTO DE PRODUTO COM ALTERAÇÃO NO VALOR UNITÁRIO E SEM PROMOÇÃO NO PRODUTO
					- DEVE CALCULAR O TOTAL A PARTIR DO VALOR UNITÁRIO ALTERADO, TANTO NA INCLUSÃO QUANTO NA ALTERAÇÃO (tem.valorUnitario)
				- LANCAMENTO DE PRODUTO SEM ALTERAÇÃO NO VALOR UNITÁRIO E COM PROMOÇÃO NO PRODUTO
					- DEVE SER CALCULADO O TOTAL A PARTIR DO VALOR DE PROMOÇÃO DO PRODUTO (item.produto.valorPromocaoPrazo)
				- LANCAMENTO DE PRODUTO COM ALTERAÇÃO NO VALOR UNITÁRIO E COM PROMOÇÃO NO PRODUTO		
					- DEVE CALCULAR O TOTAL A PARTIR DO VALOR UNITÁRIO ALTERADO, TANTO NA INCLUSÃO QUANTO NA ALTERAÇÃO (tem.valorUnitario)
			*/

			const temTroca = this.$route.query.trocarProdutos;
			const lancaCusto = this.$store.state.LancarCusto;
			const { alteracao } = this.$route.query;
			this.itens.forEach(
				item => {
					const vlrPromocao = item.produto.valorPromocaoPrazo;
					const promocaoAplicavel = item.cdpromocao && vlrPromocao && !temTroca;
					const vlrUnitario = item.valorUnitario;
					const valorNormal = item.produto.valorPrazo;
					const alterouVlrUnitario = !promocaoAplicavel 
						? (vlrUnitario !== item.produto.valorVista) && vlrUnitario !== valorNormal
						: vlrUnitario !== item.produto.valorPromocaoVista && vlrUnitario !== vlrPromocao;
					if(alteracao) {
						if(promocaoAplicavel && alterouVlrUnitario) {
							preco += item.valorUnitario * item.quantidade;
						} else if(promocaoAplicavel && !alterouVlrUnitario) {
							preco += vlrPromocao * item.quantidade;
						} else if(!alterouVlrUnitario && !promocaoAplicavel) {
							preco += valorNormal * item.quantidade;
						} else {
							preco += vlrUnitario * item.quantidade;
						}
					} else {
						if (!lancaCusto) {
							if(item.cdTipo !== "D" && item.cdTipo !== "T") {
								if(promocaoAplicavel) {
									preco += vlrPromocao * item.quantidade;
								} else if(promocaoAplicavel && alterouVlrUnitario) {
									preco += vlrUnitario * item.quantidade;
								} else if(!promocaoAplicavel && alterouVlrUnitario) {
									preco += vlrUnitario * item.quantidade;
								} else {
									preco += valorNormal * item.quantidade;
								}
							} else if(item.cdTipo === "T") {
								preco -= Number(item.valorUnitario * item.quantidade);
							}
						}
					}
				}
			);
			if(this.promocaoFormaPagamentoAplicavel) {
				const vlrDesconto = preco * (this.percDescontoPgto / 100);
				const total = preco - vlrDesconto;
				return total;
			}
			return preco;
		},

		totalGeral(){
			let preco = 0;
			this.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D") {
					preco += Number(item.valorUnitario * item.quantidade);
				} else if (item.cdTipo == "T") {
					preco -= Number(item.valorUnitario * item.quantidade);
				}
			});
			return preco;
		},
	},

	methods: {
		cancelouPagamento() {
			this.promocaoFormaPagamentoAplicavel = false;
			this.percDescontoPgto = 0;
		},
		/*
			RECALCULAR VALOR NORMAL QUANDO MUDAR AS OPÇÕES DO SELETOR VISTA E PRAZO
			VERIFICAR COMO VAI FICAR O VALOR PAGO,
			E COMO VAI FICAR A APLICAÇÃO DO DESCONTO SE VAI APLICAR EM CIMA DO VALOR TOTAL OU DO VALOR INSERIDO PELO USUARIO
		*/
		descontoFormaPagamento({descontoAplicado, percDesconto}) {
			this.promocaoFormaPagamentoAplicavel = descontoAplicado;
			this.percDescontoPgto = percDesconto;
		},
		solicitarRecalcularValores(tipo) {			
			this.$store.state.Venda.financeiro = [];
			this.$emit("recalcularRecebidos");
			// const bloqueiavendaprazo = this.$store.state.Venda.cliente.classificacao.bloqueiavendaprazo;
			const clientePadrao = this.$store.state.ClientePadrao.id;
			const clienteVenda = this.$store.state.Venda.cliente.id;
			try {
				// if (tipo == "vista" && this.totalVista < 0) {
				// 	throw new Error(`O valor a ${tipo} está negativo, o cliente tem ${Number(this.totalVista *(-1)).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})} para levar em produtos!`);
				// } else if (tipo == "prazo" && this.totalPrazo < 0) {
				// 	throw new Error(`O valor a ${tipo} está negativo, o cliente tem ${Number(this.totalPrazo *(-1)).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})} para levar em produtos!`);
				// }
				if(tipo === "prazo" && this.$route.query.tipoVenda != "C"){
					if(clientePadrao != clienteVenda){
						// this.$emit("recalcular", "vista");
						// if(bloqueiavendaprazo){
						// 	throw new Error("Não é possivel vender a prazo para a classificação do cliente.");
						// }
					}
				}
				if(!this.$store.state.LancarCusto){
					this.$emit("recalcular", tipo);
				}
				this.descontoFormaPagamento(false, 0);
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
				setTimeout(() => {
					document.getElementById(tipo == "vista" ? "aPrazo" : "aVista").checked = true;
				}, 20);
			}
		},

		ehClientePadrao() {
			return this.$store.state.ClientePadrao.id === this.$store.state.Venda.cliente.id;
		}
	},

	created() {
		this.$bus.$on("descontoFormaPagamento", this.descontoFormaPagamento);
	},
	
	mounted() {
		this.$bus.$on("cancelouPagamento/Desconto", this.cancelouPagamento);
		this.$bus.$on("recalcularValores", this.solicitarRecalcularValores);
		const { alteracao } = this.$route.query;

		/*
			* todo: REALIZAR TESTE DESSA CONDICAO ABAIXIO, PARA VENDA SEM CLIENTE PADRAO, E COM CLIENTE QUE BLOQUEIA VENDA PRAZO
			* TESTAR TAMBEM A ALTERAÇÃO DE VENDA PARA CLIENTE NÃO PADRAO (CONSUMIDOR FINAL)
		*/
		if(
			(!this.ehClientePadrao())
			&& (!this.$store.state.Venda.valorVista && this.$store.state.Venda.cliente.classificacao.bloqueiavendaprazo)
			&& !alteracao
		){
			this.$store.state.Venda.valorVista = true;
			if(!this.$store.state.LancarCusto){
				this.$emit("recalcular", "vista");
			}
		}
		if (this.$store.state.Venda.valorVista) {
			setTimeout(() => {document.getElementById("aVista").click();}, 50);
		} else {
			setTimeout(() => {document.getElementById("aPrazo").click();}, 10);
		}
	},
	destroyed(){
		this.$bus.$off("cancelouPagamento/Desconto", this.cancelouPagamento);
		this.$bus.$off("descontoFormaPagamento", this.descontoFormaPagamento);
		this.$bus.$off("recalcularValores", this.solicitarRecalcularValores);
	},
};
</script>

<style scoped>
.formaPagamento {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 5px;
}

.inputControle {
	padding-left: 5px;
	flex-grow: 1;
	font-size: var(--txt-medio);
	text-align: right;
	margin-left: 5px;
}


@media screen and (max-width: 380px) {
	.formaPagamento, * {
		font-size: var(--txt-pequeno) !important;
	}
}

</style>