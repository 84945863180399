<template>
  <div class="containerPage">
	<div id="listaModal" :class="`${!showTable? 'table-full' : ''}`">
		<div class="w-full containerTable" id="table" v-if="Parcelas.length > 0">
			<table>
				<thead>
					<tr>
						<th :style="{color: 'var(--acao-background)'}">
							Cód. Pedido
						</th>
						<th>
							Fornecedor
						</th>
						<th>
							Qtd. Produtos
						</th>
						<th>
							Valor
						</th>
						<th>
							Valor da Parcela
						</th>
						<th>
							Data Vencimento
						</th>
						<th>
							Parcela
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(parcela, index) in Parcelas"
						:key="index"
					>
						<td :style="{color: 'var(--acao-background)'}">
							{{ parcela.id }}
						</td>
						<td style="text-align: left;"> 
							{{ parcela.cdcliente }} - {{ parcela.nome }}
						</td>
						<td>
							{{ parcela.quantidade }}
						</td>
						<td style="text-align: right;">
							{{ (parcela.valor || 0) | formatarParaReais}}
						</td>
						<td style="text-align: right;">
							{{ (parcela.valorparcela || 0) | formatarParaReais}}
						</td>
						<td>
							{{ parcela.datavencimento.split("-").reverse().join("/") }}
						</td>
						<td>
							{{ parcela.parcelas }}
						</td>
					</tr>
					<tr>
						<td colspan="2">Total:</td>
						<td>{{ Totalizador.quantidade }}</td>
						<td style="text-align: right;">{{ Totalizador.valor | formatarParaReais }}</td>
						<td style="text-align: right;">{{ Totalizador.valorParcela | formatarParaReais }}</td>
						<td colspan="2"></td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="col center containerExclamation" v-else>
			<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
			<h1>Não há previsões de conta.</h1>
			<h2>
				Tente filtrar e/ou aumentar ou diminuir as parametrizações
			</h2>
		</div>
	</div>	
  </div>
</template>
<script>
import formatarParaReais from "../filters/formatarParaMoeda";

export default {
	name: "TabelaPrevisaoCompraVencimento",
	props: {
		showTable: Boolean,
		Parcelas: Array,
	},
	filters: {
		formatarParaReais
	},
	computed: {
		Totalizador(){
			let totais = {
				quantidade: 0,
				valor: 0,
				valorParcela: 0,
			};
			this.Parcelas.forEach((parcela)=>{
				totais.quantidade += parcela.quantidade;
				totais.valor += parcela.valor;
				totais.valorParcela += parcela.valorparcela;
			});
			return totais;
		},
	},

};
</script>

<style scoped>
.containerPage{
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.containerFiltro{
	width: 100%;
	height: 30vh;
	display: flex;
	flex-direction: row;
}

.containerColuna{
	width: 40%;
	height: 25vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 15px;
}

.grupoData .input-group{
	width: 230px;
}

.input-group{
	width: 350px;
}

.botao{
	width: 100%;
}

table tbody tr td{
	text-align: center;
}

.paginacao{
	margin-top: 5px;
}

.containerTable{
	padding: 10px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	
}

.sm-view {
	display: none;
}

.lg-view {
	display: inline-block;
}
#listaModal {
	overflow: auto;
    margin: 0 auto;
    font-size: var(--txt-normal);
	height: 60vh !important;
	width: 100%;
}
#listaModal.table-full{
	height: 77vh !important;
}
@media screen and (max-width: 1080px) {
	.row {
		display: flex;
		flex-direction: row;
	}

	.containerColuna {
		height: auto;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.smallerGap .input-group {
		width: 20vw;
	}

	.grupoData .row .input-group {
		width: 48%;
	}

	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}
}

@media screen and (max-width: 768px) {
	.row {
		display: flex;
		flex-direction: row;
	}
	
	.containerFiltro {
		height: auto !important;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		width: 100%;
	}

	.containerColuna {
		height: auto;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.grupoData .row {
		width: 100%;
		gap: 5px;
	}

	.grupoData .row .input-group {
		width: 48%;
	}
	.row .input-group {
		width: 100%; 
		margin: 0;
	}

	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	::-webkit-calendar-picker-indicator {
		display: none;
	}

	.prodEmprNome .input-group {
		width: 270px;
		margin-top: 10px;
		margin-right: 6px;
	}

	.botao-acao {
		margin-top: 5px;
	}

	.smallerGap {
		gap: 0px;
	}
	#listaModal.table-full{
		height: 68vh !important;
	}
	#listaModal{
		height: 43vh !important;
	}
}

@media screen and (max-width: 425px){
	#listaModal.table-full{
		height: 71vh !important;
	}
	#listaModal{
		height: 30vh !important;
	}
}
</style>