<template>
	<div class="container-page-relatorio">
		<i 
			class="fas fa-print"
			style="color: var(--primario)"
			id="impressao"
			@click="gerarImpressaoRelatorio"
			v-if="Produtos.length > 0"
		/>
		<Filtro
			@handleShowTable="handleShowTable"
			@filtrar="filtrar"
			@limpar="limpar"
		>
			<div class="filtro-content">
				<div class="column-filtro">
					<div class="row-filtro">
						<div class="input-group">
							<input 
								type="date" 
								v-model="Filtro.datai"
								placeholder=" "
								id="datai"
								@blur="saiuData"
							/>
							<label for="datai">
								Data Inicial
							</label>
						</div>
						<div class="input-group">
							<input 
								type="date" 
								v-model="Filtro.dataf"
								placeholder=" "
								id="dataf"
								@blur="saiuData"
							/>
							<label for="dataf">
								Data Inicial
							</label>
						</div>
						<div class="input-group">
							<select 
								v-model="Filtro.empresa" 
								id="empresa"
							>
								<option selected value="ALL"> 
									TODAS EMPRESAS
								</option>
								<option 
									v-for="empresa in Empresas" 
									:key="empresa.id" 
									:value="empresa.id"
								>
									{{`${empresa.id} - ${empresa.nome}`}}
								</option>
							</select>
							<label>
								Empresa
							</label>
						</div>
						<div class="container-input-button">
							<div class="tooltip">
								<span class="tooltiptext right">
									Consultar produtos
								</span>
								<i 
									class="fas fa-search" 
									@click="consultarProduto = true"
								/>
							</div>
							<div class="input-group">
								<input 
									type="text" 
									v-model="Filtro.cdproduto"
									id="cdproduto"
									placeholder=" "
									autocomplete="off"
								/>
								<label for="cdproduto">
									Código Produto
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Filtro>
		<div 
			:class="`table-scroll  ${fullTable ? 'fullTable' : ''}`"  
		>
			<div class="container-table"
				v-if="Produtos.length > 0"		
			>
				<table>
					<thead>
						<tr>
							<td class="centralizado">
								N° Mov.
							</td>
							<td class="centralizado">
								Emp.
							</td>
							<td class="centralizado">
								Código Produto
							</td>
							<td>
								Data
							</td>
							<td>
								Descrição
							</td>
							<td>
								Cancelada
							</td>
							<td class="centralizado">
								Qtde. Entrada
							</td>
							<td class="centralizado">
								Qtde. Saída
							</td>
							<td class="centralizado">
								Unid.
							</td>
							<td class="centralizado">
								% Desconto
							</td>
							<td class="centralizado">
								$ Entrada
							</td>
							<td class="centralizado">
								$ Saida
							</td>
	
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(produto, index) in Produtos" :key="index"
							@click="handleSelecionarMovimentacao(produto)"
						>
							<td class="centralizado azul">
								{{ produto.documento }}
							</td>
							<td class="centralizado azul">
								{{ produto.cdempresa }}
							</td>
							<td class="centralizado azul">
								{{ produto.cdproduto }}
							</td>
							<td>
								{{ produto.data.split("-").reverse().join("/") }}
							</td>
							<td>
								{{ produto.descricao }}
							</td>
							<td :class="`${produto.cancelada === 'S' ? 'verde' : 'vermelho'} centralizado`" style="font-weight: bold;">
								{{ produto.cancelada === "S" ? "SIM" : "NÃO" }}
							</td>
							<td class="centralizado">
								{{ produto.qtdeentrada }}
							</td>
							<td class="centralizado">
								{{ produto.qtdesaida }}
							</td>
							
							<td class="centralizado">
								{{ produto.unidade }}
							</td>
							<td class="centralizado">
								{{ produto.desconto }}
							</td>
							<td class="centralizado">
								{{ (produto.qtdeentrada || 1) * produto.valorentrada | formatarParaReais }}
							</td>
							<td class="centralizado">
								{{ (produto.qtdesaida || 1) * produto.valorsaida | formatarParaReais }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="container-aviso" v-else>
				<i 
					class="fas fa-exclamation-triangle" 
					style="color: yellow" 
				/>
				<span>
					Para realizar a consulta por favor insira um código de produto
				</span>
			</div>
		</div>
		<div class="totalizador" v-if="Produtos.length > 0">
			<div class="row-totalizador">
				<div class="input-group">
					<input 
						type="text"
						placeholder=" "
						:value="TotalEntrada | formatarParaReais"
						id="totalEntrada"
					/>
					<label for="totalEntrada">
						Total Entrada
					</label>
				</div>
				<div class="input-group">
					<input 
						type="text"
						placeholder=" "
						:value="TotalSaida | formatarParaReais"
						id="totalSaida"
					/>
					<label for="totalSaida">
						Total Saída
					</label>
				</div>
				<div class="input-group">
					<input 
						type="text"
						placeholder=" "
						:value="QuantidadeSaida"
						id="QuantidadeSaida"
					/>
					<label for="QuantidadeSaida">
						Qtd. Saída
					</label>
				</div>
				<div class="input-group">
					<input 
						type="text"
						placeholder=" "
						:value="QuantidadeEntrada"
						id="QuantidadeEntrada"
					/>
					<label for="QuantidadeEntrada">
						Qtd. Entrada
					</label>
				</div>
			</div>
		</div>
		<Modal v-if="consultarProduto" @fecharModal="consultarProduto = false">
			<consulta-produtos
				:Produtos="ListaDeProdutos"
				:mostrarBotaoNovo="false"
				:height="'65vh'"
				:modal="true"
				@selecionarProduto="handleSelecionouProduto"
			>
			</consulta-produtos>
		</Modal>
		<Modal v-if="visualizarVenda" @fecharModal="fecharModal">
			<PreImpressao
				:vendaFinalizada="Venda"
				:conferir="true"
				:permiteCancelar="true"
				:empresa="Empresa"
				@fecharModal="fecharModal"
			>
			</PreImpressao>
		</Modal>
	</div>
</template>
<script>
import Filtro from "../components/Filtro.vue";
import ConsultaProdutos from "../components/ConsultaProdutos.vue";
import Modal from "../components/Modal.vue";
import PreImpressao from "../components/PreImpressao.vue";
import formatarParaReais from "../filters/formatarParaMoeda";
import { dateFormatISO } from "../utils/dateformat";
export default {
	name: "RelatorioHistoricoProduto",
	data() {
		return {
			Filtro: {
				datai: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1
				).toLocaleDateString("en-CA"),
				dataf: new Date().toLocaleDateString("en-CA"),
				empresa: "ALL",
				cdproduto: ""
			},
			fullTable: false,
			consultarProduto: false,

			visualizarVenda: false,
			Venda: {}
		};
	},
	components: {
		Filtro,
		ConsultaProdutos,
		Modal,
		PreImpressao
	},

	computed: {
		Empresa() {
			return this.$store.state.Empresa;
		},
		Empresas() {
			return this.$store.state.Empresas;
		},
		Produtos() {
			return this.$store.state.Relatorios.HistoricoProduto;
		},

		ListaDeProdutos() {
			return this.$store.state.Produtos;
		},

		TotalEntrada() {
			let valor = 0;

			if(this.Produtos?.length > 0) {
				this.Produtos.forEach(prod => {
					valor += prod.valorentrada;
				});
			}
			return valor;
		},

		TotalSaida() {
			let valor = 0;

			if(this.Produtos?.length > 0) {
				this.Produtos.forEach(prod => {
					valor += prod.valorsaida;
				});
			}
			return valor;
		},

		QuantidadeSaida() {
			let qtd = 0;
			if(this.Produtos?.length > 0) {
				this.Produtos.forEach(prod => {
					qtd += prod.qtdesaida;
				});
			}
			return qtd;
		},

		QuantidadeEntrada() {
			let qtd = 0;

			if(this.Produtos?.length > 0) {
				this.Produtos.forEach(prod => {
					qtd += prod?.qtdeentrada;
				});
			}
			return qtd;
		}
	},
	filters: {
		formatarParaReais
	},

	destroyed() {
		this.$store.commit("atualizarRelatorio", {
			campo: "HistoricoProduto",
			valor: []
		});
	},

	methods: {
		saiuData(){
			if(this.Filtro.datai == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.Filtro.datai = dateFormatISO({
					date: new Date(
						new Date().getFullYear() - 1,
						new Date().getMonth(),
						0
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.Filtro.dataf == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.Filtro.dataf = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		async gerarImpressaoRelatorio() {
			try {
				
				if(!this.Filtro.cdproduto && this.Produtos.length <= 0) {
					throw new Error(
						"Para gerar o relatório é necessário filtrar um produto primeiro, favor verificar"
					);
				}
				const totais = {
					qtdEntrada: this.QuantidadeEntrada || 0,
					qtdSaida: this.QuantidadeSaida || 0,
					vlrSaida: this.TotalSaida || 0,
					vlrEntrada: this.TotalEntrada || 0,
				};
				await this.$store.dispatch("gerarRelatorioHistoricoProduto", {
					dataI: this.Filtro.datai,
					dataF: this.Filtro.dataf,
					empresa: this.Filtro.empresa,
					produtos: this.Produtos,
					totais
				});
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
				});
				return null;
			}
		},

		async handleSelecionarMovimentacao({ documento, tipo, cdfornecedor, cdempresa, tipoCompra}) {
			if(tipo === "S") {
				this.Venda = await this.$store.dispatch("buscarVendaPorId",{
					cdVenda: documento,
					cdEmpresa: cdempresa ? cdempresa : this.Empresa.id,
				});
				this.visualizarVenda = true;
			} else if(tipo === "E") {
				if(cdempresa !== this.Empresa.id) {
					return this.$store.commit("relatarErro", {
						mensagem: "Compra localizada em outra empresa, por favor verifique"
					});
				}
				this.$router.push({ 
					name: "Gerenciamento",
					params: {
						compra: {
							cdcompra: documento,
							cdempresa,
							fornecedor: {
								id: cdfornecedor,
							},
							tipo: tipoCompra || "C",
						}
					},
					query: {
						menu: "Compras", 
						cadastro: true,
					} 
				});
			}
		},

		handleShowTable() {
			this.fullTable = !this.fullTable;
		},

		async filtrar() {
			if(!this.Filtro.cdproduto) {
				return this.$store.commit("relatarErro", {
					mensagem: "Por favor insira um código de produto para realizar a pesquisa"
				});
			}
			await this.$store.dispatch("buscarRelatorioHistoricoProduto", {
				...this.Filtro
			});
		},

		limpar() {
			this.Filtro = {
				datai: new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1
				).toLocaleDateString("en-CA"),
				dataf: new Date().toLocaleDateString("en-CA"),
				empresa: "ALL",
				cdproduto: ""
			};
		},

		fecharModal() {
			this.visualizarVenda = false;
			this.Venda = {};
		},

		handleSelecionouProduto(produto) {
			this.Filtro.cdproduto = produto?.id || produto?.cdproduto;
			this.consultarProduto = false;
			// this.filtrar();
		}
	},
};
</script>
<style scoped>
.container-page-relatorio{
	width: 100%;
	height: 90vh;
	box-shadow: var(--shadow-secundario);
	border-radius: 10px;
}
.row-filtro {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;
}
.column-filtro {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.container-input-button{
	display: flex;
	flex-direction: row;
	align-items: center;
}
.container-input-button i {
	color: var(--acao-borda);
}
.container-table{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.table-scroll {
	width: 100%;
	overflow-y: auto;
	height: 52vh;
}

.table-scroll .container-table table {
	width: 100%;
}
.centralizado{
	text-align: center;
}
.azul {
	color: var(--primario);
}
.fullTable {
	height: 70vh;
}
.container-aviso{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.container-aviso i {
	font-size: 200px;
}
.container-aviso span {
	font-size: 26px;
	font-weight: bold;
	margin-top: 10px;
}

.totalizador{
	width: 100%;
}

.totalizador .row-totalizador {
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
}

.totalizador .row-totalizador input {
	text-align: right;
}

#impressao{
	position: absolute;
	bottom: 30px;
	left: 140px;
	font-size: 35px;

	padding: 7px;
	border-radius: 50%;
    box-shadow: var(--shadow-secundario);

	background: #FFF;
	z-index: 2;
}

.verde {
	color: var(--salvar-borda);
}

.vermelho {
	color: var(--cancelar-borda);
}


@media screen and (max-height: 660px) {
	.table-scroll {
		height: 60vh;
	}
	.fullTable {
		height: 72vh;
	}
}

@media screen and (max-width: 425px){
	.row-filtro .input-group {
		width: 100%;
	}

	.container-input-button {
		width: 100%;
	}
	
	.table-scroll {
		width: 100vw;
		height: 20vh;
		overflow: auto;
	}
	.fullTable {
		height: 45vh;
	}

	.fullTable .container-aviso {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.fullTable .container-aviso i{
		font-size: 120px;
	}
	.fullTable .container-aviso span {
		font-size: 22px;
		text-align: center;
	}
	.container-page-relatorio {
		height: 71vh;
	}

	.container-aviso {
		display: none;
	}
	.totalizador .row-totalizador {
		flex-wrap: wrap;
	}
	.totalizador .row-totalizador .input-group {
		width: 125px;
		flex-grow: 1;
		margin: 5px 2px;
	}
	#impressao{
		position: absolute;
		bottom: 5px;
		left: 0px;
		font-size: 35px;
	
		padding: 7px;
		border-radius: 50%;
		box-shadow: var(--shadow-secundario);
	
		background: #FFF;
		z-index: 2;
	}
}
</style>