<template>
    <div id="listaModal" :class="`col w-95 ${!showTable ? 'table-full' : ''}`" v-if="Cidades.length >= 1">
        <table class="w-100">
            <thead>
                <tr>
                    <td style="color: blue; text-align: center;">Cód.</td>
                    <td style="text-align: left;">Nome</td>
                    <td style="text-align: center;">UF</td>
                    <td style="text-align: center;">IBGE</td>
                </tr>
            </thead>
            <tbody>
                <tr class="itemLista" v-for="(cidade, index) in Cidades" :key="index" @click="selecionarCidade(cidade)">
                    <td style="color: blue; text-align: center;">
                        <button :id="index" style="hand-button" v-if="index == indexAtual" @keydown.tab.prevent="" > 
                            <i class="fas fa-hand-point-right" style="color: var(--primario)" >
                            </i>
                        </button>
                        {{cidade.cdcidade}}
                    </td>
                    <td style="text-align: left;">{{cidade.nomecidade}}</td>
                    <td style="text-align: center;">{{cidade.uf}}</td>
                    <td style="text-align: center;">{{cidade.codibge}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div v-else class="col center">
        <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt" @click="fecharModal"></i>
        <h1>Cidade não encontrada no banco de dados da loja.</h1>
        <h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
    </div>
</template>

<script>
export default {
	name: "TabelaCidade",

	computed: {
		Cidades() {
			return this.$store.state.Cidades;
		}
	},

	data() {
		return {
			indexAtual: 0
		};
	},

	props:{		
		showTable: Boolean,
	},

	methods: {
		selecionarCidade(cidade){
			return this.$emit("selecionarCidade", cidade);
		},

		fecharModal(){
			this.$emit("fecharModal");
		},

		eventosTeclado(e) {
			if (e.key == "ArrowUp") {
				if (this.indexAtual > 0) {
					this.indexAtual--;
				}
				setTimeout(() => {
					document.getElementById(this.indexAtual).focus();
				}, 10);
			}
			if (e.key == "ArrowDown") {
				if (this.indexAtual < this.Cidades.length -1) {
					this.indexAtual++;
				}
				setTimeout(() => {
					document.getElementById(this.indexAtual).focus();
				}, 10);
			}
			if (e.key == "Enter") {
				this.selecionarCidade(this.Cidades[this.indexAtual]);
			}
			if (e.key == "Tab") {
				this.indexAtual = 0;
				setTimeout(() => {
					document.getElementById("textoPesquisa").focus();
				}, 50);
			}
		}
	},

	mounted(){
		document.addEventListener("keydown", this.eventosTeclado);
	},

	destroyed() {
		document.removeEventListener("keydown",this.eventosTeclado);
	}
};
</script>

<style scoped>
#listaModal {
    width: 95%;
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;
	height: 55vh;
}

#listaModal.table-full{
	height: 70vh !important;
}

label {
    cursor: pointer;
}

.hand-button {
    background: none;
    border: none;
    outline: none;
    clear: both;
}

@media screen and (max-width: 768px) {
	#listaModal.table-full{
		height: 68vh !important;
	}

	#listaModal{
		height: 42vh !important;
	}
}

@media screen and (max-width: 425px) {
	#listaModal.table-full{
		height: 71vh !important;
	}

	#listaModal{
		height: 30vh !important;
	}
}
</style>