<template>
    <div class="modal">
        <div class="modal-carregando">
            <img class="w-30 p-20" src="../assets/logo-transp-tidoc.png" alt="LOGO BDS">
            <h1>Por favor aguarde</h1>
            <i class="fas fa-spinner fa-pulse"></i>
            <button class="btn btn-cancelar" v-if="controller" @click="cancelarRequisicao">Cancelar</button>
        </div>
    </div>
</template>

<script>
export default {
	name: "Carregando",

	computed: {
		controller() {
			return this.$store.state.controller;
		}
	},

	methods: {
		cancelarRequisicao() {
			this.$store.state.carregando = false;
			this.controller.abort();
		}
	}
};
</script>

<style scoped>

.modal {
    z-index: 8;
    background-color: rgba(0, 0, 0, 0.8);
}

.modal-carregando {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    transition-timing-function: ease-in-out;
    transition-duration: 420ms;
    transition-delay: 500ms;
    transition-property: all;
}

h1 {
    color: var(--branco);
    margin: 0px;
}

img {
    margin: 5px auto;
    border-radius: 20px;
    max-width: 300px;
    top: 0px;
    background-color: #fff;
}

i {
    font-size: 50pt;
    margin: 5px auto;
}
.btn {
    margin: 10px auto;
    width: 300px;
}
</style>