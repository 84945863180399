<template>
    <div id="grid-caixa" class="conteudo-item">
        <i
            class="fas fa-print"
            id="btn-relatorio"
            @click="soliciarRelatorio"
            v-if="Relatorios.lucroProduto.length"
        ></i>
        <div class="conteudo-filtro">
			<div class="row">
				<div class="row between m-2 " >
					<input type="radio" id="condicao-V" class="pointer m-5" name="condicao" value="V"
                    v-model="condicao" @click="buscarDesempenhoProdutos">
                    <label for="condicao-V" class="w-100 pointer" >Vendidos</label>
                </div>
                <div class="row between m-2 " >
					<input type="radio" id="condicao-N" class="pointer m-5" name="condicao" value="N"
                    v-model="condicao" @click="buscarDesempenhoProdutos">
                    <label for="condicao-N" class="w-100 pointer" >Não vendidos</label>
                </div>
            </div>
			<div class="informacoes-item">
				<div class="row input-group">
					<input
					v-model="dataI"
					type="date"
					id="dataI"
					placeholder=" "
					@blur="saiuData"
					/>
					<label for="dataI">Data Inicial</label>
				</div>
				<div class="row input-group">
					<input
					v-model="dataF"
					type="date"
					id="dataF"
					@blur="saiuData"
					/>
					<label for="dataF">Data Final</label>
				</div>
				<button id="botao-filtrar" class="botao botao-acao" style="width:100px;margin-right:10px" @click="buscarDesempenhoProdutos">
					<i
						class="fas fa-search"
						for="cdproduto"
						style="color: var(--acao-borda)"
					></i>
					<span id="textoFiltrar">Filtrar</span>
				</button>
			</div>
        </div>
        <div class="conteudo-subitem" v-if="Relatorios.lucroProduto.length>0">
            <div class="scrollable">
                <table class="m-0 w-100" v-if="condicao == 'V'">
                    <thead>
                        <tr>
                            <td style="text-align: center; color: var(--primario);">Cód.</td>
                            <td class="text-nowrap">Descrição</td>
                            <td class="text-center text-nowrap">$ Custo</td>
                            <td class="text-center">Qtd.</td>
                            <td class="text-rigth text-nowrap">$ Bruto</td>
                            <td class="text-rigth text-nowrap">$ Desconto</td>
                            <td class="text-rigth text-nowrap">$ Troca</td>
                            <td class="text-rigth text-nowrap">$ Líquido</td>
                            <td class="text-rigth text-nowrap">$ L/P</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in Relatorios.lucroProduto" :key="index">
                            <td style="text-align: center;">{{item.cdproduto}}</td>
                            <td class="text-nowrap">{{item.descricao}}</td>
                            <td style="text-align: right;">{{(item.custo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                            <td style="text-align: center;">{{item.quantidade}}</td>
                            <td style="text-align: right;">{{(item.bruto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                            <td style="text-align: right;">{{(item.desconto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                            <td style="text-align: right;">{{(item.troca || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                            <td style="text-align: right;">{{(item.liquido || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                            <td style="text-align: right;">{{(item.lucro || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="m-0 w-100" v-else>
                    <thead>
                        <tr>
                            <td style="text-align: center; color: var(--primario);">Cód.</td>
                            <td class="text-nowrap">Descrição</td>
                            <td class="text-center">Saldo</td>
                            <td class="text-center text-nowrap">$ Custo</td>
                            <td class="text-rigth text-nowrap">% Margem</td>
                            <td class="text-rigth text-nowrap">$ Prazo</td>
                            <td class="text-rigth text-nowrap">% Desconto</td>
                            <td class="text-rigth text-nowrap">$ Vista</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in Relatorios.lucroProduto" :key="index">
                            <td style="text-align: center;">{{item.cdproduto}}</td>
                            <td class="text-nowrap">{{item.descricao}}</td>
                            <td style="text-align: center;">{{item.quantidade}}</td>
                            <td style="text-align: right;">{{(item.custo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                            <td style="text-align: right;">{{(item.lucro || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                            <td style="text-align: right;">{{(item.bruto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                            <td style="text-align: right;">{{(item.desconto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                            <td style="text-align: right;">{{(item.liquido || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="input-valor-controle">
                <div class="input-group">
                    <input type="text" readonly class="input-valor"
                    :value="`${totalCusto.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
					<label>Custo</label>
                </div>
				<div class="input-group">
                    <input type="text" readonly class="input-valor"
                    :value="totalQuantidade">
					<label>Quantidade</label>
                </div>
                <div class="input-group">
                    <input type="text" readonly class="input-valor"
                    :value="`${totalBruto.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
					<label>Bruto</label>
                </div>
                <div class="input-group" v-if="condicao == 'V'">
                    <input type="text" readonly class="input-valor"
                    :value="`${totalTroca.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
					<label>Troca</label>
                </div>
                <div class="input-group" v-if="condicao == 'V'">
                    <input type="text" readonly class="input-valor"
                    :value="`${totalDesconto.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
					<label>Desconto</label>
                </div>
                <div class="input-group">
                    <input type="text" readonly class="input-valor"
                    :value="`${totalLiquido.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
					<label>Líquido</label>
                </div>
                <div class="input-group" v-if="condicao == 'V'">
                    <input type="text" readonly class="input-valor"
                    :value="`${totalLucro.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`">
					<label>Lucro</label>
                </div>
            </div>
        </div>
		<div v-else class="col center" id="warning">
			<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
			<h1>Nenhum registro encontrado.</h1>
			<h2>Tente alterar ou diminuir os parâmetros de busca.</h2>
		</div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { dateFormatISO } from "../utils/dateformat";

export default {
	name: "RelatorioDesempenhoProduto",

	data() {
		return {
			dataI: "",
			dataF: "",
			timerFiltro: null,
			condicao: "V"
		};
	},

	computed: {
		...mapState(["Relatorios"]),
        
		totalCusto() {
			let preco = 0;
			this.Relatorios.lucroProduto.map(item => {
				preco += (item.custo || 0);
			});
			return preco;
		},

		totalBruto() {
			let preco = 0;
			this.Relatorios.lucroProduto.map(item => {
				preco += (item.bruto || 0);
			});
			return preco;
		},

		totalTroca() {
			let preco = 0;
			this.Relatorios.lucroProduto.map(item => {
				preco += (item.troca || 0);
			});
			return preco;
		},

		totalDesconto() {
			let preco = 0;
			this.Relatorios.lucroProduto.map(item => {
				preco += (item.desconto || 0);
			});
			return preco;
		},

		totalLiquido() {
			let preco = 0;
			this.Relatorios.lucroProduto.map(item => {
				preco += (item.liquido || 0);
			});
			return preco;
		},

		totalLucro() {
			let preco = 0;
			this.Relatorios.lucroProduto.map(item => {
				preco += (item.lucro || 0);
			});
			return preco;
		},

		totalQuantidade() {
			let qtd = 0;
			this.Relatorios.lucroProduto.map(item => {
				qtd += (item.quantidade || 0);
			});
			return qtd;
		},
	},

	methods: {
		saiuData(){
			if(this.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataI = dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				});
			}
			if(this.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataF = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		soliciarRelatorio() {
			this.$store.commit("relatarErro", {
				mensagem: "Deseja imprimir o relatório em A4?",
				tipo: "polar",
				item: {
					relatorio: true,
					route: this.$route.name
				}
			});
		},
		imprimirRelatorio({pageWidth}) {
			this.$store.dispatch("gerarRelatorioDesempenhoProduto", {
				empresa: this.$store.state.Empresa,
				itens: this.Relatorios.lucroProduto,
				dataI: this.dataI,
				dataF: this.dataF,
				condicao: this.condicao,
				pageWidth,
			});
		},
		buscarDesempenhoProdutos(e) {
			if (e) {
				if (e.target) {
					if (e.target.name == "condicao") {
						this.$store.state.carregando = true;
						this.$store.commit("atualizarRelatorio", {campo: "lucroProduto", valor: []});
					}
				}
			}
			if (this.timerFiltro) {
				clearTimeout(this.timerFiltro);
			}
			this.timerFiltro = setTimeout(() => {
				this.$store.dispatch("buscarDesempenhoProdutos", {
					dataI: this.dataI,
					dataF: this.dataF,
					condicao: this.condicao
				});
			}, 500);
		},

		confirmarPolar(item) {
			if (item.relatorio) {
				this.imprimirRelatorio({
					pageWidth: "A4"
				});
			}
		},

		cancelarPolar(item) {
			if (item.relatorio) {
				this.imprimirRelatorio({
					pageWidth: null
				});
			}
		},
	},

	beforeMount() {
		this.dataI = dateFormatISO({
			date: this.dataI = new Date(
				new Date().getFullYear(),
				new Date().getMonth(),
				1
			).toLocaleDateString("en-CA"),
			format: "ISOdate",	
		});
		this.dataF = dateFormatISO({date: null, format: "ISOdate"});
		this.buscarDesempenhoProdutos();
	},

	mounted() {
		this.$bus.$on("confirmarPolar", this.confirmarPolar);
		this.$bus.$on("cancelarPolar", this.cancelarPolar);
	},

	destroyed() {
		this.$bus.$off("confirmarPolar", this.confirmarPolar);
		this.$bus.$off("cancelarPolar", this.cancelarPolar);
	}
};
</script>
<style scoped>
.conteudo-filtro {
	display: flex;
	margin: 10px 0;
}

.conteudo-filtro .input-group label {
	height: 23px;
}

#grid-caixa {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
        "info-conteudo"
        "filtro-contas"
        "sub-item";
    height: 92vh;
    width: 100%;
}

.conteudo-subitem {
    border-radius: 10px;
    box-shadow: var(--shadow-secundario);
    overflow: hidden;
    height: 100%;
    grid-area: sub-item;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.scrollable {
    overflow: auto;
	min-height: 72vh;
}

.input-valor-controle {
    display: flex;
    flex-direction: row;
    width: auto;
	gap: 10px;
    margin: 10px 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.input-valor-controle .input-group {
	width: 13%;
}

.input-group input {
	width: 100%;
}

.input-valor {
    font-size: 12pt;
    width: auto;
    text-align: right;
}

.informacoes-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.informacoes-item label {
    display: flex;
    justify-content: center;
    align-items: center;
}

.informacoes-item input[type="date"] {
    max-width: 140px;
}

#dataI {
    margin-right: 5px;
}

#warning {
	margin-top: 10vh;
}

@media screen and (max-width: 1125px) {
    .input-valor-controle .input-group {
		width: 20%;
	}

	.scrollable {
		min-height: 60vh;
	}
}

@media screen and (max-width: 900px) {
    .input-valor {
        font-size: 10pt;
    }
}

@media screen and (max-width: 768px) {
    #btn-relatorio {
        left: 0;
    }
}

@media screen and (max-width: 520px) {
    .informacoes-item {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .informacoes-item div {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .informacoes-item div input[type="date"] {
        width: 120px;
    }

    .informacoes-item
        div
        input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
    }

	.input-valor-controle .input-group {
		width: 26%;
	}

	.conteudo-subitem {
		max-width: 97vw;
	}

	.conteudo-filtro{
		flex-wrap:wrap;
	}

	.conteudo-filtro .informacoes-item{
		margin-top:10px;
	}
}

@media screen and (max-width: 440px){
	#textoFiltrar{
		display:none;
	}
	.input-group{
		margin-right: 5px;
		margin-left: 5px;
	}
	#botao-filtrar{
		width: 43px!important;
	}
}
@media screen and (max-width: 440px){
	.scrollable{
		min-height: 55vh;
		height: 55vh;
	}
	.input-valor-controle{
		margin: 0px;
		margin-top: 10px;
		width: 100%;
	}
}
@media screen and (max-width: 375px) {
	#grid-caixa {
		height: 105vh;
	}
}

@media screen and (max-width: 325px) {
	#grid-caixa {
		height: 125vh;
	}
}
</style>