<template>
	<transition name="fade">
		<div class="container-modal-titulo" v-if="!loading">
			<div class="modal-titulo">
				<span>
					Lançamento de 
						{{
							tipoTitulo === "receita" ? "receitas" : "despesas"
						}} 
						({{ 
							situacaoTitulo === "A" && tipoTitulo === "receita" 
								? "A receber" : situacaoTitulo === "B" && tipoTitulo === "receita" 
								? "Recebido" :  situacaoTitulo === "A" && tipoTitulo === "despesa"
								? "A pagar" : "Pago" 
						}})
				</span>
			</div>
			<div class="controle-abas">
				<div 
					:class="abaSelecionada == 'dados-principais' 
					? 'dados-principais selecionado' 
					: 'dados-principais'" 
					id="dados-principais"
					@click="mudarAba"
				>
					Dados Principais
				</div>
				<div  
					v-if="!form.editando"
					:class="abaSelecionada == 'parcelamento' 
					? 'parcelamento selecionado' 
					: 'parcelamento'" 
					id="parcelamento"
					@click="mudarAba"
				>
					Parcelamento
				</div>
				<div
					:class="abaSelecionada == 'rateio' 
					? 'rateio selecionado'
					: 'rateio'"
					id="rateio"
					@click="mudarAba"
				>
					Rateio
				</div>
			</div>
			<div 
				class="container-formulario"
				v-if="abaSelecionada === 'dados-principais'"
			>
				<div class="container-input" style="margin-top:15px">
					<div class="input-group">
						<select 
							id="conta" 
							v-model="contaSelecionada"
							@keydown.enter="proximoCampo('aberto')"
							:disabled="form.editando"
						>
							<option value="SEL">
								SELECIONE UMA OPÇÃO
							</option>
							<option
								v-for="(conta, index) in Contas"
								:value="conta"
								:key="index"
							>
								{{ conta.nome }}
							</option>
						</select>
						<label for="conta">
							Conta <span class="obrigatorio">*</span>
						</label>
					</div>
					<div class="container-situacao" v-if="!form.editando">
						<span>
							Situação título
						</span>
						<div class="content-situacao">
							<div class="radio-group">
								<input
									v-model="situacaoTitulo"	
									type="radio"
									name="situacao-titulo"
									id="aberto"
									value="A"
									@keydown.enter="proximoCampo('emissao')"
								/>
								<label for="aberto">
									{{ tipoTitulo === "receita" ? "A receber" : "A pagar"}}
							</label>
							</div>
							<div class="radio-group">
								<input 
									v-model="situacaoTitulo"
									type="radio"
									name="situacao-titulo"
									id="pago"
									value="B"
									@keydown.enter="proximoCampo('emissao')"
								/>
								<label for="pago">
									{{ tipoTitulo === "receita" ? "Recebido" : "Pago"}}
								</label>
							</div>
						</div>
					</div>
					<div class="input-group">
						<input 
							type="date"
							placeholder=" "
							id="emissao"
							@keydown.enter="proximoCampo(situacaoTitulo === 'B' ? 'dataBaixa' : 'vencimento')"
							v-model="form.datadoc"
						/>
						<label for="emissao">
							Emissão <span class="obrigatorio">*</span>
						</label>
					</div>
					<div class="input-group" v-if="situacaoTitulo === 'A'">
						<input 
							type="date"
							placeholder=" "
							id="vencimento"
							v-model="form.datavenc"
							@keydown.enter="proximoCampo('valorTitulo')"
						/>
						<label for="vencimento">
							Vencimento <span class="obrigatorio" v-if="situacaoTitulo === 'A'">*</span>
						</label>
					</div>
					<div class="input-group" v-else>
						<input 
							type="date"
							placeholder=" "
							id="dataBaixa"
							v-model="form.databaixa"  
							@keydown.enter="proximoCampo('valorTitulo')"
						/> 
						<label for="dataBaixa">
							Data da baixa <span class="obrigatorio" v-if="situacaoTitulo === 'B'">*</span>
						</label>
					</div>
				</div>
				<div class="container-input">
					<div class="input-group">
						<input
							type="text"
							id="valorTitulo"
							autocomplete="off"
							v-mascaraFloat
							v-model="form.valorParcela"
							@keydown.enter="proximoCampo('fornecedorTitulo')"
							placeholder=" "
						/>
						<label for="valorTitulo">
							Valor <span class="obrigatorio">*</span>
						</label>
					</div>
					<div class="iconeFrente">
						<div class="input-group">
							<input
								type="text"
								id="fornecedorTitulo"
								list="lista-fornecedor"
								placeholder=" "
								v-model="queryFornecedor"
								@input="selecionarFornecedorQuery"
								autocomplete="off"						
								@keydown.enter="proximoCampo('planocontas')"
							/>
							<datalist id="lista-fornecedor">
								<option 
									v-for="(pessoa, index) in Pessoas"
									:key="index"
									:value="pessoa.nome"
								>
								</option>
							</datalist>
							<label for="fornecedorTitulo">
								Fornecedor <span class="obrigatorio" v-if="situacaoTitulo === 'A'">*</span>
							</label>						
						</div>
						<i 
							class="fas fa-address-book"
							style="color: var(--primario)" 
							@click="listarFornecedores"
						/>
					</div>
					<div class="iconeFrente">
						<div class="input-group">
							<input 
								type="text"
								id="planocontas" 
								autocomplete="off"
								@keydown.enter="listaPlanoContas = false; proximoCampo('pagamento')"
								@focus="listaPlanoContasFoco"
								@input="filtraPlanoContas"
								@keydown.tab="listaPlanoContas = false"
								:placeholder="' '"
								v-model="planoContaFiltrar">						
							<ListaBuscas 
								v-show="listaPlanoContas" 
								ref="listaPlanoConta"
								:Lista="PlanoContasFiltrado"
								:tipoLista="'PlanoContas'"
								@selecionarElemento="selecionaPlanoContas"
							/>
							<label for="planocontas">							
								Plano de Contas <span class="obrigatorio">*</span>
							</label>
						</div>
						<i 
							class="fas fa-plus" 
							style="color: var(--primario)" 
							@click="adicionarPlanoContas"
						/>
					</div>
				</div>
				<div class="container-input">
					<div class="input-group">
						<input 
							type="text"
							placeholder=" "
							id="nmrDoc"
							autocomplete="off"
							v-model="form.cddoc"
							:disabled="form.editando && form.cddoc"
							v-mascaraInteger
							@keydown.enter="proximoCampo('pagamento')"
						/>
						<label for="nmrDoc">
							N° Documento
						</label>
					</div>
					<div class="input-group">
						<select 
							id="pagamento" 
							v-model="form.cdTipoPgto"
							@keydown.enter="proximoCampo('centrodecusto')"
						>
							<option disabled value="SEL">
								SELECIONE UMA OPÇÃO
							</option>
							<option
								v-for="forma in FormasPagamento"
								:value="forma.cdtipo"
								:key="forma.cdtipo"
							>
								{{ forma.cdtipo }} - {{ forma.descricao }}
							</option>
						</select>
						<label for="pagamento">
							Forma de Pagamento <span class="obrigatorio">*</span>
						</label>
					</div>
					<div class="input-group">
						<select 
							id="centrodecusto"
							v-model="centroCusto"
							@keydown.enter="proximoCampo('competencia')"
						>
							<option disabled value="SEL">SELECIONE UMA OPÇÃO</option>
							<option 
								v-for="centro in CentroCustos"
								:key="centro.id"
								:value="centro"
							>
								{{ centro.cdcusto }} - {{ centro.descricao }}
							</option>
						</select>
						<label for="centrodecusto">
							Centro de Custo
						</label>
					</div>
				</div>
				<div class="container-input responsividade">
					<div class="input-group">
						<input 
							type="month"
							placeholder=" "
							id="competencia"
							@keydown.enter="proximoCampo('documento')"
							v-model="form.competencia"
						/>
						<label for="competencia">
							Competência <span class="obrigatorio">*</span>
						</label>
					</div>
					<div class="input-group">
						<select
							name=""
							id="documento"
							v-model="form.origem"
						>
							<option disabled>
								SELECIONE UMA OPÇÃO
							</option>
							<option value="NC">
								NFC-e
							</option>
							<option value="NE">
								NF-e
							</option>
							<option value="O">
								Outros
							</option>
						</select>
						<label for="documento">
							Documento
						</label>
					</div>
					<div class="input-group" v-if="form.origem === 'O'">
						<input 
							type="text"
							placeholder=" "
							id="outro"
							v-model="form.origemOutro"
							autocomplete="off"
						/>
						<label for="outro">
							Outro
						</label>
					</div>
					<div class="input-group">
						<input 
							type="text"
							placeholder=" "
							id="observacao"
							v-model="form.observacao"
							autocomplete="off"
						/>
						<label for="observacao">
							Observação
						</label>
					</div>
				</div>
			</div>
			<div
				class="container-formulario formulario-parcelamento"
				v-if="abaSelecionada === 'parcelamento'"
			>
				<div class="container-input">
					<div class="controle-input">
						<div class="input-group group-parcelas">
							<input 
								type="text"
								placeholder=" "
								autocomplete="off"
								id="qtdParcelas"
								v-model="qtdParcelas"
								@keydown.enter="proximoCampo('primeiroVencimento')"
								v-mascaraInteger
							/>
							<label for="qtdParcelas">
								{{ isMobileDevice ? 'Qtd. Parcelas' : "Quantidade de Parcelas" }}
							</label>
						</div>
					</div>
					<div class="controle-input">
						<div class="input-group group-vencimento">
							<input 
								type="date" 
								id="primeiroVencimento"
								v-model="primeiroVencimento"
								@keydown.enter="proximoCampo('frequencia')"
							/>
							<label for="primeiroVencimento">1° Vencimento</label>
						</div>
					</div>
					<div class="controle-input">
						<div class="row">
							<div 
								class="chipFilter" 
								id="ehFreq" 
								@click="alternarFreqFixo()"
							>
								<input 
									type="checkbox" 
									v-model="ehFrequencia" 
									id="ehFreq"
								/>
								<label>Dia Fixo</label>
							</div>
							<div class="input-group" v-if="ehFrequencia">
								<input 
									type="text"
									placeholder=" "
									autocomplete="off"
									v-model="frequencia"
									v-mascaraInteger
									id="frequencia"
									@keydown.enter="clicarBotao('calcular')"
		
								/>
								<label for="frequencia">
									Frequência
								</label>
							</div>
							<div class="input-group" v-else>
								<input 
									type="number"
									placeholder=" "
									autocomplete="off"
									v-model="diaFixo"
									id="diaFixo"
									@keydown.enter="clicarBotao('calcular')"
									@input="() => { if(diaFixo > 30) { diaFixo = 30 } if(diaFixo < 1) { diaFixo = 1 }}"
								/>
								<label for="diaFixo">
									Dia Fixo
								</label>
							</div>
						</div>
					</div>
					<div class="controle-input">
						<div class="button">
							<button
								class="botao botao-acao"
								id="calcular"
								@click="calcularParcelas">
								<span>Calcular</span>
							</button>
						</div>
					</div>
				</div>
				<div class="container-tabela" v-if="Parcelas.length > 0">
					<div class="header-tabela">
						<div class="linha-header-tabela">
							<div class="campo-tabela">
								N° Parcela
							</div>
							<div class="campo-tabela">
								Vencimento
							</div>
							<div class="campo-tabela">
								V. Parcela
							</div>
							<div class="campo-tabela">
								Percentual
							</div>
							<div class="campo-tabela campo-menor">
								Ação
							</div>
						</div>
					</div>
					<div class="body-tabela">
						<div 
							class="linha-body-tabela" 
							v-for="(parcela, index) in Parcelas" 
							:key="index"
						>
							<div class="campo-tabela">
								{{ parcela.nmrParcela }}
							</div>
							<div class="campo-tabela">
								<input 
									type="date" 
									id="tabelaVencimento"
									v-model="parcela.vencimento"
								>
							</div>
							<div class="campo-tabela">
								<input 
									v-model="parcela.vlrParcela" 
									type="text" 
									v-mascaraFloat
									@input="recalcularValores('valor', parcela)"
								/>
							</div>
							<div class="campo-tabela">
								<input 
									v-model="parcela.percentual" 
									type="text" 
									v-mascaraFloat
									@input="recalcularValores('percentual', parcela)"
								/>
							</div>
							<div class="campo-tabela campo-menor">
								<i
									class="fas fa-sync-alt"
									style="color: var(--acao-background)"
									@click="recalcularRestanteParcelas(index)"
								></i>
								<i
									class="fas fa-trash"
									style="color: var(--deletar)"
									@click="removerParcela(index)"
								></i>
								<i 
									class="fas fa-plus-square"
									style="color: var(--acao-background)"
									@click="adicionarParcelaUnica(index)"
								></i>
							</div>
						</div>
					</div>
				</div>
				<div class="linha-total" v-if="Parcelas.length > 0">
					<div class="total">
						<span>
							TOTAL:
						</span>
					</div>
					<div class="vlr-total">
						<span
							:class="ehIgual(valorCalculado,form.valorTitulo)
							? 'valorErrado'
							: 'valorCorreto'"
						>
							{{ 
								(Number(valorCalculado) || 0) | formatarParaReais
							}}
						</span>
					</div>
				</div>
			</div>
			<RateioConta 
				:Rateio="Rateio"
				:situacaoTitulo="situacaoTitulo"
				:valorTitulo="form.valorParcela"
				@clicarBotao="clicarBotao"
				@adicionarRateio="adicionarRateio" 
				v-if="abaSelecionada == 'rateio'"
			/>						
			<div class="botoes" v-if="!form.editando">
				<button 
					class="botao botao-acao"  
					@click="lancamentoTitulo(false)"
				>
					<span>
						Salvar e Criar novo
					</span>
				</button>
				<button 
					class="botao botao-acao" 
					@click="lancamentoTitulo(true)"
				>
					<span>Salvar e fechar</span>
				</button>
			</div>
			<div class="botoes" v-else>
				<button 
					class="botao botao-cancelar" 
					@click="cancelarTitulo"
				>
					<span>
						Cancelar Título
					</span>
				</button>
				<button 
					class="botao botao-acao" 
					@click="confirmarEdicaoTitulo(true)"
				>
					<span>
						Alterar e fechar
					</span>
				</button>
			</div>
			<Modal 
				v-if="modalFornecedor"
				@fecharModal="fecharModalFornecedor"
				:dimensoes="{width: '90%', height: '90%'}"
				:idModal="'lista-fornecedores'"
			>
				<consulta-cliente
					@adicionarNovo="novoFornecedor = {novo: true} "
					v-if="!novoFornecedor"
					@selecionarPessoa="selecionarFornecedor"
					:mostrarBotaoNovo="true"
				/>
				<CadastroCliente 
					v-else 
					:cliente="novoFornecedor" 
					@cancelarEdicao="novoFornecedor = null" 
				/>
			</Modal>
			<Modal 
				v-show="adicionarPlano" 
				@fecharModal="fecharModal"
				:idModal="'adicionar-plano-conta'"
				:dimensoes="
					isMobileDevice ? {
						width: '98vw',
						height: '60vh'
					} : {
						width: '75vw',
						height: 'auto'
					}
				"
			>
				<AdicionaPlanoContas 
					v-if="adicionarPlano"
					@fecharModal="fecharModal"
				/>
			</Modal>
		</div>
		<div class="container-modal-loading" v-else>
			<Loader 
				:width="'10vh'"
				:height="'10vh'"
			/>
		</div>
	</transition>
</template>

<script>
import RateioConta from "../components/RateioConta";
import ConsultaCliente from "../components/ConsultaCliente";
import CadastroCliente from "../components/CadastroCliente";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import { Titulo } from "../models/ContaPagar";
import formatarParaReais from "../filters/formatarParaMoeda";
import { proximoCampo } from "../utils/utils";
import AdicionaPlanoContas from "../components/AdicionaPlanoContas.vue";
import ListaBuscas from "./ListaBuscas.vue";
export default {
	name: "AdicionarTitulo",

	components: {
		RateioConta,
		Loader,
		ConsultaCliente,
		CadastroCliente,
		Modal,
		AdicionaPlanoContas,
		ListaBuscas,
	},

	props: {
		Titulo: Object,
		tipoTitulo: String,
		situTitulo: String
	},

	data() {
		return {
			abaSelecionada: "dados-principais",
			//FORM
			form: new Titulo({}),
			planoConta: null,
			planoContaFiltrar: "",
			centroCusto: "SEL",
			contaSelecionada: "SEL",

			Rateio: [],
			//PARCELAS
			qtdParcelas: "1",
			primeiroVencimento: new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				new Date().getDate(),
			).toLocaleDateString("en-CA"),
			frequencia: "",
			Parcelas: [],
			proximoCampo,

			queryFornecedor: null,
			loading: true,
			tipo: "SEL",
			situacaoTitulo: "A",


			modalFornecedor: false,
			novoFornecedor: null,

			ehFrequencia: true,
			diaFixo: 1,

			adicionarPlano:false,

			listaPlanoContas: false,
			PlanoContasFiltrado:[],
		};
	},

	filters: {
		formatarParaReais
	},

	computed: {
		// VERIFICAR DEVICE
		isMobileDevice() {
			const userAgent = navigator.userAgent;
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
		},

		Pessoas() {
			return this.$store.state.Clientes;
		},

		Fornecedor() {
			return this.Pessoas.find(pessoa => pessoa.nome === this.queryFornecedor.toUpperCase());
		},

		PlanoContas() {
			const { ListaPlanoContasVisualizacao } = this.$store.state.PlanoContas;
			if(ListaPlanoContasVisualizacao?.length > 0) {
				return this.tipoTitulo === "despesa" 
					? ListaPlanoContasVisualizacao?.filter(pc => 
						pc.tconta === "(-)"
					) 
					: ListaPlanoContasVisualizacao?.filter(pc => 
						pc.tconta === "(+)"
					);
			}
			return [];
		},

		Contas(){
			return this.$store.state.Contas;
		},

		FormasPagamento(){
			const formasPgto = this.$store.state.formasPagamento;
			let formasPagamento;
			if(this.situacaoTitulo == "B"){
				formasPagamento = formasPgto.filter(pgto => pgto.vistaprazo === 1);
			}
			else{
				formasPagamento = formasPgto.filter(pgto => pgto.vistaprazo === 3);
			}
			return formasPagamento;
		},

		CentroCustos(){
			return this.$store.state.CentroCustos.Lista;
		},

		valorCalculado(){
			let vlr = 0;
			this.Parcelas.forEach(parcela => {
				vlr += Number(parcela.vlrParcela.toString().replace(",", "."));
			});
			return Number(vlr).toFixed(2);
		},
	},


	beforeMount() {
		window.addEventListener("click", this.handleClickOutside);
	},

	mounted() {
		if(this.situTitulo) {
			this.situacaoTitulo = this.situTitulo;
		}
		this.clearForm();
		setTimeout( async () => {
			this.startComponent();
			this.loading = false;
		}, 220);
		window.addEventListener("keydown", (e) => {
			if(e.key === "F4") {
				e.preventDefault();
				return;
			}
		});
		this.PlanoContasFiltrado = this.PlanoContas;
		
	},
	destroyed() {
		window.removeEventListener("click", this.handleClickOutside);
	},


	methods: {
		handleClickOutside(e) {
			const listaPlanoContaContainer = this.$refs.listaPlanoConta?.$el;
			if(!listaPlanoContaContainer?.contains(e.target)) {
				if(this.listaPlanoContas) {
					this.listaPlanoContas = false;
				}
			}
		},
		ehIgual(valor1,valor2){
			const { convertNumber } = new Titulo({});
			return convertNumber(valor1) !=  convertNumber(valor2);
		},
		async confirmarEdicaoTitulo(fechar) {
			this.selecionarConta();
			this.selecionarPlanoContas();
			if(!this.validarCampos()) return;
			const titulo = new Titulo({});

			// PASSANDO UM DIA FIXO PARA O BACK CONSEGUIR FORMATAR A COMPETENCIA CORRETAMENTE
			const competencia = this.form.competencia + "-01";
			this.form.competencia = competencia;
			if(this.form?.databaixa) {
				const date = new Date(this.form.databaixa);
				const formattedDate = date.toISOString().split(".")[0] + ".000";
				this.form.databaixa = formattedDate; 
			}
			this.form.valorTitulo = titulo.convertNumber(this.form.valorTitulo);
			this.form.valorParcela = titulo.convertNumber(this.form.valorParcela);
			if(this.tipoTitulo === "despesa") {
				await this.$store.dispatch("atualizarTitulo", this.form);
			} else {
				await this.$store.dispatch("atualizarTituloReceber", this.form);
			}
			if(fechar) {
				this.$emit("fecharLancamentoTitulo");
			}
		},

		alternarFreqFixo(){
			const element = document.getElementById("ehFreq");
			element.classList.toggle("selecionado");
			this.ehFrequencia = !this.ehFrequencia;
		},
		fecharModalFornecedor() {
			this.modalFornecedor = false;
		},

		listarFornecedores() {
			this.modalFornecedor = true;
		},

		selecionarFornecedor({id, nome}) {
			this.form.cdPessoa = id;
			this.form.nome = nome;
			this.queryFornecedor = nome;
			this.fecharModalFornecedor();
		},
		// GERAL
		async cancelarTitulo(){
			const conta = this.form.conta;
			const payload = {
				cdconta: conta.cc,
				cdagencia: conta.agencia,
				cdbanco: conta.banco,
				tipo: this.form.tipo,
				cddig: this.form.cddig,
			};
			await this.$store.dispatch("removerLancamento", payload);
		},

		async startComponent(){
			// VERIFICANDO A SITUACAO DO TIPO PARA SETAR DATABAIXA OU DATAVENC
			if(this.situacaoTitulo === "B") {
				this.form.databaixa = new Date().toLocaleDateString("en-CA");
			} else {
				this.form.datavenc = new Date().toLocaleDateString("en-CA");
			}
			if(this.Titulo?.cddoc || this.Titulo?.cddig){
				this.form = this.Titulo;
				if(this.form?.competencia) {
					this.form.competencia = `${this.form?.competencia?.split("-")[0]}-${this.form?.competencia?.split("-")[1]}`;
				}
				if(this.form?.databaixa) {
					this.form.databaixa = this.form?.databaixa?.split("T")[0];
				}
				this.contaSelecionada = this.Contas.find(
					c => (c.agencia === this.form?.conta?.agencia) 
					&& (c.banco === this.form?.conta?.banco)
					&& (c.cc === this.form?.conta?.cc)
				);
				if(this.form?.planoContas?.length > 0) {
					const planoContaTitulo = this.form.planoContas[0].planoConta;
					const planoContaSelecionado = this.PlanoContas.find(pc => pc.vid === planoContaTitulo.id);
					if(planoContaSelecionado){
						const cdCentroCusto = this.form.planoContas[0].cdCentroCusto;
						this.planoConta = planoContaSelecionado;
						this.planoContaFiltrar = planoContaSelecionado.nome;
						if(cdCentroCusto) {
							this.centroCusto = this.CentroCustos.find(cc => cc.cdcusto === cdCentroCusto);
						}
					}
				}
				if(this.Titulo.situacaoTitulo) {
					this.situacaoTitulo = this.Titulo.situacaoTitulo;
				}
				if(this.form.cdPessoa) {
					const cliente = await this.$store.dispatch("buscarClientePorId", {id: this.form.cdPessoa});
					this.queryFornecedor = cliente.nome;
				} else {
					this.queryFornecedor = this.form.nome;
				}
				if(!this.form.valorParcela || this.form.valorParcela === "0,00") {
					this.form.valorParcela = this.form.valor || this.form.valorTitulo;
				}
			}
		},

		clearForm () {
			this.form = new Titulo({});
			this.queryFornecedor = "";
			this.planoConta = null;
			this.centroCusto = "SEL";
			this.Rateio = [];
			this.Parcelas = [];
		},

		mudarAba(event){
			if(event.target.id === "parcelamento"){
				this.primeiroVencimento = this.dataVencimento ? this.dataVencimento : new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					new Date().getDate(),
				).toLocaleDateString("en-CA");
			}
			this.abaSelecionada = event.target.id;
		},

		clicarBotao(id){
			const botao = document.getElementById(`${id}`);
			botao.click();
		},

		//Fornecedor 
		async selecionarFornecedorQuery() {
			setTimeout( async () => {
				await this.$store.dispatch("filtrarClientesPorNome", {
					query: this.queryFornecedor,
				});
			}, 300);
			if(this.Fornecedor && this.Fornecedor?.id){
				this.selecionarFornecedor(this.Fornecedor);
			} else {
				this.form.nome = `${this.queryFornecedor || ""}`.toUpperCase();
			}
		},
		//plano de contas
		selecionaPlanoContas(plano){
			this.planoConta = plano;
			this.planoContaFiltrar = plano.nome;
			this.listaPlanoContas = false;
		},
		listaPlanoContasFoco(){
			setTimeout(() => {
				this.listaPlanoContas = true;
			}, 200);
		},
		filtraPlanoContas(){
			this.PlanoContasFiltrado = this.PlanoContas.filter((plano)=>{
				return plano.nome.includes(this.planoContaFiltrar.toUpperCase());
			});			
			this.listaPlanoContas = true;
			if(this.planoContaFiltrar.length < 0){
				this.planoConta = null;
			}
		},
		//Parcela 
		calcularParcelas(){
			if(!this.form.valorParcela) {
				this.frequencia = "";
				return this.$store.commit("relatarErro", {
					mensagem: "É necessário informar um valor para parcelar!"
				});
			}
			const { convertNumber } = new Titulo({});
			let parcelas = [];
			let dia = Number(this.primeiroVencimento.split("-")[2]);
			let mes = Number(this.primeiroVencimento.split("-")[1]);
			let valorTotal = convertNumber(this.form.valorParcela);
			let valorParcela = Number( valorTotal / this.qtdParcelas);
			if(this.ehFrequencia){
				for(let i = 1; i <= this.qtdParcelas; i++ ){
					let percentual = (valorParcela / valorTotal) * 100;
					let vencimento = new Date(
						new Date().getFullYear(),
						mes - 1,
						dia + (i - 1) * this.frequencia,
					).toLocaleDateString("en-CA");
					parcelas.push({
						nmrParcela: i,
						vencimento: vencimento,
						vlrParcela: valorParcela.toFixed(2).replace(".", ","),
						percentual: percentual.toFixed(2).replace(".",","),
					});
				}
			}
			else{
				let percentual = (valorParcela / valorTotal) * 100;
				parcelas.push({
					nmrParcela: 1,
					vencimento: new Date(
						new Date().getFullYear(),
						mes - 1,
						dia,
					).toLocaleDateString("en-CA"),
					vlrParcela: valorParcela.toFixed(2).replace(".", ","),
					percentual: percentual.toFixed(2).replace(".",","),
				});
				for(let i = 1; i < this.qtdParcelas; i++ ){					
					if(mes+i < 13 && mes+i !== 2 || mes+i>12 && mes+i-12 !== 2 || this.diaFixo < 29){
						dia = this.diaFixo;
					}
					else{
						dia = new Date(new Date().getFullYear()+Math.floor((mes+i)/12),2,0).getDate();
					}
					let vencimento = new Date(
						new Date().getFullYear(),
						mes + i - 1,
						dia,
					).toLocaleDateString("en-CA");
					parcelas.push({
						nmrParcela: i+1,
						vencimento: vencimento,
						vlrParcela: valorParcela.toFixed(2).replace(".", ","),
						percentual: percentual.toFixed(2).replace(".",","),
					});
				}
			}
			this.Parcelas = parcelas;
		},

		adicionarParcelaUnica(idxParcela){
			const ultimaParcela = this.Parcelas[this.Parcelas.length - 1];
			const dia = Number(ultimaParcela.vencimento.split("-")[2]);
			const mes = Number(ultimaParcela.vencimento.split("-")[1]);
			const ano = Number(ultimaParcela.vencimento.split("-")[0]);
			let seq = this.Parcelas.length;
			let vencimento = new Date(
				ano,
				mes - 1,
				dia + Number(this.frequencia)
			).toLocaleDateString("en-CA");
			this.Parcelas.push({
				nmrParcela: seq + 1,
				vencimento,
				vlrParcela: Number(ultimaParcela.vlrParcela || 0),
				percentual: Number(ultimaParcela.percentual || 0),
			});
			this.recalcularRestanteParcelas(idxParcela);
		},

		removerParcela(index){
			this.Parcelas.splice(index, 1);
			if(this.Parcelas.length > 1) {
				this.Parcelas?.forEach((parcela, index) => {
					parcela.nmrParcela = index+1;
				});
			}
		},

		recalcularRestanteParcelas(idxParcela) {
			const parcelaAtual = this.Parcelas[idxParcela];
			const { convertNumber } = new Titulo({});
			const porcentagemAlterada = convertNumber(parcelaAtual.percentual);

			const porcentagemRestante = 100 - porcentagemAlterada;
			const valorTotalRestante = (convertNumber(this.form.valorParcela) - convertNumber(parcelaAtual.vlrParcela));
			
			const parcelasRestantes = this.Parcelas.filter((parcela, i) => i != idxParcela);

			let valorParcelaNovo = Number(Number( valorTotalRestante / parcelasRestantes.length).toFixed(2));
			
			let percentualParcelaNovo = (valorParcelaNovo / valorTotalRestante) * porcentagemRestante.toFixed(2);

			parcelasRestantes.forEach(parcela => {
				parcela.percentual = convertNumber(percentualParcelaNovo || 0).toLocaleString("pt-BR",{
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				});
				parcela.vlrParcela = convertNumber(valorParcelaNovo || 0).toLocaleString("pt-BR",{
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				});
			});
			const somaParcelas = this.Parcelas.reduce((total, parcela) => total + convertNumber(parcela.vlrParcela), 0);
			const diferenca = convertNumber(this.form.valorParcela) - somaParcelas;
			if (diferenca !== 0) {
				const ultimaParcela = this.Parcelas[this.Parcelas.length - 1];
				ultimaParcela.vlrParcela = ((convertNumber(ultimaParcela.vlrParcela) + diferenca) || 0).toLocaleString("pt-BR",{
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				});
			}
		},
		
		recalcularValores(tipo, parcela){
			const form = new Titulo({});
			if (tipo === "percentual") {
				const percentual = form.convertNumber(parcela.percentual);
				const novoVlrParcela = Number(percentual / 100) * form.convertNumber(this.form.valorParcela);
				parcela.vlrParcela = Number(novoVlrParcela || 0).toLocaleString("pt-BR", {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				});
			} else if (tipo === "valor"){
				const valorParcela = form.convertNumber(parcela.vlrParcela);
				const novoPercentual = Number((valorParcela / form.convertNumber(this.form.valorParcela)) * 100);
				parcela.percentual = Number(novoPercentual).toFixed(2);
				parcela.percentual = parcela.percentual.replace(".", ",");
			}
		},

		validarCampos(){
			const titulo = new Titulo({});

			let mensagemErro = "";
			if(this.situacaoTitulo === "A" && !this.form.cdPessoa) {
				mensagemErro = "É necessário informar um fornecedor para cadastrar o título, por favor verifique";
			}
			if(!this.form.contaCorrente || this.form.contaCorrente === "") {
				mensagemErro = "O campo Conta deve ser informado, por favor verifique.";
			}
			if(!this.form.cdTipoPgto || this.form.cdTipoPgto === "") {
				mensagemErro = "A forma de pagamento deve ser informada, por favor verifique.";
			}
			if(!this.form.competencia || this.form.competencia === ""){
				mensagemErro = "A data de competência documento não pode estar em branco, por favor verifique.";
			}
			if(!this.form.datadoc || this.form.datadoc === ""){
				mensagemErro = "A data de emissão não pode estar em branco, por favor verifique.";
			}
			if((!this.form.datavenc || this.form.datavenc === "") && this.situacaoTitulo === "A"){
				mensagemErro = "A data de vencimento não pode estar em branco, por favor verifique.";
			}

			if((!this.form.databaixa || this.form.databaixa === "") && this.situacaoTitulo === "B"){
				mensagemErro = "A data da baixa não pode estar em branco, por favor verifique.";
			}

			if(!this.form.valorParcela || this.form.valorParcela <= 0 || this.form.valorParcela === null){
				mensagemErro = "O valor não deve ser vazio, ou igual a zero.";
			}

			if(!this.planoConta || this.planoConta === "SEL" || this.planoConta === null){
				mensagemErro = "Por favor selecione um plano de contas.";
			}

			if(this.Parcelas.length > 0 && (this.valorCalculado != titulo.convertNumber(this.form.valorParcela).toFixed(2))){
				mensagemErro = "A soma das parcelas deve ser igual o valor total do título, verifique";
			}

			if(mensagemErro) {
				this.$store.commit("relatarErro", {
					mensagem: mensagemErro
				});
				return false;
			}
			return true;
		},

		//RATEIO
		adicionarRateio({centroCustoRateio, planoContasRateio, valorRateio, percentualRateio, competenciaRateio}){
			const titulo = new Titulo({});
			this.Rateio.push({
				competencia: competenciaRateio,
				centroCusto: this.CentroCustos.find(cc => cc?.cdcusto === centroCustoRateio),
				planoConta: planoContasRateio,
				percentual: titulo.convertNumber(percentualRateio),
				valor: titulo.convertNumber(valorRateio),
			});
		},

		selecionarConta() {
			if(this.contaSelecionada) {
				this.form.agencia = this.contaSelecionada.agencia;
				this.form.banco = this.contaSelecionada.banco;
				this.form.contaCorrente = this.contaSelecionada.cc;
				this.form.conta = {
					agencia: this.contaSelecionada.agencia,
					banco: this.contaSelecionada.banco,
					cc: this.contaSelecionada.cc
				};
			}
		},

		selecionarPlanoContas() {
			const planoContas = [];
			const titulo = new Titulo({});
			if(this.planoConta) {
				planoContas.push({
					cdCentroCusto: this.centroCusto.cdcusto,
					competencia: this.form.competencia,
					idPlanoconta: this.planoConta.vid,
					percentual: 100.00,
					valor: titulo.convertNumber(this.form.valorParcela),
				});
				this.form.planoContas = planoContas; 
			}
		},

		async adicionarTituloComParcelas(fechar) {
			let payload = [];
			for(let i=0; i < this.Parcelas.length; i++){
				const parcela = this.Parcelas[i];
				payload.push({
					...this.form,
					databaixa: this.situacaoTitulo === "A" ? null : this.form.databaixa,
					cdlan: parcela.nmrParcela,
					valorTitulo: this.form.valorParcela,
					valorParcela: parcela.vlrParcela,
					datavenc: parcela.vencimento
				});
			}			
			payload = payload.map(titulo => new Titulo(titulo).convert(titulo));
			if(this.tipoTitulo === "despesa") {
				await this.$store.dispatch("adicionarTitulosPagar", payload);
			} else {
				await this.$store.dispatch("adicionarTitulosReceber", payload);
			}
			if(fechar) {
				this.$emit("fecharLancamentoTitulo");
			}
			this.limparCamposParcelamento();
		},

		async adicionarTituloUnico(fechar) {
			this.form.valorTitulo = this.form.valorParcela;
			this.form = new Titulo({}).convert(this.form);
			this.form.cdlan = 1;
			if(this.tipoTitulo === "despesa") {
				// adicionar condicao aqui
				const { error } = await this.$store.dispatch("addTituloPagar", {
					...this.form,
					databaixa: this.situacaoTitulo === "A" ? null : this.form.databaixa,
					datavenc: this.situacaoTitulo === "B" ? new Date().toLocaleDateString("en-ca") : this.form.datavenc
				});
				this.form.competencia = `${this.form.competencia.split("-")[0]}-${this.form.competencia.split("-")[1]}`;
				this.form.databaixa = this.form.databaixa.split("T")[0];
				if(error) return null;
			} else {
				this.$store.dispatch("addTituloReceber", {
					...this.form,
					databaixa: this.situacaoTitulo === "A" ? null : this.form.databaixa,
					datavenc: this.situacaoTitulo === "B" ? this.form.databaixa.split("T")[0] : this.form.datavenc
				});
			}
			if(fechar) {
				this.$emit("fecharLancamentoTitulo");
			}
			this.$emit("salvouTitulo", this.form);
			this.clearForm();
		},

		async lancamentoTitulo(fechar) {
			try {
				this.selecionarConta();
				this.selecionarPlanoContas();
				if(!this.validarCampos()) return;
				if(this.Rateio.length > 0) {
					this.form.planoContas = this.Rateio;
				}
				if(this.Parcelas.length > 0){
					this.adicionarTituloComParcelas(fechar);
				} else {
					this.adicionarTituloUnico(fechar);
				}
			} catch (error) {
				console.error(error);
			}
		},

		limparCamposParcelamento() {
			this.qtdParcelas = "";
			this.frequencia = "";
		},
		
		adicionarPlanoContas(){
			this.adicionarPlano = true;
		},
		async fecharModal(){
			this.adicionarPlano = false;
		},
	}
};
</script>
<style scoped>
.iconeFrente{
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}
.iconeFrente .input-group{
	margin-right: 0px;
}
input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.chipFilter{
	width: 90px;
	font-size: 14px;
	border-radius: 10px;
	text-transform: uppercase;
	white-space: nowrap;
	padding: 3px 10px;
	-webkit-box-shadow: 0px 5px 21px -4px rgba(133,133,133,1);
	-moz-box-shadow: 0px 5px 21px -4px rgba(133,133,133,1);
	box-shadow: 0px 5px 21px -4px rgba(133,133,133,1);
	display: flex;
	justify-content: center;
	align-items: center;
}
.chipFilter.selecionado{
	border: 2px solid var(--primario);
}
.container-modal-titulo{
	display: flex;
	flex-direction: column;
}
.container-modal-titulo .botoes{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: 10px;
}
.modal-titulo{
	font-size: 24px;
	text-align: left;
	padding: 5px 5px;
}
.controle-abas{
	display: flex;
	flex-direction: row;
	gap: 5px;
}
.controle-abas div{
	padding: 5px;
	background-color: #FFF;
	border: 2px solid #cecece;
	border-bottom: none;
	cursor: pointer;
}
.controle-abas .selecionado{
	border-bottom: 3px solid var(--acao-borda);
}
.container-formulario{
	width: 100%;
	height: 54vh;
	overflow: auto;
	padding: 10px;
	border: solid 2px #cecece;
	display: flex;
	flex-direction: column;	
}
.container-formulario .container-input{
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 10vh;
	gap: 20px;
	border-bottom: 1px solid #cecece;
}
.controle-input{
	width: 45%;
}
.input-group{
	width: 100%;
}
.botoes{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	white-space: nowrap;
	gap: 5px;
}
.botoes .botao{
	width: 25%;
	padding: 2px 2px;
	white-space: nowrap;
}
.botao span{
	font-size: 15px;
}
#calcular{
	width: 190px;
	margin-left: 15px;
}
.container-tabela{
	width: 89vw;
	overflow: auto;
	height: 41vh;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 7px 5px;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}
.campo-menor{
	min-width: 100px;
	text-align: center;
}
.modal-titulo{
	font-size: 24px;
	text-align: left;
	padding: 5px 5px;
}
.container-formulario .container-input{
	width: 100%;
	padding: 10px;
	border-bottom: 1px solid #cecece;
}
.formulario-parcelamento .container-tabela{
	width: 800px;
}
.formulario-parcelamento .container-tabela
.campo-tabela{
	width: 145px;
}
.formulario-parcelamento .container-tabela
.campo-tabela input[type="text"] {
	padding: 5px;
	width: 75px;
	font-size: 15px;
	text-align: center;
	border-top: none;
	border-left: none;
	border-right: none;
}
.formulario-parcelamento .container-tabela
.campo-tabela input[type="date"] {
	padding: 5px;
	width: 120px;
	font-size: 15px;
	text-align: center;
	border-top: none;
	border-left: none;
	border-right: none;
}

.linha-total{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: auto;
	justify-content: space-around;
	width: 100%;
}
.valorErrado{
	color: #c50303;
}
.valorCorreto{
	color: #02048f;
}
.campo-invalido{
	border: 3px solid #c50303;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.container-modal-loading{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}
.group-parcelas,
.group-vencimento {
	width: 100%;
}
.radio-group label{
	margin-left: 5px;
}
.container-situacao{
	height: 9vh;
	text-align: left;
}
.content-situacao{
	display: flex;
	flex-direction: row;
	width: 225px;
	gap: 15px;
	justify-content: center;
	align-items: center;
}
.obrigatorio{
	color:#f00;
}
@media screen and (max-width: 425px) {
	.container-formulario {
		flex-direction: column;
	}
	.container-formulario .container-input {
		margin-top: 150px;
		display: flex;
		flex-direction: column;
		border-bottom: none;
	}
	.formulario-parcelamento .container-input{
		margin-top: 100px;
	}
	.botoes .botao {
		width: 100%;
	}
	.formulario-parcelamento .controle-input{
		width: 95%;
	}
	.formulario-parcelamento .container-tabela{
		margin-top: 90px;
		padding-bottom: 250px;
		width: 100%;
		height: 60vh;
	}
	.responsividade{
		margin-bottom: 45px;
	}
}
.plano-disabled {
	color: #f00;
}
</style>