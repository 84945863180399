<template>
	<div class="container-modal-titulo">
		<div class="modal-titulo">
			<span>
				Baixar títulos
			</span>
		</div>
		<div class="controle-abas">
			<div 
				:class="
					abaSelecionada == 'dados-principais' ?
					'dados-principais selecionado'
					: 'dados-principais'"
					id="dados-principais"
					@click="mudarAba"
				>
				Dados Principais
			</div>

			<div 
				:class="
					abaSelecionada == 'rateio' ? 'rateio selecionado' 
					: 'rateio'
				" 
				id="rateio"
				@click="mudarAba"
			>
				Rateio
			</div>
		</div>
		<div
			class="container-formulario" 
			v-if="abaSelecionada == 'dados-principais'"
		>
			<div class="container-input">
				<div class="input-group">
					<input 
						type="date" 
						id="dataBaixa" 
						v-model="dataBaixa"
						@keydown.enter="proximoCampo('conta')"
					/>
					<label for="dataBaixa">
						Data da baixa
					</label>
				</div>
				<div class="input-group">
					<select 
						id="conta" 
						v-model="contaSelecionada"
						@keydown.enter="proximoCampo('formaPgtoTitulo')"
					>
						<option
							disabled
							value="SEL"
						>
							SELECIONE UMA OPÇÃO
						</option>
						<option
							v-for="(conta, index) in Contas"
							:value="conta"
							:key="index"
						>
							{{ conta.nome }}
						</option>
					</select>
					<label for="conta">
						Conta
					</label>
				</div>
				<div class="input-group">
					<select 
						id="formaPgtoTitulo" 
						v-model="cdTipoPgto"
						@change="selecionarMetodoPagamento(cdTipoPgto)"
						@keydown.enter.prevent="proximoCampo('valorJuros')"
					>
						<option
							value="SEL"
							disabled
						>
							SELECIONE UMA OPÇÃO
						</option>
						<option 
							v-for="(forma, index) in FormasPagamento"
							:key="index"
							:value="forma.cdtipo"
						>
							{{ forma.descricao }}
						</option>
					</select>
					<label for="formaPgtoTitulo">
						Forma de Pagamento
					</label>
				</div>
			</div>
			<div class="container-input">
				<div class="input-group">
					<input 
						type="text" 
						id="valorParcela"
						v-mascaraFloat
						class="input-valor"
						readonly
						:value="valorParcelas | formatarParaReais"
						autocomplete="off"
					/>
					<label for="valorParcela">
						Valor total das parcelas
					</label>
				</div>
				<div class="input-group border-none">
				</div>
				<div class="input-group">
					<input 
						type="text" 
						id="valorJuros"
						class="input-valor"
						v-model="valorJuros"
						v-mascaraFloat
						@input="calculaJurosDesconto('juros')"
						@keydown.enter="proximoCampo('desconto')"
						autocomplete="off"
					/>
					<label for="valorJuros">
						Valor do juros
					</label>
				</div>
				
			</div>
			<div class="container-input">
				<div class="input-group">
					<input 
						type="text" 
						id="valorBaixa"
						class="input-valor"
						v-mascaraFloat
						v-model="valorBaixa"
						@input="handleJurosDesconto"
						@keydown.enter="
							proximoCampo(
								verificarVirgula(valorJuros) > 0 ? 'valorJuros' : 
								verificarVirgula(desconto) < 0 ? 'desconto' : ''
							)
						"
						autocomplete="off"
					/>
					<label for="saldo">
						Valor da baixa
					</label>
				</div>
				<div class="input-group">
					<input 
						type="text" 
						id="desconto"
						class="input-valor"
						v-mascaraFloat
						v-model="desconto"
						autocomplete="off"
						@input="calculaJurosDesconto('desconto')"
						@keydown.enter="proximoCampo('valorBaixa')"
					/>
					<label for="desconto">Desconto</label>
				</div>
				<div class="input-group">
					<input 
						type="text" 
						id="saldo"
						class="input-valor"
						v-mascaraFloat
						v-model="saldo"
						autocomplete="off"
						readonly
					/>
					<label for="saldo">
						Saldo
					</label>
				</div>
			</div>
			<div class="container-input">
				<div class="input-group">
					<input 
						type="text" 
						id="observacao"
						autocomplete="off"
						v-model="observacao"
					/>
					<label for="observacao">
						Observações
					</label>
				</div>
			</div>
		</div>
		<RateioConta
			:Rateio="planoContas || []"
			:valorTitulo="`${valorPagar}`"
			@adicionarRateio="addRateio"
			v-if="abaSelecionada === 'rateio'"
		/>
		<div class="botoes">
			<button
				class="botao botao-acao"
				@click="baixarContas(true)"
			>
				Baixar e Fechar
			</button>
		</div>
	</div>
</template>
<script>
import formatarParaReais from "../filters/formatarParaMoeda";
import { dateFormatISO } from "../utils/dateformat";
import RateioConta from "../components/RateioConta";
export default{
	name: "BaixarConta",

	props: {
		contasBaixar: Array,
	},

	data() {
		return {
			abaSelecionada: "dados-principais",
			valorJuros: "",
			dataBaixa: new Date().toLocaleDateString("en-ca"),
			observacao: "",
			planoContas: [],
			desconto: "",
			valorBaixa: 0,
			cdTipoPgto: "SEL",
			contaSelecionada: "SEL",
			saldoDescontoJuros: 0,
			saldo: "",
			formaPgto: {}
		};
	},

	components: {
		RateioConta
	},

	computed: {
		Contas(){
			if(this.formaPgto.ehPix === "S" || this.formaPgto.deposito === "S") {
				return this.$store.state.Contas.filter(c => c.aceitapix);
			}
			return this.$store.state.Contas;
		},

		FormasPagamento(){
			return this.$store.state.formasPagamento.filter(fp => fp.mostrarecebimento === "S" && fp.vistaprazo === 1);
		},
		
		valorParcelas(){
			let vlr = 0;
			if(this.contasBaixar?.forEach){
				this.contasBaixar.forEach(conta => {
					console.log(this.convertStringToNumber(conta.valorParcela || 0));
					vlr += this.convertStringToNumber(conta.valorParcela || 0);
				});
			}
			return vlr;
		},

		valorPagar() {
			return Number(
				Number(this.verificarVirgula(this.valorParcelas)) 
				+ 
				Number(this.verificarVirgula(this.valorJuros))
				-
				Number(this.verificarVirgula(this.desconto))
			);
		},

		CentroCustos(){
			return this.$store.state.CentroCustos.Lista;
		},

		PlanoContas() {
			return this.$store.state.PlanoContas.ListaPlanoContas;
		},
		
	},
	
	filters: {
		formatarParaReais
	},

	methods: {
		convertStringToNumber(value) {
			return typeof value === "string" ? Number(value.replace(",", ".")) : value;
		},

		selecionarMetodoPagamento(cdtipopgto) {
			this.formaPgto = this.FormasPagamento.find(fp => fp.cdtipo === cdtipopgto);
			if(this.formaPgto.deposito === "S" || this.formaPgto.ehPix === "S") {
				this.contaSelecionada = "SEL";
			}
		},

		proximoCampo(id) {
			const input = document.getElementById(id);
			if(input) {
				input.focus();
				if(input.select) {
					input.select();
				}
			}
		},

		handleJurosDesconto() {
			const valorBaixa = this.verificarVirgula(this.valorBaixa);
			const valorParcelas = this.verificarVirgula(this.valorParcelas);
			if(valorBaixa > valorParcelas){
				this.saldoDescontoJuros = valorBaixa - valorParcelas;
				this.valorJuros = this.saldoDescontoJuros.toLocaleString("pt-BR", {
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
				this.desconto = "";
			} else if(valorBaixa < valorParcelas) {
				this.saldoDescontoJuros =  valorParcelas - valorBaixa;
				this.desconto = this.saldoDescontoJuros.toLocaleString("pt-BR", {
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
				this.valorJuros = "";
			}
		},

		validarCampos() {
			let mensagemErro = "";
			if(!this.contaSelecionada.cc) {
				mensagemErro = "Para baixar os títulos é necessário informar a conta onde será baixado.";
			}
			if(this.cdTipoPgto === "SEL" || !this.cdTipoPgto){
				mensagemErro = "Para baixar os títulos é necessário informar a forma de pagamento primeiro.";
			}
			if(!this.dataBaixa){
				mensagemErro = "Para baixar os títulos é necessário informar a data da baixa.";
			}
			if(this.valorBaixa <= 0){
				mensagemErro = "Informe um valor a baixar";
			}
			if(mensagemErro) {
				this.$store.commit("relatarErro", {
					mensagem: mensagemErro
				});
				return false;
			}
			return true;
		},

		calculaJurosDesconto(campo) {
			if(campo === "juros") {
				this.saldoDescontoJuros = this.verificarVirgula(this.valorJuros);
			}
			if(campo === "desconto") {
				if(Number(this.desconto) === 0 || !this.desconto || this.desconto === "") {

					this.saldo = this.saldoDescontoJuros.toLocaleString("pt-BR", {
						style: "decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			}
			this.valorBaixa = this.valorPagar.toLocaleString("pt-BR", {
				style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			});
		},

		addRateio({centroCustoRateio, planoContasRateio, valorRateio, percentualRateio, competenciaRateio}){
			this.planoContas.push({
				competencia: competenciaRateio,
				centroCusto: this.CentroCustos.find(cc => cc.cdcusto === centroCustoRateio),
				planoConta: this.PlanoContas.find(pc => pc.cod === planoContasRateio),
				valor: valorRateio || 0,
				percentual: percentualRateio || 0,
			});
		},

		async baixarContas(total) {
			const form = [];
			if(!this.validarCampos()) return;
			if(this.contasBaixar?.forEach){
				this.contasBaixar.sort((a, b) => {
					const dataA = dateFormatISO({date: a.dataEmissao, format: "ISOdate"});
					const dataB = dateFormatISO({date: b.dataEmissao, format: "ISOdate"});
					return dataA.localeCompare(dataB);
				});
				const cc = {
					cdagenciaBaixa: this.contaSelecionada.agencia,
					cdbancoBaixa: this.contaSelecionada.banco,
					cdcontaBaixa: this.contaSelecionada.cc,
				};
				let valorTitulo = 0;
				this.contasBaixar.forEach(conta => {
					
					if(!total) {
						const vlrJuros = Number(this.verificarVirgula(this.valorJuros));
						const desconto = Number(this.verificarVirgula(this.desconto));
						conta.valorParcela +=  vlrJuros - desconto;
					}
					const valorBaixa = this.verificarVirgula(this.valorBaixa);
					
					const saldo = Number(this.verificarVirgula(this.saldoDescontoJuros || 0)?.toFixed(2)); // variavel que joga o juros e o desconto mas nao da pra saber se é juros ou desconto
					// LOGICA PARA BAIXAR MAIS DE UMA CONTA
					console.log(this.planoContas);
					valorTitulo += this.verificarVirgula(conta?.valorParcela || 0);
					if((valorBaixa >= valorTitulo) && this.contasBaixar.length > 1){
						form.push({
							...cc,
							aumdesc: total ? saldo : 0,
							cdcliente: conta?.cdpessoa || "",
							cddoc: conta?.cdmovi || "",
							cdempresa: conta?.cdempresa || "",
							cdvendedor: conta?.cdvendedor || "",
							dataBaixa: this.dataBaixa,
							nome: conta?.nome || "",
							tipoPgto: this.cdTipoPgto,
							observa: `${this.observacao || ""}`.toUpperCase() || "",
							tituloABaixarCdagencia: conta?.cdagencia,
							tituloABaixarCdbanco: conta?.cdbanco,
							tituloABaixarCdconta: conta?.cdconta,
							tituloABaixarCddig: conta?.cddig,
							tituloABaixarTipo: conta?.tipo,
							valorBaixa: valorTitulo,
							valorEntreguePeloCliente: valorTitulo, 
							// planoConta: conta?.planoConta
							planoContas: this.planoContas
						});
					} else {
						console.log("aqui dentro");
						this.desconto = this.verificarVirgula(this.desconto);
						this.valorJuros = this.verificarVirgula(this.valorJuros);
						let saldoContaUnica = 0;
						if(this.valorJuros && this.valorJuros > 0) {
							saldoContaUnica += this.valorJuros * - 1;
						}
						if(this.desconto && this.desconto > 0) {
							saldoContaUnica += this.desconto;
						}
						form.push({
							...cc,
							aumdesc: !total ? saldoContaUnica : 0,
							cdcliente: conta?.cdpessoa || "",
							cddoc: conta?.cdmovi || "",
							cdempresa: conta?.cdempresa || "",
							cdvendedor: conta?.cdvendedor || "",
							dataBaixa: this.dataBaixa || "",
							nome: conta?.nome || "",
							tipoPgto: this.cdTipoPgto,
							observa: `${this.observacao || ""}`.toUpperCase() || "",
							tituloABaixarCdagencia: conta?.cdagencia,
							tituloABaixarCdbanco: conta?.cdbanco,
							tituloABaixarCdconta: conta?.cdconta,
							tituloABaixarCddig: conta?.cddig,
							tituloABaixarTipo: conta?.tipo,
							valorBaixa: valorBaixa,
							valorEntreguePeloCliente: valorBaixa,
							// planoConta: conta?.planoConta
							planoContas: this.planoContas
						});
					}
				});
			}
			await this.$store.dispatch("baixarTitulos", form);
			this.$emit("fecharModal", true);
		},

		verificarVirgula(valor){
			if (typeof valor === "string") {
				const valorSemPontos = valor.replace(/\./g, "");
				const valorSemVirgulas = valorSemPontos.replace(",", ".");
				return Number(valorSemVirgulas);
			}
			return valor;
		},

		mudarAba(e){
			this.abaSelecionada = e.target.id;
		},
	},

	mounted() {
		if(this.contasBaixar?.forEach){
			this.contasBaixar?.forEach(conta => {
				if(conta.planoContas?.forEach){
					conta.planoContas?.forEach(plano => {
						console.log(plano);
						this.planoContas.push(plano);
					});
				}
			});
		}
		this.valorBaixa = this.verificarVirgula(this.valorParcelas || 0).toLocaleString("pt-BR", {
			style: "decimal",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	}
};
</script>
<style scoped>
.container-modal-titulo{
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
}
.modal-titulo{
	font-size: 24px;
	text-align: left;
	padding: 5px 5px;
}
.container-formulario{
	width: 100%;
	height: 90vh;
	overflow: auto;
	padding: 10px;
	border: solid 2px #cecece;
	display: flex;
	flex-direction: column;	
}
.container-formulario .container-input{
	width: 100%;
	border-bottom: 1px solid #cecece;
	display: flex;
	height: 10vh;
	justify-content: center;
	align-items: center;
}
.controle-abas{
	display: flex;
	flex-direction: row;
	gap: 5px;
}
.controle-abas div{
	padding: 5px;
	background-color: #FFF;
	border: 2px solid #cecece;
	border-bottom: none;
	cursor: pointer;
}
.controle-abas .selecionado{
	border-bottom: 3px solid var(--acao-borda);
}
.container-input{
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 15px;
}
.container-input .input-group{
	width: 100%;
}
.container-modal-titulo .botoes{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: 10px;
	gap: 20px;
}
.input-valor{
	text-align: right;
}
.botoes{
	width: 100%;
}
.botoes button{
	width: auto;
	padding: 5px;
}

.border-none {
	border: none;
}
</style>