import { gerarImpressaoPix } from "./Impressao.js";
import {doRequest} from "./http.js";

export default{
	state: {
		CobrancaPix: {},
		ConfiguracaoPixEmpresa: [],
		ConsultarPix: {
			listaConsulta: [],
			paginacao: {
				page: 0,
				size: 25,
				totalElements: 0,
				totalPages: 3
			}
		},
		carregamento: false,
	},
	actions: {
		async estornarPixCobranca({commit, state}, payload){
			state.carregando = true;
			const { valor, descricao, e2E } = payload;
			const form = { valor, descricao, e2E };
			const body = JSON.stringify(form);
			const url = `pix/estornarPix?empresa=${state.Empresa.id}&banco=${state.ConfiguracaoPixEmpresa[0].banco}`;
			const data = await doRequest(url, {
				method: "put",
				cache: "no-cache",
				body,
			}, commit);
			state.carregando = false;
			if(data){
				return data;
			}
			state.carregando = false;
		},
		async filtrarListaPix({state, commit}, payload){
			const url = `pix/consultarCobrancas?empresa=${state.Empresa.id}${payload.tipoPesquisa == "CPF/CNPJ" && payload.filtroDinamico != "" ? `&CpfCnpj=${payload.filtroDinamico}` : ""}${payload.tipoPesquisa == "TXID" && payload.filtroDinamico != "" ? `&txId=${payload.filtroDinamico}` : ""}
				${payload.tipoPesquisa == "Nome Pagador" && payload.filtroDinamico != "" ? `&nomePagador=${payload.filtroDinamico}` : ""}${payload.datai ? `&dataInicio=${payload.datai}` : ""}${payload.dataf ? `&dataFinal=${payload.dataf}` : ""}${payload.page ? `&page=${payload.page}` : ""}
				${payload.size ? `&size=${payload.size}` : ""}${payload.statusCobranca && payload.statusCobranca != "SELECIONAR" ? `&statusCobranca=${payload.statusDevolucao ? "CONCLUIDA" : payload.statusCobranca }` : ""}${payload.statusCobranca != "SELECIONAR" ? `&statusDevolucao=${payload.statusDevolucao}` : ""}`;

			const data = await doRequest(url, {
				method: "GET",
				cache: "no-cache"
			}, commit);
			if(data && data.mensagem){
				state.ConsultarPix.listaConsulta = [];
			}
			if(data){
				commit("atualizarListaState", data);
			}
		},
		async buscarListaPix({state, commit}){
			state.carregando = true;
			const url = `pix/consultarCobrancas?empresa=${state.Empresa.id}`;
			const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
			state.carregando = false;
			if(data?.content){
				commit("atualizarListaState", data);
				return;
			}
			commit("atualizarListaState", []);
			

		},
		impressaoPix($store, payload) {
			gerarImpressaoPix($store, {valor: payload, qrcode: $store.state.CobrancaPix.qrcode});
		},

		async verificarConfiguracaoPix({commit, state}){
			try {
				state.carregando = true;
				const url = `pix/listarConfiguracoes?empresa=${state.Empresa.id}`;
				const data = await doRequest(
					url,
					{ method: "get", cache: "no-cache" },
					commit
				);
				
				state.carregando = false;
				commit("atualizarConfigState", data);
				return data;
			} catch (error) {
				state.carregando = false;
				console.log(error);
			}
		},
		async gerarCobrancaPix({commit, state}, payload){
			try {
				state.carregamento = true;
				const { nome, cpf, mensagem, banco } = payload;
				const valor = typeof payload.valor == "string" ? payload.valor.replace(",", ".") : payload.valor;
				const url = `pix/criarCobrancaPix?empresa=${state.Empresa.id}&banco=${banco}`;
				const form = nome ? {
					cdempresa: state.Empresa.id,
					nome: nome ? nome : "",
					cpf: cpf ? cpf : "",
					observacao: mensagem ? mensagem : "",
					valor: valor
				} : {
					cdempresa: state.Empresa.id,
					cpf: cpf ? cpf : "",
					valor: valor,
					observacao: mensagem ? mensagem : "",
				};
				const body = JSON.stringify(form);
				const data = await doRequest(url, {
					method: "POST",
					body,
					cache: "no-cache"
				}, commit);
				if(data){
					commit("atualizarCobranca", data);
					state.carregamento = false;
					return data;
				}
			} catch (error) {
				console.log(error);
				state.carregamento = false;
			}

		},
		async cancelarCobrancaPix({state, commit}, payload){
			if(state.CobrancaPix.statuscobranca == "CONCLUIDA") return;
			state.carrengando = true;
			const {txid} = payload;
			const url = `pix/cancelaCobrancaPix?banco=${payload.banco}&empresa=${state.Empresa.id}&txId=${txid || state.CobrancaPix.txid}`;
			const data = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);
			if(data ? !data.txid : false){
				return commit("relatarErro", {mensagem: data?.mensagem || (data.message || JSON.stringify(data))});
			}
			state.carregando = false;
			if(data){
				commit("relatarErro", {mensagem: "Cobrança cancelada com sucesso", sucess: true});
				commit("zerarCobrancaState");
			}
		},
		async consultarCobrancaPix({state, commit}, payload){
			state.carregamento = true;
			const { banco, txid } = payload;
			const url = `pix/consultarPix?banco=${banco}&empresa=${state.Empresa.id}&txId=${txid}`;
			const data = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);
			state.carregamento = false;
			if(data ? !data.txid : false){
				return commit("relatarErro", {mensagem: "Por favor tente novamente em alguns instantes"});
			}
			return data;

		},
		async validarDataEstorno({ commit}, payload){
			const { txid } = payload;
			const url = `pix/validar/estorno?txid=${txid}`;

			const data = await doRequest(url, {method: "put", cache: "no-cache"}, commit);

			return data;
		},
		async cadastrarEmissor({commit, state}, payload){
			try {
				state.carrengando = true;

				const {
					cep,
					cdCidade,
					cnpj,
					ufCidade
				} = state.Empresa;
				const url = "pix/cadastrarEmissor";
				const form = {
					ambiente: 1,
					appliocationkeybrasil: payload.applicationkey ? payload.applicationkey : "",
					banco: payload.selectBanco,
					cep: cep,
					chavepix: payload.inputChavePix,
					cidade: cdCidade,
					clienteid: payload.clienteid,
					clientesecret: payload.clientesecret,
					cnpjcpf: cnpj,
					expiracao: 3600,
					nomeRecebedor: payload.nomeRecebedor,
					timeout: 9000,
					tipochave: payload.selectTipoChave,
					uf: ufCidade

				};
				const body = JSON.stringify(form);
				const data = await doRequest(url, {
					method: "POST",
					body: body,
					cache: "no-cache"

				}, commit);
				if(data && !data.mensagem){
					commit("relatarErro", {mensagem: "Cadastro de emissor realizado com sucesso", sucess: true});
				}
				state.carrengando = true;

			} catch (error) {
				commit("relatarErro", {mensagem: error.message,});
			}
		}
	},
	mutations: {
		atualizarConfigState(state, payload){
			state.ConfiguracaoPixEmpresa = [...payload];
		},
		atualizarListaState(state, payload){
			state.ConsultarPix.listaConsulta = payload?.content ? payload.content : [];
			state.ConsultarPix.paginacao = {
				page: payload?.page || 0,
				size: payload?.size || 25,
				totalElements: payload?.totalElements || 0,
				totalPages: payload?.totalPages || 0,
				last: payload?.last || true,
			};
		},
		zerarCobrancaState(state){
			state.CobrancaPix = {};
		},
		atualizarCobranca(state, payload){
			state.CobrancaPix = {...payload};
		}
	}
};
