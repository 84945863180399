<script>
import { Doughnut, mixins }from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";


export default {
	name: "PieChartPagamentos",
	extends: Doughnut,
	plugins: [ChartDataLabels],
	mixins: [mixins.reactiveData],
	data() {
		return {
			chartData: {
				labels: [],
				datasets: [
					{
						backgroundColor:[],
					}
				]
			},
			showDatapoints: true,
			options: {
				
				plugins: {
					datalabels: {
						color: "#000",
						align: "end",
						anchor: "end",
						formatter: function(value, context) {
							const dataset = context.chart.data.datasets[context.datasetIndex];
							const total = dataset.data.reduce((acc, data) => acc + data, 0);
							const percentage = Number(((value / total) * 100).toFixed(2));
							return percentage < 1.00 ? "" : percentage + "%";
						},
						font: {
							size: 12,
							style: "bold"
						},
					}
				},
				tooltips: {
					callbacks: {
						label: function(context, data) {
							const currentIndex = context.index;
							const label_text = data?.labels[currentIndex];
							const label_value = data?.datasets[0]?.data[currentIndex];
							const label = `${label_text}: ${(label_value || 0).toLocaleString("pt-BR", {
								style: "currency",
								currency: "BRL"
							})}`;

							return label;
						}
					}
				},
				responsive: true,
				maintainAspectRatio: false,
				percentageInnerCutout: 90,
				title: {
					display: true,
					position: "top",
					lineHeight: 2.5,
					fontSize: 15,
					text: "Vendas por Métodos de pagamento"
				},
				legend: {
					display: true,
					position: "bottom",
					labels: {
						fontSize: 10
					},
					maintainAspectRatio: false,
					align: "start"
				},
			},
		};
	},
	computed: {
		Financeiro() {
			return this.$store.state.Relatorios.Dashboard.Financeiro;
		}
	},

	watch: {
		Financeiro(newV) {
			this.setDataToChart(newV);
		}
	},
	methods: {
		randomColors(tamanho) {
			const coresHex = [
				"#8fbeff", "#bdd9ff", "#91a4bd", "#5f789c",
				"#3a5b8a", "#001b42", "#183154", "#003175",
				"#005bd9", "#003d91", "#006bff", "#3d8fff",
				"#397ad4", "#6673C2", "#93a5bd", "#002454",
				"#5972ff", "#0026ff", "#000d59", "#425fff"
			];
			const arrBackgrounds = [];
			
			for (let i=0; i < tamanho; i++) {
				let pos;
				do {
					pos = Math.floor(Math.random() * coresHex.length);
				} while (arrBackgrounds.includes(coresHex[pos]));

				arrBackgrounds.push(coresHex[pos]);
					
			}
			return arrBackgrounds;
		},

		setDataToChart(financeiro){
			this.chartData.datasets[0].data = financeiro.map(fin => fin.valor);

			this.chartData.datasets[0].backgroundColor = this.randomColors(financeiro.length, "#3B4599");
			this.chartData.labels = financeiro.map(fin => fin.descricao);

			if(this?._data?._chart) {
				this._data._chart.destroy();
			}
			// Chart.plugins.register(ChartJsPluginDataLabels);
			this.addPlugin(ChartDataLabels);

			this.renderChart(this.chartData, this.options);
		}
	},
	mounted() {
		setTimeout(() => {
			if(this.Financeiro?.length > 0) {
				this.setDataToChart(this.Financeiro);
			}
		}, 200);
	}
};
</script>

<style></style>
