<template>
    <div id="TelaCaixa">
		<div class="titulo">
			<h1>Dados da Caixa</h1>
		</div>
		<div class="container-form">
			<div class="row w-100 wrap" id="caixaEConta">
				<div class="input-group" id="nomeDoCaixa">
					<input 
					v-model="CaixaCadastrado.identificador" @input="setUpperCase" 
					autocomplete="off" maxlength="30" id="identificador" type="text"
					placeholder=" "
					>
					<label for="identificador">Nome do Caixa</label>
				</div>
				<div class="input-group" id="contaCorrente">
					<select id="conta" v-model="CaixaCadastrado.conta">
                        <option :value="{
							banco: conta.banco,
							agencia: conta.agencia,
							cc: conta.cc,
						}" v-for="(conta, index) in contas" :key="index">
                            {{conta.nome}}
                        </option>
                    </select>
					<label for="conta">Conta Corrente</label>
				</div>
				<div id="ativo" @click="mudarAtivo('ativo', 'A', 'I')" class="campos row nowrap">
					<label class="mx-5 text-right w-auto" for="ativo">Ativo: </label>
					<i class="far fa-times-circle  text-left" v-if="CaixaCadastrado.ativo == 'I'" style="color: red;"> Não</i>
					<i class="far fa-check-circle  text-left" v-else style="color: green"> Sim</i>
				</div>
			</div>
			<div class="input-group w-100" v-if="$store.state.Usuario.usuarioPrivilegiado">
				<select id="cdempresa" v-model="CaixaCadastrado.cdempresa">
					<option v-for="empresa in Empresas" :key="empresa.id" :value="empresa.id">{{`${empresa.id} - ${empresa.nome}`}}</option>
				</select>
				<label for="cdempresa">Empresa</label>
			</div>
		</div>
		<div class="botoes">
            <button @click="cancelarEdicao" class="botao botao-cancelar">
                <span class="lg-view">Cancelar [Alt + C]</span>
				<span class="sm-view">Cancelar</span>
            </button>           
            <button @click="salvarCaixa" class="botao botao-adicionar">
                <span class="lg-view">Salvar [Alt + S]</span>
				<span class="sm-view">Salvar</span>
            </button>            
        </div>              
	</div>
</template>
<script>
export default {
	name: "CadastroCaixa",

	props: {
		Caixa: Object,
	},

	data() {
		return {
			CaixaCadastrado: {
				conta: null,
				cdempresa: "",
				identificador: "",
				ativo: "A"
			},
		};
	},

	computed: {
		contas() {
			return this.$store.state.Contas;
		},
		Empresas(){
			return this.$store.state.Empresas;
		}
	},

	methods: {
		mudarAtivo(campo, letra1, letra2) {
			if (this.CaixaCadastrado[campo] == letra1) {
				this.CaixaCadastrado[campo] = letra2;
			}
			else {  
				this.CaixaCadastrado[campo] = letra1;
			}
		},

		setUpperCase(e) {
			this.CaixaCadastrado[e.target.id] = this.CaixaCadastrado[e.target.id].toUpperCase();
		},

		setOnlyNumbers(e) {
			const regex = /[0-9,]/;
			if (!regex.test(e.data) && e.data != null) {
				this.CaixaCadastrado[e.target.id] = this.CaixaCadastrado[e.target.id].substring(0, this.CaixaCadastrado[e.target.id].length -1);
			}
		},

		async salvarCaixa() {
			if (!this.CaixaCadastrado.conta) {
				return this.$store.commit("relatarErro", {mensagem: "Campo conta não pode estar vazio"});
			}
			
			if (!this.CaixaCadastrado.identificador) {
				return this.$store.commit("relatarErro", {mensagem: "Campo nome do caixa não pode estar vazio"});
			}

			if (this.Caixa && this.Caixa.novo) {
				this.$emit("cancelarEdicao");
				return this.$store.dispatch("adicionarCaixa", {...this.CaixaCadastrado});
			}

			if (JSON.stringify(this.CaixaCadastrado) != JSON.stringify(this.Caixa)) {
				this.$emit("cancelarEdicao");
				return this.$store.dispatch("editarCaixa", {...this.CaixaCadastrado, idcaixa: this.Caixa.cdCaixa});
			}
		},

		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},
	},

	mounted() {
		this.$store.dispatch("buscarContas");
		if (this.Caixa) {
			if (this.Caixa.novo) {
				return;
			}
			this.CaixaCadastrado = {
				conta: {
					agencia: this.Caixa.agencia,
					banco: this.Caixa.banco,
					cc: this.Caixa.cc,
				},
				cdempresa: this.Caixa.cdEmpresa,
				identificador: this.Caixa.identificador,
				ativo: this.Caixa.ativo,
			};
			if (!this.$store.state.Usuario.usuarioPrivilegiado || !this.$store.state.Usuario.incluiCliente) {
				document.getElementById("conta").disabled = true;
			}
		}
	},
};
</script>
<style scoped>
.campos {
	margin: 5px 0;
}

.campos i {
	place-self: center;
}

#TelaCaixa{
    display: flex;
	flex-direction: column;
	width: 95%;
	height: 100%;
	margin: 0px auto;
}

label {
	width: auto;
	white-space: nowrap;
	margin: 0 10px;
	align-items: center;
	justify-content: center;
}

input, select {
	width: 100%;
}
.container-form {
	flex-grow: 1;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.input-group label {
	height: 12px;
	margin: 0;
}

#nomeDoCaixa {
	width: 50vw;
}

#contaCorrente {
	width: 20vw;
}

.sm-view {
	display: none;
}

.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}

.botoes .botao {
	width: 45%;
}
@media screen and (max-width: 1024px) {
	#contaCorrente {
		width: 18vw;
	}
}

@media screen and (max-width: 768px) {
	#contaCorrente {
		width: 22vw;
	}

	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}
}

@media screen and (max-width: 440px) {
	#nomeDoCaixa {
		width: 100%;
	}

	#contaCorrente {
		width: 65%;
		margin-top: 15px;
	}

	#ativo {
		margin-top: 15px;
	}

	.titulo {
        font-size: 13pt;
    }

	.lg-view {
		display: none;
	}
	
	.sm-view {
		display: inline-block;
	}
}

@media screen and (max-width: 375px) {
	#nomeDoCaixa {
		width: 100%;
	}

	#contaCorrente {
		width: 100%;
		margin-top: 15px;
	}

	#ativo {
		margin-top: 15px;
	}

	.titulo {
        font-size: 13pt;
    }

	.lg-view {
		display: none;
	}
	
	.sm-view {
		display: inline-block;
	}
}
</style>