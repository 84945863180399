// import state from "../store/state";
import { doRequest } from "./http";

export default {
	state: {
		grupoProduto: [],
		grupoSelecionado: null,
		paginaGruposAtual: -1,
		totalPaginasGrupos: 0,
	},

	actions: {
		async buscarGrupos({commit}, payload){
			try {
				let url;
				if (!payload || payload == undefined){
					url = "grupoProduto/all?page=0&size=25&sort=descricao&sortOrder=asc";
				} else {
					url = `grupoProduto/all?page=${payload.page || 0}&size=${payload.size || 25}&sort=descricao&sortOrder=asc`;
				}
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.content) {
					return commit("atualizarListaGrupoProdutos", data);
				}
				else {
					return commit("atualizarListaGrupoProdutos", {
						content: [],
						page: -1,
						totalPages: 0,
					});
				}
			} catch (error) {
				console.log(error);
				return commit("atualizarListaGrupoProdutos", {
					content: [],
					page: -1,
					totalPages: 0,
				});
			}
		},

		async adicionarGrupoProduto({state, commit}, payload){
			try {
				state.carregando = true;
				const body = JSON.stringify ({
					descricao: payload.descricao,
					subGrupos: payload.subGrupos.map((subgrupo, indice) => {
						return {
							descricao: subgrupo.descricao,
							cdsubgrupo: indice +1,
							ncm: subgrupo.ncm
						};
					}),
					tipo: payload.tipo,
					ncm: payload.ncm
				});
				let url = "grupoProduto";
				const data = await doRequest(url, {method: "post", body}, commit);
				state.carregando = false;
				if (!data.cdGrupo) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				return data;
			} catch (error) {
				console.log(error);
				state.carregando = false;
				commit("relatarErro", {mensagem: error.mensagem || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
				return null;
			}
		},

		async atualizarGrupoProduto({state, commit}, payload) {
			try {
				state.carregando = true;

				const subGrupos = payload.subGrupos;
				// const cdsubgrupos = subGrupos.map(s => s.cdsubgrupo || 0);
				// const maxCdSubgrupo = Math.max(...cdsubgrupos);
				// let currentCdSubgrupo = maxCdSubgrupo + 1;

				// const body = JSON.stringify({
				// 	descricao: payload.descricao,
				// 	subGrupos: payload.subGrupos.map((subgrupo) => {								
				// 		const cdSubgrupo = subgrupo.cdsubgrupo || currentCdSubgrupo;
				// 		currentCdSubgrupo = cdSubgrupo + 1;
				// 		return {
				// 			descricao: subgrupo.descricao,
				// 			cdsubgrupo: cdSubgrupo,
				// 			ncm: subgrupo.ncm
				// 		};
				// 	}),
				// 	tipo: payload.tipo,
				// 	ncm: payload.ncm
				// });

				if(subGrupos?.forEach) {
					subGrupos.forEach(subg => {
						if(!subg.cdsubgrupo) {
							subg.cdsubgrupo = 0;
						}
					});
				}
				const body = JSON.stringify({
					descricao: payload.descricao,
					subGrupos: subGrupos,
					tipo: payload.tipo,
					ncm: payload.ncm
				});
				let url = `grupoProduto?id=${payload.cdGrupo}`;
				const data = await doRequest(url, {method: "put", body}, commit);
				state.carregando = false;
				if (!data.cdGrupo) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				return data;
			} catch (error) {
				console.log(error);
				state.carregando = false;
				commit("relatarErro", {mensagem: error.mensagem || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
				return null;
			}
		},

		async deletarGrupoProdutos({state, commit}, payload){
			try {
				let url = `grupoProduto/excluirGrupo?cdgrupo=${payload.cdGrupo}`;
				state.carregando = true;
				const data = await doRequest(url, {method: "delete", cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.message == "Server Error") {
					return commit("relatarErro", {mensagem: JSON.stringify(data.details)});
				}
				if (data.mensagem != "Grupo Excluido com sucesso!") {
					return commit("relatarErro", {mensagem: data.mensagem});
				}
			} catch (error) {
				commit("relatarErro", {mensagem: JSON.stringify(error)});
				console.log(error);
			}
		},

		async filtrarGrupos({commit}, payload){
			try {
				let url;
				url = `grupoProduto/filter?${payload.query ? `query=${payload.query}&` : ""}${payload.cdGrupo ? `cdGrupo=${payload.cdGrupo}&` : ""}${payload.descricaoGrupo ? `descricaoGrupo=${payload.descricaoGrupo}&` : ""}page=${payload.page || 0}&size=25&sort=descricao&sortOrder=asc`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.content) {
					return commit("atualizarListaGrupoProdutos",data);
				} else {
					return commit("atualizarListaGrupoProdutos", {
						content: [],
						page: -1,
						totalPages: 0,
					});
				}
			} catch (error) {
				commit("relatarErro", {mensagem: JSON.stringify(error)});
				console.log(error);
				return commit("atualizarListaGrupoProdutos", {
					content: [],
					page: -1,
					totalPages: 0,
				});
			}
		},
		async buscarUnicoGrupo({commit},payload) {
			try {
				let url = `grupoProduto?id=${payload}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.cdGrupo) {
					commit("selecionarUnicoGrupo", data);
					return data;
				} else {
					commit("selecionarUnicoGrupo", null);
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
		},
		async deletarSubGrupo({state, commit, dispatch}, payload) {
			try {
				let url = `grupoProduto/excluirSubGrupo?cdgrupo=${payload.cdGrupo}&cdsubgrupo=${payload.cdsubgrupo}`;
				state.carregando = true;
				const data = await doRequest(url, {method: "delete", cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.mensagem) {
					await dispatch("buscarGrupos");
					return(data);
				}
			} catch (error) {
				commit("relatarErro", {mensagem: JSON.stringify(error)});
				console.log(error);
			}   
		},
	},

	mutations: {
		atualizarListaGrupoProdutos(state, payload) {
			state.grupoProduto = [];
			state.grupoProduto = payload.content;
			state.paginaGruposAtual = payload.page;
			state.totalPaginasGrupos = payload.totalPages;
		},
		selecionarUnicoGrupo(state, payload) {
			state.grupoSelecionado = null;
			state.grupoSelecionado = payload;
		}
	},
    
};

