import { render, staticRenderFns } from "./ConsultaVendasTroca.vue?vue&type=template&id=7ebc0aa2&scoped=true"
import script from "./ConsultaVendasTroca.vue?vue&type=script&lang=js"
export * from "./ConsultaVendasTroca.vue?vue&type=script&lang=js"
import style0 from "./ConsultaVendasTroca.vue?vue&type=style&index=0&id=7ebc0aa2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ebc0aa2",
  null
  
)

export default component.exports