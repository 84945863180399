import {doRequest} from "./http.js";
export default {
	state: {
		Promocoes: [],
		paginaPromocaoAtual: -1,
		totalPaginasPromocao: 0,
		PromocaoUnica: null
	},

	actions: {
		async adicionarPromocao({state ,commit}, payload){
			let cadastrou = false;
			try {
				const body = JSON.stringify({
					cdempresa: state.Empresa.id,
					datafinal: payload.datafinal,
					datainicio: payload.datainicio,
					descontoprazo: Number(`${payload.descontoprazo}`.split(".").join("").replace(",", ".") || 0),
					descontovista: Number(`${payload.descontovista}`.split(".").join("").replace(",", ".") || 0),
					nome: `${payload.nome}`.toUpperCase(),
					produtos: payload.produtos,
					todosProdutos: payload.todosProdutos,
					domingo: payload.domingo,
					segunda: payload.segunda,
					terca: payload.terca,
					quarta: payload.quarta,
					quinta: payload.quinta,
					sexta: payload.sexta,
					sabado: payload.sabado,
					pagamentos: payload.pagamentos,
				});
				let url = "promocao";
				state.carregando = true;
				const data = await doRequest(url, {method: "post", body}, commit);
				state.carregando = false;
				if (!data.cdpromocao) {
					commit("relatarErro", {mensagem: data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data))});
				}else{
					cadastrou = true;
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
			}
			return cadastrou;
		},

		async editarPromocao({state ,commit}, payload){
			try {
				state.carregando = true;
				const body = JSON.stringify({
					cdempresa: state.Empresa.id,
					datafinal: payload.datafinal,
					datainicio: payload.datainicio,
					descontoprazo: Number(`${payload.descontoprazo}`.split(".").join("").replace(",", ".") || 0),
					descontovista: Number(`${payload.descontovista}`.split(".").join("").replace(",", ".") || 0),
					nome: `${payload.nome}`.toUpperCase(),
					produtos: payload.produtos,
					todosProdutos: payload.todosProdutos,
					domingo: payload.domingo ? payload.domingo : false,
					segunda: payload.segunda ? payload.segunda : false,
					terca: payload.terca ? payload.terca : false,
					quarta: payload.quarta ? payload.quarta : false,
					quinta: payload.quinta ? payload.quinta : false,
					sexta: payload.sexta ? payload.sexta : false,
					sabado: payload.sabado ? payload.sabado : false,
					pagamentos: payload.pagamentos,
				});
				let url = `promocao?id=${payload.id}`;
				const data = await doRequest(url, {method: "put", body}, commit);
				state.carregando = false;
				if (!data.cdpromocao) {
					commit("relatarErro", {mensagem: data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data))});
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
			}
		},

		async filtrarPromocao({state ,commit}, payload){
			try {
				let url = `promocao/filter?empresa=${state.Empresa.id}`;
				url += `${payload.campoFiltro == "status" && payload.status == "A" ? "" : `&dataFim=${payload.datafinal}&dataInicio=${payload.datainicio}`}`;
				payload.campoFiltro == "status" ? url : url += `&campoFiltro=${payload.campoFiltro}`;
				url += `${payload.nome ? `&nome=${payload.nome}` : "" }`;
				url += `&page=${payload.page || 0}`;
				url += "&size=25";
				url += "&sort=nome";
				url += "&sortOrder=asc";
				url += `${payload.campoFiltro != "status" ? "" : `&status=${payload.status}`}`;
				state.carregando = true;	
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.content) {
					commit("atualizarListaPromocoes", data);
				} else {
					commit("atualizarListaPromocoes", {
						content: [],
						page: -1,
						totalPages: 0
					});
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
			}
		},

		async buscarPromocaoPorId({state ,commit}, payload){
			try {
				state.carregando = true;
				if (!payload.cdpromocao) {
					throw new Error("Falta o atributo cdpromocao!");
				}
				let url = `promocao?id=${payload.cdpromocao}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.cdpromocao) {
					commit("setPromocaoUnica", data);
					return data;
				} else {
					commit("setPromocaoUnica", null);
					return null;
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
		},

		async validaPromocao({state ,commit}, payload){
			try {
				let url = "promocao/validaProduto?";
				url += `dataInicio=${payload.datainicio}`;
				url += `&dataFim=${payload.datafinal}`;
				url += `&empresa=${state.Empresa.id}`;
				url += `&id=${payload.id}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				return data;
			} catch (error) {
				console.log(error);
			}
		},

		async cancelarPromocao({state ,commit}, payload){
			try {
				state.carregando = true;
				commit("relatarErro", {mensagem: `${payload}`});
				const url = `promocao?id=${payload.cdpromocao}`;
				const data = await doRequest(url, {method: "delete", cache: "no-cache"}, commit);
				if (!data) throw new Error("Erro ao cancelar promoção!");
				if (data.details) {
					throw new Error(JSON.stringify(data.details));
				}
				if (data.mensagem ? !data.mensagem.includes("sucesso") : false) {
					throw new Error(data.mensagem);
				}
				state.carregando = false;
				return true;
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
				return null;
			}
		},
	},

	mutations: {
		atualizarListaPromocoes(state, payload) {
			state.Promocoes = [];
			state.Promocoes = payload.content,
			state.paginaPromocaoAtual = payload.page;
			state.totalPaginasPromocao = payload.totalPages;
		},

		setPromocaoUnica(state, payload) {
			state.PromocaoUnica = payload;
		}
	},
};