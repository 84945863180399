<template>
	<div id="gridPrincipalVendas">
		<div class="filtro-flutuante" @click="fecharPainelFiltro">
			<div class="filtro-flutuante-scroll">
				<div class="input-group" style="margin-bottom: 10px;">
					<input type="date" id="dataI" class="w-100" style="margin-top:2px" v-model="dataI" @keydown.enter="proximoCampo('dataF')">
					<label for="dataI" class="text-right m-2">Início</label>
				</div>
				<div class="input-group" style="margin-bottom: 5px;">
					<input type="date" id="dataF" class="w-100" style="margin-top:2px" v-model="dataF" @keydown.enter="proximoCampo('textoPesquisaVenda')">
					<label for="dataF" class="text-right m-2">Fim</label>
				</div>
				<div class="input-group" style="margin-bottom: 5px;">
					<select class="my-5 w-100" v-model="campoPesquisa" @select="selecionaCampoPesquisa">
						<option value="cdVenda">Código da venda</option>
						<option value="cliente">Cliente</option>
						<option value="descricao">Descrição do produto</option>
						<option value="cdProduto">Código do produto</option>
						<option value="codbarra">Código de barras do produto</option>
						<option value="referencia">Referência do produto</option>
					</select>
				</div>
				<div class="input-group" style="margin-bottom: 5px;">
					<input type="text" id="textoPesquisaVenda" class="my-5 w-100" v-model="textoPesquisa" autocomplete="off"
						@keydown.enter="filtrarVendas" placeholder="O que está procurando?">
				</div>
				<button id="pesquisarVendas" class="botao botao-acao" @click="filtrarVendas" style="width: 100%">
					<i class="fas fa-search" for="pesquisarVendas"></i>
					<label for="pesquisarVendas">Pesquisar</label>
				</button>
				<div class="row between my-5">
					<input type="radio" id="todas-filtro" class="pointer mx-5" name="tipo-venda-filtro" value=""
						v-model="cancelada" @click="filtrarVendas">
					<label for="todas-filtro" class="w-100 pointer">Todas</label>
				</div>
				<div class="row between my-5">
					<input type="radio" id="normais-filtro" class="pointer mx-5" name="tipo-venda-filtro" value="N"
						v-model="cancelada" @click="filtrarVendas">
					<label for="normais-filtro" class="w-100 pointer">Normais</label>
				</div>
				<div class="row between my-5">
					<input type="radio" id="canceladas-filtro" class="pointer mx-5" name="tipo-venda-filtro" value="S"
						v-model="cancelada" @click="filtrarVendas">
					<label for="canceladas-filtro" class="w-100 pointer">Canceladas</label>
				</div>
				<div class="row between my-5">
					<input type="radio" id="vendas-filtro" class="pointer mx-5" name="condicional-filtro" value="N"
						v-model="mostrarCondicional" @click="filtrarVendas">
					<label for="vendas-filtro" class="w-100 pointer">Vendas</label>
				</div>
				<div class="row between my-5">
					<input type="radio" id="condicionais-filtro" class="pointer mx-5" name="condicional-filtro"
						value="S" v-model="mostrarCondicional" @click="filtrarVendas">
					<label for="condicionais-filtro" class="w-100 pointer">Condicionais</label>
				</div>
				<div class="row between my-5">
					<input type="radio" id="enviada-filtro" class="pointer mx-5" name="enviada-filtro" value="S"
						v-model="enviada" @click="filtrarVendas">
					<label for="enviada-filtro" class="w-100 pointer">Com NFC-e</label>
				</div>
				<div class="row between my-5">
					<input type="radio" id="nao-enviada-filtro" class="pointer mx-5" name="enviada-filtro" value="N"
						v-model="enviada" @click="filtrarVendas">
					<label for="nao-enviada-filtro" class="w-100 pointer">Sem NFC-e</label>
				</div>
				<div class="input-group" style="margin-bottom: 5px;">
					<select v-model="filtro[0].texto" class="my-5 w-100">
						<option value="">Método de pagamento</option>
						<option :value="metodo.cdtipo" v-for="metodo in formasPagamento" :key="metodo.cdtipo">
							{{ metodo.descricao }}</option>
					</select>
				</div>
				<div class="input-group">
					<select v-model="filtro[1].texto" class="my-5 w-100">
						<option value="">Vendedor</option>
						<option :value="vendedor.id" v-for="vendedor in Vendedores" :key="vendedor.id">{{ vendedor.nome }}
						</option>
					</select>
				</div>
			</div>
		</div>
		<div id="painel-controle"
			v-if="Usuario.supervisor || (Usuario.caixa && Usuario.caixaConsultaVendas) || Usuario.usuarioPrivilegiado || Usuario.caixaConsultaVendas">
			<div class="filtros col">
				<div class="row w-100 primeiro-filtro" style="margin-bottom: 5px;">
					<div id="datas" class="row mx-5">
						<div class="input-group">
							<input type="date" id="dataI" v-model="dataI" style="margin-top:2px" @keydown.enter="proximoCampo('dataFSupervisor')" @blur="saiuData">
							<label for="dataI" class="text-right m-2">Início</label>
						</div>
						<div class="input-group">
							<input type="date" id="dataFSupervisor" v-model="dataF" style="margin-top:2px" @keydown.enter="proximoCampo('textoPesquisaVendaSupervisor')" @blur="saiuData">
							<label for="dataF" class="text-right m-2">Fim</label>
						</div>
					</div>
					<div class="input-group" style="min-width: 310px;">
						<select id="campoPesquisa" v-model="campoPesquisa"
							@change="selecionaCampoPesquisa">
							<option value="cdVenda">Código da venda</option>
							<option value="cliente">Cliente</option>
							<option value="descricao">Descrição do produto</option>
							<option value="cdProduto">Código do produto</option>
							<option value="codbarra">Código de barras do produto</option>
							<option value="referencia">Referência do produto</option>
						</select>
					</div>
					<!-- <select id="ordem" class="mx-5 d-flex w-50">
						<option value="dataEmissao">Data de emissão</option>
						<option value="cdVenda">Código</option>
						<option value="valorBruto">Valor bruto</option>
					</select> -->
					<div class="input-group" style="width: 100%;">
						<input type="search" id="textoPesquisaVendaSupervisor" class="mx-5 w-100" v-model="textoPesquisa"
							autocomplete="off" @keydown.enter="filtrarVendas"
							placeholder="O que está procurando?">
					</div>
					<button id="pesquisarVendas" class="botao botao-acao" @click="filtrarVendas">
						<i class="fas fa-search" for="pesquisarVendas"></i>
						<label for="pesquisarVendas">Pesquisar</label>
					</button>
				</div>
				<div class="row around w-100 segundo-filtro">
					<div class="row">
						<div class="row h-100">
							<div class="row m-2 ">
								<input type="radio" id="todas" class="pointer m-5" name="formaPagamento" value=""
									v-model="cancelada" @click="filtrarVendas">
								<label for="todas" class="w-100 pointer">Todas</label>
							</div>
							<div class="row m-2 ">
								<input type="radio" id="normais" class="pointer m-5" name="formaPagamento" value="N"
									v-model="cancelada" @click="filtrarVendas">
								<label for="normais" class="w-100 pointer">Normais</label>
							</div>
							<div class="row m-2 ">
								<input type="radio" id="canceladas" class="pointer m-5" name="formaPagamento" value="S"
									v-model="cancelada" @click="filtrarVendas">
								<label for="canceladas" class="w-100 pointer">Canceladas</label>
							</div>
						</div>
						<div class="row h-100">
							<div class="row m-2 ">
								<input type="radio" id="vendas" class="pointer m-5" name="mostrarCondicional" value="N"
									v-model="mostrarCondicional" @click="filtrarVendas">
								<label for="vendas" class="w-100 pointer">Vendas</label>
							</div>
							<div class="row m-2 ">
								<input type="radio" id="condicionais" class="pointer m-5" name="mostrarCondicional"
									value="S" v-model="mostrarCondicional" @click="filtrarVendas">
								<label for="condicionais" class="w-100 pointer">Condicionais</label>
							</div>
						</div>
					</div>
					<div class="row around w-100">
						<div class="row h-100">
							<div class="row between m-2">
								<input type="radio" id="enviada" class="pointer m-5" name="enviada" value="S"
									v-model="enviada" @click="filtrarVendas">
								<label for="enviada" class="w-100 pointer">Com NFC-e</label>
								<i class="fas fa-file-export enviar-notas-icone"
									v-if="ListaVendas.content.length && enviada == 'S'" @click="solicitarEnvioNotas"></i>
								<Modal v-if="enviarNotas" v-on:fecharModal="cancelarEnvioNotas"
									:dimensoes="{ width: 'auto', height: 'auto' }">
									<div class="envio-notas">
										<div class="envio-notas-titulo">
											<h4>No período de {{ (new Date(`${`${dataI}`.split("-") || new
													Date()}`).toLocaleDateString("pt-BR"))
											}} até {{ (new Date(`${`${dataF}`.split("-") || new Date()}`).toLocaleDateString("pt-BR")) }}.</h4>
											<h5>Você possuí {{ ListaVendas.totalItens }} notas fiscais!</h5>
										</div>

										<div class="aba-controle">
											<div class="aba aba-selecionada" @click="mudarAba(0)">Download</div>
											<!-- <div class="aba" @click="mudarAba(1)">Email</div> -->
										</div>

										<div class="envio-notas-conteudo" v-if="tipoEnvioNotas == 'Download'">
											<div class="envio-notas-opcao">
												<input type="checkbox" id="gerarPdf" v-model="gerarPdf" :value="true">
												<label for="gerarPdf">Gerar PDF</label>
											</div>
											<div class="envio-notas-opcao">
												<input type="checkbox" id="gerarXml" v-model="gerarXml" :value="true">
												<label for="gerarXml">Gerar XML</label>
											</div>
											<div class="envio-notas-item">
												<button class="btn btn-cancelar w-40" @click="cancelarEnvioNotas"><i
														class="fas fa-times"></i></button>
												<button class="btn btn-confirmar w-40" @click="downloadNotas"><i
														class="fas fa-download"></i></button>
											</div>
										</div>
										<div class="envio-notas-conteudo" v-else-if="tipoEnvioNotas == 'Email'">
											<div class="envio-notas-opcao">
												<input type="checkbox" id="gerarPdf" v-model="gerarPdf" :value="true">
												<label for="gerarPdf">Gerar PDF</label>
											</div>
											<div class="envio-notas-opcao">
												<input type="checkbox" id="gerarXml" v-model="gerarXml" :value="true">
												<label for="gerarXml">Gerar XML</label>
											</div>
											<div class="envio-notas-item">
												<label for="destinatario">Destinatário:&nbsp;</label>
												<input type="email" id="destinatario" class="w-100">
											</div>
											<div class="envio-notas-item">
												<label for="assunto">Assunto:&nbsp;</label>
												<input type="text" id="assunto" class="w-100">
											</div>
											<div class="envio-notas-item">
												<button class="btn btn-cancelar w-40" @click="cancelarEnvioNotas"><i
														class="fas fa-times"></i></button>
												<button class="btn btn-confirmar w-40" @click="downloadNotas"><i
														class="fas fa-paper-plane"></i></button>
											</div>
										</div>
									</div>
								</Modal>
							</div>
							<div class="row between m-2">
								<input type="radio" id="nao-enviada" class="pointer m-5" name="enviada" value="N"
									v-model="enviada" @click="filtrarVendas">
								<label for="nao-enviada" class="w-100 pointer">Sem NFC-e</label>
							</div>
						</div>
						<div class="input-group">
							<select v-model="filtro[0].texto" class="mx-2">
								<option value="">Método de pagamento</option>
								<option :value="metodo.cdtipo" v-for="metodo in formasPagamento" :key="metodo.cdtipo">
									{{ metodo.descricao }}</option>
							</select>
						</div>
						<div class="input-group">
							<select v-model="filtro[1].texto" class="mx-2">
								<option value="">Vendedor</option>
								<option :value="vendedor.id" v-for="vendedor in Vendedores" :key="vendedor.id">
									{{ vendedor.nome }}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<span class="filter-icon">
				<i class="fa fa-angle-right" @click="abrirPainelFiltro"></i>
				<span v-if="!mostrarFiltro" @click="abrirPainelFiltro">Mostrar filtros.</span>
				<span v-else @click="abrirPainelFiltro">Esconder.</span>
			</span>
		</div>
		<div id="painel-controle" v-else>
			<div class="row w-100">
				<div class="input-group w-100">
					<input 
						type="search"
						class="mx-5 w-80"
						autocomplete="off"
						@input="filtrarState"
						id="textoPesquisaVendaElse"
						v-model="textoPesquisa"
						placeholder=" "
					>
					<label for="nomeCli" class="text-right m-2">Nome do Cliente:</label>
				</div>
				<div class="input-group" v-if="campoPesquisa == 'periodo'">
					<input type="date" id="dataI" v-model="dataI" @keydown.enter="proximoCampo('dataFElse')">
					<label for="dataI" class="text-right m-2">Início:</label>
				</div>
				<div class="input-group" v-if="campoPesquisa == 'periodo'">
					<input type="date" id="dataFElse" v-model="dataF" @keydown.enter="proximoCampo('textoPesquisaVendaElse')">
					<label for="dataF" class="text-right m-2">Fim:</label>
				</div>
			</div>
			<div class="row w-100">
				<div class="input-group">
					<select id="vendedor" v-model="filtro[1].texto" class="my-5 w-100" :disabled="!Usuario.caixaConsultaVendas" >
						<option value="">Vendedor</option>
						<option :value="vendedor.id" v-for="vendedor in Vendedores" :key="vendedor.id">{{ vendedor.nome }}
						</option>
					</select>
					<label for="vendedor">Vendedor</label>
				</div>
				<button id="pesquisarVendas" class="botao botao-acao" style="width:165px" @click="filtrarVendas">
					<i class="fas fa-search" for="pesquisarVendas"></i>
					<label for="pesquisarVendas">Pesquisar</label>
				</button>
			</div>
		</div>
		<div id="conteudo">
			<ListaVendas
				class="lista"
				titulo="Vendas filtradas"
				:ListaVendas="ListaVendas" 
				v-on:atualizarLista="filtrarVendas"
				v-on:selecionarItem="selecionarVenda"
				:pgtoSelecionado="pagamentoSelecionado"
			/>
		</div>

		<Modal 
			v-if="vendaSelecionada" 
			@fecharModal="vendaSelecionada = null"
			:dimensoes="{ width: '80%', height: 'auto' }"
			:idModal="'pre-impressao'"
		>
			<PreImpressao :vendaFinalizada="vendaSelecionada" v-on:fecharModal="vendaSelecionada = null"
				v-on:receberVenda="receberVenda" :permiteCancelar="true" v-on:alterarVenda="alterarVenda"
				v-on:vendaCancelada="filtrarVendas" v-on:copiarVenda="copiarVenda"/>
		</Modal>

		<Modal v-if="listarClientes" v-on:fecharModal="listarClientes = false">
			<consulta-cliente  
				@selecionarPessoa="selecionarCliente" 
			/>
			<!-- <Paginacao tipoConteudo="Clientes" :desabilitarNovo="true">
				<TabelaPessoas tipoConteudo="Clientes" v-on:selecionarPessoa="selecionarCliente" />
			</Paginacao> -->
		</Modal>
		<Modal v-if="solicitaAbrirCaixa" :dimensoes="{width: 'auto', heigth: 'auto'}" v-on:fecharModal="solicitaAbrirCaixa = false">
			<AberturaCaixa v-on:caixaAberto="solicitaAbrirCaixa = false" :caixa="Caixa"></AberturaCaixa>
		</Modal>
		<i id="btn-relatorio" class="fas fa-print" @click="solicitarImpressao" v-if="VendasFiltradas.length > 0"></i>
	</div>
</template>

<script>
import ListaVendas from "../components/ListaVendas.vue";
// import TabelaPessoas from "../components/TabelaPessoas.vue";
import Modal from "../components/Modal.vue";
// import Paginacao from "../components/Paginacao.vue";
import PreImpressao from "../components/PreImpressao.vue";
import { mapState } from "vuex";
import AberturaCaixa from "../components/AberturaCaixa.vue";
import { dateFormatISO } from "../utils/dateformat";
import ConsultaCliente from "../components/ConsultaCliente.vue";

export default {
	name: "ConsultaVendas",

	components: {
		ListaVendas,
		Modal,
		PreImpressao,
		// TabelaPessoas,
		// Paginacao,
		AberturaCaixa,
		ConsultaCliente,
	},

	data() {
		return {
			solicitaAbrirCaixa: false,
			campoPesquisa: "cdVenda",
			textoPesquisa: "",
			filtro: [
				{ texto: "", campo: "tipoPagamento" },
				{ texto: "", campo: "vendedor" },
			],
			dataI: dateFormatISO({date: null, format: "ISOdate"}),
			dataF: dateFormatISO({date: null, format: "ISOdate"}),
			vendaSelecionada: null,
			indexVenda: 0,
			cancelada: "N",
			Size: 50,
			timerFiltro: null,
			mostrarCondicional: "N",
			enviada: "",
			pgtoSelecionado: null,
			mostrarFiltro: false,
			enviarNotas: false,
			tipoEnvioNotas: "Download",
			gerarPdf: true,
			gerarXml: true,
			assunto: "",
			textoMensagem: "",
			listarClientes: false,
			pesquisaPeriodoGrande: false
		};
	},

	computed: {
		...mapState([
			"paginaVendasAtual",
			"totalPaginasVendas",
			"VendasFiltradas",
			"Usuario",
			"ListaVendas"
		]),
		formasPagamento() {
			return this.$store.state.formasPagamento.filter(metodo => metodo.mostravenda == "S" || metodo.mostrarecebimento == "S");
		},
		pagamentoSelecionado() {
			return this.formasPagamento.find(metodo => metodo.cdtipo == this.filtro[0].texto);
		},
		Vendedores() {
			let vendedores = this.$store.state.Vendedores.filter(vendedor => vendedor.ativo == "A");
			if (this.Usuario.caixa && !this.Usuario.caixaConsultaVendas){
				vendedores.push(this.Usuario);
			}
			return vendedores;
		},
		Caixa(){
			return this.$store.state.Caixa.caixa;
		}
	},
	methods: {
		saiuData(){
			if(this.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataI = dateFormatISO({date: null, format: "ISOdate"});
			}
			if(this.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataF = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		proximoCampo(idCampo) {
			return document.getElementById(idCampo).focus();
		},
		selecionarVenda(venda) {
			this.vendaSelecionada = { ...venda };
		},
		selecionarCliente(pessoa) {
			this.textoPesquisa = pessoa.nome;
			this.listarClientes = false;
			this.filtrarVendas({});
		},
		solicitarEnvioNotas() {
			this.enviarNotas = true;
			this.assunto = `Notas fiscais de ${this.$store.state.Empresa.nome} período ${new Date(`${this.dataI}`.split("-")).toLocaleDateString("pt-BR")} á ${new Date(`${this.dataF}`.split("-")).toLocaleDateString("pt-BR")}`;
			this.textoMensagem = `Olá, tudo bem?
			Segue em anexo as notas fiscais da empresa ${this.$store.state.Empresa.nome}. CNPJ: ${this.$store.state.Empresa.cnpj}.
			Período de ${new Date(`${this.dataI}`.split("-")).toLocaleDateString("pt-BR")} á ${new Date(`${this.dataF}`.split("-")).toLocaleDateString("pt-BR")}.
			
			Atenciosamente,
			${this.$store.state.Usuario.nome}.
			
			Favor não responder a este email.
			Enviado de BDS Soluções em Sistemas.`;
		},
		cancelarEnvioNotas() {
			this.mudarAba(0, "Download");
			this.enviarNotas = false;
		},
		mudarAba(index, mostrarAba) {
			const aba = document.getElementsByClassName("aba")[index];
			const abaSelecionada = document.getElementsByClassName("aba-selecionada")[0];
			if (!aba || !abaSelecionada) return;
			if (aba != abaSelecionada) {
				aba.classList.add(["aba-selecionada"]);
				abaSelecionada.classList.remove(["aba-selecionada"]);
				setTimeout(() => {
					this.tipoEnvioNotas = mostrarAba || aba.innerText;
				}, 30);
			}
		},
		confirmarPolar(item) {
			if(item.filtroPeriodoGrande){
				this.pesquisaPeriodoGrande = true;
				this.filtrarVendas({});				
			}
			if(item.abrirCaixa){
				this.solicitaAbrirCaixa = true;
			}
			if (item.impressao) {
				this.abrirImpressao(true);
			}
		},

		cancelarPolar(item) {
			if (item.impressao) {
				this.abrirImpressao(false);
			}
		},

		solicitarImpressao() {
			this.$store.commit("relatarErro", {
				mensagem: "Deseja imprimir o relatório completo?",
				tipo: "polar",
				item: {
					impressao: true,
					route: this.$route.name
				}
			});
		},

		abrirImpressao(completo) {
			this.$store.dispatch("buscarRelatorioVendas", {
				dataf: this.dataF,
				datai: this.dataI,
				mostrarCondicional: this.mostrarCondicional,
				page: 0,
				size: this.Size * this.totalPaginasVendas,
				sort: "dataemissao",
				sortOrder: "asc",
				cancelada: this.cancelada,
				completo,
				enviada: this.enviada,
				[this.campoPesquisa]: this.textoPesquisa,
				[this.filtro[0].campo]: this.filtro[0].texto,
				[this.filtro[1].campo]: this.filtro[1].texto,
			});
		},

		filtrarVendas({ size, page, target }) {
			var meses;
			meses = (new Date(this.dataF).getFullYear() - new Date(this.dataI).getFullYear()) * 12;
			meses -= new Date(this.dataI).getMonth();
			meses += new Date(this.dataF).getMonth();			
			if (size) {
				this.Size = size;
			}
			if (target) {
				if (target.value == this.enviada) {
					this.enviada = "";
				}
			}
			clearTimeout(this.timerFiltro);
			this.timerFiltro = setTimeout( async () => {
				if (this.campoPesquisa == "cdVenda" && this.textoPesquisa.includes("F")) {
					this.textoPesquisa = this.textoPesquisa.split("F")[1];
				}
				let filtro = {};
				if (this.campoPesquisa != "cdVenda" || this.textoPesquisa == "") {
					filtro.dataI = this.dataI;
					filtro.dataF = this.dataF;
				}
				filtro[this.filtro[0].campo] = this.filtro[0].texto;
				filtro[this.filtro[1].campo] = this.filtro[1].texto;
				filtro[this.campoPesquisa] = this.textoPesquisa;
				filtro.cancelada = this.cancelada;
				filtro.mostrarCondicional = this.mostrarCondicional;
				filtro.enviada = this.enviada;
				filtro.size = this.Size;
				filtro.page = page;
				if(meses > 5 && !this.pesquisaPeriodoGrande){
					this.$store.commit("relatarErro", { 
						mensagem: "A consulta para o periodo escolhido pode demorar, deseja continuar?", 
						tipo: "polar", 
						item: {
							filtroPeriodoGrande: true
						}
					});
					return;
				}
				await this.$store.dispatch("filtrarVendas", filtro);
			}, 450);
		},
		receberVenda(venda) {
			const condicional = venda.itens.find(item => item.cdTipo == "C");
			this.$store.state.Venda = null;
			this.$store.state.Venda = {
				cliente: venda.cliente,
				vendedor: venda.vendedor,
				cdVenda: venda.cdVenda,
				nomeCliente: venda.nomeCliente,
				foneCliente: venda.foneCliente || venda.telefoneCliente,
				observacao: venda.observ1,
				cdEmpresa: venda.cdEmpresa,
				enderecoCliente: venda.enderecoCliente
			};
			if (!condicional) {
				this.$store.state.itensVenda = [];
				this.$store.state.itensVenda = [...venda.itens];
				this.$store.state.Venda.itens = [...venda.itens];
				this.$router.push({ name: "Fechamento" });
			} else {
				this.$store.state.itensVenda = [];
				venda.itens.map(item => {
					item.cdTipo = "S";
				});
				this.$store.state.itensVenda = [...venda.itens];
				this.$router.push({ name: "Lancamento", query: { tipoVenda: "S", baixarCondicional: true } });
			}
		},
		alterarVenda(venda) {
			if (!this.verificarCaixaAberto()) return;
			this.$store.state.Venda = null;
			this.$store.state.Venda = {
				cliente: venda.cliente,
				vendedor: venda.vendedor,
				cdVenda: venda.cdVenda,
				dataEmissao: venda.dataEmissao,
				dataEntradaSaida: venda.dataEntradaSaida,
				cdUsuarioLiberacao: venda.cdUsuarioLiberacao,
				cdUsuarioCaixa: venda.cdUsuarioCaixa,
				descontoMoeda: venda.descontoMoeda,
				descontoPercentual: venda.descontoPercentual,
				observacao: venda.observacao,
				nomeCliente: venda.nomeCliente,
				foneCliente: venda.foneCliente,
				enderecoCliente: venda.enderecoCliente,
				bairroCliente: venda.bairroCliente,
				cepCliente: venda.cepCliente,
				cidadeCliente: venda.cidadeCliente,
				cnpjCliente: venda.cnpjCliente,
				ieCliente: venda.ieCliente,
				ufCliente: venda.ufCliente,
				nascimentoCliente: venda.nascimentoCliente,
				numeroCliente: venda.numeroCliente,
				identificador: venda.identificador,
				financeiro: venda.financeiro
			};
			this.$store.state.itensVenda = [];
			venda.itens.map(item => {
				item.vendaOrigem = venda.cdVenda;
				item.dataOrigem = venda.dataEmissao;
				if (item.cdpromocao && item.produto) {
					if (item.produto.promocaoPrazo || item.produto.promocaoVista) {
						item.promocaoPrazo = item.produto.promocaoPrazo;
						item.promocaoVista = item.produto.promocaoVista;
					}
				}
			});
			this.$store.state.itensVenda = [...venda.itens];
			this.$router.push({
				name: "Lancamento", query: {
					tipoVenda: venda.valorCondicional > 0 ? "C" : "S",
					alteracao: true,
					baixarCondicional: venda.valorCondicional > 0
				}
			});
		},
		async copiarVenda(venda){
			if (!this.verificarCaixaAberto()) return;
			this.$store.state.Venda = null;
			this.$store.state.Venda = {
				cliente: venda.cliente,
				vendedor: venda.vendedor,
				dataEntradaSaida: venda.dataEntradaSaida,
				cdUsuarioLiberacao: venda.cdUsuarioLiberacao,
				cdUsuarioCaixa: venda.cdUsuarioCaixa,
				descontoMoeda: venda.descontoMoeda,
				descontoPercentual: venda.descontoPercentual,
				observacao: venda.observacao,
				nomeCliente: venda.nomeCliente,
				foneCliente: venda.foneCliente,
				enderecoCliente: venda.enderecoCliente,
				bairroCliente: venda.bairroCliente,
				cepCliente: venda.cepCliente,
				cidadeCliente: venda.cidadeCliente,
				cnpjCliente: venda.cnpjCliente,
				ieCliente: venda.ieCliente,
				ufCliente: venda.ufCliente,
				nascimentoCliente: venda.nascimentoCliente,
				numeroCliente: venda.numeroCliente,
				identificador: venda.identificador,
				financeiro: []
			};
			this.$store.state.itensVenda = [];
			venda.itens.map(async item => {
				await this.$store.dispatch("selecionarProdutoPorEmpresa", {id: item.id});
				item.dataOrigem = venda.dataEmissao;
				const produto = this.$store.state.produtoSelecionado;
				if (produto.promocaoPrazo || produto.promocaoVista) {
					item.promocaoPrazo = produto.promocaoPrazo;
					item.promocaoVista = produto.promocaoVista;
					item.valorPromocaoPrazo = produto.valorPromocaoPrazo;
					item.valorPromocaoVista = produto.valorPromocaoVista;
					item.cdpromocao = produto.cdpromocao;
					item.valorUnitario = produto.valorPromocaoPrazo;
				}
				this.$store.state.produtoSelecionado = null;
				item.situacao = "S";
			});
			this.$store.state.itensVenda = [...venda.itens];
			this.$router.push({
				name: "Lancamento", query: {
					tipoVenda: venda.valorCondicional > 0 ? "C" : "S",
					baixarCondicional: venda.valorCondicional > 0,
				}
			});
		},
		filtrarState() {
			this.$store.commit("filtrarVendas", { textoPesquisa: this.textoPesquisa });
		},

		selecionaCampoPesquisa() {
			this.textoPesquisa = "";
			if (this.campoPesquisa == "cliente") {
				return this.listarClientes = true;
			}
			setTimeout(() => {
				document.getElementById("textoPesquisaVenda").focus();
			}, 30);
		},

		abrirPainelFiltro() {
			this.mostrarFiltro = !this.mostrarFiltro;
			document.querySelector("div.filtro-flutuante").classList.toggle("slide-right");
			document.querySelector("span.filter-icon").classList.toggle("slide");
		},
		fecharPainelFiltro(e) {
			if (e.x > 240.5 && this.mostrarFiltro) {
				this.abrirPainelFiltro();
			}
		},
		verificarCaixaAberto() {
			if (this.$store.state.Caixa.caixa.aberto == "N") {
				this.$store.commit("relatarErro", { 
					mensagem: "O caixa está fechado, deseja abrir o caixa para iniciar as movimentações?", 
					tipo: "polar", 
					item: {
						abrirCaixa: true
					}
				});
				return false;
			} else {
				return true;
			}
		},
		downloadNotas() {
			this.$store.dispatch("compactarNotas", {
				dataf: this.dataF,
				datai: this.dataI,
				size: this.Size * this.totalPaginasVendas,
				sort: "dataemissao",
				sortOrder: "asc",
				enviada: this.enviada,
				gerarPdf: this.gerarPdf,
				gerarXml: this.gerarXml,
			});
		}
	},

	beforeMount() {
		this.$store.dispatch("buscarFormasPagamento", {carregando: true});
		this.$store.dispatch("buscarVendedores");
	},

	mounted() {
		if ((!this.$store.state.Usuario.supervisor && !this.$store.state.Usuario.caixaConsultaVendas)) {
			this.campoPesquisa = "periodo";
		}
		if (this.$route.params.conferir) {
			this.filtro[0].texto = this.$route.params.conferir;
			this.dataF = this.$route.params.data;
			this.dataI = this.$route.params.data;
		}
		if((!this.Usuario.supervisor || !this.Usuario.usuarioPrivilegiado ) && !this.Usuario.caixaConsultaVendas ){
			this.filtro[1].texto = this.Usuario.id;
		}
		this.filtrarVendas({});
		this.$bus.$on("confirmarPolar", this.confirmarPolar);
		this.$bus.$on("cancelarPolar", this.cancelarPolar);
	},

	destroyed() {
		this.$bus.$off("confirmarPolar", this.confirmarPolar);
		this.$bus.$off("cancelarPolar", this.cancelarPolar);
		this.$store.commit("limparStateListaVendas");
	},
};
</script>

<style scoped>
#pesquisarVendas {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

#pesquisarVendas i {
	color: var(--acao-borda);
}

#pesquisarVendas label {
	cursor: pointer;
}

#btn-relatorio {
	left: 5px;
}

#gridPrincipalVendas {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 100px auto;
	grid-template-areas:
		"painel-controle"
		"conteudo";
	background-color: var(--bg-app);
	padding-top: 5px;
	height: 100%;
	max-height: 92vh;
	/* min-height: 530px; */
	width: 100%;
}

#painel-controle {
	grid-area: painel-controle;
	width: 95%;
	margin: 0px auto;
	display: flex;
	flex-direction: column;
	height: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	justify-content: space-around;
	align-items: center;
	overflow: hidden;
}

#painel-controle-alternativo {
	grid-area: painel-controle;
	width: 95%;
	margin: 0px auto;
	display: flex;
	flex-direction: row;
	height: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	justify-content: space-around;
	align-items: center;
	overflow: hidden;
}

#conteudo {
	grid-area: conteudo;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	margin: 0px auto;
	border-top-left-radius: 3%;
	border-top-right-radius: 3%;
	background-color: var(--bg-secundario);
	overflow: hidden;
}

.filtros {
	width: 100%;
	justify-content: space-around;
	padding: 5px;
}

label {
	font-size: 14px;
}

.lista {
	width: 95%;
	height: 95%;
}

.start {
	justify-content: flex-start;
}

.filter-icon {
	display: none;
}

.enviar-notas-icone {
	color: var(--primario);
	margin: 0px 2px;
}

.envio-notas {
	display: flex;
	flex-direction: column;
	min-width: 300px;
	width: 100%;
	max-width: 768px;
	margin: 0px;
	margin-top: 10px;
}

.envio-notas-titulo {
	display: flex;
	flex-direction: column;
	text-align: left;
	margin: 3px;
	cursor: pointer;
	user-select: none;
}

.aba-controle {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	text-align: center;
	vertical-align: middle;
	margin: 2px 5px 0px 5px;
	padding: 0px 10px;
	z-index: 1;
	height: 35px;
}

.aba {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	font-size: 14pt;
	margin: 0px 2px;
	text-align: center;
	cursor: pointer;
	height: 100%;
	transition-property: all;
	transition-duration: 300ms;
	transition-timing-function: ease-in-out;
	user-select: none;
	width: 100%;
	vertical-align: middle;
}

.aba-selecionada {
	text-align: center;
	height: 100%;
	background-color: var(--bg-app);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	box-shadow: 1px -2px 2px #292929;
	cursor: default;
	width: 100%;
}

.envio-notas-conteudo {
	border-radius: 10px;
	box-shadow: 2px 3px 3px 1px #292929;
	display: flex;
	flex-direction: column;
}

.envio-notas-item {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin: 5px;
	flex-grow: 1;
}

.envio-notas-item button {
	white-space: nowrap;
}

.envio-notas-opcao {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 5px;
	margin-left: 6%;
	align-items: center;
	flex-grow: 1;
}

.envio-notas-opcao input {
	cursor: pointer;
}

.envio-notas-opcao label {
	margin-left: 5px;
	cursor: pointer;
}

.w-100{
	width: 100%;
}

@media screen and (max-width: 1040px) {
	#gridPrincipalVendas {
		grid-template-rows: 110px auto;
		/* min-height: 580px; */
		/* min-width: 650px; */
	}

	.row.around.w-80 {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	#campoPesquisa,
	#ordem,
	#datas {
		width: 80%;
		margin: 2px;
	}

	#datas>div {
		flex-direction: column;
	}

	.segundo-filtro {
		flex-direction: column;
	}
}

@media screen and (max-width: 768px) {
	#gridPrincipalVendas {
		grid-template-rows: 40px auto;
	}

	#painel-controle {
		align-items: flex-start;
	}

	#painel-controle .filtros {
		display: none;
	}

	.filter-icon {
		display: flex;
	}
}
</style>