
export class PrevisaoCompra {
	constructor( compra, id ) {
		this.id = id || null,
		this.nome = compra?.nome || "",
		this.cdcliente = compra?.cdcliente || null;
		this.cdempresa = compra?.cdempresa || null;
		this.dataChegada = compra?.dataChegada || "";
		this.datapedido = compra?.datapedido || compra?.dataPedido || new Date().toLocaleDateString("en-CA");
		this.dataPrevista = compra?.dataPrevista || compra?.dataPrevista || new Date().toLocaleDateString("en-CA");
		this.observacao = compra?.observacao || "";	
		this.previsaoPagamentos = compra?.previsaoPagamentos || [];
		this.quantidade = compra?.quantidade || 0;
		this.valor = compra?.valor || 0;
		this.frequencia = compra?.frequencia || 30;
	}

	stringToNumber(field) {
		if(typeof field === "string"){
			field = field.replace(/\./g, "").replace(",", ".");
			return Number(
				field
			);
		}
		return Number(field);
	}

	converter() {
		const { previsaoPagamentos, ...rest } = this;
		const previsaoPagamentosConvertidos = previsaoPagamentos.map((pagamento) => {
			return new FinanceiroPrevisao(pagamento).converter();
		});
		
		
		this.previsaoPagamentos = previsaoPagamentosConvertidos;
		this.valor = this.stringToNumber(this.valor);
		this.observacao = this.setUpperCase(this.observacao);

		return {
			...rest,
			valor: this.valor,
			observacao: this.observacao,
			previsaoPagamentos: this.previsaoPagamentos
		};
	}

	setUpperCase(value) {
		return typeof value === "string" ? value.toUpperCase() : value;
	}
}

export class FinanceiroPrevisao {
	constructor(previsaoPagamento) {
		this.cdlan = previsaoPagamento?.cdlan || "";
		this.dataemissao = previsaoPagamento?.dataemissao || "";
		this.datavencimento = previsaoPagamento?.datavencimento || "";
		this.id = previsaoPagamento.id || null;
		this.datapedido = previsaoPagamento?.datapedido || "";
		this.valorparcela = previsaoPagamento?.valorparcela || "";
		this.cdTpPagamento = previsaoPagamento?.cdTpPagamento || "";
	}

	stringToNumber(field) {
		if(typeof field === "string"){
			field = field.replace(/\./g, "").replace(",", ".");
			return Number(
				field
			);
		}
		return Number(field);
	}
	
	converter() {
		return {
			dataemissao: this.dataemissao,
			cdTpPagamento: this.cdTpPagamento,
			cdlan: this.cdlan,
			datavencimento: this.datavencimento,
			id: this.id,
			valorparcela: this.stringToNumber(this.valorparcela)
		};
	}




}