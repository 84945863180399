import { doRequest } from "./http";

export default {
	state: {
		Bandeiras: {
			content: [],
			page: 0,
			size: 25,
			totalElements: 0,
			totalPages: -1,
			last: false
		},
		CadastroB: null,
		paginaBandeirasAtual  : -1,
		totalPaginasBandeiras : 0,
	},

	actions: {
		async buscarBandeiras({commit}, payload) {
			try {
				let url = `bandeira/all?size=25${payload?.page ? `&page=${payload.page}` : ""}&sort=adm&sortOrder=asc${payload?.ativo  ? `&ativo=${payload.ativo}` : ""}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.content) {
					commit("atualizarListaBandeiras", data);
				} else {
					commit("atualizarListaBandeiras", {content: []});
				}
			} catch (error) {
				commit("atualizarListaBandeiras", {content: []});
				console.log(error);
				commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
		},
		async buscarUnicaBandeira({commit},payload) {
			try {
				let url = `bandeira?codigo=${payload}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.codigo) {
					commit("atualizarBandeira", data);
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
		},
		async salvarBandeira({commit, dispatch },payload) {
			try {
				const body = JSON.stringify({
					adm: payload.adm,
					txcredito: payload.txcredito,
					txdebito: payload.txdebito,
					codconciliadora: payload.cdConciliadora,
					ativo: payload.ativo
				});
				let url = "bandeira";
				const data = await doRequest(url, {method: "post", cache: "no-cache", body}, commit);
				if (data.codigo) {
					dispatch("buscarBandeiras");
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
		},
		async editarBandeira({commit, dispatch },payload) {
			try {
				const body = JSON.stringify({
					adm: payload.adm,
					txcredito: payload.txcredito,
					txdebito: payload.txdebito,
					codconciliadora: payload.cdConciliadora,
					ativo: payload.ativo
				});
				let url = `bandeira?codigo=${payload.codigo}`;
				const data = await doRequest(url, {method: "put", cache: "no-cache", body}, commit);
				if (data.codigo) {
					dispatch("buscarBandeiras");
				}
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
		},
		async deletarBandeira({commit, dispatch },payload) {
			try {
				let url = `bandeira/excluirBanceira?codigo=${payload.codigo}`;
				const data = await doRequest(url, {method: "delete", cache: "no-cache"}, commit);
				if (data.mensagem == "Não pode excluir, pois existe lançamento no financeiro") {
					return commit("relatarErro", {mensagem: data.mensagem});
				}
				dispatch("buscarBandeiras");
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
		},

		async filtrarBandeiras({commit, state}, payload){
			try {
				state.carregando = true;
				let url = `bandeira/filter?administradora=${payload.todos}${payload?.ativo ? `&ativo=${payload.ativo}` : ""}`;
				const data = await doRequest(url, {method: "get"}, commit);
				state.carregando = false;
				if (data.error) {
					commit("atualizarListaBandeiras", {content: []});
					return commit("relatarErro", {mensagem: data.mensagem || (data.message || JSON.stringify(data))});
				}
				commit("atualizarListaBandeiras", data);
			} catch (error) {
				console.log(error);
				commit("atualizarListaBandeiras", {content: []});
			}
		},
	},

	mutations: {
		atualizarListaBandeiras(state, payload) {
			state.Bandeiras = null;
			state.Bandeiras = {
				content: payload.content,
				page: payload.page,
				size: payload.size,
				totalElements: payload.totalElements,
				totalPages: payload.totalPages,
				last: payload.last,
				anexo: payload.anexo
			};
			state.paginaBandeirasAtual = payload.page;
			state.totalPaginasBandeiras = payload.totalPages;
		},
		atualizarBandeira(state, payload) {
			state.CadastroB = null;
			state.CadastroB = payload;

		},
	}
};
