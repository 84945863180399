<template>
	<ContainerPage>
		<ContentPage>
			
			<Filtro
				@handleShowTable="handleShowTable"
				@filtrar="filtrar"
			>
				<div class="column">
					<div class="row">
						<div class="input-group">
							<input 
								type="date"
								id="dataVencI"
								v-model="Filtro.dataVencI"
								placeholder=" "
							/>
							<label for="dataVencI">
								Data Venc. Início
							</label>
						</div>
						<div class="input-group">
							<input 
								type="date"
								id="dataVencF"
								v-model="Filtro.dataVencF"
								placeholder=" "
							/>
							<label for="dataVencF">
								Data Venc. Final
							</label>
						</div>
						<div class="input-group">
							<select v-model="Filtro.condicao" id="condicao">
								<option value="B">BAIXADAS</option>
								<option value="A">ABERTAS</option>
							</select>
							<label for="condicao">
								Status
							</label>
						</div>
					</div>
				</div>
			</Filtro>
			<Agenda 
				:height="height"
				:Filtro="Filtro"
			/>
		</ContentPage>
	</ContainerPage>
</template>
<script>
import ContainerPage from "../components/ContainerPage.vue";
import Filtro from "../components/Filtro.vue";
import Agenda from "../components/Agenda.vue";
import ContentPage from "../components/ContentPage.vue";
export default {
	name: "AgendaContas",
	components: {
		ContainerPage,
		ContentPage,
		Agenda,
		Filtro
	},
	data() {
		return {
			height: "500px",
			Filtro: {
				dataVencF: new Date().toLocaleDateString("en-CA"),
				dataVencI: new Date(new Date().getFullYear() -1, new Date().getMonth(), new Date().getDate()).toLocaleDateString("en-CA"),
				condicao: "B",
			}
		};
	},
	methods: {
		handleShowTable() {
			if(this.height === "525px") {
				this.height = "628px";
			} else {
				this.height = "525px";
			}
		},

		async filtrar() {
			await this.$store.dispatch("buscarContasPorVencimento", {
				...this.Filtro
			});
		}
	},

	beforeMount() {
		this.$store.dispatch("buscarContasPorVencimento", {
			...this.Filtro
		});
	}
};
</script>
<style scoped>
.row .input-group:focus-within label,
.row .input-group input:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}
.row .input-group:focus-within label,
.row .input-group select:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}
</style>