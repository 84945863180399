<template>
	<div :class="`${autorizacao ? 'modal' : ''} container`">
		<div v-if="autorizacao" class="autorizacao">
			<p>{{ mensagem }}</p>
		</div>
		<div class="autorizacao" v-else-if="$route.query.autorizacao">
			<span>
				{{
						$store.state.Usuario.nome
				}} está solicitando autorização para {{ alertMessage }}.
			</span>
			<span v-if="!$route.params.clienteDevedor && $store.state.Venda">
				O valor do pedido bruto está em {{ ($store.state.Venda.valorBruto || 0).toLocaleString("pt-BR", {
						style:
							"currency", currency: "BRL"
					})
				}}
				e com desconto {{ (($store.state.Venda.valorBruto || 0) - (($store.state.Venda.valorBruto || 0) *
						$route.params.desconto / 100))
						.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
				}}.
			</span>
			<span v-if="$route.params.clienteDevedor && $store.state.Venda">
				O limite do cliente é {{
						($route.params.clienteDevedor.limitecredito || 0).toLocaleString("pt-BR", {
							style: "currency", currency:
								"BRL"
						})
				}} e o valor do pedido está em {{ ($store.state.Venda.valorBruto || 0).toLocaleString("pt-BR", {
		style:
			"currency", currency: "BRL"
	})
}}.
			</span>
			<span v-if="$route.params.clienteDevedor">
				Cliente já utilizou {{ `${($route.params.situacaoCliente.creditoUsado || 0).toLocaleString("pt-BR",
						{ style: "currency", currency: "BRL" })}`
				}}
				de crédito
				{{
						Number(Number($route.params.clienteDevedor.limitecredito || 0) -
							Number($route.params.situacaoCliente.creditoUsado || 0)) > 0 ?
							` e ainda possuí ${Number(Number($route.params.clienteDevedor.limitecredito || 0) -
								Number($route.params.situacaoCliente.creditoUsado || 0))
								.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} disponíveis` : `e não possui crédito
								disponível.`
				}}
			</span>
			<span v-if="$route.params.clienteDevedor">
				{{
						($route.params.situacaoCliente.diasvencido || 0) > 0 ? `Cliente com
								${$route.params.situacaoCliente.diasvencido} dias em atraso.` : ""
				}}
			</span>
		</div>
		<div class="dummy-text p-25">
			<div class="logo">
				<img
					class="w-100"
					id="principalImg"
					src="../assets/logo-transp-tidoc.png"
				/>
			</div>
			<form
				id="login"
				@submit.prevent=""
				autocomplete="off"
			>
				<div class="loginDiv">
					<label for="usuario">Usuário</label>
					<input
						type="text"
						id="usuario"
						v-model="usuario"
						placeholder="Informe seu login"
						@input="setToLowerCase"
						@keydown.enter.prevent="proximoInput('senha')"
						@keydown.tab.prevent="proximoInput('senha')"
						@keydown="verificarCase"
						style="text-transform: lowercase;"
					/>
				</div>

				<div class="loginDiv">
					<label for="senha">Senha</label>
					<input
						type="password"
						id="senha"
						placeholder="Informe sua senha"
						v-model="senha"
						@keydown="verificarCase"
					/>
				</div>

				<div class="botoes">
					<button
						class="botao botao-adicionar"
						id="confirmar"
						type="submit"
						@click="autenticar"
					>
						{{ $route.query.autorizacao || autorizacao ? "Autorizar" : "Entrar" }}
					</button>
					<button
						class="botao botao-cancelar"
						type="reset"
						tabindex="3"
						@click="cancelar"
						v-if="$route.query.autorizacao || autorizacao"
					>
						Cancelar
					</button>
					<button
						class="botao botao-acao"
						id="confirmar"
						type="submit"
						@click="fazerPreVenda"
						v-if="preVenda"
					>
						Pré Venda
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	name: "Autenticacao",

	props: {
		autorizacao: Boolean,
		mensagem: String,
		autorizar: Function,
		negar: Function,
		preVenda: Boolean,
	},

	data() {
		return {
			usuario: "",
			senha: ""
		};
	},

	computed: {
		alertMessage() {
			return this.$route.params.desconto ? `liberar
				desconto de ${this.$route.params.desconto}%`
				: this.$route.params.aumdesc ? `liberar desconto no recebimento de ${(this.$route.params.aumdesc ||
			0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}` :
					this.$route.params.nome ? `estornar a conta de ${this.$route.paramqs.nome} no valor de ` +
				`${(this.$route.params.credito || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}` :
						this.$route.params.clienteDevedor ? `liberar o cliente ${this.$route.params.clienteDevedor.nome}` : "";
		}
	},

	methods: {
		fazerPreVenda(){
			this.$store.dispatch("inserirVendaSemPagamento", {
				...this.$store.state.Venda,
				tipoVenda: this.$route.query.tipoVenda,
				cdIdentificador: 0,
			});
		},
		async autorizarSolicitacao() {
			const autenticado = await this.$store.dispatch("autenticarLiberacao", {
				usuario: this.usuario.toUpperCase(),
				senha: this.senha,
			});
			if (autenticado && this.autorizar) {
				this.autorizar(autenticado);
			}
		},
		async autenticar() {
			if (this.$store.state.carregando) return;
			if (!this.usuario || this.usuario == -1) {
				return this.$store.commit("relatarErro", { mensagem: "Por favor informar seu usuário!" });
			}
			if (this.autorizacao) {
				return this.autorizarSolicitacao();
			}
			if (this.$route.query.autorizacao) {
				return this.$store.dispatch("autorizarSolicitacao", {
					usuario: this.usuario.toUpperCase(),
					senha: this.senha,
					cdempresa: this.empresa,
					autorizado: { ...this.$route.params },
				});
			}
			this.$store.dispatch("autenticarUsuario", {
				usuario: this.usuario.toUpperCase(), 
				senha: this.senha, 
				cdempresa: this.empresa 
			});
			this.senha = "";
		},

		setToLowerCase(event) {
			const id = event.target.id;
			const value = event.target.value;
			if (!/[0-9]/.test(event.data)) {
				const regex = /[~^!#$%¨^&*()_={}+-?;<>'´`|ªº°"/\\]/;
				if (regex.test(value)) {
					return this[id] = this[id].substring(0, value.length);
				}
			}
			this[id] = this[id].toLowerCase();
		},

		cancelar() {
			if (this.autorizacao && this.negar) return this.negar();
			if (this.$route.name == "Autenticacao") {
				return this.$router.replace({
					name: this.$route.params.route,
					query: { ...this.$route.query, autorizacao: false },
					params: { ...this.$route.params }
				});
			}
		},

		proximoInput(id) {
			document.getElementById(id).focus();
		},

		verificarCase(e) {
			if (e.getModifierState("CapsLock")) {
				this.$store.commit("alterarInformativo", { mensagem: "CapsLock ativado!", erro: true, tipo: "erro" });
			} else {
				this.$store.commit("alterarInformativo", null);
			}
		}
	},

	mounted() {
		document.addEventListener("keydown", this.verificarCase);
		document.getElementById("usuario").focus();
	},

	destroyed() {
		document.removeEventListener("keydown", this.verificarCase);
	}
};
</script>

<style scoped >
.botao{
	margin-top: 15px;
	width: 100%;
}
.container{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
.autorizacao {
	display: flex;
	flex-direction: column;
	color: #fff;
	padding: 20px;
	text-shadow: 1px 1px 3px #292929;
}

.dummy-text {
	/*background: var(--bg-primario); */
	background: #f2f2f2;
	border-radius: 6px;
	/* box-shadow: var(--shadow-primario); */
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	width: 300px;
	height: auto;
	text-align: left !important;
}

.loginDiv {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.loginDiv input {
	padding: 5px;
	border-radius: 5px;

	border: 1px solid #cecece;
}

#login {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 15px;
}

#principalImg {
	border-radius: 10px;
	max-width: 250px;
	max-height: 150px;
}
.botoes{
	width: 100%;
	padding: 0px 0px !important;
	display: flex;
	flex-direction: column;
}
</style>