<template>	
	<div :class="`containerTabela ${!showTable ? 'table-full' : ''}`" id="listaModal">
		<table v-if="CreditoClientes.length > 0">
			<thead>
				<tr>
					<th>Cód.</th>
					<th>Cliente</th>
					<th>Valor Total</th>
					<th>Valor Utilizado</th>
					<th>Valor Restante</th>
					<th>Status</th>
					<th>Ação</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(credito, index) in CreditoClientes" :key="index">
					<td style="text-align: center; color: blue;">
						{{ credito.id }}
					</td>
					<td  @click="$emit('editarCreditoCliente', credito)" >
						{{ credito.cliente.id }} - {{ credito.cliente.nome }}
					</td>
					<td style="text-align: right;">
						{{ credito.valor | formatarParaReais }}
					</td>
					<td style="text-align: right;">
						{{ (credito.utilizado || 0) | formatarParaReais }}
					</td>
					<td style="text-align: right;">
						{{ credito.valor-(credito.utilizado || 0) | formatarParaReais }}
					</td>
					<td style="text-align: center;">
						{{ 
							credito.status === "L" ? "Liberado" 
							: credito.status === "A" ? "Aberto" 
							: credito.status === "R" ? "Recebido"
							: "Cancelado"
						}}
					</td>
					<td style="text-align: center;">
						<i class="fas fa-edit" style="color: var(--confirmar)" @click="$emit('editarCreditoCliente', credito)" v-if="credito.status === 'L' || credito.status === 'A' || credito.status === 'R'"></i>
						<i class="fas fa-trash" style="color: red" @click="solicitarParaExcluir(credito)" v-if="credito.status === 'L' || credito.status === 'A' || credito.status === 'R'"></i>
						<i class="fas fa-print" style="color: var(--acao-background)" @click="solicitarImpressao(credito)" v-if="credito.status === 'L' || credito.status === 'A' || credito.status === 'R'"></i>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import formatarParaReais from "../filters/formatarParaMoeda";
export default{
	name: "TabelaCreditoCliente",
	props:{
		showTable: Boolean,
	},	
	computed: {
		CreditoClientes(){
			return this.$store.state.CreditoClientes;
		}
	},
	filters: {
		formatarParaReais
	},	
	mounted(){
		this.$bus.$on("confirmarPolar", this.confirmarPolar);
	},
	destroyed(){
		this.$bus.$off("confirmarPolar", this.confirmarPolar);
	},
	methods:{
		solicitarImpressao(credito) {
			credito.valor = credito.valor.toFixed(2).replace(".", ",");
			this.$store.dispatch("gerarReciboCredito", {
				credito,
			});
		},
		solicitarParaExcluir(credito){
			if(credito.status === "C"){
				return this.$store.commit("relatarErro", {mensagem: "Este crédito ja foi cancelado"});
			}
			return this.$store.commit("relatarErro", {
				tipo: "polar",
				mensagem: "Tem certeza que deseja remover o crédito deste cliente?",
				item: {
					excluir: true,
					id: credito.id
				}
			});
		},
		confirmarPolar(item){
			if(item.excluir){
				this.excluirCredito(item.id);
			}
		},
		async excluirCredito(id){
			const data = await this.$store.dispatch("excluirCreditoCliente", {id: id});
			if(data && !data.erro){
				return this.$store.commit("relatarErro", {mensagem: "Crédito cancelado com sucesso!", sucess: true});
			} else if (data && data.erro) {
				return this.$store.commit("relatarErro", {mensagem: `${data.mensagem}`});
			}
		},
	}
};
</script>
<style scoped>
table{
	width: 100%;
}
#listaModal {
    width: 100%;
    overflow: auto;
    font-size: var(--txt-normal);
    margin: 0px auto;
	height: 65vh !important;
}
#listaModal.table-full{
	height: 85vh !important;
}

@media screen and (max-width: 830px) {
	.filtros {
		position: initial;
	}

	.novo-botao {
		top: unset;
		right: unset;
		bottom: 5px;
		left: 10px;
	}
}

@media screen and (max-width: 768px){
	#listaModal.table-full{
		height: 68vh !important;
	}

	#listaModal{
		height: 43vh !important;
	}
}

@media screen and (max-width: 655px) {
	#gridPrincipalCompras {
		grid-template-rows: 100px auto;
	}

	#painel-controle {
		justify-content: flex-start;
	}

	.filtros {
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.filtro-item {
		justify-content: flex-start;
	}

	.datas {
		width: 100%;
		justify-content: space-between;
	}

	.datas .row.center {
		justify-content: flex-end;
	}

	#campo-filtro-consorcio {
		width: auto;
		flex-grow: 1;
	}
}

@media screen and (max-width: 425px) {
	#campo-filtro-consorcio{
		width: 100%;
	}
	.input-group{
		margin: 5px;
	}
	#gridPrincipalCompras {
		grid-template-rows: 200px auto;
	}
	#listaModal.table-full{
		height: 71vh !important;
	}

	#listaModal{
		height: 30vh !important;
	}
}
</style>