<template>
    <div>
        <table class="w-100">
            <thead>
                <tr>
                    <td style="color: blue; text-align: center;">CFOP.</td>
                    <td style="text-align: left;">Natureza operaçao</td>
                    <!-- <td style="text-align: center;">Tx. Debito</td>
                    <td style="text-align: center;">Tx. Crédito</td> -->
                </tr>
            </thead>
            <tbody>
                <tr v-for="(cfop, index) in Cfops" :key="index" @click="SelecionouCfop(cfop)">
                    <td style="color: blue; text-align: center;">
                        <button :id="index" style="hand-button" v-if="index == indexAtual" @keydown.tab.prevent="" > 
                            <i class="fas fa-hand-point-right" style="color: var(--primario)" >
                            </i>
                        </button>
                        {{cfop.cfop}}
                    </td>
                    <td style="text-align: left;">{{cfop.descricao}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
	name: "TabelaCFOP",

	computed: {
		Cfops() {
			return this.$store.state.Cfops;
		},
	},

	data() {
		return {
			indexAtual: 0
		};
	},

	methods: {
		eventosTeclado(e) {
			if (e.key == "ArrowUp") {
				if (this.indexAtual > 0) {
					this.indexAtual--;
				}
				setTimeout(() => {
					document.getElementById(this.indexAtual).focus();
				}, 10);
			}
			if (e.key == "ArrowDown") {
				if (this.indexAtual < this.Cfops.length -1) {
					this.indexAtual++;
				}
				setTimeout(() => {
					document.getElementById(this.indexAtual).focus();
				}, 10);
			}            
			if (e.key == "Enter") { 
				this.SelecionouCfop(this.Cfops[this.indexAtual]);
			}
		},

		SelecionouCfop(cfop){
			this.$emit("escolheucfop", cfop);
		}

	},

	mounted(){
		this.$store.dispatch("buscarCfop");
		document.addEventListener("keydown",this.eventosTeclado);
	},
	destroyed(){
		document.removeEventListener("keydown",this.eventosTeclado);
	}
};
</script>

<style scoped>

</style>