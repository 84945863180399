<template>
	<div class="container-busca" id="listaBusca">
		<div
			class="linha-busca"
			v-for="(item, index) in Lista"
			:key="index"
			:class="index === indexSelecionado.index ? 'selectedRow' : ''"
			@click="clicked(item)"
		>
			<span v-if="tipoLista === 'Grupos'">
				{{ item.cdGrupo }} - {{ item.descricao }}
			</span>
			<span v-else-if="tipoLista === 'Subgrupos'">
				{{ item.cdsubgrupo }} - {{ item.descricao }}
			</span>
			<span v-else-if="tipoLista == 'Clientes'">
				{{ item.id }} - {{ item.nome }}
			</span>
			<span v-else-if="tipoLista == 'Produtos'" class="flex-span">
				<span>
					{{ item.descricao }} 
				</span>
				<small class="contaienrPromo" v-if="item.cdpromocao">
					{{ item.cdpromocao ? item.nomePromocao : "" }} -{{ item.promocaoVista ? item.promocaoVista : '' }}%
				</small>
			</span>
			<span v-else-if="tipoLista == 'PlanoContas'"
				:class="`${item.alterarValor === 0 ? 'plano-disabled' : ''}`"
			>
				{{ item.nome }}
			</span>
		</div>
	</div>
</template>
<script>
import { selecionarTeclado } from "../utils/utils";
export default {
	name: "ListaBuscas",
	props: {
		tipoLista: String,
		Lista: Array
	},
	data() {
		return {
			indexSelecionado: { ìndex: 0 }
		};
	},
	mounted() {
		window.addEventListener("keydown", this.eventoTeclado);
	},
	destroyed() {
		window.removeEventListener("keydown", this.eventoTeclado);
	},
	methods: {
		clicked(item){
			const clickedBool = this.tipoLista == "Grupos" || this.tipoLista == "Clientes" || this.tipoLista == "Produtos"
				? this.selecionarItem(item)
				: item.alterarValor === 0? "" : this.selecionarElemento(item);
			return clickedBool;
		},
		async eventoTeclado(e) {
			let resultado = selecionarTeclado({
				event: e,
				index: this.indexSelecionado?.index || 0,
				array: this.Lista
			});

			if (resultado && "selecionou" in resultado) {
				this.indexSelecionado = {
					selecionou: resultado.selecionou,
					index: resultado?.index
				};
			} else {
				this.indexSelecionado = {
					selecionou: false,
					index: resultado?.index || 0
				};
			}

			if (this.indexSelecionado.selecionou) {
				if (
					this.tipoLista == "Grupos" ||
					this.tipoLista == "Clientes" || this.tipoLista == "Produtos"
				) {
					await this.selecionarItem(
						this.Lista[this.indexSelecionado?.index || 0]
					);
					this.indexSelecionado = this.indexSelecionado?.index || 0;
					return;
				}
				if (this.tipoLista === "Subgrupos") {
					await this.selecionarElemento(
						this.Lista[this.indexSelecionado?.index || 0]
					);
					this.indexSelecionado = this.indexSelecionado?.index || 0;
				}
			}
		},
		async selecionarElemento(item) {
			await this.$emit("selecionarElemento", item);
		},
		async selecionarItem(item) {
			await this.$emit("selecionarItem", item);
		}
	}
};
</script>
<style>
.plano-disabled {
	color: #f00;
}
.container-busca {
	width: 100%;
	height: auto;
	max-height: 25vh;
	overflow-y: auto;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	background-color: #fff;
	-webkit-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.75);
	position: absolute;
	z-index: 999;
}
.linha-busca {
	padding: 5px 15px;
	text-align: left;
	cursor: pointer;
}
.linha-busca:hover {
	background-color: #cecece;
}
.selectedRow {
	background-color: #cecece;
}

.flex-span{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.contaienrPromo{
	background-color: #cce0ff;
	border: 1px solid #63a0ff;
	border-radius: 10px;
	padding: 5px;
	font-weight: bold;
	color: #63a0ff;
	font-size: 12px;
	white-space: nowrap;
	height: 100%;
}

</style>
