<template>
	<div class="col w-100 around h-100 hidden">
		<div class="col w-100 around scrollable">
			<div class="col w-100">
				<h2 class="text-center w-100">Pedido de venda: {{ vendaFinalizada.cdVenda }}</h2>
				<h2 class="text-center w-100">{{ Empresa.fantasia }}</h2>
				<span>{{ Empresa.endereco }}</span>
				<span>CNPJ: {{ Empresa.cnpj }}</span>
				<div class="row w-100 between">
					<span>Fone: {{ Empresa.fone }}</span>
					<span>IE: {{ Empresa.ie }}</span>
				</div>
				<div class="row w-100 between">
					<span class="text-left w-100">Cidade: {{ Empresa.cdCidade == 1 ? "Marialva" :
							""
					}}</span>
					<span class="text-right w-auto">
						Data: {{ new Date((vendaFinalizada.dataEmissao || null)).toLocaleString("pt-BR") }}
					</span>
				</div>
			</div>
			<span class="text-left w-100" v-if="this.$store.state.Caixas">
				{{
						this.$store.state.Caixas ?
							this.$store.state.Caixas.content.find(caixa => caixa.cdCaixa == vendaFinalizada.cdIdentificador) ?
								`Caixa: ${this.$store.state.Caixas.content.find(caixa => caixa.cdCaixa ==
									vendaFinalizada.cdIdentificador).identificador}`
								: ""
							: ""
				}}
			</span>
			<span class="text-left w-100">Vendedor: {{ vendaFinalizada.vendedor.nome }}</span>
			<span class="text-left w-100" v-if="vendaFinalizada.usuarioCaixa ? vendaFinalizada.usuarioCaixa.nome : false">
				Usuário: {{ vendaFinalizada.usuarioCaixa ? vendaFinalizada.usuarioCaixa.nome : "" }}
			</span>
			<h3>
				Cliente:
				{{ (vendaFinalizada.cliente.id == $store.state.ClientePadrao.id) && vendaFinalizada.nomeCliente ?
						(vendaFinalizada.foneCliente ? `${vendaFinalizada.nomeCliente} ${vendaFinalizada.foneCliente}` :
							`${vendaFinalizada.nomeCliente}`) : vendaFinalizada.cliente.nome
				}}
			</h3>
			<div class="row w-100 between">
				<span>
					{{ vendaFinalizada.cliente.tipoPessoa == "F" ? "CPF:" : "CNPJ:" }}
					{{ (vendaFinalizada.cliente.id == $store.state.ClientePadrao.id) && vendaFinalizada.cnpjCliente ? vendaFinalizada.cnpjCliente : vendaFinalizada.cliente.cnpj ? vendaFinalizada.cliente.cnpj : "Não cadastrado" }}
				</span>
				<span>
					{{ vendaFinalizada.cliente.tipoPessoa == "F" ? "RG:" : "IE:" }}
					{{ (vendaFinalizada.cliente.id == $store.state.ClientePadrao.id) && vendaFinalizada.ieCliente ? vendaFinalizada.ieCliente : vendaFinalizada.cliente.ierg ? vendaFinalizada.cliente.ierg : "Não cadastrado" }}
				</span>
			</div>
			<div class="row w-100 between">
				<span>
					End.: {{ vendaFinalizada.cliente.id == $store.state.ClientePadrao.id ?
							vendaFinalizada.enderecoCliente
							: vendaFinalizada.cliente.endereco ? vendaFinalizada.cliente.endereco : "Não cadastrado"
					}}
				</span>
				<span>
					Bairro:
					{{
							(vendaFinalizada.cliente.id == $store.state.ClientePadrao.id) && vendaFinalizada.bairroCliente ?
								vendaFinalizada.bairroCliente : vendaFinalizada.cliente.bairro ?
									vendaFinalizada.cliente.bairro : "Não cadastrado"
					}}
				</span>
			</div>
			<div class="row w-100 between">
				<span>
					Fone:
					{{ (vendaFinalizada.cliente.id == $store.state.ClientePadrao.id) && vendaFinalizada.foneCliente ? vendaFinalizada.foneCliente : vendaFinalizada.cliente.fone ? vendaFinalizada.cliente.fone : "Não cadastrado" }}
				</span>
				<span v-if="vendaFinalizada.cliente.celular">Celular: {{ vendaFinalizada.cliente.celular ?
					vendaFinalizada.cliente.celular : "Não cadastrado"
				}}</span>
			</div>
			<span class="text-left w-100" v-if="vendaFinalizada.observacao">Obs.: {{ vendaFinalizada.observacao }}</span>

			<div class="lista-itens-venda">
				<ListagemProdutos :Produtos="vendaFinalizada.itens" :impressao="true" :height="'auto'" :showTable="true"/>
			</div>

			<div class="row w-100 between">
				<span>
					Nº Itens: {{
							(vendaFinalizada.itens.filter(item => item.cdTipo != "T" && item.situacao != "D").length > 0 ?
								vendaFinalizada.itens.filter(item => item.cdTipo != "T" && item.situacao != "D").map(item =>
									item.quantidade)
									.reduce((t, v) => t += v) : 0).toLocaleString("pt-BR", {
										style: "decimal", minimumFractionDigits: 2,
										maximumFractionDigits: 2
									})
					}}
				</span>
				<span>Valor bruto: {{ (vendaFinalizada.valorBruto || 0).toLocaleString("pt-BR", {
						style: "currency", currency:
							"BRL"
					})
				}}</span>
			</div>
			<div class="row w-100 between" v-if="vendaFinalizada.valorTroca > 0">
				<span class="text-right">
					Valor troca: {{ (vendaFinalizada.valorTroca || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) }}
				</span>
			</div>
			<div class="row w-100 between" v-if="vendaFinalizada.descontoMoeda > 0">
				<span class="text-right">Desconto: {{ (vendaFinalizada.descontoMoeda || 0).toLocaleString("pt-BR", {
						style:
							"currency", currency: "BRL"
					})
				}}</span>
			</div>
			<div class="row w-100 between" v-if="vendaFinalizada.valorConsorcio > 0">
				<span class="text-right">Consórcio: {{ (vendaFinalizada.valorConsorcio || 0).toLocaleString("pt-BR",
						{ style: "currency", currency: "BRL" })
				}}</span>
			</div>
			<div class="row w-100 between">
				<h2 class="text-right">
					Valor pago: {{
							(vendaFinalizada.valorPago > 0  || vendaFinalizada.mesa === "S")? (vendaFinalizada.valorPago).toLocaleString("pt-BR", {
								style:
									"currency", currency: "BRL"
							}) :
								(Number(vendaFinalizada.valorBruto - (vendaFinalizada.valorTroca || 0) -
									(vendaFinalizada.descontoMoeda || 0) - (vendaFinalizada.valorConsorcio ||
										0))).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
					}}
				</h2>
			</div>
			<div class="row w-100 between" v-if="troco > 0">
				<span class="text-right">Troco: {{ (troco || 0).toLocaleString("pt-BR", {
						style: "currency", currency:
							"BRL"
					})
				}}</span>
			</div>
		</div>
		<div class="row w-100 around m-5"
			v-if="(vendaFinalizada.cancelada == 'S' || vendaFinalizada.condicao == 'D' || vendaFinalizada.condicao == 'B')">
			<h3>
				{{ vendaFinalizada.cancelada == "S" ? "Venda cancelada!" : vendaFinalizada.condicao == "D" ? "Condicional finalizado!" : vendaFinalizada.condicao == "B" ? "Condicional baixado!": ""}}
			</h3>
			<button
				class="botao botao-acao"
				@click="copiarVenda">
				Copiar <span class="pequeno">Venda</span>
			</button>
		</div>
		<div class="row w-100 around m-5"
			v-else-if="!conferir && (vendaFinalizada.cancelada == 'N' && vendaFinalizada.condicao != 'D' && vendaFinalizada.condicao != 'B')">
			<button class="botao botao-adicionar w-30 mx-5" @click="iniciarEmissaoNota"
				v-if="vendaFinalizada.financeiro.length > 0">
				NFC-e
			</button>
			<button class="botao botao-adicionar w-30 mx-5" @click="imprimirCumpom" v-if="vendaFinalizada.financeiro.length > 0 || vendaFinalizada.valorCondicional > 0 ||
			((vendaFinalizada.valorBruto - (vendaFinalizada.descontoMoeda || 0) - (vendaFinalizada.valorTroca || 0) -
				(vendaFinalizada.valorConsorcio > 0 ? (vendaFinalizada.valorConsorcio + vendaFinalizada.valorPago) : 0)) <= 0.009) &&
			(vendaFinalizada.condicao == 'A' || vendaFinalizada.condicao == 'N')">
				Imprimir
			</button>
			<button class="botao botao-adicionar w-30" @click="$emit('receberVenda', { ...vendaFinalizada })"
				v-else-if="(vendaFinalizada.condicao == 'A' || vendaFinalizada.condicao == 'N') && vendaFinalizada.cancelada == 'N'">
				Receber <span class="pequeno">Venda</span>
			</button>
			<button class="botao botao-acao w-30 mx-5" @click="imprimirCumpomTroca"
				v-if="vendaFinalizada.financeiro.length > 0">
				<span class="pequeno">Cupom</span> Troca
			</button>
			<button
				class="botao botao-acao w-30 mx-5"
				@click="alterarVenda"
				v-if="($store.state.Usuario.permiteAlterarVenda && vendaFinalizada.emitirNota != 'E' &&
				(new Date(new Date(vendaFinalizada.dataEntradaSaida).setMinutes(new Date(vendaFinalizada.dataEntradaSaida).getMinutes() + 30)).getTime())
				>= new Date().getTime() && (vendaFinalizada.condicao == 'A' || vendaFinalizada.condicao == 'N')
				&& vendaFinalizada.cancelada == 'N') || ($store.state.Usuario.supervisor && new Date(vendaFinalizada.dataEntradaSaida).toLocaleDateString('pt-BR') == new Date().toLocaleDateString('pt-BR'))
				|| $store.state.Usuario.usuarioPrivilegiado"
			>
				Alterar <span class="pequeno">Venda</span>
			</button>
			<button class="botao botao-cancelar w-30 mx-5" @click="iniciarCancelamento"
				v-if="(((new Date(vendaFinalizada.dataEntradaSaida).toLocaleDateString('pt-BR') == new Date().toLocaleDateString('pt-BR'))
				&& permiteCancelar && (vendaFinalizada.condicao == 'A' || vendaFinalizada.condicao == 'N') && vendaFinalizada.emitirNota != 'E'
				&& vendaFinalizada.cancelada == 'N') || $store.state.Usuario.usuarioPrivilegiado) && $route.name != 'FecharCaixa' && $route.name != 'Fechamento'">
				Cancelar
			</button>
			<button class="botao botao-cancelar w-30 mx-5" @click="$emit('fecharModal')" v-else>Fechar</button>
		</div>
		<div class="row w-100 around m-5"
			v-else-if="conferir && (vendaFinalizada.cancelada == 'N' && vendaFinalizada.condicao != 'D' && vendaFinalizada.condicao != 'B')">
			<button
				class="botao botao-acao"
				@click="alterarVenda"
				v-if="(vendaFinalizada.cancelada == 'N' && vendaFinalizada.emitirNota != 'E' && (vendaFinalizada.condicao == 'A' || vendaFinalizada.condicao == 'N') &&
				(new Date(new Date(vendaFinalizada.dataEntradaSaida).setMinutes(new Date(vendaFinalizada.dataEntradaSaida).getMinutes() + 30)).getTime())
				>= new Date().getTime()) || ($store.state.Usuario.supervisor && new Date(vendaFinalizada.dataEntradaSaida).toLocaleDateString('pt-BR') == new Date().toLocaleDateString('pt-BR'))
				|| $store.state.Usuario.usuarioPrivilegiado">
				Alterar <span class="pequeno">Venda</span>
			</button>
			<button class="botao botao-adicionar" @click="$emit('receberVenda', { ...vendaFinalizada })"
				v-if="vendaFinalizada.cdIdentificador != 0 && (vendaFinalizada.condicao == 'A' || vendaFinalizada.condicao == 'N') && vendaFinalizada.cancelada == 'N'">Receber
				<span class="pequeno">Venda</span></button>
			<button class="botao botao-adicionar" @click="iniciarConfirmacaoVenda" v-else>Confirmar <span
					class="pequeno">Venda</span></button>
			<button class="botao botao-cancelar w-30 mx-5" @click="iniciarCancelamento" v-if="((!conferir && (vendaFinalizada.condicao == 'A' || vendaFinalizada.condicao == 'N') &&
			(new Date(vendaFinalizada.dataEntradaSaida).toLocaleDateString('pt-BR') == new Date().toLocaleDateString('pt-BR'))
			&& vendaFinalizada.emitirNota != 'E' && vendaFinalizada.cancelada == 'N') || $store.state.Usuario.usuarioPrivilegiado) && $route.name != 'FecharCaixa'
			&& $route.name != 'Fechamento' && $route.name != 'Principal'">
				Cancelar
			</button>
			<button class="botao botao-cancelar" @click="$emit('fecharModal')">Fechar</button>
		</div>
		<Modal v-if="gerarNFE" v-on:fecharModal="gerarNFE = false" :dimensoes="{ width: '768px', height: 'auto' }">
			<div class="dados-nota-container">
				<div class="dados-nota-item">
					<h3 class="dados-nota-titulo">Deseja informar os dados na nota?</h3>
				</div>
				<div class="dados-nota-item">
					<label for="nomeCliente">Nome:&nbsp;</label>
					<input type="text" id="nomeCliente" autocomplete="off" @keydown.enter="imprimirNFC"
						v-model="nomeCliente" maxlength="60" placeholder="Nome do cliente"
						:disabled="!corrigirNomeCliente">
				</div>
				<div class="dados-nota-item">
					<label for="tipoPessoa">Tipo de pessoa:&nbsp;</label>
					<select id="tipoPessoa" v-model="tipoPessoa" @change="alterouTipoPessoa">
						<option value="F">Pessoa Física</option>
						<option value="J">Pessoa Jurídica</option>
					</select>
				</div>
				<div class="dados-nota-item">
					<label :for="tipoPessoa == 'F' ? 'cpf' : 'cnpj'">{{tipoPessoa == "F" ? "CPF" : "CNPJ" }}:&nbsp;</label>
					<input type="text" :id="tipoPessoa == 'F' ? 'cpf' : 'cnpj'" autocomplete="off" @keydown.enter="imprimirNFC"
						@input="mascara" v-model="cpf" :maxlength="tipoPessoa == 'F' ? 14 : 18"
						:placeholder="tipoPessoa == 'F' ? '000.000.000-00' : '00.000.000/0000-00'">
				</div>
				<div class="dados-nota-item botoes">
					<button class="botao botao-cancelar" @click="gerarNFE = false">Cancelar</button>
					<button class="botao botao-adicionar" @click="imprimirNFC">Confirmar</button>
				</div>
			</div>
		</Modal>
		<Modal v-if="modalCancelarVenda" v-on:fecharModal="modalCancelarVenda = false"
			:dimensoes="{ width: '768px', height: 'auto' }">
			<div class="col around m-auto w-50">
				<div class="col between w-100">
					<label for="justificativa">
						<h2>Qual a justificativa para cancelamento?</h2>
					</label>
				</div>
				<div class="col center w-100 my-5">
					<input type="text" id="justificativa" class="w-80 p-5" autocomplete="off"
						@keydown.enter="cancelarVenda" v-model="justificativa" placeholder="MOTIVO..." @input="mascara">
				</div>
				<div class="row center w-100 my-5">
					<button class="botao botao-cancelar w-45 m-auto" @click="modalCancelarVenda = false">Cancelar</button>
					<button class="botao botao-adicionar w-45 m-auto" @click="cancelarVenda">Confirmar</button>
				</div>
			</div>
		</Modal>
		<Modal v-if="solicitarValor" v-on:fecharModal="solicitarValor = false"
			:dimensoes="{width: '95vw', height: 'auto'}">
			<div class="col around m-auto w-80">
				<div class="col between w-100">
					<label for="valorEntreguePeloCliente">
						<h2>Informe o valor entregue pelo cliente</h2>
					</label>
					<input type="text" id="valorEntreguePeloCliente" class="w-100 p-5" autocomplete="off"
						@keydown.enter="proximoCampo('troco')" v-model="valorEntreguePeloCliente"
						style="font-size: 14pt; text-align: right;">
				</div>
				<div class="col center w-100 my-5">
					<label for="troco">
						<h2>Troco</h2>
					</label>
					<input type="text" id="troco" class="w-100 p-5" autocomplete="off" @keydown.enter="confirmarVenda"
						readonly :value="Number(troco || 0).toLocaleString('pt-BR', {style: 'currency', currency: 'brl'})" style="font-size: 14pt; text-align: right;">
				</div>
				<div class="row between w-100 m-5 botoes">
					<button class="botao botao-cancelar w-45" @click="solicitarValor = false">Cancelar</button>
					<button class="botao botao-adicionar w-45" @click="confirmarVenda">Confirmar</button>
				</div>
			</div>
		</Modal>
		<Modal v-if="abrirModalPix" @fecharModal="abrirModalPix = false">
			<CobrancaSelecionado :cobranca="cobranca" @voltarConsulta="abrirModalPix = false" @realizarCancelamento="modalCancelarVenda = true, abrirModalPix = false"/>
		</Modal>
		<Modal v-if="alteraNcm" @fecharModal="alteraNcm = false">
			<ConsultarNCM :venda="true" @atualizouNcm="alteraNcm = false;iniciarEmissaoNota()"/>
		</Modal>

	</div>
</template>

<script>
import ListagemProdutos from "../components/ListagemProdutos.vue";
import Modal from "../components/Modal.vue";
import CobrancaSelecionado from "./CobrancaSelecionado.vue";
import ConsultarNCM from "../components/ConsultarNCM.vue";

export default {
	name: "PreImpressao",

	components: {
		ListagemProdutos,
		Modal,
		CobrancaSelecionado,
		ConsultarNCM,
	},

	props: {
		vendaFinalizada: Object,
		conferir: Boolean,
		permiteCancelar: Boolean,
		empresa: Object,
	},

	data() {
		return {
			valorAPagarDinheiro:0,
			gerarNFE: false,
			cpf: "",
			nomeCliente: "",
			tipoPessoa: "F",
			modalCancelarVenda: false,
			justificativa: "",
			solicitarValor: false,
			valorEntreguePeloCliente: "",
			corrigirNomeCliente: false,
			abrirModalPix: false,
			cobranca: {},

			//ncms
			ncmsInvalidos: [],
			alteraNcm:false,
		};
	},
	computed: {
		Empresa () {
			if (this.empresa) {
				return this.empresa;
			} else{
				return this.$store.state.Empresa;
			}
		},
		troco () {
			return Number(Number(this.valorEntreguePeloCliente) - Number(this.valorAPagarDinheiro));
		}
	},
	methods: {
		async solicitaValor(pagamento){
			const res = await this.buscaPagamento(pagamento.cdTipo?pagamento.cdTipo:pagamento.cdTipoPgto);
			if(res.valorVista && res.ehPix === "N" && res.cartao === "N" && res.deposito === "N" && res.cheque === "N" && this.$route.name !== "Vendas"){
				return true;
			}
		},
		async alterarVenda(){
			const venda = await this.$store.dispatch("buscarVendaPorId",  {cdVenda: this.vendaFinalizada.cdVenda, cdEmpresa: this.vendaFinalizada.cdEmpresa});
			this.$emit("alterarVenda", { ...venda });
		},
		async copiarVenda(){
			const venda = await this.$store.dispatch("buscarVendaPorId",  {cdVenda: this.vendaFinalizada.cdVenda, cdEmpresa: this.vendaFinalizada.cdEmpresa});
			this.$emit("copiarVenda", { ...venda });
		},
		imprimirCumpom() {
			if(this.Empresa.tiposistema === "L") {
				this.$store.dispatch("gerarCupomLanchonete", {
					venda: this.vendaFinalizada,
					finalizar: true
				});
				return;
			}
			if (this.vendaFinalizada.condicao == "B" && this.vendaFinalizada.valorCondicional > 0) {
				return this.$store.commit("relatarErro", { mensagem: "Este condicional já foi baixado, não permite reimpressão!" });
			}
			if (this.vendaFinalizada.condicao == "C" && this.vendaFinalizada.valorCondicional > 0) {
				return this.$store.commit("relatarErro", { mensagem: "Esta venda já foi cancelada, não permite reimpressão!" });
			}
			this.$store.dispatch("solicitarImpressaoVenda", { ...this.vendaFinalizada });
		},

		imprimirCumpomTroca() {
			this.$store.dispatch("gerarCupomTroca", {
				empresa: this.vendaFinalizada.cdEmpresa,
				venda: this.vendaFinalizada
			});
		},

		alterouTipoPessoa() {
			this.cpf = "";
			setTimeout(() => {
				const idFoco = this.tipoPessoa == "F" ? "cpf" : "cnpj";
				document.getElementById(idFoco).focus();
				document.getElementById(idFoco).select();
			}, 20);
		},

		async verificaNcm(){
			let ncmsInvalidos = new Set();
			let ncmsInvalidosArray = [];
			for(const produto of this.vendaFinalizada.itens){
				const produtoAtualizado = await this.$store.dispatch("buscarProdutoPorId",produto.id);
				const ncmTeste = produtoAtualizado.ncm;
				if(ncmTeste){
					const response = await this.$store.dispatch("verificaNcm", {ncm: ncmTeste});		
					if(response?.invalido){
						ncmsInvalidos.add(ncmTeste);
					}
				}
			}
			ncmsInvalidosArray=[...ncmsInvalidos];
			return ncmsInvalidosArray;
		},

		async iniciarEmissaoNota() {
			this.ncmsInvalidos = await this.verificaNcm();
			if(this.ncmsInvalidos.length > 0){
				this.$store.commit("atualizarListaNcm",this.ncmsInvalidos);
				this.alteraNcm = true;
				return;
			}
			this.corrigirNomeCliente = false;
			if (this.vendaFinalizada.emitirNota == "E" || this.vendaFinalizada.nfepdf != null) {
				return this.$store.dispatch("gerarNFE", { ...this.vendaFinalizada });
			}
			if (this.vendaFinalizada.emitirNota == "E" && !this.vendaFinalizada.nfepdf) {
				return this.$store.commit("relatarErro", {
					mensagem: "O pedido está marcado como nota emitida, porém na base de dados não contém o PDF, favor entrar em contato com o suporte!",
				});
			}
			this.gerarNFE = true;
			if (this.vendaFinalizada.cliente.id == this.$store.state.ClientePadrao.id) {
				this.corrigirNomeCliente = true;
				this.tipoPessoa = "F";
			}
			this.nomeCliente = this.vendaFinalizada.cliente.nome;
			if (this.vendaFinalizada.nomeCliente != this.vendaFinalizada.cliente.nome) {
				this.nomeCliente = this.vendaFinalizada.nomeCliente;
			}
			if (this.vendaFinalizada.cliente.cnpj && (this.vendaFinalizada.cliente.id != this.$store.state.ClientePadrao.id)) {
				if (this.tipoPessoa == "F") {
					if (!this.verificaCPF(this.vendaFinalizada.cliente.cnpj)) {
						setTimeout(() => {
							this.$store.commit("alterarInformativo", {
								tipo: "erro",
								mensagem: "CPF do cliente é inválido, Verifique!",
							});
						},20);
					}
					this.cpf = this.vendaFinalizada.cliente.cnpj;
				} else {
					if (!this.verificaCPF(this.vendaFinalizada.cliente.cnpj)) {
						setTimeout(() => {
							this.$store.commit("alterarInformativo", {
								tipo: "erro",
								mensagem: "CNPJ do cliente é inválido, Verifique!",
							});
						},20);
					}
					this.cpf = this.vendaFinalizada.cliente.cnpj;
				}
			} else if (this.vendaFinalizada.cnpjCliente && (this.vendaFinalizada.cnpjCliente != this.$store.state.ClientePadrao.cnpj)) {
				if (this.vendaFinalizada.cnpjCliente.length > 14) {
					this.tipoPessoa = "J";
				}
				this.cpf = this.vendaFinalizada.cnpjCliente;
			}
			setTimeout(() => {
				const idFoco = this.tipoPessoa == "F" ? "cpf" : "cnpj";
				document.getElementById(idFoco).focus();
				document.getElementById(idFoco).select();
			}, 10);
		},

		imprimirNFC () {
			const regex = /[!@#$%¨^&*()_={}+?;<>'"/\\]/;
			if (regex.test(this.vendaFinalizada.cliente.nome)) {
				this.corrigirNomeCliente = true;
				this.$store.commit("alterarInformativo", {
					tipo: "erro",
					mensagem: "Favor verificar e remover caracteres especiais no nome do cliente!",
				});
				return setTimeout(() => {
					document.getElementById("nomeCliente").focus();
					document.getElementById("nomeCliente").select();
				}, 30);
			}
			if (this.vendaFinalizada.emitirNota == "E" || this.vendaFinalizada.nfepdf != null) {
				return this.$store.dispatch("gerarNFE", { cdVenda: this.vendaFinalizada.cdVenda });
			}
			if (this.cpf != "") {
				if (this.tipoPessoa == "F") {
					if (!this.verificaCPF(this.cpf)) {
						return setTimeout(() => this.$store.commit("relatarErro", { mensagem: "CPF não passou no cálculo do dígito verificador, favor realizar a consulta do CPF no site da receita federal!" }), 10);
					}
				} else {
					if (!this.verificaCNPJ(this.cpf)) {
						return setTimeout(() => this.$store.commit("relatarErro", { mensagem: "CNPJ não passou no cálculo do dígito verificador, favor realizar a consulta do CNPJ no site da receita federal!" }), 10);
					}
				}
			}
			if (this.corrigirNomeCliente && (this.vendaFinalizada.cliente.id != this.$store.state.ClientePadrao.id)) {
				this.$store.dispatch("atualizarCliente", {
					...this.vendaFinalizada.cliente,
					nome: this.nomeCliente
				});
			}
			this.$store.dispatch("gerarNFE", { ...this.vendaFinalizada, cpf: this.cpf });

			this.gerarNFE = false;
		},

		mascara(evento) {
			if (evento.data === null) {
				return;
			}
			const id = evento.target.id;
			const input = document.getElementById(id);
			const v = input.value;

			if (id == "cpf") {
				if (isNaN(v[v.length - 1])) { // impede entrar outro caractere que não seja número
					this.cpf = this.cpf.substring(0, v.length - 1);
					return;
				}
				if (v.length == 3 || v.length == 7) {
					this.cpf += ".";
				}
				if ((v.length == 4 || v.length == 8) && v.length - 1 != ".") {
					this.cpf = v.slice(0, v.length - 1) + "." + evento.data;
				}
				if (v.length == 11) {
					this.cpf += "-";
				}
				if (v.length == 12 && v.length - 1 != "-") {
					this.cpf = v.slice(0, v.length - 1) + "-" + evento.data;
				}
			} else if (id == "cnpj") {
				if (v.length == 2 || v.length == 6) {
					this.cpf += ".";
				}
				if (v.length == 3) {
					if (v.length-1 == "/"){
						return;
					}
					this.cpf = this.cpf.slice(0, 10);
					this.cpf += "/" + evento.data;
				}
				if (v.length == 10) input.value += "/";
				if (v.length == 11) {
					if (v.length-1 == "/"){
						return;
					}
					this.cpf = this.cpf.slice(0, 10);
					this.cpf += "/" + evento.data;
				}
				if (v.length == 15) {
					this.cpf += "-";
				}
			} else if (id == "justificativa") {
				this[id] = this[id].toUpperCase();
			}

		},

		verificaCPF(strCPF) {
			strCPF = strCPF.replace(/[^\d]+/g, "");

			if (strCPF == "") return false;

			if (strCPF.length != 11)
				return false;

			if (strCPF == "00000000000" ||
				strCPF == "11111111111" ||
				strCPF == "22222222222" ||
				strCPF == "33333333333" ||
				strCPF == "44444444444" ||
				strCPF == "55555555555" ||
				strCPF == "66666666666" ||
				strCPF == "77777777777" ||
				strCPF == "88888888888" ||
				strCPF == "99999999999")
				return false;

			let Soma;
			let Resto;
			Soma = 0;
			if (strCPF == "00000000000") return false;
			for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
			Resto = (Soma * 10) % 11;
			if ((Resto == 10) || (Resto == 11)) Resto = 0;
			if (Resto != parseInt(strCPF.substring(9, 10))) return false;
			Soma = 0;
			for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
			Resto = (Soma * 10) % 11;
			if ((Resto == 10) || (Resto == 11)) Resto = 0;
			if (Resto != parseInt(strCPF.substring(10, 11))) return false;
			return true;
		},

		verificaCNPJ(cnpj) {

			cnpj = cnpj.replace(/[^\d]+/g, "");

			if (cnpj == "") return false;

			if (cnpj.length != 14)
				return false;

			if (cnpj == "00000000000000" ||
				cnpj == "11111111111111" ||
				cnpj == "22222222222222" ||
				cnpj == "33333333333333" ||
				cnpj == "44444444444444" ||
				cnpj == "55555555555555" ||
				cnpj == "66666666666666" ||
				cnpj == "77777777777777" ||
				cnpj == "88888888888888" ||
				cnpj == "99999999999999")
				return false;

			let tamanho = cnpj.length - 2;
			let numeros = cnpj.substring(0, tamanho);
			let digitos = cnpj.substring(tamanho);
			let soma = 0;
			let pos = tamanho - 7;
			let i;
			for (i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
					pos = 9;
			}
			let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(0))
				return false;

			tamanho = tamanho + 1;
			numeros = cnpj.substring(0, tamanho);
			soma = 0;
			pos = tamanho - 7;
			for (i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2)
					pos = 9;
			}
			resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(1))
				return false;

			return true;
		},

		iniciarCancelamento() {
			this.$store.commit("relatarErro", {
				mensagem: `Tem certeza que deseja cancelar a venda ${this.vendaFinalizada.cdVenda}?`,
				tipo: "polar",
				item: {
					cancelarVenda: true,
					venda: this.vendaFinalizada
				}
			});
		},

		async cancelarVenda() {
			if (!this.justificativa) {
				return this.$store.commit("relatarErro", { mensagem: "É necessário informar a justificativa do cancelamento!" });
			}
			this.modalCancelarVenda = false;
			await this.$store.dispatch("cancelarVenda", { ...this.vendaFinalizada, justificativa: this.justificativa });
			this.$emit("vendaCancelada", { size: null, page: null, target: null });
			this.$emit("fecharModal");
		},

		iniciarConfirmacaoVenda() {
			const existe = this.vendaFinalizada.financeiro.find(parcela => parcela.vistaprazo == 1 && parcela.cartao != "S" && parcela.deposito != "S" && parcela.cheque != "S");
			if(!existe && this.vendaFinalizada.financeiro.length > 1){
				return this.confirmarVenda();
			}
			this.confirmarVenda();
			// this.solicitarValor = true;
			// this.valorEntreguePeloCliente = Number(this.vendaFinalizada.valorPago).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			// this.$forceUpdate();
			// setTimeout(() => {
			// 	document.getElementById("valorEntreguePeloCliente").focus();
			// 	document.getElementById("valorEntreguePeloCliente").select();
			// }, 50);
		},
		confirmarVenda() {
			if(this.solicitarValor){
				this.solicitarValor = false;
			}
			else{
				this.$emit("confirmarVenda", { ...this.vendaFinalizada });
			}
		},
		proximoCampo(id) {
			document.getElementById(id).focus();
		},
		polarConfirmado(item) {
			if (item.cancelarVenda) {
				for(let i = 0; i < this.vendaFinalizada.financeiro.length; i++){
					const financeiro = this.vendaFinalizada.financeiro[i];
					if(financeiro.txid){
						return setTimeout(() => {
							this.realizarEstorno(financeiro.txid);
						}, 200);
					}
				}
				this.modalCancelarVenda = true;
				setTimeout(() => {
					document.getElementById("justificativa").focus();
					document.getElementById("justificativa").select();
				}, 50);
			}
		},
		async realizarEstorno(txid){
			const data = await this.$store.dispatch("consultarCobrancaPix", {txid: txid, banco: this.$store.state.ConfiguracaoPixEmpresa[0].banco});
			if(data.statusdevolucao == "DEVOLVIDO"){
				this.abrirModalPix = false;
				return this.modalCancelarVenda = true;
			}
			if(data){
				this.$store.commit("relatarErro", {mensagem: "Para cancelar a venda é necessário estornar a cobrança referente a venda"});
				this.cobranca = data;
				return this.abrirModalPix = true;
			}

		},
		async buscaPagamento(id){
			const pagamento = await this.$store.dispatch("buscarFormasPagamentoPorId",id);
			return pagamento;
		}
	},

	mounted() {
		this.$bus.$on("confirmarPolar", this.polarConfirmado);		
		if(!this.$store.state.Usuario.somenteVendaSemCaixa || this.$store.state.Usuario.caixa){
			const financeiro = this.$store.state.Venda ? this.$store.state.Venda.financeiro : this.vendaFinalizada.financeiro;
			setTimeout( async () => {
				financeiro?.forEach(async (pagamento) => {					
					const solicitarValorIteracao = await this.solicitaValor(pagamento);
					this.valorAPagarDinheiro += solicitarValorIteracao?pagamento.valorParcela:0;
					this.solicitarValor=solicitarValorIteracao?solicitarValorIteracao:this.solicitarValor;						
				});				
				setTimeout(() => {
					if(this.solicitarValor){
						document.getElementById("valorEntreguePeloCliente").focus();
					}
				}, 100);
			}, 150);
		}
	},

	destroyed() {
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
	}
};
</script>

<style scoped>
.botao{
	margin:5px;
}
.text-right {
	text-align: right;
	width: 100%;
}

.text-center {
	text-align: center;
}

.z-10 {
	z-index: 10;
}

.lista-itens-venda {
	display: flex;
	width: 100%;
	min-height: 100px;
	max-height: 150px;
	overflow: auto;
}

.hidden {
	display: flex;
	height: 100%;
	max-height: 98vh;
	min-height: max-content;
	overflow: hidden;
}

.scrollable {
	display: block;
	overflow: auto;
	max-height: 100vh;
}

.text-left {
	display: flex;
	text-align: left;
	width: 100%;
}

.dados-nota-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: flex-start;
	align-items: center;
	height: max-content;
	margin: 0px auto;
}
.dados-nota-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 95%;
	margin: 5px 0px;
}
.dados-nota-item input,
.dados-nota-item select {
	display: flex;
	width: 100%;
	height: max-content;
	padding: 3px;
}
.dados-nota-titulo {
	width: 100%;
}
.dados-nota-item.botoes {
	flex-direction: row;
	justify-content: space-between;
}
.dados-nota-item .btn {
	width: 49%;
}

@media screen and (max-width: 740px) {
	.pequeno {
		display: none;
	}
}

@media screen and (max-width: 485px) {
	.row.w-100.around.m-5 {
		display: flex;
		flex-direction: column;
	}

	.row.w-100.around.m-5 .btn {
		width: 100%;
		height: auto;
		margin: 5px 0px;
	}

	#gridTelaPrincipal>div.modal.center>div>div>div.col.w-100 {
		overflow-y: auto;
		height: 100%;
	}

	.col.w-100.around.h-100,
	* {
		font-size: 11pt;
	}
}
</style>
