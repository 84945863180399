<template>
	<div>
		<div class="title">
			<h1>Configuração Fiscal</h1>
		</div>
		<div class="containerPage">
			<div class="contentPage">
				<fieldset>
					<legend>CERTIFICADO DIGITAL</legend>
					<!-- FLEX - COL -->
					<div class="containerFieldset">
							<!-- FLEX ROW -->
						<div class="fieldsetLinha">
							<div id="certificadoDigital">								
								<div class="fieldsetItem input-group">
									<input type="file" @input="getFiles" id="certInput" style="display: none;" accept=".pfx" placeholder=" "/>
									<input autocomplete="off" id="caminhocert" type="text" v-model="empConfigNfe.nfeCaminhoCertificado" :readonly="!Usuario.usuarioPrivilegiado" disabled
									placeholder=" "/>
									<label class="lg-view" for="caminhocert">CAMINHO DO CERTIFICADO DIGITAL</label>
									<label class="sm-view" for="caminhocert">CAMINHO CERT. DIGITAL</label>
								</div>
								<div class="iconeCertDigital">
									<label for="certInput" v-if="!certificado">
										<i class="fas fa-cloud-upload-alt" style="color: var(--primario)"></i>
									</label>
									<i class="fas fa-trash" style="color: var(--deletar)" v-else @click="removerCaminho"></i>
								</div>
							</div>
							<div class="fieldsetItem input-group">
								<input type="text" autocomplete="off" id="senhacert" v-model="empConfigNfe.nfeSenhaCertificado" :readonly="!Usuario.usuarioPrivilegiado"
								placeholder=" "/>
								<label for="senhacert">SENHA CERTIFICADO DIGITAL</label>
							</div>
						</div>
						<div class="fieldsetLinha">
							<div class="fieldsetItem input-group">
								<input type="text" id="caminhoapi" autocomplete="off" v-model="empConfigNfe.caminhoAPI" :readonly="!Usuario.usuarioPrivilegiado"
								placeholder=" "/>
								<label for="caminhoapi">CAMINHO API</label>
							</div>
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend>CONFIGURAÇÃO GERAL</legend>
					<div class="fieldsetLinha">
						<div class="fieldsetItemToken input-group" id="serieNfce">
							<input type="text" autocomplete="off" v-model="empConfigNfe.serie"
							placeholder=" " id="serienfce"/>
							<label for="serienfce">Serie NFC-e</label>
						</div>
						<div class="fieldsetItem input-group">
							<input type="text" autocomplete="off" v-model="empConfigNfe.nronfce" placeholder=" " id="proximanfce"/>
							<label for="proximanfce">N° Proxima NFC-e</label>
						</div>
					</div>
					<fieldset class="tokens">
						<legend>TOKENS AUTENTICAÇÃO</legend>
						<fieldset>
							<legend>PRODUÇÃO</legend>
							<div class="fieldsetLinha">
								<div class="fieldsetItemToken input-group">
									<input type="text" placeholder=" " id="idTokenProd" style="width: 60%; text-align: right;" v-model="empConfigNfe.nfeIdTokenPro"/>
									<label for="idTokenProd">ID</label>
								</div>
								<div class="fieldsetItem input-group">
									<input type="text" placeholder=" " id="tokenProd" v-model="empConfigNfe.nfeTokenPro" />
									<label for="tokenProd">TOKEN</label>
								</div>
							</div>
						</fieldset>
						<fieldset>
							<legend>HOMOLOGAÇÃO</legend>
							<div class="fieldsetLinha">
								<div class="fieldsetItemToken input-group">
									<input type="text" placeholder=" " id="idTokenHom" style="width: 60%; text-align: right;" v-model="empConfigNfe.nfeIdTokenHom"/>
									<label for="idTokenHom">ID</label>
								</div>
								<div class="fieldsetItem input-group">
									<input type="text" placeholder=" " id="tokenHom"  v-model="empConfigNfe.nfeTokenHom"/>
									<label for="tokenHom">TOKEN</label>
								</div>
							</div>
						</fieldset>
					</fieldset>
				</fieldset>
			</div>
		</div>
		<div class="botoes-container">
			<button class="botao botao-cancelar" @click="cancelarEdicao">
				<i class="fas fa-times" style="color: var(--deletar-borda)"></i>
				Cancelar
			</button>
			<button class="botao botao-adicionar" @click="salvarConfigFiscal">
				<i class="fa fa-check" style="color: var(--adicionar-borda)"></i>
				Salvar
			</button>
		</div>
	</div>
</template>
<script>
class EmpresaDados{
	constructor(empConfig){
		this.caminhoAPI = empConfig.caminhoAPI;
		this.nfeCaminhoCertificado = empConfig.nfeCaminhoCertificado;
		this.nfeSenhaCertificado = empConfig.nfeSenhaCertificado;
		this.fileCertificado = empConfig.fileCertificado;
		this.id = empConfig.id;
		this.nfeIdTokenPro = empConfig.nfeIdTokenPro;
		this.nfeTokenPro = empConfig.nfeTokenPro;
		
		this.nronfce = empConfig.nronfce;
		this.serie = empConfig.serie;
		this.nfeIdTokenHom = empConfig.nfeIdTokenHom;
		this.nfeTokenHom = empConfig.nfeTokenHom;
	}
}
export default{
	name: "NFeConfigEmpresa",
	props: { Empresa: Object },
	data(){
		return {
			empConfigNfe: {
				id: "",
				caminhoAPI: "",
				nfeCaminhoCertificado: "",
				nfeSenhaCertificado: "",
				fileCertificado: "",
				nfeIdTokenPro: "",
				nfeIdTokenHom: "",
				nfeTokenPro: "",
				nfeTokenHom: "",
				nronfce: "",
				serie: ""
			},
			certificado: false
		};
	},
	computed: {
		Usuario(){
			return this.$store.state.Usuario;
		}
	},
	methods: {
		removerCaminho() {
			this.$store.dispatch("deleteFile", this.empConfigNfe.nfeCaminhoCertificado);
			this.empConfigNfe.nfeCaminhoCertificado = "";
			this.certificado = false;
		},
		
		async getFiles(e) {
			if (e.target.files[0].type != "application/x-pkcs12") {
				this.$store.commit("relatarErro", {mensagem: "Tipo de arquivo inválido"});
			}
			this.empConfigNfe.fileCertificado = e.target.files[0];
			const data = await this.$store.dispatch("uploadFile", this.empConfigNfe.fileCertificado);
			this.empConfigNfe.nfeCaminhoCertificado = data.nfeCaminhoCertificado;
			this.certificado = true;
			e.target.value = null;
		},
		async salvarConfigFiscal() {
			this.$emit("salvarConfigFiscal", {...this.empConfigNfe});
		},
		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},
	},
	mounted(){
		if (this.Empresa) {
			if (this.Empresa.novo) return;
			this.empConfigNfe = new EmpresaDados(this.Empresa);
			if(this.empConfigNfe.nfeCaminhoCertificado){
				this.certificado= true;
			}
		}
		this.$bus.$on("executarAcao", this.salvarConfigFiscal);
	},
	destroyed(){
		this.$bus.$off("executarAcao", this.salvarConfigFiscal);
	}
};
</script>
<style scoped>
.sm-view {
	display: none;
}

#certificadoDigital {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 75%;
}

#certificadoDigital i{
	margin-top: 10px;
}
.iconeCertDigital {
	display: flex;
	align-items: center;
	justify-content: center;
}
.input-group {
	margin-top: 10px;
}

.input-group label {
	height: 12px;
}

#serienfce {
	width: 100%;
	text-align: right;
}
.containerPage {
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 0px 10px;
}
.contentPage{
	height: 92vh;
}
.title{
	text-align: center;
}

fieldset{
	margin-bottom: 5px;
	padding: 5px 5px;
	border: 2px solid var(--border);
	border-radius: 8px;
}
fieldset legend{
	margin-left: 1.5rem;
	padding: 0.5rem;
}

.containerFieldset{
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 4px;
}
.fieldsetLinha{
	display: flex;
	flex-direction: row;
	padding: 3px 5px;
	border: 2px solid var(--border);
	border-radius: 8px;
}

.fieldsetLinha .fieldsetItem{
	width: 100%;
	margin-left: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: nowrap;
}

.fieldsetLinha .fieldsetItem input{
	margin-left: 5px;
	width: 100%;
}

.fieldsetItemToken input{
	margin-left: 5px;
}
.fieldsetItemToken{
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: nowrap;
	margin-left: 5px;
}

.botoes-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 5px 0px;
	gap: 15px;
}

.botoes-container button{
	width: 100%;
}

.tokens{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 10px
}

.tokens fieldset{
	width: 50%;
}
.dadosNota{
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

#serieNfce {
	width: 20%;
}

@media screen and (max-width: 1024px) {
	
}

@media screen and (max-width: 1000px) {
	.fieldsetLinha{
		display: flex;
		flex-direction: column;
		padding: 3px 5px;
	}
	.fieldsetItem{
		margin-top: 5px;
	}
	.fieldsetItem input{
		margin-right: 10px;
	}
}

@media screen and (max-width: 768px) {
	.fieldsetLinha .fieldsetItem {
		margin-left: 0;
		margin-top: 10px;
	}

	#serienfce {
		width: 90%;
	}

	#certificadoDigital {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
	}
}

@media screen and (max-width: 460px){
	.fieldsetLinha .input-group{
		margin-right:0px;
		margin-left:0px;
	}
	.fieldsetItem{
		display: flex;
		flex-direction: column !important;
		justify-content: center !important;
		align-items: center !important;
	}

	#serieNfce {
		width: 100%;
	}

	#serieNfce input{
		width: 100%;
	}

	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	.tokens {
		flex-direction: column;
	}

	.tokens fieldset {
		width: 100%;
	}
} 

</style>