<template>
	<div class="w-100 col center">
		<div class="parcelamento">
			<div class="parcelamento-item">
				<div class="input-group">
					<input type="text" id="receber" disabled readonly class="text-right"
					:value="valorReceber" placeholder=" ">
					<label for="receber" class="text-left">Recebendo</label> &nbsp;
				</div>
				<div class="input-group">
					<input type="text" id="metodoRecebimento" v-model="metodoRecebimento.descricao" disabled
					class="w-100 text-center" placeholder=" ">
					<label for="metodoRecebimento" class="text-left">Recebendo em</label> &nbsp;
				</div>

				<div class="input-group" id="observacaoIg">
					<input type="text" id="observacao" v-model="observacao" @input="gerarParcelas"
					@keydown.enter="proximoCampo(metodoRecebimento.cartao == 'S' ? 'produtoConci' : 'nParcelas')" class="w-100" maxlength="150"
					placeholder=" " autocomplete="off">
					<label for="observacao" class="text-left">Observação</label> &nbsp;
				</div>
			</div>
			<div class="parcelamento-item" v-if="metodoRecebimento.cartao == 'S'">
				<div class="input-group">
					<input type="text" id="valor" v-model="valor" @change="validaValor"
					@keydown.enter="proximoCampo(metodoRecebimento.cartao == 'S' ? 'produtoConci' : 'nParcelas')" class="w-100" maxlength="150"
					placeholder=" " autocomplete="off">
					<label for="observacao" class="text-left">Valor</label> &nbsp;
				</div>
				<div class="input-group" style="width: 100%">
					<div class="row w-100 m-2">
						<input
						id="produtoConci"
						type="text"
						list="produto-list"
						class="w-100"
						v-model="produtoConci"
						@input="selecionarProdutoConciliadora"
						@keydown.enter="proximoCampo"
						autocomplete="off"
						placeholder=" "
						style="line-height: 20px;"
						>
						<label class="text-left" for="produtoConci">Produto&nbsp;</label>
						<datalist id="produto-list" name="produto" role="produtoList">
							<option :value="produto.nome" v-for="produto in listaprodutos" :key="produto.codigo">
							</option>
						</datalist>
					</div>
				</div>				
			</div>
			<div class="parcelamento-item" v-if="metodoRecebimento.cartao == 'S'">
				<div class="input-group">
					<input
					type="text"
					id="bandeira"
					list="bandeira-list"
					class="w-100"
					v-model="queryBandeira"
					autocomplete="off"
					placeholder=" "
					>
					<datalist id="bandeira-list" name="bandeira" role="bandeiraList">
						<option :value="bandeira.adm" v-for="bandeira in bandeiras" :key="bandeira.codigo">
						</option>
					</datalist>
					<label for="bandeira" class="text-left">Bandeira</label> &nbsp;
				</div>
				<div class="input-group">
					<input
					type="text"
					id="doccartao"
					class="w-100"
					autocomplete="off"
					v-model="nsucartao"
					maxlength="20"
					@keydown.enter="proximoCampo('nsucartao')"
					placeholder=" "
					>
					<label for="doccartao" class="text-left">Nº DOC</label> &nbsp;
				</div>
				<div class="input-group">
					<input
					type="text"
					id="nsucartao"
					class="w-100"
					autocomplete="off"
					v-model="doccartao"
					maxlength="20"
					@keydown.enter="apertaBotao"
					placeholder=" "
					>
					<label for="nsucartao" class="text-left">Nº AUT</label> &nbsp;
				</div>
			</div>
			<div class="parcelamento-item" v-if="metodoRecebimento.cheque != 'S'">
				<div class="input-group">
					<input
						type="number"
						id="nParcelas"
						min="1"
						class="w-100"
						v-model="nParcelas"
						@input="gerarParcelas"
						@keydown.enter="confirmarParcelas"
						:disabled="(metodoRecebimento.vistaprazo == 1 && metodoRecebimento.cheque != 'S') || (ProdutoConciliadora ? ProdutoConciliadora.codmodalidade == '0' : false)"
						placeholder=" "
						v-if="metodoRecebimento.cartao !== 'S'"
					>
					<input
						type="number"
						id="nParcelas"
						min="1"
						class="w-100"
						v-model="qtdeParcelaCartao"
						@input="gerarParcelaUnica"
						@keydown.enter="confirmarParcelas"
						:disabled="((metodoRecebimento.vistaprazo === 1 && metodoRecebimento.quantidadediasprazo !== 0) && metodoRecebimento.cheque != 'S') || (ProdutoConciliadora ? ProdutoConciliadora.codmodalidade == '0' : false)"
						placeholder=" "
						v-else
					>
					<label for="nParcelas" class="text-left">Nº Parcelas</label> &nbsp;
				</div>

				<div class="input-group">
					<input type="number" id="diaVencimento" v-model="diaVencimento" @input="gerarParcelas"
					@keydown.enter="confirmarParcelas" class="w-100 text-right" max="31" maxlength="2" min="1"
					:disabled="(metodoRecebimento.vistaprazo == 1 && metodoRecebimento.cheque != 'S') || (ProdutoConciliadora ? ProdutoConciliadora.codmodalidade == '0' : false)">
					<label for="diaVencimento" class="text-left">Dia de vencimento</label> &nbsp;
				</div>

				<div class="input-group">
					<input type="number" id="primeiraParcela" v-model="primeiraParcela" @input="gerarParcelas"
					class="w-100 text-right"
					:disabled="(metodoRecebimento.vistaprazo == 1 && metodoRecebimento.cheque != 'S') || (ProdutoConciliadora ? ProdutoConciliadora.codmodalidade == '0' : false)">
					<label for="primeiraParcela" class="text-left">Primeira parcela</label> &nbsp;
				</div>
				<div class="input-group"  v-if="metodoRecebimento.cartao === 'S' && metodoRecebimento.vistaprazo === 3">
					<input type="number" id="primeiraParcela"
					class="w-100 text-right" :value="valorParcelasCartao"
					disabled>
					<label for="primeiraParcela" class="text-left">Valor parcela</label> &nbsp;
				</div>
			</div>

			<div class="botao-controle" v-if="metodoRecebimento.cheque != 'S'">
				<button id="botaoCalcular" class="botao botao-acao" @click="gerarParcelas">Calcular</button>
				<button class="botao botao-adicionar" @click="adiconarCartao" v-if="(!valorEhTotal && valorRestante != 0) && metodoRecebimento.cartao == 'S'">Adicionar Cartão</button>
				<button class="botao botao-adicionar" @click="confirmarParcelas" v-else>Concluir</button>
			</div>
		</div>

		<!-- <div id="listaParcelas" class="col w-100 h-100"> -->
		<div id="listaParcelas" class="col w-100 h-100" v-if="metodoRecebimento.cheque != 'S'">
			<table v-if="parcelas.length > 0 && metodoRecebimento.cartao != 'S'">
				<thead>
					<tr>
						<td colspan="5" style="text-align: center; font-size: 18pt">Lista de Parcelas</td>
					</tr>
					<tr>
						<td style="text-align: center">Parcela Nº</td>
						<td style="text-align: right">Data de emissão</td>
						<td style="text-align: right">Data de vencimento</td>
						<td style="text-align: right">Valor de parcela</td>
						<td class="text-center" v-if="metodoRecebimento.cheque == 'S'">Cheque</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(parcela, index) in parcelas" :key="index">
						<td style="text-align: center">{{ parcela.numeroParcela }}</td>
						<td style="text-align: right" v-if="!$store.state.Usuario.supervisor && !$store.state.Usuario.usuarioPrivilegiado">
							{{ `${parcela.dataDoc}`.split("-").reverse().join("/") }}
						</td>
						<td style="text-align: right" v-else>
							<input type="date" :id="`dataDoc-${index}`" v-model="parcela.dataDoc">
						</td>
						<td style="text-align: right" v-if="!parcela.alterarDataVencimento"
							@dblclick="solicitaAlterarDataVencimento(index)">
							{{ parcela.dataVencimento }}
						</td>
						<td style="text-align: right" v-else>
							<input type="date" :id="index" v-model="parcela.dataVencimento"
								@keydown="alterouDataVencimento">
						</td>
						<td style="text-align: right" v-if="alteraParcelas"> <input type="text" :id="index" v-model="parcela.valorParcela"
								@keydown.enter="recalculaParcelas()"></td>
						<td v-else  @dblclick="alteraParcelas = true">{{ Number(parcela.valorParcela ? parcela.valorParcela :
								0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
						}}</td>
						<td class="text-center" v-if="metodoRecebimento.cheque == 'S'">
							<i class="fas fa-money-check-alt"
								:style="parcela.chequeLancado ? 'color: var(--confirmar);' : 'color: var(--cancelar);'"
								@click="lancarCheque(index)"></i>
						</td>
					</tr>
				</tbody>
			</table>
			<table v-if="cartoes.length>0">
				<thead>
					<tr>
						<td colspan="5" style="text-align: center; font-size: 18pt">Lista de Cartões</td>
					</tr>
					<tr>
						<td style="text-align: center">Cartão</td>
						<td style="text-align: right">Bandeira</td>
						<td style="text-align: right">Nº Doc</td>
						<td style="text-align: right">Nº Aut</td>
						<td style="text-align: right">Valor</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(cartao, index) in cartoes" :key="index">
						<td style="text-align: center">{{ cartao.produto }}</td>
						<td style="text-align: center">{{ cartao.bandeira }}</td>
						<td style="text-align: center">{{ cartao.ndoc }}</td>
						<td style="text-align: center">{{ cartao.naut }}</td>
						<td style="text-align: center">{{ cartao.valor }}</td>						
					</tr>
				</tbody>
			</table>
		</div>
		<LancamentoCheque
			v-else
			@confirmarParcelas="confirmarParcelasCheque"
			:valorReceber="valorReceber"
			:metodoRecebimento="metodoRecebimento"
		/>
	</div>
</template>

<script>
import LancamentoCheque from "./LancamentoCheque.vue";
import { dateFormatISO } from "../utils/dateformat";
export default {
	name: "ParcelarPagamento",
	props: {
		valorReceber: String,
		metodoRecebimento: Object,
		contaBaixar: Object
	},

	components: {
		LancamentoCheque
	},

	data(){
		return {
			recebido: "",
			nParcelas: 1,
			diaVencimento: "",
			primeiraParcela: "",
			observacao: "",
			parcelas: [],
			calcular: null,
			queryBandeira: "",
			bandeira: null,
			doccartao: "",
			nsucartao: "",
			timer: null,
			lancamentoCheque: null,
			produtoConci: "",
			qtdeParcelaCartao: 0,
			total: 0,
			valor: "",
			cartoes:[],
			alteraParcelas: false,
		};
	},

	computed: {
		valorParcelasCartao(){
			return (Number(this.valor.replace(",","."))/Number(this.qtdeParcelaCartao)).toFixed(2);
		},
		valorRestante(){
			return this.recebido - this.total;
		} ,
		valorEhTotal (){
			return !(Number(this.valor.replace(",","."))<this.recebido);
		},
		listaprodutos(){
			return this.$store.state.Conciliadora.conteudo;
		},
		ProdutoConciliadora () {
			if (this.listaprodutos) {
				return this.listaprodutos.find(produto => `${produto.nome}`.toUpperCase() == `${this.produtoConci}`.toUpperCase());
			}
			return null;
		},
		bandeiras() {
			return this.$store.state.Bandeiras.content;
		},
		Bandeira(){
			return this.bandeiras.find(bandeira => `${bandeira.adm}` === `${this.queryBandeira}`);
		}
	},

	beforeMount() {
		this.$store.dispatch("buscarBandeiras", {ativo: "A"});
	},

	mounted() {
		this.diaVencimento = new Date().getDate();
		this.recebido = typeof this.valorReceber === "string" 
			? 	parseFloat(this.valorReceber.replace(".", "").replace(",", "."))
			: Number(this.valorReceber);
		this.valor = this.valorRestante.toFixed(2).replace(".",",");
		if (this.metodoRecebimento.cheque == "S") return;
		let idFoco = "nParcelas";
		if (this.metodoRecebimento.cartao == "S") {
			this.$store.dispatch("buscarProdutosConciliadora", {cdtipo: this.metodoRecebimento.cdTipo || this.metodoRecebimento.cdtipo});
			idFoco = "produtoConci";
			document.getElementById("botaoCalcular").style.display = "none";
			this.qtdeParcelaCartao = 1;
			this.gerarParcelaUnica();
		}
		this.proximoCampo(idFoco);
		this.gerarParcelas();
	},

	methods: {
		recalculaParcelas(){
			let ultimaParcela = Number(this.valorReceber.replace(",","."));
			for(let i = 0; i < this.nParcelas-1; i++){
				ultimaParcela = (ultimaParcela-(typeof this.parcelas[i].valorParcela != "number"? Number(this.parcelas[i].valorParcela.replace(",",".")).toFixed(2):this.parcelas[i].valorParcela.toFixed(2)));
			}
			this.parcelas[this.nParcelas-1].valorParcela = ultimaParcela+"";
			this.alteraParcelas = false;
			if(ultimaParcela<=0){
				this.$store.commit("relatarErro", {mensagem: "Lançamento incorreto, os valores não conferem!"});
				this.gerarParcelas();
			}
		},
		apertaBotao(){
			(!this.valorEhTotal && this.valorRestante != 0)? this.adiconarCartao() : this.confirmarParcelas();
		},
		adiconarCartao(){
			if (Number(this.qtdeParcelaCartao) < 1) {
				return;
			}
			const emissao = this.$store.state.Venda?.dataEmissao;
			const dataEmissao = this.$store.state.Venda?.dataEmissao ? dateFormatISO({date: new Date(emissao).toLocaleDateString("en-CA"), format: "ISOdate"}) : this.contaBaixar ? this.contaBaixar.dataBaixa :  dateFormatISO({date: null, format: "ISOdate"});
			this.cartoes.push({
				produto: this.produtoConci,
				bandeira: this.queryBandeira,
				ndoc: this.nsucartao,
				naut: this.doccartao,
				valor: this.valor,
				parcela:{
					dataDoc: dataEmissao,
					numeroParcela: 0,
					dataVencimento: null,
					descricao: this.metodoRecebimento.descricao,
					qtdeParcelaCartao: this.qtdeParcelaCartao ? this.qtdeParcelaCartao : 0,
					valorParcela: this.valor ? Number(Number(this.valor.replace(",",".")).toFixed(2)) : 0,
					metodoParcela: this.metodoRecebimento.descricao,
					cdTipo: this.metodoRecebimento.cdTipo || this.metodoRecebimento.cdtipo,
					cartao: this.metodoRecebimento.cartao,
					valorVista: this.metodoRecebimento.valorVista,
					vistaprazo: this.metodoRecebimento.vistaprazo,
					bandeira: this.bandeira,
					doccartao: this.doccartao,
					nsucartao: this.nsucartao,
					observacao: this.observacao,
					alterarDataVencimento: false,
					nominal: "",
					codprodutoconciliadora: this.ProdutoConciliadora?.codigo,
				}
			});
			this.total += Number(this.valor.replace(",","."));
			this.valor = this.valorRestante.toFixed(2).replace(".",",");
			this.produtoConci = "";
			this.queryBandeira = "";
			this.bandeira = null;
			this.doccartao = "";
			this.nsucartao = "";
		},	
		validaValor(){
			try{
				if(Number(this.valor.replace(",",".")).isNaN){
					throw new Error("O valor digitado não é numerico, por favor verifique.");
				}
				if(Number(this.valor.replace(",","."))>this.valorRestante){
					this.valor = this.valorRestante.toFixed(2).replace(".",",");
					throw new Error("O valor digitado é maior que o valor restante a ser recebido.");					
				}
			}
			catch(error){
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});

			}
		},
		selecionarProdutoConciliadora () {
			if (this.ProdutoConciliadora?.id) {
				if (this.ProdutoConciliadora.codmodalidade == "0") {
					this.nParcelas = "1";
					this.primeiraParcela = "";
					this.gerarParcelas();
				}
			}
		},
		proximoCampo(idCampo) {
			setTimeout(() => {
				const input = document.getElementById(idCampo);
				if (input) {
					input.focus();
					if (input.select) input.select();
				}
			},100);
		},
		solicitaAlterarDataVencimento(index) {
			this.parcelas[index].dataVencimento = new Date(this.parcelas[index].dataVencimento.split("/").reverse()).toLocaleDateString("en-CA");
			this.parcelas[index].alterarDataVencimento = true;
			setTimeout(() => {
				const input = document.getElementById(index);
				input.focus();
			},100);
		},
		alterouDataVencimento(evento) {
			clearTimeout(this.timer);
			if (evento.key == "Enter") {
				const index = evento.target.id;
				this.parcelas[index].dataVencimento = new Date(this.parcelas[index].dataVencimento.split("-")).toLocaleDateString("en-GB");
				return this.parcelas[index].alterarDataVencimento = false;
			}
			this.timer = setTimeout(() => {
				const index = evento.target.id;
				this.parcelas[index].dataVencimento = new Date(this.parcelas[index].dataVencimento.split("-")).toLocaleDateString("en-GB");
				this.parcelas[index].alterarDataVencimento = false;
			}, 1200);
		},
		liberarParcelamento() {
			let numeroMaximoDeParcelas;
			let tipoUsuarioMensagem;
			if (this.$store.state.Usuario.usuarioPrivilegiado) {
				numeroMaximoDeParcelas = this.$store.state.Usuario.maxNumeroParcelas;
				tipoUsuarioMensagem = " (Usuário privilegiado)";
			} else {
				numeroMaximoDeParcelas = this.metodoRecebimento.qtdeParcela;
				tipoUsuarioMensagem = "";
			}

			if (this.nParcelas > numeroMaximoDeParcelas){
				setTimeout(() => {
					this.$store.commit("relatarErro",
						{mensagem: `O número máximo de parcelas é ${numeroMaximoDeParcelas}${tipoUsuarioMensagem}`});
				}, 10);
				return this.$store.state.Usuario.supervisor;
			}
			return true;
		},

		adicionarParcela(dataDoc, numeroParcela, dataVenc, valorParcela, qtdeParcelaCartao) {
			this.parcelas.push({
				dataDoc,
				numeroParcela,
				dataVencimento: dataVenc,
				descricao: this.metodoRecebimento.descricao,
				qtdeParcelaCartao: qtdeParcelaCartao ? qtdeParcelaCartao : 0,
				valorParcela: valorParcela ? Number(valorParcela.toFixed(2)) : 0,
				metodoParcela: this.metodoRecebimento.descricao,
				cdTipo: this.metodoRecebimento.cdTipo || this.metodoRecebimento.cdtipo,
				cartao: this.metodoRecebimento.cartao,
				valorVista: this.metodoRecebimento.valorVista,
				vistaprazo: this.metodoRecebimento.vistaprazo,
				bandeira: this.bandeira,
				doccartao: this.doccartao,
				nsucartao: this.nsucartao,
				observacao: this.observacao,
				alterarDataVencimento: false,
				nominal: "",
			});
		},

		validacaoParcelas(e, diaVencimento) {
			let numeroMaximoDeParcelas;
			let tipoUsuarioMensagem;
			if (this.$store.state.Usuario.usuarioPrivilegiado) {
				numeroMaximoDeParcelas = this.$store.state.Usuario.maxNumeroParcelas;
				tipoUsuarioMensagem = " (Usuário privilegiado)";
			} else {
				numeroMaximoDeParcelas = this.metodoRecebimento.qtdeParcela;
				tipoUsuarioMensagem = "";
			}

			try {
				if(this.metodoRecebimento.vistaprazo === 3){
					const primeiraParcela = this.primeiraParcela.includes(",") ? Number(this.primeiraParcela.replace(",", ".")) : Number(this.primeiraParcela);
					const valorReceber =  this.valorReceber.includes(",") ? Number(this.valorReceber.replace(",", ".")) : Number(this.valorReceber);
					if(primeiraParcela >= valorReceber){
						setTimeout(() => {
							document.getElementById("primeiraParcela").focus();
						}, 50);
						throw new Error("O valor da primeira parcela nao pode ser maior ou igual ao valor total da venda!");
					}
					if(numeroMaximoDeParcelas < this.nParcelas){
						this.nParcelas = 0;
						throw new Error(`O número maximo de parcelas permitido é ${numeroMaximoDeParcelas}${tipoUsuarioMensagem}, verifique`);
					}
				}
				if (e && e.target) {
					if (e.target.id == "recebido" || e.target.id == "primeiraParcela" || e.target.id == "bandeira") {
						clearTimeout(this.calcular);
						return this.calcular = setTimeout(() => this.gerarParcelas(), 750);
					}
				}
				if (this.diaVencimento > 31) {
					diaVencimento = 31;
					throw new Error("O dia máximo para vencimento é 31.");
				} else if (isNaN(diaVencimento)) {
					throw new Error("Parece que você inseriu um dia inválido, verifique!");
				} else if (diaVencimento < 0) {
					throw new Error("Parece que você inseriu um dia inválido, verifique!");
				}
				return true;
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error?.message
				});
				return false;
			}
		},

		gerarParcelaUnica() {
			if (Number(this.qtdeParcelaCartao) < 1) {
				this.parcelas.length = 0;
				return;
			}
			this.parcelas = [];
			const emissao = this.$store.state.Venda?.dataEmissao;
			const dataEmissao = this.$store.state.Venda?.dataEmissao ? dateFormatISO({date: new Date(emissao).toLocaleDateString("en-CA"), format: "ISOdate"}) : this.contaBaixar ? this.contaBaixar.dataBaixa :  dateFormatISO({date: null, format: "ISOdate"});
			this.adicionarParcela(dataEmissao, 0, null, this.recebido, this.qtdeParcelaCartao);

		},
		gerarParcelas(e){ //vistaprazo === 3 metodo pagamento é prazo e se for vistaprazo === 1 metodo de pagamento é av
			let diaVencimento = Number(this.diaVencimento === 31 ? 30 : this.diaVencimento);

			if(!this.validacaoParcelas(e, diaVencimento)) return;
			if(this.metodoRecebimento.cartao === "S") return;
			this.parcelas = [];
			if (!this.nParcelas) {
				return;
			}
			const emissao = this.$store.state.Venda?.dataEmissao;
			const dataEmissao = this.$store.state.Venda?.dataEmissao ? dateFormatISO({date: new Date(emissao).toLocaleDateString("en-CA"), format: "ISOdate"}) : this.contaBaixar ? this.contaBaixar.dataBaixa :  dateFormatISO({date: null, format: "ISOdate"});
			// const dataEmissao = "2024-05-31";
			// this.diaVencimento = `${diaVencimento.toFixed(0)}`;
			let valorParcelas;
			if (this.primeiraParcela != ""){
				if (!this.liberarParcelamento(this.primeiraParcela)){
					return;
				}
			}
			if ((this.recebido != 0 || this.recebido != "") && (!this.primeiraParcela || this.primeiraParcela == "")) {
				valorParcelas = Number(Number(this.recebido / this.nParcelas).toFixed(2));
				if (!this.liberarParcelamento(valorParcelas)){
					return;
				}
				for (let i = 0; i < this.nParcelas; i++){
					let dataVenc = new Date(new Date().getFullYear(), new Date().getMonth()+i, (Number(diaVencimento) + 1 + 30)).toLocaleDateString("en-GB");
					// let dataVenc = new Date(new Date().getFullYear(), new Date().getMonth()+i, this.$store.state.Venda.valorVista && this.metodoRecebimento.cdTipo == "CART" ? (new Date().getDate() + 10) : (Number(this.diaVencimento) + 1 + 30)).toLocaleDateString("pt-BR");
					this.adicionarParcela(dataEmissao, (i+1), dataVenc, i == 0 ?
						Number(Number(this.recebido - (valorParcelas * (this.nParcelas -1))).toFixed(2)) :
						Number(Number(valorParcelas).toFixed(2)));
				}
			} else if ((this.recebido != 0 && this.recebido != "") && (this.primeiraParcela != 0 && this.primeiraParcela != "")) {
				valorParcelas = Number(Number((this.recebido - this.primeiraParcela) / (this.nParcelas - 1)).toFixed(2));
				if (!this.liberarParcelamento(valorParcelas)){
					return;
				}
				for (let i = 0; i < this.nParcelas; i++){
					let dataVenc = new Date(new Date().getFullYear(), new Date().getMonth()+i, (Number(diaVencimento) + 1 + 30)).toLocaleDateString("en-GB");
					// let dataVenc = new Date(new Date().getFullYear(), new Date().getMonth()+i, this.$store.state.Venda.valorVista && this.metodoRecebimento.cdTipo == "CART" ? (new Date().getDate() + 10) : (Number(this.diaVencimento) + 1 + 30)).toLocaleDateString("pt-BR");
					this.adicionarParcela(dataEmissao, (i+1), dataVenc, Number(i == 0 ? Number(Number(this.primeiraParcela).toFixed(2)) : i+1 != this.nParcelas ? Number(Number(valorParcelas).toFixed(2)) : Number(this.recebido - this.primeiraParcela - (valorParcelas * (i -1)))));
				}

			} else if ((!this.recebido || this.recebido == "") && (this.primeiraParcela != 0 || this.primeiraParcela != "")) {
				valorParcelas = Number(Number((Number(`${this.valorReceber || ""}`.split(".").join("").replace(",", ".")) - this.primeiraParcela) / (this.nParcelas - 1)).toFixed(2));
				if (!this.liberarParcelamento(valorParcelas)){
					return;
				}
				for (let i = 0; i < this.nParcelas; i++){
					let dataVenc = new Date(new Date().getFullYear(), new Date().getMonth()+i, (Number(diaVencimento) + 1 + 30)).toLocaleDateString("en-GB");
					// let dataVenc = new Date(new Date().getFullYear(), new Date().getMonth()+i, this.$store.state.Venda.valorVista && this.metodoRecebimento.cdTipo == "CART" ? (new Date().getDate() + 10) : (Number(this.diaVencimento) + 1 + 30)).toLocaleDateString("en-GB");
					this.adicionarParcela(dataEmissao, (i+1), dataVenc, Number(i == 0 ? Number(this.primeiraParcela).toFixed(2) : i+1 != this.nParcelas ? Number(Number(valorParcelas).toFixed(2)) : Number(this.valorReceber - Number(this.recebido - this.primeiraParcela - (valorParcelas * (i -1))))));
				}

			} else {
				valorParcelas = Number(Number(Number(`${this.valorReceber || ""}`.split(".").join("").replace(",", ".")) / this.nParcelas).toFixed(2));
				if (!this.liberarParcelamento(valorParcelas)){
					return;
				}
				for (let i = 0; i < this.nParcelas; i++){
					let dataVenc = new Date(new Date().getFullYear(), new Date().getMonth()+i, (Number(diaVencimento) + 1 + 30)).toLocaleDateString("en-GB");
					// let dataVenc = new Date(new Date().getFullYear(), new Date().getMonth()+i, this.$store.state.Venda.valorVista && this.metodoRecebimento.cdTipo == "CART" ? (new Date().getDate() + 10) : (Number(this.diaVencimento) + 1 + 30)).toLocaleDateString("pt-BR");
					this.adicionarParcela(dataEmissao, (i+1), dataVenc, (i == 0 ?  Number(Number(Number(`${this.valorReceber || ""}`.split(".").join("").replace(",", ".")) - (valorParcelas * (this.nParcelas -1))).toFixed(2)) : Number(Number(valorParcelas).toFixed(2))));
				}
			}
		},

		async confirmarParcelas(){
			let numeroMaximoDeParcelas;
			let tipoUsuarioMensagem;
			if (this.$store.state.Usuario.usuarioPrivilegiado) {
				numeroMaximoDeParcelas = this.$store.state.Usuario.maxNumeroParcelas;
				tipoUsuarioMensagem = " (Usuário Privilegiado)";
			} else {
				numeroMaximoDeParcelas = this.metodoRecebimento.qtdeParcela;
				tipoUsuarioMensagem = "";
			}
			// const vlrPrimeiraParcela = parseFloat(this.parcelas[0].valorParcela);
			const totalReceber = Number(`${this.valorReceber || ""}`.split(".").join("").replace(",", "."));
			const valorParcela = totalReceber / this.nParcelas;
			const minValorParcela = this.$store.state.Usuario.minValorParcela;
			if (this.nParcelas > 1 && (((minValorParcela > valorParcela) || (this.primeiraParcela && minValorParcela > this.primeiraParcela)) && this.metodoRecebimento.cartao == "N")){
				setTimeout(() => {
					this.$store.commit("relatarErro", {mensagem:
					`O valor das parcelas deve ser maior que ${this.$store.state.Usuario.minValorParcela
						.toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}.`});
				}, 10);
				return this.$store.state.Usuario.supervisor;
			}
			for(let i=0; i < this.parcelas.length; i++){
				const parcela = this.parcelas[i];
				if(Number(parcela.valorParcela) <= 0){
					return this.$store.commit("relatarErro", {
						mensagem: "O valor das parcelas não pode ser inferior ou igual a R$0,00, verifique"
					});
				}
			}
			if (this.metodoRecebimento.cartao === "S" ? Number(this.qtdeParcelaCartao) < 1 : this.parcelas.length < 1) {
				return this.$store.commit("relatarErro", {
					mensagem: "O número de parcelas não pode ser inferior a 1"
				});
			}else if (this.parcelas[0].qtdeParcelaCartao > numeroMaximoDeParcelas && this.metodoRecebimento.cartao == "S") {
				return this.$store.commit("relatarErro", {
					mensagem: `O número máximo de parcelas é ${numeroMaximoDeParcelas}${tipoUsuarioMensagem}`
				});
			}
			if (this.metodoRecebimento.cartao == "S" && this.$store.state.Usuario.controlaCartao) {
				if (!this.ProdutoConciliadora?.codigo) {
					return this.$store.commit("relatarErro", {mensagem: "Favor selecionar o produto da conciliadora!"});
				}
				if (!this.Bandeira?.codigo) {
					return this.$store.commit("relatarErro", {mensagem: "Favor selecionar a bandeira da máquina de cartão!"});
				}
				if (!this.doccartao) {
					return this.$store.commit("relatarErro", {mensagem: "Favor informar o nº do recibo."});
				}
				if (!this.nsucartao) {
					return this.$store.commit("relatarErro", {mensagem: "Favor informar a aprovação do recibo"});
				}
			}
			let valorRecebido = 0;
			if(this.$route.path == "/fechamento" || this.$route.path == "/fechamentoMesa"){
				if(this.cartoes.length>0){
					let parcelasCartao = [];
					this.cartoes.forEach(cartao =>{
						parcelasCartao.push(cartao.parcela);
						valorRecebido = valorRecebido + Number(Number(cartao.parcela.valorParcela).toFixed(2));
					});
					this.$emit("confirmarParcelas", { parcelas: [...parcelasCartao], valorRecebido});
				}
				else{
					this.parcelas.forEach(parcela => {
						if (this.metodoRecebimento.cartao == "S") {
							parcela.codprodutoconciliadora = this.ProdutoConciliadora?.codigo;
							parcela.cdbandeira = this.Bandeira?.codigo;
							parcela.doccartao = this.doccartao;
							parcela.nsucartao = this.nsucartao;
							parcela.cartao = this.metodoRecebimento.cartao;
							parcela.descricao = this.metodoRecebimento.descricao;
						}
						valorRecebido = valorRecebido + Number(Number(parcela.valorParcela).toFixed(2));
					});
					this.$emit("confirmarParcelas", { parcelas: [...this.parcelas], valorRecebido});
				}				
			} else if (this.$route.path == "/contasreceber"){
				this.contaBaixar.parcelas.forEach(parcela => {
					parcela.codprodutoconciliadora = this.ProdutoConciliadora?.codigo;
					parcela.cdbandeira = this.Bandeira?.codigo;
					parcela.doccartao = this.doccartao;
					parcela.nsucartao = this.nsucartao;
					parcela.cartao = this.metodoRecebimento.cartao;
				});
				this.$emit("confirmarParcelamento", {...this.contaBaixar}, this.qtdeParcelaCartao);
			}
			this.recebido = "";
			this.nParcelas = 1;
			this.qtdeParcelaCartao = 0;
			this.primeiraParcela = "";
			this.parcelas = [];
			delete this.$route.query.parcelas;
		},

		confirmarParcelasCheque(parcela) {
			this.parcelas.push(parcela);
			this.confirmarParcelas();
		}
	},
};
</script>

<style scoped>
.parcelamento {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 96%;
	gap: 15px;
	margin-top: 10px;
}

.botao-controle {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	max-height: 10rem;
	overflow-y: auto;
	gap: 2vw;
	justify-content: center;
}

.botao-controle button {
	height: 30px;
	margin: 30px 0px;
}

.botao-adicionar, .botao-acao {
	line-height: 0;
}

.parcelamento-item {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.parcelamento-item .input-group{
	width: 33%;
}

.input-group label {
	height: 15px;
}

#listaParcelas {
	overflow: auto;
	font-size: var(--txt-medio);
	box-shadow: var(--shadow-secundario);
	margin: 10px 0px;
	max-height: 68vh;
	width: 100%;
}

@media screen and (max-width: 1086px) {
	.parcelamento {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	.parcelamento label {
		max-width: 180px;
	}

	.parcelamento input {
		text-align: left;
	}

	#receber {
		text-align: right;
	}

	.parcelamento-item {
		flex-direction: row;
		width: 100%;
	}

	.parcelamento-item div {
		width: 95%;
	}

	.botao-controle {
		width: 100%;
	}

	button.btn {
		height: auto;
		margin: 5px;
		width: 95%;
	}
}

@media screen and (max-width: 780px) {
	.parcelamento-item {
		flex-direction: row;
		width: 100%;
	}

	.parcelamento-item input {
		text-align: left;
	}

	.botao-controle {
		width: 100%;
	}
}

@media screen and (max-width: 440px) {
	.parcelamento-item {
		flex-wrap: wrap;
		gap: 10px;
	}

	.parcelamento-item .input-group {
		width: 43%;
	}

	#observacaoIg {
		width: 100%;
	}
}

@media screen and (max-width: 375px) {
	.parcelamento-item .input-group {
		width: 100%;
	}
}
</style>
