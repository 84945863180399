export class FluxoCaixa {
	valor;
	total;
	saldo;
	grupo;
	calculo;
	nivel;
	pesquisa;
	constructor(fluxo){
		this.valor = fluxo.valor;
		this.total = fluxo.total;
		this.saldo = fluxo.saldo;
		this.grupo = fluxo.grupo;
		this.calculo = fluxo.calculo;
		this.nivel = fluxo.nivel;
		this.pesquisa = fluxo.pesquisa;
	}
}