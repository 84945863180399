<template>
	<div class="main col around m-5">
		<div class="row center w-100">
			<h2>Pagamentos</h2>
		</div>
		<div class="col w-100 scrollable">
			<span class="titlePagamentos">
				Métodos à vista:
			</span>
			<form
				:key="index"
				class="metodoPagamento"
				v-for="
					(metodo, index) in formasPagamentoVista 
				"
				@submit.prevent="selecionarPagamento(metodo, index)"
				:style="{backgroundColor: index % 2 == 0 ? '' : 'background-color: #ddd;'}"
			>
				<label 
					@contextmenu.prevent="liberarInput" 
					:for="metodo.cdtipo" 
					class="w-70 pointer descricao-promocao" 
				>
					<span>
						{{metodo.descricao}}:
					</span>
					<span class="desc-promo" v-if="aplicaDescontoFormaPagamento">
						{{ metodo.desconto ? `${metodo.desconto}% - ${(calculaDesconto(metodo.desconto) || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`: ""}}
					</span>
				</label>
				<input
					type="text"
					:id="'metodo-' + metodo.cdtipo"
					v-model="metodo.valor"
					class="btnPagamento w-30"
					placeholder="R$ 0,00"
					autocomplete="off"
					@beforeinput="verificarInput"
					@dblclick="selecionarPagamento(metodo, index)"
					@keydown.arrow-down="proximoMetodo(index, metodo)"
					@keydown.arrow-up="retornarMetodo(index, metodo)"
					@input="apagaPagamento(metodo)"
					:style="{backgroundColor: index % 2 == 0 ? '' : 'background-color: #ddd; border: 1px solid #a9a9a9'}"
					pattern="[0-9]+([,\.][0-9]+)?"
				>
				<input type="submit" hidden />
			</form>
		</div>
		<div class="col w-100 scrollable">
			<span class="titlePagamentos">
				Métodos à prazo:
			</span>
			<form
				:key="index"
				class="metodoPagamento"
				v-for="
					(metodo, index) in formasPagamentoPrazo
				"
				@submit.prevent="selecionarPagamento(metodo, index)"
				:style="(index % 2) == 0 ? `` : `background-color: #ddd;`"
			>
				<label
					@contextmenu.prevent="liberarInput"
					:for="metodo.cdtipo"
					class="w-70 pointer descricao-promocao"
				>
					<span>
						{{metodo.descricao}}:
					</span>
					<span class="desc-promo" v-if="aplicaDescontoFormaPagamento">
						{{ metodo.desconto ? `${metodo.desconto}% - ${(calculaDesconto(metodo.desconto) || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`: ""}}
					</span>
				</label>
				<input
					type="text"
					:id="'metodo-' + metodo.cdtipo"
					v-model="metodo.valor"
					class="btnPagamento w-30"
					placeholder="R$ 0,00"
					autocomplete="off"
					@beforeinput="verificarInput"	
					@dblclick="selecionarPagamento(metodo, index)"
					@keydown.arrow-down="proximoMetodo(index, metodo)"
					@keydown.arrow-up="retornarMetodo(index, metodo)"
					@input="apagaPagamento(metodo)"
					:style="(index % 2) == 0 ? `` : `background-color: #ddd; border: 1px solid #a9a9a9;`"
					pattern="[0-9]+([,\.][0-9]+)?"
				>
				<input type="submit" hidden/>
			</form>
		</div>
		<div class="row between w-100 my-5 container-valores">
			<div class="row between w-100 textarea">
				<textarea placeholder="Observações" style="width: 100%; resize:none; padding: 5px; font-size: 12pt;
					margin-left: 5px; height:100px;" maxlength="500" v-model="observacao" @keyup="atualizarObservacao()"></textarea>
			</div>
		</div>
	
	</div>
</template>

<script>

class VendaPagamento {
	constructor (pagamento) {
		this.descricao = pagamento.descricao;
		this.cdTipo = pagamento.cdtipo;
		this.valorVista = pagamento.valorVista;
		this.vistaprazo = pagamento.vistaprazo;
		this.cartao = pagamento.cartao;
		this.deposito = pagamento.deposito;
		this.mostravenda = pagamento.mostravenda;
		this.mostrarecebimento = pagamento.mostrarecebimento;
		this.permiteconsumidorfinal = pagamento.permiteconsumidorfinal;
		this.verificaSituacao = pagamento.verificaSituacao;
		this.cheque = pagamento.cheque;
		this.valor = Number(Number(`${pagamento.valor || 0}`.split(".").join("").replace(",", ".") || 0).toFixed(2));
		this.elementoHTML = pagamento.elementoHTML || document.getElementById(pagamento.cdtipo);
		this.txid = pagamento.txid;
		this.qtdeParcela = pagamento.qtdeParcela;
		this.quantidadediasdeprazo = pagamento.quantidadediasdeprazo;
		this.desconto = pagamento.desconto;
	}
}
export default {
	name: "MetodosPagamento",

	props: {
		totalVenda: Number,
	},
	data(){
		return {
			observacao: "",
			abrirModal:false,
			pagamento: {},
			valorPix: "",
			aplicaDescontoFormaPagamento: false,
		};
	},
	computed: {
		totVendaSemDesconto() {
			return this.$store.state.Venda.valorBruto;
		},

		formasPagamentoVista() {
			return (this.Usuario.supervisor || this.Usuario.caixa) ? this.formasPagamento.filter(pgto => pgto.vistaprazo == 1 || (pgto.vistaprazo === 3 && pgto.quantidadediasdeprazo === 0))
				: this.Usuario.somenteVendaSemCaixa ? this.formasPagamento.filter(pgto => pgto.vistaprazo === 1 || (pgto.vistaprazo === 3 && pgto.quantidadediasdeprazo === 0)) 
					: this.formasPagamento.filter(pgto => pgto.vistaprazo === 1 || (pgto.vistaprazo === 3 && pgto.quantidadediasdeprazo === 0));
		},

		formasPagamentoPrazo() {
			return (this.Usuario.supervisor || this.Usuario.caixa) ? this.formasPagamento.filter(pgto => pgto.vistaprazo === 3 && pgto.quantidadediasdeprazo !== 0)
				: this.Usuario.somenteVendaSemCaixa ? this.formasPagamento.filter(pgto => pgto.vistaprazo === 3 && pgto.quantidadediasdeprazo !== 0)
					: this.formasPagamento.filter(pgto => pgto.vistaprazo === 3 && pgto.quantidadediasdeprazo !== 0);
		},

		formasPagamento(){
			return this.$store.state.formasPgtoVenda;
		},
		Usuario(){
			return this.$store.state.Usuario;
		},
		AlteroCheckbox(){
			return this.$store.state.Venda.valorVista;
		},
	},

	watch: {
		AlteroCheckbox: function(){
			this.formasPagamentoVista.forEach((e)=>{
				e.valor = null;
			});
			this.formasPagamentoPrazo.forEach((e)=>{
				e.valor = null;
			});
		}
	},

	methods: {
		verificaDescontoFormaPagamento() {
			const classiCliente = this.$store.state.Venda.cliente.classificacao;
			const descontoClassificacao = this.$store.state.Venda.valorVista ? 
				classiCliente?.descvista
				: classiCliente?.descprazo;
				
			if(this.isClientePadrao(this.$store.state.Venda.cliente)) return this.aplicaDescontoFormaPagamento = true;
			if(!this.isClientePadrao(this.$store.state.Venda.cliente) && descontoClassificacao === 0) return  this.aplicaDescontoFormaPagamento = true;
			return this.aplicaDescontoFormaPagamento = false;
		},

		calculaDesconto(descontoPercentual) {
			if(!descontoPercentual) console.error("Faltou o campo descontoPercentual");
			const vlrDesconto = this.totVendaSemDesconto * (descontoPercentual / 100);
			const descontoCalculado = this.totVendaSemDesconto - vlrDesconto;
			return descontoCalculado;
		},

		proximoMetodo(index, metodo) {
			const vista = this.verificarVista(metodo); 
			let arrayAtual = vista ? this.formasPagamentoVista : this.formasPagamentoPrazo;
			if(index === arrayAtual.length - 1) {
				index = - 1;
				arrayAtual = vista ? this.formasPagamentoPrazo : this.formasPagamentoVista;
			}
			const cdTipoProximoMetodo = arrayAtual[index + 1].cdtipo;
			const input = document.getElementById(`metodo-${cdTipoProximoMetodo}`);
			if(input) {
				input.focus();
				input.select();
			}
		},

		retornarMetodo(index, metodo) {
			const vista = this.verificarVista(metodo); 

			let arrayAtual = vista ? this.formasPagamentoVista : this.formasPagamentoPrazo;

			if(index === 0) {
				arrayAtual = vista ? this.formasPagamentoPrazo : this.formasPagamentoVista;
				index = arrayAtual.length;
			}

			const cdTipoMetodoAnterior = arrayAtual[index - 1].cdtipo;
			const input = document.getElementById(`metodo-${cdTipoMetodoAnterior}`);

			if(input) {
				input.focus();
				input.select();
			}

		},

		verificarVista(metodo) {
			return (metodo.vistaprazo === 1 || metodo.vistaprazo === 3 && metodo.quantidadediasdeprazo === 0) ? true : false;
		},
		
		atualizarObservacao(observacao){
			if (observacao) this.observacao = observacao;
			this.$store.state.Venda.observacao = this.observacao;
			this.$forceUpdate();
		},
		liberarPagamento(pagamento) {
			this.$emit("liberarPagamento", new VendaPagamento(pagamento));
		},
		selecionarPagamento(pagamento) {
			try {
				if(!this.aplicaDescontoFormaPagamento && pagamento.desconto > 0) {
					return this.$store.commit("relatarErro", {
						mensagem: `A forma de pagamento possui ${pagamento.desconto}% de desconto, deseja trocar o desconto ?`,
						tipo: "polar",
						item: {
							liberarDescontoFormaPgto: true,
							pagamento: true,
						}
					});
				}
				if(pagamento.desconto > 0 && this.aplicaDescontoFormaPagamento) {
					const descFormaPgto = this.calculaDesconto(pagamento.desconto);
					if(Number(`${pagamento.valor}`.replace(",", ".") > Number(descFormaPgto.toFixed(2)))) {
						throw new Error(
							`A forma de pagamento está em promoção (${descFormaPgto.toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}), 
							esta tentando receber um valor maior que o total da venda, verifique`
						);
					}
				}
				if(Number(`${pagamento.valor}`.replace(",", ".") > this.totalVenda)){
					throw new Error("Você está recebendo um valor maior do que o total da venda, verifique");
				}
				if (pagamento?.elementoHTML?.readOnly) {
					return this.liberarPagamento(pagamento);
				}
				if (pagamento.ativo != "A") {
					throw new Error("Você está selecionano um método de pagamento que está inativo, verifique!");
				}
				if (pagamento.permiteconsumidorfinal == "N" && (this.$store.state.Venda.cliente.id == this.$store.state.ClientePadrao.id)) {
					throw new Error("Não é possível selecionar este método de pagamento para consumidor final.");
				}
				if(this.$store.state.Venda.cliente.classificacao.bloqueiavendaprazo && pagamento.valorVista == false && pagamento.cartao != "S" && pagamento.cheque != "S"){
					throw new Error("Não é possivel vender a prazo para a classificação do cliente.");
				}
				const metodo = document.getElementById("metodo-" + pagamento.cdtipo);
				metodo.blur();
				if (pagamento.ehPix === "S"){
					return this.$emit("verificarPix", pagamento);
				}
				if(pagamento.desconto > 0 && !this.aplicaDescontoFormaPagamento) {
					pagamento.desconto = 0;
				}
				this.emitirEvento(pagamento);
			} catch (error) {
				return this.$store.commit("relatarErro", {mensagem: error.message, alerta: true});
			}
		},
		emitirEvento(pagamento){
			this.$emit("selecionarPagamento", new VendaPagamento(pagamento), document.getElementById(`metodo-${pagamento.cdtipo}`));
		},
		apagaPagamento(metodo){
			if(!metodo.valor){
				this.$emit("apagaPagamento", metodo.cdtipo);
			}
		},
		mascara(e) {
			const regex = /[0-9,]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"Enter",
				"End",
				"Home",
				"Tab",
				"Up",
				"Down",
				null
			];
			let key = e.key || e.data;
			if (!regex.test(key) && !regexKey.includes(key)) {
				e.preventDefault();
			}
			if ((`${e.target.value}`.includes(",") || !`${e.target.value}`.length) && key == ","){
				e.preventDefault();
			}
		},

		verificarInput(e) {
			this.mascara(e);
		},

		
		verificarAlteracaoVenda() {
			if (this.$route.query.alteracao) {
				if (this.$store.state.Venda.financeiro?.length) {
					this.$store.state.Venda.financeiro.forEach(financa => {
						const forma = this.formasPagamento.find(metodo => metodo.cdtipo == financa.cdTipoPgto);
						if (forma) {
							const input = document.getElementById(financa.cdTipoPgto);
							input.value = financa.contarc > 0 ? financa.contarc : financa.credito;
							this.$emit("selecionarMetodo", {target: input, alteracao: this.$route.query.alteracao});
						}
					});
				}
			}
		},

		async buscarFormasPagamento() {
			await this.$store.dispatch("buscarFormasPagamentoVenda");
			const btnPagamento = document.getElementsByClassName("btnPagamento")[0];
			if(btnPagamento) {
				btnPagamento.focus();
			}
			this.verificarAlteracaoVenda();
		},

		cancelarPagamento(pagamento) {
			if (!pagamento) return;
			if (!pagamento.cdTipo) return;

			const pgtoEncontrado = this.formasPagamento?.find(pgto => pgto.cdtipo === pagamento.cdTipo);
			if(pgtoEncontrado) {
				pgtoEncontrado.valor = "";
			}
			if(pagamento.desconto) {
				this.$bus.$emit("cancelouPagamento/Desconto", pagamento);
			}
		},

		isClientePadrao(cliente) {
			const clientePadrao = this.$store.state.ClientePadrao;

			return clientePadrao.id === cliente.id ? true : false;
		},

		// aplicaDescontoFormaPagamento() {
		// 	const classiCliente = this.$store.state.Venda.cliente.classificacao;
		// 	const descontoClassificacao = this.$store.state.Venda.valorVista ? 
		// 		classiCliente?.descvista
		// 		: classiCliente?.descprazo;

		// 	console.log(descontoClassificacao);
		// 	if(!this.isClientePadrao(this.$store.state.Venda.cliente) && descontoClassificacao === 0) return true;
		// 	return false;
		// }
		confirmarPolar(item) {
			if(item.liberarDescontoFormaPgto) {
				this.aplicaDescontoFormaPagamento = true;
				this.selecionarPagamento(item.pagamento);
			}
		}
	},

	mounted() {
		this.verificaDescontoFormaPagamento();
		this.observacao = (this.$route.query.alteracao ? this.$store.state.Venda.observacao : "") || "";
		this.buscarFormasPagamento();
		this.$bus.$on("atualizarObservacao", this.atualizarObservacao);
		this.$bus.$on("confirmarPolar", this.confirmarPolar);
		this.$bus.$on("cancelouPagamento", this.cancelarPagamento);
	},

	destroyed() {
		this.$bus.$off("atualizarObservacao", this.atualizarObservacao);
		this.$bus.$off("cancelouPagamento", this.cancelarPagamento);
	}
};
</script>

<style scoped>
.main {
	padding: 0px 10px;
	background-color: var(--bg-secundario);
	box-shadow: var(--shadow-secundario);
}

.scrollable {
	background-color: var(--bg-app);
	box-shadow: var(--shadow-secundario);
	overflow-y: auto;
	height: auto;
}

.metodoPagamento {
	border: 1px solid #292929;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.btnPagamento {
	height: 100%;
	text-align: right;
	font-size: var(--txt-normal);
}

.separador {
	display: flex;
	justify-content: space-between;
	margin: 5px;
}

.inputValor {
	width: 100%;
	min-width: 190px;
	font-size: 14pt;
	text-align: right;
	align-self: flex-end;
}

h3, h1 {
	margin: 0px;
}

label, span {
	font-size: var(--txt-medio);
}

.scrollable .titlePagamentos{
	border: 1px solid #000;
	padding: 2px;
	font-weight: bold;
	text-transform: uppercase;
}

.descricao-promocao{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.desc-promo{
	font-weight: bold;
	color: red;
	font-size: 14px;
	margin-right: 5px;
}
@media screen and (max-width: 1100px){
	.container-valores{
		display: flex;
		flex-direction: column;
	}
	.valores{
		display: flex;
		width: 100%;
		flex-direction: row;
	}
	.valores .inputValor{
		margin-left: 5px;
		width: 100%;
	}
	.textarea{
		margin-right: 5px;
	}
}

@media screen and (max-width: 575px) {
	.valores{
		display: flex;
		width: 100%;
		flex-direction: column;
	}
	.inputValor{
		margin-top: 10px;
	}
	.row.w-100 {
		flex-direction: column;
	}

	.row.w-100 h2,
	.row.w-100 input {
		width: 100%;
		text-align: right;
	}

	.col.w-30 {
		width: 70%;
	}

	.desconto {
		flex-direction: column;
	}

	#rodapeAcao {
		flex-direction: row;
		margin: 5px 0px;
	}

	.btnPagamento {
		height: 100%;
	}

}

@media screen and (max-width: 380px) {
	* {
		font-size: var(--txt-pequeno) !important;
	}
}
</style>
