<template>
	<!-- COMPONENTE PARA CONTROLAR FILTROS -->
	<div class="container-filtro">
		<div class="controle-filtro">
			<div class="titulo">
				<i class="fas fa-filter" />
				<span>
					Selecione as opções de pesquisa
				</span>
			</div>
			<div class="button-mostrar-esconder">
				<button
					@click="showFilter" 
					class="min-plus-button" 
					v-if="!show"
				>
					<i class="fas fa-minus"  />
				</button>
				<button 
					@click="showFilter" 
					class="min-plus-button" 
					v-else
				>
					<i class="fas fa-plus"  />
				</button>
			</div>
		</div>
		<div class="content-filtro show" :id="`${modal?'filtro-container-modal':'filtro-container'}`">
			<div class="container-cards-filtro">
				<div class="scroll-container-filtro">
					<slot></slot>
				</div>
				<div class="botoes">
					<button 
						class="botao botao-acao" 
						id="botao-filtrar" 
						@click="$emit('filtrar')"
						style="max-width:70px"
					>
						<span>
							Filtrar
						</span>
					</button>
					<button 
						class="botao botao-cancelar" 
						@click="$emit('limpar')"
						style="max-width:80px"
					>
						<span>Limpar</span>
					</button>
					
					<div class="btn-dropdown">
						<div v-if="!itensNovo">
							<button 
								class="botao botao-adicionar" 
								id="botao-filtrar" 
								@click="handleAdicionar"
								v-if="mostrarBotaoNovo"
								style="max-width:60px"
							>
								<span>
									Novo
								</span>
							</button>	
							<div class="dropdown-add" ref="dropdownAdd"  v-if="habilitaDropdown">
								<span
									class="dropdown-item"
									@click="$emit(`${ddItem.event}`); handleDropDown()"
									v-for="(ddItem, index) in DropDownItens"
									:key="index"
								>
									{{ ddItem.title }}
								</span>
							</div>
						</div>
						<div class="row" v-else-if="mostrarBotaoNovo">
							<button 
								class="botao botao-adicionar" 
								id="botao-filtrar" 
								@click="handleAdicionar(item)"
								style="margin-right:10px"
								v-for="item in itensNovo"
								:key="item"
							>
								<span>
									{{item}}
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>			
		</div>
	</div>
</template>
<script>
// RECE UMA PROPS QUE CONTROLA O BOTAO PARA CADASTRAR NOVO
export default {
	name: "Filtro",

	props: {
		mostrarBotaoNovo: Boolean,
		itensNovo: Array,
		modal: Boolean,
		habilitaDropdown: Boolean,
		DropDownItens: Array,
	},

	data() {
		return {
			show: false,
		};
	},

	methods: {

		handleDropDown() {
			const dropdown = this.$refs.dropdownAdd;
			if(dropdown) {
				dropdown.classList.toggle("dropdown-active");
			}
		},

		showFilter() {
			this.show = !this.show;
			const container = this.modal ? document.getElementById("filtro-container-modal") : document.getElementById("filtro-container");
			container.classList.toggle("show");
			this.$emit("handleShowTable");
		},

		handleAdicionar(item) {
			if(this.habilitaDropdown) {
				this.handleDropDown();
			}
			this.$emit("adicionarNovo",item);
		}
	}
};

</script>
<style scoped>
.input-group{
	height: 33px;
}
.input-group input{
	height: 33px;
	font-size: 13px;
}
.input-group select{
	height: 33px;
	font-size: 13px;
}
*{
	font-size: 13px;
}
.container-filtro {
	display: flex;
	flex-direction: column;

	margin: 5px 5px;

	border-top: 5px solid var(--acao-background);
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	
	background-color: #f8f8f8;
	
	height: auto;
}
.controle-filtro {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	padding: 2px;

	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.titulo {
	display: flex;
	align-items: center;
}

.titulo i {
	font-size: 13px;
	color: var(--primario);
}
.button-mostrar-esconder button {
	background-color: var(--acao-background);
	
	border: none;
	outline: none;
	border-radius: 8px;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 20px;
	height: 20px;

	-webkit-box-shadow: -5px 6px 10px -9px rgba(0,0,0,0.75);
	-moz-box-shadow: -5px 6px 10px -9px rgba(0,0,0,0.75);
	box-shadow: -5px 6px 10px -9px rgba(0,0,0,0.75);
}

.content-filtro {
	max-height: 0px;
	position: relative;
	overflow: hidden;
	margin-top: -1px;
	transition: max-height .5s ease-in; 
	width: 100%;
}

.content-filtro.show {
	max-height: 500px;
	transition: max-height .5s ease-in; 
}


.container-cards-filtro {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	padding: 5px 10px;
}

.botoes{
	width: auto;
	display: flex;
	flex-direction: row;
	justify-content: end;
	gap: 10px;
	padding-bottom: 10px;
	margin-top: 10px;
}

.botoes button {
	width: 100%;
	max-height: 45px;
}

@media screen and (max-width: 425px) {
	.scroll-container-filtro {
		height: 20vh;
		overflow-y: auto;
		overflow-x: hidden !important;
		padding: 5px;
	}
}

.input-group:focus-within label,
.input-group input:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}

.input-group:focus-within label,
.input-group select:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}

.dropdown-add{
	position: relative;
	display: flex;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	width: 40px;
	height: 40px;
	gap: 5px;
	border-radius: 10px;
	padding: 5px;
	transition: all .2s ease;
	opacity: 0;
	font-size: 2px;
}

.dropdown-add span {
	cursor: pointer;
	width: 100%;
	padding: 0px 5px;
}

.dropdown-add span:hover {
	border-bottom: 1px solid #cecece;
}

.dropdown-active {
	transition: all .2s ease;
	opacity: 1;
	width: 155px;
	height: 90px;
	font-size: 16px;
}

.btn-dropdown {
	display: flex;
	flex-direction: row;
}
</style>