<template>
	<div class="container-page">
		<div class="container-filtro" v-if="!venda">
			<button class="botao botao-acao" @click="buscarNcmIncorreto">
				Buscar produtos com NCM incorreto
			</button>
			<i 
				class="fas fa-sync"
				style="color: var(--primario)"
				v-if="$store.state.Usuario.usuarioPrivilegiado"
				@click="updateTableNcm"
			/>
		</div>
		<div class="container-tabela">
			<table v-if="NcmsIncorretos && NcmsIncorretos.length > 0 && !loading">
				<thead>
					<tr>
						<th>
							Ação
						</th>
						<th>
							NCM Incorreto
						</th>
						<th>
							NCM Correto
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(ncm) in NcmsIncorretos" :key="ncm">
						<td class="text-center">
							<i 
								class="fas fa-eye"
								@click="buscarProdutosNcmIncorreto(ncm)"
							/>
						</td>
						<td>
							{{ ncm }}
						</td>
						<td>
							<input
								type="text"
								:id="`ncmcorreto-${ncm}`"
								maxlength="8"
								autocomplete="off"
							/>
							<i class="fas fa-check" @click="salvarNcmCorreto(ncm)" />
						</td>
					</tr>
				</tbody>
			</table>
			<div v-else-if="!loading && NcmsIncorretos.length === 0" class="col center" id="warning">
				<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
				<h1>Nenhum registro encontrado.</h1>
				<h2>Tente alterar ou diminuir os parâmetros de busca.</h2>
			</div>
			<div v-else class="col center spinner">
				<Loader />
			</div>
		</div>
		<Modal
			v-if="produtosNcmIncorreto.length > 0"
			@fecharModal="produtosNcmIncorreto = []"
			:dimensoes="{width: '65%', height: '80%'}"
			:idModal="'modalProdutosNcmIncorreto'"
		>
			<div class="container-modal">
				<div class="container-tabela-modal">
					<table>
						<thead>
							<tr>
								<th>Código</th>
								<th>Descrição</th>
								<th>NCM</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(produto) in produtosNcmIncorreto"
								:key="produto.cdproduto"
							>
								<td>
									{{ produto.cdproduto }}
								</td>
								<td>
									{{ produto.descricao }}
								</td>
								<td>
									{{ produto.ncm }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="botoes">
					<button class="botao botao-cancelar" @click="produtosNcmIncorreto = []" v-if="!loading">
						Fechar
					</button>
				</div>
			</div>
		</Modal>
	</div>
</template>
<script>
import Modal from "../components/Modal.vue";
import Loader from "../components/Loader.vue";
export default {
	name: "ConsultarNCM",
	
	components: {
		Modal,
		Loader
	},

	props: {
		venda: Boolean,
	},

	computed: {
		NcmsIncorretos() {
			return this.$store.state.NcmsIncorreto;
		}
	},

	data() {
		return {
			produtosNcmIncorreto: [],
			loading: false,
		};
	},

	methods: {
		async updateTableNcm() {
			await this.$store.dispatch("updateTableNcm");
		},

		async buscarNcmIncorreto() {
			this.loading = true;
			try {
				await this.$store.dispatch("getNcmsIncorretos");
				this.loading = false;
			}
			catch (error) {
				console.error(error);
			}
		},

		async buscarProdutosNcmIncorreto(ncm) {
			try {
				this.produtosNcmIncorreto = await this.$store.dispatch("buscarProdutosNcmIncorreto", ncm);

			} catch (error) {
				console.error(error);
			}
		},
		async salvarNcmCorreto(ncm) {
			const inputValue = document.getElementById(`ncmcorreto-${ncm}`)?.value;
			

			if(inputValue === "") {
				this.$store.commit("relatarErro", {mensagem: "Por favor insira um NCM para continuar!"});
				return;
			}
			

			const response = await this.$store.dispatch("verificaNcm", {ncm: inputValue});

			
			if(response?.invalido) {
				this.$store.commit("relatarErro", {mensagem: response.mensagem});
				return;
			}
			const res = await this.$store.dispatch("updateCorrectNcm", {ncmIncorreto: ncm, ncmCorreto: inputValue});
			
			this.$store.commit("relatarErro", {mensagem: res.mensagem, sucess: true});
			if(this.venda){
				this.$emit("atualizouNcm");
			} else{
				await this.buscarNcmIncorreto();
			}
		}

	}
};

</script>
<style scoped>
.container-page {
	width: 100%;
	padding: 10px 10px;
}

.container-tabela{
	width: 100%;
	height: 75vh;
	overflow-y: auto;
	padding: 20px 10px;
}
table {
	width: 100%;
}
.text-center {
	text-align: center;
}

.fa-eye {
	color: var(--primario);
}

.fa-check{
	color: var(--salvar-borda);
}

.container-modal{
	padding: 20px 5px;
	width: 100%;
}

.container-tabela-modal {
	width: 100%;
	height: 65vh;
	overflow-y: auto;
}

.botoes{
	width: 100%;
}
.botoes button {
	width: 100%;
}

.spinner {
	width: 100%;
	height: 100%;
}
</style>