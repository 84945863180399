var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"identificador-vendedor-cliente"},[_c('img',{staticClass:"logo-bds",attrs:{"src":_vm.imagemEmpresa}}),_c('div',{staticClass:"identificador-vendedor"},[_c('h3',{on:{"click":_vm.trocarVendedor}},[_vm._v(" "+_vm._s(!_vm.Venda.vendedor ? "Selecionar vendedor" : `Vendedor: ${_vm.Venda.vendedor.nome}`)+" ")])]),_c('div',{staticClass:"identificador-caixa my-5"},[_c('h3',{on:{"click":_vm.trocarCaixa}},[_vm._v(" Caixa: "+_vm._s(_vm.Caixa.caixa.identificador)+" ")])]),_c('div',{staticClass:"identificador-cliente my-5"},[_c('i',{staticClass:"fas fa-address-book",on:{"click":_vm.trocarCliente}}),_c('h3',{on:{"click":_vm.trocarCliente}},[_vm._v(" Cliente: "+_vm._s(_vm.Venda.cliente ? _vm.Venda.cliente.id : "0")+" - "+_vm._s(_vm.Venda.cliente ? (_vm.Venda.nomeCliente && _vm.Venda.cliente.nome != _vm.Venda.nomeCliente && _vm.Venda.nomeCliente != "") ? _vm.Venda.nomeCliente : _vm.Venda.cliente.nome : "NÃO ENCONTRADO")+" ")]),(
					_vm.Venda.cliente && _vm.$store.state.ClientePadrao 
					? _vm.Venda.cliente.id != _vm.$store.state.ClientePadrao.id : false
				)?_c('i',{staticClass:"fas fa-wallet",on:{"click":function($event){return _vm.$emit('consultarContas', _vm.Venda.cliente)}}}):_vm._e(),_c('i',{staticClass:"fas fa-id-card",on:{"click":_vm.solicitarIncluirObservacao}}),(_vm.$store.state.Usuario.usuarioPrivilegiado)?_c('button',{staticClass:"none",on:{"click":_vm.abrirDataEmissao}},[_c('i',{staticClass:"fas fa-calendar-alt"})]):_vm._e()])]),(_vm.inserirObservacao && !_vm.$route.query.baixarCondicional)?_c('Modal',{attrs:{"dimensoes":{width:'auto', height: '85vh'},"idModal":'cupom-dados'},on:{"fecharModal":_vm.cancelouInformacoes}},[_c('CupomDados',{attrs:{"clienteVenda":_vm.clienteVenda},on:{"fecharModal":function($event){_vm.inserirObservacao = false},"salvarInformacoes":_vm.salvouInformacoes,"cancelarInformacoes":_vm.cancelouInformacoes}})],1):_vm._e(),(_vm.trocarDataEmissao)?_c('Modal',{attrs:{"dimensoes":{
			width: 'auto',
			height: 'auto',
		},"idModal":'data-emissao'},on:{"fecharModal":function($event){_vm.trocarDataEmissao = !_vm.trocarDataEmissao}}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataEmissao),expression:"dataEmissao"}],attrs:{"type":"date","id":"dataEmissao"},domProps:{"value":(_vm.dataEmissao)},on:{"input":[function($event){if($event.target.composing)return;_vm.dataEmissao=$event.target.value},_vm.alterarDataEmissao],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.fecharDataEmissao.apply(null, arguments)}}}),_c('label',{attrs:{"for":"dataEmissao"}},[_vm._v(" Data emissão ")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }