import {doRequest} from "./http.js";
import {ContaAgenda} from "../models/ContaPagar.js";
export default{
	state: {
		Agenda: {
			Contas: []
		},
	},
	actions: {
		async buscarContasPorVencimento({ state, commit }, payload) {
			try {
				const {
					dataVencF,
					dataVencI,
					condicao,
					sort,
					sortOrder,
				} = payload;

				let url = "contasAPagar/agrupadoPorVencimento?";
				url += `cdempresa=${state.Empresa.id}`;
				url += `${dataVencF ? `&dataVencF=${dataVencF}` : ""}`;
				url += `${dataVencI ? `&dataVencI=${dataVencI}` : ""}`;
				url += `${condicao ? `&condicao=${condicao}` : ""}`;
				url += `${sort ? `&sort=${sort}` : "&sort=datavenc"}`;
				url += `${sortOrder ? `&sortOrder=${sortOrder}` : "&sortOrder=ASC"}`;

				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache",
				}, commit);
				if(data?.mensagem) {
					throw new Error(data.mensagem);
				}
				if(data && (!data.erro || !data.error || !data.mensagem)) {
					data?.forEach(p => {
						p.condicao = payload.condicao;
					});
					commit("preencherListaDeEventos", data);
					return;
				}
				return null;
			} catch (error) {
				console.log(error);
				commit("relatarErro", {
					mensagem: error.message
				});
			}
		},

		async buscarContasPorDia( {state, commit}, payload ) {
			try {
				const {
					dataVencF,
					dataVencI,
					condicao,
					sortOrder,
					sort,
				} = payload;
				let url = "contasAPagar/agrupadoPorVencimento?";
				url += `cdempresa=${state.Empresa.id}`;
				url += `${dataVencF ? `&dataVencF=${dataVencF}` : ""}`;
				url += `${dataVencI ? `&dataVencI=${dataVencI}` : ""}`;
				url += `${condicao ? `&condicao=${condicao}` : ""}`;
				url += `${sort ? `&sort=${sort}` : "&sort=datavenc"}`;
				url += `${sortOrder ? `&sortOrder=${sortOrder}` : "&sortOrder=ASC"}`;

				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache",
				}, commit);
				if(data?.mensagem) {
					throw new Error(data.mensagem);
				}
				if(data && (!data.erro || !data.error || !data.mensagem)) {
					data?.forEach(p => {
						p.condicao = payload.condicao;
					});
					return data.map(conta => new ContaAgenda(conta));
				}
				return null;
			} catch (error) {
				console.log(error);
			}
		}
	},
	mutations :{
		preencherListaDeEventos(state, payload) {
			if(payload?.length > 0) {
				state.Agenda.Contas = payload.map(conta =>  new ContaAgenda(conta));
			}
		},
	}
};