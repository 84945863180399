export const selecionarTeclado = ({event, index, array}) => {
	index = index ?? -1;
	let selecionou = false;
	if(event.repeat) return;
	if(event?.key === "Backspace"){
		index = -1;
		return {
			index: index
		};
	}
	if(event?.repeat) return;
	if (event?.key == "ArrowDown"){
		if (index < array?.length - 1) {
			index++;
		} else {
			index = 0;
		}
	}
	if (event?.key == "ArrowUp") {
		if (index != 0) {
			index--;
		} else {
			index = array?.length - 1;
		}
	}
	if (event?.key == "Enter") {
		selecionou = true;
		return {
			selecionou: selecionou,
			index,
		};
	}
	return { 
		index: index
	};
};

export const validarCPF = (cpf) => {
	cpf = cpf.replace(/[^\d]/g, "");

	if (cpf.length !== 11) {
		return false;
	}

	let soma = 0;
	for (let i = 0; i < 9; i++) {
		soma += parseInt(cpf.charAt(i)) * (10 - i);
	}
	let digito1 = 11 - (soma % 11);
	if (digito1 > 9) {
		digito1 = 0;
	}

	soma = 0;
	for (let i = 0; i < 10; i++) {
		soma += parseInt(cpf.charAt(i)) * (11 - i);
	}
	let digito2 = 11 - (soma % 11);
	if (digito2 > 9) {
		digito2 = 0;
	}

	if (parseInt(cpf.charAt(9)) !== digito1 || parseInt(cpf.charAt(10)) !== digito2) {
		return false;
	}

	return true;

};

export const proximoCampo = (id) => {
	try {
		if (!id) {
			throw new Error("Falta o atribuito id!");
		}
		setTimeout(() => {
			const input = document.getElementById(id);
			if (input) {
				input.focus();
				if(input.click){
					input.click();
				}
				if (input.select) {
					input.select();
				}
			}
		},20);
	} catch (error) {
		console.log(error);
	}
};