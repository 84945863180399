<template>
	<div class="container-page" id="container-page">
		<slot></slot>
	</div>
</template>
<script>
export default{
	name: "ContainerPage",
};
</script>
<style scoped>
.container-page{
	height: 90vh;
	width: 100%;
	overflow: hidden !important;
}

@media screen and (max-width: 425px) {
	.container-page {
		height: 85vh;
		width: 100vw;
	}
}
</style>