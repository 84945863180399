<template>
    <div id="TelaConta">
		<div class="titulo">
			<h1>Dados da Conta</h1>
		</div>
		<div class="containerCadastro">
			<div class="containerBanco">
				<div class="input-group" id="nomeBanco">
					<input 
						v-model="ContaCadastrada.banco" @input="setUpperCase" autocomplete="off" 
						maxlength="10" id="banco" type="text" placeholder=" ">
					<label for="banco">Banco</label>
				</div>
				<div class="input-group" id="nomeAgencia">
					<input 
						v-model="ContaCadastrada.agencia" @input="setUpperCase" autocomplete="off" 
						maxlength="10" id="agencia" type="text" placeholder=" ">
					<label for="agencia">Agência</label>
				</div>
				<div class="input-group" id="contaCorrente">
					<input 
						v-model="ContaCadastrada.cc" @input="setUpperCase" autocomplete="off" 
						maxlength="20" id="cc" type="text" placeholder=" ">
					<label for="cc">Conta Corrente</label>
				</div>
				<div class="input-group-switch">
					<label class="label-switch">Aceita Pix</label>
					<label class="container">
						<input
							type="checkbox"
							v-model="ContaCadastrada.aceitapix"
							class="esconder-input"
							id="esconder-input"
						/>
						<span class="thumb-container">
							<span class="thumb"></span>
						</span>
							<span class="track"></span>
					</label>
				</div>
			</div>
			<div class="contaNomeTipo">
				<div class="input-group" id="contaNome">
					<input 
						v-model="ContaCadastrada.nome" @input="setUpperCase" autocomplete="off" 
						maxlength="30" id="nome" type="text" placeholder=" ">
					<label for="nome">Nome da Conta</label>
				</div>
				<div id="contaAtivo" @click="mudarAtivo('ativo', 'A', 'I')">
					<label class="lg-view mx-5 text-right w-auto" for="contaAtivo">Ativo:</label>
					<label class="sm-view mx-5 text-right w-auto" for="contaAtivo">Ativo</label>
					<i class="far fa-times-circle text-left" v-if="ContaCadastrada.ativo == 'I'" style="color: red;">Não</i>
					<i class="far fa-check-circle text-left" v-else style="color: green">Sim</i>
				</div>
				<div class="input-group" id="contaTipo">
					<select v-model="ContaCadastrada.tipo" id="tipo">
						<option value="1">Conta Corrente</option>
						<option value="2">Devolução</option>
						<option value="3">Caixa da Empresa</option>
					</select>
					<label for="contaTipo">Tipo da conta</label>
				</div>
				<div class="input-group" id="nomeEmpresa" v-if="$store.state.Usuario.usuarioPrivilegiado">
					<select id="cdempresa" v-model="ContaCadastrada.cdempresa">
						<option v-for="empresa in Empresas" :key="empresa.id" :value="empresa.id">{{`${empresa.id} - ${empresa.nome}`}}</option>
					</select>
					<label for="cdempresa">Empresa</label>
				</div>
			</div>
		</div>
		<div class="botoes">
			<button @click="cancelarEdicao" class="botao botao-cancelar">
				<span class="lg-view">Cancelar [Alt + C]</span>
				<span class="sm-view">Cancelar</span>
			</button>
            <button @click="salvarConta" class="botao botao-adicionar">
                <span class="lg-view">Salvar [Alt + S]</span>
				<span class="sm-view">Salvar</span>
            </button>
        </div>
	</div>
</template>
<script>
export default {
	name: "CadastroContas",

	props: {
		Conta: Object,
	},

	computed: {
		Empresas(){
			return this.$store.state.Empresas;
		}
	},

	data() {
		return {
			ContaCadastrada: {
				banco: "",
				agencia: "",
				cc: "",
				nome:  "",
				tipo: "1",
				cdempresa: "",
				ativo: "A",
				aceitapix: false,
			},
		};
	},

	methods: {
		mudarAtivo(campo, letra1, letra2) {
			if (this.ContaCadastrada[campo] == letra1) {
				this.ContaCadastrada[campo] = letra2;
			}
			else {  
				this.ContaCadastrada[campo] = letra1;
			}
		},

		setUpperCase(e) {
			if (e.target.id == "banco" || e.target.id == "agencia" || e.target.id == "cc") {
				this.ContaCadastrada[e.target.id] = this.ContaCadastrada[e.target.id].toUpperCase();
			}
			else {
				this.ContaCadastrada[e.target.id] = this.ContaCadastrada[e.target.id].toUpperCase();
			}
		},

		setOnlyNumbers(e) {
			const regex = /[0-9,]/;
			if (!regex.test(e.data) && e.data != null) {
				this.ContaCadastrada[e.target.id] = this.ContaCadastrada[e.target.id].substring(0, this.ContaCadastrada[e.target.id].length -1);
			}
		},

		async salvarConta() {
			if (!this.ContaCadastrada.banco) {
				return this.$store.commit("relatarErro", {mensagem: "Campo banco não pode estar vazio"});
			}
			
			if (!this.ContaCadastrada.agencia) {
				return this.$store.commit("relatarErro", {mensagem: "Campo agência não pode estar vazio"});
			}

			if (!this.ContaCadastrada.cc) {
				return this.$store.commit("relatarErro", {mensagem: "Campo conta corrente não pode estar vazio"});
			}

			if (!this.ContaCadastrada.nome) {
				return this.$store.commit("relatarErro", {mensagem: "Campo nome da conta não pode estar vazio"});
			}

			if (this.Conta && this.Conta.novo) {
				this.$emit("cancelarEdicao");
				return this.$store.dispatch("adcionarConta", {...this.ContaCadastrada});
			}

			if (JSON.stringify(this.ContaCadastrada) != JSON.stringify(this.Conta)) {
				this.$emit("cancelarEdicao");
				return this.$store.dispatch("editarConta", {...this.ContaCadastrada, ContaAnterior: {
					banco: this.Conta.banco,
					agencia: this.Conta.agencia,
					cc: this.Conta.cc,
					tipo: this.Conta.tipo
				}});
			}
		},

		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},
	},

	mounted() {
		if (this.Conta) {
			if (this.Conta.novo) {
				return;
			}
			this.ContaCadastrada = {
				banco: this.Conta.banco,
				agencia: this.Conta.agencia,
				cc: this.Conta.cc,
				nome: this.Conta.nome,
				tipo: this.Conta.tipo,
				cdempresa: this.Conta.cdempresa,
				ativo: this.Conta.ativo,
				aceitapix: this.Conta.aceitapix,
			};
			
			["banco", "agencia", "cc"].forEach(key => {
				document.getElementById(key).disabled = true;
			});
		}
	},
};
</script>
<style scoped>
input {
	width: 100%;
}

.containerCadastro {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	flex-grow: 1;
	width: 100%;
	gap: 15px;
	margin-top: 10px;
}

.contaNomeTipo {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.input-group{
	width: 25.33%;
}


.containerBanco {
	width: 100%;
	display: flex;
	flex-direction: row;
}

#TelaConta {
    display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin: 0px auto;
	flex-grow: 1;
}

.input-group label {
	height: 13px;
}

.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}

.botoes .botao {
	width: 45%;
}
.lg-view {
	display: inline-block;
}

.sm-view {
	display: none;
}

@media screen and (max-width: 1024px) {
	#contaTipo {
		width: 22%;
	}
}

@media screen and (max-width: 768px) {
	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	#contaNome {
		width: 83%;
	}

	#contaTipo {
		width: 43%;
	}

	#nomeEmpresa {
		width: 50%;
	}

	.contaNomeTipo {
		flex-wrap: wrap;
		gap: 10px;
	}

	.botoes-controle {
		gap: 15px;
	}
}

@media screen and (max-width: 440px) {
	.titulo {
        font-size: 12pt;
    }

	.containerCadastro {
		gap: 10px;
	}

	.containerBanco {
		flex-wrap: wrap;
		gap: 10px;
	}

	#nomeBanco {
		width: 55%;
	}

	#nomeAgencia {
		width: 33%;
	}

	#contaCorrente {
		width: 100%;
	}

	#contaNome {
		width: 70%;
	}

	#contaAtivo {
		width: 20%;
		margin-right: 10px;
	}

	#contaTipo {
		width: 100%;
	}

	#nomeEmpresa {
		width: 100%;
	}

}

@media screen and (max-width: 424px) {
	#nomeBanco {
		width: 100%;
	}

	#nomeAgencia {
		width: 100%;
	}

	#contaNome {
		width: 72%;
	}

	#contaAtivo {
		width: 15%;
	}
}
</style>