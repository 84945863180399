<template>
	<!-- <article v-if="!this.novaTransferencia" class="tabela-transferencia">
		<TabelaTransferenciaEstoque  v-on:abrirCadastro="cadastrarTransferencia"/>
	</article> -->
	<article class="article-cadastro">
		<div class="container-transferir-estoque">
			<h2 style="text-align:center" class="title-pagina">Transferência de Estoque</h2>
			<div class="containerButton">
				<button class="botao botao-acao" @click="finalizarTransf" v-if="!transferenciaFinalizada && !novaTransferencia.novo"> Finalizar Transferência</button>
			</div>
			<div class="container-transferencia">
				<div class="acoes-principal" v-if="!transferenciaFinalizada">
					<button class="botao botao-acao fas fa-cog" id="botaoModalEmpresa" @click="modalOpcoes = true" style="margin-top: 5px;">
						<span style="font-weight: normal;">&nbsp;&nbsp;Configurações</span>
					</button>
					<div class="produto-linha row" id="codProdQtd">
						<div class="input-group" for="inputConsultar">
							<input type="text" placeholder=" " id="inputConsultar" v-model="inputConsultar"
							autocomplete="off" @keyup="iniciarTimer"/>
							<label for="inputConsultar">Cód. prod.</label>
						</div>
						<button class="botao botao-acao" @click="indexListaProduto = 0">
							<i class="fas fa-search" style="color: var(--primario)"></i>
						</button>
						<div class="input-group" id="qtdTransferirDiv" v-if="this.produtoAdicionado.length >= 1" for="qtdTransferir">
							<input type="text" placeholder=" " id="qtdTransferir" v-model="qtdTransferente" @keydown=" transferirProdutos"
								autocomplete="off" enterkeyhint="done"/>
							<label for="qtdTransferir">Qtd.</label>
						</div>
					</div>
					<div class="input-group" id="selectAcaoMassa">
						<select v-model="selectAcao" @keydown="proximoCampo('inputTransferir')">
							<option value="acaoEmMassa">Selecione uma ação em massa</option>
							<option value="removerProdutos">Remover Produtos Selecionados</option>
							<option value="transferirProdutos">Transferir Produtos Selecionados</option>
						</select>
					</div>
					<button id="aplicar" class="botao botao-adicionar" v-if="selectAcao == 'removerProdutos'" @click="removerProdutos">
						<label for="aplicar" style="cursor: pointer;">Aplicar</label>
					</button>
					<div class="transferir" v-if="selectAcao == 'transferirProdutos'">
						<div class="input-group" style="justify-content: center;">
							<input type="text" id="inputTransferir" placeholder=" " style="font-size: 12pt; width: 60px; text-align:center;"
								autocomplete="off"/>
							<label for="inputTransferir">Qtd</label>
						</div>
						&nbsp;
						<button id="aplicarQtd" class="botao botao-adicionar" @click="transferirProdutos">
							<label for="aplicarQtd" style="cursor: pointer;">Aplicar</label>
						</button>
					</div>
					<div  class="contagem-produtos" v-if="!transferenciaFinalizada">
						<label class="lg-view">Produtos para transferência: {{ this.produtoAdicionado.length}}</label>
						<label class="sm-view">Produtos p/ transf.: {{ this.produtoAdicionado.length }}</label>
						<label>Produtos selecionados: {{ this.produtosSelecionados }}</label>	
					</div>
				</div>
			</div>
			<div class="produto-selecionado-linha">
				<table v-if="produtoAdicionado.length > 0" id="table" class="table">
					<thead>
						<tr>
							<td v-if="!transferenciaFinalizada">Selecionar</td>
							<td v-if="!transferenciaFinalizada">Ação</td>
							<td>Seq.</td>
							<td>Cód</td>
							<td>Produto</td>
							<td>Saldo Estoque</td>
							<td>Qtd. <span v-if="!transferenciaFinalizada">Transferencia</span> <span v-else>Transferiu</span></td>
							<td>$ Custo Fixo</td>
							<td>Total Custo</td>
							<td>% Margem</td>
							<td>$ Prazo</td>
							<td>% Desc.</td>
							<td>$ Vista</td>
							<td>Total Prazo</td>
							<td>Total Vista</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(produto, index) in produtoAdicionado" :key="index">
							<td v-if="!transferenciaFinalizada"><input @change="contarSelecionados(produto)" type="checkbox" v-model="produto.checked" id="checkbox" v-if="!transferenciaFinalizada"/></td>
							<td @click="solicitarExcluirItem(produto, index)" v-if="!transferenciaFinalizada"><i class="fas fa-trash" style="color: #f00" v-if="!transferenciaFinalizada"></i></td>
							<td>{{index +1}}</td>
							<td>{{produto.idProduto}}</td>
							<td>{{produto.produtoDesc}}</td>
							<td>{{produto.saldoEstoque}}</td>
							<td><input type="text" v-model="produto.qtdTabela" id="qtdTransferir" style="text-align: center;"  :readonly="transferenciaFinalizada" @input="recalcularItemTabela('quantidade', produto)" v-mascaraFloat @keyup.enter="proximoCampo('custofixo')" v-if="!transferenciaFinalizada"><span v-else>{{produto.qtdTransferir}}</span></td>
							<td>
								<input type="text" v-model="produto.custoFixoTabela" id="custofixo" class="input-tabela" @input="recalcularItemTabela('custofixo', produto)" :readonly="selectTransferencia === 'D' || transferenciaFinalizada == true" v-mascaraFloat @keyup.enter="proximoCampo('margem')"/>
							</td>
							<td>
								{{Number(Number(`${produto.qtdTabela || 1}`.split(".").join("").replace(",", ".")) * Number(produto.custoFixoProduto || 0)).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}
							</td>
							<td>
								<input type="text" v-model="produto.margemTabela" id="margem" class="input-tabela" @input="recalcularItemTabela('maselectTransferergem', produto)" :readonly="selectTransferencia === 'D' || transferenciaFinalizada" v-mascaraFloat @keyup.enter="proximoCampo('vlrPrazo')"/>
							</td>
							<td>
								<input type="text" v-model="produto.vlrPrazoTabela" id="vlrPrazo" class="input-tabela" @input="recalcularItemTabela('prazo', produto)" :readonly="selectTransferencia === 'D' || transferenciaFinalizada" v-mascaraFloat @keyup.enter="proximoCampo('desconto')"/>
							</td>
							<td>
								<input type="text" v-model="produto.descProdutoTabela" id="desconto" class="input-tabela" @input="recalcularItemTabela('desconto', produto)" :readonly="selectTransferencia === 'D' || transferenciaFinalizada" v-mascaraFloat @keyup.enter="proximoCampo('vlrVista')">
							</td>
							<td>
								<input type="text" v-model="produto.vlrVistaTabela" id="vlrVista" class="input-tabela" @input="recalcularItemTabela('vista', produto)" :readonly="selectTransferencia === 'D' || transferenciaFinalizada" v-mascaraFloat @keyup.enter="proximoCampo('inputConsultar')"/>
							</td>
							<td>{{Number(Number(produto.valorPrazo || 0) * Number(`${produto.qtdTabela || 1}`.replace(".", "").replace(",", "."))).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
							<td>{{Number(Number(produto.valorVista || 0) * Number(`${produto.qtdTabela || 1}`.replace(".", "").replace(",", "."))).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			&nbsp;
			<div class="botoes">
				<button class="botao botao-cancelar" @click="cancelarTransferencia" >Cancelar</button>
				<button class="botao botao-adicionar" @click="salvarTransferencia" v-if="novaTransferencia.novo">Cadastrar</button>
				<button class="botao botao-adicionar" @click="salvarTransferencia" v-else-if="!transferenciaFinalizada">Salvar</button>
				<button class="botao botao-acao" id="voltar" @click="cancelarTransferencia" v-else>Voltar</button>
			</div>
			<Modal v-if="indexListaProduto >= 0" v-on:fecharModal="indexListaProduto = -1">
				<consulta-produtos 
					v-if="buscaProduto"
					:Produtos="listadeproduto"
					@selecionarProduto="selecionarProduto"
					:buscarTodosPorDescricao="true" 
					@selecionarTodos="selecionarTodos" 
					:height="'55vh'"
				/>
				<!-- <Paginacao tipoConteudo="Produtos" v-if="buscaProduto" :desabilitarNovo="true" :buscarTodosPorDescricao="true" v-on:selecionarTodos="selecionarTodos">
					<ListagemProdutos v-bind:Produtos="listadeproduto" tipoConteudo="Produtos" v-on:selecionarProduto="selecionarProduto" :desabilitarAcao="true" />
				</Paginacao> -->


			</Modal>

			<Modal v-if="empresasEscolher"  v-on:fecharModal="fecharModal" :dimensoes="{width:'auto', height: 'auto'}" :iconeFechar="true">
				<div class="tabela-empresas-selecionar">
					<table style="width: 100%;">
						<thead>
							<tr>
								<td>Cód.</td>
								<td>Empresa</td>
							</tr>
						</thead>
						<tbody>
							<tr class="itemList" v-for="(empresa, index) in Empresas" :key="index" @click="selecionouEmpresa(empresa)">
								<td>
									{{empresa.id}}
								</td>
								<td>
									{{empresa.nome}}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<button class="btn btn-cancelar" @click="fecharModal">Fechar</button>
			</Modal>
		</div>

		<Modal v-if="modalOpcoes" v-on:fecharModal="fecharModalOpcoes" :dimensoes="{width:'80%', height: 'auto'}" :idModal="'Config'">
			<div class="infos-container-modal">
				<div class="infos-linha">
					<div class="input-group">
						<input type="text"  class="inputInfo" id="cdTransferencia" placeholder=" "  v-model="cdTransferencia" disabled>
						<label for="cdTransferencia" class="lg-view">Código</label>
						<label for="cdTransferencia" class="sm-view">Cód.</label>
					</div>
					<div class="input-group">
						<input type="date" class="inputInfo" v-model="data" v-if="!transferenciaFinalizada" @blur="saiuData">
						<input type="text" class="inputInfo" :value="data.split('-').reverse().join('/')" readonly v-else>
						<label>Data</label>
					</div>
					<div class="input-group">
						<input type="text" class="inputInfo" id="inputUsuario" placeholder=" " v-model="inputUsuario" readonly>
						<label for="inputUsuario">Usuário</label>
					</div>
					<div class="input-group">
						<input type="text" class="inputInfo" id="valorTotalPrazo" placeholder=" " :value="valorTotalCusto" readonly>
						<label for="valorTotalPrazo" class="lg-view">Total Custo</label>
						<label for="valorTotalPrazo" class="sm-view">T. Custo</label>
					</div>
					<div class="input-group">
						<input type="text" class="inputInfo" id="valorTotalPrazo" placeholder=" " :value="valorTotalPrazo" readonly>
						<label for ="valorTotalPrazo" class="lg-view">Total Prazo</label>
						<label for="valorTotalPrazo" class="sm-view">T. Prazo</label>
					</div>
					<div class="input-group">
						<input type="text" class="inputInfo" id="valorTotalVista" placeholder=" " :value="valorTotalVista" readonly>
						<label for="valorTotalVista" class="lg-view">Total Vista</label>
						<label for="valorTotalVista" class="sm-view">T. Vista</label>
					</div>
				</div>
				<div class="infos-linha-2">
					<div class="infos-item containerTipos">
						<div class="input-group selectTransferencia">
							<select v-model="selectTransferencia" @change="recalcularValores" :disabled="transferenciaFinalizada">
								<option value="N">Normal</option>
								<option value="V">Venda</option>
								<option value="D">Devolução</option>
							</select>
							<label class="lg-view">Tipo transferência</label>
							<label class="sm-view">Tipo transf.</label>

						</div>
						<div class="containerValores">
							<label>Valores:</label>
							<div class="checkboxContainer">
								<input type="checkbox" v-model="inputValorCusto" id="inputCusto" :disabled="transferenciaFinalizada" />
								<label for="inputCusto" class="lg-view">Valor de custo</label>
								<label for="inputCusto" class="sm-view">de custo</label>
							</div>
							<div class="checkboxContainer">
								<input type="checkbox" v-model="inputValorVenda" id="inputVenda" :disabled="transferenciaFinalizada" />
								<label for="inputVenda" class="lg-view">Valor de venda</label>
								<label for="inputVenda" class="sm-view">de venda</label>
							</div>
						</div>
					</div>
					<div class="empresas-container">
						<div class="empresa-box">
							<table>
								<thead>
									<tr>
										<td>Cód</td>
										<td>Empresa Origem</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{this.EmpresaSel.id}}</td>
										<td>{{this.EmpresaSel.nome}}</td>
									</tr>
								</tbody>
							</table>
							<button class="botao botao-acao" @click="selecionarEmpresa" v-if="this.empresaNome == ''">
								<span class="lg-view">Selecionar Empresa Destino</span>
								<span class="sm-view">Empresa Destino</span>
							</button>

							<table v-if="this.empresaNome != ''">
								<thead>
									<tr>
										<td>Cód.</td>
										<td>Empresa Destino</td>
										<td v-if="!transferenciaFinalizada">Trocar</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{this.empresaId}}</td>
										<td id ="nomeEmpresaDestino">{{this.empresaNome}}</td>
										<td @click="selecionarEmpresa" v-if="!transferenciaFinalizada"><i class="fas fa-edit" style="color: var(--primario);"></i></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<Modal v-if="empresasEscolher"  v-on:fecharModal="fecharModal" :dimensoes="{width:'auto', height: 'auto'}" :iconeFechar="true">
					<div class="tabela-empresas-selecionar">
						<table style="width: 100%;">
							<thead>
								<tr>
									<td>Cód.</td>
									<td>Empresa</td>
								</tr>
							</thead>
							<tbody>
								<tr class="itemList" v-for="(empresa, index) in Empresas" :key="index" @click="selecionouEmpresa(empresa)">
									<td>
										{{empresa.id}}
									</td>
									<td>
										{{empresa.nome}}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<button class="botao botao-cancelar" @click="fecharModal" style="align-self: center;">Fechar</button>
				</Modal>
			</div>
		</Modal>
	</article>
</template>
<script>
// import Paginacao from "./Paginacao.vue";
// import ListagemProdutos from "./ListagemProdutos.vue";
import Modal from "../components/Modal.vue";
import { dateFormatISO } from "../utils/dateformat";
import ConsultaProdutos from "./ConsultaProdutos.vue";


export default{
	name: "ProdutoTransferencia",
	
	props: {
		novaTransferencia: Object,
	},
	components:{
		Modal,
		// Paginacao,
		// ListagemProdutos,
		ConsultaProdutos,
	},
	data(){
		return{
			timerCarregar: null,

			qtdTransferente: 0,
			selectAcao: "acaoEmMassa",


			selectTransferencia: "N",
			inputValorCusto: false,
			inputValorVenda: false,

			buscaProduto: true,
			disable: false,
			transferenciaFinalizada: false,
			qtdTotal: 0,

			listaProdutos: 0,
			empresasEscolher: false,
			empresaId: "",
			empresaNome: "",
			inputConsultar: "",
			cdEmpresaDestino: "",
			produtoEncontrado: null,
			cdTransferencia: "",
			produtosSelecionados: 0,
			inputUsuario: "",
			totalCusto: Number(0).toLocaleString("pt-BR", {style: "currency", currency:"BRL"}),
			totalPrazo: Number(0).toLocaleString("pt-BR", {style: "currency", currency:"BRL"}),
			totalVista: Number(0).toLocaleString("pt-BR", {style: "currency", currency:"BRL"}),
			data: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
			dataInicial: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
			timer: null,
			checked: false,
			//Produto
			produtoAdicionado: [

			],
			indexListaProduto: -1,

			produtoEditando: {},

			modalOpcoes: false,
		};
	},
	
	computed: {
		
		listadeproduto(){
			return this.$store.state.Produtos;
		},
		Empresas(){
			return this.$store.state.Empresas.filter(emp=> emp.id !== this.EmpresaSel.id);
		},
		EmpresaSel(){
			return this.$store.state.Empresa;
		},
		Precos(){
			return this.$store.state.PrecoAll;
		},
		valorTotalVista(){
			let total = 0;
			this.produtoAdicionado.forEach(item =>{
				total += Number(Number (`${item.qtdTabela}`.replace(".", "").replace(",", ".") || 0) * Number(item.valorVista));
			});
			return Number(total.toFixed(2)).toLocaleString("pt-BR", {style: "currency", currency: "BRL"});
		},
		valorTotalPrazo(){
			let total = 0;
			this.produtoAdicionado.forEach(item =>{
				total += Number(Number(`${item.qtdTabela}`.replace(".", "").replace(",", ".") || 0) * Number(item.valorPrazo));
			});
			return Number(total.toFixed(2)).toLocaleString("pt-BR", {style: "currency", currency: "BRL"});
		},
		valorTotalCusto(){
			let total = 0;
			this.produtoAdicionado.forEach(item => {
				total += Number(Number(`${item.qtdTabela}`.replace(".", "").replace(",", ".") || 0) * Number(item.custoFixoTabela.replace(".", "").replace(",", ".")));

			});
			return Number(total.toFixed(2)).toLocaleString("pt-BR", {style: "currency", currency: "BRL"});
		},
	},
	mounted(){
		this.empresaNome = "";
		this.empresaId = "";
		this.inputUsuario = this.$store.state.Usuario.nome;
		this.incluirTransferencia(this.novaTransferencia);
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		this.$bus.$on("cancelarPolar", this.polarCancelado);
	},
	destroyed(){
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
		this.$bus.$off("cancelarPolar", this.polarCancelado);
	},
	methods:{
		saiuData(){
			if(this.data == ""){
				if(this.cdTransferencia){
					this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
					this.data = dateFormatISO({date: this.dataInicial, format: "ISOdate"});
				}
				else{
					this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
					this.data = dateFormatISO({date: null, format: "ISOdate"});
				}
			}
		},
		async selecionarTodos (filtro, acaoCancelar){
			await this.$store.dispatch("filtrarProdutos", {...filtro, size:  this.$store.state.paginacaoProduto.totalProdutos});
			const produtos = this.$store.state.Produtos;
			this.$store.commit("relatarErro", {
				mensagem: `Tem certeza que deseja adicionar ${this.listadeproduto.length}, na transferência?`,
				tipo: "polar",
				item: {
					selecionarTodosProdutos: true,
					produtos: [...produtos],
					acaoCancelar

				}
			});
		},
		

		finalizarTransf (){
			this.$store.commit("relatarErro", {
				mensagem: "Tem certeza que deseja efetuar a transferência?",
				tipo: "polar",
				item: {
					acao: this.confirmarTransf,
					component: this.$options._componentTag,
				}
			});
		},
		async confirmarTransf() {
			try {
				const Transferencia = this.gerarTransferencia();
				Transferencia.produtos.forEach(produto => {
					if(produto.qtdTransferir === 0){
						throw new Error("Náo é foi possivel finalizar sua transferência a quantidade de transferência  deve ser maior que 0");
					}
					if(produto.saldoEstoque < produto.qtdTransferir){
						throw new Error(`Não foi possivel finalizar sua transferência, produto Cód ${produto.idProduto} - ${produto.produtoDesc}, está com saldo menor do que a quantidade de transferência`);
					}
				});
				if(Transferencia.produtos.length === 0){
					throw new Error("Não é possivel realizar a transferência sem produtos na área de transferência");
				}
				await this.$store.dispatch("atualizarTransferencia", Transferencia);

				await this.$store.dispatch("finalizarTransferencia", Transferencia);
				this.$store.commit("relatarErro", {mensagem: `A transferência ${Transferencia.cdtransferencia} foi executada com sucesso!`, sucess: true});
				this.cancelarTransferencia();
			} catch (error) {
				setTimeout(async () => {
					this.$store.commit("relatarErro", {mensagem: error.message});
				}, 10);
			}
		},
		polarConfirmado(item){
			if(item.selecionarTodosProdutos){
				this.$store.state.carregando = true;
				this.adicionarTodos(item.produtos);


			}
			if (item.component == this.$options._componentTag) {
				if(item.acao ? typeof item.acao == "function" : false){
					item.acao(item.params);
				}
			}
		},
		async adicionarTodos(produtos){
			clearTimeout(this.timerCarregar);
			this.timerCarregar = setTimeout(async () => {
				for(let i = 0; i < produtos.length; i++){
					await this.adicionarProduto(produtos[i]);
				}
				this.$store.state.carregando = false;
			}, 100);

			this.indexListaProduto = -1;
		},
		recalcularItemTabela(tipo, produto) {
			if(tipo === "custofixo"){
				produto.custoFixoProduto = Number(`${produto.custoFixoTabela}`.split(".").join("").replace(",", "."));
				produto.valorPrazo = produto.custoFixoProduto + (produto.custoFixoProduto * produto.margemProduto / 100);
				produto.vlrPrazoTabela = Number(produto.valorPrazo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				produto.valorVista = produto.valorPrazo + (produto.valorPrazo * produto.descontoProduto / 100);
				produto.vlrVistaTabela = Number(produto.valorVista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
			if(tipo === "margem"){
				produto.margemProduto = Number(`${produto.margemTabela}`.split(".").join("").replace(",", "."));
				produto.valorVista = produto.custoFixoProduto + (produto.custoFixoProduto * produto.margemProduto / 100);
				produto.valorPrazo = produto.custoFixoProduto + (produto.custoFixoProduto * produto.margemProduto / 100);
				produto.vlrVistaTabela = Number(produto.valorVista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				produto.vlrPrazoTabela = Number(produto.valorPrazo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
			if(tipo === "prazo"){
				produto.valorPrazo = Number(`${produto.vlrPrazoTabela}`.split(".").join("").replace(",", "."));
				produto.margemProduto = (produto.valorPrazo - produto.custoFixoProduto) / produto.custoFixoProduto * 100;
				produto.valorVista = produto.valorPrazo - (produto.valorPrazo * produto.descontoProduto / 100);
				produto.vlrVistaTabela =  Number(produto.valorVista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				produto.margemTabela = Number(produto.margemProduto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
			if(tipo === "desconto"){
				produto.descontoProduto = Number(`${produto.descProdutoTabela}`.split(".").join("").replace(",", "."));
				produto.valorVista = produto.valorPrazo - (produto.valorPrazo * produto.descontoProduto / 100);
				produto.vlrVistaTabela = Number(produto.valorVista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
			if(tipo === "vista"){
				produto.valorVista = Number(`${produto.vlrVistaTabela}`.split(".").join("").replace(",", "."));
				produto.descontoProduto = (produto.valorPrazo - produto.valorVista) / produto.valorPrazo * 100;
				produto.descProdutoTabela = Number(produto.descontoProduto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			}
			this.$forceUpdate();
		},

		recalcularValores() {
			this.produtoAdicionado.forEach(produto => {
				if (this.selectTransferencia === "V") {
					if(produto.adicionado){
						return;
					}
					const custo = Number(`${produto.vlrPrazoTabela || 0}`.split(".").join("").replace(",", "."));
					produto.custoTabela = Number(custo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					produto.custoFixoTabela = Number(custo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});

					const novoPrazo = Number(`${produto.custoFixoTabela || 0}`.split(".").join("").replace(",", "."));
					produto.vlrPrazoTabela = (Number(Number(novoPrazo || 0) + ((Number(novoPrazo || 0)) * Number(produto.margemProduto / 100)))).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});

					const novoVista = Number(`${produto.vlrPrazoTabela || 0}`.split(".").join("").replace(",", "."));
					produto.vlrVistaTabela = (Number(Number(novoVista || 0) - ((Number(novoVista || 0)) * Number(produto.descontoProduto / 100)))).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});


					//Mandar para o backend
					produto.custoProduto =  Number(`${produto.custoTabela || 0}`.split(".").join("").replace(",", "."));
					produto.custoFixoProduto = Number(`${produto.custoTabela || 0}`.split(".").join("").replace(",", "."));
					produto.valorPrazo = Number(`${produto.vlrPrazoTabela || 0}`.split(".").join("").replace(",", "."));
					produto.valorVista = Number(`${produto.vlrVistaTabela || 0}`.split(".").join("").replace(",", "."));
					this.$forceUpdate();
					produto.adicionado = true;
				} else {
					produto.adicionado = false;
					produto.custoFixoTabela = produto.custoFixoAnterior.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					produto.vlrPrazoTabela = produto.valorPrazoAnterior.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
					produto.vlrVistaTabela = produto.valorVistaAnterior.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});

					//Mandar para o backend
					produto.custoProduto =  Number(`${produto.custoTabela || 0}`.split(".").join("").replace(",", "."));
					produto.custoFixoProduto = Number(`${produto.custoFixoTabela || 0}`.split(".").join("").replace(",", "."));
					produto.valorPrazo = Number(`${produto.vlrPrazoTabela || 0}`.split(".").join("").replace(",", "."));
					produto.valorVista = Number(`${produto.vlrVistaTabela || 0}`.split(".").join("").replace(",", "."));
				}
			});
			this.$forceUpdate();
		},
		incluirTransferencia(transferencia){
			if(transferencia.novo) return;
			if(transferencia.valorcusto) {
				this.inputValorCusto = true;
			}
			if(transferencia.valorvenda) {
				this.inputValorVenda = true;
			}
			this.produtoAdicionado = transferencia.produtos;
			if(transferencia.situacao === "CONFIRMADO"){
				this.transferenciaFinalizada = true;
				this.$forceUpdate();
			}
			if(transferencia.situacao === "DIGITACAO" || !this.novaTransferencia.cdtransferencia){
				this.transferenciaFinalizada = false;
			}

			//Preencher informações de configuração
			this.data = transferencia.data;
			this.dataInicial = transferencia.data;
			this.cdTransferencia = transferencia.cdtransferencia;
			this.selectTransferencia = transferencia.tipo;
			this.empresaId = transferencia.cdempresadestino;
			this.empresaNome = transferencia.empresadestino;

			//Preencher Produtos
			for (let prod in this.produtoAdicionado) {
				this.produtoAdicionado[prod].custoFixoProduto = this.produtoAdicionado[prod].custofixo;
				this.produtoAdicionado[prod].margemProduto = this.produtoAdicionado[prod].markup;
				this.produtoAdicionado[prod].descontoProduto = this.produtoAdicionado[prod].descvista;

				//Atualizar Tabela
				this.produtoAdicionado[prod].produtoDesc = this.produtoAdicionado[prod].produtoTodo.descricao;
				this.produtoAdicionado[prod].saldoEstoque = this.produtoAdicionado[prod].produtoTodo.saldo;
				this.produtoAdicionado[prod].qtdTabela = this.produtoAdicionado[prod].quantidade;
				this.produtoAdicionado[prod].qtdTransferir = this.produtoAdicionado[prod].qtdTabela;
				this.produtoAdicionado[prod].qtdTabela = this.produtoAdicionado[prod].quantidade;


				this.produtoAdicionado[prod].custoTabela = Number(this.produtoAdicionado[prod].custoProduto || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.produtoAdicionado[prod].custoFixoTabela = Number(this.produtoAdicionado[prod].custofixo).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.produtoAdicionado[prod].margemTabela = Number(this.produtoAdicionado[prod].markup).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.produtoAdicionado[prod].vlrPrazoTabela = Number(this.produtoAdicionado[prod].valorPrazo).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.produtoAdicionado[prod].descProdutoTabela = Number(this.produtoAdicionado[prod].descvista).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.produtoAdicionado[prod].vlrVistaTabela = Number(this.produtoAdicionado[prod].valorVista).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});

				this.produtoAdicionado[prod].custoFixoAnterior = Number(this.produtoAdicionado[prod].produtoTodo.custofixo).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.produtoAdicionado[prod].valorPrazoAnterior = Number(this.produtoAdicionado[prod].produtoTodo.pontoequi).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.produtoAdicionado[prod].valorVistaAnterior = Number(this.produtoAdicionado[prod].produtoTodo.vlrvista).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});

			}
		},
		contarSelecionados(produto){
			if(produto.checked){
				return this.produtosSelecionados += 1;
			}
			if(!produto.checked){
				return this.produtosSelecionados -= 1;
			}
		},
		transferirProdutos(e){
			const regex = /[0-9,]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
			if ((`${e.target.value}`.includes(",") || !`${e.target.value}`.length) && e.key == ","){
				e.preventDefault();
			}
			if(e.key == "Enter"){
				if(this.produtoEditando.idProduto){
					this.produtoAdicionado.forEach(prod => {
						if(prod.idProduto == this.produtoEditando.idProduto){
							prod.qtdTabela = Number(this.qtdTransferente.includes(",") ? this.qtdTransferente.replace(",", ".") : this.qtdTransferente);
						}
					});
					this.qtdTransferente = "";
					this.produtoEditando = {};
					this.proximoCampo("inputConsultar");
					return;
				}
				this.produtoAdicionado[this.produtoAdicionado.length -1].qtdTabela = Number(this.qtdTransferente.includes(",") ? this.qtdTransferente.replace(",", ".") : this.qtdTransferente);
				this.produtoEditando = {};
				this.proximoCampo("inputConsultar");
			}
			this.inputConsultar = 0;
		},
		solicitarExcluirItem(produto, index){
			this.$store.commit("relatarErro", {
				mensagem: `Deseja remover o produto ${produto.idProduto} - ${produto.produtoDesc}`,
				tipo: "polar",
				item: {
					acao: this.removerItem,
					params: index,
					component: this.$options._componentTag,
				}
			});
		},
		async removerItem(index){
			this.produtoAdicionado.splice(index, 1);
			const Transferencia = this.gerarTransferencia();
			Transferencia.cdtransferencia = this.novaTransferencia.cdtransferencia;
			await this.$store.dispatch("atualizarTransferencia", Transferencia);

		},
		removerProdutos(){
			this.produtoAdicionado = this.produtoAdicionado.filter(produto => !produto.checked);
			this.produtosSelecionados = 0;
		},
		selecionarEmpresa(){
			this.empresasEscolher = true;
		},
		selecionouEmpresa(empresa){
			this.empresaId = empresa.id;
			this.empresaNome = empresa.nome;
			this.empresasEscolher = false;
		},
		adicionarProduto(produto) {
			if(produto.saldo == 0 || produto.saldo == null || !produto.saldo){
				return this.$store.commit("relatarErro", {mensagem: "Não é possivel adicionar produtos sem saldo"});
			}
			if(this.alterarQuantidade(produto)) return;

			this.produtoAdicionado.push({
				qtdTransferir:Number(`${produto.qtdTabela}`.replace(".", ",")),
				idProduto: produto.id,
				produtoDesc: produto.descricao,
				saldoEstoque: produto.saldo,
				custoProduto: produto.custo || 0,
				custoFixoProduto: produto.custofixo || 0,
				margemProduto: produto.markup || 0,
				valorPrazo: produto.valorPrazo,
				valorVista: produto.valorVista,
				descontoProduto : produto.descvista,
				produtoTotalPrazo:produto.valorPrazo,
				produtoTotalVista: produto.valorVista,
				checked: false,
				//valores anterior
				custoAnterior: produto.custo || 0,
				custoFixoAnterior: produto.custofixo || 0,
				valorPrazoAnterior: produto.valorPrazo,
				valorVistaAnterior: produto.valorVista,

				//valores mostrados na tabela
				qtdTabela: Number(produto.qtdTransferir || 1).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
				custoTabela: Number(produto.custo || 0 ).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
				custoFixoTabela: Number(produto.custofixo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
				margemTabela: Number(produto.markup || 0 ).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
				vlrPrazoTabela: Number(produto.valorPrazo || 0 ).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
				descProdutoTabela: Number(produto.descvista || 0 ).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
				vlrVistaTabela: Number(produto.valorVista || 0 ).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}),
			});

			this.recalcularValores();
		},
		async selecionarPorGrupo (grupo, subg) {
			try {
				this.indexListaProduto = -1;
				let data = await this.$store.dispatch("buscarProdutoPorGrupo", {grupo, subg});
				if (!data) return;
				data.map(produto => {
					if(produto.saldo > 0){
						this.adicionarProduto(produto);
					}
				});
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},
		selecionarProduto(produto){
			try {
				this.indexListaProduto = -1;
				if(!produto) {
					throw new Error("Produto não encontrado");
				}
				if(produto.saldo <= 0){
					throw new Error("Não é possivel adicionar produto com saldo negativo");
				}
				this.inputConsultar = "";
				this.adicionarProduto(produto);
				this.proximoCampo("qtdTransferir"); 
				this.inputConsultar = produto.id;
			} catch (error) {
				this.inputConsultar = "";
				this.$store.commit("relatarErro", {mensagem: error.message});
				this.proximoCampo("inputConsultar");
			}
		},
		polarCancelado (item) {
			if(item.selecionarTodosProdutos){
				item.acaoCancelar();
			}
		},
		proximoCampo(id) {
			try {
				if (!id) {
					throw new Error("erro");
				}
				setTimeout(() => {
					const input = document.getElementById(id);
					if (input) {
						input.focus();
						if (input.select) {
							input.select();
						}
					}
				},40);
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},
		fecharModal(){
			this.empresasEscolher = false;
		},
		fecharModalOpcoes() {
			this.modalOpcoes = false;
		},
		gerarTransferencia() {
			try {
				this.produtoAdicionado.forEach((item) =>{
					const qtd = Number(`${item.qtdTabela}`.split(".").join("").replace(",", "."));
					item.qtdTransferir = qtd;
				});
				const Transferencia = {
					data: this.data,
					cdusuario: this.$store.state.Usuario.id,
					cdempresaorigem: this.$store.state.Empresa.id,
					cdempresadestino: this.empresaId,
					tipo: this.selectTransferencia,
					produtos: this.produtoAdicionado,
					valorcusto: this.inputValorCusto,
					valorvenda: this.inputValorVenda,
					cdtransferencia: this.cdTransferencia,
				};
				return Transferencia;

			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},
		async salvarTransferencia(){
			try {
				if(this.empresaNome === ""){
					throw new Error ("Selecione a empresa destino");
				}
				if(this.produtoAdicionado.length === 0){
					throw new Error("Por favor adicione um produto");
				}

				const Transferencia = this.gerarTransferencia();
				if(this.novaTransferencia.novo){
					Transferencia.produtos.forEach(produto => {
						if(produto.qtdTransferir > produto.saldoEstoque){
							throw new Error(`Não foi possivel cadastrar a transferência, produto Cód ${produto.idProduto} - ${produto.produtoDesc} está com saldo menor do que a quantidade de transferência`);
						}
					});
					const data = await this.$store.dispatch("cadastrarTransferencia", Transferencia);
					if(!data){
						throw new Error("Não foi possivel cadastrar sua transferência, por favor entre em contato com o suporte");
					}
					if(data.details){
						throw new Error(JSON.stringify(data.details));
					}
					if(data.message){
						throw new Error(data.message);
					}					
					this.cdTransferencia = data.id;
					Transferencia.cdtransferencia = this.cdTransferencia;

					this.$store.commit("relatarErro", {mensagem: `Transferência, Cód. ${data.id} cadastrada com sucesso`, sucess: true});
					this.cancelarTransferencia();
				} else {

					Transferencia.cdtransferencia = this.cdTransferencia;
					this.$store.dispatch("atualizarTransferencia", Transferencia);
					this.$store.commit("relatarErro", {mensagem: "Transferência salva com sucesso", sucess: true});
					this.cancelarTransferencia();

				}


			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},
		cancelarTransferencia(){
			this.selectTipoValor = "selecione";
			this.listaProdutos = 0;
			this.produtoAdicionado = [];
			this.produtosSelecionados = 0;
			this.data = dateFormatISO({
				date: null,
				format: "ISOdate"
			});
			this.cdTransferencia = "";
			this.empresaNome = "";
			this.$emit("cancelarTransferencia");

		},
		iniciarTimer(event){
			clearTimeout(this.timer);
			if(event.key == "F4"){
				event.preventDefault();
				return this.indexListaProduto = 0;
			}
			this.timer = setTimeout(this.consultarProduto(event), 90);
		},

		async consultarProduto(e){
			try{
				if(e.key == "Enter"){
					if(!this.inputConsultar) return;
					let produto = null;
					produto = await this.$store.dispatch("buscarProdutoPorCodigoBarras", this.inputConsultar);
					if(!produto){
						produto = await this.$store.dispatch("buscarProdutoPorId", this.inputConsultar);
					}
					this.produtoEncontrado = produto;
					if(produto){
						this.inputConsultar = "";
						this.adicionarProduto(produto);
					}
					return;
				}
			}catch(error){
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},
		alterarQuantidade(produto){
			const existe = this.produtoAdicionado.find(prod => prod.idProduto == produto.id);
			if(existe){
				this.produtoEditando = existe;
				this.qtdTransferente = existe.qtdTransferir || existe.qtdTabela;
				this.proximoCampo("qtdTransferir");
				return true;
			}
			return false;
		
		}
	},
};

</script>
<style scoped>
.article-cadastro
{
	width: 100%;
	overflow-y: auto;
}
.container-transferir-estoque
{
	width: 100%;
	height: 92vh;
	padding: 5px;
	display: flex;
	flex-direction: column;
}

.infos-container
{
	width: 100%;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	margin-top: 10px;
}
.infos-linha
{	margin-top: 10px;
	display: flex;
	width:100%;
	flex-direction: row;
	justify-content: flex-start;
	text-align:center;
}
.infos-linha-2
{
	display: flex;
	justify-content: center;
	gap: 30px;
	align-items: center;
	flex-direction: row;
}

.infos-container .infos-item .inputInfo{
	width: 100%;
	max-width: 110px;
	text-align: right;
}
.infos-item
{
	display: flex;
	white-space: nowrap;
}
.infos-item input{
	text-align: right;
	margin-left: 5px;
}

.infos-item select {
	text-align: center;

}
.infos-item input[type="checkbox"]{
	margin-right: 10px;
}

.containerValores{
	display: flex;
	flex-direction: column;
	margin-left: 30px;
}
.containerTipos{
	display: flex;
	flex-direction: row;
}

.checkboxContainer{
	display: flex;
	flex-direction: row;
	align-items: center;
}

.checkboxContainer label, input {
	cursor: pointer;
}

.empresas-container
{
	margin-top: 10px;
	margin:auto;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-around;
	align-items: center;
}
.empresa-box{
	width: auto;
	justify-content: center;
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	align-items: center;
	height: auto;
	padding: 5px;
	border: 3px solid var(--border);
	border-radius: 8px;
	margin-top: 15px;
}
.empresas-container .empresa-box table{
	width:30%;
	text-align: center;
	align-items: center;
	margin-right: 10px;
}
.empresas-container .empresa-box table thead tr td,
.empresas-container .empresa-box table tbody tr td{
	text-align: center;
}

/*Area Transferencia*/
.container-transferencia
{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	white-space:nowrap;
	margin-top: 10px;
	width: 100%;
}

.container-transferencia .acoes-principal .produto-linha label,
.container-transferencia .acoes-principal .produto-linha select{
	font-size: 12pt;
}

.produto-linha
{
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	width: auto;
	align-items: center;
}
.produto-selecionado-linha
{
	white-space: nowrap;
	display: flex;
	flex-direction: column;
	/* height: 50vh; */
	max-height: 60vh;
	min-height: 40vh;
	margin-top: 10px;
	width: 100%;
	overflow-x: auto;
	overflow-y: auto;
	text-align: center;
	flex-grow: 1;
}

.produto-selecionado-linha table td tr
{
	width: 100%;
	max-width: 290px;
}
.produto-selecionado-linha table td input
{
	background: transparent;
	border: none;
}

.container-transferencia .contagem-produtos label{
	font-size: 12pt;
}

.container-buttons
{
	width: 100%;
	display: flex;
	flex-direction: row;
	text-align: center;
	gap: 30px;
	padding: 0px 10px;
}

.container-buttons .botao {
	margin-bottom: 10px;
	width: 50%;
}

.container-buttons .btn
{
	width: 100%;
	max-width: 45%;
	margin-bottom: 10px;
}

.acoes-principal .transferir{
	width: auto;
	margin-right: 10px;
}
.acoes-principal .transferir .btn
{
	width:100%;
	max-width: 110px;
}
.acoes-principal
{
	display: flex;
	flex-direction: row;
	width: 100%;
}
.contagem-produtos
{
	display: flex;
	flex-direction: column; 
	margin-right: 10px;
}
.input-tabela {
	background-color: transparent;
	max-width: 80px;
	width: 80px;
	height: 100%;
	border: none;
	text-align: right;
	font-size: 11pt;
}

.botao-acao {
	width: auto;
	background-color: #7984eb;
}

.empresa-box .botao-acao {
	height: 57px;
}

.selectTransferencia {
	margin-top: 20px;
	width: 15vw;
}

.input-group label {
	height: 13px;
}

.transferir {
	display: flex;
	align-items: center;
}

.transferir .botao-adicionar, .botao-acao {
	width: auto;
	height: 40px;
}

.produto-linha .botao-adicionar {
	width: auto;
	height: 40px;
}

#voltar {
	height: auto;
}

.botao {
	display: flex;
	align-items: center;
	justify-content: center;
}

.sm-view {
	display: none;
}

#aplicar {
	margin-right: 10px;
}

#qtdTransferir {
	text-align: center;
}

#qtdTransferirDiv {
	width: 45%;
}

#selectAcaoMassa {
	margin-top: 5px;
	width: 26vw;
}

#codProdQtd {
	width: 20vw;
}

#aplicar {
	width: 20vw;
	height: 40px;
	margin-top: 5px;
}
.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}

.botoes .botao {
	width: 45%;
}
@media screen and (max-width: 1080px) {
	#codProdQtd {
		width: 35%;
	}

	.selectTransferencia .sm-view {
		display: flex;
	}

	.selectTransferencia .lg-view {
		display: none;
	}

	.acoes-principal {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	#selectAcaoMassa {
		width: 39%;
		margin-bottom: 5px;
	}

	#aplicar {
		width: 20%;
	}

	.infos-linha-2 {
		gap: 5px;
	}

	.containerValores {
		margin-left: 0;
		margin-top: 0;
	}

	.containerValores .sm-view {
		display: flex;
	}

	.containerValores .lg-view {
		display: none;
	}

	.contagem-produtos {
		gap: 20px;
		flex-direction: row;
	}
	.lg-view {
		display: none;
	}

	.sm-view {
		display: flex;
	}

}

@media screen and (max-width: 940px){
	.infos-container
	{
		display: flex;
	}
}
@media screen and (max-width: 810px){
	::-webkit-scrollbar, ::-webkit-calendar-picker-indicator {
		display: none;
	}

	.infos-linha {
		flex-wrap: wrap;
		gap: 10px;
	}

	.infos-linha .input-group {
		width: 20%;
		display: flex;
		flex-direction: column;
	}

	.infos-container-modal {
		margin-top: 30px;
	}

	.infos-linha-2 {
		gap: 0;
		flex-wrap: wrap;
	}

	.containerValores {
		margin: 0;
		margin-left: 30px;
	}

	.containerTipos {
		width: 100%;
	}

	.selectTransferencia {
		width: 60%;
	}

	.acoes-principal {
		flex-direction: row;
		flex-wrap: wrap;
		gap: 0px;
	}

	#codProdQtd {
		width: 51%;
	}

	.contagem-produtos {
		width: auto;
		gap: 0;
		flex-direction: column;
	}

	#selectAcaoMassa {
		width: 47%;
	}

	.transferir {
		margin-right: 0;
	}

	.transferir .input-group {
		margin: 0;
	}

	#botaoModalEmpresa {
		width: 40%;
		margin-left: 10px;
	}

	#nomeEmpresaDestino {
		overflow: auto; 
		max-width: 12vw;
	}

	#aplicar {
		margin-top: 5px;
	}

	#qtdTransferirDiv {
		width: 30%;
		margin-left: 10px;
	}
}

@media screen and (max-width: 575px){
	.empresa-box
	{
		display: flex;
		flex-direction: column;
	}
	.empresa-box table
	{
		margin-top: 10px;
	}
	.containerValores{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		align-content: flex-start;
	}
	.container-transferencia
	{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}

@media screen and (max-width: 440px){
		h2 {
			font-size: 20px;
		}
		
		.lg-view {
			display: none;
		}

		.sm-view {
			display: flex;
		}

		.infos-linha, .infos-linha-2 {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			gap: 10px;
		}

		.infos-linha .input-group {
			width: 45%;
			margin: 0;
		}

		.selectTransferencia {
			width: 49%;
		}
		
		.containerValores {
			margin-left: 20px;
		}

		.infos-linha-2 {
			gap: 0;
		}

		.empresa-box {
			width: 95%;
		}

		#codProdQtd {
			width: 100%;
			margin: 0;
		}

		#codProdQtd button {
			margin-right: 10px;
		}

		.infos-container-modal {
			margin-top: 30px;
		}

		#inputConsultar {
			width: 100%;
		}

		#botaoModalEmpresa {
			margin: 0px 10px;
		}

		#selectAcaoMassa {
			width: 94%;
			margin-left: 10px;
			margin-bottom: 10px;
			margin-top: 10px;
		}

		.transferir {
			justify-content: center;
			margin-left: 10px;
		}

		.acoes-principal {
			align-items: center;
			gap: 0;
		}

		#aplicar {
			width: auto;
			margin-left: 10px;
		}

		.contagem-produtos {
			margin-left: 15px;
		}

		#botaoModalEmpresa {
			width: 100%;
			margin-left: 10px;
			margin-bottom: 10px;
		}

		#qtdTransferirDiv {
			width: 30%;
			margin-left: 0;
		}
	}

	@media screen and (max-width: 375px) {
		.acoes-principal {
			justify-content: center;
		}

	}

	@media screen and (max-width: 320px) {
		#selectAcaoMassa {
			margin: 0;
			width: 100%;
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}

</style>
