<template>
	<div class="informacoes-cliente">
		<div class="col between w-100" style="margin-bottom: 5px;">
			<h2>Identificação Consumidor</h2>
		</div>
		<!-- VERIFICAR EM QUAL COMPONENTE O CUPOM DADOS FICA PARA REMOVER A FUNÇÃO DE FINALIZAR QUANDO FECHAR O MODAL, QUANDO FOR CONDICIONAL -->
		<div class="container-form">
			<div class="input-group">
				<i
					class="fas fa-times"
					style="color: red;"
					v-if="cdCliente && ! $route.query.baixarCondicional"
					@click="limparCliente"
				/>
				<!-- <i class="fas fa-search" style="color: #cecece;" v-else></i> -->
				<input 
					type="text" placeholder=" " 
					id="nomeCliente"
					autocomplete="off" 
					@input="buscarClienteNome" 
					@keydown.tab="listaCliente = false"
					v-model="nomeCliente"
					@keydown.enter="proximoCampo('tipoPessoa')"
					v-if="!cdCliente"
				/>
				<input 
					type="text" placeholder=" " 
					id="nomeCliente"
					autocomplete="off" 
					v-model="nomeCliente"
					@keydown.enter="proximoCampo('tipoPessoa')"
					v-else
				/>
				<label for="nomeCliente">
					Nome <span class="required">*</span>
				</label>
				<ListaBuscas 
					v-show="listaCliente"
					:Lista="Clientes"
					ref="listaClinete"
					:tipoLista="'Clientes'"
					@selecionarItem="selecionarCliente"
				/>
			</div>
			<div class="agroup">
				<div class="input-group">
					<select 
						v-model="tipoPessoa" 
						name="tipoPessoa" 
						id="tipoPessoa"
						@change="cnpjCliente = ''"
						@keydown.enter.prevent="proximoCampo(`${tipoPessoa == 'F' ? 'cpf' : 'cnpj'}`)"
					>
						<option value="N" disabled>Selecione uma opção</option>
						<option value="F" selected >Pessoa Física</option>
						<option value="J">Pessoa Jurídica</option>
					</select>
					<label for="tipoPessoa">
						Tipo Pessoa
					</label>
				</div>
				<div class="input-group">
					<input 
						type="text" 
						autocomplete="off" 
						:id="`${tipoPessoa == 'F' ? 'cpf' : 'cnpj'}`" 
						v-model="cnpjCliente" 
						@input="mascara" 
						:maxlength="`${tipoPessoa == 'F' ? 14 : 18}`"
						placeholder=" " 
						@keydown.enter="proximoCampo('foneCliente')"
						v-mascaraInteger
					/>
					<label :for="`${tipoPessoa == 'F' ? 'cpf' : 'cnpj'}`">
						{{tipoPessoa == "F" ? "CPF" : tipoPessoa == "J" ? "CNPJ" : ""}}
						<span 
							class="required" 
							v-if="!Usuario.solicitaNomeConsumidorFinal"
						>
							*
						</span>
					</label>
				</div>
			</div>
			<div class="agroup">
				<div class="input-group">
					<input 
						type="text" 
						autocomplete="off" 
						id="foneCliente" 
						v-model="foneCliente" 
						@input="mascara" maxlength="14"
						placeholder=" " 
						@keydown.enter="proximoCampo('dataNascimento')" 
						v-mascaraInteger
					/>
					<label for="foneCliente">
						Telefone
						<span 
							class="required" 
							v-if="!Usuario.solicitaNomeConsumidorFinal"
						>
							*
						</span>
					</label>
				</div>
				<div class="input-group">
					<input 
						type="date" 
						autocomplete="off" 
						id="dataNascimento" 
						v-model="dataNascimento" 
						@input="mascara" maxlength="14"
						placeholder=" " 
						@keydown.enter="proximoCampo(tipoPessoa == 'F' ? 'rg' : 'ie')" 
					/>
					<label for="dataNascimento">
						Data de nascimento 						
						<span 
							class="required" 
							v-if="!Usuario.solicitaNomeConsumidorFinal && Empresa.tiposistema !== 'L' && tipoPessoa === 'F'"
						>
							*
						</span>
					</label>
				</div>
			</div>
			
			<div class="agroup">
				<div class="input-group">
					<input 
						type="text" 
						autocomplete="off" 
						:id="`${tipoPessoa == 'F' ? 'rg' : 'ie'}`" 
						v-model="ieCliente" 
						@input="mascara" maxlength="20"
						placeholder=" " 
						@keydown.enter="proximoCampo('enderecoCliente')"
						v-mascaraInteger
					/>
					<label :for="`${tipoPessoa == 'F' ? 'rg' : 'ie'}`">
						{{tipoPessoa == "F" ? "RG" : tipoPessoa == "J" ? "IE" : "Tipo da pessoa não cadastrado!"}}&nbsp;
					</label>
				</div>
				<div class="input-group" v-if="cdCliente">
					<input 
						type="text" 
						autocomplete="off" 
						id="cdCliente" 
						v-model="cdCliente" 
						disabled 
						placeholder=" "
					/>
					<label for="cdCliente">
						Código Cliente
					</label>
				</div>
			</div>
			<div class="agroup">
				<div class="input-group">
					<input 
						type="text" 
						autocomplete="off" 
						id="enderecoCliente" 
						v-model="enderecoCliente" 
						@input="mascara" 
						maxlength="60"
						placeholder=" " 
						@keydown.enter.prevent="proximoCampo('numeroEndereco')"
					/>
					<label for="enderecoCliente">
						Endereço 
						<span 
							class="required" 
							v-if="!Usuario.solicitaNomeConsumidorFinal"
						>
							*
						</span>
					</label>
				</div>
				<div class="input-group">
					<input 
						type="text" 
						autocomplete="off" 
						id="numeroEndereco" 
						v-model="numeroEndereco" 
						@input="mascara" 
						maxlength="5"
						placeholder=" " 
						@keydown.enter.prevent="proximoCampo('bairroCliente')" 
					/>
					<label for="numeroEndereco">
						Número 
						<span 
							class="required" 
							v-if="!Usuario.solicitaNomeConsumidorFinal"
						>
							*
						</span>
					</label>
				</div>
			</div>
			<div class="agroup">
				<div class="input-group">
					<input 
						type="text" 
						autocomplete="off" 
						id="bairroCliente" 
						v-model="bairroCliente" 
						@input="mascara" 
						maxlength="50"
						placeholder=" " 
						@keydown.enter="proximoCampo('cepCliente')" 
					/>
					<label for="bairroCliente">
						Bairro 
						<span 
							class="required" 
							v-if="!Usuario.solicitaNomeConsumidorFinal"
						>
							*
						</span>
					</label>
				</div>
				<div class="input-group">
					<input 
						type="text" 
						autocomplete="off" 
						id="cepCliente" 
						v-model="cepCliente" 
						@input="mascara" 
						maxlength="9"
						placeholder=" " 
						@keydown.enter="proximoCampo('cidadeCliente')"

					/>
					<label for="cepCliente">
						CEP 
						<span 
							class="required" 
							v-if="!Usuario.solicitaNomeConsumidorFinal"
						>
							*
						</span>
					</label>
				</div>
			</div>
			<div class="agroup">
				<div class="input-group">
					<input 
						type="text" 
						autocomplete="off" 
						id="cidadeCliente" 
						v-model="cidadeCliente" 
						@input="mascara" 
						maxlength="40"
						placeholder=" " 
						@keydown.enter="proximoCampo('ufCliente')" 
					/>
					<label for="cidadeCliente">
						Cidade 
						<span 
							class="required" 
							v-if="!Usuario.solicitaNomeConsumidorFinal"
						>
							*
						</span>
					</label> 
				</div>
				<div class="input-group">
					<input type="text" autocomplete="off" id="ufCliente" v-model="ufCliente" @input="mascara" maxlength="2"
					placeholder=" " @keydown.enter="proximoCampo('identificador')">
					<label for="ufCliente">UF</label>
				</div>
			</div>
			<div class="input-group">
				<input 
					type="text" 
					autocomplete="off" 
					id="identificador" 
					v-model="identificador" 
					@input="mascara" 
					maxlength="150"
					placeholder=" " 
					@keydown.enter="proximoCampo(taxaEntrega && fretegratis === 'N' ? 'valorEntrega' : 'btn-salvar-info')" 
				/>
				<label for="identificador">
					Identificador
				</label>
			</div>
			<div class="container-taxa-entrega" v-if="taxaEntrega">
				<div class="input-radio">
					<input 
						type="radio" 
						name="taxaEntrega" 
						id="taxaEntregaPaga" 
						v-model="fretegratis" 
						:value="'N'"					
					/>
					<label for="taxaEntregaPaga">
						Entrega Paga
					</label>
					<div class="input-group" v-if="fretegratis === 'N'">
						<input 
							type="text"
							v-model="valorEntrega"
							v-mascaraFloat
							id="valorEntrega"
							@keydown.enter="proximoCampo('btn-salvar-info')"
						/>
						<label for="valorEntrega">
							Informe o valor
						</label>
					</div>
				</div>
				<div class="input-radio">
					<input 
						type="radio" 
						name="taxaEntrega" 
						id="taxaEntregaGratis" 
						v-model="fretegratis" 
						:value="'S'"					
					/>
					<label for="taxaEntregaGratis">
						Entrega Grátis
					</label>
				</div>
			</div>
		</div>
		<div class="botoes">
			<button class="botao botao-cancelar" @click="cancelarInformacoes(true)">
				Cliente não informou
			</button>
			<button class="botao botao-adicionar" @click="salvarInformacoes" id="btn-salvar-info">
				Salvar
			</button>
		</div>
	</div>
</template>
<script>
import ListaBuscas from "../components/ListaBuscas.vue";
import { validarCPF } from "../utils/utils";
export default{
	name: "CupomDados",
	components: { ListaBuscas },
	props: {
		clienteVenda: Object,
		taxaEntrega: Boolean
	},
	data(){
		return{
			cnpjCliente: "",
			ieCliente: "",
			nomeCliente: "",
			foneCliente: "",
			enderecoCliente: "",
			numeroEndereco: "",
			cdCliente: "",
			bairroCliente: "",
			cepCliente: "",
			cidadeCliente: "",
			ufCliente: "",
			tipoPessoa: "F",
			identificador: "",
			inserirObservacao: "",
			timerBusca: null,
			listaCliente: false,
			cdcidade: "",
			dataNascimento: "",
			codibge: "",
			
			
			fretegratis: "", // FRETE === "N" NAO MANDA VALOR DO FRETE, E FRETE GRATIS SIM
			valorEntrega: "",
		};
	},
	computed: {
		Venda(){
			return this.$store.state.Venda;
		},
		Clientes(){
			return this.$store.state.Clientes;
		},
		Usuario () {
			return this.$store.state.Usuario;
		},
		Empresa () {
			return this.$store.state.Empresa;
		}
	},
	mounted(){
		
		window.addEventListener("click", this.handleClickOutside);
		if(this.clienteVenda){
			this.preencherCliente(this.clienteVenda);
			if(this.clienteVenda.cep) {
				this.buscarCidade();
			}
		}
	},
	destroyed() {
		window.addEventListener("click", this.handleClickOutside);
	},
	methods: {
		proximoCampo(id){
			try {
				if (!id) {
					throw new Error("Falta o atribuito id!");
				}
				setTimeout(() => {
					const input = document.getElementById(id);
					if (input) {
						input.focus();
						// if (input.select) {
						// 	input.select();
						// }
					}
				},20);
			} catch (error) {
				console.log(error);
			}
		},
		
		handleClickOutside(e) {
			const listContainer = this.$refs.listaClinete?.$el;
			if(!listContainer?.contains(e.target)){
				if(this.listaCliente){
					this.listaCliente = false;
				}
			}
		},

		//VALIDAÇÕES
		verificaCNPJ(cnpj) {
			cnpj = cnpj.replace(/[^\d]+/g,"");
	
			if(cnpj == "") return false;
	
			if (cnpj.length != 14) return false;
	
			let constants = [
				"00000000000000",
				"11111111111111",
				"22222222222222",
				"33333333333333",
				"44444444444444",
				"55555555555555",
				"66666666666666",
				"77777777777777",
				"88888888888888",
				"99999999999999"
			];
			if (this.$store.state.Usuario.validarCnpj) if (constants.includes(cnpj)) return false;
	
			let tamanho = cnpj.length - 2;
			let numeros = cnpj.substring(0,tamanho);
			let digitos = cnpj.substring(tamanho);
			let soma = 0;
			let pos = tamanho - 7;
			let i;
			for (i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2) pos = 9;
			}
			let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(0)) return false;
	
			tamanho = tamanho + 1;
			numeros = cnpj.substring(0,tamanho);
			soma = 0;
			pos = tamanho - 7;
			for (i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2) pos = 9;
			}
			resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			if (resultado != digitos.charAt(1)) return false;
			return true;
		},

		verificaCPF(strCPF) {
			strCPF = strCPF.replace(/[^\d]+/g,"");

			if(strCPF == "") return false;

			if (strCPF.length != 11) return false;

			let constants = [
				"00000000000",
				"11111111111",
				"22222222222",
				"33333333333",
				"44444444444",
				"55555555555",
				"66666666666",
				"77777777777",
				"88888888888",
				"99999999999"
			];
			if (this.$store.state.Usuario.validarCnpj) if (constants.includes(strCPF)) return false;

			let Soma;
			let Resto;
			Soma = 0;
			for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
			Resto = (Soma * 10) % 11;
			if ((Resto == 10) || (Resto == 11))  Resto = 0;
			if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;
			Soma = 0;
			for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
			Resto = (Soma * 10) % 11;
			if ((Resto == 10) || (Resto == 11))  Resto = 0;
			if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
			return true;
		},
		
		validarCampos(){
			try {
				if(!this.nomeCliente && !this.cdCliente){
					throw new Error("É necessário informar o nome do cliente!");
				}
				if(!this.Usuario.solicitaNomeConsumidorFinal){
					if (!this.cnpjCliente) {
						throw new Error("É necessário informar o CPF / CNPJ do cliente!");
					}
					
					if (!this.nomeCliente && (this.Usuario.solicitaNomeConsumidorFinal || (this.Usuario.condicionalConsumidorFinal && this.$route.query.tipoVenda == "C"))) {
						throw new Error("É necessário informar o nome do cliente!");
					}

					if (this.$store.state.Usuario.validarCnpj) {
						if (this.tipoPessoa == "F") {
							if (!this.verificaCPF(this.cnpjCliente) && !validarCPF(this.cnpjCliente)) {
								throw new Error("CPF inválido. Verifique!");
							}
						} else {
							if (!this.verificaCNPJ(this.cnpjCliente)) {
								throw new Error("CNPJ inválido. Verifique!");
							}
						}
					}
					
					if (!this.foneCliente) {
						throw new Error("É necessário informar o telefone do cliente!");
					}
					
					if(!this.dataNascimento && this.tipoPessoa === "F" && this.Empresa.tiposistema !== "L"){
						throw new Error("É necessário informar data de nascimento do cliente");
					}
					
					if(!this.enderecoCliente){
						throw new Error("É necessário informar o endereço do cliente");
					}
					
					if(!this.numeroEndereco){
						throw new Error("É necessário informar o número do endereço do cliente");
					}

					if(!this.bairroCliente){
						throw new Error("É necessário informar o bairro do cliente");
					}

					if(!this.cepCliente){
						throw new Error("É necessário informar o CEP do cliente");
					}

					if(this.cepCliente.length<9){
						throw new Error("O CEP inválido. Verifique!");
					}	
				}
				return true;		
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
				return false;
			}
		},

		//CONSULTA
		async buscarClienteNome() {
			this.listaCliente = true;
			clearTimeout(this.timerBusca);
			this.timerBusca = setTimeout( async () => {
				await this.$store.dispatch("filtrarClientes", {
					query: this.nomeCliente,
					todos: this.nomeCliente,
					nome: "",
					nomeCliente: "",
					checkboxPesquisa: true,
					troca: false,
					ativo: "A"
				});
			}, 600);
			if(this.nomeCliente.length < 0){
				this.limparCliente();
			}
		},

	


		async consultaPessoaVendaFone() {
			try {
				clearTimeout(this.timer);
				this.timer = setTimeout(async () => {
					let pessoa;
					if(!this.cdCliente){
						pessoa = await this.$store.dispatch("buscarClienteFoneCPF", {
							fone: this.foneCliente
						});
					}
					if (pessoa) {
						this.preencherCliente(pessoa);
					}
					this.timer = null;
				},600);
			} catch (error) {
				console.log(error);
			}
		},
	
		async consultaPessoaVenda() {
			try {
				clearTimeout(this.timer);
				this.timer = setTimeout(async () => {
					if (this.$store.state.Usuario.validarCnpj) {
						if (this.tipoPessoa == "F") {
							if (!this.verificaCPF(this.cnpjCliente)) {
								return setTimeout(() => {
									this.$store.commit("relatarErro", {mensagem: "CPF inválido. Verifique!"});
								}, 20);
							}
						} else {
							if (!this.verificaCNPJ(this.cnpjCliente)) {
								return setTimeout(() => {
									this.$store.commit("relatarErro", {mensagem: "CNPJ inválido. Verifique!"});
								}, 20);
							}
						}
					}
					let  pessoa;				
					if(!this.cdCliente){
						pessoa = await this.$store.dispatch("buscarClienteFoneCPF", {
							cpfcnpj: this.cnpjCliente
						});
					}
					if (pessoa) {
						this.preencherCliente(pessoa);
						
					}
					//  else {
					// 	this.limparCliente();
					// }
					this.timer = null;
				},420);
			} catch (error) {
				console.log(error);
			}
		},

		
		// GERAL
		mascara(e) {
			const id= e.target.id;
			const texto = e.target.value;
			const regexInteger = /[0-9]/;
			const regexCaracteresEspeciais = /[~^!@#$%¨^&*()_={}+-?;<>'´`|ªº°"/\\]/;
			if (id == "identificador") {
				this.identificador = this.identificador.toUpperCase();
			} else if (id == "nomeCliente") {
				if (regexCaracteresEspeciais.test(this.nomeCliente)) {
					this.nomeCliente = this.nomeCliente.substring(0, this.nomeCliente.length-1);
				}
				this.nomeCliente = this.nomeCliente.toUpperCase();
			} else if (id == "enderecoCliente") {
				this.enderecoCliente = this.enderecoCliente.toUpperCase();
			} else if (id == "numeroEndereco") {
				if (!regexInteger.test(e.data)) {
					this.numeroEndereco = this.numeroEndereco.substring(0, this.numeroEndereco.length-1);
				}
			} else if (id == "bairroCliente") {
				this.bairroCliente = this.bairroCliente.toUpperCase();
			} else if (id == "cidadeCliente") {
				this.cidadeCliente = this.cidadeCliente.toUpperCase();
			} else if (id == "ieCliente") {
				this.ieCliente = this.ieCliente.toUpperCase();
			} else if (id == "ufCliente") {
				this.ufCliente = this.ufCliente.toUpperCase();
			} else if (id == "cepCliente") {
				this.buscarCidade();
				if (texto.length == 5 && e.data) {
					this.cepCliente += "-";
				}
				if (texto.length == 6 && e.data && texto[5] != "-") {
					this.cepCliente = this.cepCliente.substring(0, 5) + "-" + e.data;
				}
			} else if (id == "foneCliente") {
				if (e.data === null){
					if (texto.length == 13 && texto[8] != "-") {
						this[id] = `${this[id].slice(0, 8) + "-" + texto[10] + texto.slice(10) }`;
					}
					return;
				}
				const regex = /[^-()0-9]/;
				regex.lastIndex = 0;
				if (regex.test(texto)) {
					this[id] = this[id].substring(0,texto.length-1);
				}
				if (texto.length == 1) {
					this[id] = "(" + e.data;
				}
				if (texto.length == 3) {
					this[id] += ")";
				}
				if (texto.length == 4 && texto.length -1 != ")") {
					this[id] = this[id].slice(0, 3) + ")" + e.data;
				}
				if (texto.length == 8) {
					if (texto.length-1 == "-"){
						return;
					}
					this[id] += "-";
				}
				if (texto.length == 9 && e.data!= "-") {
					this[id] = this[id].slice(0, 8) + "-" + e.data;
				}
				if (texto.length == 14 && texto[8] == "-") {
					this[id] = `${this[id].slice(0, 8)+ texto[9] + "-" + texto.slice(10)}`;
				}
			} else if (id == "cpf" && e.data) {
				if (texto.length == 3 || texto.length == 7) {
					this.cnpjCliente += ".";
				}
				if ((texto.length == 4 || texto.length == 8) && texto.length -1 != ".") {
					this.cnpjCliente = texto.slice(0, texto.length-1) + "." + e.data;
				}
				if (texto.length == 11) {
					this.cnpjCliente += "-";
				}
				if (texto.length == 12 && texto.length-1 != "-") {
					this.cnpjCliente = texto.slice(0, texto.length-1) + "-" + e.data;
				}
			} else if(id == "cnpj" && e.data) {
				if (texto.length == 2 || texto.length == 6) {
					this.cnpjCliente += ".";
				}
				if (texto.length == 3) {
					if (texto.length-1 == "/"){
						return;
					}
					this.cnpjCliente = this.cnpjCliente.slice(0, 10);
					this.cnpjCliente += "/" + e.data;
				}
				if (texto.length == 10) e.target.value += "/";
				if (texto.length == 11) {
					if (texto.length-1 == "/"){
						return;
					}
					this.cnpjCliente = this.cnpjCliente.slice(0, 10);
					this.cnpjCliente += "/" + e.data;
				}
				if (texto.length == 15) {
					this.cnpjCliente += "-";
				}
			}
			let constants = [
				"00000000000",
				"11111111111",
				"22222222222",
				"33333333333",
				"44444444444",
				"55555555555",
				"66666666666",
				"77777777777",
				"88888888888",
				"99999999999",
				"00000000000000",
				"11111111111111",
				"22222222222222",
				"33333333333333",
				"44444444444444",
				"55555555555555",
				"66666666666666",
				"77777777777777",
				"88888888888888",
				"99999999999999",
				"0000000000",
				"1111111111",
				"2222222222",
				"3333333333",
				"4444444444",
				"5555555555",
				"6666666666",
				"7777777777",
				"8888888888",
				"9999999999",
			];
			if (["cpf", "cnpj"].includes(id)) {
				if (constants.includes(this.cnpjCliente.replace(/[^\d]+/g,""))) return;
				if (id == "cpf" && this.cnpjCliente.length >= 14 && this.cnpjCliente != "000.000.000") {
					this.consultaPessoaVenda();
				} else if (id == "cnpj" && this.cnpjCliente.length >= 18) {
					this.consultaPessoaVenda();
				}
			}
			if (id == "foneCliente") {
				if(constants.includes(this.foneCliente.replace(/[^\d]+/g,""))) return;
				if (this.foneCliente.length >= 13) {
					this.consultaPessoaVendaFone();
				}
			}
		},

		async buscarCidade(){
			const res = await this.$store.dispatch("buscarCidadeViaCep", { cep: this.cepCliente });
			if(res?.erro) {
				this.cidadeCliente = "";
				this.ufCliente = "";
				this.cepCliente = "";
				this.cdcidade = "";	
				return this.$store.commit("relatarErro", {mensagem: "CEP não encontrado, verifique!"});
			}
			this.cidadeCliente = res?.nomecidade.toUpperCase() || "";
			this.ufCliente = res?.uf?.toUpperCase() || "";
			this.cdcidade = res?.cdcidade || "";
			this.codibge = res?.codibge;
		},
		
		//CLIENTE
		selecionarCliente(cliente){
			if(this.listaCliente){
				if(cliente){
					this.preencherCliente(cliente);
				}
			}
		},
		preencherCliente(cliente){
			const { 
				nome, 
				cnpj, 
				ierg, 
				fone, 
				celular,
				endereco, 
				numero, 
				id, 
				bairro,
				cep,
				cidade, 
				tipoPessoa,
				dataNascimento,
				valorFrete,
				fretegratis
			} = cliente;
			this.cdCliente = this.$store.state.ClientePadrao.id == id ? null : id;
			if (cliente?.endereco?.includes(",")) {
				this.numeroEndereco = cliente.endereco.split(",")[1].trim();
				this.enderecoCliente = cliente.endereco.split(",")[0];
			} else {
				if(cliente.numero && cliente.numero != ""){
					this.numeroEndereco = cliente.numero;
				}
				if(cliente.endereco && cliente.endereco != ""){
					this.enderecoCliente = cliente.endereco;
				}
			}

			this.nomeCliente = nome;
			this.cnpjCliente = cnpj;
			this.ieCliente = ierg;
			this.foneCliente = fone?fone:celular;
			this.enderecoCliente = endereco;
			this.numeroEndereco = numero;
			this.bairroCliente = bairro;
			this.cepCliente = cep;
			this.cidadeCliente = cidade?.nomecidade;
			this.ufCliente = cidade?.uf;
			this.tipoPessoa = tipoPessoa || "F";
			this.listaCliente = false;
			this.dataNascimento = dataNascimento;
			this.fretegratis = fretegratis ? "S" : "N";
			this.valorEntrega = valorFrete || "5,00";
		},
		
		limparCliente(){
			this.nomeCliente = "";
			this.cnpjCliente = "";
			this.ieCliente = "";
			this.foneCliente = "";
			this.enderecoCliente = "";
			this.numeroEndereco = "";
			this.cdCliente = null;
			this.bairroCliente = "";
			this.cepCliente = "";
			this.cidadeCliente = "";
			this.ufCliente = "";
			this.tipoPessoa = "F";
			this.dataNascimento = "";
			this.listaCliente = false;
			this.valorEntrega = "5,00";
		},

		//SALVAR / ALTERAR
		salvarInformacoes() {
			if(!this.validarCampos()) return;
			this.$emit("fecharModal");
			this.$emit("salvarInformacoes", {
				cnpjCliente: this.cnpjCliente || null,
				cdCliente: this.cdCliente || null,
				nomeCliente: this.nomeCliente?.toUpperCase() || null,
				ierg: this.ieCliente || null,
				foneCliente: this.foneCliente || null,
				enderecoCliente: this.enderecoCliente ? this.enderecoCliente.toUpperCase() : null,
				numeroEndereco: this.numeroEndereco || null,
				bairroCliente: this.bairroCliente?.toUpperCase() || null,
				cepCliente: this.cepCliente || null,
				cidadeCliente: this.cidadeCliente?.toUpperCase() || null,
				cdcidade: this.cdcidade || "",
				ufCliente: this.ufCliente?.toUpperCase() || null,
				nascimentoCliente: this.dataNascimento || null,
				numeroCliente: this.numeroEndereco || null,
				tipoPessoa: this.tipoPessoa?.toUpperCase() || null,
				identificador: this.identificador || this.Venda?.identificador || null,
				dataNascimento: this.dataNascimento || "",
				codibge: this.codibge || "",
				valorFrete: this.valorEntrega || 0,
				fretegratis: this.fretegratis,
			});
		},

		//CANCELAR / EXCLUIR
		cancelarInformacoes(finalizar) {
			this.$emit("fecharModal");
			this.$emit("cancelarInformacoes", finalizar);
		},
	}
};
</script>
<style scoped>

.input-group i{
	position: absolute;
	top: 7px;
	left: 95%;
	font-size: 1.2rem;
}
.container-form{
	width: 100%;
	display: flex;
	height: 100%;
	flex-direction: column;
	gap: 10px;
}
.agroup{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}
.agroup div:nth-child(1) input{
	width: 380px;
}

.agroup div:nth-child(2) input{
	width: 217px;
}
.botoes{
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 20px;
	margin-top: 15px;
	justify-content: center;
}
.botoes .botao{
	width: 50%;
}

#cpf, #cnpj{
	width: 400px;
}

.required{
	color: red;
}

@media screen and (max-width: 425px) {
	.input-group{
		width: 95% !important;
	}
	.agroup{
		width: 100%;
		gap: 10px;
	}
	.agroup div:nth-child(1) input{
		width: 100%;
	}
	.agroup div:nth-child(2) input{
		width: 100%;
	}

	#cpf, #cnpj {
		width: 100% !important;
	}
	
	#nomeCliente {
		width: 100% !important;
	}
	
	.input-group i{
		position: absolute;
		top: 7px;
		left: 90%;
		font-size: 1.2rem;
	}
	
}
.container-taxa-entrega{
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.input-radio{
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;	
}
</style>