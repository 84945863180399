<template>
	<div class="containerModalEdit">
		<div class="containerTitleModal">
			<h2>
				{{ produtoSelecionado.id }} - {{ produtoSelecionado.descricao }}
			</h2>
			<div class="containerQtdMaxima">
				<span v-if="produtoSelecionado.produto.qteEmbalagem > 1"> 
					{{ produtoSelecionado.quantidade }} / {{ produtoSelecionado.produto.qteEmbalagem }} 
				</span>
			</div>
		</div>
		<div class="containerQuantidade">
			<span>Quantidade</span>
			<div>
				<button 
					class="btnLess" 
					@click="produtoSelecionado.quantidade > 0 
						? produtoSelecionado.quantidade-- 
						: produtoSelecionado.quantidade = 0
					"
					v-if="produtoSelecionado.produto.qteEmbalagem <= 1"
				>
					<i class="fas fa-minus" />
				</button>
				<label class="containerInputQtd">
					<input 
						type="text" 
						:value="produtoSelecionado.quantidade" 
						readonly
					/>
				</label>
				<button 
					class="btnPlus" 
					@click="produtoSelecionado.quantidade++"
					v-if="produtoSelecionado.produto.qteEmbalagem <= 1"	
				>
					<i class="fas fa-plus" />
				</button>
			</div>
		</div>
		<div class="containerContent">
			<div 
				class="containerSugestao" 
				v-if="produtoSelecionado.sugestoes 
					? produtoSelecionado.sugestoes.length > 0 
					: false
				"
			>
				<div 
					class="containerTitleSugestao" 
					@click="mostrarSugestoes = !mostrarSugestoes"
				>
					<span>
						Sugestões
					</span>
					<i 
						class="fas fa-chevron-down" 
						style="color: #000" 
						v-if="!mostrarSugestoes"
					/>
					<i 
						class="fas fa-chevron-up" 
						style="color: #000" v-else 
					/>
				</div>
				<div 
					class="contentSugestoes" 
					v-if="mostrarSugestoes"
				>
					<div 
						class="cardSugestao" 
						v-for="(sugestao, index) in produtoSelecionado.sugestoes" 
						:key="index"
					>
						<div class="sugestaoTitle">
							{{ sugestao.descricao }}
						</div>	
						<div class="handleSugestao">
							<button 
								class="btnLess" 
								@click="handleAmountSugestao(sugestao, 'less')"
							>
								<i class="fas fa-minus" />
							</button>
							<input 
								type="text" 
								:value="sugestao.quantidade" 
								readonly
							/>
							<button 
								class="btnPlus" 
								@click="handleAmountSugestao(sugestao, 'add')"
							>
								<i class="fas fa-plus" />
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="containerObservacao">
				<label for="observacao">
					Observação
				</label>
				<textarea  v-model="produtoSelecionado.observacao">

				</textarea>
			</div>
			<div class="containerEditButtons">
				<button 
					class="botao botao-cancelar" 
					@click="$emit('cancelItem')"
				>
					Cancelar
				</button>
				<button 
					class="botao botao-adicionar" 
					@click="saveItem"
				>
					Salvar
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ProdutoSugestao",

	props: {
		produtoSelecionado: Object,
	},

	data() {
		return {
			mostrarSugestoes: true,
		};
	},

	methods: {
		saveItem() {
			const sugestaoMontada = [];
			if(this.produtoSelecionado?.sugestoes?.length > 0) {
				this.produtoSelecionado.sugestoes.forEach((sug, index) => {
					if(sug.quantidade > 0) {
						sug.cdseq = index + 1;
						sugestaoMontada.push({
							...sug,
						});
					}
				});
			}

			this.$emit("saveItem", sugestaoMontada);
		},

		handleAmountSugestao(sugestao, increment) {
			if(increment === "add") {
				sugestao.quantidade ++;
				if (sugestao?.multiplo) {
					this.produtoSelecionado.quantidade += sugestao.multiplo;
				}
			}
			if(increment === "less" && (sugestao?.quantidade > 0)) {
				sugestao.quantidade --;
				if (sugestao?.multiplo) {
					if(this.produtoSelecionado.quantidade === 0) return;
					this.produtoSelecionado.quantidade -= sugestao.multiplo;
				}
			}
			this.$forceUpdate();
		},


	}
};
</script>

<style scoped>
.containerModalEdit{
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex-grow: 1;
}
.containerTitleModal{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0px;
}
.containerQtdMaxima span{
	font-weight: bold;
}
.containerQuantidade{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.containerQuantidade input {
	border: none;
	outline: none;
	text-align: center;
	width: 50px;
	padding: 10px;
}
.containerQuantidade button{
	padding: 5px;
}
.btnLess{
	border: 2px solid var(--cancelar-borda);
	border-radius: 5px;
	background: var(--cancelar-background);
}
.btnLess i{
	color: var(--cancelar-texto);
}
.btnPlus{
	border: 2px solid var(--salvar-borda);
	border-radius: 5px;
	background: var(--salvar-background);
}
.btnPlus i{
	color: var(--salvar-texto);
}
.containerContent{
	display: flex;
	flex-direction: column;
	width: 100%;
}
.containerSugestao{
	display: flex;
	flex-direction: column;
	width: 100%;
	
}
.containerTitleSugestao{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	justify-content: flex-start;
}
.contentSugestoes{
	width: 100%;
	height: 40vh;
	overflow-y: auto;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: space-between;
	gap: 5px;	
}
.cardSugestao{
	display: flex;
	flex-direction: column;
	width: auto;
	margin-top: 5px;
}
.handleSugestao{
	display: flex;
	flex-direction: row;
	gap: 5px;
}
.handleSugestao input{
	width: 75px;
	border-top: none;
	border-left: none;
	border-right: none;
	text-align: center;
	background: transparent;
	outline: none;
}
.handleSugestao button{
	width: 40px;
	height: 40px;
}
.containerObservacao{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
}
.containerObservacao textarea{
	width: 100%;
	height: 15vh;
	resize: none;
}
.containerEditButtons{
	width: 100%;
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
}
.containerEditButtons button {
	width: 45%;
}


</style>