export class PaginacaoOption {
	valor = "";
	tipoConteudo = "";
	mensagem = "";

	constructor(valor, tipoConteudo, mensagem) {
		this.valor = valor;
		this.tipoConteudo = tipoConteudo;
		this.mensagem = mensagem;
	}
}

export class PaginacaoConfig {
	options = [];
	desabilitarNovo = false;

	constructor(options = [], desabilitarNovo = false) {
		this.options = options;
		this.desabilitarNovo = desabilitarNovo;
	}
}