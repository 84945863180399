<template>
    <div class="modal">
        <div class="modal-interno">
            <iframe id="printer" name="printerFrame" width="100%" height="100%" :src="base64"></iframe>
            <button class="botao botao-cancelar w-80 m-5" @click="finalizarImpressao">Fechar</button>
        </div>
    </div>
</template>

<script>
export default {
	name: "Impressao",
	data() {
		return {
			printed: false,
		};
	},
	computed: {
		base64() {
			return this.$store.state.base64;
		}
	},
	methods: {
		iniciarImpressao() {
			window.frames["printer"].focus();
			window.frames["printer"].contentWindow.print();
		},
		finalizarImpressao() {
			this.$store.state.base64 = null;
			if(this.$route.path === "/lancamento") return;
			if(this.$store.state.Empresa.tiposistema === "L") {
				this.$router.replace({name: "Principal"});
			}
			if((this.$route.path === "/fechamento") && !this.$route.query.impressaoPix){
				this.$router.replace({ name: "Principal" });
				this.$route.query.tipoVenda = "S";
				this.$store.commit("limparStateVenda");
				this.$forceUpdate();
			}
			if(this.$route.query.impressaoPix){
				this.$router.replace({name: "Fechamento", query: {tipoVenda: this.$store.state.Venda.tipoVenda}});
			}
		}
	},
	mounted() {
		document.addEventListener("keydown", event => {
			if (event.key == "Esc") {
				this.finalizarImpressao();
			}
		});
		setTimeout(() => {
			this.iniciarImpressao();
		},50);
	},
	destroyed() {
		document.removeEventListener("keydown", event => {
			if (event.key == "Esc") {
				this.finalizarImpressao();
			}
		});
	}
};
</script>

<style scoped>
.modal {
    height: auto;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
}
.modal-interno {
    background-color: var(--bg-app);
    box-shadow: var(--shadow-primario);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 98%;
    width: 98%;
    margin: 20px;
    border-radius: 10px;
    position: absolute;
}
</style>
