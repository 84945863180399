import {doRequest} from "./http.js";
export default {
	state: {
		NcmsIncorreto: []
	},

	actions: {
		async updateTableNcm({state}) {
			state.carregando = true;
			const url = "auxncm/update";
			await doRequest(url, {method: "GET"});
			state.carregando = false;
		},

		async getNcmsIncorretos({state, commit}) {
			const cdempresa = state.Empresa.id;
			const url = `auxncm/ncm-invalido?cdempresa=${cdempresa}`;
			try {
				const data = await doRequest(url, {method: "GET"}, commit);
				if(data && data.length > 0) {
					commit("atualizarListaNcm", data);
					return;
				}
				if(data && data.mensagem){
					commit("atualizarListaNcm", []);
					return;
				}
				throw new Error(data);

			} catch (error) {
				commit("relatarErro", {
					mensagem: `Erro: ${error.message}`
				});
			}
		},

		async buscarProdutosNcmIncorreto({state, commit}, payload) {
			state.carregando = true;
			try {
				const cdempresa = state.Empresa.id;
				let url = `consultaProduto/localiza-ncm?cdempresa=${cdempresa}`;
				if(!payload) commit("relatarErro", {mensagem: "Esta faltando o NCM"});
				url += `&ncm=${payload}`;

				const data = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);
				state.carregando = false;
				return data;
			} catch (error) {
				console.error(error);
			}
		},

		async updateCorrectNcm({state, commit}, payload) {
			state.carregando = true;
			const idEmpresa = state.Empresa.id;
			const { ncmCorreto, ncmIncorreto } = payload;
			try {
				let url = `produto/update-ncm?empresa=${idEmpresa}`;
				if(ncmCorreto) {
					url += `&ncmCorreto=${ncmCorreto}`;
				}
				if(ncmIncorreto) {
					url += `&ncmIncorreto=${ncmIncorreto}`;
				}

				const data = await doRequest(url, {method: "PUT"}, commit);
				
				state.carregando = false;
				if(data && data.erro) {
					throw new Error(data.mensagem ? data.mensagem : data);
				}

				return data;
				
			} catch (error) {
				console.error(error);
				commit("relatarErro", {
					mensagem: `Erro: ${error}`
				});
			}
		},
		
		async verificaNcm({commit}, payload) {
			try {
				const {ncm} = payload;
				if(!ncm) return commit("relatarErro", {mensagem: "Faltou o campo NCM"});
				const url = `auxncm/verificar-ncm?ncm=${ncm}`;

				const data = await doRequest(url, {method: "GET"}, commit);

				if(data && data.mensagem && data.mensagem === "Ncm inválido, por favor verifique!") {
					return {
						invalido: true,
						mensagem: data.mensagem
					};
				}
				return {
					invalido: false,
					mensagem: data.mensagem
				};
			} catch (error) {
				console.error(error);
			}
		}
	},
	mutations: {
		atualizarListaNcm(state, payload) {
			state.NcmsIncorreto = [];
			state.NcmsIncorreto = payload;
		}
	}
};