<template>
	<div>
		<div class="spinner">
		</div>
	</div>
</template>
<script>
export default{
	name: "Loader",
	props: {
		width: String,
		height: String,
		idSpinner: String,
	},

	mounted () {
		this.changeSizeSpinner(this.idSpinner);
	},

	methods: {
		changeSizeSpinner(id) {
			const spinner = document.getElementById(id);
			if (spinner) {
				spinner.style.width = this.width;

				spinner.style.height = this.height;
			}
		}
	},

	watch: {
		id(newV) {
			this.changeSizeSpinner(newV);
		},
	}
};
</script>
<style scoped>
.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--primario);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
</style>