import { doRequest  } from "./http";

class LogWeb {
	constructor(log){
		this.cdlog = log.cdlog;
		this.cdusuario = log.cdusuario;
		this.nomeusuario = log.nomeusuario;
		this.cdempresa = log.cdempresa;
		this.tela = log.tela;
		this.acao = log.acao;
		this.codigo = log.codigo;
		this.motivo = log.motivo;
		this.dataalteracao = log.dataalteracao;
	}
}

class LogWebFiltros {
	constructor (logs) {
		this.tela = logs.tela;
		this.acao = logs.acao;

	}
}

export default {
	state: {
		Logs: {
			listaLogs: [],
			paginacao: {
				page: 0,
				totalPages: 0,
				size: 25,
			},
		},
		filtrosLog: [],
	},
	actions: {
		async buscarLogs({state, commit}, payload){
			state.carregando = true;
			let url;
			if(!payload){
				url = `logs?empresa=${state.Empresa.id}&sortOrder=desc`;
			}else {
				url = `logs?empresa=${state.Empresa.id}${payload.page ? `&page=${payload.page}` : ""}${payload.size ? `&size=${payload.size}` : ""}
				${payload.filtroDinamico.telas ? `&tela=${payload.filtroDinamico.telas}` : ""}${payload.filtroDinamico.acao ? `&acao=${payload.filtroDinamico.acao}` : ""}
				${payload.dataI ? `&dataI=${payload.dataI}` : ""}${payload.dataF ? `&dataF=${payload.dataF}` : ""}${payload.codigo ? `&codigo=${payload.codigo}` : ""}&sortOrder=desc`;
			}
		
			const data = await doRequest(url, {method: "GET", cache:"no-cache"}, commit);
			state.carregando = false;
			if(!data){
				return null;
			}
			if(!data.content){
				commit("atualizarListaLogs", []);

			}
			commit("atualizarListaLogs", data);
		},
		async buscarFiltrosLogs({commit}){
			const url = "logs/filtros";
			const data = await doRequest(url, {method: "GET", cache:"no-cache"}, commit);
			if(!data) return;

			commit("atualizarFiltrosLog", data);
		}
	},
	mutations: {
		atualizarFiltrosLog(state, payload){
			if(!payload) return;
			state.filtrosLog = new LogWebFiltros(payload);
		},
		atualizarListaLogs(state, payload){
			state.Logs = {
				listaLogs: [],
				paginacao: {
					page: 0,
					totalPages: 0,
					size: 25,
				},
			};
			if(!payload.content) return;
			state.Logs = {
				listaLogs: payload.content.map(log => new LogWeb(log)),
				paginacao: {
					page: payload.page,
					size: payload.size,
					totalPages: payload.totalPages,
				},
			};

		}
	},
};