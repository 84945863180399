import { TransferenciaEstoqueAPI, TransferenciaEstoque } from "../models/TransferenciaEstoque";
import { doRequest } from "./http";
// import { TransferenciaEstoque } from "../models/TransferenciaEstoque";
import { gerarHeaderCupom, gerarImpressao, gerarMensagemAgradecimento, gerarRodape } from "../plugins/pdfmake.js";

export default {
	state: {
		totalPaginasTransferenciaEstoque: 0,
		paginaTransferenciaEstoqueAtual: 0,
		transferenciaEstoque: {
			listaTransferencias: [],
			paginacao: {
				page: 0,
				totalPages: 0,
				size: 25,
			}
		},
	},
	actions: {
		async deleteTransferencia({state, commit}, payload){
			state.carregando = true;
			const url = `transferenciaProduto?id=${payload}`;
			const data = await doRequest(url, {method: "DELETE", cache: "no-cache"}, commit);
			if(!data) return;
			state.carregando = false;
		},
		async cadastrarTransferencia ($store, payload) {
			$store.state.carregando = true;
			const url = "transferenciaProduto";
			const form = new TransferenciaEstoqueAPI(payload);
			const body = JSON.stringify(form);
			const data = await doRequest(url, {method: "POST", body, cache: "no-cache"}, $store.commit);
			$store.state.carregando = false;
			if(!data){
				return null;
			}
			return data;
		},

		async atualizarTransferencia ($store, payload){
			$store.state.carregando = true;
			const url = `transferenciaProduto?id=${payload.cdtransferencia}`;
			const body = JSON.stringify(new TransferenciaEstoqueAPI(payload));

			const data = await doRequest(url, {method: "PUT", body, cache:"no-cache"}, $store.commit);
			if(!data){
				return null;
			}
			$store.state.carregando = false;
		},

		async finalizarTransferencia ($store, payload) {
			const url = `transferenciaProduto/confirmar?id=${payload.cdtransferencia}`;
			const body = JSON.stringify(new TransferenciaEstoqueAPI(payload));
			const data = await doRequest(url, {method: "PUT", body, cache: "no-cache"}, $store.commit);
			if(!data){
				return null;
			}
			return data;
		},
		
		async buscarTransferenciaEstoque ($store) {
			const url = `transferenciaProduto/filtrar?empresaOrigem=${$store.state.Empresa.id}`;
			const data = await doRequest(url, {method: "GET", cache: "no-cache"}, $store.commit);
			if(!data){
				return null;
			}
			$store.commit("atualizarListaTransferencia", data);
			
		},
		async filtrarTransferenciaEstoque($store, payload) {
			$store.state.carregando = true;
			const url = `transferenciaProduto/filtrar?${payload.id ? `id=${payload.id}` : ""}${payload.order ? `&sortOrder=${payload.order}` : ""}${payload.sort ? `&sort=${payload.sort}` : ""}${payload.dataF ? `&dataf=${payload.dataF}` : "" }${payload.dataI ? `&datai=${payload.dataI}` : ""}${payload.filtroDinamico.empresa ? `&empresaDestino=${payload.filtroDinamico.empresa}` : ""}
			${payload.filtroDinamico.situacao ? `&situacao=${payload.filtroDinamico.situacao}` : "" }${payload.filtroDinamico.tipo ? `&tipo=${payload.filtroDinamico.tipo}` : "" }
			${payload.page ? `&page=${payload.page}` : ""}${payload.size ? `&size=${payload.size}` : ""}&empresaOrigem=${$store.state.Empresa.id}`;


		
			const data = await doRequest(url, {method: "GET", cache: "no-cache"}, $store.commit);
			$store.state.carregando = false;
			if(!data.content){
				return $store.commit("atualizarListaTransferencia", []);
			}
			$store.commit("atualizarListaTransferencia", data);
		},

		async gerarComprovanteTransferencia (context, payload) {

			let tabelaProdutos = [];
			let tableWidths = [];
			if (payload.pageWidth) {
				if(!payload.transferencia.valorcusto && !payload.transferencia.valorvenda){
					tabelaProdutos.push([
						{ text: "Cód.", style: "headerTabela" },
						{ text: "Cód Referencia", style: "headerTabela"},
						{ text: "Descrição", style: "headerTabela" }, 
						{ text: "Quantidade", style: "headerTabela"},
					]);
				}else if(!payload.transferencia.valorcusto){
					tabelaProdutos.push([
						{ text: "Cód.", style: "headerTabela" },
						{ text: "Cód Referencia", style: "headerTabela"},
						{ text: "Descrição", style: "headerTabela" }, 
						{ text: "Qtd.", style: "headerTabela"},
						{ text: "$ Prazo", style: "headerTabela"},
						{ text: "$ Vista", style: "headerTabela"},
					]);
				}else if(!payload.transferencia.valorvenda){
					tabelaProdutos.push([
						{ text: "Cód.", style: "headerTabela" },
						{ text: "Cód Referencia", style: "headerTabela"},
						{ text: "Descrição", style: "headerTabela" }, 
						{ text: "Qtd.", style: "headerTabela"},
						{ text: "Custo", style: "headerTabela"}
					]);	
				}else{
					tabelaProdutos.push([
						{ text: "Cód.", style: "headerTabela" },
						{ text: "Cód Referencia", style: "headerTabela"},
						{ text: "Descrição", style: "headerTabela" }, 
						{ text: "Qtd.", style: "headerTabela"},
						{ text: "$ Prazo", style: "headerTabela"},
						{ text: "$ Vista", style: "headerTabela"},
						{ text: "Custo", style: "headerTabela"}

					]);					
				}
				payload.transferencia.produtos.map(produto => {
					if(!payload.transferencia.valorcusto && !payload.transferencia.valorvenda){
						tabelaProdutos.push([
							{text: `${produto.idProduto}`, style: "itemTabela"},
							{text: `${produto.produtoTodo.cdreferencia?produto.produtoTodo.cdreferencia:"S/Ref."}`, style: "descricao"},
							{text: `${produto.produtoTodo.descricao}`, style: "descricao"},
							{text: `${produto.quantidade}`, style: "itemTabela"},
		
						]);
						tableWidths = ["auto","auto", "*", "auto"];
					}else if(!payload.transferencia.valorcusto){
						tabelaProdutos.push([
							{text: `${produto.idProduto}`, style: "itemTabela"},
							{text: `${produto.produtoTodo.cdreferencia}`, style: "descricao"},
							{text: `${produto.produtoTodo.descricao}`, style: "descricao"},
							{text: `${produto.quantidade}`, style: "itemTabela"},
							{text: `${Number(produto.valorPrazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, style: "valorTabela"},
							{text: `${Number(produto.valorVista).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, style: "valorTabela"},
						]);
						tableWidths = ["auto","*", "*", "auto", "auto", "auto"];
					}else if(!payload.transferencia.valorvenda){
						tabelaProdutos.push([
							{text: `${produto.idProduto}`, style: "itemTabela"},
							{text: `${produto.produtoTodo.cdreferencia}`, style: "descricao"},
							{text: `${produto.produtoTodo.descricao}`, style: "descricao"},
							{text: `${produto.quantidade}`, style: "itemTabela"},
							{text: `${Number(produto.custoProduto).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, style: "valorTabela"},
						]);
						tableWidths = ["auto","*", "*", "auto", "auto"];
					}else {
						tabelaProdutos.push([
							{text: `${produto.idProduto}`, style: "itemTabela"},
							{text: `${produto.produtoTodo.cdreferencia}`, style: "descricao"},
							{text: `${produto.produtoTodo.descricao}`, style: "descricao"},
							{text: `${produto.quantidade}`, style: "itemTabela"},
							{text: `${Number(produto.custoProduto).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, style: "valorTabela"},
							{text: `${Number(produto.valorPrazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, style: "valorTabela"},
							{text: `${Number(produto.valorVista).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, style: "valorTabela"},
						]);
						tableWidths = ["auto","*", "*", "auto", "auto", "auto", "auto"];

					}
				});


			} else {
				tabelaProdutos.push([
					{ text: "Cód.", style: "headerTabela" },
					{ text: "Cód Referencia", style: "headerTabela"},
					{ text: "Descrição", style: "headerTabela" }, 
					{ text: "Qtd.", style: "headerTabela"},

				]);

				payload.transferencia.produtos.map(produto => {
					tabelaProdutos.push([
						{text: `${produto.idProduto}`, style: "itemTabela"},
						{text: `${produto.produtoTodo.cdreferencia?produto.produtoTodo.cdreferencia:"S/Ref."}`, style: "itemTabela"},
						{text: `${produto.produtoTodo.descricao}`, style: "itemTabela"},
						{text: `${produto.quantidade}`, style: "itemTabela"},
					]);
				});
				tableWidths = ["auto", "*", "auto", "auto"];
			}

			//CALCULO TOTALIZADORES
			const totalPrazo = payload.transferencia.produtos.map(produto => Number(Number(produto.valorPrazo || 0) * Number(produto.quantidade || 1)));
			let resultadoPrazo = 0;
			for(let i in totalPrazo){
				resultadoPrazo += totalPrazo[i];
			}
		
			const totalVista = payload.transferencia.produtos.map(produto => Number(Number(produto.valorVista || 0) * Number(produto.quantidade || 1)));
			let resultadoVista = 0;
			for(let i in totalVista){
				resultadoVista += totalVista[i];
			}
			const totalCusto = payload.transferencia.produtos.map(produto => Number(Number(produto.quantidade || 0) * Number(produto.custoProduto || produto.custofixo)));
			let resultadoCusto = 0;
			for (let i in totalCusto){
				resultadoCusto += totalCusto[i];
			}
			const totalQuantidade = payload.transferencia.produtos.map(produto => produto.quantidade);
			let qtdTotal = 0;
			for(let i in totalQuantidade) {
				qtdTotal += totalQuantidade[i];
			}
			//ESTILOS
			const styles = {
				headerTabela:{
					fontSize: 9,
					bold: true,
					alignment: "center",
					noWrap: true
				},
				itemTabela: {
					fontSize: 8,
					alignment: "center",
				},
			};
			let pageSize = !payload.pageWidth ? {
				width: 200,
				height: "auto"
			} : payload.pageWidth;
		
			//DOCUMENTO
			const docDefinition = {
				info: {
					title: `Transferência feita por ${payload.transferencia.nomeUsuario}`,
					author: "BDS Soluções em Sistemas"
				},

				pageSize,
			
				pageMargins: payload.pageWidth ? [10, 0, 10, 0] : [1, 0, 1, 0],
				styles,
	

				content: [
					...gerarHeaderCupom(context.state.Empresa), // Esse header é da empresa origem, deixa ele
		
					{ text: "Relatório Transferência de Estoque", style: "header" },
					{ text: `Transferência Cód. ${payload.transferencia.cdtransferencia}`, style: {bold: true, fontSize: 9}},
					{ text: `Empresa Destino: ${payload.transferencia.cdempresadestino} - ${payload.transferencia.empresadestino}`, style: { bold: true, fontSize: 9 } },
					{ text: `Data Transferência: ${payload.transferencia.data.split("-").reverse().join("/")}` , style: { bold: true, fontSize: 9 } },
					
					{
						table: {
							headerRows: 1,
							body: tabelaProdutos,
							widths: tableWidths,
						},
						layout: {
							paddingBottom: function () {
								return 3;
							},
							paddingLeft: function () {
								return 1;
							},
							paddingRight: function () {
								return 1;
							},
							paddingTop: function () {
								return 1;
							}
						},
	
					},
				
					payload.transferencia.valorvenda ? {
						text : `Valor total a prazo da transferência: ${Number(resultadoPrazo).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}`,
						style: "valores"
					} : {},
					payload.transferencia.valorvenda ? {
						text: `Valor total a vista da transferência: ${Number(resultadoVista).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}`,
						style: "valores"
					} : {},
					payload.transferencia.valorcusto ? {
						text: `Valor total custo ${Number(resultadoCusto).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}`,
						style: "valores"
					} : {},
					{
						text: `Quantidade de produtos transferidos: ${qtdTotal}`,
						style: "valores"
					},
					{
						text: gerarMensagemAgradecimento(new Date().getHours()),
						style: "header"
					},
		
					

					...gerarRodape(),
				],

			};
		
			const base64Pdf = await gerarImpressao(docDefinition);
			if (!base64Pdf) {
				throw new Error("Não foi possível gerar a impressão....");
			}
			context.state.base64 = base64Pdf;
		}
	},

	mutations: {
		atualizarListaTransferencia (state, payload) {
			state.transferenciaEstoque = {
				listaTransferencias: [],
				paginacao: {
					size: 25,
					page: 0,
					totalPages: 0,
				}
			};
			state.TransferenciaEstoque = {
				listaTransferencias: [],
				paginacao: {
					size: 25,
					page: 0,
					totalPages: 0,
				}
			};
			if(!payload.content) return; 
			state.paginaTransferenciaEstoqueAtual = payload.page;
			state.totalPaginasTransferenciaEstoque = payload.totalPages;
			state.TransferenciaEstoque.paginacao = {
				listaTransferencias: payload.content.map(transferencia => new TransferenciaEstoque(transferencia)),
				last: payload?.last || true,
				page: payload?.page || 0,
				size: payload?.size || 25,
				totalElements: payload?.totalElements || 0,
				totalPages: payload?.totalPages || 1,
			};
			state.transferenciaEstoque = {
				listaTransferencias: payload.content.map(transferencia => new TransferenciaEstoque(transferencia)),
				paginacao:{
					size: payload.size,
					page: payload.page,
					totalPages: payload.totalPages
				}
			};

			payload.content.map(transferencia => new TransferenciaEstoque(transferencia));
		}
	}
};

