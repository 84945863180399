<template>
	<div id="CadastroPromocao">
		<div class="row center w-100">
			<h1 class="titulo">Cadastro de Promoções</h1>
		</div>
		<div class="container-page">
			<div 
				id="containerForm" 
				class="container-form"
			>
				<div id="nomePromocao" class="input-group">
					<input
						style="text-transform: uppercase;"
						v-model="promocaoCadastrada.nome"
						type="text" id="nome" class="w-100"
						placeholder=" "
						@keydown.enter="proximoCampo('datainicio')" maxlength="30"
						autocomplete="off"
					>
					<label for="nome">Nome</label>
				</div>
				<div class="todos-produtos">
					<div class="col center no-white-space todosprodutos">
						<label for="todosProdutos">Todos os produtos</label>
						<div class="row between mx-5 w-100">
							<div class="row center">
								<label for="todosProdutosS">Sim</label> &nbsp;
								<input 
									type="radio" name="todosProdutos" 
									id="todosProdutosS" value="S"
									v-model="promocaoCadastrada.todosProdutos" 
									@click="alterarTodosProdutos"
									>
							</div>
							<div class="row center">
								<label for="todosProdutosN">Não</label> &nbsp;
								<input 
									type="radio" name="todosProdutos" 
									id="todosProdutosN" value="N"
									v-model="promocaoCadastrada.todosProdutos"
								>
							</div>
						</div>
					</div>
				</div>
				<div class="data">
					<div class="input-group" id="selectPeriodoPromocao">
						<select v-model="dataSemana">
							<option value="data">Período</option>
							<option value="semana">Dias da Semana</option>
							<option value="pagamento">Forma de Pagamento</option>
						</select>
					</div>
					<div class="row datas-filtro" v-if="dataSemana === 'data'">
						<div class="input-group">
							<input 
								type="date" id="datainicio" 
								v-model="promocaoCadastrada.datainicio"
								@keydown.enter="proximoCampo('datafinal')"
								@blur="saiuData"
							>
							<label for="datainicio">Início</label>		
						</div>
						<div class="input-group">
							<input 
								type="date" id="datafinal" 
								v-model="promocaoCadastrada.datafinal"
								@keydown.enter="proximoCampo('cdproduto')"
								@blur="saiuData"
							>
							<label for="datafinal">Fim</label>
						</div>
					</div> 
					<div class="row dias-semana"  v-if="dataSemana === 'semana'">
						<p>Selecione os dias da semana</p>
						<div class="semana">
							<div class="dia">
								<input type="checkbox" id="dom" style="cursor: pointer" v-model="promocaoCadastrada.domingo">
								<label for="dom" style="cursor: pointer">Dom</label>
							</div>
							<div class="dia">
								<input type="checkbox" id="seg" style="cursor: pointer" v-model="promocaoCadastrada.segunda">
								<label for="seg" style="cursor: pointer">Seg</label>
							</div>
							<div class="dia">
								<input type="checkbox" id="ter" style="cursor: pointer" v-model="promocaoCadastrada.terca">
								<label for="ter" style="cursor: pointer">Ter</label>
							</div>
							<div class="dia">
								<input type="checkbox" id="qua" style="cursor: pointer" v-model="promocaoCadastrada.quarta">
								<label for="qua" style="cursor: pointer">Qua</label>
							</div>
							<div class="dia">
								<input type="checkbox" id="qui" style="cursor: pointer" v-model="promocaoCadastrada.quinta">
								<label for="qui" style="cursor: pointer">Qui</label>
							</div>
							<div class="dia">
								<input type="checkbox" id="sex" style="cursor: pointer" v-model="promocaoCadastrada.sexta">
								<label for="sex" style="cursor: pointer">Sex</label>
							</div>
							<div class="dia">
								<input type="checkbox" id="sab" style="cursor: pointer" v-model="promocaoCadastrada.sabado">
								<label for="sab" style="cursor: pointer">Sab</label>
							</div>
						</div>
					</div>
					<div class="row" v-if="dataSemana === 'pagamento'" style="margin-top: 10px;">
						<button 
							class="botao botao-acao btn-consulta" 
							@click="abrirModalFormas"
							style="max-width: 200px;"
						>
							<i
								class="fas fa-search"
								for="cdproduto"
								style="color: var(--acao-borda)"
							></i>
							<span style="cursor: pointer;" class="lg-view">Formas de Pgto</span>
							<span style="cursor: pointer;" class="sm-view">Formas</span>
						</button>
					</div>
				</div>				
				<div class="row descontos">
					<div class="input-group">
						<input 
							@keydown="aplicarDescontoPromocao" @input="descontoTabela" placeholder=" "
							v-model="promocaoCadastrada.descontovista" style="text-align: right;" type="text" id="descontovista"
							@keydown.enter="proximoCampo('descontoprazo')" @focus="selecionarTexto"
							autocomplete="off">
						<label for="descontovista" class="grande">Desconto a vista (%)</label>
						<label for="descontovista" class="pequeno">Desc. vista (%)</label>
					</div>
					<div class="input-group">
						<input @keydown="aplicarDescontoPromocao" @input="descontoTabela" v-model="promocaoCadastrada.descontoprazo"
							style="text-align: right;" type="text" id="descontoprazo" min="1" @focus="selecionarTexto"
							autocomplete="off">
						<label for="descontoprazo" class="grande">Desconto a prazo (%)</label>
						<label for="descontoprazo" class="pequeno">Desc. prazo (%)</label>
					</div>
				</div>	
				<div id="produto" class="row">
					<div id="cod-codbarra" class="input-group" style="max-width: 275px;">
						<input maxlength="12" type="text" v-model="cdproduto" id="cdproduto"
							placeholder="Cód / Cód. barra / (F4)" @focus="selecionarTexto"
							@keydown.enter.prevent="buscarProduto" @keydown="marcaraCdproduto"
							autocomplete="off">
					</div>
					<div class="botoes-consulta">
						<div 
							class="botao botao-acao" 
							@click="abrirModal"
						>
							<i
								class="fas fa-search"
								for="cdproduto"
								style="color: var(--acao-borda)"
							></i>
							<label style="cursor: pointer;" >Consultar Produto</label>
							<label style="cursor: pointer;" class="sm-view">Consultar</label>
							<!-- <label class="no-white-space" for="cdproduto">Produto</label> -->
						</div>
						<button 
							class="botao botao-cancelar btn-consulta" 
							@click="removerProdutoBotao"
							style="max-width: 200px;"
							v-if="promocaoCadastrada.produtos.length>0"
						>
							<i
								class="fas fa-trash-alt"
								for="cdproduto"
								style="color: var(--cancelar-borda)"
							></i>
							<span style="cursor: pointer;" class="lg-view">Remover Produto</span>
							<span style="cursor: pointer;" class="sm-view">Remover</span>
						</button>
					</div>
				</div>		
			</div>
			<div class="container-form">
				<div class="produtos-promocao">
					<table class="w-100" v-if="promocaoCadastrada.produtos.length">
						<thead>
							<tr>
								<td class="center" style="max-width: 35px; min-width: 35px;">Seq.</td>
								<td class="center">Cód.</td>
								<td style="min-width: 400px;">Produtos</td>
								<td class="center">$ prazo</td>
								<td class="center">$ vista</td>
								<td class="center">Ação</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(produto, index) in promocaoCadastrada.produtos" :key="index">
								<td class="center">{{ index + 1 }}</td>
								<td class="center">{{ produto.cdproduto }}</td>
								<td style="text-align: left;">{{ produto.nomeProduto }}</td>
								<td class="text-right" >{{ (produto.descprazo || 0).toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL"
									})
								}}</td>
								<td class="text-right">{{ (produto.descvista || 0).toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL"
									})
								}}</td>
								<td style="text-align: center">
									<i class="fas fa-trash-alt" @click="solicitaRemoverItem(index)"
										style="color: var(--deletar)"></i>
								</td>
							</tr>
						</tbody>
					</table>
					<div v-else class="col center">
						<h3 v-if="promocaoCadastrada.todosProdutos == 'N'">Insira os produtos para promoção!</h3>
						<h3 v-else>Todos os produtos da loja serão incluidos na promoção!</h3>
					</div>
				</div>
			</div>
		</div>
		<div class="botoes">
			<button class="botao botao-cancelar" id="botaoCancelar" @click="cancelarEdicao">
				<label class="lg-view">Cancelar [Alt + C]</label>
				<label for="botaoCancelar" class="sm-view">Cancelar</label>
			</button>
			<button class="botao botao-adicionar" id="botaoAdicionar" @click="adicionarPromocao">
				<label for="botaoAdicionar" class="lg-view">Salvar [Alt + S]</label>
				<label for="botaoAdicionar" class="sm-view">Salvar</label>
			</button>
		</div>
		<Modal v-if="listarProdutos" v-on:fecharModal="listarProdutos = false">
			<consulta-produtos 
				v-if="buscaProduto"
				:Produtos="listadeproduto"
				@selecionarProduto="selecionarProduto"
				:buscarTodosPorDescricao="true" 
				@selecionarTodos="selecionarTodos" 
				@cancelarTodos="polarCancelado"
				:height="'55vh'"
			/>

			<!-- <Paginacao v-if="buscaProduto" tipoConteudo="Produtos" :desabilitarNovo="true" :buscarTodosPorDescricao="true" v-on:selecionarTodos="selecionarTodos" v-on:cancelarTodos="polarCancelado">
				<ListagemProdutos v-bind:Produtos="listadeproduto" v-on:selecionarProduto="selecionarProduto"
				:desabilitarAcao="true" />
			</Paginacao> -->
		</Modal>
		<Modal 
			v-if="listarFormas" 
			@fecharModal="listarFormas = false" 
			:dimensoes="{width: '40%', height: '90%'}"
			:idModal="'lista-formasPagamento'">
			<div class="container-modal">
				<span class="title-modal">
					Formas de Pagamento
				</span>
				<div 
					class="container-tabela tabela-container"  
					v-if="FormasPagamentoVista.length > 0" 
					id="table"
				>
					<span>Formas de Pagamento à Vista:</span>
					<div class="header-tabela">
						<div class="linha-header-tabela">
							<div class="campo-tabela campo-medio">
								<span>Descrição</span>
							</div>
							<div class="campo-tabela campo-menor">
								<span>Selecionado</span>
							</div>
						</div>
					</div>
					<div class="body-tabela">
						<div 
							class="linha-body-tabela" 
							v-for="(forma, index) in FormasPagamentoVista" 
							:key="index" :id="`linha-${index}`"
						>
							<div class="campo-tabela campo-medio">
								{{ forma.descricao }}
							</div>
							<div class="campo-tabela campo-menor">
								<input type="checkbox" v-model="promocaoFormas[forma.cdtipo]" style="width: 80%;">
							</div>
						</div>
					</div>
				</div>
				<div 
					class="container-tabela tabela-container"  
					v-if="FormasPagamentoPrazo.length > 0" 
					id="table"
				>
					<span>Formas de Pagamento a Prazo:</span>
					<div class="header-tabela">
						<div class="linha-header-tabela">
							<div class="campo-tabela campo-medio">
								<span>Descrição</span>
							</div>
							<div class="campo-tabela campo-menor">
								<span>Selecionado</span>
							</div>
						</div>
					</div>
					<div class="body-tabela">
						<div 
							class="linha-body-tabela" 
							v-for="(forma, index) in FormasPagamentoPrazo" 
							:key="index" :id="`linha-${index}`"
						>
							<div class="campo-tabela campo-medio">
								{{ forma.descricao }}
							</div>
							<div class="campo-tabela campo-menor">
								<input type="checkbox" v-model="promocaoFormas[forma.cdtipo]" style="width: 80%;">
							</div>
						</div>
					</div>
				</div>
				<div class="botoes" style="justify-content: space-between;margin-bottom: 0px;">
					<button class="botao botao-cancelar" id="botaoCancelar" @click="cancelaFormas">
						<label class="lg-view">Cancelar [Alt + C]</label>
						<label for="botaoCancelar" class="sm-view">Cancelar</label>
					</button>
					<button class="botao botao-adicionar" id="botaoAdicionar" @click="listarFormas = false">
						<label for="botaoAdicionar" class="lg-view">Salvar [Alt + S]</label>
						<label for="botaoAdicionar" class="sm-view">Salvar</label>
					</button>
				</div>
			</div>
		</Modal>
	</div>
</template>
<script>
// import Paginacao from "./Paginacao.vue";
// import ListagemProdutos from "./ListagemProdutos.vue";
import Modal from "./Modal.vue";
import { dateFormatISO } from "../utils/dateformat";
import ConsultaProdutos from "./ConsultaProdutos.vue";

export default {
	name: "CadastroPromocao",

	props: {
		promocao: Object,
	},

	computed: {
		listadeproduto() {
			return this.$store.state.Produtos;
		},
		FormasPagamento() {
			return this.$store.state.formasPagamento;
		},
		FormasPagamentoVista() {
			return this.FormasPagamento.filter(pgto => pgto.mostravenda === "S" && pgto.vistaprazo === 1 || (pgto.vistaprazo === 3 && pgto.quantidadediasdeprazo === 0 && pgto.mostravenda === "S"));
		},

		FormasPagamentoPrazo() {
			return this.FormasPagamento.filter(pgto => pgto.mostravenda === "S" && pgto.vistaprazo === 3 && pgto.quantidadediasdeprazo !== 0);
		},
	},

	components: {
		// Paginacao,
		// ListagemProdutos,
		Modal,
		ConsultaProdutos,
	},

	data() {
		return {
			buscaProduto: true,
			dataSemana: "data",
			promocaoCadastrada: {
				cdempresa: "",
				datafinal: "",
				datainicio: "",
				descontoprazo: "0",
				descontovista: "0",
				nome: "",
				produtos: [],
				todosProdutos: "N",
				domingo: false,
				segunda: false,
				terca: false,
				quarta: false,
				quinta: false,
				sexta: false,
				sabado: false,
			},
			listarProdutos: false,
			cdproduto: "",
			produtoSelecionado: null,
			timerBuscaProduto: null,
			timerInsereProduto: null,
			listarFormas: false,
			promocaoFormas: {},

		}; 
	},

	methods: {
		async iniciaFormas(){
			await this.$store.dispatch("buscarFormasPagamento");
			this.FormasPagamento.forEach((forma)=>{
				this.promocaoFormas[forma.cdtipo] = false;
			});
			if(this.promocao?.pagamentos?.length > 0){
				this.promocao.pagamentos.forEach((forma)=>{
					this.promocaoFormas[forma] = true;
				});
			}
		},
		async cancelaFormas(){
			this.listarFormas = false;
			await this.iniciaFormas();
		},
		formasAtivas(){
			let formasAtivas = [];
			this.FormasPagamento.forEach((forma)=>{
				if(this.promocaoFormas[forma.cdtipo]) formasAtivas.push(forma.cdtipo);
			});
			return formasAtivas;
		},
		saiuData(){
			if(this.promocaoCadastrada.datainicio == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.promocaoCadastrada.datainicio = this.promocao.novo? dateFormatISO({date: null, format: "ISOdate"}) : this.promocao.datainicio;
			}
			if(this.promocaoCadastrada.datafinal == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.promocaoCadastrada.datafinal = this.promocao.novo? dateFormatISO({
					date: new Date(
						new Date().getFullYear(),
						new Date().getMonth() + 1,
						0,
					).toLocaleDateString("en-CA"),
					format: "ISOdate"
				}) : this.promocao.datafinal;
			}
		},
		limparPeriodoPromocao (tipo) {
			if (tipo == "data") {
				this.promocaoCadastrada.domingo = true;
				this.promocaoCadastrada.segunda = true;
				this.promocaoCadastrada.terca = true;
				this.promocaoCadastrada.quarta = true;
				this.promocaoCadastrada.quinta = true;
				this.promocaoCadastrada.sexta = true;
				this.promocaoCadastrada.sabado = true;
			} else {
				this.promocaoCadastrada.datafinal = new Date(
					new Date().getFullYear()+1000,
					new Date().getMonth(),
					new Date().getDate(),
				).toLocaleDateString("en-CA");
			}
		},
		

		async selecionarPorGrupo (grupo, subg) {
			let data = await this.$store.dispatch("buscarProdutoPorGrupo", {grupo, subg});
			for (let i = 0; i < data.length; i++){
				if(!data[i].id || data[i].id == null){
					return this.$store.commit("relatarErro", {mensagem: "Produto não encontrado"});
				}
				this.selecionarProduto(data[i]);
			}
		},
		async selecionarTodos (filtro, acaoCancelar){
			await this.$store.dispatch("filtrarProdutos", {...filtro, size:  this.$store.state.paginacaoProduto.totalProdutos});
			const produtos = this.$store.state.Produtos;
			this.$store.commit("relatarErro", {
				mensagem: `Tem certeza que deseja adicionar ${this.listadeproduto.length}, na promoção?`,
				tipo: "polar",
				item: {
					selecionarProdutos: true,
					produtos: [...produtos],
					acaoCancelar

				}
			});
		},
		selecionarTexto(evento) {
			if (evento.target.select) evento.target.select();
		},

		async validarEntradaProduto(produto) {
			const data = await this.$store.dispatch("validaPromocao", {
				datainicio: dateFormatISO({
					date: this.promocaoCadastrada.datainicio,
					format: "ISOdate"
				}),
				datafinal: dateFormatISO({
					date: this.promocaoCadastrada.datafinal,
					format: "ISOdate"
				}),
				id: produto.id,
			});
			if (data.erro) {
				throw new Error(data.mensagem);
			}
			if (this.promocaoCadastrada.produtos.find(e => e.cdproduto == produto.id)) {
				throw new Error("Produto já está na lista para promoção! Deseja remover esse produto?");
			}
		},

		incluirProduto(produto) {
			this.promocaoCadastrada.produtos.push({
				cdproduto: produto.id,
				nomeProduto: produto.descricao,
				descprazo: produto.valorPrazo,
				descvista: produto.valorPrazo,
				vlrOriginalPrazo: produto.valorPrazo,
				vlrOriginalVista: produto.valorPrazo,
				codBarra: produto.codBarra,
			});
		},

		async selecionarProduto(produto, todos) {
			this.listarProdutos = false;
			if (this.promocaoCadastrada.todosProdutos == "S") return;
			try {
				if (!produto) {
					throw new Error("Produto não encontrado, verifique!");
				}
				await this.validarEntradaProduto(produto);
				this.incluirProduto(produto);
				this.descontoTabela();
				this.cdproduto = "";
				this.produtoSelecionado = null;
				this.proximoCampo("cdproduto");
				if(!todos) {
					this.$store.state.carregando = false;
				}
			} catch (error) {
				if(error.message == "Produto já está na lista para promoção! Deseja remover esse produto?"){
					this.$store.commit("relatarErro", { 
						mensagem: error.message, 
						tipo: "polar",
						item: {
							acao: this.removerProduto,
							param: this.promocaoCadastrada.produtos.find(e => e.cdproduto == produto.id),
						}
					});
				}else{
					this.$store.commit("relatarErro", { mensagem: error.message });
				}
				this.cdproduto = "";
				this.produtoSelecionado = null;
				this.$store.state.carregando = false;
			}
		},

		abrirModal() {
			if (this.promocaoCadastrada.todosProdutos == "S") {
				return this.$store.commit("relatarErro", { mensagem: "Não é possivel adicionar um novo produto, pois a opção todos os produtos está marcada como \"sim\"" });
			} else {
				this.listarProdutos = true;
			}
		},

		abrirModalFormas(){
			this.listarFormas = true;
		},

		mudarAtivo() {
			if (this.promocaoCadastrada.todosProdutos == "S") {
				this.promocaoCadastrada.todosProdutos = "N";
			} else {
				this.promocaoCadastrada.todosProdutos = "S";
				this.promocaoCadastrada.produtos = [];
			}
		},

		descontoTabela() {
			let descvista = Number(`${this.promocaoCadastrada.descontovista || 0}`.split(".").join("").replace(",", ".") || 0);
			let descprazo = Number(`${this.promocaoCadastrada.descontoprazo || 0}`.split(".").join("").replace(",", ".") || 0);
			if((this.promocaoCadastrada.descontovista || this.promocaoCadastrada.descontoprazo)[0] == ",") {
				this.promocaoCadastrada.descontovista = "";
				this.promocaoCadastrada.descontoprazo = "";
				this.$store.commit("relatarErro", {
					mensagem: "Desconto Inválido, verifique",
				});
			}
			if (descvista > 100) {
				descvista = 100;
				this.$store.commit("relatarErro", {
					mensagem: "Desconto não pode ser maior que 100%. Desconto alterado para 100%!",
					alerta: true
				});
				this.promocaoCadastrada.descontovista = descvista.toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
			}
			if (descprazo > 100) {
				descprazo = 100;
				this.$store.commit("relatarErro", {
					mensagem: "Desconto não pode ser maior que 100%. Desconto alterado para 100%!",
					alerta: true
				});
				this.promocaoCadastrada.descontoprazo = descprazo.toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
			}
			this.promocaoCadastrada.produtos.forEach(element => {
				element.descvista = Number((Number(element.vlrOriginalPrazo) - ((Number(element.vlrOriginalPrazo) / 100) * descvista)).toFixed(2));
				element.descprazo = Number((Number(element.vlrOriginalPrazo) - ((Number(element.vlrOriginalPrazo) / 100) * descprazo)).toFixed(2));
			});
		},

		buscarProduto(e) {
			clearTimeout(this.timerBuscaProduto);
			this.timerBuscaProduto = setTimeout(async () => {
				try {
					if (!this.cdproduto) return;
					this.produtoSelecionado = await this.$store.dispatch("buscarProdutoPorCodigoBarras", `${this.cdproduto}`.substring(0, 12));
					if (!this.produtoSelecionado) {
						this.produtoSelecionado = await this.$store.dispatch("buscarProdutoPorId", this.cdproduto);
					}
					if (e.key == "Enter" || this.cdproduto.length == 12) {
						this.selecionarProduto(this.produtoSelecionado);
					}
				} catch (error) {
					console.log(error);
				}
			}, 250);
		},
		removerProdutoBotao() {
			let index = this.promocaoCadastrada.produtos.findIndex((e)=>e.cdproduto==this.cdproduto);
			if(index==-1){
				index = this.promocaoCadastrada.produtos.findIndex((e)=>e.codBarra==this.cdproduto);
				if(index==-1) return this.$store.commit("relatarErro", {mensagem: "Produto não encontrado!"});
			}		
			this.$store.commit("relatarErro", { 
				mensagem: "Tem certeza que deseja remover esse produto da promoção?", 
				tipo: "polar",
				item: {
					acao: this.removerProduto,
					param: index,
				}
			});				
			this.cdproduto = "";
			this.produtoSelecionado = null;
		},
		removerProduto(index) {
			this.promocaoCadastrada.produtos.splice(index, 1);
		},

		solicitaRemoverItem(index) {
			this.$store.commit("relatarErro", {
				mensagem: `Tem certeza que deseja remover o produto ${this.promocaoCadastrada.produtos[index].nomeProduto}?`,
				tipo: "polar",
				item: {
					acao: this.removerProduto,
					param: index
				}
			});
		},

		alterarTodosProdutos() {
			this.$store.commit("relatarErro", {
				mensagem: "Tem certeza que deseja colocar todos os produtos da loja em promoção?",
				tipo: "polar",
				item: { todosProdutos: true }
			});
		},

		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},

		mascaraFloat(e) {
			const regex = /[0-9,]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
			if ((`${e.target.value}`.includes(",") || !`${e.target.value}`.length) && e.key == ",") {
				e.preventDefault();
			}
		},

		marcaraCdproduto(e) {
			if (e.key == "F4") {
				e.preventDefault();
				return this.listarProdutos = true;
			}
			const regex = /[0-9.]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (!regex.test(e.data) && e.data != null && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
			this.buscarProduto(e);
		},

		proximoCampo(id) {
			const campo = document.getElementById(id);
			if (!campo) {
				console.error("Falta atributo id no próximo campo!");
			}
			campo.focus();
			if (campo.select) {
				campo.select();
			}
		},
		async adicionarTodos (produtos) {
			this.$store.state.carregando = true;
			for (let i = 0; i < produtos.length; i++){
				await this.selecionarProduto(produtos[i], true);
			}
			this.$store.state.carregando = false;

		},

		polarConfirmado(item) {
			if(item.selecionarProdutos){
				this.adicionarTodos(item.produtos);
			}
			if (item.todosProdutos) {
				this.promocaoCadastrada.produtos = [];
				this.promocaoCadastrada.todosProdutos = "S";
			} else if (item.acao) {
				if (typeof item.acao == "function") {
					item.acao(item.param);
				} else if (typeof item.acao == "string" && this[item.acao]) {
					this[item.acao](item.param);
				}
			}
		},

		aplicarDescontoPromocao(e) {
			this.mascaraFloat(e);
		},

		polarCancelado(item) {
			if (item.todosProdutos) {
				this.promocaoCadastrada.todosProdutos = "N";
			}
			if(item.selecionarProdutos){
				if(item.acaoCancelar){
					item.acaoCancelar();
				}
			}

		},

		async validarPreSave() {
			try {
				const descprazo = Number(`${this.promocaoCadastrada.descontoprazo}`.split(".").join("").replace(",", ".")) || 0;
				const descvista = Number(`${this.promocaoCadastrada.descontovista}`.split(".").join("").replace(",", ".")) || 0;
				if (descprazo > 100 || descvista > 100) {
					throw new Error(`Desconto no valor á ${descvista > 100 ? "vista" : "prazo"} não pode ser maior do que 100%`);
				}
	
				if (descprazo < 0 || descvista < 0) {
					throw new Error(`Desconto no valor á ${descvista < 0 ? "vista" : "prazo"} não pode ser menor que 0 (zero)!`);
				}
	
				if (!this.promocaoCadastrada.nome) {
					throw new Error("Campo nome não pode estar vazio");
				}
	
				if (this.dataSemana === "data") {
					if(this.promocaoCadastrada.datafinal === "") {
						throw new Error("Insira uma data final com uma data válida!");	
					}

					if(this.promocaoCadastrada.datainicio === "") {
						throw new Error("Insira uma data inicio com uma data válida!");	
					}

					let dataI = new Date(this.promocaoCadastrada.datainicio.split("-")).getTime();
					let dataF = new Date(this.promocaoCadastrada.datafinal.split("-")).getTime();
					let dataA = new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						new Date().getDate(),
					).getTime();
	
					
					if (dataF < dataI) {
						throw new Error("A data final da promoção não pode ser antes da data inicial, verifique!");
					}
		
					if ((dataI < dataA) && this.promocao.novo) {
						throw new Error("A data inicial da promoção não pode ser antes da data de hoje, verifique!");
					}
					
				} else if(this.dataSemana === "semana") {
					if (!this.promocaoCadastrada.domingo && !this.promocaoCadastrada.segunda && !this.promocaoCadastrada.terca 
					&& !this.promocaoCadastrada.quarta && !this.promocaoCadastrada.quinta && !this.promocaoCadastrada.sexta 
					&& !this.promocaoCadastrada.sabado) {
						throw new Error("Nenhum dia da semana selecionado, verifique!");
					}
				} else if(this.dataSemana === "pagamento"){
					const formas = this.formasAtivas();
					if(!formas || formas.length < 1){
						throw new Error("Nenhuma forma de pagamento selecionada, verifique!");
					}
				}
	
				if (this.promocaoCadastrada.todosProdutos == "N" && !this.promocaoCadastrada.produtos.length) {
					throw new Error("Não é possível cadastrar uma promoção sem nenhum produto!");
				}
				return true;
			} catch (error) {
				this.$store.commit("relatarErro", { mensagem: error.message });				
				return false;
			}
		},

		async adicionarPromocao() {
			let cadastrou = null;
			this.limparPeriodoPromocao(this.dataSemana);
			if(!await this.validarPreSave()) return;

			let payload = {...this.promocaoCadastrada};
			payload.produtos = payload.produtos.map(e => e.cdproduto);
			payload.pagamentos = this.formasAtivas();
			if (this.promocao && this.promocao.novo) {
				cadastrou = await this.$store.dispatch("adicionarPromocao", { ...payload });
			} else {
				await this.$store.dispatch("editarPromocao", {
					...payload,
					id: this.promocao.cdpromocao,
				});
			}
			if(cadastrou == null || cadastrou){
				this.cancelarEdicao();
			}
		},

		async iniciarDados() {
			if (this.promocao) {
				if (this.promocao.novo) return;
			}
			this.$store.state.carregando = true;
			setTimeout(() => {
				this.promocaoCadastrada = {
					datafinal: this.promocao.datafinal,
					datainicio: this.promocao.datainicio,
					descontoprazo: this.promocao.descontoprazo.toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }),
					descontovista: this.promocao.descontovista.toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }),
					nome: this.promocao.nome,
					produtos: this.promocao.produtos ? this.promocao.produtos.map(e => {
						return {
							cdproduto: e.cdproduto,
							nomeProduto: e.descricao,
							descprazo: e.pontoequi,
							descvista: e.pontoequi,
							vlrOriginalPrazo: e.pontoequi,
							vlrOriginalVista: e.pontoequi,
						};
					}) : [],
					todosProdutos: this.promocao.todosprodutos,
				};
				this.descontoTabela();
				this.$store.state.carregando = false;
			}, 100);

			setTimeout(() => {
				this.dataSemana = parseInt(this.promocao.datafinal) - parseInt(this.promocao.datainicio) !== 1000 ? "data" : this.promocao.pagamentos.length < 1 ? "semana" : "pagamento";
				this.promocaoCadastrada.domingo = this.promocao.domingo;
				this.promocaoCadastrada.segunda = this.promocao.segunda;
				this.promocaoCadastrada.terca = this.promocao.terca;
				this.promocaoCadastrada.quarta = this.promocao.quarta;
				this.promocaoCadastrada.quinta = this.promocao.quinta;
				this.promocaoCadastrada.sexta = this.promocao.sexta;
				this.promocaoCadastrada.sabado = this.promocao.sabado;
			}, 100);

			setTimeout(() => {
				this.proximoCampo("nome");
			}, 50);
		},

		capturarEventoTeclado(event) {
			if (`${event.key}`.toUpperCase() == "C" && event.altKey) {
				event.preventDefault();
				return this.cancelarEdicao();
			}
			if (`${event.key}`.toUpperCase() == "S" && event.altKey) {
				event.preventDefault();
				return this.adicionarPromocao();
			}
		}
	},

	beforeMount() {
		this.promocaoCadastrada.datainicio = dateFormatISO({
			date: new Date(
				new Date().getFullYear(),
				new Date().getMonth(),
				new Date().getDate(),
			).toLocaleDateString("en-CA"),
			format: "ISOdate"
		});
		this.promocaoCadastrada.datafinal = dateFormatISO({
			date: new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0,
			).toLocaleDateString("en-CA"),
			format: "ISOdate"
		});
	},

	mounted() {
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		this.$bus.$on("cancelarPolar", this.polarCancelado);
		window.addEventListener("keydown", this.capturarEventoTeclado);
		this.iniciarDados();
		this.iniciaFormas();
	},

	destroyed() {
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
		this.$bus.$off("cancelarPolar", this.polarCancelado);
		window.removeEventListener("keydown", this.capturarEventoTeclado);
	}
};
</script>
<style scoped>
.container-modal {
	display: flex;
	flex-direction: column;
	width: auto;
	padding: 10px;
}
.title-modal { 
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
}
.container-tabela{
	width: auto;
	overflow: auto;
	height: auto;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.header-tabela .linha-header-tabela,
.body-tabela .linha-body-tabela{
	display: flex;
	white-space: nowrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 2px 5px;
}
.header-tabela .linha-header-tabela{
	background-color: #e6e6e6;
	color: #494444;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.body-tabela .linha-body-tabela{
	border-bottom: 1px solid #cecece;
}
.linha-header-tabela .campo-tabela i{
	margin-left: 10px;
	color: #7a7a7a;
}
.campo-tabela{
	font-size: 14px;
}
.campo-menor{
	width: 100px;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
}
.campo-medio{
	width: 250px;
	overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;
	margin-left: 15px;
}
#selectPeriodoPromocao {
	width: 100%;
}
.data {
	display: flex;
	flex-direction: row;
}
.dias-semana {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.semana {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.dia {
	display: flex;
	flex-direction: column;
}

.lg-view {
	display: inline-block;
}

.sm-view {
	display: none;
}

#CadastroPromocao {
	display: flex;
	flex-direction: column;
	width: 98%;
	margin: 0px auto;
}

.cabecalho-promocao,
.cabecalho-promocao-2 {
	display: flex;
	flex-direction: row;
}

.datas-filtro {
	width: 100%;
}

.datas-filtro input::-webkit-datetime-edit {
	padding: 0px;
}

.datas-filtro input::-webkit-datetime-edit-text {
	padding: 0;
}

.datas-filtro input::-webkit-calendar-picker-indicator {
	padding: 0;
	margin: 0;
}

#cdproduto {
	flex-grow: 1;
}

.no-white-space {
	white-space: nowrap;
}

.produtos-promocao {
	overflow-y: auto;
	width: 100%;
	height: 45vh;
	margin-top: 5px;
}

.pequeno {
	display: none;
}

.containerButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
	margin-bottom: 20px;
}

.botao-cancelar {
	width:50%;
}

.botao-adicionar {
	width:50%;
}
.input-group .botoes {
	width: 100%;

} 
.container-page{
	height: 92vh;
	width: 100%;
	overflow-y: hidden;
}
.container-form{
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
    width: 100%;
}


#produto {
	width: 750px;
	align-items: flex-end;
}
#nomePromocao {
	width: 450px;
}
.campos-cadastro{
	width: 100%;
	height: 92vh;
	display: flex;	
	flex-direction: column;
}
.input-group{
	width: 550px;
	margin-top: 15px;
}

.input-group-switch{
	margin-top: 20px;
	margin-left: 15px;
}

.data .input-group{
	width: 150px;
}

.botao-acao {
	width: 205px;
}

.datas-filtro {
	column-gap:0px;
}
.container-form .todos-produtos{
	margin-left: 10px;
	margin-right: 10px;
}
.data .column {
	text-align:center;
}
.column .input-group {
	width: 100%;
}

.col .input-group {
	margin-top: 0px;
	width: 210px;
}

.descontos{
	align-items: center;
}

.descontos .input-group{
	width: 210px;
	margin-left: 10px;
}

#cod-codbarra {
	margin-top: 10px;
}

.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}

.botoes .botao {
	width: 45%;
}

@media screen and (max-width: 1024px) {
	.containerButtons {
		margin-top: 10px;
	}

	.container-page {
		overflow: auto;
	}
}

@media screen and (max-width: 768px) {
	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	.container-page::-webkit-scrollbar {
		display: none;
	}
}

@media screen and (max-width: 738px) {
	.cabecalho-promocao-2 {
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.cabecalho-promocao-2 .col {
		width: 40%;
	}

	.cabecalho-promocao-2 .todosprodutos {
		align-items: flex-start;
	}
}

@media screen and (max-width: 609px) {
	.cabecalho-promocao {
		flex-wrap: wrap;
	}

	.datas-filtro {
		margin: 2px 0px;
	}
}

@media screen and (max-width: 580px) {
	.cabecalho-promocao-2 .col {
		width: 45%;
	}

	.grande {
		display: none;
	}

	.pequeno {
		display: block;
	}
}

@media screen and (max-width: 510px) {
	#botoes span {
		display: none;
	}

	#nomePromocao{
		width: 100%;
	}

	
	.data{
		width: 100%;
	}

	#produto{
		width: 100%;
	}

	h1 {
		font-size: 18pt;
	}
}

@media screen and (max-width: 440px) {
	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	.containerButtons {
		gap: 20px;
	}

	#datainicio {
		width: 100%;
	}

	#datafinal {
		width: 100%;
	}

	.datas-filtro .input-group {
		width: 46%;
	}

	#descontovista {
		width: 100%;
	}

	#descontoprazo {
		width: 100%;
	}	
	
	.descontos {
		width: 100%;
	}

	#cod-codbarra {
		width: 54%;
	}

	#produto .botao-acao {
		width: 39%;
	}

	.container-page {
		overflow-x: auto;
		overflow-y: auto;
	}
	.data{
		flex-direction: column;
	}
	#selectPeriodoPromocao{
		width: 95%;
	}
	.botoes i{
		display:none;
	}

}


.botoes-consulta{
	width: 100%;
	display: flex;
	gap: 10px;
}
@media screen and (max-width: 375px) {

	#datainicio {
		font-size: 15px;
	}

	#datafinal {
		font-size: 15px;
	}

	.datas-filtro .input-group {
		width: 44%;
	}

	.descontos {
		font-size: smaller;	
	}

	#cod-codbarra {
		width: 53%;
	}

	#produto .botao-acao {
		width: 45%;
	}
}
</style>
