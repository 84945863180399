<template>
    <div class="d-flex row w-100" style="overflow: auto;" v-if="tipoMovimento ? Relatorios.movimentoCaixa.filter(e => e[tipoMovimento] > 0).length > 0 : Relatorios.movimentoCaixa.length > 0">
        <table class="w-100">
            <thead>
                <tr>
                    <td class="tabTitulo">Código:</td>
                    <td class="tabTitulo text-left" v-if="Relatorios.movimentoCaixa.filter(e => e.condicao == 'M').length">Descrição:</td>
                    <td class="tabTitulo text-left" v-else>Cliente:</td>
                    <td class="tabTitulo text-right" v-if="Relatorios.movimentoCaixa.filter(e => e.condicao != 'M').length">Valor:</td>
                    <td class="tabTitulo text-right" v-if="Relatorios.movimentoCaixa.filter(e => (e.condicao == 'M')).length && tipoMovimento == 'credito'">Crédito:</td>
                    <td class="tabTitulo text-right" v-if="Relatorios.movimentoCaixa.filter(e => (e.condicao == 'M')).length && tipoMovimento == 'debito'">Débito:</td>
                    <td class="tabTitulo">Metodo de Pagamento:</td>
                    <td class="tabTitulo" v-if="Relatorios.movimentoCaixa.filter(e => e.condicao == 'V').length">Vendedor:</td>
                    <td class="tabTitulo">Emissão:</td>
                    <td class="tabTitulo">Observação:</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(relatorio, index) in tipoMovimento ? Relatorios.movimentoCaixa.filter(e => e[tipoMovimento] > 0) : Relatorios.movimentoCaixa" :key="index" @click="selecionarVenda(relatorio)">
                    <td class="tabCorpo">{{!relatorio.cdmovi ? relatorio.cddig : relatorio.cdmovi}}</td>
                    <td class="tabCorpo text-left">{{relatorio.nomecliente}}</td>
                    <td class="tabCorpo text-right" v-if="Relatorios.movimentoCaixa.filter(e => e.condicao != 'M').length">{{((relatorio.valor || relatorio.credito) > 0 ? (relatorio.valor || relatorio.credito) : relatorio.contarc).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        })}}</td>
                    <td v-if="Relatorios.movimentoCaixa.filter(e => (e.condicao == 'M')).length  && tipoMovimento == 'credito'" class="tabCorpo text-right">{{(relatorio.credito || 0).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        })}}</td>
                    <td v-if="Relatorios.movimentoCaixa.filter(e => (e.condicao == 'M')).length  && tipoMovimento == 'debito'" class="tabCorpo text-right">{{(relatorio.debito || 0).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        })}}</td>
                    <td class="tabCorpo">{{relatorio.metodopagamento}}</td>
                    <td class="tabCorpo" v-if="Relatorios.movimentoCaixa.filter(e => e.condicao == 'V').length">{{relatorio.nomevendedor}}</td>
                    <td class="tabCorpo">{{new Date(relatorio.datadoc.split("-")).toLocaleDateString("pt-BR")}}</td>
                    <td class="tabCorpo">{{relatorio.observa}}</td>
                </tr>
            </tbody>
        </table>

         <Modal v-if="vendaSelecionada" v-on:fecharModal="vendaSelecionada = null" :dimensoes="{width: '80%', height: 'auto'}">
            <PreImpressao :vendaFinalizada="vendaSelecionada" v-on:fecharModal="vendaSelecionada = null" 
            v-on:alterarVenda="alterarVenda" :permiteCancelar="false" />
        </Modal>
    </div>
    <div v-else class="col center h-100">
        <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
        <h1>Nenhum registro encontrado.</h1>
        <h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
    </div>
</template>
<script>
import {mapState} from "vuex";
import PreImpressao from "./PreImpressao.vue";
import Modal from "./Modal.vue";

export default {
	name: "RelatorioContasModal",
    
	components: {
		Modal,
		PreImpressao
	},

	props: {
		tipoMovimento: String,
	},

	data() {
		return {
			vendaSelecionada: null,
		};
	},

	computed: {
		...mapState(["Relatorios"]),
	},

	methods: {
		async selecionarVenda(relatorio) {
			if (relatorio.cdmovi) {
				this.vendaSelecionada = await this.$store.dispatch("buscarVendaPorId", {cdVenda: relatorio.cdmovi, cdEmpresa: relatorio.cdEmpresa});
			}
			else if (relatorio.condicao != "M") {
				await this.$store.dispatch("buscarBaixa", {
					cdAgencia: relatorio.cdAgencia,
					cdBanco: relatorio.cdBanco,
					cdConta: relatorio.cdConta,
					cdDig: relatorio.cddig,
					tipo: relatorio.tipo,
					impressao: true,
				});
			}
		},
        

		alterarVenda(venda) {
			this.$store.state.Venda = null;
			this.$store.state.Venda = {
				cliente: venda.cliente,
				vendedor: venda.vendedor,
				cdVenda: venda.cdVenda,
				dataEmissao: venda.dataEmissao,
				dataEntradaSaida: venda.dataEntradaSaida,
				cdUsuarioLiberacao: venda.cdUsuarioLiberacao,
				cdUsuarioCaixa: venda.cdUsuarioCaixa,
				financeiro: venda.financeiro
			};
			this.$store.state.itensVenda = [];
			venda.itens.map(item => {
				item.vendaOrigem = venda.cdVenda;
				item.dataOrigem = venda.dataEmissao;
			});
			this.$store.state.itensVenda = [...venda.itens];
			this.$router.push({name: "Lancamento", query: {
				tipoVenda: venda.valorCondicional > 0 ? "C" : "S",
				alteracao: true,
				baixarCondicional: venda.valorCondicional > 0
			}});
		},
	},
};
</script>
<style scoped>
table {
    margin-top: 20px;
}
</style>