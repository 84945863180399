<template>
    <div class="tela">
        <div class="dummy-text">
            <div class="logo">
                <img id="principalImg" src='../assets/logo.png'>
            </div>
            <form id="login" @submit.prevent="(evt) => {evt.preventDefault();}" autocomplete="off">
                <div class="loginDiv" >
                    <label for="usuario">Usuário:</label><br/>
                    <input type="text" id="usuario" v-model="usuario" tabindex="1" autofocus placeholder="Digite seu usuário" @input="mascaraUsuario">
                </div>

                <div class="loginDiv" >
                    <label for="senha">Senha:</label><br/>
                    <input type="password" id="senha" placeholder="Digite sua senha" v-model="senha" tabindex="2">
                </div>
                <div class="loginDiv" >
                    <button class="btnAcao w-80 m-5" id="btnEntrar" @click="autenticar" type="submit" tabindex="3">
                        Entrar
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
	name: "SolicitaAutorizacao",

	data() {
		return { 
			usuario: "",
			senha: "",
		};
	},

	methods: {
		autenticar() {
			if (!this.usuario || this.usuario == -1){
				return this.$store.commit("relatarErro", "Por favor informar seu usuário!");
			}
			if (!this.senha){
				return this.$store.commit("relatarErro", "Por favor informar sua senha!");
			}
			this.$store.dispatch("autenticarUsuario", {usuario: this.usuario, senha: this.senha, cdempresa: this.empresa});
			this.senha = "";
		},

		mascaraUsuario(e) {
			if (e.data != null && e.data != undefined && e.data) {
				this.usuario = e.target.value.toUpperCase();
			}
		}
	},

};
</script>

<style scoped >

.loginDiv {
    color: var(--branco);
}

.loginDiv select, input {
    width: 80%;
    margin: 5px;
    border-radius: 5px; 
    padding: 5px;
}

.logo #principalImg {
    border-radius: 10px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: var(--shadow-primario);
    width: 100%;
    max-width: 250px;
    max-height: 150px;
}

#btnEntrar{
    background: var(--confirmar);
    height: 30px;
    border-radius: 5px;
}
</style>