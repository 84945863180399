import { doRequest } from "./http";

class Cargo {
	constructor(cargo) {
		this.id = cargo.cdcargo;
		this.descricao = cargo.descricao;
	}
}

export default {
	state: {
		ListaCargos: {
			content: [],
			size: 25,
			paginaAtual: 0,
			totalPaginas: 0
		}
	},
	actions: {
		async filtrarCargos({state, commit}, payload) {
			try {
				state.carregando = true;
				let url = "cargo/filter?sortOrder=asc&sort=descricao";
				const {page, size, query, filtroDinamico} = payload;
				url += `&page=${page || 0}`;
				url += `&size=${size || state.ListaUsuarios.size}`;
				if (query) {
					url += `&query=${query}`;
				}
				if (Object.keys(filtroDinamico).length > 0) {
					Object.keys(filtroDinamico).forEach(key => {
						url += `&${key}=${filtroDinamico[key]}`;
					});
				}
				const data = await doRequest(url, { method: "get", cache: "no-cache" }, commit);
				state.carregando = false;
				if (!data) {
					commit("atualizarListaCargos", null);
					return null;
				}
				if (!data.content) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				commit("atualizarListaCargos", data);
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("atualizarListaCargos", null);
				return null;
			}
		}
	},
	mutations: {
		atualizarListaCargos(state, payload) {
			state.ListaCargos = {
				content: [],
				size: 25,
				paginaAtual: 0,
				totalPaginas: 0
			};
			if (!payload) return;
			state.ListaCargos = {
				content: payload.content.map(cargo => new Cargo(cargo)),
				size: payload.size,
				paginaAtual: payload.page,
				totalPaginas: payload.totalPages
			};
		},

		limparListaCargos(state) {
			state.ListaCargos = {
				content: [],
				size: 25,
				paginaAtual: 0,
				totalPaginas: 0
			};
		}
	}
};