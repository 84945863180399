<template>
	<div class="cadastroCliente">
		<div id="dados">
			<fieldset style="width: 98%;">
				<legend>
					Endereço Comercial
				</legend>
				<div class="row margin-top">
					<div class="input-group">
						<input id="trabalho" v-model="clienteCadastrado.trabalho" type="text" autocomplete="off" @keydown.enter.prevent="proximoCampo('funcao')">
						<label for="trabalho">Local Trabalho</label>
					</div>
					<div class="input-group">
						<input id="funcao" v-model="clienteCadastrado.funcao" type="text" autocomplete="off" @keydown.enter.prevent="proximoCampo('fone')">
						<label for="funcao">Função</label>
					</div>
					<div class="input-group max150" >
						<input id="fonetrab" v-model="clienteCadastrado.fonetrab" type="text" @input="mascara" autocomplete="off" maxlength="14" @keydown.enter.prevent="proximoCampo('tempoTrabalho')" v-mascaraInteger>
						<label for="fonetrab">Fone</label>
					</div>
					<div class="input-group max180">
						<input id="tempoTrabalho" v-model="clienteCadastrado.tempo" type="month" autocomplete="off" @keydown.enter.prevent="proximoCampo('salario')">
						<label for="tempoTrabalho">Trabalha desde</label>
					</div>
				</div>
				<div class="row margin-top">
					<div class="input-group">
						<input id="endereco" v-model="clienteCadastrado.endtrab" type="text" autocomplete="off" @keydown.enter.prevent="proximoCampo('bairro')" maxlength="40">
						<label for="endereco">Endereço</label>
					</div>
				</div>
			</fieldset>
			<fieldset style="width: 98%;">
				<legend>
					Filiação
				</legend>
				<div class="row margin-top">
					<div class="input-group">
						<input id="pai" v-model="clienteCadastrado.pai" type="text"
							class="w-100" autocomplete="off" @keydown.enter.prevent="proximoCampo('mae')">
						<label for="pai">Nome do Pai</label>
					</div>
					<div class="input-group">
						<input id="mae" v-model="clienteCadastrado.mae" type="text" autocomplete="off" @keydown.enter.prevent="proximoCampo('referencia')">
						<label for="mae">Nome da Mãe</label>
					</div>
					<div class="input-group">
						<input id="referencia" v-model="clienteCadastrado.parente" type="text" autocomplete="off" @keydown.enter.prevent="proximoCampo('estadoCivil')">
						<label for="referencia">Referências Familiar ou amigo</label>
					</div>
				</div>
			</fieldset>
			<fieldset style="width: 98%;">
				<legend>
					Conjuge
				</legend>
				<div class="row margin-top">
					<div class="input-group max200">
						<select id="estadoCivil" v-model="clienteCadastrado.estcivil" type="text" @keydown.enter.prevent="proximoCampo('conjuge')">
							<option value="solteiro">Solteiro</option>
							<option value="casado">Casado</option>
							<option value="separado">Separado</option>
							<option value="divorciado">Divorciado</option>
							<option value="viuvo">Viúvo</option>
						</select>
						<label for="estadoCivil">Estado Civil</label>
					</div>
					<div class="input-group">
						<input id="conjuge" v-model="clienteCadastrado.conjugue" type="text" autocomplete="off" @keydown.enter.prevent="proximoCampo('dataNascimento')">
						<label for="conjuge">Conjuge</label>
					</div>
				</div>
				<div class="row margin-top">			
					<div class="input-group max180">
						<input id="dataNascimento" v-model="clienteCadastrado.nascconj" type="date" autocomplete="off" @keydown.enter.prevent="proximoCampo('rg')">
						<label for="dataNascimento">Data Nasc.</label>
					</div>
					<div class="input-group max200">
						<input id="rg" v-model="clienteCadastrado.rgconj" type="text" autocomplete="off" @keydown.enter.prevent="proximoCampo('cpf')">
						<label for="rg">RG</label>
					</div>
					<div class="input-group max200">
						<input id="cpf" v-model="clienteCadastrado.cpfconj" type="text" @input="mascara" autocomplete="off" @keydown.enter.prevent="proximoCampo('conjugeTrabalho')" v-mascaraInteger maxlength="14">
						<label for="cpf">CPF</label>
					</div>
				</div>
			</fieldset>
			<fieldset style="width: 98%;">
				<legend>
					Referências Comerciais
				</legend>		
				<div class="row margin-top">
					<div class="input-group">
						<input id="referencia1" v-model="clienteCadastrado.ref1" type="text" autocomplete="off" @keydown.enter.prevent="proximoCampo('referencia2')">
						<label for="referencia1">Referência 1</label>
					</div>
					<div class="input-group">
						<input id="referencia2" v-model="clienteCadastrado.ref2" type="text" autocomplete="off" @keydown.enter.prevent="proximoCampo('referencia3')">
						<label for="referencia2">Referência 2</label>
					</div>			
				</div>
				<div class="row margin-top">
					<div class="input-group">
						<input id="referencia3" v-model="clienteCadastrado.ref3" type="text" autocomplete="off" @keydown.enter.prevent="proximoCampo('referencia4')">
						<label for="referencia3">Referência 3</label>
					</div>
					<div class="input-group">
						<input id="referencia4" v-model="clienteCadastrado.ref4" type="text" autocomplete="off">
						<label for="referencia4">Referência 4</label>
					</div>			
				</div>
			</fieldset>
		</div>
		<div class="botoes" id="botoes" style="margin-top: 5px;">
			<button @click="()=>{this.$emit('cancelarEdicao')}" class="botao botao-cancelar">
				<label for="botaoCancelar" class="lg-view">Cancelar [Alt + C]</label>
				<label for="botaoCancelar" class="sm-view">Cancelar</label>
			</button>
			<button @click="()=>{this.$emit('editarCliente', {clienteCadastrado: this.clienteCadastrado,classi: this.clienteCadastrado.classificacao, classiErro: null});}" class="botao botao-adicionar">
				<label for="botaoAdicionar" class="lg-view">Salvar [Alt + S]</label>
				<label for="botaoAdicionar" class="sm-view">Salvar</label>
			</button>
		</div>
	</div>
</template>
<script>
export default {
	name: "ClienteAdicional",

	props: {
		cliente: Object,
	},

	data() {
		return {
			clienteCadastrado: {
				trabalho: null,
				funcao: null,
				fonetrab: null,
				tempo: null,
				endtrab: null,
				pai:null,
				mae:null,
				parente:null,
				estcivil: "solteiro",
				conjugue:null,
				nascconj: null,
				rgconj:null,
				cpfconj:null,				
				ref1: null,
				ref2: null,
				ref3: null,
				ref4: null,
			},
		};
	},
	mounted(){
		this.iniciarComponent();
		document.addEventListener("keydown", this.eventosTeclado);
		this.$bus.$on("salvarCliente", ()=>{this.$emit("editarCliente", {clienteCadastrado: this.clienteCadastrado,classi: this.clienteCadastrado.classificacao, classiErro: null});});
	},

	methods: {	
		mascara(evento) {
			const id = evento.target.id;
			const input = document.getElementById(id);
			const v = input.value;
			if (isNaN(v[v.length - 1])) { 
				this.clienteCadastrado[id != "cpf" ? id : "cpfconj"] = this.clienteCadastrado[id != "cpf" ? id : "cnpj"].substring(0, v.length - 1);
				return;
			}
			if (evento.data === null) {
				if ((id == "fonetrab") && (v.length == 13 && v[8] != "-")) {
					this.clienteCadastrado[id] = `${this.clienteCadastrado[id].slice(0, 8) + "-" + v[10] + v.slice(10)}`;
				}
				return;
			}
			if (id == "cpf") {
				if (v.length == 3 || v.length == 7) {
					this.clienteCadastrado.cpfconj += ".";
				}
				if ((v.length == 4 || v.length == 8) && v.length - 1 != ".") {
					this.clienteCadastrado.cpfconj = v.slice(0, v.length - 1) + "." + evento.data;
				}
				if (v.length == 11) {
					this.clienteCadastrado.cpfconj += "-";
				}
				if (v.length == 12 && v.length - 1 != "-") {
					this.clienteCadastrado.cpfconj = v.slice(0, v.length - 1) + "-" + evento.data;
				}
			}
			else if (id == "fonetrab") {
				if (v.length == 1) {
					this.clienteCadastrado[id] = "(" + evento.data;
				}
				if (v.length == 3) {
					this.clienteCadastrado[id] += ")";
				}
				if (v.length == 4 && v.length - 1 != ")") {
					this.clienteCadastrado[id] = this.clienteCadastrado[id].slice(0, 3) + ")" + evento.data;
				}
				if (v.length == 8) {
					if (v.length - 1 == "-") {
						return;
					}
					this.clienteCadastrado[id] += "-";
				}
				if (v.length == 9 && evento.data != "-") {
					this.clienteCadastrado[id] = this.clienteCadastrado[id].slice(0, 8) + "-" + evento.data;
				}
				if (v.length == 14 && v[8] == "-") {
					this.clienteCadastrado[id] = `${this.clienteCadastrado[id].slice(0, 8) + v[9] + "-" + v.slice(10)}`;
				}
			} 			
		},
		eventosTeclado(e) {
			if (`${e.key}`.toUpperCase() == "S" && e.altKey) {
				e.preventDefault();
				this.$emit("editarCliente",{clienteCadastrado: this.clienteCadastrado,classi: this.clienteCadastrado.classificacao, classiErro: null});
			}
			if (`${e.key}`.toUpperCase() == "C" && e.altKey) {
				e.preventDefault();
				this.$emit("cancelarEdicao");
			}
		},
		proximoCampo(idCampo) {
			const campo = document.getElementById(idCampo);
			if (!campo) {
				console.log("Método próximo campo não foi possível encontrar o campo!");
			} else {
				campo.focus();
				if (campo.select) {
					campo.select();
				}
			}
		},
		async iniciarComponent () {
			await this.$store.dispatch("buscarClassificacao");
			if (this.cliente) {    
				this.clienteCadastrado = {
					...this.cliente,
				};
			}
			else if (this.$route.params.cliente) {
				this.clienteCadastrado = {
					...this.$route.params.cliente,					
				};				
			}
		}
	},

	destroyed() {
		document.removeEventListener("keydown", this.eventosTeclado);
		this.$bus.$off("salvarCliente", ()=>{this.$emit("editarCliente", {clienteCadastrado: this.clienteCadastrado,classi: this.clienteCadastrado.classificacao, classiErro: null});});
	},
};
</script>
<style scoped>
#dados{
	overflow: auto;
	width: 100%;
	padding-left: 2%;
}
fieldset{
	padding-bottom: 5px;
}
.input-group{
	width: 100%;
}
.cadastroCliente {
	display: flex;
	flex-direction: column;
	align-self: center;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: auto;
}
.margin-top{
	margin-top: 10px;
}
.btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 30%;
}
.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	padding: 10px 0px;	
}
.botoes .botao {
	width: 45%;
}
.lg-view {
	display: inline-block;
}
.sm-view {
	display: none;
}
@media screen and (max-width: 1000px){
	.row{
		flex-wrap: wrap;
	}
	.margin-top{
		margin-top: 0px;
	}
	.input-group{
		margin: 5px;
		width: 98%;
	}
	.max150{
		max-width: 150px;
	}
	.max350{
		max-width: 350px;
	}
	.max200{
		max-width: 200px;
	}
	.max180{
		max-width: 180px;
	}
}
@media screen and (max-width: 768px){
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
}
@media screen and (max-width: 675px) {
	button span {
		display: none;
	}
}

@media screen and (max-width: 425px){
	.input-group{
		max-width: 100% !important;
	}
}
</style>