import { render, staticRenderFns } from "./ListaVendas.vue?vue&type=template&id=04c8d287&scoped=true"
import script from "./ListaVendas.vue?vue&type=script&lang=js"
export * from "./ListaVendas.vue?vue&type=script&lang=js"
import style0 from "./ListaVendas.vue?vue&type=style&index=0&id=04c8d287&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c8d287",
  null
  
)

export default component.exports