import { PrevisaoCompra } from "../models/PrevisaoCompra.js";
import {doRequest} from "./http.js";

export default{
	state: {
		PrevisaoCompras: {
			content: [],
			paginacao: {
				last: true,
				page: 0,
				size: 25,
				totalElements: 0,
				totalPages: 1
			}
		},		
		totalPaginasPrevisaoCompras : 0,
	},

	actions: {
		async addPrevisao({state, commit}, payload) {
			try {
				state.carregando = true;
				payload.cdempresa = state.Empresa.id;
				
				const body = JSON.stringify(payload);
				const url = "pedido-compra-controller";

				const data = doRequest(url, {
					method: "POST",
					body,
					cache: "no-cache"
				}, commit);

				state.carregando = false;
				return data;

			} catch (error) {
				console.log(error);
				state.carregando = false;
				return null;
			}
		},

		modifyPrevisao({state, commit}, payload) {
			try {
				state.carregando = true;
				payload.cdempresa = state.Empresa.id;
				
				const body = JSON.stringify(payload);
				let url = "pedido-compra-controller";
				url += `?id=${payload.id}`;

				const data = doRequest(url, {
					method: "PUT",
					body,
					cache: "no-cache"
				}, commit);

				state.carregando = false;
				return data;

			} catch (error) {
				console.log(error);
				state.carregando = false;
				return null;
			}
		},

		async filtrarPrevisaoCompras({state, commit}, payload) {
			try {
				state.carregando = true;
				let url = "pedido-compra-controller/filter	";
				url += `?empresa=${payload?.empresa ? payload.empresa : state.Empresa.id}`;
				url += `${payload?.codigo ? `&codigo=${payload?.codigo}` : ""}`;
				url += `${payload?.dataChegadaF ? `&dataChegadaF=${payload?.dataChegadaF}` : ""}`;
				url += `${payload?.dataChegadaI ? `&dataChegadaI=${payload?.dataChegadaI}` : ""}`;
				url += `${payload?.dataPedidoF ? `&dataPedidoF=${payload?.dataPedidoF}` : ""}`;
				url += `${payload?.dataPedidoI ? `&dataPedidoI=${payload?.dataPedidoI}` : ""}`;
				url += `${payload?.dataPrevistaF ? `&dataPrevistaF=${payload?.dataPrevistaF}` : ""}`;
				url += `${payload?.dataPrevistaI ? `&dataPrevistaI=${payload?.dataPrevistaI}` : ""}`;
				url += `${payload?.nome ? `&nome=${payload?.nome}` : ""}`;
				url += `${payload?.page ? `&page=${payload?.page}` : ""}`;
				url += `${payload?.size ? `&size=${payload?.size}` : ""}`;
				url += `${payload?.sort ? `&sort=${payload?.sort}` : ""}`;
				url += `${payload?.sortOrder ? `&sortOrder=${payload?.sortOrder}` : ""}`;

				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache"
				}, commit);

				state.carregando = false;
				if(data && data.content){
					commit("atualizarContent", {...data});
					return;
				}
				commit("atualizarContent", {
					content: [],
					paginacao: {
						last: true,
						page: 0,
						size: 25,
						totalElements: 0,
						totalPages: 1
					}
				});

			} catch (error) {
				state.carregando = false;
				console.log(error);
			}
		},

		async filtrarPrevisaoComprasVencimento({state, commit}, payload) {
			try {
				state.carregando = true;
				let url = "pedido-compra-controller/agrupadoPorVencimento	";
				url += `?empresa=${payload?.empresa ? payload.empresa : state.Empresa.id}`;
				url += `${payload?.dataVencimentoF ? `&dataVencF=${payload?.dataVencimentoF}` : ""}`;
				url += `${payload?.dataVencimentoI ? `&dataVencI=${payload?.dataVencimentoI}` : ""}`;
				url += `${payload?.sort ? `&sort=${payload?.sort}` : ""}`;
				url += `${payload?.sortOrder ? `&sortOrder=${payload?.sortOrder}` : ""}`;

				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache"
				}, commit);
				state.carregando = false;
				if(data && !data?.mensagem){
					return data;
				}
				else{
					throw new Error(data.mensagem);
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				return [];				
			}
		}
	},

	mutations: {
		atualizarContent(state, payload){
			state.PrevisaoCompras = [];
			if(payload?.content && payload?.content.length > 0){
				state.PrevisaoCompras = payload.content.map(previsao => new PrevisaoCompra(previsao, previsao.id));
			}

			state.paginaPrevisaoComprasAtual = payload.page;
			state.totalPaginasPrevisaoCompras = payload.totalPages;
			state.PrevisaoCompras.paginacao = {
				last: payload?.last || true,
				page: payload?.page || 0,
				size: payload?.size || 25,
				totalElements: payload?.totalElements || 0,
				totalPages: payload?.totalPages || 1,
			};
		},
		limpaPrevisaoCompra(state){
			state.PrevisaoCompras = [];
		}
	}

};