import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";
import JsBarcode from "jsbarcode";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function gerarHeaderCupom (Empresa) {
	return [
		{ text: Empresa.fantasia, style: "header" },
		{ text: `${Empresa.id} - ${Empresa.nome}`, style: "descricao" },
		{
			columns: [
				{ text: `CNPJ: ${Empresa.cnpj}`, style: "descricao" },
				{ text: `IE: ${Empresa.ie}`, style: "descricao", alignment: "right" },
			]
		},
		{ text: `${Empresa.endereco}, ${Empresa.numero} - ${Empresa.bairro}`, style: "descricao" },
		{ text: `CEP: ${Empresa.cep}. CIDADE: ${Empresa.nomeCidade} / ${Empresa.ufCidade}`, style: "descricao" },
		{ text: `TELEFONE: ${Empresa.fone} / ${Empresa.celular}`, style: "descricao" },
	];
}

export function gerarCodigoBarras (texto, height) {
	const canvas = document.createElement("canvas");
	JsBarcode(canvas, texto, {
		format: "CODE128",
		height: height || 40,
		displayValue: false
	});
	return canvas.toDataURL("image/png");
}

export function gerarMensagemAgradecimento (hora) {
	if (hora >= 4 && hora < 12) {
		return `Obrigado.
		Tenha um Bom Dia!`;
	} else if (hora >= 12 && hora < 18) {
		return `Obrigado.
		Tenha uma Boa tarde!`;
	} else {
		return `Obrigado.
		Tenha uma Boa noite!`;
	}
}

export function gerarRodape () {
	return [
		{ text: "Documento não fiscal", style: "naoFiscal" },
		{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
		{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape" },
	];
}

const defaultStyles = {
	header: {
		fontSize: 12,
		bold: true,
		alignment: "center",
		margin: [0, 2, 0, 0]
	},

	headerTabela: {
		fontSize: 9,
		bold: true,
		alignment: "center",
		noWrap: true
	},

	itemTabela: {
		fontSize: 8,
		alignment: "center",
	},

	dataDocumento: {
		fontSize: 9,
		alignment: "right"
	},
	valorTabela: {
		alignment: "right",
		fontSize: 9
	},
	descricao: {
		fontSize: 9,
		alignment: "left",
	},

	headerPedido: {
		fontSize: 9,
		alignment: "left",
		bold: true,
	},

	valores: {
		alignment: "right",
		bold: true,
		fontSize: 9
	},

	rodape: {
		alignment: "center",
		fontSize: 7,
		margin: [0, 0, 0, 3]
	},

	naoFiscal: {
		alignment: "center",
		fontSize: 7
	},

	codigobarra: {
		alignment: "center",
	}
};

export async function gerarImpressao (pdfMakeDocumentDefinitions) {
	if (!pdfMakeDocumentDefinitions) return null;
	const styles = {...defaultStyles};
	if (pdfMakeDocumentDefinitions.styles) {
		Object.keys(pdfMakeDocumentDefinitions.styles).forEach(key => {
			styles[key] = pdfMakeDocumentDefinitions.styles[key];
		});
	}
	return await new Promise((res, rej) => {
		const documento = pdfMake.createPdf({
			pageMargins: [1, 1, 1, 5],
			...pdfMakeDocumentDefinitions,
			styles
		});
	
		documento.getBlob((pdfBlob) => {
			if (!pdfBlob) {
				return rej(null);
			}
			return res(window.URL.createObjectURL(pdfBlob));
		});
	});
}
