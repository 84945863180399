<template>
    <div id="TelaCodigoBarra">    
        <h1 class="titulo w-100 text-center my-5">CÓDIGO DE BARRA</h1>
        <div class="borda" v-if="!codiBarra.barracompra">
            <div class="cabeca">
                <label class="text-right m-2">Produto:</label>
                <span style="color: blue;"><strong>{{codiBarra ? codiBarra.barraunico.id || codiBarra.barraunico.cdproduto : ""}}</strong></span>
                <span>&nbsp; - &nbsp;</span> 
                <span style="color: blue;"><strong>{{codiBarra? codiBarra.barraunico.descricao : ""}}</strong></span>
            </div>
        </div>
        <div class="borda">
            <div class="cabeca row row-responsividade">
				<div class="row">
					<div class="row center mx-5">
						<input type="checkbox" id="imprimirDescricao" :value="true" v-model="imprimirDescricao" class="mx-2 pointer">
						<label for="imprimirDescricao" class="pointer">Descrição</label>
					</div>
					<div class="row center mx-5">
						<input type="checkbox" id="imprimirRef" :value="true" v-model="imprimirRef" class="mx-2 pointer">
						<label for="imprimirRef" class="pointer">Referência</label>
					</div>
				</div>	
				<div class="row">
					<div class="row center mx-5">
						<input type="checkbox" id="imprimirPrazo" :value="true" v-model="imprimirPrazo" class="mx-2 pointer">
						<label for="imprimirPrazo" class="pointer">Valor prazo</label>
					</div>
					<div class="row center mx-5">
						<input type="checkbox" id="imprimirVista" :value="true" v-model="imprimirVista" class="mx-2 pointer">
						<label for="imprimirVista" class="pointer">Valor vista</label>
					</div>
				</div>
                <div class="row center mx-5" v-if="mostrarQuantidadeGerar">
                    <label for="Quantidade">&nbsp; Gerar: &nbsp;</label> 
                    <input class="gerar" v-model="Quantidade" type="text" id="Quantidade" @input="mascaraInteger" 
                    @focus="(e) => {e.target.select()}" @keydown.enter="enterQuantidadeEtiqueta" @keydown.esc="limparetiqueta"
                    :disabled="mostrarAba != 'Gerar'">
                </div>
            </div>
        </div>
        <div class="iconecompra">
            <i class="fas fa-trash-alt icones" style="color: var(--deletar)" @click="limparetiqueta"></i>
            <i class="fas fa-save icones" style="color: var(--confirmar)" v-if="mostrarAba == 'Gerar'" @click="salvarEtiquetas"></i>
            <i class="fas fa-print icones" style="color: var(--confirmar)" v-if="etiquetas.length && mostrarAba == 'Imprimir'" @click="imprimirEtiqueta"></i>
        </div>
        <div id="aba-controle-etiqueta">
            <div class="aba-etiqueta aba-selecionada-etiqueta" @click="mudarAba(0, 'Gerar')">Gerar</div>
            <div class="aba-etiqueta" @click="mudarAba(1, 'Imprimir')">
                Imprimir
                <span class="quantidade-etiquetas">{{etiquetas.length}}</span>
            </div>
        </div>
        <div class="scroll" v-if="mostrarAba == 'Gerar'">
            <table class="w-100" v-if="!codiBarra.barravariacao">
                <thead>
                    <tr>
                        <td style="text-align: center; max-width: 90px;">Codigo</td>
                        <td style="color: var(--primario)">Produto</td>
                        <td style="min-width: 90px color: var(--primario)">Código Barra</td>
                        <td style="min-width: 90px;">$ Prazo</td>
                        <td style="min-width: 90px color: var(--primario)">$ Vista</td>
                        <td class="center">Ação</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(gerado, index) in gerarBarra" :key="index">
                        <td style="text-align: left;">{{gerado.codigo}}</td>
                        <td style="text-align: left;">{{gerado.descricao}}</td>
                        <td style="text-align: left;">{{gerado.codigobarra}}</td>
                        <td style="text-align: right;">{{Number((typeof gerado.prazo === "number")?gerado.prazo:gerado.prazo.replace(/[.]+/g,"").replace(",",".") || 0).toLocaleString("pt-BR",{style:"currency",currency:"BRL"})}}</td>   
                        <td style="text-align: right;">{{Number((typeof gerado.vista === "number")?gerado.vista:gerado.vista.replace(/[.]+/g,"").replace(",",".") || 0).toLocaleString("pt-BR",{style:"currency",currency:"BRL"})}}</td>
                        <td class="text-nowrap">
                            <i class="fas fa-trash-alt" style="color: var(--deletar)" @click="removerItem(index)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="w-100" v-else>
                <thead>
                    <tr>
                        <td class="text-left" style="max-width: 120px;">Codigo</td>
                        <td class="text-left" style="max-width: 500px;">Código Barra</td>                        
                        <td class="text-left" style="max-width: 500px;">Variaçao</td>
                        <td class="text-center">Grade</td>                        
                        <td class="text-right" style="max-width: 120px;">Saldo</td>
                        <td class="text-right" style="max-width: 40px">Quantidade</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(variacao, index) in codiBarra.barravariacao" :key="index">
                        <td class="text-left">{{ variacao.cdproduto || variacao.id }}</td>
                        <td class="text-left">{{ variacao.codbarra || variacao.codBarra || variacao.codbarraFornecedor }}</td>                                                                   
                        <td class="text-left">{{variacao.variacao}}</td>                                   
                        <td class="text-center">{{variacao.grade}}</td>                                   
                        <td class="text-right" style="max-width: 80px;">{{variacao.saldo}}</td> 
                        <td class="text-right" style="max-width: 80px;">
                            <input
								class="input-tabela"
								:id="variacao.cdproduto"
								type="text"
								v-model="variacao.quantidade"
								@focus="(e) => {e.target.select()}"
								@keydown.enter.prevent="proximoCampo(codiBarra.barravariacao[index+1 < codiBarra.barravariacao.length ? index+1 : 0].cdproduto)"
								v-mascaraInteger
								@input="geraretiquetaVariacao"
							>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="scroll" id="tabela-etiquetas" v-else>
            <table class="w-100">
                <thead>
                    <tr>
                        <td style="text-align: center;">Seq.</td>
                        <td style="text-align: center; max-width: 90px;">Codigo</td>
                        <td style="color: var(--primario)">Produto</td>
                        <td style="min-width: 90px color: var(--primario)">Código Barra</td>
                        <td style="min-width: 90px;">$ Prazo</td>
                        <td style="min-width: 90px color: var(--primario)">$ Vista</td>
                        <td class="center">Ação</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(etiqueta, index) in etiquetas" :key="index">
                        <td style="text-align: left;">{{index+1}}</td>
                        <td style="text-align: left;">{{etiqueta.codigo}}</td>
                        <td style="text-align: left;">{{etiqueta.descricao}}</td>
                        <td style="text-align: left;">{{etiqueta.codigobarra}}</td>
                        <td style="text-align: right;">{{Number((typeof etiqueta.prazo === "number")?etiqueta.prazo:etiqueta.prazo.replace(/[.]+/g,"").replace(",",".") || 0).toLocaleString("pt-BR",{style:"currency",currency:"BRL"})}}</td>
                        <td style="text-align: right;">{{Number((typeof etiqueta.vista === "number")?etiqueta.vista:etiqueta.vista.replace(/[.]+/g,"").replace(",",".") || 0).toLocaleString("pt-BR",{style:"currency",currency:"BRL"})}}</td>
                        <td class="text-nowrap">
                            <i class="fas fa-trash-alt" style="color: var(--deletar)" @click="removerEtiqueta(index)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { Produto } from "../models/Produto";

export default {
	name: "CodigoBarra",
	props: {
		codiBarra: Object
	},
	data(){
		return {
			Quantidade : "1",
			gerarBarra : [],
			imprimirCodigo: true,
			imprimirDescricao: true,
			imprimirRef: true,
			imprimirPrazo: true,
			imprimirVista: true,
			mostrarAba: "Gerar"
		};
	},
	computed: {
		etiquetas() {
			return this.$store.state.etiquetas;
		},

		mostrarQuantidadeGerar () {
			if(this.codiBarra.editarQuantidade) return true;
			return !this.codiBarra.barravariacao && !this.codiBarra.barracompra && (this.codiBarra.barraunico?.tipo != "V");
		}
	},
	mounted(){
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		if (this.codiBarra.barravariacao) {
			this.iniciarBarraVariacao();
			this.geraretiquetaVariacao();
		} else if (this.codiBarra.barraunico){
			this.montarBarraUnico();
		} else if (this.codiBarra.barracompra) {
			this.montacompra();
		}
	},
	destroyed() {
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
	},
	methods:{
		// REMOVER / DELETAR
		removerItem(index) {
			this.$store.state.carregando = true;
			this.gerarBarra.splice(index,1);
			setTimeout(() => {
				this.$store.state.carregando = false;
			}, 50);
		},
		removerEtiqueta(index) {
			this.$store.state.carregando = true;
			this.etiquetas.splice(index, 1);
			setTimeout(() => {
				this.$store.state.carregando = false;
			}, 50);
		},

		limparetiqueta(){
			if (this.mostrarAba != "Gerar" && this.etiquetas.length) {
				return this.$store.commit("relatarErro", {
					mensagem: "Tem certeza que deseja limpar todas as etiquetas selecionadas?",
					tipo: "polar",
					item: {
						zerarEtiquetas: true
					},
				});
			}
			if (this.codiBarra.barracompra || this.codiBarra.barravariacao) {
				this.$emit("fecharCodigoBarra");
			} else if (this.codiBarra.barraunico) {
				this.proximoCampo("Quantidade");
				this.Quantidade = "1";
				this.geraretiqueta();
			}
		},


		//SALVAR / ALTERAR
		salvarEtiquetas() {
			this.$store.state.carregando = true;
			this.$store.commit("incluirCodigoBarras", [...this.gerarBarra]);
			this.Quantidade = "0";
			this.gerarBarra = [];
			this.mudarAba(1, "Imprimir");
			setTimeout(() => {
				this.$store.state.carregando = false;
			}, 50);
		},

		//INCLUIR / GERAR 
		gerarDescricao(produto){
			const empresa = this.$store.state.Empresa;
			if(empresa.tipoEtiqueta === 1){
				if(produto.cdpai){
					return produto.descresumido ? `${produto.descresumido}; ${produto.variacao} [${produto.grade}]` : produto.descricao;
				} else {
					return produto.descricao;

				}
			} else if (empresa.tipoEtiqueta == 2){
				if(produto.cdpai){
					return produto.descresumido ? `${produto.descresumido} [${produto.grade}]` : produto.descricao;
				} else {
					return produto.descricao;
				}
			} else if (empresa.tipoEtiqueta == 3){
				return produto.descricao || produto.descresumido;
			} else if (empresa.tipoEtiqueta === 4){
				return produto.descricao || produto.descresumido;
			} else if (empresa.tipoEtiqueta === 5){
				return produto.descricao || produto.descresumido;
			} else if (empresa.tipoEtiqueta === 6){
				return produto.descresumido || produto.descricao;
			}
		},

		incluirEtiqueta (produto) {
			this.gerarBarra.push({
				codigo: produto.id ? produto.id : produto.cdproduto,
				descricao: this.gerarDescricao(produto),
				cdreferencia: produto.cdreferencia || produto.cdReferencia,
				codigobarra: produto.codBarra || produto.codbarra,
				prazo: produto.valorPrazo || produto.pontoequi,
				vista: produto.valorVista || produto.vlrvista,
				variacao: produto.variacao,
				grade: produto.grade
			});
		},

		montarBarraUnico () {
			const produto = this.codiBarra.barraunico;
			if (produto.tipo === "V") {
				return this.geraretiqueta();
			}
			this.proximoCampo("Quantidade");
			this.Quantidade = "1";
			this.geraretiqueta();
		},

		async geraretiqueta(){
			this.gerarBarra = [];
			const produto = this.codiBarra.barraunico;
			if (produto.tipo === "V") {
				await this.$store.dispatch("buscarFilhos", { pai: produto.id });
				const filhos = this.$store.state.produtoFilhos;
				filhos.forEach(filho => {
					if (filho.saldo && (filho.saldo > 0)) {
						for (let i = 0; i < filho.saldo; i++){
							this.incluirEtiqueta(new Produto({
								...filho,
								codbarra: filho.codBarra
							}));
						}
					}
				});
			} else {
				for (let i = 0; i < this.Quantidade; i++){
					this.incluirEtiqueta(produto);
				}
			}
		},

		geraretiquetaVariacao(){
			this.gerarBarra = [];
			this.codiBarra.barravariacao.forEach(Geravariacao => {
				console.log(Geravariacao);
				for (let i=0; i< Geravariacao.quantidade; i++){
					this.gerarBarra.push({
						codigo: Geravariacao.id || Geravariacao.cdproduto,
						descricao: this.gerarDescricao(Geravariacao),
						codigobarra: Geravariacao.codbarra || Geravariacao.codBarra,
						cdreferencia: Geravariacao.cdreferencia || Geravariacao.cdReferencia,
						prazo: Geravariacao.pontoequi || 0,
						vista: Geravariacao.vlrvista || 0,
						variacao: Geravariacao.variacao,
						grade: Geravariacao.grade
					});
				}                
			});
		},

		montacompra(){
			this.gerarBarra = [];
			this.codiBarra.barracompra.forEach(item => {
				if (!item.filhos?.length) {
					for (let i = 0; i < item.quantidade; i++){
						this.gerarBarra.push({
							codigo: item.cdproduto,
							cdreferencia: item.cdreferencia,
							descricao: this.gerarDescricao(item),
							codigobarra: item.codbarra,
							prazo: parseFloat(item.prazo.replace(".","").replace(",",".")) || 0,
							vista: parseFloat(item.vista.replace(".","").replace(",",".")) || 0,
						});
					}
				} else {
					item.filhos.forEach(filho => {
						for (let j = 0; j < filho.quantidade; j++) {
							this.gerarBarra.push({
								codigo: filho.cdproduto,
								cdreferencia: filho.cdreferencia,
								descricao:  this.gerarDescricao(filho),
								codigobarra: filho.codbarra,
								prazo: typeof filho.prazo == "number"? filho.prazo : parseFloat(filho.prazo.replace(".","").replace(",",".")) || 0,
								vista: typeof filho.vista == "number"? filho.vista : parseFloat(filho.vista.replace(".","").replace(",",".")) || 0,
								variacao: filho.variacao,
								grade: filho.grade
							});
						}
					});
				}
			});

		},

	
		
		//METODOS DIVERSOS
		enterQuantidadeEtiqueta() {
			if (this.mostrarAba == "Gerar") {
				this.salvarEtiquetas();
			} else {
				this.imprimirEtiqueta();
			}
		},

		mudarAba(index, mostrarAba){
			const aba = document.getElementsByClassName("aba-etiqueta")[index];
			const abaSelecionada = document.getElementsByClassName("aba-selecionada-etiqueta")[0];
			if (!aba || !abaSelecionada) return;
			if (!this.etiquetas.length && aba.innerHTML == "Imprimir") return;
			if (aba != abaSelecionada) {
				aba.classList.add(["aba-selecionada-etiqueta"]);
				abaSelecionada.classList.remove(["aba-selecionada-etiqueta"]);
				setTimeout(() => {
					this.mostrarAba = mostrarAba || aba.innerText;
					if (this.mostrarAba != "Gerar") {
						setTimeout(() => {
							const scrollDiv = document.getElementById("tabela-etiquetas");
							if (scrollDiv) {
								scrollDiv.scrollTop = (scrollDiv.scrollHeight);
							}
						},30);
					}
				}, 30);
			}
		},
		
		mascaraInteger(evento) {
			const regex = /[0-9]/;
			const id = evento.target.id;
			if (!regex.test(evento.data)) {
				this[id] = this[id].substring(0,this[id].length-1);
			}
			if (id == "Quantidade") {
				this.geraretiqueta();
			}
		},

		imprimirEtiqueta() {
			this.$store.dispatch("gerarCodigoBarrasProduto", {
				variacao: this.etiquetas,
				imprimirCodigo: this.imprimirCodigo,
				imprimirDescricao: this.imprimirDescricao,
				imprimirRef: this.imprimirRef,
				imprimirPrazo: this.imprimirPrazo,
				imprimirVista: this.imprimirVista,
				cdcompra: this.codiBarra.cdcompra
			});
			if (this.codiBarra.barravariacao) {
				this.geraretiquetaVariacao();
			} else if (this.codiBarra.barraunico){
				this.proximoCampo("Quantidade");
				this.Quantidade = "1";
				this.geraretiqueta();
			} else if (this.codiBarra.barracompra) {
				this.montacompra();
			}
			this.mudarAba(0, "Gerar");
		},

		proximoCampo(id){
			if (!id && id !=0){
				throw new Error("Falta o Id");
			}
			const input = document.getElementById(id);
			if (input) {
				input.focus();
				if (input.select){
					input.select();
				}
			}
		},
		polarConfirmado(item) {
			if (item.zerarEtiquetas) {
				this.$store.commit("limparEtiquetas");
				this.mudarAba(0, "Gerar");
				if (this.codiBarra.barravariacao) {
					this.geraretiquetaVariacao();
				} else if (this.codiBarra.barraunico){
					this.Quantidade = "1";
					this.proximoCampo("Quantidade");
					this.geraretiqueta();
				} else if (this.codiBarra.barracompra) {
					this.montacompra();
				}
			}
		},

		iniciarBarraVariacao () {
			this.codiBarra.barravariacao.forEach(item => {
				item.quantidade = `${item.saldo || ""}`;
			});
		},
	},
};

</script>

<style scoped>

.gerar{
    text-align: right;
    width: 60px;
}

.titulo{
    margin-top: 12px;
    background-color: var(--primario);
    color: white;
}

.borda {
    border: 1px solid black;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    margin: 2px 0px;
}
.cabeca{
    justify-content: center;
}

#TelaCodigoBarra {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 92vh;
    margin: 0px auto;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    overflow: hidden;
}
.icones{
    font-size: 16pt;
    align-self: center;
    padding: 5px;
    border: 1px solid black;
    border-radius: 50%;
    box-shadow: 1px 1px 3px 1px black;    
}

.icones:active{
    transform: translateY(3px);
}

.input-tabela {
	background-color: transparent;
	width: 100%;
	height: 28px;
	border: none;
	text-align: right;
	font-size: 11pt;
}

.scroll{
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin: 0px 2px;
    box-shadow: 3px 3px 5px #292929;
    padding-top: 3px;
}

.iconecompra{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 5px 0px;
}

#aba-controle-etiqueta {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    margin: 2px 0px 0px 0px;
    padding: 0px 10px;
    z-index: 1;
    height: 35px;
}
.aba-etiqueta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: 14pt;
    margin: 0px 2px;
    text-align: center;
    cursor: pointer;
    height: 35px;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    user-select: none;
    width: 100%;
}
.aba-selecionada-etiqueta {
    text-align: center;
    height: 100%;
    background-color: var(--bg-app);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 1px -2px 2px #292929;
    cursor: default;
}
.quantidade-etiquetas {
    font-size: 8pt;
    margin: auto 5px;
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    color: #fff;
    background-color: var(--primario);
    border: none;
    border-radius: 50%;
}

@media screen and (max-width:440px) {
	.row-responsividade{
		flex-direction: column;
	}
}
</style>