<template>
	<div class="containerPage">
		<div id="listaModal" :class="`containerTable ${!showTable? 'table-full' : ''}`">
			<table v-if="classificacao.length != 0">
				<thead>
					<tr>
						<td style="text-align: center;">
							Classificação Padrão
						</td>
						<td style="text-align: center;">Cód.</td>
						<td style="text-align: center;">Descrição</td>
						<td style="text-align: center;">% Desc. Prazo</td>
						<td style="text-align: center;">% Desc. Vista</td>
						<td style="text-align: center;">Somente vista</td>
						<td style="text-align: center;">Qtd. Produtos condicional</td>
						<td style="text-align: center;">Ação</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(classi, index) in classificacao" :key="classi.cdclassi" >
						<td style="text-align: center; cursor: default;" >
							<input v-if="classi.padrao" type="checkbox" v-model="classi.padrao" @change="definirClassificacaoPadrao(classi, index)"
								class="classificacaoPadrao" />
							<input v-else type="checkbox" v-model="classi.padrao" @change="definirClassificacaoPadrao(classi,index)"
								class="classificacaoNaoSelecionada" />
						</td>
						<td style="text-align: center; color: blue;" @click="selecionarClassificacao(classi)">{{ classi.cdclassi }}</td>
						<td @click="selecionarClassificacao(classi)">{{ classi.descricao }}</td>
						<td style="text-align: right" @click="selecionarClassificacao(classi)">{{ `${classi.descprazo || 0}`.replace(".", ",") }}</td>
						<td  style="text-align: right" @click="selecionarClassificacao(classi)">{{ `${classi.descvista || 0}`.replace(".", ",") }}</td>
						<td  style="text-align: center" @click="selecionarClassificacao(classi)">{{ classi.bloqueiavendaprazo ? "SIM" : "NÃO" }}</td>
						<td style="text-align: center" @click="selecionarClassificacao(classi)">{{ classi.limitecondicional == -1 ? "SEM LIMITE" : classi.limitecondicional}}</td>
						<td style="text-align: center; "><i class="fas fa-trash" @click="solicitarRemoverClassificacao(classi)" style="color: var(--deletar)"></i> </td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
export default{
	name:"TabelaClassificacao",
	props: {
		showTable: Boolean, 
	},
	data() {
		return{
			pesquisaLivre: "",
			timerFiltro: null,
		};
	},
	computed: {
		classificacao(){
			return this.$store.state.Classificacao.content;
		},
		paginacao(){
			return this.$store.state.Classificacao.paginacao;
		}
	},
	methods: {
		async definirClassificacaoPadrao(classi, index){
			for(let i=0; i < this.classificacao.length; i++){
				if(i != index) {
					this.classificacao[i].padrao = false;
				}
			}
			if(classi.padrao){
				const data = await this.$store.dispatch("definirClassificacaoPadrao", {cdclassi: classi.cdclassi});
				if(data){
					return this.$store.commit("relatarErro", {mensagem: data?.mensagem, sucess: true});
				}
			}
		},
		solicitarRemoverClassificacao(classi) {
			if (classi.padrao) {
				this.$store.commit("relatarErro", {
					mensagem: "Não é permitido remover a classificação padrão"
				});
				return;
			}
			this.$store.commit("relatarErro", {
				mensagem: `Tem certeza que deseja remover a classificação ${classi.cdclassi} - ${classi.descricao}?`,
				tipo:"polar",
				item: {
					...classi
				}
			});
		},
		async polarConfirmado(classi){
			const res = await this.$store.dispatch("removerClassificacao", classi.cdclassi);
			if(res){
				this.$store.dispatch("buscarClassificacao");
			}
		},
		selecionarClassificacao(classi){
			this.$emit("selecionarClassificacao", classi);
		},
		async buscarClassificacao(){
			await this.$store.dispatch("buscarClassificacao");
		}
	},
	mounted(){
		this.buscarClassificacao();
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
	},
	destroyed() {
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
	}
};
</script>
<style scoped>
.classificacaoNaoSelecionada {
	cursor: pointer;
}
.classificacaoPadrao {
	pointer-events: none;
}

.containerPage{
	width: 100%;
	overflow: hidden;
}

.containerFiltro{
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 20px 10px;
	gap: 30px;
}
.containerFiltro input{
	width: 100%;
}
.containerTable{
	overflow-y: auto;
}
.containerTable table{
	padding: 0 10px;
	width: 100%;
}
table thead tr td{
	padding: 0px 5px;
}
#listaModal {		
	height: 65vh;
    overflow: auto;
    margin: 0 auto;
    font-size: var(--txt-normal);
}
#listaModal.table-full{
	height: 77vh !important;
}
@media screen and (max-width: 800px){
	.containerTable{
		height: 55vh;
		white-space: nowrap;
		width: 100%;
		overflow-y: auto;
		overflow-x: auto;
	}
	.paginacao{
		margin-top: 20px;
	}
}
@media screen and (max-width: 768px) {
	#listaModal.table-full{
		height: 68vh !important;
	}
	#listaModal{
		height: 43vh !important;
	}
}
@media screen and (max-width: 425px) {
	#listaModal.table-full{
		height: 71vh !important;
	}
	#listaModal{
		height: 30vh !important;
	}
}
</style>