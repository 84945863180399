<template>
	<div class="container-page">
		<paginacao-filtro
			@filtrar="filtrar"
			@limpar="limparFiltro"
			@handleShowTable="handleShowTable"
			:ControlePaginacao="Paginacao"
		>
			<template v-slot:filtro>
				<div class="filtro-content">
					<div class="column-filtro">
						<div class="row-filtro">
							<div class="input-group" style="min-width: 260px;">
								<input type="search" v-model="nomeCliente"
									@keydown.tab.prevent="proximoInput('pesquisar')"
									@keydown.enter.prevent="proximoInput('pesquisar')" id="nomeCliente"
									placeholder=" "
									autocomplete="off"
									:disabled="$route.query.trocarProdutos && (!$store.state.Usuario.supervisor || !$store.state.Usuario.usuarioPrivilegiado)"
								/>
								<label for="nomeCliente" style="background-color: #f8f8f8;">PRIMEIRO INFORME O CLIENTE</label>
							</div>
							<div class="input-group select-group">
								<select 
									v-model="tipoFiltro"
									id="tipoFiltro"
									@change="handleTipoFiltro"
								>
									<option disabled value="SEL">
										SELECIONE UMA OPÇÃO
									</option>
									<option v-for="(filtro, index) in Filtros" :key="index" :value="filtro">
										{{filtro}}
									</option>
								</select>
								<label for="tipoFiltro" style="background-color: #f8f8f8;">
									Opção Filtro
								</label>
							</div>
							<div class="input-group" v-if="tipoFiltro!='DATA DE EMISSÃO'">
								<input
									type="text"
									v-model="filtroPesquisa"
									placeholder=" "
									tabindex="2"
									@keydown.tab.prevent=""
									autocomplete="off"
									id="filtroPesquisa"
									@keydown.left="proximoInput('pesquisar')"
									:maxlength="tipoFiltro === 'CÓD. DE BARRA DO PROD.' ? 12 : 9999"
								>
								<label for="filtroPesquisa" style="background-color: #f8f8f8;">
									Pesquisa
								</label>
							</div>
							<div class="input-group" v-else>
								<input
									type="date"
									v-model="filtroPesquisa"
									placeholder=" "
									tabindex="2"
									@keydown.tab.prevent=""
									autocomplete="off"
									id="filtroPesquisa" 
									@keydown.left="proximoInput('pesquisar')"
								>
							</div>
							<div class="input-group" v-if="$route.query.menu === 'IniciarTroca'">
								<input 
									type="text"
									id="maxDiaTroca"
									readonly
									placeholder=" "
									style="text-align: right;"
									:value="$store.state.Usuario.maxDiasTroca"
								/>
								<label for="maxDiaTroca" style="background-color: #f8f8f8;">
									Máx. dia p/ troca
								</label>
							</div>							
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<ListagemVendasTroca 
					:Vendas="VendasTroca"
					@selecionarVenda="$emit('selecionarVenda',$event)"
					:showTable="showTable"
					:height="height"			
				/>
			</template>
		</paginacao-filtro>
	</div>
</template>
<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import ListagemVendasTroca from "../components/ListagemVendasTroca.vue";

export default{
	name: "ConsultaVendasTroca",
	components: {
		PaginacaoFiltro,
		ListagemVendasTroca
	},
	props: {
		VendasTroca: Array,
		height: String,
	},
	data() {
		return {
			tipoFiltro: "SEL",
			Filtros: [
				"CÓDIGO DA VENDA",
				"DATA DE EMISSÃO",
				"DESCRIÇÃO DO PRODUTO",
				"CÓDIGO DO PRODUTO",
				"CÓD. DE BARRA DO PROD.",
				"REFERÊNCIA DO PRODUTO"
			],
			filtroPesquisa: "",
			nomeCliente: "",
			nomePromocao: "",
			showTable: true,
		};
	},

	computed: {
		Paginacao() {
			return {
				paginaAtual: this.$store.state.paginaVendasTrocaAtual,
				totalPaginas: this.$store.state.totalPaginasVendasTroca
			};
		}
	},

	methods: {
		handleShowTable() {
			this.showTable = !this.showTable;
		},

		limparFiltro() {
			this.nomeCliente = "";
			this.tipoFiltro = "SEL";
			this.filtroPesquisa = "";
			this.focarInputPesquisa();
			this.filtrar(null);
		},

		handleTipoFiltro() {
			this.filtroPesquisa = "";
			this.focarInputPesquisa();
		},

		focarInputPesquisa() {
			setTimeout(() => {
				document.getElementById("filtroPesquisa").focus();
			}, 50);
		},

		async filtrar(page) {
			const filtro = {
				page: page || 0,
				troca: true,
				nomeCliente: this.nomeCliente,
				nome: this.nomePromocao,
				size: 25,
				cdVenda: this.tipoFiltro === "CÓDIGO DA VENDA"? (this.filtroPesquisa.includes("F") ? this.filtroPesquisa.split("F")[1] : this.filtroPesquisa) : null,
				descproduto: this.tipoFiltro === "DESCRIÇÃO DO PRODUTO"? this.filtroPesquisa : null,
				cdProduto: this.tipoFiltro === "CÓDIGO DO PRODUTO"? this.filtroPesquisa : null,
				codbarra: this.tipoFiltro === "CÓD. DE BARRA DO PROD."? this.filtroPesquisa : null,
				referencia: this.tipoFiltro === "REFERÊNCIA DO PRODUTO"? this.filtroPesquisa : null,
			};
			filtro.dataF = new Date().toLocaleDateString("en-CA");
			if (this.tipoFiltro === "DATA DE EMISSÃO"){
				if(this.filtroPesquisa > new Date(
					new Date().getFullYear(), new Date().getMonth(),
					new Date().getDate() - this.$store.state.Usuario.maxDiasTroca
				).toLocaleDateString("en-CA")){
					filtro.dataI = this.filtroPesquisa;	
				} else {
					filtro.dataI = new Date(
						new Date().getFullYear(), new Date().getMonth(),
						new Date().getDate() - this.$store.state.Usuario.maxDiasTroca
					).toLocaleDateString("en-CA");		
				}
			} else {
				filtro.dataI = new Date(
					new Date().getFullYear(), new Date().getMonth(),
					new Date().getDate() - this.$store.state.Usuario.maxDiasTroca
				).toLocaleDateString("en-CA");
			}
			
			return this.$store.dispatch("buscarVendasTroca", filtro);
		}
	},
};
</script>
<style scoped>
.container-page {
	width: 100%;
	height: 88vh;
	overflow: hidden;
}

.row-filtro{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;

}
.column-filtro{
	display: flex;
	flex-direction: column;
	width: 100%;
}
@media screen and (max-width:425px){
	.input-group{
		width: 100%;
	}
}
</style>