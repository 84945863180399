<template>
	<div class="containerPage">
		<div class="title">
			<h2 v-if="!PrevisaoCompra.id" >Cadastro Previsão de Compra</h2>
			<h2 v-else>Visualização Previsão Compra Cód. {{ form.id }} </h2>
		</div>
		<div class="contentPage">
			<div class="row">
				<div class="input-group">
					<input 
						type="text" 
						placeholder=" "
						v-model="fornecedor"
						@keydown.enter="proximoCampo('buscaFornecedor')"
						@click="() => { !form.cdcliente?buscaFornecedor = true:null }"
						autocomplete="off" 
						readonly
					/>
					<label>Fornecedor</label>
				</div>
				<i class="fas fa-times" style="color: var(--acao-texto)" @click="handleRemoveFornecedor" v-if="form.cdcliente"/>
				<div class="input-group data">
					<input 
						type="date" 
						id="dataPedido" 
						placeholder=" "
						v-model="form.datapedido" 
						autocomplete="off" 
						@keydown.enter="proximoCampo('prevEntrega')" 
						@blur="saiuData"
					/>
					<label for="dataPedido">
						Data Pedido
					</label>
				</div>
				<div class="input-group data" style="min-width:162px;">
					<input 
						type="date" 
						id="prevEntrega" 
						placeholder=" " 
						v-model="form.dataPrevista" 
						autocomplete="off" 
						@keydown.enter="proximoCampo(form.id ? 'dataEntrega' : 'qtdProdutos')"						
						@blur="handleParcelas();saiuData()"
					/>
					<label for="prevEntrega">
						Previsão Entrega
					</label>
				</div>
				<div class="input-group data">
					<input 
						type="date" 
						id="dataEntrega" 
						placeholder=" " 
						v-model="form.dataChegada" 
						autocomplete="off"
						:readonly="!form.id" 
						@keydown.enter="proximoCampo('qtdProdutos')" 
						@blur="handleParcelas();saiuData()" 
					/>
					<label for="dataEntrega">
						Data Entrega
					</label>
				</div>
			</div>
			<!-- VALORES -->
			<div class="row">
				<div class="input-group valor">
					<input 
						id="qtdProdutos" 
						type="text" 
						placeholder=" "
						v-mascaraInteger 
						autocomplete="off" 
						v-model="form.quantidade" 
						@keydown.enter="proximoCampo('vlrPedido')" 
					/>
					<label for="qtdProdutos">
						Qtd. Produtos
					</label>
				</div>
				<div class="input-group valor">
					<input 
						id="vlrPedido" 
						v-mascaraFloat
						type="text" 
						placeholder=" "
						autocomplete="off"
						v-model="form.valor" 
						@keydown.enter="proximoCampo('qtdParcelas')" 
						@input="handleParcelas"
					/>
					<label for="vlrPedido">
						Valor Pedido
					</label>
				</div>
				<div class="input-group valor">
					<input 
						id="qtdParcelas" 
						v-mascaraInteger
						type="text"
						v-model="qtdParcelas" 
						placeholder=" "
						@input="handleParcelas"
						autocomplete="off" 
						@keydown.enter="proximoCampo('frequencia')" 
					/>
					<label for="qtdParcelas">
						Qtd. parcelas
					</label>
				</div>
				<div class="input-group valor">
					<input 
						id="frequencia"
						type="text"
						v-mascaraInteger
						v-model="form.frequencia"
						placeholder=" "
						autocomplete="off"
						@input="handleParcelas"
						@keydown.enter="proximoCampo('observacao')" 
					/>
					<label for="frequencia">
						Frequência
					</label>
				</div> 
			</div>
			<div class="row w-100">
				<div class="textarea-group" style="margin-top:5px;">
					<textarea 
						id="observacao"
						class="w-100" 
						autocomplete="off"
						v-model="form.observacao"
						placeholder=" "
					>

					</textarea>
					<label for="observacao">
						Observação
					</label>
				</div>
			</div>			
			<div class="containerTabela">
				<table v-if="form.previsaoPagamentos.length > 0">
					<thead>
						<tr>
							<th>Sequência</th>
							<th>
								Metódo pagamento: &nbsp; 
								<select  @change="alterarFormasDePagamento()" v-model="tipoPagamentoPrincipal">
									<option v-for="(forma, index) in FormasPagamento" :key="index" :value="forma.cdtipo" >
										{{ forma.descricao }} 
									</option>
								</select>
							</th>
							<th>
								Valor Parcela
							</th>
							<th>
								Previsão vencimento
							</th>
							<th>
								Data Entrega
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(parcela, index) in form.previsaoPagamentos"
							:key="parcela.cdlan"
						>
							<td>
								{{ parcela.cdlan }}
							</td>
							<td>
								<select v-model="parcela.cdTpPagamento">
									<option v-for="(forma, index) in FormasPagamento" :key="index" :value="forma.cdtipo">
										{{ forma.descricao }}
									</option>
								</select>
							</td>
							<td>
								<input 
									type="text"
									v-mascaraFloat
									class="inputParcela"
									@input="recalculateValues(index)"
									v-model="parcela.valorparcela"
									autocomplete="off"
								/>
							</td>
							<td>
								<input 
									type="date"
									v-model="parcela.datavencimento"
									autocomplete="off"
								/>
							</td>
							<td>
								<!-- <i class="fas fa-check" v-if="!parcela.dataemissao" style="color: green" @click="addDataEntrega(parcela)"></i> -->
								<input 
									type="date"
									placeholder=" "
									id="dataEntrega"
									v-model="parcela.dataemissao"
									disabled
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="botoes">
				<button class="botao botao-cancelar" @click="$emit('cancelarCadastro')">
					<span>Cancelar</span>
				</button>
				<button class="botao botao-adicionar" @click="addItem">
					<span v-if="!this.form.id">Cadastrar</span>
					<span v-else>Salvar Alteração</span>
				</button>
			</div>
		</div>
		<Modal @fecharModal="buscaFornecedor = false" v-if="buscaFornecedor">
			<consulta-cliente  
				@selecionarPessoa="handleFornecedor" 
			/>
			<!-- <Paginacao tipoConteudo="Clientes" :desabilitarNovo="true">
				<TabelaPessoas tipoConteudo="Clientes" @selecionarPessoa="handleFornecedor"
					@fecharModal="buscarFornecedor = false" />
			</Paginacao> -->
		</Modal>
	</div>
</template>
<script>
import { FinanceiroPrevisao, PrevisaoCompra } from "../models/PrevisaoCompra";
import { proximoCampo } from "../utils/utils";
import Modal from "../components/Modal.vue";
// import TabelaPessoas from "../components/TabelaPessoas.vue";
// import Paginacao from "../components/Paginacao.vue";
import ConsultaCliente from "./ConsultaCliente.vue";
import { dateFormatISO } from "../utils/dateformat";
export default {
	name: "CadastroPrevisaoCompra",

	props: {
		PrevisaoCompra: Object
	},

	components: {
		Modal,
		ConsultaCliente,
		// TabelaPessoas,
		// Paginacao
	},

	data() {
		return {
			form: new PrevisaoCompra({}, null),
			
			fornecedor: "",
			buscaFornecedor: false,
			
			qtdParcelas: "",
			proximoCampo,

			dataPrevista: "",
			tipoPagamentoPrincipal: "",

			datasIniciais:{},
		};

	},

	computed: {
		FormasPagamento () {
			return this.$store.state.formasPagamento.filter(mt => (mt.mostracompra || "").toUpperCase() == "S");    
		}
	},

	mounted() {
		this.startComponent();
		window.addEventListener("keydown", (e) => {
			if(e.key === "F4"){
				e.preventDefault();
				this.buscaFornecedor = true;
			}
		});
		this.setDataPrevista();
	},

	destroyed() {
		window.removeEventListener("keydown", (e) => {return e;});
	},

	methods: {
		saiuData(){
			if(this.form.datapedido == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.form.datapedido = this.datasIniciais.datapedido? this.datasIniciais.datapedido : dateFormatISO({date: null, format: "ISOdate"});
			}
			if(this.form.dataPrevista == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.form.dataPrevista = this.datasIniciais.dataPrevista? this.datasIniciais.dataPrevista : dateFormatISO({date: null, format: "ISOdate"});
			}			
			if(this.form.dataChegada == "" && this.form.id){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.form.dataChegada = this.datasIniciais.dataChegada? this.datasIniciais.dataChegada : " ";
			}
		},
		iniciarFormasDePagamento() {
			this.tipoPagamentoPrincipal = this.FormasPagamento[0].cdtipo;
		},

		alterarFormasDePagamento() {
			if(this.form.previsaoPagamentos?.forEach){
				this.form.previsaoPagamentos?.forEach(parcela => {
					parcela.cdTpPagamento = this.tipoPagamentoPrincipal;
				});
			}
		},
		setDataPrevista () {
			if(this.form) {
				if (this.form.dataChegada) {
					document.getElementById("dataEntrega").disabled = true;
					document.getElementById("dataPedido").disabled = true;
					document.getElementById("prevEntrega").disabled = true;
				}
				return;
			}
			var data = new Date();
			data.setMonth(data.getMonth() +1);
			data.setDate(10);
			this.form.dataPrevista = data.toLocaleDateString("en-CA");
		},
		addDataEntrega(parcela) {
			parcela.dataemissao = new Date().toLocaleDateString("en-CA");
		},
		startComponent() {
			this.form = new PrevisaoCompra(this.PrevisaoCompra, this.PrevisaoCompra.id).converter();
			this.datasIniciais.dataChegada = this.form.dataChegada;
			this.datasIniciais.dataPrevista = this.form.dataPrevista;
			this.datasIniciais.datapedido = this.form.datapedido;
			this.qtdParcelas = this.form.previsaoPagamentos.length;
			this.form.previsaoPagamentos.forEach(parcela => {
				parcela.valorparcela = Number(parcela?.valorparcela || 0).toLocaleString("pt-BR", {
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				});
				parcela.dataemissao = this.form.dataChegada;
			});
			this.form.valor = this.form.valor.toLocaleString("pt-BR", {
				style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
			this.fornecedor = this.form.nome;
			this.iniciarFormasDePagamento();
		},

		//HANDLES
		handleRemoveFornecedor() {
			this.form.cdcliente = "",
			this.fornecedor = "";
		},

		handleFornecedor(fornecedor){
			this.form.cdcliente = fornecedor?.id;
			this.form.nome = fornecedor?.nome;
			this.fornecedor = fornecedor?.nome;
			this.buscaFornecedor = false;
			this.proximoCampo("dataPedido");
		},

		handleParcelas() {
			const valorTotal = Number(this.form.valor.replace(/\./g, "").replace(",", "."));
			if(valorTotal === 0 || !valorTotal) return;
			if(this.form.dataPrevista.trim() == "")return;
			this.form.previsaoPagamentos = [];
			const dataPrevisao = this.form.dataPrevista.split("-");
			const primeiroVencimento = new Date(				
				dataPrevisao[0],
				dataPrevisao[1]-1,
				dataPrevisao[2],
			);
			const valorParcela = Number(Number(valorTotal) / Number(this.qtdParcelas));	
			for(let i = 1; i <= this.qtdParcelas; i++){
				let vencimento = new Date(dataPrevisao[0],dataPrevisao[1]-1);
				vencimento.setDate(primeiroVencimento.getDate() + i * this.form.frequencia);
				vencimento = vencimento.toLocaleDateString("en-CA");
				if(Number(valorParcela) > 0){
					this.form.previsaoPagamentos.push({
						cdlan: i,
						datavencimento: vencimento,
						valorparcela: Number(valorParcela).toLocaleString("pt-BR", {
							style: "decimal",
							minimumFractionDigits: 2,
							maximumFractionDigits: 2
						}),
						dataemissao: this.form.dataChegada,
						cdTpPagamento: this.tipoPagamentoPrincipal
					});
				}
			}	
		},

		recalculateValues(idx) {
			const Pagamentos = this.form.previsaoPagamentos.map(pgto => new FinanceiroPrevisao(pgto));
			const parcelaAtual = Pagamentos[idx];
			const valorTotal = parcelaAtual.stringToNumber(this.form.valor);
			const valorTotalRestante = Number(valorTotal) - Number(parcelaAtual.stringToNumber(parcelaAtual.valorparcela));
			if(valorTotalRestante > 0){
				const parcelasRestante = Pagamentos.filter((pgto, index) => index != idx);
				if(Number(parcelaAtual.valorparcela) >= valorTotal){
					parcelasRestante.forEach((parcela, index) => {
						Pagamentos.splice(1, index);
					});
				}
				
				let novoValorParcela = Number(valorTotalRestante / parcelasRestante.length ).toFixed(2);
				parcelasRestante.forEach((parcela) => {
					parcela.valorparcela = Number(novoValorParcela).toLocaleString("pt-BR", {
						style: "decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				});
				this.form.previsaoPagamentos = Pagamentos;
			}
			else{
				this.handleParcelas();
				return this.$store.commit("relatarErro", { mensagem: "O valor da parcela deve ser menor que o valor total!" });
			}
		},

		//VALIDATION
		validateFields() {
			try {
				let dataPedidoNumero = parseInt(this.form.datapedido.replaceAll("-", ""));
				let dataPrevistaNumero = parseInt(this.form.dataPrevista.replaceAll("-", ""));
				let dataChegadaNumero = this.dataChegada != "" ? parseInt(this.form.dataChegada.replaceAll("-", "")) : "";
				if (dataPedidoNumero > dataPrevistaNumero) {
					throw new Error("A data prevista não pode ser anterior a data do pedido");
				}
				if (dataChegadaNumero != "" && dataPedidoNumero > dataChegadaNumero) {
					throw new Error("A data de entrega não pode ser anterior a data do pedido");
				}
				if(!this.form.cdcliente) {
					throw new Error("É necessário informar um fornecedor!");
				}
				if(!this.form.datapedido) {
					throw new Error("É necessário informar uma data do pedido!");
				}
				if(!this.form.dataPrevista) {
					throw new Error("É necessário informar uma data prevista da entrega!");
				}
				if(!this.form.quantidade || this.quantidade === 0) {
					throw new Error("É necessário informar a quantidade correta de produtos previsto na compra!");
				}
				if(!this.qtdParcelas || this.qtdParcelas === 0) {
					throw new Error("A quantidade de parcelas deve ser superior a 0!");
				}
				if(!this.form.valor || Number(this.form.valor.replace(/\./g, "").replace(",", ".")) <= 0) {
					throw new Error("O valor do pedido não pode ser igual a 0!");
				}
				return true;
				
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
				return false;
			}
		},

		//DISPATCHS
		async addItem() {
			if(!this.validateFields()) return;
			this.form.previsaoPagamentos.forEach(parcela => {
				parcela.dataemissao = this.form.dataChegada;
			});
			if(!this.form.id){
				const previsao = new PrevisaoCompra(this.form).converter();
				const data = await this.$store.dispatch("addPrevisao", {...previsao});
				if(data.id) {
					this.$store.commit("relatarErro", {
						mensagem: `A previsão de compra nº ${data.id} foi cadastrada com sucesso!`,
						sucess: true
					});
					this.$emit("cancelarCadastro");
					return;
				} else {
					return this.$store.commit("relatarErro", {
						mensagem: data.mensagem || data.details || data
					});
				}
				
			} else {
				const previsao = new PrevisaoCompra(this.form, this.form.id).converter();
				const data = await this.$store.dispatch("modifyPrevisao", {...previsao});
				if(data.id) {
					this.$store.commit("relatarErro", {
						mensagem: "A previsão de compra foi alterada com sucesso!",
						sucess: true
					});
					this.$emit("cancelarCadastro");
				}
			}
		},
		
	}
};
</script>

<style scoped>
.containerPage{
	width: 100%;
	height: 92vh;
	padding: 10px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;

}
.contentPage{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 92vh;
	gap: 20px;
}

.containerTabela{
	width: 100%;
	height: 30vh;
	overflow-y: auto;

}
.title{
	text-align: center;
	padding: 10px;
}
.row{
	align-items: center;
}
.input-group{
	width: 350px;
}
.botao{
	width: 150px;
}
.data{
	width: 200px;
}
.valor {
	width: 200px;
}

.valor input{
	text-align: right;
}

.textarea-group{
	width: 100%;
	height: 15vh;
}

.botoes {
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	margin-bottom: 20px;
}

.botoes .botao {
	width: 45%;
}

table {
	width: 100%;
}

table tbody tr td {
	text-align: center;
}

table tbody tr td input{
	background: transparent;
	outline: none;
	border: none;
	border-bottom: 2px solid #000;
}

.inputParcela{
	text-align: right;
}

.input-group:focus-within label,
.input-group input:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}
@media screen and (max-width:425px){
	.row{
		flex-wrap: wrap;
		margin:0px;
	}
	.input-group{
		margin-top:9px;
		max-width:40%;
	}
	.contentPage{
		gap:5px;
	}
	.title{
		padding:0px;
	}	
	.textarea-group{
		height:60px;
	}
	.containerTabela{
		width: 100%;
		height: 19vh;
		overflow:auto;
	}
}
@media screen and (max-width:375px){
	.input-group{
		max-width:43%;
	}
}
</style>